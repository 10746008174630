import useScreenDimensions from '../../hooks/useScreenDimension';

const EarningsUpArrow = () => {
  const isMobile = useScreenDimensions();
  const fillColor = isMobile ? '#065BDB' : 'green';
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4039 7.11355L8.50205 4.21035V13.0052C8.50205 13.1378 8.44937 13.265 8.35561 13.3588C8.26184 13.4525 8.13466 13.5052 8.00205 13.5052C7.86944 13.5052 7.74227 13.4525 7.6485 13.3588C7.55473 13.265 7.50205 13.1378 7.50205 13.0052V4.21885L4.6089 7.11355C4.51466 7.20528 4.38808 7.25621 4.25657 7.25531C4.12506 7.25441 3.99919 7.20176 3.90621 7.10874C3.81323 7.01573 3.76062 6.88984 3.75978 6.75833C3.75893 6.62681 3.80991 6.50026 3.90168 6.40605L7.65278 2.65305C7.69921 2.6066 7.75434 2.56974 7.81502 2.5446C7.8757 2.51946 7.94073 2.50652 8.00641 2.50652C8.0721 2.50652 8.13713 2.51946 8.19781 2.5446C8.25849 2.56974 8.31362 2.6066 8.36005 2.65305L12.1112 6.40605C12.2034 6.50017 12.2548 6.62688 12.2541 6.75865C12.2535 6.89043 12.2008 7.01662 12.1077 7.10982C12.0145 7.20301 11.8883 7.25568 11.7566 7.25638C11.6248 7.25708 11.4981 7.20575 11.4039 7.11355Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default EarningsUpArrow;
