import { conversionText } from '../dataConversion/ja';

export const eventsTabKeys = [
  'time',
  'country',
  'importance',
  'event',
  'actual',
  'forecast',
  'previous',
  // 'alert',
  'expand',
];

export const eventsTabKeysMobile = [
  [['country', 'importance', 'event'], ['time']],
  [
    [['actual', 'forecast'], ['previous']],
    // , ['bell']
  ],
];

export const earningsTabKeysMobile = [
  [['country', 'importance', 'company'], ['time']],
  [['earning per share', 'revenue']],
];

export const dividendsTabKeys = ['country', 'company', 'payment day', 'amount'];

export const dividendsTabKeysMobile = [
  [['country'], ['company']],
  [['payment day'], ['amount']],
];

export const ipoTabKeysMobile = [
  [['country'], ['company']],
  [
    ['exchange', 'startPrice'],
    ['value', 'date'],
  ],
];

export const stockTabKeysMobile = [[['country', 'company'], ['ratio']]];

export const ipoTabKeys = ['country', 'company', 'exchange', 'startPrice', 'value'];
export const stockTabKeys = ['country', 'company', 'ratio'];
export const earningsTabKeys = [
  'time',
  'country',
  'importance',
  'company',
  'earning per share',
  'revenue',
];

export const checkName = {
  startPrice: 'Start Price',
  ratio: 'Ratio',
  paymentDate: 'Payment Date',
  company: 'Company Name',
  previous: 'Previous',
  revenuePrevious: 'Revenue',
};

export const headingsNameCheck = {
  'payment day': 'paymentday',
  'earning per share': 'earningspershare',
};

export const dataAccessProperty = {
  eventstime: 'date',
  'dividends-reportscompany': 'name',
  iposcompany: 'name',
  'dividends-reportspayment day': 'paymentDate',
};

export const tabsTitles = {
  events: 'Macroeconomic Events',
  'earnings-reports': 'Earnings',
  'dividends-reports': 'Dividends',
  'stock-splits': 'Splits',
  ipos: 'IPOs',
};

export const getConversionText = (text, locale, tabKey, data) => {
  if (text && locale === 'ja' && tabKey === 'event') {
    const key = `${text?.toLowerCase()?.split(' ')?.join('_')}.title:${locale}:${data.country}`;
    const defaultKey = `${text?.toLowerCase()?.split(' ')?.join('_')}.title:${locale}:DEFAULT`;
    const convertedText = conversionText[key] || conversionText[defaultKey];
    if (convertedText) {
      return convertedText;
    } else {
      return text;
    }
  }

  return text;
};

export const getFormattedDescription = (expandedSection, locale) => {
  if (expandedSection.description && locale === 'ja') {
    const key = `${expandedSection.event
      .toLowerCase()
      ?.split(' ')
      ?.join('_')}.description:${locale}:${expandedSection.country}`;
    const defaultKey = `${expandedSection.event
      .toLowerCase()
      ?.split(' ')
      ?.join('_')}.description:${locale}:DEFAULT`;
    const convertedText = conversionText[key] || conversionText[defaultKey];
    if (convertedText) {
      return convertedText;
    } else {
      return expandedSection.description;
    }
  }

  return expandedSection.description;
};
