import { useEffect, useState } from 'react';
import NoEventsMobileScreen from '../assets/svgs/NoEventsMobileScreen';
import NoNetworkMobile from '../assets/svgs/NoNetworkMobile';
import SomethingWentWrong from '../assets/svgs/SomethingWentWrong';
import { useEconCalendarParams } from '../hooks/use-econ-calendar-params';
import { useEconCalendar } from '../hooks/useEconCalendar';
import style from '../styles/Error/MobileErrorScreen.module.css';
import { accounts } from '../utils/accountRegions';
import { continents, getContinentForCountry, getCountryCode } from '../utils/util';
import { useSessionData } from '../hooks/use-session-data';

const MobileErrorScreen = ({ errorMessage, t }) => {
  const {
    searchText,
    changeDateFilter,
    updateShowSearch,
    handleResetToDefault,
    activeDateFilter,
    fetchData,
    econData,
    selectedImportance,
    allCountry,
    activeTab,
  } = useEconCalendar();
  const { isIos } = useEconCalendarParams();
  const { igCompanySiteId } = useSessionData();
  const [showClearFilter, setShowClearFilter] = useState(false);
  const changeDate = () => {
    changeDateFilter('next 14 days', '');
  };

  useEffect(() => {
    const completeData = econData[activeDateFilter[0]];
    const value = accounts[igCompanySiteId] && getCountryCode(accounts[igCompanySiteId]);
    const continentName = getContinentForCountry(igCompanySiteId);

    if (!continents[continentName]?.includes(value[0][0])) {
      continents[continentName]?.push(value[0][0]);
    }

    const allCountries = Array.from(new Set(Object.values(continents).flat()));
    let countryFound = false;
    completeData &&
      Object.keys(completeData)?.forEach((value, index) => {
        const data = completeData[value];
        data?.forEach((dataValue, index1) => {
          if (
            allCountries?.includes(
              activeTab !== 'dividends-reports'
                ? getCountryCode(dataValue?.country)?.[0]?.[0]
                : dataValue?.symbol?.split(':')[1],
            )
          ) {
            countryFound = true;
            setShowClearFilter(true);
            return;
          }
        });
      });

    if (!countryFound && showClearFilter) {
      setShowClearFilter(false);
    }
  }, [activeDateFilter, searchText, selectedImportance, allCountry]);

  const updateFilters = (filter) => {
    if (filter === 'search') {
      updateShowSearch();
    } else {
      handleResetToDefault('all');
    }
  };

  const fetchDataAgain = async () => {
    if (activeDateFilter[0][0] === 'custom date') {
      if (!('today' in econData)) {
        await fetchData('', 'today');
      }
      await fetchData(activeDateFilter[0][1], activeDateFilter[0][0]);
    } else {
      await fetchData('', 'today');
    }
  };
  return errorMessage?.toLowerCase().includes('no network access.') ? (
    <div className={isIos ? style.errorWrapperIos : style.errorWrapperAndroid}>
      <div className={isIos ? style.iosError : style.androidError}>
        <NoNetworkMobile />
        <div className={isIos ? style.iosErrorMessageWrapper : style.androidErrorMessageWrapper}>
          <p
            className={`${isIos ? style.iosErrorMessageFirstLine : style.androidErrorMessageFirstLine}`}
          >
            {t(`No network access`)}
          </p>
          <p className={`${isIos ? style.iosErrorMessage : style.androidErrorMessage}`}>
            {t('Connect to the internet to see events.')}
          </p>
        </div>
      </div>
    </div>
  ) : errorMessage?.toLowerCase().includes(`we currently can't access`) ? (
    <div className={isIos ? style.errorWrapperIos : style.errorWrapperAndroid}>
      <div className={isIos ? style.iosError : style.androidError}>
        <SomethingWentWrong />
        <div className={isIos ? style.iosErrorMessageWrapper : style.androidErrorMessageWrapper}>
          <p
            className={`${isIos ? style.iosErrorMessageFirstLine : style.androidErrorMessageFirstLine}`}
          >
            {t('Something went wrong')}
          </p>
          <p className={`${isIos ? style.iosErrorMessage : style.androidErrorMessage}`}>
            {t(`We currently can't access calendar data. Please try again later.`)}
          </p>
        </div>
        <button
          className={isIos ? style.iosTryAgain : style.androidTryAgain}
          onClick={fetchDataAgain}
        >
          {t('Try again')}
        </button>
      </div>
    </div>
  ) : searchText.length > 0 &&
    errorMessage?.toLowerCase().includes('sorry, no results match your search') ? (
    <div className={isIos ? style.errorWrapperIos : style.errorWrapperAndroid}>
      <div className={isIos ? style.iosError : style.androidError}>
        <NoEventsMobileScreen />
        <div className={isIos ? style.iosErrorMessageWrapper : style.androidErrorMessageWrapper}>
          <p
            className={`${isIos ? style.iosErrorMessageFirstLine : style.androidErrorMessageFirstLine}`}
          >
            {t('No events match the search text')}
          </p>
          <p className={`${isIos ? style.iosErrorMessage : style.androidErrorMessage}`}>
            {t('Change the date range or broaden your filters')}
          </p>
        </div>
      </div>
      <div className={style.actionButtons}>
        {activeDateFilter[0] !== 'next 14 days' && (
          <button className={`${style.actionButton}`} onClick={changeDate}>
            {t('See next 14 days')}
          </button>
        )}
        <button className={`${style.actionButton}`} onClick={() => updateFilters('search')}>
          {t('Clear search')}
        </button>
      </div>
    </div>
  ) : errorMessage?.length && errorMessage.toLowerCase().includes('no events to display.') ? (
    <div className={isIos ? style.errorWrapperIos : style.errorWrapperAndroid}>
      <div className={isIos ? style.iosError : style.androidError}>
        <NoEventsMobileScreen />
        <div className={isIos ? style.iosErrorMessageWrapper : style.androidErrorMessageWrapper}>
          <p
            className={`${isIos ? style.iosErrorMessageFirstLine : style.androidErrorMessageFirstLine}`}
          >
            {t('No events match the criteria')}
          </p>
          <p className={`${isIos ? style.iosErrorMessage : style.androidErrorMessage}`}>
            {t('Change the date range or broaden your filters')}
          </p>
        </div>
      </div>
      <div className={style.actionButtons}>
        {activeDateFilter[0] !== 'next 14 days' && (
          <button className={`${style.actionButton}`} onClick={changeDate}>
            {t('See next 14 days')}
          </button>
        )}
        {showClearFilter && (
          <button className={`${style.actionButton}`} onClick={() => updateFilters('filters')}>
            {t('Clear filters')}
          </button>
        )}
      </div>
    </div>
  ) : (
    <></>
  );
};

export default MobileErrorScreen;
