const CalendarIconMobile = () => {
  const fillColor = '#000';
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.886 8.755H10.636V10.005H11.886V8.755ZM11.886 11.255H10.636V12.505H11.886V11.255ZM14.386 8.755H13.136V10.005H14.386V8.755ZM14.386 11.255H13.136V12.505H14.386V11.255ZM6.88599 8.755H5.63599V10.005H6.88599V8.755ZM6.88599 11.255H5.63599V12.505H6.88599V11.255ZM9.38599 8.755H8.13599V10.005H9.38599V8.755ZM9.38599 11.255H8.13599V12.505H9.38599V11.255ZM11.886 13.755H10.636V15.005H11.886V13.755ZM14.386 13.755H13.136V15.005H14.386V13.755ZM6.88599 13.755H5.63599V15.005H6.88599V13.755ZM9.38599 13.755H8.13599V15.005H9.38599V13.755ZM5.01099 6.255H15.011V7.505H5.01099V6.255ZM8.13599 3.755H11.886V5.005H8.13599V3.755ZM5.63599 3.755H6.88599V5.005H5.63599V3.755ZM13.136 3.755H14.3879V5.005H13.136V3.755Z"
        fill="black"
      />
      <path
        d="M15.6404 3.75531V5.00562C15.9835 5.01094 16.261 5.29156 16.261 5.63562V15.6244C16.261 15.9722 15.9782 16.255 15.6304 16.255H4.39161C4.0438 16.255 3.76099 15.9722 3.76099 15.6244V5.63562C3.76099 5.2925 4.03661 5.01312 4.37817 5.00562V3.75531C3.34755 3.76281 2.51099 4.60312 2.51099 5.63562V15.6244C2.51099 16.6612 3.35474 17.505 4.39161 17.505H15.6304C16.6672 17.505 17.511 16.6612 17.511 15.6244V5.63562C17.511 4.60187 16.6729 3.76062 15.6404 3.75531Z"
        fill={fillColor}
      />
      <path
        d="M14.3879 3.13094C14.3879 2.78525 14.1076 2.505 13.7619 2.505C13.4162 2.505 13.136 2.78525 13.136 3.13094V4.37907C13.136 4.72476 13.4162 5.005 13.7619 5.005C14.1076 5.005 14.3879 4.72476 14.3879 4.37907V3.13094Z"
        fill="black"
      />
      <path
        d="M6.88688 3.13094C6.88688 2.78525 6.60664 2.505 6.26095 2.505C5.91525 2.505 5.63501 2.78525 5.63501 3.13094V4.37907C5.63501 4.72476 5.91525 5.005 6.26095 5.005C6.60664 5.005 6.88688 4.72476 6.88688 4.37907V3.13094Z"
        fill="black"
      />
    </svg>
  );
};

export default CalendarIconMobile;
