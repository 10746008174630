// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hsgXTtD0UmlZU2M8adEz {
  color: var(--error-message-color);
  margin: 100px auto 0 auto;
  /* font-family: 'MatterIGTF-Regular', sans-serif; */
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  text-align: left;
}

.lQyzKPlZPGhdRfIlVf_E {
  color: var(--error-message-color);
  margin: 100px auto 0 auto;
  /* font-family: 'MatterIGTF-Regular', sans-serif; */
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  text-align: left;
}
`, "",{"version":3,"sources":["webpack://./src/styles/Error/DesktopErrorScreen.module.css"],"names":[],"mappings":"AAAA;EACE,iCAAiC;EACjC,yBAAyB;EACzB,mDAAmD;EACnD,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,iCAAiC;EACjC,yBAAyB;EACzB,mDAAmD;EACnD,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,gBAAgB;AAClB","sourcesContent":[".errorMessage {\n  color: var(--error-message-color);\n  margin: 100px auto 0 auto;\n  /* font-family: 'MatterIGTF-Regular', sans-serif; */\n  font-weight: 400;\n  font-size: 13px;\n  line-height: 20px;\n  text-align: left;\n}\n\n.noDataFound {\n  color: var(--error-message-color);\n  margin: 100px auto 0 auto;\n  /* font-family: 'MatterIGTF-Regular', sans-serif; */\n  font-weight: 400;\n  font-size: 13px;\n  line-height: 20px;\n  text-align: left;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"errorMessage": `hsgXTtD0UmlZU2M8adEz`,
	"noDataFound": `lQyzKPlZPGhdRfIlVf_E`
};
export default ___CSS_LOADER_EXPORT___;
