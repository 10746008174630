import { useState, useEffect, useRef } from 'react';
import { useEconCalendar } from '../../hooks/useEconCalendar';
import { useTranslation } from 'react-i18next';
import style from '../../styles/EconCalendarTabs.module.css';
import DayPicker from '../DayPicker';
import EconCalendarFilters from '../EconCalendarFilters';
import SearchBox from '../Filters/SearchBox';
import useScreenDimensions from '../../hooks/useScreenDimension';
import MobileNavButton from './MobileNavButton';
import TabsList from './TabsList';
import FilterComponents from '../Filters/FilterComponents';
import { useEconCalendarParams } from '../../hooks/use-econ-calendar-params';
import ZoneCountryImportance from '../Filters/ZoneCountryImportance';
import { eventNameMappingForMobile } from '../../utils/util';

const EconCalendarTabs: React.FC = () => {
  const { t } = useTranslation();
  const {
    changeActiveTab,
    activeTab,
    showDetail,
    toggleDetail,
    expandTabs,
    updateExpandTabs,
    showSearch,
    showFilters,
    updateShowSearch,
    updateShowFilters,
    isDataLoading,
    updateCalendarVisibility,
    extendedCountryFilter,
  } = useEconCalendar();
  const isMobile = useScreenDimensions();
  const sectionRef = useRef<HTMLUListElement>(null);
  const [focusedButton, setFocusedButton] = useState<string | null>(null);
  const { isIos, isAndroid, isWebsite, isIpad, backButtonHandler, specifySpecificTab } =
    useEconCalendarParams();

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (sectionRef.current && !sectionRef.current.contains(event.target as Node)) {
        updateExpandTabs(false);
      }
    };
    document.addEventListener('keydown', handleClick);

    return () => document.removeEventListener('keydown', handleClick);
  }, []);

  const handleButtonClick = () => {
    if (showSearch) {
      updateShowSearch();
    } else {
      showFilters
        ? updateShowFilters()
        : (isAndroid || isIos) && !showDetail
          ? // @ts-ignore
            backButtonHandler?.postMessage(
              JSON.stringify({
                type: 'app/click',
                identifier: 'BACK_BUTTON_CLICK',
                data: 'Back Button Click',
              }),
            )
          : toggleDetail('');
    }
  };

  const expandTabsClass = expandTabs
    ? isAndroid || isWebsite
      ? style.tabSectionMobileExpanded
      : style.iosTabSectionMobileExpanded
    : style.tabSectionMobile;
  const handleListClass = isMobile ? expandTabsClass : style.tabsSection;

  const notWebsite = isAndroid || isIos;

  const showBackButton =
    (showDetail && isAndroid) ||
    (!showDetail && (isAndroid || isIos) && !isIpad) ||
    (showDetail && isIos) ||
    (showDetail && isWebsite && !notWebsite);

  return (
    <div className={isMobile ? style.nav_filter_wrapper_mobile : style.nav_filter_wrapper}>
      <nav
        className={`${isMobile ? style.nav_wrapper_mobile : specifySpecificTab ? '' : style.nav_wrapper} ${
          showFilters ? style.showShadow : style.noShadow
        } ${isIos ? style.nav_wrapper_ios : style.none}`}
      >
        {showBackButton && (
          <MobileNavButton
            isMobile={isMobile}
            showFilters={showFilters}
            showDetail={showDetail}
            handleButtonClick={handleButtonClick}
            isIos={isIos}
            t={t}
          />
        )}
        {showFilters && (
          <div className={`${style.filterHeading} calendar-medium`}>{t('Filters')}</div>
        )}
        {showSearch ? (
          <div
            className={`${style.searchBoxWrapper} ${
              showSearch ? style.searchBoxWrapperVisible : ''
            }`}
          >
            <SearchBox />
          </div>
        ) : (
          !showFilters &&
          !specifySpecificTab && (
            <div
              className={
                isMobile && expandTabs
                  ? isIos
                    ? style.nav_wrapper_mobile_expanded_ios
                    : isWebsite
                      ? style.nav_wrapper_mobile_expanded_web
                      : style.nav_wrapper_mobile_expanded
                  : style.none
              }
            >
              <TabsList
                isMobile={isMobile}
                expandTabs={expandTabs}
                handleListClass={handleListClass}
                sectionRef={sectionRef}
                focusedButton={focusedButton}
                setFocusedButton={setFocusedButton}
                activeTab={activeTab}
                changeActiveTab={changeActiveTab}
                updateExpandTabs={updateExpandTabs}
                showDetail={showDetail}
                isDataLoading={isDataLoading}
                updateCalendarVisibility={updateCalendarVisibility}
              />
            </div>
          )
        )}
        {isMobile && expandTabs && (
          <div className={style.modal} role="button" onClick={() => updateExpandTabs(false)}></div>
        )}
        {isIos && expandTabs && (
          <span
            className={`${style.tabName} ${isMobile ? 'calendar-medium' : 'none'} ${
              isMobile ? style.mobileTabName : style.none
            }`}
          >
            {t(eventNameMappingForMobile[activeTab])}
          </span>
        )}
        {isMobile && isIos && !specifySpecificTab && (
          <button className={style.changeBtn} onClick={() => updateExpandTabs(null)}>
            {t('change')}
          </button>
        )}
      </nav>
      {isMobile && isIos && <SearchBox />}
      {!showFilters &&
        ((!showDetail && !isIos) || (isMobile && isIos && !extendedCountryFilter) || !isMobile) && (
          <DayPicker />
        )}
      {isMobile && showFilters ? (
        <div className={style.filterPageMobile}>
          <FilterComponents />
        </div>
      ) : (
        !showDetail && (!isMobile || !isIos) && <EconCalendarFilters />
      )}

      {isMobile && isIos && !showDetail && (
        <div className={style.iosFilters}>
          <ZoneCountryImportance />
        </div>
      )}
    </div>
  );
};

export default EconCalendarTabs;
