import EarningsUpArrow from '../../assets/svgs/EarningsUpArrow';
import EarningsDownArrow from '../../assets/svgs/EarningsDownArrow';
import useScreenDimensions from '../../hooks/useScreenDimension';
import { formatToTwoDecimalPlaces, getValueWithCurrency } from '../../utils/util';
import { useTranslation } from 'react-i18next';
import { useEconCalendarParams } from '../../hooks/use-econ-calendar-params';

const EarningPerShare = ({ marketTrend, singleDataEntry }) => {
  const isMobile = useScreenDimensions();
  const { t } = useTranslation();
  const { locale } = useEconCalendarParams();
  const actualClass = singleDataEntry.actual
    ? marketTrend[0] > 0
      ? 'actualPositive'
      : 'actualNegative'
    : 'noMarketTrend';
  return (
    <div className="earningspershare">
      {isMobile && (
        <div className={`${isMobile ? 'calendar-regular' : 'none'} eps`}> {t('EPS')}: </div>
      )}
      <div className="actual">
        {!isMobile && <div className="label">{t('Actual')}</div>}

        <div className={actualClass}>
          <span className={`${isMobile ? 'calendar-regular' : 'none'} actualValue`}>
            {singleDataEntry?.actual
              ? getValueWithCurrency(
                  formatToTwoDecimalPlaces(singleDataEntry['actual']),
                  locale,
                  singleDataEntry['currency'],
                  false,
                )
              : '-'}
          </span>
          {singleDataEntry.actual &&
            (marketTrend[0] > 0 ? <EarningsUpArrow /> : <EarningsDownArrow />)}
        </div>
      </div>
      <div className={`${isMobile ? 'calendar-regular' : 'none'} forecast`}>
        {!isMobile && <span>{t('Forecast')}</span>}
        <span>
          {singleDataEntry?.forecast
            ? getValueWithCurrency(
                formatToTwoDecimalPlaces(singleDataEntry['forecast']),
                locale,
                singleDataEntry['currency'],
                false,
              )
            : '-'}
        </span>
      </div>
      <div className={`${isMobile ? 'calendar-regular' : 'none'} previous`}>
        {!isMobile && <span>{t('Previous')}</span>}
        <span>
          {singleDataEntry?.previous
            ? getValueWithCurrency(
                formatToTwoDecimalPlaces(singleDataEntry['previous']),
                locale,
                singleDataEntry['currency'],
                false,
              )
            : '-'}
        </span>
      </div>
    </div>
  );
};

export default EarningPerShare;
