import { useEconCalendarParams } from '../../hooks/use-econ-calendar-params';

const SelectedTabTick = () => {
  const { currentTheme } = useEconCalendarParams();
  const fillColor = currentTheme === 'dark' ? '#000' : '#fff';
  return (
    <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.4312 6.57943L6.29639 11.6399L14.5689 0.809267C14.7022 0.634418 14.9132 0.522323 15.1455 0.502983C15.3777 0.483643 15.6074 0.559033 15.7731 0.708975C16.0478 0.973114 16.0763 1.37908 15.8408 1.67314L7.09975 13.1173C6.9164 13.3571 6.61646 13.5 6.29635 13.5C5.97624 13.5 5.67631 13.3571 5.49296 13.1173L1.15924 7.44337C0.923667 7.14929 0.952147 6.74325 1.22688 6.47907C1.39263 6.32913 1.62236 6.25375 1.85459 6.27311C2.08683 6.29246 2.29786 6.40457 2.4312 6.57943Z"
        fill={fillColor}
        stroke={fillColor}
      />
    </svg>
  );
};

export default SelectedTabTick;
