import { useEffect, useRef, useState } from 'react';
import style from '../../styles/filters/CountryFilter.module.css';
import {
  arraysHaveSameElements,
  continents,
  findContinentbyCountry,
  getCountryCode,
} from '../../utils/util';
import CountryList from './CountryList';
import { useEconCalendar } from '../../hooks/useEconCalendar';
import useScreenDimensions from '../../hooks/useScreenDimension';
import { useTranslation } from 'react-i18next';
import CustomCheckbox from './CustomCheckbox';
import { useSessionData } from '../../hooks/use-session-data';
import { accounts } from '../../utils/accountRegions';
import { useEconCalendarParams } from '../../hooks/use-econ-calendar-params';
import CancelSearchIos from '../../assets/svgs/CancelSearchIos';

const CountryFilter = () => {
  const [selectedContinent, setSelectedContinent] = useState('Americas');
  const { t } = useTranslation();
  const {
    selectedContinents,
    allCountry,
    eventsDefaultContinentList,
    eventsDefaultCountryList,
    nonEventsDefaultContinentList,
    nonEventsDefaultCountryList,
    updateContinentSelection,
    extendedCountryFilter,
    updateExtendedCountryFilter,
    showFilters,
    updateShowFilters,
    selectCountry,
    activeTab,
    handleResetToDefault,
    defaultFilterButtonDisabled,
    updateResetToDefaultState,
  } = useEconCalendar();

  const { isIos } = useEconCalendarParams();

  const sectionRef = useRef(null);
  const importanceFilterPresent = ['events', 'earnings-reports'].includes(activeTab);
  const isMobile = useScreenDimensions();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedCountryIndex, setSelectedCountryIndex] = useState(null);
  const [activeSelected, setActiveSelected] = useState('continent');
  const { igCompanySiteId } = useSessionData();

  const continentList = Object.keys(continents);
  const countryList = Object.values(continents);

  const countryAccount =
    igCompanySiteId && accounts[igCompanySiteId]
      ? getCountryCode(accounts[igCompanySiteId])[0][0]
      : 'US';
  const countryContinent =
    igCompanySiteId && accounts[igCompanySiteId]
      ? findContinentbyCountry(igCompanySiteId)
      : 'Americas';

  if (!continents[countryContinent]?.includes(countryAccount)) {
    continents[countryContinent]?.push(countryAccount);
  }

  const handleKeySelection = (continent) => {
    updateSelectedContinent(continent);
    updateSelection(continent);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const clickedElement = event.target;
      const countryFilterElement = clickedElement.closest(`.countryFilter`); // Select closest ancestor with class
      if (
        extendedCountryFilter &&
        sectionRef.current &&
        !sectionRef.current.contains(event.target) &&
        !countryFilterElement
      ) {
        updateExtendedCountryFilter();
      }
    };

    const handleKeyDown = (event) => {
      const { key } = event;
      const optionsLength = continentList.length;
      const optionsCountryLength = countryList[selectedIndex].length;

      if (key === 'ArrowDown') {
        activeSelected === 'continent' &&
          setSelectedIndex((prevIndex) => (prevIndex < optionsLength - 1 ? prevIndex + 1 : 0));
        activeSelected === 'country' &&
          setSelectedCountryIndex((prevIndex) =>
            prevIndex < optionsCountryLength - 1 ? prevIndex + 1 : 0,
          );
      } else if (key === 'ArrowUp') {
        activeSelected === 'continent' &&
          setSelectedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : optionsLength - 1));
        activeSelected === 'country' &&
          setSelectedCountryIndex((prevIndex) =>
            prevIndex > 0 ? prevIndex - 1 : optionsCountryLength - 1,
          );
      } else if (key === 'ArrowRight') {
        updateSelectedContinent(continentList[selectedIndex]);
        setActiveSelected('country');
        setSelectedCountryIndex(0);
      } else if (key === 'ArrowLeft') {
        setActiveSelected('continent');
        setSelectedIndex((prev) => prev);
        setSelectedCountryIndex(null);
      } else if (key === 'Escape') {
        updateExtendedCountryFilter();
      } else if (key === ' ' || key === 'Enter') {
        if (activeSelected === 'continent') {
          handleKeySelection(continentList[selectedIndex]);
        } else if (activeSelected === 'country') {
          selectCountry(countryList[selectedIndex][selectedCountryIndex]);
        }
      }
      event.preventDefault();
    };

    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('mousedown', handleClickOutside); // Listen for clicks outside the component

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [
    extendedCountryFilter,
    selectedIndex,
    selectedCountryIndex,
    activeSelected,
    updateExtendedCountryFilter,
    handleKeySelection,
    selectCountry,
  ]);

  useEffect(() => {
    const checkAndSetButtonState = (contList, countryList) => {
      const isEqual =
        arraysHaveSameElements(selectedContinents, contList) &&
        arraysHaveSameElements(allCountry, countryList);
      setTimeout(() => updateResetToDefaultState(isEqual), 0);
    };

    if (activeTab === 'events') {
      checkAndSetButtonState(eventsDefaultContinentList, eventsDefaultCountryList);
    } else {
      checkAndSetButtonState(nonEventsDefaultContinentList, nonEventsDefaultCountryList);
    }
  }, [allCountry, selectedContinent]);

  useEffect(() => {
    activeSelected === 'continent' && updateSelectedContinent(continentList[selectedIndex]);
  }, [selectedIndex, selectedCountryIndex]);

  const updateSelectedContinent = (continent) => {
    setSelectedContinent(continent);
  };

  const updateSelectedContinentIos = (continent) => {
    if (showSelectDeselect(continent)) updateContinentSelection(continent, 'select');
    else updateContinentSelection(continent, 'deselect');
  };

  const updateSelection = (continent) => {
    if (!selectedContinents?.includes(continent)) {
      updateContinentSelection(continent, 'select');
    } else {
      updateContinentSelection(continent, 'deselect');
    }
  };

  const getSelectedCountryCount = (continent) => {
    const allContinentCountries = continents[continent];
    const matchingValues = allContinentCountries?.filter((country) =>
      allCountry?.includes(country),
    );
    return matchingValues.length;
  };

  const showSelectDeselect = (continent) => {
    if (getSelectedCountryCount(continent) !== continents[continent].length) return true;
    else return false;
  };

  return (
    <>
      {isIos && extendedCountryFilter && (
        <div className={style.modal} onClick={updateExtendedCountryFilter} role="button" />
      )}
      <div
        className={`${
          isMobile
            ? isIos
              ? style.countryFilterPageIosMobile
              : style.countryFilterPageMobile
            : style.expandedCountrySection
        } ${importanceFilterPresent ? style.importanceMargin : style.noMargin}`}
        ref={!isMobile ? sectionRef : null}
      >
        <div
          className={isMobile ? style.countryFilterPageContainer : style.expandedCountryContainer}
        >
          {isMobile && isIos && extendedCountryFilter && (
            <div className={style.countryIosMenuSection}>
              <div className={style.countryIosMenuText}>
                <button
                  className={defaultFilterButtonDisabled ? style.noCursor : style.cursor}
                  disabled={defaultFilterButtonDisabled}
                  onClick={() => handleResetToDefault('')}
                >
                  {t('Reset')}
                </button>
                <span>{t('Country/Region')} </span>
              </div>
              <button onClick={updateExtendedCountryFilter}>
                <CancelSearchIos />
              </button>
            </div>
          )}
          {showFilters && (
            <div className={`${style.countryHeadingMobile} calendar-medium`}>
              {t('Country/Region')}
            </div>
          )}
          <div className={isIos ? style.iOScontinentWrapper : style.none}>
            {continentList.map((continent, index) => (
              <div
                className={`${isMobile ? style.continentSectionMobile : style.continentSection} ${
                  !isMobile && selectedIndex === index ? style.applyBorder : ''
                }`}
                key={continent}
                data-testid={`continent_div_${continent}`}
              >
                <button
                  className={`${isMobile ? style.continentMobile : style.continent} ${
                    continent === selectedContinent && !isMobile ? style.active : ''
                  }`}
                  onClick={() => updateSelectedContinent(continent)}
                  data-testid={continent}
                >
                  <div>
                    {isMobile && isIos ? (
                      <div
                        className={style.selectAll}
                        role="button"
                        tabIndex={0}
                        onClick={() => updateSelectedContinentIos(continent)}
                      >
                        {showSelectDeselect(continent) ? t('select-all') : t('deselect-all')}
                      </div>
                    ) : (
                      <CustomCheckbox
                        onClickHandler={updateSelection}
                        value={continent}
                        checked={selectedContinents?.includes(continent)}
                        isContinent={true}
                      />
                    )}
                  </div>
                  <div
                    className={
                      isMobile && isIos ? style.iosContinentName : style.nonIosContinentName
                    }
                  >
                    <label className={!isMobile ? style.desktopLabel : 'calendar-regular'}>
                      {t(continent)}
                    </label>
                    {isIos && isMobile && (
                      <span>
                        ({getSelectedCountryCount(continent)}/{continents[continent].length})
                      </span>
                    )}
                  </div>
                </button>
                {isMobile && (
                  <CountryList
                    continent={continent}
                    continents={continents}
                    importanceFilterPresent={importanceFilterPresent}
                  />
                )}
              </div>
            ))}
          </div>
          {!isMobile && (
            <button
              className={`${style.resetFilterButton} ${
                defaultFilterButtonDisabled ? style.noCursor : style.cursor
              }`}
              onClick={() => handleResetToDefault('')}
              disabled={defaultFilterButtonDisabled}
            >
              {t('restore-to-default')}
            </button>
          )}
        </div>
        {!isMobile && (
          <CountryList
            continent={selectedContinent}
            continents={continents}
            selectedCountryIndex={selectedCountryIndex}
            importanceFilterPresent={importanceFilterPresent}
          />
        )}
        {isMobile && showFilters && (
          <div className={style.resultsButtonWrapper}>
            <button
              className={`${style.resultsButton} calendar-medium`}
              onClick={updateShowFilters}
            >
              {t('Ok')}
            </button>
            <button
              className={`${style.resultsButton} ${
                defaultFilterButtonDisabled ? style.noCursor : style.cursor
              } calendar-medium`}
              onClick={() => handleResetToDefault('')}
              disabled={defaultFilterButtonDisabled}
            >
              {t('restore-to-default')}
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default CountryFilter;
