import { useEconCalendarParams } from '../../hooks/use-econ-calendar-params';

export default function MarketClose() {
  const { currentTheme } = useEconCalendarParams();
  const fillColor = currentTheme === 'dark' ? '#fff' : '#11203E';
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1260_12090)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.00185 1.73925C6.18209 1.94263 5.38706 2.30426 4.66171 2.83125C1.64486 5.02313 0.976081 9.24563 3.16795 12.2625C5.35982 15.2793 9.58233 15.9481 12.5992 13.7562C13.3245 13.2292 13.9141 12.5849 14.3609 11.8681C11.8041 12.3818 9.06488 11.4127 7.43165 9.16471C5.79841 6.91676 5.72316 4.01218 7.00185 1.73925Z"
          fill="#5A5E5F"
        />
      </g>
      <defs>
        <clipPath id="clip0_1260_12090">
          <rect width="16" height="16" fill={fillColor} transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}
