const AlertEnabledMobile = () => {
  const fillColor = '#fff';
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.34167 17.3C8.34167 18.2167 9.08333 18.9584 10 18.9584C10.9167 18.9584 11.6583 18.2167 11.6583 17.3H8.34167ZM10 4.79169C12.3 4.79169 14.1667 6.65835 14.1667 8.95835V14.7917H5.83333V8.95835C5.83333 6.65835 7.7 4.79169 10 4.79169ZM10 1.04169C9.30833 1.04169 8.75 1.60002 8.75 2.29169V3.26669C6.13333 3.83335 4.16667 6.16669 4.16667 8.95835V13.9584L2.5 15.625V16.4584H17.5V15.625L15.8333 13.9584V8.95835C15.8333 6.16669 13.8667 3.83335 11.25 3.26669V2.29169C11.25 1.60002 10.6917 1.04169 10 1.04169ZM10.8333 6.45835H9.16667V8.95835H6.66667V10.625H9.16667V13.125H10.8333V10.625H13.3333V8.95835H10.8333V6.45835Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default AlertEnabledMobile;
