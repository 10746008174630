import { conversionText } from '../dataConversion/ja';
import { icsToJSON } from '../utils/util';

export const getEconData = async (
  { activeTab, fromDate, toDate, locale = 'en' },
  // cst,
  authString,
  apiHost,
) => {
  return await fetch(`${apiHost}/${activeTab}?from=${fromDate}&to=${toDate}&lang=${locale}`, {
    headers: new Headers({
      Authorization: `Basic ${authString}`,
      // cst,
    }),
  });
};

export const getHistoricalData = async (
  ticker,
  // cst,
  authString,
  apiHost,
) => {
  return await fetch(`${apiHost}/historical-data/${ticker}`, {
    headers: new Headers({
      Authorization: `Basic ${authString}`,
      // cst,
    }),
    method: 'GET',
  });
};

export const getLatestUpdates = async (
  // cst,
  authString,
  apiHost,
) => {
  return await fetch(`${apiHost}/latest-updates?secondsAgo=3600&roundSeconds=10`, {
    headers: new Headers({
      Authorization: `Basic ${authString}`,
      // cst,
    }),
  });
};

export const downloadIcsFile = async (
  selectedChartSection: {},
  locale,
  // cst,
  authString,
  apiHost,
) => {
  try {
    const response = await fetch(`${apiHost}/generate-ics-file/${selectedChartSection['id']}.ics`, {
      headers: {
        Authorization: `Basic ${authString}`,
        // cst,
      },
      method: 'GET',
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const blob = await response.blob();
    const text = await blob.text();

    const updatedText = text?.replace(
      /ORGANIZER;CN=([^\n]+(\n\s[^\n]+)*)/g,
      'ORGANIZER;CN=ig.com:ig.com',
    );

    const updatedBlob = new Blob([updatedText], { type: 'text/calendar' });

    let filename = '';
    if (locale === 'ja') {
      const key = `${selectedChartSection['event'].toLowerCase()?.split(' ')?.join('_')}.title:${locale}:${
        selectedChartSection['country']
      }`;
      const defaultKey = `${selectedChartSection['event'].toLowerCase()?.split(' ')?.join('_')}.title:${locale}:DEFAULT`;
      const convertedText = conversionText[key] || conversionText[defaultKey];

      filename = `${convertedText || selectedChartSection['event']}.ics`;
    } else {
      filename = `${selectedChartSection['event']}.ics`;
    }

    const jsonData = icsToJSON(updatedText);

    return { updatedBlob, updatedText, filename, jsonData };
  } catch (error) {
    console.error('Error downloading file:', error);
    // Handle download error (e.g., display error message)
  }
};
