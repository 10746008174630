import { useEconCalendarParams } from '../../hooks/use-econ-calendar-params';

const CancelSearchIos = ({ width = '30', height = '31' }) => {
  const { currentTheme } = useEconCalendarParams();
  const fillColor = currentTheme === 'dark' ? '#fff' : '#000';
  return (
    // <svg
    //   width={width}
    //   height={height}
    //   viewBox="0 0 30 31"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M15 30.25C23.2843 30.25 30 23.5343 30 15.25C30 6.96573 23.2843 0.25 15 0.25C6.71573 0.25 0 6.96573 0 15.25C0 23.5343 6.71573 30.25 15 30.25Z"
    //     fill={fillColor}
    //   />
    //   <path
    //     d="M21.3786 21.5723C21.2241 21.7261 21.0145 21.8125 20.7959 21.8125C20.5772 21.8125 20.3676 21.7261 20.2131 21.5723L15.0248 16.4124L9.84137 21.5679C9.519 21.8851 8.99954 21.8837 8.67889 21.5648C8.35824 21.2459 8.35687 20.7292 8.67582 20.4087L13.8594 15.2533L8.67677 10.0989C8.36285 9.77756 8.36655 9.26506 8.68507 8.94822C9.00359 8.63138 9.5189 8.62761 9.84207 8.93976L15.0249 14.0941L20.2122 8.93526C20.4194 8.72364 20.7251 8.63931 21.0124 8.71454C21.2996 8.78977 21.524 9.01292 21.5995 9.29861C21.6751 9.58431 21.5903 9.88836 21.3774 10.0944L16.1905 15.2533L21.3788 20.4133C21.5333 20.567 21.6201 20.7755 21.6201 20.9928C21.6201 21.2102 21.5332 21.4187 21.3786 21.5723Z"
    //     fill={strokeColor}
    //     stroke={strokeColor}
    //     strokeWidth="0.9375"
    //   />
    // </svg>
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.70711 7.04289C7.31658 6.65237 6.68342 6.65237 6.29289 7.04289C5.90237 7.43342 5.90237 8.06658 6.29289 8.45711L10.5858 12.75L6.29289 17.0429C5.90237 17.4334 5.90237 18.0666 6.29289 18.4571C6.68342 18.8476 7.31658 18.8476 7.70711 18.4571L12 14.1642L16.2929 18.4571C16.6834 18.8476 17.3166 18.8476 17.7071 18.4571C18.0976 18.0666 18.0976 17.4334 17.7071 17.0429L13.4142 12.75L17.7071 8.45711C18.0976 8.06658 18.0976 7.43342 17.7071 7.04289C17.3166 6.65237 16.6834 6.65237 16.2929 7.04289L12 11.3358L7.70711 7.04289Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default CancelSearchIos;
