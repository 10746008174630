import { useMediaQuery } from 'react-responsive';
import { useEconCalendarParams } from './use-econ-calendar-params';

const useScreenDimensions = () => {
  const { isIos, isAndroid, isWebsite } = useEconCalendarParams();
  const isMobile = useMediaQuery({ query: '(max-width: 1368px)' });
  return isMobile && (isIos || isAndroid || isWebsite);
};

export default useScreenDimensions;
