import { useEconCalendarParams } from '../../hooks/use-econ-calendar-params';

const SomethingWentWrong = () => {
  const { currentTheme } = useEconCalendarParams();
  const fillColor = currentTheme === 'dark' ? '#8292a6' : '#5a5e5f';
  return (
    <svg width="73" height="72" viewBox="0 0 73 72" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M65.2393 63.5078H7.76058C7.09574 63.5074 6.44295 63.3303 5.86906 62.9947C5.29518 62.659 4.8208 62.1769 4.49452 61.5976C4.16824 61.0184 4.00178 60.3628 4.01218 59.698C4.02258 59.0333 4.20946 58.3832 4.5537 57.8144L33.2931 10.3009C33.6267 9.74894 34.0972 9.29242 34.659 8.97555C35.2208 8.65868 35.8549 8.49219 36.4999 8.49219C37.1449 8.49219 37.779 8.65868 38.3408 8.97555C38.9026 9.29242 39.373 9.74894 39.7067 10.3009L68.4462 57.8144C68.7904 58.3832 68.9773 59.0333 68.9877 59.698C68.9981 60.3628 68.8317 61.0184 68.5054 61.5976C68.1791 62.1769 67.7047 62.659 67.1308 62.9947C66.5569 63.3303 65.9042 63.5074 65.2393 63.5078ZM36.4998 58.5238C37.2415 58.5238 37.9665 58.3039 38.5832 57.8918C39.1999 57.4798 39.6805 56.8941 39.9644 56.2089C40.2482 55.5237 40.3225 54.7697 40.1778 54.0422C40.0331 53.3148 39.6759 52.6466 39.1515 52.1222C38.627 51.5977 37.9588 51.2406 37.2314 51.0959C36.504 50.9512 35.75 51.0254 35.0648 51.3093C34.3795 51.5931 33.7939 52.0737 33.3818 52.6904C32.9698 53.3071 32.7498 54.0321 32.7498 54.7738C32.7498 55.7684 33.1449 56.7222 33.8482 57.4255C34.5514 58.1287 35.5053 58.5238 36.4998 58.5238ZM38.998 23.5238H34.0243C33.8528 23.5218 33.6827 23.5548 33.5243 23.6207C33.366 23.6866 33.2227 23.7841 33.1033 23.9073C32.9839 24.0304 32.8908 24.1766 32.8298 24.3368C32.7687 24.4971 32.741 24.6682 32.7482 24.8396L33.8916 46.0896C33.9124 46.4128 34.0564 46.7157 34.2938 46.9359C34.5312 47.1562 34.844 47.2771 35.1678 47.2738H37.8545C38.1783 47.2771 38.4911 47.1561 38.7285 46.9359C38.9659 46.7156 39.1099 46.4127 39.1308 46.0896L40.2742 24.8396C40.2814 24.6682 40.2537 24.4971 40.1926 24.3369C40.1315 24.1766 40.0384 24.0304 39.919 23.9073C39.7996 23.7842 39.6563 23.6867 39.4979 23.6208C39.3396 23.5549 39.1695 23.5219 38.998 23.5239V23.5238Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default SomethingWentWrong;
