import { useEconCalendarParams } from '../../hooks/use-econ-calendar-params';

const ExpandFiltersIcon = () => {
  const { currentTheme } = useEconCalendarParams();
  const fillColor = currentTheme === 'dark' || currentTheme === 'navy' ? '#fff' : '#11203e';

  return (
    <svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.12785 5.09281C4.32654 5.31425 4.67346 5.31425 4.87215 5.09281L8.25175 1.32625C8.54061 1.00432 8.31213 0.492331 7.8796 0.492331H1.1204C0.687873 0.492331 0.459389 1.00432 0.748247 1.32625L4.12785 5.09281Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default ExpandFiltersIcon;
