import style from '../styles/Error/DesktopErrorScreen.module.css';

const DesktopErrorScreen = ({ t, errorMessage }) => {
  return errorMessage !== null ? (
    <p className={style.errorMessage}>{t(errorMessage)}</p>
  ) : (
    <p className={style.noDataFound}>
      {t('No events to display. Change the date range or broaden your filters.')}
    </p>
  );
};

export default DesktopErrorScreen;
