import { useEconCalendarParams } from '../../hooks/use-econ-calendar-params';

export default function BackArrow({ width = '12', height = '21' }) {
  const { currentTheme } = useEconCalendarParams();
  const fillColor = currentTheme === 'dark' ? '#fff' : '#E61E1E';
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5424 3.17421C12.1301 2.62473 12.1526 1.71173 11.5927 1.13496C11.0328 0.558202 10.1025 0.536088 9.51477 1.08557C9.51477 1.08557 0.44563 9.69643 0.420893 9.72144C-0.158688 10.3165 -0.137011 11.2599 0.46931 11.8287L9.51468 20.4128C10.1014 20.9632 11.0318 20.9426 11.5927 20.3668C12.1536 19.7909 12.1326 18.8779 11.5458 18.3275L4.07026 11.1105C3.86666 10.914 3.86662 10.5878 4.07018 10.3911L11.5424 3.17421Z"
        fill={fillColor}
      />
    </svg>
  );
}
