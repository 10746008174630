import { useEconCalendarParams } from '../../hooks/use-econ-calendar-params';

export default function ArrowExpandIcon() {
  const { currentTheme } = useEconCalendarParams();
  const fillColor = currentTheme === 'dark' ? '#fff' : '#11203E';
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4398 13.5719C10.3818 13.63 10.3129 13.6761 10.2371 13.7075C10.1612 13.7389 10.0799 13.7551 9.99781 13.7551C9.91571 13.7551 9.83441 13.7389 9.75856 13.7075C9.68271 13.6761 9.61381 13.63 9.55578 13.5719L4.86688 8.88056C4.80754 8.8228 4.76026 8.75382 4.72779 8.67764C4.69533 8.60146 4.67832 8.51959 4.67775 8.43677C4.67718 8.35396 4.69307 8.27187 4.72449 8.19525C4.75591 8.11863 4.80224 8.04901 4.86079 7.99044C4.91933 7.93188 4.98893 7.88552 5.06554 7.85407C5.14215 7.82262 5.22424 7.8067 5.30705 7.80723C5.38986 7.80776 5.47174 7.82474 5.54793 7.85718C5.62413 7.88962 5.69312 7.93687 5.75091 7.99619L9.99778 12.2452L14.2447 7.99619C14.3024 7.93676 14.3714 7.88941 14.4476 7.85689C14.5239 7.82436 14.6058 7.80731 14.6887 7.80673C14.7715 7.80615 14.8537 7.82205 14.9304 7.8535C15.007 7.88495 15.0767 7.93133 15.1353 7.98994C15.1939 8.04855 15.2402 8.11822 15.2717 8.1949C15.3031 8.27158 15.319 8.35375 15.3183 8.43662C15.3177 8.51949 15.3007 8.60141 15.2681 8.67762C15.2356 8.75383 15.1882 8.82282 15.1288 8.88056L10.4398 13.5719Z"
        fill={fillColor}
      />
    </svg>
  );
}
