import { getCountryName, importanceMapping, searchCountryCode } from '../../utils/util';
import { FlagIcon } from 'react-flag-kit';
import EarningPerShare from './EarningPerShare';
import Revenue from './Revenue';
import Badge from '../Global/Badge';
import useScreenDimensions from '../../hooks/useScreenDimension';
import MarketOpen from '../../assets/svgs/MarketOpen';
import MarketClose from '../../assets/svgs/MarketClose';
import { FlagIconCode } from '../../types/flagIconCode';
import WorldFlag from '../../assets/svgs/WorldFlag';
import { supportedCountryCodes } from '../../utils/supportedFlagIconList';
import PlaceholderFlag from '../../assets/svgs/PlaceholderFlag';
import { usePopperTooltip } from 'react-popper-tooltip';
import { useTranslation } from 'react-i18next';
import { useEconCalendar } from '../../hooks/useEconCalendar';

const earningsTime = {
  by_day_end: 'Day End',
  before_open: 'Before Open',
  after_close: 'After Close',
};

const EarningsReport = ({ tabKey, singleDataEntry, marketTrend, isWorld }) => {
  const { t } = useTranslation();
  const isMobile = useScreenDimensions();
  const countryName = getCountryName(singleDataEntry, 'country');
  const flagCode = searchCountryCode(countryName) as FlagIconCode;
  const { calendarWidth } = useEconCalendar();

  const {
    getTooltipProps: getTooltipProps1,
    setTooltipRef: setTooltipRef1,
    setTriggerRef: setTriggerRef1,
    getArrowProps: getArrowProps1,
    visible: visible1,
  } = usePopperTooltip({ trigger: 'hover', placement: 'top' });

  const {
    getTooltipProps: getTooltipProps2,
    setTooltipRef: setTooltipRef2,
    setTriggerRef: setTriggerRef2,
    getArrowProps: getArrowProps2,
    visible: visible2,
  } = usePopperTooltip({ trigger: 'hover', placement: 'top' });

  const {
    getTooltipProps: getTooltipProps3,
    setTooltipRef: setTooltipRef3,
    setTriggerRef: setTriggerRef3,
    getArrowProps: getArrowProps3,
    visible: visible3,
  } = usePopperTooltip({ trigger: 'hover', placement: 'top' });

  return (
    <>
      {tabKey === 'importance' && singleDataEntry.importance ? (
        <Badge text={importanceMapping[singleDataEntry.importance]} />
      ) : tabKey === 'country' ? (
        <div className="countryDetails">
          <>
            <div className="flag" ref={setTriggerRef1}>
              {isWorld ? (
                <WorldFlag />
              ) : supportedCountryCodes?.includes(flagCode) ? (
                <FlagIcon code={flagCode} loading="lazy" />
              ) : (
                <PlaceholderFlag />
              )}
            </div>
            {!isMobile && visible1 && (
              <div
                ref={setTooltipRef1}
                {...getTooltipProps1({ className: 'tooltip-container tooltip' })}
              >
                {t(countryName)}
                <div {...getArrowProps1({ className: 'tooltip-arrow' })} />
              </div>
            )}
          </>
          {!isMobile && calendarWidth > 900 && (
            <>
              <div className="countryName" ref={setTriggerRef2}>
                {t(countryName)}
              </div>
              {!isMobile && visible2 && (
                <div
                  ref={setTooltipRef2}
                  {...getTooltipProps2({ className: 'tooltip-container tooltip' })}
                >
                  {t(countryName)}
                  <div {...getArrowProps2({ className: 'tooltip-arrow' })} />
                </div>
              )}
            </>
          )}
        </div>
      ) : tabKey === 'time' ? (
        <div>
          {!isMobile ? (
            t(earningsTime[singleDataEntry.marketRelease])
          ) : singleDataEntry.marketRelease === 'before_open' ? (
            <MarketOpen />
          ) : (
            <MarketClose />
          )}
        </div>
      ) : tabKey === 'company' ? (
        <div className="companySection">
          <>
            <div
              className={`${isMobile ? 'calendar-medium' : 'none'} name`}
              ref={setTriggerRef3}
              data-testid={singleDataEntry?.name}
            >
              {singleDataEntry.name}
            </div>
            {!isMobile && visible3 && (
              <div
                ref={setTooltipRef3}
                {...getTooltipProps3({
                  className: `tooltip-container tooltip tooltip-wrap`,
                })}
              >
                {singleDataEntry.name}
                <div {...getArrowProps3({ className: 'tooltip-arrow' })} />
              </div>
            )}
          </>
          <div className={`${isMobile ? 'calendar-regular' : 'none'} symbol`}>
            {singleDataEntry?.symbol?.split(':')[0]}
          </div>
        </div>
      ) : tabKey === 'earning per share' ? (
        <EarningPerShare marketTrend={marketTrend} singleDataEntry={singleDataEntry} />
      ) : (
        <Revenue marketTrend={marketTrend} singleDataEntry={singleDataEntry} />
      )}
    </>
  );
};

export default EarningsReport;
