import { useEconCalendar } from '../../hooks/useEconCalendar';
import useScreenDimensions from '../../hooks/useScreenDimension';
import style from '../../styles/Badge.module.css';
import { usePopperTooltip } from 'react-popper-tooltip';
import { useTranslation } from 'react-i18next';

export default function Badge({ text, holiday = false }) {
  const { t } = useTranslation();
  const isMobile = useScreenDimensions();
  const badgeStyle =
    text.toLowerCase() === 'high'
      ? style.highImportance
      : text.toLowerCase() === 'medium'
        ? isMobile
          ? style.mediumImportanceMobile
          : style.mediumImportance
        : isMobile
          ? style.lowImportanceMobile
          : style.lowImportance;
  const { calendarWidth } = useEconCalendar();

  const { getTooltipProps, setTooltipRef, setTriggerRef, getArrowProps, visible } =
    usePopperTooltip({ trigger: 'hover', placement: 'top' });
  const valueToShow = t(isMobile ? text[0] : calendarWidth > 821 ? text : text[0]);
  return (
    <div
      className={`${
        isMobile ? style.badgeWrapperMobile : style.badgeWrapper
      } ${badgeStyle} ${holiday ? style.hide : style.show}`}
      ref={!isMobile ? setTriggerRef : null}
    >
      <div
        className={`${isMobile ? style.mobileFont : style.font} ${isMobile && 'calendar-medium'}`}
      >
        {valueToShow}
      </div>
      {!isMobile && visible && (
        <div ref={setTooltipRef} {...getTooltipProps({ className: `tooltip-container tooltip` })}>
          {t(text)}
          <div {...getArrowProps({ className: 'tooltip-arrow' })} />
        </div>
      )}
    </div>
  );
}
