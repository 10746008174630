import { useEconCalendarParams } from '../../hooks/use-econ-calendar-params';

const BackArrow = () => {
  const { currentTheme } = useEconCalendarParams();
  const fillColor = currentTheme === 'dark' ? '#ffffff' : '#11203e';
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 23H19.8L25.4 17.4L24 16L16 24L24 32L25.4 30.6L19.8 25H32V23Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default BackArrow;
