import { useEconCalendarParams } from '../../hooks/use-econ-calendar-params';

const SearchIconIos = () => {
  const { currentTheme } = useEconCalendarParams();
  const fillColor = currentTheme === 'dark' ? '#8292A6' : '#6A7787';
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 6.4292C0 7.31422 0.167394 8.1448 0.502183 8.92094C0.836952 9.69708 1.29916 10.3805 1.88879 10.9713C2.47842 11.5621 3.16014 12.0232 3.93396 12.3545C4.70778 12.6858 5.53952 12.8514 6.42918 12.8514C7.14046 12.8514 7.81813 12.7414 8.4622 12.5213C9.10629 12.3012 9.6936 11.9931 10.2242 11.5969L14.3631 15.7498C14.4512 15.8332 14.5491 15.8957 14.6568 15.9374C14.7645 15.9791 14.8752 16 14.9887 16C15.2459 16 15.4532 15.916 15.6108 15.748C15.7683 15.5801 15.8471 15.3721 15.8471 15.1242C15.8471 15.0061 15.8262 14.8949 15.7845 14.7906C15.7428 14.6864 15.6838 14.5937 15.6073 14.5126L11.4822 10.3771C11.9108 9.83493 12.2462 9.22677 12.4883 8.55257C12.7304 7.87837 12.8514 7.17057 12.8514 6.4292C12.8514 5.54185 12.6846 4.71069 12.351 3.9357C12.0174 3.16073 11.5569 2.47785 10.9696 1.88706C10.3823 1.29626 9.69939 0.834061 8.92094 0.500446C8.14248 0.166816 7.3119 0 6.42918 0C5.53952 0 4.70778 0.166816 3.93396 0.500446C3.16014 0.834061 2.47842 1.29626 1.88879 1.88706C1.29916 2.47785 0.836952 3.16073 0.502183 3.9357C0.167394 4.71069 0 5.54185 0 6.4292ZM1.22676 6.4292C1.22676 5.71098 1.36056 5.03794 1.62815 4.41008C1.89575 3.78222 2.26876 3.23024 2.74718 2.75413C3.22561 2.27803 3.77932 1.90444 4.40834 1.63338C5.03736 1.36231 5.71098 1.22677 6.42918 1.22677C7.1474 1.22677 7.81928 1.36231 8.44482 1.63338C9.07037 1.90444 9.62236 2.27803 10.1008 2.75413C10.5792 3.23024 10.954 3.78222 11.225 4.41008C11.4961 5.03794 11.6316 5.71098 11.6316 6.4292C11.6316 7.14741 11.4961 7.81987 11.225 8.44656C10.954 9.07327 10.5792 9.62525 10.1008 10.1025C9.62236 10.5798 9.07037 10.9539 8.44482 11.225C7.81928 11.4961 7.1474 11.6316 6.42918 11.6316C5.71098 11.6316 5.03736 11.4961 4.40834 11.225C3.77932 10.9539 3.22561 10.5798 2.74718 10.1025C2.26876 9.62525 1.89575 9.07327 1.62815 8.44656C1.36056 7.81987 1.22676 7.14741 1.22676 6.4292Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default SearchIconIos;
