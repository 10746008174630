import style from '../../styles/filters/CountryList.module.css';
import { FlagIcon } from 'react-flag-kit';
import { countryCodeToNameMap } from '../../utils/countryCodeNameMapping';
import { useEconCalendar } from '../../hooks/useEconCalendar';
import { useEffect, useRef, useState } from 'react';
import useScreenDimensions from '../../hooks/useScreenDimension';
import { useTranslation } from 'react-i18next';
import CustomCheckbox from './CustomCheckbox';
import { useEconCalendarParams } from '../../hooks/use-econ-calendar-params';

const CountryList = ({
  continent,
  continents,
  selectedCountryIndex = null,
  importanceFilterPresent,
}) => {
  const { allCountry, selectCountry, updateResultData } = useEconCalendar();
  const firstRender = useRef(true);
  const isMobile = useScreenDimensions();
  const { isIos } = useEconCalendarParams();
  const { t } = useTranslation();

  const [showMore, setShowMore] = useState(false);
  const visibleCountries = !(isMobile && isIos)
    ? continents[continent]
    : showMore
      ? continents[continent]
      : continents[continent].slice(0, 5);
  const remainingCount = continents[continent].length - visibleCountries.length;

  useEffect(() => {
    if (!firstRender.current) {
      updateResultData();
    }
  }, [allCountry]);

  useEffect(() => {
    firstRender.current = false;
  }, []);

  return (
    <div
      className={`${isMobile ? style.countryListWrapperMobile : style.countryListWrapper} ${
        !isMobile
          ? importanceFilterPresent
            ? style.importanceMargin
            : style.noMargin
          : style.notMobile
      }`}
    >
      <div
        className={`${
          isMobile
            ? isIos
              ? style.countryListMobileIos
              : style.countryListMobile
            : style.countryList
        }`}
      >
        {visibleCountries.map((country, index) => {
          return (
            <div
              className={`${isMobile ? style.countryMobile : style.country} ${
                selectedCountryIndex === index ? style.applyBorder : style.none
              } ${isMobile ? (isIos ? style.iosCountryMobile : style.nonIosMobile) : style.none}`}
              key={country}
              data-testid={`country_div_${country}`}
            >
              <input
                type="checkbox"
                id={country}
                checked={allCountry?.includes(country)}
                onChange={() => selectCountry(country)}
                className={style.checkbox_input}
              />
              <CustomCheckbox
                onClickHandler={selectCountry}
                value={country}
                checked={allCountry?.includes(country)}
              />
              <label
                className={isMobile ? style.countryFlagMobile : style.countryFlag}
                htmlFor={country}
                data-testid={country}
              >
                <span>
                  <FlagIcon code={country} loading="lazy" />
                </span>
                <span className={isMobile ? 'calendar-regular' : 'none'}>
                  {t(countryCodeToNameMap[country] || country)}
                </span>
              </label>
            </div>
          );
        })}
      </div>
      {isMobile && isIos && remainingCount > 0 && (
        <div className={style.showMoreWrapper}>
          <button onClick={() => setShowMore(true)} className={style.showMoreButton}>
            {`${t(`view-more`)} (${remainingCount})`}
          </button>
        </div>
      )}
    </div>
  );
};

export default CountryList;
