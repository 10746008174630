import { downloadIcsFile } from '../api/EconCalendarApi';
import { useEconCalendarParams } from '../hooks/use-econ-calendar-params';
import { useEconCalendar } from '../hooks/useEconCalendar';
import style from '../styles/Detail.module.css';
import {
  convertDateToString,
  getCountryName,
  getFormattedTime,
  importanceMapping,
  searchCountryCode,
  shouldShowAddToCalendar,
} from '../utils/util';
import Badge from './Global/Badge';
import HistoricalChart from './HistoricalChart';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useTranslation } from 'react-i18next';
import { FlagIcon } from 'react-flag-kit';
import { FlagIconCode } from '../types/flagIconCode';
import WorldFlag from '../assets/svgs/WorldFlag';
import { supportedCountryCodes } from '../utils/supportedFlagIconList';
import PlaceholderFlag from '../assets/svgs/PlaceholderFlag';
import { useSessionData } from '../hooks/use-session-data';
import CalendarIconMobile from '../assets/svgs/CalendarIconMobile';
import AlertEnabledMobile from '../assets/svgs/AlertEnabledMobile';
import { getConversionText, getFormattedDescription } from '../utils/tabsDataAccess-utils';
import { useEffect } from 'react';

const MobileDetailsPage = () => {
  const { t } = useTranslation();
  const {
    eventDetailValue,
    chartData,
    isChartLoading,
    selectedChartSection,
    fetchHistoricalData,
    authString,
  } = useEconCalendar();
  const countryName = getCountryName(eventDetailValue, 'country');
  const flagCode = searchCountryCode(countryName) as FlagIconCode;
  const isWorld = eventDetailValue['country']?.toUpperCase() === 'WORLD';
  const {
    locale,
    baseColorSkeleton,
    highlightColorSkeleton,
    isIos,
    isAndroid,
    handleAddToCalendar,
  } = useEconCalendarParams();
  const { calendarApiUrl, timezoneOffset } = useSessionData();

  useEffect(() => {
    if (eventDetailValue) {
      // @ts-ignore
      fetchHistoricalData(eventDetailValue);
    }
  }, [eventDetailValue]);

  const addToCal = async () => {
    const resp = await downloadIcsFile(
      selectedChartSection,
      locale,
      // cst,
      authString,
      calendarApiUrl,
    );
    const { updatedBlob, filename } = resp;

    if (isIos || isAndroid) {
      //@ts-ignore
      handleAddToCalendar?.postMessage(
        JSON.stringify({ type: 'app/click', identifier: 'ADD_TO_CALENDAR_CLICK', data: resp }),
      );
    } else {
      const link = document.createElement('a');
      // check for browser compatibility, older browsers (chrome/safari) might support window.navigator.webkitURL.createObjectURL()
      link.href = window.URL.createObjectURL(updatedBlob); // Use window.URL for broader compatibility
      link.download = filename;
      link.click();

      // Revoke object URL immediately to avoid memory leaks
      window.URL.revokeObjectURL(link.href);
    }
  };

  const text = importanceMapping[eventDetailValue['importance']];
  const badgeStyle =
    text.toLowerCase() === 'high'
      ? style.highImportance
      : text.toLowerCase() === 'medium'
        ? style.mediumImportanceMobile
        : style.lowImportanceMobile;

  return (
    <div className={style.detailWrapper}>
      <div className={`${style.firstRow} ${badgeStyle} ${style.badgeWrapperMobile}`}>
        <div className={`${style.detailsFont}`}>
          {importanceMapping[eventDetailValue['importance']]}
        </div>
      </div>
      <div className={`${style.secondRow} calendar-medium`}>
        <div>
          {' '}
          {getConversionText(eventDetailValue['event'], locale, 'event', selectedChartSection)}{' '}
        </div>
        <span className={`${style.timeInfo} calendar-regular`}>
          {`${getFormattedTime(eventDetailValue['date'], timezoneOffset)}
          ${convertDateToString(
            eventDetailValue['date'],
            timezoneOffset,
            true,
            locale != 'en' ? locale : undefined,
            t,
          )}`}
        </span>
      </div>
      <div className={style.thirdRow}>
        {isWorld ? (
          <WorldFlag />
        ) : supportedCountryCodes?.includes(flagCode) ? (
          <FlagIcon code={flagCode} loading="lazy" />
        ) : (
          <PlaceholderFlag />
        )}
        <span className={`${style.countryName} calendar-regular`}>{t(countryName)}</span>
      </div>
      <div className={style.fourthRow}>
        <div>
          <div className={`${style.actual} calendar-regular`}>
            <div> {t('Actual')} </div>
            <div> {eventDetailValue['actual'] || '-'} </div>
          </div>
          <div className={`${style.previous} calendar-regular`}>
            <div> {t('Previous')} </div>
            <div> {eventDetailValue['previous'] || '-'} </div>
          </div>
        </div>
      </div>
      <div>
        <div className={`${style.forecast} calendar-regular`}>
          <div> {t('Forecast')} </div>
          <div> {eventDetailValue['forecast'] || '-'} </div>
        </div>
      </div>
      {shouldShowAddToCalendar(eventDetailValue['date']) && (
        <div className={`${style.buttons} calendar-medium`}>
          <button>
            <AlertEnabledMobile /> {t('CANCEL ALERT')}
          </button>
          <button className={style.addToCalendar} onClick={addToCal}>
            <CalendarIconMobile />
            {t('Add to calendar')}
          </button>
        </div>
      )}

      <div className={style.historicalData}>
        {chartData && chartData.length > 0 && (
          <div className="calendar-semibold"> {t('Historical data')} </div>
        )}
        <div className={style.chartDescription}>
          {isChartLoading ? (
            <SkeletonTheme baseColor={baseColorSkeleton} highlightColor={highlightColorSkeleton}>
              <div>
                <Skeleton width={document.body.offsetWidth - 24} height={207} />
              </div>
            </SkeletonTheme>
          ) : (
            chartData &&
            chartData.length > 0 && (
              <HistoricalChart
                data={chartData}
                width={document.body.offsetWidth - 24}
                height={207}
              />
            )
          )}
          {selectedChartSection?.description && (
            <div className={style.description}>
              <div className="calendar-semibold"> {t('Summary')} </div>
              <p className="calendar-regular">
                {' '}
                {getFormattedDescription(selectedChartSection, locale)}{' '}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MobileDetailsPage;
