// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mrdNLtZIbShLBCHHwB0F {
  height: 38px;
  margin: 16px auto;
  z-index: 35;
  width: calc(100% - 32px);
}

.V9YmATeYobBnmApWwlVT {
  z-index: 35;
  position: absolute;
  right: 0;
}

.u0nDm9ZUtrZmv650LsNW {
  width: 48px;
  height: 48px;
  margin: 4px auto;
  z-index: 35;
  position: absolute;
  right: 0;
}

.H8x2ci8M_e5aHbHbewCi {
  margin: 0 12px;
  display: flex;
  gap: 16px;
  background-color: var(--container-bg-color);
}

.to9LCK4WYxly__Yvixn7 {
  position: sticky;
  z-index: 45;
  background-color: var(--container-bg-color);
  top: 0;
}

.hunWM17lS8sALwEJ8R3F {
  /* min-height: 168px !important; */
  position: sticky;
  z-index: 45;
  background-color: var(--container-bg-color);
  top: 0;
}

.KJZATy437bQYovRqmPw1 {
  display: flex;
  margin: 4px 0;
  /* margin: 0; */
}

.KJZATy437bQYovRqmPw1 > button {
  all: unset;
}

.KJZATy437bQYovRqmPw1 > button > svg {
  z-index: 10;
}
`, "",{"version":3,"sources":["webpack://./src/styles/EconCalendarFilters.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,iBAAiB;EACjB,WAAW;EACX,wBAAwB;AAC1B;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,QAAQ;AACV;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB,WAAW;EACX,kBAAkB;EAClB,QAAQ;AACV;;AAEA;EACE,cAAc;EACd,aAAa;EACb,SAAS;EACT,2CAA2C;AAC7C;;AAEA;EACE,gBAAgB;EAChB,WAAW;EACX,2CAA2C;EAC3C,MAAM;AACR;;AAEA;EACE,kCAAkC;EAClC,gBAAgB;EAChB,WAAW;EACX,2CAA2C;EAC3C,MAAM;AACR;;AAEA;EACE,aAAa;EACb,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,WAAW;AACb","sourcesContent":[".filterWrapper {\n  height: 38px;\n  margin: 16px auto;\n  z-index: 35;\n  width: calc(100% - 32px);\n}\n\n.filterWrapperMobile {\n  z-index: 35;\n  position: absolute;\n  right: 0;\n}\n\n.filterWrapperMobileSearch {\n  width: 48px;\n  height: 48px;\n  margin: 4px auto;\n  z-index: 35;\n  position: absolute;\n  right: 0;\n}\n\n.filter {\n  margin: 0 12px;\n  display: flex;\n  gap: 16px;\n  background-color: var(--container-bg-color);\n}\n\n.importanceExpanded {\n  position: sticky;\n  z-index: 45;\n  background-color: var(--container-bg-color);\n  top: 0;\n}\n\n.countryExpanded {\n  /* min-height: 168px !important; */\n  position: sticky;\n  z-index: 45;\n  background-color: var(--container-bg-color);\n  top: 0;\n}\n\n.mobileFilterView {\n  display: flex;\n  margin: 4px 0;\n  /* margin: 0; */\n}\n\n.mobileFilterView > button {\n  all: unset;\n}\n\n.mobileFilterView > button > svg {\n  z-index: 10;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filterWrapper": `mrdNLtZIbShLBCHHwB0F`,
	"filterWrapperMobile": `V9YmATeYobBnmApWwlVT`,
	"filterWrapperMobileSearch": `u0nDm9ZUtrZmv650LsNW`,
	"filter": `H8x2ci8M_e5aHbHbewCi`,
	"importanceExpanded": `to9LCK4WYxly__Yvixn7`,
	"countryExpanded": `hunWM17lS8sALwEJ8R3F`,
	"mobileFilterView": `KJZATy437bQYovRqmPw1`
};
export default ___CSS_LOADER_EXPORT___;
