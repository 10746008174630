import ImportanceFilter from '../Filters/ImportanceFilter';
import CountryFilter from '../Filters/CountryFilter';
import style from '../../styles/filters/FilterComponents.module.css';
import { useEconCalendar } from '../../hooks/useEconCalendar';

const FilterComponents: React.FC = () => {
  const { activeTab } = useEconCalendar();
  const toShowImportanceFilterSection = ['events', 'earnings-reports'].includes(activeTab);

  return (
    <div className={style.filters}>
      {toShowImportanceFilterSection && <ImportanceFilter />}
      <CountryFilter />
    </div>
  );
};

export default FilterComponents;
