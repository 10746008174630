import { useEconCalendarParams } from '../../hooks/use-econ-calendar-params';

const SearchIcon = () => {
  const { currentTheme } = useEconCalendarParams();
  const fillColor = currentTheme === 'dark' || currentTheme === 'navy' ? '#fff' : '#11203e';
  return (
    <svg width="15" height="15" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7616 3.91504C10.7067 1.81857 8.55887 0.496997 6.21193 0.500235C3.51454 0.499067 1.12498 2.23965 0.298821 4.80741C-0.527338 7.37517 0.398932 10.1826 2.59093 11.7546C4.78293 13.3265 7.73913 13.3033 9.90621 11.6972L12.7432 15.05C13.0562 15.4167 13.5485 15.5768 14.0173 15.4643C14.4861 15.3519 14.8521 14.9858 14.9646 14.517C15.0771 14.0482 14.917 13.5559 14.5503 13.243L11.1969 10.4067C12.598 8.52385 12.8166 6.01151 11.7616 3.91504ZM11.1159 6.71176C11.1159 9.42012 8.92037 11.6157 6.21202 11.6157C3.50367 11.6157 1.30812 9.4201 1.30811 6.71176C1.30811 4.0034 3.50366 1.80785 6.21202 1.80785C8.92037 1.80785 11.1159 4.0034 11.1159 6.71176Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default SearchIcon;
