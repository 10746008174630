import EarningsUpArrow from '../../assets/svgs/EarningsUpArrow';
import EarningsDownArrow from '../../assets/svgs/EarningsDownArrow';
import useScreenDimensions from '../../hooks/useScreenDimension';
import { formatToTwoDecimalPlaces, getValueWithCurrency } from '../../utils/util';
import { useTranslation } from 'react-i18next';
import { useEconCalendarParams } from '../../hooks/use-econ-calendar-params';

const Revenue = ({ marketTrend, singleDataEntry }) => {
  const isMobile = useScreenDimensions();
  const { t } = useTranslation();
  const { locale } = useEconCalendarParams();
  return (
    <div className="revenueWrapper">
      {isMobile && (
        <div className={`${isMobile ? 'calendar-regular' : 'none'} revenueHeading`}>
          {t('Revenue')}:
        </div>
      )}
      <div className="actual">
        {!isMobile && <div className="label">{t('Actual')}</div>}
        <div
          className={
            singleDataEntry.revenue
              ? marketTrend[1] > 0
                ? 'actualPositive'
                : 'actualNegative'
              : 'noMarketTrend'
          }
        >
          <span className={`${isMobile ? 'calendar-regular' : 'none'} actualValue`}>
            {singleDataEntry?.revenue
              ? getValueWithCurrency(
                  formatToTwoDecimalPlaces(singleDataEntry['revenue']),
                  locale,
                  singleDataEntry['currency'],
                  false,
                )
              : '-'}
          </span>
          {singleDataEntry.revenue &&
            (marketTrend[1] > 0 ? <EarningsUpArrow /> : <EarningsDownArrow />)}
        </div>
      </div>
      <div className={`${isMobile ? 'calendar-regular' : 'none'} forecast`}>
        {!isMobile && <span>{t('Forecast')}</span>}
        <span>
          {singleDataEntry?.revenueForecast
            ? getValueWithCurrency(
                formatToTwoDecimalPlaces(singleDataEntry['revenueForecast']),
                locale,
                singleDataEntry['currency'],
                false,
              )
            : '-'}
        </span>
      </div>
      <div className={`${isMobile ? 'calendar-regular' : 'none'} previous`}>
        {!isMobile && <span>{t('Previous')}</span>}
        <span>
          {singleDataEntry?.revenuePrevious
            ? getValueWithCurrency(
                formatToTwoDecimalPlaces(singleDataEntry['revenuePrevious']),
                locale,
                singleDataEntry['currency'],
                false,
              )
            : '-'}
        </span>
      </div>
    </div>
  );
};

export default Revenue;
