// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aCirLeCqiSjVTyMCphSR {
  border-radius: 4px;
  padding: 3px 6px;
  text-transform: capitalize;
}

.ZGou67QtHYvcBuVgkSSY {
  text-transform: capitalize;
}

.GgDnRnnNWhqqLoVTGxr4 {
  /* font-family: 'MatterIGTF-Medium', sans-serif; */
  font-weight: 500;
  font-size: 13px;
  line-height: 13px;
  text-align: center;
}

.n8BCEFOdc8sgdLBjrjAX {
  height: 16px;
  width: 20px;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  font-weight: 500;
  text-transform: uppercase;
}

.bT5tvVwSBcsjl10NtCqC {
  width: 68px;
  text-align: center !important;
  padding: 2.5px 0px 1.5px 0px;
}

.JLo1SS7WKvnbirtWqYUx {
  background-color: var(--high-importance-bg-color);
  color: var(--high-importance-text-color);
}

.p5CRUME5yMtmbxwSfbPn {
  background-color: var(--medium-importance-bg-color);
  color: var(--medium-importance-text-color);
}

.oOQTlOzP84EGBuJun12f {
  background-color: var(--mobile-medium-importance-bg-color);
  color: var(--mobile-medium-importance-text-color);
}

.Yix1hGyLxHMA1b_Q75ip {
  background-color: var(--mobile-low-importance-background-color);
  color: var(--mobile-low-importance-text-color);
}

.MnoiIXOUP4CSIDugLOlh {
  background-color: var(--low-importance-bg-color);
  color: var(--low-importance-text-color);
}

.LsHCwpKp7QLEMpV4tyde {
  visibility: hidden;
}
`, "",{"version":3,"sources":["webpack://./src/styles/Badge.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,0BAA0B;AAC5B;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,kDAAkD;EAClD,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,eAAe;EACf,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB;EAChB,yBAAyB;AAC3B;;AAEA;EACE,WAAW;EACX,6BAA6B;EAC7B,4BAA4B;AAC9B;;AAEA;EACE,iDAAiD;EACjD,wCAAwC;AAC1C;;AAEA;EACE,mDAAmD;EACnD,0CAA0C;AAC5C;;AAEA;EACE,0DAA0D;EAC1D,iDAAiD;AACnD;;AAEA;EACE,+DAA+D;EAC/D,8CAA8C;AAChD;;AAEA;EACE,gDAAgD;EAChD,uCAAuC;AACzC;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".badgeWrapper {\n  border-radius: 4px;\n  padding: 3px 6px;\n  text-transform: capitalize;\n}\n\n.badgeWrapperMobile {\n  text-transform: capitalize;\n}\n\n.font {\n  /* font-family: 'MatterIGTF-Medium', sans-serif; */\n  font-weight: 500;\n  font-size: 13px;\n  line-height: 13px;\n  text-align: center;\n}\n\n.mobileFont {\n  height: 16px;\n  width: 20px;\n  font-size: 12px;\n  line-height: 16px;\n  text-align: center;\n  font-weight: 500;\n  text-transform: uppercase;\n}\n\n.detailsFont {\n  width: 68px;\n  text-align: center !important;\n  padding: 2.5px 0px 1.5px 0px;\n}\n\n.highImportance {\n  background-color: var(--high-importance-bg-color);\n  color: var(--high-importance-text-color);\n}\n\n.mediumImportance {\n  background-color: var(--medium-importance-bg-color);\n  color: var(--medium-importance-text-color);\n}\n\n.mediumImportanceMobile {\n  background-color: var(--mobile-medium-importance-bg-color);\n  color: var(--mobile-medium-importance-text-color);\n}\n\n.lowImportanceMobile {\n  background-color: var(--mobile-low-importance-background-color);\n  color: var(--mobile-low-importance-text-color);\n}\n\n.lowImportance {\n  background-color: var(--low-importance-bg-color);\n  color: var(--low-importance-text-color);\n}\n\n.hide {\n  visibility: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"badgeWrapper": `aCirLeCqiSjVTyMCphSR`,
	"badgeWrapperMobile": `ZGou67QtHYvcBuVgkSSY`,
	"font": `GgDnRnnNWhqqLoVTGxr4`,
	"mobileFont": `n8BCEFOdc8sgdLBjrjAX`,
	"detailsFont": `bT5tvVwSBcsjl10NtCqC`,
	"highImportance": `JLo1SS7WKvnbirtWqYUx`,
	"mediumImportance": `p5CRUME5yMtmbxwSfbPn`,
	"mediumImportanceMobile": `oOQTlOzP84EGBuJun12f`,
	"lowImportanceMobile": `Yix1hGyLxHMA1b_Q75ip`,
	"lowImportance": `MnoiIXOUP4CSIDugLOlh`,
	"hide": `LsHCwpKp7QLEMpV4tyde`
};
export default ___CSS_LOADER_EXPORT___;
