import { useEconCalendarParams } from '../../hooks/use-econ-calendar-params';
import { useEconCalendar } from '../../hooks/useEconCalendar';
import useScreenDimensions from '../../hooks/useScreenDimension';
import style from '../../styles/filters/CustomCheckbox.module.css';
import { continents } from '../../utils/util';

const CustomCheckbox = ({ onClickHandler, value, checked, isContinent = false }) => {
  const { allCountry } = useEconCalendar();
  const { isIos } = useEconCalendarParams();
  const isMobile = useScreenDimensions();
  const countries = isContinent && continents[value];

  const getClassName = () => {
    let baseClass = `${style.checkbox_custom} ${isMobile ? style.mobileCustomCheckbox : style.none} `;

    if (checked) {
      if (isContinent) {
        if (countries.every((value) => allCountry?.includes(value))) {
          baseClass += isMobile ? style.checkedAllMobile : style.checkedAll;
        } else {
          baseClass += isMobile ? style.checkedPartialMobile : style.checkedPartial;
        }
      } else {
        baseClass += isMobile ? style.checkAllMobile : style.checkedAll;
      }
    } else {
      baseClass += isMobile ? style.uncheckedMobile : style.unchecked;
      baseClass += isMobile && isIos ? style.iosUnchecked : style.none;
    }
    const shadowCheck = !isMobile ? style.shadow : style.noShadow;
    baseClass += ` ${checked ? style.noShadow : shadowCheck} ${
      isIos ? style.iosCheckbox : style.none
    }`;
    return baseClass.trim();
  };

  return (
    <div className={`${style.checkbox_custom_wrapper}`}>
      <div
        onClick={() => onClickHandler(value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') onClickHandler(value);
        }}
        className={getClassName()}
        data-testid={`checkbox_${value}`}
        role="button"
        tabIndex={0}
        aria-pressed={checked}
      ></div>
    </div>
  );
};

export default CustomCheckbox;
