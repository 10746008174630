import { createChart, ColorType } from 'lightweight-charts';
import { useEffect, useRef, useState } from 'react';
import { convertUTCDateToNumber } from '../utils/util';
import { useEconCalendarParams } from '../hooks/use-econ-calendar-params';

export const ChartComponent = (props) => {
  const { currentTheme } = useEconCalendarParams();
  const themeCheck = currentTheme === 'website' || currentTheme === 'light';
  const {
    data,
    width,
    height,
    colors: {
      backgroundColor = currentTheme === 'website' || currentTheme === 'light'
        ? 'white'
        : currentTheme === 'dark'
          ? '#11171c'
          : '#0d1530',
      lineColor = themeCheck ? '#2962FF' : 'white',
      textColor = themeCheck ? 'black' : 'white',
      areaTopColor = themeCheck ? '#2962FF' : 'white',
      areaBottomColor = themeCheck ? 'rgba(41, 98, 255, 0.28)' : 'rgba(255, 255, 255, 0.28)',
    } = {},
  } = props;
  const chartContainerRef = useRef();

  useEffect(() => {
    const handleResize = () => {
      chart.applyOptions({ width: width }); //chartContainerRef.current?.clientWidth });
    };

    const chart = createChart(chartContainerRef.current, {
      layout: {
        background: { type: ColorType.Solid, color: backgroundColor },
        textColor,
      },
      width: width, //chartContainerRef.current.clientWidth,
      height: height,
    });
    chart.timeScale().fitContent();

    const newSeries = chart.addAreaSeries({
      lineColor,
      topColor: areaTopColor,
      bottomColor: areaBottomColor,
    });
    newSeries.setData(data);

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);

      chart.remove();
    };
  }, [data, backgroundColor, lineColor, textColor, areaTopColor, areaBottomColor]);

  return <div style={{ zIndex: '30' }} ref={chartContainerRef} />;
};

export default function HistoricalChart({ data, width, height }) {
  const [factoredData, setFactoredData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (data && data.length > 0) {
      let values = [];
      data.map((item) => {
        values.push({ time: convertUTCDateToNumber(item.date), value: item.value });
      });

      if (!values || values.length == 0) {
        setIsLoading(false);
        return;
      }

      // remove duplicates and sort by time
      values = values
        .filter((v, i, a) => a.findIndex((v2) => v2.time === v.time) === i)
        .sort((a, b) => {
          if (a.time < b.time) {
            return -1;
          } else {
            return 1;
          }
        });

      // normalize values
      let factor = 0;
      if (event?.previous) {
        let parsedPrevious = parseFloat(event.previous?.replace(/[^0-9.-]/g, ''));
        for (let i = values.length - 1; i >= 0 && i > values.length - 3; i--) {
          let previousValue = values[i].value;
          for (const potentialFactor of [1000000, 100000, 10000, 1000, 100]) {
            if (Math.round(previousValue / parsedPrevious / potentialFactor) == 1) {
              factor = potentialFactor;
              break;
            }
          }

          if (factor) {
            break;
          }
        }

        if (factor) {
          for (const i in values) {
            values[i].value /= factor;
          }
        }
      }

      let eventValue = {
        time: convertUTCDateToNumber(event?.date),
        value: parseFloat(
          (event?.actual ? event.actual : event?.forecast)?.replace(/[^0-9.-]/g, ''),
        ),
      };

      if (!Number.isNaN(eventValue?.value)) {
        values.push(eventValue);
      }

      const res = values.map((item) => ({ time: item.time, value: parseFloat(item.value) }));
      setFactoredData(res);
    }
  }, [data]);

  return (
    <div>
      {factoredData && factoredData.length > 0 && (
        <ChartComponent data={factoredData} width={width} height={height}></ChartComponent>
      )}
    </div>
  );
}
