const EarningsDownArrow = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.35205 13.3575C8.30564 13.404 8.25052 13.4408 8.18984 13.466C8.12917 13.4912 8.06413 13.5041 7.99844 13.5041C7.93276 13.5041 7.86772 13.4912 7.80704 13.466C7.74637 13.4408 7.69124 13.404 7.64483 13.3575L3.89368 9.60448C3.79984 9.51069 3.74711 9.38347 3.74707 9.2508C3.74705 9.1851 3.75997 9.12005 3.7851 9.05935C3.81022 8.99866 3.84705 8.9435 3.89349 8.89704C3.93993 8.85057 3.99507 8.81371 4.05575 8.78856C4.11643 8.7634 4.18148 8.75045 4.24717 8.75043C4.37984 8.75039 4.50709 8.80306 4.60093 8.89685L7.50258 11.8V3.00523C7.50258 2.87262 7.55526 2.74544 7.64903 2.65167C7.74279 2.5579 7.86997 2.50523 8.00258 2.50523C8.13519 2.50523 8.26236 2.5579 8.35613 2.65167C8.4499 2.74544 8.50258 2.87262 8.50258 3.00523V11.7916L11.3959 8.89685C11.4424 8.85041 11.4975 8.81357 11.5582 8.78845C11.6189 8.76332 11.684 8.7504 11.7497 8.75041C11.8154 8.75043 11.8804 8.76338 11.9411 8.78854C12.0018 8.81369 12.0569 8.85055 12.1034 8.89701C12.1498 8.94348 12.1866 8.99863 12.2118 9.05933C12.2369 9.12003 12.2498 9.18508 12.2498 9.25078C12.2498 9.31647 12.2368 9.38152 12.2117 9.44221C12.1865 9.50289 12.1497 9.55803 12.1032 9.60448L8.35205 13.3575Z"
        fill="#E61E1E"
      />
    </svg>
  );
};

export default EarningsDownArrow;
