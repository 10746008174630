const ENV_URLS = {
  test: {
    apiHost: 'https://net-deal.ig.com',
    calendarApiUrl: 'https://net-api.ig.com/explore',
    tastyUserName: 'tasty',
    tastyUserPassword: 'yummy',
    supportedLocaleList: ['en', 'fr', 'it', 'es', 'no', 'de', 'nl', 'sv', 'ja'],
  },
  uat: {
    apiHost: 'https://web-deal.ig.com',
    calendarApiUrl: 'https://web-api.ig.com/explore',
    tastyUserName: 'tasty',
    tastyUserPassword: 'yummy',
    supportedLocaleList: ['en', 'fr', 'it', 'es', 'no', 'de', 'nl', 'sv', 'ja'],
  },
  demo: {
    apiHost: 'https://demo-deal.ig.com',
    calendarApiUrl: 'https://demo-api.ig.com/explore',
    tastyUserName: 'calendar',
    tastyUserPassword: 'SuPeRlOnGpA$$$w00rd5671',
    supportedLocaleList: ['en', 'fr', 'it', 'es', 'no', 'de', 'nl', 'sv', 'ja'],
  },
  prod: {
    apiHost: 'https://deal.ig.com',
    calendarApiUrl: 'https://api.ig.com/explore',
    tastyUserName: 'calendar',
    tastyUserPassword: 'SuPeRlOnGpA$$$w00rd5671',
    supportedLocaleList: ['en', 'fr', 'it', 'es', 'no', 'de', 'nl', 'sv', 'ja'],
  },
};

export const getEnvURLs = (env: string) => {
  const dataKey = env?.toLowerCase();

  if (!Object.keys(ENV_URLS)?.includes(dataKey)) {
    return {
      apiHost: '',
      calendarApiUrl: '',
      tastyUserName: '',
      tastyUserPassword: '',
    };
  }

  return ENV_URLS[dataKey];
};
