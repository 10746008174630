import { createContext, useContext } from 'react';

type ISessionDataContext = {
  currentAccountId: string;
  apiHost: string;
  xst: string;
  cst: string;
  calendarApiUrl: string;
  timezoneOffset: number;
  igCompanySiteId: string;
};

type SessionDataProps = {
  children: React.ReactNode;
  currentAccountId: string;
  apiHost: string;
  xst: string;
  cst: string;
  calendarApiUrl: string;
  timezoneOffset: number;
  igCompanySiteId: string;
};

export const SessionDataContext = createContext<ISessionDataContext>({
  currentAccountId: '',
  apiHost: '',
  xst: '',
  cst: '',
  calendarApiUrl: '',
  timezoneOffset: null,
  igCompanySiteId: '',
});

export const SessionDataProvider: React.FC<SessionDataProps> = ({
  children,
  currentAccountId,
  apiHost,
  xst,
  cst,
  calendarApiUrl,
  timezoneOffset,
  igCompanySiteId,
}) => (
  <SessionDataContext.Provider
    value={{
      currentAccountId,
      apiHost,
      xst,
      cst,
      calendarApiUrl,
      timezoneOffset,
      igCompanySiteId,
    }}
  >
    {children}
  </SessionDataContext.Provider>
);

export const useSessionData = () => useContext(SessionDataContext);
