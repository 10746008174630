import { createContext, useContext } from 'react';

export const THEME_CLASSES = {
  DEFAULT: 'website',
  LIGHT: 'light',
  DARK: 'dark',
  WEBSITE: 'website',
  NAVY: 'navy',
};

type IEconCalendarParamsContext = {
  currentTheme: string;
  isWebsiteTheme: boolean;
  rowHeight: number;
  locale: string;
  fullLocale: string;
  baseColorSkeleton: string;
  highlightColorSkeleton: string;
  isWebsite: boolean;
  isAndroid: boolean;
  isIos: boolean;
  specifySpecificTab: string;
  isIpad: boolean;
  workspaceId: string;
  handleAddToCalendar: (val: Object) => void;
  backButtonHandler: (val: Object) => void;
  eventIdForDetails: string;
  handleEventIdForDetails: (val: Object) => void;
};

type EconCalendarParamsProps = {
  children: React.ReactNode;
  theme: string;
  locale: string;
  fullLocale: string;
  isWebsite: boolean;
  isAndroid: boolean;
  isIos: boolean;
  specifySpecificTab: string;
  isIpad: boolean;
  workspaceId: string;
  handleAddToCalendar: (val: Object) => void;
  backButtonHandler: (val: Object) => void;
  eventIdForDetails: string;
  handleEventIdForDetails: (val: Object) => void;
};

export const EconCalendarParamsContext = createContext<IEconCalendarParamsContext>({
  currentTheme: THEME_CLASSES.DEFAULT,
  isWebsiteTheme: false,
  rowHeight: 25,
  locale: 'en',
  fullLocale: 'en_GB',
  baseColorSkeleton: '#e0e0e0',
  highlightColorSkeleton: '#f5f5f5',
  isWebsite: false,
  isAndroid: false,
  isIos: false,
  specifySpecificTab: '',
  isIpad: false,
  workspaceId: 'WEBSITE',
  handleAddToCalendar: () => {},
  backButtonHandler: () => {},
  eventIdForDetails: '',
  handleEventIdForDetails: () => {},
});

export const EconCalendarParamsProvider: React.FC<EconCalendarParamsProps> = ({
  children,
  theme,
  locale,
  fullLocale,
  isWebsite,
  isAndroid,
  isIos,
  specifySpecificTab,
  isIpad,
  workspaceId,
  handleAddToCalendar,
  backButtonHandler,
  eventIdForDetails,
  handleEventIdForDetails,
}) => (
  <EconCalendarParamsContext.Provider
    value={{
      currentTheme: theme,
      isWebsiteTheme: theme === THEME_CLASSES.WEBSITE,
      rowHeight: theme === THEME_CLASSES.WEBSITE ? 57 : 25,
      locale,
      fullLocale,
      baseColorSkeleton: theme === 'dark' ? '#212B36' : theme === 'navy' ? '#0B1E44' : '#e0e0e0',
      highlightColorSkeleton:
        theme === 'dark' ? '#11171D' : theme === 'navy' ? '#0E1530' : '#f5f5f5',
      isWebsite: isWebsite,
      isAndroid: isAndroid,
      isIos: isIos,
      specifySpecificTab,
      isIpad: isIpad,
      workspaceId,
      handleAddToCalendar,
      backButtonHandler,
      eventIdForDetails,
      handleEventIdForDetails,
    }}
  >
    {children}
  </EconCalendarParamsContext.Provider>
);

export const useEconCalendarParams = () => useContext(EconCalendarParamsContext);
