export const conversionText = {
  '10-year_bond_auction.title:ja:DEFAULT': '10年国債入札',
  '10-year_bond_auction.title:ja:Netherlands': '10年国債入札',
  '10-year_bond_auction.title:ja:Canada': '',
  '10-year_bond_auction.title:ja:Italy': '10年国債入札',
  '10-year_jgb_auction.title:ja:DEFAULT': '10年日本国債入札',
  '10-year_jgb_auction.title:ja:Japan': '10年日本国債入札',
  '10-year_note_auction.title:ja:DEFAULT': '10年国債入札',
  '10-year_note_auction.title:ja:United States': '10年国債入札',
  '10-year_tips_auction.title:ja:DEFAULT': 'TIPS物価連動10年国債入札',
  '10-year_tips_auction.title:ja:United States': 'TIPS物価連動10年国債入札',
  '103-day_bill_auction.title:ja:DEFAULT': '103日割引国債入札',
  '103-day_bill_auction.title:ja:United States': '103日割引国債入札',
  '105-day_bill_auction.title:ja:DEFAULT': '105日割引国債入札',
  '105-day_bill_auction.title:ja:United States': '105日割引国債入札',
  '118-day_bill_auction.title:ja:DEFAULT': '118日割引国債入札',
  '118-day_bill_auction.title:ja:United States': '118日割引国債入札',
  '12-month_btf_auction.title:ja:DEFAULT': 'BTF12カ月国債入札',
  '12-month_btf_auction.title:ja:France': 'BTF12カ月国債入札',
  '120-day_bill_auction.title:ja:DEFAULT': '120日割引国債入札',
  '120-day_bill_auction.title:ja:United States': '120日割引国債入札',
  '13-week_bill_auction.title:ja:DEFAULT': '13週割引国債入札',
  '13-week_bill_auction.title:ja:Greece': '13週割引国債入札',
  '154-day_bill_auction.title:ja:DEFAULT': '154日割引国債入札',
  '154-day_bill_auction.title:ja:United States': '154日割引国債入札',
  '1848_revolution_day.title:ja:DEFAULT': '1848年革命記念日',
  '1848_revolution_day.title:ja:Hungary': '1848年革命記念日',
  '1st_day_flag_referendum.title:ja:DEFAULT': '国旗変更国民投票初日',
  '1st_day_flag_referendum.title:ja:New Zealand': '国旗変更国民投票初日',
  '1st_day_national_people_s_congress.title:ja:DEFAULT': '全国人民代表大会初日',
  '1st_day_national_people_s_congress.title:ja:China': '全国人民代表大会初日',
  '1st_day_of_muharram_1435.title:ja:DEFAULT': 'ムハッラム初日',
  '1st_day_of_muharram_1435.title:ja:Indonesia': 'ムハッラム初日',
  '2-year_bond_auction.title:ja:DEFAULT': '',
  '2-year_bond_auction.title:ja:Canada': '',
  '2-year_schatz_auction.title:ja:DEFAULT': 'シャッツ2年債入札',
  '2-year_schatz_auction.title:ja:Germany': 'シャッツ2年債入札',
  '20-year_btp_auction.title:ja:DEFAULT': '',
  '20-year_btp_auction.title:ja:Italy': '',
  '20-year_bond_auction.title:ja:DEFAULT': '20年国債入札',
  '20-year_bond_auction.title:ja:United States': '20年国債入札',
  '20-year_treasury_gilt_auction.title:ja:DEFAULT': 'ギルト20年国債入札',
  '20-year_treasury_gilt_auction.title:ja:United Kingdom': 'ギルト20年国債入札',
  '2016_annual_budget_announcement.title:ja:DEFAULT': '2016年度予算発表',
  '2016_annual_budget_announcement.title:ja:United Kingdom': '2016年度予算発表',
  '2016_budget_announcement.title:ja:DEFAULT': '2016年予算発表',
  '2016_budget_announcement.title:ja:Singapore': '2016年予算発表',
  '2016_budget_announcement.title:ja:Canada': '2016年予算発表',
  '2016_budget_presentation.title:ja:DEFAULT': '2016年予算プレゼンテーション',
  '2016_budget_presentation.title:ja:South Africa': '2016年予算プレゼンテーション',
  '2016_2017_budget_proposal.title:ja:DEFAULT': '2016/2017年予算案',
  '2016_2017_budget_proposal.title:ja:Hong Kong': '2016/2017年予算案',
  '2017-18_budget_release.title:ja:DEFAULT': '2017-18年予算発表',
  '2017-18_budget_release.title:ja:Australia': '2017-18年予算発表',
  '2020_fiscal_budget.title:ja:DEFAULT': '2020年度財政予算',
  '2020_fiscal_budget.title:ja:Canada': '2020年度財政予算',
  '2021_national_budget.title:ja:DEFAULT': '2021年度国家予算',
  '2021_national_budget.title:ja:South Africa': '2021年度国家予算',
  '21-day_bill_auction.title:ja:DEFAULT': '',
  '21-day_bill_auction.title:ja:United States': '',
  '228_peace_memorial_day.title:ja:DEFAULT': '和平記念日',
  '228_peace_memorial_day.title:ja:Taiwan': '和平記念日',
  '26-week_bill_auction.title:ja:DEFAULT': '26週割引国債入札',
  '26-week_bill_auction.title:ja:Greece': '26週割引国債入札',
  '26-weeks_bill_auction.title:ja:DEFAULT': '26週割引国債入札',
  '26-weeks_bill_auction.title:ja:Greece': '26週割引国債入札',
  '3-month_btf_auction.title:ja:DEFAULT': 'BTF3カ月国債入札',
  '3-month_btf_auction.title:ja:France': 'BTF3カ月国債入札',
  '3-month_letras_auction.title:ja:DEFAULT': 'Letras3カ月国債入札',
  '3-month_letras_auction.title:ja:Spain': 'Letras3カ月国債入札',
  '3-month_t-bill_auction.title:ja:DEFAULT': '3カ月割引国債入札',
  '3-month_t-bill_auction.title:ja:Netherlands': '3カ月割引国債入札',
  '30-year_btp_auction.title:ja:DEFAULT': 'BTP30年国債入札',
  '30-year_btp_auction.title:ja:Italy': 'BTP30年国債入札',
  '30-year_bond_auction.title:ja:DEFAULT': '30年国債入札',
  '30-year_bond_auction.title:ja:Canada': '',
  '30-year_bond_auction.title:ja:United States': '30年国債入札',
  '30-year_note_auction.title:ja:DEFAULT': '30年国債入札',
  '30-year_note_auction.title:ja:United States': '30年国債入札',
  '30-year_obligacion_auction.title:ja:DEFAULT': '',
  '30-year_obligacion_auction.title:ja:Spain': '',
  '30-year_tips_auction.title:ja:DEFAULT': 'TIPS物価連動30年国債入札',
  '30-year_tips_auction.title:ja:United States': 'TIPS物価連動30年国債入札',
  '30-year_jgb_auction.title:ja:DEFAULT': '30年日本国債入札',
  '30-year_jgb_auction.title:ja:Japan': '30年日本国債入札',
  '30-year_treasury_gilt_auction.title:ja:DEFAULT': 'Gilt30年国債入札',
  '30-year_treasury_gilt_auction.title:ja:United Kingdom': 'Gilt30年国債入札',
  '4-year_btan_auction.title:ja:DEFAULT': 'BTAN4年債入札',
  '4-year_btan_auction.title:ja:France': 'BTAN4年債入札',
  '42-day_bill_auction.title:ja:DEFAULT': '42日割引国債入札',
  '42-day_bill_auction.title:ja:United States': '42日割引国債入札',
  '2-year_frn_auction.title:ja:DEFAULT': 'FRN2年債入札',
  '2-year_frn_auction.title:ja:United States': 'FRN2年債入札',
  '43-day_bill_auction.title:ja:DEFAULT': '43日割引国債入札',
  '43-day_bill_auction.title:ja:United States': '43日割引国債入札',
  '5-year_btan_auction.title:ja:DEFAULT': 'BTAN5年債入札',
  '5-year_btan_auction.title:ja:France': 'BTAN5年債入札',
  '5-year_bobl_auction.title:ja:DEFAULT': 'Bobl5年債入札',
  '5-year_bobl_auction.title:ja:Germany': 'Bobl5年債入札',
  '5-y_bond_auction.title:ja:DEFAULT': '5年国債入札',
  '5-y_bond_auction.title:ja:Italy': '5年国債入札',
  '50-year_bond_auction.title:ja:DEFAULT': '',
  '50-year_bond_auction.title:ja:Canada': '',
  '52-week_bill_auction.title:ja:DEFAULT': '52週割引国債入札',
  '52-week_bill_auction.title:ja:United States': '52週割引国債入札',
  '6-month_btf_auction.title:ja:DEFAULT': 'BTF6カ月国債入札',
  '6-month_btf_auction.title:ja:France': 'BTF6カ月国債入札',
  '6-month_bill_auction.title:ja:DEFAULT': '6カ月割引国債入札',
  '6-month_bill_auction.title:ja:Netherlands': '6カ月割引国債入札',
  '6-month_bill_auction.title:ja:United States': '6カ月割引国債入札',
  '8-week_bill_auction.title:ja:DEFAULT': '8週割引国債入札',
  '8-week_bill_auction.title:ja:United States': '8週割引国債入札',
  'absa_manufacturing_pmi.title:ja:DEFAULT': 'ABSA製造業購買担当者景気指数',
  'absa_manufacturing_pmi.title:ja:South Africa': 'ABSA製造業購買担当者景気指数',
  'adp_employment_change.title:ja:DEFAULT': 'ADP雇用統計',
  'adp_employment_change.title:ja:Canada': 'ADP雇用統計',
  'adp_employment_change.title:ja:United States': 'ADP 雇用統計',
  'aib_services_pmi.title:ja:DEFAULT': 'AIB サービス業購買担当者景気指数',
  'aib_services_pmi.title:ja:Ireland': 'AIB サービス業購買担当者景気指数',
  'aig_construction_index.title:ja:DEFAULT': 'AIG 建設業指数',
  'aig_construction_index.title:ja:Australia': 'AIG 建設業指数',
  'aig_manufacturing_index.title:ja:DEFAULT': 'AIG製造業指数',
  'aig_manufacturing_index.title:ja:Australia': 'AIG製造業指数',
  '2-year_note_auction.title:ja:DEFAULT': '2年国債入札',
  '2-year_note_auction.title:ja:United States': '2年国債入札',
  'aig_services_index.title:ja:DEFAULT': 'AIG サービス業指数',
  'aig_services_index.title:ja:Australia': 'AIG サービス業指数',
  'anz_business_confidence_final.title:ja:DEFAULT': 'ANZ 企業信頼感指数実績',
  'anz_business_confidence_final.title:ja:New Zealand': 'ANZ 企業信頼感指数実績',
  'anz_business_confidence_prel.title:ja:DEFAULT': 'ANZ 企業信頼感指数最終値',
  'anz_business_confidence_prel.title:ja:New Zealand': 'ANZ 企業信頼感指数最終値',
  'anz_internet_job_ads_mom.title:ja:DEFAULT': 'ANZ インターネット求人広告件数前月比',
  'anz_internet_job_ads_mom.title:ja:Australia': 'ANZ インターネット求人広告件数前月比',
  'anz_job_advertisement.title:ja:DEFAULT': 'ANZ 求人広告件数',
  'anz_job_advertisement.title:ja:Australia': 'ANZ 求人広告件数',
  'anz_job_advertisement_mom.title:ja:DEFAULT': 'ANZ求人広告件数（前月比）',
  'anz_job_advertisement_mom.title:ja:Australia': 'ANZ求人広告件数（前月比）',
  'anz_job_advertisment.title:ja:DEFAULT': 'ANZ求人広告件数',
  'anz_job_advertisment.title:ja:Australia': 'ANZ求人広告件数',
  'anz_newspaper_job_ads_mom.title:ja:DEFAULT': 'ANZ新聞求人広告件数（前月比）',
  'anz_newspaper_job_ads_mom.title:ja:Australia': 'ANZ新聞求人広告件数（前月比）',
  'anz_roy_morgan_consumer_confidence.title:ja:DEFAULT': 'ANZロイ・モーガン消費者信頼指数',
  'anz_roy_morgan_consumer_confidence.title:ja:New Zealand': 'ANZロイ・モーガン消費者信頼指数',
  'anzac_day.title:ja:DEFAULT': 'ANZACの日',
  'anzac_day.title:ja:New Zealand': 'ANZACの日',
  'anzac_day.title:ja:Australia': 'ANZACの日',
  'api_crude_stock_change.title:ja:DEFAULT': '米国石油協会（API）原油在庫変化',
  'api_crude_stock_change.title:ja:United States': '米国石油協会（API）原油在庫変化',
  'api_weekly_crude_oil_stock.title:ja:DEFAULT': '米国石油協会（API）週間原油在庫',
  'api_weekly_crude_oil_stock.title:ja:United States': '米国石油協会（API）週間原油在庫',
  '8th_european_central_bank_conference_on_statistics.title:ja:DEFAULT':
    '第8回欧州中央銀行統計会合',
  '8th_european_central_bank_conference_on_statistics.title:ja:Euro Area':
    '第8回欧州中央銀行統計会合',
  'api_weekly_crude_stock_change.title:ja:DEFAULT': '米国石油協会（API）週間原油在庫変化',
  'api_weekly_crude_stock_change.title:ja:United States': '米国石油協会（API）週間原油在庫変化',
  'additional_day.title:ja:DEFAULT': '追加特別休日',
  'additional_day.title:ja:Philippines': '追加特別休日',
  'adjusted_holiday.title:ja:DEFAULT': '振替休日',
  'adjusted_holiday.title:ja:Taiwan': '振替休日',
  'adjustment_holiday.title:ja:DEFAULT': '振替休日',
  'adjustment_holiday.title:ja:South Africa': '振替休日',
  'ai_group_construction_index.title:ja:DEFAULT': 'AIG建設業指数',
  'ai_group_construction_index.title:ja:Australia': 'AIG建設業指数',
  'ai_group_manufacturing_index.title:ja:DEFAULT': 'AIG製造業指数',
  'ai_group_manufacturing_index.title:ja:Australia': 'AIG製造業指数',
  'ai_group_services_index.title:ja:DEFAULT': 'AIGサービス業指数',
  'ai_group_services_index.title:ja:Australia': 'AIGサービス業指数',
  'aig_perf_services_index.title:ja:DEFAULT': 'AIGサービス業景況指数',
  'aig_perf_services_index.title:ja:Australia': 'AIGサービス業景況指数',
  'aig_perfor_construction_index.title:ja:DEFAULT': 'AIG建設業景況指数',
  'aig_perfor_construction_index.title:ja:Australia': 'AIG建設業景況指数',
  'aig_performance_constr_index.title:ja:DEFAULT': 'AIG建設業景況指数',
  'aig_performance_constr_index.title:ja:Australia': 'AIG建設業景況指数',
  'aig_performance_mfg_index.title:ja:DEFAULT': 'AIG製造業景況指数',
  'aig_performance_mfg_index.title:ja:Australia': 'AIG製造業景況指数',
  'aig_performance_of_construction_index.title:ja:DEFAULT': 'AIG建設業景況指数',
  'aig_performance_of_construction_index.title:ja:Australia': 'AIG建設業景況指数',
  'aig_performance_of_mfg_index.title:ja:DEFAULT': 'AIG製造業景況指数',
  'aig_performance_of_mfg_index.title:ja:Australia': 'AIG製造業景況指数',
  'alberta_general_election.title:ja:DEFAULT': 'アルバータ州一般選挙',
  'alberta_general_election.title:ja:Canada': 'アルバータ州一般選挙',
  'all_industry_activity_index_mom.title:ja:DEFAULT': '全産業活動指数',
  'all_industry_activity_index_mom.title:ja:Japan': '全産業活動指数',
  '3-month_bill_auction.title:ja:DEFAULT': '3ヶ月割引債入札',
  '3-month_bill_auction.title:ja:Netherlands': '3ヶ月割引債入札',
  '3-month_bill_auction.title:ja:United States': '3ヶ月割引債入札',
  'all_saint_s_day.title:ja:DEFAULT': '諸聖人の日',
  'all_saint_s_day.title:ja:Colombia': '諸聖人の日',
  'all_saint_s_day.title:ja:Philippines': '諸聖人の日',
  'all_saint_s_day.title:ja:Hungary': '諸聖人の日',
  'all_saint_s_day.title:ja:Poland': '諸聖人の日',
  'all_saint_s_day.title:ja:Austria': '諸聖人の日',
  'all_saint_s_day.title:ja:Sweden': '諸聖人の日',
  'all_saints_day.title:ja:DEFAULT': '諸聖人の日',
  'all_saints_day.title:ja:Colombia': '諸聖人の日',
  'all_saints_day.title:ja:Brazil': '諸聖人の日',
  'all_saints_day.title:ja:Sweden': '諸聖人の日',
  'all_saints_day.title:ja:Germany': '諸聖人の日',
  'all_saints_day.title:ja:France': '諸聖人の日',
  'all_saints_day.title:ja:Belgium': '諸聖人の日',
  'all_saints_day.title:ja:Poland': '諸聖人の日',
  'all_saints_day.title:ja:Austria': '諸聖人の日',
  'all_saints_day.title:ja:Hungary': '諸聖人の日',
  'all_saints_day.title:ja:Finland': '諸聖人の日',
  'all_saints_day.title:ja:Philippines': '諸聖人の日',
  'all_saints_day.title:ja:Italy': '諸聖人の日',
  'all_saints_day.title:ja:Portugal': '諸聖人の日',
  'all_saints_day.title:ja:Spain': '諸聖人の日',
  'all_souls_day.title:ja:DEFAULT': '諸聖人の日',
  'all_souls_day.title:ja:Mexico': '諸聖人の日',
  'all_souls_day.title:ja:Philippines': '死者の日',
  'all_souls_day.title:ja:Brazil': '死者の日',
  'all_truck_sales.title:ja:DEFAULT': '全トラック販売台数',
  'all_truck_sales.title:ja:United States': '全トラック販売台数',
  'annual_budget_release.title:ja:DEFAULT': '年度予算発表',
  'annual_budget_release.title:ja:New Zealand': '年度予算発表',
  'annualized_housing_starts.title:ja:DEFAULT': '年換算住宅着工件数',
  'annualized_housing_starts.title:ja:Japan': '年換算住宅着工件数',
  'armistice_day.title:ja:DEFAULT': '第一次世界大戦休戦記念日',
  'armistice_day.title:ja:France': '第一次世界大戦休戦記念日',
  'armistice_day.title:ja:Belgium': '第一次世界大戦休戦記念日',
  'asarnha_bucha.title:ja:DEFAULT': '三宝節',
  'asarnha_bucha.title:ja:Thailand': '三宝節',
  'asarnha_bucha_day.title:ja:DEFAULT': '三宝節',
  'asarnha_bucha_day.title:ja:Thailand': '三宝節',
  'asarnha_bucha_day_substitute.title:ja:DEFAULT': '三宝節（振替）',
  'asarnha_bucha_day_substitute.title:ja:Thailand': '三宝節（振替）',
  'ascension_day.title:ja:DEFAULT': 'キリストの昇天',
  'ascension_day.title:ja:Colombia': 'キリストの昇天',
  'ascension_day.title:ja:Switzerland': 'キリストの昇天',
  'ascension_day.title:ja:Finland': 'キリストの昇天',
  'ascension_day.title:ja:Denmark': 'キリストの昇天',
  'ascension_day.title:ja:France': 'キリストの昇天',
  'ascension_day.title:ja:Austria': 'キリストの昇天',
  'ascension_day.title:ja:Germany': 'キリストの昇天',
  'ascension_day.title:ja:Sweden': 'キリストの昇天',
  'ascension_day.title:ja:Indonesia': 'キリストの昇天',
  'ascension_day.title:ja:Netherlands': 'キリストの昇天',
  'ascension_day.title:ja:Belgium': 'キリストの昇天',
  'ascension_day.title:ja:Norway': 'キリストの昇天',
  'asia_economic_integration_monitor_adb_semiannual_review.title:ja:DEFAULT':
    '​アジア経済統合モニター・ADB半期レビュー',
  'asia_economic_integration_monitor_adb_semiannual_review.title:ja:Philippines':
    '​アジア経済統合モニター・ADB半期レビュー',
  'asian_development_bank_annual_meeting.title:ja:DEFAULT': 'アジア開発銀行年次会合',
  'asian_development_bank_annual_meeting.title:ja:Germany': 'アジア開発銀行年次会合',
  '41-day_bill_auction.title:ja:DEFAULT': '41日割引国債入札',
  '41-day_bill_auction.title:ja:United States': '41日割引国債入札',
  'asian_financial_forum.title:ja:DEFAULT': 'アジア金融フォーラム',
  'asian_financial_forum.title:ja:Hong Kong': 'アジア金融フォーラム',
  'australia_day.title:ja:DEFAULT': 'オーストラリアの日',
  'australia_day.title:ja:Australia': 'オーストラリアの日',
  'australia_day_substitute_day.title:ja:DEFAULT': 'オーストラリアの日（振替休日）',
  'australia_day_substitute_day.title:ja:Australia': 'オーストラリアの日（振替休日）',
  'auto_production_yoy.title:ja:DEFAULT': '自動車生産台数',
  'auto_production_yoy.title:ja:Mexico': '自動車生産台数',
  'autumn_budget.title:ja:DEFAULT': '秋季財政演説',
  'autumn_budget.title:ja:United Kingdom': '秋季財政演説',
  'autumn_budget_2018.title:ja:DEFAULT': '2018年秋の予算編成方針',
  'autumn_budget_2018.title:ja:United Kingdom': '2018年秋の予算編成方針',
  'autumn_equinox_day.title:ja:DEFAULT': '秋分の日',
  'autumn_equinox_day.title:ja:Japan': '秋分の日',
  'autumnal_equinox_day.title:ja:DEFAULT': '秋分の日',
  'autumnal_equinox_day.title:ja:Japan': '秋分の日',
  'average_cash_earnings_yoy.title:ja:DEFAULT': '平均給与（前年比）',
  'average_cash_earnings_yoy.title:ja:Japan': '平均給与（前年比）',
  'average_earnings_excl_bonus.title:ja:DEFAULT': '平均給与（ボーナス除く）',
  'average_earnings_excl_bonus.title:ja:United Kingdom': '平均給与（ボーナス除く）',
  'average_earnings_incl_bonus.title:ja:DEFAULT': '平均給与（ボーナス含む）',
  'average_earnings_incl_bonus.title:ja:United Kingdom': '平均給与（ボーナス含む）',
  'average_hourly_wages_yoy.title:ja:DEFAULT': '平均時給（前年比）',
  'average_hourly_wages_yoy.title:ja:Canada': '平均時給（前年比）',
  'bba_mortgage_approval.title:ja:DEFAULT': 'BBA 住宅ローン利率承認',
  'bba_mortgage_approval.title:ja:United Kingdom': 'BBA 住宅ローン利率承認',
  'bba_mortgage_rate.title:ja:DEFAULT': 'BBA 住宅ローン利率',
  'bba_mortgage_rate.title:ja:United Kingdom': 'BBA 住宅ローン利率',
  'bcb_inflation_report.title:ja:DEFAULT': 'BCB インフレ報告',
  'bcb_inflation_report.title:ja:Brazil': 'BCB インフレ報告',
  'boe_consumer_credit.title:ja:DEFAULT': 'BOE消費者信用残高',
  'boe_consumer_credit.title:ja:United Kingdom': 'BOE消費者信用残高',
  'boe_credit_conditions_survey.title:ja:DEFAULT': 'BOE 信用状況調査',
  'boe_credit_conditions_survey.title:ja:United Kingdom': 'BOE 信用状況調査',
  'boe_gov_carney_speech.title:ja:DEFAULT': 'BOE（イングランド銀行）カーニー総裁講演',
  'boe_gov_carney_speech.title:ja:United Kingdom': 'BOE（イングランド銀行）カーニー総裁講演',
  'boe_quantitative_easing.title:ja:DEFAULT': 'イングランド銀行量的緩和',
  'boe_quantitative_easing.title:ja:United Kingdom': 'イングランド銀行量的緩和',
  'boe_quarterly_inflation_report.title:ja:DEFAULT': 'イングランド銀行四半期インフレ報告',
  'boe_quarterly_inflation_report.title:ja:United Kingdom': 'イングランド銀行四半期インフレ報告',
  'boe_s_gov_carney_speech.title:ja:DEFAULT': 'イングランド銀行カーニー総裁講演',
  'boe_s_gov_carney_speech.title:ja:United Kingdom': 'イングランド銀行カーニー総裁講演',
  'boj_monetary_policy_statement.title:ja:DEFAULT': '日本銀行金融政策発表',
  'boj_monetary_policy_statement.title:ja:Japan': '日本銀行金融政策発表',
  'boj_monthly_report.title:ja:DEFAULT': '日本銀行月報',
  'boj_monthly_report.title:ja:Japan': '日本銀行月報',
  'brc_retail_sales_monitor_yoy.title:ja:DEFAULT': 'BRC英小売協会売上高モニター（前年比）',
  'brc_retail_sales_monitor_yoy.title:ja:United Kingdom': 'BRC英小売協会売上高モニター（前年比）',
  'brc_retail_sales_yoy.title:ja:DEFAULT': 'BRC英小売協会売上高（前年比）',
  'brc_retail_sales_yoy.title:ja:United Kingdom': 'BRC英小売協会売上高（前年比）',
  'brc_shop_price_index_mom.title:ja:DEFAULT': 'BRC 英小売協会店頭価格指数（前月比）',
  'brc_shop_price_index_mom.title:ja:United Kingdom': 'BRC 英小売協会店頭価格指数（前月比）',
  'brc_shop_price_index_yoy.title:ja:DEFAULT': 'BRC英小売協会店頭価格指数（前年比）',
  'brc_shop_price_index_yoy.title:ja:United Kingdom': 'BRC英小売協会店頭価格指数（前年比）',
  'brics_summit.title:ja:DEFAULT': 'BRICSサミット',
  'brics_summit.title:ja:Brazil': 'BRICSサミット',
  'bsi_large_manufacturing_qoq.title:ja:DEFAULT': 'BSI日本大企業製造業景況判断指数',
  'bsi_large_manufacturing_qoq.title:ja:Japan': 'BSI日本大企業製造業景況判断指数',
  'baden-w_rttemberg_state_election.title:ja:DEFAULT': 'バーデン＝ヴュルテンベルク州選挙',
  'baden-w_rttemberg_state_election.title:ja:Germany': 'バーデン＝ヴュルテンベルク州選挙',
  'balance_of_trade.title:ja:DEFAULT': '貿易収支',
  'balance_of_trade.title:ja:Thailand': '貿易収支',
  'balance_of_trade.title:ja:Switzerland': '貿易収支',
  'balance_of_trade.title:ja:Philippines': '貿易収支',
  'balance_of_trade.title:ja:Colombia': '貿易収支',
  'balance_of_trade.title:ja:Euro Area': '貿易収支',
  'balance_of_trade.title:ja:Japan': '貿易収支',
  'balance_of_trade.title:ja:Singapore': '貿易収支',
  'balance_of_trade.title:ja:Italy': '貿易収支',
  'balance_of_trade.title:ja:Netherlands': '貿易収支',
  'balance_of_trade.title:ja:New Zealand': '貿易収支',
  'balance_of_trade.title:ja:India': '貿易収支',
  'balance_of_trade.title:ja:United States': '貿易収支',
  'balance_of_trade.title:ja:Indonesia': '貿易収支',
  'balance_of_trade.title:ja:Canada': '貿易収支',
  'balance_of_trade.title:ja:South Korea': '貿易収支',
  'balance_of_trade.title:ja:Taiwan': '貿易収支',
  'balance_of_trade.title:ja:Norway': '貿易収支',
  'balance_of_trade.title:ja:Mexico': '貿易収支',
  'balance_of_trade.title:ja:Brazil': '貿易収支',
  'balance_of_trade.title:ja:Poland': '貿易収支',
  'balance_of_trade.title:ja:Germany': '貿易収支',
  'balance_of_trade.title:ja:Australia': '貿易収支',
  'balance_of_trade.title:ja:Hong Kong': '貿易収支',
  'balance_of_trade.title:ja:Greece': '貿易収支',
  'balance_of_trade.title:ja:Belgium': '貿易収支',
  'balance_of_trade.title:ja:France': '貿易収支',
  'balance_of_trade.title:ja:South Africa': '貿易収支',
  'balance_of_trade.title:ja:Sweden': '貿易収支',
  'balance_of_trade.title:ja:Austria': '貿易収支',
  'balance_of_trade.title:ja:Ireland': '貿易収支',
  'balance_of_trade.title:ja:United Kingdom': '貿易収支',
  'balance_of_trade.title:ja:Finland': '貿易収支',
  'balance_of_trade.title:ja:Spain': '貿易収支',
  'balance_of_trade.title:ja:Russia': '貿易収支',
  'balance_of_trade.title:ja:Czech Republic': '貿易収支',
  'balance_of_trade.title:ja:Denmark': '貿易収支',
  'balance_of_trade.title:ja:Israel': '貿易収支',
  'balance_of_trade.title:ja:Hungary': '貿易収支',
  'balance_of_trade.title:ja:China': '貿易収支',
  'balance_of_trade.title:ja:Portugal': '貿易収支',
  'balance_of_trade_final.title:ja:DEFAULT': '貿易収支 確報値',
  'balance_of_trade_final.title:ja:India': '貿易収支 確報値',
  'balance_of_trade_prel.title:ja:DEFAULT': '貿易収支 速報値',
  'balance_of_trade_prel.title:ja:India': '貿易収支 速報値',
  'balinese_new_year.title:ja:DEFAULT': 'ニュピ（バリ元日）',
  'balinese_new_year.title:ja:Indonesia': 'ニュピ（バリ元日）',
  'bank_austria_manufacturing_pmi.title:ja:DEFAULT':
    'オーストリア銀行製造業購買担当者景気指数（PMI）',
  'bank_austria_manufacturing_pmi.title:ja:Austria':
    'オーストリア銀行製造業購買担当者景気指数（PMI）',
  'bank_holiday.title:ja:DEFAULT': 'バンク・ホリデー',
  'bank_holiday.title:ja:United Kingdom': 'バンク・ホリデー',
  'bank_holiday.title:ja:Japan': 'バンク・ホリデー',
  'bank_holiday.title:ja:Mexico': 'バンク・ホリデー',
  'bank_holiday.title:ja:Ireland': 'バンク・ホリデー',
  'bank_holiday.title:ja:Denmark': 'バンク・ホリデー',
  'bank_holiday_-_annual_accounts_closing.title:ja:DEFAULT': 'バンク・ホリデー－年度決算',
  'bank_holiday_-_annual_accounts_closing.title:ja:India': 'バンク・ホリデー－年度決算',
  'bank_lending.title:ja:DEFAULT': '銀行貸出残高',
  'bank_lending.title:ja:Singapore': '銀行貸出残高',
  'bank_lending_mom.title:ja:DEFAULT': '銀行貸出残高（前月比）',
  'bank_lending_mom.title:ja:Brazil': '銀行貸出残高（前月比）',
  'bank_lending_yoy.title:ja:DEFAULT': '銀行貸出残高（前年比）',
  'bank_lending_yoy.title:ja:Japan': '銀行貸出残高（前年比）',
  'bank_loan_growth.title:ja:DEFAULT': '銀行融資残高伸び率',
  'bank_loan_growth.title:ja:India': '銀行融資残高伸び率',
  'bank_stress_tests.title:ja:DEFAULT': '銀行ストレステスト',
  'bank_stress_tests.title:ja:United Kingdom': '銀行ストレステスト',
  'bank_stress_tests_results.title:ja:DEFAULT': '銀行ストレステスト結果',
  'bank_stress_tests_results.title:ja:Euro Area': '銀行ストレステスト結果',
  'bank_of_canada_consumer_price_index_core_yoy.title:ja:DEFAULT':
    'カナダ銀行コア消費者物価指数（前年比）',
  'bank_of_canada_consumer_price_index_core_yoy.title:ja:Canada':
    'カナダ銀行コア消費者物価指数（前年比）',
  'bank_of_canada_core_inflation_mom.title:ja:DEFAULT': 'カナダ銀行コアインフレ率（前月比）',
  'bank_of_canada_core_inflation_mom.title:ja:Canada': 'カナダ銀行コアインフレ率（前月比）',
  'bank_of_indonesia_rate.title:ja:DEFAULT': 'インドネシア銀行金利',
  'bank_of_indonesia_rate.title:ja:Indonesia': 'インドネシア銀行金利',
  'banxico_inflation_report.title:ja:DEFAULT': 'メキシコ銀行インフレ報告',
  'banxico_inflation_report.title:ja:Mexico': 'メキシコ銀行インフレ報告',
  'barclays_manufacturing_pmi.title:ja:DEFAULT': 'バークレイズ製造業購買担当者景気指数（PMI）',
  'barclays_manufacturing_pmi.title:ja:South Africa': 'バークレイズ製造業購買担当者景気指数（PMI）',
  'battle_of_boyac.title:ja:DEFAULT': 'ボヤカ戦勝記念日',
  'battle_of_boyac.title:ja:Colombia': 'ボヤカ戦勝記念日',
  'bde_gov_linde_speech.title:ja:DEFAULT': 'スペイン中央銀行リンデ総裁講演',
  'bde_gov_linde_speech.title:ja:Spain': 'スペイン中央銀行リンデ総裁講演',
  'bdm_inflation_report.title:ja:DEFAULT': 'BdMインフレ報告',
  'bdm_inflation_report.title:ja:Mexico': 'BdMインフレ報告',
  'bdp_gov_costa_speech.title:ja:DEFAULT': 'ポルトガル中央銀行コスタ総裁講演',
  'bdp_gov_costa_speech.title:ja:Portugal': 'ポルトガル中央銀行コスタ総裁講演',
  'beige_book.title:ja:DEFAULT': '地区連銀景況報告（ベージュブック）',
  'beige_book.title:ja:United States': '地区連銀景況報告（ベージュブック）',
  'belgian_national_day.title:ja:DEFAULT': 'ベルギー建国記念日',
  'belgian_national_day.title:ja:Belgium': 'ベルギー建国記念日',
  'benito_ju_rez_s_birthday_memorial.title:ja:DEFAULT': 'ベニート・フアレス生誕日',
  'benito_ju_rez_s_birthday_memorial.title:ja:Mexico': 'ベニート・フアレス生誕日',
  'benn_bill_debate.title:ja:DEFAULT': 'ベン議員法案の審議',
  'benn_bill_debate.title:ja:United Kingdom': 'ベン議員法案の審議',
  'birth_of_benito_ju_rez.title:ja:DEFAULT': 'ベニート・フアレス生誕日',
  'birth_of_benito_ju_rez.title:ja:Mexico': 'ベニート・フアレス生誕日',
  'black_saturday.title:ja:DEFAULT': '聖土曜日',
  'black_saturday.title:ja:Philippines': '聖土曜日',
  'boc_business_outlook_survey.title:ja:DEFAULT': 'カナダ銀行企業見通し調査',
  'boc_business_outlook_survey.title:ja:Canada': 'カナダ銀行企業見通し調査',
  'boc_deputy_gov_patterson_speech.title:ja:DEFAULT': 'カナダ銀行パターソン副総裁講演',
  'boc_deputy_gov_patterson_speech.title:ja:Canada': 'カナダ銀行パターソン副総裁講演',
  'anzac_day_substitute_day.title:ja:DEFAULT': 'ANZACの日（振替休日）',
  'anzac_day_substitute_day.title:ja:New Zealand': 'ANZACの日（振替休日）',
  'boc_deputy_gov_schembri_speech.title:ja:DEFAULT': 'カナダ銀行シェンブリ副総裁講演',
  'boc_deputy_gov_schembri_speech.title:ja:Canada': 'カナダ銀行シェンブリ副総裁講演',
  'boc_deputy_gov_wilkins_speech.title:ja:DEFAULT': 'カナダ銀行ウィルキンス副総裁講演',
  'boc_deputy_gov_wilkins_speech.title:ja:Canada': 'カナダ銀行ウィルキンス副総裁講演',
  'boc_financial_system_review.title:ja:DEFAULT': 'カナダ銀行金融システムレビュー',
  'boc_financial_system_review.title:ja:Canada': 'カナダ銀行金融システムレビュー',
  'boc_gov_lane_speech.title:ja:DEFAULT': 'カナダ銀行レーン総裁講演',
  'boc_gov_lane_speech.title:ja:Canada': 'カナダ銀行レーン総裁講演',
  'boc_gov_macklem_speech.title:ja:DEFAULT': 'カナダ銀行マックレム総裁講演',
  'boc_gov_macklem_speech.title:ja:Canada': 'カナダ銀行マックレム総裁講演',
  'boc_gov_poloz_press_conference.title:ja:DEFAULT': 'カナダ銀行ポロス総裁記者会見',
  'boc_gov_poloz_press_conference.title:ja:Canada': 'カナダ銀行ポロス総裁記者会見',
  'boc_gov_poloz_speaks.title:ja:DEFAULT': 'カナダ銀行ポロス総裁講演',
  'boc_gov_poloz_speaks.title:ja:Canada': 'カナダ銀行ポロス総裁講演',
  'boc_gov_poloz_speech.title:ja:DEFAULT': 'カナダ銀行ポロス総裁講演',
  'boc_gov_poloz_speech.title:ja:Canada': 'カナダ銀行ポロス総裁講演',
  'boc_gravelle_speech.title:ja:DEFAULT': 'カナダ銀行グラベル氏講演',
  'boc_gravelle_speech.title:ja:Canada': 'カナダ銀行グラベル氏講演',
  'boc_interest_rate_decision.title:ja:DEFAULT': 'カナダ銀行金利決定',
  'boc_interest_rate_decision.title:ja:Canada': 'カナダ銀行金利決定',
  'boc_patterson_speech.title:ja:DEFAULT': 'カナダ銀行パターソン氏講演',
  'boc_patterson_speech.title:ja:Canada': 'カナダ銀行パターソン氏講演',
  'boc_review.title:ja:DEFAULT': 'カナダ銀行レビュー',
  'boc_review.title:ja:Canada': 'カナダ銀行レビュー',
  'boc_schembri_speech.title:ja:DEFAULT': 'カナダ銀行シェンブリ氏講演',
  'boc_schembri_speech.title:ja:Canada': 'カナダ銀行シェンブリ氏講演',
  'boc_senior_loan_officer_survey.title:ja:DEFAULT': 'カナダ銀行上級融資担当者調査',
  'boc_senior_loan_officer_survey.title:ja:Canada': 'カナダ銀行上級融資担当者調査',
  'boe_asset_purchase_facility.title:ja:DEFAULT': 'イングランド銀行資産購入枠（APF）',
  'boe_asset_purchase_facility.title:ja:United Kingdom': 'イングランド銀行資産購入枠（APF）',
  'boe_bailey_speech.title:ja:DEFAULT': 'イングランド銀行ベイリー氏講演',
  'boe_bailey_speech.title:ja:United Kingdom': 'イングランド銀行ベイリー氏講演',
  'boe_brazier_speech.title:ja:DEFAULT': 'イングランド銀行ブレイジア氏講演',
  'boe_brazier_speech.title:ja:United Kingdom': 'イングランド銀行ブレイジア氏講演',
  'boe_breeden_speech.title:ja:DEFAULT': 'イングランド銀行ブリーデン氏講演',
  'boe_breeden_speech.title:ja:United Kingdom': 'イングランド銀行ブリーデン氏講演',
  'boe_broadbent_speech.title:ja:DEFAULT': 'イングランド銀行ブロードベント氏講演',
  'boe_broadbent_speech.title:ja:United Kingdom': 'イングランド銀行ブロードベント氏講演',
  'boe_carney_speaks.title:ja:DEFAULT': 'イングランド銀行カーニー氏講演',
  'boe_carney_speaks.title:ja:United Kingdom': 'イングランド銀行カーニー氏講演',
  'boe_carney_speaks_in_parliament_on_brexit.title:ja:DEFAULT':
    'イングランド銀行カーニー氏によるブレグジットについての議会での講演',
  'boe_carney_speaks_in_parliament_on_brexit.title:ja:United Kingdom':
    'イングランド銀行カーニー氏によるブレグジットについての議会での講演',
  'boe_carney_speech.title:ja:DEFAULT': 'イングランド銀行カーニー氏講演',
  'boe_carney_speech.title:ja:United Kingdom': 'イングランド銀行カーニー氏講演',
  'boe_chief_economist_haldane_speech.title:ja:DEFAULT':
    'イングランド銀行チーフエコノミスト、ホールデン氏講演',
  'boe_chief_economist_haldane_speech.title:ja:United Kingdom':
    'イングランド銀行チーフエコノミスト、ホールデン氏講演',
  'boe_cunliffe_speech.title:ja:DEFAULT': 'イングランド銀行カンリフ氏講演',
  'boe_cunliffe_speech.title:ja:United Kingdom': 'イングランド銀行カンリフ氏講演',
  'boe_deputy_gov_broadbent_speech.title:ja:DEFAULT': 'イングランド銀行ブロードベント副総裁講演',
  'boe_deputy_gov_broadbent_speech.title:ja:United Kingdom':
    'イングランド銀行ブロードベント副総裁講演',
  'boe_deputy_gov_cunliffe_speech.title:ja:DEFAULT': 'イングランド銀行カンリフ副総裁講演',
  'boe_deputy_gov_cunliffe_speech.title:ja:United Kingdom': 'イングランド銀行カンリフ副総裁講演',
  'boe_deputy_gov_woods_speech.title:ja:DEFAULT': 'イングランド銀行ウッズ副総裁講演',
  'boe_deputy_gov_woods_speech.title:ja:United Kingdom': 'イングランド銀行ウッズ副総裁講演',
  'boe_fpc_meeting.title:ja:DEFAULT': 'イングランド銀行金融行政委員会（FPC）会合',
  'boe_fpc_meeting.title:ja:United Kingdom': 'イングランド銀行金融行政委員会（FPC）会合',
  'boe_fpc_meeting_minutes.title:ja:DEFAULT': '',
  'boe_fpc_meeting_minutes.title:ja:United Kingdom': '',
  'boe_fpc_record.title:ja:DEFAULT': 'イングランド銀行金融行政委員会（FPC）議事録',
  'boe_fpc_record.title:ja:United Kingdom': 'イングランド銀行金融行政委員会（FPC）議事録',
  'boe_forbes_speech.title:ja:DEFAULT': 'イングランド銀行フォーブス氏講演',
  'boe_forbes_speech.title:ja:United Kingdom': 'イングランド銀行フォーブス氏講演',
  'boe_haskel_speech.title:ja:DEFAULT': 'イングランド銀行ハスケル氏講演',
  'boe_haskel_speech.title:ja:United Kingdom': 'イングランド銀行ハスケル氏講演',
  'boe_hauser_speech.title:ja:DEFAULT': 'イングランド銀行ハウザー氏講演',
  'boe_hauser_speech.title:ja:United Kingdom': 'イングランド銀行ハウザー氏講演',
  'boe_hogg_speech.title:ja:DEFAULT': 'イングランド銀行ホッグ氏講演',
  'boe_hogg_speech.title:ja:United Kingdom': 'イングランド銀行ホッグ氏講演',
  'boe_kohn_speech.title:ja:DEFAULT': 'イングランド銀行コーン氏講演',
  'boe_kohn_speech.title:ja:United Kingdom': 'イングランド銀行コーン氏講演',
  'boe_mpc_vote_hike.title:ja:DEFAULT': 'イングランド銀行金融政策委員会（MPC）利上げ決定',
  'boe_mpc_vote_hike.title:ja:United Kingdom': 'イングランド銀行金融政策委員会（MPC）利上げ決定',
  'boe_mpc_vote_unchanged.title:ja:DEFAULT':
    'イングランド銀行金融政策委員会（MPC）金利変更なし決定',
  'boe_mpc_vote_unchanged.title:ja:United Kingdom':
    'イングランド銀行金融政策委員会（MPC）金利変更なし決定',
  'boe_monetary_policy_report_hearings.title:ja:DEFAULT': '',
  'boe_monetary_policy_report_hearings.title:ja:United Kingdom': '',
  'boe_ramsden_speech.title:ja:DEFAULT': 'イングランド銀行ラムズデン氏講演',
  'boe_ramsden_speech.title:ja:United Kingdom': 'イングランド銀行ラムズデン氏講演',
  'boe_rule_speech.title:ja:DEFAULT': 'イングランド銀行ルール氏講演',
  'boe_rule_speech.title:ja:United Kingdom': 'イングランド銀行ルール氏講演',
  'boe_shafik_speech.title:ja:DEFAULT': 'イングランド銀行シャフィク氏講演',
  'boe_shafik_speech.title:ja:United Kingdom': 'イングランド銀行シャフィク氏講演',
  'boe_systemic_risk_survey_results.title:ja:DEFAULT': 'イングランド銀行システムリスク調査結果',
  'boe_systemic_risk_survey_results.title:ja:United Kingdom':
    'イングランド銀行システムリスク調査結果',
  'boe_tenreyro_speech.title:ja:DEFAULT': 'イングランド銀行テンレイロ氏講演',
  'boe_tenreyro_speech.title:ja:United Kingdom': 'イングランド銀行テンレイロ氏講演',
  'boe_vlieghe_speech.title:ja:DEFAULT': 'イングランド銀行ブリハ氏講演',
  'boe_vlieghe_speech.title:ja:United Kingdom': 'イングランド銀行ブリハ氏講演',
  'boe_weale_speech.title:ja:DEFAULT': 'イングランド銀行ウィール氏講演',
  'boe_weale_speech.title:ja:United Kingdom': 'イングランド銀行ウィール氏講演',
  'boe_s_carney_and_cunliffe_speak_in_parliament.title:ja:DEFAULT':
    'イングランド銀行カーニー氏およびカンリフ氏の議会での説明',
  'boe_s_carney_and_cunliffe_speak_in_parliament.title:ja:United Kingdom':
    'イングランド銀行カーニー氏およびカンリフ氏の議会での説明',
  'boe_s_governor_carney_speaks.title:ja:DEFAULT': 'イングランド銀行カーニー総裁講演',
  'boe_s_governor_carney_speaks.title:ja:United Kingdom': 'イングランド銀行カーニー総裁講演',
  'boe_s_governor_carney_speaks_at_parliament.title:ja:DEFAULT':
    'イングランド銀行カーニー総裁の議会での説明',
  'boe_s_governor_carney_speaks_at_parliament.title:ja:United Kingdom':
    'イングランド銀行カーニー総裁の議会での説明',
  'boe_s_governor_king_speech.title:ja:DEFAULT': 'イングランド銀行キング総裁講演',
  'boe_s_governor_king_speech.title:ja:United Kingdom': 'イングランド銀行キング総裁講演',
  'bof_gov_liikanen_speech.title:ja:DEFAULT': 'フィンランド銀行リーカネン総裁講演',
  'bof_gov_liikanen_speech.title:ja:Finland': 'フィンランド銀行リーカネン総裁講演',
  'bank_of_england_quarterly_inflation_report.title:ja:DEFAULT':
    'イングランド銀行四半期インフレ報告',
  'bank_of_england_quarterly_inflation_report.title:ja:United Kingdom':
    'イングランド銀行四半期インフレ報告',
  'boi_gov_visco_speech.title:ja:DEFAULT': 'イタリア銀行ビスコ総裁講演',
  'boi_gov_visco_speech.title:ja:Italy': 'イタリア銀行ビスコ総裁講演',
  'boi_rossi_speech.title:ja:DEFAULT': 'イタリア銀行ロッシ氏講演',
  'boi_rossi_speech.title:ja:Italy': 'イタリア銀行ロッシ氏講演',
  'boj_adachi_speech.title:ja:DEFAULT': '日本銀行安達氏講演',
  'boj_adachi_speech.title:ja:Japan': '日本銀行安達氏講演',
  'boc_monetary_policy_report.title:ja:DEFAULT': 'カナダ銀行金融政策報告',
  'boc_monetary_policy_report.title:ja:Canada': 'カナダ銀行金融政策報告',
  'boj_deputy_gov_iwata_speech.title:ja:DEFAULT': '日本銀行副総裁岩田氏講演',
  'boj_deputy_gov_iwata_speech.title:ja:Japan': '日本銀行副総裁岩田氏講演',
  'boj_funo_speech.title:ja:DEFAULT': '日本銀行布野氏講演',
  'boj_funo_speech.title:ja:Japan': '日本銀行布野氏講演',
  'boj_gov_kuroda_speaks.title:ja:DEFAULT': '日本銀行黒田総裁講演',
  'boj_gov_kuroda_speaks.title:ja:Japan': '日本銀行黒田総裁講演',
  'boj_harada_speech.title:ja:DEFAULT': '日本銀行原田氏講演',
  'boj_harada_speech.title:ja:Japan': '日本銀行原田氏講演',
  'boj_kataoka_speech.title:ja:DEFAULT': '日本銀行片岡氏講演',
  'boj_kataoka_speech.title:ja:Japan': '日本銀行片岡氏講演',
  'boj_kiuchi_speech.title:ja:DEFAULT': '日本銀行木内氏講演',
  'boj_kiuchi_speech.title:ja:Japan': '日本銀行木内氏講演',
  'boj_kuwabara_speech.title:ja:DEFAULT': '日本銀行桑原氏講演',
  'boj_kuwabara_speech.title:ja:Japan': '日本銀行桑原氏講演',
  'boj_masai_speech.title:ja:DEFAULT': '日本銀行政井氏講演',
  'boj_masai_speech.title:ja:Japan': '日本銀行政井氏講演',
  'boj_monetary_policy_statement_and_press_conference.title:ja:DEFAULT':
    '日銀金融政策声明よび記者会見',
  'boj_monetary_policy_statement_and_press_conference.title:ja:Japan':
    '日銀金融政策声明よび記者会見',
  'boj_nakamura_speech.title:ja:DEFAULT': '日本銀行中村氏講演',
  'boj_nakamura_speech.title:ja:Japan': '日本銀行中村氏講演',
  'boj_nakaso_speech.title:ja:DEFAULT': '日本銀行中曽氏講演',
  'boj_nakaso_speech.title:ja:Japan': '日本銀行中曽氏講演',
  'boj_quarterly_report.title:ja:DEFAULT': '日本銀行四半期短観',
  'boj_quarterly_report.title:ja:Japan': '日本銀行四半期短観',
  'boj_sato_speech.title:ja:DEFAULT': '日本銀行佐藤氏講演',
  'boj_sato_speech.title:ja:Japan': '日本銀行佐藤氏講演',
  'boj_summary_of_opinions.title:ja:DEFAULT': '日本銀行金融政策決定会合における主な意見',
  'boj_summary_of_opinions.title:ja:Japan': '日本銀行金融政策決定会合における主な意見',
  'boj_suzuki_speech.title:ja:DEFAULT': '日本銀行鈴木氏講演',
  'boj_suzuki_speech.title:ja:Japan': '日本銀行鈴木氏講演',
  'baker_hughes_total_rig_count.title:ja:DEFAULT': 'ベーカー・ヒューズ石油・天然ガス掘削リグ数',
  'baker_hughes_total_rig_count.title:ja:United States':
    'ベーカー・ヒューズ石油・天然ガス掘削リグ数',
  'boj_wakatabe_speech.title:ja:DEFAULT': '日本銀行若田部氏講演',
  'boj_wakatabe_speech.title:ja:Japan': '日本銀行若田部氏講演',
  'bok_mp_meeting_minutes.title:ja:DEFAULT': '韓国銀行金融政策会合議事録',
  'bok_mp_meeting_minutes.title:ja:South Korea': '韓国銀行金融政策会合議事録',
  'bonifacio_day.title:ja:DEFAULT': 'ボニファシオ生誕記念日',
  'bonifacio_day.title:ja:Philippines': 'ボニファシオ生誕記念日',
  'boxing_day.title:ja:DEFAULT': 'ボクシング・デー',
  'boxing_day.title:ja:Italy': 'ボクシング・デー',
  'boxing_day.title:ja:Canada': 'ボクシング・デー',
  'boxing_day.title:ja:United Kingdom': 'ボクシング・デー',
  'boxing_day.title:ja:Finland': 'ボクシング・デー',
  'boxing_day.title:ja:New Zealand': 'ボクシング・デー',
  'boxing_day.title:ja:Denmark': 'ボクシング・デー',
  'boxing_day.title:ja:Austria': 'ボクシング・デー',
  'boxing_day.title:ja:Germany': 'ボクシング・デー',
  'boxing_day.title:ja:Sweden': 'ボクシング・デー',
  'boxing_day.title:ja:Greece': 'ボクシング・デー',
  'boxing_day.title:ja:Hong Kong': 'ボクシング・デー',
  'boxing_day.title:ja:Hungary': 'ボクシング・デー',
  'boxing_day.title:ja:Australia': 'ボクシング・デー',
  'boxing_day.title:ja:Netherlands': 'ボクシング・デー',
  'boxing_day.title:ja:Norway': 'ボクシング・デー',
  'boxing_day_substitute_day.title:ja:DEFAULT': 'ボクシング・デー（振替休日）',
  'boxing_day_substitute_day.title:ja:New Zealand': 'ボクシング・デー（振替休日）',
  'boxing_day_substitute_day.title:ja:Canada': 'ボクシング・デー（振替休日）',
  'boxing_day_substitute_day.title:ja:United Kingdom': 'ボクシング・デー（振替休日）',
  'boxing_day_substitute_day.title:ja:Australia': 'ボクシング・デー（振替休日）',
  'brexit_bill_debate.title:ja:DEFAULT': 'ブレグジット法案審議',
  'brexit_bill_debate.title:ja:United Kingdom': 'ブレグジット法案審議',
  'brexit_deadline.title:ja:DEFAULT': 'ブレグジット最終期限',
  'brexit_deadline.title:ja:United Kingdom': 'ブレグジット最終期限',
  'buddhist_lent_day.title:ja:DEFAULT': '入安居（ワン・カオパンサー）',
  'buddhist_lent_day.title:ja:Thailand': '入安居（ワン・カオパンサー）',
  'budget_balance.title:ja:DEFAULT': '政府予算収支',
  'budget_balance.title:ja:Czech Republic': '政府予算収支',
  'budget_balance.title:ja:Portugal': '政府予算収支',
  'budget_balance.title:ja:Israel': '政府予算収支',
  'budget_balance.title:ja:France': '政府予算収支',
  'budget_balance.title:ja:Hungary': '政府予算収支',
  'budget_balance.title:ja:Philippines': '政府予算収支',
  'budget_balance.title:ja:Canada': '政府予算収支',
  'building_permits.title:ja:DEFAULT': '建設許可件数',
  'building_permits.title:ja:United States': '建設許可件数',
  'building_permits_mom.title:ja:DEFAULT': '建設許可（前月比）',
  'building_permits_mom.title:ja:Canada': '建設許可（前月比）',
  'building_permits_mom.title:ja:Australia': '建設許可（前月比）',
  'building_permits_mom.title:ja:United States': '建設許可（前月比）',
  'building_permits_mom.title:ja:New Zealand': '建設許可（前月比）',
  'building_permits_mom_final.title:ja:DEFAULT': '建設許可（前月比）確報値',
  'building_permits_mom_final.title:ja:Australia': '建設許可（前月比）確報値',
  'building_permits_yoy.title:ja:DEFAULT': '建設許可（前年比）',
  'building_permits_yoy.title:ja:Australia': '建設許可（前年比）',
  'building_permits_yoy.title:ja:South Africa': '建設許可（前年比）',
  'building_permits_s_a_mom.title:ja:DEFAULT': '建設許可件数（季節調整済み前月比）',
  'building_permits_s_a_mom.title:ja:New Zealand': '建設許可件数（季節調整済み前月比）',
  'bundesbank_annual_report.title:ja:DEFAULT': 'ドイツ連邦銀行年次報告書',
  'bundesbank_annual_report.title:ja:Germany': 'ドイツ連邦銀行年次報告書',
  'bundesbank_annual_report_2017.title:ja:DEFAULT': '2017年ドイツ連邦銀行年次報告書',
  'bundesbank_annual_report_2017.title:ja:Germany': '2017年ドイツ連邦銀行年次報告書',
  'bundesbank_buch_speech.title:ja:DEFAULT': 'ドイツ連邦銀行ブーフ副総裁講演',
  'bundesbank_buch_speech.title:ja:Germany': 'ドイツ連邦銀行ブーフ副総裁講演',
  'bundesbank_monthly_report.title:ja:DEFAULT': 'ドイツ連邦銀行月次報告書',
  'bundesbank_monthly_report.title:ja:Germany': 'ドイツ連邦銀行月次報告書',
  'bundesbank_president_weidmann_speech.title:ja:DEFAULT': 'ドイツ連邦銀行ワイトマン総裁講演',
  'bundesbank_president_weidmann_speech.title:ja:Germany': 'ドイツ連邦銀行ワイトマン総裁講演',
  'bundesbank_thiele_speech.title:ja:DEFAULT': 'ドイツ連邦銀行ティーレ理事講演',
  'bundesbank_thiele_speech.title:ja:Germany': 'ドイツ連邦銀行ティーレ理事講演',
  '4-week_bill_auction.title:ja:DEFAULT': '4週割引国債入札',
  '4-week_bill_auction.title:ja:United States': '4週割引国債入札',
  'bundesbank_wuermeling_speech.title:ja:DEFAULT': 'ドイツ連邦銀行ヴュルメリング理事講演',
  'bundesbank_wuermeling_speech.title:ja:Germany': 'ドイツ連邦銀行ヴュルメリング理事講演',
  'business_climate_indicator.title:ja:DEFAULT': '企業景況感指数',
  'business_climate_indicator.title:ja:France': '企業景況感指数',
  'business_confidence.title:ja:DEFAULT': '企業信頼感指数',
  'business_confidence.title:ja:Switzerland': '企業信頼感指数',
  'business_confidence.title:ja:United Kingdom': '企業信頼感指数',
  'business_confidence.title:ja:New Zealand': '企業信頼感指数',
  'business_confidence.title:ja:Germany': '企業信頼感指数',
  'business_confidence.title:ja:Thailand': '企業信頼感指数',
  'business_confidence.title:ja:Israel': '企業信頼感指数',
  'business_confidence.title:ja:South Korea': '企業信頼感指数',
  'business_confidence.title:ja:Greece': '企業信頼感指数',
  'business_confidence.title:ja:Hong Kong': '企業信頼感指数',
  'business_confidence.title:ja:Finland': '企業信頼感指数',
  'business_confidence.title:ja:Colombia': '企業信頼感指数',
  'business_confidence.title:ja:Euro Area': '企業信頼感指数',
  'business_confidence.title:ja:South Africa': '企業信頼感指数',
  'business_confidence.title:ja:France': '企業信頼感指数',
  'business_confidence.title:ja:Belgium': '企業信頼感指数',
  'business_confidence.title:ja:Mexico': '企業信頼感指数',
  'business_confidence.title:ja:Norway': '企業信頼感指数',
  'business_confidence.title:ja:Brazil': '企業信頼感指数',
  'business_confidence.title:ja:Austria': '企業信頼感指数',
  'business_confidence.title:ja:Sweden': '企業信頼感指数',
  'business_confidence.title:ja:Netherlands': '企業信頼感指数',
  'business_confidence.title:ja:Italy': '企業信頼感指数',
  'business_confidence.title:ja:Indonesia': '企業信頼感指数',
  'business_confidence.title:ja:Portugal': '企業信頼感指数',
  'business_confidence.title:ja:Singapore': '企業信頼感指数',
  'business_confidence.title:ja:Spain': '企業信頼感指数',
  'business_confidence.title:ja:Denmark': '企業信頼感指数',
  'business_confidence.title:ja:Czech Republic': '企業信頼感指数',
  'business_confidence.title:ja:Russia': '企業信頼感指数',
  'business_inflation_expectations.title:ja:DEFAULT': '企業インフレ予想',
  'business_inflation_expectations.title:ja:New Zealand': '企業インフレ予想',
  'business_inventories_mom.title:ja:DEFAULT': '企業在庫（前月比）',
  'business_inventories_mom.title:ja:United States': '企業在庫（前月比）',
  'baker_hughes_oil_rig_count.title:ja:DEFAULT': 'ベーカー・ヒューズ石油・天然ガス掘削リグ稼働数',
  'baker_hughes_oil_rig_count.title:ja:United States':
    'ベーカー・ヒューズ石油・天然ガス掘削リグ稼働数',
  'business_investment_qoq.title:ja:DEFAULT': '企業投資（前四半期比）',
  'business_investment_qoq.title:ja:United Kingdom': '企業投資（前四半期比）',
  'business_investment_qoq_final.title:ja:DEFAULT': '企業投資（前四半期比）確報値',
  'business_investment_qoq_final.title:ja:United Kingdom': '企業投資（前四半期比）確報値',
  'business_investment_qoq_prel.title:ja:DEFAULT': '企業投資（前四半期比）速報値',
  'business_investment_qoq_prel.title:ja:United Kingdom': '企業投資（前四半期比）速報値',
  'boe_saporta_speech.title:ja:DEFAULT': 'イングランド銀行サポルタ理事講演',
  'boe_saporta_speech.title:ja:United Kingdom': 'イングランド銀行サポルタ理事講演',
  'business_investment_yoy.title:ja:DEFAULT': '企業投資（前年比）',
  'business_investment_yoy.title:ja:United Kingdom': '企業投資（前年比）',
  'business_nz_markit_pmi.title:ja:DEFAULT': 'ビジネスNZ / マークイット購買担当者景気指数（PMI）',
  'business_nz_markit_pmi.title:ja:New Zealand':
    'ビジネスNZ / マークイット購買担当者景気指数（PMI）',
  'business_outlook_survey_indicator.title:ja:DEFAULT': 'カナダ銀行企業見通し調査指標',
  'business_outlook_survey_indicator.title:ja:Canada': 'カナダ銀行企業見通し調査指標',
  'cb_consumer_confidence.title:ja:DEFAULT': 'コンファレンスボード（CB）消費者信頼感指数',
  'cb_consumer_confidence.title:ja:United States': 'コンファレンスボード（CB）消費者信頼感指数',
  'cb_employment_trends_index.title:ja:DEFAULT': 'コンファレンスボード（CB）雇用情勢指数',
  'cb_employment_trends_index.title:ja:United States': 'コンファレンスボード（CB）雇用情勢指数',
  'cb_leading_economic_index.title:ja:DEFAULT': 'コンファレンスボード（CB）景気先行指数',
  'cb_leading_economic_index.title:ja:China': 'コンファレンスボード（CB）景気先行指数',
  'cb_leading_economic_index_mom.title:ja:DEFAULT':
    'コンファレンスボード（CB）景気先行指数（前月比）',
  'cb_leading_economic_index_mom.title:ja:United Kingdom':
    'コンファレンスボード（CB）景気先行指数（前月比）',
  'cbi_business_optimism_index.title:ja:DEFAULT': '英産業連盟（CBI）の製造業楽観指数',
  'cbi_business_optimism_index.title:ja:United Kingdom': '英産業連盟（CBI）の製造業楽観指数',
  'cbr_press_conference.title:ja:DEFAULT': 'ロシア中銀（CBR）記者会見',
  'cbr_press_conference.title:ja:Russia': 'ロシア中銀（CBR）記者会見',
  'cdu_congress.title:ja:DEFAULT': 'ドイツキリスト教民主同盟党（CDU）党大会',
  'cdu_congress.title:ja:Germany': 'ドイツキリスト教民主同盟党（CDU）党大会',
  'chain_stores_wow.title:ja:DEFAULT': '米チェーンストア売上高（前週比）',
  'chain_stores_wow.title:ja:United States': '米チェーンストア売上高（前週比）',
  'cnb_interest_rate_decison.title:ja:DEFAULT': 'チェコ国立銀行（CNB）政策金利決定',
  'cnb_interest_rate_decison.title:ja:Czech Republic': 'チェコ国立銀行（CNB）政策金利決定',
  'cpif_yoy.title:ja:DEFAULT': 'CPIF（金利一定ベース消費者物価指数）（前年比）',
  'cpif_yoy.title:ja:Sweden': 'CPIF（金利一定ベース消費者物価指数）（前年比）',
  'cabinet_meeting_on_brexit_draft_deal.title:ja:DEFAULT': 'ブレグジット合意案に関する閣僚会議',
  'cabinet_meeting_on_brexit_draft_deal.title:ja:United Kingdom':
    'ブレグジット合意案に関する閣僚会議',
  'caixin_manufacturing_pmi.title:ja:DEFAULT': '中国財新製造業購買担当者景気指数（PMI）',
  'caixin_manufacturing_pmi.title:ja:China': '中国財新製造業購買担当者景気指数（PMI）',
  'caixin_manufacturing_pmi_final.title:ja:DEFAULT':
    '中国財新製造業購買担当者景況指数（PMI）（確報値）',
  'caixin_manufacturing_pmi_final.title:ja:China':
    '中国財新製造業購買担当者景況指数（PMI）（確報値）',
  'caixin_manufacturing_pmi_flash.title:ja:DEFAULT':
    '中国財新製造業購買担当者景況指数（PMI）（速報値）',
  'caixin_manufacturing_pmi_flash.title:ja:China':
    '中国財新製造業購買担当者景況指数（PMI）（速報値）',
  'canada_day.title:ja:DEFAULT': 'カナダ建国記念日',
  'canada_day.title:ja:Canada': 'カナダ建国記念日',
  'canada_day_substitute_day.title:ja:DEFAULT': 'カナダ建国記念日（振替休日）',
  'canada_day_substitute_day.title:ja:Canada': 'カナダ建国記念日（振替休日）',
  'canadian_portfolio_investment_in_foreign_securities.title:ja:DEFAULT':
    'カナダの外国証券へのポートフォリオ投資',
  'canadian_portfolio_investment_in_foreign_securities.title:ja:Canada':
    'カナダの外国証券へのポートフォリオ投資',
  'capacity_utilization.title:ja:DEFAULT': '設備稼働率',
  'capacity_utilization.title:ja:United States': '設備稼働率',
  'capacity_utilization.title:ja:Canada': '設備稼働率',
  'capacity_utilization_qoq.title:ja:DEFAULT': '設備稼働率（前四半期比）',
  'capacity_utilization_qoq.title:ja:Sweden': '設備稼働率（前四半期比）',
  'capital_flows.title:ja:DEFAULT': '資本フロー',
  'capital_flows.title:ja:China': '資本フロー',
  'capital_spending_yoy.title:ja:DEFAULT': '資本支出（前年比）',
  'capital_spending_yoy.title:ja:Japan': '資本支出（前年比）',
  'car_production_mom.title:ja:DEFAULT': '自動車生産台数（前月比）',
  'car_production_mom.title:ja:Brazil': '自動車生産台数（前月比）',
  'car_production_yoy.title:ja:DEFAULT': '自動車生産台数（前年比）',
  'car_production_yoy.title:ja:United Kingdom': '自動車生産台数（前年比）',
  'car_registrations.title:ja:DEFAULT': '自動車登録件数',
  'car_registrations.title:ja:South Africa': '自動車登録件数',
  'carnival.title:ja:DEFAULT': '謝肉祭 （カーニバル）',
  'carnival.title:ja:Brazil': '謝肉祭 （カーニバル）',
  'carnival_day.title:ja:DEFAULT': '謝肉祭 （カーニバル）',
  'carnival_day.title:ja:Brazil': '謝肉祭 （カーニバル）',
  'cash_reserve_ratio.title:ja:DEFAULT': '預金準備率',
  'cash_reserve_ratio.title:ja:India': '預金準備率',
  'catalonian_parlimentary_elections.title:ja:DEFAULT': 'カタルーニャ州議会選挙',
  'catalonian_parlimentary_elections.title:ja:Spain': 'カタルーニャ州議会選挙',
  'cement_production_yoy.title:ja:DEFAULT': 'セメント生産量（前年比）',
  'cement_production_yoy.title:ja:Colombia': 'セメント生産量（前年比）',
  'central_bank_fx_reserves.title:ja:DEFAULT': '中央銀行の外貨準備高',
  'central_bank_fx_reserves.title:ja:Poland': '中央銀行の外貨準備高',
  'central_economic_work_conference.title:ja:DEFAULT': '中国中央経済工作会議',
  'central_economic_work_conference.title:ja:China': '中国中央経済工作会議',
  'chain_stores_sales_wow.title:ja:DEFAULT': 'チェーンストア売上高（前週比）',
  'chain_stores_sales_wow.title:ja:United States': 'チェーンストア売上高（前週比）',
  'chakri_day.title:ja:DEFAULT': 'タイのチャクリー王朝記念日',
  'chakri_day.title:ja:Thailand': 'タイのチャクリー王朝記念日',
  'chicago_fed_national_activity_index.title:ja:DEFAULT': 'シカゴ連銀全米活動指数',
  'chicago_fed_national_activity_index.title:ja:United States': 'シカゴ連銀全米活動指数',
  'chicago_purchasing_managers_index.title:ja:DEFAULT': 'シカゴ購買部協会景気指数（PMI）',
  'chicago_purchasing_managers_index.title:ja:United States': 'シカゴ購買部協会景気指数（PMI）',
  'children_s_day.title:ja:DEFAULT': '子どもの日',
  'children_s_day.title:ja:South Korea': '子どもの日',
  'children_s_day.title:ja:Japan': '子どもの日',
  'chinese_new_year.title:ja:DEFAULT': '中国旧正月（春節）',
  'chinese_new_year.title:ja:Singapore': '中国旧正月（春節）',
  'chinese_new_year.title:ja:Hong Kong': '中国旧正月（春節）',
  'chinese_new_year.title:ja:Taiwan': '中国旧正月（春節）',
  'chinese_new_year.title:ja:China': '中国旧正月（春節）',
  'chinese_new_year.title:ja:Indonesia': '中国旧正月（春節）',
  'chinese_new_year.title:ja:Philippines': '中国旧正月（春節）',
  'chinese_new_year_s_eve.title:ja:DEFAULT': '中国旧暦大晦日',
  'chinese_new_year_s_eve.title:ja:Taiwan': '中国旧暦大晦日',
  'ching_ming_festival.title:ja:DEFAULT': '清明節',
  'ching_ming_festival.title:ja:Hong Kong': '清明節',
  'christmas_day.title:ja:DEFAULT': 'クリスマス',
  'christmas_day.title:ja:South Korea': 'クリスマス',
  'christmas_day.title:ja:Singapore': 'クリスマス',
  'christmas_day.title:ja:Hong Kong': 'クリスマス',
  'christmas_day.title:ja:Philippines': 'クリスマス',
  'christmas_day.title:ja:Colombia': 'クリスマス',
  'christmas_day.title:ja:India': 'クリスマス',
  'christmas_day.title:ja:New Zealand': 'クリスマス',
  'christmas_day.title:ja:Australia': 'クリスマス',
  'christmas_day.title:ja:Indonesia': 'クリスマス',
  'christmas_day.title:ja:Canada': 'クリスマス',
  'christmas_day.title:ja:Greece': 'クリスマス',
  'christmas_day.title:ja:United States': 'クリスマス',
  'christmas_day.title:ja:United Kingdom': 'クリスマス',
  'christmas_day.title:ja:Ireland': 'クリスマス',
  'christmas_day.title:ja:Denmark': 'クリスマス',
  'christmas_day.title:ja:Czech Republic': 'クリスマス',
  'christmas_day.title:ja:Spain': 'クリスマス',
  'christmas_day.title:ja:South Africa': 'クリスマス',
  'christmas_day.title:ja:France': 'クリスマス',
  'christmas_day.title:ja:Belgium': 'クリスマス',
  'christmas_day.title:ja:Mexico': 'クリスマス',
  'christmas_day.title:ja:Norway': 'クリスマス',
  'christmas_day.title:ja:Poland': 'クリスマス',
  'christmas_day.title:ja:Germany': 'クリスマス',
  'christmas_day.title:ja:Austria': 'クリスマス',
  'christmas_day.title:ja:Sweden': 'クリスマス',
  'christmas_day.title:ja:Finland': 'クリスマス',
  'christmas_day.title:ja:Brazil': 'クリスマス',
  'christmas_day.title:ja:Hungary': 'クリスマス',
  'christmas_day.title:ja:Italy': 'クリスマス',
  'christmas_day.title:ja:Netherlands': 'クリスマス',
  'christmas_day.title:ja:Portugal': 'クリスマス',
  'christmas_day.title:ja:Switzerland': 'クリスマス',
  'christmas_eve.title:ja:DEFAULT': 'クリスマス・イブ',
  'christmas_eve.title:ja:Switzerland': 'クリスマス・イブ',
  'christmas_eve.title:ja:Austria': 'クリスマス・イブ',
  'christmas_eve.title:ja:Brazil': 'クリスマス・イブ',
  'christmas_eve.title:ja:Sweden': 'クリスマス・イブ',
  'christmas_eve.title:ja:Germany': 'クリスマス・イブ',
  'christmas_eve.title:ja:Denmark': 'クリスマス・イブ',
  'christmas_eve.title:ja:Czech Republic': 'クリスマス・イブ',
  'christmas_eve.title:ja:Finland': 'クリスマス・イブ',
  'christmas_eve.title:ja:Philippines': 'クリスマス・イブ',
  'christmas_eve.title:ja:Hungary': 'クリスマス・イブ',
  'christmas_eve.title:ja:Norway': 'クリスマス・イブ',
  'chung_yeung_day.title:ja:DEFAULT': '重陽の節句',
  'chung_yeung_day.title:ja:Hong Kong': '重陽の節句',
  'chung_yeung_festival.title:ja:DEFAULT': '重陽の節句',
  'chung_yeung_festival.title:ja:Hong Kong': '重陽の節句',
  'chuseok_festivity.title:ja:DEFAULT': '秋夕（チュソク）',
  'chuseok_festivity.title:ja:South Korea': '秋夕（チュソク）',
  'claimant_count_change.title:ja:DEFAULT': '申請者数の変化',
  'claimant_count_change.title:ja:United Kingdom': '申請者数の変化',
  'claimant_count_rate.title:ja:DEFAULT': '失業給付申請率',
  'claimant_count_rate.title:ja:United Kingdom': '失業給付申請率',
  'clean_monday.title:ja:DEFAULT': 'クリーンマンデー',
  'clean_monday.title:ja:Greece': 'クリーンマンデー',
  'coincident_index_-_final.title:ja:DEFAULT': '一致指数 - 確定値',
  'coincident_index_-_final.title:ja:Japan': '一致指数 - 確定値',
  'coincident_index_final.title:ja:DEFAULT': '一致指数 - 確定値',
  'coincident_index_final.title:ja:Japan': '一致指数 - 確定値',
  'coincident_index_prel.title:ja:DEFAULT': '一致指数 - 速報値',
  'coincident_index_prel.title:ja:Japan': '一致指数 - 速報値',
  'columbus_day.title:ja:DEFAULT': 'コロンブス・デー',
  'columbus_day.title:ja:Colombia': 'コロンブス・デー',
  'columbus_day.title:ja:United States': 'コロンブス・デー',
  'coming_of_age_day.title:ja:DEFAULT': '成人の日',
  'coming_of_age_day.title:ja:Japan': '成人の日',
  'boe_sharp_speech.title:ja:DEFAULT': 'イングランド銀行シャープ委員講演',
  'boe_sharp_speech.title:ja:United Kingdom': 'イングランド銀行シャープ委員講演',
  'commbank_composite_pmi_flash.title:ja:DEFAULT':
    '豪コモンウェルス銀行総合購買担当者景気指数（PMI）速報値',
  'commbank_composite_pmi_flash.title:ja:Australia':
    '豪コモンウェルス銀行総合購買担当者景気指数（PMI）速報値',
  'chain_stores_sales_yoy.title:ja:DEFAULT': 'チェーンストア売上高（前年比）',
  'chain_stores_sales_yoy.title:ja:United States': 'チェーンストア売上高（前年比）',
  'commbank_manufacturing_pmi_flash.title:ja:DEFAULT':
    '豪コモンウェルス銀行製造業購買担当者景気指数（PMI）速報値',
  'commbank_manufacturing_pmi_flash.title:ja:Australia':
    '豪コモンウェルス銀行製造業購買担当者景気指数（PMI）速報値',
  'commbank_services_pmi_final.title:ja:DEFAULT':
    '豪コモンウェルス銀行サービス業購買担当者景気指数（PMI）確定値',
  'commbank_services_pmi_final.title:ja:Australia':
    '豪コモンウェルス銀行サービス業購買担当者景気指数（PMI）確定値',
  'commbank_services_pmi_flash.title:ja:DEFAULT':
    '豪コモンウェルス銀行サービス業購買担当者景気指数（PMI）速報値',
  'commbank_services_pmi_flash.title:ja:Australia':
    '豪コモンウェルス銀行サービス業購買担当者景気指数（PMI）速報値',
  'company_gross_operating_profits_qoq.title:ja:DEFAULT': '企業の営業総利益（前四半期比）',
  'company_gross_operating_profits_qoq.title:ja:Australia': '企業の営業総利益（前四半期比）',
  'cfib_business_barometer.title:ja:DEFAULT': '',
  'cfib_business_barometer.title:ja:Canada': '',
  'company_gross_profits_qoq.title:ja:DEFAULT': '企業の売上総利益 （前四半期比）',
  'company_gross_profits_qoq.title:ja:Australia': '企業の売上総利益 （前四半期比）',
  'composite_economic_index.title:ja:DEFAULT': '総合景気指数',
  'composite_economic_index.title:ja:Israel': '総合景気指数',
  'constitution_memorial_day.title:ja:DEFAULT': '憲法記念日',
  'constitution_memorial_day.title:ja:Japan': '憲法記念日',
  'constitutional_referendum.title:ja:DEFAULT': '憲法国民投票',
  'constitutional_referendum.title:ja:Thailand': '憲法国民投票',
  'construction_work_done.title:ja:DEFAULT': '建設工事完工高',
  'construction_work_done.title:ja:Australia': '建設工事完工高',
  'consumer_confidence_final.title:ja:DEFAULT': '消費者信頼感指数（確報値）',
  'consumer_confidence_final.title:ja:Netherlands': '消費者信頼感指数（確報値）',
  'consumer_confidence_final.title:ja:Switzerland': '消費者信頼感指数（確報値）',
  'consumer_confidence_final.title:ja:Euro Area': '消費者信頼感指数（確報値）',
  'consumer_confidence_flash.title:ja:DEFAULT': '消費者信頼感指数（速報値）',
  'consumer_confidence_flash.title:ja:Euro Area': '消費者信頼感指数（速報値）',
  'consumer_confidence_index.title:ja:DEFAULT': '消費者信頼感指数',
  'consumer_confidence_index.title:ja:Mexico': '消費者信頼感指数',
  'consumer_inflation_expectation.title:ja:DEFAULT': '消費者インフレ予想',
  'consumer_inflation_expectation.title:ja:Australia': '消費者インフレ予想',
  'consumer_price_index.title:ja:DEFAULT': '消費者物価指数',
  'consumer_price_index.title:ja:Australia': '消費者物価指数',
  'consumer_price_index.title:ja:United States': '消費者物価指数',
  'cpi_real_earnings_mom.title:ja:DEFAULT': 'CPI実質賃金（前月比）',
  'cpi_real_earnings_mom.title:ja:United States': 'CPI実質賃金（前月比）',
  'consumer_price_index_eu_norm_mom.title:ja:DEFAULT': '消費者物価指数（EU基準）（前月比）',
  'consumer_price_index_eu_norm_mom.title:ja:Italy': '消費者物価指数（EU基準）（前月比）',
  'consumer_price_index_-_core_yoy.title:ja:DEFAULT': 'コア消費者物価指数（前年比）',
  'consumer_price_index_-_core_yoy.title:ja:Euro Area': 'コア消費者物価指数（前年比）',
  'boe_fpc_statement.title:ja:DEFAULT': 'イングランド銀行FPC声明',
  'boe_fpc_statement.title:ja:United Kingdom': 'イングランド銀行FPC声明',
  'consumer_price_index_-_core_mom.title:ja:DEFAULT': 'コア消費者物価指数（前月比）',
  'consumer_price_index_-_core_mom.title:ja:Canada': 'コア消費者物価指数（前月比）',
  'consumer_price_index_ex_food_energy_mom.title:ja:DEFAULT':
    '食品とエネルギーを除く消費者物価指数（前月比）',
  'consumer_price_index_ex_food_energy_mom.title:ja:United States':
    '食品とエネルギーを除く消費者物価指数（前月比）',
  'boc_wilkins_speech.title:ja:DEFAULT': 'カナダ銀行ウィルキンス上級副総裁講演',
  'boc_wilkins_speech.title:ja:Canada': 'カナダ銀行ウィルキンス上級副総裁講演',
  'consumer_spending_mom.title:ja:DEFAULT': '消費支出（前月比）',
  'consumer_spending_mom.title:ja:France': '消費支出（前月比）',
  '2019_us_monetary_policy_forum.title:ja:DEFAULT': '2019年米国金融政策フォーラム',
  '2019_us_monetary_policy_forum.title:ja:United States': '2019年米国金融政策フォーラム',
  'consumer_spending_volume.title:ja:DEFAULT': '消費支出額',
  'consumer_spending_volume.title:ja:Netherlands': '消費支出額',
  'copom_meeting_minutes.title:ja:DEFAULT': 'ブラジル中央銀行金融政策会議議事録',
  'copom_meeting_minutes.title:ja:Brazil': 'ブラジル中央銀行金融政策会議議事録',
  'core_cpi_yoy.title:ja:DEFAULT': 'コア消費者物価指数（前年比）',
  'core_cpi_yoy.title:ja:Canada': 'コア消費者物価指数（前年比）',
  'core_consumer_price_index.title:ja:DEFAULT': 'コア消費者物価指数',
  'core_consumer_price_index.title:ja:United States': 'コア消費者物価指数',
  'core_durable_goods_orders.title:ja:DEFAULT': 'コア耐久財受注',
  'core_durable_goods_orders.title:ja:United States': 'コア耐久財受注',
  'core_durable_goods_orders_mom.title:ja:DEFAULT': 'コア耐久財受注（前月比）',
  'core_durable_goods_orders_mom.title:ja:United States': 'コア耐久財受注（前月比）',
  'cpif_mom.title:ja:DEFAULT': 'CPIF（金利一定ベース消費者物価指数）（前月比）',
  'cpif_mom.title:ja:Sweden': 'CPIF（金利一定ベース消費者物価指数）（前月比）',
  'core_inflation_rate_mom.title:ja:DEFAULT': 'コアインフレ率（前月比）',
  'core_inflation_rate_mom.title:ja:United Kingdom': 'コアインフレ率（前月比）',
  'core_inflation_rate_mom.title:ja:Canada': 'コアインフレ率（前月比）',
  'core_inflation_rate_mom.title:ja:United States': 'コアインフレ率（前月比）',
  'core_inflation_rate_mom.title:ja:Mexico': 'コアインフレ率（前月比）',
  'core_inflation_rate_mom.title:ja:Norway': 'コアインフレ率（前月比）',
  'core_inflation_rate_yoy.title:ja:DEFAULT': 'コアインフレ率（前年比）',
  'core_inflation_rate_yoy.title:ja:Singapore': 'コアインフレ率（前年比）',
  'core_inflation_rate_yoy.title:ja:Indonesia': 'コアインフレ率（前年比）',
  'core_inflation_rate_yoy.title:ja:Mexico': 'コアインフレ率（前年比）',
  'core_inflation_rate_yoy.title:ja:Japan': 'コアインフレ率（前年比）',
  'core_inflation_rate_yoy.title:ja:Ireland': 'コアインフレ率（前年比）',
  'core_inflation_rate_yoy.title:ja:United States': 'コアインフレ率（前年比）',
  'core_inflation_rate_yoy.title:ja:Canada': 'コアインフレ率（前年比）',
  'core_inflation_rate_yoy.title:ja:Philippines': 'コアインフレ率（前年比）',
  'core_inflation_rate_yoy.title:ja:Hungary': 'コアインフレ率（前年比）',
  'core_inflation_rate_yoy.title:ja:Poland': 'コアインフレ率（前年比）',
  'core_inflation_rate_yoy.title:ja:South Africa': 'コアインフレ率（前年比）',
  'core_inflation_rate_yoy.title:ja:Thailand': 'コアインフレ率（前年比）',
  'core_inflation_rate_yoy.title:ja:United Kingdom': 'コアインフレ率（前年比）',
  'core_inflation_rate_yoy.title:ja:Euro Area': 'コアインフレ率（前年比）',
  'core_inflation_rate_yoy.title:ja:Norway': 'コアインフレ率（前年比）',
  'core_inflation_rate_yoy_flash.title:ja:DEFAULT': 'コアインフレ率（前年比）速報値',
  'core_inflation_rate_yoy_flash.title:ja:Euro Area': 'コアインフレ率（前年比）速報値',
  'boe_salmon_speech.title:ja:DEFAULT': 'イングランド銀行サーモン氏講演',
  'boe_salmon_speech.title:ja:United Kingdom': 'イングランド銀行サーモン氏講演',
  'core_inflation_yoy.title:ja:DEFAULT': 'コアインフレ率（前年比）',
  'core_inflation_yoy.title:ja:Euro Area': 'コアインフレ率（前年比）',
  'core_pce_mom.title:ja:DEFAULT': 'コア個人消費支出（PCE）（前月比）',
  'core_pce_mom.title:ja:United States': 'コア個人消費支出（PCE）（前月比）',
  'boc_lane_speech.title:ja:DEFAULT': 'カナダ銀行レーン総裁講演',
  'boc_lane_speech.title:ja:Canada': 'カナダ銀行レーン総裁講演',
  'core_pce_prices_qoq_2_est.title:ja:DEFAULT': 'コアPCE価格（前四半期比）2次速報値',
  'core_pce_prices_qoq_2_est.title:ja:United States': 'コアPCE価格（前四半期比）2次速報値',
  'core_pce_prices_qoq_2nd_est.title:ja:DEFAULT': 'コアPCE価格（前四半期比）2次速報値',
  'core_pce_prices_qoq_2nd_est.title:ja:United States': 'コアPCE価格（前四半期比）2次速報値',
  'core_pce_prices_qoq_final.title:ja:DEFAULT': 'コアPCE価格（前四半期比）確報値',
  'core_pce_prices_qoq_final.title:ja:United States': 'コアPCE価格（前四半期比）確報値',
  'core_pce_yoy.title:ja:DEFAULT': 'コア個人消費支出（PCE）（前月比）',
  'core_pce_yoy.title:ja:United States': 'コア個人消費支出（PCE）（前月比）',
  'core_pce_price_index_mom.title:ja:DEFAULT': 'コアPCE物価指数（前月比）',
  'core_pce_price_index_mom.title:ja:United States': 'コアPCE物価指数（前月比）',
  'core_pce_price_index_yoy.title:ja:DEFAULT': 'コアPCE物価指数（前年比）',
  'core_pce_price_index_yoy.title:ja:United States': 'コアPCE物価指数（前年比）',
  'core_pce_prices_mom.title:ja:DEFAULT': 'コアPCE価格（前月比）',
  'core_pce_prices_mom.title:ja:United States': 'コアPCE価格（前月比）',
  'core_ppi_mom.title:ja:DEFAULT': 'コア生産者物価指数（PPI）（前月比）',
  'core_ppi_mom.title:ja:United States': 'コア生産者物価指数（PPI）（前月比）',
  'boj_press_conference.title:ja:DEFAULT': '日銀記者会見',
  'boj_press_conference.title:ja:Japan': '日銀記者会見',
  'core_ppi_yoy.title:ja:DEFAULT': 'コア生産者物価指数（PPI）（前年比）',
  'core_ppi_yoy.title:ja:United States': 'コア生産者物価指数（PPI）（前年比）',
  'core_personal_cons_exp_qoq.title:ja:DEFAULT': 'コア個人消費支出（前四半期比）',
  'core_personal_cons_exp_qoq.title:ja:United States': 'コア個人消費支出（前四半期比）',
  '6th_european_financial_congress.title:ja:DEFAULT': '第6回欧州金融会議',
  '6th_european_financial_congress.title:ja:Euro Area': '第6回欧州金融会議',
  'core_personal_cons_exp_yoy.title:ja:DEFAULT': 'コア個人消費支出（前年比）',
  'core_personal_cons_exp_yoy.title:ja:United States': 'コア個人消費支出（前年比）',
  'core_personal_consumption_expenditure_-_price_index_mom.title:ja:DEFAULT':
    'コア個人消費支出－価格指数（前月比）',
  'core_personal_consumption_expenditure_-_price_index_mom.title:ja:United States':
    'コア個人消費支出－価格指数（前月比）',
  'core_personal_consumption_expenditure_yoy.title:ja:DEFAULT': 'コア個人消費支出（前年比）',
  'core_personal_consumption_expenditure_yoy.title:ja:United States': 'コア個人消費支出（前年比）',
  'core_personal_consumption_expenditures_qoq.title:ja:DEFAULT': 'コア個人消費支出（前四半期比）',
  'core_personal_consumption_expenditures_qoq.title:ja:United States':
    'コア個人消費支出（前四半期比）',
  'core_rpi_mom.title:ja:DEFAULT': 'コア小売価格指数（RPI）（前月比）',
  'core_rpi_mom.title:ja:United Kingdom': 'コア小売価格指数（RPI）（前月比）',
  'core_rpi_yoy.title:ja:DEFAULT': 'コア小売価格指数（RPI）（前年比）',
  'core_rpi_yoy.title:ja:United Kingdom': 'コア小売価格指数（RPI）（前年比）',
  'bank_of_canada_core_inflation_yoy.title:ja:DEFAULT': 'カナダ銀行コアインフレ率（前年比）',
  'bank_of_canada_core_inflation_yoy.title:ja:Canada': 'カナダ銀行コアインフレ率（前年比）',
  'core_retail_sales_mom.title:ja:DEFAULT': 'コア小売売上高（前月比）',
  'core_retail_sales_mom.title:ja:Canada': 'コア小売売上高（前月比）',
  'core_retail_sales_mom.title:ja:United States': 'コア小売売上高（前月比）',
  'core_retail_sales_yoy.title:ja:DEFAULT': 'コア小売売上高（前年比）',
  'core_retail_sales_yoy.title:ja:United Kingdom': 'コア小売売上高（前年比）',
  'corporate_profits.title:ja:DEFAULT': '企業利益',
  'corporate_profits.title:ja:Russia': '企業利益',
  'corporate_profits_qoq_final.title:ja:DEFAULT': '企業利益（前四半期比）確報値',
  'corporate_profits_qoq_final.title:ja:United States': '企業利益（前四半期比）確報値',
  'corporate_profits_qoq_prel.title:ja:DEFAULT': '企業利益（前四半期比）速報値',
  'corporate_profits_qoq_prel.title:ja:United States': '企業利益（前四半期比）速報値',
  'corporate_sector_wages_growth.title:ja:DEFAULT': '企業部門賃金上昇率',
  'corporate_sector_wages_growth.title:ja:Poland': '企業部門賃金上昇率',
  'corporate_sector_wages_yoy.title:ja:DEFAULT': '企業部門賃金（前年比）',
  'corporate_sector_wages_yoy.title:ja:Poland': '企業部門賃金（前年比）',
  'corporate_service_price_yoy.title:ja:DEFAULT': '企業向けサービス価格指数（前年比）',
  'corporate_service_price_yoy.title:ja:Japan': '企業向けサービス価格指数（前年比）',
  'corporative_sector_wages_growth.title:ja:DEFAULT': '企業部門賃金上昇率',
  'corporative_sector_wages_growth.title:ja:Poland': '企業部門賃金上昇率',
  'corpus_christi.title:ja:DEFAULT': '聖体の祝日',
  'corpus_christi.title:ja:Colombia': '聖体の祝日',
  'corpus_christi.title:ja:Austria': '聖体の祝日',
  'corpus_christi.title:ja:Brazil': '聖体の祝日',
  'corpus_christi.title:ja:Germany': '聖体の祝日',
  'corpus_christi.title:ja:Poland': '聖体の祝日',
  'corpus_christi.title:ja:Portugal': '聖体の祝日',
  'corpus_christi_day.title:ja:DEFAULT': '聖体の祝日',
  'corpus_christi_day.title:ja:Colombia': '聖体の祝日',
  'corpus_christi_day.title:ja:Brazil': '聖体の祝日',
  'credit_card_spending_yoy.title:ja:DEFAULT': 'クレジットカード支出（前年比）',
  'credit_card_spending_yoy.title:ja:New Zealand': 'クレジットカード支出（前年比）',
  'credit_conditions_survey.title:ja:DEFAULT': '信用状況調査',
  'credit_conditions_survey.title:ja:United Kingdom': '信用状況調査',
  'currency_reserves.title:ja:DEFAULT': '外貨準備高',
  'currency_reserves.title:ja:Denmark': '外貨準備高',
  'current_account_prel.title:ja:DEFAULT': '経常収支（速報値）',
  'current_account_prel.title:ja:Hong Kong': '経常収支（速報値）',
  'current_account_to_gdp.title:ja:DEFAULT': '経常収支対GDP',
  'current_account_to_gdp.title:ja:Mexico': '経常収支対GDP',
  'czech_statehood_day.title:ja:DEFAULT': 'チェコ国家の日',
  'czech_statehood_day.title:ja:Czech Republic': 'チェコ国家の日',
  'dilf_manufacturing_pmi.title:ja:DEFAULT': 'DILF製造業購買担当者景気指数（PMI）',
  'dilf_manufacturing_pmi.title:ja:Denmark': 'DILF製造業購買担当者景気指数（PMI）',
  'dnb_manufacturing_pmi.title:ja:DEFAULT': 'DNB製造業購買担当者景気指数（PMI）',
  'dnb_manufacturing_pmi.title:ja:Norway': 'DNB製造業購買担当者景気指数（PMI）',
  'dallas_fed_manufacturing_index.title:ja:DEFAULT': 'ダラス連銀製造業景気指数',
  'dallas_fed_manufacturing_index.title:ja:United States': 'ダラス連銀製造業景気指数',
  'danmarks_nationalbank_press_conference.title:ja:DEFAULT': 'デンマーク国立銀行記者会見',
  'danmarks_nationalbank_press_conference.title:ja:Denmark': 'デンマーク国立銀行記者会見',
  'dasera.title:ja:DEFAULT': 'ダセラ',
  'dasera.title:ja:India': 'ダセラ',
  'day_after_new_year_s_day.title:ja:DEFAULT': '元旦の翌日',
  'day_after_new_year_s_day.title:ja:New Zealand': '元旦の翌日',
  'day_following_chung_yeung_festival.title:ja:DEFAULT': '重陽の節句の翌日',
  'day_following_chung_yeung_festival.title:ja:Hong Kong': '重陽の節句の翌日',
  'day_following_the_chinese_mid-autumn_festival.title:ja:DEFAULT': '中国の中秋節の翌日',
  'day_following_the_chinese_mid-autumn_festival.title:ja:Hong Kong': '中国の中秋節の翌日',
  'day_after_mid-autumn_festival.title:ja:DEFAULT': '中秋節の翌日',
  'day_after_mid-autumn_festival.title:ja:Hong Kong': '中秋節の翌日',
  'construction_orders_yoy.title:ja:DEFAULT': '建設受注（前年比）',
  'construction_orders_yoy.title:ja:United Kingdom': '建設受注（前年比）',
  'construction_orders_yoy.title:ja:Japan': '建設受注（前年比）',
  'day_of_atonement.title:ja:DEFAULT': '贖罪の日',
  'day_of_atonement.title:ja:Israel': '贖罪の日',
  'day_of_reconciliation.title:ja:DEFAULT': '和解の日',
  'day_of_reconciliation.title:ja:South Africa': '和解の日',
  'day_of_valor.title:ja:DEFAULT': '勇者の日',
  'day_of_valor.title:ja:Philippines': '勇者の日',
  'article_50_brexit_process_starts.title:ja:DEFAULT': 'EU条約第50条のブレグジットプロセス開始',
  'article_50_brexit_process_starts.title:ja:United Kingdom':
    'EU条約第50条のブレグジットプロセス開始',
  'day_of_the_virgin_of_guadalupe.title:ja:DEFAULT': 'グアダルーペの聖母の祝日',
  'day_of_the_virgin_of_guadalupe.title:ja:Mexico': 'グアダルーペの聖母の祝日',
  'deadline_for_greece_to_enact_reforms.title:ja:DEFAULT': 'ギリシャが改革を実施する期限',
  'deadline_for_greece_to_enact_reforms.title:ja:Euro Area': 'ギリシャが改革を実施する期限',
  'deadline_on_detailed_proposal_on_economic_reforms_from_greece.title:ja:DEFAULT':
    'ギリシャからの経済改革に関する詳細な提案の期限',
  'deadline_on_detailed_proposal_on_economic_reforms_from_greece.title:ja:Euro Area':
    'ギリシャからの経済改革に関する詳細な提案の期限',
  'debt_repayment_of_eur_3_5b_due_to_ecb.title:ja:DEFAULT': 'ECBからの借入金35億ユーロの返済',
  'debt_repayment_of_eur_3_5b_due_to_ecb.title:ja:Greece': 'ECBからの借入金35億ユーロの返済',
  'business_investment_yoy_prel.title:ja:DEFAULT': '企業投資（前年比）速報値',
  'business_investment_yoy_prel.title:ja:United Kingdom': '企業投資（前年比）速報値',
  'deepavali.title:ja:DEFAULT': 'ディーワーリー',
  'deepavali.title:ja:Singapore': 'ディーワーリー',
  'defenders_of_the_fatherland_day.title:ja:DEFAULT': '祖国防衛の日',
  'defenders_of_the_fatherland_day.title:ja:Russia': '祖国防衛の日',
  'defense_of_the_fatherland_day.title:ja:DEFAULT': '祖国防衛の日',
  'defense_of_the_fatherland_day.title:ja:Russia': '祖国防衛の日',
  'boe_moulder_speech.title:ja:DEFAULT': 'イングランド銀行モルダー氏講演',
  'boe_moulder_speech.title:ja:United Kingdom': 'イングランド銀行モルダー氏講演',
  'deposit_facility_rate.title:ja:DEFAULT': '中銀預金金利',
  'deposit_facility_rate.title:ja:Euro Area': '中銀預金金利',
  'deposit_facility_rate.title:ja:Indonesia': '中銀預金金利',
  'deposit_facilty_rate.title:ja:DEFAULT': '中銀預金金利',
  'deposit_facilty_rate.title:ja:Euro Area': '中銀預金金利',
  'diwali.title:ja:DEFAULT': 'ディーワーリー',
  'diwali.title:ja:Singapore': 'ディーワーリー',
  'diwali_balipratipada.title:ja:DEFAULT': 'ディーワーリー（バリプラティパダ）',
  'diwali_balipratipada.title:ja:India': 'ディーワーリー（バリプラティパダ）',
  'diwali-laxmi_pujan.title:ja:DEFAULT': 'ディーワーリー（ラクシュミー・プジャ）',
  'diwali-laxmi_pujan.title:ja:India': 'ディーワーリー（ラクシュミー・プジャ）',
  'domestic_corporate_goods_price_index_yoy.title:ja:DEFAULT': '国内企業物価指数（前年比）',
  'domestic_corporate_goods_price_index_yoy.title:ja:Japan': '国内企業物価指数（前年比）',
  'domestic_corporate_goods_price_index_mom.title:ja:DEFAULT': '国内企業物価指数（前月比）',
  'domestic_corporate_goods_price_index_mom.title:ja:Japan': '国内企業物価指数（前月比）',
  'dormition_of_the_holy_virgin.title:ja:DEFAULT': '生神女就寝祭',
  'dormition_of_the_holy_virgin.title:ja:Greece': '生神女就寝祭',
  'boe_financial_stability_report.title:ja:DEFAULT': 'イングランド銀行金融安定性報告書',
  'boe_financial_stability_report.title:ja:United Kingdom': 'イングランド銀行金融安定性報告書',
  'dr_baba_saheb_ambedkar_jayanti.title:ja:DEFAULT': 'アンベードカル博士生誕祭',
  'dr_baba_saheb_ambedkar_jayanti.title:ja:India': 'アンベードカル博士生誕祭',
  'christmas_day_substitute_day.title:ja:DEFAULT': 'クリスマス・デー（振替休日）',
  'christmas_day_substitute_day.title:ja:United Kingdom': 'クリスマス・デー（振替休日）',
  'christmas_day_substitute_day.title:ja:United States': 'クリスマス・デー（振替休日）',
  'dragon_boat_festival.title:ja:DEFAULT': '龍船節',
  'dragon_boat_festival.title:ja:Taiwan': '龍船節',
  'dragon_boat_festival.title:ja:Hong Kong': '龍船節',
  'dragon_boat_festival.title:ja:China': '龍船節',
  'duanwu_festival.title:ja:DEFAULT': '龍船節',
  'duanwu_festival.title:ja:Hong Kong': '龍船節',
  'duanwu_festival.title:ja:China': '龍船節',
  'duanwu_festival.title:ja:Taiwan': '龍船節',
  'durable_goods_orders_ex_defense_mom.title:ja:DEFAULT': '耐久財受注、防衛費除く（前月比）',
  'durable_goods_orders_ex_defense_mom.title:ja:United States': '耐久財受注、防衛費除く（前月比）',
  'durable_goods_orders_mom.title:ja:DEFAULT': '耐久財受注（前月比）',
  'durable_goods_orders_mom.title:ja:United States': '耐久財受注（前月比）',
  'durable_goods_orders_ex_transp_mom.title:ja:DEFAULT': '耐久財受注、輸送除く（前月比）',
  'durable_goods_orders_ex_transp_mom.title:ja:United States': '耐久財受注、輸送除く（前月比）',
  'dussehra.title:ja:DEFAULT': 'ダシェラ祭',
  'dussehra.title:ja:India': 'ダシェラ祭',
  '5-year_note_auction.title:ja:DEFAULT': '5年国債入札',
  '5-year_note_auction.title:ja:United States': '5年国債入札',
  'ebrd_annual_meeting.title:ja:DEFAULT': 'EBRD年次総会',
  'ebrd_annual_meeting.title:ja:Euro Area': 'EBRD年次総会',
  'ec-ecb_joint_conference.title:ja:DEFAULT': '欧州委員会（EC）欧州中央銀行（ECB）共同会議',
  'ec-ecb_joint_conference.title:ja:Euro Area': '欧州委員会（EC）欧州中央銀行（ECB）共同会議',
  'ecb_2015_annual_report.title:ja:DEFAULT': 'ECB2015年年次報告書',
  'ecb_2015_annual_report.title:ja:Euro Area': 'ECB2015年年次報告書',
  'ecb_angeloni_speech.title:ja:DEFAULT': 'ECBアンジェローニ氏講演',
  'ecb_angeloni_speech.title:ja:Euro Area': 'ECBアンジェローニ氏講演',
  'ecb_annual_report.title:ja:DEFAULT': 'ECB年次報告書',
  'ecb_annual_report.title:ja:Euro Area': 'ECB年次報告書',
  'ecb_bank_lending_survey.title:ja:DEFAULT': 'ECB銀行貸出調査',
  'ecb_bank_lending_survey.title:ja:Euro Area': 'ECB銀行貸出調査',
  'ecb_coeur_speech.title:ja:DEFAULT': 'ECBクーレ氏講演',
  'ecb_coeur_speech.title:ja:Euro Area': 'ECBクーレ氏講演',
  'ecb_dickson_speech.title:ja:DEFAULT': 'ECBディクソン氏講演',
  'ecb_dickson_speech.title:ja:Euro Area': 'ECBディクソン氏講演',
  'anz_job_advertisment_mom.title:ja:DEFAULT': 'ANZ求人広告件数（前月比）',
  'anz_job_advertisment_mom.title:ja:Australia': 'ANZ求人広告件数（前月比）',
  'ecb_diemer_speech.title:ja:DEFAULT': 'ECBディーマ―氏講演',
  'ecb_diemer_speech.title:ja:Euro Area': 'ECBディーマ―氏講演',
  'ecb_draghi_speech.title:ja:DEFAULT': 'ECBドラギ氏講演',
  'ecb_draghi_speech.title:ja:Euro Area': 'ECBドラギ氏講演',
  'ecb_enria_speech.title:ja:DEFAULT': 'ECBエンリア氏講演',
  'ecb_enria_speech.title:ja:Euro Area': 'ECBエンリア氏講演',
  'ecb_financial_stability_review.title:ja:DEFAULT': 'ECB金融安定性レビュー',
  'ecb_financial_stability_review.title:ja:Euro Area': 'ECB金融安定性レビュー',
  'ecb_financial_statement_publication.title:ja:DEFAULT': 'ECB財務諸表発行',
  'ecb_financial_statement_publication.title:ja:Euro Area': 'ECB財務諸表発行',
  '7-year_btp_auction.title:ja:DEFAULT': 'BTP7年固債入札',
  '7-year_btp_auction.title:ja:Italy': 'BTP7年固債入札',
  'ecb_financial_statements.title:ja:DEFAULT': '',
  'ecb_financial_statements.title:ja:Euro Area': '',
  'ecb_forum_on_central_banking.title:ja:DEFAULT': 'ECB中央銀行フォーラム',
  'ecb_forum_on_central_banking.title:ja:Euro Area': 'ECB中央銀行フォーラム',
  'ecb_forum_on_central_banking.title:ja:Portugal': 'ECB中央銀行フォーラム',
  'ecb_galhau_speech.title:ja:DEFAULT': 'ECBガルハウ氏講演',
  'ecb_galhau_speech.title:ja:Euro Area': 'ECBガルハウ氏講演',
  'ecb_governing_council_meeting.title:ja:DEFAULT': 'ECB政策理事会会合',
  'ecb_governing_council_meeting.title:ja:Euro Area': 'ECB政策理事会会合',
  '67-day_bill_auction.title:ja:DEFAULT': '',
  '67-day_bill_auction.title:ja:United States': '',
  'ecb_guindos_speech.title:ja:DEFAULT': 'ECBデ・ギンドス氏講演',
  'ecb_guindos_speech.title:ja:Euro Area': 'ECBデ・ギンドス氏講演',
  'ecb_interest_rate_decision.title:ja:DEFAULT': 'ECB政策金利決定',
  'ecb_interest_rate_decision.title:ja:Euro Area': 'ECB政策金利決定',
  'ecb_lagarde_speech.title:ja:DEFAULT': 'ECBラガルド氏講演',
  'ecb_lagarde_speech.title:ja:Euro Area': 'ECBラガルド氏講演',
  'ecb_lane_speech.title:ja:DEFAULT': 'ECBレーン氏講演',
  'ecb_lane_speech.title:ja:Euro Area': 'ECBレーン氏講演',
  'ecb_liikanen_speech.title:ja:DEFAULT': 'ECBリーカネン氏講演',
  'ecb_liikanen_speech.title:ja:Euro Area': 'ECBリーカネン氏講演',
  'ecb_mccaul_speech.title:ja:DEFAULT': 'ECBマコール氏講演',
  'ecb_mccaul_speech.title:ja:Euro Area': 'ECBマコール氏講演',
  'ecb_meeting_minutes.title:ja:DEFAULT': 'ECB会合議事録',
  'ecb_meeting_minutes.title:ja:Euro Area': 'ECB会合議事録',
  'ecb_mersch_speech.title:ja:DEFAULT': 'ECBメルシュ氏講演',
  'ecb_mersch_speech.title:ja:Euro Area': 'ECBメルシュ氏講演',
  'ecb_monetary_policy_statement_and_press_conference.title:ja:DEFAULT':
    'ECB金融政策声明および記者会見',
  'ecb_monetary_policy_statement_and_press_conference.title:ja:Euro Area':
    'ECB金融政策声明および記者会見',
  'deadline_for_funding_for_new_fiscal_year.title:ja:DEFAULT': '新年度予算期日',
  'deadline_for_funding_for_new_fiscal_year.title:ja:United States': '新年度予算期日',
  'ecb_non-monetary_policy_meeting.title:ja:DEFAULT': 'ECB非金融政策会合',
  'ecb_non-monetary_policy_meeting.title:ja:Euro Area': 'ECB非金融政策会合',
  'ecb_nowotny_speech.title:ja:DEFAULT': 'ECBノボトニー氏講演',
  'ecb_nowotny_speech.title:ja:Euro Area': 'ECBノボトニー氏講演',
  'credit_indicator.title:ja:DEFAULT': '与信指数',
  'credit_indicator.title:ja:Norway': '与信指数',
  'ecb_panetta_speech.title:ja:DEFAULT': 'ECBパネッタ氏講演',
  'ecb_panetta_speech.title:ja:Euro Area': 'ECBパネッタ氏講演',
  'ecb_president_draghi_speech.title:ja:DEFAULT': 'ECBドラギ総裁講演',
  'ecb_president_draghi_speech.title:ja:Euro Area': 'ECBドラギ総裁講演',
  'ecb_president_draghi_s_speech.title:ja:DEFAULT': 'ECBドラギ総裁講演',
  'ecb_president_draghi_s_speech.title:ja:Euro Area': 'ECBドラギ総裁講演',
  'ecb_press_conference.title:ja:DEFAULT': 'ECB記者会見',
  'ecb_press_conference.title:ja:Euro Area': 'ECB記者会見',
  'coronation_day.title:ja:DEFAULT': '国王戴冠日',
  'coronation_day.title:ja:Thailand': '国王戴冠日',
  'ecb_quantitative_easing.title:ja:DEFAULT': 'ECB量的緩和',
  'ecb_quantitative_easing.title:ja:Euro Area': 'ECB量的緩和',
  'cbi_gov_lane_speech.title:ja:DEFAULT': 'アイルランド中央銀行レーン総裁講演',
  'cbi_gov_lane_speech.title:ja:Ireland': 'アイルランド中央銀行レーン総裁講演',
  'ecb_ssm_annual_report.title:ja:DEFAULT': 'ECB SSM年次報告書',
  'ecb_ssm_annual_report.title:ja:Euro Area': 'ECB SSM年次報告書',
  'ecb_schnabel_speech.title:ja:DEFAULT': 'ECBシュナーベル氏講演',
  'ecb_schnabel_speech.title:ja:Euro Area': 'ECBシュナーベル氏講演',
  'ecb_weidmann_speech.title:ja:DEFAULT': 'ECBワイトマン氏講演',
  'ecb_weidmann_speech.title:ja:Euro Area': 'ECBワイトマン氏講演',
  'bank_of_canada_business_outlook_survey.title:ja:DEFAULT': 'カナダ銀行企業見通し調査',
  'bank_of_canada_business_outlook_survey.title:ja:Canada': 'カナダ銀行企業見通し調査',
  'edsa_revolution_anniversary.title:ja:DEFAULT': 'エドゥサ革命記念日',
  'edsa_revolution_anniversary.title:ja:Philippines': 'エドゥサ革命記念日',
  'eia_crude_oil_imports.title:ja:DEFAULT': 'EIA原油輸入量',
  'eia_crude_oil_imports.title:ja:United States': 'EIA原油輸入量',
  'eia_crude_oil_imports_change.title:ja:DEFAULT': 'EIA原油輸入量変化',
  'eia_crude_oil_imports_change.title:ja:United States': 'EIA原油輸入量変化',
  'eia_crude_oil_stocks_change.title:ja:DEFAULT': 'EIA原油在庫量変化',
  'eia_crude_oil_stocks_change.title:ja:United States': 'EIA原油在庫量変化',
  'eia_cushing_crude_oil_stocks_change.title:ja:DEFAULT': 'EIAクッシング原油在庫量変化',
  'eia_cushing_crude_oil_stocks_change.title:ja:United States': 'EIAクッシング原油在庫量変化',
  'eia_distillate_fuel_production_change.title:ja:DEFAULT': 'EIA留出油生産量変化',
  'eia_distillate_fuel_production_change.title:ja:United States': 'EIA留出油生産量変化',
  'eia_distillate_stocks_change.title:ja:DEFAULT': 'EIA留出油燃料在庫量変化',
  'eia_distillate_stocks_change.title:ja:United States': 'EIA留出油燃料在庫量変化',
  'eia_gasoline_production.title:ja:DEFAULT': 'EIAガソリン生産量変化',
  'eia_gasoline_production.title:ja:United States': 'EIAガソリン生産量変化',
  'eia_gasoline_production_change.title:ja:DEFAULT': 'EIAガソリン生産量変化',
  'eia_gasoline_production_change.title:ja:United States': 'EIAガソリン生産量変化',
  'eia_gasoline_stocks_change.title:ja:DEFAULT': 'EIAガソリン在庫量変化',
  'eia_gasoline_stocks_change.title:ja:United States': 'EIAガソリン在庫量変化',
  'eia_natural_gas_stocks_change.title:ja:DEFAULT': 'EIA天然ガス在庫量変化',
  'eia_natural_gas_stocks_change.title:ja:United States': 'EIA天然ガス在庫量変化',
  'esri_consumer_sentiment_index.title:ja:DEFAULT': 'ESRI消費者信頼感指数',
  'esri_consumer_sentiment_index.title:ja:Ireland': 'ESRI消費者信頼感指数',
  'eu_barnier_speech.title:ja:DEFAULT': 'EUバルニエ氏講演',
  'eu_barnier_speech.title:ja:European Union': 'EUバルニエ氏講演',
  'eu_commission_decision_on_budget_drafts.title:ja:DEFAULT': '欧州委員会による予算案決定',
  'eu_commission_decision_on_budget_drafts.title:ja:Euro Area': '欧州委員会による予算案決定',
  'eu_donald_tusk_speech.title:ja:DEFAULT': 'EUドナルド・トゥスク氏講演',
  'eu_donald_tusk_speech.title:ja:European Union': 'EUドナルド・トゥスク氏講演',
  'eu_juncker_speech.title:ja:DEFAULT': 'EUユンケル氏講演',
  'eu_juncker_speech.title:ja:Euro Area': 'EUユンケル氏講演',
  'eu_meeting_on_common_recovery_plan.title:ja:DEFAULT': '共通の復興計画に関するEU会合',
  'eu_meeting_on_common_recovery_plan.title:ja:European Union': '共通の復興計画に関するEU会合',
  'eu_membership_referendum_-_final_results.title:ja:DEFAULT':
    '欧州連合の離脱是非を問う国民投票（最終結果）',
  'eu_membership_referendum_-_final_results.title:ja:United Kingdom':
    '欧州連合の離脱是非を問う国民投票（最終結果）',
  'eu-canada_summit.title:ja:DEFAULT': 'EUカナダ首脳会議',
  'eu-canada_summit.title:ja:European Union': 'EUカナダ首脳会議',
  'nbh_nagy_speech.title:ja:DEFAULT': 'NBH（ハンガリー中銀）ナジ氏講演',
  'nbh_nagy_speech.title:ja:Hungary': 'NBH（ハンガリー中銀）ナジ氏講演',
  'nbs_manufacturing_pmi.title:ja:DEFAULT': 'NBS製造業購買担当者景気指数（PMI）',
  'nbs_manufacturing_pmi.title:ja:China': 'NBS製造業購買担当者景気指数（PMI）',
  'vernal_equinox_day.title:ja:DEFAULT': '春分の日',
  'vernal_equinox_day.title:ja:Japan': '春分の日',
  'boj_monetary_policy_meeting_minutes.title:ja:DEFAULT': '日本銀行金融政策会合議事録',
  'boj_monetary_policy_meeting_minutes.title:ja:Japan': '日本銀行金融政策会合議事録',
  'm4_money_supply_mom.title:ja:DEFAULT': 'M4マネーサプライ（前月比）',
  'm4_money_supply_mom.title:ja:United Kingdom': 'M4マネーサプライ（前月比）',
  '10-year_treasury_gilt_auction.title:ja:DEFAULT': '英国10年国債入札',
  '10-year_treasury_gilt_auction.title:ja:United Kingdom': '英国10年国債入札',
  'oecd_gurria_speech_on_uk_referendum.title:ja:DEFAULT': 'OECDグリア氏の英国国民投票に関する講演',
  'oecd_gurria_speech_on_uk_referendum.title:ja:United Kingdom':
    'OECDグリア氏の英国国民投票に関する講演',
  'boe_fpc_minutes.title:ja:DEFAULT': 'イングランド銀行金融行政委員会（FPC）議事録',
  'boe_fpc_minutes.title:ja:United Kingdom': 'イングランド銀行金融行政委員会（FPC）議事録',
  'nevi_manufacturing_pmi.title:ja:DEFAULT': 'NEVI製造業購買担当者景気指数（PMI）',
  'nevi_manufacturing_pmi.title:ja:Netherlands': 'NEVI製造業購買担当者景気指数（PMI）',
  'pmi_manufacturing.title:ja:DEFAULT': '製造業購買担当者景気指数（PMI）',
  'pmi_manufacturing.title:ja:Austria': '製造業購買担当者景気指数（PMI）',
  'boe_proudman_speech.title:ja:DEFAULT': 'イングランド銀行プラウドマン氏講演',
  'boe_proudman_speech.title:ja:United Kingdom': 'イングランド銀行プラウドマン氏講演',
  'federal_election.title:ja:DEFAULT': '連邦選挙',
  'federal_election.title:ja:Canada': '連邦選挙',
  'federal_election.title:ja:Germany': '連邦選挙',
  'federal_election.title:ja:Australia': '連邦選挙',
  'vice_presidential_debate.title:ja:DEFAULT': '副大統領候補討論会',
  'vice_presidential_debate.title:ja:United States': '副大統領候補討論会',
  'liberation_day.title:ja:DEFAULT': '光復節',
  'liberation_day.title:ja:South Korea': '光復節',
  'liberation_day.title:ja:Netherlands': '光復節',
  'liberation_day.title:ja:Italy': '光復節',
  'eu_meeting_on_iran.title:ja:DEFAULT': 'イランに関するEU会合',
  'eu_meeting_on_iran.title:ja:Euro Area': 'イランに関するEU会合',
  'nima_manufacturing_pmi.title:ja:DEFAULT': 'NIMA製造業購買担当者景気指数（PMI）',
  'nima_manufacturing_pmi.title:ja:Norway': 'NIMA製造業購買担当者景気指数（PMI）',
  'tertiary_industry_index_mom.title:ja:DEFAULT': '第3次産業指数（前月比）',
  'tertiary_industry_index_mom.title:ja:Japan': '第3次産業指数（前月比）',
  'npc_committee_meeting.title:ja:DEFAULT': '全国人民代表大会委員会会合',
  'npc_committee_meeting.title:ja:China': '全国人民代表大会委員会会合',
  'ny_fed_treasury_purchases_0_to_2_25_yrs.title:ja:DEFAULT':
    'ニューヨーク連銀国債購入０～2.25年物',
  'ny_fed_treasury_purchases_0_to_2_25_yrs.title:ja:United States':
    'ニューヨーク連銀国債購入０～2.25年物',
  'presidential_debate.title:ja:DEFAULT': '大統領候補討論会',
  'presidential_debate.title:ja:United States': '大統領候補討論会',
  'the_constitutional_war_of_1932.title:ja:DEFAULT': '1932年護憲革命記念日',
  'the_constitutional_war_of_1932.title:ja:Brazil': '1932年護憲革命記念日',
  'markit_cips_composite_pmi_flash.title:ja:DEFAULT':
    'マークイット/CIPS総合購買担当者景況指数（PMI)（速報値）',
  'markit_cips_composite_pmi_flash.title:ja:United Kingdom':
    'マークイット/CIPS総合購買担当者景況指数（PMI)（速報値）',
  'ny_fed_treasury_purchases_22_5_to_30_yrs.title:ja:DEFAULT':
    'ニューヨーク連銀国債購入22.5～30年物',
  'ny_fed_treasury_purchases_22_5_to_30_yrs.title:ja:United States':
    'ニューヨーク連銀国債購入22.5～30年物',
  'ny_fed_treasury_purchases_tips_1_to_7_5_yrs.title:ja:DEFAULT':
    'ニューヨーク連銀国債購入TIPS1～7.5年物',
  'ny_fed_treasury_purchases_tips_1_to_7_5_yrs.title:ja:United States':
    'ニューヨーク連銀国債購入TIPS1～7.5年物',
  'nzier_business_confidence.title:ja:DEFAULT': 'NZIER企業信頼感指数',
  'nzier_business_confidence.title:ja:New Zealand': 'NZIER企業信頼感指数',
  'ubs_consumption_indicator.title:ja:DEFAULT': 'UBS消費指数',
  'ubs_consumption_indicator.title:ja:Switzerland': 'UBS消費指数',
  'nzier_qsbo_capacity_utilization.title:ja:DEFAULT': 'NZIER QSBO設備稼働率',
  'nzier_qsbo_capacity_utilization.title:ja:New Zealand': 'NZIER QSBO設備稼働率',
  'ny_fed_treasury_purchases_tips_7_5_to_30_yrs.title:ja:DEFAULT':
    'ニューヨーク連銀国債購入TIPS 7.5～30年物',
  'ny_fed_treasury_purchases_tips_7_5_to_30_yrs.title:ja:United States':
    'ニューヨーク連銀国債購入TIPS 7.5～30年物',
  'national_cpi_ex-fresh_food_yoy.title:ja:DEFAULT': '生鮮食品を除く全国CPI（前年比）',
  'national_cpi_ex-fresh_food_yoy.title:ja:Japan': '生鮮食品を除く全国CPI（前年比）',
  'national_day_of_the_people_republic_of_china.title:ja:DEFAULT': '中華人民共和国国慶節',
  'national_day_of_the_people_republic_of_china.title:ja:Hong Kong': '中華人民共和国国慶節',
  'ifo_current_conditions_prel.title:ja:DEFAULT': 'IFO現況指数',
  'ifo_current_conditions_prel.title:ja:Germany': 'IFO現況指数',
  '3-year_btp_auction.title:ja:DEFAULT': 'イタリア3年物国債入札',
  '3-year_btp_auction.title:ja:Italy': 'イタリア3年物国債入札',
  'gdp_growth_rate_yoy_-_p.title:ja:DEFAULT': 'GDP成長率（前年比）－速報値',
  'gdp_growth_rate_yoy_-_p.title:ja:Greece': 'GDP成長率（前年比）－速報値',
  'gdp_growth_rate_yoy_-_p.title:ja:Hungary': 'GDP成長率（前年比）－速報値',
  'national_founding_day.title:ja:DEFAULT': '建国記念日',
  'national_founding_day.title:ja:Japan': '建国記念日',
  'private_consumption_yoy.title:ja:DEFAULT': '民間消費（前年比）',
  'private_consumption_yoy.title:ja:Portugal': '民間消費（前年比）',
  'national_heroes_day.title:ja:DEFAULT': '英雄の日',
  'national_heroes_day.title:ja:Philippines': '英雄の日',
  'pm_johnson_statement_to_parliament.title:ja:DEFAULT': 'ジョンソン首相による議会声明',
  'pm_johnson_statement_to_parliament.title:ja:United Kingdom': 'ジョンソン首相による議会声明',
  'national_holiday.title:ja:DEFAULT': '国の祝日',
  'national_holiday.title:ja:Greece': '国の祝日',
  'national_holiday.title:ja:Japan': '国の祝日',
  'boc_leduc_speech.title:ja:DEFAULT': 'カナダ銀行ルデュク氏講演',
  'boc_leduc_speech.title:ja:Canada': 'カナダ銀行ルデュク氏講演',
  'national_unity_day_holiday.title:ja:DEFAULT': '国民団結の日',
  'national_unity_day_holiday.title:ja:Russia': '国民団結の日',
  'nationwide_housing_prices_mom.title:ja:DEFAULT': '全国住宅価格指数（前月比）',
  'nationwide_housing_prices_mom.title:ja:United Kingdom': '全国住宅価格指数（前月比）',
  'pce_prices_qoq_adv.title:ja:DEFAULT': 'PCE物価指数（前四半期比）（速報値）',
  'pce_prices_qoq_adv.title:ja:United States': 'PCE物価指数（前四半期比）（速報値）',
  'treasury_committee_hearing_inflation_report.title:ja:DEFAULT': '財務委員会公聴会 - インフレ報告',
  'treasury_committee_hearing_inflation_report.title:ja:United Kingdom':
    '財務委員会公聴会 - インフレ報告',
  'gdp_final_consumption_qoq.title:ja:DEFAULT': 'GDP最終消費（前四半期比）',
  'gdp_final_consumption_qoq.title:ja:Australia': 'GDP最終消費（前四半期比）',
  'boc_gov_carney_speaks.title:ja:DEFAULT': 'カナダ銀行カーニー総裁講演',
  'boc_gov_carney_speaks.title:ja:Canada': 'カナダ銀行カーニー総裁講演',
  'day_of_prayer.title:ja:DEFAULT': '大祈祷日',
  'day_of_prayer.title:ja:Denmark': '大祈祷日',
  'south_african_manufacturing_pmi.title:ja:DEFAULT': '南アフリカ製造業購買担当者景気指数（PMI）',
  'south_african_manufacturing_pmi.title:ja:South Africa':
    '南アフリカ製造業購買担当者景気指数（PMI）',
  'net_long-term_tic_flows.title:ja:DEFAULT': '正味長期TICフロー',
  'net_long-term_tic_flows.title:ja:United States': '正味長期TICフロー',
  'new_car_registrations_mom.title:ja:DEFAULT': '新車登録台数（前月比）',
  'new_car_registrations_mom.title:ja:Brazil': '新車登録台数（前月比）',
  'personal_income_mom.title:ja:DEFAULT': '個人所得（前月比）',
  'personal_income_mom.title:ja:United States': '個人所得（前月比）',
  'new_home_sales_mom.title:ja:DEFAULT': '新築住宅販売件数（前月比）',
  'new_home_sales_mom.title:ja:United States': '新築住宅販売件数（前月比）',
  'may_day_bank_holiday.title:ja:DEFAULT': '5月バンク・ホリデー',
  'may_day_bank_holiday.title:ja:United Kingdom': '5月バンク・ホリデー',
  'new_motor_vehicle_sales_mom.title:ja:DEFAULT': '新車販売台数（前月比）',
  'new_motor_vehicle_sales_mom.title:ja:Australia': '新車販売台数（前月比）',
  'burning_at_stake_of_jan_hus.title:ja:DEFAULT': 'ヤン・フスを偲ぶ日',
  'burning_at_stake_of_jan_hus.title:ja:Czech Republic': 'ヤン・フスを偲ぶ日',
  'foreign_investment_in_japan_stocks.title:ja:DEFAULT': '日本株外国人投資',
  'foreign_investment_in_japan_stocks.title:ja:Japan': '日本株外国人投資',
  'new_year.title:ja:DEFAULT': '元日',
  'new_year.title:ja:Israel': '元日',
  'new_year.title:ja:South Korea': '元日',
  'new_year.title:ja:Thailand': '元日',
  'new_year_s_day_substitute_day.title:ja:DEFAULT': '元旦(振替休日)',
  'new_year_s_day_substitute_day.title:ja:United Kingdom': '元旦(振替休日)',
  'new_year_s_day_substitute_day.title:ja:Ireland': '元旦(振替休日)',
  'news_conference_on_italy_budget_talks.title:ja:DEFAULT': 'イタリア予算協議記者会見',
  'news_conference_on_italy_budget_talks.title:ja:European Union': 'イタリア予算協議記者会見',
  'martin_l_king_day.title:ja:DEFAULT': 'マーティン・L・キング・デー',
  'martin_l_king_day.title:ja:United States': 'マーティン・L・キング・デー',
  'nikkei_manufacturing_pmi.title:ja:DEFAULT': '日経製造業購買担当者景気指数（PMI）',
  'nikkei_manufacturing_pmi.title:ja:Philippines': '日経PMI（製造業購買担当者景気指数）',
  'nikkei_manufacturing_pmi.title:ja:Taiwan': '日経製造業購買担当者景気指数（PMI）',
  'nikkei_manufacturing_pmi.title:ja:Thailand': '日経PMI（製造業購買担当者景気指数）',
  'nikkei_manufacturing_pmi.title:ja:South Korea': '日経PMI（製造業購買担当者景気指数）',
  'nikkei_manufacturing_pmi.title:ja:Indonesia': '日経PMI（製造業購買担当者景気指数）',
  'nikkei_manufacturing_pmi.title:ja:Hong Kong': '日経PMI（製造業購買担当者景気指数）',
  'nikkei_manufacturing_pmi.title:ja:India': '日経PMI（製造業購買担当者景気指数）',
  'fed_yellen_testymony_-_house.title:ja:DEFAULT': 'FRBイエレン氏証言－下院',
  'fed_yellen_testymony_-_house.title:ja:United States': 'FRBイエレン氏証言－下院',
  'nikkei_markit_services_pmi.title:ja:DEFAULT':
    '日経マークイットサービス業購買担当者景気指数（PMI）',
  'nikkei_markit_services_pmi.title:ja:Japan':
    '日経マークイットサービス業購買担当者景気指数（PMI）',
  'boj_sakurai_speech.title:ja:DEFAULT': '日本銀行櫻井氏講演',
  'boj_sakurai_speech.title:ja:Japan': '日本銀行櫻井氏講演',
  'nomura_jmma_manufacturing_pmi.title:ja:DEFAULT': '野村/JMMA製造業購買担当者景気指数（PMI）',
  'nomura_jmma_manufacturing_pmi.title:ja:Japan': '野村/JMMA製造業購買担当者景気指数（PMI）',
  'non_farm_payrolls_qoq.title:ja:DEFAULT': '非農業部門雇用者数（前四半期比）',
  'non_farm_payrolls_qoq.title:ja:France': '非農業部門雇用者数（前四半期比）',
  'fed_beige_book.title:ja:DEFAULT': '地区連銀景況報告（ベージュブック）',
  'fed_beige_book.title:ja:United States': '地区連銀景況報告（ベージュブック）',
  'pce_prices_qoq_2nd_est.title:ja:DEFAULT': 'PCE物価指数（前四半期比）（2次速報値）',
  'pce_prices_qoq_2nd_est.title:ja:United States': 'PCE物価指数（前四半期比）（2次速報値）',
  'm3_money_supply.title:ja:DEFAULT': 'M3マネーサプライ',
  'm3_money_supply.title:ja:India': 'M3マネーサプライ',
  'thanksgiving_day.title:ja:DEFAULT': '感謝祭',
  'thanksgiving_day.title:ja:United States': '感謝祭',
  'thanksgiving_day.title:ja:Canada': '感謝祭',
  'national_unity_day.title:ja:DEFAULT': '国民団結の日',
  'national_unity_day.title:ja:Russia': '国民団結の日',
  'norges_bank_interest_rate_decision.title:ja:DEFAULT': 'ノルウェー中央銀行政策金利決定',
  'norges_bank_interest_rate_decision.title:ja:Norway': 'ノルウェー中央銀行政策金利決定',
  'norges_bank_interest_rate_decison.title:ja:DEFAULT': 'ノルウェー中央銀行政策金利決定',
  'norges_bank_interest_rate_decison.title:ja:Norway': 'ノルウェー中央銀行政策金利決定',
  'anz_business_confidence.title:ja:DEFAULT': 'ANZ企業信頼感指数',
  'anz_business_confidence.title:ja:New Zealand': 'ANZ企業信頼感指数',
  'gdp_growth_rate_yoy_final.title:ja:DEFAULT': 'GDP成長率（前年比）確報値',
  'gdp_growth_rate_yoy_final.title:ja:Euro Area': 'GDP成長率（前年比）確報値',
  'gdp_growth_rate_yoy_final.title:ja:Israel': 'GDP成長率（前年比）確報値',
  'gdp_growth_rate_yoy_final.title:ja:Hungary': 'GDP成長率（前年比）確報値',
  'gdp_growth_rate_yoy_final.title:ja:Portugal': 'GDP成長率（前年比）確報値',
  'gdp_growth_rate_yoy_final.title:ja:Greece': 'GDP成長率（前年比）確報値',
  'gdp_growth_rate_yoy_final.title:ja:Hong Kong': 'GDP成長率（前年比）確報値',
  'gdp_growth_rate_yoy_final.title:ja:Finland': 'GDP成長率（前年比）確報値',
  'gdp_growth_rate_yoy_final.title:ja:Ireland': 'GDP成長率（前年比）確報値',
  'gdp_growth_rate_yoy_final.title:ja:United Kingdom': 'GDP成長率（前年比）確報値',
  'gdp_growth_rate_yoy_final.title:ja:South Korea': 'GDP成長率（前年比）確報値',
  'gdp_growth_rate_yoy_final.title:ja:Taiwan': 'GDP成長率（前年比）確報値',
  'gdp_growth_rate_yoy_final.title:ja:Belgium': 'GDP成長率（前年比）確報値',
  'gdp_growth_rate_yoy_final.title:ja:France': 'GDP成長率（前年比）確報値',
  'gdp_growth_rate_yoy_final.title:ja:Mexico': 'GDP成長率（前年比）確報値',
  'gdp_growth_rate_yoy_final.title:ja:Italy': 'GDP成長率（前年比）確報値',
  'gdp_growth_rate_yoy_final.title:ja:Netherlands': 'GDP成長率（前年比）確報値',
  'gdp_growth_rate_yoy_final.title:ja:Singapore': 'GDP成長率（前年比）確報値',
  'gdp_growth_rate_yoy_final.title:ja:Poland': 'GDP成長率（前年比）確報値',
  'gdp_growth_rate_yoy_final.title:ja:Germany': 'GDP成長率（前年比）確報値',
  'gdp_growth_rate_yoy_final.title:ja:Austria': 'GDP成長率（前年比）確報値',
  'gdp_growth_rate_yoy_final.title:ja:Sweden': 'GDP成長率（前年比）確報値',
  'gdp_growth_rate_yoy_final.title:ja:Spain': 'GDP成長率（前年比）確報値',
  'gdp_growth_rate_yoy_final.title:ja:Czech Republic': 'GDP成長率（前年比）確報値',
  'gdp_growth_rate_yoy_final.title:ja:Denmark': 'GDP成長率（前年比）確報値',
  'gdp_growth_rate_yoy_final.title:ja:Russia': 'GDP成長率（前年比）確報値',
  '12-month_bubill_auction.title:ja:DEFAULT': 'ドイツ12カ月物国債入札',
  '12-month_bubill_auction.title:ja:Germany': 'ドイツ12カ月物国債入札',
  'retail_price_index_yoy.title:ja:DEFAULT': '小売価格指数（前年比）',
  'retail_price_index_yoy.title:ja:Philippines': '小売価格指数（前年比）',
  'retail_price_index_yoy.title:ja:United Kingdom': '小売価格指数（前年比）',
  'isra_and_mi_raj.title:ja:DEFAULT': 'モハメッド（ムハンマド）昇天祭',
  'isra_and_mi_raj.title:ja:Indonesia': 'モハメッド（ムハンマド）昇天祭',
  'oecd_global_forum_on_productivity.title:ja:DEFAULT': '生産性に関するOECDグローバル・フォーラム',
  'oecd_global_forum_on_productivity.title:ja:Italy': '生産性に関するOECDグローバル・フォーラム',
  'opec_meeting.title:ja:DEFAULT': '石油輸出国機構（OPEC）会合',
  'opec_meeting.title:ja:Austria': '石油輸出国機構（OPEC）会合',
  'mnb_interest_rate_decison.title:ja:DEFAULT': 'ハンガリー国立銀行（MNB）金利決定',
  'mnb_interest_rate_decison.title:ja:Hungary': 'ハンガリー国立銀行（MNB）金利決定',
  'obamacare_repeal_vote_-_pulled.title:ja:DEFAULT': 'オバマケア廃止投票―中止',
  'obamacare_repeal_vote_-_pulled.title:ja:United States': 'オバマケア廃止投票―中止',
  'boe_s_governor_carney_speech.title:ja:DEFAULT': 'イングランド銀行カーニー総裁講演',
  'boe_s_governor_carney_speech.title:ja:United Kingdom': 'イングランド銀行カーニー総裁講演',
  'belt_and_road_forum.title:ja:DEFAULT': '一帯一路国際協力サミットフォーラム',
  'belt_and_road_forum.title:ja:China': '一帯一路国際協力サミットフォーラム',
  'corporate_profits_qoq.title:ja:DEFAULT': '企業利益（前四半期比）',
  'corporate_profits_qoq.title:ja:United States': '企業利益（前四半期比）',
  'ocean_day.title:ja:DEFAULT': '海の日',
  'ocean_day.title:ja:Japan': '海の日',
  'day_following_the_mid-autumn_festival.title:ja:DEFAULT': '',
  'day_following_the_mid-autumn_festival.title:ja:Hong Kong': '',
  'ochi_day.title:ja:DEFAULT': '参戦記念日',
  'ochi_day.title:ja:Greece': '参戦記念日',
  'philadelphia_fed_manufacturing_index.title:ja:DEFAULT': 'フィラデルフィア連銀製造業景気指数',
  'philadelphia_fed_manufacturing_index.title:ja:United States':
    'フィラデルフィア連銀製造業景気指数',
  'ihs_markit_services_pmi.title:ja:DEFAULT': 'IHS マークイットサービス業購買担当者景気指数（PMI）',
  'ihs_markit_services_pmi.title:ja:Italy': 'IHS マークイットサービス業購買担当者景気指数（PMI）',
  'ontario_provincial_elections.title:ja:DEFAULT': 'オンタリオ州選挙',
  'ontario_provincial_elections.title:ja:Canada': 'オンタリオ州選挙',
  'eve_of_pesach.title:ja:DEFAULT': 'エレブ・ペサハ（過ぎ越しの祭り初日前夜）',
  'eve_of_pesach.title:ja:Israel': 'エレブ・ペサハ（過ぎ越しの祭り初日前夜）',
  'orthodox_christmas_day.title:ja:DEFAULT': '正教会降誕祭（クリスマス）',
  'orthodox_christmas_day.title:ja:Russia': '正教会降誕祭（クリスマス）',
  'assumption_day.title:ja:DEFAULT': '聖母被昇天祭',
  'assumption_day.title:ja:Colombia': '聖母被昇天祭',
  'orthodox_easter_monday.title:ja:DEFAULT': '正教会復活の月曜日',
  'orthodox_easter_monday.title:ja:Greece': '正教会復活の月曜日',
  'urban_investment_ytd_yoy.title:ja:DEFAULT': '都市投資（年初来）（前年比）',
  'urban_investment_ytd_yoy.title:ja:China': '都市投資（年初来）前年比',
  'unit_labor_costs_qoq_final.title:ja:DEFAULT': '単位労働コスト（前四半期比）確報値',
  'unit_labor_costs_qoq_final.title:ja:United States': '単位労働コスト（前四半期比）確報値',
  'orthodox_easter_sunday.title:ja:DEFAULT': '正教会復活祭',
  'orthodox_easter_sunday.title:ja:Greece': '正教会復活祭',
  'orthodox_good_friday.title:ja:DEFAULT': '正教会聖金曜日',
  'orthodox_good_friday.title:ja:Greece': '正教会聖金曜日',
  'shavuot_pentecost.title:ja:DEFAULT': 'シャブオット（五旬節）',
  'shavuot_pentecost.title:ja:Israel': 'シャブオット（五旬節）',
  'gold_production_yoy.title:ja:DEFAULT': '金生産量（前年比）',
  'gold_production_yoy.title:ja:South Africa': '金生産量（前年比）',
  'gdp_growth_rate_qoq.title:ja:DEFAULT': 'GDP成長率（前四半期比）',
  'gdp_growth_rate_qoq.title:ja:Singapore': 'GDP成長率（前四半期比）',
  '1956_revolution_memorial_day.title:ja:DEFAULT': '1956年革命記念日',
  '1956_revolution_memorial_day.title:ja:Hungary': '1956年革命記念日',
  'new_housing_price_index_mom.title:ja:DEFAULT': '新築住宅価格指数（前月比）',
  'new_housing_price_index_mom.title:ja:Canada': '新築住宅価格指数（前月比）',
  'gfk_consumer_confidence.title:ja:DEFAULT': 'GfK消費者信頼感指数',
  'gfk_consumer_confidence.title:ja:Germany': 'GfK消費者信頼感指数',
  'gfk_consumer_confidence.title:ja:United Kingdom': 'GfK消費者信頼感指数',
  'bundesbank_beermann_speech.title:ja:DEFAULT': 'ドイツ連邦銀行ヨハネス・ベーアマン理事講演',
  'bundesbank_beermann_speech.title:ja:Germany': 'ドイツ連邦銀行ヨハネス・ベーアマン理事講演',
  'outlook_for_economic_activity_and_prices.title:ja:DEFAULT': '経済活動および価格の見通し',
  'outlook_for_economic_activity_and_prices.title:ja:Japan': '経済活動および価格の見通し',
  'european_parliament_elections.title:ja:DEFAULT': '欧州議会選挙',
  'european_parliament_elections.title:ja:European Union': '欧州議会選挙',
  'overall_household_spending_yoy.title:ja:DEFAULT': '全世帯家計支出（前年比）',
  'overall_household_spending_yoy.title:ja:Japan': '全世帯家計支出（前年比）',
  'overall_net_capital_flows.title:ja:DEFAULT': '全体純資本フロー',
  'overall_net_capital_flows.title:ja:United States': '全体純資本フロー',
  'pce_price_index_yoy.title:ja:DEFAULT': 'PCE物価指数（前年比）',
  'pce_price_index_yoy.title:ja:United States': 'PCE物価指数（前年比）',
  'whit_monday.title:ja:DEFAULT': 'ウィットマンデー',
  'whit_monday.title:ja:Switzerland': 'ウィットマンデー',
  'whit_monday.title:ja:Norway': 'ウィットマンデー',
  'whit_monday.title:ja:Netherlands': 'ウィットマンデー',
  'whit_monday.title:ja:Hungary': 'ウィットマンデー',
  'whit_monday.title:ja:Austria': 'ウィットマンデー',
  'whit_monday.title:ja:France': 'ウィットマンデー',
  'whit_monday.title:ja:Belgium': 'ウィットマンデー',
  'whit_monday.title:ja:Denmark': 'ウィットマンデー',
  'whit_monday.title:ja:Germany': 'ウィットマンデー',
  'pce_prices_qoq_2_est.title:ja:DEFAULT': 'PCE物価指数（前四半期比）2次速報値',
  'pce_prices_qoq_2_est.title:ja:United States': 'PCE物価指数（前四半期比）2次速報値',
  'seollal_holiday_day_3.title:ja:DEFAULT': 'ソルラル（旧正月）3日目',
  'seollal_holiday_day_3.title:ja:South Korea': 'ソルラル（旧正月）3日目',
  'obr_fiscal_risks_report.title:ja:DEFAULT': '英予算責任局（OBR）財政リスク報告書',
  'obr_fiscal_risks_report.title:ja:United Kingdom': '英予算責任局（OBR）財政リスク報告書',
  'balance_of_trade-final.title:ja:DEFAULT': '貿易収支（確報値）',
  'balance_of_trade-final.title:ja:Ireland': '貿易収支（確報値）',
  'national_day_of_the_people_s_republic_of_china.title:ja:DEFAULT': '',
  'national_day_of_the_people_s_republic_of_china.title:ja:Hong Kong': '',
  'catalonia_parliamentary_election.title:ja:DEFAULT': 'カタルーニャ議会選挙',
  'catalonia_parliamentary_election.title:ja:Spain': 'カタルーニャ議会選挙',
  'nonfarm_productivity_yoy.title:ja:DEFAULT': '非農業部門生産性（前年比）',
  'nonfarm_productivity_yoy.title:ja:United States': '非農業部門生産性（前年比）',
  'industrial_orders_yoy.title:ja:DEFAULT': '工業受注（前年比）',
  'industrial_orders_yoy.title:ja:Spain': '工業受注（前年比）',
  'industrial_orders_yoy.title:ja:Switzerland': '工業受注（前年比）',
  'industrial_orders_yoy.title:ja:Italy': '工業受注（前年比）',
  'car_sales_yoy.title:ja:DEFAULT': '自動車販売台数（前年比）',
  'car_sales_yoy.title:ja:Indonesia': '自動車販売台数（前年比）',
  'net_lending_to_individuals_mom.title:ja:DEFAULT': '個人向け純貸出（前月比）',
  'net_lending_to_individuals_mom.title:ja:United Kingdom': '個人向け純貸出（前月比）',
  'vehicle_sales_yoy.title:ja:DEFAULT': '車両販売台数（前年比）',
  'vehicle_sales_yoy.title:ja:Russia': '車両販売台数（前年比）',
  'vehicle_sales_yoy.title:ja:Japan': '車両販売台数（前年比）',
  'vehicle_sales_yoy.title:ja:China': '車両販売台数（前年比）',
  'manufacturing_sales.title:ja:DEFAULT': '製造業売上高',
  'manufacturing_sales.title:ja:New Zealand': '製造業売上高',
  'pm_conte_speech_in_the_senate.title:ja:DEFAULT': '上院でのコンテ首相演説',
  'pm_conte_speech_in_the_senate.title:ja:Italy': '上院でのコンテ首相演説',
  'chatrapati_shivaji_maharaj_jayanti.title:ja:DEFAULT': 'チャトラティー・シヴァージー生誕日',
  'chatrapati_shivaji_maharaj_jayanti.title:ja:India': 'チャトラティー・シヴァージー生誕日',
  'pm_may_s_statement_on_brexit.title:ja:DEFAULT': 'ブレグジットに関するメイ首相声明',
  'pm_may_s_statement_on_brexit.title:ja:United Kingdom': 'ブレグジットに関するメイ首相声明',
  'gdp_capital_expenditure_qoq_final.title:ja:DEFAULT': 'GDP設備投資（前四半期比）確報値',
  'gdp_capital_expenditure_qoq_final.title:ja:Japan': 'GDP設備投資（前四半期比）確報値',
  'pm_shinzo_abe_to_dissolve_parliament.title:ja:DEFAULT': '安倍首相による国会解散',
  'pm_shinzo_abe_to_dissolve_parliament.title:ja:Japan': '安倍首相による国会解散',
  'gdp_growth_rate_yoy_-_1st_est.title:ja:DEFAULT': 'GDP成長率（前年比）－1次速報値',
  'gdp_growth_rate_yoy_-_1st_est.title:ja:Israel': 'GDP成長率（前年比）－1次速報値',
  'rbc_manufacturing_pmi.title:ja:DEFAULT': 'RBC製造業購買担当者景気指数（PMI）',
  'rbc_manufacturing_pmi.title:ja:Canada': 'RBC製造業購買担当者景気指数（PMI）',
  'pm_trudeau_statement.title:ja:DEFAULT': 'トルドー首相声明',
  'pm_trudeau_statement.title:ja:Canada': 'トルドー首相声明',
  'obr_fiscal_forecasts.title:ja:DEFAULT': '英予算責任局（OBR）財政見通し',
  'obr_fiscal_forecasts.title:ja:United Kingdom': '英予算責任局（OBR）財政見通し',
  'westpac_consumer_confidence_change.title:ja:DEFAULT': 'ウエストパック消費者信頼感指数',
  'westpac_consumer_confidence_change.title:ja:Australia': 'ウエストパック消費者信頼感指数',
  'pmi_services.title:ja:DEFAULT': 'サービス業PMI',
  'pmi_services.title:ja:Ireland': 'サービス業PMI',
  '5-year_bond_auction.title:ja:DEFAULT': '',
  '5-year_bond_auction.title:ja:Canada': '',
  'pnad_unemployment_rate.title:ja:DEFAULT': '全国家庭サンプル調査（PNAD）失業率',
  'pnad_unemployment_rate.title:ja:Brazil': '全国家庭サンプル調査（PNAD）失業率',
  'ppi_mom_prel.title:ja:DEFAULT': '生産者物価指数（前月比）速報値',
  'ppi_mom_prel.title:ja:Canada': '生産者物価指数（前月比）速報値',
  'ppi_output_mom.title:ja:DEFAULT': '生産者物価指数（PPI）アウトプット（前月比）',
  'ppi_output_mom.title:ja:United Kingdom': '生産者物価指数（PPI）アウトプット（前月比）',
  'api_gasoline_stock_change.title:ja:DEFAULT': '米国石油協会（API）ガソリン在庫変化',
  'api_gasoline_stock_change.title:ja:United States': '米国石油協会（API）ガソリン在庫変化',
  'bundesbank_dombret_speech.title:ja:DEFAULT': 'ドイツ連邦銀行ドンブレット理事講演',
  'bundesbank_dombret_speech.title:ja:Germany': 'ドイツ連邦銀行ドンブレット理事講演',
  'ppi_qoq.title:ja:DEFAULT': '生産者物価指数（PPI）前四半期比',
  'ppi_qoq.title:ja:Australia': '生産者物価指数（PPI）前四半期比',
  'gdp_growth_rate_qoq-adv.title:ja:DEFAULT': 'GDP成長率（前四半期比）速報値',
  'gdp_growth_rate_qoq-adv.title:ja:Singapore': 'GDP成長率（前四半期比）速報値',
  'ppi_yoy_final.title:ja:DEFAULT': '生産者物価指数（PPI）（前年比）確報値',
  'ppi_yoy_final.title:ja:Canada': '生産者物価指数（PPI）（前年比）確報値',
  'pm_trudeau_testimony.title:ja:DEFAULT': 'トルドー首相証言',
  'pm_trudeau_testimony.title:ja:Canada': 'トルドー首相証言',
  'leading_economic_index_final.title:ja:DEFAULT': '経済先行指数（確報値）',
  'leading_economic_index_final.title:ja:Japan': '経済先行指数（確報値）',
  'parliament_debate_on_brexit.title:ja:DEFAULT': 'ブレグジットに関する議会討論',
  'parliament_debate_on_brexit.title:ja:United Kingdom': 'ブレグジットに関する議会討論',
  'snb_zurbruegg_speech.title:ja:DEFAULT': 'スイス国立銀行ツアブリュック副総裁演説',
  'snb_zurbruegg_speech.title:ja:Switzerland': 'スイス国立銀行ツアブリュック副総裁演説',
  'manufacturing_sales_yoy.title:ja:DEFAULT': '製造業売上高（前年比）',
  'manufacturing_sales_yoy.title:ja:Canada': '製造業売上高（前年比）',
  'manufacturing_sales_yoy.title:ja:New Zealand': '製造業売上高（前年比）',
  'producer_import_prices_yoy.title:ja:DEFAULT': '生産者/輸入物価指数（前年比）',
  'producer_import_prices_yoy.title:ja:Switzerland': '生産者/輸入物価指数（前年比）',
  'parliamentary_debate_vote_on_coronavirus_aid_package.title:ja:DEFAULT':
    '新型コロナウイルス対策に関する議会討論と投票',
  'parliamentary_debate_vote_on_coronavirus_aid_package.title:ja:Canada':
    '新型コロナウイルス対策に関する議会討論と投票',
  'parliamentary_debate_vote_on_part_of_brexit_deal.title:ja:DEFAULT':
    'ブレグジットの一部に関する議会討論と投票',
  'parliamentary_debate_vote_on_part_of_brexit_deal.title:ja:United Kingdom':
    'ブレグジットの一部に関する議会討論と投票',
  'parliamentary_debate_on_coronavirus_bill_day_1.title:ja:DEFAULT':
    '新型コロナウイルス関連法案に関する議会討論：1日目',
  'parliamentary_debate_on_coronavirus_bill_day_1.title:ja:United Kingdom': '',
  'standard_bank_pmi.title:ja:DEFAULT': 'スタンダード銀行PMI',
  'standard_bank_pmi.title:ja:South Africa': 'スタンダード銀行PMI',
  'parliamentary_vote_on_2019_budget.title:ja:DEFAULT': '2019年予算の議会投票',
  'parliamentary_vote_on_2019_budget.title:ja:Spain': '2019年予算の議会投票',
  'martin_luther_king_jr_day.title:ja:DEFAULT': 'キング牧師記念日',
  'martin_luther_king_jr_day.title:ja:United States': 'キング牧師記念日',
  'outstanding_loan_growth_yoy.title:ja:DEFAULT': '融資残高伸び率（前年比）',
  'outstanding_loan_growth_yoy.title:ja:China': '融資残高伸び率（前年比）',
  'parliamentary_vote_on_brexit_deal.title:ja:DEFAULT': 'ブレグジット合意に関する議会投票',
  'parliamentary_vote_on_brexit_deal.title:ja:United Kingdom': 'ブレグジット合意に関する議会投票',
  'boe_inflation_report.title:ja:DEFAULT': 'イングランド銀行インフレ報告',
  'boe_inflation_report.title:ja:United Kingdom': 'イングランド銀行インフレ報告',
  'parliamentary_vote_on_no-deal_brexit.title:ja:DEFAULT': '合意なきブレグジットに関する議会投票',
  'parliamentary_vote_on_no-deal_brexit.title:ja:United Kingdom':
    '合意なきブレグジットに関する議会投票',
  'capital_spending_yoy_final.title:ja:DEFAULT': '資本支出（前年比）確報値',
  'capital_spending_yoy_final.title:ja:Japan': '資本支出（前年比）確報値',
  'private_loans_yoy.title:ja:DEFAULT': '民間部門向け融資（前年比）',
  'private_loans_yoy.title:ja:Euro Area': '民間部門向け融資（前年比）',
  'parliamentary_votes_on_brexit_alternatives.title:ja:DEFAULT':
    'ブレグジット代替案に関する議会投票',
  'parliamentary_votes_on_brexit_alternatives.title:ja:United Kingdom':
    'ブレグジット代替案に関する議会投票',
  'caixin_composite_pmi.title:ja:DEFAULT': '財新総合PMI',
  'caixin_composite_pmi.title:ja:China': '財新総合PMI',
  'fed_interest_rate_decision.title:ja:DEFAULT': 'FRB金利決定',
  'fed_interest_rate_decision.title:ja:United States': 'FRB金利決定',
  'passover_begins.title:ja:DEFAULT': '過越祭初日',
  'passover_begins.title:ja:Israel': '過越祭初日',
  'monetary_base_yoy.title:ja:DEFAULT': 'マネタリーベース（前年比）',
  'monetary_base_yoy.title:ja:Japan': 'マネタリーベース（前年比）',
  'nonfarm_payrolls_qoq.title:ja:DEFAULT': '非農業部門雇用者数（前四半期比）',
  'nonfarm_payrolls_qoq.title:ja:France': '非農業部門雇用者数（前四半期比）',
  'inflation_expectations.title:ja:DEFAULT': 'インフレ期待',
  'inflation_expectations.title:ja:Israel': 'インフレ期待',
  'ppi_output_qoq.title:ja:DEFAULT': '生産者物価指数（PPI）アウトプット（前四半期比）',
  'ppi_output_qoq.title:ja:New Zealand': '生産者物価指数（PPI）アウトプット（前四半期比）',
  'passover_ends.title:ja:DEFAULT': '過越祭最終日',
  'passover_ends.title:ja:Israel': '過越祭最終日',
  'kansas_fed_manufacturing_index.title:ja:DEFAULT': 'カンザス連銀製造業景気指数',
  'kansas_fed_manufacturing_index.title:ja:United States': 'カンザス連銀製造業景気指数',
  'reteurs_econometer.title:ja:DEFAULT': 'ロイター　エコノメーター',
  'reteurs_econometer.title:ja:South Africa': 'ロイター　エコノメーター',
  'passover_ii.title:ja:DEFAULT': '二度目の過越祭',
  'passover_ii.title:ja:Israel': '二度目の過越祭',
  'tokyo_core_cpi_yoy.title:ja:DEFAULT': '東京都区部コアCPI（前年比）',
  'tokyo_core_cpi_yoy.title:ja:Japan': '東京都区部コアCPI（前年比）',
  'passover_ii_eve.title:ja:DEFAULT': '二度目の過越祭前夜',
  'passover_ii_eve.title:ja:Israel': '二度目の過越祭前夜',
  'treasury_refunding_announcement.title:ja:DEFAULT': '財務省定例入札発表',
  'treasury_refunding_announcement.title:ja:United States': '財務省定例入札発表',
  'bastille_day.title:ja:DEFAULT': 'フランス革命記念日',
  'bastille_day.title:ja:France': 'フランス革命記念日',
  'bof_liikanen_speech.title:ja:DEFAULT': 'フィンランド銀行リーカネン総裁講演',
  'bof_liikanen_speech.title:ja:Finland': 'フィンランド銀行リーカネン総裁講演',
  'investec_manufacturing_pmi.title:ja:DEFAULT': 'インベステック製造業購買担当者景気指数（PMI）',
  'investec_manufacturing_pmi.title:ja:Ireland': 'インベステック製造業購買担当者景気指数（PMI）',
  'pending_home_sales_yoy.title:ja:DEFAULT': '住宅販売契約指数（前年比）',
  'pending_home_sales_yoy.title:ja:United States': '住宅販売契約指数（前年比）',
  'ura_property_index_qoq_final.title:ja:DEFAULT': 'URA住宅価格指数（前四半期比）確報値',
  'ura_property_index_qoq_final.title:ja:Singapore': 'URA住宅価格指数（前四半期比）確報値',
  'inflation_rate_yoy.title:ja:DEFAULT': 'インフレ率（前年比）',
  'inflation_rate_yoy.title:ja:Thailand': 'インフレ率（前年比）',
  'gdp_growth_rate_yoy_2nd_est.title:ja:DEFAULT': 'GDP成長率（前年比）（2次速報）',
  'gdp_growth_rate_yoy_2nd_est.title:ja:Greece': 'GDP成長率（前年比）2次速報値',
  'gdp_growth_rate_yoy_2nd_est.title:ja:France': 'GDP成長率（前年比）2次速報値',
  'gdp_growth_rate_yoy_2nd_est.title:ja:Euro Area': 'GDP成長率（前年比）2次速報値',
  'gdp_growth_rate_yoy_2nd_est.title:ja:Belgium': 'GDP成長率（前年比）2次速報値',
  'gdp_growth_rate_yoy_2nd_est.title:ja:Czech Republic': 'GDP成長率（前年比）2次速報値',
  'gdp_growth_rate_yoy_2nd_est.title:ja:United Kingdom': 'GDP成長率（前年比）2次速報値',
  'gdp_growth_rate_yoy_2nd_est.title:ja:Russia': 'GDP成長率（前年比）2次速報値',
  'gdp_growth_rate_yoy_2nd_est.title:ja:Netherlands': 'GDP成長率（前年比）2次速報値',
  'gdp_growth_rate_yoy_2nd_est.title:ja:Hungary': 'GDP成長率（前年比）2次速報値',
  'gdp_growth_rate_yoy_2nd_est.title:ja:Poland': 'GDP成長率（前年比）（2次速報）',
  'gdp_growth_rate_yoy_2nd_est.title:ja:Portugal': 'GDP成長率（前年比）2次速報値',
  'boe_governor_king_speech.title:ja:DEFAULT': 'イングランド銀行キング総裁講演',
  'boe_governor_king_speech.title:ja:United Kingdom': 'イングランド銀行キング総裁講演',
  'cnb_press_conference.title:ja:DEFAULT': 'CNB記者会見',
  'cnb_press_conference.title:ja:Czech Republic': 'CNB記者会見',
  'pentecost_shavuot.title:ja:DEFAULT': '五旬節（シャブオット）',
  'pentecost_shavuot.title:ja:Israel': '五旬節（シャブオット）',
  'pentecost_shavuot_eve.title:ja:DEFAULT': '五旬節（シャブオット）前夜',
  'pentecost_shavuot_eve.title:ja:Israel': '五旬節（シャブオット）前夜',
  'pentecost_monday.title:ja:DEFAULT': '五旬節月曜日',
  'pentecost_monday.title:ja:Switzerland': '五旬節月曜日',
  'pentecost_monday.title:ja:Norway': '五旬節月曜日',
  'pentecost_monday.title:ja:Netherlands': '五旬節月曜日',
  'pentecost_monday.title:ja:Hungary': '五旬節月曜日',
  'pentecost_monday.title:ja:Germany': '五旬節月曜日',
  'pentecost_monday.title:ja:Austria': '五旬節月曜日',
  'pentecost_monday.title:ja:Denmark': '五旬節月曜日',
  'pentecost_monday.title:ja:Belgium': '五旬節月曜日',
  'pentecost_monday.title:ja:France': '五旬節月曜日',
  'people_power_revolution.title:ja:DEFAULT': 'エドゥサ革命記念日',
  'people_power_revolution.title:ja:Philippines': 'エドゥサ革命記念日',
  'nonfarm_productivity_qoq.title:ja:DEFAULT': '非農業部門生産性（前四半期比）',
  'nonfarm_productivity_qoq.title:ja:United States': '非農業部門生産性（前四半期比）',
  'inflation_rate_mom_prel.title:ja:DEFAULT': 'インフレ率（前月比）（速報値）',
  'inflation_rate_mom_prel.title:ja:Russia': 'インフレ率（前月比）（速報値）',
  'inflation_rate_mom_prel.title:ja:France': 'インフレ率（前月比）（速報値）',
  'inflation_rate_mom_prel.title:ja:Spain': 'インフレ率（前月比）（速報値）',
  'inflation_rate_mom_prel.title:ja:Germany': 'インフレ率（前月比）（速報値）',
  'inflation_rate_mom_prel.title:ja:Poland': 'インフレ率（前月比）（速報値）',
  'inflation_rate_mom_prel.title:ja:Portugal': 'インフレ率（前月比）（速報値）',
  'inflation_rate_mom_prel.title:ja:Italy': 'インフレ率（前月比）（速報値）',
  'export_price_index_qoq.title:ja:DEFAULT': '輸出価格指数（前四半期比）',
  'export_price_index_qoq.title:ja:Australia': '輸出価格指数（前四半期比）',
  'personal_spending_mom.title:ja:DEFAULT': '個人支出（前月比）',
  'personal_spending_mom.title:ja:United States': '個人支出（前月比）',
  'coincident_index.title:ja:DEFAULT': '一致指数',
  'coincident_index.title:ja:Japan': '一致指数',
  'coincident_index.title:ja:Thailand': '一致指数',
  'philadelphia_fed_plosser_speech.title:ja:DEFAULT': 'フィラデルフィア連銀プロッサー氏講演',
  'philadelphia_fed_plosser_speech.title:ja:United States': 'フィラデルフィア連銀プロッサー氏講演',
  'portfolio_investment_in_foreign_securities.title:ja:DEFAULT': '外国証券へのポートフォリオ投資',
  'portfolio_investment_in_foreign_securities.title:ja:Canada': '外国証券へのポートフォリオ投資',
  'battle_of_boyaca_day.title:ja:DEFAULT': 'ボヤカ戦勝記念日',
  'battle_of_boyaca_day.title:ja:Colombia': 'ボヤカ戦勝記念日',
  'non_farm_payrolls_qoq_final.title:ja:DEFAULT': '非農業部門雇用者数（前四半期比）（確報値）',
  'non_farm_payrolls_qoq_final.title:ja:France': '非農業部門雇用者数（前四半期比）（確報値）',
  'fed_lockhart_speech.title:ja:DEFAULT': 'アトランタ連銀ロックハート氏講演',
  'fed_lockhart_speech.title:ja:United States': 'アトランタ連銀ロックハート氏講演',
  'portugal_day.title:ja:DEFAULT': 'ポルトガルの日',
  'portugal_day.title:ja:Portugal': 'ポルトガルの日',
  'ny_empire_state_manufacturing_index.title:ja:DEFAULT': 'ニューヨーク連銀製造業景気指数',
  'ny_empire_state_manufacturing_index.title:ja:United States': 'ニューヨーク連銀製造業景気指数',
  'nikkei_services_pmi.title:ja:DEFAULT': '日経サービス業購買担当者景気指数（PMI）',
  'nikkei_services_pmi.title:ja:Japan': '日経サービス業購買担当者景気指数（PMI）',
  'nikkei_services_pmi.title:ja:India': '日経サービス業購買担当者景気指数（PMI）',
  'official_foreign_reserves.title:ja:DEFAULT': '外貨準備',
  'official_foreign_reserves.title:ja:India': '外貨準備',
  'bataan_day.title:ja:DEFAULT': '勇者の日',
  'bataan_day.title:ja:Philippines': '勇者の日',
  'new_orders.title:ja:DEFAULT': '新規受注',
  'new_orders.title:ja:Sweden': '新規受注',
  'referendum_on_affordable_housing.title:ja:DEFAULT': '低価格住宅に関する国民投票',
  'referendum_on_affordable_housing.title:ja:Switzerland': '低価格住宅に関する国民投票',
  'new_orders_manufacturing_yoy.title:ja:DEFAULT': '製造業新規受注（前年比）',
  'new_orders_manufacturing_yoy.title:ja:Sweden': '製造業新規受注（前年比）',
  'ecb_macroprudential_policy_and_research_conference.title:ja:DEFAULT':
    'ECBマクロプルーデンス政策とリサーチ会議',
  'ecb_macroprudential_policy_and_research_conference.title:ja:Euro Area':
    'ECBマクロプルーデンス政策とリサーチ会議',
  'new_year_s_holiday.title:ja:DEFAULT': '元日',
  'new_year_s_holiday.title:ja:Russia': '元日',
  'russia_day.title:ja:DEFAULT': 'ロシアの日',
  'russia_day.title:ja:Russia': 'ロシアの日',
  'president_trump_press_conference.title:ja:DEFAULT': 'トランプ大統領記者会見',
  'president_trump_press_conference.title:ja:United States': 'トランプ大統領記者会見',
  'chancellor_sunak_statement_to_parliament.title:ja:DEFAULT': 'スナク財務大臣による議会への声明',
  'chancellor_sunak_statement_to_parliament.title:ja:United Kingdom':
    'スナク財務大臣による議会への声明',
  'fed_bullard_speech.title:ja:DEFAULT': 'セントルイス連銀ブラード総裁講演',
  'fed_bullard_speech.title:ja:Euro Area': 'セントルイス連銀ブラード総裁講演',
  'fed_bullard_speech.title:ja:United States': 'セントルイス連銀ブラード総裁講演',
  'president_trump_tax_speech.title:ja:DEFAULT': 'トランプ大統領の税金に関する演説',
  'president_trump_tax_speech.title:ja:United States': 'トランプ大統領の税金に関する演説',
  'prospective_plantings_-_soy.title:ja:DEFAULT': '作付意向―大豆',
  'prospective_plantings_-_soy.title:ja:United States': '作付意向―大豆',
  'president_s_day.title:ja:DEFAULT': 'プレジデント・デー',
  'president_s_day.title:ja:United States': 'プレジデント・デー',
  'auto_exports_yoy.title:ja:DEFAULT': '自動車輸出台数前年比',
  'auto_exports_yoy.title:ja:Mexico': '自動車輸出台数前年比',
  'labour_holiday.title:ja:DEFAULT': 'レイバー・ホリデー',
  'labour_holiday.title:ja:Russia': 'レイバー・ホリデー',
  'pm_johnson_announcement_on_coronavirus.title:ja:DEFAULT':
    'ジョンソン首相による新型コロナウイルスに関する発表',
  'pm_johnson_announcement_on_coronavirus.title:ja:United Kingdom':
    'ジョンソン首相による新型コロナウイルスに関する発表',
  'gdp_growth_rate_qoq-_second_estimate.title:ja:DEFAULT': '前四半期比GDP成長率（二次速報値）',
  'gdp_growth_rate_qoq-_second_estimate.title:ja:Poland': '前四半期比GDP成長率（二次速報値）',
  'presidential_election_-_2nd_round.title:ja:DEFAULT': '大統領選挙第2回投票',
  'presidential_election_-_2nd_round.title:ja:Brazil': '大統領選挙第2回投票',
  'conference_call_on_emergency_lending_to_greek_banks.title:ja:DEFAULT':
    'ギリシャの銀行への緊急融資に関する電話会議',
  'conference_call_on_emergency_lending_to_greek_banks.title:ja:Euro Area':
    'ギリシャの銀行への緊急融資に関する電話会議',
  'svme_manufacturing_pmi.title:ja:DEFAULT': 'SVME製造業購買担当者景況指数（PMI）',
  'svme_manufacturing_pmi.title:ja:Switzerland': 'SVME製造業購買担当者景況指数（PMI）',
  'korean_new_year.title:ja:DEFAULT': 'ソルラル（韓国の旧正月）',
  'korean_new_year.title:ja:South Korea': 'ソルラル（韓国の旧正月）',
  'deposit_growth_yoy.title:ja:DEFAULT': '預金残高成長率（前年比）',
  'deposit_growth_yoy.title:ja:India': '預金残高成長率（前年比）',
  'markit_manufacturing_pmi_final.title:ja:DEFAULT':
    'マークイット製造業購買担当者景況指数（確報値）',
  'markit_manufacturing_pmi_final.title:ja:Australia':
    'マークイット製造業購買担当者景況指数（確報値）',
  'markit_manufacturing_pmi_final.title:ja:Germany':
    'マークイット製造業購買担当者景況指数（確報値）',
  'markit_manufacturing_pmi_final.title:ja:France':
    'マークイット製造業購買担当者景況指数（確報値）',
  'markit_manufacturing_pmi_final.title:ja:Euro Area':
    'マークイット製造業購買担当者景況指数（確報値）',
  'markit_manufacturing_pmi_final.title:ja:United States':
    'マークイット製造業購買担当者景況指数（確報値）',
  'niesr_gdp_est_3m.title:ja:DEFAULT': '国立経済社会研究所（NIESR）国内総生産予想（3カ月）',
  'niesr_gdp_est_3m.title:ja:United Kingdom': '国立経済社会研究所（NIESR）国内総生産予想（3カ月）',
  'presidential_elections_2nd_round.title:ja:DEFAULT': '大統領選挙第2回投票',
  'presidential_elections_2nd_round.title:ja:Colombia': '大統領選挙第2回投票',
  'ism_manufacturing_new_orders.title:ja:DEFAULT': 'ISM製造業新規受注',
  'ism_manufacturing_new_orders.title:ja:United States': 'ISM製造業新規受注',
  'prime_minister_johnson_testimony.title:ja:DEFAULT': 'ジョンソン首相証言',
  'prime_minister_johnson_testimony.title:ja:United Kingdom': 'ジョンソン首相証言',
  'prime_minister_may_speech.title:ja:DEFAULT': 'メイ首相演説',
  'prime_minister_may_speech.title:ja:United Kingdom': 'メイ首相演説',
  'ecb_annual_research_conference.title:ja:DEFAULT': 'ECB年次リサーチ会議',
  'ecb_annual_research_conference.title:ja:Euro Area': 'ECB年次リサーチ会議',
  'prime_minister_may_speech_on_brexit.title:ja:DEFAULT': 'ブレグジットに関するメイ首相演説',
  'prime_minister_may_speech_on_brexit.title:ja:United Kingdom': 'ブレグジットに関するメイ首相演説',
  'prime_minister_narendra_modi_speech.title:ja:DEFAULT': 'ナレンドラ・モディ首相演説',
  'prime_minister_narendra_modi_speech.title:ja:India': 'ナレンドラ・モディ首相演説',
  'producer_and_import_prices_yoy.title:ja:DEFAULT': '生産者輸入物価指数（前年比）',
  'producer_and_import_prices_yoy.title:ja:Switzerland': '生産者輸入物価指数（前年比）',
  'gdp_growth_annualized_qoq.title:ja:DEFAULT': 'GDP年率成長率（前四半期比）',
  'gdp_growth_annualized_qoq.title:ja:Israel': 'GDP年率成長率（前四半期比）',
  'gdp_growth_annualized_qoq.title:ja:Japan': 'GDP年率成長率（前四半期比）',
  'ecb_economic_bulletin.title:ja:DEFAULT': 'ECB経済報告',
  'ecb_economic_bulletin.title:ja:Euro Area': 'ECB経済報告',
  'prime_minister_trudeau_speech.title:ja:DEFAULT': 'トルドー首相演説',
  'prime_minister_trudeau_speech.title:ja:Canada': 'トルドー首相演説',
  'parliamentary_vote_on_brexit_amendments.title:ja:DEFAULT': 'ブレグジット修正案に関する議会投票',
  'parliamentary_vote_on_brexit_amendments.title:ja:United Kingdom':
    'ブレグジット修正案に関する議会投票',
  'prime_overdraft_rate.title:ja:DEFAULT': 'プライムレート',
  'prime_overdraft_rate.title:ja:South Africa': 'プライムレート',
  'construction_work_done_qoq.title:ja:DEFAULT': '建設工事完工高（前四半期比）',
  'construction_work_done_qoq.title:ja:Australia': '建設工事完工高（前四半期比）',
  'terms_of_trade_qoq.title:ja:DEFAULT': '交易条件（前四半期比）',
  'terms_of_trade_qoq.title:ja:New Zealand': '交易条件（前四半期比）',
  'interest_rate_decison.title:ja:DEFAULT': '金利決定',
  'interest_rate_decison.title:ja:Poland': '金利決定',
  'interest_rate_decison.title:ja:Hungary': '金利決定',
  'interest_rate_decison.title:ja:Mexico': '金利決定',
  'restoration_of_independence.title:ja:DEFAULT': '独立回復記念日',
  'restoration_of_independence.title:ja:Portugal': '独立回復記念日',
  'norges_bank_olsen_speech.title:ja:DEFAULT': 'ノルゲス銀行オルセン氏講演',
  'norges_bank_olsen_speech.title:ja:Norway': 'ノルゲス銀行オルセン氏講演',
  'priv_new_capital_exp_qoq.title:ja:DEFAULT': '民間企業新規設備投資前四半期比',
  'priv_new_capital_exp_qoq.title:ja:Australia': '民間企業新規設備投資前四半期比',
  'trading_holiday.title:ja:DEFAULT': '市場休業日',
  'trading_holiday.title:ja:Indonesia': '市場休業日',
  'private_capital_expenditure.title:ja:DEFAULT': '民間企業設備投資',
  'private_capital_expenditure.title:ja:Australia': '民間企業設備投資',
  'bank_of_canada_consumer_price_index_core_mom.title:ja:DEFAULT':
    'カナダ銀行コア消費者物価指数前月比',
  'bank_of_canada_consumer_price_index_core_mom.title:ja:Canada':
    'カナダ銀行コア消費者物価指数前月比',
  'ninoy_aquino_day.title:ja:DEFAULT': 'ニノイ・アキノ記念日',
  'ninoy_aquino_day.title:ja:Philippines': 'ニノイ・アキノ記念日',
  'private_non_farm_payrolls_qoq_final.title:ja:DEFAULT': '民間非農業部門雇用者数全四半期比確定値',
  'private_non_farm_payrolls_qoq_final.title:ja:France': '民間非農業部門雇用者数全四半期比確定値',
  'inflation_rate_qoq.title:ja:DEFAULT': 'インフレ率（前四半期比）',
  'inflation_rate_qoq.title:ja:Australia': 'インフレ率（前四半期比）',
  'inflation_rate_qoq.title:ja:New Zealand': 'インフレ率（前四半期比）',
  'holi.title:ja:DEFAULT': 'ホーリー祭',
  'holi.title:ja:India': 'ホーリー祭',
  'mortgage_approvals.title:ja:DEFAULT': '住宅ローン承認件数',
  'mortgage_approvals.title:ja:United Kingdom': '住宅ローン承認件数',
  'lending_facility_rate.title:ja:DEFAULT': '貸出ファシリティー金利',
  'lending_facility_rate.title:ja:Indonesia': '貸出ファシリティー金利',
  'ipca_mid-month_cpi_yoy.title:ja:DEFAULT': '月央拡大消費者物価指数（IPCA）前年比',
  'ipca_mid-month_cpi_yoy.title:ja:Brazil': '月央拡大消費者物価指数（IPCA）前年比',
  'banrep_meeting_minutes.title:ja:DEFAULT': 'コロンビア中央銀行会合議事録',
  'banrep_meeting_minutes.title:ja:Colombia': 'コロンビア中央銀行会合議事録',
  'imf_lagarde_speech.title:ja:DEFAULT': 'IMFラガルド専務理事講演',
  'imf_lagarde_speech.title:ja:Austria': 'IMFラガルド専務理事講演',
  'imf_lagarde_speech.title:ja:Germany': 'IMFラガルド専務理事講演',
  'private_sector_credit_yoy.title:ja:DEFAULT': '民間セクター与信前年比',
  'private_sector_credit_yoy.title:ja:South Africa': '民間セクター与信前年比',
  'private_sector_credit_yoy.title:ja:Australia': '民間部門の信用供与(前年比)',
  'current_account_final.title:ja:DEFAULT': '経常収支（確報値）',
  'current_account_final.title:ja:Ireland': '経常収支（確報値）',
  'current_account_final.title:ja:Hong Kong': '経常収支（確報値）',
  'uk_pm_statement_on_brexit.title:ja:DEFAULT': 'ブレグジットに関する英国首相声明',
  'uk_pm_statement_on_brexit.title:ja:United Kingdom': 'ブレグジットに関する英国首相声明',
  'wage_price_index_qoq.title:ja:DEFAULT': '賃金価格指数前四半期比',
  'wage_price_index_qoq.title:ja:Australia': '賃金価格指数前四半期比',
  'midsummer_day.title:ja:DEFAULT': '夏至祭',
  'midsummer_day.title:ja:Sweden': '夏至祭',
  'midsummer_day.title:ja:Finland': '夏至祭',
  'markit_adaci_services_pmi.title:ja:DEFAULT':
    'マークイット/ADACIサービス業購買担当者景気指数（PMI）',
  'markit_adaci_services_pmi.title:ja:Italy':
    'マークイット/ADACIサービス業購買担当者景気指数（PMI）',
  'industrial_capacity_utilization.title:ja:DEFAULT': '工場稼働率',
  'industrial_capacity_utilization.title:ja:China': '工場稼働率',
  'producer_import_prices_mom.title:ja:DEFAULT': '生産者輸入物価指数(前月比）',
  'producer_import_prices_mom.title:ja:Switzerland': '生産者輸入物価指数（前月比）',
  'rba_commodity_index_sdr_yoy.title:ja:DEFAULT': 'RBA商品価格指数 特別引出権（SDR）建て（前年比）',
  'rba_commodity_index_sdr_yoy.title:ja:Australia': 'RBA商品価格指数SDR（前年比）',
  'producer_price_index.title:ja:DEFAULT': '生産者価格指数',
  'producer_price_index.title:ja:South Africa': '生産者価格指数',
  'producer_price_index_ex_food_energy_mom.title:ja:DEFAULT':
    '食品・エネルギーを除く生産者価格指数(前月比)',
  'producer_price_index_ex_food_energy_mom.title:ja:United States':
    '食品・エネルギーを除く生産者価格指数(前月比)',
  'jibun_bank_services_pmi_final.title:ja:DEFAULT':
    'じぶん銀行サービス業購買担当者景気指数（PMI）確報値',
  'jibun_bank_services_pmi_final.title:ja:Japan':
    'じぶん銀行サービス業購買担当者景気指数（PMI）確報値',
  'money_supply_m2.title:ja:DEFAULT': 'マネーサプライM2',
  'money_supply_m2.title:ja:Czech Republic': 'マネーサプライM2',
  'ppi_exl_food_and_energy_mom.title:ja:DEFAULT':
    '食品とエネルギーを除く生産者物価指数（PPI）前月比',
  'ppi_exl_food_and_energy_mom.title:ja:United States':
    '食品とエネルギーを除く生産者物価指数（PPI）前月比',
  'ppi_exl_food_and_energy_yoy.title:ja:DEFAULT':
    '食品とエネルギーを除く生産者物価指数（PPI）前年比',
  'ppi_exl_food_and_energy_yoy.title:ja:United States':
    '食品とエネルギーを除く生産者物価指数（PPI）前年比',
  'president_biden_speech.title:ja:DEFAULT': 'バイデン大統領演説',
  'president_biden_speech.title:ja:United States': 'バイデン大統領演説',
  'machinery_orders_mom.title:ja:DEFAULT': '機械受注(前月比)',
  'machinery_orders_mom.title:ja:Japan': '機械受注(前月比)',
  'public_sector_net_borrowing.title:ja:DEFAULT': '公共部門純借入額',
  'public_sector_net_borrowing.title:ja:United Kingdom': '公共部門純借入額',
  'nikkei_manufacturing_pmi_flash.title:ja:DEFAULT': '日経製造業購買担当者景況指数（PMI）速報値',
  'nikkei_manufacturing_pmi_flash.title:ja:Japan': '日経製造業購買担当者景況指数（PMI）速報値',
  'president_trump_state_of_the_union_speech.title:ja:DEFAULT': 'トランプ大統領一般教書演説',
  'president_trump_state_of_the_union_speech.title:ja:United States': 'トランプ大統領一般教書演説',
  'oenb_gov_nowotny_speech.title:ja:DEFAULT': 'オーストリア中央銀行ノヴォトニー総裁講演',
  'oenb_gov_nowotny_speech.title:ja:Austria': 'オーストリア中央銀行ノヴォトニー総裁講演',
  'purchasing_manager_index_manufacturing.title:ja:DEFAULT': '製造業購買担当者指数（PMI）',
  'purchasing_manager_index_manufacturing.title:ja:Poland': '製造業購買担当者指数（PMI）',
  'purchasing_manager_index_manufacturing.title:ja:Ireland': '製造業購買担当者指数（PMI）',
  'shmini_atzeret_simchat_torah.title:ja:DEFAULT': 'シェミニ・アゼレット /シムハット・トーラー',
  'shmini_atzeret_simchat_torah.title:ja:Israel': 'シェミニ・アゼレット /シムハット・トーラー',
  'building_permits_mom_prel.title:ja:DEFAULT': '建設許可件数（前月比）（速報値）',
  'building_permits_mom_prel.title:ja:Australia': '建設許可件数（前月比）（速報値）',
  'business_nz_pmi.title:ja:DEFAULT': 'ニュージーランド購買担当者景気指数（PMI）',
  'business_nz_pmi.title:ja:New Zealand': 'ニュージーランド購買担当者景気指数（PMI）',
  'purchasing_managers_index.title:ja:DEFAULT': '購買担当者指数（PMI）',
  'purchasing_managers_index.title:ja:Singapore': '購買担当者指数（PMI）',
  'the_prophet_muhammads_birthday.title:ja:DEFAULT': '預言者ムハンマド生誕祭',
  'the_prophet_muhammads_birthday.title:ja:Indonesia': '預言者ムハンマド生誕祭',
  'ganesh_chaturthi.title:ja:DEFAULT': 'ガネーシュ・フェスティバル',
  'ganesh_chaturthi.title:ja:India': 'ガネーシュ・フェスティバル',
  '5-year_treasury_gilt_auction.title:ja:DEFAULT': '英国5年物国債入札',
  '5-year_treasury_gilt_auction.title:ja:United Kingdom': '英国5年物国債入札',
  'boe_stress_test_results.title:ja:DEFAULT': 'イングランド銀行ストレステスト結果',
  'boe_stress_test_results.title:ja:United Kingdom': 'イングランド銀行ストレステスト結果',
  'remembrance_day.title:ja:DEFAULT': '戦没者追悼記念日',
  'remembrance_day.title:ja:Canada': '戦没者追悼記念日',
  'qe_mbs.title:ja:DEFAULT': '量的緩和住宅ローン担保証券',
  'qe_mbs.title:ja:United States': '量的緩和住宅ローン担保証券',
  'feast_of_shavout_pentecost.title:ja:DEFAULT': 'シャブオット（五旬節）',
  'feast_of_shavout_pentecost.title:ja:Israel': 'シャブオット（五旬節）',
  'qingming_festival.title:ja:DEFAULT': '清明節',
  'qingming_festival.title:ja:China': '清明節',
  'quarterly_grain_stocks_-_corn.title:ja:DEFAULT': '四半期穀物在庫―コーン',
  'quarterly_grain_stocks_-_corn.title:ja:United States': '四半期穀物在庫―コーン',
  '10-year_btp_auction.title:ja:DEFAULT': 'イタリア10年物国債入札',
  '10-year_btp_auction.title:ja:Italy': 'イタリア10年物国債入札',
  'independence_day_substitute_day.title:ja:DEFAULT': '独立記念日（振替休日）',
  'independence_day_substitute_day.title:ja:United States': '独立記念日（振替休日）',
  'gdp_growth_rate_qoq-_first_estimate.title:ja:DEFAULT': 'GDP成長率（前四半期比）－1次速報値',
  'gdp_growth_rate_qoq-_first_estimate.title:ja:Israel': 'GDP成長率（前四半期比）－1次速報値',
  'g7_conference_call.title:ja:DEFAULT': 'G7電話会議',
  'g7_conference_call.title:ja:United States': 'G7電話会議',
  'quarterly_grain_stocks_-_soy.title:ja:DEFAULT': '四半期穀物在庫―大豆',
  'quarterly_grain_stocks_-_soy.title:ja:United States': '四半期穀物在庫―大豆',
  'ny_fed_treasury_purchases_7_to_10_yrs.title:ja:DEFAULT': 'ニューヨーク連銀国債購入7-10年物',
  'ny_fed_treasury_purchases_7_to_10_yrs.title:ja:United States':
    'ニューヨーク連銀国債購入7-10年物',
  'mps_debate_internal_market_bill.title:ja:DEFAULT': '国内市場法案に関する議会討論',
  'mps_debate_internal_market_bill.title:ja:United Kingdom': '国内市場法案に関する議会討論',
  'german_buba_president_weidmann_speech.title:ja:DEFAULT': 'ドイツ連邦銀行ワイトマン総裁講演',
  'german_buba_president_weidmann_speech.title:ja:Germany': 'ドイツ連邦銀行ワイトマン総裁講演',
  'consumer_credit_change.title:ja:DEFAULT': '消費者信用変動',
  'consumer_credit_change.title:ja:United States': '消費者信用変動',
  'employment_level_qoq.title:ja:DEFAULT': '雇用水準（前四半期比）',
  'employment_level_qoq.title:ja:Switzerland': '雇用水準（前四半期比）',
  '10-year_obligacion_auction.title:ja:DEFAULT': '10年物国債入札',
  '10-year_obligacion_auction.title:ja:Spain': '10年物国債入札',
  'quebec_provincial_elections.title:ja:DEFAULT': 'ケベック州選挙',
  'quebec_provincial_elections.title:ja:Canada': 'ケベック州選挙',
  '57-day_bill_auction.title:ja:DEFAULT': '',
  '57-day_bill_auction.title:ja:United States': '',
  'ny_fed_treasury_purchases_4_5_to_7_yrs.title:ja:DEFAULT': 'ニューヨーク連銀国債購入4.5-7年物',
  'ny_fed_treasury_purchases_4_5_to_7_yrs.title:ja:United States':
    'ニューヨーク連銀国債購入4.5-7年物',
  'rba_assist_gov_edey_speech.title:ja:DEFAULT': 'RBAエディ総裁補佐講演',
  'rba_assist_gov_edey_speech.title:ja:Australia': 'RBAエディ総裁補佐講演',
  'us-china_phase_1_trade_deal_signature.title:ja:DEFAULT': '米中第1段階の貿易合意に署名',
  'us-china_phase_1_trade_deal_signature.title:ja:United States': '米中第1段階の貿易合意に署名',
  'general_elections.title:ja:DEFAULT': '一般選挙',
  'general_elections.title:ja:Philippines': '一般選挙',
  'general_elections.title:ja:South Africa': '一般選挙',
  'general_elections.title:ja:Indonesia': '一般選挙',
  'general_elections.title:ja:Sweden': '一般選挙',
  'general_elections.title:ja:Spain': '一般選挙',
  'general_elections.title:ja:Brazil': '一般選挙',
  'general_elections.title:ja:New Zealand': '一般選挙',
  'general_elections.title:ja:Japan': '一般選挙',
  'general_elections.title:ja:Italy': '一般選挙',
  'rba_assist_gov_kent_speech.title:ja:DEFAULT': 'RBAケント総裁補佐演説',
  'rba_assist_gov_kent_speech.title:ja:Australia': 'RBAケント総裁補佐演説',
  'youth_day.title:ja:DEFAULT': '青年の日',
  'youth_day.title:ja:South Africa': '青年の日',
  'fx_reserves_usd.title:ja:DEFAULT': '外貨準備（米ドル）',
  'fx_reserves_usd.title:ja:India': '外貨準備（米ドル）',
  'economic_activity_mom.title:ja:DEFAULT': '経済活動前月比',
  'economic_activity_mom.title:ja:Mexico': '経済活動前月比',
  'rba_chart_pack.title:ja:DEFAULT': 'RBAチャート集',
  'rba_chart_pack.title:ja:Australia': 'RBAチャート集',
  'ny_fed_treasury_purchases_2_25_to_4_5_yrs.title:ja:DEFAULT':
    'ニューヨーク連銀国債購入2.25-4.5年物',
  'ny_fed_treasury_purchases_2_25_to_4_5_yrs.title:ja:United States':
    'ニューヨーク連銀国債購入2.25-4.5年物',
  'ascension_of_the_prophet_muhammad.title:ja:DEFAULT': '預言者ムハンマド昇天祭',
  'ascension_of_the_prophet_muhammad.title:ja:Indonesia': '預言者ムハンマド昇天祭',
  'uk_may_-_eu_juncker_meeting.title:ja:DEFAULT': '英国メイ首相とEUユンケル委員長会合',
  'uk_may_-_eu_juncker_meeting.title:ja:United Kingdom': '英国メイ首相とEUユンケル委員長会合',
  'michigan_consumer_expectations_prel.title:ja:DEFAULT': 'ミシガン大学消費者信頼感指数',
  'michigan_consumer_expectations_prel.title:ja:United States': 'ミシガン大学消費者信頼感指数',
  'ecb_elderson_speech.title:ja:DEFAULT': 'ECBエルダーソン氏講演',
  'ecb_elderson_speech.title:ja:Euro Area': 'ECBエルダーソン氏講演',
  'rba_coombs_speech.title:ja:DEFAULT': 'RBAクームズ氏講演',
  'rba_coombs_speech.title:ja:Australia': 'RBAクームズ氏講演',
  'inflation_rate_yoy_prel.title:ja:DEFAULT': 'インフレ率（前年比）（速報値）',
  'inflation_rate_yoy_prel.title:ja:Russia': 'インフレ率（前年比）（速報値）',
  'inflation_rate_yoy_prel.title:ja:France': 'インフレ率（前年比）（速報値）',
  'inflation_rate_yoy_prel.title:ja:Spain': 'インフレ率（前年比）（速報値）',
  'inflation_rate_yoy_prel.title:ja:Germany': 'インフレ率（前年比）（速報値）',
  'inflation_rate_yoy_prel.title:ja:Poland': 'インフレ率（前年比）（速報値）',
  'inflation_rate_yoy_prel.title:ja:Portugal': 'インフレ率（前年比）（速報値）',
  'inflation_rate_yoy_prel.title:ja:Italy': 'インフレ率（前年比）（速報値）',
  'rba_debelle_speech.title:ja:DEFAULT': 'RBAデベル副総裁講演',
  'rba_debelle_speech.title:ja:Australia': 'RBAデベル副総裁講演',
  'spring_festival.title:ja:DEFAULT': '春節',
  'spring_festival.title:ja:China': '春節',
  '15-year_btp_auction.title:ja:DEFAULT': '',
  '15-year_btp_auction.title:ja:Italy': '',
  'post-brexit_meeting.title:ja:DEFAULT': 'ブレグジット後の会合',
  'post-brexit_meeting.title:ja:Euro Area': 'ブレグジット後の会合',
  'michigan_inflation_expectations_prel.title:ja:DEFAULT': 'ミシガン大学インフレ期待率',
  'michigan_inflation_expectations_prel.title:ja:United States': 'ミシガン大学インフレ期待率',
  'rba_ellis_speech.title:ja:DEFAULT': 'RBAエリス総裁補講演',
  'rba_ellis_speech.title:ja:Australia': 'RBAエリス総裁補講演',
  'fed_pace_of_mbs_purchase_program.title:ja:DEFAULT':
    'FRBによるMBS（住宅ローン担保証券）購入ペース',
  'fed_pace_of_mbs_purchase_program.title:ja:United States':
    'FRBによるMBS（住宅ローン担保証券）購入ペース',
  'us-turkey_meeting.title:ja:DEFAULT': '米国・トルコ会合',
  'us-turkey_meeting.title:ja:Belgium': '米国・トルコ会合',
  'rba_gov_glenn_speech.title:ja:DEFAULT': 'RBAグレン・スティーブンス総裁講演',
  'rba_gov_glenn_speech.title:ja:Australia': 'RBAグレン・スティーブンス総裁講演',
  'aig_performance_serv_index.title:ja:DEFAULT': 'AIGサービス業景況指数',
  'aig_performance_serv_index.title:ja:Australia': 'AIGサービス業景況指数',
  'total_business_investment_qoq.title:ja:DEFAULT': '企業投資合計（前四半期比）',
  'total_business_investment_qoq.title:ja:United Kingdom': '企業投資合計（前四半期比）',
  'inflation_rate_yoy_flash.title:ja:DEFAULT': 'インフレ率（前年比）（速報値）',
  'inflation_rate_yoy_flash.title:ja:Poland': 'インフレ率（前年比）（速報値）',
  'inflation_rate_yoy_flash.title:ja:Russia': 'インフレ率（前年比）（速報値）',
  'inflation_rate_yoy_flash.title:ja:Euro Area': 'インフレ率（前年比）（速報値）',
  'chancellor_rishi_sunak_testimony.title:ja:DEFAULT': 'リシ・スナック財務大臣証言',
  'chancellor_rishi_sunak_testimony.title:ja:United Kingdom': 'リシ・スナック財務大臣証言',
  'rbi_press_conference.title:ja:DEFAULT': 'インド中銀（RBI）記者会見',
  'rbi_press_conference.title:ja:India': 'インド中銀（RBI）記者会見',
  'nuclear_withdrawal_initiative_referendum.title:ja:DEFAULT': '脱原発イニシアチブの国民投票',
  'nuclear_withdrawal_initiative_referendum.title:ja:Switzerland': '脱原発イニシアチブの国民投票',
  'bank_loan_growth_yoy.title:ja:DEFAULT': '銀行融資残高伸び率前年比',
  'bank_loan_growth_yoy.title:ja:India': '銀行融資残高伸び率前年比',
  'rba_heath_speech.title:ja:DEFAULT': 'RBAヒース経済分析部門部講演',
  'rba_heath_speech.title:ja:Australia': 'RBAヒース経済分析部門部講演',
  'oecd_head_angel_gurria_speech.title:ja:DEFAULT': 'OECDアンヘル・グリア事務総長講演',
  'oecd_head_angel_gurria_speech.title:ja:Japan': 'OECDアンヘル・グリア事務総長講演',
  'pm_morrison_television_address_on_coronavirus.title:ja:DEFAULT':
    'モリソン首相による新型コロナウイルスに関するテレビ演説',
  'pm_morrison_television_address_on_coronavirus.title:ja:Australia':
    'モリソン首相による新型コロナウイルスに関するテレビ演説',
  'capacity_utilization_mom.title:ja:DEFAULT': '設備稼働率（前月比）',
  'capacity_utilization_mom.title:ja:Japan': '設備稼働率（前月比）',
  'rba_lowe_speech.title:ja:DEFAULT': 'RBAロウ総裁講演',
  'rba_lowe_speech.title:ja:Australia': 'RBAロウ総裁講演',
  'us_president_trump_speech.title:ja:DEFAULT': '米トランプ大統領の演説',
  'us_president_trump_speech.title:ja:Switzerland': '米トランプ大統領の演説',
  'us_president_trump_speech.title:ja:United States': '米トランプ大統領の演説',
  'rba_meeting_minutes.title:ja:DEFAULT': 'RBA会合議事録',
  'rba_meeting_minutes.title:ja:Australia': 'RBA会合議事録',
  'producer_and_import_prices_mom.title:ja:DEFAULT': '生産者輸入物価指数（前月比）',
  'producer_and_import_prices_mom.title:ja:Switzerland': '生産者輸入物価指数（前月比）',
  'ifo_expectations_final.title:ja:DEFAULT': 'Ifo企業景況感（確報値）',
  'ifo_expectations_final.title:ja:Germany': 'Ifo企業景況感（確報値）',
  'rba_rate_statement.title:ja:DEFAULT': 'RBA金利発表',
  'rba_rate_statement.title:ja:Australia': 'RBA金利発表',
  'fed_monetary_policy_report.title:ja:DEFAULT': 'FRB金融政策報告',
  'fed_monetary_policy_report.title:ja:United States': 'FRB金融政策報告',
  'rba_richards_speech.title:ja:DEFAULT': 'RBAリチャーズ氏講演',
  'rba_richards_speech.title:ja:Australia': 'RBAリチャーズ氏講演',
  'spring_budget_2019.title:ja:DEFAULT': '2019年春季財政演説',
  'spring_budget_2019.title:ja:United Kingdom': '2019年春季財政演説',
  'pm_johnson_statement_on_coronavirus.title:ja:DEFAULT':
    'ジョンソン首相による新型コロナウイルスに関する声明',
  'pm_johnson_statement_on_coronavirus.title:ja:United Kingdom':
    'ジョンソン首相による新型コロナウイルスに関する声明',
  'hsbc_markit_pmi.title:ja:DEFAULT': 'HSBCマークイット購買担当者景気指数（PMI）',
  'hsbc_markit_pmi.title:ja:Czech Republic': 'HSBCマークイット購買担当者景気指数（PMI）',
  'rba_simon_speech.title:ja:DEFAULT': 'RBAサイモン氏講演',
  'rba_simon_speech.title:ja:Australia': 'RBAサイモン氏講演',
  'prospective_plantings_-_corn.title:ja:DEFAULT': '作付意向―コーン',
  'prospective_plantings_-_corn.title:ja:United States': '作付意向―コーン',
  'monthly_budget_statement.title:ja:DEFAULT': '月次財政収支',
  'monthly_budget_statement.title:ja:United States': '月次財政収支',
  'rba_weighted_mean_cpi_qoq.title:ja:DEFAULT': 'RBA加重平均消費者物価指数（前四半期比）',
  'rba_weighted_mean_cpi_qoq.title:ja:Australia': 'RBA加重平均消費者物価指数（前四半期比）',
  'inflation_rate_mom_flash.title:ja:DEFAULT': 'インフレ率（前年比）速報値',
  'inflation_rate_mom_flash.title:ja:Russia': 'インフレ率（前年比）速報値',
  'inflation_rate_mom_flash.title:ja:Euro Area': 'インフレ率（前年比）速報値',
  'inflation_rate_mom_flash.title:ja:Germany': 'インフレ率（前年比）速報値',
  'inflation_rate_mom_flash.title:ja:Poland': 'インフレ率（前年比）速報値',
  'fomc_member_raskin_speech.title:ja:DEFAULT': 'FOMCラスキン委員講演',
  'fomc_member_raskin_speech.title:ja:United States': 'FOMCラスキン委員講演',
  'rba_trimmed_mean_cpi_yoy.title:ja:DEFAULT': 'RBAトリム平均消費者物価指数（前年比）',
  'rba_trimmed_mean_cpi_yoy.title:ja:Australia': 'RBAトリム平均消費者物価指数（前年比）',
  'ppi_input_qoq.title:ja:DEFAULT': '生産者物価指数（PPI）インプット（前四半期比）',
  'ppi_input_qoq.title:ja:New Zealand': '生産者物価指数（PPI）インプット（前四半期比）',
  'national_labor_day.title:ja:DEFAULT': '全国レイバー・デー',
  'national_labor_day.title:ja:Thailand': '全国レイバー・デー',
  'rba_s_gov_stevens_speech.title:ja:DEFAULT': 'RBAスティーブンス総裁講演',
  'rba_s_gov_stevens_speech.title:ja:Australia': 'RBAスティーブンス総裁講演',
  'birthday_of_h_m_queen_suthida.title:ja:DEFAULT': 'スティダー王妃誕生日',
  'birthday_of_h_m_queen_suthida.title:ja:Thailand': 'スティダー王妃誕生日',
  'international_labor_day.title:ja:DEFAULT': '国際レイバー・デー',
  'international_labor_day.title:ja:Indonesia': '国際レイバー・デー',
  'rba_s_governor_glenn_stevens_speech.title:ja:DEFAULT': 'RBAグレン・スティーブンス総裁講演',
  'rba_s_governor_glenn_stevens_speech.title:ja:Australia': 'RBAグレン・スティーブンス総裁講演',
  'rbnz_economic_assesment.title:ja:DEFAULT': 'RBNZ経済評価報告',
  'rbnz_economic_assesment.title:ja:New Zealand': 'RBNZ経済評価報告',
  'challenger_job_cuts.title:ja:DEFAULT': 'チャレンジャー人員削減数',
  'challenger_job_cuts.title:ja:United States': 'チャレンジャー人員削減数',
  'prospective_plantings_-_cotton.title:ja:DEFAULT': '作付意向―綿花',
  'prospective_plantings_-_cotton.title:ja:United States': '作付意向―綿花',
  'exports_yoy.title:ja:DEFAULT': '輸出（前年比）',
  'exports_yoy.title:ja:Taiwan': '輸出（前年比）',
  'exports_yoy.title:ja:Philippines': '輸出（前年比）',
  'exports_yoy.title:ja:Australia': '輸出（前年比）',
  'exports_yoy.title:ja:Hong Kong': '輸出（前年比）',
  'exports_yoy.title:ja:Japan': '輸出（前年比）',
  'exports_yoy.title:ja:Colombia': '輸出（前年比）',
  'exports_yoy.title:ja:South Korea': '輸出（前年比）',
  'exports_yoy.title:ja:China': '輸出（前年比）',
  'exports_yoy.title:ja:Indonesia': '輸出（前年比）',
  'exports_yoy.title:ja:Thailand': '輸出（前年比）',
  'qing_ming_jie.title:ja:DEFAULT': '清明節',
  'qing_ming_jie.title:ja:China': '清明節',
  'rbnz_gov_orr_speech.title:ja:DEFAULT': 'RBNZオア総裁講演',
  'rbnz_gov_orr_speech.title:ja:New Zealand': 'RBNZオア総裁講演',
  'rbnz_gov_wheeler_speech.title:ja:DEFAULT': 'RBNZウィーラー総裁講演',
  'rbnz_gov_wheeler_speech.title:ja:New Zealand': 'RBNZウィーラー総裁講演',
  'national_day_of_the_peoples_republic_of_china.title:ja:DEFAULT': '中華人民共和国国慶節',
  'national_day_of_the_peoples_republic_of_china.title:ja:Hong Kong': '中華人民共和国国慶節',
  'reinz_house_price_index_mom.title:ja:DEFAULT': 'REINZ住宅価格指数（前月比）',
  'reinz_house_price_index_mom.title:ja:New Zealand': 'REINZ住宅価格指数（前月比）',
  'nonfarm_productivity_qoq_final.title:ja:DEFAULT': '非農業部門生産性（前四半期比）（確報値）',
  'nonfarm_productivity_qoq_final.title:ja:United States':
    '非農業部門生産性（前四半期比）（確報値）',
  'last_day_flag_referendum.title:ja:DEFAULT': '国旗変更国民投票最終日',
  'last_day_flag_referendum.title:ja:New Zealand': '国旗変更国民投票最終日',
  'halifax_house_price_index_yoy.title:ja:DEFAULT': 'ハリファックス住宅価格指数（前年比）',
  'halifax_house_price_index_yoy.title:ja:United Kingdom': 'ハリファックス住宅価格指数（前年比）',
  'pce_prices_qoq_-_2_est.title:ja:DEFAULT': 'PCE価格指数（前四半期比）（2次速報）',
  'pce_prices_qoq_-_2_est.title:ja:United States': 'PCE価格指数（前四半期比）（2次速報）',
  'riksbank_interest_rate.title:ja:DEFAULT': 'リクスバンク金利',
  'riksbank_interest_rate.title:ja:Sweden': 'リクスバンク金利',
  'tokyo_cpi_yoy.title:ja:DEFAULT': '東京都区部消費者物価指数（前年比）',
  'tokyo_cpi_yoy.title:ja:Japan': '東京都区部消費者物価指数（前年比）',
  'the_queen_s_birthday_substitute.title:ja:DEFAULT': '女王陛下誕生日（振替休日）',
  'the_queen_s_birthday_substitute.title:ja:Thailand': '女王陛下誕生日（振替休日）',
  'rba_weighted_mean_cpi_yoy.title:ja:DEFAULT': 'RBA加重平均消費者物価指数（前年比）',
  'rba_weighted_mean_cpi_yoy.title:ja:Australia': 'RBA加重平均消費者物価指数（前年比）',
  'boe_woods_speech.title:ja:DEFAULT': 'イングランド銀行ウッズ副総裁講演',
  'boe_woods_speech.title:ja:United Kingdom': 'イングランド銀行ウッズ副総裁講演',
  'gdp_growth_rate_yoy_prel.title:ja:DEFAULT': 'GDP成長率（前年比）（速報値）',
  'gdp_growth_rate_yoy_prel.title:ja:Euro Area': 'GDP成長率（前年比）（速報値）',
  'gdp_growth_rate_yoy_prel.title:ja:Netherlands': 'GDP成長率（前年比）（速報値）',
  'gdp_growth_rate_yoy_prel.title:ja:Spain': 'GDP成長率（前年比）（速報値）',
  'gdp_growth_rate_yoy_prel.title:ja:South Korea': 'GDP成長率（前年比）（速報値）',
  'gdp_growth_rate_yoy_prel.title:ja:Russia': 'GDP成長率（前年比）（速報値）',
  'gdp_growth_rate_yoy_prel.title:ja:Greece': 'GDP成長率（前年比）（速報値）',
  'gdp_growth_rate_yoy_prel.title:ja:Hungary': 'GDP成長率（前年比）（速報値）',
  'gdp_growth_rate_yoy_prel.title:ja:Denmark': 'GDP成長率（前年比）（速報値）',
  'gdp_growth_rate_yoy_prel.title:ja:Portugal': 'GDP成長率（前年比）（速報値）',
  'gdp_growth_rate_yoy_prel.title:ja:United Kingdom': 'GDP成長率（前年比）（速報値）',
  'gdp_growth_rate_yoy_prel.title:ja:Czech Republic': 'GDP成長率（前年比）（速報値）',
  'gdp_growth_rate_yoy_prel.title:ja:France': 'GDP成長率（前年比）（速報値）',
  'gdp_growth_rate_yoy_prel.title:ja:Mexico': 'GDP成長率（前年比）（速報値）',
  'gdp_growth_rate_yoy_prel.title:ja:Poland': 'GDP成長率（前年比）（速報値）',
  'gdp_growth_rate_yoy_prel.title:ja:Finland': 'GDP成長率（前年比）（速報値）',
  'fed_daly_speech.title:ja:DEFAULT': 'サンフランシスコ地区連銀デイリー総裁講演',
  'fed_daly_speech.title:ja:United States': 'サンフランシスコ地区連銀デイリー総裁講演',
  'chicago_pmi.title:ja:DEFAULT': 'シカゴ購買担当者景気指数（PMI）',
  'chicago_pmi.title:ja:United States': 'シカゴ購買担当者景気指数（PMI）',
  'nationwide_housing_prices_yoy.title:ja:DEFAULT': '全国住宅価格指数（前年比）',
  'nationwide_housing_prices_yoy.title:ja:United Kingdom': '全国住宅価格指数（前年比）',
  'services_pmi.title:ja:DEFAULT': 'サービス業購買担当者景気指数（PMI）',
  'services_pmi.title:ja:Spain': 'サービス業購買担当者景気指数（PMI）',
  'services_pmi.title:ja:Germany': 'サービス業購買担当者景気指数（PMI）',
  'services_pmi.title:ja:France': 'サービス業購買担当者景気指数（PMI）',
  'services_pmi.title:ja:Sweden': 'サービス業購買担当者景気指数（PMI）',
  'premier_li_keqiang_speech.title:ja:DEFAULT': '李克強国務院総理演説',
  'premier_li_keqiang_speech.title:ja:China': '李克強国務院総理演説',
  'singaporean_pmi.title:ja:DEFAULT': 'シンガポール購買担当者景気指数（PMI）',
  'singaporean_pmi.title:ja:Singapore': 'シンガポール購買担当者景気指数（PMI）',
  'non-farm_payrolls_qoq.title:ja:DEFAULT': '非農業部門雇用者数（前四半期比）',
  'non-farm_payrolls_qoq.title:ja:France': '非農業部門雇用者数（前四半期比）',
  'caixin_services_pmi.title:ja:DEFAULT': '財新サービス業購買担当者景気指数（PMI）',
  'caixin_services_pmi.title:ja:China': '財新サービス業購買担当者景気指数（PMI）',
  'private_consumption_mom.title:ja:DEFAULT': '民間消費（前月比）',
  'private_consumption_mom.title:ja:Thailand': '民間消費（前月比）',
  'household_consumption_mom.title:ja:DEFAULT': '家計支出（前月比）',
  'household_consumption_mom.title:ja:Norway': '家計支出（前月比）',
  'household_consumption_mom.title:ja:Sweden': '家計支出（前月比）',
  'household_consumption_mom.title:ja:France': '家計支出（前月比）',
  'tokyo_cpi_ex_food_energy_yoy.title:ja:DEFAULT':
    '東京区部食品とエネルギーを除く消費者物価指数（前年比）',
  'tokyo_cpi_ex_food_energy_yoy.title:ja:Japan':
    '東京区部食品とエネルギーを除く消費者物価指数（前年比）',
  'pce_price_index_mom.title:ja:DEFAULT': 'PCE価格指数（前月比）',
  'pce_price_index_mom.title:ja:United States': 'PCE価格指数（前月比）',
  'eco_watchers_survey_outlook.title:ja:DEFAULT': '景気ウォッチャー調査：先行き',
  'eco_watchers_survey_outlook.title:ja:Japan': '景気ウォッチャー調査：先行き',
  'ifo_business_climate_final.title:ja:DEFAULT': 'Ifo企業景況感指数（確報値）',
  'ifo_business_climate_final.title:ja:Germany': 'Ifo企業景況感指数（確報値）',
  'fed_yellen_testifies.title:ja:DEFAULT': 'FRBイエレン議長証言',
  'fed_yellen_testifies.title:ja:United States': 'FRBイエレン議長証言',
  'second_day_of_christmas.title:ja:DEFAULT': 'クリスマス翌日',
  'second_day_of_christmas.title:ja:Poland': 'クリスマス翌日',
  'budget_2021.title:ja:DEFAULT': '2021年予算',
  'budget_2021.title:ja:United Kingdom': '2021年予算',
  'fed_chair_yellen_speaks.title:ja:DEFAULT': 'FRBイエレン議長講演',
  'fed_chair_yellen_speaks.title:ja:United States': 'FRBイエレン議長講演',
  'rba_trimmed_mean_cpi_qoq.title:ja:DEFAULT': 'RBAトリム平均消費者物価指数（前四半期比）',
  'rba_trimmed_mean_cpi_qoq.title:ja:Australia': 'RBAトリム平均消費者物価指数（前四半期比）',
  'budget_proposal.title:ja:DEFAULT': '予算案',
  'budget_proposal.title:ja:India': '予算案',
  'wholesale_prices_n_s_a_mom.title:ja:DEFAULT': '季節調整前卸売価格（前月比）',
  'wholesale_prices_n_s_a_mom.title:ja:Austria': '季節調整前卸売価格（前月比）',
  '10-year_bund_auction.title:ja:DEFAULT': 'ドイツ10年物国債入札',
  '10-year_bund_auction.title:ja:Germany': 'ドイツ10年物国債入札',
  'st_cyril_and_methodius_day.title:ja:DEFAULT': '聖キュリロスと聖メトディウスの日',
  'st_cyril_and_methodius_day.title:ja:Czech Republic': '聖キュリロスと聖メトディウスの日',
  'rbnz_inflation_expectations_yoy.title:ja:DEFAULT': 'RBNZインフレ期待（前年比）',
  'rbnz_inflation_expectations_yoy.title:ja:New Zealand': 'RBNZインフレ期待（前年比）',
  'core_pce_prices_qoq_adv.title:ja:DEFAULT': 'コアPCE価格（前四半期比）（速報値）',
  'core_pce_prices_qoq_adv.title:ja:United States': 'コアPCE価格（前四半期比）（速報値）',
  'jp_foreign_reserves.title:ja:DEFAULT': '日本外貨準備高',
  'jp_foreign_reserves.title:ja:Japan': '日本外貨準備高',
  'ecb_constancio_speech.title:ja:DEFAULT': 'ECBコンスタンシオ副総裁講演',
  'ecb_constancio_speech.title:ja:Euro Area': 'ECBコンスタンシオ副総裁講演',
  'snb_studer_speech.title:ja:DEFAULT': 'スイス国立銀行スチューダー氏講演',
  'snb_studer_speech.title:ja:Switzerland': 'スイス国立銀行スチューダー氏講演',
  'prime_rate.title:ja:DEFAULT': 'プライムレート',
  'prime_rate.title:ja:South Africa': 'プライムレート',
  'showa_day.title:ja:DEFAULT': '昭和の日',
  'showa_day.title:ja:Japan': '昭和の日',
  'foreign_bond_investment.title:ja:DEFAULT': '外国債券投資',
  'foreign_bond_investment.title:ja:Japan': '外国債券投資',
  'jibun_bank_services_pmi.title:ja:DEFAULT': 'じぶん銀行サービス業購買担当者景気指数（PMI）',
  'jibun_bank_services_pmi.title:ja:Japan': 'じぶん銀行サービス業購買担当者景気指数（PMI）',
  'total_social_financing.title:ja:DEFAULT': '社会融資総量',
  'total_social_financing.title:ja:China': '社会融資総量',
  'rba_gov_lowe_speech.title:ja:DEFAULT': 'RBAロウ総裁講演',
  'rba_gov_lowe_speech.title:ja:Australia': 'RBAロウ総裁講演',
  'foreign_currency_reserves.title:ja:DEFAULT': '外貨準備高',
  'foreign_currency_reserves.title:ja:Switzerland': '外貨準備高',
  'ascension_day_of_jesus_christ.title:ja:DEFAULT': 'キリスト昇天祭',
  'ascension_day_of_jesus_christ.title:ja:Indonesia': 'キリスト昇天祭',
  'ecb_monetary_policy_statement.title:ja:DEFAULT': 'ECB金融政策発表',
  'ecb_monetary_policy_statement.title:ja:Euro Area': 'ECB金融政策発表',
  'tourist_arrivals.title:ja:DEFAULT': '',
  'tourist_arrivals.title:ja:Spain': '',
  'trade_balance_mom.title:ja:DEFAULT': '貿易収支（前月比）',
  'trade_balance_mom.title:ja:New Zealand': '貿易収支（前月比）',
  'snb_chair_jordan_speech.title:ja:DEFAULT': 'スイス国立銀行ジョルダン総裁講演',
  'snb_chair_jordan_speech.title:ja:Switzerland': 'スイス国立銀行ジョルダン総裁講演',
  'ecb_fernandez-bollo_speech.title:ja:DEFAULT': '',
  'ecb_fernandez-bollo_speech.title:ja:Euro Area': '',
  'third_day_of_lunar_new_year.title:ja:DEFAULT': '旧正月3日目',
  'third_day_of_lunar_new_year.title:ja:Hong Kong': '旧正月3日目',
  'employment_cost_-_benefits_qoq.title:ja:DEFAULT': '雇用コスト―福利厚生（前四半期比）',
  'employment_cost_-_benefits_qoq.title:ja:United States': '雇用コスト―福利厚生（前四半期比）',
  'treasury_sec_lew_speaks.title:ja:DEFAULT': 'ルー財務長官演説',
  'treasury_sec_lew_speaks.title:ja:United States': 'ルー財務長官演説',
  'constitution_memorial_day_substitute_day.title:ja:DEFAULT': '憲法記念日（振替休日）',
  'constitution_memorial_day_substitute_day.title:ja:Japan': '憲法記念日（振替休日）',
  'consumer_confidence.title:ja:DEFAULT': '消費者信頼感指数',
  'consumer_confidence.title:ja:United States': '消費者信頼感指数',
  'consumer_confidence.title:ja:Taiwan': '消費者信頼感指数',
  'consumer_confidence.title:ja:Euro Area': '消費者信頼感指数',
  'consumer_confidence.title:ja:Philippines': '消費者信頼感指数',
  'consumer_confidence.title:ja:Colombia': '消費者信頼感指数',
  'consumer_confidence.title:ja:Thailand': '消費者信頼感指数',
  'consumer_confidence.title:ja:Indonesia': '消費者信頼感指数',
  'consumer_confidence.title:ja:Norway': '消費者信頼感指数',
  'consumer_confidence.title:ja:Mexico': '消費者信頼感指数',
  'consumer_confidence.title:ja:Japan': '消費者信頼感指数',
  'consumer_confidence.title:ja:Israel': '消費者信頼感指数',
  'consumer_confidence.title:ja:Ireland': '消費者信頼感指数',
  'consumer_confidence.title:ja:Finland': '消費者信頼感指数',
  'consumer_confidence.title:ja:Greece': '消費者信頼感指数',
  'consumer_confidence.title:ja:Portugal': '消費者信頼感指数',
  'consumer_confidence.title:ja:Italy': '消費者信頼感指数',
  'consumer_confidence.title:ja:Netherlands': '消費者信頼感指数',
  'consumer_confidence.title:ja:France': '消費者信頼感指数',
  'consumer_confidence.title:ja:South Africa': '消費者信頼感指数',
  'consumer_confidence.title:ja:Belgium': '消費者信頼感指数',
  'consumer_confidence.title:ja:Russia': '消費者信頼感指数',
  'consumer_confidence.title:ja:Czech Republic': '消費者信頼感指数',
  'consumer_confidence.title:ja:Spain': '消費者信頼感指数',
  'consumer_confidence.title:ja:Denmark': '消費者信頼感指数',
  'consumer_confidence.title:ja:Austria': '消費者信頼感指数',
  'consumer_confidence.title:ja:Sweden': '消費者信頼感指数',
  'consumer_confidence.title:ja:South Korea': '消費者信頼感指数',
  'consumer_confidence.title:ja:Brazil': '消費者信頼感指数',
  'consumer_confidence.title:ja:Switzerland': '消費者信頼感指数',
  'ppi_output_yoy.title:ja:DEFAULT': '生産者物価指数（PPI）アウトプット（前年比）',
  'ppi_output_yoy.title:ja:United Kingdom': '生産者物価指数（PPI）アウトプット（前年比）',
  'world_economic_forum_-_davos.title:ja:DEFAULT': 'ダボス世界経済フォーラム',
  'world_economic_forum_-_davos.title:ja:Switzerland': 'ダボス世界経済フォーラム',
  'good_friday.title:ja:DEFAULT': '聖金曜日',
  'good_friday.title:ja:Hungary': '聖金曜日',
  'good_friday.title:ja:United States': '聖金曜日',
  'good_friday.title:ja:Netherlands': '聖金曜日',
  'good_friday.title:ja:Switzerland': '聖金曜日',
  'good_friday.title:ja:Brazil': '聖金曜日',
  'good_friday.title:ja:Belgium': '聖金曜日',
  'good_friday.title:ja:France': '聖金曜日',
  'good_friday.title:ja:South Africa': '聖金曜日',
  'good_friday.title:ja:Australia': '聖金曜日',
  'good_friday.title:ja:Singapore': '聖金曜日',
  'good_friday.title:ja:Germany': '聖金曜日',
  'good_friday.title:ja:Austria': '聖金曜日',
  'good_friday.title:ja:Sweden': '聖金曜日',
  'good_friday.title:ja:Finland': '聖金曜日',
  'good_friday.title:ja:Colombia': '聖金曜日',
  'good_friday.title:ja:Philippines': '聖金曜日',
  'good_friday.title:ja:Hong Kong': '聖金曜日',
  'good_friday.title:ja:Canada': '聖金曜日',
  'good_friday.title:ja:Indonesia': '聖金曜日',
  'good_friday.title:ja:United Kingdom': '聖金曜日',
  'good_friday.title:ja:Ireland': '聖金曜日',
  'good_friday.title:ja:Denmark': '聖金曜日',
  'good_friday.title:ja:Czech Republic': '聖金曜日',
  'good_friday.title:ja:Spain': '聖金曜日',
  'good_friday.title:ja:India': '聖金曜日',
  'good_friday.title:ja:New Zealand': '聖金曜日',
  'good_friday.title:ja:Mexico': '聖金曜日',
  'good_friday.title:ja:Norway': '聖金曜日',
  'good_friday.title:ja:Portugal': '聖金曜日',
  'snb_maechler_speech.title:ja:DEFAULT': 'スイス国立銀行メクラ―理事講演',
  'snb_maechler_speech.title:ja:Switzerland': 'スイス国立銀行メクラ―理事講演',
  'wage_inflation_mom.title:ja:DEFAULT': '賃金インフレ（前月比）',
  'wage_inflation_mom.title:ja:Italy': '賃金インフレ（前月比）',
  'external_debt.title:ja:DEFAULT': '対外債務',
  'external_debt.title:ja:South Korea': '対外債務',
  'external_debt.title:ja:India': '対外債務',
  'external_debt.title:ja:Czech Republic': '対外債務',
  'no_trading.title:ja:DEFAULT': '取引なし',
  'no_trading.title:ja:Taiwan': '取引なし',
  'davivienda_manufacturing_pmi.title:ja:DEFAULT':
    'ダヴィヴィエンダ製造業購買担当者景気指数（PMI）',
  'davivienda_manufacturing_pmi.title:ja:Colombia':
    'ダヴィヴィエンダ製造業購買担当者景気指数（PMI）',
  'fed_yellen_testimony_hfsc.title:ja:DEFAULT': 'FRBイエレン議長証言HFSC',
  'fed_yellen_testimony_hfsc.title:ja:United States': 'FRBイエレン議長証言HFSC',
  'legislative_elections.title:ja:DEFAULT': '議会選挙',
  'legislative_elections.title:ja:Colombia': '議会選挙',
  'legislative_elections.title:ja:Israel': '議会選挙',
  'private_new_capital_expenditure_qoq.title:ja:DEFAULT': '民間企業新規設備投資（前四半期比）',
  'private_new_capital_expenditure_qoq.title:ja:Australia': '民間企業新規設備投資（前四半期比）',
  'last_day_of_pesach_passover.title:ja:DEFAULT': '過越祭最終日',
  'last_day_of_pesach_passover.title:ja:Israel': '過越祭最終日',
  'house_of_councillors_election.title:ja:DEFAULT': '参議院選挙',
  'house_of_councillors_election.title:ja:Japan': '参議院選挙',
  'rics_house_price_balance.title:ja:DEFAULT': 'RICS住宅価格バランス',
  'rics_house_price_balance.title:ja:United Kingdom': 'RICS住宅価格バランス',
  'unit_labour_costs_qoq_prel.title:ja:DEFAULT': '単位労働コスト（前四半期比）（速報値）',
  'unit_labour_costs_qoq_prel.title:ja:United States': '単位労働コスト（前四半期比）（速報値）',
  'gnp_yoy.title:ja:DEFAULT': 'GNP（国民総生産）（前年比）',
  'gnp_yoy.title:ja:Ireland': 'GNP（国民総生産）（前年比）',
  'personal_consumption_expenditure_yoy.title:ja:DEFAULT': '個人消費支出（PCE）（前年比）',
  'personal_consumption_expenditure_yoy.title:ja:United States': '個人消費支出（PCE）（前年比）',
  'total_business_investment_yoy.title:ja:DEFAULT': '企業投資合計（前年比）',
  'total_business_investment_yoy.title:ja:United Kingdom': '企業投資合計（前年比）',
  'independence_day_observed.title:ja:DEFAULT': '独立記念日（祝日）',
  'independence_day_observed.title:ja:United States': '独立記念日（祝日）',
  'factory_orders_mom.title:ja:DEFAULT': '工場受注（前月比）',
  'factory_orders_mom.title:ja:United States': '工場受注（前月比）',
  'factory_orders_mom.title:ja:Germany': '工場受注（前月比）',
  'us_budget_plan_fy_2022.title:ja:DEFAULT': '米国2022年度予算案',
  'us_budget_plan_fy_2022.title:ja:United States': '米国2022年度予算案',
  'marine_day.title:ja:DEFAULT': '海の日',
  'marine_day.title:ja:Japan': '海の日',
  'fed_rosengren_speech.title:ja:DEFAULT': 'FRBローゼングレン氏講演',
  'fed_rosengren_speech.title:ja:United States': 'FRBローゼングレン氏講演',
  'saxony-anhalt_state_election.title:ja:DEFAULT': 'ザクセン・アンハルト州選挙',
  'saxony-anhalt_state_election.title:ja:Germany': 'ザクセン・アンハルト州選挙',
  'us_monetary_policy_forum.title:ja:DEFAULT': '米国金融政策フォーラム',
  'us_monetary_policy_forum.title:ja:United States': '米国金融政策フォーラム',
  'spring_meeting_of_the_world_bank_group-imf.title:ja:DEFAULT': '世界銀行グループ・IMF春季会合',
  'spring_meeting_of_the_world_bank_group-imf.title:ja:United States':
    '世界銀行グループ・IMF春季会合',
  'reuters_michigan_consumer_sentiment_prel.title:ja:DEFAULT':
    'ロイター・ミシガン大学消費者信頼感指数（速報値）',
  'reuters_michigan_consumer_sentiment_prel.title:ja:United States':
    'ロイター・ミシガン大学消費者信頼感指数速報値',
  'economic_sentiment.title:ja:DEFAULT': '景況感指数',
  'economic_sentiment.title:ja:Euro Area': '景況感指数',
  'tiradentes_day.title:ja:DEFAULT': 'チラデンテスの日(ブラジル独立運動の英雄の日)',
  'tiradentes_day.title:ja:Brazil': 'チラデンチスの日',
  'chancellor_hammond_budget_statement.title:ja:DEFAULT': 'ハモンド財務相予算声明',
  'chancellor_hammond_budget_statement.title:ja:United Kingdom': 'ハモンド財務相予算声明',
  'fx_reserves.title:ja:DEFAULT': '外貨準備',
  'fx_reserves.title:ja:Switzerland': '外貨準備',
  'fx_reserves.title:ja:Czech Republic': '外貨準備',
  'westpac_mni_consumer_sentiment_indicator.title:ja:DEFAULT': 'ウエストパックMNI消費者信頼感指数',
  'westpac_mni_consumer_sentiment_indicator.title:ja:China': 'ウエストパックMNI消費者信頼感指数',
  'president-elect_trump_speech.title:ja:DEFAULT': '米トランプ次期大統領の演説',
  'president-elect_trump_speech.title:ja:United States': '米トランプ次期大統領の演説',
  'private_spending_qoq.title:ja:DEFAULT': '民間支出（前四半期比）',
  'private_spending_qoq.title:ja:Mexico': '民間支出（前四半期比）',
  'yom_haatzmaut.title:ja:DEFAULT': 'イスラエル独立記念日',
  'yom_haatzmaut.title:ja:Israel': 'イスラエル独立記念日',
  'boj_kuroda_speech.title:ja:DEFAULT': '日本銀行黒田総裁講演',
  'boj_kuroda_speech.title:ja:Japan': '日本銀行黒田総裁講演',
  'us_gov_temporary_funding_expires.title:ja:DEFAULT': '米政府暫定予算期限',
  'us_gov_temporary_funding_expires.title:ja:United States': '米政府暫定予算期限',
  'trade_balance_yoy.title:ja:DEFAULT': '貿易収支（前年比）',
  'trade_balance_yoy.title:ja:New Zealand': '貿易収支（前年比）',
  'us-russia_meeting.title:ja:DEFAULT': '米国・ロシア間会合',
  'us-russia_meeting.title:ja:Switzerland': '米国・ロシア間会合',
  'usmca_trade_deal_signature.title:ja:DEFAULT': '米国・メキシコ・カナダ（USMCA）貿易協定調印',
  'usmca_trade_deal_signature.title:ja:United States':
    '米国・メキシコ・カナダ（USMCA）貿易協定調印',
  'orthodox_whit_monday.title:ja:DEFAULT': '正教会ウィットマンデー',
  'orthodox_whit_monday.title:ja:Greece': '正教会ウィットマンデー',
  'employment_change_yoy_prel.title:ja:DEFAULT': '雇用変化（前年比）（速報値）',
  'employment_change_yoy_prel.title:ja:Euro Area': '雇用変化（前年比）（速報値）',
  'summit_on_brexit.title:ja:DEFAULT': 'ブレグジットに関する首脳会議',
  'summit_on_brexit.title:ja:European Union': 'ブレグジットに関する首脳会議',
  'christmas_day_observed.title:ja:DEFAULT': 'クリスマス（祝日）',
  'christmas_day_observed.title:ja:Hong Kong': 'クリスマス（祝日）',
  'establishment_of_the_czechoslovak_republic.title:ja:DEFAULT': 'チェコスロバキア共和国の設立',
  'establishment_of_the_czechoslovak_republic.title:ja:Czech Republic':
    'チェコスロバキア共和国の設立',
  'ecb_nouy_speech.title:ja:DEFAULT': 'ECBヌイ委員長講演',
  'ecb_nouy_speech.title:ja:Euro Area': 'ECBヌイ委員長講演',
  'early_may_bank_holiday.title:ja:DEFAULT': '５月初旬のバンク・ホリデー',
  'early_may_bank_holiday.title:ja:United Kingdom': '５月初旬のバンク・ホリデー',
  'senate_election.title:ja:DEFAULT': '上院選挙',
  'senate_election.title:ja:Czech Republic': '上院選挙',
  'current_account.title:ja:DEFAULT': '経常収支',
  'current_account.title:ja:Switzerland': '経常収支',
  'current_account.title:ja:Brazil': '経常収支',
  'current_account.title:ja:Greece': '経常収支',
  'current_account.title:ja:Hong Kong': '経常収支',
  'current_account.title:ja:Netherlands': '経常収支',
  'current_account.title:ja:Italy': '経常収支',
  'current_account.title:ja:Colombia': '経常収支',
  'current_account.title:ja:Euro Area': '経常収支',
  'current_account.title:ja:Singapore': '経常収支',
  'current_account.title:ja:Japan': '経常収支',
  'current_account.title:ja:Mexico': '経常収支',
  'current_account.title:ja:Norway': '経常収支',
  'current_account.title:ja:Austria': '経常収支',
  'current_account.title:ja:Sweden': '経常収支',
  'current_account.title:ja:Australia': '経常収支',
  'current_account.title:ja:Indonesia': '経常収支',
  'current_account.title:ja:Canada': '経常収支',
  'current_account.title:ja:Taiwan': '経常収支',
  'current_account.title:ja:South Korea': '経常収支',
  'current_account.title:ja:United Kingdom': '経常収支',
  'current_account.title:ja:Ireland': '経常収支',
  'current_account.title:ja:Hungary': '経常収支',
  'current_account.title:ja:Israel': '経常収支',
  'current_account.title:ja:United States': '経常収支',
  'current_account.title:ja:Thailand': '経常収支',
  'current_account.title:ja:Germany': '経常収支',
  'current_account.title:ja:Poland': '経常収支',
  'current_account.title:ja:Belgium': '経常収支',
  'current_account.title:ja:France': '経常収支',
  'current_account.title:ja:South Africa': '経常収支',
  'current_account.title:ja:Russia': '経常収支',
  'current_account.title:ja:Spain': '経常収支',
  'current_account.title:ja:Czech Republic': '経常収支',
  'current_account.title:ja:Denmark': '経常収支',
  'current_account.title:ja:Finland': '経常収支',
  'current_account.title:ja:India': '経常収支',
  'current_account.title:ja:New Zealand': '経常収支',
  'current_account.title:ja:China': '経常収支',
  'current_account.title:ja:Portugal': '経常収支',
  'may_no_confidence_vote_result.title:ja:DEFAULT': 'メイ首相不信任投票結果',
  'may_no_confidence_vote_result.title:ja:United Kingdom': 'メイ首相不信任投票結果',
  'labor_productivity_qoq.title:ja:DEFAULT': '労働生産性（前四半期比）',
  'labor_productivity_qoq.title:ja:Canada': '労働生産性（前四半期比）',
  'ifo_current_conditions.title:ja:DEFAULT': 'IFO現況',
  'ifo_current_conditions.title:ja:Germany': 'IFO現況',
  'eia_heating_oil_stocks_change.title:ja:DEFAULT': 'EIA暖房油在庫変化',
  'eia_heating_oil_stocks_change.title:ja:United States': 'EIA暖房油在庫変化',
  'nzier_capacity_utilization.title:ja:DEFAULT': 'NZIER設備稼働率',
  'nzier_capacity_utilization.title:ja:New Zealand': 'NZIER設備稼働率',
  'gdp_growth_mainland_qoq_final.title:ja:DEFAULT': '本土GDP成長率（前四半期比）（確報値）',
  'gdp_growth_mainland_qoq_final.title:ja:Norway': '本土GDP成長率（前四半期比）（確報値）',
  'queen_s_birthday_day.title:ja:DEFAULT': '女王陛下誕生日',
  'queen_s_birthday_day.title:ja:Australia': '女王陛下誕生日',
  'queen_s_birthday_day.title:ja:New Zealand': '女王陛下誕生日',
  'unemployed_persons_nsa.title:ja:DEFAULT': '季節調整前失業者数',
  'unemployed_persons_nsa.title:ja:Germany': '季節調整前失業者数',
  'spring_bank_holiday.title:ja:DEFAULT': '春季のバンク・ホリデー',
  'spring_bank_holiday.title:ja:United Kingdom': '春季のバンク・ホリデー',
  'hsbc_manufacturing_pmi.title:ja:DEFAULT': 'HSBC製造業購買担当者景気指数（PMI）',
  'hsbc_manufacturing_pmi.title:ja:China': 'HSBC製造業購買担当者景気指数（PMI）',
  'hsbc_manufacturing_pmi.title:ja:Hong Kong': 'HSBC製造業購買担当者景気指数（PMI）',
  'hsbc_manufacturing_pmi.title:ja:Indonesia': 'HSBC製造業購買担当者景気指数（PMI）',
  'hsbc_manufacturing_pmi.title:ja:South Korea': 'HSBC製造業購買担当者景気指数（PMI）',
  'hsbc_manufacturing_pmi.title:ja:Taiwan': 'HSBC製造業購買担当者景気指数（PMI）',
  'hsbc_manufacturing_pmi.title:ja:India': 'HSBC製造業購買担当者景気指数（PMI）',
  'hsbc_manufacturing_pmi.title:ja:Poland': 'HSBC製造業購買担当者景気指数（PMI）',
  'hsbc_manufacturing_pmi.title:ja:Brazil': 'HSBC製造業購買担当者景気指数（PMI）',
  'hsbc_manufacturing_pmi.title:ja:Russia': 'HSBC製造業購買担当者景気指数（PMI）',
  'hsbc_manufacturing_pmi.title:ja:Czech Republic': 'HSBC製造業購買担当者景気指数（PMI）',
  'hsbc_manufacturing_pmi.title:ja:Mexico': 'HSBC製造業購買担当者景気指数（PMI）',
  'unemployment_rate_-_final.title:ja:DEFAULT': '失業率（確報値）',
  'unemployment_rate_-_final.title:ja:Singapore': '失業率（確報値）',
  'net_payrolls.title:ja:DEFAULT': '雇用者純増数',
  'net_payrolls.title:ja:Brazil': '雇用者純増数',
  'third_and_fourth_day_of_chinese_new_year.title:ja:DEFAULT': '中国旧正月（春節）の3日目と4日目',
  'third_and_fourth_day_of_chinese_new_year.title:ja:Hong Kong': '中国旧正月（春節）の3日目と4日目',
  'unit_labour_costs_qoq.title:ja:DEFAULT': '単位労働コスト（前四半期比）',
  'unit_labour_costs_qoq.title:ja:United States': '単位労働コスト（前四半期比）',
  'government_budget_value.title:ja:DEFAULT': '政府予算額',
  'government_budget_value.title:ja:Brazil': '政府予算額',
  'government_budget_value.title:ja:India': '政府予算額',
  'government_budget_value.title:ja:Mexico': '政府予算額',
  'unemployment_rate_final.title:ja:DEFAULT': '失業率（確報値）',
  'unemployment_rate_final.title:ja:Singapore': '失業率（確報値）',
  'danmarks_nationalbank_economic_forecast.title:ja:DEFAULT': 'デンマーク国立銀行経済予測',
  'danmarks_nationalbank_economic_forecast.title:ja:Denmark': 'デンマーク国立銀行経済予測',
  'unit_labor_costs_qoq.title:ja:DEFAULT': '単位労働コスト（前四半期比）',
  'unit_labor_costs_qoq.title:ja:United States': '単位労働コスト（前四半期比）',
  'eu-japan_summit.title:ja:DEFAULT': 'EU日本首脳会議',
  'eu-japan_summit.title:ja:Japan': 'EU日本首脳会議',
  'eu-japan_summit.title:ja:Euro Area': 'EU日本首脳会議',
  'russia-africa_summit.title:ja:DEFAULT': 'ロシア・アフリカ首脳会議',
  'russia-africa_summit.title:ja:Russia': 'ロシア・アフリカ首脳会議',
  'st_joseph_s_day.title:ja:DEFAULT': '聖ヨセフの日',
  'st_joseph_s_day.title:ja:Spain': '聖ヨセフの日',
  'holiday.title:ja:DEFAULT': '祝日',
  'holiday.title:ja:Japan': '祝日',
  'cb_leading_indicator.title:ja:DEFAULT': 'コンファレンスボード（CB）先行指数',
  'cb_leading_indicator.title:ja:Australia': 'コンファレンスボード（CB）先行指数',
  'un_general_assembly.title:ja:DEFAULT': '国連総会',
  'un_general_assembly.title:ja:United States': '国連総会',
  'td-mi_inflation_gauge_mom.title:ja:DEFAULT': 'TD─MIインフレ指数（前月比）',
  'td-mi_inflation_gauge_mom.title:ja:Australia': 'TD─MIインフレ指数（前月比）',
  'referendum_on_constitutional_reform.title:ja:DEFAULT': '憲法改正国民投票',
  'referendum_on_constitutional_reform.title:ja:Italy': '憲法改正国民投票',
  'ecb_president_draghi_speaks.title:ja:DEFAULT': 'ECBドラギ総裁講演',
  'ecb_president_draghi_speaks.title:ja:Euro Area': 'ECBドラギ総裁講演',
  'joe_biden_s_inauguration.title:ja:DEFAULT': 'ジョー・バイデン大統領就任',
  'joe_biden_s_inauguration.title:ja:United States': 'ジョー・バイデン大統領就任',
  'new_motor_vehicle_sales.title:ja:DEFAULT': '新車販売台数',
  'new_motor_vehicle_sales.title:ja:Canada': '新車販売台数',
  'simchat_torah_shemini_atzeret.title:ja:DEFAULT':
    'シムハット・トーラー（シェミニ・アツェレット）',
  'simchat_torah_shemini_atzeret.title:ja:Israel': 'シムハット・トーラー（シェミニ・アツェレット）',
  'boe_inflation_report_hearings.title:ja:DEFAULT': 'BOEインフレ報告聴聞会',
  'boe_inflation_report_hearings.title:ja:United Kingdom': 'BOEインフレ報告聴聞会',
  'producer_price_index_qoq.title:ja:DEFAULT': '生産者価格指数（前四半期比）',
  'producer_price_index_qoq.title:ja:Australia': '生産者価格指数（前四半期比）',
  'wholesale_sales_mom_final.title:ja:DEFAULT': '卸売売上高（前月比）（確報値）',
  'wholesale_sales_mom_final.title:ja:Canada': '卸売売上高（前月比）（確報値）',
  'core_pce_prices_qoq.title:ja:DEFAULT': 'コアPCE価格（前四半期比）',
  'core_pce_prices_qoq.title:ja:United States': 'コアPCE価格（前四半期比）',
  'regional_elections_-_1st_round.title:ja:DEFAULT': '地方選挙―第1回投票',
  'regional_elections_-_1st_round.title:ja:France': '地方選挙―第1回投票',
  'ecb_president_lagarde_testimony.title:ja:DEFAULT': 'ECBラガルド総裁証言',
  'ecb_president_lagarde_testimony.title:ja:Euro Area': 'ECBラガルド総裁証言',
  'rba_edey_speech.title:ja:DEFAULT': 'RBAエディ総裁補講演',
  'rba_edey_speech.title:ja:Australia': 'RBAエディ総裁補講演',
  'anz_job_advertisements_mom.title:ja:DEFAULT': 'ANZ求人広告件数（前月比）',
  'anz_job_advertisements_mom.title:ja:Australia': 'ANZ求人広告件数（前月比）',
  'unemployment_rate.title:ja:DEFAULT': '失業率',
  'unemployment_rate.title:ja:Brazil': '失業率',
  'the_queens_birthday.title:ja:DEFAULT': '女王陛下誕生日',
  'the_queens_birthday.title:ja:Thailand': '女王陛下誕生日',
  'fed_evans_speech.title:ja:DEFAULT': 'シカゴ地区連銀エバンス総裁講演',
  'fed_evans_speech.title:ja:Euro Area': 'シカゴ地区連銀エバンス総裁講演',
  'fed_evans_speech.title:ja:United States': 'シカゴ地区連銀エバンス総裁講演',
  'union_budget_2020.title:ja:DEFAULT': '2020年度連合予算',
  'union_budget_2020.title:ja:India': '2020年度連合予算',
  'uk-eu_brexit_talks.title:ja:DEFAULT': '英国EUブレグジット交渉',
  'uk-eu_brexit_talks.title:ja:United Kingdom': '英国EUブレグジット交渉',
  '6-month_t-bill_auction.title:ja:DEFAULT': '6カ月割引債入札',
  '6-month_t-bill_auction.title:ja:Netherlands': '6カ月割引債入札',
  'union_budget_2021-22.title:ja:DEFAULT': '202-22年度連合予算',
  'union_budget_2021-22.title:ja:India': '202-22年度連合予算',
  'reuters_michigan_consumer_expectations.title:ja:DEFAULT': 'ロイター・ミシガン大学消費者信頼感',
  'reuters_michigan_consumer_expectations.title:ja:United States':
    'ロイター・ミシガン大学消費者信頼感',
  'ecofin_video_conference.title:ja:DEFAULT': 'エコフィン（経済財務相理事会）ビデオ会議',
  'ecofin_video_conference.title:ja:European Union': 'エコフィン（経済財務相理事会）ビデオ会議',
  'rbnz_wheeler_speech.title:ja:DEFAULT': 'RBNZウィーラー総裁講演',
  'rbnz_wheeler_speech.title:ja:New Zealand': 'RBNZウィーラー総裁講演',
  'unit_labor_cost_qoq.title:ja:DEFAULT': '単位労働コスト（前四半期比）',
  'unit_labor_cost_qoq.title:ja:United States': '単位労働コスト（前四半期比）',
  'april_monetary_policy.title:ja:DEFAULT': '4月の金融政策',
  'april_monetary_policy.title:ja:Singapore': '4月の金融政策',
  'inflation_rate_ex-fresh_food_yoy.title:ja:DEFAULT': '生鮮食品を除くインフレ率（前年比）',
  'inflation_rate_ex-fresh_food_yoy.title:ja:Japan': '生鮮食品を除くインフレ率（前年比）',
  'palm_sunday.title:ja:DEFAULT': 'パーム・サンデー',
  'palm_sunday.title:ja:Denmark': 'パーム・サンデー',
  'palm_sunday.title:ja:Norway': 'パーム・サンデー',
  'gdp_growth_rate_annualized.title:ja:DEFAULT': 'GDP成長率（年率）',
  'gdp_growth_rate_annualized.title:ja:Canada': 'GDP成長率（年率）',
  'wholesale_prices_nsa_yoy.title:ja:DEFAULT': '季節調整前卸売価格（前年比）',
  'wholesale_prices_nsa_yoy.title:ja:Austria': '季節調整前卸売価格（前年比）',
  '13-weeks_bill_auction.title:ja:DEFAULT': '13週割引債入札',
  '13-weeks_bill_auction.title:ja:Greece': '13週割引債入札',
  'average_weekly_hours.title:ja:DEFAULT': '週平均労働時間',
  'average_weekly_hours.title:ja:United States': '週平均労働時間',
  'ise_economic_activity_yoy.title:ja:DEFAULT': 'ISE経済活動前年比',
  'ise_economic_activity_yoy.title:ja:Colombia': 'ISE経済活動前年比',
  '5-year_btp_auction.title:ja:DEFAULT': 'イタリア5年物国債入札',
  '5-year_btp_auction.title:ja:Italy': 'イタリア5年物国債入札',
  'restoration_of_independence_day.title:ja:DEFAULT': '独立回復記念日',
  'restoration_of_independence_day.title:ja:Portugal': '独立回復記念日',
  'gdp_growth_annualized_prel.title:ja:DEFAULT': 'GDP年率成長率速報値',
  'gdp_growth_annualized_prel.title:ja:Japan': 'GDP年率成長率速報値',
  'gdp_annual_growth_rate_yoy_-_preliminary.title:ja:DEFAULT': 'GDP年率成長率（前年比）（速報値）',
  'gdp_annual_growth_rate_yoy_-_preliminary.title:ja:Greece': 'GDP年率成長率（前年比）（速報値）',
  'eurogroup_meeting.title:ja:DEFAULT': 'ユーロ圏財務相会合',
  'eurogroup_meeting.title:ja:Euro Area': 'ユーロ圏財務相会合',
  'eu-india_summit.title:ja:DEFAULT': 'EUインド首脳会議',
  'eu-india_summit.title:ja:European Union': 'EUインド首脳会議',
  'eu-india_summit.title:ja:India': 'EUインド首脳会議',
  'prime_minister_modi_speech.title:ja:DEFAULT': 'モディ首相演説',
  'prime_minister_modi_speech.title:ja:India': 'モディ首相演説',
  'markit_cips_manufacturing_pmi_flash.title:ja:DEFAULT':
    'マークイット/CIPS製造業購買担当者景況指数（PMI）（速報値）',
  'markit_cips_manufacturing_pmi_flash.title:ja:United Kingdom':
    'マークイット/CIPS製造業購買担当者景況指数（PMI）（速報値）',
  'new_year_s_day.title:ja:DEFAULT': '元旦',
  'new_year_s_day.title:ja:Thailand': '元旦',
  'new_year_s_day.title:ja:Taiwan': '元旦',
  'new_year_s_day.title:ja:Switzerland': '元旦',
  'new_year_s_day.title:ja:Singapore': '元旦',
  'new_year_s_day.title:ja:Portugal': '元旦',
  'new_year_s_day.title:ja:Spain': '元旦',
  'new_year_s_day.title:ja:Russia': '元旦',
  'new_year_s_day.title:ja:Denmark': '元旦',
  'new_year_s_day.title:ja:Czech Republic': '元旦',
  'new_year_s_day.title:ja:Brazil': '元旦',
  'new_year_s_day.title:ja:Sweden': '元旦',
  'new_year_s_day.title:ja:Austria': '元旦',
  'new_year_s_day.title:ja:United Kingdom': '元旦',
  'new_year_s_day.title:ja:Ireland': '元旦',
  'new_year_s_day.title:ja:Canada': '元旦',
  'new_year_s_day.title:ja:Indonesia': '元旦',
  'new_year_s_day.title:ja:Australia': '元旦',
  'new_year_s_day.title:ja:Italy': '元旦',
  'new_year_s_day.title:ja:Netherlands': '元旦',
  'new_year_s_day.title:ja:Finland': '元旦',
  'new_year_s_day.title:ja:Colombia': '元旦',
  'new_year_s_day.title:ja:Philippines': '元旦',
  'new_year_s_day.title:ja:Belgium': '元旦',
  'new_year_s_day.title:ja:France': '元旦',
  'new_year_s_day.title:ja:Norway': '元旦',
  'new_year_s_day.title:ja:Mexico': '元旦',
  'new_year_s_day.title:ja:Poland': '元旦',
  'new_year_s_day.title:ja:Germany': '元旦',
  'new_year_s_day.title:ja:Greece': '元旦',
  'new_year_s_day.title:ja:Hong Kong': '元旦',
  'new_year_s_day.title:ja:Hungary': '元旦',
  'new_year_s_day.title:ja:New Zealand': '元旦',
  'summer_bank_holiday.title:ja:DEFAULT': '夏季のバンク・ホリデー',
  'summer_bank_holiday.title:ja:United Kingdom': '夏季のバンク・ホリデー',
  'producer_price_index_ex_food_energy_yoy.title:ja:DEFAULT':
    '食品とエネルギーを除く生産者価格指数（前年比）',
  'producer_price_index_ex_food_energy_yoy.title:ja:United States':
    '食品とエネルギーを除く生産者価格指数（前年比）',
  'markit_cips_composite_pmi_final.title:ja:DEFAULT':
    'マークイット/CIPS総合購買担当者景況指数（PMI）（確報値）',
  'markit_cips_composite_pmi_final.title:ja:United Kingdom':
    'マークイット/CIPS総合購買担当者景況指数（PMI）（確報値）',
  'rizal_day.title:ja:DEFAULT': 'リサール記念日',
  'rizal_day.title:ja:Philippines': 'リサール記念日',
  'fdi_ytd_yoy.title:ja:DEFAULT': '海外直接投資（年初来）前年比',
  'fdi_ytd_yoy.title:ja:China': '海外直接投資（年初来）前年比',
  'westpac_consumer_confidence_index.title:ja:DEFAULT': 'ウエストパック消費者信頼感指数',
  'westpac_consumer_confidence_index.title:ja:Australia': 'ウエストパック消費者信頼感指数',
  'employment_cost_-_wages_qoq.title:ja:DEFAULT': '雇用コスト―賃金（前四半期比）',
  'employment_cost_-_wages_qoq.title:ja:United States': '雇用コスト―賃金（前四半期比）',
  'state_of_the_nation_address.title:ja:DEFAULT': '国民演説',
  'state_of_the_nation_address.title:ja:South Africa': '国民演説',
  'ny_fed_treasury_purchases_7_5_to_30_yrs.title:ja:DEFAULT': '',
  'ny_fed_treasury_purchases_7_5_to_30_yrs.title:ja:United States': '',
  'business_climate.title:ja:DEFAULT': '企業景況感指数',
  'business_climate.title:ja:Euro Area': '企業景況感指数',
  'terms_of_trade_index.title:ja:DEFAULT': '交易条件指数',
  'terms_of_trade_index.title:ja:New Zealand': '交易条件指数',
  'usd_consumption_indicator.title:ja:DEFAULT': 'USD消費指数',
  'usd_consumption_indicator.title:ja:Switzerland': 'USD消費指数',
  'ecb_general_council_meeting.title:ja:DEFAULT': 'ECB一般理事会会合',
  'ecb_general_council_meeting.title:ja:Euro Area': 'ECB一般理事会会合',
  'rba_boulton_speech.title:ja:DEFAULT': 'RBAブルトン氏講演',
  'rba_boulton_speech.title:ja:Australia': 'RBAブルトン氏講演',
  'rejoicing_of_the_law.title:ja:DEFAULT': 'シムハット・トラー（律法の感謝祭）',
  'rejoicing_of_the_law.title:ja:Israel': 'シムハット・トラー（律法の感謝祭）',
  'new_year_s_day.title:ja:South Korea': '元旦',
  'new_year_s_day.title:ja:Japan': '元旦',
  'new_year_s_day.title:ja:South Africa': '元旦',
  'new_year_s_day.title:ja:China': '元旦',
  'new_year_s_day.title:ja:United States': '元旦',
  'industrial_production_mom_prel.title:ja:DEFAULT': '工業生産(前月比)速報値',
  'industrial_production_mom_prel.title:ja:Japan': '工業生産(前月比)速報値',
  'new_housing_price_index_yoy.title:ja:DEFAULT': '新築住宅価格指数（前年比）',
  'new_housing_price_index_yoy.title:ja:Canada': '新築住宅価格指数（前年比）',
  'niesr_monthly_gdp_tracker.title:ja:DEFAULT': 'NIESR月次GDPトラッカー',
  'niesr_monthly_gdp_tracker.title:ja:United Kingdom': 'NIESR月次GDPトラッカー',
  'swedbank_manufacturing_pmi.title:ja:DEFAULT': 'スウェドバンク製造業購買担当者景気指数（PMI）',
  'swedbank_manufacturing_pmi.title:ja:Sweden': 'スウェドバンク製造業購買担当者景気指数（PMI）',
  'wholesale_prices_nsa_mom.title:ja:DEFAULT': '季節調整前卸売価格（前月比）',
  'wholesale_prices_nsa_mom.title:ja:Austria': '季節調整前卸売価格（前月比）',
  'the_ochi_day.title:ja:DEFAULT': '参戦記念日',
  'the_ochi_day.title:ja:Greece': '参戦記念日',
  'balance_of_trade_-_final.title:ja:DEFAULT': '貿易収支－確報値',
  'balance_of_trade_-_final.title:ja:South Korea': '貿易収支－確報値',
  'jobs_applications_ratio.title:ja:DEFAULT': '有効求人倍率',
  'jobs_applications_ratio.title:ja:Japan': '有効求人倍率',
  'virgin_of_guadalupe_day.title:ja:DEFAULT': 'グアダルーペの聖母の祝日',
  'virgin_of_guadalupe_day.title:ja:Mexico': 'グアダルーペの聖母の祝日',
  'housing_starts.title:ja:DEFAULT': '住宅着工件数',
  'housing_starts.title:ja:United States': '住宅着工件数',
  'housing_starts.title:ja:Canada': '住宅着工件数',
  'home_loans_mom.title:ja:DEFAULT': '住宅ローン（前月比）',
  'home_loans_mom.title:ja:Australia': '住宅ローン（前月比）',
  'wwii_victory_day.title:ja:DEFAULT': '第二次世界大戦戦勝記念日',
  'wwii_victory_day.title:ja:France': '第二次世界大戦戦勝記念日',
  'president_trump_and_president_xi_jinping_meeting.title:ja:DEFAULT':
    'トランプ大統領習近平主席首脳会談',
  'president_trump_and_president_xi_jinping_meeting.title:ja:United States':
    'トランプ大統領習近平主席首脳会談',
  'nzier_business_confidence_qoq.title:ja:DEFAULT': 'NZIER企業信頼感指数（前四半期比）',
  'nzier_business_confidence_qoq.title:ja:New Zealand': 'NZIER企業信頼感指数（前四半期比）',
  'total_net_tic_flows.title:ja:DEFAULT': '合計ネットTICフロー（対米証券投資）',
  'total_net_tic_flows.title:ja:United States': '合計ネットTICフロー（対米証券投資）',
  'cnb_interest_rate_decision.title:ja:DEFAULT': 'チェコ国立銀行（CNB）政策金利決定',
  'cnb_interest_rate_decision.title:ja:Czech Republic': 'チェコ国立銀行（CNB）政策金利決定',
  'raw_materials_price_index_yoy.title:ja:DEFAULT': '原材料価格指数（前年比）',
  'raw_materials_price_index_yoy.title:ja:Canada': '原材料価格指数（前年比）',
  'feast_of_purim_jerusalem.title:ja:DEFAULT': 'プリム祭（エルサレム）',
  'feast_of_purim_jerusalem.title:ja:Israel': 'プリム祭（エルサレム）',
  'queen_suthida_s_birthday.title:ja:DEFAULT': 'スティダー王妃誕生日',
  'queen_suthida_s_birthday.title:ja:Thailand': 'スティダー王妃誕生日',
  'unemployment_rate.title:ja:Italy': '失業率',
  'ura_property_index_qoq_prel.title:ja:DEFAULT': 'URA住宅価格指数（前四半期比）速報値',
  'ura_property_index_qoq_prel.title:ja:Singapore': 'URA住宅価格指数（前四半期比）速報値',
  'primary_budget_surplus.title:ja:DEFAULT': 'プライマリーバランス黒字',
  'primary_budget_surplus.title:ja:Brazil': 'プライマリーバランス黒字',
  'unemployment_rate.title:ja:Austria': '失業率',
  'wholesale_prices_yoy.title:ja:DEFAULT': '卸売価格（前年比）',
  'wholesale_prices_yoy.title:ja:India': '卸売価格（前年比）',
  'wholesale_prices_yoy.title:ja:Germany': '卸売価格（前年比）',
  'wholesale_prices_yoy.title:ja:Taiwan': '卸売価格（前年比）',
  'wholesale_prices_yoy.title:ja:Austria': '卸売価格（前年比）',
  'wholesale_prices_yoy.title:ja:Ireland': '卸売価格（前年比）',
  'pm_abe_press_conference.title:ja:DEFAULT': '安倍首相記者会見',
  'pm_abe_press_conference.title:ja:Japan': '安倍首相記者会見',
  'brc_retail_sales_monitor_-_all_yoy.title:ja:DEFAULT':
    'BRC英小売協会売上高モニター-全売上高(前年比）',
  'brc_retail_sales_monitor_-_all_yoy.title:ja:United Kingdom':
    'BRC英小売協会売上高モニター全売上高（前年比）',
  'jibun_bank_services_pmi_flash.title:ja:DEFAULT':
    'じぶん銀行サービス業購買担当者景気指数（PMI）速報値',
  'jibun_bank_services_pmi_flash.title:ja:Japan':
    'じぶん銀行サービス業購買担当者景気指数（PMI）速報値',
  'balance_of_trade_s_a.title:ja:DEFAULT': '季節調整後貿易収支',
  'balance_of_trade_s_a.title:ja:Germany': '季節調整後貿易収支',
  'industrial_production_yoy_prel.title:ja:DEFAULT': '鉱工業生産（前年比）速報値',
  'industrial_production_yoy_prel.title:ja:Japan': '鉱工業生産（前年比）速報値',
  'us_budget_plan_fy_2021.title:ja:DEFAULT': '米国2021年度予算案',
  'us_budget_plan_fy_2021.title:ja:United States': '米国2021年度予算案',
  'winter_economy_plan.title:ja:DEFAULT': '冬季経済計画',
  'winter_economy_plan.title:ja:United Kingdom': '冬季経済計画',
  'yom_haatzmaut_independence_day.title:ja:DEFAULT': 'イスラエル独立記念日',
  'yom_haatzmaut_independence_day.title:ja:Israel': 'イスラエル独立記念日',
  'wholesale_prices_n_s_a_yoy.title:ja:DEFAULT': '季節調整前卸売価格（前年比）',
  'wholesale_prices_n_s_a_yoy.title:ja:Austria': '季節調整前卸売価格（前年比）',
  'euro-south_korea_summit.title:ja:DEFAULT': 'ユーロ圏・韓国首脳会談',
  'euro-south_korea_summit.title:ja:Euro Area': 'ユーロ圏・韓国首脳会談',
  'industrail_sales_mom.title:ja:DEFAULT': '鉱工業売上高（前月比）',
  'industrail_sales_mom.title:ja:Italy': '鉱工業売上高（前月比）',
  'womens_day.title:ja:DEFAULT': '女性デ―',
  'womens_day.title:ja:South Africa': '女性デ―',
  'northern_ireland_assembly_election.title:ja:DEFAULT': '北アイルランド議会選挙',
  'northern_ireland_assembly_election.title:ja:United Kingdom': '北アイルランド議会選挙',
  'westpac_cons_conf_change.title:ja:DEFAULT': 'ウエストパック消費者信頼感変化',
  'westpac_cons_conf_change.title:ja:Australia': 'ウエストパック消費者信頼感変化',
  'us-north_korea_summit.title:ja:DEFAULT': '米国・北朝鮮首脳会談',
  'us-north_korea_summit.title:ja:United States': '米国・北朝鮮首脳会談',
  'purchasing_manager_index_services.title:ja:DEFAULT': '購買担当者指数サービス業',
  'purchasing_manager_index_services.title:ja:Ireland': '購買担当者指数サービス業',
  'industrial_production_yoy.title:ja:DEFAULT': '鉱工業生産（前年比）',
  'industrial_production_yoy.title:ja:United States': '鉱工業生産（前年比）',
  'industrial_production_yoy.title:ja:Hong Kong': '鉱工業生産（前年比）',
  'industrial_production_yoy.title:ja:New Zealand': '鉱工業生産（前年比）',
  'industrial_production_yoy.title:ja:India': '鉱工業生産（前年比）',
  'industrial_production_yoy.title:ja:Austria': '鉱工業生産（前年比）',
  'industrial_production_yoy.title:ja:Sweden': '鉱工業生産（前年比）',
  'industrial_production_yoy.title:ja:Poland': '鉱工業生産（前年比）',
  'industrial_production_yoy.title:ja:Germany': '鉱工業生産（前年比）',
  'industrial_production_yoy.title:ja:Brazil': '鉱工業生産（前年比）',
  'industrial_production_yoy.title:ja:Taiwan': '鉱工業生産（前年比）',
  'industrial_production_yoy.title:ja:South Korea': '鉱工業生産（前年比）',
  'industrial_production_yoy.title:ja:Japan': '鉱工業生産（前年比）',
  'industrial_production_yoy.title:ja:Singapore': '鉱工業生産（前年比）',
  'industrial_production_yoy.title:ja:Switzerland': '鉱工業生産（前年比）',
  'industrial_production_yoy.title:ja:Thailand': '鉱工業生産（前年比）',
  'industrial_production_yoy.title:ja:Israel': '鉱工業生産（前年比）',
  'industrial_production_yoy.title:ja:Hungary': '鉱工業生産（前年比）',
  'industrial_production_yoy.title:ja:Russia': '鉱工業生産（前年比）',
  'industrial_production_yoy.title:ja:Czech Republic': '鉱工業生産（前年比）',
  'industrial_production_yoy.title:ja:Denmark': '鉱工業生産（前年比）',
  'industrial_production_yoy.title:ja:Spain': '鉱工業生産（前年比）',
  'industrial_production_yoy.title:ja:United Kingdom': '鉱工業生産（前年比）',
  'industrial_production_yoy.title:ja:Ireland': '鉱工業生産（前年比）',
  'industrial_production_yoy.title:ja:Mexico': '鉱工業生産（前年比）',
  'industrial_production_yoy.title:ja:Norway': '鉱工業生産（前年比）',
  'industrial_production_yoy.title:ja:Finland': '鉱工業生産（前年比）',
  'industrial_production_yoy.title:ja:Colombia': '鉱工業生産（前年比）',
  'industrial_production_yoy.title:ja:Euro Area': '鉱工業生産（前年比）',
  'industrial_production_yoy.title:ja:Philippines': '鉱工業生産（前年比）',
  'industrial_production_yoy.title:ja:South Africa': '鉱工業生産（前年比）',
  'industrial_production_yoy.title:ja:Belgium': '鉱工業生産（前年比）',
  'industrial_production_yoy.title:ja:France': '鉱工業生産（前年比）',
  'industrial_production_yoy.title:ja:Netherlands': '鉱工業生産（前年比）',
  'industrial_production_yoy.title:ja:Italy': '鉱工業生産（前年比）',
  'industrial_production_yoy.title:ja:Portugal': '鉱工業生産（前年比）',
  'industrial_production_yoy.title:ja:China': '鉱工業生産（前年比）',
  'industrial_production_yoy.title:ja:Greece': '鉱工業生産（前年比）',
  'wholesale_sales_yoy.title:ja:DEFAULT': '卸売価格（前年比）',
  'wholesale_sales_yoy.title:ja:Canada': '卸売価格（前年比）',
  'tankan_small_non-manufacturing_index.title:ja:DEFAULT': '日銀短観中小企業・非製造業業況判断指数',
  'tankan_small_non-manufacturing_index.title:ja:Japan': '日銀短観中小企業・非製造業業況判断指数',
  'construction_output_yoy.title:ja:DEFAULT': '建設部門生産高（前年比）',
  'construction_output_yoy.title:ja:Italy': '建設部門生産高（前年比）',
  'construction_output_yoy.title:ja:Hungary': '建設部門生産高（前年比）',
  'construction_output_yoy.title:ja:South Korea': '建設部門生産高（前年比）',
  'construction_output_yoy.title:ja:Belgium': '建設部門生産高（前年比）',
  'construction_output_yoy.title:ja:Euro Area': '建設部門生産高（前年比）',
  'construction_output_yoy.title:ja:Greece': '建設部門生産高（前年比）',
  'construction_output_yoy.title:ja:United Kingdom': '建設部門生産高（前年比）',
  'construction_output_yoy.title:ja:Czech Republic': '建設部門生産高（前年比）',
  'construction_output_yoy.title:ja:Sweden': '建設部門生産高（前年比）',
  'construction_output_yoy.title:ja:Ireland': '建設部門生産高（前年比）',
  'presidential_election.title:ja:DEFAULT': '大統領選挙',
  'presidential_election.title:ja:Portugal': '大統領選挙',
  'presidential_election.title:ja:United States': '大統領選挙',
  'presidential_election.title:ja:Austria': '大統領選挙',
  'presidential_election.title:ja:Czech Republic': '大統領選挙',
  'presidential_election.title:ja:Poland': '大統領選挙',
  'presidential_election.title:ja:Brazil': '大統領選挙',
  'presidential_election.title:ja:Taiwan': '大統領選挙',
  'presidential_election.title:ja:Finland': '大統領選挙',
  'presidential_election.title:ja:Russia': '大統領選挙',
  'private_sector_credit_mom.title:ja:DEFAULT': '民間部門信用供与(前月比）',
  'private_sector_credit_mom.title:ja:Australia': '民間部門信用供与(前月比）',
  'non_farm_payrolls_qoq_prel.title:ja:DEFAULT': '非農業部門雇用者数（前四半期比）（速報値）',
  'non_farm_payrolls_qoq_prel.title:ja:France': '非農業部門雇用者数（前四半期比）（速報値）',
  'existing_home_sales_mom.title:ja:DEFAULT': '中古住宅販売件数（前月比）',
  'existing_home_sales_mom.title:ja:United States': '中古住宅販売件数（前月比）',
  'reuters_michigan_current_conditions_prel.title:ja:DEFAULT':
    'ロイター・ミシガン大学消費者信頼感現況指数（速報値）',
  'reuters_michigan_current_conditions_prel.title:ja:United States':
    'ロイター・ミシガン大学消費者信頼感現況指数（速報値）',
  'new_motor_vehicle_sales_yoy.title:ja:DEFAULT': '新車販売台数(前年比)',
  'new_motor_vehicle_sales_yoy.title:ja:Australia': '新車販売台数(前年比)',
  'wholesale_sales_mom_prel.title:ja:DEFAULT': '卸売売上高（前月比）（速報値）',
  'wholesale_sales_mom_prel.title:ja:Canada': '卸売売上高（前月比）（速報値）',
  'rba_governor_stevens_speaks.title:ja:DEFAULT':
    'オーストラリア準備銀行（RBA）スティーブンズ総裁演説',
  'rba_governor_stevens_speaks.title:ja:Australia':
    'オーストラリア準備銀行（RBA）スティーブンズ総裁演説',
  'the_foundation_day_of_korea.title:ja:DEFAULT': '韓国独立記念日',
  'the_foundation_day_of_korea.title:ja:South Korea': '韓国独立記念日',
  'vappu_may_day.title:ja:DEFAULT': 'ヴァップ（メイデー）',
  'vappu_may_day.title:ja:Finland': 'ヴァップ（メイデー）',
  'mortgage_lending.title:ja:DEFAULT': '住宅ローン貸出額',
  'mortgage_lending.title:ja:United Kingdom': '住宅ローン貸出額',
  'labour_cost_index_yoy.title:ja:DEFAULT': '労働コスト指数（前年比）',
  'labour_cost_index_yoy.title:ja:New Zealand': '労働コスト指数（前年比）',
  'non_manufacturing_pmi.title:ja:DEFAULT': '非製造業購買担当者景気指数（PMI）',
  'non_manufacturing_pmi.title:ja:China': '非製造業購買担当者景気指数（PMI）',
  'gdp_growth_rate_qoq_adv.title:ja:DEFAULT': 'GDP成長率（前四半期比）（速報値）',
  'gdp_growth_rate_qoq_adv.title:ja:Czech Republic': 'GDP成長率（前四半期比）（速報値）',
  'gdp_growth_rate_qoq_adv.title:ja:Hong Kong': 'GDP成長率（前四半期比）（速報値）',
  'gdp_growth_rate_qoq_adv.title:ja:Denmark': 'GDP成長率（前四半期比）（速報値）',
  'gdp_growth_rate_qoq_adv.title:ja:Italy': 'GDP成長率（前四半期比）（速報値）',
  'gdp_growth_rate_qoq_adv.title:ja:Singapore': 'GDP成長率（前四半期比）（速報値）',
  'gdp_growth_rate_qoq_adv.title:ja:United States': 'GDP成長率（前四半期比）（速報値）',
  'gdp_growth_rate_qoq_adv.title:ja:South Korea': 'GDP成長率（前四半期比）（速報値）',
  'gdp_growth_rate_qoq_adv.title:ja:Belgium': 'GDP成長率（前四半期比）（速報値）',
  'eu-wide_stress_test_results.title:ja:DEFAULT': 'EU圏銀行ストレステスト結果',
  'eu-wide_stress_test_results.title:ja:European Union': 'EU圏銀行ストレステスト結果',
  'unemployment_rate_prel.title:ja:DEFAULT': '失業率（速報値）',
  'unemployment_rate_prel.title:ja:Singapore': '失業率（速報値）',
  '2020_state_of_the_nation_address.title:ja:DEFAULT': '2020年一般教書演説',
  '2020_state_of_the_nation_address.title:ja:South Africa': '2020年一般教書演説',
  'national_activity_index.title:ja:DEFAULT': '全米活動指数',
  'national_activity_index.title:ja:United States': '全米活動指数',
  'victory_day.title:ja:DEFAULT': '戦勝記念日',
  'victory_day.title:ja:Russia': '戦勝記念日',
  'victory_day.title:ja:China': '戦勝記念日',
  'greek_third_bailout_program_s_vote.title:ja:DEFAULT': 'ギリシャ第三次金融支援プログラム投票',
  'greek_third_bailout_program_s_vote.title:ja:Greece': 'ギリシャ第三次金融支援プログラム投票',
  'reuters_michigan_current_conditions.title:ja:DEFAULT':
    'ロイター・ミシガン大学消費者信頼感現況指数',
  'reuters_michigan_current_conditions.title:ja:United States':
    'ロイター・ミシガン大学消費者信頼感現況指数',
  '3-year_bond_auction.title:ja:DEFAULT': '',
  '3-year_bond_auction.title:ja:Canada': '',
  'nbb_business_confidence.title:ja:DEFAULT': 'ベルギー国立銀行（NBB）企業信頼感指数',
  'nbb_business_confidence.title:ja:Belgium': 'ベルギー国立銀行（NBB）企業信頼感指数',
  'general_prayer_day.title:ja:DEFAULT': '大祈禱日',
  'general_prayer_day.title:ja:Denmark': '大祈禱日',
  'feast_of_saint_peter_and_saint_paul.title:ja:DEFAULT': '聖ペトロと聖パウロの祝日',
  'feast_of_saint_peter_and_saint_paul.title:ja:Colombia': '聖ペトロと聖パウロの祝日',
  'yom_kippur.title:ja:DEFAULT': 'ヨム・キプル',
  'yom_kippur.title:ja:Israel': 'ヨム・キプル',
  'visakha_bucha.title:ja:DEFAULT': 'ウィサーカ・ブーチャー（仏誕節）',
  'visakha_bucha.title:ja:Thailand': 'ウィサーカ・ブーチャー（仏誕節）',
  'national_core_inflation_rate_yoy.title:ja:DEFAULT': 'コア・インフレ率（前年比）',
  'national_core_inflation_rate_yoy.title:ja:Japan': 'コア・インフレ率（前年比）',
  'seollal_holiday_day_1.title:ja:DEFAULT': 'ソルラル（旧正月）初日',
  'seollal_holiday_day_1.title:ja:South Korea': 'ソルラル（旧正月）初日',
  'gdp_growth_rate_qoq_2nd_est.title:ja:DEFAULT': 'GDP成長率(前四半期比)第二次推計値',
  'gdp_growth_rate_qoq_2nd_est.title:ja:Israel': 'GDP成長率(前四半期比)第二次推計値',
  'gdp_growth_rate_qoq_2nd_est.title:ja:Poland': 'GDP成長率(前四半期比)第二次推計値',
  'ppi_input_yoy.title:ja:DEFAULT': '生産者物価指数（PPI）投入物価（前年比）',
  'ppi_input_yoy.title:ja:United Kingdom': '生産者物価指数（PPI）投入物価（前年比）',
  '12-month_letras_auction.title:ja:DEFAULT': 'スペイン12カ月物国債入札',
  '12-month_letras_auction.title:ja:Spain': 'スペイン12カ月物国債入札',
  'national_committee_of_the_cppcc.title:ja:DEFAULT': '中国人民政治協商会議全国委員会',
  'national_committee_of_the_cppcc.title:ja:China': '中国人民政治協商会議全国委員会',
  'tisha_b_av.title:ja:DEFAULT': 'ティシュアー・ベ＝アーブ',
  'tisha_b_av.title:ja:Israel': 'ティシュアー・ベ＝アーブ',
  'president_trump_speech.title:ja:DEFAULT': '米トランプ大統領演説',
  'president_trump_speech.title:ja:United States': '米トランプ大統領演説',
  'eia_refinery_crude_runs_change.title:ja:DEFAULT': '米国エネルギー情報局（EIA）原油精製量変化',
  'eia_refinery_crude_runs_change.title:ja:United States':
    '米国エネルギー情報局（EIA）原油精製量変化',
  'uk_finance_mortgage_approvals.title:ja:DEFAULT': '英国住宅ローン承認件数',
  'uk_finance_mortgage_approvals.title:ja:United Kingdom': '英国住宅ローン承認件数',
  'boe_gov_bailey_testimony.title:ja:DEFAULT': '',
  'boe_gov_bailey_testimony.title:ja:United Kingdom': '',
  'production_price_index_yoy.title:ja:DEFAULT': '生産価格指数（前年比）',
  'production_price_index_yoy.title:ja:South Africa': '生産価格指数（前年比）',
  'gdp_private_consumption_qoq_final.title:ja:DEFAULT': 'GDP民間消費（前四半期比）（確報値）',
  'gdp_private_consumption_qoq_final.title:ja:Japan': 'GDP民間消費（前四半期比）（確報値）',
  'referendum_on_responsible_business_initiative.title:ja:DEFAULT':
    '責任ある企業イニシアチブ国民投票',
  'referendum_on_responsible_business_initiative.title:ja:Switzerland':
    '責任ある企業イニシアチブ国民投票',
  'rba_deputy_gov_lowe_speech.title:ja:DEFAULT': 'オーストラリア準備銀行（RBA）ロウ総裁講演',
  'rba_deputy_gov_lowe_speech.title:ja:Australia': 'オーストラリア準備銀行（RBA）ロウ総裁講演',
  'manufacturing_sales_mom_prel.title:ja:DEFAULT': '製造業売上高（前月比）（速報値）',
  'manufacturing_sales_mom_prel.title:ja:Canada': '製造業売上高（前月比）（速報値）',
  'cb_governor_oystein_olsen_speech.title:ja:DEFAULT':
    'ノルウェー銀行（CB）オイスタイン・オルセン総裁講演',
  'cb_governor_oystein_olsen_speech.title:ja:Norway':
    'ノルウェー銀行（CB）オイスタイン・オルセン総裁講演',
  'pesach_vii.title:ja:DEFAULT': 'ペサハVII',
  'pesach_vii.title:ja:Israel': 'ペサハVII',
  'pce_prices_qoq_final.title:ja:DEFAULT': '個人消費支出（PCE）価格（前四半期比）（確報値）',
  'pce_prices_qoq_final.title:ja:United States': '個人消費支出（PCE）価格（前四半期比）（確報値）',
  'global_dairy_trade_price_index.title:ja:DEFAULT': 'グローバル酪農貿易価格指数',
  'global_dairy_trade_price_index.title:ja:New Zealand': 'グローバル酪農貿易価格指数',
  'tokyo_core_yoy.title:ja:DEFAULT': '東京都区部コア消費者物価指数（前年比）',
  'tokyo_core_yoy.title:ja:Japan': '東京都区部コア消費者物価指数（前年比）',
  'national_day_golden_week.title:ja:DEFAULT': '',
  'national_day_golden_week.title:ja:China': '',
  'ppi_yoy_prel.title:ja:DEFAULT': '生産者物価指数（PPI）（前年比）（速報値）',
  'ppi_yoy_prel.title:ja:Canada': '生産者物価指数（PPI）（前年比）（速報値）',
  'zew_survey_-_expectations.title:ja:DEFAULT': 'ZEW景況感指数',
  'zew_survey_-_expectations.title:ja:Switzerland': 'ZEW景況感指数',
  'wisakha_bucha_day.title:ja:DEFAULT': 'ウィサーカ・ブーチャー（仏誕節）',
  'wisakha_bucha_day.title:ja:Thailand': 'ウィサーカ・ブーチャー（仏誕節）',
  'national_cpi_ex_food_energy_yoy.title:ja:DEFAULT':
    '食品とエネルギーを除く全国消費者物価指数（前年比）',
  'national_cpi_ex_food_energy_yoy.title:ja:Japan':
    '食品とエネルギーを除く全国消費者物価指数（前年比）',
  'gdp_price_index_qoq_final.title:ja:DEFAULT': 'GDP価格指数（前四半期比）（確報値）',
  'gdp_price_index_qoq_final.title:ja:United States': 'GDP価格指数（前四半期比）（確報値）',
  'fed_richard_fisher_speech.title:ja:DEFAULT': 'FRBリチャード・フィッシャー氏演説',
  'fed_richard_fisher_speech.title:ja:United States': 'FRBリチャード・フィッシャー氏演説',
  'budget_speech.title:ja:DEFAULT': '政府予算収支演説',
  'budget_speech.title:ja:South Africa': '政府予算収支演説',
  'net_lending_to_individuals.title:ja:DEFAULT': '個人向け純貸出',
  'net_lending_to_individuals.title:ja:United Kingdom': '個人向け純貸出',
  'retail_sales_mom_final.title:ja:DEFAULT': '小売売上高（前月比）（確報値）',
  'retail_sales_mom_final.title:ja:Australia': '小売売上高（前月比）（確報値）',
  'pmi.title:ja:DEFAULT': '購買担当者景気指数（PMI）',
  'pmi.title:ja:Singapore': '購買担当者景気指数（PMI）',
  'reuters_michigan_current_conditions_final.title:ja:DEFAULT':
    'ロイター・ミシガン大学消費者信頼感現況指数（確報値）',
  'reuters_michigan_current_conditions_final.title:ja:United States':
    'ロイター・ミシガン大学消費者信頼感現況指数（確報値）',
  'loans_to_households_yoy.title:ja:DEFAULT': '家計向け融資額（前年比）',
  'loans_to_households_yoy.title:ja:Euro Area': '家計向け融資額（前年比）',
  'zew_investor_sentiment.title:ja:DEFAULT': '欧州経済研究センター（ZEW）投資家景況感指数',
  'zew_investor_sentiment.title:ja:Switzerland': '欧州経済研究センター（ZEW）投資家景況感指数',
  'bakri_id.title:ja:DEFAULT': 'バクリ・イード',
  'bakri_id.title:ja:India': 'バクリ・イード',
  'ny_fed_treasury_purchases_10_to_22_5_yrs.title:ja:DEFAULT': 'ニューヨーク連銀10〜22.5年国債購入',
  'ny_fed_treasury_purchases_10_to_22_5_yrs.title:ja:United States':
    'ニューヨーク連銀10〜22.5年国債購入',
  'business_investment_yoy_final.title:ja:DEFAULT': '企業投資（前年比）（確報値）',
  'business_investment_yoy_final.title:ja:United Kingdom': '企業投資（前年比）（確報値）',
  'reuters_michigan_consumer_expectations_prel.title:ja:DEFAULT':
    'ロイター・ミシガン大学消費者信頼感（速報値）',
  'reuters_michigan_consumer_expectations_prel.title:ja:United States':
    'ロイター・ミシガン大学消費者信頼感（速報値）',
  'tankan_small_manufacturing_outlook.title:ja:DEFAULT': '日銀短観中小企業・製造業先行き判断',
  'tankan_small_manufacturing_outlook.title:ja:Japan': '日銀短観中小企業・製造業先行き判断',
  'gdp_external_demand_qoq_prel.title:ja:DEFAULT': 'GDP外需（前四半期比）（速報値）',
  'gdp_external_demand_qoq_prel.title:ja:Japan': 'GDP外需（前四半期比）（速報値）',
  'yom_kippur_eve.title:ja:DEFAULT': 'ヨム・キプル前夜',
  'yom_kippur_eve.title:ja:Israel': 'ヨム・キプル前夜',
  'eia_distillate_stocks.title:ja:DEFAULT': 'EIA留出油燃料在庫量',
  'eia_distillate_stocks.title:ja:United States': 'EIA留出油燃料在庫量',
  'mid-month_inflation_rate_yoy.title:ja:DEFAULT': '月半ばのインフレ率（前年比）',
  'mid-month_inflation_rate_yoy.title:ja:Mexico': '月半ばのインフレ率（前年比）',
  'mining_production_mom.title:ja:DEFAULT': '鉱山生産量（前月比）',
  'mining_production_mom.title:ja:South Africa': '鉱山生産量（前月比）',
  'renzi_merkel_hollande_meet_on_brexit.title:ja:DEFAULT':
    'メルケル独首相、オランド仏大統領、レンツィ伊首相によるブレグジット会合',
  'renzi_merkel_hollande_meet_on_brexit.title:ja:Italy':
    'メルケル独首相、オランド仏大統領、レンツィ伊首相によるブレグジット会合',
  'westpac_mni_consumer_sentiment.title:ja:DEFAULT': 'ウエストパックMNI消費者信頼感',
  'westpac_mni_consumer_sentiment.title:ja:China': 'ウエストパックMNI消費者信頼感',
  'national_assembly_for_wales_elections.title:ja:DEFAULT': 'ウェールズ選挙国民議会',
  'national_assembly_for_wales_elections.title:ja:United Kingdom': 'ウェールズ選挙国民議会',
  'workers_day.title:ja:DEFAULT': '労働者の日',
  'workers_day.title:ja:South Africa': '労働者の日',
  '5-year_bonos_auction.title:ja:DEFAULT': 'スペイン5年物国債入札',
  '5-year_bonos_auction.title:ja:Spain': 'スペイン5年物国債入札',
  'king_s_day.title:ja:DEFAULT': 'キングスデー',
  'king_s_day.title:ja:Netherlands': 'キングスデー',
  'fed_bowman_speech.title:ja:DEFAULT': 'FRBボウマン理事演説',
  'fed_bowman_speech.title:ja:United States': 'FRBボウマン理事演説',
  'holiday_-_ramzan_id.title:ja:DEFAULT': '祝日－ラマダーン・イード（イスラム教断食明け祭）',
  'holiday_-_ramzan_id.title:ja:India': '祝日－ラマダーン・イード（イスラム教断食明け祭）',
  'treasury_secretary_mnuchin_speech.title:ja:DEFAULT': 'ムニューシン財務長官演説',
  'treasury_secretary_mnuchin_speech.title:ja:United States': 'ムニューシン財務長官演説',
  'feast_of_the_immaculate_conception.title:ja:DEFAULT': '無原罪の聖マリアの祭日',
  'feast_of_the_immaculate_conception.title:ja:Spain': '無原罪の聖マリアの祭日',
  'feast_of_the_immaculate_conception.title:ja:Austria': '無原罪の聖マリアの祭日',
  'feast_of_the_immaculate_conception.title:ja:Colombia': '無原罪の聖マリアの祭日',
  'feast_of_the_immaculate_conception.title:ja:Italy': '無原罪の聖マリアの祭日',
  'feast_of_the_immaculate_conception.title:ja:Portugal': '無原罪の聖マリアの祭日',
  'westpac_leading_index_mom.title:ja:DEFAULT': 'ウエストパック先行指数（前月比）',
  'westpac_leading_index_mom.title:ja:Australia': 'ウエストパック先行指数（前月比）',
  'wholesale_inventories_mom_adv.title:ja:DEFAULT': '卸売在庫（前月比）（速報値）',
  'wholesale_inventories_mom_adv.title:ja:United States': '卸売在庫（前月比）（速報値）',
  'us-russia_summit.title:ja:DEFAULT': '米国・ロシア間会合',
  'us-russia_summit.title:ja:United States': '米国・ロシア間会合',
  'white_house_jobs_announcement.title:ja:DEFAULT': 'ホワイトハウス雇用者数発表',
  'white_house_jobs_announcement.title:ja:United States': 'ホワイトハウス雇用者数発表',
  'nbb_business_climate.title:ja:DEFAULT': 'ベルギー国立銀行（NBB）企業景況感指数',
  'nbb_business_climate.title:ja:Belgium': 'ベルギー国立銀行（NBB）企業景況感指数',
  'government_autumn_forecasts.title:ja:DEFAULT': '政府秋季予測',
  'government_autumn_forecasts.title:ja:United Kingdom': '政府秋季予測',
  'rba_aylmer_speech.title:ja:DEFAULT': 'オーストラリア準備銀行（RBA）アリマー氏講演',
  'rba_aylmer_speech.title:ja:Australia': 'オーストラリア準備銀行（RBA）アリマー氏講演',
  'harvest_festival.title:ja:DEFAULT': '収穫祭',
  'harvest_festival.title:ja:South Korea': '収穫祭',
  'wholesale_inventories_mom.title:ja:DEFAULT': '卸売在庫（前月比）',
  'wholesale_inventories_mom.title:ja:United States': '卸売在庫（前月比）',
  'tourist_arrivals_yoy.title:ja:DEFAULT': '観光客到着数（前年比）',
  'tourist_arrivals_yoy.title:ja:Spain': '観光客到着数（前年比）',
  'tourist_arrivals_yoy.title:ja:Israel': '観光客到着数（前年比）',
  'tourist_arrivals_yoy.title:ja:Indonesia': '観光客到着数（前年比）',
  'tomb_sweeping_day.title:ja:DEFAULT': '清明節',
  'tomb_sweeping_day.title:ja:Taiwan': '清明節',
  'sipmm_manufacturing_pmi.title:ja:DEFAULT':
    'シンガポール資材購買管理協会（SIPMM）製造業購買担当者景気指数（PMI）',
  'sipmm_manufacturing_pmi.title:ja:Singapore':
    'シンガポール資材購買管理協会（SIPMM）製造業購買担当者景気指数（PMI）',
  'pm_may_statement_on_new_brexit_deal.title:ja:DEFAULT': 'ブレグジット新合意に関するメイ首相演説',
  'pm_may_statement_on_new_brexit_deal.title:ja:United Kingdom':
    'ブレグジット新合意に関するメイ首相演説',
  'the_emperor_s_birthday.title:ja:DEFAULT': '天皇陛下誕生日',
  'the_emperor_s_birthday.title:ja:Japan': '天皇陛下誕生日',
  'winter_solstice_festival.title:ja:DEFAULT': '冬至祭',
  'winter_solstice_festival.title:ja:Hong Kong': '冬至祭',
  'unemployment_benefit_claims.title:ja:DEFAULT': '失業保険申請件数',
  'unemployment_benefit_claims.title:ja:France': '失業保険申請件数',
  'ppi_mom.title:ja:DEFAULT': '生産者物価指数（PPI）（前月比）',
  'ppi_mom.title:ja:Portugal': '生産者物価指数（PPI）（前月比）',
  'ppi_mom.title:ja:Czech Republic': '生産者物価指数（PPI）（前月比）',
  'ppi_mom.title:ja:Euro Area': '生産者物価指数（PPI）（前月比）',
  'ppi_mom.title:ja:Japan': '生産者物価指数（PPI）（前月比）',
  'ppi_mom.title:ja:France': '生産者物価指数（PPI）（前月比）',
  'ppi_mom.title:ja:Germany': '生産者物価指数（PPI）（前月比）',
  'ppi_mom.title:ja:Sweden': '生産者物価指数（PPI）（前月比）',
  'ppi_mom.title:ja:Austria': '生産者物価指数（PPI）（前月比）',
  'ppi_mom.title:ja:South Africa': '生産者物価指数（PPI）（前月比）',
  'ppi_mom.title:ja:United States': '生産者物価指数（PPI）（前月比）',
  'ppi_mom.title:ja:Italy': '生産者物価指数（PPI）（前月比）',
  'ppi_mom.title:ja:Switzerland': '生産者物価指数（PPI）（前月比）',
  'ppi_mom.title:ja:Canada': '生産者物価指数（PPI）（前月比）',
  'ppi_mom.title:ja:South Korea': '生産者物価指数（PPI）（前月比）',
  'ppi_mom.title:ja:Russia': '生産者物価指数（PPI）（前月比）',
  'fed_tarullo_speech.title:ja:DEFAULT': 'FRBタルーロ氏演説',
  'fed_tarullo_speech.title:ja:United States': 'FRBタルーロ氏演説',
  'nato_summit.title:ja:DEFAULT': 'NATOサミット',
  'nato_summit.title:ja:Belgium': 'NATOサミット',
  'nato_summit.title:ja:Poland': 'NATOサミット',
  'world_economic_forum_annual_meeting.title:ja:DEFAULT': '世界経済フォーラム年次総会',
  'world_economic_forum_annual_meeting.title:ja:Switzerland': '世界経済フォーラム年次総会',
  'rbnz_press_conference.title:ja:DEFAULT': 'ニュージーランド準備銀行（RBNZ）記者会見',
  'rbnz_press_conference.title:ja:New Zealand': 'ニュージーランド準備銀行（RBNZ）記者会見',
  'retail_trade_s_a_mom.title:ja:DEFAULT': '季節調整済み小売業指数（前月比）',
  'retail_trade_s_a_mom.title:ja:Japan': '季節調整済み小売業指数（前月比）',
  'obr_economic_and_fiscal_forecasts.title:ja:DEFAULT': '英予算責任局（OBR）財政見通し',
  'obr_economic_and_fiscal_forecasts.title:ja:United Kingdom': '英予算責任局（OBR）財政見通し',
  'prospective_plantings_-_wheat.title:ja:DEFAULT': '作付意向―小麦',
  'prospective_plantings_-_wheat.title:ja:United States': '作付意向―小麦',
  'government_budget.title:ja:DEFAULT': '政府予算',
  'government_budget.title:ja:Philippines': '政府予算',
  'government_budget.title:ja:Italy': '政府予算',
  'government_budget.title:ja:Germany': '政府予算',
  'visitor_arrivals_mom.title:ja:DEFAULT': '観光客到着数（前月比）',
  'visitor_arrivals_mom.title:ja:New Zealand': '観光客到着数（前月比）',
  'snb_press_conference.title:ja:DEFAULT': 'スイス国立銀行（SNB）記者会見',
  'snb_press_conference.title:ja:Switzerland': 'スイス国立銀行（SNB）記者会見',
  'non-manufacturing_business_activity.title:ja:DEFAULT': '非製造業事業活動',
  'non-manufacturing_business_activity.title:ja:United States': '非製造業事業活動',
  'snb_financial_stability_report.title:ja:DEFAULT': 'スイス国立銀行金融安定性報告書',
  'snb_financial_stability_report.title:ja:Switzerland': 'スイス国立銀行金融安定性報告書',
  'gdp_capital_expenditure_qoq_prel.title:ja:DEFAULT': 'GDP設備投資（前四半期比）（速報値）',
  'gdp_capital_expenditure_qoq_prel.title:ja:Japan': 'GDP設備投資（前四半期比）（速報値）',
  '273-day_bill_auction.title:ja:DEFAULT': '273日割引国債入札',
  '273-day_bill_auction.title:ja:United States': '273日割引国債入札',
  'uk-eu_trade_talks.title:ja:DEFAULT': '英国－EU貿易交渉',
  'uk-eu_trade_talks.title:ja:Belgium': '英国－EU貿易交渉',
  'norges_bank_gov_olsen_speech.title:ja:DEFAULT': 'ノルウェー銀行オルセン総裁講演',
  'norges_bank_gov_olsen_speech.title:ja:Norway': 'ノルウェー銀行オルセン総裁講演',
  'treasury_secretary_mnuchin_testimony.title:ja:DEFAULT': 'ムニューシン財務長官証言',
  'treasury_secretary_mnuchin_testimony.title:ja:United States': 'ムニューシン財務長官証言',
  'hangeul_proclamation_day.title:ja:DEFAULT': 'ハングルの日',
  'hangeul_proclamation_day.title:ja:South Korea': 'ハングルの日',
  '6-month_bot_auction.title:ja:DEFAULT': 'イタリア6カ月物国債入札',
  '6-month_bot_auction.title:ja:Italy': 'イタリア6カ月物国債入札',
  'westpac_consumer_confidence.title:ja:DEFAULT': 'ウエストパック消費者信頼感指数',
  'westpac_consumer_confidence.title:ja:Australia': 'ウエストパック消費者信頼感指数',
  'westpac_consumer_confidence.title:ja:New Zealand': 'ウエストパック消費者信頼感指数',
  'milad-un-nabi.title:ja:DEFAULT': '預言者誕生祭',
  'milad-un-nabi.title:ja:Indonesia': '預言者誕生祭',
  'isra_mi_raj.title:ja:DEFAULT': 'イスラー・ワル・ミラージュ',
  'isra_mi_raj.title:ja:Indonesia': 'イスラー・ワル・ミラージュ',
  'yutaka_harada_speech.title:ja:DEFAULT': '日本銀行原田泰氏演説',
  'yutaka_harada_speech.title:ja:Japan': '日本銀行原田泰氏演説',
  'investment_lending_for_homes.title:ja:DEFAULT': '投資住宅ローン',
  'investment_lending_for_homes.title:ja:Australia': '投資住宅ローン',
  'veterans_day_substitute_day.title:ja:DEFAULT': '復員軍人の日（振替休日）',
  'veterans_day_substitute_day.title:ja:United States': '復員軍人の日（振替休日）',
  'our_lady_of_aparecida_day.title:ja:DEFAULT': '聖母アパレシーダの日',
  'our_lady_of_aparecida_day.title:ja:Brazil': '聖母アパレシーダの日',
  'qe_total.title:ja:DEFAULT': '量的緩和総量',
  'qe_total.title:ja:United States': '量的緩和総量',
  'inflation_rate_yoy_final.title:ja:DEFAULT': 'インフレ率（前年比）（確報値）',
  'inflation_rate_yoy_final.title:ja:Russia': 'インフレ率（前年比）（確報値）',
  'inflation_rate_yoy_final.title:ja:Poland': 'インフレ率（前年比）（確報値）',
  'inflation_rate_yoy_final.title:ja:Germany': 'インフレ率（前年比）（確報値）',
  'inflation_rate_yoy_final.title:ja:Spain': 'インフレ率（前年比）（確報値）',
  'inflation_rate_yoy_final.title:ja:Portugal': 'インフレ率（前年比）（確報値）',
  'inflation_rate_yoy_final.title:ja:Italy': 'インフレ率（前年比）（確報値）',
  'inflation_rate_yoy_final.title:ja:France': 'インフレ率（前年比）（確報値）',
  'inflation_rate_yoy_final.title:ja:Euro Area': 'インフレ率（前年比）（確報値）',
  'king_s_birthday.title:ja:DEFAULT': '国王陛下誕生日',
  'king_s_birthday.title:ja:Netherlands': '国王陛下誕生日',
  'washington_s_birthday.title:ja:DEFAULT': 'ワシントン誕生日',
  'washington_s_birthday.title:ja:United States': 'ワシントン誕生日',
  'rba_meeting_s_minutes.title:ja:DEFAULT': 'オーストラリア準備銀行（RBA）政策理事会議事録',
  'rba_meeting_s_minutes.title:ja:Australia': 'オーストラリア準備銀行（RBA）政策理事会議事録',
  'boj_amamiya_speech.title:ja:DEFAULT': '日銀雨宮氏氏演説',
  'boj_amamiya_speech.title:ja:Japan': '日銀雨宮氏氏演説',
  'markit_pmi.title:ja:DEFAULT': 'マークイット購買担当者景気指数（PMI）',
  'markit_pmi.title:ja:Hong Kong': 'マークイット購買担当者景気指数（PMI）',
  'markit_pmi.title:ja:Singapore': 'マークイット購買担当者景気指数（PMI）',
  'saint_joseph_s_day.title:ja:DEFAULT': '聖ヨセフの日',
  'saint_joseph_s_day.title:ja:Colombia': '聖ヨセフの日',
  'westpac_consumer_confidence_mom.title:ja:DEFAULT': 'ウエストパック消費者信頼感指数（前月比）',
  'westpac_consumer_confidence_mom.title:ja:Australia': 'ウエストパック消費者信頼感指数（前月比）',
  'tripartite_social_summit_video_conference.title:ja:DEFAULT':
    '三者間ソーシャルサミットビデオ会議',
  'tripartite_social_summit_video_conference.title:ja:European Union':
    '三者間ソーシャルサミットビデオ会議',
  'st_patricks_day.title:ja:DEFAULT': '聖パトリックの日',
  'st_patricks_day.title:ja:Ireland': '聖パトリックの日',
  'all_saints_day.title:ja:Mexico': '諸聖人の日',
  'president_xi_jinping_speech.title:ja:DEFAULT': '習近平主席演説',
  'president_xi_jinping_speech.title:ja:China': '習近平主席演説',
  'visitor_arrivals_yoy.title:ja:DEFAULT': '観光客到着数（前年比）',
  'visitor_arrivals_yoy.title:ja:New Zealand': '観光客到着数（前年比）',
  'consumer_price_index_mom.title:ja:DEFAULT': '消費者物価指数（前月比）',
  'consumer_price_index_mom.title:ja:Italy': '消費者物価指数（前月比）',
  'consumer_price_index_mom.title:ja:Germany': '消費者物価指数（前月比）',
  'consumer_price_index_mom.title:ja:Euro Area': '消費者物価指数（前月比）',
  'industrial_production_yoy_-_final.title:ja:DEFAULT': '工業生産（前年比）（確報値）',
  'industrial_production_yoy_-_final.title:ja:Hungary': '工業生産（前年比）（確報値）',
  'gdp_final_consumption.title:ja:DEFAULT': 'GDP最終消費',
  'gdp_final_consumption.title:ja:Australia': 'GDP最終消費',
  'waitangi_day.title:ja:DEFAULT': 'ワイタンギ・デー',
  'waitangi_day.title:ja:New Zealand': 'ワイタンギ・デー',
  'harmonised_inflation_rate_yoy_prel.title:ja:DEFAULT': '調和インフレ率（前年比）（速報値）',
  'harmonised_inflation_rate_yoy_prel.title:ja:Italy': '調和インフレ率（前年比）（速報値）',
  'harmonised_inflation_rate_yoy_prel.title:ja:France': '調和インフレ率（前年比）（速報値）',
  'harmonised_inflation_rate_yoy_prel.title:ja:Germany': '調和インフレ率（前年比）（速報値）',
  'harmonised_inflation_rate_yoy_prel.title:ja:Spain': '調和インフレ率（前年比）（速報値）',
  'hsbc_composite_pmi.title:ja:DEFAULT': 'HSBC総合購買担当者景気指数（PMI）',
  'hsbc_composite_pmi.title:ja:Brazil': 'HSBC総合購買担当者景気指数（PMI）',
  'upper_house_elections.title:ja:DEFAULT': '上院選挙',
  'upper_house_elections.title:ja:Japan': '上院選挙',
  'ism_non-manufacturing_pmi.title:ja:DEFAULT': 'ISM非製造業購買担当者景気指数（PMI）',
  'ism_non-manufacturing_pmi.title:ja:United States': 'ISM非製造業購買担当者景気指数（PMI）',
  'chinese_people_s_political_consultative_conference.title:ja:DEFAULT': '中国人民政治協商会議',
  'chinese_people_s_political_consultative_conference.title:ja:China': '中国人民政治協商会議',
  'prophet_muhammad_s_birthday.title:ja:DEFAULT': '預言者生誕祭',
  'prophet_muhammad_s_birthday.title:ja:Indonesia': '預言者生誕祭',
  'international_women_s_day.title:ja:DEFAULT': '国際女性デー',
  'international_women_s_day.title:ja:Russia': '国際女性デー',
  'sukkot.title:ja:DEFAULT': '仮庵の祭り（ユダヤ教）',
  'sukkot.title:ja:Israel': '仮庵の祭り（ユダヤ教）',
  'fed_yellen_testimony_before_congress.title:ja:DEFAULT': 'FRBイエレン議長議会証言',
  'fed_yellen_testimony_before_congress.title:ja:United States': 'FRBイエレン議長議会証言',
  'boe_quarterly_bulletin.title:ja:DEFAULT': 'イングランド銀行（BoE）四半期報告書',
  'boe_quarterly_bulletin.title:ja:United Kingdom': 'イングランド銀行（BoE）四半期報告書',
  'inflation_rate_mom_final.title:ja:DEFAULT': 'インフレ率（前月比）（確報値）',
  'inflation_rate_mom_final.title:ja:Russia': 'インフレ率（前月比）（確報値）',
  'inflation_rate_mom_final.title:ja:Poland': 'インフレ率(前月比)確報値',
  'inflation_rate_mom_final.title:ja:Germany': 'インフレ率（前月比）（確報値）',
  'inflation_rate_mom_final.title:ja:Spain': 'インフレ率（前月比）（確報値）',
  'inflation_rate_mom_final.title:ja:Portugal': 'インフレ率（前月比）（確報値）',
  'inflation_rate_mom_final.title:ja:Italy': 'インフレ率（前月比）（確報値）',
  'inflation_rate_mom_final.title:ja:France': 'インフレ率（前月比）（確報値）',
  'inflation_rate_mom_final.title:ja:Euro Area': 'インフレ率（前月比）（確報値）',
  'gdp_growth_annualized.title:ja:DEFAULT': 'GDP成長率（年換算）',
  'gdp_growth_annualized.title:ja:Israel': 'GDP成長率（年換算）',
  'wage_price_index_yoy.title:ja:DEFAULT': '賃金価格指数（前年比）',
  'wage_price_index_yoy.title:ja:Australia': '賃金価格指数（前年比）',
  'new_loans.title:ja:DEFAULT': '新規融資',
  'new_loans.title:ja:China': '新規融資',
  'fin_minister_hammond_speech.title:ja:DEFAULT': 'ハモンド英財務相演説',
  'fin_minister_hammond_speech.title:ja:United Kingdom': 'ハモンド英財務相演説',
  'new_car_registrations_yoy.title:ja:DEFAULT': '新車登録台数（前年比）',
  'new_car_registrations_yoy.title:ja:Germany': '新車登録台数（前年比）',
  'new_car_registrations_yoy.title:ja:France': '新車登録台数（前年比）',
  'new_car_registrations_yoy.title:ja:European Union': '新車登録台数（前年比）',
  'defenders_of_the_fatherland_holiday.title:ja:DEFAULT': '祖国防衛の日',
  'defenders_of_the_fatherland_holiday.title:ja:Russia': '祖国防衛の日',
  'wage_growth_yoy.title:ja:DEFAULT': '実質賃金上昇率（前年比）',
  'wage_growth_yoy.title:ja:Euro Area': '実質賃金上昇率（前年比）',
  'participation_rate.title:ja:DEFAULT': '労働参加率',
  'participation_rate.title:ja:Canada': '労働参加率',
  'urban_investment_ytd.title:ja:DEFAULT': '都市投資（年初来）',
  'urban_investment_ytd.title:ja:China': '都市投資（年初来）',
  'national_day.title:ja:DEFAULT': '建国記念日',
  'national_day.title:ja:Hong Kong': '建国記念日',
  'national_day.title:ja:Spain': '建国記念日',
  'national_day.title:ja:Russia': '建国記念日',
  'national_day.title:ja:Portugal': '建国記念日',
  'national_day.title:ja:Sweden': '建国記念日',
  'national_day.title:ja:Austria': '建国記念日',
  'national_day.title:ja:China': '建国記念日',
  'national_day.title:ja:Belgium': '建国記念日',
  'national_day.title:ja:Switzerland': '建国記念日',
  'national_day.title:ja:Singapore': '建国記念日',
  'national_day.title:ja:Czech Republic': '建国記念日',
  'national_day.title:ja:Taiwan': '建国記念日',
  'victory_holiday.title:ja:DEFAULT': '戦勝記念日',
  'victory_holiday.title:ja:Russia': '戦勝記念日',
  'tankan_non-manufacturing_outlook.title:ja:DEFAULT': '日銀短観非製造業企業先行き',
  'tankan_non-manufacturing_outlook.title:ja:Japan': '日銀短観非製造業企業先行き',
  'fed_open_board_meeting.title:ja:DEFAULT': 'FRB公開会合',
  'fed_open_board_meeting.title:ja:United States': 'FRB公開会合',
  'hsbc_pmi_manufacturing.title:ja:DEFAULT': 'HSBC製造業購買担当者景気指数（PMI）',
  'hsbc_pmi_manufacturing.title:ja:Brazil': 'HSBC製造業購買担当者景気指数（PMI）',
  'finance_minister_sch_uble_speech.title:ja:DEFAULT': 'ショイブレ財務相講演',
  'finance_minister_sch_uble_speech.title:ja:Germany': 'ショイブレ財務相講演',
  'wpi_inflation_yoy.title:ja:DEFAULT': '卸売価格指数（WPI）インフレ率（前年比）',
  'wpi_inflation_yoy.title:ja:India': '卸売価格指数（WPI）インフレ率（前年比）',
  'unemployed_persons.title:ja:DEFAULT': '失業者数',
  'unemployed_persons.title:ja:Austria': '失業者数',
  'unemployed_persons.title:ja:Norway': '失業者数',
  'unemployed_persons.title:ja:South Africa': '失業者数',
  'participation_rate.title:ja:New Zealand': '労働参加率',
  'participation_rate.title:ja:Australia': '労働参加率',
  'participation_rate.title:ja:United States': '労働参加率',
  'government_payrolls.title:ja:DEFAULT': '政府雇用者数',
  'government_payrolls.title:ja:United States': '政府雇用者数',
  'eu_leaders_vote_on_art_50_extension.title:ja:DEFAULT': 'EU首脳陣による50条延長の投票',
  'eu_leaders_vote_on_art_50_extension.title:ja:European Union': 'EU首脳陣による50条延長の投票',
  'local_elections.title:ja:DEFAULT': '地方選挙',
  'local_elections.title:ja:Taiwan': '地方選挙',
  'local_elections.title:ja:United Kingdom': '地方選挙',
  'local_elections.title:ja:Netherlands': '地方選挙',
  'local_elections.title:ja:France': '地方選挙',
  'local_elections.title:ja:Belgium': '地方選挙',
  'local_elections.title:ja:Indonesia': '地方選挙',
  'local_elections.title:ja:South Korea': '地方選挙',
  'wage_inflation_yoy.title:ja:DEFAULT': '賃金インフレ率(前年比）',
  'wage_inflation_yoy.title:ja:Italy': '賃金インフレ率(前年比）',
  's_p_case-shiller_home_price_yoy.title:ja:DEFAULT': 'S&P/ケース・シラー住宅価格（前年比）',
  's_p_case-shiller_home_price_yoy.title:ja:United States': 'S&P/ケース・シラー住宅価格（前年比）',
  'michigan_expectations_prel.title:ja:DEFAULT': 'ミシガン大学消費者信頼感指数（速報値）',
  'michigan_expectations_prel.title:ja:United States': 'ミシガン大学消費者信頼感指数（速報値）',
  'maundy_thursday.title:ja:DEFAULT': '聖木曜日',
  'maundy_thursday.title:ja:Portugal': '聖木曜日',
  'maundy_thursday.title:ja:Philippines': '聖木曜日',
  'maundy_thursday.title:ja:Colombia': '聖木曜日',
  'maundy_thursday.title:ja:Norway': '聖木曜日',
  'maundy_thursday.title:ja:Denmark': '聖木曜日',
  'maundy_thursday.title:ja:Spain': '聖木曜日',
  'maundy_thursday.title:ja:Mexico': '聖木曜日',
  'pm_may_s_plan_b_statement_on_brexit.title:ja:DEFAULT': 'ブレグジットに関するメイ首相のプランB',
  'pm_may_s_plan_b_statement_on_brexit.title:ja:United Kingdom':
    'ブレグジットに関するメイ首相のプランB',
  'existing_home_sales.title:ja:DEFAULT': '中古住宅販売件数',
  'existing_home_sales.title:ja:United States': '中古住宅販売件数',
  'continuing_jobless_claims.title:ja:DEFAULT': '失業保険継続申請件数',
  'continuing_jobless_claims.title:ja:United States': '失業保険継続申請件数',
  'ecofin_meeting.title:ja:DEFAULT': 'ユーロ圏経済・財務相会合',
  'ecofin_meeting.title:ja:European Union': 'ユーロ圏経済・財務相会合',
  'ecofin_meeting.title:ja:Euro Area': 'ユーロ圏経済・財務相会合',
  'foundation_day_of_korea.title:ja:DEFAULT': '韓国独立記念日',
  'foundation_day_of_korea.title:ja:South Korea': '韓国独立記念日',
  'purim_tel_aviv.title:ja:DEFAULT': 'プリム（テルアビブ）',
  'purim_tel_aviv.title:ja:Israel': 'プリム（テルアビブ）',
  'usda_prospective_plantings_report.title:ja:DEFAULT': '米国農務省（USDA）作付意向レポート',
  'usda_prospective_plantings_report.title:ja:United States': '米国農務省（USDA）作付意向レポート',
  'pce_prices_qoq.title:ja:DEFAULT': '個人消費支出（PCE）物価指数（前四半期比）',
  'pce_prices_qoq.title:ja:United States': '個人消費支出（PCE）物価指数（前四半期比）',
  'waisak_day.title:ja:DEFAULT': 'ウェーサーカ祭',
  'waisak_day.title:ja:Indonesia': 'ウェーサーカ祭',
  'employment_change_qoq_prel.title:ja:DEFAULT': '雇用変化（前四半期比）（速報値）',
  'employment_change_qoq_prel.title:ja:Euro Area': '雇用変化（前四半期比）（速報値）',
  'queen_s_birthday.title:ja:DEFAULT': '女王陛下誕生日',
  'queen_s_birthday.title:ja:New Zealand': '女王陛下誕生日',
  'queen_s_birthday.title:ja:Australia': '女王陛下誕生日',
  'gfk_consumer_confidence_final.title:ja:DEFAULT': 'GFK消費者信頼感指数（確報値）',
  'gfk_consumer_confidence_final.title:ja:United Kingdom': 'GFK消費者信頼感指数（確報値）',
  'fed_yellen_testimony.title:ja:DEFAULT': 'FRBイエレン議長証言',
  'fed_yellen_testimony.title:ja:United States': 'FRBイエレン議長証言',
  'm2_money_supply_yoy.title:ja:DEFAULT': 'M2マネーサプライ（前年比）',
  'm2_money_supply_yoy.title:ja:Russia': 'M2マネーサプライ（前年比）',
  'm2_money_supply_yoy.title:ja:Taiwan': 'M2マネーサプライ（前年比）',
  'm2_money_supply_yoy.title:ja:Czech Republic': 'M2マネーサプライ（前年比）',
  'm2_money_supply_yoy.title:ja:China': 'M2マネーサプライ（前年比）',
  'm2_money_supply_yoy.title:ja:Indonesia': 'M2マネーサプライ（前年比）',
  'feast_of_the_sacred_heart.title:ja:DEFAULT': 'みこころの祝日',
  'feast_of_the_sacred_heart.title:ja:Colombia': 'みこころの祝日',
  'rba_kohler_speech.title:ja:DEFAULT': 'オーストラリア準備銀行（RBA）コーラー氏講演',
  'rba_kohler_speech.title:ja:Australia': 'オーストラリア準備銀行（RBA）コーラー氏講演',
  'boe_interest_rate_decision.title:ja:DEFAULT': 'イングランド銀行（BoE）金利決定',
  'boe_interest_rate_decision.title:ja:United Kingdom': 'イングランド銀行（BoE）金利決定',
  'labor_cost.title:ja:DEFAULT': '労働コスト',
  'labor_cost.title:ja:Euro Area': '労働コスト',
  'public_sector_borrowing_mom.title:ja:DEFAULT': '公共部門純借入額（前月比）',
  'public_sector_borrowing_mom.title:ja:United Kingdom': '公共部門純借入額（前月比）',
  'gdp_growth_rate_qoq_-_p.title:ja:DEFAULT': 'GDP成長率（前四半期比）（速報値）',
  'gdp_growth_rate_qoq_-_p.title:ja:Hungary': 'GDP成長率（前四半期比）（速報値）',
  'fed_ccar_for_big_banks.title:ja:DEFAULT':
    '連邦準備理事会による大手銀行の包括的資本分析およびレビュー（CCAR）',
  'fed_ccar_for_big_banks.title:ja:United States':
    '連邦準備理事会による大手銀行の包括的資本分析およびレビュー（CCAR）',
  'manufacturing_production_mom.title:ja:DEFAULT': '製造業生産（前月比）',
  'manufacturing_production_mom.title:ja:Norway': '製造業生産（前月比）',
  'manufacturing_production_mom.title:ja:South Africa': '製造業生産（前月比）',
  'manufacturing_production_mom.title:ja:United States': '製造業生産（前月比）',
  'manufacturing_production_mom.title:ja:United Kingdom': '製造業生産（前月比）',
  'ram_navmi.title:ja:DEFAULT': 'ラーム・ナヴミ',
  'ram_navmi.title:ja:India': 'ラーム・ナヴミ',
  'ecb_c_ur_speech.title:ja:DEFAULT': 'ECBクーレ氏講演',
  'ecb_c_ur_speech.title:ja:Euro Area': 'ECBクーレ氏講演',
  'total_new_vehicle_sales.title:ja:DEFAULT': '新車販売台数',
  'total_new_vehicle_sales.title:ja:South Africa': '新車販売台数',
  'st_patrick_s_day.title:ja:DEFAULT': '聖パトリックの日',
  'st_patrick_s_day.title:ja:Ireland': '聖パトリックの日',
  'nfib_business_optimism_index.title:ja:DEFAULT': '全米独立企業連盟（NFIB）中小企業楽観指数',
  'nfib_business_optimism_index.title:ja:United States': '全米独立企業連盟（NFIB）中小企業楽観指数',
  'hari_raya_haji.title:ja:DEFAULT': 'ハリ・ラヤ・ハジ',
  'hari_raya_haji.title:ja:Indonesia': 'ハリ・ラヤ・ハジ',
  'hari_raya_haji.title:ja:Singapore': 'ハリ・ラヤ・ハジ',
  'feast_of_purim_tel_aviv.title:ja:DEFAULT': 'プリム祭（テルアビブ）',
  'feast_of_purim_tel_aviv.title:ja:Israel': 'プリム祭（テルアビブ）',
  'qe_treasuries.title:ja:DEFAULT': '量的緩和（QE）国債',
  'qe_treasuries.title:ja:United States': '量的緩和（QE）国債',
  'ppi_core_output_yoy.title:ja:DEFAULT': '生産者物価指数（PPI）コア産出物価（前年比）',
  'ppi_core_output_yoy.title:ja:United Kingdom': '生産者物価指数（PPI）コア産出物価（前年比）',
  'bundesbank_mauderer_speech.title:ja:DEFAULT': 'ドイツ連邦銀行マウデラー理事講演',
  'bundesbank_mauderer_speech.title:ja:Germany': 'ドイツ連邦銀行マウデラー理事講演',
  'markit_cips_uk_services_pmi.title:ja:DEFAULT':
    'マークイット/CIPS英国サービス業購買担当者景気指数（PMI）',
  'markit_cips_uk_services_pmi.title:ja:United Kingdom':
    'マークイット/CIPS英国サービス業購買担当者景気指数（PMI）',
  'markit_manufacturing_pmi.title:ja:DEFAULT': 'マークイット製造業購買担当者指数 (PMI)',
  'markit_manufacturing_pmi.title:ja:Taiwan': 'マークイット製造業購買担当者指数 (PMI)',
  'redbook_mom.title:ja:DEFAULT': 'レッドブック（前月比)',
  'redbook_mom.title:ja:United States': 'レッドブック（前月比)',
  '15-year_obligacion_auction.title:ja:DEFAULT': '',
  '15-year_obligacion_auction.title:ja:Spain': '',
  'raw_material_price_mom.title:ja:DEFAULT': '原材料価格指数（前月比）',
  'raw_material_price_mom.title:ja:Canada': '原材料価格指数（前月比）',
  'parliamentary_vote_on_art_50_extension.title:ja:DEFAULT': '第50条延長に関する議会投票',
  'parliamentary_vote_on_art_50_extension.title:ja:United Kingdom': '第50条延長に関する議会投票',
  'private_capital_expenditure_qoq.title:ja:DEFAULT': '民間企業設備投資（前四半期比）',
  'private_capital_expenditure_qoq.title:ja:Australia': '民間企業設備投資（前四半期比）',
  'jibun_bank_manufacturing_pmi_final.title:ja:DEFAULT':
    'auじぶん銀行製造業購買担当者景気指数（PMI）（確報値）',
  'jibun_bank_manufacturing_pmi_final.title:ja:Japan':
    'auじぶん銀行製造業購買担当者景気指数（PMI）（確報値）',
  'private_non_farm_payrolls_qoq_prel.title:ja:DEFAULT':
    '民間非農業部門雇用者数（前四半期比）（速報値）',
  'private_non_farm_payrolls_qoq_prel.title:ja:France':
    '民間非農業部門雇用者数（前四半期比）（速報値）',
  'capital_expenditure_qoq.title:ja:DEFAULT': '設備投資（前四半期比）',
  'capital_expenditure_qoq.title:ja:Australia': '設備投資（前四半期比）',
  'rba_bulletin.title:ja:DEFAULT': 'オーストラリア準備銀行（RBA）レポート',
  'rba_bulletin.title:ja:Australia': 'オーストラリア準備銀行（RBA）レポート',
  'factory_orders_ex_transportation.title:ja:DEFAULT': '工場受注（輸送を除く）',
  'factory_orders_ex_transportation.title:ja:United States': '工場受注（輸送を除く）',
  'cabinet_meeting_on_stimulus_measures.title:ja:DEFAULT': '経済刺激策に関する閣僚会議',
  'cabinet_meeting_on_stimulus_measures.title:ja:South Korea': '経済刺激策に関する閣僚会議',
  'vesak_day.title:ja:DEFAULT': 'ウェーサーカ祭',
  'vesak_day.title:ja:Indonesia': 'ウェーサーカ祭',
  'vesak_day.title:ja:Singapore': 'ウェーサーカ祭',
  'imf_meeting.title:ja:DEFAULT': '国際通貨基金（IMF）会合',
  'imf_meeting.title:ja:United States': '国際通貨基金（IMF）会合',
  'harvest_festival_day_2.title:ja:DEFAULT': '収穫祭2日目',
  'harvest_festival_day_2.title:ja:South Korea': '収穫祭2日目',
  'cpi_ex-fresh_food_yoy.title:ja:DEFAULT': '生鮮食品を除く全国消費者物価指数（CPI）（前年比）',
  'cpi_ex-fresh_food_yoy.title:ja:Japan': '生鮮食品を除く全国消費者物価指数（CPI）（前年比）',
  'gdp_growth_rate_2015_flash.title:ja:DEFAULT': '2015年国内総生産（GDP）成長率（速報）',
  'gdp_growth_rate_2015_flash.title:ja:Germany': '2015年国内総生産（GDP）成長率（速報）',
  'shavuot.title:ja:DEFAULT': 'シャブオット',
  'shavuot.title:ja:Israel': 'シャブオット',
  'gross_fixed_investment_yoy.title:ja:DEFAULT': '総設備投資（前年比）',
  'gross_fixed_investment_yoy.title:ja:Mexico': '総設備投資（前年比）',
  'emperor_s_birthday.title:ja:DEFAULT': '天皇陛下誕生日',
  'emperor_s_birthday.title:ja:Japan': '天皇陛下誕生日',
  'zew_economic_sentiment_index.title:ja:DEFAULT': 'ZEW景況感指数',
  'zew_economic_sentiment_index.title:ja:Switzerland': 'ZEW景況感指数',
  'zew_economic_sentiment_index.title:ja:Germany': 'ZEW景況感指数',
  'zew_economic_sentiment_index.title:ja:Euro Area': 'ZEW景況感指数',
  'rhineland-palatinate_state_election.title:ja:DEFAULT': 'ラインラント・プファルツ州選挙',
  'rhineland-palatinate_state_election.title:ja:Germany': 'ラインラント・プファルツ州選挙',
  'labour_productivity_qoq_final.title:ja:DEFAULT': '労働生産性（前四半期比）（確報値）',
  'labour_productivity_qoq_final.title:ja:United Kingdom': '労働生産性（前四半期比）（確報値）',
  'urban_investment_yoy.title:ja:DEFAULT': '都市投資（前年比）',
  'urban_investment_yoy.title:ja:China': '都市投資（前年比）',
  'fed_yellen_speaks.title:ja:DEFAULT': '連邦準備制度理事会イエレン議長講演',
  'fed_yellen_speaks.title:ja:United States': '連邦準備制度理事会イエレン議長講演',
  'international_children_s_day.title:ja:DEFAULT': '世界子供の日',
  'international_children_s_day.title:ja:South Korea': '世界子供の日',
  'international_children_s_day.title:ja:Japan': '世界子供の日',
  'international_children_s_day.title:ja:Taiwan': '世界子供の日',
  'us-china_trade_talks.title:ja:DEFAULT': '米中貿易協議',
  'us-china_trade_talks.title:ja:United States': '米中貿易協議',
  'us-china_trade_talks.title:ja:China': '米中貿易協議',
  'new_year_s_eve.title:ja:DEFAULT': '大晦日',
  'new_year_s_eve.title:ja:Germany': '大晦日',
  'new_year_s_eve.title:ja:Philippines': '大晦日',
  'new_year_s_eve.title:ja:Sweden': '大晦日',
  'new_year_s_eve.title:ja:South Korea': '大晦日',
  'new_year_s_eve.title:ja:Thailand': '大晦日',
  'new_year_s_eve.title:ja:Israel': '大晦日',
  'gdp_growth_rate_qoq_1st_est.title:ja:DEFAULT': 'GDP成長率（前四半期比）（1次速報）',
  'gdp_growth_rate_qoq_1st_est.title:ja:France': 'GDP成長率（前四半期比）（1次速報）',
  'gdp_growth_rate_qoq_1st_est.title:ja:Israel': 'GDP成長率（前四半期比）（1次速報）',
  'total_vehicle_sales.title:ja:DEFAULT': '車両販売台数',
  'total_vehicle_sales.title:ja:United States': '車両販売台数',
  'uk_supreme_court_ruling_on_article_50.title:ja:DEFAULT': '英国最高裁判所リスボン条約第50条判決',
  'uk_supreme_court_ruling_on_article_50.title:ja:United Kingdom':
    '英国最高裁判所リスボン条約第50条判決',
  'zew_current_conditions.title:ja:DEFAULT': 'ZEW現況指数',
  'zew_current_conditions.title:ja:Germany': 'ZEW現況指数',
  'quarterly_grain_stocks_-_wheat.title:ja:DEFAULT': '四半期穀物在庫―小麦',
  'quarterly_grain_stocks_-_wheat.title:ja:United States': '四半期穀物在庫―小麦',
  'ibc-br_economic_activity.title:ja:DEFAULT': 'IBC-BR経済活動指数',
  'ibc-br_economic_activity.title:ja:Brazil': 'IBC-BR経済活動指数',
  'vehicle_production_yoy.title:ja:DEFAULT': '車両生産台数（前年比）',
  'vehicle_production_yoy.title:ja:Japan': '車両生産台数（前年比）',
  'unity_day.title:ja:DEFAULT': '統一記念日',
  'unity_day.title:ja:Russia': '統一記念日',
  'rba_ryan_speech.title:ja:DEFAULT': 'オーストラリア準備銀行（RBA）ライアン氏講演',
  'rba_ryan_speech.title:ja:Australia': 'オーストラリア準備銀行（RBA）ライアン氏講演',
  'unit_labor_costs_qoq_prel.title:ja:DEFAULT': '単位労働コスト（前四半期比）（速報値）',
  'unit_labor_costs_qoq_prel.title:ja:United States': '単位労働コスト（前四半期比）（速報値）',
  'treasury_secretary_steven_mnuchin_testimony.title:ja:DEFAULT':
    'スティーブン・ムニューシン財務長官証言',
  'treasury_secretary_steven_mnuchin_testimony.title:ja:United States':
    'スティーブン・ムニューシン財務長官証言',
  'retail_sales_mom_prel.title:ja:DEFAULT': '小売売上高（前月比）（速報値）',
  'retail_sales_mom_prel.title:ja:Australia': '小売売上高（前月比）（速報値）',
  'economic_sentiment_indicator.title:ja:DEFAULT': '景況感指数',
  'economic_sentiment_indicator.title:ja:Euro Area': '景況感指数',
  'orthodox_pentecost_monday.title:ja:DEFAULT': '正教会のペンテコステ月曜日',
  'orthodox_pentecost_monday.title:ja:Greece': '正教会のペンテコステ月曜日',
  'treasury_secretary_yellen_senate_confirmation_hearing.title:ja:DEFAULT':
    'イエレン財務長官の乗員証言公聴会',
  'treasury_secretary_yellen_senate_confirmation_hearing.title:ja:United States':
    'イエレン財務長官の乗員証言公聴会',
  'fed_chairman_nomination_vote.title:ja:DEFAULT': 'FRB議長指名投票',
  'fed_chairman_nomination_vote.title:ja:United States': 'FRB議長指名投票',
  'the_emperor_s_birthday_substitute_day.title:ja:DEFAULT': '天皇陛下誕生日（振替休日）',
  'the_emperor_s_birthday_substitute_day.title:ja:Japan': '天皇陛下誕生日（振替休日）',
  'european_council_meeting_on_brexit.title:ja:DEFAULT': 'ブレグジットに関する欧州理事会会合',
  'european_council_meeting_on_brexit.title:ja:European Union':
    'ブレグジットに関する欧州理事会会合',
  '50-year_obligacion_auction.title:ja:DEFAULT': '',
  '50-year_obligacion_auction.title:ja:Spain': '',
  'nbp_interest_rate_decison.title:ja:DEFAULT': 'ポーランド国立銀行（NBP）政策金利決定',
  'nbp_interest_rate_decison.title:ja:Poland': 'ポーランド国立銀行（NBP）政策金利決定',
  'the_day_of_valor.title:ja:DEFAULT': '勇者の日',
  'the_day_of_valor.title:ja:Philippines': '勇者の日',
  'imports.title:ja:DEFAULT': '輸入高',
  'imports.title:ja:Philippines': '輸入高',
  'imports.title:ja:Russia': '輸入高',
  'imports.title:ja:Portugal': '輸入高',
  'imports.title:ja:China': '輸入高',
  'imports.title:ja:South Korea': '輸入高',
  'imports.title:ja:Taiwan': '輸入高',
  'imports.title:ja:Canada': '輸入高',
  'imports.title:ja:New Zealand': '輸入高',
  'imports.title:ja:Colombia': '輸入高',
  'imports.title:ja:Brazil': '輸入高',
  'imports.title:ja:United States': '輸入高',
  'imports.title:ja:Israel': '輸入高',
  'imports.title:ja:Hong Kong': '輸入高',
  'imports.title:ja:South Africa': '輸入高',
  'imports.title:ja:Japan': '輸入高',
  'imports.title:ja:Mexico': '輸入高',
  'imports.title:ja:Czech Republic': '輸入高',
  'imports.title:ja:Thailand': '輸入高',
  'imports.title:ja:Indonesia': '輸入高',
  'gdp_deflator_yoy_final.title:ja:DEFAULT': 'GDPデフレーター（前年比）（確報値）',
  'gdp_deflator_yoy_final.title:ja:Japan': 'GDPデフレーター（前年比）（確報値）',
  'private_sector_credit.title:ja:DEFAULT': '民間セクター信用供与',
  'private_sector_credit.title:ja:South Africa': '民間セクター信用供与',
  'rbnz_mcdermott_speech.title:ja:DEFAULT':
    'ニュージーランド準備銀行（RBNZ）マクダーモット総裁補佐講演',
  'rbnz_mcdermott_speech.title:ja:New Zealand':
    'ニュージーランド準備銀行（RBNZ）マクダーモット総裁補佐講演',
  'tankan_small_manufacturers_index.title:ja:DEFAULT': '日銀短観中小企業・製造業業況判断指数',
  'tankan_small_manufacturers_index.title:ja:Japan': '日銀短観中小企業・製造業業況判断指数',
  'wpi_fuel_yoy.title:ja:DEFAULT': '卸売物価指数（WPI）燃料価格（前年比）',
  'wpi_fuel_yoy.title:ja:India': '卸売物価指数（WPI）燃料価格（前年比）',
  'new_home_sales.title:ja:DEFAULT': '新築住宅販売件数',
  'new_home_sales.title:ja:United States': '新築住宅販売件数',
  'tankan_non-manufacturers_outlook.title:ja:DEFAULT': '日銀短観非製造業企業先行き',
  'tankan_non-manufacturers_outlook.title:ja:Japan': '日銀短観非製造業企業先行き',
  'tankan_large_non-manufacturing_index.title:ja:DEFAULT': '日銀短観大企業・非製造業業況判断指数',
  'tankan_large_non-manufacturing_index.title:ja:Japan': '日銀短観大企業・非製造業業況判断指数',
  'manufacturing_production.title:ja:DEFAULT': '製造業生産',
  'manufacturing_production.title:ja:South Korea': '製造業生産',
  'bundesbank_weidmann_speech.title:ja:DEFAULT': 'ドイツ連邦銀行ワイトマン総裁発言',
  'bundesbank_weidmann_speech.title:ja:Germany': 'ドイツ連邦銀行ワイトマン総裁発言',
  'federal_budget_balance.title:ja:DEFAULT': '連邦予算収支',
  'federal_budget_balance.title:ja:United States': '連邦予算収支',
  'retail_sales_ex_fuel_mom.title:ja:DEFAULT': '小売売上高（燃料を除く）（前月比）',
  'retail_sales_ex_fuel_mom.title:ja:United Kingdom': '小売売上高（燃料を除く）（前月比）',
  'labour_productivity_qoq_prel.title:ja:DEFAULT': '労働生産性（前四半期比）速報値',
  'labour_productivity_qoq_prel.title:ja:United Kingdom': '労働生産性（前四半期比）速報値',
  'nikkei_manufacturing_pmi_final.title:ja:DEFAULT': '日経製造業PMI（購買担当者景気指数）確報値',
  'nikkei_manufacturing_pmi_final.title:ja:Japan': '日経製造業PMI（購買担当者景気指数）確報値',
  'referendum_on_corporate_tax_unification.title:ja:DEFAULT': '法人税改革案の国民投票',
  'referendum_on_corporate_tax_unification.title:ja:Switzerland': '法人税改革案の国民投票',
  'battle_of_boyac_day.title:ja:DEFAULT': 'ボヤカ戦勝記念日',
  'battle_of_boyac_day.title:ja:Colombia': 'ボヤカ戦勝記念日',
  'wpi_food_yoy.title:ja:DEFAULT': '卸売物価指数（WPI）食品価格（前年比）',
  'wpi_food_yoy.title:ja:India': '卸売物価指数（WPI）食品価格（前年比）',
  'passover_i_eve.title:ja:DEFAULT': '過越祭初日前夜',
  'passover_i_eve.title:ja:Israel': '過越祭初日前夜',
  'personal_consumption_expenditures_-_price_index_mom.title:ja:DEFAULT':
    '個人消費支出－価格指数（前月比）',
  'personal_consumption_expenditures_-_price_index_mom.title:ja:United States':
    '個人消費支出－価格指数（前月比）',
  'markit_bme_manufacturing_pmi_final.title:ja:DEFAULT':
    'マークイット/BME製造業購買担当者景気指数（PMI）確報値',
  'markit_bme_manufacturing_pmi_final.title:ja:Germany':
    'マークイット/BME製造業購買担当者景気指数（PMI）確報値',
  'ecb_hakkarainen_speech.title:ja:DEFAULT': 'ECBハッカライネン銀行監督理事会代表講演',
  'ecb_hakkarainen_speech.title:ja:Euro Area': 'ECBハッカライネン銀行監督理事会代表講演',
  'pentecost.title:ja:DEFAULT': '五旬節',
  'pentecost.title:ja:France': '五旬節',
  'pentecost.title:ja:Hungary': '五旬節',
  'pentecost.title:ja:Israel': '五旬節',
  'pentecost.title:ja:Sweden': '五旬節',
  'pentecost.title:ja:Denmark': '五旬節',
  'pentecost.title:ja:Poland': '五旬節',
  'pentecost.title:ja:Finland': '五旬節',
  'pentecost.title:ja:Netherlands': '五旬節',
  'pentecost.title:ja:Norway': '五旬節',
  'tankan_all_small_industry_capex.title:ja:DEFAULT': '日銀短観中小企業・全産業設備投資計画',
  'tankan_all_small_industry_capex.title:ja:Japan': '日銀短観中小企業・全産業設備投資計画',
  'anz_commodity_price.title:ja:DEFAULT': 'ANZ商品価格指数',
  'anz_commodity_price.title:ja:New Zealand': 'ANZ商品価格指数',
  'republic_implantation.title:ja:DEFAULT': '共和制樹立記念日',
  'republic_implantation.title:ja:Portugal': '共和制樹立記念日',
  'veterans_day.title:ja:DEFAULT': '復員軍人の日（ベテランズデー）',
  'veterans_day.title:ja:United States': '復員軍人の日（ベテランズデー）',
  'assumption_of_mary.title:ja:DEFAULT': '聖母被昇天祭',
  'assumption_of_mary.title:ja:Spain': '聖母被昇天祭',
  'assumption_of_mary.title:ja:Portugal': '聖母被昇天祭',
  'assumption_of_mary.title:ja:Italy': '聖母被昇天祭',
  'assumption_of_mary.title:ja:Greece': '聖母被昇天祭',
  'assumption_of_mary.title:ja:Poland': '聖母被昇天祭',
  'assumption_of_mary.title:ja:Germany': '聖母被昇天祭',
  'assumption_of_mary.title:ja:Austria': '聖母被昇天祭',
  'assumption_of_mary.title:ja:Colombia': '聖母被昇天祭',
  'assumption_of_mary.title:ja:Belgium': '聖母被昇天祭',
  'assumption_of_mary.title:ja:France': '聖母被昇天祭',
  'buddhas_birthday.title:ja:DEFAULT': '灌仏会',
  'buddhas_birthday.title:ja:Hong Kong': '灌仏会',
  'buddhas_birthday.title:ja:Singapore': '灌仏会',
  'buddhas_birthday.title:ja:Indonesia': '灌仏会',
  'buddhas_birthday.title:ja:South Korea': '灌仏会',
  'buddhas_birthday.title:ja:Thailand': '灌仏会',
  'index_of_services_3m_3m.title:ja:DEFAULT': 'サービス業指数（直近3カ月とその前の3カ月比）',
  'index_of_services_3m_3m.title:ja:United Kingdom': 'サービス業指数（直近3カ月とその前の3カ月比）',
  'cb_leading_index_mom.title:ja:DEFAULT': 'コンファレンスボード（CB）景気先行指数（前月比）',
  'cb_leading_index_mom.title:ja:United Kingdom':
    'コンファレンスボード（CB）景気先行指数（前月比）',
  'cb_leading_index_mom.title:ja:Australia': 'コンファレンスボード（CB）景気先行指数（前月比）',
  'cb_leading_index_mom.title:ja:United States': 'コンファレンスボード（CB）景気先行指数（前月比）',
  'pm_may_no_confidence_vote.title:ja:DEFAULT': 'メイ首相不信任投票',
  'pm_may_no_confidence_vote.title:ja:United Kingdom': 'メイ首相不信任投票',
  'retail_trade_yoy.title:ja:DEFAULT': '小売売上高（前年比）',
  'retail_trade_yoy.title:ja:Japan': '小売売上高（前年比）',
  'unit_labour_costs_qoq_final.title:ja:DEFAULT': '単位労働コスト（前四半期比）確報値',
  'unit_labour_costs_qoq_final.title:ja:United States': '単位労働コスト（前四半期比）確報値',
  'svme_pmi.title:ja:DEFAULT': 'スイスSVME購買担当者景気指数（PMI）',
  'svme_pmi.title:ja:Switzerland': 'スイスSVME購買担当者景気指数（PMI）',
  'us-japan_trade_talks.title:ja:DEFAULT': '日米貿易交渉',
  'us-japan_trade_talks.title:ja:Japan': '日米貿易交渉',
  'td_securities_inflation_mom.title:ja:DEFAULT': 'TD Securitiesインフレ率（前月比）',
  'td_securities_inflation_mom.title:ja:Australia': 'TD Securitiesインフレ率（前月比）',
  'td_securities_inflation_yoy.title:ja:DEFAULT': 'TD Securitiesインフレ率（前年比）',
  'td_securities_inflation_yoy.title:ja:Australia': 'TD証券インフレ指数（前年比）',
  'michigan_current_conditions_final.title:ja:DEFAULT':
    'ミシガン大学消費者信頼感現況指数（確報値）',
  'michigan_current_conditions_final.title:ja:United States':
    'ミシガン大学消費者信頼感現況指数（確報値）',
  'rba_monetary_policy_statement.title:ja:DEFAULT': 'オーストラリア準備銀行（RBA）金融政策発表',
  'rba_monetary_policy_statement.title:ja:Australia': 'オーストラリア準備銀行（RBA）金融政策発表',
  'national_women_s_day.title:ja:DEFAULT': '全国女性の日',
  'national_women_s_day.title:ja:South Africa': '全国女性の日',
  'markit_services_pmi_final.title:ja:DEFAULT':
    'マークイットサービス業購買担当者景気指数（PMI）確報値',
  'markit_services_pmi_final.title:ja:Australia':
    'マークイットサービス業購買担当者景気指数（PMI）確報値',
  'markit_services_pmi_final.title:ja:Germany':
    'マークイットサービス業購買担当者景気指数（PMI）確報値',
  'markit_services_pmi_final.title:ja:France':
    'マークイットサービス業購買担当者景気指数（PMI）確報値',
  'markit_services_pmi_final.title:ja:Euro Area':
    'マークイットサービス業購買担当者景気指数（PMI）確報値',
  'markit_services_pmi_final.title:ja:United States':
    'マークイットサービス業購買担当者景気指数（PMI）確報値',
  'black_awareness_day.title:ja:DEFAULT': '黒人意識の日',
  'black_awareness_day.title:ja:Brazil': '黒人意識の日',
  'fin_minister_osborne_speaks_in_parliament.title:ja:DEFAULT': 'オズボーン英財務相議会演説',
  'fin_minister_osborne_speaks_in_parliament.title:ja:United Kingdom': 'オズボーン英財務相議会演説',
  'riksbank_gov_ingves_speech.title:ja:DEFAULT':
    'リクスバンク（スウェーデン中銀）イングベス総裁講演',
  'riksbank_gov_ingves_speech.title:ja:Sweden':
    'リクスバンク（スウェーデン中銀）イングベス総裁講演',
  'industrial_production_yoy_-_prel.title:ja:DEFAULT': '鉱工業生産（前年比）速報値',
  'industrial_production_yoy_-_prel.title:ja:Japan': '鉱工業生産（前年比）速報値',
  'supplementary_budget.title:ja:DEFAULT': '補正予算',
  'supplementary_budget.title:ja:United Kingdom': '補正予算',
  'supplementary_budget.title:ja:South Africa': '補正予算',
  'referendum_on_limitation_initiative.title:ja:DEFAULT': '移民制限イニシアチブ国民投票',
  'referendum_on_limitation_initiative.title:ja:Switzerland': '移民制限イニシアチブ国民投票',
  'gdp_3-month_avg.title:ja:DEFAULT': 'GDP（3カ月平均）',
  'gdp_3-month_avg.title:ja:United Kingdom': 'GDP（3カ月平均）',
  'wpi_manufacturing_yoy.title:ja:DEFAULT': '卸売物価指数（WPI）製造業（前年比）',
  'wpi_manufacturing_yoy.title:ja:India': '卸売物価指数（WPI）製造業（前年比）',
  'hia_new_home_sales.title:ja:DEFAULT': 'オーストラリア住宅産業協会（HIA）新築住宅販売件数',
  'hia_new_home_sales.title:ja:Australia': 'オーストラリア住宅産業協会（HIA）新築住宅販売件数',
  'chancellor_osborne_budget_statement.title:ja:DEFAULT': 'オズボーン英財務相による予算演説',
  'chancellor_osborne_budget_statement.title:ja:United Kingdom': 'オズボーン英財務相による予算演説',
  'sunak_statement_on_covid_support.title:ja:DEFAULT':
    'スナク英財務相による新型コロナウイルス感染症（COVID）支援措置の声明発表',
  'sunak_statement_on_covid_support.title:ja:United Kingdom':
    'スナク英財務相による新型コロナウイルス感染症（COVID）支援措置の声明発表',
  'regional_elections.title:ja:DEFAULT': '地方選挙',
  'regional_elections.title:ja:Germany': '地方選挙',
  'regional_elections.title:ja:Italy': '地方選挙',
  'regional_elections.title:ja:Austria': '地方選挙',
  'money_supply_m2_cd_yoy.title:ja:DEFAULT': 'マネーサプライM2+CD (前年比）',
  'money_supply_m2_cd_yoy.title:ja:Japan': 'マネーサプライ（M2+CD）（前年比）',
  'the_prophet_muhammad_s_birthday.title:ja:DEFAULT': '預言者ムハマンド生誕祭',
  'the_prophet_muhammad_s_birthday.title:ja:Indonesia': '預言者ムハマンド生誕祭',
  'president_obama_speech.title:ja:DEFAULT': 'オバマ米大統領演説',
  'president_obama_speech.title:ja:United States': 'オバマ米大統領演説',
  'prime_minister_theresa_may_speech.title:ja:DEFAULT': 'メイ英首相演説',
  'prime_minister_theresa_may_speech.title:ja:United Kingdom': 'メイ英首相演説',
  'great_prayer_day.title:ja:DEFAULT': '大祈禱日',
  'great_prayer_day.title:ja:Denmark': '大祈禱日',
  'struggle_for_freedom_and_democracy_day.title:ja:DEFAULT': '自由と民主主義闘争記念日',
  'struggle_for_freedom_and_democracy_day.title:ja:Czech Republic': '自由と民主主義闘争記念日',
  'stock_investment_by_foreigners.title:ja:DEFAULT': '外国人投資家による株式投資',
  'stock_investment_by_foreigners.title:ja:Canada': '外国人投資家による株式投資',
  'stock_investment_by_foreigners.title:ja:Japan': '外国人投資家による株式投資',
  'president_s_fy2022_budget.title:ja:DEFAULT': '大統領2022年度予算教書',
  'president_s_fy2022_budget.title:ja:United States': '大統領2022年度予算教書',
  'property_price_index_yoy.title:ja:DEFAULT': '不動産価格指数（前年比）',
  'property_price_index_yoy.title:ja:Indonesia': '不動産価格指数（前年比）',
  'domestic_truck_sales.title:ja:DEFAULT': '国内トラック販売台数',
  'domestic_truck_sales.title:ja:United States': '国内トラック販売台数',
  'gdp_growth_yoy.title:ja:DEFAULT': 'GDP成長率（前年比）',
  'gdp_growth_yoy.title:ja:Spain': 'GDP成長率（前年比）',
  'gdp_growth_yoy.title:ja:United Kingdom': 'GDP成長率（前年比）',
  'the_kings_birthday.title:ja:DEFAULT': '国王陛下誕生日',
  'the_kings_birthday.title:ja:Thailand': '国王陛下誕生日',
  'non_farm_payrolls.title:ja:DEFAULT': '非農業部門雇用者数',
  'non_farm_payrolls.title:ja:Switzerland': '非農業部門雇用者数',
  'non_farm_payrolls.title:ja:United States': '非農業部門雇用者数',
  'the_queen_mother_s_birthday.title:ja:DEFAULT': '王太后誕生日',
  'the_queen_mother_s_birthday.title:ja:Thailand': '王太后誕生日',
  'easter_sunday.title:ja:DEFAULT': 'イースター・サンデー',
  'easter_sunday.title:ja:Italy': 'イースター・サンデー',
  'easter_sunday.title:ja:Sweden': 'イースター・サンデー',
  'easter_sunday.title:ja:Portugal': 'イースター・サンデー',
  'easter_sunday.title:ja:Colombia': 'イースター・サンデー',
  'easter_sunday.title:ja:Brazil': 'イースター・サンデー',
  'easter_sunday.title:ja:Finland': 'イースター・サンデー',
  'easter_sunday.title:ja:New Zealand': 'イースター・サンデー',
  'easter_sunday.title:ja:Poland': 'イースター・サンデー',
  'easter_sunday.title:ja:Austria': 'イースター・サンデー',
  'easter_sunday.title:ja:Denmark': 'イースター・サンデー',
  'easter_sunday.title:ja:Hungary': 'イースター・サンデー',
  'easter_sunday.title:ja:Australia': 'イースター・サンデー',
  'easter_sunday.title:ja:Netherlands': 'イースター・サンデー',
  'easter_sunday.title:ja:Norway': 'イースター・サンデー',
  'revolution_day_memorial.title:ja:DEFAULT': '革命記念日',
  'revolution_day_memorial.title:ja:Mexico': '革命記念日',
  'boe_gov_bailey_speech.title:ja:DEFAULT': 'イングランド銀行ベイリー総裁講演',
  'boe_gov_bailey_speech.title:ja:United Kingdom': 'イングランド銀行ベイリー総裁講演',
  'defenders_of_the_fatherland_day_holiday.title:ja:DEFAULT': '祖国防衛の日',
  'defenders_of_the_fatherland_day_holiday.title:ja:Russia': '祖国防衛の日',
  'waisak_day_buddha_s_anniversary.title:ja:DEFAULT': 'ウェーサーカ祭（釈迦誕生日）',
  'waisak_day_buddha_s_anniversary.title:ja:Indonesia': 'ウェーサーカ祭（釈迦誕生日）',
  'st_stephen_s_day.title:ja:DEFAULT': '聖ステファノの日',
  'st_stephen_s_day.title:ja:Switzerland': '聖ステファノの日',
  'st_stephen_s_day.title:ja:Italy': '聖ステファノの日',
  'st_stephen_s_day.title:ja:Austria': '聖ステファノの日',
  'st_stephen_s_day.title:ja:Ireland': '聖ステファノの日',
  'st_stephen_s_day.title:ja:Hungary': '聖ステファノの日',
  'st_stephen_s_day.title:ja:Poland': '聖ステファノの日',
  'st_stephen_s_day.title:ja:Germany': '聖ステファノの日',
  'st_stephen_s_day.title:ja:Greece': '聖ステファノの日',
  'st_stephen_s_day.title:ja:Czech Republic': '聖ステファノの日',
  'fomc_press_conference.title:ja:DEFAULT': '米連邦公開市場委員会（FOMC）後の記者会見',
  'fomc_press_conference.title:ja:United States': '米連邦公開市場委員会（FOMC）後の記者会見',
  'employment_change_sa.title:ja:DEFAULT': '雇用者数変化（季節調整済み）',
  'employment_change_sa.title:ja:Australia': '雇用者数変化（季節調整済み）',
  'electronic_card_retail_sales_mom.title:ja:DEFAULT': '電子カード小売売上高（前月比）',
  'electronic_card_retail_sales_mom.title:ja:New Zealand': '電子カード小売売上高（前月比）',
  'nikkei_pmi.title:ja:DEFAULT': '日経購買担当者景気指数（PMI）',
  'nikkei_pmi.title:ja:Singapore': '日経購買担当者景気指数（PMI）',
  'nikkei_pmi.title:ja:Hong Kong': '日経購買担当者景気指数（PMI）',
  'st_peter_and_st_paul_day.title:ja:DEFAULT': '聖ペテロと聖パウロの日',
  'st_peter_and_st_paul_day.title:ja:Colombia': '聖ペテロと聖パウロの日',
  'spring_and_labor_day.title:ja:DEFAULT': '春と勤労の日',
  'spring_and_labor_day.title:ja:Russia': '春と勤労の日',
  'ecb_praet_speech.title:ja:DEFAULT': 'ECBプラート専務理事講演',
  'ecb_praet_speech.title:ja:Euro Area': 'ECBプラート専務理事講演',
  'spring_equinox_day.title:ja:DEFAULT': '春分の日',
  'spring_equinox_day.title:ja:Japan': '春分の日',
  'spring_budget_2018.title:ja:DEFAULT': '2018年春の予算編成',
  'spring_budget_2018.title:ja:United Kingdom': '2018年春の予算編成',
  'family_day.title:ja:DEFAULT': '家族の日',
  'family_day.title:ja:Australia': '家族の日',
  'family_day.title:ja:South Africa': '家族の日',
  'family_day.title:ja:Canada': '家族の日',
  'jobless_claims_4-week_average.title:ja:DEFAULT': '新規失業保険申請件数ー4週間移動平均',
  'jobless_claims_4-week_average.title:ja:United States': '新規失業保険申請件数ー4週間移動平均',
  'retail_price_index_mom.title:ja:DEFAULT': '小売物価指数（前月比）',
  'retail_price_index_mom.title:ja:United Kingdom': '小売物価指数（前月比）',
  'net_change_in_employment.title:ja:DEFAULT': '雇用者数純増減',
  'net_change_in_employment.title:ja:Canada': '雇用者数純増減',
  'special_holiday.title:ja:DEFAULT': '特別公休日',
  'special_holiday.title:ja:Thailand': '特別公休日',
  '12-month_bot_auction.title:ja:DEFAULT': '12カ月物タイ中央銀行（BOT）債入札',
  '12-month_bot_auction.title:ja:Italy': '12カ月物タイ中央銀行（BOT）債入札',
  'fed_stress_test_results_for_big_banks.title:ja:DEFAULT':
    'FRBによる大手銀行対象のストレステスト結果公表',
  'fed_stress_test_results_for_big_banks.title:ja:United States':
    'FRBによる大手銀行対象のストレステスト結果公表',
  'ppi_yoy.title:ja:DEFAULT': '生産者物価指数（PPI）（前年比）',
  'ppi_yoy.title:ja:Norway': '生産者物価指数（PPI）（前年比）',
  'ppi_yoy.title:ja:Philippines': '生産者物価指数（PPI）（前年比）',
  'ppi_yoy.title:ja:Colombia': '生産者物価指数（PPI）（前年比）',
  'ppi_yoy.title:ja:Euro Area': '生産者物価指数（PPI）（前年比）',
  'ppi_yoy.title:ja:Italy': '生産者物価指数（PPI）（前年比）',
  'ppi_yoy.title:ja:Canada': '生産者物価指数（PPI）（前年比）',
  'ppi_yoy.title:ja:South Korea': '生産者物価指数（PPI）（前年比）',
  'ppi_yoy.title:ja:Hungary': '生産者物価指数（PPI）（前年比）',
  'ppi_yoy.title:ja:France': '生産者物価指数（PPI）（前年比）',
  'ppi_yoy.title:ja:Belgium': '生産者物価指数（PPI）（前年比）',
  'ppi_yoy.title:ja:South Africa': '生産者物価指数（PPI）（前年比）',
  'ppi_yoy.title:ja:Austria': '生産者物価指数（PPI）（前年比）',
  'ppi_yoy.title:ja:Sweden': '生産者物価指数（PPI）（前年比）',
  'ppi_yoy.title:ja:Poland': '生産者物価指数（PPI）（前年比）',
  'ppi_yoy.title:ja:Germany': '生産者物価指数（PPI）（前年比）',
  'ppi_yoy.title:ja:Greece': '生産者物価指数（PPI）（前年比）',
  'ppi_yoy.title:ja:United States': '生産者物価指数（PPI）（前年比）',
  'ppi_yoy.title:ja:Switzerland': '生産者物価指数（PPI）（前年比）',
  'ppi_yoy.title:ja:China': '生産者物価指数（PPI）（前年比）',
  'ppi_yoy.title:ja:Portugal': '生産者物価指数（PPI）（前年比）',
  'ppi_yoy.title:ja:Spain': '生産者物価指数（PPI）（前年比）',
  'ppi_yoy.title:ja:Czech Republic': '生産者物価指数（PPI）（前年比）',
  'ppi_yoy.title:ja:Russia': '生産者物価指数（PPI）（前年比）',
  'ppi_yoy.title:ja:Finland': '生産者物価指数（PPI）（前年比）',
  'ppi_yoy.title:ja:Australia': '生産者物価指数（PPI）（前年比）',
  'ppi_yoy.title:ja:Singapore': '生産者物価指数（PPI）（前年比）',
  'ppi_yoy.title:ja:Japan': '生産者物価指数（PPI）（前年比）',
  'songkran_festival.title:ja:DEFAULT': 'ソンクラーン祭り',
  'songkran_festival.title:ja:Thailand': 'ソンクラーン祭り',
  'ecb_chief_lagarde_testimony.title:ja:DEFAULT': 'ECBラガルド総裁による議会証言',
  'ecb_chief_lagarde_testimony.title:ja:Euro Area': 'ECBラガルド総裁による議会証言',
  'hometrack_housing_prices_mom.title:ja:DEFAULT': 'ホームトラック住宅価格指数（前月比）',
  'hometrack_housing_prices_mom.title:ja:United Kingdom': 'ホームトラック住宅価格指数（前月比）',
  'rba_bullock_speech.title:ja:DEFAULT': 'オーストラリア準備銀行（RBA）ブロック総裁補講演',
  'rba_bullock_speech.title:ja:Australia': 'オーストラリア準備銀行（RBA）ブロック総裁補講演',
  'rbnz_financial_stability_report.title:ja:DEFAULT':
    'ニュージーランド準備銀行（RBNZ）金融安定性報告書',
  'rbnz_financial_stability_report.title:ja:New Zealand':
    'ニュージーランド準備銀行（RBNZ）金融安定性報告書',
  'eu_bailout_expiration.title:ja:DEFAULT': 'EUによる救済措置終了',
  'eu_bailout_expiration.title:ja:Greece': 'EUによる救済措置終了',
  'simchat_torah.title:ja:DEFAULT': 'シムハット・トーラー',
  'simchat_torah.title:ja:Israel': 'シムハット・トーラー',
  'eurogroup_meeting_on_greece.title:ja:DEFAULT': 'ギリシャ問題に関するユーロ圏財務相会合',
  'eurogroup_meeting_on_greece.title:ja:Euro Area': 'ギリシャ問題に関するユーロ圏財務相会合',
  'new_brunswick_provincial_elections.title:ja:DEFAULT': 'ニューブランズウィック州選挙',
  'new_brunswick_provincial_elections.title:ja:Canada': 'ニューブランズウィック州選挙',
  'fed_kocherlakota_speech.title:ja:DEFAULT': 'ミネアポリス連銀コチャラコタ総裁講演',
  'fed_kocherlakota_speech.title:ja:United States': 'ミネアポリス連銀コチャラコタ総裁講演',
  'shushan_purim_jerusalem.title:ja:DEFAULT': 'シュシャンプリム（エルサレム）',
  'shushan_purim_jerusalem.title:ja:Israel': 'シュシャンプリム（エルサレム）',
  'rbi_interest_rate_decision.title:ja:DEFAULT': 'インド準備銀行（RBI）政策金利決定',
  'rbi_interest_rate_decision.title:ja:India': 'インド準備銀行（RBI）政策金利決定',
  'gdp_growth_rate_yoy-final.title:ja:DEFAULT': 'GDP成長率（前年比）確報値',
  'gdp_growth_rate_yoy-final.title:ja:Singapore': 'GDP成長率（前年比）確報値',
  'treasury_secretary_lew_speaks.title:ja:DEFAULT': 'ルー米財務長官講演',
  'treasury_secretary_lew_speaks.title:ja:United States': 'ルー米財務長官講演',
  'shushan_purim.title:ja:DEFAULT': 'シュシャンプリム',
  'shushan_purim.title:ja:Israel': 'シュシャンプリム',
  'new_orders_yoy.title:ja:DEFAULT': '新規受注（前年比）',
  'new_orders_yoy.title:ja:Sweden': '新規受注（前年比）',
  'fed_bernanke_testifies.title:ja:DEFAULT': 'FRBバーナンキ議長証言',
  'fed_bernanke_testifies.title:ja:United States': 'FRBバーナンキ議長証言',
  'national_people_s_congress.title:ja:DEFAULT': '全国人民代表大会',
  'national_people_s_congress.title:ja:China': '全国人民代表大会',
  'mahatama_gandhi_jayanti.title:ja:DEFAULT': 'ガンディー生誕記念日',
  'mahatama_gandhi_jayanti.title:ja:India': 'ガンディー生誕記念日',
  'services_nz_psi.title:ja:DEFAULT': 'NZサービス業指数（PSI）',
  'services_nz_psi.title:ja:New Zealand': 'NZサービス業指数（PSI）',
  'export_orders_yoy.title:ja:DEFAULT': '輸出受注（前年比）',
  'export_orders_yoy.title:ja:Taiwan': '輸出受注（前年比）',
  'seollal_holiday_day_2.title:ja:DEFAULT': 'ソルラル（旧正月）2日目',
  'seollal_holiday_day_2.title:ja:South Korea': 'ソルラル（旧正月）2日目',
  'boj_quarterly_outlook_report.title:ja:DEFAULT': '日本銀行四半期展望レポート',
  'boj_quarterly_outlook_report.title:ja:Japan': '日本銀行四半期展望レポート',
  'president_trump_speech_on_iran_deal.title:ja:DEFAULT':
    'イラン核合意に関するトランプ米大統領の演説',
  'president_trump_speech_on_iran_deal.title:ja:United States':
    'イラン核合意に関するトランプ米大統領の演説',
  'm3_money_supply_3m.title:ja:DEFAULT': 'M3 マネーサプライ（3カ月）',
  'm3_money_supply_3m.title:ja:Euro Area': 'M3 マネーサプライ（3カ月）',
  'labour_cost_index_qoq.title:ja:DEFAULT': '労働コスト指数（前四半期比）',
  'labour_cost_index_qoq.title:ja:New Zealand': '労働コスト指数（前四半期比）',
  'markit_composite_pmi_final.title:ja:DEFAULT': 'マークイット総合購買担当者景気指数（PMI）確報値',
  'markit_composite_pmi_final.title:ja:Australia':
    'マークイット総合購買担当者景気指数（PMI）確報値',
  'markit_composite_pmi_final.title:ja:Germany': 'マークイット総合購買担当者景気指数（PMI）確報値',
  'markit_composite_pmi_final.title:ja:France': 'マークイット総合購買担当者景気指数（PMI）確報値',
  'markit_composite_pmi_final.title:ja:Euro Area':
    'マークイット総合購買担当者景気指数（PMI）確報値',
  'markit_composite_pmi_final.title:ja:United States':
    'マークイット総合購買担当者景気指数（PMI）確報値',
  'seollal_holiday.title:ja:DEFAULT': 'ソルラル（旧正月）',
  'seollal_holiday.title:ja:South Korea': 'ソルラル（旧正月）',
  'sentix_investors_sentiment.title:ja:DEFAULT': 'センティックス投資家信頼感指数',
  'sentix_investors_sentiment.title:ja:Euro Area': 'センティックス投資家信頼感指数',
  'new_year_holiday_week.title:ja:DEFAULT': '正月休みの週',
  'new_year_holiday_week.title:ja:Russia': '正月休みの週',
  'hsbc_manufacturing_pmi_flash.title:ja:DEFAULT': 'HSBC製造業購買担当者景気指数（PMI）速報値',
  'hsbc_manufacturing_pmi_flash.title:ja:China': 'HSBC製造業購買担当者景気指数（PMI）速報値',
  'house_price_index_mom.title:ja:DEFAULT': '住宅価格指数（前月比）',
  'house_price_index_mom.title:ja:Norway': '住宅価格指数（前月比）',
  'house_price_index_mom.title:ja:United Kingdom': '住宅価格指数（前月比）',
  'house_price_index_mom.title:ja:China': '住宅価格指数（前月比）',
  'house_price_index_mom.title:ja:United States': '住宅価格指数（前月比）',
  'rba_meetings_minutes.title:ja:DEFAULT': 'オーストラリア準備銀行（RBA）政策理事会議事録',
  'rba_meetings_minutes.title:ja:Australia': 'オーストラリア準備銀行（RBA）政策理事会議事録',
  'scottish_independence_referendum.title:ja:DEFAULT': 'スコットランド独立の住民投票',
  'scottish_independence_referendum.title:ja:United Kingdom': 'スコットランド独立の住民投票',
  'gdp_growth_rate_qoq_prel.title:ja:DEFAULT': 'GDP成長率（前四半期比）速報',
  'gdp_growth_rate_qoq_prel.title:ja:Japan': 'GDP成長率（前四半期比）速報値',
  'reuters_tankan_index.title:ja:DEFAULT': 'ロイター短観',
  'reuters_tankan_index.title:ja:Japan': 'ロイター短観',
  'sacrifice_feast.title:ja:DEFAULT': '犠牲祭（イスラム教の祝日）',
  'sacrifice_feast.title:ja:Singapore': '犠牲祭（イスラム教の祝日）',
  'sacrifice_feast.title:ja:Indonesia': '犠牲祭（イスラム教の祝日）',
  'aig_performance_construction_index.title:ja:DEFAULT':
    'オーストラリア産業グループ（AIG） 建設業指数',
  'aig_performance_construction_index.title:ja:Australia':
    'オーストラリア産業グループ（AIG） 建設業指数',
  'fed_williams_speech.title:ja:DEFAULT': 'ニューヨーク連銀ウィリアムズ総裁講演',
  'fed_williams_speech.title:ja:United States': 'ニューヨーク連銀ウィリアムズ総裁講演',
  'tankan_large_all_industry_capex.title:ja:DEFAULT': '日銀短観大企業・全産業設備投資計画',
  'tankan_large_all_industry_capex.title:ja:Japan': '日銀短観大企業・全産業設備投資計画',
  'german_unity_day.title:ja:DEFAULT': 'ドイツ統一の日',
  'german_unity_day.title:ja:Germany': 'ドイツ統一の日',
  'total_credit_yoy.title:ja:DEFAULT': '総与信額（前年比）',
  'total_credit_yoy.title:ja:Greece': '総与信額（前年比）',
  'unemployment_rate.title:ja:Thailand': '失業率',
  'unemployment_rate.title:ja:China': '失業率',
  'unemployment_rate.title:ja:Portugal': '失業率',
  'unemployment_rate.title:ja:New Zealand': '失業率',
  'unemployment_rate.title:ja:Finland': '失業率',
  'unemployment_rate.title:ja:South Korea': '失業率',
  'unemployment_rate.title:ja:Hong Kong': '失業率',
  'unemployment_rate.title:ja:Indonesia': '失業率',
  'unemployment_rate.title:ja:Canada': '失業率',
  'unemployment_rate.title:ja:Norway': '失業率',
  'unemployment_rate.title:ja:Mexico': '失業率',
  'unemployment_rate.title:ja:Singapore': '失業率',
  'unemployment_rate.title:ja:Japan': '失業率',
  'unemployment_rate.title:ja:Israel': '失業率',
  'unemployment_rate.title:ja:Hungary': '失業率',
  'unemployment_rate.title:ja:Netherlands': '失業率',
  'unemployment_rate.title:ja:Australia': '失業率',
  'unemployment_rate.title:ja:Taiwan': '失業率',
  'unemployment_rate.title:ja:Poland': '失業率',
  'unemployment_rate.title:ja:Germany': '失業率',
  'unemployment_rate.title:ja:Sweden': '失業率',
  'unemployment_rate.title:ja:South Africa': '失業率',
  'unemployment_rate.title:ja:France': '失業率',
  'unemployment_rate.title:ja:Belgium': '失業率',
  'unemployment_rate.title:ja:United Kingdom': '失業率',
  'unemployment_rate.title:ja:Ireland': '失業率',
  'unemployment_rate.title:ja:United States': '失業率',
  'unemployment_rate.title:ja:Greece': '失業率',
  'unemployment_rate.title:ja:Spain': '失業率',
  'unemployment_rate.title:ja:Russia': '失業率',
  'unemployment_rate.title:ja:Denmark': '失業率',
  'unemployment_rate.title:ja:Czech Republic': '失業率',
  'unemployment_rate.title:ja:Switzerland': '失業率',
  'unemployment_rate.title:ja:Colombia': '失業率',
  'unemployment_rate.title:ja:Euro Area': '失業率',
  'unemployment_rate.title:ja:Philippines': '失業率',
  'mpc_member_paul_fisher_speech.title:ja:DEFAULT':
    'イングランド銀行金融政策委員会フィッシャー委員講演',
  'mpc_member_paul_fisher_speech.title:ja:United Kingdom':
    'イングランド銀行金融政策委員会フィッシャー委員講演',
  'pmi_services.title:ja:Sweden': 'サービス業PMI',
  'peace_memorial_day.title:ja:DEFAULT': '平和記念日',
  'peace_memorial_day.title:ja:Taiwan': '平和記念日',
  'non_trading.title:ja:DEFAULT': '取引なし',
  'non_trading.title:ja:Taiwan': '取引なし',
  'gross_fixed_investment_mom.title:ja:DEFAULT': '総固定投資（前月比）',
  'gross_fixed_investment_mom.title:ja:Mexico': '総固定投資（前月比）',
  'wholesale_sales_mom_flash.title:ja:DEFAULT': '卸売売上高（前月比）速報値',
  'wholesale_sales_mom_flash.title:ja:Canada': '卸売売上高（前月比）速報値',
  'svme_-_purchasing_managers_index.title:ja:DEFAULT': 'スイスSVME購買担当者景気指数（PMI）',
  'svme_-_purchasing_managers_index.title:ja:Switzerland': 'スイスSVME購買担当者景気指数（PMI）',
  'norges_bank_monetary_policy_report.title:ja:DEFAULT': 'ノルウェー銀行金融政策報告',
  'norges_bank_monetary_policy_report.title:ja:Norway': 'ノルウェー銀行金融政策報告',
  'rba_jones_testimony.title:ja:DEFAULT': '',
  'rba_jones_testimony.title:ja:Australia': '',
  'real_wage_growth_yoy.title:ja:DEFAULT': '実質賃金上昇率（前年比）',
  'real_wage_growth_yoy.title:ja:Russia': '実質賃金上昇率（前年比）',
  'boc_rate_statement.title:ja:DEFAULT': 'カナダ中銀（BoC）政策金利発表',
  'boc_rate_statement.title:ja:Canada': 'カナダ中銀（BoC）政策金利発表',
  'yom_kippur_atonement_day.title:ja:DEFAULT': 'ヨム・キプル（贖罪の日）',
  'yom_kippur_atonement_day.title:ja:Israel': 'ヨム・キプル（贖罪の日）',
  'guru_nanak_jayanti.title:ja:DEFAULT': 'グル・ナーナク生誕祭',
  'guru_nanak_jayanti.title:ja:India': 'グル・ナーナク生誕祭',
  'fed_pace_of_treasury_purchase_program.title:ja:DEFAULT': 'FRBによる米国債購入プログラムのペース',
  'fed_pace_of_treasury_purchase_program.title:ja:United States':
    'FRBによる米国債購入プログラムのペース',
  'day_of_german_unity.title:ja:DEFAULT': 'ドイツ統一の日',
  'day_of_german_unity.title:ja:Germany': 'ドイツ統一の日',
  'nonfarm_payrolls_private.title:ja:DEFAULT': '民間非農業部門雇用者数',
  'nonfarm_payrolls_private.title:ja:United States': '民間非農業部門雇用者数',
  'wholesale_prices_mom.title:ja:DEFAULT': '卸売物価（前月比）',
  'wholesale_prices_mom.title:ja:Ireland': '卸売物価（前月比）',
  'wholesale_prices_mom.title:ja:Austria': '卸売物価（前月比）',
  'wholesale_prices_mom.title:ja:Germany': '卸売物価（前月比）',
  'snb_zurbr_gg_speech.title:ja:DEFAULT': 'スイス国立銀行ツアブリュック氏講演',
  'snb_zurbr_gg_speech.title:ja:Switzerland': 'スイス国立銀行ツアブリュック氏講演',
  'nominal_budget_balance.title:ja:DEFAULT': '名目予算収支',
  'nominal_budget_balance.title:ja:Brazil': '名目予算収支',
  'gross_domestic_product_annualized_r.title:ja:DEFAULT': 'GDP年率（改定値）',
  'gross_domestic_product_annualized_r.title:ja:Japan': 'GDP年率（改定値）',
  'us-mexico_trade_talks.title:ja:DEFAULT': '米国・メキシコ貿易交渉',
  'us-mexico_trade_talks.title:ja:United States': '米国・メキシコ貿易交渉',
  'canadian_investment_in_foreign_securities.title:ja:DEFAULT': 'カナダ対外証券投資',
  'canadian_investment_in_foreign_securities.title:ja:Canada': 'カナダ対外証券投資',
  'victory_in_europe_day.title:ja:DEFAULT': 'ヨーロッパ戦勝記念日',
  'victory_in_europe_day.title:ja:France': 'ヨーロッパ戦勝記念日',
  'victory_in_europe_day.title:ja:Czech Republic': 'ヨーロッパ戦勝記念日',
  'day_of_the_republic.title:ja:DEFAULT': '共和国記念日',
  'day_of_the_republic.title:ja:Hungary': '共和国記念日',
  'labor_thanksgiving_day.title:ja:DEFAULT': '勤労感謝の日',
  'labor_thanksgiving_day.title:ja:Japan': '勤労感謝の日',
  'real_consumer_spending.title:ja:DEFAULT': '実質消費支出',
  'real_consumer_spending.title:ja:United States': '実質消費支出',
  'industrial_sales_mom.title:ja:DEFAULT': '鉱工業売上高（前月比）',
  'industrial_sales_mom.title:ja:Italy': '鉱工業売上高（前月比）',
  'august_bank_holiday.title:ja:DEFAULT': '8月のバンク・ホリデー',
  'august_bank_holiday.title:ja:Ireland': '8月のバンク・ホリデー',
  'ism_manufacturing_prices_paid.title:ja:DEFAULT':
    '米供給管理協会（ISM） 製造業購買担当者景気指数（PMI）仕入価格指数',
  'ism_manufacturing_prices_paid.title:ja:United States':
    '米供給管理協会（ISM） 製造業購買担当者景気指数（PMI）仕入価格指数',
  'bundesbank_semi-annual_forecasts.title:ja:DEFAULT': 'ドイツ連銀半期経済見通し',
  'bundesbank_semi-annual_forecasts.title:ja:Germany': 'ドイツ連銀半期経済見通し',
  'snb_monthly_bulletin.title:ja:DEFAULT': 'スイス国立銀行（SNB）月報',
  'snb_monthly_bulletin.title:ja:Switzerland': 'スイス国立銀行（SNB）月報',
  'house_prices_yoy.title:ja:DEFAULT': '住宅価格指数（前年比）',
  'house_prices_yoy.title:ja:Spain': '住宅価格指数（前年比）',
  'snb_moser_speech.title:ja:DEFAULT': 'スイス国立銀行（SNB）モーザー代理委員講演',
  'snb_moser_speech.title:ja:Switzerland': 'スイス国立銀行（SNB）モーザー代理委員講演',
  'snb_interest_rate_decision.title:ja:DEFAULT': 'スイス国立銀行（SNB）政策金利決定',
  'snb_interest_rate_decision.title:ja:Switzerland': 'スイス国立銀行（SNB）政策金利決定',
  'gdp_growth_rate_2nd_est.title:ja:DEFAULT': 'GDP 成長率（2次速報）',
  'gdp_growth_rate_2nd_est.title:ja:United States': 'GDP 成長率（2次速報）',
  'new_car_sales_yoy.title:ja:DEFAULT': '新車販売台数（前年比）',
  'new_car_sales_yoy.title:ja:United Kingdom': '新車販売台数（前年比）',
  'new_car_sales_yoy.title:ja:Thailand': '新車販売台数（前年比）',
  'new_car_sales_yoy.title:ja:Spain': '新車販売台数（前年比）',
  'retail_sales_qoq.title:ja:DEFAULT': '小売売上高（前四半期比）',
  'retail_sales_qoq.title:ja:New Zealand': '小売売上高（前四半期比）',
  'snb_chairman_jordan_speech.title:ja:DEFAULT': 'スイス国立銀行（SNB）ジョルダン総裁講演',
  'snb_chairman_jordan_speech.title:ja:Switzerland': 'スイス国立銀行（SNB）ジョルダン総裁講演',
  'fixed_asset_investment_ytd_yoy.title:ja:DEFAULT': '固定資産投資（年初来）（前年比）',
  'fixed_asset_investment_ytd_yoy.title:ja:China': '固定資産投資（年初来）（前年比）',
  'import_prices_mom.title:ja:DEFAULT': '輸入物価指数（前月比）',
  'import_prices_mom.title:ja:United States': '輸入物価指数（前月比）',
  'import_prices_mom.title:ja:Germany': '輸入物価指数（前月比）',
  'snb_annual_report.title:ja:DEFAULT': 'スイス国立銀行（SNB）年次報告書',
  'snb_annual_report.title:ja:Switzerland': 'スイス国立銀行（SNB）年次報告書',
  'ppi_input_mom.title:ja:DEFAULT': '生産者物価指数（PPI）投入物価（前月比）',
  'ppi_input_mom.title:ja:United Kingdom': '生産者物価指数（PPI）投入物価（前月比）',
  'president_trump_statement_on_coronavirus.title:ja:DEFAULT':
    '新型コロナウイルス感染症に関するトランプ米大統領の声明',
  'president_trump_statement_on_coronavirus.title:ja:United States':
    '新型コロナウイルス感染症に関するトランプ米大統領の声明',
  'sacci_business_confidence.title:ja:DEFAULT': '南アフリカ商工会議所（SACCI）企業景況感指数',
  'sacci_business_confidence.title:ja:South Africa': '南アフリカ商工会議所（SACCI）企業景況感指数',
  'reconciliation_day.title:ja:DEFAULT': '調和の日',
  'reconciliation_day.title:ja:Australia': '調和の日',
  'reconciliation_day.title:ja:South Africa': '調和の日',
  'imf_world_bank_spring_meetings.title:ja:DEFAULT': 'IMF・世界銀行春季会合',
  'imf_world_bank_spring_meetings.title:ja:United States': 'IMF・世界銀行春季会合',
  'imf_world_bank_spring_meetings.title:ja:Euro Area': 'IMF・世界銀行春季会合',
  'president_zuma_state_of_the_nation_speech.title:ja:DEFAULT': 'ズマ南アフリカ大統領一般教書演説',
  'president_zuma_state_of_the_nation_speech.title:ja:South Africa':
    'ズマ南アフリカ大統領一般教書演説',
  'riksbank_rate_decision.title:ja:DEFAULT': 'リクスバンク（スウェーデン中銀）政策金利決定',
  'riksbank_rate_decision.title:ja:Sweden': 'リクスバンク（スウェーデン中銀）政策金利決定',
  'bcb_copom_meeting_minutes.title:ja:DEFAULT': 'ブラジル中銀金融政策委員会（COPOM）議事録',
  'bcb_copom_meeting_minutes.title:ja:Brazil': 'ブラジル中銀金融政策委員会（COPOM）議事録',
  'gdp_yoy.title:ja:DEFAULT': 'GDP（国内総生産）（前年比）',
  'gdp_yoy.title:ja:United Kingdom': 'GDP（国内総生産）（前年比）',
  'gdp_yoy.title:ja:Finland': 'GDP（国内総生産）（前年比）',
  'gdp_yoy.title:ja:Russia': 'GDP（国内総生産）（前年比）',
  'boes_governor_king_speech.title:ja:DEFAULT': 'イングランド銀行キング総裁講演',
  'boes_governor_king_speech.title:ja:United Kingdom': 'イングランド銀行キング総裁講演',
  'loans_to_private_sector.title:ja:DEFAULT': '民間部門向け融資',
  'loans_to_private_sector.title:ja:Indonesia': '民間部門向け融資',
  'loans_to_private_sector.title:ja:Greece': '民間部門向け融資',
  'pesach_vii_last_day_of_passover.title:ja:DEFAULT': 'ペサハ7日目（過越祭最終日）',
  'pesach_vii_last_day_of_passover.title:ja:Israel': 'ペサハ7日目（過越祭最終日）',
  'fed_waller_speech.title:ja:DEFAULT': 'FRBウォラー理事講演',
  'fed_waller_speech.title:ja:United States': 'FRBウォラー理事講演',
  'riksbank_meeting_minutes.title:ja:DEFAULT':
    'リクスバンク（スウェーデン中銀）金融政策決定会合議事録',
  'riksbank_meeting_minutes.title:ja:Sweden':
    'リクスバンク（スウェーデン中銀）金融政策決定会合議事録',
  'presidential_elections.title:ja:DEFAULT': '大統領選挙',
  'presidential_elections.title:ja:Indonesia': '大統領選挙',
  'presidential_elections.title:ja:Colombia': '大統領選挙',
  'presidential_elections.title:ja:South Korea': '大統領選挙',
  'presidential_elections.title:ja:France': '大統領選挙',
  'presidential_elections.title:ja:Germany': '大統領選挙',
  'presidential_elections.title:ja:Taiwan': '大統領選挙',
  'presidential_elections.title:ja:Austria': '大統領選挙',
  'presidential_elections.title:ja:United States': '大統領選挙',
  'presidential_elections.title:ja:Philippines': '大統領選挙',
  'second_day_of_chinese_lunar_new_year.title:ja:DEFAULT': '旧正月2日目',
  'second_day_of_chinese_lunar_new_year.title:ja:Hong Kong': '旧正月2日目',
  'second_day_of_chinese_lunar_new_year.title:ja:Singapore': '旧正月2日目',
  'richmond_fed_manufacturing_index.title:ja:DEFAULT': 'リッチモンド連銀製造業指数',
  'richmond_fed_manufacturing_index.title:ja:United States': 'リッチモンド連銀製造業指数',
  'reverse_repo_rate.title:ja:DEFAULT': 'リバースレポ金利',
  'reverse_repo_rate.title:ja:India': 'リバースレポ金利',
  'raw_materials_prices_mom.title:ja:DEFAULT': '原材料価格指数（前月比）',
  'raw_materials_prices_mom.title:ja:Canada': '原材料価格指数（前月比）',
  'reuters_michigan_inflation_expectations_prel.title:ja:DEFAULT':
    'ロイター・ミシガン大学期待インフレ率（速報値）',
  'reuters_michigan_inflation_expectations_prel.title:ja:United States':
    'ロイター・ミシガン大学期待インフレ率（速報値）',
  'ppi_mom_final.title:ja:DEFAULT': '生産者物価指数（PPI）（前月比）	（確報値）',
  'ppi_mom_final.title:ja:Canada': '生産者物価指数（PPI）（前月比）	（確報値）',
  'nab_quarterly_business_confidence.title:ja:DEFAULT':
    'ナショナルオーストラリア銀行（NAB）企業信頼感指数',
  'nab_quarterly_business_confidence.title:ja:Australia':
    'ナショナルオーストラリア銀行（NAB）企業信頼感指数',
  'referendum_on_sexual_orientation_discrimination.title:ja:DEFAULT':
    '性的指向に関する差別の是非をめぐる国民投票',
  'referendum_on_sexual_orientation_discrimination.title:ja:Switzerland':
    '性的指向に関する差別の是非をめぐる国民投票',
  'core_inflation_rate.title:ja:DEFAULT': 'コアインフレ率',
  'core_inflation_rate.title:ja:Ireland': 'コアインフレ率',
  'reuters_michigan_consumer_sentiment_final.title:ja:DEFAULT':
    'ロイター・ミシガン大学消費者信頼感指数（確報値）',
  'reuters_michigan_consumer_sentiment_final.title:ja:United States':
    'ロイター・ミシガン大学消費者信頼感指数（確報値）',
  'private_investment_mom.title:ja:DEFAULT': '民間投資（前月比）',
  'private_investment_mom.title:ja:Thailand': '民間投資（前月比）',
  'president_biden_speech_on_recovery_package.title:ja:DEFAULT':
    '救済計画に関するバイデン米大統領演説',
  'president_biden_speech_on_recovery_package.title:ja:United States':
    '救済計画に関するバイデン米大統領演説',
  'seco_economic_forecasts.title:ja:DEFAULT': 'スイス経済省経済事務局（SECO）経済予測',
  'seco_economic_forecasts.title:ja:Switzerland': 'スイス経済省経済事務局（SECO）経済予測',
  'inflation_rate.title:ja:DEFAULT': 'インフレ率',
  'inflation_rate.title:ja:Indonesia': 'インフレ率',
  'inflation_rate.title:ja:Germany': 'インフレ率',
  'inflation_rate.title:ja:South Africa': 'インフレ率',
  'inflation_rate.title:ja:Austria': 'インフレ率',
  'inflation_rate.title:ja:Ireland': 'インフレ率',
  'inflation_rate.title:ja:Japan': 'インフレ率',
  'inflation_rate.title:ja:Singapore': 'インフレ率',
  'inflation_rate.title:ja:Spain': 'インフレ率',
  'ivey_pmi_s_a.title:ja:DEFAULT': 'アイビー（Ivey）購買担当者景気指数（PMI）季節調整済',
  'ivey_pmi_s_a.title:ja:Canada': 'アイビー（Ivey）購買担当者景気指数（PMI）季節調整済',
  'api_crude_oil_stock_change.title:ja:DEFAULT': '米石油協会（API）原油在庫変化',
  'api_crude_oil_stock_change.title:ja:United States': '米石油協会（API）原油在庫変化',
  'fed_s_beige_book.title:ja:DEFAULT': '地区連銀経済報告（ベージュブック）',
  'fed_s_beige_book.title:ja:United States': '地区連銀経済報告（ベージュブック）',
  'republic_day.title:ja:DEFAULT': '共和国記念日',
  'republic_day.title:ja:Portugal': '共和国記念日',
  'republic_day.title:ja:India': '共和国記念日',
  'republic_day.title:ja:Brazil': '共和国記念日',
  'republic_day.title:ja:Italy': '共和国記念日',
  'markit_cips_manufacturing_pmi_final.title:ja:DEFAULT':
    'マークイット/CIPS製造業購買担当者景気指数（PMI）確報値',
  'markit_cips_manufacturing_pmi_final.title:ja:United Kingdom':
    'マークイット/CIPS製造業購買担当者景気指数（PMI）確報値',
  'parliament_debate_on_brexit_alternatives.title:ja:DEFAULT': '英国のEU離脱代替案に関する議会審議',
  'parliament_debate_on_brexit_alternatives.title:ja:United Kingdom':
    '英国のEU離脱代替案に関する議会審議',
  'saint_stephen_s_day.title:ja:DEFAULT': '聖ステファノの日',
  'saint_stephen_s_day.title:ja:Switzerland': '聖ステファノの日',
  'saint_stephen_s_day.title:ja:Netherlands': '聖ステファノの日',
  'saint_stephen_s_day.title:ja:Czech Republic': '聖ステファノの日',
  'saint_stephen_s_day.title:ja:Austria': '聖ステファノの日',
  'saint_stephen_s_day.title:ja:Ireland': '聖ステファノの日',
  'saint_stephen_s_day.title:ja:Finland': '聖ステファノの日',
  'retail_sales_ex_fuel_yoy.title:ja:DEFAULT': '小売売上高（燃料を除く）（前年比）',
  'retail_sales_ex_fuel_yoy.title:ja:United Kingdom': '小売売上高（燃料を除く）（前年比）',
  'ppi_core_output_mom.title:ja:DEFAULT': '生産者物価指数（PPI）コア産出物価（前月比）',
  'ppi_core_output_mom.title:ja:United Kingdom': '生産者物価指数（PPI）コア産出物価（前月比）',
  'referendum_on_legalising_euthanasia.title:ja:DEFAULT': '安楽死の合法化に関する国民投票',
  'referendum_on_legalising_euthanasia.title:ja:New Zealand': '安楽死の合法化に関する国民投票',
  'westpac_consumer_survey.title:ja:DEFAULT': 'ウエストパック消費者信頼感調査',
  'westpac_consumer_survey.title:ja:New Zealand': 'ウエストパック消費者信頼感調査',
  'hsbc_china_services_pmi.title:ja:DEFAULT': 'HSBC中国サービス業購買担当者景気指数（PMI）',
  'hsbc_china_services_pmi.title:ja:China': 'HSBC中国サービス業購買担当者景気指数（PMI）',
  'presidential_election_round_2.title:ja:DEFAULT': '大統領選挙第2回投票',
  'presidential_election_round_2.title:ja:Czech Republic': '大統領選挙第2回投票',
  'australia_day_observed.title:ja:DEFAULT': 'オーストラリアの日（祝日）',
  'australia_day_observed.title:ja:Australia': 'オーストラリアの日（祝日）',
  'retail_sales_yoy.title:ja:DEFAULT': '小売売上高（前年比）',
  'retail_sales_yoy.title:ja:Finland': '小売売上高（前年比）',
  'retail_sales_yoy.title:ja:Australia': '小売売上高（前年比）',
  'retail_sales_yoy.title:ja:Thailand': '小売売上高（前年比）',
  'retail_sales_yoy.title:ja:Taiwan': '小売売上高（前年比）',
  'retail_sales_yoy.title:ja:New Zealand': '小売売上高（前年比）',
  'retail_sales_yoy.title:ja:Switzerland': '小売売上高（前年比）',
  'retail_sales_yoy.title:ja:Poland': '小売売上高（前年比）',
  'retail_sales_yoy.title:ja:Germany': '小売売上高（前年比）',
  'retail_sales_yoy.title:ja:South Korea': '小売売上高（前年比）',
  'retail_sales_yoy.title:ja:Hong Kong': '小売売上高（前年比）',
  'retail_sales_yoy.title:ja:Greece': '小売売上高（前年比）',
  'retail_sales_yoy.title:ja:Netherlands': '小売売上高（前年比）',
  'retail_sales_yoy.title:ja:Italy': '小売売上高（前年比）',
  'retail_sales_yoy.title:ja:Canada': '小売売上高（前年比）',
  'retail_sales_yoy.title:ja:Indonesia': '小売売上高（前年比）',
  'retail_sales_yoy.title:ja:United Kingdom': '小売売上高（前年比）',
  'retail_sales_yoy.title:ja:Ireland': '小売売上高（前年比）',
  'retail_sales_yoy.title:ja:United States': '小売売上高（前年比）',
  'retail_sales_yoy.title:ja:Mexico': '小売売上高（前年比）',
  'retail_sales_yoy.title:ja:Norway': '小売売上高（前年比）',
  'retail_sales_yoy.title:ja:South Africa': '小売売上高（前年比）',
  'retail_sales_yoy.title:ja:France': '小売売上高（前年比）',
  'retail_sales_yoy.title:ja:Belgium': '小売売上高（前年比）',
  'retail_sales_yoy.title:ja:Colombia': '小売売上高（前年比）',
  'retail_sales_yoy.title:ja:Euro Area': '小売売上高（前年比）',
  'retail_sales_yoy.title:ja:Hungary': '小売売上高（前年比）',
  'retail_sales_yoy.title:ja:Sweden': '小売売上高（前年比）',
  'retail_sales_yoy.title:ja:Austria': '小売売上高（前年比）',
  'retail_sales_yoy.title:ja:Portugal': '小売売上高（前年比）',
  'retail_sales_yoy.title:ja:China': '小売売上高（前年比）',
  'retail_sales_yoy.title:ja:Japan': '小売売上高（前年比）',
  'retail_sales_yoy.title:ja:Singapore': '小売売上高（前年比）',
  'retail_sales_yoy.title:ja:Denmark': '小売売上高（前年比）',
  'retail_sales_yoy.title:ja:Russia': '小売売上高（前年比）',
  'retail_sales_yoy.title:ja:Spain': '小売売上高（前年比）',
  'retail_sales_yoy.title:ja:Czech Republic': '小売売上高（前年比）',
  'retail_sales_yoy.title:ja:Brazil': '小売売上高（前年比）',
  'retail_sales_mom.title:ja:DEFAULT': '小売売上高（前月比）',
  'retail_sales_mom.title:ja:Indonesia': '小売売上高（前月比）',
  'retail_sales_mom.title:ja:Canada': '小売売上高（前月比）',
  'retail_sales_mom.title:ja:Switzerland': '小売売上高（前月比）',
  'retail_sales_mom.title:ja:Colombia': '小売売上高（前月比）',
  'retail_sales_mom.title:ja:Euro Area': '小売売上高（前月比）',
  'retail_sales_mom.title:ja:Mexico': '小売売上高（前月比）',
  'retail_sales_mom.title:ja:Norway': '小売売上高（前月比）',
  'retail_sales_mom.title:ja:Finland': '小売売上高（前月比）',
  'retail_sales_mom.title:ja:South Korea': '小売売上高（前月比）',
  'retail_sales_mom.title:ja:Sweden': '小売売上高（前月比）',
  'retail_sales_mom.title:ja:Austria': '小売売上高（前月比）',
  'retail_sales_mom.title:ja:Germany': '小売売上高（前月比）',
  'retail_sales_mom.title:ja:Poland': '小売売上高（前月比）',
  'retail_sales_mom.title:ja:United Kingdom': '小売売上高（前月比）',
  'retail_sales_mom.title:ja:Ireland': '小売売上高（前月比）',
  'retail_sales_mom.title:ja:United States': '小売売上高（前月比）',
  'retail_sales_mom.title:ja:South Africa': '小売売上高（前月比）',
  'retail_sales_mom.title:ja:Belgium': '小売売上高（前月比）',
  'retail_sales_mom.title:ja:France': '小売売上高（前月比）',
  'retail_sales_mom.title:ja:Greece': '小売売上高（前月比）',
  'retail_sales_mom.title:ja:Hungary': '小売売上高（前月比）',
  'retail_sales_mom.title:ja:Italy': '小売売上高（前月比）',
  'retail_sales_mom.title:ja:Netherlands': '小売売上高（前月比）',
  'retail_sales_mom.title:ja:Australia': '小売売上高（前月比）',
  'retail_sales_mom.title:ja:Japan': '小売売上高（前月比）',
  'retail_sales_mom.title:ja:Singapore': '小売売上高（前月比）',
  'retail_sales_mom.title:ja:Portugal': '小売売上高（前月比）',
  'retail_sales_mom.title:ja:Russia': '小売売上高（前月比）',
  'retail_sales_mom.title:ja:Denmark': '小売売上高（前月比）',
  'retail_sales_mom.title:ja:Spain': '小売売上高（前月比）',
  'retail_sales_mom.title:ja:Czech Republic': '小売売上高（前月比）',
  'retail_sales_mom.title:ja:Brazil': '小売売上高（前月比）',
  'part_time_employment_chg.title:ja:DEFAULT': 'パートタイム労働者数変化',
  'part_time_employment_chg.title:ja:Canada': 'パートタイム労働者数変化',
  'fed_monetary_policy_statement_and_press_conference.title:ja:DEFAULT':
    'FRBの金融政策に関する声明と記者会見',
  'fed_monetary_policy_statement_and_press_conference.title:ja:United States':
    'FRBの金融政策に関する声明と記者会見',
  'respect_for_the_aged_day.title:ja:DEFAULT': '敬老の日',
  'respect_for_the_aged_day.title:ja:Japan': '敬老の日',
  'foreign_exchange_reserves.title:ja:DEFAULT': '外貨準備',
  'foreign_exchange_reserves.title:ja:Switzerland': '外貨準備',
  'foreign_exchange_reserves.title:ja:China': '外貨準備',
  'foreign_exchange_reserves.title:ja:Russia': '外貨準備',
  'foreign_exchange_reserves.title:ja:Singapore': '外貨準備',
  'foreign_exchange_reserves.title:ja:Hong Kong': '外貨準備',
  'foreign_exchange_reserves.title:ja:Taiwan': '外貨準備',
  'foreign_exchange_reserves.title:ja:South Korea': '外貨準備',
  'foreign_exchange_reserves.title:ja:Indonesia': '外貨準備',
  'foreign_exchange_reserves.title:ja:Czech Republic': '外貨準備',
  'foreign_exchange_reserves.title:ja:Thailand': '外貨準備',
  'foreign_exchange_reserves.title:ja:Denmark': '外貨準備',
  'foreign_exchange_reserves.title:ja:Poland': '外貨準備',
  'foreign_exchange_reserves.title:ja:India': '外貨準備',
  'foreign_exchange_reserves.title:ja:South Africa': '外貨準備',
  'foreign_exchange_reserves.title:ja:Israel': '外貨準備',
  'foreign_exchange_reserves.title:ja:Mexico': '外貨準備',
  'foreign_exchange_reserves.title:ja:Japan': '外貨準備',
  'foreign_exchange_reserves.title:ja:Philippines': '外貨準備',
  'consumer_confidence_households.title:ja:DEFAULT': '消費動向調査家計状況',
  'consumer_confidence_households.title:ja:Japan': '消費動向調査家計状況',
  'snb_chair_jordan_speaks.title:ja:DEFAULT': 'スイス国立銀行（SNB）ジョルダン総裁講演',
  'snb_chair_jordan_speaks.title:ja:Switzerland': 'スイス国立銀行（SNB）ジョルダン総裁講演',
  'retail_sales_ex_gas_autos_mom.title:ja:DEFAULT':
    '小売売上高（ガソリン・自動車を除く）（前月比）',
  'retail_sales_ex_gas_autos_mom.title:ja:United States':
    '小売売上高（ガソリン・自動車を除く）（前月比）',
  'retail_sales_ex_autos_mom.title:ja:DEFAULT': '小売売上高（自動車を除く）（前月比）',
  'retail_sales_ex_autos_mom.title:ja:Canada': '小売売上高（自動車を除く）（前月比）',
  'retail_sales_ex_autos_mom.title:ja:United States': '小売売上高（自動車を除く）（前月比）',
  'the_passing_of_king_bhumibol.title:ja:DEFAULT': 'ラマ9世（故プミポン国王）記念日',
  'the_passing_of_king_bhumibol.title:ja:Thailand': 'ラマ9世（故プミポン国王）記念日',
  'makha_bucha_day_substitute.title:ja:DEFAULT': '万仏祭（振替休日）',
  'makha_bucha_day_substitute.title:ja:Thailand': '万仏祭（振替休日）',
  'fed_harker_speech.title:ja:DEFAULT': 'フィラデルフィア連銀ハーカー総裁講演',
  'fed_harker_speech.title:ja:United States': 'フィラデルフィア連銀ハーカー総裁講演',
  'retail_pmi.title:ja:DEFAULT': '小売業購買担当者景気指数（PMI）',
  'retail_pmi.title:ja:Euro Area': '小売業購買担当者景気指数（PMI）',
  'residential_property_prices_yoy.title:ja:DEFAULT': '住宅用不動産価格（前年比）',
  'residential_property_prices_yoy.title:ja:Ireland': '住宅用不動産価格（前年比）',
  'culture_day.title:ja:DEFAULT': '文化の日',
  'culture_day.title:ja:Japan': '文化の日',
  'president_obama_statement_on_the_economy.title:ja:DEFAULT': '経済に関するオバマ大統領の声明',
  'president_obama_statement_on_the_economy.title:ja:United States':
    '経済に関するオバマ大統領の声明',
  'investec_services_pmi.title:ja:DEFAULT': 'インベステック・サービスの購買担当者景気指数 (PMI)',
  'investec_services_pmi.title:ja:Ireland': 'インベステック・サービスの購買担当者景気指数 (PMI)',
  'industrial_sentiment.title:ja:DEFAULT': '産業界の景況感',
  'industrial_sentiment.title:ja:Euro Area': '産業界の景況感',
  'non-oil_exports_yoy.title:ja:DEFAULT': '非石油輸出額(前年比)',
  'non-oil_exports_yoy.title:ja:Singapore': '非石油輸出額(前年比)',
  'markit_cips_manufacturing_pmi.title:ja:DEFAULT':
    'マークイット/ CIPS 工業購買担当者景気指数 (PMI)',
  'markit_cips_manufacturing_pmi.title:ja:United Kingdom':
    'マークイット/ CIPS 工業購買担当者景気指数 (PMI)',
  'easter_eve.title:ja:DEFAULT': 'イースター(復活祭)前夜',
  'easter_eve.title:ja:Sweden': 'イースター(復活祭)前夜',
  'new_year_s_day_substitute_day.title:ja:Spain': '元旦(振替休日)',
  'new_year_s_day_substitute_day.title:ja:Canada': '元旦(振替休日)',
  'new_year_s_day_substitute_day.title:ja:United States': '元旦(振替休日)',
  'new_year_s_day_substitute_day.title:ja:Australia': '元旦(振替休日)',
  'ram_navami.title:ja:DEFAULT': 'ラーム・ナヴミ（ヒンズー教ラーム神誕生日）',
  'ram_navami.title:ja:India': 'ラーム・ナヴミ（ヒンズー教ラーム神誕生日）',
  'aib_manufacturing_pmi.title:ja:DEFAULT': 'AIB 製造業購買担当者景気指数 (PMI)',
  'aib_manufacturing_pmi.title:ja:Ireland': 'AIB 製造業購買担当者景気指数 (PMI)',
  'boj_iwata_speech.title:ja:DEFAULT': '日銀の岩田氏講演',
  'boj_iwata_speech.title:ja:Japan': '日銀の岩田氏講演',
  'halifax_house_price_index_mom.title:ja:DEFAULT': 'ハリファックス住宅価格指数(前月比)',
  'halifax_house_price_index_mom.title:ja:United Kingdom': 'ハリファックス住宅価格指数(前月比)',
  'food_price_index_mom.title:ja:DEFAULT': '食品価格指数(前月比)',
  'food_price_index_mom.title:ja:New Zealand': '食品価格指数(前月比)',
  'rba_gov_debelle_speech.title:ja:DEFAULT': 'RBAのデベル総裁講演',
  'rba_gov_debelle_speech.title:ja:Australia': 'RBAのデベル総裁講演',
  'labour_day.title:ja:DEFAULT': '労働者の日',
  'labour_day.title:ja:South Korea': '労働者の日',
  'labour_day.title:ja:Australia': '労働者の日',
  'labour_day.title:ja:Hong Kong': '労働者の日',
  'labour_day.title:ja:Thailand': '労働者の日',
  'labour_day.title:ja:Taiwan': '労働者の日',
  'labour_day.title:ja:Singapore': '労働者の日',
  'labour_day.title:ja:Philippines': '労働者の日',
  'labour_day.title:ja:Colombia': '労働者の日',
  'labour_day.title:ja:Brazil': '労働者の日',
  'labour_day.title:ja:Indonesia': '労働者の日',
  'labour_day.title:ja:Canada': '労働者の日',
  'labour_day.title:ja:Greece': '労働者の日',
  'labour_day.title:ja:Sweden': '労働者の日',
  'labour_day.title:ja:Austria': '労働者の日',
  'labour_day.title:ja:Germany': '労働者の日',
  'labour_day.title:ja:Poland': '労働者の日',
  'labour_day.title:ja:South Africa': '労働者の日',
  'labour_day.title:ja:France': '労働者の日',
  'labour_day.title:ja:Belgium': '労働者の日',
  'labour_day.title:ja:Mexico': '労働者の日',
  'labour_day.title:ja:Norway': '労働者の日',
  'labour_day.title:ja:Hungary': '労働者の日',
  'labour_day.title:ja:Italy': '労働者の日',
  'labour_day.title:ja:China': '労働者の日',
  'labour_day.title:ja:Portugal': '労働者の日',
  'labour_day.title:ja:Switzerland': '労働者の日',
  'labour_day.title:ja:New Zealand': '労働者の日',
  'labour_day.title:ja:Russia': '労働者の日',
  'labour_day.title:ja:Spain': '労働者の日',
  'labour_day.title:ja:Czech Republic': '労働者の日',
  'procure_ch_manufacturing_pmi.title:ja:DEFAULT': 'procure.ch 製造業購買担当者景気指数 (PMI)',
  'procure_ch_manufacturing_pmi.title:ja:Switzerland': 'procure.ch 製造業購買担当者景気指数 (PMI)',
  'sacred_heart.title:ja:DEFAULT': '聖なる心の祝日',
  'sacred_heart.title:ja:Colombia': '聖なる心の祝日',
  'residential_property_prices_mom.title:ja:DEFAULT': '住宅不動産価格(前月比)',
  'residential_property_prices_mom.title:ja:Ireland': '住宅不動産価格(前月比)',
  'repentance_day.title:ja:DEFAULT': '懺悔と祈りの日',
  'repentance_day.title:ja:Germany': '懺悔と祈りの日',
  'isra_and_mi_raj_prophet_s_ascension.title:ja:DEFAULT': 'イスラ・ワル・ミラージュ(預言者昇天祭)',
  'isra_and_mi_raj_prophet_s_ascension.title:ja:Indonesia':
    'イスラ・ワル・ミラージュ(預言者昇天祭)',
  'christmas.title:ja:DEFAULT': 'クリスマス',
  'christmas.title:ja:Switzerland': 'クリスマス',
  'christmas.title:ja:Sweden': 'クリスマス',
  'christmas.title:ja:Portugal': 'クリスマス',
  'christmas.title:ja:Singapore': 'クリスマス',
  'christmas.title:ja:Netherlands': 'クリスマス',
  'christmas.title:ja:Mexico': 'クリスマス',
  'christmas.title:ja:Ireland': 'クリスマス',
  'christmas.title:ja:Philippines': 'クリスマス',
  'christmas.title:ja:Finland': 'クリスマス',
  'christmas.title:ja:Brazil': 'クリスマス',
  'christmas.title:ja:Colombia': 'クリスマス',
  'christmas.title:ja:Indonesia': 'クリスマス',
  'christmas.title:ja:Norway': 'クリスマス',
  'christmas.title:ja:Belgium': 'クリスマス',
  'christmas.title:ja:South Africa': 'クリスマス',
  'christmas.title:ja:Poland': 'クリスマス',
  'christmas.title:ja:Austria': 'クリスマス',
  'christmas.title:ja:Czech Republic': 'クリスマス',
  'christmas.title:ja:Denmark': 'クリスマス',
  'christmas.title:ja:Greece': 'クリスマス',
  'christmas.title:ja:Hungary': 'クリスマス',
  'rejoicing_of_the_law_eve.title:ja:DEFAULT': '律法の祝典前夜（シムハット・トーラー）',
  'rejoicing_of_the_law_eve.title:ja:Israel': '律法の祝典前夜（シムハット・トーラー）',
  'regional_elections_-_2nd_round.title:ja:DEFAULT': '地域選挙 - 第2回投票',
  'regional_elections_-_2nd_round.title:ja:France': '地域選挙 - 第2回投票',
  'reformation_day.title:ja:DEFAULT': '宗教改革記念日',
  'reformation_day.title:ja:Germany': '宗教改革記念日',
  'hsbc_manufacturing_pmi_prel.title:ja:DEFAULT': 'HSBC 製造業購買担当者景気指数 (PMI) 速報',
  'hsbc_manufacturing_pmi_prel.title:ja:China': 'HSBC製造業購買担当者景気指数 (PMI) （速報値）',
  'president_biden_formal_press_conference.title:ja:DEFAULT': 'バイデン大統領公式記者会見',
  'president_biden_formal_press_conference.title:ja:United States': 'バイデン大統領公式記者会見',
  'referendum_on_legalising_cannabis.title:ja:DEFAULT': '大麻の合法化に関する国民投票',
  'referendum_on_legalising_cannabis.title:ja:New Zealand': '大麻の合法化に関する国民投票',
  'referendum.title:ja:DEFAULT': '国民投票',
  'referendum.title:ja:Hungary': '国民投票',
  'referendum.title:ja:Colombia': '国民投票',
  'redbook_yoy.title:ja:DEFAULT': 'レッドブック (前年比)',
  'redbook_yoy.title:ja:United States': 'レッドブック (前年比)',
  'raw_materials_prices_yoy.title:ja:DEFAULT': '原材料価格指数(前年比）',
  'raw_materials_prices_yoy.title:ja:Canada': '原材料価格指数(前年比）',
  'private_sector_loans.title:ja:DEFAULT': '民間セクターローン',
  'private_sector_loans.title:ja:Ireland': '民間セクターローン',
  'raw_material_price_index.title:ja:DEFAULT': '原材料価格指数',
  'raw_material_price_index.title:ja:Canada': '原材料価格指数',
  'gdp_external_demand_qoq.title:ja:DEFAULT': 'GDP外需(前四半期比)',
  'gdp_external_demand_qoq.title:ja:Japan': 'GDP外需(前四半期比)',
  'nahb_housing_market_index.title:ja:DEFAULT': 'NAHB住宅市場指数',
  'nahb_housing_market_index.title:ja:United States': 'NAHB住宅市場指数',
  'feast_of_sacrifice.title:ja:DEFAULT': 'イードアルアドハー',
  'feast_of_sacrifice.title:ja:India': 'イードアルアドハー',
  'whitsun.title:ja:DEFAULT': '聖霊降臨祭 (Whitsun)',
  'whitsun.title:ja:Sweden': '聖霊降臨祭 (Whitsun)',
  'municipal_election_first_round.title:ja:DEFAULT': '地方選挙(第1回投票)',
  'municipal_election_first_round.title:ja:Brazil': '地方選挙(第1回投票)',
  'muharram_islamic_new_year.title:ja:DEFAULT': 'ムハッラム/イスラムの新年',
  'muharram_islamic_new_year.title:ja:Indonesia': 'ムハッラム/イスラムの新年',
  'revised_2016_budget.title:ja:DEFAULT': '2016年度修正予算',
  'revised_2016_budget.title:ja:Norway': '2016年度修正予算',
  'midsummer_s_eve.title:ja:DEFAULT': '夏至の前夜',
  'midsummer_s_eve.title:ja:Finland': '夏至の前夜',
  'constitution_day_substitute.title:ja:DEFAULT': '憲法記念日(振替休日)',
  'constitution_day_substitute.title:ja:Thailand': '憲法記念日(振替休日)',
  'the_independence_day_of_korea.title:ja:DEFAULT': '韓国独立記念日',
  'the_independence_day_of_korea.title:ja:South Korea': '韓国独立記念日',
  'muharram.title:ja:DEFAULT': 'ムハッラム祭',
  'muharram.title:ja:India': 'ムハッラム祭',
  'mountain_day.title:ja:DEFAULT': '山の日',
  'mountain_day.title:ja:Japan': '山の日',
  'foreign_direct_investment.title:ja:DEFAULT': '海外直接投資',
  'foreign_direct_investment.title:ja:Indonesia': '海外直接投資',
  'foreign_direct_investment.title:ja:Philippines': '海外直接投資',
  'foreign_direct_investment.title:ja:Brazil': '海外直接投資',
  'reuters_michigan_consumer_sentiment_index.title:ja:DEFAULT':
    'ロイター・ミシガン大学消費者信頼感指数',
  'reuters_michigan_consumer_sentiment_index.title:ja:United States':
    'ロイター・ミシガン大学消費者信頼感指数',
  'motorbike_sales_yoy.title:ja:DEFAULT': '二輪車販売台数(前年比)',
  'motorbike_sales_yoy.title:ja:Indonesia': '二輪車販売台数(前年比)',
  'monthly_gdp_3-month_avg.title:ja:DEFAULT': '月次GDP 3カ月平均',
  'monthly_gdp_3-month_avg.title:ja:United Kingdom': '月次GDP 3カ月平均',
  'midsummer_eve.title:ja:DEFAULT': '夏至の前夜',
  'midsummer_eve.title:ja:Sweden': '夏至の前夜',
  'midsummer_eve.title:ja:Finland': '夏至の前夜',
  'monthly_gdp.title:ja:DEFAULT': '月間GDP',
  'monthly_gdp.title:ja:United Kingdom': '月間GDP',
  'money_supply_m1_yoy.title:ja:DEFAULT': 'マネーサプライM1 (前年比）',
  'money_supply_m1_yoy.title:ja:Israel': 'マネーサプライM1 (前年比）',
  'consumer_confidence_westpac.title:ja:DEFAULT': 'ウェストパック消費者信頼感指数',
  'consumer_confidence_westpac.title:ja:New Zealand': 'ウェストパック消費者信頼感指数',
  'money_supply_m1.title:ja:DEFAULT': 'マネーサプライM1',
  'money_supply_m1.title:ja:Israel': 'マネーサプライM1',
  'manufacturing_sales_mom.title:ja:DEFAULT': '製造業売上高(前月比)',
  'manufacturing_sales_mom.title:ja:Canada': '製造業売上高(前月比)',
  'monetary_policy_report.title:ja:DEFAULT': '金融政策報告',
  'monetary_policy_report.title:ja:Sweden': '金融政策報告',
  'monetary_policy_report.title:ja:Russia': '金融政策報告',
  'monetary_policy_report.title:ja:United Kingdom': '金融政策報告',
  'monetary_policy_report.title:ja:United States': '金融政策報告',
  'monetary_policy_meeting_minutes.title:ja:DEFAULT': '金融政策会議議事録',
  'monetary_policy_meeting_minutes.title:ja:South Korea': '金融政策会議議事録',
  'monetary_policy_meeting_minutes.title:ja:Mexico': '金融政策会議議事録',
  'monetary_policy_meeting_minutes.title:ja:Sweden': '金融政策会議議事録',
  'monetary_policy_meeting_minutes.title:ja:India': '金融政策会議議事録',
  'monetary_policy_meeting_minutes.title:ja:Poland': '金融政策会議議事録',
  'eu-turkey_migration_summit.title:ja:DEFAULT': 'EUとトルコの移民に関するサミット',
  'eu-turkey_migration_summit.title:ja:Euro Area': 'EUとトルコの移民に関するサミット',
  'rba_kent_speech.title:ja:DEFAULT': 'RBAのケント氏講演',
  'rba_kent_speech.title:ja:Australia': 'RBAのケント氏講演',
  'moharram.title:ja:DEFAULT': 'ムハッラム（イスラム教)',
  'moharram.title:ja:India': 'ムハッラム（イスラム教)',
  'mining_production_yoy.title:ja:DEFAULT': '鉱業生産(前年比）',
  'mining_production_yoy.title:ja:South Africa': '鉱業生産(前年比）',
  'fed_powell_speech.title:ja:DEFAULT': 'FRBパウエル議長演説',
  'fed_powell_speech.title:ja:United States': 'FRBパウエル議長演説',
  'mid-month_inflation_rate_mom.title:ja:DEFAULT': '月央インフレ率(前月比）',
  'mid-month_inflation_rate_mom.title:ja:Mexico': '月央インフレ率(前月比）',
  'mid-month_core_inflation_rate_mom.title:ja:DEFAULT': '月央コア・インフレ率(前月比）',
  'mid-month_core_inflation_rate_mom.title:ja:Mexico': '月央コア・インフレ率(前月比）',
  'mid-autumn_festival.title:ja:DEFAULT': '中秋節',
  'mid-autumn_festival.title:ja:Taiwan': '中秋節',
  'mid-year_economic_and_fiscal_outlook.title:ja:DEFAULT': '年央経済財政見通し',
  'mid-year_economic_and_fiscal_outlook.title:ja:Australia': '年央経済財政見通し',
  'mid-term_elections.title:ja:DEFAULT': '中間選挙',
  'mid-term_elections.title:ja:United States': '中間選挙',
  'fed_yellen_speaks_at_jackson_hole.title:ja:DEFAULT': 'FRBイエレン議長ジャクソンホール講演',
  'fed_yellen_speaks_at_jackson_hole.title:ja:United States': 'FRBイエレン議長ジャクソンホール講演',
  '7-year_note_auction.title:ja:DEFAULT': '7年国債入札',
  '7-year_note_auction.title:ja:United States': '7年国債入札',
  'rejoicing_of_the_law_simchat_torah.title:ja:DEFAULT': '律法の祝典(シムハット・トーラー）',
  'rejoicing_of_the_law_simchat_torah.title:ja:Israel': '律法の祝典(シムハット・トーラー）',
  'mid-autumn_holiday.title:ja:DEFAULT': '中秋の祝日',
  'mid-autumn_holiday.title:ja:China': '中秋の祝日',
  'harmonised_inflation_rate_mom_final.title:ja:DEFAULT': '調和インフレ率(前月比)確報値',
  'harmonised_inflation_rate_mom_final.title:ja:Italy': '調和インフレ率(前月比)確報値',
  'harmonised_inflation_rate_mom_final.title:ja:France': '調和インフレ率(前月比)確報値',
  'harmonised_inflation_rate_mom_final.title:ja:Spain': '調和インフレ率(前月比)確報値',
  'harmonised_inflation_rate_mom_final.title:ja:Germany': '調和インフレ率(前月比)確報値',
  'mid-autumn_festival.title:ja:South Korea': '',
  'mid-autumn_festival.title:ja:China': '中秋節',
  'michigan_inflation_expectations_final.title:ja:DEFAULT': 'ミシガン大学インフレ期待指数(確報値)',
  'michigan_inflation_expectations_final.title:ja:United States':
    'ミシガン大学インフレ期待指数(確報値)',
  'fiscal_year_gdp_growth_final.title:ja:DEFAULT': '会計年度GDP成長率(確報値)',
  'fiscal_year_gdp_growth_final.title:ja:India': '会計年度GDP成長率(確報値)',
  'michigan_consumer_sentiment_prel.title:ja:DEFAULT': 'ミシガン大学消費者信頼感指数速報値',
  'michigan_consumer_sentiment_prel.title:ja:United States': 'ミシガン大学消費者信頼感指数速報値',
  'wasde_report.title:ja:DEFAULT': '世界農業需給予測(WASDE)報告',
  'wasde_report.title:ja:United States': '世界農業需給予測(WASDE)報告',
  'gdp_deflator_yoy_prel.title:ja:DEFAULT': 'GDPデフレーター(前年比)速報値',
  'gdp_deflator_yoy_prel.title:ja:Japan': 'GDPデフレーター(前年比)速報値',
  'finance_minister_aso_speech.title:ja:DEFAULT': '麻生財務大臣講演',
  'finance_minister_aso_speech.title:ja:Japan': '麻生財務大臣講演',
  'michigan_consumer_sentiment_final.title:ja:DEFAULT':
    'ロイター・ミシガン大学消費者信頼感指数(確報値)',
  'michigan_consumer_sentiment_final.title:ja:United States':
    'ロイター・ミシガン大学消費者信頼感指数(確報値)',
  'michigan_consumer_expectations_final.title:ja:DEFAULT': 'ミシガン大学消費者期待指数(確報値)',
  'michigan_consumer_expectations_final.title:ja:United States':
    'ミシガン大学消費者期待指数(確報値)',
  'michigan_5_year_inflation_expectations_prel.title:ja:DEFAULT':
    'ミシガン大学5年間インフレ期待指数（速報値）',
  'michigan_5_year_inflation_expectations_prel.title:ja:United States':
    'ミシガン大学5年間インフレ期待指数（速報値）',
  'michigan_5_year_inflation_expectations_final.title:ja:DEFAULT':
    'ミシガン大学5年間インフレ期待指数(確報値)',
  'michigan_5_year_inflation_expectations_final.title:ja:United States':
    'ミシガン大学5年間インフレ期待指数(確報値)',
  'household_consumption_yoy.title:ja:DEFAULT': '家計消費(前年比）',
  'household_consumption_yoy.title:ja:Netherlands': '家計消費(前年比）',
  'memorial_day_holiday.title:ja:DEFAULT': 'メモリアルデー休日',
  'memorial_day_holiday.title:ja:United States': 'メモリアルデー休日',
  'boj_deputy_gov_nakaso_speech.title:ja:DEFAULT': '日銀の中曽副総裁講演',
  'boj_deputy_gov_nakaso_speech.title:ja:Japan': '日銀の中曽副総裁講演',
  'business_inventories_qoq.title:ja:DEFAULT': '企業在庫(前四半期比)',
  'business_inventories_qoq.title:ja:Australia': '企業在庫(前四半期比)',
  'memorial_day.title:ja:DEFAULT': 'メモリアルデー祝日',
  'memorial_day.title:ja:South Korea': 'メモリアルデー祝日',
  'memorial_day.title:ja:Israel': 'メモリアルデー祝日',
  'memorial_day.title:ja:United States': 'メモリアルデー祝日',
  'housing_starts_s_a_yoy.title:ja:DEFAULT': '住宅着工件数（季節調整済み）前年比',
  'housing_starts_s_a_yoy.title:ja:Canada': '住宅着工件数（季節調整済み）前年比',
  'ramadan_feast.title:ja:DEFAULT': 'ラマダンの饗宴',
  'ramadan_feast.title:ja:India': 'ラマダンの饗宴',
  'goods_trade_balance_adv.title:ja:DEFAULT': '財貿易収支（速報値）',
  'goods_trade_balance_adv.title:ja:United States': '財貿易収支（速報値）',
  'core_inflation_rate_yoy_final.title:ja:DEFAULT': 'コアインフレ率(前年比)確報値',
  'core_inflation_rate_yoy_final.title:ja:Euro Area': 'コアインフレ率(前年比)確報値',
  'may_day.title:ja:DEFAULT': '労働者の日',
  'may_day.title:ja:China': '労働者の日',
  'may_day.title:ja:Czech Republic': '労働者の日',
  'may_day.title:ja:Ireland': '労働者の日',
  'may_day.title:ja:United Kingdom': '労働者の日',
  'may_day.title:ja:Finland': '労働者の日',
  'fed_evans_speaks.title:ja:DEFAULT': 'FRBエバンス氏講演',
  'fed_evans_speaks.title:ja:United States': 'FRBエバンス氏講演',
  'ura_property_index_qoq.title:ja:DEFAULT': 'URA住宅価格指数(前四半期比）',
  'ura_property_index_qoq.title:ja:Singapore': 'URA住宅価格指数(前四半期比）',
  'may_bank_holiday.title:ja:DEFAULT': '5月のバンク・ホリデー',
  'may_bank_holiday.title:ja:Ireland': '5月のバンク・ホリデー',
  'westpac_leading_index.title:ja:DEFAULT': 'ウエストパック先行指数',
  'westpac_leading_index.title:ja:Australia': 'ウエストパック先行指数',
  'may_1st.title:ja:DEFAULT': '労働者の日',
  'may_1st.title:ja:Sweden': '労働者の日',
  'mass_layoffs.title:ja:DEFAULT': '大量解雇',
  'mass_layoffs.title:ja:United States': '大量解雇',
  'services_sentiment.title:ja:DEFAULT': 'サービス業景況感',
  'services_sentiment.title:ja:Euro Area': 'サービス業景況感',
  'martin_l_king_s_birthday.title:ja:DEFAULT': 'キング牧師記念日',
  'martin_l_king_s_birthday.title:ja:United States': 'キング牧師記念日',
  'markit_jmma_manufacturing_pmi_flash.title:ja:DEFAULT':
    'マークイット/JMMA製造業購買担当者景気指数 (PMI) 速報値',
  'markit_jmma_manufacturing_pmi_flash.title:ja:Japan':
    'マークイット/JMMA製造業購買担当者景気指数 (PMI) 速報値',
  'general_affairs_council_meeting.title:ja:DEFAULT': '総務理事会会合',
  'general_affairs_council_meeting.title:ja:European Union': '総務理事会会合',
  'battle_of_puebla.title:ja:DEFAULT': 'プエブラ戦勝記念日',
  'battle_of_puebla.title:ja:Mexico': 'プエブラ戦勝記念日',
  'labor_day.title:ja:DEFAULT': '労働者の日（レイバー・デイ）',
  'labor_day.title:ja:New Zealand': '労働者の日（レイバー・デイ）',
  'labor_day.title:ja:Austria': '労働者の日（レイバー・デイ）',
  'labor_day.title:ja:Hong Kong': '労働者の日（レイバー・デイ）',
  'labor_day.title:ja:Greece': '労働者の日（レイバー・デイ）',
  'labor_day.title:ja:Taiwan': '労働者の日（レイバー・デイ）',
  'labor_day.title:ja:South Korea': '労働者の日（レイバー・デイ）',
  'labor_day.title:ja:Singapore': '労働者の日（レイバー・デイ）',
  'labor_day.title:ja:Thailand': '労働者の日（レイバー・デイ）',
  'labor_day.title:ja:Spain': '労働者の日（レイバー・デイ）',
  'labor_day.title:ja:Czech Republic': '労働者の日（レイバー・デイ）',
  'labor_day.title:ja:Canada': '労働者の日（レイバー・デイ）',
  'labor_day.title:ja:Indonesia': '労働者の日（レイバー・デイ）',
  'labor_day.title:ja:Italy': '労働者の日（レイバー・デイ）',
  'labor_day.title:ja:United States': '労働者の日（レイバー・デイ）',
  'labor_day.title:ja:Brazil': '労働者の日（レイバー・デイ）',
  'labor_day.title:ja:Philippines': '労働者の日（レイバー・デイ）',
  'labor_day.title:ja:Colombia': '労働者の日（レイバー・デイ）',
  'labor_day.title:ja:Portugal': '労働者の日（レイバー・デイ）',
  'labor_day.title:ja:China': '労働者の日（レイバー・デイ）',
  'labor_day.title:ja:Belgium': '労働者の日（レイバー・デイ）',
  'labor_day.title:ja:France': '労働者の日（レイバー・デイ）',
  'labor_day.title:ja:Norway': '労働者の日（レイバー・デイ）',
  'labor_day.title:ja:Mexico': '労働者の日（レイバー・デイ）',
  'labor_day.title:ja:Poland': '労働者の日（レイバー・デイ）',
  'labor_day.title:ja:Germany': '労働者の日（レイバー・デイ）',
  'labor_day.title:ja:Hungary': '労働者の日（レイバー・デイ）',
  'employment_growth_yoy.title:ja:DEFAULT': '雇用成長率(前年比)',
  'employment_growth_yoy.title:ja:Poland': '雇用成長率(前年比)',
  'commbank_manufacturing_pmi_final.title:ja:DEFAULT':
    'CommBank製造業購買担当者景気指数 (PMI) 確報値',
  'commbank_manufacturing_pmi_final.title:ja:Australia':
    'CommBank製造業購買担当者景気指数 (PMI) 確報値',
  'markit_jmma_manufacturing_pmi_final.title:ja:DEFAULT':
    'マークイット/JMMA製造業購買担当者景気指数 (PMI) 確報値',
  'markit_jmma_manufacturing_pmi_final.title:ja:Japan':
    'マークイット/JMMA製造業購買担当者景気指数 (PMI) 確報値',
  'markit_jmma_manufacturing_pmi.title:ja:DEFAULT':
    'マークイット/JMMA製造業購買担当者景気指数 (PMI)',
  'markit_jmma_manufacturing_pmi.title:ja:Japan': 'マークイット/JMMA製造業購買担当者景気指数 (PMI)',
  'markit_cips_uk_services_pmi_flash.title:ja:DEFAULT':
    'マークイット/CIPS英国サービス業購買担当者景気指数 (PMI) 速報値',
  'markit_cips_uk_services_pmi_flash.title:ja:United Kingdom':
    'マークイット/CIPS英国サービス業購買担当者景気指数 (PMI) 速報値',
  'api_weekly_gasoline_stock_change.title:ja:DEFAULT': '米国石油協会（API) 週間ガソリン在庫推移',
  'api_weekly_gasoline_stock_change.title:ja:United States':
    '米国石油協会（API) 週間ガソリン在庫推移',
  'markit_cips_uk_services_pmi_final.title:ja:DEFAULT':
    'マークイット/CIPS英国サービス業購買担当者景気指数 (PMI) 確報値',
  'markit_cips_uk_services_pmi_final.title:ja:United Kingdom':
    'マークイット/CIPS英国サービス業購買担当者景気指数 (PMI) 確報値',
  'mps_vote_on_brexit_bill.title:ja:DEFAULT': '英国EU離脱に関する国会議員投票',
  'mps_vote_on_brexit_bill.title:ja:United Kingdom': '英国EU離脱に関する国会議員投票',
  'fed_labor_market_conditions_index_mom.title:ja:DEFAULT': 'FRB労働市場情勢指数(前月比)',
  'fed_labor_market_conditions_index_mom.title:ja:United States': 'FRB労働市場情勢指数(前月比)',
  'raw_materials_price_index_mom.title:ja:DEFAULT': '原材料価格指数(前月比）',
  'raw_materials_price_index_mom.title:ja:Canada': '原材料価格指数(前月比）',
  'markit_bme_manufacturing_pmi_flash.title:ja:DEFAULT':
    'マークイット/BME製造業購買担当者景気指数 (PMI)速報値',
  'markit_bme_manufacturing_pmi_flash.title:ja:Germany':
    'マークイット/BME製造業購買担当者景気指数 (PMI)速報値',
  'markit_adaci_manufacturing_pmi.title:ja:DEFAULT':
    'マークイット/ADACI製造業購買担当者景気指数(PMI)',
  'markit_adaci_manufacturing_pmi.title:ja:Italy':
    'マークイット/ADACI製造業購買担当者景気指数(PMI)',
  'ifo_business_climate_prel.title:ja:DEFAULT': 'Ifo企業景況感指数速報値',
  'ifo_business_climate_prel.title:ja:Germany': 'Ifo企業景況感指数速報値',
  'markit_services_pmi_prel.title:ja:DEFAULT':
    'マークイットサービス業購買担当者景気指数(PMI)速報値',
  'markit_services_pmi_prel.title:ja:United States':
    'マークイットサービス業購買担当者景気指数(PMI)速報値',
  'h_m_the_queen_s_birthday.title:ja:DEFAULT': '女王陛下誕生日',
  'h_m_the_queen_s_birthday.title:ja:Thailand': '女王陛下誕生日',
  'boi_annual_report.title:ja:DEFAULT': '日本銀行年次報告書',
  'boi_annual_report.title:ja:Italy': '日本銀行年次報告書',
  'markit_services_pmi_flash.title:ja:DEFAULT':
    'マークイットサービス業購買担当者景気指数（PMI) 速報値',
  'markit_services_pmi_flash.title:ja:Australia':
    'マークイットサービス業購買担当者景気指数（PMI) 速報値',
  'markit_services_pmi_flash.title:ja:Germany':
    'マークイットサービス業購買担当者景気指数（PMI) 速報値',
  'markit_services_pmi_flash.title:ja:France':
    'マークイットサービス業購買担当者景気指数（PMI) 速報値',
  'markit_services_pmi_flash.title:ja:Euro Area':
    'マークイットサービス業購買担当者景気指数（PMI) 速報値',
  'markit_services_pmi_flash.title:ja:United States':
    'マークイットサービス業購買担当者景気指数（PMI) 速報値',
  'markit_services_pmi.title:ja:DEFAULT': 'マークイットサービス業購買担当者景気指数（PMI)',
  'markit_services_pmi.title:ja:Russia': 'マークイットサービス業購買担当者景気指数（PMI)',
  'markit_services_pmi.title:ja:Brazil': 'マークイットサービス業購買担当者景気指数（PMI)',
  'markit_services_pmi.title:ja:Sweden': 'マークイットサービス業購買担当者景気指数（PMI)',
  'markit_services_pmi.title:ja:Germany': 'マークイットサービス業購買担当者景気指数（PMI)',
  'markit_services_pmi.title:ja:India': 'マークイットサービス業購買担当者景気指数（PMI)',
  'markit_services_pmi.title:ja:United Kingdom': 'マークイットサービス業購買担当者景気指数（PMI)',
  'markit_services_pmi.title:ja:Japan': 'マークイットサービス業購買担当者景気指数（PMI)',
  'markit_services_pmi.title:ja:France': 'マークイットサービス業購買担当者景気指数（PMI)',
  'markit_services_pmi.title:ja:Euro Area': 'マークイットサービス業購買担当者景気指数（PMI)',
  'markit_services_pmi.title:ja:Spain': 'マークイットサービス業購買担当者景気指数（PMI)',
  'markit_services_pmi.title:ja:Ireland': 'マークイットサービス業購買担当者景気指数（PMI)',
  'markit_manufacturing_pmi_flash.title:ja:DEFAULT':
    'マークイット製造業購買担当者景気指数 (PMI) 速報値',
  'markit_manufacturing_pmi_flash.title:ja:France':
    'マークイット製造業購買担当者景気指数 (PMI) 速報値',
  'pending_home_sales_mom.title:ja:DEFAULT': '住宅販売保留件数(前月比)',
  'pending_home_sales_mom.title:ja:United States': '住宅販売保留件数(前月比)',
  'health-sports_day.title:ja:DEFAULT': '体育の日',
  'health-sports_day.title:ja:Japan': '体育の日',
  'fed_duke_speech.title:ja:DEFAULT': 'FRBデューク理事講演',
  'fed_duke_speech.title:ja:United States': 'FRBデューク理事講演',
  'markit_manufacturing_pmi.title:ja:Spain': 'マークイット製造業購買担当者指数 (PMI)',
  'markit_manufacturing_pmi_flash.title:ja:Australia':
    'マークイット製造業購買担当者景気指数 (PMI) 速報値',
  'markit_manufacturing_pmi_flash.title:ja:Germany':
    'マークイット製造業購買担当者景気指数 (PMI) 速報値',
  'markit_manufacturing_pmi_flash.title:ja:Euro Area':
    'マークイット製造業購買担当者景気指数 (PMI) 速報値',
  'markit_manufacturing_pmi_flash.title:ja:United States':
    'マークイット製造業購買担当者景気指数 (PMI) 速報値',
  'milad-un-nabi_holy_prophet_s_birthday.title:ja:DEFAULT': '',
  'milad-un-nabi_holy_prophet_s_birthday.title:ja:Indonesia': '',
  'fed_yellen_testimony_sbc.title:ja:DEFAULT': 'FRBイエレン議長証言-上院銀行委員会',
  'fed_yellen_testimony_sbc.title:ja:United States': 'FRBイエレン議長証言-上院銀行委員会',
  'markit_composite_pmi_flash.title:ja:DEFAULT': 'マークイット総合PMI速報値',
  'markit_composite_pmi_flash.title:ja:Australia': 'マークイット総合PMI速報値',
  'markit_composite_pmi_flash.title:ja:Germany': 'マークイット総合PMI速報値',
  'markit_composite_pmi_flash.title:ja:France': 'マークイット総合PMI速報値',
  'markit_composite_pmi_flash.title:ja:Euro Area': 'マークイット総合PMI速報値',
  'markit_composite_pmi_flash.title:ja:United States': 'マークイット総合PMI速報値',
  'mark_carney_will_become_the_new_bank_of_england_governor.title:ja:DEFAULT':
    'イングランド銀行の新総裁にマーク・カーニー氏が就任予定',
  'mark_carney_will_become_the_new_bank_of_england_governor.title:ja:United Kingdom':
    'イングランド銀行の新総裁にマーク・カーニー氏が就任予定',
  'marginal_lending_rate.title:ja:DEFAULT': '限界貸出金利',
  'marginal_lending_rate.title:ja:Euro Area': '限界貸出金利',
  'parliamentary_elections.title:ja:DEFAULT': '国会議員選挙',
  'parliamentary_elections.title:ja:Switzerland': '国会議員選挙',
  'parliamentary_elections.title:ja:Finland': '国会議員選挙',
  'parliamentary_elections.title:ja:Belgium': '国会議員選挙',
  'parliamentary_elections.title:ja:Denmark': '国会議員選挙',
  'parliamentary_elections.title:ja:Hungary': '国会議員選挙',
  'parliamentary_elections.title:ja:Japan': '国会議員選挙',
  'parliamentary_elections.title:ja:Mexico': '国会議員選挙',
  'parliamentary_elections.title:ja:France': '国会議員選挙',
  'parliamentary_elections.title:ja:Canada': '国会議員選挙',
  'parliamentary_elections.title:ja:Portugal': '国会議員選挙',
  'parliamentary_elections.title:ja:Greece': '国会議員選挙',
  'parliamentary_elections.title:ja:United Kingdom': '国会議員選挙',
  'parliamentary_elections.title:ja:Poland': '国会議員選挙',
  'parliamentary_elections.title:ja:Spain': '国会議員選挙',
  'parliamentary_elections.title:ja:Ireland': '国会議員選挙',
  'parliamentary_elections.title:ja:Russia': '国会議員選挙',
  'parliamentary_elections.title:ja:Singapore': '国会議員選挙',
  'parliamentary_elections.title:ja:Netherlands': '国会議員選挙',
  's_p_case-shiller_home_price_mom.title:ja:DEFAULT': 'S&P/ケース・シラー住宅価格指数(前月比)',
  's_p_case-shiller_home_price_mom.title:ja:United States':
    'S&P/ケース・シラー住宅価格指数(前月比)',
  'manufacturing_shipments_yoy.title:ja:DEFAULT': '製造業出荷額(前年比)',
  'manufacturing_shipments_yoy.title:ja:Canada': '製造業出荷額(前年比)',
  'presidential_election_results.title:ja:DEFAULT': '大統領選挙結果',
  'presidential_election_results.title:ja:United States': '大統領選挙結果',
  'revolution_day.title:ja:DEFAULT': '革命記念日',
  'revolution_day.title:ja:Mexico': '革命記念日',
  'revolution_day.title:ja:Hungary': '革命記念日',
  'finance_minister_schelling_speech.title:ja:DEFAULT': 'シェリング財務相講演',
  'finance_minister_schelling_speech.title:ja:Austria': 'シェリング財務相講演',
  'bcc_quarterly_economic_survey.title:ja:DEFAULT': 'BCC四半期経済動向調査',
  'bcc_quarterly_economic_survey.title:ja:United Kingdom': 'BCC四半期経済動向調査',
  'manufacturing_shipments_mom.title:ja:DEFAULT': '製造業出荷額(前月比)',
  'manufacturing_shipments_mom.title:ja:Canada': '製造業出荷額(前月比)',
  'manufacturing_sales_mom_final.title:ja:DEFAULT': '製造業売上高(前月比)確報値',
  'manufacturing_sales_mom_final.title:ja:Canada': '製造業売上高(前月比)確報値',
  'manufacturing_production_yoy.title:ja:DEFAULT': '製造業生産(前年比)',
  'manufacturing_production_yoy.title:ja:New Zealand': '製造業生産(前年比)',
  'manufacturing_production_yoy.title:ja:Netherlands': '製造業生産(前年比)',
  'manufacturing_production_yoy.title:ja:Norway': '製造業生産(前年比)',
  'manufacturing_production_yoy.title:ja:South Korea': '製造業生産(前年比)',
  'manufacturing_production_yoy.title:ja:United States': '製造業生産(前年比)',
  'manufacturing_production_yoy.title:ja:South Africa': '製造業生産(前年比)',
  'manufacturing_production_yoy.title:ja:United Kingdom': '製造業生産(前年比)',
  'manufacturing_production_yoy.title:ja:India': '製造業生産(前年比)',
  'semi-annual_monetary_policy_decision.title:ja:DEFAULT': '半年ごとの金融政策決定',
  'semi-annual_monetary_policy_decision.title:ja:Singapore': '半年ごとの金融政策決定',
  'electronic_card_retail_sales_yoy.title:ja:DEFAULT': '電子カード小売売上高（前年比）',
  'electronic_card_retail_sales_yoy.title:ja:New Zealand': '電子カード小売売上高（前年比）',
  'manufacturing_production_index_yoy.title:ja:DEFAULT': '製造業生産指数(前年比)',
  'manufacturing_production_index_yoy.title:ja:South Africa': '製造業生産指数(前年比)',
  '2-year_btan_auction.title:ja:DEFAULT': 'BTAN2年債入札',
  '2-year_btan_auction.title:ja:France': 'BTAN2年債入札',
  'songkran.title:ja:DEFAULT': 'ソンクラーン祭り',
  'songkran.title:ja:Thailand': 'ソンクラーン祭り',
  'manufacturing_prod_yoy.title:ja:DEFAULT': '製造業生産(前年比)',
  'manufacturing_prod_yoy.title:ja:New Zealand': '製造業生産(前年比)',
  'manufacturing_prod_yoy.title:ja:Netherlands': '製造業生産(前年比)',
  'manufacturing_prod_yoy.title:ja:South Africa': '製造業生産(前年比)',
  'manufacturing_prod_yoy.title:ja:Norway': '製造業生産(前年比)',
  'unit_labor_costs_yoy.title:ja:DEFAULT': '単位労働コスト(前年比)',
  'unit_labor_costs_yoy.title:ja:United States': '単位労働コスト(前年比)',
  'liberal_democratic_party_leadership_vote.title:ja:DEFAULT': '自民党総裁選挙',
  'liberal_democratic_party_leadership_vote.title:ja:Japan': '自民党総裁選挙',
  'national_day_double_tenth_day.title:ja:DEFAULT': '双十節(建国記念日)',
  'national_day_double_tenth_day.title:ja:Taiwan': '双十節(建国記念日)',
  'manufacturing_payrolls.title:ja:DEFAULT': '製造業給与',
  'manufacturing_payrolls.title:ja:United States': '製造業給与',
  'manufacturing_pmi.title:ja:DEFAULT': '製造業購買担当者景気指数（PMI）',
  'manufacturing_pmi.title:ja:South Africa': '製造業購買担当者景気指数（PMI）',
  'manufacturing_pmi.title:ja:Japan': '製造業購買担当者景気指数（PMI）',
  'manufacturing_pmi.title:ja:Israel': '製造業購買担当者景気指数（PMI）',
  'manufacturing_pmi.title:ja:Italy': '製造業購買担当者景気指数（PMI）',
  'manufacturing_pmi.title:ja:France': '製造業購買担当者景気指数（PMI）',
  'fiscal_year_gdp_growth.title:ja:DEFAULT': '会計年度GDP成長率',
  'fiscal_year_gdp_growth.title:ja:India': '会計年度GDP成長率',
  'cbi_distributive_trades.title:ja:DEFAULT': 'CBI流通業取引',
  'cbi_distributive_trades.title:ja:United Kingdom': 'CBI流通業取引',
  'manufacturing_confidence.title:ja:DEFAULT': '製造業信頼感指数',
  'manufacturing_confidence.title:ja:Netherlands': '製造業信頼感指数',
  'presidential_and_legislative_elections.title:ja:DEFAULT': '大統領・立法府選挙',
  'presidential_and_legislative_elections.title:ja:Mexico': '大統領・立法府選挙',
  'ism_manufacturing_pmi.title:ja:DEFAULT': 'ISM製造業購買担当者景気指数（PMI）',
  'ism_manufacturing_pmi.title:ja:United States': 'ISM製造業購買担当者景気指数（PMI）',
  'israel_municipal_election_day.title:ja:DEFAULT': 'イスラエル地方選挙日',
  'israel_municipal_election_day.title:ja:Israel': 'イスラエル地方選挙日',
  'exports_final.title:ja:DEFAULT': '輸出(確報値）',
  'exports_final.title:ja:India': '輸出(確報値）',
  'makha_bucha_day.title:ja:DEFAULT': '万仏祭（マカブチャ）',
  'makha_bucha_day.title:ja:Thailand': '万仏祭（マカブチャ）',
  'finance_summit_2019.title:ja:DEFAULT': '金融サミット2019',
  'finance_summit_2019.title:ja:France': '金融サミット2019',
  'unemployment_rate_nsa.title:ja:DEFAULT': '失業率-季節調整なし',
  'unemployment_rate_nsa.title:ja:Norway': '失業率-季節調整なし',
  'mahavir_jayanti.title:ja:DEFAULT': 'マハヴィール・ジャヤンティ(ジャイナ教開祖生誕祭）',
  'mahavir_jayanti.title:ja:India': 'マハヴィール・ジャヤンティ(ジャイナ教開祖生誕祭）',
  'prophet_s_ascension.title:ja:DEFAULT': '預言者昇天祭',
  'prophet_s_ascension.title:ja:Indonesia': '預言者昇天祭',
  'mahatma_gandhi_s_birthday.title:ja:DEFAULT': 'ガンディー生誕記念日',
  'mahatma_gandhi_s_birthday.title:ja:India': 'ガンディー生誕記念日',
  'hsbc_india_services_pmi.title:ja:DEFAULT': 'HSBCインドサービス業購買担当者景気指数（PMI)',
  'hsbc_india_services_pmi.title:ja:India': 'HSBCインドサービス業購買担当者景気指数（PMI)',
  'mahatma_gandhi_birthday.title:ja:DEFAULT': 'ガンディー生誕記念日',
  'mahatma_gandhi_birthday.title:ja:India': 'ガンディー生誕記念日',
  'mahashivratri.title:ja:DEFAULT': 'マハ・シバラトリ(シバ神生誕祭)',
  'mahashivratri.title:ja:India': 'マハ・シバラトリ(シバ神生誕祭)',
  'mahashivarati.title:ja:DEFAULT': 'マハ・シバラトリ(シバ神生誕祭)',
  'mahashivarati.title:ja:India': 'マハ・シバラトリ(シバ神生誕祭)',
  'machinery_orders_yoy.title:ja:DEFAULT': '機械受注(前年比)',
  'machinery_orders_yoy.title:ja:Japan': '機械受注(前年比)',
  'ibd_tipp_economic_optimism.title:ja:DEFAULT': 'IBD/TIPP景気楽観指数',
  'ibd_tipp_economic_optimism.title:ja:United States': 'IBD/TIPP景気楽観指数',
  'primary_budget_balance.title:ja:DEFAULT': '基礎的財政収支',
  'primary_budget_balance.title:ja:Brazil': '基礎的財政収支',
  'rba_girn_speech.title:ja:DEFAULT': 'RBAガーン氏講演',
  'rba_girn_speech.title:ja:Australia': 'RBAガーン氏講演',
  'machine_tool_orders_yoy.title:ja:DEFAULT': '工作機械受注(前年比)',
  'machine_tool_orders_yoy.title:ja:Japan': '工作機械受注(前年比)',
  'labour_day_holiday.title:ja:DEFAULT': '労働者の日の休日',
  'labour_day_holiday.title:ja:Russia': '労働者の日の休日',
  'fda_hearing_on_covid-19_vaccines.title:ja:DEFAULT': 'コロナウイルスワクチンに関するFDA公聴会',
  'fda_hearing_on_covid-19_vaccines.title:ja:United States':
    'コロナウイルスワクチンに関するFDA公聴会',
  'mps_vote_on_withdrawal_agreement_bill.title:ja:DEFAULT': '離脱協定法案の議会投票',
  'mps_vote_on_withdrawal_agreement_bill.title:ja:United Kingdom': '離脱協定法案の議会投票',
  'gdp_consumer_spending_yoy.title:ja:DEFAULT': 'GDP消費者支出(前年比)',
  'gdp_consumer_spending_yoy.title:ja:United States': 'GDP消費者支出(前年比)',
  'mps_vote_on_pm_election_call.title:ja:DEFAULT': '首相による総選挙要請の議会投票',
  'mps_vote_on_pm_election_call.title:ja:United Kingdom': '首相による総選挙要請の議会投票',
  'mps_vote_on_internal_market_bill.title:ja:DEFAULT': '域内市場法案の議会投票',
  'mps_vote_on_internal_market_bill.title:ja:United Kingdom': '域内市場法案の議会投票',
  'mps_vote_on_benn_bill.title:ja:DEFAULT': 'ベン法案の議会投票',
  'mps_vote_on_benn_bill.title:ja:United Kingdom': 'ベン法案の議会投票',
  'freedom_and_democracy_day.title:ja:DEFAULT': '自由・民主主義闘争記念日',
  'freedom_and_democracy_day.title:ja:Czech Republic': '自由・民主主義闘争記念日',
  'monetary_policy_statement.title:ja:DEFAULT': '金融政策声明',
  'monetary_policy_statement.title:ja:New Zealand': '金融政策声明',
  'monetary_policy_statement.title:ja:Singapore': '金融政策声明',
  'mpc_member_bean_speech.title:ja:DEFAULT': 'MPC委員ビーン氏講演',
  'mpc_member_bean_speech.title:ja:United Kingdom': 'MPC委員ビーン氏講演',
  'boe_mpc_vote_cut.title:ja:DEFAULT': 'イングランド銀行金融政策委員会（MPC）利下げ投票',
  'boe_mpc_vote_cut.title:ja:United Kingdom': 'イングランド銀行金融政策委員会（MPC）利下げ投票',
  'seollal_substitute_holiday.title:ja:DEFAULT': '韓国旧正月の振替休日',
  'seollal_substitute_holiday.title:ja:South Korea': '韓国旧正月の振替休日',
  'ihs_markit_manufacturing_pmi.title:ja:DEFAULT': 'IHSマークイット製造業購買担当者指数 (PMI)',
  'ihs_markit_manufacturing_pmi.title:ja:Italy': 'IHSマークイット製造業購買担当者指数 (PMI)',
  'mpc_meeting_minutes.title:ja:DEFAULT': 'MPC会合議事録',
  'mpc_meeting_minutes.title:ja:United Kingdom': 'MPC会合議事録',
  'rba_gov_glenn_stevens_speech.title:ja:DEFAULT': 'RBAグレン・スティーブンス総裁講演',
  'rba_gov_glenn_stevens_speech.title:ja:Australia': 'RBAグレン・スティーブンス総裁講演',
  'mof_asakawa_speech.title:ja:DEFAULT': '財務省浅川氏講演',
  'mof_asakawa_speech.title:ja:Japan': '財務省浅川氏講演',
  'large_retailer_sales.title:ja:DEFAULT': '大規模小売店売上高',
  'large_retailer_sales.title:ja:Japan': '大規模小売店売上高',
  'holiday_-_ganesh_chaturthi.title:ja:DEFAULT':
    '祝日－ ガネーシャ・チャトルティ(ガネーシャ神生誕祭)',
  'holiday_-_ganesh_chaturthi.title:ja:India':
    '祝日－ ガネーシャ・チャトルティ(ガネーシャ神生誕祭)',
  'mni_consumer_sentiment_indicator.title:ja:DEFAULT': 'MNI消費者信頼感指数',
  'mni_consumer_sentiment_indicator.title:ja:Russia': 'MNI消費者信頼感指数',
  'mni_consumer_sentiment_indicator.title:ja:India': 'MNI消費者信頼感指数',
  'st_berchtold.title:ja:DEFAULT': '聖ベルヒトルト',
  'st_berchtold.title:ja:Switzerland': '聖ベルヒトルト',
  'industrial_sales_yoy.title:ja:DEFAULT': '工業売上高(前年比)',
  'industrial_sales_yoy.title:ja:Italy': '工業売上高(前年比)',
  'mni_business_sentiment_indicator.title:ja:DEFAULT': 'MNI企業景況感指数',
  'mni_business_sentiment_indicator.title:ja:China': 'MNI企業景況感指数',
  '10-year_oat_auction.title:ja:DEFAULT': 'フランス10年物国債 (OAT) 入札',
  '10-year_oat_auction.title:ja:France': 'フランス10年物国債 (OAT) 入札',
  'ecb_draghi_speaks_at_jackson_hole.title:ja:DEFAULT': 'ECBドラギ氏ジャクソンホール講演',
  'ecb_draghi_speaks_at_jackson_hole.title:ja:Euro Area': 'ECBドラギ氏ジャクソンホール講演',
  'mba_mortgage_applications_wow.title:ja:DEFAULT': 'MBA住宅ローン申請件数(前週比)',
  'mba_mortgage_applications_wow.title:ja:United States': 'MBA住宅ローン申請件数(前週比)',
  'foreign_securities_purchases_by_canadians.title:ja:DEFAULT': 'カナダ人による外国証券購入状況',
  'foreign_securities_purchases_by_canadians.title:ja:Canada': 'カナダ人による外国証券購入状況',
  'mba_mortgage_applications.title:ja:DEFAULT': 'MBA住宅ローン申請件数',
  'mba_mortgage_applications.title:ja:United States': 'MBA住宅ローン申請件数',
  'uk_general_election.title:ja:DEFAULT': 'イギリス総選挙',
  'uk_general_election.title:ja:United Kingdom': 'イギリス総選挙',
  'mba_30-year_mortgage_rate.title:ja:DEFAULT': 'MBA30年住宅ローン金利',
  'mba_30-year_mortgage_rate.title:ja:United States': 'MBA30年住宅ローン金利',
  'mas_chair_shanmugaratnam_speech.title:ja:DEFAULT':
    'シンガポール金融管理局(MAS) シャンムガラトナム会長講演',
  'mas_chair_shanmugaratnam_speech.title:ja:Singapore':
    'シンガポール金融管理局(MAS) シャンムガラトナム会長講演',
  'm3_money_supply_yoy.title:ja:DEFAULT': 'M3マネーサプライ(前年比)',
  'm3_money_supply_yoy.title:ja:Euro Area': 'M3マネーサプライ(前年比)',
  'm3_money_supply_yoy.title:ja:New Zealand': 'M3マネーサプライ(前年比)',
  'm3_money_supply_yoy.title:ja:Poland': 'M3マネーサプライ(前年比)',
  'm3_money_supply_yoy.title:ja:India': 'M3マネーサプライ(前年比)',
  'm3_money_supply_yoy.title:ja:South Africa': 'M3マネーサプライ(前年比)',
  'm3_money_supply_yoy.title:ja:Czech Republic': 'M3マネーサプライ(前年比)',
  'm1_money_supply_yoy.title:ja:DEFAULT': 'M1マネーサプライ(前年比)',
  'm1_money_supply_yoy.title:ja:Israel': 'M1マネーサプライ(前年比)',
  'nab_business_confidence.title:ja:DEFAULT': 'NAB企業信頼感指数',
  'nab_business_confidence.title:ja:Australia': 'NAB企業信頼感指数',
  'london_mayoral_election.title:ja:DEFAULT': 'ロンドン市長選挙',
  'london_mayoral_election.title:ja:United Kingdom': 'ロンドン市長選挙',
  'london_assembly_election.title:ja:DEFAULT': 'ロンドン議会議員選挙',
  'london_assembly_election.title:ja:United Kingdom': 'ロンドン議会議員選挙',
  'loans_to_private_sector_yoy.title:ja:DEFAULT': '民間部門向け貸出(前年比)',
  'loans_to_private_sector_yoy.title:ja:Greece': '民間部門向け貸出(前年比)',
  'loans_to_companies_yoy.title:ja:DEFAULT': '企業向け貸出(前年比)',
  'loans_to_companies_yoy.title:ja:Euro Area': '企業向け貸出(前年比)',
  'jerusalem_day.title:ja:DEFAULT': 'エルサレムの日',
  'jerusalem_day.title:ja:Israel': 'エルサレムの日',
  'loan_prime_rate_5y.title:ja:DEFAULT': 'ローンのプライムレート 5年',
  'loan_prime_rate_5y.title:ja:China': 'ローンのプライムレート 5年',
  'asalha_bucha.title:ja:DEFAULT': '三宝節(タイの祝祭)',
  'asalha_bucha.title:ja:Thailand': '三宝節(タイの祝祭)',
  'loan_officer_survey.title:ja:DEFAULT': '融資担当者調査',
  'loan_officer_survey.title:ja:United States': '融資担当者調査',
  'loan_growth_yoy.title:ja:DEFAULT': 'ローン成長率(前年比)',
  'loan_growth_yoy.title:ja:Euro Area': 'ローン成長率(前年比)',
  'loan_growth_yoy.title:ja:Indonesia': 'ローン成長率(前年比)',
  'loan_growth_yoy.title:ja:Norway': 'ローン成長率(前年比)',
  'liberty_day.title:ja:DEFAULT': '自由の日',
  'liberty_day.title:ja:Portugal': '自由の日',
  'liberation_from_fascism.title:ja:DEFAULT': 'ファシズム解放記念日',
  'liberation_from_fascism.title:ja:Czech Republic': 'ファシズム解放記念日',
  'imf_world_bank_annual_meetings.title:ja:DEFAULT': 'IMF/世界銀行年次総会',
  'imf_world_bank_annual_meetings.title:ja:Indonesia': 'IMF/世界銀行年次総会',
  'imf_world_bank_annual_meetings.title:ja:United States': 'IMF/世界銀行年次総会',
  'lending_to_households_yoy.title:ja:DEFAULT': '家計向け貸出額(前年比)',
  'lending_to_households_yoy.title:ja:Sweden': '家計向け貸出額(前年比)',
  'legislative_election.title:ja:DEFAULT': '立法府選挙',
  'legislative_election.title:ja:South Korea': '立法府選挙',
  'legislative_election.title:ja:Israel': '立法府選挙',
  'leading_economic_index_prel.title:ja:DEFAULT': '経済先行指数速報値',
  'leading_economic_index_prel.title:ja:Japan': '経済先行指数速報値',
  'manufacturing_output_mom.title:ja:DEFAULT': '製造業生産(前月比)',
  'manufacturing_output_mom.title:ja:Norway': '製造業生産(前月比)',
  'leading_composite_index_prel.title:ja:DEFAULT': '先行総合指数速報値',
  'leading_composite_index_prel.title:ja:Japan': '先行総合指数速報値',
  'leading_composite_index_final.title:ja:DEFAULT': '先行総合指数確報値',
  'leading_composite_index_final.title:ja:Japan': '先行総合指数確報値',
  'treasury_secretary_yellen_testimony.title:ja:DEFAULT': 'イエレン財務長官証言',
  'treasury_secretary_yellen_testimony.title:ja:United States': 'イエレン財務長官証言',
  'eid_ul-fitr.title:ja:DEFAULT': '断食明け大祭(イスラム教)',
  'eid_ul-fitr.title:ja:Philippines': '断食明け大祭(イスラム教)',
  'eid_ul-fitr.title:ja:Indonesia': '断食明け大祭(イスラム教)',
  'large_retailer_s_sales.title:ja:DEFAULT': '大規模小売店売上高',
  'large_retailer_s_sales.title:ja:Japan': '大規模小売店売上高',
  'bcb_focus_market_readout.title:ja:DEFAULT': 'ブラジル中央銀行Focusマーケット情報',
  'bcb_focus_market_readout.title:ja:Brazil': 'ブラジル中央銀行Focusマーケット情報',
  'construction_pmi.title:ja:DEFAULT': '建設業購買担当者景気指数 (PMI)',
  'construction_pmi.title:ja:Italy': '建設業購買担当者景気指数 (PMI)',
  'construction_pmi.title:ja:United Kingdom': '建設業購買担当者景気指数 (PMI)',
  'construction_pmi.title:ja:Ireland': '建設業購買担当者景気指数 (PMI)',
  'construction_pmi.title:ja:Germany': '建設業購買担当者景気指数 (PMI)',
  'construction_pmi.title:ja:France': '建設業購買担当者景気指数 (PMI)',
  'construction_pmi.title:ja:Euro Area': '建設業購買担当者景気指数 (PMI)',
  'm4_money_supply_yoy.title:ja:DEFAULT': 'M4マネーサプライ(前年比)',
  'm4_money_supply_yoy.title:ja:United Kingdom': 'M4マネーサプライ(前年比)',
  'rba_ellis_testimony.title:ja:DEFAULT': '',
  'rba_ellis_testimony.title:ja:Australia': '',
  'labour_thanksgiving_day.title:ja:DEFAULT': '勤労感謝の日',
  'labour_thanksgiving_day.title:ja:Japan': '勤労感謝の日',
  'labour_productivity_qoq.title:ja:DEFAULT': '労働生産性(前四半期比)',
  'labour_productivity_qoq.title:ja:Canada': '労働生産性(前四半期比)',
  'labour_productivity_qoq.title:ja:United Kingdom': '労働生産性(前四半期比)',
  'labour_costs_qoq.title:ja:DEFAULT': '労働コスト(前四半期比）',
  'labour_costs_qoq.title:ja:United Kingdom': '労働コスト(前四半期比）',
  'frankfurt_finance_summit_2016.title:ja:DEFAULT': 'フランクフルト金融サミット2016',
  'frankfurt_finance_summit_2016.title:ja:Germany': 'フランクフルト金融サミット2016',
  'labour_costs_index_yoy.title:ja:DEFAULT': '労働コスト指数（前年比）',
  'labour_costs_index_yoy.title:ja:New Zealand': '労働コスト指数（前年比）',
  'labour_costs_index_qoq.title:ja:DEFAULT': '労働コスト指数(前四半期比）',
  'labour_costs_index_qoq.title:ja:New Zealand': '労働コスト指数(前四半期比）',
  'labor_costs_qoq.title:ja:DEFAULT': '労働コスト(前四半期比）',
  'labor_costs_qoq.title:ja:United States': '労働コスト(前四半期比）',
  'rba_statement_on_monetary_policy.title:ja:DEFAULT': 'RBA金融政策声明',
  'rba_statement_on_monetary_policy.title:ja:Australia': 'RBA金融政策声明',
  'election_for_national_assembly.title:ja:DEFAULT': '国会議員選挙',
  'election_for_national_assembly.title:ja:South Korea': '国会議員選挙',
  'hsbc_manufacturing_pmi_final.title:ja:DEFAULT': 'HSBC製造業購買担当者景況指数（PMI）確報値',
  'hsbc_manufacturing_pmi_final.title:ja:China': 'HSBC製造業購買担当者景況指数（PMI）確報値',
  'kof_leading_indicator.title:ja:DEFAULT': 'KOF先行指標',
  'kof_leading_indicator.title:ja:Switzerland': 'KOF先行指標',
  'ecb_annual_report_2019.title:ja:DEFAULT': 'ECB年次報告書2019年',
  'ecb_annual_report_2019.title:ja:Euro Area': 'ECB年次報告書2019年',
  'june_bank_holiday.title:ja:DEFAULT': '6月のバンク・ホリデー',
  'june_bank_holiday.title:ja:Ireland': '6月のバンク・ホリデー',
  'boe_press_conference.title:ja:DEFAULT': 'イングランド銀行(英中央銀行)記者会見',
  'boe_press_conference.title:ja:United Kingdom': 'イングランド銀行(英中央銀行)記者会見',
  'jobseekers_total.title:ja:DEFAULT': '求職者合計',
  'jobseekers_total.title:ja:France': '求職者合計',
  'jibun_bank_manufacturing_pmi_flash.title:ja:DEFAULT':
    'auじぶん銀行製造業購買担当者景気指数 (PMI) 速報値',
  'jibun_bank_manufacturing_pmi_flash.title:ja:Japan':
    'auじぶん銀行製造業購買担当者景気指数 (PMI) 速報値',
  'ecb_const_ncio_speech.title:ja:DEFAULT': 'ECBコンスタンシオ副総裁講演',
  'ecb_const_ncio_speech.title:ja:Euro Area': 'ECBコンスタンシオ副総裁講演',
  'jibun_bank_composite_pmi_flash.title:ja:DEFAULT':
    'auじぶん銀行総合購買担当者景気指数（PMI）速報値',
  'jibun_bank_composite_pmi_flash.title:ja:Japan':
    'auじぶん銀行総合購買担当者景気指数（PMI）速報値',
  'jibun_bank_composite_pmi_final.title:ja:DEFAULT':
    'auじぶん銀行総合購買担当者景気指数（PMI）速報値',
  'jibun_bank_composite_pmi_final.title:ja:Japan':
    'auじぶん銀行総合購買担当者景気指数（PMI）速報値',
  'gdp_price_index_yoy_final.title:ja:DEFAULT': 'GDP物価指数(前年比)確報値',
  'gdp_price_index_yoy_final.title:ja:Japan': 'GDP物価指数(前年比)確報値',
  'jewish_new_year_ii.title:ja:DEFAULT': 'ユダヤ教新年2日目',
  'jewish_new_year_ii.title:ja:Israel': 'ユダヤ教新年2日目',
  'ism_new_york_index.title:ja:DEFAULT': 'ISMニューヨーク指数',
  'ism_new_york_index.title:ja:United States': 'ISMニューヨーク指数',
  'tuen_ng_festival.title:ja:DEFAULT': '龍船節',
  'tuen_ng_festival.title:ja:Hong Kong': '龍船節',
  'jewish_new_year_eve.title:ja:DEFAULT': 'ユダヤ教大晦日',
  'jewish_new_year_eve.title:ja:Israel': 'ユダヤ教大晦日',
  'gdp_growth_annualized_qoq_prel.title:ja:DEFAULT': 'GDP成長率の年率換算(前四半期比)速報値',
  'gdp_growth_annualized_qoq_prel.title:ja:Japan': 'GDP成長率の年率換算(前四半期比)速報値',
  'jewish_new_year.title:ja:DEFAULT': 'ユダヤ教新年',
  'jewish_new_year.title:ja:Israel': 'ユダヤ教新年',
  'unemployment_rate_harmonised.title:ja:DEFAULT': '調整後失業率',
  'unemployment_rate_harmonised.title:ja:Germany': '調整後失業率',
  'jan_hus_day.title:ja:DEFAULT': 'ヤン・フスの日(チェコ)',
  'jan_hus_day.title:ja:Czech Republic': 'ヤン・フスの日(チェコ)',
  'jackson_hole_symposium.title:ja:DEFAULT': 'ジャクソンホール会議',
  'jackson_hole_symposium.title:ja:United States': 'ジャクソンホール会議',
  'mps_vote_on_brexit_timetable.title:ja:DEFAULT': '英国のEU離脱スケジュールに関する議員投票',
  'mps_vote_on_brexit_timetable.title:ja:United Kingdom':
    '英国のEU離脱スケジュールに関する議員投票',
  'jackson_hole_economic_symposium.title:ja:DEFAULT': '',
  'jackson_hole_economic_symposium.title:ja:United States': '',
  'jackson_hole_economic_policy_symposium.title:ja:DEFAULT': 'ジャクソンホール経済シンポジウム',
  'jackson_hole_economic_policy_symposium.title:ja:United States':
    'ジャクソンホール経済シンポジウム',
  'house_price_index_qoq.title:ja:DEFAULT': '住宅価格指数(前四半期比)',
  'house_price_index_qoq.title:ja:Australia': '住宅価格指数(前四半期比)',
  'boe_saunders_speech.title:ja:DEFAULT': 'イングランド銀行ハウザー氏講演',
  'boe_saunders_speech.title:ja:United Kingdom': 'イングランド銀行ハウザー氏講演',
  'jolts_job_openings.title:ja:DEFAULT': 'JOLTs求人件数',
  'jolts_job_openings.title:ja:United States': 'JOLTs求人件数',
  'yom_kippur_eve_eve_of_atonement_day.title:ja:DEFAULT': 'ヨム・キップルの前夜(贖罪日の前夜)',
  'yom_kippur_eve_eve_of_atonement_day.title:ja:Israel': 'ヨム・キップルの前夜(贖罪日の前夜)',
  'italy_pm_confidence_vote_in_parliament.title:ja:DEFAULT': 'イタリア首相の議会での信任投票',
  'italy_pm_confidence_vote_in_parliament.title:ja:Italy': 'イタリア首相の議会での信任投票',
  'gdp_growth_rate_2015_prel.title:ja:DEFAULT': 'GDP成長率2015年速報値',
  'gdp_growth_rate_2015_prel.title:ja:Poland': 'GDP成長率2015年速報値',
  'gdp_growth_rate_2015_prel.title:ja:Russia': 'GDP成長率2015年速報値',
  'commbank_composite_pmi_final.title:ja:DEFAULT':
    '豪コモンウェルス銀行総合購買担当者景気指数（PMI）確報値',
  'commbank_composite_pmi_final.title:ja:Australia':
    '豪コモンウェルス銀行総合購買担当者景気指数（PMI）確報値',
  'fed_brainard_speech.title:ja:DEFAULT': 'FRBのブレイナード氏講演',
  'fed_brainard_speech.title:ja:United States': 'FRBのブレイナード氏講演',
  'israel_election_day.title:ja:DEFAULT': 'イスラエル選挙日',
  'israel_election_day.title:ja:Israel': 'イスラエル選挙日',
  'snb_jordan_speech.title:ja:DEFAULT': 'スイス国立銀行ジョルダン氏講演',
  'snb_jordan_speech.title:ja:Switzerland': 'スイス国立銀行ジョルダン氏講演',
  'financial_stability_review.title:ja:DEFAULT': '金融安定性レビュー',
  'financial_stability_review.title:ja:Euro Area': '金融安定性レビュー',
  'eve_of_rosh_hashanah.title:ja:DEFAULT': 'ロシュ・ハシャナ(ユダヤ教新年)の前夜',
  'eve_of_rosh_hashanah.title:ja:Israel': 'ロシュ・ハシャナ(ユダヤ教新年)の前夜',
  'islamic_new_year.title:ja:DEFAULT': 'イスラム教の新年',
  'islamic_new_year.title:ja:India': 'イスラム教の新年',
  'islamic_new_year.title:ja:Indonesia': 'イスラム教の新年',
  'international_women_s_holiday.title:ja:DEFAULT': '国際女性デーの休日',
  'international_women_s_holiday.title:ja:Russia': '国際女性デーの休日',
  'interest_rate_decision.title:ja:DEFAULT': '金利決定',
  'interest_rate_decision.title:ja:Euro Area': '金利決定',
  'interest_rate_decision.title:ja:Australia': '金利決定',
  'interest_rate_decision.title:ja:Hong Kong': '金利決定',
  'interest_rate_decision.title:ja:China': '金利決定',
  'interest_rate_decision.title:ja:Mexico': '金利決定',
  'interest_rate_decision.title:ja:Philippines': '金利決定',
  'interest_rate_decision.title:ja:New Zealand': '金利決定',
  'interest_rate_decision.title:ja:India': '金利決定',
  'interest_rate_decision.title:ja:South Africa': '金利決定',
  'interest_rate_decision.title:ja:Poland': '金利決定',
  'interest_rate_decision.title:ja:United Kingdom': '金利決定',
  'interest_rate_decision.title:ja:Thailand': '金利決定',
  'interest_rate_decision.title:ja:Canada': '金利決定',
  'interest_rate_decision.title:ja:Taiwan': '金利決定',
  'interest_rate_decision.title:ja:South Korea': '金利決定',
  'interest_rate_decision.title:ja:Indonesia': '金利決定',
  'interest_rate_decision.title:ja:Brazil': '金利決定',
  'interest_rate_decision.title:ja:Colombia': '金利決定',
  'interest_rate_decision.title:ja:Russia': '金利決定',
  'interest_rate_decision.title:ja:Israel': '金利決定',
  'interest_rate_decision.title:ja:Hungary': '金利決定',
  'hia_new_home_sales_mom.title:ja:DEFAULT': 'HIA新築住宅販売件数(前月比）',
  'hia_new_home_sales_mom.title:ja:Australia': 'HIA新築住宅販売件数(前月比）',
  'epiphany_holiday.title:ja:DEFAULT': '公現祭(エピファニー・ホリデー)',
  'epiphany_holiday.title:ja:Spain': '公現祭(エピファニー・ホリデー)',
  'bank_holiday_-_mid-year_accounts_closing.title:ja:DEFAULT': 'バンク・ホリデー - 年度中間決算',
  'bank_holiday_-_mid-year_accounts_closing.title:ja:India': 'バンク・ホリデー - 年度中間決算',
  'gdp_growth_rate_yoy_-_third_estimate.title:ja:DEFAULT': 'GDP成長率(前年比)-第3次予測値',
  'gdp_growth_rate_yoy_-_third_estimate.title:ja:Poland': 'GDP成長率(前年比)-第3次予測値',
  'st_wenceslas_day.title:ja:DEFAULT': '聖ウェンセスラスの日',
  'st_wenceslas_day.title:ja:Czech Republic': '聖ウェンセスラスの日',
  'initial_jobless_claims.title:ja:DEFAULT': '新規失業保険申請件数',
  'initial_jobless_claims.title:ja:United States': '新規失業保険申請件数',
  'gdp_growth_annualized-2_est.title:ja:DEFAULT': 'GDP年率成長率- 2次推定値',
  'gdp_growth_annualized-2_est.title:ja:Israel': 'GDP年率成長率- 2次推定値',
  'infrastructure_output_yoy.title:ja:DEFAULT': 'インフラストラクチャー生産(前年比)',
  'infrastructure_output_yoy.title:ja:India': 'インフラストラクチャー生産(前年比)',
  'inflation_report_hearings.title:ja:DEFAULT': 'インフレ報告公聴会',
  'inflation_report_hearings.title:ja:United Kingdom': 'インフレ報告公聴会',
  'boj_gov_kuroda_speech.title:ja:DEFAULT': '日銀黒田総裁講演',
  'boj_gov_kuroda_speech.title:ja:Japan': '日銀黒田総裁講演',
  'rba_financial_stability_review.title:ja:DEFAULT': 'RBA金融安定性レビュー',
  'rba_financial_stability_review.title:ja:Australia': 'RBA金融安定性レビュー',
  'industrial_production_mom.title:ja:DEFAULT': '工業生産(前月比)',
  'industrial_production_mom.title:ja:Mexico': '工業生産(前月比)',
  'nonfarm_productivity_qoq_prel.title:ja:DEFAULT': '非農業部門生産性(前四半期比)速報値',
  'nonfarm_productivity_qoq_prel.title:ja:United States': '非農業部門生産性(前四半期比)速報値',
  'inflation_rate_yoy.title:ja:Australia': 'インフレ率（前年比）',
  'inflation_rate_yoy.title:ja:Hong Kong': 'インフレ率（前年比）',
  'inflation_rate_yoy.title:ja:Germany': 'インフレ率（前年比）',
  'inflation_rate_yoy.title:ja:Poland': 'インフレ率（前年比）',
  'inflation_rate_yoy.title:ja:Finland': 'インフレ率（前年比）',
  'inflation_rate_yoy.title:ja:New Zealand': 'インフレ率（前年比）',
  'inflation_rate_yoy.title:ja:India': 'インフレ率（前年比）',
  'inflation_rate_yoy.title:ja:United Kingdom': 'インフレ率（前年比）',
  'inflation_rate_yoy.title:ja:Ireland': 'インフレ率（前年比）',
  'inflation_rate_yoy.title:ja:Italy': 'インフレ率（前年比）',
  'inflation_rate_yoy.title:ja:Netherlands': 'インフレ率（前年比）',
  'inflation_rate_yoy.title:ja:South Korea': 'インフレ率（前年比）',
  'inflation_rate_yoy.title:ja:Taiwan': 'インフレ率（前年比）',
  'inflation_rate_yoy.title:ja:Canada': 'インフレ率（前年比）',
  'inflation_rate_yoy.title:ja:Indonesia': 'インフレ率（前年比）',
  'inflation_rate_yoy.title:ja:South Africa': 'インフレ率（前年比）',
  'inflation_rate_yoy.title:ja:France': 'インフレ率（前年比）',
  'inflation_rate_yoy.title:ja:Belgium': 'インフレ率（前年比）',
  'inflation_rate_yoy.title:ja:United States': 'インフレ率（前年比）',
  'inflation_rate_yoy.title:ja:Singapore': 'インフレ率（前年比）',
  'inflation_rate_yoy.title:ja:Japan': 'インフレ率（前年比）',
  'inflation_rate_yoy.title:ja:Norway': 'インフレ率（前年比）',
  'inflation_rate_yoy.title:ja:Mexico': 'インフレ率（前年比）',
  'inflation_rate_yoy.title:ja:Euro Area': 'インフレ率（前年比）',
  'inflation_rate_yoy.title:ja:Philippines': 'インフレ率（前年比）',
  'inflation_rate_yoy.title:ja:Colombia': 'インフレ率（前年比）',
  'inflation_rate_yoy.title:ja:Brazil': 'インフレ率（前年比）',
  'inflation_rate_yoy.title:ja:Switzerland': 'インフレ率（前年比）',
  'inflation_rate_yoy.title:ja:Portugal': 'インフレ率（前年比）',
  'inflation_rate_yoy.title:ja:China': 'インフレ率（前年比）',
  'inflation_rate_yoy.title:ja:Spain': 'インフレ率（前年比）',
  'inflation_rate_yoy.title:ja:Russia': 'インフレ率（前年比）',
  'inflation_rate_yoy.title:ja:Czech Republic': 'インフレ率（前年比）',
  'inflation_rate_yoy.title:ja:Denmark': 'インフレ率（前年比）',
  'inflation_rate_yoy.title:ja:Israel': 'インフレ率（前年比）',
  'inflation_rate_yoy.title:ja:Hungary': 'インフレ率（前年比）',
  'inflation_rate_yoy.title:ja:Greece': 'インフレ率（前年比）',
  'inflation_rate_yoy.title:ja:Austria': 'インフレ率（前年比）',
  'inflation_rate_yoy.title:ja:Sweden': 'インフレ率（前年比）',
  'inflation_rate_mom.title:ja:DEFAULT': 'インフレ率(前月比)',
  'inflation_rate_mom.title:ja:Greece': 'インフレ率(前月比）',
  'inflation_rate_mom.title:ja:Italy': 'インフレ率(前月比）',
  'inflation_rate_mom.title:ja:Germany': 'インフレ率(前月比）',
  'inflation_rate_mom.title:ja:Poland': 'インフレ率(前月比）',
  'inflation_rate_mom.title:ja:Brazil': 'インフレ率(前月比）',
  'inflation_rate_mom.title:ja:South Korea': 'インフレ率(前月比）',
  'inflation_rate_mom.title:ja:Taiwan': 'インフレ率(前月比）',
  'inflation_rate_mom.title:ja:Canada': 'インフレ率(前月比）',
  'inflation_rate_mom.title:ja:Indonesia': 'インフレ率(前月比）',
  'inflation_rate_mom.title:ja:Euro Area': 'インフレ率(前月比）',
  'inflation_rate_mom.title:ja:Colombia': 'インフレ率(前月比）',
  'inflation_rate_mom.title:ja:Philippines': 'インフレ率(前月比）',
  'inflation_rate_mom.title:ja:Finland': 'インフレ率(前月比）',
  'inflation_rate_mom.title:ja:United States': 'インフレ率(前月比）',
  'inflation_rate_mom.title:ja:United Kingdom': 'インフレ率(前月比）',
  'inflation_rate_mom.title:ja:Ireland': 'インフレ率(前月比）',
  'inflation_rate_mom.title:ja:Switzerland': 'インフレ率(前月比）',
  'inflation_rate_mom.title:ja:Singapore': 'インフレ率(前月比）',
  'inflation_rate_mom.title:ja:Japan': 'インフレ率(前月比）',
  'inflation_rate_mom.title:ja:Norway': 'インフレ率(前月比）',
  'inflation_rate_mom.title:ja:Mexico': 'インフレ率(前月比）',
  'inflation_rate_mom.title:ja:South Africa': 'インフレ率(前月比）',
  'inflation_rate_mom.title:ja:Belgium': 'インフレ率(前月比）',
  'inflation_rate_mom.title:ja:France': 'インフレ率(前月比）',
  'inflation_rate_mom.title:ja:Spain': 'インフレ率(前月比）',
  'inflation_rate_mom.title:ja:Russia': 'インフレ率(前月比）',
  'inflation_rate_mom.title:ja:Czech Republic': 'インフレ率(前月比）',
  'inflation_rate_mom.title:ja:Israel': 'インフレ率(前月比）',
  'inflation_rate_mom.title:ja:Hungary': 'インフレ率(前月比）',
  'inflation_rate_mom.title:ja:Austria': 'インフレ率(前月比）',
  'inflation_rate_mom.title:ja:Sweden': 'インフレ率(前月比）',
  'inflation_rate_mom.title:ja:China': 'インフレ率(前月比）',
  'inflation_rate_mom.title:ja:Portugal': 'インフレ率(前月比）',
  'hari_raya_puasa.title:ja:DEFAULT': '断食明け大祭(ハリ・ラヤ・プアサ)',
  'hari_raya_puasa.title:ja:Singapore': '断食明け大祭(ハリ・ラヤ・プアサ)',
  'inflation_rate_ex-food_and_energy_yoy.title:ja:DEFAULT':
    '食料・エネルギーを除くインフレ率(前年比)',
  'inflation_rate_ex-food_and_energy_yoy.title:ja:Japan':
    '食料・エネルギーを除くインフレ率(前年比)',
  '2nd_christmas_day.title:ja:DEFAULT': 'クリスマス2日目',
  '2nd_christmas_day.title:ja:Denmark': 'クリスマス2日目',
  'industrial_profits_ytd_yoy.title:ja:DEFAULT': '工業利益(年初来)前年比',
  'industrial_profits_ytd_yoy.title:ja:China': '工業利益(年初来)前年比',
  'day_of_good_will.title:ja:DEFAULT': '親善の日',
  'day_of_good_will.title:ja:South Africa': '親善の日',
  'industrial_production_yoy_final.title:ja:DEFAULT': '工業生産(前年比)確報値',
  'industrial_production_yoy_final.title:ja:Japan': '工業生産(前年比)確報値',
  'industrial_production_mom.title:ja:Israel': '工業生産(前月比)',
  'industrial_production_mom_final.title:ja:DEFAULT': '工業生産(前年比)確報値',
  'industrial_production_mom_final.title:ja:Japan': '工業生産(前年比)確報値',
  'greek_parliament_elections.title:ja:DEFAULT': 'ギリシャ国会議員選挙',
  'greek_parliament_elections.title:ja:Greece': 'ギリシャ国会議員選挙',
  'spring_budget_2020.title:ja:DEFAULT': '2020年春季予算',
  'spring_budget_2020.title:ja:United Kingdom': '2020年春季予算',
  'industrial_production_mom.title:ja:Hungary': '工業生産(前月比)',
  'industrial_production_mom.title:ja:Finland': '工業生産(前月比)',
  'industrial_production_mom.title:ja:Poland': '工業生産(前月比)',
  'industrial_production_mom.title:ja:United States': '工業生産(前月比)',
  'industrial_production_mom.title:ja:Czech Republic': '工業生産(前月比)',
  'industrial_production_mom.title:ja:United Kingdom': '工業生産(前月比)',
  'industrial_production_mom.title:ja:Brazil': '工業生産(前月比)',
  'industrial_production_mom.title:ja:Portugal': '工業生産(前月比)',
  'industrial_production_mom.title:ja:Italy': '工業生産(前月比)',
  'industrial_production_mom.title:ja:South Korea': '工業生産(前月比)',
  'industrial_production_mom.title:ja:Netherlands': '工業生産(前月比)',
  'industrial_production_mom.title:ja:Singapore': '工業生産(前月比)',
  'industrial_production_mom.title:ja:Germany': '工業生産(前月比)',
  'industrial_production_mom.title:ja:Sweden': '工業生産(前月比)',
  'industrial_production_mom.title:ja:Denmark': '工業生産(前月比)',
  'industrial_production_mom.title:ja:Belgium': '工業生産(前月比)',
  'industrial_production_mom.title:ja:Euro Area': '工業生産(前月比)',
  'industrial_production_mom.title:ja:Norway': '工業生産(前月比)',
  'industrial_production_mom.title:ja:Japan': '工業生産(前月比)',
  'industrial_production_mom.title:ja:France': '工業生産(前月比)',
  'euro_finance_week.title:ja:DEFAULT': 'ユーロ・ファイナンス・ウィーク',
  'euro_finance_week.title:ja:Germany': 'ユーロ・ファイナンス・ウィーク',
  'st_joseph_s_day.title:ja:Colombia': '聖ヨセフの日',
  'kagiso_manufacturing_pmi.title:ja:DEFAULT': 'KAGISO製造業購買担当者景気指数（PMI）',
  'kagiso_manufacturing_pmi.title:ja:South Africa': 'KAGISO製造業購買担当者景気指数（PMI）',
  'industrial_production.title:ja:DEFAULT': '工業生産',
  'industrial_production.title:ja:Russia': '工業生産',
  'industrial_production.title:ja:Portugal': '工業生産',
  'industrial_production.title:ja:Singapore': '工業生産',
  'industrial_production.title:ja:Taiwan': '工業生産',
  'industrial_production.title:ja:Thailand': '工業生産',
  'industrial_production.title:ja:South Korea': '工業生産',
  'industrial_product_price_yoy.title:ja:DEFAULT': '工業製品価格(前年比）',
  'industrial_product_price_yoy.title:ja:Canada': '工業製品価格(前年比）',
  'lmi_logistics_managers_index_current.title:ja:DEFAULT': 'LMI物流担当者指数(現在)',
  'lmi_logistics_managers_index_current.title:ja:United States': 'LMI物流担当者指数(現在)',
  'industrial_product_price_mom.title:ja:DEFAULT': '工業製品価格(前月比）',
  'industrial_product_price_mom.title:ja:Canada': '工業製品価格(前月比）',
  'industrial_output_mom.title:ja:DEFAULT': '工業生産(前月比)',
  'industrial_output_mom.title:ja:Brazil': '工業生産(前月比)',
  'industrial_orders_mom.title:ja:DEFAULT': '工業受注 (前月比)',
  'industrial_orders_mom.title:ja:Italy': '工業受注 (前月比)',
  'industrial_confidence.title:ja:DEFAULT': '工業信頼感',
  'industrial_confidence.title:ja:Euro Area': '工業信頼感',
  'industrial_confidence.title:ja:Norway': '工業信頼感',
  'gdp_growth_rate_qoq_2st_est.title:ja:DEFAULT': 'GDP成長率(前四半期比)第二次推定値',
  'gdp_growth_rate_qoq_2st_est.title:ja:Israel': 'GDP成長率(前四半期比)第二次推定値',
  'indonesian_independence_day.title:ja:DEFAULT': 'インドネシア独立記念日',
  'indonesian_independence_day.title:ja:Indonesia': 'インドネシア独立記念日',
  'indonesia_hsbc_pmi.title:ja:DEFAULT': 'インドネシアHSBC購買担当者景気指数（PMI)',
  'indonesia_hsbc_pmi.title:ja:Indonesia': 'インドネシアHSBC購買担当者景気指数（PMI)',
  'india_general_election_voting_starts.title:ja:DEFAULT': 'インド総選挙の投票開始',
  'india_general_election_voting_starts.title:ja:India': 'インド総選挙の投票開始',
  'ism_non-manufacturing_employment.title:ja:DEFAULT': 'ISM非製造業雇用',
  'ism_non-manufacturing_employment.title:ja:United States': 'ISM非製造業雇用',
  'gdp_growth_rate_yoy_-_adv.title:ja:DEFAULT': 'GDP成長率(前年比) - 速報値',
  'gdp_growth_rate_yoy_-_adv.title:ja:South Korea': 'GDP成長率(前年比) - 速報値',
  'gdp_growth_rate_yoy_-_adv.title:ja:Singapore': 'GDP成長率(前年比) - 速報値',
  'tankan_small_non-manufacturing_outlook.title:ja:DEFAULT': '日銀短観非製造小企業見通し',
  'tankan_small_non-manufacturing_outlook.title:ja:Japan': '日銀短観非製造小企業見通し',
  'fed_kashkari_speech.title:ja:DEFAULT': 'FRBカシュカリ氏講演',
  'fed_kashkari_speech.title:ja:United States': 'FRBカシュカリ氏講演',
  'non-oil_exports_mom.title:ja:DEFAULT': '非石油輸出(前月比)',
  'non-oil_exports_mom.title:ja:Singapore': '非石油輸出(前月比)',
  'india_general_election_voting_ends.title:ja:DEFAULT': 'インド総選挙の投票終了',
  'india_general_election_voting_ends.title:ja:India': 'インド総選挙の投票終了',
  'independent_czechoslovak_state_day.title:ja:DEFAULT': 'チェコ共和国独立記念日',
  'independent_czechoslovak_state_day.title:ja:Czech Republic': 'チェコ共和国独立記念日',
  'midterm_elections.title:ja:DEFAULT': '中間選挙',
  'midterm_elections.title:ja:United States': '中間選挙',
  'snb_president_studer_speech.title:ja:DEFAULT': 'スイス国立銀行スチューダー総裁講演',
  'snb_president_studer_speech.title:ja:Switzerland': 'スイス国立銀行スチューダー総裁講演',
  'independence_of_cartagena_day.title:ja:DEFAULT': 'カルタヘナ独立記念日',
  'independence_of_cartagena_day.title:ja:Colombia': 'カルタヘナ独立記念日',
  'gdp_price_index_qoq.title:ja:DEFAULT': 'GDP物価指数(前四半期比)',
  'gdp_price_index_qoq.title:ja:United States': 'GDP物価指数(前四半期比)',
  'employment_change_yoy.title:ja:DEFAULT': '雇用変化(前年比）',
  'employment_change_yoy.title:ja:Euro Area': '雇用変化(前年比）',
  'independence_of_cartagena.title:ja:DEFAULT': 'カルタヘナ独立記念日',
  'independence_of_cartagena.title:ja:Colombia': 'カルタヘナ独立記念日',
  'independence_day.title:ja:DEFAULT': '独立記念日',
  'independence_day.title:ja:Greece': '独立記念日',
  'independence_day.title:ja:Poland': '独立記念日',
  'independence_day.title:ja:India': '独立記念日',
  'independence_day.title:ja:Israel': '独立記念日',
  'independence_day.title:ja:United States': '独立記念日',
  'independence_day.title:ja:Finland': '独立記念日',
  'independence_day.title:ja:Philippines': '独立記念日',
  'independence_day.title:ja:Brazil': '独立記念日',
  'independence_day.title:ja:Colombia': '独立記念日',
  'independence_day.title:ja:Indonesia': '独立記念日',
  'independence_day.title:ja:Mexico': '独立記念日',
  'independence_day.title:ja:Czech Republic': '独立記念日',
  '30-year_bund_auction.title:ja:DEFAULT': '30年物国債入札',
  '30-year_bund_auction.title:ja:Germany': '30年物国債入札',
  'riksbank_skingsley_speech.title:ja:DEFAULT':
    'リクスバンク(スウェーデン中央銀行)のスキングスレイ氏講演',
  'riksbank_skingsley_speech.title:ja:Sweden':
    'リクスバンク(スウェーデン中央銀行)のスキングスレイ氏講演',
  'imports_yoy.title:ja:DEFAULT': '輸入(前年比）',
  'imports_yoy.title:ja:Taiwan': '輸入(前年比）',
  'imports_yoy.title:ja:Philippines': '輸入(前年比）',
  'imports_yoy.title:ja:Australia': '輸入(前年比）',
  'imports_yoy.title:ja:Hong Kong': '輸入(前年比）',
  'imports_yoy.title:ja:Japan': '輸入(前年比）',
  'imports_yoy.title:ja:Colombia': '輸入(前年比）',
  'imports_yoy.title:ja:South Korea': '輸入(前年比）',
  'imports_yoy.title:ja:China': '輸入(前年比）',
  'imports_yoy.title:ja:Indonesia': '輸入(前年比）',
  'imports_yoy.title:ja:Thailand': '輸入(前年比）',
  'ecb_monthly_report.title:ja:DEFAULT': 'ECB月次報告',
  'ecb_monthly_report.title:ja:Euro Area': 'ECB月次報告',
  'imports_prel.title:ja:DEFAULT': '輸入(速報値）',
  'imports_prel.title:ja:India': '輸入(速報値）',
  'aig_performance_of_services_index.title:ja:DEFAULT': 'AiGサービス業景況指数',
  'aig_performance_of_services_index.title:ja:Australia': 'AiGサービス業景況指数',
  'imports_mom_s_a.title:ja:DEFAULT': '輸入(前月比）季節調整済み',
  'imports_mom_s_a.title:ja:Germany': '輸入(前月比）季節調整済み',
  'eid_al-adha.title:ja:DEFAULT': '犠牲祭(イスラム教)',
  'eid_al-adha.title:ja:Singapore': '犠牲祭(イスラム教)',
  'eid_al-adha.title:ja:Philippines': '犠牲祭(イスラム教)',
  'eid_al-adha.title:ja:Indonesia': '犠牲祭(イスラム教)',
  'imports_mom.title:ja:DEFAULT': '輸入(前月比）',
  'imports_mom.title:ja:Australia': '輸入(前月比）',
  'imports_final.title:ja:DEFAULT': '輸入(確報値)',
  'imports_final.title:ja:India': '輸入(確報値)',
  'medium-term_budget_policy_statement.title:ja:DEFAULT': '中期予算方針声明',
  'medium-term_budget_policy_statement.title:ja:South Africa': '中期予算方針声明',
  'gdp_annual_growth_rate_yoy_final.title:ja:DEFAULT': 'GDP年間成長率前年比 (確報値)',
  'gdp_annual_growth_rate_yoy_final.title:ja:Japan': 'GDP年間成長率前年比 (確報値)',
  'import_prices_yoy.title:ja:DEFAULT': '輸入価格(前年比）',
  'import_prices_yoy.title:ja:Singapore': '輸入価格(前年比）',
  'import_prices_yoy.title:ja:Germany': '輸入価格(前年比）',
  'import_prices_yoy.title:ja:Finland': '輸入価格(前年比）',
  'import_prices_yoy.title:ja:United States': '輸入価格(前年比）',
  'import_prices_yoy.title:ja:South Korea': '輸入価格(前年比）',
  'import_prices.title:ja:DEFAULT': '輸入価格',
  'import_prices.title:ja:Finland': '輸入価格',
  'import_price_index_qoq.title:ja:DEFAULT': '輸入価格指数(前四半期比)',
  'import_price_index_qoq.title:ja:Australia': '輸入価格指数(前四半期比)',
  'parliamentary_debate_vote_on_brexit.title:ja:DEFAULT':
    'ブレグジット(イギリスのEU離脱)に関する議会討論と投票',
  'parliamentary_debate_vote_on_brexit.title:ja:European Union':
    'ブレグジット(イギリスのEU離脱)に関する議会討論と投票',
  'harmonised_inflation_rate_yoy.title:ja:DEFAULT': '調和インフレ率(前年比)',
  'harmonised_inflation_rate_yoy.title:ja:Germany': '調和インフレ率(前年比)',
  'harmonised_inflation_rate_yoy.title:ja:Denmark': '調和インフレ率(前年比)',
  'harmonised_inflation_rate_yoy.title:ja:Greece': '調和インフレ率(前年比)',
  'harmonised_inflation_rate_yoy.title:ja:France': '調和インフレ率(前年比)',
  'harmonised_inflation_rate_yoy.title:ja:Ireland': '調和インフレ率(前年比)',
  'harmonised_inflation_rate_yoy.title:ja:Austria': '調和インフレ率(前年比)',
  'gdp_growth_rate_qoq_2nd_est.title:ja:Portugal': 'GDP成長率(前四半期比)第二次推計値',
  'gdp_growth_rate_qoq_2nd_est.title:ja:France': 'GDP成長率(前四半期比)第二次推計値',
  'gdp_growth_rate_qoq_2nd_est.title:ja:Euro Area': 'GDP成長率(前四半期比)第二次推計値',
  'gdp_growth_rate_qoq_2nd_est.title:ja:Belgium': 'GDP成長率(前四半期比)第二次推計値',
  'gdp_growth_rate_qoq_2nd_est.title:ja:United States': 'GDP成長率(前四半期比)第二次推計値',
  'gdp_growth_rate_qoq_2nd_est.title:ja:Hungary': 'GDP成長率(前四半期比)第二次推計値',
  'gdp_growth_rate_qoq_2nd_est.title:ja:United Kingdom': 'GDP成長率(前四半期比)第二次推計値',
  'gdp_growth_rate_qoq_2nd_est.title:ja:Czech Republic': 'GDP成長率(前四半期比)第二次推計値',
  'gdp_growth_rate_qoq_2nd_est.title:ja:Denmark': 'GDP成長率(前四半期比)第二次推計値',
  'gdp_growth_rate_qoq_2nd_est.title:ja:Netherlands': 'GDP成長率(前四半期比)第二次推計値',
  'immaculate_conception_of_mary.title:ja:DEFAULT': '無原罪の聖マリア懐胎の祭日',
  'immaculate_conception_of_mary.title:ja:Philippines': '無原罪の聖マリア懐胎の祭日',
  'imaculate_conception_day.title:ja:DEFAULT': '無原罪の聖マリア懐胎の祭日',
  'imaculate_conception_day.title:ja:Colombia': '無原罪の聖マリア懐胎の祭日',
  'monetary_policy_announcement.title:ja:DEFAULT': '金融政策発表',
  'monetary_policy_announcement.title:ja:Australia': '金融政策発表',
  'ifo_expectations_prel.title:ja:DEFAULT': 'Ifo企業景況感(速報値）',
  'ifo_expectations_prel.title:ja:Germany': 'Ifo企業景況感(速報値）',
  'construction_spending_mom.title:ja:DEFAULT': '建設支出(前月比）',
  'construction_spending_mom.title:ja:United States': '建設支出(前月比）',
  'gdp_growth_rate_yoy_second_est.title:ja:DEFAULT': 'GDP成長率(前年比)第二次推計',
  'gdp_growth_rate_yoy_second_est.title:ja:United States': 'GDP成長率(前年比)第二次推計',
  'ifo_expectations.title:ja:DEFAULT': 'Ifo企業期待指数',
  'ifo_expectations.title:ja:Germany': 'Ifo企業期待指数',
  'leading_business_cycle_indicator_mom.title:ja:DEFAULT': '先行景気循環指数(前月比)',
  'leading_business_cycle_indicator_mom.title:ja:South Africa': '先行景気循環指数(前月比)',
  'employment_cost_index.title:ja:DEFAULT': '雇用コスト指数',
  'employment_cost_index.title:ja:United States': '雇用コスト指数',
  'new_year_s_eve.title:ja:Switzerland': '大晦日',
  'ifo_current_conditions_final.title:ja:DEFAULT': 'Ifo現況指数(確報値)',
  'ifo_current_conditions_final.title:ja:Germany': 'Ifo現況指数(確報値)',
  'snb_zurbrugg_speech.title:ja:DEFAULT': 'スイス国立銀行ツアブリュック氏講演',
  'snb_zurbrugg_speech.title:ja:Switzerland': 'スイス国立銀行ツアブリュック氏講演',
  'ifo_business_climate.title:ja:DEFAULT': 'Ifo企業景況感指数',
  'ifo_business_climate.title:ja:Germany': 'Ifo企業景況感指数',
  'idul_juha.title:ja:DEFAULT': '巡礼祭／犠牲祭(イスラム教)',
  'idul_juha.title:ja:India': '巡礼祭／犠牲祭(イスラム教)',
  'mid_year_day.title:ja:DEFAULT': 'ミッドイヤー・デイ',
  'mid_year_day.title:ja:Thailand': 'ミッドイヤー・デイ',
  'ism_prices_paid.title:ja:DEFAULT': '米供給管理協会 (ISM) 仕入価格',
  'ism_prices_paid.title:ja:United States': '米供給管理協会 (ISM) 仕入価格',
  'markit_manufacturing_pmi.title:ja:Euro Area': 'マークイット製造業購買担当者指数 (PMI)',
  'markit_manufacturing_pmi.title:ja:Germany': 'マークイット製造業購買担当者指数 (PMI)',
  'markit_manufacturing_pmi.title:ja:Russia': 'マークイット製造業購買担当者指数 (PMI)',
  'markit_manufacturing_pmi.title:ja:Brazil': 'マークイット製造業購買担当者指数 (PMI)',
  'markit_manufacturing_pmi.title:ja:India': 'マークイット製造業購買担当者指数 (PMI)',
  'markit_manufacturing_pmi.title:ja:Poland': 'マークイット製造業購買担当者指数 (PMI)',
  'markit_manufacturing_pmi.title:ja:Hong Kong': 'マークイット製造業購買担当者指数 (PMI)',
  'markit_manufacturing_pmi.title:ja:Greece': 'マークイット製造業購買担当者指数 (PMI)',
  'markit_manufacturing_pmi.title:ja:United States': 'マークイット製造業購買担当者指数 (PMI)',
  'markit_manufacturing_pmi.title:ja:United Kingdom': 'マークイット製造業購買担当者指数 (PMI)',
  'markit_manufacturing_pmi.title:ja:Thailand': 'マークイット製造業購買担当者指数 (PMI)',
  'markit_manufacturing_pmi.title:ja:Czech Republic': 'マークイット製造業購買担当者指数 (PMI)',
  'markit_manufacturing_pmi.title:ja:France': 'マークイット製造業購買担当者指数 (PMI)',
  'markit_manufacturing_pmi.title:ja:Mexico': 'マークイット製造業購買担当者指数 (PMI)',
  'markit_manufacturing_pmi.title:ja:Indonesia': 'マークイット製造業購買担当者指数 (PMI)',
  'markit_manufacturing_pmi.title:ja:Canada': 'マークイット製造業購買担当者指数 (PMI)',
  'markit_manufacturing_pmi.title:ja:South Korea': 'マークイット製造業購買担当者指数 (PMI)',
  'markit_manufacturing_pmi.title:ja:Philippines': 'マークイット製造業購買担当者指数 (PMI)',
  'ism_non-manufacturing_prices.title:ja:DEFAULT': '米供給管理協会（ISM) 非製造業価格',
  'ism_non-manufacturing_prices.title:ja:United States': '米供給管理協会（ISM) 非製造業価格',
  'sukkot_i.title:ja:DEFAULT': '仮庵の祭り(ユダヤ教)',
  'sukkot_i.title:ja:Israel': '仮庵の祭り(ユダヤ教)',
  'victoria_day.title:ja:DEFAULT': 'ビクトリアデー(ビクトリア元女王の誕生日)',
  'victoria_day.title:ja:Canada': 'ビクトリアデー(ビクトリア元女王の誕生日)',
  'ism_non-manufacturing_new_orders.title:ja:DEFAULT': '米供給管理協会 (ISM) 非製造業新規受注',
  'ism_non-manufacturing_new_orders.title:ja:United States':
    '米供給管理協会 (ISM) 非製造業新規受注',
  'boe_report_on_eu_withdrawal_scenarios.title:ja:DEFAULT':
    'EU離脱のシナリオに関するイングランド銀行報告書',
  'boe_report_on_eu_withdrawal_scenarios.title:ja:United Kingdom':
    'EU離脱のシナリオに関するイングランド銀行報告書',
  'saints_cyril_and_methodius.title:ja:DEFAULT': '聖人シリルとメトディウス',
  'saints_cyril_and_methodius.title:ja:Czech Republic': '聖人シリルとメトディウス',
  'referendum_on_bailout_terms.title:ja:DEFAULT': '金融支援条件に関する国民投票',
  'referendum_on_bailout_terms.title:ja:Greece': '金融支援条件に関する国民投票',
  'finance_ministers_conference_call.title:ja:DEFAULT': '財務相電話会議',
  'finance_ministers_conference_call.title:ja:Euro Area': '財務相電話会議',
  'ism_manufacturing_prices.title:ja:DEFAULT': '米供給管理協会 (ISM) 製造業価格指数',
  'ism_manufacturing_prices.title:ja:United States': '米供給管理協会 (ISM) 製造業価格指数',
  'ism_manufacturing_employment.title:ja:DEFAULT': '米供給管理協会 (ISM) 製造業雇用指数',
  'ism_manufacturing_employment.title:ja:United States': '米供給管理協会 (ISM) 製造業雇用指数',
  'g7_video_conference_meeting.title:ja:DEFAULT': 'G7ビデオ会議',
  'g7_video_conference_meeting.title:ja:United States': 'G7ビデオ会議',
  'ipc-fipe_inflation_mom.title:ja:DEFAULT': 'IPC-Fipeインフレ率(前月比)',
  'ipc-fipe_inflation_mom.title:ja:Brazil': 'IPC-Fipeインフレ率(前月比)',
  'imf_world_bank_virtual_annual_meeting.title:ja:DEFAULT':
    '国際通貨基金 (IMF)/世界銀行バーチャル年次総会',
  'imf_world_bank_virtual_annual_meeting.title:ja:United States':
    '国際通貨基金 (IMF)/世界銀行バーチャル年次総会',
  'fed_yellen_testimony_to_the_senate.title:ja:DEFAULT': 'FRBイエレン財務長官の上院証言',
  'fed_yellen_testimony_to_the_senate.title:ja:United States': 'FRBイエレン財務長官の上院証言',
  'consumer_price_index_ex_food_energy_yoy.title:ja:DEFAULT':
    '食品とエネルギーを除く消費者物価指数(前年比）',
  'consumer_price_index_ex_food_energy_yoy.title:ja:United States':
    '食品とエネルギーを除く消費者物価指数(前年比）',
  'imf_world_economic_outlook.title:ja:DEFAULT': '国際通貨基金 (IMF) 世界経済見通し',
  'imf_world_economic_outlook.title:ja:United States': '国際通貨基金 (IMF) 世界経済見通し',
  'national_foundation_day.title:ja:DEFAULT': '建国記念日',
  'national_foundation_day.title:ja:South Korea': '建国記念日',
  'national_foundation_day.title:ja:Japan': '建国記念日',
  'imf_spring_meeting.title:ja:DEFAULT': '国際通貨基金 (IMF) 春季会合',
  'imf_spring_meeting.title:ja:United States': '国際通貨基金 (IMF) 春季会合',
  'imf_loan_repayment.title:ja:DEFAULT': 'IMFローン返済',
  'imf_loan_repayment.title:ja:Greece': 'IMFローン返済',
  'michigan_current_conditions_prel.title:ja:DEFAULT': 'ミシガン大学現況指数(速報値)',
  'michigan_current_conditions_prel.title:ja:United States': 'ミシガン大学現況指数(速報値)',
  'iif_spring_membership_meeting_2016.title:ja:DEFAULT': '国際金融協会（IIF）2016年春季総会',
  'iif_spring_membership_meeting_2016.title:ja:Spain': '国際金融協会（IIF）2016年春季総会',
  'construction_output_mom.title:ja:DEFAULT': '建設部門生産高（前月比）',
  'construction_output_mom.title:ja:Euro Area': '建設部門生産高（前月比）',
  'ihs_markit_pmi.title:ja:DEFAULT': 'IHSマークイット購買担当者景気指数（PMI）',
  'ihs_markit_pmi.title:ja:South Africa': 'IHSマークイット購買担当者景気指数（PMI）',
  'ihs_markit_composite_pmi.title:ja:DEFAULT': 'IHSマークイット総合購買担当者景気指数（PMI）',
  'ihs_markit_composite_pmi.title:ja:Italy': 'IHSマークイット総合購買担当者景気指数（PMI）',
  '2020_spending_review.title:ja:DEFAULT': '2020年歳出見直し',
  '2020_spending_review.title:ja:United Kingdom': '2020年歳出見直し',
  'average_hourly_earnings_yoy.title:ja:DEFAULT': '平均時給（前年比）',
  'average_hourly_earnings_yoy.title:ja:United States': '平均時給（前年比）',
  'iea_oil_market_report.title:ja:DEFAULT': '国際エネルギー機関（IEA）原油市場報告',
  'iea_oil_market_report.title:ja:France': '国際エネルギー機関（IEA）原油市場報告',
  'iea_oil_market_report.title:ja:United States': '国際エネルギー機関（IEA）原油市場報告',
  'interest_rate.title:ja:DEFAULT': '金利',
  'interest_rate.title:ja:Israel': '金利',
  'interest_rate.title:ja:Thailand': '金利',
  'interest_rate.title:ja:South Korea': '金利',
  'interest_rate.title:ja:Philippines': '金利',
  'interest_rate.title:ja:South Africa': '金利',
  'hungary_national_day.title:ja:DEFAULT': 'ハンガリー建国記念日',
  'hungary_national_day.title:ja:Hungary': 'ハンガリー建国記念日',
  'new_yuan_loans.title:ja:DEFAULT': '新規人民元建て融資',
  'new_yuan_loans.title:ja:China': '新規人民元建て融資',
  'fomc_economic_projections.title:ja:DEFAULT': '米連邦公開市場委員会（FOMC）経済見通し',
  'fomc_economic_projections.title:ja:United States': '米連邦公開市場委員会（FOMC）経済見通し',
  'housing_starts_yoy.title:ja:DEFAULT': '住宅着工戸数（前年比）',
  'housing_starts_yoy.title:ja:Canada': '住宅着工戸数（前年比）',
  'housing_starts_yoy.title:ja:Japan': '住宅着工戸数（前年比）',
  'housing_starts_mom.title:ja:DEFAULT': '住宅着工戸数（前月比）',
  'housing_starts_mom.title:ja:United States': '住宅着工戸数（前月比）',
  'household_spending_yoy.title:ja:DEFAULT': '家計支出（前年比）',
  'household_spending_yoy.title:ja:Japan': '家計支出（前年比）',
  'household_spending_mom.title:ja:DEFAULT': '家計支出（前月比）',
  'household_spending_mom.title:ja:Japan': '家計支出（前月比）',
  'household_saving_ratio.title:ja:DEFAULT': '家計貯蓄率',
  'household_saving_ratio.title:ja:Ireland': '家計貯蓄率',
  'leading_economic_index.title:ja:DEFAULT': '景気先行指数',
  'leading_economic_index.title:ja:Thailand': '景気先行指数',
  'leading_economic_index.title:ja:Japan': '景気先行指数',
  'leading_economic_index.title:ja:Israel': '景気先行指数',
  'unemployment_rate-prel.title:ja:DEFAULT': '失業率（速報値）',
  'unemployment_rate-prel.title:ja:Singapore': '失業率（速報値）',
  'household_lending_growth_yoy.title:ja:DEFAULT': '家計貸出伸び率（前年比）',
  'household_lending_growth_yoy.title:ja:Sweden': '家計貸出伸び率（前年比）',
  'household_consumption_mm.title:ja:DEFAULT': '家計支出（前月比）',
  'household_consumption_mm.title:ja:Sweden': '家計支出（前月比）',
  'house_price_index_yoy.title:ja:DEFAULT': '住宅価格指数（前年比）',
  'house_price_index_yoy.title:ja:Norway': '住宅価格指数（前年比）',
  'house_price_index_yoy.title:ja:China': '住宅価格指数（前年比）',
  'house_price_index_yoy.title:ja:United States': '住宅価格指数（前年比）',
  'house_price_index_yoy.title:ja:Australia': '住宅価格指数（前年比）',
  'house_price_index_yoy.title:ja:United Kingdom': '住宅価格指数（前年比）',
  'ecb_president_lagarde_speech.title:ja:DEFAULT': 'ECBラガルド総裁演説',
  'ecb_president_lagarde_speech.title:ja:Euro Area': 'ECBラガルド総裁演説',
  'gdp_growth_rate_qoq_prel.title:ja:Netherlands': 'GDP成長率（前四半期比）速報',
  'gdp_growth_rate_qoq_prel.title:ja:Germany': 'GDP成長率（前四半期比）速報',
  'gdp_growth_rate_qoq_prel.title:ja:Italy': 'GDP成長率（前四半期比）速報',
  'gdp_growth_rate_qoq_prel.title:ja:Spain': 'GDP成長率（前四半期比）速報',
  'gdp_growth_rate_qoq_prel.title:ja:Norway': 'GDP成長率（前四半期比）速報',
  'gdp_growth_rate_qoq_prel.title:ja:South Korea': 'GDP成長率（前四半期比）速報',
  'gdp_growth_rate_qoq_prel.title:ja:Greece': 'GDP成長率（前四半期比）速報',
  'gdp_growth_rate_qoq_prel.title:ja:Hungary': 'GDP成長率（前四半期比）速報',
  'gdp_growth_rate_qoq_prel.title:ja:Denmark': 'GDP成長率（前四半期比）速報',
  'gdp_growth_rate_qoq_prel.title:ja:Portugal': 'GDP成長率（前四半期比）速報',
  'gdp_growth_rate_qoq_prel.title:ja:United Kingdom': 'GDP成長率（前四半期比）速報',
  'gdp_growth_rate_qoq_prel.title:ja:Czech Republic': 'GDP成長率（前四半期比）速報',
  'gdp_growth_rate_qoq_prel.title:ja:Poland': 'GDP成長率（前四半期比）速報',
  'gdp_growth_rate_qoq_prel.title:ja:Finland': 'GDP成長率（前四半期比）速報',
  'gdp_growth_rate_qoq_prel.title:ja:Mexico': 'GDP成長率（前四半期比）速報',
  'gdp_growth_rate_qoq_prel.title:ja:France': 'GDP成長率（前四半期比）速報',
  'consumer_price_index_eu_norm_yoy.title:ja:DEFAULT': '消費者物価指数（EU基準）（前年比）',
  'consumer_price_index_eu_norm_yoy.title:ja:Italy': '消費者物価指数（EU基準）（前年比）',
  'hong_kong_special_administrative_region_establishment_day.title:ja:DEFAULT':
    '香港特別行政府設立記念日',
  'hong_kong_special_administrative_region_establishment_day.title:ja:Hong Kong':
    '香港特別行政府設立記念日',
  'hometrack_housing_prices_s_a.title:ja:DEFAULT': 'ホームトラック住宅価格指数（季節調整後）',
  'hometrack_housing_prices_s_a.title:ja:United Kingdom':
    'ホームトラック住宅価格指数（季節調整後）',
  'all_car_sales.title:ja:DEFAULT': '全自動車販売台数',
  'all_car_sales.title:ja:United States': '全自動車販売台数',
  'freedom_day.title:ja:DEFAULT': '自由の日',
  'freedom_day.title:ja:South Africa': '自由の日',
  'rosh_hashanah.title:ja:DEFAULT': 'ロシュ・ハシャナ',
  'rosh_hashanah.title:ja:Israel': 'ロシュ・ハシャナ',
  'finance_minister_guindos_speech.title:ja:DEFAULT': '仏デギンドス財務相講演',
  'finance_minister_guindos_speech.title:ja:Spain': '仏デギンドス財務相講演',
  'eu_tusk_juncker_speak_at_european_parliament.title:ja:DEFAULT':
    'EUトゥスク大統領とユンケル委員長の欧州議会演説',
  'eu_tusk_juncker_speak_at_european_parliament.title:ja:Euro Area':
    'EUトゥスク大統領とユンケル委員長の欧州議会演説',
  'holy_spirit_monday.title:ja:DEFAULT': '聖霊月曜日',
  'holy_spirit_monday.title:ja:Greece': '聖霊月曜日',
  'tokyo_cpi_ex_fresh_food_yoy.title:ja:DEFAULT':
    '生鮮食品を除く東京都区部消費者物価指数（CPI）（前年比）',
  'tokyo_cpi_ex_fresh_food_yoy.title:ja:Japan':
    '生鮮食品を除く東京都区部消費者物価指数（CPI）（前年比）',
  'greenery_day.title:ja:DEFAULT': 'みどりの日',
  'greenery_day.title:ja:Japan': 'みどりの日',
  'pancasila_day.title:ja:DEFAULT': 'パンチャシラの日',
  'pancasila_day.title:ja:Indonesia': 'パンチャシラの日',
  'holy_saturday.title:ja:DEFAULT': '聖土曜日',
  'holy_saturday.title:ja:Philippines': '聖土曜日',
  'holi_feast.title:ja:DEFAULT': 'ホーリー祭',
  'holi_feast.title:ja:India': 'ホーリー祭',
  'hindu_day_of_silence_1935.title:ja:DEFAULT': 'ヒンドゥー教「静寂の日」1935年',
  'hindu_day_of_silence_1935.title:ja:Indonesia': 'ヒンドゥー教「静寂の日」1935年',
  'heritage_day.title:ja:DEFAULT': '伝統文化継承の日',
  'heritage_day.title:ja:South Africa': '伝統文化継承の日',
  'general_elections_2nd_round.title:ja:DEFAULT': '総選挙 - 第2回投票',
  'general_elections_2nd_round.title:ja:Brazil': '総選挙 - 第2回投票',
  'cbi_industrial_trends_orders.title:ja:DEFAULT': '英国産業連盟（CBI）産業動向受注',
  'cbi_industrial_trends_orders.title:ja:United Kingdom': '英国産業連盟（CBI）産業動向受注',
  'health_and_sports_day.title:ja:DEFAULT': 'スポーツの日',
  'health_and_sports_day.title:ja:Japan': 'スポーツの日',
  'ecb_lautenschl_ger_speech.title:ja:DEFAULT': 'ECBラウテンシュレーガー専務理事講演',
  'ecb_lautenschl_ger_speech.title:ja:Euro Area': 'ECBラウテンシュレーガー専務理事講演',
  'ipca_mid-month_cpi_mom.title:ja:DEFAULT': '月央拡大消費者物価指数（IPCA）（前月比）',
  'ipca_mid-month_cpi_mom.title:ja:Brazil': '月央拡大消費者物価指数（IPCA）（前月比）',
  'harvest_festival_day_3.title:ja:DEFAULT': '収穫祭3日目',
  'harvest_festival_day_3.title:ja:South Korea': '収穫祭3日目',
  'harvest_festival_day_1.title:ja:DEFAULT': '収穫祭1日目',
  'harvest_festival_day_1.title:ja:South Korea': '収穫祭1日目',
  'harmonized_inflation_rate_yoy.title:ja:DEFAULT': '調和インフレ率（前年比）',
  'harmonized_inflation_rate_yoy.title:ja:Greece': '調和インフレ率（前年比）',
  'brazil_investment_forum_2017.title:ja:DEFAULT': '2017年ブラジル投資フォーラム',
  'brazil_investment_forum_2017.title:ja:Brazil': '2017年ブラジル投資フォーラム',
  'ecb_macroeconomic_projections.title:ja:DEFAULT': 'ECBマクロ経済予測',
  'ecb_macroeconomic_projections.title:ja:Euro Area': 'ECBマクロ経済予測',
  'harmonised_inflation_rate_yoy_final.title:ja:DEFAULT': '調和インフレ率（前年比）（確報値）',
  'harmonised_inflation_rate_yoy_final.title:ja:Italy': '調和インフレ率（前年比）（確報値）',
  'harmonised_inflation_rate_yoy_final.title:ja:France': '調和インフレ率（前年比）（確報値）',
  'harmonised_inflation_rate_yoy_final.title:ja:Spain': '調和インフレ率（前年比）（確報値）',
  'harmonised_inflation_rate_yoy_final.title:ja:Germany': '調和インフレ率（前年比）（確報値）',
  '3-year_note_auction.title:ja:DEFAULT': '3年国債入札',
  '3-year_note_auction.title:ja:United States': '3年国債入札',
  'gdp_capital_expenditure_qoq.title:ja:DEFAULT': 'GDP設備投資（前四半期比）',
  'gdp_capital_expenditure_qoq.title:ja:Australia': 'GDP設備投資（前四半期比）',
  'gdp_capital_expenditure_qoq.title:ja:Japan': 'GDP設備投資（前四半期比）',
  'gurunanak_jayanti.title:ja:DEFAULT': 'グルナーナク・ジャヤンティ',
  'gurunanak_jayanti.title:ja:India': 'グルナーナク・ジャヤンティ',
  'european_commission_recovery_fund_proposal.title:ja:DEFAULT': '欧州委員会復興基金案',
  'european_commission_recovery_fund_proposal.title:ja:European Union': '欧州委員会復興基金案',
  'boc_press_conference.title:ja:DEFAULT': 'カナダ銀行記者会見',
  'boc_press_conference.title:ja:Canada': 'カナダ銀行記者会見',
  'harmonised_inflation_rate_mom_prel.title:ja:DEFAULT': '調和インフレ率（前月比）（速報値）',
  'harmonised_inflation_rate_mom_prel.title:ja:Italy': '調和インフレ率（前月比）（速報値）',
  'harmonised_inflation_rate_mom_prel.title:ja:France': '調和インフレ率（前月比）（速報値）',
  'harmonised_inflation_rate_mom_prel.title:ja:Germany': '調和インフレ率（前月比）（速報値）',
  'harmonised_inflation_rate_mom_prel.title:ja:Spain': '調和インフレ率（前月比）（速報値）',
  'fed_tarullo_testifies.title:ja:DEFAULT': 'FRBタルーロ理事証言',
  'fed_tarullo_testifies.title:ja:United States': 'FRBタルーロ理事証言',
  'maharashtra_day.title:ja:DEFAULT': 'マハラシュトラの日',
  'maharashtra_day.title:ja:India': 'マハラシュトラの日',
  'harmonised_inflation_rate_mom.title:ja:DEFAULT': '調和インフレ率（前月比）',
  'harmonised_inflation_rate_mom.title:ja:Austria': '調和インフレ率（前月比）',
  'harmonised_inflation_rate_mom.title:ja:Ireland': '調和インフレ率（前月比）',
  'harmonised_inflation_rate_mom.title:ja:Germany': '調和インフレ率（前月比）',
  'harmonised_inflation_rate_mom.title:ja:France': '調和インフレ率（前月比）',
  'hari_raya_aidil_fitri_1434_hijriyah.title:ja:DEFAULT':
    'ハリ・ラヤ・アイディルフィトリ（ヒジュラ暦1434年）',
  'hari_raya_aidil_fitri_1434_hijriyah.title:ja:Indonesia':
    'ハリ・ラヤ・アイディルフィトリ（ヒジュラ暦1434年）',
  'the_queen_s_birthday.title:ja:DEFAULT': '女王陛下誕生日',
  'the_queen_s_birthday.title:ja:Thailand': '女王陛下誕生日',
  'hangul_day.title:ja:DEFAULT': 'ハングルの日',
  'hangul_day.title:ja:South Korea': 'ハングルの日',
  'job_vacancies.title:ja:DEFAULT': '求人数',
  'job_vacancies.title:ja:Germany': '求人数',
  'job_vacancies.title:ja:Canada': '求人数',
  'hammond_presents_autumn_statement.title:ja:DEFAULT': 'ハモンド英財務相秋季財政報告',
  'hammond_presents_autumn_statement.title:ja:United Kingdom': 'ハモンド英財務相秋季財政報告',
  'hsbc_markit_services_pmi.title:ja:DEFAULT':
    'HSBC/マークイットサービス業購買担当者景気指数（PMI）',
  'hsbc_markit_services_pmi.title:ja:India': 'HSBC/マークイットサービス業購買担当者景気指数（PMI）',
  'kof_leading_indicators.title:ja:DEFAULT': 'KOF先行指標',
  'kof_leading_indicators.title:ja:Switzerland': 'KOF先行指標',
  'hsbc_markit_manufacturing_pmi.title:ja:DEFAULT':
    'HSBC/マークイット製造業購買担当者景気指数（PMI）',
  'hsbc_markit_manufacturing_pmi.title:ja:India':
    'HSBC/マークイット製造業購買担当者景気指数（PMI）',
  'hsbc_markit_manufacturing_pmi.title:ja:South Korea':
    'HSBC/マークイット製造業購買担当者景気指数（PMI）',
  'hsbc_markit_manuf_pmi.title:ja:DEFAULT': 'HSBC/マークイット製造業購買担当者景気指数（PMI）',
  'hsbc_markit_manuf_pmi.title:ja:South Korea': 'HSBC/マークイット製造業購買担当者景気指数（PMI）',
  'g7_finance_ministers_meeting.title:ja:DEFAULT': 'G7財務相会合',
  'g7_finance_ministers_meeting.title:ja:Germany': 'G7財務相会合',
  'hsbc_services_pmi.title:ja:DEFAULT': 'HSBCサービス業購買担当者景気指数（PMI)',
  'hsbc_services_pmi.title:ja:United States': 'HSBCサービス業購買担当者景気指数（PMI)',
  'hsbc_services_pmi.title:ja:Russia': 'HSBCサービス業購買担当者景気指数（PMI)',
  'hsbc_services_pmi.title:ja:Brazil': 'HSBCサービス業購買担当者景気指数（PMI)',
  'hsbc_services_pmi.title:ja:India': 'HSBCサービス業購買担当者景気指数（PMI)',
  'hsbc_services_pmi.title:ja:China': 'HSBCサービス業購買担当者景気指数（PMI)',
  'gdp_growth_rate_yoy-2_est.title:ja:DEFAULT': 'GDP成長率（前年比）（2次速報）',
  'gdp_growth_rate_yoy-2_est.title:ja:Poland': 'GDP成長率（前年比）（2次速報）',
  'hm_the_king_s_birthday.title:ja:DEFAULT': '国王誕生日',
  'hm_the_king_s_birthday.title:ja:Thailand': '国王誕生日',
  'average_weekly_earnings_yoy.title:ja:DEFAULT': '平均週給（前年比）',
  'average_weekly_earnings_yoy.title:ja:Ireland': '平均週給（前年比）',
  'average_weekly_earnings_yoy.title:ja:Canada': '平均週給（前年比）',
  'hicp_yoy.title:ja:DEFAULT': 'HICP（前年比）',
  'hicp_yoy.title:ja:Spain': 'HICP（前年比）',
  'gfk_consumer_confidence_flash.title:ja:DEFAULT': 'GfK消費者信頼感指数（速報値）',
  'gfk_consumer_confidence_flash.title:ja:United Kingdom': 'GfK消費者信頼感指数（速報値）',
  'gdp_growth_rate_yoy_third_estimate.title:ja:DEFAULT': 'GDP成長率（前年比）（3次速報）',
  'gdp_growth_rate_yoy_third_estimate.title:ja:United States': 'GDP成長率（前年比）（3次速報）',
  'halpim_manufacturing_pmi.title:ja:DEFAULT': 'HALPIM製造業購買担当者景気指数（PMI）',
  'halpim_manufacturing_pmi.title:ja:Hungary': 'HALPIM製造業購買担当者景気指数（PMI）',
  'gudi_padwa.title:ja:DEFAULT': 'グディパドワ',
  'gudi_padwa.title:ja:India': 'グディパドワ',
  '17_may_constitution_day.title:ja:DEFAULT': 'ノルウェー憲法記念日（5月17日）',
  '17_may_constitution_day.title:ja:Norway': 'ノルウェー憲法記念日（5月17日）',
  'eu-south_korea_video_conference_meeting.title:ja:DEFAULT': 'EU-韓国ビデオ会議',
  'eu-south_korea_video_conference_meeting.title:ja:European Union': 'EU-韓国ビデオ会議',
  'gross_wage_yoy.title:ja:DEFAULT': '総賃金（前年比）',
  'gross_wage_yoy.title:ja:Hungary': '総賃金（前年比）',
  'gross_domestic_product_price_index.title:ja:DEFAULT': 'GDP価格指数',
  'gross_domestic_product_price_index.title:ja:United States': 'GDP価格指数',
  'scottish_parliament_election.title:ja:DEFAULT': 'スコットランド議会選挙',
  'scottish_parliament_election.title:ja:United Kingdom': 'スコットランド議会選挙',
  'gross_domestic_product_annualized.title:ja:DEFAULT': 'GDP（年率）',
  'gross_domestic_product_annualized.title:ja:United States': 'GDP（年率）',
  'gross_domestic_product_mom.title:ja:DEFAULT': 'GDP（前月比）',
  'gross_domestic_product_mom.title:ja:Canada': 'GDP（前月比）',
  'government_debt_to_gdp.title:ja:DEFAULT': '政府債務残高対GDP比',
  'government_debt_to_gdp.title:ja:Euro Area': '政府債務残高対GDP比',
  'government_budget_to_gdp.title:ja:DEFAULT': '政府歳出対GDP比',
  'government_budget_to_gdp.title:ja:Euro Area': '政府歳出対GDP比',
  'queen_s_speech_at_the_parliament.title:ja:DEFAULT': '議会での女王演説',
  'queen_s_speech_at_the_parliament.title:ja:United Kingdom': '議会での女王演説',
  'goodwill_day.title:ja:DEFAULT': '親善の日',
  'goodwill_day.title:ja:South Africa': '親善の日',
  'global_business_summit.title:ja:DEFAULT': 'グローバル・ビジネスサミット',
  'global_business_summit.title:ja:India': 'グローバル・ビジネスサミット',
  'bundesbank_balz_speech.title:ja:DEFAULT': 'ドイツ連邦銀行バルツ氏講演',
  'bundesbank_balz_speech.title:ja:Germany': 'ドイツ連邦銀行バルツ氏講演',
  'childrens_day.title:ja:DEFAULT': '子どもの日',
  'childrens_day.title:ja:South Korea': '子どもの日',
  'childrens_day.title:ja:Taiwan': '子どもの日',
  'import_prices_qoq.title:ja:DEFAULT': '輸入物価指数（前四半期比）',
  'import_prices_qoq.title:ja:New Zealand': '輸入物価指数（前四半期比）',
  'import_prices_qoq.title:ja:Australia': '輸入物価指数（前四半期比）',
  'general_government_budget.title:ja:DEFAULT': '一般政府予算',
  'general_government_budget.title:ja:Euro Area': '一般政府予算',
  'general_election_results.title:ja:DEFAULT': '総選挙の結果',
  'general_election_results.title:ja:India': '総選挙の結果',
  'part_time_employment_chg.title:ja:Australia': 'パートタイム労働者数変化',
  'general_election.title:ja:DEFAULT': '総選挙',
  'general_election.title:ja:New Zealand': '総選挙',
  'general_election.title:ja:Thailand': '総選挙',
  'general_election.title:ja:Spain': '総選挙',
  'general_election.title:ja:United Kingdom': '総選挙',
  'general_election.title:ja:Singapore': '総選挙',
  'european_council_special_meeting.title:ja:DEFAULT': '欧州理事会特別会合',
  'european_council_special_meeting.title:ja:European Union': '欧州理事会特別会合',
  'fipes_ipc_inflation.title:ja:DEFAULT': 'IPC-Fipeインフレ指数',
  'fipes_ipc_inflation.title:ja:Brazil': 'IPC-Fipeインフレ指数',
  'general_council_meeting.title:ja:DEFAULT': '一般理事会会合',
  'general_council_meeting.title:ja:Euro Area': '一般理事会会合',
  'riksbank_monetary_policy_report.title:ja:DEFAULT': 'リスクバンク金融政策報告',
  'riksbank_monetary_policy_report.title:ja:Sweden': 'リスクバンク金融政策報告',
  'industrial_capacity_capacity_utilization.title:ja:DEFAULT': '工場稼働率',
  'industrial_capacity_capacity_utilization.title:ja:China': '工場稼働率',
  'gasoline_inventories.title:ja:DEFAULT': 'ガソリン在庫',
  'gasoline_inventories.title:ja:United States': 'ガソリン在庫',
  'gnp_yoy_final.title:ja:DEFAULT': '国民総生産（GNP）（前年比）（確報値）',
  'gnp_yoy_final.title:ja:Ireland': '国民総生産（GNP）（前年比）（確報値）',
  'gnp_qoq_final.title:ja:DEFAULT': '国民総生産（GNP）（前四半期比）（確報値）',
  'gnp_qoq_final.title:ja:Ireland': '国民総生産（GNP）（前四半期比）（確報値）',
  'tankan_non-manufacturing_index.title:ja:DEFAULT': '日銀短観・非製造業業況判断指数',
  'tankan_non-manufacturing_index.title:ja:Japan': '日銀短観・非製造業業況判断指数',
  'gnp_qoq.title:ja:DEFAULT': 'GNP（前四半期比）',
  'gnp_qoq.title:ja:Ireland': 'GNP（前四半期比）',
  'fed_george_testimony.title:ja:DEFAULT': 'FRBジョージ理事証言',
  'fed_george_testimony.title:ja:United States': 'FRBジョージ理事証言',
  'markit_composite_pmi.title:ja:DEFAULT': 'マークイット総合購買担当者景気指数（PMI）',
  'markit_composite_pmi.title:ja:Spain': 'マークイット総合購買担当者景気指数（PMI）',
  'markit_composite_pmi.title:ja:Brazil': 'マークイット総合購買担当者景気指数（PMI）',
  'markit_composite_pmi.title:ja:India': 'マークイット総合購買担当者景気指数（PMI）',
  'markit_composite_pmi.title:ja:Euro Area': 'マークイット総合購買担当者景気指数（PMI）',
  'markit_composite_pmi.title:ja:Russia': 'マークイット総合購買担当者景気指数（PMI）',
  'gdp_private_consumption_qoq.title:ja:DEFAULT': 'GDP民間消費（前四半期比）',
  'gdp_private_consumption_qoq.title:ja:Japan': 'GDP民間消費（前四半期比）',
  'gross_domestic_product_annualized_final.title:ja:DEFAULT': 'GDP（年率）（確報値）',
  'gross_domestic_product_annualized_final.title:ja:Japan': 'GDP（年率）（確報値）',
  'full_year_gdp_growth.title:ja:DEFAULT': '会計年度 GDP 成長率',
  'full_year_gdp_growth.title:ja:Russia': '会計年度 GDP 成長率',
  'full_year_gdp_growth.title:ja:Philippines': '会計年度 GDP 成長率',
  'full_year_gdp_growth.title:ja:Italy': '会計年度 GDP 成長率',
  'full_year_gdp_growth.title:ja:Germany': '会計年度 GDP 成長率',
  'full_year_gdp_growth.title:ja:Poland': '会計年度 GDP 成長率',
  'full_year_gdp_growth.title:ja:Thailand': '会計年度 GDP 成長率',
  'full_year_gdp_growth.title:ja:Indonesia': '会計年度 GDP 成長率',
  'gdp_price_index_yoy_prel.title:ja:DEFAULT': 'GDP物価指数（前年比）（速報値）',
  'gdp_price_index_yoy_prel.title:ja:Japan': 'GDP物価指数（前年比）（速報値）',
  'parliamentary_debate.title:ja:DEFAULT': '国会討論',
  'parliamentary_debate.title:ja:United Kingdom': '国会討論',
  'gdp_price_index_qoq_adv.title:ja:DEFAULT': 'GDP物価指数（前四半期比）（速報値）',
  'gdp_price_index_qoq_adv.title:ja:United States': 'GDP物価指数（前四半期比）（速報値）',
  'ifo_-_current_assessment.title:ja:DEFAULT': 'IFO現況',
  'ifo_-_current_assessment.title:ja:Germany': 'IFO現況',
  'fed_harker_mester_lockhart_speech.title:ja:DEFAULT':
    'FRBハーカー氏、メスター氏、ロックハート氏講演',
  'fed_harker_mester_lockhart_speech.title:ja:United States':
    'FRBハーカー氏、メスター氏、ロックハート氏講演',
  'gdp_price_index_qoq_2nd_est.title:ja:DEFAULT': 'GDP物価指数（前四半期比）（2次速報）',
  'gdp_price_index_qoq_2nd_est.title:ja:United States': 'GDP物価指数（前四半期比）（2次速報）',
  'labour_cost_index_yoy.title:ja:Euro Area': '労働コスト指数（前年比）',
  'fed_brainard_speaks.title:ja:DEFAULT': 'FRBブレイナード氏講演',
  'fed_brainard_speaks.title:ja:United States': 'FRBブレイナード氏講演',
  'gdp_price_index.title:ja:DEFAULT': 'GDP物価指数',
  'gdp_price_index.title:ja:United States': 'GDP物価指数',
  '2016_convergence_report.title:ja:DEFAULT': '2016年コンバージェンス・レポート',
  '2016_convergence_report.title:ja:Euro Area': '2016年コンバージェンス・レポート',
  'finance_min_osborne_speaks_on_brexit.title:ja:DEFAULT':
    'ブレグジットに関するオズボーン財務相講演',
  'finance_min_osborne_speaks_on_brexit.title:ja:United Kingdom':
    'ブレグジットに関するオズボーン財務相講演',
  'real_wages_yoy.title:ja:DEFAULT': '実質賃金上昇率（前年比）',
  'real_wages_yoy.title:ja:Czech Republic': '実質賃金上昇率（前年比）',
  'gdp_mom.title:ja:DEFAULT': 'GDP（前月比）',
  'gdp_mom.title:ja:United Kingdom': 'GDP（前月比）',
  'gdp_mom.title:ja:Canada': 'GDP（前月比）',
  'gdp_implicit_price_qoq.title:ja:DEFAULT':
    '国民総生産（GDP）インプリシット物価指数（前四半期比）',
  'gdp_implicit_price_qoq.title:ja:Canada': '国民総生産（GDP）インプリシット物価指数（前四半期比）',
  'national_day_holiday.title:ja:DEFAULT': '国家の祝日',
  'national_day_holiday.title:ja:Russia': '国家の祝日',
  'gdp_growth_rate_yoy_flash.title:ja:DEFAULT': '国民総生産（GDP）成長率（前年比）（速報値）',
  'gdp_growth_rate_yoy_flash.title:ja:Greece': '国民総生産（GDP）成長率（前年比）（速報値）',
  'gdp_growth_rate_yoy_flash.title:ja:Euro Area': '国民総生産（GDP）成長率（前年比）（速報値）',
  'gdp_growth_rate_yoy_flash.title:ja:Sweden': '国民総生産（GDP）成長率（前年比）（速報値）',
  'gdp_growth_rate_yoy_flash.title:ja:Austria': '国民総生産（GDP）成長率（前年比）（速報値）',
  'gdp_growth_rate_yoy_flash.title:ja:Spain': '国民総生産（GDP）成長率（前年比）（速報値）',
  'gdp_growth_rate_yoy_flash.title:ja:Poland': '国民総生産（GDP）成長率（前年比）（速報値）',
  'gdp_growth_rate_yoy_flash.title:ja:Germany': '国民総生産（GDP）成長率（前年比）（速報値）',
  'gdp_growth_rate_yoy_flash.title:ja:Netherlands': '国民総生産（GDP）成長率（前年比）（速報値）',
  'democratic_primaries_super_tuesday.title:ja:DEFAULT':
    '大統領選挙民主党予備選「スーパー・チューズデー」',
  'democratic_primaries_super_tuesday.title:ja:United States':
    '大統領選挙民主党予備選「スーパー・チューズデー」',
  'gdp_growth_rate_yoy_adv.title:ja:DEFAULT': 'GDP成長率（前年比）（速報値）',
  'gdp_growth_rate_yoy_adv.title:ja:Czech Republic': 'GDP成長率（前年比）（速報値）',
  'gdp_growth_rate_yoy_adv.title:ja:United States': 'GDP成長率（前年比）（速報値）',
  'gdp_growth_rate_yoy_adv.title:ja:Greece': 'GDP成長率（前年比）（速報値）',
  'gdp_growth_rate_yoy_adv.title:ja:Hong Kong': 'GDP成長率（前年比）（速報値）',
  'gdp_growth_rate_yoy_adv.title:ja:South Korea': 'GDP成長率（前年比）（速報値）',
  'gdp_growth_rate_yoy_adv.title:ja:Singapore': 'GDP成長率（前年比）（速報値）',
  'gdp_growth_rate_yoy_adv.title:ja:Taiwan': 'GDP成長率（前年比）（速報値）',
  'gdp_growth_rate_yoy_adv.title:ja:Belgium': 'GDP成長率（前年比）（速報値）',
  'gdp_growth_rate_yoy_adv.title:ja:Italy': 'GDP成長率（前年比）（速報値）',
  'boe_gov_carney_testimony.title:ja:DEFAULT': 'イングランド銀行カーニー総裁証言',
  'boe_gov_carney_testimony.title:ja:United Kingdom': 'イングランド銀行カーニー総裁証言',
  'economy_and_finance_minister_padoan_speech.title:ja:DEFAULT': 'パドアン経済・財務相講演',
  'economy_and_finance_minister_padoan_speech.title:ja:Italy': 'パドアン経済・財務相講演',
  'easter.title:ja:DEFAULT': 'イースター（復活祭）',
  'easter.title:ja:Poland': 'イースター（復活祭）',
  'gdp_growth_rate_yoy_3rd_est.title:ja:DEFAULT': 'GDP成長率（前年比）（3次速報）',
  'gdp_growth_rate_yoy_3rd_est.title:ja:Euro Area': 'GDP成長率（前年比）（3次速報）',
  'gdp_growth_rate_yoy_1st_est.title:ja:DEFAULT': 'GDP成長率（前年比）（1次速報）',
  'gdp_growth_rate_yoy_1st_est.title:ja:France': 'GDP成長率（前年比）（1次速報）',
  'foreign_portfolio_investment_in_canadian_securities.title:ja:DEFAULT':
    'カナダ証券への外国ポートフォリオ投資',
  'foreign_portfolio_investment_in_canadian_securities.title:ja:Canada':
    'カナダ証券への外国ポートフォリオ投資',
  'extra_holiday.title:ja:DEFAULT': '臨時休日',
  'extra_holiday.title:ja:Thailand': '臨時休日',
  '69-day_bill_auction.title:ja:DEFAULT': '69日割引債入札',
  '69-day_bill_auction.title:ja:United States': '69日割引債入札',
  'gdp_growth_rate_yoy_-_2nd_est.title:ja:DEFAULT': 'GDP成長率（前年比）二次速報値',
  'gdp_growth_rate_yoy_-_2nd_est.title:ja:Israel': 'GDP成長率（前年比）二次速報値',
  'gdp_growth_rate_yoy_-_final.title:ja:DEFAULT': 'GDP成長率（前年比）（確報値）',
  'gdp_growth_rate_yoy_-_final.title:ja:Denmark': 'GDP成長率（前年比）（確報値）',
  'gdp_growth_rate_yoy.title:ja:DEFAULT': 'GDP成長率（前年比）',
  'gdp_growth_rate_yoy.title:ja:Hungary': 'GDP成長率（前年比）',
  'gdp_growth_rate_yoy.title:ja:Israel': 'GDP成長率（前年比）',
  'gdp_growth_rate_yoy.title:ja:Germany': 'GDP成長率（前年比）',
  'gdp_growth_rate_yoy.title:ja:Poland': 'GDP成長率（前年比）',
  'gdp_growth_rate_yoy.title:ja:Italy': 'GDP成長率（前年比）',
  'gdp_growth_rate_yoy.title:ja:Netherlands': 'GDP成長率（前年比）',
  'gdp_growth_rate_yoy.title:ja:South Korea': 'GDP成長率（前年比）',
  'gdp_growth_rate_yoy.title:ja:Portugal': 'GDP成長率（前年比）',
  'gdp_growth_rate_yoy.title:ja:China': 'GDP成長率（前年比）',
  'gdp_growth_rate_yoy.title:ja:Brazil': 'GDP成長率（前年比）',
  'gdp_growth_rate_yoy.title:ja:Finland': 'GDP成長率（前年比）',
  'gdp_growth_rate_yoy.title:ja:Euro Area': 'GDP成長率（前年比）',
  'gdp_growth_rate_yoy.title:ja:Colombia': 'GDP成長率（前年比）',
  'gdp_growth_rate_yoy.title:ja:Philippines': 'GDP成長率（前年比）',
  'gdp_growth_rate_yoy.title:ja:Switzerland': 'GDP成長率（前年比）',
  'gdp_growth_rate_yoy.title:ja:Austria': 'GDP成長率（前年比）',
  'gdp_growth_rate_yoy.title:ja:Sweden': 'GDP成長率（前年比）',
  'gdp_growth_rate_yoy.title:ja:United Kingdom': 'GDP成長率（前年比）',
  'gdp_growth_rate_yoy.title:ja:Ireland': 'GDP成長率（前年比）',
  'gdp_growth_rate_yoy.title:ja:Australia': 'GDP成長率（前年比）',
  'gdp_growth_rate_yoy.title:ja:Singapore': 'GDP成長率（前年比）',
  'gdp_growth_rate_yoy.title:ja:Canada': 'GDP成長率（前年比）',
  'gdp_growth_rate_yoy.title:ja:Indonesia': 'GDP成長率（前年比）',
  'gdp_growth_rate_yoy.title:ja:Hong Kong': 'GDP成長率（前年比）',
  'gdp_growth_rate_yoy.title:ja:Greece': 'GDP成長率（前年比）',
  'gdp_growth_rate_yoy.title:ja:Thailand': 'GDP成長率（前年比）',
  'gdp_growth_rate_yoy.title:ja:Denmark': 'GDP成長率（前年比）',
  'gdp_growth_rate_yoy.title:ja:Spain': 'GDP成長率（前年比）',
  'gdp_growth_rate_yoy.title:ja:Russia': 'GDP成長率（前年比）',
  'gdp_growth_rate_yoy.title:ja:Czech Republic': 'GDP成長率（前年比）',
  'gdp_growth_rate_yoy.title:ja:Norway': 'GDP成長率（前年比）',
  'gdp_growth_rate_yoy.title:ja:Mexico': 'GDP成長率（前年比）',
  'gdp_growth_rate_yoy.title:ja:France': 'GDP成長率（前年比）',
  'gdp_growth_rate_yoy.title:ja:Belgium': 'GDP成長率（前年比）',
  'gdp_growth_rate_yoy.title:ja:South Africa': 'GDP成長率（前年比）',
  'gdp_growth_rate_yoy.title:ja:New Zealand': 'GDP成長率（前年比）',
  'gdp_growth_rate_yoy.title:ja:India': 'GDP成長率（前年比）',
  'gdp_growth_rate_yoy.title:ja:Taiwan': 'GDP成長率（前年比）',
  'birthday_of_martin_luther_king_jr.title:ja:DEFAULT': 'キング牧師記念日',
  'birthday_of_martin_luther_king_jr.title:ja:United States': 'キング牧師記念日',
  'gdp_growth_rate_qoq-final.title:ja:DEFAULT': 'GDP成長率（前四半期比）（確報値）',
  'gdp_growth_rate_qoq-final.title:ja:Singapore': 'GDP成長率（前四半期比）（確報値）',
  'gdp_growth_annualized_final.title:ja:DEFAULT': 'GDP年率成長率（確報値）',
  'gdp_growth_annualized_final.title:ja:Israel': 'GDP年率成長率（確報値）',
  'gdp_growth_annualized_final.title:ja:Japan': 'GDP年率成長率（確報値）',
  'eco_watchers_survey_current.title:ja:DEFAULT': '景気ウォッチャー調査：現状判断',
  'eco_watchers_survey_current.title:ja:Japan': '景気ウォッチャー調査：現状判断',
  'gdp_growth_rate_qoq_flash.title:ja:DEFAULT': 'GDP成長率（前四半期比）（速報値）',
  'gdp_growth_rate_qoq_flash.title:ja:Denmark': 'GDP成長率（前四半期比）（速報値）',
  'gdp_growth_rate_qoq_flash.title:ja:Euro Area': 'GDP成長率（前四半期比）（速報値）',
  'gdp_growth_rate_qoq_flash.title:ja:Sweden': 'GDP成長率（前四半期比）（速報値）',
  'gdp_growth_rate_qoq_flash.title:ja:Austria': 'GDP成長率（前四半期比）（速報値）',
  'gdp_growth_rate_qoq_flash.title:ja:Spain': 'GDP成長率（前四半期比）（速報値）',
  'gdp_growth_rate_qoq_flash.title:ja:Germany': 'GDP成長率（前四半期比）（速報値）',
  'gdp_growth_rate_qoq_flash.title:ja:Netherlands': 'GDP成長率（前四半期比）（速報値）',
  'foreign_investment_in_canadian_securities.title:ja:DEFAULT': 'カナダ証券外国人投資',
  'foreign_investment_in_canadian_securities.title:ja:Canada': 'カナダ証券外国人投資',
  'employment_change_qoq.title:ja:DEFAULT': '雇用者数変化（前四半期比）',
  'employment_change_qoq.title:ja:New Zealand': '雇用者数変化（前四半期比）',
  'employment_change_qoq.title:ja:Euro Area': '雇用者数変化（前四半期比）',
  'gdp_growth_rate_qoq_final.title:ja:DEFAULT': 'GDP成長率（前四半期比）（確報値）',
  'gdp_growth_rate_qoq_final.title:ja:Taiwan': 'GDP成長率（前四半期比）（確報値）',
  'gdp_growth_rate_qoq_final.title:ja:Euro Area': 'GDP成長率（前四半期比）（確報値）',
  'gdp_growth_rate_qoq_final.title:ja:Hong Kong': 'GDP成長率（前四半期比）（確報値）',
  'gdp_growth_rate_qoq_final.title:ja:Portugal': 'GDP成長率（前四半期比）（確報値）',
  'gdp_growth_rate_qoq_final.title:ja:Greece': 'GDP成長率（前四半期比）（確報値）',
  'gdp_growth_rate_qoq_final.title:ja:Finland': 'GDP成長率（前四半期比）（確報値）',
  'gdp_growth_rate_qoq_final.title:ja:United States': 'GDP成長率（前四半期比）（確報値）',
  'gdp_growth_rate_qoq_final.title:ja:Hungary': 'GDP成長率（前四半期比）（確報値）',
  'gdp_growth_rate_qoq_final.title:ja:Israel': 'GDP成長率（前四半期比）（確報値）',
  'gdp_growth_rate_qoq_final.title:ja:Ireland': 'GDP成長率（前四半期比）（確報値）',
  'gdp_growth_rate_qoq_final.title:ja:United Kingdom': 'GDP成長率（前四半期比）（確報値）',
  'gdp_growth_rate_qoq_final.title:ja:Belgium': 'GDP成長率（前四半期比）（確報値）',
  'gdp_growth_rate_qoq_final.title:ja:France': 'GDP成長率（前四半期比）（確報値）',
  'gdp_growth_rate_qoq_final.title:ja:Norway': 'GDP成長率（前四半期比）（確報値）',
  'gdp_growth_rate_qoq_final.title:ja:Mexico': 'GDP成長率（前四半期比）（確報値）',
  'gdp_growth_rate_qoq_final.title:ja:South Korea': 'GDP成長率（前四半期比）（確報値）',
  'gdp_growth_rate_qoq_final.title:ja:Italy': 'GDP成長率（前四半期比）（確報値）',
  'gdp_growth_rate_qoq_final.title:ja:Netherlands': 'GDP成長率（前四半期比）（確報値）',
  'gdp_growth_rate_qoq_final.title:ja:Japan': 'GDP成長率（前四半期比）（確報値）',
  'gdp_growth_rate_qoq_final.title:ja:Singapore': 'GDP成長率（前四半期比）（確報値）',
  'gdp_growth_rate_qoq_final.title:ja:Spain': 'GDP成長率（前四半期比）（確報値）',
  'gdp_growth_rate_qoq_final.title:ja:Czech Republic': 'GDP成長率（前四半期比）（確報値）',
  'gdp_growth_rate_qoq_final.title:ja:Denmark': 'GDP成長率（前四半期比）（確報値）',
  'gdp_growth_rate_qoq_final.title:ja:Poland': 'GDP成長率（前四半期比）（確報値）',
  'gdp_growth_rate_qoq_final.title:ja:Germany': 'GDP成長率（前四半期比）（確報値）',
  'gdp_growth_rate_qoq_final.title:ja:Austria': 'GDP成長率（前四半期比）（確報値）',
  'gdp_growth_rate_qoq_final.title:ja:Sweden': 'GDP成長率（前四半期比）（確報値）',
  'gdp_growth_rate_qoq_3st_est.title:ja:DEFAULT': 'GDP成長率（前四半期比）（3次速報）',
  'gdp_growth_rate_qoq_3st_est.title:ja:Israel': 'GDP成長率（前四半期比）（3次速報）',
  'gdp_growth_rate_qoq_3rd_est.title:ja:DEFAULT': 'GDP成長率（前四半期比）（3次速報）',
  'gdp_growth_rate_qoq_3rd_est.title:ja:Euro Area': 'GDP成長率（前四半期比）（3次速報）',
  'gdp_growth_rate_qoq_3rd_est.title:ja:Israel': 'GDP成長率（前四半期比）（3次速報）',
  'gdp_growth_rate_qoq_-_third_estimate.title:ja:DEFAULT': 'GDP成長率（前四半期比）（3次速報）',
  'gdp_growth_rate_qoq_-_third_estimate.title:ja:Israel': 'GDP成長率（前四半期比）（3次速報）',
  'deposit_interest_rate.title:ja:DEFAULT': '預金金利',
  'deposit_interest_rate.title:ja:Hungary': '預金金利',
  'financial_stability_report_2017.title:ja:DEFAULT': '2017年金融安定性報告書',
  'financial_stability_report_2017.title:ja:Sweden': '2017年金融安定性報告書',
  'gdp_growth_rate_qoq_-_p.title:ja:Euro Area': 'GDP成長率（前四半期比）（速報値）',
  'gdp_growth_rate_qoq_-_flash.title:ja:DEFAULT': 'GDP成長率（前四半期比）（速報値）',
  'gdp_growth_rate_qoq_-_flash.title:ja:Euro Area': 'GDP成長率（前四半期比）（速報値）',
  'gdp_growth_rate_qoq_-_flash.title:ja:Netherlands': 'GDP成長率（前四半期比）（速報値）',
  'gdp_growth_rate_qoq_-_flash.title:ja:Poland': 'GDP成長率（前四半期比）（速報値）',
  'gdp_growth_rate_qoq_-_flash.title:ja:Finland': 'GDP成長率（前四半期比）（速報値）',
  'consumer_price_index_yoy.title:ja:DEFAULT': '消費者物価指数（前年比）',
  'consumer_price_index_yoy.title:ja:Germany': '消費者物価指数（前年比）',
  'consumer_price_index_yoy.title:ja:Euro Area': '消費者物価指数（前年比）',
  'foreign_bond_investment.title:ja:United States': '外国債券投資',
  'gdp_growth_rate_qoq_-_2nd_est.title:ja:DEFAULT': 'GDP成長率（前四半期比）（2次速報）',
  'gdp_growth_rate_qoq_-_2nd_est.title:ja:Israel': 'GDP成長率（前四半期比）（2次速報）',
  'gdp_growth_rate_qoq_-_1st_est.title:ja:DEFAULT': 'GDP成長率（前四半期比）（1次速報）',
  'gdp_growth_rate_qoq_-_1st_est.title:ja:Israel': 'GDP成長率（前四半期比）（1次速報）',
  'nbs_press_conference.title:ja:DEFAULT': 'NBS記者会見',
  'nbs_press_conference.title:ja:China': 'NBS記者会見',
  'gdp_growth_rate_qoq.title:ja:Germany': 'GDP成長率（前四半期比）',
  'gdp_growth_rate_qoq.title:ja:Poland': 'GDP成長率（前四半期比）',
  'gdp_growth_rate_qoq.title:ja:Taiwan': 'GDP成長率（前四半期比）',
  'gdp_growth_rate_qoq.title:ja:South Korea': 'GDP成長率（前四半期比）',
  'gdp_growth_rate_qoq.title:ja:Japan': 'GDP成長率（前四半期比）',
  'gdp_growth_rate_qoq.title:ja:Netherlands': 'GDP成長率（前四半期比）',
  'gdp_growth_rate_qoq.title:ja:Italy': 'GDP成長率（前四半期比）',
  'gdp_growth_rate_qoq.title:ja:Israel': 'GDP成長率（前四半期比）',
  'gdp_growth_rate_qoq.title:ja:Hungary': 'GDP成長率（前四半期比）',
  'gdp_growth_rate_qoq.title:ja:Brazil': 'GDP成長率（前四半期比）',
  'gdp_growth_rate_qoq.title:ja:New Zealand': 'GDP成長率（前四半期比）',
  'gdp_growth_rate_qoq.title:ja:Portugal': 'GDP成長率（前四半期比）',
  'gdp_growth_rate_qoq.title:ja:China': 'GDP成長率（前四半期比）',
  'gdp_growth_rate_qoq.title:ja:United States': 'GDP成長率（前四半期比）',
  'gdp_growth_rate_qoq.title:ja:Austria': 'GDP成長率（前四半期比）',
  'gdp_growth_rate_qoq.title:ja:Sweden': 'GDP成長率（前四半期比）',
  'gdp_growth_rate_qoq.title:ja:Switzerland': 'GDP成長率（前四半期比）',
  'gdp_growth_rate_qoq.title:ja:Indonesia': 'GDP成長率（前四半期比）',
  'gdp_growth_rate_qoq.title:ja:Canada': 'GDP成長率（前四半期比）',
  'gdp_growth_rate_qoq.title:ja:Australia': 'GDP成長率（前四半期比）',
  'gdp_growth_rate_qoq.title:ja:Hong Kong': 'GDP成長率（前四半期比）',
  'gdp_growth_rate_qoq.title:ja:Greece': 'GDP成長率（前四半期比）',
  'gdp_growth_rate_qoq.title:ja:Thailand': 'GDP成長率（前四半期比）',
  'gdp_growth_rate_qoq.title:ja:Denmark': 'GDP成長率（前四半期比）',
  'gdp_growth_rate_qoq.title:ja:Spain': 'GDP成長率（前四半期比）',
  'gdp_growth_rate_qoq.title:ja:Czech Republic': 'GDP成長率（前四半期比）',
  'gdp_growth_rate_qoq.title:ja:Ireland': 'GDP成長率（前四半期比）',
  'gdp_growth_rate_qoq.title:ja:United Kingdom': 'GDP成長率（前四半期比）',
  'gdp_growth_rate_qoq.title:ja:Norway': 'GDP成長率（前四半期比）',
  'gdp_growth_rate_qoq.title:ja:Mexico': 'GDP成長率（前四半期比）',
  'gdp_growth_rate_qoq.title:ja:France': 'GDP成長率（前四半期比）',
  'gdp_growth_rate_qoq.title:ja:Belgium': 'GDP成長率（前四半期比）',
  'gdp_growth_rate_qoq.title:ja:South Africa': 'GDP成長率（前四半期比）',
  'gdp_growth_rate_qoq.title:ja:Euro Area': 'GDP成長率（前四半期比）',
  'gdp_growth_rate_qoq.title:ja:Colombia': 'GDP成長率（前四半期比）',
  'gdp_growth_rate_qoq.title:ja:Philippines': 'GDP成長率（前四半期比）',
  'gdp_growth_rate_qoq.title:ja:Finland': 'GDP成長率（前四半期比）',
  'gdp_growth_rate_annualized_qoq.title:ja:DEFAULT': 'GDP年率成長率（前四半期比）',
  'gdp_growth_rate_annualized_qoq.title:ja:Israel': 'GDP年率成長率（前四半期比）',
  'gdp_growth_rate_2018.title:ja:DEFAULT': '2018年GDP成長率',
  'gdp_growth_rate_2018.title:ja:Philippines': '2018年GDP成長率',
  'gdp_growth_rate_2017.title:ja:DEFAULT': '2017年GDP成長率',
  'gdp_growth_rate_2017.title:ja:Poland': '2017年GDP成長率',
  'gdp_growth_rate_2017.title:ja:Philippines': '2017年GDP成長率',
  'gdp_growth_rate_2016.title:ja:DEFAULT': '2016年GDP成長率',
  'gdp_growth_rate_2016.title:ja:Philippines': '2016年GDP成長率',
  'gdp_growth_rate_2015.title:ja:DEFAULT': '2015年GDP成長率',
  'gdp_growth_rate_2015.title:ja:Italy': '2015年GDP成長率',
  'gdp_growth_rate_2015.title:ja:Indonesia': '2015年GDP成長率',
  'gdp_growth_rate_2015.title:ja:Philippines': '2015年GDP成長率',
  'gdp_growth_rate_2014.title:ja:DEFAULT': '2014年GDP成長率',
  'gdp_growth_rate_2014.title:ja:Germany': '2014年GDP成長率',
  'purim.title:ja:DEFAULT': 'プーリーム',
  'purim.title:ja:Israel': 'プーリーム',
  'epiphany_day.title:ja:DEFAULT': '公現祭',
  'epiphany_day.title:ja:Sweden': '公現祭',
  'epiphany_day.title:ja:Poland': '公現祭',
  'epiphany_day.title:ja:Greece': '公現祭',
  'epiphany_day.title:ja:Finland': '公現祭',
  'gdp_growth_rate.title:ja:DEFAULT': 'GDP成長率',
  'gdp_growth_rate.title:ja:Germany': 'GDP成長率',
  'gdp_growth_rate.title:ja:Finland': 'GDP成長率',
  'gdp_growth_rate.title:ja:South Africa': 'GDP成長率',
  'gdp_growth_rate.title:ja:Hong Kong': 'GDP成長率',
  'gdp_growth_rate.title:ja:United Kingdom': 'GDP成長率',
  'gdp_growth_rate.title:ja:Denmark': 'GDP成長率',
  'norges_bank_press_conference.title:ja:DEFAULT': 'ノルウェー銀行記者会見',
  'norges_bank_press_conference.title:ja:Norway': 'ノルウェー銀行記者会見',
  'rba_interest_rate_decision.title:ja:DEFAULT': 'RBA政策金利決定',
  'rba_interest_rate_decision.title:ja:Australia': 'RBA政策金利決定',
  'municipal_election_second_round.title:ja:DEFAULT': '地方選挙（第2回投票）',
  'municipal_election_second_round.title:ja:Brazil': '地方選挙（第2回投票）',
  'gdp_growth_mainland_qoq_prel.title:ja:DEFAULT': '本土GDP成長率（前四半期比）（速報値）',
  'gdp_growth_mainland_qoq_prel.title:ja:Norway': '本土GDP成長率（前四半期比）（速報値）',
  'gdp_growth_mainland_qoq.title:ja:DEFAULT': '本土GDP成長率（前四半期比）',
  'gdp_growth_mainland_qoq.title:ja:Norway': '本土GDP成長率（前四半期比）',
  'communist_party_annual_meeting.title:ja:DEFAULT': '共産党年次総会',
  'communist_party_annual_meeting.title:ja:China': '共産党年次総会',
  'gdp_growth_annualized_2nd_est.title:ja:DEFAULT': 'GDP年率成長率（2次速報）',
  'gdp_growth_annualized_2nd_est.title:ja:Israel': 'GDP年率成長率（2次速報）',
  'gdp_private_consumption_qoq_prel.title:ja:DEFAULT': 'GDP民間消費（前四半期比）（速報値）',
  'gdp_private_consumption_qoq_prel.title:ja:Japan': 'GDP民間消費（前四半期比）（速報値）',
  'gdp_capital_expenditure.title:ja:DEFAULT': 'GDP設備投資',
  'gdp_capital_expenditure.title:ja:Australia': 'GDP設備投資',
  'gdp_growth_annualized_1st_est.title:ja:DEFAULT': 'GDP年率成長率（1次速報）',
  'gdp_growth_annualized_1st_est.title:ja:Israel': 'GDP年率成長率（1次速報）',
  'gdp_external_demand_qoq_final.title:ja:DEFAULT': 'GDP外需（前四半期比）（確報値）',
  'gdp_external_demand_qoq_final.title:ja:Japan': 'GDP外需（前四半期比）（確報値）',
  'proclamation_of_the_republic_day.title:ja:DEFAULT': '共和国宣言記念日',
  'proclamation_of_the_republic_day.title:ja:Brazil': '共和国宣言記念日',
  'gdp_deflator_qoq.title:ja:DEFAULT': 'GDPデフレーター （前四半期比）',
  'gdp_deflator_qoq.title:ja:United States': 'GDPデフレーター （前四半期比）',
  'gdp_deflator_qoq.title:ja:Australia': 'GDPデフレーター （前四半期比）',
  '119-day_bill_auction.title:ja:DEFAULT': '119日割引国債入札',
  '119-day_bill_auction.title:ja:United States': '119日割引国債入札',
  'gdp_deflator.title:ja:DEFAULT': 'GDPデフレーター',
  'gdp_deflator.title:ja:United States': 'GDPデフレーター',
  'gdp_deflator.title:ja:Australia': 'GDPデフレーター',
  'jewish_new_year_i.title:ja:DEFAULT': 'ユダヤ新年I',
  'jewish_new_year_i.title:ja:Israel': 'ユダヤ新年I',
  'gdp_consumer_spending_qoq_final.title:ja:DEFAULT': 'GDP消費支出（前四半期比）（確報値）',
  'gdp_consumer_spending_qoq_final.title:ja:United States': 'GDP消費支出（前四半期比）（確報値）',
  'eu-ukraine_summit.title:ja:DEFAULT': 'EUウクライナ首脳会議',
  'eu-ukraine_summit.title:ja:European Union': 'EUウクライナ首脳会議',
  'eu-ukraine_summit.title:ja:Euro Area': 'EUウクライナ首脳会議',
  'gdp_consumer_spending_qoq_adv.title:ja:DEFAULT': 'GDP消費支出（前四半期比）（速報値）',
  'gdp_consumer_spending_qoq_adv.title:ja:United States': 'GDP消費支出（前四半期比）（速報値）',
  'reuters_michigan_consumer_expectations_final.title:ja:DEFAULT':
    'ロイター・ミシガン大学消費者信頼感（確報値）',
  'reuters_michigan_consumer_expectations_final.title:ja:United States':
    'ロイター・ミシガン大学消費者信頼感（確報値）',
  'independence_movement_day.title:ja:DEFAULT': '独立運動記念日',
  'independence_movement_day.title:ja:South Korea': '独立運動記念日',
  'gdp_annual_growth_rate_yoy_-_second_estimate.title:ja:DEFAULT':
    'GDP年率成長率（前年比）（2次速報）',
  'gdp_annual_growth_rate_yoy_-_second_estimate.title:ja:Euro Area':
    'GDP年率成長率（前年比）（2次速報）',
  'gdp_annual_growth_rate_yoy.title:ja:DEFAULT': 'GDP年率成長率（前年比）',
  'gdp_annual_growth_rate_yoy.title:ja:Portugal': 'GDP年率成長率（前年比）',
  'gdp_annual_growth_rate_yoy.title:ja:Italy': 'GDP年率成長率（前年比）',
  'gdp_annual_growth_rate_yoy.title:ja:Czech Republic': 'GDP年率成長率（前年比）',
  'gdp_annual_growth_rate_yoy.title:ja:Greece': 'GDP年率成長率（前年比）',
  'gdp_annual_growth_rate_yoy.title:ja:Australia': 'GDP年率成長率（前年比）',
  'gdp_annual_growth_rate_yoy.title:ja:Austria': 'GDP年率成長率（前年比）',
  'gdp_annual_growth_rate_yoy.title:ja:Belgium': 'GDP年率成長率（前年比）',
  'gdp_annual_growth_rate_yoy.title:ja:Hungary': 'GDP年率成長率（前年比）',
  '3-year_bonos_auction.title:ja:DEFAULT': 'スペイン3年物国債入札',
  '3-year_bonos_auction.title:ja:Spain': 'スペイン3年物国債入札',
  'domestic_car_sales.title:ja:DEFAULT': '国内自動車販売台数',
  'domestic_car_sales.title:ja:United States': '国内自動車販売台数',
  'gdp_annual_growth_rate_-_adv.title:ja:DEFAULT': 'GDP年率成長率（速報値）',
  'gdp_annual_growth_rate_-_adv.title:ja:South Korea': 'GDP年率成長率（速報値）',
  'boc_beaudry_speech.title:ja:DEFAULT': 'カナダ銀行ボードリ氏講演',
  'boc_beaudry_speech.title:ja:Canada': 'カナダ銀行ボードリ氏講演',
  'g7_summit.title:ja:DEFAULT': '主要7カ国（G7）首脳会議',
  'g7_summit.title:ja:United Kingdom': '主要7カ国（G7）首脳会議',
  'g7_summit.title:ja:Japan': '主要7カ国（G7）首脳会議',
  'g7_summit.title:ja:Italy': '主要7カ国（G7）首脳会議',
  'g7_summit.title:ja:Canada': '主要7カ国（G7）首脳会議',
  'g7_summit.title:ja:France': '主要7カ国（G7）首脳会議',
  'g7_meeting_on_afghanistan_crisis.title:ja:DEFAULT': '',
  'g7_meeting_on_afghanistan_crisis.title:ja:United Kingdom': '',
  'passenger_vehicles_sales_mom.title:ja:DEFAULT': '乗用車販売台数（前月比）',
  'passenger_vehicles_sales_mom.title:ja:India': '乗用車販売台数（前月比）',
  'labour_day_substitute_day.title:ja:DEFAULT': 'レイバー・デー（振替休日）',
  'labour_day_substitute_day.title:ja:Greece': 'レイバー・デー（振替休日）',
  'labour_day_substitute_day.title:ja:Spain': 'レイバー・デー（振替休日）',
  'fed_ccar_results_for_big_banks.title:ja:DEFAULT':
    'FRBによる大手銀行の包括的資本分析およびレビュー（CCAR）結果',
  'fed_ccar_results_for_big_banks.title:ja:United States':
    'FRBによる大手銀行の包括的資本分析およびレビュー（CCAR）結果',
  'hispanic_day.title:ja:DEFAULT': 'ヒスパニック・デー',
  'hispanic_day.title:ja:Spain': 'ヒスパニック・デー',
  'g7_foreign_ministers_meeting.title:ja:DEFAULT': 'G7外相会議',
  'g7_foreign_ministers_meeting.title:ja:Italy': 'G7外相会議',
  'credit_expansion_yoy.title:ja:DEFAULT': '信用拡大（前年比）',
  'credit_expansion_yoy.title:ja:Greece': '信用拡大（前年比）',
  'g7_finance_ministers_and_central_bank_governors_meeting.title:ja:DEFAULT':
    '7カ国財務大臣・中央銀行総裁会議',
  'g7_finance_ministers_and_central_bank_governors_meeting.title:ja:Japan':
    '7カ国財務大臣・中央銀行総裁会議',
  'unemployment_change.title:ja:DEFAULT': '失業者数変化',
  'unemployment_change.title:ja:Norway': '失業者数変化',
  'unemployment_change.title:ja:Spain': '失業者数変化',
  'unemployment_change.title:ja:Germany': '失業者数変化',
  'g7_finance_ministers_and_central_bank_governors_meeting.title:ja:France':
    '7カ国財務大臣・中央銀行総裁会議',
  'g7_fin_ministers_and_cb_governors_meeting.title:ja:DEFAULT': 'G7財務相・中央銀行総裁会合',
  'g7_fin_ministers_and_cb_governors_meeting.title:ja:Italy': 'G7財務相・中央銀行総裁会合',
  'buddha_s_birthday.title:ja:DEFAULT': '灌仏会',
  'buddha_s_birthday.title:ja:Hong Kong': '灌仏会',
  'buddha_s_birthday.title:ja:Singapore': '灌仏会',
  'buddha_s_birthday.title:ja:South Korea': '灌仏会',
  'buddha_s_birthday.title:ja:Thailand': '灌仏会',
  'buddha_s_birthday.title:ja:Indonesia': '灌仏会',
  'g30_international_banking_seminar.title:ja:DEFAULT': 'G30 国際バンキングセミナー',
  'g30_international_banking_seminar.title:ja:United States': 'G30 国際バンキングセミナー',
  'g20-conference.title:ja:DEFAULT': 'G20会議',
  'g20-conference.title:ja:Germany': 'G20会議',
  'g20_summit_meeting.title:ja:DEFAULT': 'G20サミット会議',
  'g20_summit_meeting.title:ja:Japan': 'G20サミット会議',
  'g20_summit.title:ja:DEFAULT': 'G20サミット',
  'g20_summit.title:ja:Germany': 'G20サミット',
  'boe_quarterly_bulletin_q2.title:ja:DEFAULT': 'イングランド銀行第2四半期報告書',
  'boe_quarterly_bulletin_q2.title:ja:United Kingdom': 'イングランド銀行第2四半期報告書',
  'g20_meeting.title:ja:DEFAULT': 'G20会合',
  'g20_meeting.title:ja:Australia': 'G20会合',
  'g20_meeting.title:ja:Japan': 'G20会合',
  'g20_meeting.title:ja:China': 'G20会合',
  'g20_meeting.title:ja:United States': 'G20会合',
  'president_jinping_speech_at_boao_forum.title:ja:DEFAULT':
    'ボアオ・アジアフォーラムでの習近平国家主席講演',
  'president_jinping_speech_at_boao_forum.title:ja:China':
    'ボアオ・アジアフォーラムでの習近平国家主席講演',
  'g20_foreign_ministers_meeting.title:ja:DEFAULT': 'G20外相会議',
  'g20_foreign_ministers_meeting.title:ja:Germany': 'G20外相会議',
  'g20_finance_and_central_bank_deputies_meeting.title:ja:DEFAULT': '',
  'g20_finance_and_central_bank_deputies_meeting.title:ja:Italy': '',
  'g20_finance_ministers_and_central_bank_governors_meeting.title:ja:DEFAULT':
    'G20 財務大臣・中央銀行総裁会議',
  'g20_finance_ministers_and_central_bank_governors_meeting.title:ja:United States':
    'G20 財務大臣・中央銀行総裁会議',
  'rba_lowe_speaks_before_parliament.title:ja:DEFAULT': 'RBAロウ総裁議会演説',
  'rba_lowe_speaks_before_parliament.title:ja:Australia': 'RBAロウ総裁議会演説',
  'riksbank_ingves_speech.title:ja:DEFAULT': 'リクスバンク（スウェーデン中銀）イングベス総裁講演',
  'riksbank_ingves_speech.title:ja:Sweden': 'リクスバンク（スウェーデン中銀）イングベス総裁講演',
  'financial_stability_report_2018.title:ja:DEFAULT': '2018年金融安定性報告書',
  'financial_stability_report_2018.title:ja:Sweden': '2018年金融安定性報告書',
  'snb_quarterly_bulletin.title:ja:DEFAULT': 'スイス銀行四半期報告書',
  'snb_quarterly_bulletin.title:ja:Switzerland': 'スイス銀行四半期報告書',
  'european_council_video_conference.title:ja:DEFAULT': '欧州理事会ビデオ会議',
  'european_council_video_conference.title:ja:European Union': '欧州理事会ビデオ会議',
  'g20_finance_ministers_and_cb_governors_meeting.title:ja:DEFAULT': 'G20財務相・中央銀行総裁会合',
  'g20_finance_ministers_and_cb_governors_meeting.title:ja:Indonesia':
    'G20財務相・中央銀行総裁会合',
  'g20_finance_ministers_and_cb_governors_meeting.title:ja:United States':
    'G20財務相・中央銀行総裁会合',
  'g20_finance_ministers_and_cb_governors_meeting.title:ja:Germany': 'G20財務相・中央銀行総裁会合',
  'tankan_large_manufacturing_outlook.title:ja:DEFAULT': '日銀短観大企業・製造業先行き判断',
  'tankan_large_manufacturing_outlook.title:ja:Japan': '日銀短観大企業・製造業先行き判断',
  'mni_business_sentiment.title:ja:DEFAULT': 'MNI企業景況感指数',
  'mni_business_sentiment.title:ja:China': 'MNI企業景況感指数',
  'employment_change_yoy_final.title:ja:DEFAULT': '雇用変化（前年比）（確報値）',
  'employment_change_yoy_final.title:ja:Euro Area': '雇用変化（前年比）（確報値）',
  'g20_fin_ministers_and_cb_governors_meeting.title:ja:DEFAULT': 'G20財務相・中央銀行総裁会合',
  'g20_fin_ministers_and_cb_governors_meeting.title:ja:United States':
    'G20財務相・中央銀行総裁会合',
  'g20_fin_ministers_and_cb_governors_meeting.title:ja:China': 'G20財務相・中央銀行総裁会合',
  'eve_of_pesach_passover.title:ja:DEFAULT': 'エレブ・ペサハ（過ぎ越しの祭り）',
  'eve_of_pesach_passover.title:ja:Israel': 'エレブ・ペサハ（過ぎ越しの祭り）',
  'fulltime_employment.title:ja:DEFAULT': 'フルタイム雇用者数',
  'fulltime_employment.title:ja:Australia': 'フルタイム雇用者数',
  'full_time_employment_chg.title:ja:DEFAULT': 'フルタイム雇用者数変化',
  'full_time_employment_chg.title:ja:Canada': 'フルタイム雇用者数変化',
  'full_time_employment_chg.title:ja:Australia': 'フルタイム雇用者数変化',
  'employment_wages_qoq.title:ja:DEFAULT': '雇用賃金（前四半期比）',
  'employment_wages_qoq.title:ja:United States': '雇用賃金（前四半期比）',
  'full_employment_change.title:ja:DEFAULT': 'フルタイム雇用者数変化',
  'full_employment_change.title:ja:Canada': 'フルタイム雇用者数変化',
  'exports.title:ja:DEFAULT': '輸出',
  'exports.title:ja:Philippines': '輸出',
  'exports.title:ja:Russia': '輸出',
  'exports.title:ja:Portugal': '輸出',
  'exports.title:ja:China': '輸出',
  'exports.title:ja:South Korea': '輸出',
  'exports.title:ja:Taiwan': '輸出',
  'exports.title:ja:Canada': '輸出',
  'exports.title:ja:United States': '輸出',
  'exports.title:ja:Israel': '輸出',
  'exports.title:ja:New Zealand': '輸出',
  'exports.title:ja:Brazil': '輸出',
  'exports.title:ja:Hong Kong': '輸出',
  'exports.title:ja:South Africa': '輸出',
  'exports.title:ja:Japan': '輸出',
  'exports.title:ja:Mexico': '輸出',
  'exports.title:ja:Czech Republic': '輸出',
  'exports.title:ja:Thailand': '輸出',
  'exports.title:ja:Indonesia': '輸出',
  'friday_after_ascension_day.title:ja:DEFAULT': 'キリストの昇天後の金曜日',
  'friday_after_ascension_day.title:ja:Belgium': 'キリストの昇天後の金曜日',
  'french_and_german_leaders_meeting_on_greece.title:ja:DEFAULT':
    'ギリシャ問題に関する独仏首脳会議',
  'french_and_german_leaders_meeting_on_greece.title:ja:Euro Area':
    'ギリシャ問題に関する独仏首脳会議',
  'employment_benefits_qoq.title:ja:DEFAULT': '福利厚生（前四半期比）',
  'employment_benefits_qoq.title:ja:United States': '福利厚生（前四半期比）',
  'boe_cleland_speech.title:ja:DEFAULT': 'イングランド銀行クレランド氏講演',
  'boe_cleland_speech.title:ja:United Kingdom': 'イングランド銀行クレランド氏講演',
  'boj_interest_rate_decision.title:ja:DEFAULT': '日銀金利決定',
  'boj_interest_rate_decision.title:ja:Japan': '日銀金利決定',
  'private_spending_yoy.title:ja:DEFAULT': '民間支出（前年比）',
  'private_spending_yoy.title:ja:Mexico': '民間支出（前年比）',
  'wholesale_sales_mom.title:ja:DEFAULT': '卸売売上高（前月比）',
  'wholesale_sales_mom.title:ja:Canada': '卸売売上高（前月比）',
  'frankfurt_finance_summit_2017.title:ja:DEFAULT': 'フランクフルト金融サミット2017',
  'frankfurt_finance_summit_2017.title:ja:Germany': 'フランクフルト金融サミット2017',
  'fourth_day_of_lunar_new_year.title:ja:DEFAULT': '旧正月4日目',
  'fourth_day_of_lunar_new_year.title:ja:Hong Kong': '旧正月4日目',
  'consumer_inflation_expectations.title:ja:DEFAULT': '消費者インフレ予想',
  'consumer_inflation_expectations.title:ja:Euro Area': '消費者インフレ予想',
  'consumer_inflation_expectations.title:ja:United Kingdom': '消費者インフレ予想',
  'consumer_inflation_expectations.title:ja:Australia': '消費者インフレ予想',
  'consumer_inflation_expectations.title:ja:United States': '消費者インフレ予想',
  'consumer_inflation_expectations.title:ja:Sweden': '消費者インフレ予想',
  'foreign_securities_purchases.title:ja:DEFAULT': '外国証券の購入',
  'foreign_securities_purchases.title:ja:Canada': '外国証券の購入',
  'snb_interest_rate_decison.title:ja:DEFAULT': 'スイス国立銀行（SNB）政策金利決定',
  'snb_interest_rate_decison.title:ja:Switzerland': 'スイス国立銀行（SNB）政策金利決定',
  'foreign_direct_investment_yoy.title:ja:DEFAULT': '海外直接投資（前年比）',
  'foreign_direct_investment_yoy.title:ja:Indonesia': '海外直接投資（前年比）',
  'food_inflation_yoy.title:ja:DEFAULT': '食品インフレ率（前年比）',
  'food_inflation_yoy.title:ja:New Zealand': '食品インフレ率（前年比）',
  'rba_gov_kent_speech.title:ja:DEFAULT': 'RBAケント総裁補講演',
  'rba_gov_kent_speech.title:ja:Australia': 'RBAケント総裁補講演',
  'european_council_summit.title:ja:DEFAULT': '欧州理事会サミット',
  'european_council_summit.title:ja:Euro Area': '欧州理事会サミット',
  'cb_leading_index.title:ja:DEFAULT': 'コンファレンスボード（CB）景気先行指数',
  'cb_leading_index.title:ja:Australia': 'コンファレンスボード（CB）景気先行指数',
  'cb_leading_index.title:ja:United Kingdom': 'コンファレンスボード（CB）景気先行指数',
  'financial_stability_report.title:ja:DEFAULT': '金融安定性報告書',
  'financial_stability_report.title:ja:United Kingdom': '金融安定性報告書',
  'financial_stability_report.title:ja:Sweden': '金融安定性報告書',
  'financial_stability_report.title:ja:New Zealand': '金融安定性報告書',
  'statement_on_monetary_policy.title:ja:DEFAULT': '金融政策に関する声明',
  'statement_on_monetary_policy.title:ja:Australia': '金融政策に関する声明',
  'financial_accounts_of_the_us.title:ja:DEFAULT': '米国金融収支',
  'financial_accounts_of_the_us.title:ja:United States': '米国金融収支',
  'finance_minister_osborne_speech.title:ja:DEFAULT': 'オズボーン財務相講演',
  'finance_minister_osborne_speech.title:ja:United Kingdom': 'オズボーン財務相講演',
  'finance_minister_morneau_speech.title:ja:DEFAULT': 'モルノー財務相講演',
  'finance_minister_morneau_speech.title:ja:Canada': 'モルノー財務相講演',
  'budget_report.title:ja:DEFAULT': '予算報告',
  'budget_report.title:ja:United Kingdom': '予算報告',
  'fed_yellen_testimony_to_the_congress.title:ja:DEFAULT': 'FRBイエレン議長議会証言',
  'fed_yellen_testimony_to_the_congress.title:ja:United States': 'FRBイエレン議長議会証言',
  'human_rights_day.title:ja:DEFAULT': '人権デー',
  'human_rights_day.title:ja:South Africa': '人権デー',
  'federal_tax_revenues.title:ja:DEFAULT': '連邦税収入',
  'federal_tax_revenues.title:ja:Brazil': '連邦税収入',
  'fed_barkins_speech.title:ja:DEFAULT': 'FRBバーキン氏理事',
  'fed_barkins_speech.title:ja:United States': 'FRBバーキン氏理事',
  'federal_fast_day.title:ja:DEFAULT': '断食祭',
  'federal_fast_day.title:ja:Switzerland': '断食祭',
  'nab_business_survey.title:ja:DEFAULT': 'NAB企業信頼感指数',
  'nab_business_survey.title:ja:Australia': 'NAB企業信頼感指数',
  'fed_press_conference.title:ja:DEFAULT': 'FRB記者会見',
  'fed_press_conference.title:ja:United States': 'FRB記者会見',
  'fiscal_balance.title:ja:DEFAULT': '財政収支',
  'fiscal_balance.title:ja:Mexico': '財政収支',
  'fed_yellen_testymony_-_senate.title:ja:DEFAULT': 'FRBイエレン議長証言－上院',
  'fed_yellen_testymony_-_senate.title:ja:United States': 'FRBイエレン議長証言－上院',
  'fed_bullard_speaks.title:ja:DEFAULT': 'FRBブラード氏講演',
  'fed_bullard_speaks.title:ja:United States': 'FRBブラード氏講演',
  'fed_yellen_speech.title:ja:DEFAULT': 'FRBイエレン議長講演',
  'fed_yellen_speech.title:ja:United States': 'FRBイエレン議長講演',
  'the_king_s_birthday.title:ja:DEFAULT': '国王陛下誕生日',
  'the_king_s_birthday.title:ja:Thailand': '国王陛下誕生日',
  'fed_yellen_imf_s_lagarde_speech.title:ja:DEFAULT': 'FRBイエレン議長とIMFラガルド専務理事講演',
  'fed_yellen_imf_s_lagarde_speech.title:ja:United States':
    'FRBイエレン議長とIMFラガルド専務理事講演',
  'civic_holiday.title:ja:DEFAULT': 'シビック・ホリデー',
  'civic_holiday.title:ja:Canada': 'シビック・ホリデー',
  'fed_quarles_testimony.title:ja:DEFAULT': 'FRBクォ―ルズ氏証言',
  'fed_quarles_testimony.title:ja:United States': 'FRBクォ―ルズ氏証言',
  'ecb_monetary_policy_meeting_accounts.title:ja:DEFAULT': 'ECB金融政策会合議事録',
  'ecb_monetary_policy_meeting_accounts.title:ja:Euro Area': 'ECB金融政策会合議事録',
  'fed_quarles_speech.title:ja:DEFAULT': 'FRBクォ―ルズ氏演説',
  'fed_quarles_speech.title:ja:United States': 'FRBクォ―ルズ氏演説',
  'employment_change_s_a.title:ja:DEFAULT': '雇用変化（季節調整済み）',
  'employment_change_s_a.title:ja:Australia': '雇用変化（季節調整済み）',
  'new_years_s_day.title:ja:DEFAULT': '元旦',
  'new_years_s_day.title:ja:Japan': '元旦',
  'fed_bank_stress_test_results.title:ja:DEFAULT': 'FRB銀行ストレステスト結果',
  'fed_bank_stress_test_results.title:ja:United States': 'FRB銀行ストレステスト結果',
  'fed_mester_speech.title:ja:DEFAULT': 'FRBメスター氏講演',
  'fed_mester_speech.title:ja:Euro Area': 'FRBメスター氏講演',
  'fed_mester_speech.title:ja:United States': 'FRBメスター氏講演',
  'ash_wednesday.title:ja:DEFAULT': '灰の水曜日',
  'ash_wednesday.title:ja:Brazil': '灰の水曜日',
  'bba_mortgage_approvals.title:ja:DEFAULT': 'BBA（英銀行協会）住宅ローン承認件数',
  'bba_mortgage_approvals.title:ja:United Kingdom': 'BBA（英銀行協会）住宅ローン承認件数',
  'president_biden_remarks_on_the_economy.title:ja:DEFAULT': 'バイデン大統領の経済に関するコメント',
  'president_biden_remarks_on_the_economy.title:ja:United States':
    'バイデン大統領の経済に関するコメント',
  'fed_meeting_under_expedited_procedures.title:ja:DEFAULT': '迅速手続き下のFRB会合',
  'fed_meeting_under_expedited_procedures.title:ja:United States': '迅速手続き下のFRB会合',
  'fed_lacker_speech.title:ja:DEFAULT': 'FRBラッカー氏講演',
  'fed_lacker_speech.title:ja:United States': 'FRBラッカー氏講演',
  'fed_kaplan_speech.title:ja:DEFAULT': 'FRBカプラン氏講演',
  'fed_kaplan_speech.title:ja:United States': 'FRBカプラン氏講演',
  'boe_deputy_gov_bailey_speech.title:ja:DEFAULT': 'イングランド銀行ベイリー副総裁講演',
  'boe_deputy_gov_bailey_speech.title:ja:United Kingdom': 'イングランド銀行ベイリー副総裁講演',
  'fed_george_speech.title:ja:DEFAULT': 'FRBジョージ氏講演',
  'fed_george_speech.title:ja:United States': 'FRBジョージ氏講演',
  'fed_fischer_speech.title:ja:DEFAULT': 'FRBフィッシャー氏講演',
  'fed_fischer_speech.title:ja:United States': 'FRBフィッシャー氏講演',
  'late_summer_bank_holiday.title:ja:DEFAULT': '夏季のバンク・ホリデー（8月最終月曜）',
  'late_summer_bank_holiday.title:ja:United Kingdom': '夏季のバンク・ホリデー（8月最終月曜）',
  'fed_dudley_speech.title:ja:DEFAULT': 'FRBダドリー氏講演',
  'fed_dudley_speech.title:ja:United States': 'FRBダドリー氏講演',
  'fed_clarida_speech.title:ja:DEFAULT': 'FRBクラリダ氏講演',
  'fed_clarida_speech.title:ja:United States': 'FRBクラリダ氏講演',
  'fed_chairman_yellen_speaks.title:ja:DEFAULT': 'FRBイエレン議長講演',
  'fed_chairman_yellen_speaks.title:ja:United States': 'FRBイエレン議長講演',
  'aggregate_demand_qoq.title:ja:DEFAULT': '総需要（前四半期比）',
  'aggregate_demand_qoq.title:ja:Mexico': '総需要（前四半期比）',
  'fed_chairman_bernanke_speech.title:ja:DEFAULT': 'FRBバーナンキ議長講演',
  'fed_chairman_bernanke_speech.title:ja:United States': 'FRBバーナンキ議長講演',
  'reuters_econometer.title:ja:DEFAULT': 'ロイター・エコノメーター',
  'reuters_econometer.title:ja:South Africa': 'ロイター・エコノメーター',
  'fed_chairman_bernanke_speaks.title:ja:DEFAULT': 'FRBバーナンキ議長講演',
  'fed_chairman_bernanke_speaks.title:ja:United States': 'FRBバーナンキ議長講演',
  'fed_chair_powell_testimony.title:ja:DEFAULT': 'FRBパウエル議長証言',
  'fed_chair_powell_testimony.title:ja:United States': 'FRBパウエル議長証言',
  '6-month_letras_auction.title:ja:DEFAULT': 'スペイン6カ月物国債入札',
  '6-month_letras_auction.title:ja:Spain': 'スペイン6カ月物国債入札',
  'fed_chair_powell_speech.title:ja:DEFAULT': 'FRBパウエル議長講演',
  'fed_chair_powell_speech.title:ja:United States': 'FRBパウエル議長講演',
  'fed_bowman_testimony.title:ja:DEFAULT': 'FRBボウマン氏証言',
  'fed_bowman_testimony.title:ja:United States': 'FRBボウマン氏証言',
  'fed_brainard_testimony.title:ja:DEFAULT': 'FRBブレイナード氏証言',
  'fed_brainard_testimony.title:ja:United States': 'FRBブレイナード氏証言',
  'fed_bostic_speech.title:ja:DEFAULT': 'FRBボスティック氏講演',
  'fed_bostic_speech.title:ja:United States': 'FRBボスティック氏講演',
  'fed_bernanke_speech.title:ja:DEFAULT': 'FRBバーナンキ議長講演',
  'fed_bernanke_speech.title:ja:United States': 'FRBバーナンキ議長講演',
  'fed_barkin_speech.title:ja:DEFAULT': 'FRBバーキン氏講演',
  'fed_barkin_speech.title:ja:United States': 'FRBバーキン氏講演',
  'central_bank_reserves.title:ja:DEFAULT': '中央銀行準備高（＄）',
  'central_bank_reserves.title:ja:Russia': '中央銀行準備高（＄）',
  'fed_bank_stress_test_results_2nd_round.title:ja:DEFAULT': 'FRB銀行ストレステスト結果第2弾',
  'fed_bank_stress_test_results_2nd_round.title:ja:United States': 'FRB銀行ストレステスト結果第2弾',
  'boe_haldane_speech.title:ja:DEFAULT': 'イングランド銀行ハルデーン氏講演',
  'boe_haldane_speech.title:ja:United Kingdom': 'イングランド銀行ハルデーン氏講演',
  'constitution_day.title:ja:DEFAULT': '憲法記念日',
  'constitution_day.title:ja:Thailand': '憲法記念日',
  'constitution_day.title:ja:Denmark': '憲法記念日',
  'constitution_day.title:ja:Spain': '憲法記念日',
  'constitution_day.title:ja:Mexico': '憲法記念日',
  'constitution_day.title:ja:Japan': '憲法記念日',
  'constitution_day.title:ja:Poland': '憲法記念日',
  'constitution_day.title:ja:Norway': '憲法記念日',
  'swiss_national_day.title:ja:DEFAULT': 'スイス建国記念日',
  'swiss_national_day.title:ja:Switzerland': 'スイス建国記念日',
  'fed_chairman_bernanke_testifies.title:ja:DEFAULT': 'FRBバーナンキ議長証言',
  'fed_chairman_bernanke_testifies.title:ja:United States': 'FRBバーナンキ議長証言',
  'chulalongkorn_day.title:ja:DEFAULT': 'チュラロンコン大王祭',
  'chulalongkorn_day.title:ja:Thailand': 'チュラロンコン大王祭',
  'loan_prime_rate_1y.title:ja:DEFAULT': '貸付プライムレート1年物',
  'loan_prime_rate_1y.title:ja:China': '貸付プライムレート1年物',
  'gdp_growth_annualized_3rd_est.title:ja:DEFAULT': 'GDP年率成長率（3次速報）、',
  'gdp_growth_annualized_3rd_est.title:ja:Israel': 'GDP年率成長率（3次速報）、',
  'feast_of_tabernacles_eve.title:ja:DEFAULT': '仮庵の祭り前夜',
  'feast_of_tabernacles_eve.title:ja:Israel': '仮庵の祭り前夜',
  'feast_of_tabernacles.title:ja:DEFAULT': '仮庵の祭り',
  'feast_of_tabernacles.title:ja:Israel': '仮庵の祭り',
  'father_s_day.title:ja:DEFAULT': '父の日',
  'father_s_day.title:ja:Thailand': '父の日',
  'employment_change_qoq_final.title:ja:DEFAULT': '雇用者数変化（前四半期比）（確報値）',
  'employment_change_qoq_final.title:ja:Euro Area': '雇用者数変化（前四半期比）（確報値）',
  'riksbank_ingves_speaks_before_parliament.title:ja:DEFAULT':
    'リクスバンク（スウェーデン中銀）イングベス総裁議会演説',
  'riksbank_ingves_speaks_before_parliament.title:ja:Sweden':
    'リクスバンク（スウェーデン中銀）イングベス総裁議会演説',
  'manufacturing_prod_mom.title:ja:DEFAULT': '製造業生産（前月比）',
  'manufacturing_prod_mom.title:ja:South Africa': '製造業生産（前月比）',
  'fast_day.title:ja:DEFAULT': '断食の日',
  'fast_day.title:ja:Israel': '断食の日',
  'factory_utilization.title:ja:DEFAULT': '設備稼働率',
  'factory_utilization.title:ja:Brazil': '設備稼働率',
  'bali_s_day_of_silence_and_hindu_new_year.title:ja:DEFAULT': 'バリの沈黙の日/ヒンドゥー教の新年',
  'bali_s_day_of_silence_and_hindu_new_year.title:ja:Indonesia':
    'バリの沈黙の日/ヒンドゥー教の新年',
  'ism_non-manufacturing_business_activity.title:ja:DEFAULT': 'ISM非製造業事業活動指数',
  'ism_non-manufacturing_business_activity.title:ja:United States': 'ISM非製造業事業活動指数',
  'factory_orders_yoy.title:ja:DEFAULT': '工場受注（前年比）',
  'factory_orders_yoy.title:ja:Germany': '工場受注（前年比）',
  'fomc_policy_statement_and_press_conference.title:ja:DEFAULT': 'FOMC金融政策声明および記者会見',
  'fomc_policy_statement_and_press_conference.title:ja:United States':
    'FOMC金融政策声明および記者会見',
  'fomc_minutes.title:ja:DEFAULT': 'FOMC議事録',
  'fomc_minutes.title:ja:United States': 'FOMC議事録',
  'fomc_member_yellen_speech.title:ja:DEFAULT': 'FOMCイエレン委員講演',
  'fomc_member_yellen_speech.title:ja:United States': 'FOMCイエレン委員講演',
  'fgv_consumer_confidence.title:ja:DEFAULT': 'FGV消費者信頼感指数',
  'fgv_consumer_confidence.title:ja:Brazil': 'FGV消費者信頼感指数',
  'european_council_meeting.title:ja:DEFAULT': '欧州理事会サミット',
  'european_council_meeting.title:ja:Euro Area': '欧州理事会サミット',
  'flash_gdp_forecast.title:ja:DEFAULT': 'GDP予想（速報値）',
  'flash_gdp_forecast.title:ja:Canada': 'GDP予想（速報値）',
  'exports_prel.title:ja:DEFAULT': '輸出（速報値）',
  'exports_prel.title:ja:India': '輸出（速報値）',
  'treasury_select_committee_hearing.title:ja:DEFAULT': '財務省特別委員会聴聞会',
  'treasury_select_committee_hearing.title:ja:United Kingdom': '財務省特別委員会聴聞会',
  'exports_mom_s_a.title:ja:DEFAULT': '輸出（前月比）（季節調整済み）',
  'exports_mom_s_a.title:ja:Germany': '輸出（前月比）（季節調整済み）',
  'ecofin_meeting_on_greece.title:ja:DEFAULT': 'ギリシャ問題に関するユーロ圏経済・財務相会合',
  'ecofin_meeting_on_greece.title:ja:Euro Area': 'ギリシャ問題に関するユーロ圏経済・財務相会合',
  'exports_mom.title:ja:DEFAULT': '輸出（前月比）',
  'exports_mom.title:ja:Germany': '輸出（前月比）',
  'exports_mom.title:ja:Australia': '輸出（前月比）',
  'export_prices_yoy.title:ja:DEFAULT': '輸出物価指数（前年比）',
  'export_prices_yoy.title:ja:Finland': '輸出物価指数（前年比）',
  'export_prices_yoy.title:ja:South Korea': '輸出物価指数（前年比）',
  'export_prices_yoy.title:ja:Singapore': '輸出物価指数（前年比）',
  'export_prices_yoy.title:ja:United States': '輸出物価指数（前年比）',
  'european_council_meeting.title:ja:European Union': '欧州理事会サミット',
  'export_prices_qoq.title:ja:DEFAULT': '輸出物価指数（前四半期比）',
  'export_prices_qoq.title:ja:New Zealand': '輸出物価指数（前四半期比）',
  'export_prices_qoq.title:ja:Australia': '輸出物価指数（前四半期比）',
  'ambedkar_jayanti.title:ja:DEFAULT': 'アンベドカール・ジャヤンティ',
  'ambedkar_jayanti.title:ja:India': 'アンベドカール・ジャヤンティ',
  'money_supply_m3.title:ja:DEFAULT': 'マネーサプライM3',
  'money_supply_m3.title:ja:Hong Kong': 'マネーサプライM3',
  'export_prices_mom.title:ja:DEFAULT': '輸出物価指数（前月比）',
  'export_prices_mom.title:ja:United States': '輸出物価指数（前月比）',
  'eurozone_leaders_meeting.title:ja:DEFAULT': 'ユーロ圏首脳会合',
  'eurozone_leaders_meeting.title:ja:Euro Area': 'ユーロ圏首脳会合',
  'european_union_membership_referendum.title:ja:DEFAULT': '欧州連合の離脱是非を問う国民投票',
  'european_union_membership_referendum.title:ja:United Kingdom':
    '欧州連合の離脱是非を問う国民投票',
  'average_hourly_earnings_mom.title:ja:DEFAULT': '平均時給（前月比）',
  'average_hourly_earnings_mom.title:ja:United States': '平均時給（前月比）',
  'european_commission_hill_speech.title:ja:DEFAULT': 'ヒル欧州委員講演',
  'european_commission_hill_speech.title:ja:Euro Area': 'ヒル欧州委員講演',
  'european_commission_growth_forecasts.title:ja:DEFAULT': '欧州委員会成長見通し',
  'european_commission_growth_forecasts.title:ja:Euro Area': '欧州委員会成長見通し',
  'makha_bucha.title:ja:DEFAULT': '万仏祭（マカブチャ）',
  'makha_bucha.title:ja:Thailand': '万仏祭（マカブチャ）',
  'european_commission_forecasts.title:ja:DEFAULT': '欧州委員会予測',
  'european_commission_forecasts.title:ja:European Union': '欧州委員会予測',
  'european_commission_forecasts.title:ja:Euro Area': '欧州委員会予測',
  'eurogroup_video_conference.title:ja:DEFAULT': 'ユーロ圏財務相会合ビデオ会議',
  'eurogroup_video_conference.title:ja:Euro Area': 'ユーロ圏財務相会合ビデオ会議',
  'european_business_summit.title:ja:DEFAULT': '欧州ビジネスサミット',
  'european_business_summit.title:ja:Euro Area': '欧州ビジネスサミット',
  'goods_trade_balance.title:ja:DEFAULT': '商品貿易収支',
  'goods_trade_balance.title:ja:United Kingdom': '商品貿易収支',
  'goods_trade_balance.title:ja:United States': '商品貿易収支',
  'euro_zone_leaders_summit_on_greece.title:ja:DEFAULT': 'ギリシャ問題に関するユーロ圏首脳会議',
  'euro_zone_leaders_summit_on_greece.title:ja:Euro Area': 'ギリシャ問題に関するユーロ圏首脳会議',
  'euro_summit.title:ja:DEFAULT': 'ユーロ圏首脳会議',
  'euro_summit.title:ja:European Union': 'ユーロ圏首脳会議',
  'euro_summit.title:ja:Euro Area': 'ユーロ圏首脳会議',
  '3-year_btan_auction.title:ja:DEFAULT': 'フランス3年物国債入札',
  '3-year_btan_auction.title:ja:France': 'フランス3年物国債入札',
  'anz_job_advertisements.title:ja:DEFAULT': 'ANZ求人広告件数',
  'anz_job_advertisements.title:ja:Australia': 'ANZ求人広告件数',
  'euro_finance_week_2017.title:ja:DEFAULT': '2017年ユーロ・ファイナンス・ウィーク',
  'euro_finance_week_2017.title:ja:Germany': '2017年ユーロ・ファイナンス・ウィーク',
  'establishment_day.title:ja:DEFAULT': '香港特別行政府設立記念日',
  'establishment_day.title:ja:Hong Kong': '香港特別行政府設立記念日',
  'international_women_s_day_holiday.title:ja:DEFAULT': '国際女性デーの休日',
  'international_women_s_day_holiday.title:ja:Russia': '国際女性デーの休日',
  'epiphany.title:ja:DEFAULT': '公現祭',
  'epiphany.title:ja:Colombia': '公現祭',
  'epiphany.title:ja:Spain': '公現祭',
  'epiphany.title:ja:Italy': '公現祭',
  'epiphany.title:ja:Sweden': '公現祭',
  'epiphany.title:ja:Austria': '公現祭',
  'epiphany.title:ja:Poland': '公現祭',
  'epiphany.title:ja:Finland': '公現祭',
  'epiphany.title:ja:Germany': '公現祭',
  'epiphany.title:ja:Greece': '公現祭',
  'end_of_ramadan.title:ja:DEFAULT': 'ラマダン終了日',
  'end_of_ramadan.title:ja:Singapore': 'ラマダン終了日',
  'end_of_ramadan.title:ja:India': 'ラマダン終了日',
  'end_of_ramadan.title:ja:Philippines': 'ラマダン終了日',
  'end_of_ramadan.title:ja:Indonesia': 'ラマダン終了日',
  'gdp_growth_annualized_qoq_final.title:ja:DEFAULT': 'GDP年率成長率（前四半期比）（確報値）',
  'gdp_growth_annualized_qoq_final.title:ja:Japan': 'GDP年率成長率（前四半期比）（確報値）',
  'employment_cost_index_qoq.title:ja:DEFAULT': '雇用コスト指数（前四半期比）',
  'employment_cost_index_qoq.title:ja:United States': '雇用コスト指数（前四半期比）',
  'hm_the_king_s_memorial_day.title:ja:DEFAULT': '国王誕生日',
  'hm_the_king_s_memorial_day.title:ja:Thailand': '国王誕生日',
  'employment_change.title:ja:DEFAULT': '雇用者数変化',
  'employment_change.title:ja:Canada': '雇用者数変化',
  'employment_change.title:ja:United Kingdom': '雇用者数変化',
  'employment_change.title:ja:Australia': '雇用者数変化',
  'rba_kearns_speech.title:ja:DEFAULT': 'RBAカーンズ氏講演',
  'rba_kearns_speech.title:ja:Australia': 'RBAカーンズ氏講演',
  'employed_persons.title:ja:DEFAULT': '被雇用者数',
  'employed_persons.title:ja:Germany': '被雇用者数',
  'electronic_retail_card_spending_yoy.title:ja:DEFAULT': '電子カード小売売上高（前年比）',
  'electronic_retail_card_spending_yoy.title:ja:New Zealand': '電子カード小売売上高（前年比）',
  'boe_mccafferty_speech.title:ja:DEFAULT': 'イングランド銀行マカファーティ氏講演',
  'boe_mccafferty_speech.title:ja:United Kingdom': 'イングランド銀行マカファーティ氏講演',
  'immaculate_conception.title:ja:DEFAULT': '無原罪の聖マリアの祭日',
  'immaculate_conception.title:ja:Colombia': '無原罪の聖マリアの祭日',
  'immaculate_conception.title:ja:Austria': '無原罪の聖マリアの祭日',
  'immaculate_conception.title:ja:Italy': '無原罪の聖マリアの祭日',
  'immaculate_conception.title:ja:Portugal': '無原罪の聖マリアの祭日',
  'immaculate_conception.title:ja:Spain': '無原罪の聖マリアの祭日',
  'electronic_retail_card_spending_mom.title:ja:DEFAULT': '電子カード小売売上高（前月比）',
  'electronic_retail_card_spending_mom.title:ja:New Zealand': '電子カード小売売上高（前月比）',
  '5-year_tips_auction.title:ja:DEFAULT': 'TIPS物価連動5年国債入札',
  '5-year_tips_auction.title:ja:United States': 'TIPS物価連動5年国債入札',
  'pm_conte_press_conference.title:ja:DEFAULT': 'コンテ首相記者会見',
  'pm_conte_press_conference.title:ja:Italy': 'コンテ首相記者会見',
  'industrial_inventories_qoq.title:ja:DEFAULT': '工業在庫（前四半期比）',
  'industrial_inventories_qoq.title:ja:Sweden': '工業在庫（前四半期比）',
  'election_day.title:ja:DEFAULT': '選挙日',
  'election_day.title:ja:Philippines': '選挙日',
  'aggregate_demand_yoy.title:ja:DEFAULT': '総需要（前年比）',
  'aggregate_demand_yoy.title:ja:Mexico': '総需要（前年比）',
  'tankan_large_manufacturers_index.title:ja:DEFAULT': '日銀短観大企業・製造業業況判断指数',
  'tankan_large_manufacturers_index.title:ja:Japan': '日銀短観大企業・製造業業況判断指数',
  'october_bank_holiday.title:ja:DEFAULT': '10月のバンク・ホリデー',
  'october_bank_holiday.title:ja:Ireland': '10月のバンク・ホリデー',
  'economic_sentiment_index.title:ja:DEFAULT': '景況感指数',
  'economic_sentiment_index.title:ja:Switzerland': '景況感指数',
  'economic_bulletin.title:ja:DEFAULT': '経済報告',
  'economic_bulletin.title:ja:Euro Area': '経済報告',
  'economic_activity_yoy.title:ja:DEFAULT': '経済活動（前月比）',
  'economic_activity_yoy.title:ja:Portugal': '経済活動（前月比）',
  'economic_activity_yoy.title:ja:Mexico': '経済活動（前月比）',
  'ecofin_summit_on_greece.title:ja:DEFAULT': 'ギリシャ問題に関するユーロ圏経済・財務相会合',
  'ecofin_summit_on_greece.title:ja:Euro Area': 'ギリシャ問題に関するユーロ圏経済・財務相会合',
  'easter_saturday.title:ja:DEFAULT': 'イースター・サタデー',
  'easter_saturday.title:ja:Australia': 'イースター・サタデー',
  'boe_churm_speech.title:ja:DEFAULT': 'イングランド銀行チャーム氏講演',
  'boe_churm_speech.title:ja:United Kingdom': 'イングランド銀行チャーム氏講演',
  'easter_monday.title:ja:DEFAULT': 'イースター・マンデー',
  'easter_monday.title:ja:Switzerland': 'イースター・マンデー',
  'easter_monday.title:ja:New Zealand': 'イースター・マンデー',
  'easter_monday.title:ja:Italy': 'イースター・マンデー',
  'easter_monday.title:ja:Ireland': 'イースター・マンデー',
  'easter_monday.title:ja:United Kingdom': 'イースター・マンデー',
  'easter_monday.title:ja:Czech Republic': 'イースター・マンデー',
  'easter_monday.title:ja:Norway': 'イースター・マンデー',
  'easter_monday.title:ja:Belgium': 'イースター・マンデー',
  'easter_monday.title:ja:France': 'イースター・マンデー',
  'easter_monday.title:ja:Poland': 'イースター・マンデー',
  'easter_monday.title:ja:Austria': 'イースター・マンデー',
  'easter_monday.title:ja:Netherlands': 'イースター・マンデー',
  'easter_monday.title:ja:Australia': 'イースター・マンデー',
  'easter_monday.title:ja:Hong Kong': 'イースター・マンデー',
  'easter_monday.title:ja:Denmark': 'イースター・マンデー',
  'easter_monday.title:ja:Finland': 'イースター・マンデー',
  'easter_monday.title:ja:Sweden': 'イースター・マンデー',
  'easter_monday.title:ja:Germany': 'イースター・マンデー',
  'easter_monday.title:ja:Hungary': 'イースター・マンデー',
  'zew_expectations.title:ja:DEFAULT': 'ZEW景況感指数',
  'zew_expectations.title:ja:Switzerland': 'ZEW景況感指数',
  'eu-china_summit.title:ja:DEFAULT': 'EU中国首脳会議',
  'eu-china_summit.title:ja:European Union': 'EU中国首脳会議',
  'eu-china_summit.title:ja:Euro Area': 'EU中国首脳会議',
  'eu-china_summit.title:ja:Belgium': 'EU中国首脳会議',
  'eu-china_summit.title:ja:China': 'EU中国首脳会議',
  '10-year_bond_auction.description:ja:DEFAULT': '',
  '10-year_bond_auction.description:ja:Netherlands':
    '一般的に国債は国家政府により発行され、その国の通貨により値付けされる。国から発行された債券で外貨建てのものは通常ソブリン債と呼ばれる。資金をその国の政府に融資する投資家が必要とする利回りは、予測インフレ率および元本の償還可能性を考慮に含めたものとなる。',
  '10-year_bond_auction.description:ja:Canada': '',
  '10-year_bond_auction.description:ja:Italy':
    '一般的に国債は国家政府により発行され、その国の通貨により値付けされる。国から発行された債券で外貨建てのものは通常ソブリン債と呼ばれる。資金をその国の政府に融資する投資家が必要とする利回りは、予測インフレ率および元本の償還可能性を考慮に含めたものとなる。',
  '10-year_jgb_auction.description:ja:DEFAULT': '',
  '10-year_jgb_auction.description:ja:Japan':
    '一般的に国債は国家政府により発行され、その国の通貨により値付けされる。国から発行された債券で外貨建てのものは通常ソブリン債と呼ばれる。資金をその国の政府に融資する投資家が必要とする利回りは、予測インフレ率および元本の償還可能性を考慮に含めたものとなる。',
  '10-year_note_auction.description:ja:DEFAULT': '',
  '10-year_note_auction.description:ja:United States':
    '一般的に国債は国家政府により発行され、その国の通貨により値付けされる。国から発行された債券で外貨建てのものは通常ソブリン債と呼ばれる。資金をその国の政府に融資する投資家が必要とする利回りは、予測インフレ率および元本の償還可能性を考慮に含めたものとなる。',
  '10-year_tips_auction.description:ja:DEFAULT': '',
  '10-year_tips_auction.description:ja:United States': '',
  '103-day_bill_auction.description:ja:DEFAULT': '',
  '103-day_bill_auction.description:ja:United States': '',
  '105-day_bill_auction.description:ja:DEFAULT': '',
  '105-day_bill_auction.description:ja:United States': '',
  '118-day_bill_auction.description:ja:DEFAULT': '',
  '118-day_bill_auction.description:ja:United States': '',
  '12-month_btf_auction.description:ja:DEFAULT': '',
  '12-month_btf_auction.description:ja:France': '',
  '120-day_bill_auction.description:ja:DEFAULT': '',
  '120-day_bill_auction.description:ja:United States': '',
  '13-week_bill_auction.description:ja:DEFAULT': '',
  '13-week_bill_auction.description:ja:Greece': '',
  '154-day_bill_auction.description:ja:DEFAULT': '',
  '154-day_bill_auction.description:ja:United States': '',
  '1848_revolution_day.description:ja:DEFAULT': '',
  '1848_revolution_day.description:ja:Hungary': '',
  '1st_day_flag_referendum.description:ja:DEFAULT': '',
  '1st_day_flag_referendum.description:ja:New Zealand': '',
  '1st_day_national_people_s_congress.description:ja:DEFAULT': '',
  '1st_day_national_people_s_congress.description:ja:China': '',
  '1st_day_of_muharram_1435.description:ja:DEFAULT': '',
  '1st_day_of_muharram_1435.description:ja:Indonesia': '',
  '2-year_bond_auction.description:ja:DEFAULT': '',
  '2-year_bond_auction.description:ja:Canada': '',
  '2-year_schatz_auction.description:ja:DEFAULT': '',
  '2-year_schatz_auction.description:ja:Germany': '',
  '20-year_btp_auction.description:ja:DEFAULT': '',
  '20-year_btp_auction.description:ja:Italy': '',
  '20-year_bond_auction.description:ja:DEFAULT': '',
  '20-year_bond_auction.description:ja:United States': '',
  '20-year_treasury_gilt_auction.description:ja:DEFAULT': '',
  '20-year_treasury_gilt_auction.description:ja:United Kingdom': '',
  '2016_annual_budget_announcement.description:ja:DEFAULT': '',
  '2016_annual_budget_announcement.description:ja:United Kingdom': '',
  '2016_budget_announcement.description:ja:DEFAULT': '',
  '2016_budget_announcement.description:ja:Singapore': '',
  '2016_budget_announcement.description:ja:Canada': '',
  '2016_budget_presentation.description:ja:DEFAULT': '',
  '2016_budget_presentation.description:ja:South Africa': '',
  '2016_2017_budget_proposal.description:ja:DEFAULT': '',
  '2016_2017_budget_proposal.description:ja:Hong Kong': '',
  '2017-18_budget_release.description:ja:DEFAULT': '',
  '2017-18_budget_release.description:ja:Australia': '',
  '2020_fiscal_budget.description:ja:DEFAULT': '',
  '2020_fiscal_budget.description:ja:Canada': '',
  '2021_national_budget.description:ja:DEFAULT': '',
  '2021_national_budget.description:ja:South Africa': '',
  '21-day_bill_auction.description:ja:DEFAULT': '',
  '21-day_bill_auction.description:ja:United States': '',
  '228_peace_memorial_day.description:ja:DEFAULT': '',
  '228_peace_memorial_day.description:ja:Taiwan': '',
  '26-week_bill_auction.description:ja:DEFAULT': '',
  '26-week_bill_auction.description:ja:Greece': '',
  '26-weeks_bill_auction.description:ja:DEFAULT': '',
  '26-weeks_bill_auction.description:ja:Greece': '',
  '3-month_btf_auction.description:ja:DEFAULT': '',
  '3-month_btf_auction.description:ja:France': '',
  '3-month_letras_auction.description:ja:DEFAULT': '',
  '3-month_letras_auction.description:ja:Spain': '',
  '3-month_t-bill_auction.description:ja:DEFAULT': '',
  '3-month_t-bill_auction.description:ja:Netherlands': '',
  '30-year_btp_auction.description:ja:DEFAULT': '',
  '30-year_btp_auction.description:ja:Italy': '',
  '30-year_bond_auction.description:ja:DEFAULT': '',
  '30-year_bond_auction.description:ja:Canada': '',
  '30-year_bond_auction.description:ja:United States': '',
  '30-year_note_auction.description:ja:DEFAULT': '',
  '30-year_note_auction.description:ja:United States': '',
  '30-year_obligacion_auction.description:ja:DEFAULT': '',
  '30-year_obligacion_auction.description:ja:Spain': '',
  '30-year_tips_auction.description:ja:DEFAULT': '',
  '30-year_tips_auction.description:ja:United States': '',
  '30-year_jgb_auction.description:ja:DEFAULT': '',
  '30-year_jgb_auction.description:ja:Japan': '',
  '30-year_treasury_gilt_auction.description:ja:DEFAULT': '',
  '30-year_treasury_gilt_auction.description:ja:United Kingdom': '',
  '4-year_btan_auction.description:ja:DEFAULT': '',
  '4-year_btan_auction.description:ja:France': '',
  '42-day_bill_auction.description:ja:DEFAULT': '',
  '42-day_bill_auction.description:ja:United States': '',
  '2-year_frn_auction.description:ja:DEFAULT': '',
  '2-year_frn_auction.description:ja:United States': '',
  '43-day_bill_auction.description:ja:DEFAULT': '',
  '43-day_bill_auction.description:ja:United States': '',
  '5-year_btan_auction.description:ja:DEFAULT': '',
  '5-year_btan_auction.description:ja:France': '',
  '5-year_bobl_auction.description:ja:DEFAULT': '',
  '5-year_bobl_auction.description:ja:Germany': '',
  '5-y_bond_auction.description:ja:DEFAULT': '',
  '5-y_bond_auction.description:ja:Italy': '',
  '50-year_bond_auction.description:ja:DEFAULT': '',
  '50-year_bond_auction.description:ja:Canada': '',
  '52-week_bill_auction.description:ja:DEFAULT': '',
  '52-week_bill_auction.description:ja:United States': '',
  '6-month_btf_auction.description:ja:DEFAULT': '',
  '6-month_btf_auction.description:ja:France': '',
  '6-month_bill_auction.description:ja:DEFAULT': '',
  '6-month_bill_auction.description:ja:Netherlands': '',
  '6-month_bill_auction.description:ja:United States': '',
  '8-week_bill_auction.description:ja:DEFAULT': '',
  '8-week_bill_auction.description:ja:United States': '',
  'absa_manufacturing_pmi.description:ja:DEFAULT': '',
  'absa_manufacturing_pmi.description:ja:South Africa':
    'Absa製造業購買担当者景気指数（PMI）は、南アフリカの製造業購買担当者を調査対象とする月次指数で、製造業の業況を示す先行指標である。指数が50%超では製造業の経済は概して拡大しており、50%未満は縮小していることを示す。',
  'adp_employment_change.description:ja:DEFAULT': '',
  'adp_employment_change.description:ja:Canada':
    'ADPカナダ雇用統計は、カナダにおける非農業部門雇用者数の変化を測定したものである。この報告はオートマティック・データ・プロセッシング（ADP）カナダ社がまとめた、企業の給与データに基づく。製造業、建設業、天然資源および鉱業、貿易／輸送および公益事業、情報、金融／不動産、専門／技術を含む専門家／事業者向けサービス、企業経営マネジメントおよび管理と補佐、教育サービスとヘルスケア、レジャーおよびホスピタリティ、公務員を含むその他サービス業を対象とした200万人の雇用者を対象に調査したものである。',
  'adp_employment_change.description:ja:United States':
    'ADP雇用統計は、非農業民間部門の雇用水準を示すものである。この統計情報は、オートマティック・データ・プロセッシング（ADP）社が処理した2,400万人の実際の雇用者のデータに基づいている。',
  'aib_services_pmi.description:ja:DEFAULT': '',
  'aib_services_pmi.description:ja:Ireland':
    'Inveseicサービス業PMIは、アイルランドの民間サービス企業約450社に送付する質問書への回答による月次の調査に基づいている。指数が50.0を超えると、その項目の活動（新規受注、価格、雇用状況など）が拡大、50.0より低い場合は活動の縮小を示す。50.0ちょうどの場合、前月と比較し状況が安定していることを示す。',
  'aig_construction_index.description:ja:DEFAULT': '',
  'aig_construction_index.description:ja:Australia':
    'オーストラリアの建設業景況指数（PCI）は、建設業の活動の概況を示す総合指標である。PCIは売上、新規受注、雇用者数、納品、価格の5つの項目についてまとめたディフュージョン・インデックスである。50を超えると建設業の対前月比の景気が拡大、50未満は縮小、50は変化がないことを示す。',
  'aig_manufacturing_index.description:ja:DEFAULT': '',
  'aig_manufacturing_index.description:ja:Australia':
    'オーストラリア産業グループ（AIG）の製造業購買担当者指数（PMI）は、製造業200社の調査に基づき、製造業の業績を測定する。新規受注、納入、在庫、雇用、5つからなる個別インデックスに加重配分して構成される総合指数である。50を超える指数は製造業セクターが前月に比べて拡大していることを、50を切る指数は縮小していることを、50は変化がないことを示す。',
  '2-year_note_auction.description:ja:DEFAULT': '',
  '2-year_note_auction.description:ja:United States': '',
  'aig_services_index.description:ja:DEFAULT': '',
  'aig_services_index.description:ja:Australia':
    'オーストラリア産業グループ（AIG）のオーストラリアサービス業指数（Australian PSI®）は、オーストラリア経済のサービス業の先行指標である。Australian PSIは、売上、受注/新規受注、納期、在庫および雇用を加重したディフュージョンインデックスに基づく季節調整を加えた複合指標である。Australian PSI®が50ポイントを超えるとサービス業の景況は全般的に拡大しており、50を下回ると縮小していることを示す。指数が50からどれだけ離れているかが、拡大または縮小の強さを示す。この指数は各月約200社の企業からの回答に基づき算出される。',
  'anz_business_confidence_final.description:ja:DEFAULT': '',
  'anz_business_confidence_final.description:ja:New Zealand':
    'ニュージーランドの企業信頼感指標は、自社の今後の業績見通しおよび経済全体に関し事業者の景況感を把握するものである。約700社からの回答によりまとめられる。景況が悪化するとの回答数から改善するとの回答数を引いたパーセンテージで示される。',
  'anz_business_confidence_prel.description:ja:DEFAULT': '',
  'anz_business_confidence_prel.description:ja:New Zealand':
    'ニュージーランドの企業信頼感指標は、自社の今後の業績見通しおよび経済全体に関し事業者の景況感を把握するものである。約700社からの回答によりまとめられる。景況が悪化するとの回答数から改善するとの回答数を引いたパーセンテージで示される。',
  'anz_internet_job_ads_mom.description:ja:DEFAULT': '',
  'anz_internet_job_ads_mom.description:ja:Australia':
    'オーストラリアの主要都市の大手新聞およびインターネットサイトの求人広告件数を測るものである。',
  'anz_job_advertisement.description:ja:DEFAULT': '',
  'anz_job_advertisement.description:ja:Australia': '',
  'anz_job_advertisement_mom.description:ja:DEFAULT': '',
  'anz_job_advertisement_mom.description:ja:Australia': '',
  'anz_job_advertisment.description:ja:DEFAULT': '',
  'anz_job_advertisment.description:ja:Australia': '',
  'anz_newspaper_job_ads_mom.description:ja:DEFAULT': '',
  'anz_newspaper_job_ads_mom.description:ja:Australia':
    'オーストラリアの主要都市の大手新聞およびインターネットサイトの求人広告件数を測るものである。',
  'anz_roy_morgan_consumer_confidence.description:ja:DEFAULT': '',
  'anz_roy_morgan_consumer_confidence.description:ja:New Zealand': '',
  'anzac_day.description:ja:DEFAULT': '',
  'anzac_day.description:ja:New Zealand': '',
  'anzac_day.description:ja:Australia': '',
  'api_crude_stock_change.description:ja:DEFAULT': '',
  'api_crude_stock_change.description:ja:United States': '',
  'api_weekly_crude_oil_stock.description:ja:DEFAULT': '',
  'api_weekly_crude_oil_stock.description:ja:United States': '',
  '8th_european_central_bank_conference_on_statistics.description:ja:DEFAULT': '',
  '8th_european_central_bank_conference_on_statistics.description:ja:Euro Area': '',
  'api_weekly_crude_stock_change.description:ja:DEFAULT': '',
  'api_weekly_crude_stock_change.description:ja:United States': '',
  'additional_day.description:ja:DEFAULT': '',
  'additional_day.description:ja:Philippines': '',
  'adjusted_holiday.description:ja:DEFAULT': '',
  'adjusted_holiday.description:ja:Taiwan': '',
  'adjustment_holiday.description:ja:DEFAULT': '',
  'adjustment_holiday.description:ja:South Africa': '',
  'ai_group_construction_index.description:ja:DEFAULT': '',
  'ai_group_construction_index.description:ja:Australia':
    'オーストラリアの建設業景況指数（PCI）は、建設業の活動の概況を示す総合指標である。PCIは売上、新規受注、雇用者数、納品、価格の5つの項目についてまとめたディフュージョン・インデックスである。50を超えると建設業の対前月比の景気が拡大、50未満は縮小、50は変化がないことを示す。',
  'ai_group_manufacturing_index.description:ja:DEFAULT': '',
  'ai_group_manufacturing_index.description:ja:Australia':
    'オーストラリア産業グループ（AIG）の製造業購買担当者指数（PMI）は、製造業200社の調査に基づき、製造業の業績を測定する。新規受注、納入、在庫、雇用、5つからなる個別インデックスに加重配分して構成される総合指数である。50を超える指数は製造業セクターが前月に比べて拡大していることを、50を切る指数は縮小していることを、50は変化がないことを示す。',
  'ai_group_services_index.description:ja:DEFAULT': '',
  'ai_group_services_index.description:ja:Australia':
    'オーストラリア産業グループ（AIG）のオーストラリアサービス業指数（Australian PSI®）は、オーストラリア経済のサービス業の先行指標である。Australian PSIは、売上、受注/新規受注、納期、在庫および雇用を加重したディフュージョンインデックスに基づく季節調整を加えた複合指標である。Australian PSI®が50ポイントを超えるとサービス業の景況は全般的に拡大しており、50を下回ると縮小していることを示す。指数が50からどれだけ離れているかが、拡大または縮小の強さを示す。この指数は各月約200社の企業からの回答に基づき算出される。',
  'aig_perf_services_index.description:ja:DEFAULT': '',
  'aig_perf_services_index.description:ja:Australia': '',
  'aig_perfor_construction_index.description:ja:DEFAULT': '',
  'aig_perfor_construction_index.description:ja:Australia': '',
  'aig_performance_constr_index.description:ja:DEFAULT': '',
  'aig_performance_constr_index.description:ja:Australia': '',
  'aig_performance_mfg_index.description:ja:DEFAULT': '',
  'aig_performance_mfg_index.description:ja:Australia': '',
  'aig_performance_of_construction_index.description:ja:DEFAULT': '',
  'aig_performance_of_construction_index.description:ja:Australia': '',
  'aig_performance_of_mfg_index.description:ja:DEFAULT': '',
  'aig_performance_of_mfg_index.description:ja:Australia': '',
  'alberta_general_election.description:ja:DEFAULT': '',
  'alberta_general_election.description:ja:Canada': '',
  'all_industry_activity_index_mom.description:ja:DEFAULT': '',
  'all_industry_activity_index_mom.description:ja:Japan':
    '第3次産業活動指数は最重要セクターとなっており、全産業活動の73%を占める。工業生産部門が21%、建設業活動が6%と続く。',
  '3-month_bill_auction.description:ja:DEFAULT': '',
  '3-month_bill_auction.description:ja:Netherlands': '',
  '3-month_bill_auction.description:ja:United States': '',
  'all_saint_s_day.description:ja:DEFAULT': '',
  'all_saint_s_day.description:ja:Colombia': '',
  'all_saint_s_day.description:ja:Philippines': '',
  'all_saint_s_day.description:ja:Hungary': '',
  'all_saint_s_day.description:ja:Poland': '',
  'all_saint_s_day.description:ja:Austria': '',
  'all_saint_s_day.description:ja:Sweden': '',
  'all_saints_day.description:ja:DEFAULT': '',
  'all_saints_day.description:ja:Colombia': '',
  'all_saints_day.description:ja:Brazil': '',
  'all_saints_day.description:ja:Sweden': '',
  'all_saints_day.description:ja:Germany': '',
  'all_saints_day.description:ja:France': '',
  'all_saints_day.description:ja:Belgium': '',
  'all_saints_day.description:ja:Poland': '',
  'all_saints_day.description:ja:Austria': '',
  'all_saints_day.description:ja:Hungary': '',
  'all_saints_day.description:ja:Finland': '',
  'all_saints_day.description:ja:Philippines': '',
  'all_saints_day.description:ja:Italy': '',
  'all_saints_day.description:ja:Portugal': '',
  'all_saints_day.description:ja:Spain': '',
  'all_souls_day.description:ja:DEFAULT': '',
  'all_souls_day.description:ja:Mexico': '',
  'all_souls_day.description:ja:Philippines': '',
  'all_souls_day.description:ja:Brazil': '',
  'all_truck_sales.description:ja:DEFAULT': '',
  'all_truck_sales.description:ja:United States': '',
  'annual_budget_release.description:ja:DEFAULT': '',
  'annual_budget_release.description:ja:New Zealand': '',
  'annualized_housing_starts.description:ja:DEFAULT': '',
  'annualized_housing_starts.description:ja:Japan': '',
  'armistice_day.description:ja:DEFAULT': '',
  'armistice_day.description:ja:France': '',
  'armistice_day.description:ja:Belgium': '',
  'asarnha_bucha.description:ja:DEFAULT': '',
  'asarnha_bucha.description:ja:Thailand': '',
  'asarnha_bucha_day.description:ja:DEFAULT': '',
  'asarnha_bucha_day.description:ja:Thailand': '',
  'asarnha_bucha_day_substitute.description:ja:DEFAULT': '',
  'asarnha_bucha_day_substitute.description:ja:Thailand': '',
  'ascension_day.description:ja:DEFAULT': '',
  'ascension_day.description:ja:Colombia': '',
  'ascension_day.description:ja:Switzerland': '',
  'ascension_day.description:ja:Finland': '',
  'ascension_day.description:ja:Denmark': '',
  'ascension_day.description:ja:France': '',
  'ascension_day.description:ja:Austria': '',
  'ascension_day.description:ja:Germany': '',
  'ascension_day.description:ja:Sweden': '',
  'ascension_day.description:ja:Indonesia': '',
  'ascension_day.description:ja:Netherlands': '',
  'ascension_day.description:ja:Belgium': '',
  'ascension_day.description:ja:Norway': '',
  'asia_economic_integration_monitor_adb_semiannual_review.description:ja:DEFAULT': '',
  'asia_economic_integration_monitor_adb_semiannual_review.description:ja:Philippines': '',
  'asian_development_bank_annual_meeting.description:ja:DEFAULT': '',
  'asian_development_bank_annual_meeting.description:ja:Germany': '',
  '41-day_bill_auction.description:ja:DEFAULT': '',
  '41-day_bill_auction.description:ja:United States': '',
  'asian_financial_forum.description:ja:DEFAULT': '',
  'asian_financial_forum.description:ja:Hong Kong': '',
  'australia_day.description:ja:DEFAULT': '',
  'australia_day.description:ja:Australia': '',
  'australia_day_substitute_day.description:ja:DEFAULT': '',
  'australia_day_substitute_day.description:ja:Australia': '',
  'auto_production_yoy.description:ja:DEFAULT': '',
  'auto_production_yoy.description:ja:Mexico': 'メキシコの自動車生産台数は、車両総生産数を表す。',
  'autumn_budget.description:ja:DEFAULT': '',
  'autumn_budget.description:ja:United Kingdom': '',
  'autumn_budget_2018.description:ja:DEFAULT': '',
  'autumn_budget_2018.description:ja:United Kingdom': '',
  'autumn_equinox_day.description:ja:DEFAULT': '',
  'autumn_equinox_day.description:ja:Japan': '',
  'autumnal_equinox_day.description:ja:DEFAULT': '',
  'autumnal_equinox_day.description:ja:Japan': '',
  'average_cash_earnings_yoy.description:ja:DEFAULT': '',
  'average_cash_earnings_yoy.description:ja:Japan':
    '日本の賃金増加率は、5人以上の従業員を抱える事業者の非正規、ボーナス給与を含めた平均現金給与額の変化を算出したものである。',
  'average_earnings_excl_bonus.description:ja:DEFAULT': '',
  'average_earnings_excl_bonus.description:ja:United Kingdom':
    'イギリスの給与上昇率は、税引き前およびその他天引き前のボーナスを含めた週間所得の平均値の変化を表したものである。',
  'average_earnings_incl_bonus.description:ja:DEFAULT': '',
  'average_earnings_incl_bonus.description:ja:United Kingdom':
    'イギリスの給与上昇率は、税引き前およびその他天引き前のボーナスを含めた週間所得の平均値の変化を表したものである。',
  'average_hourly_wages_yoy.description:ja:DEFAULT': '',
  'average_hourly_wages_yoy.description:ja:Canada':
    'カナダの正規雇用者の平均時給は、本業として就業する正規雇用者の基本賃金・給与を表したものである。回答者は、税引き前およびその他天引き前の給与を回答し、チップや成果報酬を含めたものを質問される。週給および時給は、週あたりの労働時間に対して支払われる基本給に関して計算される。',
  'bba_mortgage_approval.description:ja:DEFAULT': '',
  'bba_mortgage_approval.description:ja:United Kingdom': '',
  'bba_mortgage_rate.description:ja:DEFAULT': '',
  'bba_mortgage_rate.description:ja:United Kingdom':
    '英国のBBA住宅ローン利率は、月末の英国金融機関（中央銀行を除く）の英ポンド一般世帯向け住宅ローン変動金利の利率を加重平均したもので、標準的変動利率により決まった住宅ローン利率を示す。',
  'bcb_inflation_report.description:ja:DEFAULT': '',
  'bcb_inflation_report.description:ja:Brazil': '',
  'boe_consumer_credit.description:ja:DEFAULT': '',
  'boe_consumer_credit.description:ja:United Kingdom':
    '消費者信用残高（学生ローンは含まず）は、イギリスの消費者の商品およびサービスへの支出における借り入れを示したものである。消費者信用残高（学生ローンは含まず）は、クレジットカード借り入れと「その他」借り入れ（主に借越とその他借入/キャッシング）の2つに分かれている。',
  'boe_credit_conditions_survey.description:ja:DEFAULT': '',
  'boe_credit_conditions_survey.description:ja:United Kingdom':
    'イギリスの金融政策委員会（MPC）が基準利率を設定する。中央銀行であるイングランド銀行の公式金利はレポ金利である。このレポ金利は、イングランド銀行の取引相手（銀行、住宅金融組合、証券会社）に対する公開市場操作での利率となる。',
  'boe_gov_carney_speech.description:ja:DEFAULT': '',
  'boe_gov_carney_speech.description:ja:United Kingdom': '',
  'boe_quantitative_easing.description:ja:DEFAULT': '',
  'boe_quantitative_easing.description:ja:United Kingdom':
    'イギリスの金融政策委員会（MPC）が基準利率を設定する。中央銀行であるイングランド銀行の公式金利はレポ金利である。このレポ金利は、イングランド銀行の取引相手（銀行、住宅金融組合、証券会社）に対する公開市場操作での利率となる。',
  'boe_quarterly_inflation_report.description:ja:DEFAULT': '',
  'boe_quarterly_inflation_report.description:ja:United Kingdom': '',
  'boe_s_gov_carney_speech.description:ja:DEFAULT': '',
  'boe_s_gov_carney_speech.description:ja:United Kingdom': '',
  'boj_monetary_policy_statement.description:ja:DEFAULT': '',
  'boj_monetary_policy_statement.description:ja:Japan': '',
  'boj_monthly_report.description:ja:DEFAULT': '',
  'boj_monthly_report.description:ja:Japan': '',
  'brc_retail_sales_monitor_yoy.description:ja:DEFAULT': '',
  'brc_retail_sales_monitor_yoy.description:ja:United Kingdom': '',
  'brc_retail_sales_yoy.description:ja:DEFAULT': '',
  'brc_retail_sales_yoy.description:ja:United Kingdom': '',
  'brc_shop_price_index_mom.description:ja:DEFAULT': '',
  'brc_shop_price_index_mom.description:ja:United Kingdom': '',
  'brc_shop_price_index_yoy.description:ja:DEFAULT': '',
  'brc_shop_price_index_yoy.description:ja:United Kingdom': '',
  'brics_summit.description:ja:DEFAULT': '',
  'brics_summit.description:ja:Brazil': '',
  'bsi_large_manufacturing_qoq.description:ja:DEFAULT': '',
  'bsi_large_manufacturing_qoq.description:ja:Japan': '',
  'baden-w_rttemberg_state_election.description:ja:DEFAULT': '',
  'baden-w_rttemberg_state_election.description:ja:Germany': '',
  'balance_of_trade.description:ja:DEFAULT': '',
  'balance_of_trade.description:ja:Thailand':
    'タイは輸出志向の国であり、タイ製品の需要の減少につながる外国の経済ショックの影響を大きく受け、貿易収支に影響する。タイの主な輸出品目は電子機器、車両、機械、装置となっている。主に燃料、電子機器、機械器具を輸入している。主な貿易相手国は、日本（総輸出額の10%および総輸入額の20%）および中国（総輸出額の12%および総輸入額の15%）。他には、欧州連合、アメリカ、マレーシアが挙げられる。',
  'balance_of_trade.description:ja:Switzerland':
    '2019年、スイスの貿易黒字は前年比で19%拡大し370億スイスフランとなった。世界貿易の緊張、ブレグジットの不透明感、香港の民主化デモなどにも関わらず、輸出入とも過去最高を記録した。貿易黒字の相手国の上位は、アメリカ、インド、イギリス、中国、香港、日本、シンガポール。貿易赤字の上位は、アラブ首長国連邦、ドイツ、アイルランド、イタリア、タイ。',
  'balance_of_trade.description:ja:Philippines':
    'フィリピンは、原料と中間品の輸入量が大きいことにより年間貿易収支の赤字が続いている。2013年、貿易赤字の相手国上位は台湾、サウジアラビア、タイ、韓国、貿易黒字の上位は日本、香港、アメリカとなっている。',
  'balance_of_trade.description:ja:Colombia':
    'コロンビアは、石油と石炭の大規模輸出国であり、これらが総輸出額の59%を占めている。その他の輸出品目には、化学品および関連製品（5.1%）、金（5%）がある。主な輸入品目には、機械および輸送機器（総輸入額の39%）、工業製品（22%）、化学品（17%）、燃料および潤滑油（10%）となっている。主な貿易相手国は、アメリカ（総輸出額の36%および総輸入額の39%）および中国（輸出額の6%および輸入額の16%）である。他には、メキシコ、スペイン、ベネズエラが挙げられる。',
  'balance_of_trade.description:ja:Euro Area':
    'ユーロ圏は、主に機械や車両などの製造製品輸出の高さにより常時貿易黒字を記録している。ユーロ圏はエネルギーおよび原料の大規模輸入地域である。ドイツ、イタリア、フランス、オランダが総貿易額の最大部分を占めている。主な貿易相手国は、イギリス（総輸出額の12%および総輸入額の10%）とアメリカ（輸出額の13%および輸入額の6%）。',
  'balance_of_trade.description:ja:Japan':
    '1980年から2010年まで、日本は輸出額が大きいため一貫して貿易黒字を記録してきた。しかし、貿易収支は2011年に福島の原子力発電所災害が原因となった円安下で化石燃料および天然ガス輸入が増加したことにより赤字化した。2016年、2017年には黒字転換するが、2018年と2019年の日本の貿易収支は米中貿易摩擦の激化および世界経済成長の減退により再び赤字となった。2019年、日本の貿易黒字の相手国の上位はアメリカ、香港、韓国、台湾、シンガポール、オランダであった。貿易赤字の上位は中国、オーストラリア、サウジアラビア、アラブ首長国連邦、カタールであった。',
  'balance_of_trade.description:ja:Singapore':
    'シンガポール経済は中間品の購入および高付加価値製品の輸出に頼っている。主な輸出品目は機械および装置（総輸出額の43%）、石油製品（19%）、化学品（13%）。主な輸入品目は、機械および装置（総輸入額の39%）、石油（33%）、化学品（7%）および雑多な製造品（7%）。2017年、貿易赤字の相手国の上位は中国、香港、マレーシア、インドネシア、アメリカであった。シンガポールは、あらゆる国に対して貿易黒字を記録していない。',
  'balance_of_trade.description:ja:Italy':
    '2017年、イタリアの輸入額は9%の上昇により2011年以来最高水準となる4,010億ユーロとなり、輸出額がより緩やかな7%の上昇を見せ史上最高額の4,480億ユーロとなったため、貿易黒字が前年の史上最高額500億ユーロから減少し、470億ユーロとなった。貿易黒字の相手国上位はアメリカ、イギリス、フランス、スイス、ポーランド、日本。貿易赤字の相手国の上位は、中国、オランダ、ドイツ、ベルギー、ロシア。',
  'balance_of_trade.description:ja:Netherlands':
    'オランダは輸出中心の経済となっており、商業貿易が国内総生産の2/3以上を占めている。主な輸出品目は、機械および輸送機器（総輸出額の28%）、鉱物性燃料（23%）、食品（11%）、衣料および靴（10%）、医薬品（5%）となっている。オランダの主な輸入品目は、燃料（29%）、機械（26%）、食品および家畜（8.6%）である。主な貿易相手国は、ドイツ（総輸出額の24%および総輸入額の17%）とベルギー（総輸出額の12%および総輸入額の10%）となっている。他には、中国、フランス、イギリス、アメリカが挙げられる。',
  'balance_of_trade.description:ja:New Zealand':
    'ニュージーランドは国際貿易に大きく依存している。ニュージーランド経済はこれまで、非常に効率の高い農業による乳製品、食肉、林業製品、果物および飲料の輸出に支えられてきた。ニュージーランドの主な輸入品目は、車両、機械および装置、石油、電気製品、プラスチックおよび航空機。主な貿易相手国は、中国、オーストラリア、アメリカ、日本、韓国。',
  'balance_of_trade.description:ja:India':
    'インドは1980年より、特に鉱物性燃料、石油・ワックス・瀝青物質、および真珠、貴石および半貴石と宝飾品など、主に輸入額の大きな成長により貿易赤字が続いている。近年では、貿易赤字の相手国の上位は中国、スイス、サウジアラビア、イラク、インドネシアとなっている。対アメリカ、アラブ首長国連邦、香港、イギリス、ベトナムでは貿易黒字を計上している。',
  'balance_of_trade.description:ja:United States':
    'アメリカは、大量の石油と日用品を輸入していることで1976年以来一貫した貿易赤字を記録している。2018年、最大の貿易赤字相手国は中国、メキシコ、ドイツ、日本、アイルランド、ベトナム、イタリアとなっており、貿易黒字相手国の上位は香港、オランダ、オーストラリア、アラブ首長国連邦、ベルギー、ブラジル、パナマとなっている。中国が最大の貿易相手国であり、総貿易額の16%を占め、カナダ（15%）とメキシコ（15%）が続いている。',
  'balance_of_trade.description:ja:Indonesia':
    '1970年代以降、インドネシアは輸出の好調により貿易黒字を一貫して維持してきた。しかし、2012年から2014年には世界経済の減速とコモディティ価格の下落により輸出量が減少し、貿易赤字を記録し始めた。2015年には、ほぼ20%に上る輸入量減少により貿易収支は再び黒字に転換した。近年、貿易赤字の相手国上位は中国、タイ、日本、ドイツ、韓国となっている。インドネシアは主に対インド、アメリカ、マレーシアで貿易黒字を記録している。.',
  'balance_of_trade.description:ja:Canada':
    '1970年から2008年まで、カナダは毎年貿易黒字を記録している。2009年以降、2011年、2014年を除き貿易収支は赤字に転落した。2018年、貿易赤字の上位は対中国、ドイツ、メキシコ、貿易黒字の上位は対アメリカ、イギリス、ノルウェーとなっている。',
  'balance_of_trade.description:ja:South Korea':
    '韓国は2019年、半導体、石油化学品、工業用石油製品の各業界の悪化、さらに米中貿易摩擦、日本の輸出規制、ブレグジット、香港の政情不安にもかかわらず、392億米ドルの貿易黒字となり11年連続輸出超過国となった。',
  'balance_of_trade.description:ja:Taiwan':
    '外国貿易は、40年に渡り台湾の急速な成長の動力源となってきた。主要輸出品目は電子機器、卑金属、情報、通信およびオーディオ/映像製品、機械およびおよびプラスチックとゴム製品となっている。台湾の輸入の大半は電子部品、鉱業原料、卑金属、機械、化学薬品を輸入となっている。主な貿易相手は中国、香港、アメリカ、日本、ヨーロッパ、ASEAN諸国となっている。',
  'balance_of_trade.description:ja:Norway':
    'ノルウェーの貿易収支は、1989年以来黒字となっている。2019年、世界貿易での緊張激化と外国からの需要の縮小により、貿易黒字は前年比48.3%急激に縮小し、1480億ノルウェークローネとなった。貿易黒字の相手国の上位は、イギリス、オランダ、ドイツ、フランス、ベルギー、スペイン、アイスランド、ポルトガル、シンガポール、アイルランド。貿易赤字の上位は、中国、アメリカ、スウェーデン、ロシア、イタリア、カナダ、ブラジル、ポーランド、チェコ共和国、スイス。',
  'balance_of_trade.description:ja:Mexico':
    'メキシコの主な輸入品目は工業製品（総輸出額の89%）および石油と石油製品（6%）である。主な輸入品目は、金属製品、機械および装置（総輸入額の53%）、石油製品（10%）および農業製品（3%）。同国の貿易相手国最上位はアメリカ（総輸出額の80%および総輸入額の46%）である。上位には他に、中国、日本、ドイツがある。2017年、メキシコとアメリカの貿易額は5,220億米ドルに達し、メキシコが貿易黒字約1,320億米ドルとなった。アメリカへの主な輸出品目には、自動車のその他部品および付属品（総輸出額の14%）、トラック、バス、特殊目的車両（10%）、乗用車（10%）、コンピュータ（6%）、電話通信機器（5%）がある。アメリカからの主な輸入品目は、自動車のその他部品および付属品（総輸入額の8%）、電子機器（7%）、石油製品（6%）、コンピュータ周辺機器（6%）。 .',
  'balance_of_trade.description:ja:Brazil':
    '2001年から2012年まで、さらに2015年から2017年まで、ブラジルは主に鉱業および農産品の輸出量が多く、貿易黒字を記録した。2018年の貿易黒字の相手国の上位は中国、オランダ、アルゼンチン、チリ、イラン、スペイン、最大の貿易赤字相手国はドイツ、韓国、ロシア、スイス、アルジェリア、フランスとなっている。',
  'balance_of_trade.description:ja:Poland':
    '2010年代初頭以来、ポーランドの対外貿易は10倍ほどに拡大した。ポーランドは加工果物および野菜、肉、乳製品、電気機械製品、車両、航空機、船舶を輸出している。ポーランドの輸入の大部分は、製造業での新規設備となる資本財および製造業原料となる機械・輸送機器、化学品、鉱産物、燃料、潤滑油などである。EUがこれまで最大の貿易相手国であり、輸出の79%、輸入の64%を占めている。',
  'balance_of_trade.description:ja:Germany':
    'ドイツは1952年以来、主に車両とその他機械の輸出が堅調で、継続して貿易黒字を記録している。2017年、最大の貿易黒字の相手国はアメリカ、イギリス、フランス、オーストリア、スペイン、スウェーデン、アラブ首長国連邦。一方、貿易赤字の相手国の上位は中国、ベトナム、ノルウェー、ロシア、オランダ、アイルランド、チェコ共和国。',
  'balance_of_trade.description:ja:Australia':
    '2017年および2018年、オーストラリアは天然ガス、金属鉱石、石炭、コークス、ブリケット、食肉や穀物などの地産品などの天然資源輸出の増加により貿易黒字を記録した。貿易黒字相手国の上位は、中国、香港、日本、ニュージランドとなっており、最大の貿易赤字相手国はアメリカ、シンガポール、韓国、マレーシア、タイとなっている。.',
  'balance_of_trade.description:ja:Hong Kong':
    '1990年代後半以降、香港は輸入額が輸出額の伸びを上回り貿易赤字を記録している。香港の主な輸出品目は電気機械、装置および器具、電気通信および音響機器、自動データプロセッシング機器となっている。おもに機械、輸送機器、その他工業製品、食料、家畜を輸入している。主な貿易相手国は、中国（総輸出額の40%および総輸入額の47%）。他には、日本、シンガポール、台湾、アメリカが挙げられる。',
  'balance_of_trade.description:ja:Greece':
    'ギリシャは輸入が多いため、慢性的に貿易赤字となっている。主な輸入品は鉱物性燃料、機械、輸送機器および化学品となっている。近年、貿易赤字の相手国の上位はロシア、ドイツ、中国、イタリア、フランスであ4る。ギリシャは対トルコ、キプロス、アメリカ、アルジェリア、アラブ首長国連邦では貿易黒字となっている。',
  'balance_of_trade.description:ja:Belgium':
    '対外貿易はベルギー経済の約70%を占めている。ベルギーの貿易の約80%はEU諸国である。ベルギーの主な輸出品目は、自動車、医薬品製剤、据え付け前のダイヤモンド。ベルギーの輸入品目は主に機械・装置、化学品、ダイヤモンド、医薬品、食料、輸送機器、石油製品となっている。',
  'balance_of_trade.description:ja:France':
    '2004年以来、フランスは輸出志向産業の衰退、ユーロ高、輸入エネルギーと輸入工業製品への依存度の高まりにより、貿易赤字を記録している。2017年、貿易赤字相手国の上位は中国、香港、ドイツ、オランダ、イタリア、ベルギー、貿易黒字相手国の上位は中東、イギリス、アフリカ、スイスとなっている。',
  'balance_of_trade.description:ja:South Africa':
    '2017年、南アフリカの貿易赤字の最大相手国は中国、サウジアラビア、ドイツ、タイ、フランス、ナイジェリア、イタリア、ブラジル、最大の貿易黒字相手国はボツワナ、ナミビア、ザンビア、ジンバブエ、モザンビーク、ベルギー、オランダとなっている。',
  'balance_of_trade.description:ja:Sweden':
    'スウェーデンは主に機械、自動車、紙製品、パルプ及び木材、鉄、鋼鉄製品、化学品を輸出している。機械、石油及び石油製品、化学品、自動車、鉄、鋼鉄、食料、衣料を輸入している。欧州連合がこれまで輸出の61%および輸入の72%にのぼる主な貿易相手国となっている。ノルウェー、ロシア、アメリカ、中国も重要な比重を占める。',
  'balance_of_trade.description:ja:Austria':
    'オーストリア経済は対外貿易に依存しており、特にドイツを始めとする他のEU諸国の経済と密接に関連している。オーストリアの主な輸出品は自動車及び自動車部品、機械、紙製品。オーストリアの主な輸入品は機械、装置、自動車、化学品、金属製品、石油及び石油製品、食料。',
  'balance_of_trade.description:ja:Ireland':
    'アイルランドは1985年以来継続して貿易黒字を記録している。2017年、輸出入とも史上最高額となり、貿易黒字は過去最高となる450億ユーロに達した。貿易黒字の相手国の上位はアメリカ、ベルギー、スイス、オランダ、ドイツ、貿易赤字の上位はフランス、イギリスとなっている。',
  'balance_of_trade.description:ja:United Kingdom':
    'イギリスの貿易収支は、財の貿易赤字がサービスの貿易黒字で一部相殺され、1998年以来赤字となっている。イギリスの財が総貿易額に占める割合は、1985年の75%をピークに1986年以降段階的に減少している。これは、イギリスの貿易に占めるサービス（2017年の総貿易額の35%）の上昇と一致している。2017年、財の貿易赤字相手国の上位はドイツ、中国、オランダ、ノルウェー、ベルギー、ルクセンブルク、イタリア。貿易赤字相手国の上位はアメリカ、アイルランド、韓国。',
  'balance_of_trade.description:ja:Finland':
    '国際貿易は、フィンランドの国内総生産（GDP）の3分の1を占める。フィンランドの主要輸出品目は、電話通信設備、乗用車、林業製品および紙、ペーパーボード。フィンランドの主要輸入品目は、食物、石油および石油製品、化学品、輸送機器、鉄と鉄鋼、機械、繊維、穀物となっている。欧州連合が総貿易の55%を占める相手である。最大貿易相手国は、ドイツ、ロシア、スウェーデン、中国、アメリカ。',
  'balance_of_trade.description:ja:Spain':
    'スペインは燃料の輸入と高付加価値製品の大きな輸入額により、構造的な貿易赤字に陥っている。2017年、スペインの貿易赤字は主に国内需要の急増と燃料費の増加により前年比31.9%増と急上昇し250億ユーロとなった。自治州の中で最も輸出が多いのはカタルーニャ（総輸出額の26%）、アンダルシア、マドリード、バレンシア（各11%）およびバスク（9%）。輸入が最も多いのはカタルーニャ（総輸入額の28%）、マドリード（20%）、アンダルシア（10%）、バレンシア（9%）。対EUとの貿易黒字は、2016年の130億ユーロから160億ユーロに拡大しているが、これは貿易黒字がユーロ圏（2016年の70億ユーロから90億ユーロへの増加）および非ユーロ圏（2016年の60億ユーロから70億ユーロへの増加）の両方で増加したことによる。EU加盟国以外に関しては、貿易赤字は前年の320億ユーロから410億ユーロに拡大しており、対アジアの貿易赤字が290億ユーロから350億ユーロに増加、対アフリカでは40億ユーロから60億ユーロに増加している。また、対南米の貿易赤字も2016年の6,000万ユーロから20億ユーロに拡大し、対北米では10億ユーロのまま変わらずとなっている。',
  'balance_of_trade.description:ja:Russia':
    'ロシアは、主に原油や天然ガスなどのコモディティ輸出額が大きいことにより1998年以来安定した貿易黒字を記録している。2015年、貿易黒字は、原油価格の急落およびウクライナ危機に対するヨーロッパとアメリカの制裁により、大幅に縮小した。2015年、貿易黒字相手国上位はオランダ、トルコ、イアリア、日本、貿易赤字上位は中国、アメリカ、フランスとなっている。',
  'balance_of_trade.description:ja:Czech Republic':
    'チェコ共和国では国際貿易が盛んに行われている。主な輸出品目は、自動車製造用の各種製品、家具、電気家電となっている。主な輸入品は、機械、輸送機器、原料、燃料、化学品となっている。欧州連合が群を抜いて最大の貿易相手国であり、輸出の83%、輸出の65%を占めている。',
  'balance_of_trade.description:ja:Denmark':
    'デンマークは1988年以来、一貫した貿易黒字を記録している。デンマークは石油、天然ガス、風力およびバイオ発電により燃料を自給している。主な輸出品目は機械、化学品、食料品となっている。デンマークは主に機械および装置、工業用の原料および半製品、食品および化学品を輸入している。2017年、貿易黒字の相手国上位はアメリカ、イギリス、ノルウェー、フランス、ロシア、貿易赤字での上位はドイツ、オランダ、中国、スウェーデンとなっている。',
  'balance_of_trade.description:ja:Israel':
    'イスラエルでは、天然資源不足により1959年以来貿易赤字を記録している。イスラエルの主要輸入品目は、石油およびその他鉱物性燃料、未加工ダイヤモンドとなっている。主な輸出品は、加工ダイヤモンド、真珠、その他貴金属と貴石、電気機械と装置、機械器具、家電、電子機器となっている。イスラエルの主な貿易相手は、アメリカ（輸出総額の28%および輸入総額の12%）であり、その他貿易相手国には香港、中国、ベルギー、オランダ、ドイツ、イギリスがある。',
  'balance_of_trade.description:ja:Hungary':
    'ハンガリーの主な輸出品目は機械、輸送機器、日用品、農業製品、化学品、衣料、繊維、鉄と鉄鋼、ワインとなっている。ハンガリーの主要輸入品目は機械および装置、その他製品および燃料、電子機器となっている。欧州連合が何より最大の貿易相手国であり、輸入の79%、輸出の76%を占めている。',
  'balance_of_trade.description:ja:China':
    '1995年以来、中国は一貫して貿易黒字を記録しており、貿易黒字は2004年から2009年の間に10倍に伸びている。2019年、中国は輸出額が0.5%増加したのに対し、国内需要の低下およびアメリカとの貿易摩擦により輸入額が2.7%減少したため、4,219億米ドルの貿易黒字を記録し2016年来の最高となった。貿易黒字の相手国の上位はアメリカ、香港、EU諸国（特にオランダ、イギリス、ポーランド、スペイン、イタリア、ベルギー）、インド、ベトナム、フィリピン、シンガポール、アラブ首長国連邦、パキスタン、トルコ、インドネシア。貿易赤字の上位は対台湾、オーストラリア、韓国、ブラジル、サウジアラビア、日本、ドイツ、スイス、マレーシア、オマーン、チリ、ロシア。',
  'balance_of_trade.description:ja:Portugal':
    '2017年、ポルトガルの貿易赤字は前年比23.4%と急激に増加して138億ユーロとなり、2011年以来最大の貿易不均衡となった。輸入は12.5%増加して2010年以来の最大となり、一方輸出は2011年以来の最大となるもより緩やかな10.1%の伸びとなった。貿易赤字の相手国の上位は、スペイン、ドイツ、イタリア、オランダ、ロシア、中国、貿易黒字の上位は対アメリカ、イギリス、フランス。',
  'balance_of_trade_final.description:ja:DEFAULT': '',
  'balance_of_trade_final.description:ja:India':
    'インドは1980年より、特に鉱物性燃料、石油・ワックス・瀝青物質、および真珠、貴石および半貴石と宝飾品など、主に輸入額の大きな成長により貿易赤字が続いている。近年では、貿易赤字の相手国の上位は中国、スイス、サウジアラビア、イラク、インドネシアとなっている。対アメリカ、アラブ首長国連邦、香港、イギリス、ベトナムでは貿易黒字を計上している。',
  'balance_of_trade_prel.description:ja:DEFAULT': '',
  'balance_of_trade_prel.description:ja:India':
    'インドは1980年より、特に鉱物性燃料、石油・ワックス・瀝青物質、および真珠、貴石および半貴石と宝飾品など、主に輸入額の大きな成長により貿易赤字が続いている。近年では、貿易赤字の相手国の上位は中国、スイス、サウジアラビア、イラク、インドネシアとなっている。対アメリカ、アラブ首長国連邦、香港、イギリス、ベトナムでは貿易黒字を計上している。',
  'balinese_new_year.description:ja:DEFAULT': '',
  'balinese_new_year.description:ja:Indonesia': '',
  'bank_austria_manufacturing_pmi.description:ja:DEFAULT': '',
  'bank_austria_manufacturing_pmi.description:ja:Austria':
    'オーストリアのオーストリア銀行製造業購買担当者景気指数（PMI）は、製造業の業績を測定したものである。製造業PMIは、新規受注（30%）、生産（25%）、雇用（20%）、サプライヤーの納期（15%）、購入品の在庫（10%）からなる5つのサブインデックスをそれぞれの加重で計算して構成される。納期インデックスは他と揃えるため符号を逆転させて計算される。50を超える指数は製造業セクターが前期に比べて拡大していることを、50を切る指数は縮小していることを、50は変化がないことを示す。',
  'bank_holiday.description:ja:DEFAULT': '',
  'bank_holiday.description:ja:United Kingdom': '',
  'bank_holiday.description:ja:Japan': '',
  'bank_holiday.description:ja:Mexico': '',
  'bank_holiday.description:ja:Ireland': '',
  'bank_holiday.description:ja:Denmark': '',
  'bank_holiday_-_annual_accounts_closing.description:ja:DEFAULT': '',
  'bank_holiday_-_annual_accounts_closing.description:ja:India': '',
  'bank_lending.description:ja:DEFAULT': '',
  'bank_lending.description:ja:Singapore':
    'シンガポールの銀行貸出残高は、消費者および事業者への銀行ローン貸出および貸付金の残高を示したものである。',
  'bank_lending_mom.description:ja:DEFAULT': '',
  'bank_lending_mom.description:ja:Brazil':
    'ブラジルの民間部門融資は、事業者への貸付を示したものである。',
  'bank_lending_yoy.description:ja:DEFAULT': '',
  'bank_lending_yoy.description:ja:Japan':
    '日本の銀行貸出残高は、銀行および信用金庫のすべての融資および割引の残高の前年比変化を指す。企業の景況感が高まり投資が増えると貸出額は増加する。日本の銀行セクターを蝕む弱い日本経済の状況を見る重要な指標となっている。',
  'bank_loan_growth.description:ja:DEFAULT': '',
  'bank_loan_growth.description:ja:India':
    'インドでは、銀行貸出成長率とは商業銀行による与信（クレジット）の前年比変化を示す。食料クレジット、非食料クレジットおよびローン、現金クレジット、オーバードラフトなどが含まれる。',
  'bank_stress_tests.description:ja:DEFAULT': '',
  'bank_stress_tests.description:ja:United Kingdom': '',
  'bank_stress_tests_results.description:ja:DEFAULT': '',
  'bank_stress_tests_results.description:ja:Euro Area': '',
  'bank_of_canada_consumer_price_index_core_yoy.description:ja:DEFAULT': '',
  'bank_of_canada_consumer_price_index_core_yoy.description:ja:Canada': '',
  'bank_of_canada_core_inflation_mom.description:ja:DEFAULT': '',
  'bank_of_canada_core_inflation_mom.description:ja:Canada': '',
  'bank_of_indonesia_rate.description:ja:DEFAULT': '',
  'bank_of_indonesia_rate.description:ja:Indonesia': '',
  'banxico_inflation_report.description:ja:DEFAULT': '',
  'banxico_inflation_report.description:ja:Mexico': '',
  'barclays_manufacturing_pmi.description:ja:DEFAULT': '',
  'barclays_manufacturing_pmi.description:ja:South Africa':
    'Absa製造業購買担当者景気指数（PMI）は、南アフリカの製造業購買担当者を調査対象とする月次指数で、製造業の業況を示す先行指標である。指数が50%超では製造業の経済は概して拡大しており、50%未満は縮小していることを示す。',
  'battle_of_boyac.description:ja:DEFAULT': '',
  'battle_of_boyac.description:ja:Colombia': '',
  'bde_gov_linde_speech.description:ja:DEFAULT': '',
  'bde_gov_linde_speech.description:ja:Spain': '',
  'bdm_inflation_report.description:ja:DEFAULT': '',
  'bdm_inflation_report.description:ja:Mexico': '',
  'bdp_gov_costa_speech.description:ja:DEFAULT': '',
  'bdp_gov_costa_speech.description:ja:Portugal': '',
  'beige_book.description:ja:DEFAULT': '',
  'beige_book.description:ja:United States': '',
  'belgian_national_day.description:ja:DEFAULT': '',
  'belgian_national_day.description:ja:Belgium': '',
  'benito_ju_rez_s_birthday_memorial.description:ja:DEFAULT': '',
  'benito_ju_rez_s_birthday_memorial.description:ja:Mexico': '',
  'benn_bill_debate.description:ja:DEFAULT': '',
  'benn_bill_debate.description:ja:United Kingdom': '',
  'birth_of_benito_ju_rez.description:ja:DEFAULT': '',
  'birth_of_benito_ju_rez.description:ja:Mexico': '',
  'black_saturday.description:ja:DEFAULT': '',
  'black_saturday.description:ja:Philippines': '',
  'boc_business_outlook_survey.description:ja:DEFAULT': '',
  'boc_business_outlook_survey.description:ja:Canada':
    'カナダでは、基準金利が中央銀行であるカナダ銀行（BoC）理事会によって定められる。公式金利は翌日物レートとなっている。1996年以来、銀行金利は短期金融市場の翌日物レートでの運用幅の上限に設定されている。1980年3月から1996年2月までは、この金利は3カ月割引国債の週間入札金利平均より25ベーシスポイント上に設定されていた。',
  'boc_deputy_gov_patterson_speech.description:ja:DEFAULT': '',
  'boc_deputy_gov_patterson_speech.description:ja:Canada': '',
  'anzac_day_substitute_day.description:ja:DEFAULT': '',
  'anzac_day_substitute_day.description:ja:New Zealand': '',
  'boc_deputy_gov_schembri_speech.description:ja:DEFAULT': '',
  'boc_deputy_gov_schembri_speech.description:ja:Canada': '',
  'boc_deputy_gov_wilkins_speech.description:ja:DEFAULT': '',
  'boc_deputy_gov_wilkins_speech.description:ja:Canada': '',
  'boc_financial_system_review.description:ja:DEFAULT': '',
  'boc_financial_system_review.description:ja:Canada':
    'カナダでは、基準金利が中央銀行であるカナダ銀行（BoC）理事会によって定められる。公式金利は翌日物レートとなっている。1996年以来、銀行金利は短期金融市場の翌日物レートでの運用幅の上限に設定されている。1980年3月から1996年2月までは、この金利は3カ月割引国債の週間入札金利平均より25ベーシスポイント上に設定されていた。',
  'boc_gov_lane_speech.description:ja:DEFAULT': '',
  'boc_gov_lane_speech.description:ja:Canada':
    'カナダでは、基準金利が中央銀行であるカナダ銀行（BoC）理事会によって定められる。公式金利は翌日物レートとなっている。1996年以来、銀行金利は短期金融市場の翌日物レートでの運用幅の上限に設定されている。1980年3月から1996年2月までは、この金利は3カ月割引国債の週間入札金利平均より25ベーシスポイント上に設定されていた。',
  'boc_gov_macklem_speech.description:ja:DEFAULT': '',
  'boc_gov_macklem_speech.description:ja:Canada':
    'カナダでは、基準金利が中央銀行であるカナダ銀行（BoC）理事会によって定められる。公式金利は翌日物レートとなっている。1996年以来、銀行金利は短期金融市場の翌日物レートでの運用幅の上限に設定されている。1980年3月から1996年2月までは、この金利は3カ月割引国債の週間入札金利平均より25ベーシスポイント上に設定されていた。',
  'boc_gov_poloz_press_conference.description:ja:DEFAULT': '',
  'boc_gov_poloz_press_conference.description:ja:Canada': '',
  'boc_gov_poloz_speaks.description:ja:DEFAULT': '',
  'boc_gov_poloz_speaks.description:ja:Canada': '',
  'boc_gov_poloz_speech.description:ja:DEFAULT': '',
  'boc_gov_poloz_speech.description:ja:Canada':
    'カナダでは、基準金利が中央銀行であるカナダ銀行（BoC）理事会によって定められる。公式金利は翌日物レートとなっている。1996年以来、銀行金利は短期金融市場の翌日物レートでの運用幅の上限に設定されている。1980年3月から1996年2月までは、この金利は3カ月割引国債の週間入札金利平均より25ベーシスポイント上に設定されていた。',
  'boc_gravelle_speech.description:ja:DEFAULT': '',
  'boc_gravelle_speech.description:ja:Canada':
    'カナダでは、基準金利が中央銀行であるカナダ銀行（BoC）理事会によって定められる。公式金利は翌日物レートとなっている。1996年以来、銀行金利は短期金融市場の翌日物レートでの運用幅の上限に設定されている。1980年3月から1996年2月までは、この金利は3カ月割引国債の週間入札金利平均より25ベーシスポイント上に設定されていた。',
  'boc_interest_rate_decision.description:ja:DEFAULT': '',
  'boc_interest_rate_decision.description:ja:Canada':
    'カナダでは、基準金利が中央銀行であるカナダ銀行（BoC）理事会によって定められる。公式金利は翌日物レートとなっている。1996年以来、銀行金利は短期金融市場の翌日物レートでの運用幅の上限に設定されている。1980年3月から1996年2月までは、この金利は3カ月割引国債の週間入札金利平均より25ベーシスポイント上に設定されていた。',
  'boc_patterson_speech.description:ja:DEFAULT': '',
  'boc_patterson_speech.description:ja:Canada': '',
  'boc_review.description:ja:DEFAULT': '',
  'boc_review.description:ja:Canada': '',
  'boc_schembri_speech.description:ja:DEFAULT': '',
  'boc_schembri_speech.description:ja:Canada':
    'カナダでは、基準金利が中央銀行であるカナダ銀行（BoC）理事会によって定められる。公式金利は翌日物レートとなっている。1996年以来、銀行金利は短期金融市場の翌日物レートでの運用幅の上限に設定されている。1980年3月から1996年2月までは、この金利は3カ月割引国債の週間入札金利平均より25ベーシスポイント上に設定されていた。',
  'boc_senior_loan_officer_survey.description:ja:DEFAULT': '',
  'boc_senior_loan_officer_survey.description:ja:Canada':
    'カナダでは、基準金利が中央銀行であるカナダ銀行（BoC）理事会によって定められる。公式金利は翌日物レートとなっている。1996年以来、銀行金利は短期金融市場の翌日物レートでの運用幅の上限に設定されている。1980年3月から1996年2月までは、この金利は3カ月割引国債の週間入札金利平均より25ベーシスポイント上に設定されていた。',
  'boe_asset_purchase_facility.description:ja:DEFAULT': '',
  'boe_asset_purchase_facility.description:ja:United Kingdom': '',
  'boe_bailey_speech.description:ja:DEFAULT': '',
  'boe_bailey_speech.description:ja:United Kingdom': '',
  'boe_brazier_speech.description:ja:DEFAULT': '',
  'boe_brazier_speech.description:ja:United Kingdom': '',
  'boe_breeden_speech.description:ja:DEFAULT': '',
  'boe_breeden_speech.description:ja:United Kingdom': '',
  'boe_broadbent_speech.description:ja:DEFAULT': '',
  'boe_broadbent_speech.description:ja:United Kingdom':
    'イギリスの金融政策委員会（MPC）が基準利率を設定する。中央銀行であるイングランド銀行の公式金利はレポ金利である。このレポ金利は、イングランド銀行の取引相手（銀行、住宅金融組合、証券会社）に対する公開市場操作での利率となる。',
  'boe_carney_speaks.description:ja:DEFAULT': '',
  'boe_carney_speaks.description:ja:United Kingdom': '',
  'boe_carney_speaks_in_parliament_on_brexit.description:ja:DEFAULT': '',
  'boe_carney_speaks_in_parliament_on_brexit.description:ja:United Kingdom': '',
  'boe_carney_speech.description:ja:DEFAULT': '',
  'boe_carney_speech.description:ja:United Kingdom': '',
  'boe_chief_economist_haldane_speech.description:ja:DEFAULT': '',
  'boe_chief_economist_haldane_speech.description:ja:United Kingdom': '',
  'boe_cunliffe_speech.description:ja:DEFAULT': '',
  'boe_cunliffe_speech.description:ja:United Kingdom':
    'イギリスの金融政策委員会（MPC）が基準利率を設定する。中央銀行であるイングランド銀行の公式金利はレポ金利である。このレポ金利は、イングランド銀行の取引相手（銀行、住宅金融組合、証券会社）に対する公開市場操作での利率となる。',
  'boe_deputy_gov_broadbent_speech.description:ja:DEFAULT': '',
  'boe_deputy_gov_broadbent_speech.description:ja:United Kingdom': '',
  'boe_deputy_gov_cunliffe_speech.description:ja:DEFAULT': '',
  'boe_deputy_gov_cunliffe_speech.description:ja:United Kingdom': '',
  'boe_deputy_gov_woods_speech.description:ja:DEFAULT': '',
  'boe_deputy_gov_woods_speech.description:ja:United Kingdom': '',
  'boe_fpc_meeting.description:ja:DEFAULT': '',
  'boe_fpc_meeting.description:ja:United Kingdom':
    'イギリスの金融政策委員会（MPC）が基準利率を設定する。中央銀行であるイングランド銀行の公式金利はレポ金利である。このレポ金利は、イングランド銀行の取引相手（銀行、住宅金融組合、証券会社）に対する公開市場操作での利率となる。',
  'boe_fpc_meeting_minutes.description:ja:DEFAULT': '',
  'boe_fpc_meeting_minutes.description:ja:United Kingdom': '',
  'boe_fpc_record.description:ja:DEFAULT': '',
  'boe_fpc_record.description:ja:United Kingdom':
    'イギリスの金融政策委員会（MPC）が基準利率を設定する。中央銀行であるイングランド銀行の公式金利はレポ金利である。このレポ金利は、イングランド銀行の取引相手（銀行、住宅金融組合、証券会社）に対する公開市場操作での利率となる。',
  'boe_forbes_speech.description:ja:DEFAULT': '',
  'boe_forbes_speech.description:ja:United Kingdom': '',
  'boe_haskel_speech.description:ja:DEFAULT': '',
  'boe_haskel_speech.description:ja:United Kingdom':
    'イギリスの金融政策委員会（MPC）が基準利率を設定する。中央銀行であるイングランド銀行の公式金利はレポ金利である。このレポ金利は、イングランド銀行の取引相手（銀行、住宅金融組合、証券会社）に対する公開市場操作での利率となる。',
  'boe_hauser_speech.description:ja:DEFAULT': '',
  'boe_hauser_speech.description:ja:United Kingdom': '',
  'boe_hogg_speech.description:ja:DEFAULT': '',
  'boe_hogg_speech.description:ja:United Kingdom': '',
  'boe_kohn_speech.description:ja:DEFAULT': '',
  'boe_kohn_speech.description:ja:United Kingdom': '',
  'boe_mpc_vote_hike.description:ja:DEFAULT': '',
  'boe_mpc_vote_hike.description:ja:United Kingdom':
    'イギリスの金融政策委員会（MPC）が基準利率を設定する。中央銀行であるイングランド銀行の公式金利はレポ金利である。このレポ金利は、イングランド銀行の取引相手（銀行、住宅金融組合、証券会社）に対する公開市場操作での利率となる。',
  'boe_mpc_vote_unchanged.description:ja:DEFAULT': '',
  'boe_mpc_vote_unchanged.description:ja:United Kingdom':
    'イギリスの金融政策委員会（MPC）が基準利率を設定する。中央銀行であるイングランド銀行の公式金利はレポ金利である。このレポ金利は、イングランド銀行の取引相手（銀行、住宅金融組合、証券会社）に対する公開市場操作での利率となる。',
  'boe_monetary_policy_report_hearings.description:ja:DEFAULT': '',
  'boe_monetary_policy_report_hearings.description:ja:United Kingdom': '',
  'boe_ramsden_speech.description:ja:DEFAULT': '',
  'boe_ramsden_speech.description:ja:United Kingdom':
    'イギリスの金融政策委員会（MPC）が基準利率を設定する。中央銀行であるイングランド銀行の公式金利はレポ金利である。このレポ金利は、イングランド銀行の取引相手（銀行、住宅金融組合、証券会社）に対する公開市場操作での利率となる。',
  'boe_rule_speech.description:ja:DEFAULT': '',
  'boe_rule_speech.description:ja:United Kingdom': '',
  'boe_shafik_speech.description:ja:DEFAULT': '',
  'boe_shafik_speech.description:ja:United Kingdom': '',
  'boe_systemic_risk_survey_results.description:ja:DEFAULT': '',
  'boe_systemic_risk_survey_results.description:ja:United Kingdom': '',
  'boe_tenreyro_speech.description:ja:DEFAULT': '',
  'boe_tenreyro_speech.description:ja:United Kingdom':
    'イギリスの金融政策委員会（MPC）が基準利率を設定する。中央銀行であるイングランド銀行の公式金利はレポ金利である。このレポ金利は、イングランド銀行の取引相手（銀行、住宅金融組合、証券会社）に対する公開市場操作での利率となる。',
  'boe_vlieghe_speech.description:ja:DEFAULT': '',
  'boe_vlieghe_speech.description:ja:United Kingdom':
    'イギリスの金融政策委員会（MPC）が基準利率を設定する。中央銀行であるイングランド銀行の公式金利はレポ金利である。このレポ金利は、イングランド銀行の取引相手（銀行、住宅金融組合、証券会社）に対する公開市場操作での利率となる。',
  'boe_weale_speech.description:ja:DEFAULT': '',
  'boe_weale_speech.description:ja:United Kingdom': '',
  'boe_s_carney_and_cunliffe_speak_in_parliament.description:ja:DEFAULT': '',
  'boe_s_carney_and_cunliffe_speak_in_parliament.description:ja:United Kingdom': '',
  'boe_s_governor_carney_speaks.description:ja:DEFAULT': '',
  'boe_s_governor_carney_speaks.description:ja:United Kingdom': '',
  'boe_s_governor_carney_speaks_at_parliament.description:ja:DEFAULT': '',
  'boe_s_governor_carney_speaks_at_parliament.description:ja:United Kingdom': '',
  'boe_s_governor_king_speech.description:ja:DEFAULT': '',
  'boe_s_governor_king_speech.description:ja:United Kingdom': '',
  'bof_gov_liikanen_speech.description:ja:DEFAULT': '',
  'bof_gov_liikanen_speech.description:ja:Finland': '',
  'bank_of_england_quarterly_inflation_report.description:ja:DEFAULT': '',
  'bank_of_england_quarterly_inflation_report.description:ja:United Kingdom': '',
  'boi_gov_visco_speech.description:ja:DEFAULT': '',
  'boi_gov_visco_speech.description:ja:Italy': '',
  'boi_rossi_speech.description:ja:DEFAULT': '',
  'boi_rossi_speech.description:ja:Italy': '',
  'boj_adachi_speech.description:ja:DEFAULT': '',
  'boj_adachi_speech.description:ja:Japan':
    '日本では、金利は政策委員会による金融政策決定会合で決められる。日銀の公式金利は、基準割引率である。金融政策決定会合では、次の会合までの間の金融市場操作におけるガイドラインが決められ、このガイドラインは無担保コール翌日物金利の目標として定められる。',
  'boc_monetary_policy_report.description:ja:DEFAULT': '',
  'boc_monetary_policy_report.description:ja:Canada':
    'カナダでは、基準金利が中央銀行であるカナダ銀行（BoC）理事会によって定められる。公式金利は翌日物レートとなっている。1996年以来、銀行金利は短期金融市場の翌日物レートでの運用幅の上限に設定されている。1980年3月から1996年2月までは、この金利は3カ月割引国債の週間入札金利平均より25ベーシスポイント上に設定されていた。',
  'boj_deputy_gov_iwata_speech.description:ja:DEFAULT': '',
  'boj_deputy_gov_iwata_speech.description:ja:Japan': '',
  'boj_funo_speech.description:ja:DEFAULT': '',
  'boj_funo_speech.description:ja:Japan': '',
  'boj_gov_kuroda_speaks.description:ja:DEFAULT': '',
  'boj_gov_kuroda_speaks.description:ja:Japan': '',
  'boj_harada_speech.description:ja:DEFAULT': '',
  'boj_harada_speech.description:ja:Japan': '',
  'boj_kataoka_speech.description:ja:DEFAULT': '',
  'boj_kataoka_speech.description:ja:Japan':
    '日本では、金利は政策委員会による金融政策決定会合で決められる。日銀の公式金利は、基準割引率である。金融政策決定会合では、次の会合までの間の金融市場操作におけるガイドラインが決められ、このガイドラインは無担保コール翌日物金利の目標として定められる。',
  'boj_kiuchi_speech.description:ja:DEFAULT': '',
  'boj_kiuchi_speech.description:ja:Japan': '',
  'boj_kuwabara_speech.description:ja:DEFAULT': '',
  'boj_kuwabara_speech.description:ja:Japan': '',
  'boj_masai_speech.description:ja:DEFAULT': '',
  'boj_masai_speech.description:ja:Japan':
    '日本では、金利は政策委員会による金融政策決定会合で決められる。日銀の公式金利は、基準割引率である。金融政策決定会合では、次の会合までの間の金融市場操作におけるガイドラインが決められ、このガイドラインは無担保コール翌日物金利の目標として定められる。',
  'boj_monetary_policy_statement_and_press_conference.description:ja:DEFAULT': '',
  'boj_monetary_policy_statement_and_press_conference.description:ja:Japan': '',
  'boj_nakamura_speech.description:ja:DEFAULT': '',
  'boj_nakamura_speech.description:ja:Japan':
    '日本では、金利は政策委員会による金融政策決定会合で決められる。日銀の公式金利は、基準割引率である。金融政策決定会合では、次の会合までの間の金融市場操作におけるガイドラインが決められ、このガイドラインは無担保コール翌日物金利の目標として定められる。',
  'boj_nakaso_speech.description:ja:DEFAULT': '',
  'boj_nakaso_speech.description:ja:Japan': '',
  'boj_quarterly_report.description:ja:DEFAULT': '',
  'boj_quarterly_report.description:ja:Japan': '',
  'boj_sato_speech.description:ja:DEFAULT': '',
  'boj_sato_speech.description:ja:Japan': '',
  'boj_summary_of_opinions.description:ja:DEFAULT': '',
  'boj_summary_of_opinions.description:ja:Japan':
    '日本では、金利は政策委員会による金融政策決定会合で決められる。日銀の公式金利は、基準割引率である。金融政策決定会合では、次の会合までの間の金融市場操作におけるガイドラインが決められ、このガイドラインは無担保コール翌日物金利の目標として定められる。',
  'boj_suzuki_speech.description:ja:DEFAULT': '',
  'boj_suzuki_speech.description:ja:Japan':
    '日本では、金利は政策委員会による金融政策決定会合で決められる。日銀の公式金利は、基準割引率である。金融政策決定会合では、次の会合までの間の金融市場操作におけるガイドラインが決められ、このガイドラインは無担保コール翌日物金利の目標として定められる。',
  'baker_hughes_total_rig_count.description:ja:DEFAULT': '',
  'baker_hughes_total_rig_count.description:ja:United States':
    '米石油掘削リグ稼働数は、アメリカでの週あたりの石油掘削装置の活動数を示したものである。',
  'boj_wakatabe_speech.description:ja:DEFAULT': '',
  'boj_wakatabe_speech.description:ja:Japan':
    '日本では、金利は政策委員会による金融政策決定会合で決められる。日銀の公式金利は、基準割引率である。金融政策決定会合では、次の会合までの間の金融市場操作におけるガイドラインが決められ、このガイドラインは無担保コール翌日物金利の目標として定められる。',
  'bok_mp_meeting_minutes.description:ja:DEFAULT': '',
  'bok_mp_meeting_minutes.description:ja:South Korea': '',
  'bonifacio_day.description:ja:DEFAULT': '',
  'bonifacio_day.description:ja:Philippines': '',
  'boxing_day.description:ja:DEFAULT': '',
  'boxing_day.description:ja:Italy': '',
  'boxing_day.description:ja:Canada': '',
  'boxing_day.description:ja:United Kingdom': '',
  'boxing_day.description:ja:Finland': '',
  'boxing_day.description:ja:New Zealand': '',
  'boxing_day.description:ja:Denmark': '',
  'boxing_day.description:ja:Austria': '',
  'boxing_day.description:ja:Germany': '',
  'boxing_day.description:ja:Sweden': '',
  'boxing_day.description:ja:Greece': '',
  'boxing_day.description:ja:Hong Kong': '',
  'boxing_day.description:ja:Hungary': '',
  'boxing_day.description:ja:Australia': '',
  'boxing_day.description:ja:Netherlands': '',
  'boxing_day.description:ja:Norway': '',
  'boxing_day_substitute_day.description:ja:DEFAULT': '',
  'boxing_day_substitute_day.description:ja:New Zealand': '',
  'boxing_day_substitute_day.description:ja:Canada': '',
  'boxing_day_substitute_day.description:ja:United Kingdom': '',
  'boxing_day_substitute_day.description:ja:Australia': '',
  'brexit_bill_debate.description:ja:DEFAULT': '',
  'brexit_bill_debate.description:ja:United Kingdom': '',
  'brexit_deadline.description:ja:DEFAULT': '',
  'brexit_deadline.description:ja:United Kingdom': '',
  'buddhist_lent_day.description:ja:DEFAULT': '',
  'buddhist_lent_day.description:ja:Thailand': '',
  'budget_balance.description:ja:DEFAULT': '',
  'budget_balance.description:ja:Czech Republic': '',
  'budget_balance.description:ja:Portugal':
    '政府予算収支とは、国家の歳入と歳出の差を示したものである。予算は、歳出が歳入と等しくなった場合に均衡し、歳入が歳出を超える場合は黒字、歳出が歳入を超える場合は赤字となる。',
  'budget_balance.description:ja:Israel':
    '純政府予算収支（貸出・借入を除く）は、政府歳入と歳出の差を示したものである。予算は、歳出が歳入に等しくなった場合に均衡し、歳入が歳出を超える場合は黒字、歳出が再入を超える場合は赤字となる。この数字には、政府の貸出と借入返済は含まれない。',
  'budget_balance.description:ja:France':
    '政府予算収支とは、国家の歳入と歳出の差を示したものであり、財務省特別特別コンポーネントを含む。予算は、歳出が歳入と等しくなった場合に均衡し、歳入が歳出を超える場合は黒字、歳出が再入を超える場合は赤字となる。',
  'budget_balance.description:ja:Hungary':
    '政府予算収支とは、国家の歳入と歳出の差を示したものである。予算は、歳出が歳入と等しくなった場合に均衡し、歳入が歳出を超える場合は黒字、歳出が歳入を超える場合は赤字となる。',
  'budget_balance.description:ja:Philippines':
    '政府予算収支とは、国家の歳入と歳出の差を示したものである。予算は、歳出が歳入と等しくなった場合に均衡し、歳入が歳出を超える場合は黒字、歳出が歳入を超える場合は赤字となる。',
  'budget_balance.description:ja:Canada':
    '政府予算収支とは、公的負債負担を除く、国家の歳入と歳出の差を示したものである。予算は、歳出が歳入と等しくなった場合に均衡し、歳入が歳出を超える場合は黒字、歳出が再入を超える場合は赤字となる。',
  'building_permits.description:ja:DEFAULT': '',
  'building_permits.description:ja:United States':
    '建設許可件数は、新規着工および既存建物の工事への地方自治体からの許可の数を示す。アメリカの地域によっては建設許可は必要ではない。',
  'building_permits_mom.description:ja:DEFAULT': '',
  'building_permits_mom.description:ja:Canada':
    'カナダでは、建設許可は新規着工、改築、増築、改装の金額を示す。材料費、労務費、利益、諸経費を含んだものとなっている。.',
  'building_permits_mom.description:ja:Australia':
    'オーストラリアでは、建設許可数はが既存の建造物における活動も含め、月間の許可された住居数を示している。',
  'building_permits_mom.description:ja:United States':
    '建設許可件数は、新規着工および既存建物の工事への地方自治体からの許可の数を示す。アメリカの地域によっては建設許可は必要ではない。',
  'building_permits_mom.description:ja:New Zealand':
    'ニュージーランドでは、建設許可は着工許可の総額で示されている。',
  'building_permits_mom_final.description:ja:DEFAULT': '',
  'building_permits_mom_final.description:ja:Australia':
    'オーストラリアでは、建設許可数はが既存の建造物における活動も含め、月間の許可された住居数を示している。',
  'building_permits_yoy.description:ja:DEFAULT': '',
  'building_permits_yoy.description:ja:Australia': '',
  'building_permits_yoy.description:ja:South Africa':
    '南アフリカ統計局（Statistics SA）は、南アフリカの最大規模の地方自治体機関から、民間セクターの資金による建設計画の承認・却下や完成した建物に関する情報を収集する建設統計調査を毎月実施している。これらの機関によると、低コスト住宅プロジェクトについては必ずしも通知を受けているわけではないため、低コスト住宅の大部分は含まれていない。月次調査には、完成した建物の総価値の約90％が含まれる。情報収集を担当する統計部署は、地方政府機関である。地方政府機関には地区自治体、大都市自治体、地方自治体がある。',
  'building_permits_s_a_mom.description:ja:DEFAULT': '',
  'building_permits_s_a_mom.description:ja:New Zealand': '',
  'bundesbank_annual_report.description:ja:DEFAULT': '',
  'bundesbank_annual_report.description:ja:Germany':
    'ドイツはユーロを採用している欧州連合（EU）加盟国である。ドイツの基準金利は欧州中央銀行が設定し、公式には主要リファイナンス・オペ（MRO）と呼ばれる。',
  'bundesbank_annual_report_2017.description:ja:DEFAULT': '',
  'bundesbank_annual_report_2017.description:ja:Germany': '',
  'bundesbank_buch_speech.description:ja:DEFAULT': '',
  'bundesbank_buch_speech.description:ja:Germany':
    'ドイツはユーロを採用している欧州連合（EU）加盟国である。ドイツの基準金利は欧州中央銀行が設定し、公式には主要リファイナンス・オペ（MRO）と呼ばれる。',
  'bundesbank_monthly_report.description:ja:DEFAULT': '',
  'bundesbank_monthly_report.description:ja:Germany':
    'ドイツはユーロを採用している欧州連合（EU）加盟国である。ドイツの基準金利は欧州中央銀行が設定し、公式には主要リファイナンス・オペ（MRO）と呼ばれる。',
  'bundesbank_president_weidmann_speech.description:ja:DEFAULT': '',
  'bundesbank_president_weidmann_speech.description:ja:Germany': '',
  'bundesbank_thiele_speech.description:ja:DEFAULT': '',
  'bundesbank_thiele_speech.description:ja:Germany': '',
  '4-week_bill_auction.description:ja:DEFAULT': '',
  '4-week_bill_auction.description:ja:United States': '',
  'bundesbank_wuermeling_speech.description:ja:DEFAULT': '',
  'bundesbank_wuermeling_speech.description:ja:Germany':
    'ドイツはユーロを採用している欧州連合（EU）加盟国である。ドイツの基準金利は欧州中央銀行が設定し、公式には主要リファイナンス・オペ（MRO）と呼ばれる。',
  'business_climate_indicator.description:ja:DEFAULT': '',
  'business_climate_indicator.description:ja:France':
    'フランスの企業景況感指数は製造業、サービス業、販売業（小売・卸売）、建設業の各調査から得られた情報をまとめたものであり、これらの26種の調査で得られた「改善」「悪化」の意見の正味の差異（バランス）で構築されている。',
  'business_confidence.description:ja:DEFAULT': '',
  'business_confidence.description:ja:Switzerland':
    'スイスではKOFスイス経済研究所の経済バロメーターが、企業経営者が景気についてどの程度楽観的に考えているか、自社の見通しについてどのように感じているかを測定している。KOF経済バロメーターは、3つのモジュールによるマルチセクター設計に基づいている。2014年版は219の指標変数が、統計的に決定されたウェイトに基づいて組み合わせられている。最も重要なモジュールは、建設業と銀行業を除いたGDPであり、これがスイスのGDPの90％以上を占めている。',
  'business_confidence.description:ja:United Kingdom':
    '英国には英国産業連盟がその産業動向調査の中で発表している製造業楽観度指数がある。製造業の小・中・大企業平均400社を対象に、四半期ごとに調査が実施される。一般的なビジネス状況と輸出事業状況、投資、生産能力、受注、雇用、生産量、在庫、国内・EU・非EU市場での価格競争力、技術革新およびトレーニングに関する総合的なセンチメントを評価している。各評価ごとに、肯定的な回答の割合から否定的な回答の割合を引いたものがサブ指数として算出され、それから各サブ指数の加重平均である総合指数が算出される。この楽観度指数がプラス100の場合、すべての調査回答者が非常に楽観的な見通しを持っていることを示し、-100の場合、すべての調査回答者が悲観的な見通しを持っていることを示し、指数が0の場合は、どちらでもない中立であることを示している。',
  'business_confidence.description:ja:New Zealand': '',
  'business_confidence.description:ja:Germany': '',
  'business_confidence.description:ja:Thailand':
    'タイの企業業況判断指数（BSI）は、現在の業績、受注、雇用、生産、コスト、投資に関する約1500人の企業経営者の予想を測定している。調査票は前月の最終週に送付され、当月の第3週までに集計される。測定する各変数について、肯定的・否定的・中立的な回答の割合を算出し、肯定的な解答の割合に中立的かな回答の割合の半分を合計したものを最終的な指数として報告する。BSIが50を超えると景況感が改善したことを示し、50を下回ると悪化したことを示す。',
  'business_confidence.description:ja:Israel':
    'イスラエルの業況傾向調査では、生産、売上、需要、雇用に関する現在および将来の動向について、企業幹部の楽観度を測定している。 また、米ドル／イスラエルシェケルの為替レートやインフレ率の変化についての彼らの予想も評価している。この調査は、製造業、建設業、小売業、ホテル、サービス業の5つの経済セクターの企業幹部を対象にしている。景況感は　マイナス100からプラス100の間で測定され、0を超えると楽観的、0は中立的、0を下回ると悲観的を示す。',
  'business_confidence.description:ja:South Korea':
    '韓国では製造業の景況判断指数（BSI）が、企業経営者の当月の経済のパフォーマンスと翌月の見通しに対する楽観度を測定している。この指数は約2,800社を対象に、売上高、在庫、生産設備、価格、労働力、収益性など15の分野について調査したもの。測定された各指標ごとに、肯定的な回答数と否定的な回答数の正味の差が計算される。BSIが100を超えた場合は見通しが改善していることを、100を下回る場合は見通しが悪化していることを示す。',
  'business_confidence.description:ja:Greece':
    'ギリシャでは、企業景況感指数が企業の現状と今後の見通しを測定している。調査は電話で行われ、個人事業主を含む410社を対象とする。質問項目は直近数カ月間の生産動向、受注、輸出受注、在庫、生産予測など。この指標は因子モデルの推定によって算出され、調査に含まれる共通の情報をまとめている。この指標の上昇は、活動が活発化して事業環境が改善していることを示す。',
  'business_confidence.description:ja:Hong Kong':
    '香港では、業務展望四半期統計調査が、前四半期と比較した当四半期の事業状況の変化に関する企業経営者の予想を測定している。調査対象は経済の主要セクターである製造業、建設業、卸売・小売業、宿泊・飲食サービス業、運輸・倉庫・宅配便業、情報・通信業、金融・保険業、不動産業、専門・ビジネスサービス業の上級管理職560名。調査結果は「見通し改善」を選択した回答者の割合と「見通し悪化」を選択した回答者の割合の差である正味のバランスで表示され、正の符号は上向き傾向、負の符号は下向き傾向を示す。',
  'business_confidence.description:ja:Finland':
    '製造業信頼感指数は、製造業の会社幹部の信頼感を測る調査である。',
  'business_confidence.description:ja:Colombia':
    'コロンビアの工業部門信頼感指数（ICI）は、工業・商業部門の経営者が経済のパフォーマンスについてどの程度楽観的であるか、また自社の見通しについて測定している。またこの指数は、投資環境、輸出活動、設備稼働率、雇用に関する予想も評価している。この指数はマイナス100からプラス100の間で変化し、マイナス100は極端な信頼感のなさ、0は中立、プラス100は極端に強い信頼感を示す。',
  'business_confidence.description:ja:Euro Area':
    '企業景況感指数（BCI）は、ユーロ圏の製造業に関する複合指標を適時に提供するために算出される。この指標は、製造業調査から得られた意見のバランス（改善・悪化の正味の差異）5種類、つまり直近数カ月間の生産動向、受注、輸出受注、在庫、生産予測を使用する。このためBCIは、3種類の入力値のみに基づく工業信頼感指標とは異なる。調査は電話で行われ、ユーロ圏の23,000社を対象とする。',
  'business_confidence.description:ja:South Africa':
    '南アフリカの南アフリカ経済研究所（BER）企業信頼感指数は、建設業勤務の1,400人、販売部門勤務の1,400人、製造部門勤務の1,000人を対象としている。この調査では、売上、受注、雇用、在庫、販売価格に関する現在および今後の予想について、企業の上級幹部が抱いている楽観視の水準を評価している。この指数は0から100の間で変化し、0は極端に信頼感がないことを、50は中立、100は極端に強い信頼感があることを示す。',
  'business_confidence.description:ja:France':
    'フランスの企業景況感指数は、鉱工業界の経営者が抱く事業の現況についてのセンチメントと予想を測定する。調査対象は製造業と鉱業の企業約4,000社。この指標は因子分析の手法を用い、1993年から2011年までの過去の指数値に関連した現在のセンチメントを測定する方法で算出される。110を超える値は異例に高い楽観を、90未満の値は異例に高い悲観を示し、100は中立を示す。',
  'business_confidence.description:ja:Belgium':
    'ベルギーの企業景況感調査は、企業を経営する人々が現在の経済状況についてどの程度楽観的に考えているか、また今後3カ月間の自社の見通しについてどのように感じているかを測定する。毎月、約6,000人のビジネスリーダーが調査の対象となる。',
  'business_confidence.description:ja:Mexico':
    'メキシコでは月次企業動向調査（EMOE）により短期、中期、長期の生産、生産設備稼働率、雇用、在庫に関する企業幹部の予想を測定している。またこの調査では、一般的な経済環境とそれが企業活動にどのような影響を与えるかも予想している。EMOEは全国を対象に0から100の尺度で測定され、50を超えると楽観的、50は中立的、50を下回ると悲観的となる。',
  'business_confidence.description:ja:Norway':
    'ノルウェーの景況感調査である製造業信頼感指数（ICI）は、生産予測、総受注残、自社製品在庫に関する質問への回答の数学的平均値（バランス）である。この指標では、生産量増加の予想はその次の四半期の生産量増加のシグナルとなり、総受注残の増加は受注のフルフィルメントによる生産量の増加を示し、自社製品在庫の増加は販売の減速と活動の縮小を示すことから、工業生産水準の目安となる。',
  'business_confidence.description:ja:Brazil':
    'ブラジルでは、経営者信頼感指数（ICEI）が企業の現状とその見通しを測定している。調査対象は、建設業、鉱業、製造業の約2,500社である。質問は電話で行われ、直近数カ月間の生産動向、受注、輸出受注、在庫、生産予測などが中心である。この指数は0から100の間で変化し、50は中立、0は極度に信頼感がなく、100は極度に信頼感が強いことを示す。',
  'business_confidence.description:ja:Austria':
    'オーストリアでは、企業景況感指数が企業の現状と今後の見通しを測定している。質問項目は直近数カ月間の生産動向、受注、輸出受注、在庫、生産予測など。この指標は因子モデルの推定によって算出され、調査に含まれる共通の情報をまとめている。この指標の上昇は、活動が活発化して事業環境が改善していることを示す。',
  'business_confidence.description:ja:Sweden':
    'スウェーデンの企業部門の信頼度指標は、スウェーデン企業の実際の業績、現在の状況、将来の予想を完結に定性的に示すことを目的としている。この調査の変数には新規受注、生産、雇用が含まれている。調査対象は企業部門の6,000社である。',
  'business_confidence.description:ja:Netherlands':
    'オランダでは製造業1,700社を対象に生産者信頼感調査を実施し、製造業のあらゆる活動に関する経済動向の最新情報を収集している。この調査では以下3つの要素を調査している：当月の自社の受注状況の評価、当月の完成品在庫の評価、その後3カ月間の経済活動の見通し。この指数は、肯定的な回答の割合から否定的な回答の割合を差し引いて算出される。したがってこの指数の範囲は、マイナス100（すべての参加者が自社の状況を悪いと評価し、さらに悪化すると予想している）からプラス100（すべての企業が現在の状況に満足し、さらに改善すると予想している）までで、0は中立を表す。',
  'business_confidence.description:ja:Italy':
    'イタリアの製造業信頼感指数はイタリア企業4,000社を対象としている。質問項目は受注・生産・在庫の現在の傾向、受注・生産・価格・一般的な経済状況の短期的な予測に焦点を当てている。この指数では季節的影響が調整され、基準年を2005年としている。指数の100は景況感に変化がないことを示し、100を超える値は景況感が高まっていることを、100未満は期待が低いことを示す。',
  'business_confidence.description:ja:Indonesia':
    'インドネシアの事業傾向指数（BTI）は、企業が現在の事業利益、生産能力、平均労働時間、将来の国内外からの受注、販売価格、原材料などについてどう予想しているかどうかを測定する。調査対象は、国内のほぼすべての地域の、経済の主要部門（農業、鉱業・採石業、製造業、電気・ガス・水道業、建設業、販売業、ホテル業、運輸・通信業、金融・サービス業）の大企業および中堅企業の経営者約2,000人である。測定された各変数で、肯定的、否定的、中立的な回答の割合が計算され、最終的な指数はこれらの割合の加重平均として報告される。BTIが100を超えると見通しが改善していることを、100を下回ると見通しが悪化していることを示す。',
  'business_confidence.description:ja:Portugal':
    'ポルトガルでは経済活動の複数のセクターに関する総合指標を適時に得るために、国家統計局（INE）の経済環境指標を3期移動平均で算出している。この指標は製造業（1,179社）、販売業（1,102社）、建設・公共事業（822社）、サービス業（1,427社）の各調査から得られた好悪の回答の正味の差異（バランス）を用いて推定される。計算は因子分析に基づき、推定された系列（共通成分）はGDP変化率を用いて調整される。移動平均を用いることで不規則な動きを除去して系列を平滑化し、短期的なトレンドを検出することができる。',
  'business_confidence.description:ja:Singapore':
    'シンガポールでは製造業の企業約410社を対象にした製造業業況予想調査が実施されている。これらの企業は、向こう6カ月間の一般的な事業状況、生産量、および雇用に関する予想を評価するよう求められる。寄せられた回答は、雇用と付加価値への貢献度によって重み付けされる。この指標は、肯定的な評価の加重割合（％）と否定的な回答の加重割合の差として計算される。指数はマイナス100からプラス100の間で変化し、マイナス100は極めて信頼感が低いことを、0は中立を、100は極めて信頼感が高いことを示す。',
  'business_confidence.description:ja:Spain':
    'スペインでは、工業活動の将来的動向に対する企業経営者の楽観度を測る指標として、製造業信頼感指数（ICI）が用いられ、この国の異なる企業規模、地域、セクターの2,500社の企業を対象に調査が実施されている。回答率は約60％。ICIはマイナス100（極めて信頼感が低い）からプラス100（極めて信頼感が高い）の尺度で測定される。',
  'business_confidence.description:ja:Denmark':
    'デンマークでは、製造業の業況傾向調査が製造業の現状と今後3カ月間の見通しに関する情報を提示している。この調査で使用される最も重要な変数は生産、雇用、新規受注、販売価格、投資計画、生産制限である。',
  'business_confidence.description:ja:Czech Republic':
    'チェコ共和国の企業景況感調査は、企業を経営する人々が景気についてどの程度楽観的に考えているか、また今後3カ月間の自社の見通しについてどのように感じているかを測定する。チェコ統計局の景況感指標は製造業、建設業、販売業、および特定のサービス業における季節調整済みの景況感指標の加重平均値である。',
  'business_confidence.description:ja:Russia':
    'ロシアでは、製造業者のセンチメントを測る景況感指数が発表されている。この指数は実際に存在する需要の水準、完成品の在庫（符号は逆にする）、今後3カ月間に予想される生産量の変化に対する評価の算術平均値のバランスとして計算される。この指数は、楽観的な見方をする経営者の割合と、悲観的な見方をする経営者の割合の差（バランス）を示す。この指数は、マイナス100（回答企業すべてが自社の状況を悪いと評価し、さらに悪化すると予想している）からプラス100（回答企業すべてが現在の状況に満足し、さらに改善すると予想している）の間の値をとり、0は中立を表す。',
  'business_inflation_expectations.description:ja:DEFAULT': '',
  'business_inflation_expectations.description:ja:New Zealand':
    'ニュージーランドの企業のインフレ予想とは2年間のインフレ予測を指す。2年の間に中央銀行の金融政策が物価に反映されると考えられている。',
  'business_inventories_mom.description:ja:DEFAULT': '',
  'business_inventories_mom.description:ja:United States':
    '米国の企業在庫は製造業者、小売業者、および卸売業者の在庫の月次変動率を測定する。在庫は国内総生産の重要な構成要素である。',
  'baker_hughes_oil_rig_count.description:ja:DEFAULT': '',
  'baker_hughes_oil_rig_count.description:ja:United States':
    '米石油掘削リグ稼働数は、アメリカでの週あたりの石油掘削装置の活動数を示したものである。',
  'business_investment_qoq.description:ja:DEFAULT': '',
  'business_investment_qoq.description:ja:United Kingdom': '',
  'business_investment_qoq_final.description:ja:DEFAULT': '',
  'business_investment_qoq_final.description:ja:United Kingdom':
    '英国では企業投資とは、民間企業および公営企業による純投資額を指す。これには輸送、情報通信技術（ICT）機器、その他の機械装置、酪・農業生物資産、知的財産製品（IPP：ソフトウェア、研究開発、芸術的原作品、鉱物探査への投資を含む）、建物などの建造物への投資が含まれる。中央政府や地方政府による投資、住居への投資、非生産資産（土地など）の譲渡に伴う費用は含まれていない。。',
  'business_investment_qoq_prel.description:ja:DEFAULT': '',
  'business_investment_qoq_prel.description:ja:United Kingdom':
    '英国では企業投資とは、民間企業および公営企業による純投資額を指す。これには輸送、情報通信技術（ICT）機器、その他の機械装置、酪・農業生物資産、知的財産製品（IPP：ソフトウェア、研究開発、芸術的原作品、鉱物探査への投資を含む）、建物などの建造物への投資が含まれる。中央政府や地方政府による投資、住居への投資、非生産資産（土地など）の譲渡に伴う費用は含まれていない。。',
  'boe_saporta_speech.description:ja:DEFAULT': '',
  'boe_saporta_speech.description:ja:United Kingdom': '',
  'business_investment_yoy.description:ja:DEFAULT': '',
  'business_investment_yoy.description:ja:United Kingdom': '',
  'business_nz_markit_pmi.description:ja:DEFAULT': '',
  'business_nz_markit_pmi.description:ja:New Zealand':
    'ビジネスNZ製造業購買担当者景気指数（PMI）は生産、新規受注、納入、在庫、雇用の各ディフュージョン指数を基にした複合指数である。指数が50を超えると製造業が前月に比べて拡大していることを、50を切ると縮小していることを、50は変化がないことを示す。',
  'business_outlook_survey_indicator.description:ja:DEFAULT': '',
  'business_outlook_survey_indicator.description:ja:Canada':
    '企業見通し調査は中央銀行の各地方支店がカナダの企業部門の国内総生産の構成比に従って選んだ約100社の企業の上級管理職に行ったインタビューをまとめたものである。回答での改善・悪化の意見の正味差異（バランス）はプラス+100とマイナス100の間で変化する。',
  'cb_consumer_confidence.description:ja:DEFAULT': '',
  'cb_consumer_confidence.description:ja:United States': '',
  'cb_employment_trends_index.description:ja:DEFAULT': '',
  'cb_employment_trends_index.description:ja:United States': '',
  'cb_leading_economic_index.description:ja:DEFAULT': '',
  'cb_leading_economic_index.description:ja:China': '',
  'cb_leading_economic_index_mom.description:ja:DEFAULT': '',
  'cb_leading_economic_index_mom.description:ja:United Kingdom': '',
  'cbi_business_optimism_index.description:ja:DEFAULT': '',
  'cbi_business_optimism_index.description:ja:United Kingdom':
    '英国には英国産業連盟がその産業動向調査の中で発表している製造業楽観度指数がある。製造業の小・中・大企業平均400社を対象に、四半期ごとに調査が実施される。一般的なビジネス状況と輸出事業状況、投資、生産能力、受注、雇用、生産量、在庫、国内・EU・非EU市場での価格競争力、技術革新およびトレーニングに関する総合的なセンチメントを評価している。各評価ごとに、肯定的な回答の割合から否定的な回答の割合を引いたものがサブ指数として算出され、それから各サブ指数の加重平均である総合指数が算出される。この楽観度指数がプラス100の場合、すべての調査回答者が非常に楽観的な見通しを持っていることを示し、-100の場合、すべての調査回答者が悲観的な見通しを持っていることを示し、指数が0の場合は、どちらでもない中立であることを示している。',
  'cbr_press_conference.description:ja:DEFAULT': '',
  'cbr_press_conference.description:ja:Russia':
    'ロシアでは金利の決定はロシア連邦中央銀行が行う。2013年9月16日より公式金利は1週間物レポ金利となった。2013年9月15日までの公式金利はリファイナンス金利であり、借手の金利の上限とみなされ、納税額を算出する際の基準であった。',
  'cdu_congress.description:ja:DEFAULT': '',
  'cdu_congress.description:ja:Germany': '',
  'chain_stores_wow.description:ja:DEFAULT': '',
  'chain_stores_wow.description:ja:United States': '',
  'cnb_interest_rate_decison.description:ja:DEFAULT': '',
  'cnb_interest_rate_decison.description:ja:Czech Republic':
    'チェコ共和国の基準金利はチェコ国立銀行（Cesky Národní Bank、CNB）が設定する。公式金利は、商業銀行が終業時の余剰資金を中央銀行に預ける際の2週間物のレポ金利である。',
  'cpif_yoy.description:ja:DEFAULT': '',
  'cpif_yoy.description:ja:Sweden':
    '2017年9月現在、スウェーデン中央銀行のリクスバンクはインフレターゲットの目標変数として、一定金利での消費者物価指数であるCPIFを使用している。同中央銀行は「金融政策の観点から見ると、CPI（消費者物価指数）の欠点は、政策金利の変更に直接影響を受けることだ。これらの調整は住宅ローン金利への影響を通じて、根本的なインフレ圧力とは関係なく、CPIに大きく直接的な影響を与える」としている。',
  'cabinet_meeting_on_brexit_draft_deal.description:ja:DEFAULT': '',
  'cabinet_meeting_on_brexit_draft_deal.description:ja:United Kingdom': '',
  'caixin_manufacturing_pmi.description:ja:DEFAULT': '',
  'caixin_manufacturing_pmi.description:ja:China':
    '中国の財新製造業購買担当者景気指数（PMI）は製造業民間企業430社の調査に基づき、製造業の業績を測定し、それぞれウエート付けされた、新規受注（30%）、生産量（25%）、雇用（20%）、サプライヤー納期（15%）、購買品在庫（10%）という5つの個別インデックスから構成され、納期インデックスは他のインデックスと比較可能になるように符号が反転される。50を超える指数は製造業セクターが前期に比べて拡大していることを、50を切る指数は縮小していることを、50は変化がないことを示す。',
  'caixin_manufacturing_pmi_final.description:ja:DEFAULT': '',
  'caixin_manufacturing_pmi_final.description:ja:China':
    '中国の財新製造業購買担当者景気指数（PMI）は製造業民間企業430社の調査に基づき、製造業の業績を測定し、それぞれウエート付けされた、新規受注（30%）、生産量（25%）、雇用（20%）、サプライヤー納期（15%）、購買品在庫（10%）という5つの個別インデックスから構成され、納期インデックスは他のインデックスと比較可能になるように符号が反転される。50を超える指数は製造業セクターが前期に比べて拡大していることを、50を切る指数は縮小していることを、50は変化がないことを示す。',
  'caixin_manufacturing_pmi_flash.description:ja:DEFAULT': '',
  'caixin_manufacturing_pmi_flash.description:ja:China':
    '中国の財新製造業購買担当者景気指数（PMI）は製造業民間企業430社の調査に基づき、製造業の業績を測定し、それぞれウエート付けされた、新規受注（30%）、生産量（25%）、雇用（20%）、サプライヤー納期（15%）、購買品在庫（10%）という5つの個別インデックスから構成され、納期インデックスは他のインデックスと比較可能になるように符号が反転される。50を超える指数は製造業セクターが前期に比べて拡大していることを、50を切る指数は縮小していることを、50は変化がないことを示す。',
  'canada_day.description:ja:DEFAULT': '',
  'canada_day.description:ja:Canada': '',
  'canada_day_substitute_day.description:ja:DEFAULT': '',
  'canada_day_substitute_day.description:ja:Canada': '',
  'canadian_portfolio_investment_in_foreign_securities.description:ja:DEFAULT': '',
  'canadian_portfolio_investment_in_foreign_securities.description:ja:Canada': '',
  'capacity_utilization.description:ja:DEFAULT': '',
  'capacity_utilization.description:ja:United States':
    '設備稼働率は、潜在的な生産水準が満たされているか、または使用されているレートを測定するために使用される。この指数は米国内にあるすべての施設を所有者に関係なく対象とし、89の業界（製造業71、鉱業16、公益事業2）で構築されている。',
  'capacity_utilization.description:ja:Canada':
    '設備稼働率は、製造業が生産能力をどれくらい利用しているかを示す指標であり、潜在的な生産能力に対する実際の生産量の割合のことである。',
  'capacity_utilization_qoq.description:ja:DEFAULT': '',
  'capacity_utilization_qoq.description:ja:Sweden':
    'スウェーデンでは、設備稼働率は生産能力と実際の稼働状況との差を示し、労働力と資本が最も良く活用され、実際の生産量が生産能力に近い時に、稼働率は高くなる。',
  'capital_flows.description:ja:DEFAULT': '',
  'capital_flows.description:ja:China':
    '中国では、国際的な資本フローは、国際収支の資本・金融収支で測定される。',
  'capital_spending_yoy.description:ja:DEFAULT': '',
  'capital_spending_yoy.description:ja:Japan':
    '日本では、設備投資は、資本金1,000万円以上の民間営利企業の設備投資額の前年比を指す。調査対象は、金融・保険業を除く全産業。',
  'car_production_mom.description:ja:DEFAULT': '',
  'car_production_mom.description:ja:Brazil':
    'ブラジルの自動車生産台数は、月間の乗用車、トラック、バスを含む自動車の総生産台数を示している。',
  'car_production_yoy.description:ja:DEFAULT': '',
  'car_production_yoy.description:ja:United Kingdom': '',
  'car_registrations.description:ja:DEFAULT': '',
  'car_registrations.description:ja:South Africa':
    '南アフリカでは、新車販売台数は乗用車新車の合計国内販売台数である。',
  'carnival.description:ja:DEFAULT': '',
  'carnival.description:ja:Brazil': '',
  'carnival_day.description:ja:DEFAULT': '',
  'carnival_day.description:ja:Brazil': '',
  'cash_reserve_ratio.description:ja:DEFAULT': '',
  'cash_reserve_ratio.description:ja:India':
    '預金準備率とは、商業銀行が現金または中央銀行への預金のいずれかの形で準備金として保有しなければならない、顧客の総預金額に対する指定された最小割合のことである。',
  'catalonian_parlimentary_elections.description:ja:DEFAULT': '',
  'catalonian_parlimentary_elections.description:ja:Spain': '',
  'cement_production_yoy.description:ja:DEFAULT': '',
  'cement_production_yoy.description:ja:Colombia': '',
  'central_bank_fx_reserves.description:ja:DEFAULT': '',
  'central_bank_fx_reserves.description:ja:Poland': '',
  'central_economic_work_conference.description:ja:DEFAULT': '',
  'central_economic_work_conference.description:ja:China': '',
  'chain_stores_sales_wow.description:ja:DEFAULT': '',
  'chain_stores_sales_wow.description:ja:United States':
    '米国のチェーンストア売上高とは、ディスカウント百貨店を除く百貨店における商品およびサービスの小売売上高を指す。この値は、月次小売業調査のデータに基づいている。調査対象は、家具・家庭用品、電子機器・家電、衣料品・アクセサリー、スポーツ用品、ホビー、楽器、書籍、一般商品、事務用品、文具、ギフトなど、最終消費者に商品や関連サービスを販売する1つ以上の拠点を有する企業。チェーンストアの売上高には、オンラインのみの店舗のデータは含まれない。',
  'chakri_day.description:ja:DEFAULT': '',
  'chakri_day.description:ja:Thailand': '',
  'chicago_fed_national_activity_index.description:ja:DEFAULT': '',
  'chicago_fed_national_activity_index.description:ja:United States':
    'シカゴ連銀全米経済活動指数（CFNAI）は、全体的な経済活動とそれに関連するインフレ圧力を測定することを目的としている。CFNAIは、全米の経済活動を示す85種の既存の月次指標の加重平均に基づく。平均値は0で、標準偏差は1。経済活動は時間の経過とともにトレンド成長率に近づく傾向があるため、正の値はトレンドを上回る成長、負の値はトレンドを下回る成長を示す。',
  'chicago_purchasing_managers_index.description:ja:DEFAULT': '',
  'chicago_purchasing_managers_index.description:ja:United States': '',
  'children_s_day.description:ja:DEFAULT': '',
  'children_s_day.description:ja:South Korea': '',
  'children_s_day.description:ja:Japan': '',
  'chinese_new_year.description:ja:DEFAULT': '',
  'chinese_new_year.description:ja:Singapore': '',
  'chinese_new_year.description:ja:Hong Kong': '',
  'chinese_new_year.description:ja:Taiwan': '',
  'chinese_new_year.description:ja:China': '',
  'chinese_new_year.description:ja:Indonesia': '',
  'chinese_new_year.description:ja:Philippines': '',
  'chinese_new_year_s_eve.description:ja:DEFAULT': '',
  'chinese_new_year_s_eve.description:ja:Taiwan': '',
  'ching_ming_festival.description:ja:DEFAULT': '',
  'ching_ming_festival.description:ja:Hong Kong': '',
  'christmas_day.description:ja:DEFAULT': '',
  'christmas_day.description:ja:South Korea': '',
  'christmas_day.description:ja:Singapore': '',
  'christmas_day.description:ja:Hong Kong': '',
  'christmas_day.description:ja:Philippines': '',
  'christmas_day.description:ja:Colombia': '',
  'christmas_day.description:ja:India': '',
  'christmas_day.description:ja:New Zealand': '',
  'christmas_day.description:ja:Australia': '',
  'christmas_day.description:ja:Indonesia': '',
  'christmas_day.description:ja:Canada': '',
  'christmas_day.description:ja:Greece': '',
  'christmas_day.description:ja:United States': '',
  'christmas_day.description:ja:United Kingdom': '',
  'christmas_day.description:ja:Ireland': '',
  'christmas_day.description:ja:Denmark': '',
  'christmas_day.description:ja:Czech Republic': '',
  'christmas_day.description:ja:Spain': '',
  'christmas_day.description:ja:South Africa': '',
  'christmas_day.description:ja:France': '',
  'christmas_day.description:ja:Belgium': '',
  'christmas_day.description:ja:Mexico': '',
  'christmas_day.description:ja:Norway': '',
  'christmas_day.description:ja:Poland': '',
  'christmas_day.description:ja:Germany': '',
  'christmas_day.description:ja:Austria': '',
  'christmas_day.description:ja:Sweden': '',
  'christmas_day.description:ja:Finland': '',
  'christmas_day.description:ja:Brazil': '',
  'christmas_day.description:ja:Hungary': '',
  'christmas_day.description:ja:Italy': '',
  'christmas_day.description:ja:Netherlands': '',
  'christmas_day.description:ja:Portugal': '',
  'christmas_day.description:ja:Switzerland': '',
  'christmas_eve.description:ja:DEFAULT': '',
  'christmas_eve.description:ja:Switzerland': '',
  'christmas_eve.description:ja:Austria': '',
  'christmas_eve.description:ja:Brazil': '',
  'christmas_eve.description:ja:Sweden': '',
  'christmas_eve.description:ja:Germany': '',
  'christmas_eve.description:ja:Denmark': '',
  'christmas_eve.description:ja:Czech Republic': '',
  'christmas_eve.description:ja:Finland': '',
  'christmas_eve.description:ja:Philippines': '',
  'christmas_eve.description:ja:Hungary': '',
  'christmas_eve.description:ja:Norway': '',
  'chung_yeung_day.description:ja:DEFAULT': '',
  'chung_yeung_day.description:ja:Hong Kong': '',
  'chung_yeung_festival.description:ja:DEFAULT': '',
  'chung_yeung_festival.description:ja:Hong Kong': '',
  'chuseok_festivity.description:ja:DEFAULT': '',
  'chuseok_festivity.description:ja:South Korea': '',
  'claimant_count_change.description:ja:DEFAULT': '',
  'claimant_count_change.description:ja:United Kingdom':
    '英国の申請者数は、失業関連の給付金を申請する人の数の変化を測定する。',
  'claimant_count_rate.description:ja:DEFAULT': '',
  'claimant_count_rate.description:ja:United Kingdom': '',
  'clean_monday.description:ja:DEFAULT': '',
  'clean_monday.description:ja:Greece': '',
  'coincident_index_-_final.description:ja:DEFAULT': '',
  'coincident_index_-_final.description:ja:Japan':
    '一致指数は景気循環と相関があり、経済の現状を示すために用いられる。一般的に、一致指数が上昇すると景気が拡大していることを示し、一致指数が低下すると景気が縮小していることを示す。この指数は、先行指標11項目、一致指標11項目、遅行指標6項目の前月比の変化率を用いて算出される。',
  'coincident_index_final.description:ja:DEFAULT': '',
  'coincident_index_final.description:ja:Japan':
    '一致指数は景気循環と相関があり、経済の現状を示すために用いられる。一般的に、一致指数が上昇すると景気が拡大していることを示し、一致指数が低下すると景気が縮小していることを示す。この指数は、先行指標11項目、一致指標11項目、遅行指標6項目の前月比の変化率を用いて算出される。',
  'coincident_index_prel.description:ja:DEFAULT': '',
  'coincident_index_prel.description:ja:Japan':
    '一致指数は景気循環と相関があり、経済の現状を示すために用いられる。一般的に、一致指数が上昇すると景気が拡大していることを示し、一致指数が低下すると景気が縮小していることを示す。この指数は、先行指標11項目、一致指標11項目、遅行指標6項目の前月比の変化率を用いて算出される。',
  'columbus_day.description:ja:DEFAULT': '',
  'columbus_day.description:ja:Colombia': '',
  'columbus_day.description:ja:United States': '',
  'coming_of_age_day.description:ja:DEFAULT': '',
  'coming_of_age_day.description:ja:Japan': '',
  'boe_sharp_speech.description:ja:DEFAULT': '',
  'boe_sharp_speech.description:ja:United Kingdom': '',
  'commbank_composite_pmi_flash.description:ja:DEFAULT': '',
  'commbank_composite_pmi_flash.description:ja:Australia':
    'オーストラリアのIHSマークイット・オーストラリア総合PMI生産指数は、コモンウェルス銀行製造業生産指数とコモンウェルス銀行サービス業事業活動指数のGDP加重平均値である。この指数は、オーストラリアの民間部門の経済全体における企業活動の変化を追跡する。この指数が50を超えると企業活動が拡大していることを、50を下回ると縮小していることを示す。',
  'chain_stores_sales_yoy.description:ja:DEFAULT': '',
  'chain_stores_sales_yoy.description:ja:United States':
    '米国のチェーンストア売上高とは、ディスカウント百貨店を除く百貨店における商品およびサービスの小売売上高を指す。この値は、月次小売業調査のデータに基づいている。調査対象は、家具・家庭用品、電子機器・家電、衣料品・アクセサリー、スポーツ用品、ホビー、楽器、書籍、一般商品、事務用品、文具、ギフトなど、最終消費者に商品や関連サービスを販売する1つ以上の拠点を有する企業。チェーンストアの売上高には、オンラインのみの店舗のデータは含まれない。',
  'commbank_manufacturing_pmi_flash.description:ja:DEFAULT': '',
  'commbank_manufacturing_pmi_flash.description:ja:Australia':
    'IHSマークイット製造業PMIは、オーストラリアの民間製造業400社以上の購買担当幹部を代表するパネルに送られたアンケートへの毎月の回答を編纂したデータに基づいている。このパネルは、GDPと企業の従業員規模によって階層に分けられている。製造業は以下の9つのカテゴリーに分類される：食品・飲料、繊維・衣料、木材・紙、化学・プラスチック・ゴム、金属・金属製品、電子・電気機器、機械・装置、輸送機器、その他製造業。数値が50を超えると、製造業が前月に比べて拡大していることを示し、50を下回ると縮小、50は変化がないことを示す。',
  'commbank_services_pmi_final.description:ja:DEFAULT': '',
  'commbank_services_pmi_final.description:ja:Australia':
    'IHSマークイットサービス業PMIは、オーストラリアの民間サービス業400社以上の購買担当幹部を代表するパネルに送られたアンケートへの毎月の回答を編纂したデータに基づいている。このパネルは、GDPと企業の従業員規模によって階層に分けられている。サービス業は以下の５つのカテゴリーに分類される：輸送・倉庫、消費者サービス、情報・通信、金融・保険、不動産・ビジネスサービス。数値が50を超えると、製造業が前月に比べて拡大していることを示し、50を下回ると縮小、50は変化がないことを示す。',
  'commbank_services_pmi_flash.description:ja:DEFAULT': '',
  'commbank_services_pmi_flash.description:ja:Australia':
    'IHSマークイットサービス業PMIは、オーストラリアの民間サービス業400社以上の購買担当幹部を代表するパネルに送られたアンケートへの毎月の回答を編纂したデータに基づいている。このパネルは、GDPと企業の従業員規模によって階層に分けられている。サービス業は以下の５つのカテゴリーに分類される：輸送・倉庫、消費者サービス、情報・通信、金融・保険、不動産・ビジネスサービス。数値が50を超えると、製造業が前月に比べて拡大していることを示し、50を下回ると縮小、50は変化がないことを示す。',
  'company_gross_operating_profits_qoq.description:ja:DEFAULT': '',
  'company_gross_operating_profits_qoq.description:ja:Australia': '',
  'cfib_business_barometer.description:ja:DEFAULT': '',
  'cfib_business_barometer.description:ja:Canada': '',
  'company_gross_profits_qoq.description:ja:DEFAULT': '',
  'company_gross_profits_qoq.description:ja:Australia':
    'オーストラリアでは企業利益とは、法人税および特別項目控除前の純営業損益を指し、企業が所有する資本財の売却から生じる資本損益および受取配当金を差し引いたものである。',
  'composite_economic_index.description:ja:DEFAULT': '',
  'composite_economic_index.description:ja:Israel':
    'イスラエルの総合経済状況指数は、実際の経済活動がどの方向にあるかをリアルタイムで検証する合成指標である。この指数は、鉱工業生産指数、貿易収入、サービス収入、消費財の輸入、製造用原材料の輸入、商品の輸出、サービスの輸出、民間部門の雇用数、求人率、建設着工数の10種類の要素に基づいて算出される。この指数は、2011年時点での基準値を100とする。',
  'constitution_memorial_day.description:ja:DEFAULT': '',
  'constitution_memorial_day.description:ja:Japan': '',
  'constitutional_referendum.description:ja:DEFAULT': '',
  'constitutional_referendum.description:ja:Thailand': '',
  'construction_work_done.description:ja:DEFAULT': '',
  'construction_work_done.description:ja:Australia': '',
  'consumer_confidence_final.description:ja:DEFAULT': '',
  'consumer_confidence_final.description:ja:Netherlands':
    'オランダでは消費者信頼感調査は、1,000世帯を対象に、電話で行われる。質問内容は、過去12カ月間の国内経済や回答者自身の財務状況に対する評価と、今後12カ月間の見通しについてである。この指数は、楽観的な回答者の割合と悲観的な回答者の割合の差として算出される。したがって指数はマイナス100（すべての回答者が自分の状況を悪く評価し、さらに悪くなると予想している）からプラス100（すべての参加者が現在の状況に満足し、さらに改善すると予想している）の間の値をとり、0は中立を表す。',
  'consumer_confidence_final.description:ja:Switzerland':
    'スイスでは毎年、1月、4月、7月、10月に消費者信頼感調査が実施されている。約1,000世帯を対象に、経済状況、その世帯の財務状況、インフレ、雇用の安定性などに関する主観的な評価について、経済担当国家事務局が質問する。一般的に失業率が低くGDP成長率が高ければ消費者信頼感は高い。消費者信頼感の平均値という指標は、消費者がどれくらい支出する可能性があるかを予測する際に有益となる。',
  'consumer_confidence_final.description:ja:Euro Area':
    'ユーロ圏では、消費者の経済に対する楽観的な見方を示す指標として、消費者景況感指数が用いられている。この調査は電話で行われ、ユーロ圏の23,000世帯を対象としている。調査対象となる世帯数はユーロ圏全体にわたり異なる。質問は現在の経済・金融状況、貯蓄計画、および消費者物価指数や経済全般、大型耐久財購入計画などに関する見通しを中心としたもので、この指数はマイナス100からプラス100までの間となり、マイナス100は極端に悲観的な見方を、0はニュートラルを、プラス100は極端に楽観的な見方を示す。',
  'consumer_confidence_flash.description:ja:DEFAULT': '',
  'consumer_confidence_flash.description:ja:Euro Area':
    'ユーロ圏では、消費者の経済に対する楽観的な見方を示す指標として、消費者景況感指数が用いられている。この調査は電話で行われ、ユーロ圏の23,000世帯を対象としている。調査対象となる世帯数はユーロ圏全体にわたり異なる。質問は現在の経済・金融状況、貯蓄計画、および消費者物価指数や経済全般、大型耐久財購入計画などに関する見通しを中心としたもので、この指数はマイナス100からプラス100までの間となり、マイナス100は極端に悲観的な見方を、0はニュートラルを、プラス100は極端に楽観的な見方を示す。',
  'consumer_confidence_index.description:ja:DEFAULT': '',
  'consumer_confidence_index.description:ja:Mexico':
    'メキシコの消費者信頼感指数（ICC）は、耐久消費財や非耐久消費財の購入、雇用、インフレ、貯蓄などに関して、人々が自分や家族、国全体の経済状況に対して抱いている現在の認識と将来の予想を測定したものである。この指数は、国内の32の主要都市に所在する2,336世帯のサンプルに基づいている。 ICCは2003年1月時点を100として基準にしており、100以上は楽観的、100は中立、100以下は悲観的を示す。',
  'consumer_inflation_expectation.description:ja:DEFAULT': '',
  'consumer_inflation_expectation.description:ja:Australia': '',
  'consumer_price_index.description:ja:DEFAULT': '',
  'consumer_price_index.description:ja:Australia':
    'オーストラリアでは消費者物価指数（CPI）は、消費者が様々な特定の商品とサービスに対して支払う価格の変化を測定する。',
  'consumer_price_index.description:ja:United States':
    '米国では消費者物価指数（CPI）は、消費者が様々な特定の商品とサービスに対して支払う価格の変化を測定する。',
  'cpi_real_earnings_mom.description:ja:DEFAULT': '',
  'cpi_real_earnings_mom.description:ja:United States': '',
  'consumer_price_index_eu_norm_mom.description:ja:DEFAULT': '',
  'consumer_price_index_eu_norm_mom.description:ja:Italy': '',
  'consumer_price_index_-_core_yoy.description:ja:DEFAULT': '',
  'consumer_price_index_-_core_yoy.description:ja:Euro Area': '',
  'boe_fpc_statement.description:ja:DEFAULT': '',
  'boe_fpc_statement.description:ja:United Kingdom':
    'イギリスの金融政策委員会（MPC）が基準利率を設定する。中央銀行であるイングランド銀行の公式金利はレポ金利である。このレポ金利は、イングランド銀行の取引相手（銀行、住宅金融組合、証券会社）に対する公開市場操作での利率となる。',
  'consumer_price_index_-_core_mom.description:ja:DEFAULT': '',
  'consumer_price_index_-_core_mom.description:ja:Canada': '',
  'consumer_price_index_ex_food_energy_mom.description:ja:DEFAULT': '',
  'consumer_price_index_ex_food_energy_mom.description:ja:United States': '',
  'boc_wilkins_speech.description:ja:DEFAULT': '',
  'boc_wilkins_speech.description:ja:Canada':
    'カナダでは、基準金利が中央銀行であるカナダ銀行（BoC）理事会によって定められる。公式金利は翌日物レートとなっている。1996年以来、銀行金利は短期金融市場の翌日物レートでの運用幅の上限に設定されている。1980年3月から1996年2月までは、この金利は3カ月割引国債の週間入札金利平均より25ベーシスポイント上に設定されていた。',
  'consumer_spending_mom.description:ja:DEFAULT': '',
  'consumer_spending_mom.description:ja:France': '',
  '2019_us_monetary_policy_forum.description:ja:DEFAULT': '',
  '2019_us_monetary_policy_forum.description:ja:United States': '',
  'consumer_spending_volume.description:ja:DEFAULT': '',
  'consumer_spending_volume.description:ja:Netherlands': '',
  'copom_meeting_minutes.description:ja:DEFAULT': '',
  'copom_meeting_minutes.description:ja:Brazil': '',
  'core_cpi_yoy.description:ja:DEFAULT': '',
  'core_cpi_yoy.description:ja:Canada': '',
  'core_consumer_price_index.description:ja:DEFAULT': '',
  'core_consumer_price_index.description:ja:United States':
    '米国ではコア消費者物価指数によって、価格変動の激しい項目を一部除いた財のバスケットに対して消費者が支払う価格の変動を測定する。',
  'core_durable_goods_orders.description:ja:DEFAULT': '',
  'core_durable_goods_orders.description:ja:United States': '',
  'core_durable_goods_orders_mom.description:ja:DEFAULT': '',
  'core_durable_goods_orders_mom.description:ja:United States': '',
  'cpif_mom.description:ja:DEFAULT': '',
  'cpif_mom.description:ja:Sweden':
    '2017年9月現在、スウェーデン中央銀行のリクスバンクはインフレターゲットの目標変数として、一定金利での消費者物価指数であるCPIFを使用している。同中央銀行は「金融政策の観点から見ると、CPI（消費者物価指数）の欠点は、政策金利の変更に直接影響を受けることだ。これらの調整は住宅ローン金利への影響を通じて、根本的なインフレ圧力とは関係なく、CPIに大きく直接的な影響を与える」としている。',
  'core_inflation_rate_mom.description:ja:DEFAULT': '',
  'core_inflation_rate_mom.description:ja:United Kingdom':
    '英国ではコア・インフレ率によって、価格変動の激しい項目を一部除いた財のバスケットに対して消費者が支払う価格の変動を測定する。',
  'core_inflation_rate_mom.description:ja:Canada':
    'カナダではコア・インフレ率によって、価格変動の激しい項目を一部除いた財のバスケットに対して消費者が支払う価格の変動を測定する。',
  'core_inflation_rate_mom.description:ja:United States':
    '米国ではコア消費者物価指数によって、価格変動の激しい項目を一部除いた財のバスケットに対して消費者が支払う価格の変動を測定する。',
  'core_inflation_rate_mom.description:ja:Mexico':
    'メキシコではコア・インフレ率によって、価格変動の激しい項目を一部除いた財のバスケットに対して消費者が支払う価格の変動を測定する。',
  'core_inflation_rate_mom.description:ja:Norway':
    'ノルウェーでは、中央銀行が金利を設定する際の指標として、税金とエネルギー価格を調整したコア消費者物価指数を使用している。中央銀行は、長期的に年間のインフレ率を約2％にすることを目標としている。',
  'core_inflation_rate_yoy.description:ja:DEFAULT': '',
  'core_inflation_rate_yoy.description:ja:Singapore':
    'シンガポールのコア・インフレ率は、政府の政策に大きく影響される自動車や宿泊施設の価格変動を除いた、特定商品のバスケットに対して消費者が支払う価格の変動を追跡する。',
  'core_inflation_rate_yoy.description:ja:Indonesia':
    'インドネシアではコア・インフレ率によって、価格変動の激しい項目を一部除いた財のバスケットに対して消費者が支払う価格の変動を測定する。',
  'core_inflation_rate_yoy.description:ja:Mexico':
    'メキシコではコア・インフレ率によって、価格変動の激しい項目を一部除いた財のバスケットに対して消費者が支払う価格の変動を測定する。',
  'core_inflation_rate_yoy.description:ja:Japan':
    '日本ではコア・インフレ率によって、生鮮食品を除いた財のバスケットに対して消費者が支払う価格の変動を測定する。',
  'core_inflation_rate_yoy.description:ja:Ireland':
    'アイルランドではコア・インフレ率によって、価格変動の激しい項目を一部除いた財のバスケットに対して消費者が支払う価格の変動を測定する。',
  'core_inflation_rate_yoy.description:ja:United States':
    '米国ではコア・インフレ率によって、価格変動の激しい食品と燃料費を除いた財のバスケットに対して消費者が支払う価格の変動を測定する。',
  'core_inflation_rate_yoy.description:ja:Canada':
    'カナダではコア・インフレ率によって、価格変動の激しい項目を一部除いた財のバスケットに対して消費者が支払う価格の変動を測定する。',
  'core_inflation_rate_yoy.description:ja:Philippines':
    'フィリピンではコア・インフレ率によって、価格変動の激しい項目を一部除いた財のバスケットに対して消費者が支払う価格の変動を測定する。',
  'core_inflation_rate_yoy.description:ja:Hungary':
    'ハンガリーではコア・インフレ率によって、価格変動の激しい項目を一部除いた財のバスケットに対して消費者が支払う価格の変動を測定する。',
  'core_inflation_rate_yoy.description:ja:Poland':
    'ポーランドではコア・インフレ率によって、価格変動の激しい項目を一部除いた財のバスケットに対して消費者が支払う価格の変動を測定する。',
  'core_inflation_rate_yoy.description:ja:South Africa':
    '南アフリカでは、コア消費者物価は、食品やノンアルコール飲料、燃料やエネルギーなどの変動の激しい品目を除いた標準的な商品のバスケットに対して消費者が支払う価格の幅広い上昇または下落を測定する。',
  'core_inflation_rate_yoy.description:ja:Thailand':
    'タイではコア・インフレ率によって、価格変動の激しい項目を一部除いた財のバスケットに対して消費者が支払う価格の変動を測定する。',
  'core_inflation_rate_yoy.description:ja:United Kingdom':
    '英国ではコア・インフレ率によって、価格変動の激しい項目を一部除いた財のバスケットに対して消費者が支払う価格の変動を測定する。',
  'core_inflation_rate_yoy.description:ja:Euro Area':
    'ユーロ圏のコア・インフレ率は、価格変動の激しいエネルギー、食品、アルコール、タバコを除いた総合消費者物価指数（HICP）の加重平均を用いて算出される。',
  'core_inflation_rate_yoy.description:ja:Norway':
    'ノルウェーでは、中央銀行が金利を設定する際の指標として、税金とエネルギー価格を調整したコアインフレ率を使用している。中央銀行は、長期的に年間のインフレ率を約2％にすることを目標としている。',
  'core_inflation_rate_yoy_flash.description:ja:DEFAULT': '',
  'core_inflation_rate_yoy_flash.description:ja:Euro Area':
    'ユーロ圏のコア・インフレ率は、価格変動の激しいエネルギー、食品、アルコール、タバコを除いた総合消費者物価指数（HICP）の加重平均を用いて算出される。',
  'boe_salmon_speech.description:ja:DEFAULT': '',
  'boe_salmon_speech.description:ja:United Kingdom': '',
  'core_inflation_yoy.description:ja:DEFAULT': '',
  'core_inflation_yoy.description:ja:Euro Area': '',
  'core_pce_mom.description:ja:DEFAULT': '',
  'core_pce_mom.description:ja:United States': '',
  'boc_lane_speech.description:ja:DEFAULT': '',
  'boc_lane_speech.description:ja:Canada':
    'カナダでは、基準金利が中央銀行であるカナダ銀行（BoC）理事会によって定められる。公式金利は翌日物レートとなっている。1996年以来、銀行金利は短期金融市場の翌日物レートでの運用幅の上限に設定されている。1980年3月から1996年2月までは、この金利は3カ月割引国債の週間入札金利平均より25ベーシスポイント上に設定されていた。',
  'core_pce_prices_qoq_2_est.description:ja:DEFAULT': '',
  'core_pce_prices_qoq_2_est.description:ja:United States': '',
  'core_pce_prices_qoq_2nd_est.description:ja:DEFAULT': '',
  'core_pce_prices_qoq_2nd_est.description:ja:United States': '',
  'core_pce_prices_qoq_final.description:ja:DEFAULT': '',
  'core_pce_prices_qoq_final.description:ja:United States': '',
  'core_pce_yoy.description:ja:DEFAULT': '',
  'core_pce_yoy.description:ja:United States': '',
  'core_pce_price_index_mom.description:ja:DEFAULT': '',
  'core_pce_price_index_mom.description:ja:United States':
    '米国のコア個人消費支出価格指数は、食品とエネルギーの価格を除いた、商品とサービスの国内購入に対して人々が支払った価格の指標である。コアPCEは、FRBが選好するインフレ率の指標であり、FRBは2％を目標としている。',
  'core_pce_price_index_yoy.description:ja:DEFAULT': '',
  'core_pce_price_index_yoy.description:ja:United States':
    '米国のコア個人消費支出価格指数は、食品とエネルギーの価格を除いた、商品とサービスの国内購入に対して人々が支払った価格の指標である。コアPCEは、FRBが選好するインフレ率の指標であり、FRBは2％を目標としている。',
  'core_pce_prices_mom.description:ja:DEFAULT': '',
  'core_pce_prices_mom.description:ja:United States': '',
  'core_ppi_mom.description:ja:DEFAULT': '',
  'core_ppi_mom.description:ja:United States':
    '米国の生産者物価指数（PPI）は、国内の生産者が生産物に対して受け取る価格の平均的な変化を測定するものであり、食品やエネルギーなどの変動の激しい品目が除かれている。',
  'boj_press_conference.description:ja:DEFAULT': '',
  'boj_press_conference.description:ja:Japan': '',
  'core_ppi_yoy.description:ja:DEFAULT': '',
  'core_ppi_yoy.description:ja:United States':
    '米国の生産者物価指数（PPI）は、国内の生産者が生産物に対して受け取る価格の平均的な変化を測定するものであり、食品やエネルギーなどの変動の激しい品目が除かれている。',
  'core_personal_cons_exp_qoq.description:ja:DEFAULT': '',
  'core_personal_cons_exp_qoq.description:ja:United States': '',
  '6th_european_financial_congress.description:ja:DEFAULT': '',
  '6th_european_financial_congress.description:ja:Euro Area': '',
  'core_personal_cons_exp_yoy.description:ja:DEFAULT': '',
  'core_personal_cons_exp_yoy.description:ja:United States': '',
  'core_personal_consumption_expenditure_-_price_index_mom.description:ja:DEFAULT': '',
  'core_personal_consumption_expenditure_-_price_index_mom.description:ja:United States': '',
  'core_personal_consumption_expenditure_yoy.description:ja:DEFAULT': '',
  'core_personal_consumption_expenditure_yoy.description:ja:United States': '',
  'core_personal_consumption_expenditures_qoq.description:ja:DEFAULT': '',
  'core_personal_consumption_expenditures_qoq.description:ja:United States': '',
  'core_rpi_mom.description:ja:DEFAULT': '',
  'core_rpi_mom.description:ja:United Kingdom': '',
  'core_rpi_yoy.description:ja:DEFAULT': '',
  'core_rpi_yoy.description:ja:United Kingdom': '',
  'bank_of_canada_core_inflation_yoy.description:ja:DEFAULT': '',
  'bank_of_canada_core_inflation_yoy.description:ja:Canada': '',
  'core_retail_sales_mom.description:ja:DEFAULT': '',
  'core_retail_sales_mom.description:ja:Canada': '',
  'core_retail_sales_mom.description:ja:United States': '',
  'core_retail_sales_yoy.description:ja:DEFAULT': '',
  'core_retail_sales_yoy.description:ja:United Kingdom': '',
  'corporate_profits.description:ja:DEFAULT': '',
  'corporate_profits.description:ja:Russia':
    'ロシアにおける「企業利益」とは、小規模企業、銀行、保険会社、予算機関を除く組織の、財務結果（利益から損失を引いたもの）である。企業利益は累積値である。',
  'corporate_profits_qoq_final.description:ja:DEFAULT': '',
  'corporate_profits_qoq_final.description:ja:United States':
    '企業収益は、米国企業が現在の生産によって得た合計利益を示している。企業の健全性を示す指標として、米国で最も注目されている経済指標の一つであり、景気動向の重要な指標となっている。',
  'corporate_profits_qoq_prel.description:ja:DEFAULT': '',
  'corporate_profits_qoq_prel.description:ja:United States':
    '企業収益は、米国企業が現在の生産によって得た合計利益を示している。企業の健全性を示す指標として、米国で最も注目されている経済指標の一つであり、景気動向の重要な指標となっている。',
  'corporate_sector_wages_growth.description:ja:DEFAULT': '',
  'corporate_sector_wages_growth.description:ja:Poland':
    'ポーランドの企業部門賃金は、企業部門の平均月間総賃金の前年比を測定したものである。',
  'corporate_sector_wages_yoy.description:ja:DEFAULT': '',
  'corporate_sector_wages_yoy.description:ja:Poland':
    'ポーランドの企業部門賃金は、企業部門の平均月間総賃金の前年比を測定したものである。',
  'corporate_service_price_yoy.description:ja:DEFAULT': '',
  'corporate_service_price_yoy.description:ja:Japan': '',
  'corporative_sector_wages_growth.description:ja:DEFAULT': '',
  'corporative_sector_wages_growth.description:ja:Poland':
    'ポーランドの企業部門賃金は、企業部門の平均月間総賃金の前年比を測定したものである。',
  'corpus_christi.description:ja:DEFAULT': '',
  'corpus_christi.description:ja:Colombia': '',
  'corpus_christi.description:ja:Austria': '',
  'corpus_christi.description:ja:Brazil': '',
  'corpus_christi.description:ja:Germany': '',
  'corpus_christi.description:ja:Poland': '',
  'corpus_christi.description:ja:Portugal': '',
  'corpus_christi_day.description:ja:DEFAULT': '',
  'corpus_christi_day.description:ja:Colombia': '',
  'corpus_christi_day.description:ja:Brazil': '',
  'credit_card_spending_yoy.description:ja:DEFAULT': '',
  'credit_card_spending_yoy.description:ja:New Zealand': '',
  'credit_conditions_survey.description:ja:DEFAULT': '',
  'credit_conditions_survey.description:ja:United Kingdom':
    'イギリスの金融政策委員会（MPC）が基準利率を設定する。中央銀行であるイングランド銀行の公式金利はレポ金利である。このレポ金利は、イングランド銀行の取引相手（銀行、住宅金融組合、証券会社）に対する公開市場操作での利率となる。',
  'currency_reserves.description:ja:DEFAULT': '',
  'currency_reserves.description:ja:Denmark': '',
  'current_account_prel.description:ja:DEFAULT': '',
  'current_account_prel.description:ja:Hong Kong':
    '経常収支とは、貿易収支（物品・サービスの輸出額から輸入額を差し引いたもの）、純要素所得（利子や配当金など）、純移転支出（対外援助など）の合計である。',
  'current_account_to_gdp.description:ja:DEFAULT': '',
  'current_account_to_gdp.description:ja:Mexico': '',
  'czech_statehood_day.description:ja:DEFAULT': '',
  'czech_statehood_day.description:ja:Czech Republic': '',
  'dilf_manufacturing_pmi.description:ja:DEFAULT': '',
  'dilf_manufacturing_pmi.description:ja:Denmark':
    'デンマークのPMIは受注、生産、労働力、納期、完成品在庫、原価、購買量、最終製品在庫の加重平均値を測定する。数値が50を超えると、回答企業の大部分がポジティブなセンチメントを持っていることを示し、50を下回るとネガティブな予想を示す。',
  'dnb_manufacturing_pmi.description:ja:DEFAULT': '',
  'dnb_manufacturing_pmi.description:ja:Norway':
    'ノルウェーのNIMA製造業購買担当者景気指数（PMI）は製造業300社の調査に基づき、製造業の業績を測定し、それぞれ加重された、新規受注（30%）、生産（25%）、雇用（20%）、サプライヤー納期（15%）、購買品在庫（10%）という5つの個別インデックスから構成され、納期インデックスは他のインデックスと比較可能になるように符号が反転される。50を超える指数は製造業セクターが前期に比べて拡大していることを、50を切る指数は縮小していることを、50は変化がないことを示す。',
  'dallas_fed_manufacturing_index.description:ja:DEFAULT': '',
  'dallas_fed_manufacturing_index.description:ja:United States':
    'ダラス連銀製造業景気指数は、テキサス州の製造業の業況を測定する。この指数は、約100人の企業幹部を対象としたアンケート調査に基づいており、生産、雇用、受注、価格などの変数を追跡する。指数が0を超えると、工場の活動が前月に比べて拡大していることを示し、0を下回ると縮小、0は変化がないことを示す。テキサス州は米国製造業の生産額の約9.5%を占めており、工場生産額ではカリフォルニア州に次いで第2位、製造品輸出額では第1位である。',
  'danmarks_nationalbank_press_conference.description:ja:DEFAULT': '',
  'danmarks_nationalbank_press_conference.description:ja:Denmark': '',
  'dasera.description:ja:DEFAULT': '',
  'dasera.description:ja:India': '',
  'day_after_new_year_s_day.description:ja:DEFAULT': '',
  'day_after_new_year_s_day.description:ja:New Zealand': '',
  'day_following_chung_yeung_festival.description:ja:DEFAULT': '',
  'day_following_chung_yeung_festival.description:ja:Hong Kong': '',
  'day_following_the_chinese_mid-autumn_festival.description:ja:DEFAULT': '',
  'day_following_the_chinese_mid-autumn_festival.description:ja:Hong Kong': '',
  'day_after_mid-autumn_festival.description:ja:DEFAULT': '',
  'day_after_mid-autumn_festival.description:ja:Hong Kong': '',
  'construction_orders_yoy.description:ja:DEFAULT': '',
  'construction_orders_yoy.description:ja:United Kingdom':
    '英国の建設業新規受注額は、主要な建設請負業者の新規受注額を、工事の種類別およびグレートブリテン島内地域別に測定している。2013年4月以降、データは建設業界調査会社Barbour ABIから直接提供されており、調査対象はイングランド、スコットランド、ウェールズのすべての地方自治体と、年1万社の建設請負業者である。',
  'construction_orders_yoy.description:ja:Japan':
    '日本の建設受注とは、主要建設請負業者50社が当月に新規に受注した建設工事の金額を指す。なお、受注額には、元請業者の受注額と自社使用のための建設額が含まれ、下請受注額は含まれない。。',
  'day_of_atonement.description:ja:DEFAULT': '',
  'day_of_atonement.description:ja:Israel': '',
  'day_of_reconciliation.description:ja:DEFAULT': '',
  'day_of_reconciliation.description:ja:South Africa': '',
  'day_of_valor.description:ja:DEFAULT': '',
  'day_of_valor.description:ja:Philippines': '',
  'article_50_brexit_process_starts.description:ja:DEFAULT': '',
  'article_50_brexit_process_starts.description:ja:United Kingdom': '',
  'day_of_the_virgin_of_guadalupe.description:ja:DEFAULT': '',
  'day_of_the_virgin_of_guadalupe.description:ja:Mexico': '',
  'deadline_for_greece_to_enact_reforms.description:ja:DEFAULT': '',
  'deadline_for_greece_to_enact_reforms.description:ja:Euro Area': '',
  'deadline_on_detailed_proposal_on_economic_reforms_from_greece.description:ja:DEFAULT': '',
  'deadline_on_detailed_proposal_on_economic_reforms_from_greece.description:ja:Euro Area': '',
  'debt_repayment_of_eur_3_5b_due_to_ecb.description:ja:DEFAULT': '',
  'debt_repayment_of_eur_3_5b_due_to_ecb.description:ja:Greece': '',
  'business_investment_yoy_prel.description:ja:DEFAULT': '',
  'business_investment_yoy_prel.description:ja:United Kingdom':
    '英国では企業投資とは、民間企業および公営企業による純投資額を指す。これには輸送、情報通信技術（ICT）機器、その他の機械装置、酪・農業生物資産、知的財産製品（IPP：ソフトウェア、研究開発、芸術的原作品、鉱物探査への投資を含む）、建物などの建造物への投資が含まれる。中央政府や地方政府による投資、住居への投資、非生産資産（土地など）の譲渡に伴う費用は含まれていない。。',
  'deepavali.description:ja:DEFAULT': '',
  'deepavali.description:ja:Singapore': '',
  'defenders_of_the_fatherland_day.description:ja:DEFAULT': '',
  'defenders_of_the_fatherland_day.description:ja:Russia': '',
  'defense_of_the_fatherland_day.description:ja:DEFAULT': '',
  'defense_of_the_fatherland_day.description:ja:Russia': '',
  'boe_moulder_speech.description:ja:DEFAULT': '',
  'boe_moulder_speech.description:ja:United Kingdom': '',
  'deposit_facility_rate.description:ja:DEFAULT': '',
  'deposit_facility_rate.description:ja:Euro Area':
    '中央銀行の預金制度は常設の制度として、夜間の流動性を吸収し、金融政策の一般的なスタンスを示すことを目的としており、通常は翌日物市場金利の下限となる。このファシリティは各国中央銀行へのオーバーナイトの預金として使用される。',
  'deposit_facility_rate.description:ja:Indonesia':
    '預金金利とは、商業銀行が個人または法人に支払う預金の平均金利である。',
  'deposit_facilty_rate.description:ja:DEFAULT': '',
  'deposit_facilty_rate.description:ja:Euro Area':
    '中央銀行の預金制度は常設の制度として、夜間の流動性を吸収し、金融政策の一般的なスタンスを示すことを目的としており、通常は翌日物市場金利の下限となる。このファシリティは各国中央銀行へのオーバーナイトの預金として使用される。',
  'diwali.description:ja:DEFAULT': '',
  'diwali.description:ja:Singapore': '',
  'diwali_balipratipada.description:ja:DEFAULT': '',
  'diwali_balipratipada.description:ja:India': '',
  'diwali-laxmi_pujan.description:ja:DEFAULT': '',
  'diwali-laxmi_pujan.description:ja:India': '',
  'domestic_corporate_goods_price_index_yoy.description:ja:DEFAULT': '',
  'domestic_corporate_goods_price_index_yoy.description:ja:Japan': '',
  'domestic_corporate_goods_price_index_mom.description:ja:DEFAULT': '',
  'domestic_corporate_goods_price_index_mom.description:ja:Japan': '',
  'dormition_of_the_holy_virgin.description:ja:DEFAULT': '',
  'dormition_of_the_holy_virgin.description:ja:Greece': '',
  'boe_financial_stability_report.description:ja:DEFAULT': '',
  'boe_financial_stability_report.description:ja:United Kingdom':
    'イギリスの金融政策委員会（MPC）が基準利率を設定する。中央銀行であるイングランド銀行の公式金利はレポ金利である。このレポ金利は、イングランド銀行の取引相手（銀行、住宅金融組合、証券会社）に対する公開市場操作での利率となる。',
  'dr_baba_saheb_ambedkar_jayanti.description:ja:DEFAULT': '',
  'dr_baba_saheb_ambedkar_jayanti.description:ja:India': '',
  'christmas_day_substitute_day.description:ja:DEFAULT': '',
  'christmas_day_substitute_day.description:ja:United Kingdom': '',
  'christmas_day_substitute_day.description:ja:United States': '',
  'dragon_boat_festival.description:ja:DEFAULT': '',
  'dragon_boat_festival.description:ja:Taiwan': '',
  'dragon_boat_festival.description:ja:Hong Kong': '',
  'dragon_boat_festival.description:ja:China': '',
  'duanwu_festival.description:ja:DEFAULT': '',
  'duanwu_festival.description:ja:Hong Kong': '',
  'duanwu_festival.description:ja:China': '',
  'duanwu_festival.description:ja:Taiwan': '',
  'durable_goods_orders_ex_defense_mom.description:ja:DEFAULT': '',
  'durable_goods_orders_ex_defense_mom.description:ja:United States':
    '防衛費を除く耐久財受注とは、防衛費を除いた、耐久財の納入を目的とする、メーカーの新規受注を指す。',
  'durable_goods_orders_mom.description:ja:DEFAULT': '',
  'durable_goods_orders_mom.description:ja:United States':
    '耐久財受注とは、少なくとも3年間は使用可能な耐久財の納入を目的とする、メーカーの新規受注を指す。',
  'durable_goods_orders_ex_transp_mom.description:ja:DEFAULT': '',
  'durable_goods_orders_ex_transp_mom.description:ja:United States':
    '輸送を除く耐久財受注とは、輸送機器を除いた、耐久財の納入を目的とする、メーカの新規受注を指す。',
  'dussehra.description:ja:DEFAULT': '',
  'dussehra.description:ja:India': '',
  '5-year_note_auction.description:ja:DEFAULT': '',
  '5-year_note_auction.description:ja:United States': '',
  'ebrd_annual_meeting.description:ja:DEFAULT': '',
  'ebrd_annual_meeting.description:ja:Euro Area': '',
  'ec-ecb_joint_conference.description:ja:DEFAULT': '',
  'ec-ecb_joint_conference.description:ja:Euro Area': '',
  'ecb_2015_annual_report.description:ja:DEFAULT': '',
  'ecb_2015_annual_report.description:ja:Euro Area': '',
  'ecb_angeloni_speech.description:ja:DEFAULT': '',
  'ecb_angeloni_speech.description:ja:Euro Area': '',
  'ecb_annual_report.description:ja:DEFAULT': '',
  'ecb_annual_report.description:ja:Euro Area': '',
  'ecb_bank_lending_survey.description:ja:DEFAULT': '',
  'ecb_bank_lending_survey.description:ja:Euro Area':
    'ユーロ圏の基準金利は欧州中央銀行（ECB）の理事会によって決定される。ECBの金融政策の主な目的は、物価の安定を維持することであり、それはインフレ率を中期的に2％未満でかつそれに近い水準に抑えることである。低インフレ・低金利が長期化した場合、ECBは資産購入プログラムなどの非標準的な金融政策を採用することもある。公式金利は、主要リファイナンスオペ金利である。。',
  'ecb_coeur_speech.description:ja:DEFAULT': '',
  'ecb_coeur_speech.description:ja:Euro Area': '',
  'ecb_dickson_speech.description:ja:DEFAULT': '',
  'ecb_dickson_speech.description:ja:Euro Area': '',
  'anz_job_advertisment_mom.description:ja:DEFAULT': '',
  'anz_job_advertisment_mom.description:ja:Australia': '',
  'ecb_diemer_speech.description:ja:DEFAULT': '',
  'ecb_diemer_speech.description:ja:Euro Area': '',
  'ecb_draghi_speech.description:ja:DEFAULT': '',
  'ecb_draghi_speech.description:ja:Euro Area': '',
  'ecb_enria_speech.description:ja:DEFAULT': '',
  'ecb_enria_speech.description:ja:Euro Area':
    'ユーロ圏の基準金利は欧州中央銀行（ECB）の理事会によって決定される。ECBの金融政策の主な目的は、物価の安定を維持することであり、それはインフレ率を中期的に2％未満でかつそれに近い水準に抑えることである。低インフレ・低金利が長期化した場合、ECBは資産購入プログラムなどの非標準的な金融政策を採用することもある。公式金利は、主要リファイナンスオペ金利である。。',
  'ecb_financial_stability_review.description:ja:DEFAULT': '',
  'ecb_financial_stability_review.description:ja:Euro Area':
    'ユーロ圏の基準金利は欧州中央銀行（ECB）の理事会によって決定される。ECBの金融政策の主な目的は、物価の安定を維持することであり、それはインフレ率を中期的に2％未満でかつそれに近い水準に抑えることである。低インフレ・低金利が長期化した場合、ECBは資産購入プログラムなどの非標準的な金融政策を採用することもある。公式金利は、主要リファイナンスオペ金利である。。',
  'ecb_financial_statement_publication.description:ja:DEFAULT': '',
  'ecb_financial_statement_publication.description:ja:Euro Area': '',
  '7-year_btp_auction.description:ja:DEFAULT': '',
  '7-year_btp_auction.description:ja:Italy': '',
  'ecb_financial_statements.description:ja:DEFAULT': '',
  'ecb_financial_statements.description:ja:Euro Area': '',
  'ecb_forum_on_central_banking.description:ja:DEFAULT': '',
  'ecb_forum_on_central_banking.description:ja:Euro Area': '',
  'ecb_forum_on_central_banking.description:ja:Portugal': '',
  'ecb_galhau_speech.description:ja:DEFAULT': '',
  'ecb_galhau_speech.description:ja:Euro Area': '',
  'ecb_governing_council_meeting.description:ja:DEFAULT': '',
  'ecb_governing_council_meeting.description:ja:Euro Area': '',
  '67-day_bill_auction.description:ja:DEFAULT': '',
  '67-day_bill_auction.description:ja:United States': '',
  'ecb_guindos_speech.description:ja:DEFAULT': '',
  'ecb_guindos_speech.description:ja:Euro Area':
    'ユーロ圏の基準金利は欧州中央銀行（ECB）の理事会によって決定される。ECBの金融政策の主な目的は、物価の安定を維持することであり、それはインフレ率を中期的に2％未満でかつそれに近い水準に抑えることである。低インフレ・低金利が長期化した場合、ECBは資産購入プログラムなどの非標準的な金融政策を採用することもある。公式金利は、主要リファイナンスオペ金利である。。',
  'ecb_interest_rate_decision.description:ja:DEFAULT': '',
  'ecb_interest_rate_decision.description:ja:Euro Area':
    'ユーロ圏の基準金利は欧州中央銀行（ECB）の理事会によって決定される。ECBの金融政策の主な目的は、物価の安定を維持することであり、それはインフレ率を中期的に2％未満でかつそれに近い水準に抑えることである。低インフレ・低金利が長期化した場合、ECBは資産購入プログラムなどの非標準的な金融政策を採用することもある。公式金利は、主要リファイナンスオペ金利である。。',
  'ecb_lagarde_speech.description:ja:DEFAULT': '',
  'ecb_lagarde_speech.description:ja:Euro Area': '',
  'ecb_lane_speech.description:ja:DEFAULT': '',
  'ecb_lane_speech.description:ja:Euro Area':
    'ユーロ圏の基準金利は欧州中央銀行（ECB）の理事会によって決定される。ECBの金融政策の主な目的は、物価の安定を維持することであり、それはインフレ率を中期的に2％未満でかつそれに近い水準に抑えることである。低インフレ・低金利が長期化した場合、ECBは資産購入プログラムなどの非標準的な金融政策を採用することもある。公式金利は、主要リファイナンスオペ金利である。。',
  'ecb_liikanen_speech.description:ja:DEFAULT': '',
  'ecb_liikanen_speech.description:ja:Euro Area': '',
  'ecb_mccaul_speech.description:ja:DEFAULT': '',
  'ecb_mccaul_speech.description:ja:Euro Area':
    'ユーロ圏の基準金利は欧州中央銀行（ECB）の理事会によって決定される。ECBの金融政策の主な目的は、物価の安定を維持することであり、それはインフレ率を中期的に2％未満でかつそれに近い水準に抑えることである。低インフレ・低金利が長期化した場合、ECBは資産購入プログラムなどの非標準的な金融政策を採用することもある。公式金利は、主要リファイナンスオペ金利である。。',
  'ecb_meeting_minutes.description:ja:DEFAULT': '',
  'ecb_meeting_minutes.description:ja:Euro Area': '',
  'ecb_mersch_speech.description:ja:DEFAULT': '',
  'ecb_mersch_speech.description:ja:Euro Area':
    'ユーロ圏の基準金利は欧州中央銀行（ECB）の理事会によって決定される。ECBの金融政策の主な目的は、物価の安定を維持することであり、それはインフレ率を中期的に2％未満でかつそれに近い水準に抑えることである。低インフレ・低金利が長期化した場合、ECBは資産購入プログラムなどの非標準的な金融政策を採用することもある。公式金利は、主要リファイナンスオペ金利である。。',
  'ecb_monetary_policy_statement_and_press_conference.description:ja:DEFAULT': '',
  'ecb_monetary_policy_statement_and_press_conference.description:ja:Euro Area': '',
  'deadline_for_funding_for_new_fiscal_year.description:ja:DEFAULT': '',
  'deadline_for_funding_for_new_fiscal_year.description:ja:United States': '',
  'ecb_non-monetary_policy_meeting.description:ja:DEFAULT': '',
  'ecb_non-monetary_policy_meeting.description:ja:Euro Area':
    'ユーロ圏の基準金利は欧州中央銀行（ECB）の理事会によって決定される。ECBの金融政策の主な目的は、物価の安定を維持することであり、それはインフレ率を中期的に2％未満でかつそれに近い水準に抑えることである。低インフレ・低金利が長期化した場合、ECBは資産購入プログラムなどの非標準的な金融政策を採用することもある。公式金利は、主要リファイナンスオペ金利である。。',
  'ecb_nowotny_speech.description:ja:DEFAULT': '',
  'ecb_nowotny_speech.description:ja:Euro Area': '',
  'credit_indicator.description:ja:DEFAULT': '',
  'credit_indicator.description:ja:Norway': '',
  'ecb_panetta_speech.description:ja:DEFAULT': '',
  'ecb_panetta_speech.description:ja:Euro Area':
    'ユーロ圏の基準金利は欧州中央銀行（ECB）の理事会によって決定される。ECBの金融政策の主な目的は、物価の安定を維持することであり、それはインフレ率を中期的に2％未満でかつそれに近い水準に抑えることである。低インフレ・低金利が長期化した場合、ECBは資産購入プログラムなどの非標準的な金融政策を採用することもある。公式金利は、主要リファイナンスオペ金利である。。',
  'ecb_president_draghi_speech.description:ja:DEFAULT': '',
  'ecb_president_draghi_speech.description:ja:Euro Area': '',
  'ecb_president_draghi_s_speech.description:ja:DEFAULT': '',
  'ecb_president_draghi_s_speech.description:ja:Euro Area': '',
  'ecb_press_conference.description:ja:DEFAULT': '',
  'ecb_press_conference.description:ja:Euro Area':
    'ユーロ圏の基準金利は欧州中央銀行（ECB）の理事会によって決定される。ECBの金融政策の主な目的は、物価の安定を維持することであり、それはインフレ率を中期的に2％未満でかつそれに近い水準に抑えることである。低インフレ・低金利が長期化した場合、ECBは資産購入プログラムなどの非標準的な金融政策を採用することもある。公式金利は、主要リファイナンスオペ金利である。。',
  'coronation_day.description:ja:DEFAULT': '',
  'coronation_day.description:ja:Thailand': '',
  'ecb_quantitative_easing.description:ja:DEFAULT': '',
  'ecb_quantitative_easing.description:ja:Euro Area':
    'ユーロ圏の基準金利は欧州中央銀行（ECB）の理事会によって決定される。ECBの金融政策の主な目的は、物価の安定を維持することであり、それはインフレ率を中期的に2％未満でかつそれに近い水準に抑えることである。低インフレ・低金利が長期化した場合、ECBは資産購入プログラムなどの非標準的な金融政策を採用することもある。公式金利は、主要リファイナンスオペ金利である。。',
  'cbi_gov_lane_speech.description:ja:DEFAULT': '',
  'cbi_gov_lane_speech.description:ja:Ireland': '',
  'ecb_ssm_annual_report.description:ja:DEFAULT': '',
  'ecb_ssm_annual_report.description:ja:Euro Area': '',
  'ecb_schnabel_speech.description:ja:DEFAULT': '',
  'ecb_schnabel_speech.description:ja:Euro Area':
    'ユーロ圏の基準金利は欧州中央銀行（ECB）の理事会によって決定される。ECBの金融政策の主な目的は、物価の安定を維持することであり、それはインフレ率を中期的に2％未満でかつそれに近い水準に抑えることである。低インフレ・低金利が長期化した場合、ECBは資産購入プログラムなどの非標準的な金融政策を採用することもある。公式金利は、主要リファイナンスオペ金利である。。',
  'ecb_weidmann_speech.description:ja:DEFAULT': '',
  'ecb_weidmann_speech.description:ja:Euro Area': '',
  'bank_of_canada_business_outlook_survey.description:ja:DEFAULT': '',
  'bank_of_canada_business_outlook_survey.description:ja:Canada': '',
  'edsa_revolution_anniversary.description:ja:DEFAULT': '',
  'edsa_revolution_anniversary.description:ja:Philippines': '',
  'eia_crude_oil_imports.description:ja:DEFAULT': '',
  'eia_crude_oil_imports.description:ja:United States': '',
  'eia_crude_oil_imports_change.description:ja:DEFAULT': '',
  'eia_crude_oil_imports_change.description:ja:United States': '',
  'eia_crude_oil_stocks_change.description:ja:DEFAULT': '',
  'eia_crude_oil_stocks_change.description:ja:United States':
    '原油在庫は、原油の供給状況の週次変化を指す。',
  'eia_cushing_crude_oil_stocks_change.description:ja:DEFAULT': '',
  'eia_cushing_crude_oil_stocks_change.description:ja:United States':
    'オクラホマ州クッシングで過去1週間に貯蔵された原油のバレル量の変化。',
  'eia_distillate_fuel_production_change.description:ja:DEFAULT': '',
  'eia_distillate_fuel_production_change.description:ja:United States': '',
  'eia_distillate_stocks_change.description:ja:DEFAULT': '',
  'eia_distillate_stocks_change.description:ja:United States': '',
  'eia_gasoline_production.description:ja:DEFAULT': '',
  'eia_gasoline_production.description:ja:United States': '',
  'eia_gasoline_production_change.description:ja:DEFAULT': '',
  'eia_gasoline_production_change.description:ja:United States': '',
  'eia_gasoline_stocks_change.description:ja:DEFAULT': '',
  'eia_gasoline_stocks_change.description:ja:United States':
    'ガソリン在庫は、ガソリン供給状況の週次変化を指す。',
  'eia_natural_gas_stocks_change.description:ja:DEFAULT': '',
  'eia_natural_gas_stocks_change.description:ja:United States':
    '天然ガス在庫は、天然ガス供給状況の週次変化を指す。',
  'esri_consumer_sentiment_index.description:ja:DEFAULT': '',
  'esri_consumer_sentiment_index.description:ja:Ireland':
    'アイルランドの消費者態度指数調査は国内全地域で最低1,100世帯を対象にしている。アンケートによって回答者の過去1年間の経済全般に対する認識と今後1年間の予想、失業率とインフレ率の最近の傾向に対する認識、家計の状況の最近の傾向と今後の変化の可能性、貯蓄や大型購入の意向などについて評価している。この消費者センチメント指数は、「良い」と答えた回答の割合から「悪い」とした回答の割合を引いたものに100を加えて算出される。この指標は0から200の間で変化し、0は極めて信頼感が低く、100は中立、200は極めて信頼感が強いことを示す。',
  'eu_barnier_speech.description:ja:DEFAULT': '',
  'eu_barnier_speech.description:ja:European Union': '',
  'eu_commission_decision_on_budget_drafts.description:ja:DEFAULT': '',
  'eu_commission_decision_on_budget_drafts.description:ja:Euro Area': '',
  'eu_donald_tusk_speech.description:ja:DEFAULT': '',
  'eu_donald_tusk_speech.description:ja:European Union': '',
  'eu_juncker_speech.description:ja:DEFAULT': '',
  'eu_juncker_speech.description:ja:Euro Area': '',
  'eu_meeting_on_common_recovery_plan.description:ja:DEFAULT': '',
  'eu_meeting_on_common_recovery_plan.description:ja:European Union': '',
  'eu_membership_referendum_-_final_results.description:ja:DEFAULT': '',
  'eu_membership_referendum_-_final_results.description:ja:United Kingdom': '',
  'eu-canada_summit.description:ja:DEFAULT': '',
  'eu-canada_summit.description:ja:European Union': '',
  'nbh_nagy_speech.description:ja:DEFAULT': '',
  'nbh_nagy_speech.description:ja:Hungary': '',
  'nbs_manufacturing_pmi.description:ja:DEFAULT': '',
  'nbs_manufacturing_pmi.description:ja:China':
    '中国のNBS製造業購買担当者景気指数（PMI）は比較的大規模な国有企業を対象とした調査から製造業の業況を測定しており、それぞれ以下の加重された、新規受注（30%）、生産（25%）、雇用（20%）、サプライヤー納期（15%）、購買品在庫（10%）という5つの個別インデックスから構成され、納期インデックスは他のインデックスと比較可能になるように符号が反転される。50を超える指数は製造業セクターが前期に比べて拡大していることを、50を切る指数は縮小していることを、50は変化がないことを示す。。',
  'vernal_equinox_day.description:ja:DEFAULT': '',
  'vernal_equinox_day.description:ja:Japan': '',
  'boj_monetary_policy_meeting_minutes.description:ja:DEFAULT': '',
  'boj_monetary_policy_meeting_minutes.description:ja:Japan':
    '日本では、金利は政策委員会による金融政策決定会合で決められる。日銀の公式金利は、基準割引率である。金融政策決定会合では、次の会合までの間の金融市場操作におけるガイドラインが決められ、このガイドラインは無担保コール翌日物金利の目標として定められる。',
  'm4_money_supply_mom.description:ja:DEFAULT': '',
  'm4_money_supply_mom.description:ja:United Kingdom': '',
  '10-year_treasury_gilt_auction.description:ja:DEFAULT': '',
  '10-year_treasury_gilt_auction.description:ja:United Kingdom':
    '一般的に国債は国家政府により発行され、その国の通貨により値付けされる。国から発行された債券で外貨建てのものは通常ソブリン債と呼ばれる。資金をその国の政府に融資する投資家が必要とする利回りは、予測インフレ率および元本の償還可能性を考慮に含めたものとなる。',
  'oecd_gurria_speech_on_uk_referendum.description:ja:DEFAULT': '',
  'oecd_gurria_speech_on_uk_referendum.description:ja:United Kingdom': '',
  'boe_fpc_minutes.description:ja:DEFAULT': '',
  'boe_fpc_minutes.description:ja:United Kingdom': '',
  'nevi_manufacturing_pmi.description:ja:DEFAULT': '',
  'nevi_manufacturing_pmi.description:ja:Netherlands':
    'NEVIオランダ製造業購買担当者景気指数（PMI）は製造業400社の調査に基づく製造業の業績を測定する。それぞれ加重された、新規受注（30%）、生産（25%）、雇用（20%）、サプライヤー納期（15%）、購買品在庫（10%）という5つの個別インデックスから構成され、納期インデックスは他のインデックスと比較ができるようにプラスマイナスが反転される。50を超える指数は製造業セクターが前期に比べて拡大していることを、50を切る指数は縮小していることを、50は変化がないことを示す。',
  'pmi_manufacturing.description:ja:DEFAULT': '',
  'pmi_manufacturing.description:ja:Austria': '',
  'boe_proudman_speech.description:ja:DEFAULT': '',
  'boe_proudman_speech.description:ja:United Kingdom': '',
  'federal_election.description:ja:DEFAULT': '',
  'federal_election.description:ja:Canada': '',
  'federal_election.description:ja:Germany': '',
  'federal_election.description:ja:Australia': '',
  'vice_presidential_debate.description:ja:DEFAULT': '',
  'vice_presidential_debate.description:ja:United States': '',
  'liberation_day.description:ja:DEFAULT': '',
  'liberation_day.description:ja:South Korea': '',
  'liberation_day.description:ja:Netherlands': '',
  'liberation_day.description:ja:Italy': '',
  'eu_meeting_on_iran.description:ja:DEFAULT': '',
  'eu_meeting_on_iran.description:ja:Euro Area': '',
  'nima_manufacturing_pmi.description:ja:DEFAULT': '',
  'nima_manufacturing_pmi.description:ja:Norway':
    'ノルウェーのNIMA製造業購買担当者景気指数（PMI）は製造業300社の調査に基づき、製造業の業績を測定し、それぞれ加重された、新規受注（30%）、生産（25%）、雇用（20%）、サプライヤー納期（15%）、購買品在庫（10%）という5つの個別インデックスから構成され、納期インデックスは他のインデックスと比較可能になるように符号が反転される。50を超える指数は製造業セクターが前期に比べて拡大していることを、50を切る指数は縮小していることを、50は変化がないことを示す。',
  'tertiary_industry_index_mom.description:ja:DEFAULT': '',
  'tertiary_industry_index_mom.description:ja:Japan':
    '第3次産業の活動指数は、第三次産業の全業種を含めた産業活動の全体像を把握するのに役立つ。この総合指数は、各業種の活動を表す指数を、各業種の相対的な重要度の尺度（付加価値）で加重して統合した指標である。第3次産業活動の15％を占める卸売業が最も重要であり、次いで医療・健康・福祉、生活・娯楽関連サービス（各12％）、情報・通信（11％）、小売業、輸送・郵便業（各10％）、金融・保険（9％）、不動産（8％）、ビジネス関連サービス（7％）、電気・ガス・熱供給・水道業、物品賃貸・リース業（各3％）となっている。',
  'npc_committee_meeting.description:ja:DEFAULT': '',
  'npc_committee_meeting.description:ja:China': '',
  'ny_fed_treasury_purchases_0_to_2_25_yrs.description:ja:DEFAULT': '',
  'ny_fed_treasury_purchases_0_to_2_25_yrs.description:ja:United States': '',
  'presidential_debate.description:ja:DEFAULT': '',
  'presidential_debate.description:ja:United States': '',
  'the_constitutional_war_of_1932.description:ja:DEFAULT': '',
  'the_constitutional_war_of_1932.description:ja:Brazil': '',
  'markit_cips_composite_pmi_flash.description:ja:DEFAULT': '',
  'markit_cips_composite_pmi_flash.description:ja:United Kingdom':
    '英国総合PMIは、製造業生産指数とサービス業活動指数の加重平均値である。',
  'ny_fed_treasury_purchases_22_5_to_30_yrs.description:ja:DEFAULT': '',
  'ny_fed_treasury_purchases_22_5_to_30_yrs.description:ja:United States': '',
  'ny_fed_treasury_purchases_tips_1_to_7_5_yrs.description:ja:DEFAULT': '',
  'ny_fed_treasury_purchases_tips_1_to_7_5_yrs.description:ja:United States': '',
  'nzier_business_confidence.description:ja:DEFAULT': '',
  'nzier_business_confidence.description:ja:New Zealand': '',
  'ubs_consumption_indicator.description:ja:DEFAULT': '',
  'ubs_consumption_indicator.description:ja:Switzerland': '',
  'nzier_qsbo_capacity_utilization.description:ja:DEFAULT': '',
  'nzier_qsbo_capacity_utilization.description:ja:New Zealand':
    'ニュージーランドでは、設備稼働率は生産能力と実際の稼働状況との差を示す。労働力と資本が最も良く活用され、実際の生産量が生産能力に近い時に、稼働率は高くなる。',
  'ny_fed_treasury_purchases_tips_7_5_to_30_yrs.description:ja:DEFAULT': '',
  'ny_fed_treasury_purchases_tips_7_5_to_30_yrs.description:ja:United States': '',
  'national_cpi_ex-fresh_food_yoy.description:ja:DEFAULT': '',
  'national_cpi_ex-fresh_food_yoy.description:ja:Japan': '',
  'national_day_of_the_people_republic_of_china.description:ja:DEFAULT': '',
  'national_day_of_the_people_republic_of_china.description:ja:Hong Kong': '',
  'ifo_current_conditions_prel.description:ja:DEFAULT': '',
  'ifo_current_conditions_prel.description:ja:Germany': '',
  '3-year_btp_auction.description:ja:DEFAULT': '',
  '3-year_btp_auction.description:ja:Italy': '',
  'gdp_growth_rate_yoy_-_p.description:ja:DEFAULT': '',
  'gdp_growth_rate_yoy_-_p.description:ja:Greece':
    'ギリシャでは家計消費がGDPの主な構成要素であり、その総支出の72％を占めており、次いで政府支出（20％）、総固定資本形成（12％）となっている。財・サービスの輸出はGDPの33%、輸入は35%であるため、GDP合計に2%のマイナス寄与となっている。',
  'gdp_growth_rate_yoy_-_p.description:ja:Hungary':
    'ハンガリーでは、電子機器の製造と研究などがイノベーションと経済成長の主な原動力である。また、モバイル技術、情報セキュリティ、および関連するハードウェア研究の主要な中心地としても成長している。支出面では、家計消費がGDPの主な構成要素として全体の50%を占め、総固定資本形成（22％）、政府支出（20％）と続く。財・サービスの輸出はGDPの89%を占め、輸入は82%であるため、GDP全体に7%寄与している。',
  'national_founding_day.description:ja:DEFAULT': '',
  'national_founding_day.description:ja:Japan': '',
  'private_consumption_yoy.description:ja:DEFAULT': '',
  'private_consumption_yoy.description:ja:Portugal':
    'ポルトガルでは、民間消費の前年比とは、ポルトガルの個人消費に関する8つの主要指標（実質個人消費、実質小売業売上高指数、小型乗用車販売台数、小売業販売量（業界調査）、ポルトガル在住者の観光宿泊日数、国内市場における消費財の実質工業売上高指数、家計の財務状況、一般経済状況（消費者調査））を含む一致指数の年間変化を指す。',
  'national_heroes_day.description:ja:DEFAULT': '',
  'national_heroes_day.description:ja:Philippines': '',
  'pm_johnson_statement_to_parliament.description:ja:DEFAULT': '',
  'pm_johnson_statement_to_parliament.description:ja:United Kingdom': '',
  'national_holiday.description:ja:DEFAULT': '',
  'national_holiday.description:ja:Greece': '',
  'national_holiday.description:ja:Japan': '',
  'boc_leduc_speech.description:ja:DEFAULT': '',
  'boc_leduc_speech.description:ja:Canada': '',
  'national_unity_day_holiday.description:ja:DEFAULT': '',
  'national_unity_day_holiday.description:ja:Russia': '',
  'nationwide_housing_prices_mom.description:ja:DEFAULT': '',
  'nationwide_housing_prices_mom.description:ja:United Kingdom':
    '全国住宅価格指数は、英国の住宅価格の傾向を示す指標である。この指数は、住宅ローンを利用した持ち家購入の取引に基づいて算出される。賃貸のための購入や現金での購入は含まれない。',
  'pce_prices_qoq_adv.description:ja:DEFAULT': '',
  'pce_prices_qoq_adv.description:ja:United States': '',
  'treasury_committee_hearing_inflation_report.description:ja:DEFAULT': '',
  'treasury_committee_hearing_inflation_report.description:ja:United Kingdom': '',
  'gdp_final_consumption_qoq.description:ja:DEFAULT': '',
  'gdp_final_consumption_qoq.description:ja:Australia':
    '家計最終消費支出とは、個人による財・サービスへの支出と、対家計民間非営利団体による支出の純額である。これには自動車その他の耐久財に対する個人支出、持ち家の帰属家賃、自家菜園での生産（農家で生産され消費される食品を含む）の価値、および現物支給の賃金・給与が含まれる。',
  'boc_gov_carney_speaks.description:ja:DEFAULT': '',
  'boc_gov_carney_speaks.description:ja:Canada': '',
  'day_of_prayer.description:ja:DEFAULT': '',
  'day_of_prayer.description:ja:Denmark': '',
  'south_african_manufacturing_pmi.description:ja:DEFAULT': '',
  'south_african_manufacturing_pmi.description:ja:South Africa': '',
  'net_long-term_tic_flows.description:ja:DEFAULT': '',
  'net_long-term_tic_flows.description:ja:United States':
    '正味長期TICフローは財務省証券、政府機関証券、社債、株式の米国への流入と流出を追跡している。',
  'new_car_registrations_mom.description:ja:DEFAULT': '',
  'new_car_registrations_mom.description:ja:Brazil':
    'ブラジルの新車登録台数は、乗用車、トラック、バスを含む新車登録台数である。',
  'personal_income_mom.description:ja:DEFAULT': '',
  'personal_income_mom.description:ja:United States':
    '個人所得とは、現在の生産に使用される労働力、土地、および資本の提供の見返りとして個人が受け取る所得に、現在の移転収入から公的社会保険への拠出金を差し引いたものを加算したものである。',
  'new_home_sales_mom.description:ja:DEFAULT': '',
  'new_home_sales_mom.description:ja:United States':
    '新築住宅の販売は、売買契約の締結または手付金の受領時に発生する。新築住宅の状態は、未着工、建設中、完成済みのいずれの段階でもよい。新築住宅の販売は、米国住宅市場の約10％を占める。新築一戸建て住宅販売は、前月比で非常に変動しやすく、速報値は主に建設許可証のデータから作成されるため大幅に修正される場合がある。',
  'may_day_bank_holiday.description:ja:DEFAULT': '',
  'may_day_bank_holiday.description:ja:United Kingdom': '',
  'new_motor_vehicle_sales_mom.description:ja:DEFAULT': '',
  'new_motor_vehicle_sales_mom.description:ja:Australia':
    'オーストラリアでは、自動車登録台数とは、登録されている乗用車の総数のことである。',
  'burning_at_stake_of_jan_hus.description:ja:DEFAULT': '',
  'burning_at_stake_of_jan_hus.description:ja:Czech Republic': '',
  'foreign_investment_in_japan_stocks.description:ja:DEFAULT': '',
  'foreign_investment_in_japan_stocks.description:ja:Japan': '',
  'new_year.description:ja:DEFAULT': '',
  'new_year.description:ja:Israel': '',
  'new_year.description:ja:South Korea': '',
  'new_year.description:ja:Thailand': '',
  'new_year_s_day_substitute_day.description:ja:DEFAULT': '',
  'new_year_s_day_substitute_day.description:ja:United Kingdom': '',
  'new_year_s_day_substitute_day.description:ja:Ireland': '',
  'news_conference_on_italy_budget_talks.description:ja:DEFAULT': '',
  'news_conference_on_italy_budget_talks.description:ja:European Union': '',
  'martin_l_king_day.description:ja:DEFAULT': '',
  'martin_l_king_day.description:ja:United States': '',
  'nikkei_manufacturing_pmi.description:ja:DEFAULT': '',
  'nikkei_manufacturing_pmi.description:ja:Philippines':
    'IHSマークイット・フィリピン製造業購買担当者景気指数（PMI）は製造業450社の調査に基づき、製造業の業況を測定する。それぞれウエート付けされた、新規受注（30%）、生産（25%）、雇用（20%）、サプライヤー納期（15%）、購買品在庫（10%）という5つの個別インデックスから構成され、納期インデックスは他のインデックスと比較できるように符号が反転される。50を超える指数は製造業セクターが前期に比べて拡大していることを、50を切る指数は縮小していることを、50は変化がないことを示す。',
  'nikkei_manufacturing_pmi.description:ja:Taiwan':
    'IHSマークイット台湾製造業購買担当者景気指数（PMI）は製造業300社の調査に基づき、製造業の業況を測定する。それぞれウエート付けされた、新規受注（30%）、生産（25%）、雇用（20%）、サプライヤー納期（15%）、購買品在庫（10%）という5つの個別インデックスから構成され、納期インデックスは他のインデックスと比較できるように符号が反転される。50を超える指数は製造業セクターが前期に比べて拡大していることを、50を切る指数は縮小していることを、50は変化がないことを示す。',
  'nikkei_manufacturing_pmi.description:ja:Thailand':
    'IHSマークイット・タイ製造業購買担当者景気指数（PMI）は製造業450社の調査に基づき、製造業の業況を測定する。それぞれウエート付けされた、新規受注（30%）、生産（25%）、雇用（20%）、サプライヤー納期（15%）、購買品在庫（10%）という5つの個別インデックスから構成され、納期インデックスは他のインデックスと比較できるように符号が反転される。50を超える指数は製造業セクターが前期に比べて拡大していることを、50を切る指数は縮小していることを、50は変化がないことを示す。',
  'nikkei_manufacturing_pmi.description:ja:South Korea':
    'IHSマークイット韓国製造業購買担当者景気指数（PMI）は製造業400社の調査に基づき、製造業の業況を測定する。それぞれウエート付けされた、新規受注（30%）、生産（25%）、雇用（20%）、サプライヤー納期（15%）、購買品在庫（10%）という5つの個別インデックスから構成され、納期インデックスは他のインデックスと比較できるように符号が反転される。50を超える指数は製造業セクターが前期に比べて拡大していることを、50を切る指数は縮小していることを、50は変化がないことを示す。',
  'nikkei_manufacturing_pmi.description:ja:Indonesia':
    'IHSマークイット・インドネシア製造業購買担当者景気指数（PMI）は製造業400社の調査に基づき、製造業の業況を測定する。それぞれウエート付けされた、新規受注（30%）、生産（25%）、雇用（20%）、サプライヤー納期（15%）、購買品在庫（10%）という5つの個別インデックスから構成され、納期インデックスは他のインデックスと比較できるよう符号が反転される。50を超える指数は製造業セクターが前期に比べて拡大していることを、50を切る指数は縮小していることを、50は変化がないことを示す。',
  'nikkei_manufacturing_pmi.description:ja:Hong Kong':
    '日経香港製造業購買担当者景気指数は製造業300社の調査に基づく製造業の業況を測定する。それぞれ加重された、新規受注（30%）、生産（25%）、雇用（20%）、サプライヤー納期（15%）、購買品在庫（10%）という5つの個別インデックスから構成され、納期インデックスは他のインデックスと比較ができるように符号が反転される。50を超える指数は製造業セクターが前期に比べて拡大していることを、50を切る指数は縮小していることを、50は変化がないことを示す。',
  'nikkei_manufacturing_pmi.description:ja:India':
    'IHSマークイット・インド製造業購買担当者景気指数（PMI）は製造業500社の調査に基づき、製造業の業況を測定する。それぞれウエート付けされた、新規受注（30%）、生産（25%）、雇用（20%）、サプライヤー納期（15%）、購買品在庫（10%）という5つの個別インデックスから構成され、納期インデックスは他のインデックスと比較できるよう符号が反転される。50を超える指数は製造業セクターが前期に比べて拡大していることを、50を切る指数は縮小していることを、50は変化がないことを示す。',
  'fed_yellen_testymony_-_house.description:ja:DEFAULT': '',
  'fed_yellen_testymony_-_house.description:ja:United States': '',
  'nikkei_markit_services_pmi.description:ja:DEFAULT': '',
  'nikkei_markit_services_pmi.description:ja:Japan':
    '日本のサービス業（PMI）は、日本の民間サービス業を代表する400社を超える企業のパネルから収集したデータを基に集計される。指数は、売上高、雇用、在庫、価格などの変数を追跡し、50を超えれば、サービス業が全体的に拡大傾向にあることを示し、50を下回れば、全般に縮小傾向にあることを示す。',
  'boj_sakurai_speech.description:ja:DEFAULT': '',
  'boj_sakurai_speech.description:ja:Japan':
    '日本では、金利は政策委員会による金融政策決定会合で決められる。日銀の公式金利は、基準割引率である。金融政策決定会合では、次の会合までの間の金融市場操作におけるガイドラインが決められ、このガイドラインは無担保コール翌日物金利の目標として定められる。',
  'nomura_jmma_manufacturing_pmi.description:ja:DEFAULT': '',
  'nomura_jmma_manufacturing_pmi.description:ja:Japan': '',
  'non_farm_payrolls_qoq.description:ja:DEFAULT': '',
  'non_farm_payrolls_qoq.description:ja:France':
    'フランスでは、雇用者数とは、雇用期間に関係なく四半期最終日の雇用者数を示す。すべてのセクターとすべてのフランス領土が含まれている（マヨットは除く）。',
  'fed_beige_book.description:ja:DEFAULT': '',
  'fed_beige_book.description:ja:United States':
    '米国では、金利設定の権限は連邦準備制度理事会（理事会）と連邦公開市場委員会（FOMC）に分けられている。理事会は、1つ以上の地区連邦準備銀行から提出された勧告を受けて、割引率の変更を決定する。FOMCは、中央銀行の資金量やフェデラルファンド市場金利などの公開市場操作を決定する。',
  'pce_prices_qoq_2nd_est.description:ja:DEFAULT': '',
  'pce_prices_qoq_2nd_est.description:ja:United States': '',
  'm3_money_supply.description:ja:DEFAULT': '',
  'm3_money_supply.description:ja:India': '',
  'thanksgiving_day.description:ja:DEFAULT': '',
  'thanksgiving_day.description:ja:United States': '',
  'thanksgiving_day.description:ja:Canada': '',
  'national_unity_day.description:ja:DEFAULT': '',
  'national_unity_day.description:ja:Russia': '',
  'norges_bank_interest_rate_decision.description:ja:DEFAULT': '',
  'norges_bank_interest_rate_decision.description:ja:Norway':
    'ノルウェーでは、基準金利はノルウェー中央銀行（ノルゲス銀行）が設定する。公式金利は主要政策金利で、サイト・デポジット・レートとも呼ばれる。',
  'norges_bank_interest_rate_decison.description:ja:DEFAULT': '',
  'norges_bank_interest_rate_decison.description:ja:Norway':
    'ノルウェーでは、基準金利はノルウェー中央銀行（ノルゲス銀行）が設定する。公式金利は主要政策金利で、サイト・デポジット・レートとも呼ばれる。',
  'anz_business_confidence.description:ja:DEFAULT': '',
  'anz_business_confidence.description:ja:New Zealand':
    'ニュージーランドの企業信頼感指標は、自社の今後の業績見通しおよび経済全体に関し事業者の景況感を把握するものである。約700社からの回答によりまとめられる。景況が悪化するとの回答数から改善するとの回答数を引いたパーセンテージで示される。',
  'gdp_growth_rate_yoy_final.description:ja:DEFAULT': '',
  'gdp_growth_rate_yoy_final.description:ja:Euro Area':
    'ユーロ圏は世界で2番目に大きな経済圏である。加盟している19カ国のうち、最も大きいのはドイツ（GDP合計の29％）、フランス（20％）、イタリア（15％）、スペイン（10％）である。支出面では家計消費がGDPの主な構成要素で、総支出の54％を占めており、次いで総固定資本形成（21％）、政府支出（20％）となっている。財・サービスの輸出はGDPの47%、輸入は43%であるため、GDP全体に4%寄与している。',
  'gdp_growth_rate_yoy_final.description:ja:Israel':
    'イスラエルは中東で最も技術的に進んだ先進的な市場経済である。イスラエル経済最大のセクターはサービス業で、GDPの80%を占め、うち最も重要なのは金融とビジネス（GDPの28％）、政府（同17％）、住宅（同12％）、貿易、宿泊、レストラン（同10％）、輸送・倉庫・通信（同8％）。製造業の重要性は近年低下しており、今ではGDPの14％。',
  'gdp_growth_rate_yoy_final.description:ja:Hungary':
    'ハンガリーでは、電子機器の製造と研究などがイノベーションと経済成長の主な原動力である。また、モバイル技術、情報セキュリティ、および関連するハードウェア研究の主要な中心地としても成長している。支出面では、家計消費がGDPの主な構成要素として全体の50%を占め、総固定資本形成（22％）、政府支出（20％）と続く。財・サービスの輸出はGDPの89%を占め、輸入は82%であるため、GDP全体に7%寄与している。',
  'gdp_growth_rate_yoy_final.description:ja:Portugal':
    '支出面では、家計消費がGDPの主な構成要素であり、その合計支出の66％を占めている。続いて、政府支出（19％）、総固定資本形成（15％）となっている。財・サービスの輸出はGDPの40％、輸入も40％を占める。',
  'gdp_growth_rate_yoy_final.description:ja:Greece':
    'ギリシャでは家計消費がGDPの主な構成要素であり、その総支出の72％を占めており、次いで政府支出（20％）、総固定資本形成（12％）となっている。財・サービスの輸出はGDPの33%、輸入は35%であるため、GDP合計に2%のマイナス寄与となっている。',
  'gdp_growth_rate_yoy_final.description:ja:Hong Kong':
    '近年、香港は、中国市場へのアクセスを希望するビジネス、貿易、金融、輸入業者にとって、国際的なハブとなっている。そのため、経済の最大のセクターはサービス業で、GDPの約93%を占めている。サービス業の中で最も重要なのは、卸売・小売（GDPの25％）、行政・社会サービス（17％）、金融・保険（16％）、不動産・ビジネスサービス（11％）、不動産所有（10％）、輸送・郵便サービス（6％）である。情報・通信、および食品・宿泊は8パーセントである。建設業はGDPの3.5％、電気・水・ガス供給、廃棄物処理、製造業は3.4％を占める。',
  'gdp_growth_rate_yoy_final.description:ja:Finland':
    'フィンランド経済最大のセクターは65％を占めるサービス業で、次いで製造業と精製が31％を占める。第一次産業は3％。フィンランドの主要な工業製品は紙・板・電子・金属製品である。製造業のうち主要セクターはエンジニアリングとハイテク業界。支出面では、GDPの55％を占める家計消費が最大で、次いで政府支出（25％）、総固定資本形成（20％）。財・サービスの輸出はGDPの38％である一方、輸入は39％であるため、GDP合計に1％のマイナス寄与となっている。',
  'gdp_growth_rate_yoy_final.description:ja:Ireland':
    '1995年から2007年にかけて、アイルランドは個人消費や建設投資、企業投資の増加により、年平均6％の高成長を遂げていた。しかし2008年には不動産市場の暴落により経済活動が急激に落ち込み、10年以上ぶりに景気後退に陥った。その後、財政赤字と政府債務の削減を目的とした一連の経済改革を経て、2010年第3四半期からGDPが拡大に転じ、2012年には3四半期連続で縮小した後、ユーロ圏で最も成長率の高い経済国の地位を獲得した。',
  'gdp_growth_rate_yoy_final.description:ja:United Kingdom':
    'イギリスの経済規模は、世界第6位、欧州ではドイツに次ぐ第2位である。サービス業が最も重要で、GDP全体の79％を占めている。サービス業の中で最も大きな分野は、政府、教育、医療（GDP全体の19％）、不動産（12％）、専門的・科学的・技術的活動および行政・支援サービス（12％）、卸売・小売業（11％）、金融・保険（8％）である。工業分野はGDPの21％を占め、この分野の最大のセグメントは製造業（GDP全体の10％）と建設業（6％）である。農業は、GDPのわずか1%を占めるにすぎない。',
  'gdp_growth_rate_yoy_final.description:ja:South Korea':
    '韓国の経済は、過去50年間で世界で最も多様化し、技術的にも進歩した国の一つとなった。多くの先進国と同様、GDPに占めるサービスの割合が最も高くなっている（GDPの約57％）。サービス業の中でも特に重要なのは、行政（6％）、教育（5.3％）、情報・通信（5％）、ビジネス活動（4.6％）である。韓国を主要な輸出国にした強力な製造業は、GDPの32％を占めている。',
  'gdp_growth_rate_yoy_final.description:ja:Taiwan':
    '台湾の経済は輸出主導型であるため、製造業が最も重要な経済分野であり、GDPの31%を占める。大半の先進国同様、サービス業がGDP最大の構成要素として65%近くを占め、その主要な構成セグメントには卸売・小売（GDPの17.3%）、不動産（8%）、行政・国防（6.4%）がある。',
  'gdp_growth_rate_yoy_final.description:ja:Belgium':
    'ベルギーでは家計消費がGDPの主な構成要素であり、その総支出の52％を占めており、次いで政府支出（25％）、総固定資本形成（23％）となっている。財・サービスの輸出はGDPの84%である一方、輸入は83%であるため、GDP合計に1%のマイナス寄与となっている。',
  'gdp_growth_rate_yoy_final.description:ja:France':
    'フランスの経済は世界第7位、ユーロ圏では第2位の規模。フランスは、航空機、軍事機器、香水、医薬品、ワイン、牛肉、小麦の生産など、大規模かつ多様な工業・農業基盤を有している。少なくとも年間8,200万人の外国人観光客が訪れるフランスは、世界で最も観光客の多い国であり、観光業による収入は世界第3位を維持している。経済の最大部門はサービス業（GDP全体の79％）で、工業は19％。農業のGDPへの寄与度は2%だが、フランスは世界第2位の農産物の輸出国である。',
  'gdp_growth_rate_yoy_final.description:ja:Mexico':
    'メキシコの経済規模は中南米で第2位である。輸出志向の経済で、貿易の90％以上が自由貿易協定の下で行われている。メキシコでは、サービス業がGDP全体の62%を占めている。サービス業の中で最も大きな分野は、卸売・小売（16％）、不動産（10％）、輸送・倉庫・通信（7％）、金融サービス・保険（6％）である。工業は生産高の18％を占めており、その最大のセグメントは自動車（GDP全体の4％）と食品（3.8％）である。それ以外は建設、水道・ガス・配電（8％）、鉱業（5％）、政府（4％）、農業・林業・漁業・狩猟（3％）。',
  'gdp_growth_rate_yoy_final.description:ja:Italy':
    'イタリアは欧州で2番目に大きい製造業経済であり、ユーロ圏で3番目に大きい経済国である。GDPの支出側の構成：家計消費（61％）、政府支出（19％）、総固定資本形成（17％）。財・サービスの輸出はGDPの30％、輸入は27％であるため、GDP全体に3％寄与している。',
  'gdp_growth_rate_yoy_final.description:ja:Netherlands':
    'オランダ経済はユーロ圏で第6位、ヨーロッパの重要な交通拠点である。オランダ経済は外国貿易に大きく依存しており、輸出はGDPの83％、輸入は72％を占める。GDPの主な構成要素は家計消費（45％）で、次いで政府支出（26％）、総固定資本形成（18％）、純輸出（11％）となっている。',
  'gdp_growth_rate_yoy_final.description:ja:Singapore':
    'シンガポール経済最大の分野はサービス業であり、GDPの72%を占める。サービス業の主要な部門は、卸売・小売業（合計GDPの18%）、ビジネスサービス（16%）、金融・保険（13%）、輸送・倉庫（10%）、情報・通信（5%）である。産業部門は総生産の28%を占め、その主要セグメントには、製造業（21%）、建設業（5%）がある。',
  'gdp_growth_rate_yoy_final.description:ja:Poland':
    'ポーランドの消費者市場は3,800万人と、ヨーロッパでも最大級の規模を誇る。ポーランドの主要産業は、食品・飲料加工、造船、機械・鉄鋼製品・化学品・ガラス・繊維製品の製造である。支出面では、家計消費がGDPの60％を占めており、次いで総固定資本形成（20％）、政府支出（18％）である。財・サービスの輸出はGDPの47％を占め、輸入は46％で、GDP全体に1％寄与している。',
  'gdp_growth_rate_yoy_final.description:ja:Germany':
    '世界第5位、欧州最大の経済規模を誇るドイツ経済は、機械、自動車、化学品、家庭用機器などの主要な輸出国であり、高度に熟練した労働力の恩恵を受けている。GDPの支出側の構成：家計消費（55％）、総資本形成（20％、うち建設業が10％、機械装置が6％、その他が4％）、政府支出（19％）。財・サービスの輸出はGDPの46％、輸入は39％で、GDP全体に7％寄与している。',
  'gdp_growth_rate_yoy_final.description:ja:Austria':
    'オーストリアでは家計消費がGDPの主な構成要素で54％を占めており、次いで総固定資本形成（22％）、政府支出（20％）となっている。財・サービスの輸出はGDPの53%を占める一方、輸入は50%であるため、正味の財・サービスの輸出はGDP全体に3%寄与している。',
  'gdp_growth_rate_yoy_final.description:ja:Sweden':
    'スウェーデンは、木材、水力発電、鉄鉱石などの資源をベースに、外国との貿易を重視した経済である。工業部門は、生産額と輸出額の約50%を占めている。支出面では、家計消費がGDPの主な構成要素であり、総支出の46％を占め、次いで政府消費（26％）、総固定資本形成（23％）となっている。財・サービスの輸出はGDPの45％、輸入は41％であることから、GDP全体に4％寄与している。',
  'gdp_growth_rate_yoy_final.description:ja:Spain':
    '支出側では家計消費がスペインGDPの主な構成要素で58％を占めており、次いで総固定資本形成（21％）、政府支出（18％）となっている。投資のうち、有形固定資産は、建設（10％）、機械・設備・兵器システム（7％）などを含めGDPの18％を占めており、残りの3％は知的財産製品である。財・サービスの輸出はGDPの34%である一方、輸入は31%であるため、GDP全体に3%寄与している。',
  'gdp_growth_rate_yoy_final.description:ja:Czech Republic':
    'チェコ共和国では家計消費がGDPの主な構成要素であり、その総支出の49％を占めており、次いで総固定資本形成（25％）、政府支出（19％）となっている。財・サービスの輸出はGDPの84%である一方、輸入は77%であるため、GDP合計に７%のプラス寄与となっている。',
  'gdp_growth_rate_yoy_final.description:ja:Denmark':
    'デンマークでは家計消費がGDPの主な構成要素であり、その総支出の49％を占めており、次いで政府支出（27％とEU諸国では最高）、総固定資本形成（19％）となっている。財・サービスの輸出はGDPの54%に達する一方、輸入は48%であるため、GDP合計に６%のプラス寄与となっている。',
  'gdp_growth_rate_yoy_final.description:ja:Russia':
    'ロシアは世界有数の石油・天然ガスの生産国であり、鉄鋼や一次アルミニウムなどの金属の輸出国でもある。生産面では、サービス業が経済の最大セクターであり、GDPの58％を占めている。サービス業の中で最も重要な分野は、卸売・小売（GDP合計の17％）、行政・医療・教育（12％）である。工業は総生産の40％、農業は残りの2％。',
  '12-month_bubill_auction.description:ja:DEFAULT': '',
  '12-month_bubill_auction.description:ja:Germany': '',
  'retail_price_index_yoy.description:ja:DEFAULT': '',
  'retail_price_index_yoy.description:ja:Philippines':
    'フィリピンでは、マニラ首都圏（NCR）の消費財小売価格指標（GRPI）の前年比変動によって、特定月における消費者が支払う小売価格の変動を比較する。',
  'retail_price_index_yoy.description:ja:United Kingdom':
    '英国では、RPI（小売価格指数）は当初、第一次世界大戦中の物価上昇にから一般労働者を保護するための支援策として設計された指数に基づく補償指数として開発された。RPIは1947年以降のインフレ推定値を提供しており、1956年1月に初めて正式な消費者物価インフレ指数が発表されて以来、英国の消費者物価指数（CPI）が1996年に導入されるまで、英国の消費者物価インフレ指数を測定する指数はRPIとその派生指数に限られていた。RPI改善策に向けたコンサルテーションに続き、英国統計理事会のジル・マセソン顧問は、RPI算出に使用される数式の1つは国際基準を満たさないとして新たな指数を発表することを推奨した。RPIは民間の世帯のみを対象とするが、所得においてトップ4%の世帯と、所得の4分の3以上が年金である年金生活者は除外される。',
  'isra_and_mi_raj.description:ja:DEFAULT': '',
  'isra_and_mi_raj.description:ja:Indonesia': '',
  'oecd_global_forum_on_productivity.description:ja:DEFAULT': '',
  'oecd_global_forum_on_productivity.description:ja:Italy': '',
  'opec_meeting.description:ja:DEFAULT': '',
  'opec_meeting.description:ja:Austria': '',
  'mnb_interest_rate_decison.description:ja:DEFAULT': '',
  'mnb_interest_rate_decison.description:ja:Hungary':
    'ハンガリーでは政策金利はハンガリー国立銀行（MNB）の金融理事会が決定する。主要政策金利は、中央銀行が金融機関に貸し付ける1年物融資のリファイナンス金利に基づく基準金利である。',
  'obamacare_repeal_vote_-_pulled.description:ja:DEFAULT': '',
  'obamacare_repeal_vote_-_pulled.description:ja:United States': '',
  'boe_s_governor_carney_speech.description:ja:DEFAULT': '',
  'boe_s_governor_carney_speech.description:ja:United Kingdom': '',
  'belt_and_road_forum.description:ja:DEFAULT': '',
  'belt_and_road_forum.description:ja:China': '',
  'corporate_profits_qoq.description:ja:DEFAULT': '',
  'corporate_profits_qoq.description:ja:United States':
    '企業収益は、米国企業が現在の生産によって得た合計利益を示している。企業の健全性を示す指標として、米国で最も注目されている経済指標の一つであり、景気動向の重要な指標となっている。',
  'ocean_day.description:ja:DEFAULT': '',
  'ocean_day.description:ja:Japan': '',
  'day_following_the_mid-autumn_festival.description:ja:DEFAULT': '',
  'day_following_the_mid-autumn_festival.description:ja:Hong Kong': '',
  'ochi_day.description:ja:DEFAULT': '',
  'ochi_day.description:ja:Greece': '',
  'philadelphia_fed_manufacturing_index.description:ja:DEFAULT': '',
  'philadelphia_fed_manufacturing_index.description:ja:United States':
    'フィラデルフィア連銀製造業景気指数は、米連邦準備制度第3地区における製造業の景況感調査に基づいている。調査対象企業が全体的な経済活動状況の方向と、工場における雇用、労働時間、新規受注、受注残、出荷、在庫、納期、支払価格、受取価格などの各種項目について報告する。指数がゼロを超えるプラスであれば製造業の業況拡大を、マイナスであれば縮小を示す。',
  'ihs_markit_services_pmi.description:ja:DEFAULT': '',
  'ihs_markit_services_pmi.description:ja:Italy':
    'マークイット/ADACIイタリアのサービス業購買担当者景気指数（PMI）は、イタリアのサービス業を代表する約400社のパネルから収集したデータに基づき、売上高、雇用、在庫、価格などの変数を追跡する。指数が50を超えれば全体的にサービス業が拡大していることを、50を切れば縮小していることを示す。',
  'ontario_provincial_elections.description:ja:DEFAULT': '',
  'ontario_provincial_elections.description:ja:Canada': '',
  'eve_of_pesach.description:ja:DEFAULT': '',
  'eve_of_pesach.description:ja:Israel': '',
  'orthodox_christmas_day.description:ja:DEFAULT': '',
  'orthodox_christmas_day.description:ja:Russia': '',
  'assumption_day.description:ja:DEFAULT': '',
  'assumption_day.description:ja:Colombia': '',
  'orthodox_easter_monday.description:ja:DEFAULT': '',
  'orthodox_easter_monday.description:ja:Greece': '',
  'urban_investment_ytd_yoy.description:ja:DEFAULT': '',
  'urban_investment_ytd_yoy.description:ja:China': '',
  'unit_labor_costs_qoq_final.description:ja:DEFAULT': '',
  'unit_labor_costs_qoq_final.description:ja:United States': '',
  'orthodox_easter_sunday.description:ja:DEFAULT': '',
  'orthodox_easter_sunday.description:ja:Greece': '',
  'orthodox_good_friday.description:ja:DEFAULT': '',
  'orthodox_good_friday.description:ja:Greece': '',
  'shavuot_pentecost.description:ja:DEFAULT': '',
  'shavuot_pentecost.description:ja:Israel': '',
  'gold_production_yoy.description:ja:DEFAULT': '',
  'gold_production_yoy.description:ja:South Africa':
    '南アフリカでは全体の鉱業生産のうち、金生産が16パーセントを占める。',
  'gdp_growth_rate_qoq.description:ja:DEFAULT': '',
  'gdp_growth_rate_qoq.description:ja:Singapore':
    'シンガポールは1963年に独立して以来目覚ましい経済成長を遂げ、今では人口1人当たりGDPにおいて世界の最上位国の1つとなっている。同国経済は、港湾活動と電子部品および精製石油の輸出を含む貿易に大きく依存している。シンガポールは世界中で最も競争力の高い、企業寄りの最も自由な経済の1つであることから、外国直接投資の投資先として他国をリードしており、重要な金融の中心地でもある。',
  '1956_revolution_memorial_day.description:ja:DEFAULT': '',
  '1956_revolution_memorial_day.description:ja:Hungary': '',
  'new_housing_price_index_mom.description:ja:DEFAULT': '',
  'new_housing_price_index_mom.description:ja:Canada':
    'カナダでは新築住宅価格指数（NHPI）は建設業者による新築住宅販売価格の変化を測定する。2つの連続した期間における各住宅の仕様は同じである。',
  'gfk_consumer_confidence.description:ja:DEFAULT': '',
  'gfk_consumer_confidence.description:ja:Germany':
    'GfK消費者信頼感指数は、14歳以上の個人約2000人を対象に、所得の見通し、購買志向、貯蓄を中心に調査するもので、設問に対する肯定の解答と否定の回答との差異として算出される。指数値はマイナス100とプラス100の間で、0は長期平均値を表す。',
  'gfk_consumer_confidence.description:ja:United Kingdom':
    '英国では消費者信頼感は向こう12カ月間の景気見通しに対する消費者の楽観度を測定する。GfK消費者信頼感指数は約2000人の消費者を対象に、個人の財務状況や大きな買い物に向けた環境、全体的な経済状況、貯蓄水準などを含めて過去と将来の相対的な経済状況を尋ねた調査から導き出される。',
  'bundesbank_beermann_speech.description:ja:DEFAULT': '',
  'bundesbank_beermann_speech.description:ja:Germany':
    'ドイツはユーロを採用している欧州連合（EU）加盟国である。ドイツの基準金利は欧州中央銀行が設定し、公式には主要リファイナンス・オペ（MRO）と呼ばれる。',
  'outlook_for_economic_activity_and_prices.description:ja:DEFAULT': '',
  'outlook_for_economic_activity_and_prices.description:ja:Japan':
    '日本では、金利は政策委員会による金融政策決定会合で決められる。日銀の公式金利は、基準割引率である。金融政策決定会合では、次の会合までの間の金融市場操作におけるガイドラインが決められ、このガイドラインは無担保コール翌日物金利の目標として定められる。',
  'european_parliament_elections.description:ja:DEFAULT': '',
  'european_parliament_elections.description:ja:European Union': '',
  'overall_household_spending_yoy.description:ja:DEFAULT': '',
  'overall_household_spending_yoy.description:ja:Japan': '',
  'overall_net_capital_flows.description:ja:DEFAULT': '',
  'overall_net_capital_flows.description:ja:United States':
    '米財務省の国際資本統計（TIC）純資本フローには、長期証券と短期米国証券の外国による純取得額と銀行資本フローが含まれる。',
  'pce_price_index_yoy.description:ja:DEFAULT': '',
  'pce_price_index_yoy.description:ja:United States':
    '米国では、個人消費支出（PCE）価格指数が国内で財とサービス購入のために支払った価格を測定する。消費者物価指数は財の固定バスケットに対して数年間変更されない支出ウェートを使用するが、PCE価格指数は連鎖指数を使用し、現行期間とその前の期間の支出データを使用する（フィッシャー価格指数と呼ばれる）。',
  'whit_monday.description:ja:DEFAULT': '',
  'whit_monday.description:ja:Switzerland': '',
  'whit_monday.description:ja:Norway': '',
  'whit_monday.description:ja:Netherlands': '',
  'whit_monday.description:ja:Hungary': '',
  'whit_monday.description:ja:Austria': '',
  'whit_monday.description:ja:France': '',
  'whit_monday.description:ja:Belgium': '',
  'whit_monday.description:ja:Denmark': '',
  'whit_monday.description:ja:Germany': '',
  'pce_prices_qoq_2_est.description:ja:DEFAULT': '',
  'pce_prices_qoq_2_est.description:ja:United States': '',
  'seollal_holiday_day_3.description:ja:DEFAULT': '',
  'seollal_holiday_day_3.description:ja:South Korea': '',
  'obr_fiscal_risks_report.description:ja:DEFAULT': '',
  'obr_fiscal_risks_report.description:ja:United Kingdom': '',
  'balance_of_trade-final.description:ja:DEFAULT': '',
  'balance_of_trade-final.description:ja:Ireland':
    'アイルランドは1985年以来継続して貿易黒字を記録している。2017年、輸出入とも史上最高額となり、貿易黒字は過去最高となる450億ユーロに達した。貿易黒字の相手国の上位はアメリカ、ベルギー、スイス、オランダ、ドイツ、貿易赤字の上位はフランス、イギリスとなっている。',
  'national_day_of_the_people_s_republic_of_china.description:ja:DEFAULT': '',
  'national_day_of_the_people_s_republic_of_china.description:ja:Hong Kong': '',
  'catalonia_parliamentary_election.description:ja:DEFAULT': '',
  'catalonia_parliamentary_election.description:ja:Spain': '',
  'nonfarm_productivity_yoy.description:ja:DEFAULT': '',
  'nonfarm_productivity_yoy.description:ja:United States': '',
  'industrial_orders_yoy.description:ja:DEFAULT': '',
  'industrial_orders_yoy.description:ja:Spain':
    'スペインでは、工業新規受注指数（INORI）は、工業部門に向けられる将来の需要の月次推移を測定する短期指標である。',
  'industrial_orders_yoy.description:ja:Switzerland':
    'スイスでは、工業受注は工業部門の企業による受注の前年比変化を測定する。',
  'industrial_orders_yoy.description:ja:Italy':
    'イタリアでは、新規受注が企業の景況感に大きく影響するため、国内総生産成長率の先行指標となる。',
  'car_sales_yoy.description:ja:DEFAULT': '',
  'car_sales_yoy.description:ja:Indonesia':
    'インドネシアでは、合計自動車販売台数は乗用車新車の国内販売台数である。',
  'net_lending_to_individuals_mom.description:ja:DEFAULT': '',
  'net_lending_to_individuals_mom.description:ja:United Kingdom': '',
  'vehicle_sales_yoy.description:ja:DEFAULT': '',
  'vehicle_sales_yoy.description:ja:Russia':
    'ロシアでは、合計車両販売台数は、乗用車と小型商用車の登録台数合計である。',
  'vehicle_sales_yoy.description:ja:Japan': '',
  'vehicle_sales_yoy.description:ja:China':
    '中国では、合計車両販売台数は、1カ月の間に販売された自動車の台数である。',
  'manufacturing_sales.description:ja:DEFAULT': '',
  'manufacturing_sales.description:ja:New Zealand': '',
  'pm_conte_speech_in_the_senate.description:ja:DEFAULT': '',
  'pm_conte_speech_in_the_senate.description:ja:Italy': '',
  'chatrapati_shivaji_maharaj_jayanti.description:ja:DEFAULT': '',
  'chatrapati_shivaji_maharaj_jayanti.description:ja:India': '',
  'pm_may_s_statement_on_brexit.description:ja:DEFAULT': '',
  'pm_may_s_statement_on_brexit.description:ja:United Kingdom': '',
  'gdp_capital_expenditure_qoq_final.description:ja:DEFAULT': '',
  'gdp_capital_expenditure_qoq_final.description:ja:Japan':
    '総固定資本形成は、企業、政府、家計（非株式会社の法人は除く）が取得した新規固定資産または既存の固定資産の価値から固定資産の売却分を除いた価値を測定する。',
  'pm_shinzo_abe_to_dissolve_parliament.description:ja:DEFAULT': '',
  'pm_shinzo_abe_to_dissolve_parliament.description:ja:Japan': '',
  'gdp_growth_rate_yoy_-_1st_est.description:ja:DEFAULT': '',
  'gdp_growth_rate_yoy_-_1st_est.description:ja:Israel':
    'イスラエルは中東で最も技術的に進んだ先進的な市場経済である。イスラエル経済最大のセクターはサービス業で、GDPの80%を占め、うち最も重要なのは金融とビジネス（GDPの28％）、政府（同17％）、住宅（同12％）、貿易、宿泊、レストラン（同10％）、輸送・倉庫・通信（同8％）。製造業の重要性は近年低下しており、今ではGDPの14％。',
  'rbc_manufacturing_pmi.description:ja:DEFAULT': '',
  'rbc_manufacturing_pmi.description:ja:Canada':
    'IHS マークイット・カナダ製造業購買担当者景気指数™（PMI）は、製造業の業況を測定する。製造業PMIはそれぞれウエート付けされた、新規受注（30%）、生産（25%）、雇用（20%）、サプライヤー納期（15%）、購買品在庫（10%）という5つのサブインデックスから構成され、納期インデックスは他のインデックスと同じ方向に動くよう符号を反転させる。50を超える指数は製造業セクターが前期に比べて拡大していることを、50を切る指数は縮小していることを、50は変化がないことを示す。',
  'pm_trudeau_statement.description:ja:DEFAULT': '',
  'pm_trudeau_statement.description:ja:Canada': '',
  'obr_fiscal_forecasts.description:ja:DEFAULT': '',
  'obr_fiscal_forecasts.description:ja:United Kingdom': '',
  'westpac_consumer_confidence_change.description:ja:DEFAULT': '',
  'westpac_consumer_confidence_change.description:ja:Australia':
    '消費者信頼感指数は1200以上のオーストラリアの世帯を調査した結果に基づいて算出される。この指数は、過去1年間と向こう1年間の消費者の財務状況に対する考えと、今後1年および5年間の経済状況予想、大型商品の購入状況など、5つの項目の平均をとったもので、指数が100を超えると悲観的な見方よりも楽観的な見方が多いことを示す。',
  'pmi_services.description:ja:DEFAULT': '',
  'pmi_services.description:ja:Ireland': '',
  '5-year_bond_auction.description:ja:DEFAULT': '',
  '5-year_bond_auction.description:ja:Canada': '',
  'pnad_unemployment_rate.description:ja:DEFAULT': '',
  'pnad_unemployment_rate.description:ja:Brazil':
    'ブラジルでは、失業率は労働力全体のうち、積極的に仕事を探している人口の比率を測定する。',
  'ppi_mom_prel.description:ja:DEFAULT': '',
  'ppi_mom_prel.description:ja:Canada':
    'カナダでは、生産者物価指数（PPI）は、一定期間中に卸売市場で製造者や生産者が販売した財やサービスの価格の変化の平均を測定する。',
  'ppi_output_mom.description:ja:DEFAULT': '',
  'ppi_output_mom.description:ja:United Kingdom':
    '英国では、生産者物価指数（PPI）は、生産者が販売した財の1カ月間の価格変動を測定するもので、インフレに関する重要な測定値である。工場渡し価格（アウトプット価格）は英国の製造業者が販売する財の価格で、人件費、原材料費、光熱費、借入利息、敷地や建物の維持費、賃料などが含まれ、税金は除く。',
  'api_gasoline_stock_change.description:ja:DEFAULT': '',
  'api_gasoline_stock_change.description:ja:United States': '',
  'bundesbank_dombret_speech.description:ja:DEFAULT': '',
  'bundesbank_dombret_speech.description:ja:Germany': '',
  'ppi_qoq.description:ja:DEFAULT': '',
  'ppi_qoq.description:ja:Australia':
    'オーストラリアでは、生産者物価指数（PPI）は、一定期間中に卸売市場で製造者や生産者が販売した財やサービスの価格の変化の平均を測定する。',
  'gdp_growth_rate_qoq-adv.description:ja:DEFAULT': '',
  'gdp_growth_rate_qoq-adv.description:ja:Singapore':
    'シンガポールは1963年に独立して以来目覚ましい経済成長を遂げ、今では人口1人当たりGDPにおいて世界の最上位国の1つとなっている。同国経済は、港湾活動と電子部品および精製石油の輸出を含む貿易に大きく依存している。シンガポールは世界中で最も競争力の高い、企業寄りの最も自由な経済の1つであることから、外国直接投資の投資先として他国をリードしており、重要な金融の中心地でもある。',
  'ppi_yoy_final.description:ja:DEFAULT': '',
  'ppi_yoy_final.description:ja:Canada':
    'カナダでは、生産者物価変動は、一定期間中に卸売市場で製造者や生産者が販売した財やサービスの価格の平均的な変化を測定する。',
  'pm_trudeau_testimony.description:ja:DEFAULT': '',
  'pm_trudeau_testimony.description:ja:Canada': '',
  'leading_economic_index_final.description:ja:DEFAULT': '',
  'leading_economic_index_final.description:ja:Japan':
    '日本では、総合先行指数は、在庫率や機械受注、株価、その他の経済先行指数など、12の指標から構成される。この指数は向こう数カ月間の景気の方向の変化を予測し、一般的に指標が上昇すれば景気が拡大期にあり、低下すれば縮小期にあることを示す。この指標は景気サイクルの開始時期と終了時期を公式に判断するために使われる。',
  'parliament_debate_on_brexit.description:ja:DEFAULT': '',
  'parliament_debate_on_brexit.description:ja:United Kingdom': '',
  'snb_zurbruegg_speech.description:ja:DEFAULT': '',
  'snb_zurbruegg_speech.description:ja:Switzerland': '',
  'manufacturing_sales_yoy.description:ja:DEFAULT': '',
  'manufacturing_sales_yoy.description:ja:Canada':
    'カナダでは、製造業売上高はカナダの製造業セクターによる製品売上高の特定時点での状況（スナップショット）であり、中・短期間におけるカナダ経済の状況と特定業界の状況の分析が可能である。',
  'manufacturing_sales_yoy.description:ja:New Zealand':
    'ニュージーランドでは製造業売上高は、同国経済の製造業セクターにおける売上高の前年比変化を示す。この指標には、（1）肉類・乳製品、（2）水産加工品、（3）果物・油・シリアル・その他食品、（3）飲料・タバコ、（5）繊維・革・衣類・履物、（6）材木・紙製品、（7）印刷、（8）石油・石炭製品、（9）化学品・ポリマー、ゴム製品、（10）非金属鉱物、（11）金属製品、（12）輸送機器・機械・装置および家具の12業界が含まれる。',
  'producer_import_prices_yoy.description:ja:DEFAULT': '',
  'producer_import_prices_yoy.description:ja:Switzerland':
    'スイスでは、生産者物価指数（PPI）は正式には「生産者および輸入物価指数」と呼ばれる。生産者物価指数はスイス国内で事業を行う企業が生産・販売する財の価格動向を測定し、輸入物価指数は輸入品の価格動向を測定する。これらの指数を合わせる際は、生産者物価指数では国内販売のみが考慮される。',
  'parliamentary_debate_vote_on_coronavirus_aid_package.description:ja:DEFAULT': '',
  'parliamentary_debate_vote_on_coronavirus_aid_package.description:ja:Canada': '',
  'parliamentary_debate_vote_on_part_of_brexit_deal.description:ja:DEFAULT': '',
  'parliamentary_debate_vote_on_part_of_brexit_deal.description:ja:United Kingdom': '',
  'parliamentary_debate_on_coronavirus_bill_day_1.description:ja:DEFAULT': '',
  'parliamentary_debate_on_coronavirus_bill_day_1.description:ja:United Kingdom': '',
  'standard_bank_pmi.description:ja:DEFAULT': '',
  'standard_bank_pmi.description:ja:South Africa':
    '南アフリカでは、IHSマークイット南アフリカ購買担当者景気指数（PMI）が、鉱業、製造業、サービス業、建設業、小売業などの民間セクターを代表する約400社のパネルから収集したデータを基に景気動向を追跡する。この指数は、新規受注、生産、雇用、サプライヤー納期、在庫、価格などの変数を追跡し、指数が50を超えれば全体的に企業活動が拡大していることを、50を切れば縮小していることを示す。',
  'parliamentary_vote_on_2019_budget.description:ja:DEFAULT': '',
  'parliamentary_vote_on_2019_budget.description:ja:Spain': '',
  'martin_luther_king_jr_day.description:ja:DEFAULT': '',
  'martin_luther_king_jr_day.description:ja:United States': '',
  'outstanding_loan_growth_yoy.description:ja:DEFAULT': '',
  'outstanding_loan_growth_yoy.description:ja:China':
    '中国では、融資残高伸び率は、金融機関が人民元建てで提供している融資合計額の前年比変化を追跡する。これには、新規株式公開や信託会社からの貸付、債券販売など、通常の銀行融資システムの外側に存在するオフバランス形式の融資も含まれる。',
  'parliamentary_vote_on_brexit_deal.description:ja:DEFAULT': '',
  'parliamentary_vote_on_brexit_deal.description:ja:United Kingdom': '',
  'boe_inflation_report.description:ja:DEFAULT': '',
  'boe_inflation_report.description:ja:United Kingdom':
    'イギリスの金融政策委員会（MPC）が基準利率を設定する。中央銀行であるイングランド銀行の公式金利はレポ金利である。このレポ金利は、イングランド銀行の取引相手（銀行、住宅金融組合、証券会社）に対する公開市場操作での利率となる。',
  'parliamentary_vote_on_no-deal_brexit.description:ja:DEFAULT': '',
  'parliamentary_vote_on_no-deal_brexit.description:ja:United Kingdom': '',
  'capital_spending_yoy_final.description:ja:DEFAULT': '',
  'capital_spending_yoy_final.description:ja:Japan':
    '日本では、設備投資は、資本金1,000万円以上の民間営利企業の設備投資額の前年比を指す。調査対象は、金融・保険業を除く全産業。',
  'private_loans_yoy.description:ja:DEFAULT': '',
  'private_loans_yoy.description:ja:Euro Area':
    'ユーロ圏では、民間部門向け融資は、ユーロ圏における非金融機関への調整後融資額である。',
  'parliamentary_votes_on_brexit_alternatives.description:ja:DEFAULT': '',
  'parliamentary_votes_on_brexit_alternatives.description:ja:United Kingdom': '',
  'caixin_composite_pmi.description:ja:DEFAULT': '',
  'caixin_composite_pmi.description:ja:China':
    '中国では、財新中国総合生産指数が民間セクターを代表する約400社のパネルから収集したデータを基に景気動向を追跡する。この指数は、売上高、新規受注、雇用、在庫、価格などの変数を追跡し、指数が50を超えれば全体的に企業活動が拡大していることを、50を切れば縮小していることを示す。',
  'fed_interest_rate_decision.description:ja:DEFAULT': '',
  'fed_interest_rate_decision.description:ja:United States':
    '米国では、金利設定の権限は連邦準備制度理事会（理事会）と連邦公開市場委員会（FOMC）に分けられている。理事会は、1つ以上の地区連邦準備銀行から提出された勧告を受けて、割引率の変更を決定する。FOMCは、中央銀行の資金量やフェデラルファンド市場金利などの公開市場操作を決定する。',
  'passover_begins.description:ja:DEFAULT': '',
  'passover_begins.description:ja:Israel': '',
  'monetary_base_yoy.description:ja:DEFAULT': '',
  'monetary_base_yoy.description:ja:Japan': '',
  'nonfarm_payrolls_qoq.description:ja:DEFAULT': '',
  'nonfarm_payrolls_qoq.description:ja:France': '',
  'inflation_expectations.description:ja:DEFAULT': '',
  'inflation_expectations.description:ja:Israel':
    'イスラエルでは、イスラエル銀行が調査するインフレ期待によって、向こう12カ月間における消費者の物価予想の中央値を測定する。',
  'ppi_output_qoq.description:ja:DEFAULT': '',
  'ppi_output_qoq.description:ja:New Zealand':
    'ニュージーランドでは、生産者物価指数（PPI）は、一定期間中に卸売市場で製造者や生産者が販売した財やサービスの価格の平均的な変化を測定する。',
  'passover_ends.description:ja:DEFAULT': '',
  'passover_ends.description:ja:Israel': '',
  'kansas_fed_manufacturing_index.description:ja:DEFAULT': '',
  'kansas_fed_manufacturing_index.description:ja:United States':
    'カンザスシティ連銀による第10地区の製造業月次調査は、ミズーリ州の西部3分の1、カンザス州、コロラド州、ネブラスカ州、オクラホマ州、ワイオミング州、およびニューメキシコ州の北半分までで構成される連邦準備第10地区の製造業現況に関する情報を提供する。この調査は地理的な分布状況、業種構成、規模を基に選ばれた約300の製造工場を対象とし、生産や出荷などの製造活動に関する複数の指標の変化を測定するとともに、原材料と完成品の価格変化を測定する。',
  'reteurs_econometer.description:ja:DEFAULT': '',
  'reteurs_econometer.description:ja:South Africa': '',
  'passover_ii.description:ja:DEFAULT': '',
  'passover_ii.description:ja:Israel': '',
  'tokyo_core_cpi_yoy.description:ja:DEFAULT': '',
  'tokyo_core_cpi_yoy.description:ja:Japan': '',
  'passover_ii_eve.description:ja:DEFAULT': '',
  'passover_ii_eve.description:ja:Israel': '',
  'treasury_refunding_announcement.description:ja:DEFAULT': '',
  'treasury_refunding_announcement.description:ja:United States': '',
  'bastille_day.description:ja:DEFAULT': '',
  'bastille_day.description:ja:France': '',
  'bof_liikanen_speech.description:ja:DEFAULT': '',
  'bof_liikanen_speech.description:ja:Finland': '',
  'investec_manufacturing_pmi.description:ja:DEFAULT': '',
  'investec_manufacturing_pmi.description:ja:Ireland':
    'AIB アイルランド製造業購買担当者景気指数（PMI）は製造業258社の調査に基づき、製造業の業況を測定する。製造業PMIはそれぞれウエート付けされた、新規受注（30%）、生産（25%）、雇用（20%）、サプライヤーの納期（15%）、購入品の在庫（10%）という5つの個別インデックスから構成され、納期インデックスは他のインデックスと同じ方向に動くよう符号を反転させる。50を超える指数は製造業セクターが前期に比べて拡大していることを、50を切る指数は縮小していることを、50は変化がないことを示す。',
  'pending_home_sales_yoy.description:ja:DEFAULT': '',
  'pending_home_sales_yoy.description:ja:United States':
    '住宅販売契約指数は、中古住宅の売買契約が成約したもののまだ取引が完了していない件数で、全国規模のサンプルに基づき、通常は中古住宅販売取引の約20％を代表する。指数値100は、この指数の調査が開始された2001年の契約件数平均水準に相当する。',
  'ura_property_index_qoq_final.description:ja:DEFAULT': '',
  'ura_property_index_qoq_final.description:ja:Singapore':
    'シンガポールでは、住宅指数は総合住宅不動産価格指数によって測定される。',
  'inflation_rate_yoy.description:ja:DEFAULT': '',
  'inflation_rate_yoy.description:ja:Thailand':
    'タイでは、消費者物価指数の中で最も重要なカテゴリーは、食品および非アルコール飲料（全ウエートの36％）、運輸・通信（24％）、そして住宅と家具（23％）で、その他には、医療・パーソナルケア（6％）、娯楽・教育（6％）、衣料・履物（3％）、タバコ・アルコール飲料（1％）が含まれる。',
  'gdp_growth_rate_yoy_2nd_est.description:ja:DEFAULT': '',
  'gdp_growth_rate_yoy_2nd_est.description:ja:Greece':
    'ギリシャでは家計消費がGDPの主な構成要素であり、その総支出の72％を占めており、次いで政府支出（20％）、総固定資本形成（12％）となっている。財・サービスの輸出はGDPの33%、輸入は35%であるため、GDP合計に2%のマイナス寄与となっている。',
  'gdp_growth_rate_yoy_2nd_est.description:ja:France':
    'フランスの経済は世界第7位、ユーロ圏では第2位の規模。フランスは、航空機、軍事機器、香水、医薬品、ワイン、牛肉、小麦の生産など、大規模かつ多様な工業・農業基盤を有している。少なくとも年間8,200万人の外国人観光客が訪れるフランスは、世界で最も観光客の多い国であり、観光業による収入は世界第3位を維持している。経済の最大部門はサービス業（GDP全体の79％）で、工業は19％。農業のGDPへの寄与度は2%だが、フランスは世界第2位の農産物の輸出国である。',
  'gdp_growth_rate_yoy_2nd_est.description:ja:Euro Area':
    'ユーロ圏は世界で2番目に大きな経済圏である。加盟している19カ国のうち、最も大きいのはドイツ（GDP合計の29％）、フランス（20％）、イタリア（15％）、スペイン（10％）である。支出面では家計消費がGDPの主な構成要素で、総支出の54％を占めており、次いで総固定資本形成（21％）、政府支出（20％）となっている。財・サービスの輸出はGDPの47%、輸入は43%であるため、GDP全体に4%寄与している。',
  'gdp_growth_rate_yoy_2nd_est.description:ja:Belgium':
    'ベルギーでは家計消費がGDPの主な構成要素であり、その総支出の52％を占めており、次いで政府支出（25％）、総固定資本形成（23％）となっている。財・サービスの輸出はGDPの84%である一方、輸入は83%であるため、GDP合計に1%のマイナス寄与となっている。',
  'gdp_growth_rate_yoy_2nd_est.description:ja:Czech Republic':
    'チェコ共和国では家計消費がGDPの主な構成要素であり、その総支出の49％を占めており、次いで総固定資本形成（25％）、政府支出（19％）となっている。財・サービスの輸出はGDPの84%である一方、輸入は77%であるため、GDP合計に７%のプラス寄与となっている。',
  'gdp_growth_rate_yoy_2nd_est.description:ja:United Kingdom':
    'イギリスの経済規模は、世界第6位、欧州ではドイツに次ぐ第2位である。サービス業が最も重要で、GDP全体の79％を占めている。サービス業の中で最も大きな分野は、政府、教育、医療（GDP全体の19％）、不動産（12％）、専門的・科学的・技術的活動および行政・支援サービス（12％）、卸売・小売業（11％）、金融・保険（8％）である。工業分野はGDPの21％を占め、この分野の最大のセグメントは製造業（GDP全体の10％）と建設業（6％）である。農業は、GDPのわずか1%を占めるにすぎない。',
  'gdp_growth_rate_yoy_2nd_est.description:ja:Russia':
    'ロシアは世界有数の石油・天然ガスの生産国であり、鉄鋼や一次アルミニウムなどの金属の輸出国でもある。生産面では、サービス業が経済の最大セクターであり、GDPの58％を占めている。サービス業の中で最も重要な分野は、卸売・小売（GDP合計の17％）、行政・医療・教育（12％）である。工業は総生産の40％、農業は残りの2％。',
  'gdp_growth_rate_yoy_2nd_est.description:ja:Netherlands':
    'オランダ経済はユーロ圏で第6位、ヨーロッパの重要な交通拠点である。オランダ経済は外国貿易に大きく依存しており、輸出はGDPの83％、輸入は72％を占める。GDPの主な構成要素は家計消費（45％）で、次いで政府支出（26％）、総固定資本形成（18％）、純輸出（11％）となっている。',
  'gdp_growth_rate_yoy_2nd_est.description:ja:Hungary':
    'ハンガリーでは、電子機器の製造と研究などがイノベーションと経済成長の主な原動力である。また、モバイル技術、情報セキュリティ、および関連するハードウェア研究の主要な中心地としても成長している。支出面では、家計消費がGDPの主な構成要素として全体の50%を占め、総固定資本形成（22％）、政府支出（20％）と続く。財・サービスの輸出はGDPの89%を占め、輸入は82%であるため、GDP全体に7%寄与している。',
  'gdp_growth_rate_yoy_2nd_est.description:ja:Poland':
    'ポーランドの消費者市場は3,800万人と、ヨーロッパでも最大級の規模を誇る。ポーランドの主要産業は、食品・飲料加工、造船、機械・鉄鋼製品・化学品・ガラス・繊維製品の製造である。支出面では、家計消費がGDPの60％を占めており、次いで総固定資本形成（20％）、政府支出（18％）である。財・サービスの輸出はGDPの47％を占め、輸入は46％で、GDP全体に1％寄与している。',
  'gdp_growth_rate_yoy_2nd_est.description:ja:Portugal':
    '支出面では、家計消費がGDPの主な構成要素であり、その合計支出の66％を占めている。続いて、政府支出（19％）、総固定資本形成（15％）となっている。財・サービスの輸出はGDPの40％、輸入も40％を占める。',
  'boe_governor_king_speech.description:ja:DEFAULT': '',
  'boe_governor_king_speech.description:ja:United Kingdom': '',
  'cnb_press_conference.description:ja:DEFAULT': '',
  'cnb_press_conference.description:ja:Czech Republic': '',
  'pentecost_shavuot.description:ja:DEFAULT': '',
  'pentecost_shavuot.description:ja:Israel': '',
  'pentecost_shavuot_eve.description:ja:DEFAULT': '',
  'pentecost_shavuot_eve.description:ja:Israel': '',
  'pentecost_monday.description:ja:DEFAULT': '',
  'pentecost_monday.description:ja:Switzerland': '',
  'pentecost_monday.description:ja:Norway': '',
  'pentecost_monday.description:ja:Netherlands': '',
  'pentecost_monday.description:ja:Hungary': '',
  'pentecost_monday.description:ja:Germany': '',
  'pentecost_monday.description:ja:Austria': '',
  'pentecost_monday.description:ja:Denmark': '',
  'pentecost_monday.description:ja:Belgium': '',
  'pentecost_monday.description:ja:France': '',
  'people_power_revolution.description:ja:DEFAULT': '',
  'people_power_revolution.description:ja:Philippines': '',
  'nonfarm_productivity_qoq.description:ja:DEFAULT': '',
  'nonfarm_productivity_qoq.description:ja:United States':
    '米国では、非農業部門労働者の生産性は1労働時間当たりの財・サービス生産量で測定される。労働生産性は、実質生産指数を、被雇用者、経営者、無給の家族従事者を含むすべての人の労働時間指数で除して算出する。',
  'inflation_rate_mom_prel.description:ja:DEFAULT': '',
  'inflation_rate_mom_prel.description:ja:Russia':
    '前月比インフレ率は、財とサービスの価格の前月比変化を測定する。',
  'inflation_rate_mom_prel.description:ja:France':
    'フランスでは、消費者物価指数の中で最も重要なカテゴリーはサービス（全ウエートの48％）で、特に住居の賃料とサービス（7％）、医療サービス（6％）、運輸（3％）、通信（2％）が重要である。また、同指数には、医療製品（4％）や衣料品・履物（4％）などの製造品（26％）、食料（16％）、石油製品（4％）などのエネルギー（8％）、タバコ（2％）も含まれる。',
  'inflation_rate_mom_prel.description:ja:Spain':
    '前月比インフレ率は、財とサービスの価格の前月比変化を測定する。',
  'inflation_rate_mom_prel.description:ja:Germany':
    'ドイツでは、消費者物価指数の中で最も重要なカテゴリーは、住居・水道・電気・ガスその他燃料（全ウエートの32％）、運輸（13％）、娯楽・エンターテインメント・文化（11％）、食品・非アルコール飲料（10％）で、また、同指数には、雑貨・サービス（7％）、家具・照明機器・家電・その他住宅備品（5％）、レストラン・ホテル（5％）、医療（5％）、衣料品・履物（5％）が含まれ、残りの7％はアルコール飲料、タバコ、通信、教育である。',
  'inflation_rate_mom_prel.description:ja:Poland':
    'ポーランドでは、消費者物価指数の中で最も重要なカテゴリーは、食品および非アルコール飲料（全ウエートの25％）、住居・水道・電気その他燃料（19％）、運輸（10％）、娯楽・文化（6％）、アルコール飲料・タバコ（6％）、レストラン・ホテル（6％）、家具・住宅備品、通常の住居維持（6％）、その他の財およびサービス（6％）で、残りの15％は、医療、衣料品と履物、通信、教育である。',
  'inflation_rate_mom_prel.description:ja:Portugal':
    '前月比インフレ率は、財とサービスの価格の前月比変化を測定する。',
  'inflation_rate_mom_prel.description:ja:Italy':
    'イタリアでは、消費者物価指数の中で最も重要なカテゴリーは、食品および非アルコール飲料（全ウエートの16％）、運輸（15％）、レストラン・ホテル（12％）、住居・水道・電気その他燃料（10％）、その他の財およびサービス（10％）である。また、同指数には、医療（9％）、娯楽・文化（8％）、衣料品・履物（7％）、家具と住宅備品（7％）も含まれ、残りの6％はアルコール飲料、タバコ、通信、教育である。',
  'export_price_index_qoq.description:ja:DEFAULT': '',
  'export_price_index_qoq.description:ja:Australia': '',
  'personal_spending_mom.description:ja:DEFAULT': '',
  'personal_spending_mom.description:ja:United States':
    '個人消費支出（PCE）は米国経済における財とサービスの消費支出を測る主要指標である。1 これは国内の最終支出の約3分の2を占めるため、その後の経済成長の主要なけん引役となる。PCEは、家計が得た所得のうちどれだけが現在の消費に費やされ、一方でどれだけが将来の消費のために貯蓄されているのかを示す。PCEはまた、家計が購入する財やサービスのタイプに関しても総合的な指標を示す。従って、自動車などの裁量項目が占める支出の割合や、ガソリン価格の高騰など物価変動に応じて消費者がどう調整しているかなどを示す。',
  'coincident_index.description:ja:DEFAULT': '',
  'coincident_index.description:ja:Japan': '',
  'coincident_index.description:ja:Thailand': '',
  'philadelphia_fed_plosser_speech.description:ja:DEFAULT': '',
  'philadelphia_fed_plosser_speech.description:ja:United States': '',
  'portfolio_investment_in_foreign_securities.description:ja:DEFAULT': '',
  'portfolio_investment_in_foreign_securities.description:ja:Canada': '',
  'battle_of_boyaca_day.description:ja:DEFAULT': '',
  'battle_of_boyaca_day.description:ja:Colombia': '',
  'non_farm_payrolls_qoq_final.description:ja:DEFAULT': '',
  'non_farm_payrolls_qoq_final.description:ja:France':
    'フランスでは、雇用者数とは、雇用期間に関係なく四半期最終日の雇用者数を示す。すべてのセクターとすべてのフランス領土が含まれている（マヨットは除く）。',
  'fed_lockhart_speech.description:ja:DEFAULT': '',
  'fed_lockhart_speech.description:ja:United States': '',
  'portugal_day.description:ja:DEFAULT': '',
  'portugal_day.description:ja:Portugal': '',
  'ny_empire_state_manufacturing_index.description:ja:DEFAULT': '',
  'ny_empire_state_manufacturing_index.description:ja:United States':
    'ニューヨーク連銀製造業景気指数はニューヨーク州の製造業企業からの約200人の経営幹部を対象に、全体的な事業活動の水準、新規受注、出荷、在庫、雇用者数、納期、設備投資など11項目について前月からの変化と今後6カ月それらが向かうと思われる方向について調査するもので、ゼロを超える数値は景気拡大を、ゼロ未満の数値は縮小を示す。',
  'nikkei_services_pmi.description:ja:DEFAULT': '',
  'nikkei_services_pmi.description:ja:Japan':
    '日本のサービス業（PMI）は、日本の民間サービス業を代表する400社を超える企業のパネルから収集したデータを基に集計される。指数は、売上高、雇用、在庫、価格などの変数を追跡し、50を超えれば、サービス業が全体的に拡大傾向にあることを示し、50を下回れば、全般に縮小傾向にあることを示す。',
  'nikkei_services_pmi.description:ja:India':
    'IHSマークイット・インドのサービス業購買担当者景気指数（PMI）は、約350社の民間企業の購買担当幹部から収集したアンケート調査の回答を基に集計され、売上高、雇用、在庫、価格などの変数を追跡する。指数が50を超えれば全体的にサービス業が拡大していることを、50を切れば縮小していることを示す。',
  'official_foreign_reserves.description:ja:DEFAULT': '',
  'official_foreign_reserves.description:ja:India': '',
  'bataan_day.description:ja:DEFAULT': '',
  'bataan_day.description:ja:Philippines': '',
  'new_orders.description:ja:DEFAULT': '',
  'new_orders.description:ja:Sweden':
    'スウェーデンでは、新規受注が企業の景況感に大きく影響するため、国内総生産成長率の先行指標となる。この統計は登録されたサンプルを集計し、月次ベースで新規受注の短期的変化と産業界の売上高を、総計および業界別、さらには国内市場と輸出市場別に測定する。この調査は他の経済指標調査とともに実施される。',
  'referendum_on_affordable_housing.description:ja:DEFAULT': '',
  'referendum_on_affordable_housing.description:ja:Switzerland': '',
  'new_orders_manufacturing_yoy.description:ja:DEFAULT': '',
  'new_orders_manufacturing_yoy.description:ja:Sweden': '',
  'ecb_macroprudential_policy_and_research_conference.description:ja:DEFAULT': '',
  'ecb_macroprudential_policy_and_research_conference.description:ja:Euro Area': '',
  'new_year_s_holiday.description:ja:DEFAULT': '',
  'new_year_s_holiday.description:ja:Russia': '',
  'russia_day.description:ja:DEFAULT': '',
  'russia_day.description:ja:Russia': '',
  'president_trump_press_conference.description:ja:DEFAULT': '',
  'president_trump_press_conference.description:ja:United States': '',
  'chancellor_sunak_statement_to_parliament.description:ja:DEFAULT': '',
  'chancellor_sunak_statement_to_parliament.description:ja:United Kingdom': '',
  'fed_bullard_speech.description:ja:DEFAULT': '',
  'fed_bullard_speech.description:ja:Euro Area':
    'ユーロ圏の基準金利は欧州中央銀行（ECB）の理事会によって決定される。ECBの金融政策の主な目的は、物価の安定を維持することであり、それはインフレ率を中期的に2％未満でかつそれに近い水準に抑えることである。低インフレ・低金利が長期化した場合、ECBは資産購入プログラムなどの非標準的な金融政策を採用することもある。公式金利は、主要リファイナンスオペ金利である。。',
  'fed_bullard_speech.description:ja:United States':
    '米国では、金利設定の権限は連邦準備制度理事会（理事会）と連邦公開市場委員会（FOMC）に分けられている。理事会は、1つ以上の地区連邦準備銀行から提出された勧告を受けて、割引率の変更を決定する。FOMCは、中央銀行の資金量やフェデラルファンド市場金利などの公開市場操作を決定する。',
  'president_trump_tax_speech.description:ja:DEFAULT': '',
  'president_trump_tax_speech.description:ja:United States': '',
  'prospective_plantings_-_soy.description:ja:DEFAULT': '',
  'prospective_plantings_-_soy.description:ja:United States': '',
  'president_s_day.description:ja:DEFAULT': '',
  'president_s_day.description:ja:United States': '',
  'auto_exports_yoy.description:ja:DEFAULT': '',
  'auto_exports_yoy.description:ja:Mexico':
    'メキシコでは、自動車輸出台数は、車両総輸出台数を表す。',
  'labour_holiday.description:ja:DEFAULT': '',
  'labour_holiday.description:ja:Russia': '',
  'pm_johnson_announcement_on_coronavirus.description:ja:DEFAULT': '',
  'pm_johnson_announcement_on_coronavirus.description:ja:United Kingdom': '',
  'gdp_growth_rate_qoq-_second_estimate.description:ja:DEFAULT': '',
  'gdp_growth_rate_qoq-_second_estimate.description:ja:Poland':
    'ポーランドの経済規模は欧州連合で8番目でありながら、人口1人当たりのGDPはEU平均を大きく下回る。同国の産業基盤は石炭、繊維、化学品、機械、鉄、鉄鋼セクターであり、近年は肥料、石油化学、工作機械、電子機械、エレクトロニクス、自動車、造船にも広がっている。支出面では、家計消費がGDPの主要コンポーネントで、全体の60％を占め、次いで総固定資本形成（20％）と政府支出（18％）が続く。財とサービスの輸出はGDP全体の47％を占め、輸入は46％でGDP全体に1％の寄与となっている。',
  'presidential_election_-_2nd_round.description:ja:DEFAULT': '',
  'presidential_election_-_2nd_round.description:ja:Brazil': '',
  'conference_call_on_emergency_lending_to_greek_banks.description:ja:DEFAULT': '',
  'conference_call_on_emergency_lending_to_greek_banks.description:ja:Euro Area': '',
  'svme_manufacturing_pmi.description:ja:DEFAULT': '',
  'svme_manufacturing_pmi.description:ja:Switzerland':
    'スイスの procure.ch製造業購買担当者景気指数（PMI）は、製造業の業況を測定し、翌月の購買予想に関する企業幹部へのアンケート結果に基づいて集計される。製造業PMIは、それぞれウエート付けされた、新規受注（30%）、生産（25%）、雇用（20%）、サプライヤー納期（15%）、購買品在庫（10%）という5つのサブインデックスから構成される。納期インデックスは他のインデックスと同じ方向に動くよう符号を反転させる。50を超える指数は製造業セクターが前期に比べて拡大していることを、50を切る指数は縮小していることを、50は変化がないことを示す。',
  'korean_new_year.description:ja:DEFAULT': '',
  'korean_new_year.description:ja:South Korea': '',
  'deposit_growth_yoy.description:ja:DEFAULT': '',
  'deposit_growth_yoy.description:ja:India':
    'インドでは、預金残高成長率は商業銀行預金残高の前年比変化を示す。',
  'markit_manufacturing_pmi_final.description:ja:DEFAULT': '',
  'markit_manufacturing_pmi_final.description:ja:Australia':
    'IHSマークイット製造業PMIは、オーストラリアの民間製造業400社以上の購買担当幹部を代表するパネルに送られたアンケートへの毎月の回答を編纂したデータに基づいている。このパネルは、GDPと企業の従業員規模によって階層に分けられている。製造業は以下の9つのカテゴリーに分類される：食品・飲料、繊維・衣料、木材・紙、化学・プラスチック・ゴム、金属・金属製品、電子・電気機器、機械・装置、輸送機器、その他製造業。数値が50を超えると、製造業が前月に比べて拡大していることを示し、50を下回ると縮小、50は変化がないことを示す。',
  'markit_manufacturing_pmi_final.description:ja:Germany':
    'マークイット/BMEドイツ製造業購買担当者景気指数（PMI）は製造業500社の調査に基づき、製造業の業況を測定し、それぞれウエート付けされた、新規受注（30%）、生産（25%）、雇用（20%）、サプライヤー納期（15%）、購買品在庫（10%）という5つの個別インデックスから構成され、納期インデックスは他のインデックスと同じ方向に動くよう符号が反転される。50を超える指数は製造業セクターが前期に比べて拡大していることを、50を切る指数は縮小していることを、50は変化がないことを示す。',
  'markit_manufacturing_pmi_final.description:ja:France':
    'フランスでは、マークイット製造業購買担当者景気指数（PMI）は製造業400社の調査に基づき、製造業の業況を測定し、それぞれウエート付けされた、新規受注（30%）、生産（25%）、雇用（20%）、サプライヤー納期（15%）、購買品在庫（10%）という5つの個別インデックスから構成され、納期インデックスは他のインデックスと同じ方向に動くよう符号が反転される。50を超える指数は製造業セクターが前期に比べて拡大していることを、50を切る指数は縮小していることを、50は変化がないことを示す。',
  'markit_manufacturing_pmi_final.description:ja:Euro Area':
    'ユーロ圏では、マークイット製造業購買担当者景気指数（PMI）は製造業3.000社の調査に基づき、製造業の業況を測定する。ドイツ、フランス、イタリア、スペイン、オーストリア、オランダ、ギリシャ、アイルランドの全国データが含まれており、これらの国を合わせると、ユーロ圏製造業の90％を占めると推定される。製造業PMI製造業は、それぞれウエート付けされた、新規受注（30%）、生産高（25%）、雇用（20%）、サプライヤー納期（15%）、購買品在庫（10%）という5つの個別インデックスから構成され、納期インデックスは他のインデックスと同じ方向に動くよう符号が反転される。50を超える指数は製造業セクターが前期に比べて拡大していることを、50を切る指数は縮小していることを、50は変化がないことを示す。',
  'markit_manufacturing_pmi_final.description:ja:United States':
    '米国では、マークイット製造業購買担当者景気指数（PMI）は製造業600社の調査に基づき、製造業の業況を測定し、それぞれウエート付けされた、新規受注（30%）、生産（25%）、雇用（20%）、サプライヤー納期（15%）、購買品在庫（10%）という5つの個別インデックスから構成され、納期インデックスは他のインデックスと同じ方向に動くよう符号が反転される。50を超える指数は製造業セクターが前期に比べて拡大していることを、50を切る指数は縮小していることを、50は変化がないことを示す。',
  'niesr_gdp_est_3m.description:ja:DEFAULT': '',
  'niesr_gdp_est_3m.description:ja:United Kingdom': '',
  'presidential_elections_2nd_round.description:ja:DEFAULT': '',
  'presidential_elections_2nd_round.description:ja:Colombia': '',
  'ism_manufacturing_new_orders.description:ja:DEFAULT': '',
  'ism_manufacturing_new_orders.description:ja:United States':
    'ISM製造業景気指数（Manufacturing ISM Report On Business®）は、全国の購買および供給担当幹部からのアンケート調査回答に基づいて集計され、前月と比較した当月の変化があればそれが反映される。新規受注、受注残、新規輸出受注、輸入、生産、サプライヤー納期、在庫、顧客在庫、雇用、価格の各指標について、景気がよくなるとした回答数と悪くなると回答した回答数との差、それぞれの回答の割合、およびディフュージョン・インデックス（DI）を示す。50％を上回るPMI®は製造業の景気が全体的に拡大していることを、50％を切る場合は縮小していることを示す。',
  'prime_minister_johnson_testimony.description:ja:DEFAULT': '',
  'prime_minister_johnson_testimony.description:ja:United Kingdom': '',
  'prime_minister_may_speech.description:ja:DEFAULT': '',
  'prime_minister_may_speech.description:ja:United Kingdom': '',
  'ecb_annual_research_conference.description:ja:DEFAULT': '',
  'ecb_annual_research_conference.description:ja:Euro Area': '',
  'prime_minister_may_speech_on_brexit.description:ja:DEFAULT': '',
  'prime_minister_may_speech_on_brexit.description:ja:United Kingdom': '',
  'prime_minister_narendra_modi_speech.description:ja:DEFAULT': '',
  'prime_minister_narendra_modi_speech.description:ja:India': '',
  'producer_and_import_prices_yoy.description:ja:DEFAULT': '',
  'producer_and_import_prices_yoy.description:ja:Switzerland': '',
  'gdp_growth_annualized_qoq.description:ja:DEFAULT': '',
  'gdp_growth_annualized_qoq.description:ja:Israel': '',
  'gdp_growth_annualized_qoq.description:ja:Japan': '',
  'ecb_economic_bulletin.description:ja:DEFAULT': '',
  'ecb_economic_bulletin.description:ja:Euro Area':
    'ユーロ圏の基準金利は欧州中央銀行（ECB）の理事会によって決定される。ECBの金融政策の主な目的は、物価の安定を維持することであり、それはインフレ率を中期的に2％未満でかつそれに近い水準に抑えることである。低インフレ・低金利が長期化した場合、ECBは資産購入プログラムなどの非標準的な金融政策を採用することもある。公式金利は、主要リファイナンスオペ金利である。。',
  'prime_minister_trudeau_speech.description:ja:DEFAULT': '',
  'prime_minister_trudeau_speech.description:ja:Canada': '',
  'parliamentary_vote_on_brexit_amendments.description:ja:DEFAULT': '',
  'parliamentary_vote_on_brexit_amendments.description:ja:United Kingdom': '',
  'prime_overdraft_rate.description:ja:DEFAULT': '',
  'prime_overdraft_rate.description:ja:South Africa':
    '南アフリカでは貸出金利は商業銀行のプライムレートを指し、レポ金利に3.5ベーシスポイントを上乗せしたベンチマーク金利であり、各銀行はベンチマーク金利を基準として顧客への貸出金利を決定する。',
  'construction_work_done_qoq.description:ja:DEFAULT': '',
  'construction_work_done_qoq.description:ja:Australia':
    'オーストラリアでは、建設工事完工高は、官民双方のセクターにおける建設工事完工高の前四半期比変化を示し、建設とエンジニアリングが含まれる。',
  'terms_of_trade_qoq.description:ja:DEFAULT': '',
  'terms_of_trade_qoq.description:ja:New Zealand':
    'ニュージーランドでは、交易条件（ToT）は輸入可能品価格に対する輸出可能品価格の比率を示す。',
  'interest_rate_decison.description:ja:DEFAULT': '',
  'interest_rate_decison.description:ja:Poland':
    'ポーランドでは金利決定はポーランド国立銀行（NBP）が行い、政策金利はレファレンス金利である。',
  'interest_rate_decison.description:ja:Hungary': '',
  'interest_rate_decison.description:ja:Mexico':
    'メキシコでは金利決定はメキシコ銀行（(Banco de México）が行う。2008年１月以来、商業銀行の中銀における当座預金残高水準（CORTO）に代えて翌日物銀行間金利を金利誘導目標としている。',
  'restoration_of_independence.description:ja:DEFAULT': '',
  'restoration_of_independence.description:ja:Portugal': '',
  'norges_bank_olsen_speech.description:ja:DEFAULT': '',
  'norges_bank_olsen_speech.description:ja:Norway': '',
  'priv_new_capital_exp_qoq.description:ja:DEFAULT': '',
  'priv_new_capital_exp_qoq.description:ja:Australia': '',
  'trading_holiday.description:ja:DEFAULT': '',
  'trading_holiday.description:ja:Indonesia': '',
  'private_capital_expenditure.description:ja:DEFAULT': '',
  'private_capital_expenditure.description:ja:Australia': '',
  'bank_of_canada_consumer_price_index_core_mom.description:ja:DEFAULT': '',
  'bank_of_canada_consumer_price_index_core_mom.description:ja:Canada': '',
  'ninoy_aquino_day.description:ja:DEFAULT': '',
  'ninoy_aquino_day.description:ja:Philippines': '',
  'private_non_farm_payrolls_qoq_final.description:ja:DEFAULT': '',
  'private_non_farm_payrolls_qoq_final.description:ja:France':
    'フランスでは、民間セクタ―の雇用者数は、雇用期間に関係なく四半期最終日の雇用者数を示す。全セクターの雇用者数が推定され、2010年第3四半期まではフランス本土のみのデータが集計されていたが、2010年第4四半期以降はフランス領土すべてが含まれている（マヨットは除く）。',
  'inflation_rate_qoq.description:ja:DEFAULT': '',
  'inflation_rate_qoq.description:ja:Australia':
    '前月比インフレ率は、財とサービスの価格の前月比変化を測定する。',
  'inflation_rate_qoq.description:ja:New Zealand':
    '前月比インフレ率は、財とサービスの価格の前月比変化を測定する。',
  'holi.description:ja:DEFAULT': '',
  'holi.description:ja:India': '',
  'mortgage_approvals.description:ja:DEFAULT': '',
  'mortgage_approvals.description:ja:United Kingdom':
    '住宅ローン承認件数は、顧客に対し、特定の住宅を担保として与信を提供する融資行からのk確約を指す。これは住宅不動産の第一抵当権で全額担保される融資の承認件数であり、既存の住宅ローンを別の不動産に移転する既存の借入人への融資、初めて住宅を購入する借入人への融資、および賃貸用家屋の購入（Buy-to-Let）のための融資が含まれる。',
  'lending_facility_rate.description:ja:DEFAULT': '',
  'lending_facility_rate.description:ja:Indonesia':
    'インドネシアでは貸出ファシリティー金利は中央銀行による貸出ファシリティ金利を指す。',
  'ipca_mid-month_cpi_yoy.description:ja:DEFAULT': '',
  'ipca_mid-month_cpi_yoy.description:ja:Brazil': '',
  'banrep_meeting_minutes.description:ja:DEFAULT': '',
  'banrep_meeting_minutes.description:ja:Colombia': '',
  'imf_lagarde_speech.description:ja:DEFAULT': '',
  'imf_lagarde_speech.description:ja:Austria': '',
  'imf_lagarde_speech.description:ja:Germany': '',
  'private_sector_credit_yoy.description:ja:DEFAULT': '',
  'private_sector_credit_yoy.description:ja:South Africa':
    '民間セクター与信は、国内民間セクターへの銀行融資の前年比変化を測定する。',
  'private_sector_credit_yoy.description:ja:Australia':
    'オーストラリアでは、民間セクター与信は、住宅ローン、個人ローン、企業ローンなど経済の民間部門への与信残高の前月比増減を指し、証券化も含まれる。',
  'current_account_final.description:ja:DEFAULT': '',
  'current_account_final.description:ja:Ireland':
    '経常収支とは、貿易収支（物品・サービスの輸出額から輸入額を差し引いたもの）、純要素所得（利子や配当金など）、純移転支出（対外援助など）の合計である。',
  'current_account_final.description:ja:Hong Kong':
    '経常収支とは、貿易収支（物品・サービスの輸出額から輸入額を差し引いたもの）、純要素所得（利子や配当金など）、純移転支出（対外援助など）の合計である。',
  'uk_pm_statement_on_brexit.description:ja:DEFAULT': '',
  'uk_pm_statement_on_brexit.description:ja:United Kingdom': '',
  'wage_price_index_qoq.description:ja:DEFAULT': '',
  'wage_price_index_qoq.description:ja:Australia':
    'オーストラリアでは、賃金の伸びは、官民双方セクターにおける賞与を除く賃金の時給の年次変化を測定する。',
  'midsummer_day.description:ja:DEFAULT': '',
  'midsummer_day.description:ja:Sweden': '',
  'midsummer_day.description:ja:Finland': '',
  'markit_adaci_services_pmi.description:ja:DEFAULT': '',
  'markit_adaci_services_pmi.description:ja:Italy':
    'マークイット/ADACIイタリアのサービス業購買担当者景気指数（PMI）は、イタリアのサービス業を代表する約400社のパネルから収集したデータに基づき、売上高、雇用、在庫、価格などの変数を追跡する。指数が50を超えれば全体的にサービス業が拡大していることを、50を切れば縮小していることを示す。',
  'industrial_capacity_utilization.description:ja:DEFAULT': '',
  'industrial_capacity_utilization.description:ja:China':
    '中国では、工場稼働率は生産能力と実際の稼働状況との差を示し、労働力と資本が最も良く活用され、実際の生産量が生産能力に近い時に、稼働率は高くなる。調査対象は約9万社。',
  'producer_import_prices_mom.description:ja:DEFAULT': '',
  'producer_import_prices_mom.description:ja:Switzerland':
    'スイスでは、生産者物価指数（スイス国内で事業を行う企業が生産・販売する財の価格動向を測定）と輸入物価指数（輸入品の価格動向を測定）をまとめて幅広い財をカバーする1つの物価指数としており、まとめる際は生産者物価指数の国内販売のみが考慮される。',
  'rba_commodity_index_sdr_yoy.description:ja:DEFAULT': '',
  'rba_commodity_index_sdr_yoy.description:ja:Australia': '',
  'producer_price_index.description:ja:DEFAULT': '',
  'producer_price_index.description:ja:South Africa':
    '南アフリカでは、生産者物価指数（PPI）は、一定期間中に卸売市場で製造者や生産者が販売した財やサービスの価格の平均的な変化を測定する。',
  'producer_price_index_ex_food_energy_mom.description:ja:DEFAULT': '',
  'producer_price_index_ex_food_energy_mom.description:ja:United States': '',
  'jibun_bank_services_pmi_final.description:ja:DEFAULT': '',
  'jibun_bank_services_pmi_final.description:ja:Japan':
    '日本のサービス業（PMI）は、日本の民間サービス業を代表する400社を超える企業のパネルから収集したデータを基に集計される。指数は、売上高、雇用、在庫、価格などの変数を追跡し、50を超えれば、サービス業が全体的に拡大傾向にあることを示し、50を下回れば、全般に縮小傾向にあることを示す。',
  'money_supply_m2.description:ja:DEFAULT': '',
  'money_supply_m2.description:ja:Czech Republic':
    'チェコ共和国のマネーサプライM2は、M1に銀行の短期定期預金を加えたもので、2016年にチェコ中央銀行は主要なマネーサプライ指標を、従来使用していた国内定義によるM2合計から、欧州中央銀行制度の手法に関する指針に沿って集計された調和マネタリー統計下の調和M3マネーサプライに変更した。',
  'ppi_exl_food_and_energy_mom.description:ja:DEFAULT': '',
  'ppi_exl_food_and_energy_mom.description:ja:United States': '',
  'ppi_exl_food_and_energy_yoy.description:ja:DEFAULT': '',
  'ppi_exl_food_and_energy_yoy.description:ja:United States': '',
  'president_biden_speech.description:ja:DEFAULT': '',
  'president_biden_speech.description:ja:United States': '',
  'machinery_orders_mom.description:ja:DEFAULT': '',
  'machinery_orders_mom.description:ja:Japan':
    '日本では、機械受注とは、変動の大きい船舶向けや電力会社向けを除いた、民間部門の機械受注額の前月比での変化を指す。',
  'public_sector_net_borrowing.description:ja:DEFAULT': '',
  'public_sector_net_borrowing.description:ja:United Kingdom':
    '英国では、公共セクターの銀行を除く英国公共部門純借入額（PSNB ex）で公共部門の収入（歳入）と支出合計（歳出に純投資【資本支出から資本収入を差し引いた額】）との差額が測定され、公共部門純借入額（PSNB）はしばしば「赤字」とメディアで呼ばれる。',
  'nikkei_manufacturing_pmi_flash.description:ja:DEFAULT': '',
  'nikkei_manufacturing_pmi_flash.description:ja:Japan':
    'じぶん銀行日本製造業購買担当者景況指数（PMI）®は、約400社の製造業企業の購買管理者に送信されたアンケート調査の結果をIHSマークイットが毎月集計するもので、対象企業はGDPへの寄与度に基づき、詳細セクターと企業の社員数によって階層化さている。アンケートの回答は前月に比べた変化の方向を示し、各調査変数に関してディフュージョンインデックス（DI）が算出される。同指数は「改善」と回答した割合（％）に、「横ばい」と回答した割合の半分を加算して算出する。指数の値は0～50の間で、50を超える場合は全体的に前月からの改善・増加を、50未満の場合は悪化・減少を表す。このPMIは、新規受注（50％）、生産（25％）、雇用（20％）、サプライヤー納期（15％）、購買品在庫（10％）の5つの指数の加重平均である。PMIの算出においてはサプライヤー納期インデックスは他のインデックスと同じ方向に動くよう、符号を反転させている。',
  'president_trump_state_of_the_union_speech.description:ja:DEFAULT': '',
  'president_trump_state_of_the_union_speech.description:ja:United States': '',
  'oenb_gov_nowotny_speech.description:ja:DEFAULT': '',
  'oenb_gov_nowotny_speech.description:ja:Austria': '',
  'purchasing_manager_index_manufacturing.description:ja:DEFAULT': '',
  'purchasing_manager_index_manufacturing.description:ja:Poland': '',
  'purchasing_manager_index_manufacturing.description:ja:Ireland': '',
  'shmini_atzeret_simchat_torah.description:ja:DEFAULT': '',
  'shmini_atzeret_simchat_torah.description:ja:Israel': '',
  'building_permits_mom_prel.description:ja:DEFAULT': '',
  'building_permits_mom_prel.description:ja:Australia':
    'オーストラリアでは、建設許可数はが既存の建造物における活動も含め、月間の許可された住居数を示している。',
  'business_nz_pmi.description:ja:DEFAULT': '',
  'business_nz_pmi.description:ja:New Zealand':
    'ビジネスNZ製造業購買担当者景気指数（PMI）は生産、新規受注、納入、在庫、雇用の各ディフュージョン指数を基にした複合指数である。指数が50を超えると製造業が前月に比べて拡大していることを、50を切ると縮小していることを、50は変化がないことを示す。',
  'purchasing_managers_index.description:ja:DEFAULT': '',
  'purchasing_managers_index.description:ja:Singapore': '',
  'the_prophet_muhammads_birthday.description:ja:DEFAULT': '',
  'the_prophet_muhammads_birthday.description:ja:Indonesia': '',
  'ganesh_chaturthi.description:ja:DEFAULT': '',
  'ganesh_chaturthi.description:ja:India': '',
  '5-year_treasury_gilt_auction.description:ja:DEFAULT': '',
  '5-year_treasury_gilt_auction.description:ja:United Kingdom': '',
  'boe_stress_test_results.description:ja:DEFAULT': '',
  'boe_stress_test_results.description:ja:United Kingdom': '',
  'remembrance_day.description:ja:DEFAULT': '',
  'remembrance_day.description:ja:Canada': '',
  'qe_mbs.description:ja:DEFAULT': '',
  'qe_mbs.description:ja:United States':
    '米国では、金利設定の権限は連邦準備制度理事会（理事会）と連邦公開市場委員会（FOMC）に分けられている。理事会は、1つ以上の地区連邦準備銀行から提出された勧告を受けて、割引率の変更を決定する。FOMCは、中央銀行の資金量やフェデラルファンド市場金利などの公開市場操作を決定する。',
  'feast_of_shavout_pentecost.description:ja:DEFAULT': '',
  'feast_of_shavout_pentecost.description:ja:Israel': '',
  'qingming_festival.description:ja:DEFAULT': '',
  'qingming_festival.description:ja:China': '',
  'quarterly_grain_stocks_-_corn.description:ja:DEFAULT': '',
  'quarterly_grain_stocks_-_corn.description:ja:United States': '',
  '10-year_btp_auction.description:ja:DEFAULT': '',
  '10-year_btp_auction.description:ja:Italy':
    '一般的に国債は国家政府により発行され、その国の通貨により値付けされる。国から発行された債券で外貨建てのものは通常ソブリン債と呼ばれる。資金をその国の政府に融資する投資家が必要とする利回りは、予測インフレ率および元本の償還可能性を考慮に含めたものとなる。',
  'independence_day_substitute_day.description:ja:DEFAULT': '',
  'independence_day_substitute_day.description:ja:United States': '',
  'gdp_growth_rate_qoq-_first_estimate.description:ja:DEFAULT': '',
  'gdp_growth_rate_qoq-_first_estimate.description:ja:Israel':
    'イスラエル経済の主要なけん引役は科学技術セクターで、天然資源が限定的であるにもかかわらず製造・農業セクターは高度に進化している。イスラエル経済最大のセクターはサービス業でGDPの80%を占め、うち最も重要なのは金融とビジネス（GDP寄与率28％）、政府（同17％）、住宅（同12％）、貿易、宿泊、レストラン（同10％）、輸送・保管・通信（同8％）。製造業の重要性は近年低下しており、今ではGDP寄与率は14％。',
  'g7_conference_call.description:ja:DEFAULT': '',
  'g7_conference_call.description:ja:United States': '',
  'quarterly_grain_stocks_-_soy.description:ja:DEFAULT': '',
  'quarterly_grain_stocks_-_soy.description:ja:United States': '',
  'ny_fed_treasury_purchases_7_to_10_yrs.description:ja:DEFAULT': '',
  'ny_fed_treasury_purchases_7_to_10_yrs.description:ja:United States': '',
  'mps_debate_internal_market_bill.description:ja:DEFAULT': '',
  'mps_debate_internal_market_bill.description:ja:United Kingdom': '',
  'german_buba_president_weidmann_speech.description:ja:DEFAULT': '',
  'german_buba_president_weidmann_speech.description:ja:Germany': '',
  'consumer_credit_change.description:ja:DEFAULT': '',
  'consumer_credit_change.description:ja:United States':
    '米国では消費者信用は、世帯、家族、その他個人支出のために個人に提供される与信残高を指し、不動産担保融資は除外される。',
  'employment_level_qoq.description:ja:DEFAULT': '',
  'employment_level_qoq.description:ja:Switzerland': '',
  '10-year_obligacion_auction.description:ja:DEFAULT': '',
  '10-year_obligacion_auction.description:ja:Spain':
    '一般的に国債は国家政府により発行され、その国の通貨により値付けされる。国から発行された債券で外貨建てのものは通常ソブリン債と呼ばれる。資金をその国の政府に融資する投資家が必要とする利回りは、予測インフレ率および元本の償還可能性を考慮に含めたものとなる。',
  'quebec_provincial_elections.description:ja:DEFAULT': '',
  'quebec_provincial_elections.description:ja:Canada': '',
  '57-day_bill_auction.description:ja:DEFAULT': '',
  '57-day_bill_auction.description:ja:United States': '',
  'ny_fed_treasury_purchases_4_5_to_7_yrs.description:ja:DEFAULT': '',
  'ny_fed_treasury_purchases_4_5_to_7_yrs.description:ja:United States': '',
  'rba_assist_gov_edey_speech.description:ja:DEFAULT': '',
  'rba_assist_gov_edey_speech.description:ja:Australia': '',
  'us-china_phase_1_trade_deal_signature.description:ja:DEFAULT': '',
  'us-china_phase_1_trade_deal_signature.description:ja:United States': '',
  'general_elections.description:ja:DEFAULT': '',
  'general_elections.description:ja:Philippines': '',
  'general_elections.description:ja:South Africa': '',
  'general_elections.description:ja:Indonesia': '',
  'general_elections.description:ja:Sweden': '',
  'general_elections.description:ja:Spain': '',
  'general_elections.description:ja:Brazil': '',
  'general_elections.description:ja:New Zealand': '',
  'general_elections.description:ja:Japan': '',
  'general_elections.description:ja:Italy': '',
  'rba_assist_gov_kent_speech.description:ja:DEFAULT': '',
  'rba_assist_gov_kent_speech.description:ja:Australia': '',
  'youth_day.description:ja:DEFAULT': '',
  'youth_day.description:ja:South Africa': '',
  'fx_reserves_usd.description:ja:DEFAULT': '',
  'fx_reserves_usd.description:ja:India': '',
  'economic_activity_mom.description:ja:DEFAULT': '',
  'economic_activity_mom.description:ja:Mexico': '',
  'rba_chart_pack.description:ja:DEFAULT': '',
  'rba_chart_pack.description:ja:Australia':
    'オーストラリアでは金利決定はオーストラリア準備銀行理事会が行い、政策金利はキャッシュレートである。キャッシュレートとは、金融機関の間で翌日物貸付に適用される利率で、翌日物資金の需要と供給によってマネーマーケットで決定される。',
  'ny_fed_treasury_purchases_2_25_to_4_5_yrs.description:ja:DEFAULT': '',
  'ny_fed_treasury_purchases_2_25_to_4_5_yrs.description:ja:United States': '',
  'ascension_of_the_prophet_muhammad.description:ja:DEFAULT': '',
  'ascension_of_the_prophet_muhammad.description:ja:Indonesia': '',
  'uk_may_-_eu_juncker_meeting.description:ja:DEFAULT': '',
  'uk_may_-_eu_juncker_meeting.description:ja:United Kingdom': '',
  'michigan_consumer_expectations_prel.description:ja:DEFAULT': '',
  'michigan_consumer_expectations_prel.description:ja:United States':
    '消費者信頼感指数は、（1）消費者自身の財務状況の先行き見通し、（2）目先の経済全体に関する先行き見通し、（3）長期的な経済全体に関する先行き見通しの3つの分野に注目するもので、毎月実施されるアンケート調査には50項目の中核的な設問が含まれ、それぞれの設問が消費者の態度と予想を追跡する。消費者調査のサンプルは統計的にアラスカ州とハワイ州を除くすべての米国世帯を代表するように設計され、毎月500人以上に電話でインタビューが実施される。',
  'ecb_elderson_speech.description:ja:DEFAULT': '',
  'ecb_elderson_speech.description:ja:Euro Area':
    'ユーロ圏の基準金利は欧州中央銀行（ECB）の理事会によって決定される。ECBの金融政策の主な目的は、物価の安定を維持することであり、それはインフレ率を中期的に2％未満でかつそれに近い水準に抑えることである。低インフレ・低金利が長期化した場合、ECBは資産購入プログラムなどの非標準的な金融政策を採用することもある。公式金利は、主要リファイナンスオペ金利である。。',
  'rba_coombs_speech.description:ja:DEFAULT': '',
  'rba_coombs_speech.description:ja:Australia': '',
  'inflation_rate_yoy_prel.description:ja:DEFAULT': '',
  'inflation_rate_yoy_prel.description:ja:Russia':
    'ロシアでは、消費者物価指数の中で最も重要なカテゴリーは、食品および非アルコール飲料（全ウエートの30％）、輸送（14％）で、同指数には、衣料品と履物（11％）、住居・水道・電気・ガスその他燃料（11％）、娯楽・文化活動（6％）、アルコール飲料・タバコ（6％）、家電（６％）も含まれる。残りの16％は、医療、通信、教育、ホテル、レストラン、その他の財とサービスが占める。',
  'inflation_rate_yoy_prel.description:ja:France':
    'フランスでは、消費者物価指数の中で最も重要なカテゴリーはサービス（全ウエートの48％）で、特に住居の賃料とサービス（7％）、医療サービス（6％）、運輸（3％）、通信（2％）が重要である。また、同指数には、医療製品（4％）や衣料品・履物（4％）などの製造品（26％）、食料（16％）、石油製品（4％）などのエネルギー（8％）、タバコ（2％）も含まれる。',
  'inflation_rate_yoy_prel.description:ja:Spain':
    'スペインでは、消費者物価指数の中で最も重要なカテゴリーは、食品および非アルコール飲料（全ウエートの20％）、輸送（15%）、住居と光熱費（13％）、ホテル、カフェ、レストラン（12％）で、娯楽・文化（9%）、その他の財とサービス（7%）、衣料品・履物（7%）も含まれる。残りの18%は、家具・住居備品・住居維持、医療、通信、アルコール飲料・タバコ、教育が占める。',
  'inflation_rate_yoy_prel.description:ja:Germany':
    'ドイツでは、消費者物価指数の中で最も重要なカテゴリーは、住居・水道・電気・ガスその他燃料（全ウエートの32％）、運輸（13％）、娯楽・エンターテインメント・文化（11％）、食品・非アルコール飲料（10％）で、また、同指数には、雑貨・サービス（7％）、家具・照明機器・家電・その他住宅備品（5％）、レストラン・ホテル（5％）、医療（5％）、衣料品・履物（5％）が含まれ、残りの7％はアルコール飲料、タバコ、通信、教育である。',
  'inflation_rate_yoy_prel.description:ja:Poland':
    'ポーランドでは、消費者物価指数の中で最も重要なカテゴリーは、食品および非アルコール飲料（全ウエートの24％）、住居/エネルギー/維持（21％）、輸送（9％）、娯楽・文化（7％）、アルコール飲料・タバコ、その他の財とサービス、および衣料品（それぞれ６％）で、残りの17％は、通信、レストラン、ホテル、住居備品、教育が占める。',
  'inflation_rate_yoy_prel.description:ja:Portugal':
    'ポルトガルでは、インフレ率が、標準的な財のバスケットに対し消費者が支払う価格の全体的な上下変動を測定する。ポルトガルの消費者物価指数の中で最も重要なカテゴリーは、食品および非アルコール飲料（全ウエートの20.8％）、輸送（14%）、その他財とサービス（10.4％）、住居・水道・電気・ガス・その他燃料（10.3%）で、レストラン・ホテルは8.6%、娯楽・文化は7.2%、衣料品・履物は6.9%、医療は6.3%、家具・住居備品・住居維持は6.2%で、残りの9.4%はアルコール飲料・タバコ、通信、教育が占める。',
  'inflation_rate_yoy_prel.description:ja:Italy':
    'イタリアでは、消費者物価指数の中で最も重要なカテゴリーは、食品および非アルコール飲料（全ウエートの16％）、運輸（15％）、レストラン・ホテル（12％）、住居・水道・電気その他燃料（10％）、その他の財およびサービス（10％）である。また、同指数には、医療（9％）、娯楽・文化（8％）、衣料品・履物（7％）、家具と住宅備品（7％）も含まれ、残りの6％はアルコール飲料、タバコ、通信、教育である。',
  'rba_debelle_speech.description:ja:DEFAULT': '',
  'rba_debelle_speech.description:ja:Australia':
    'オーストラリアでは金利決定はオーストラリア準備銀行理事会が行い、政策金利はキャッシュレートである。キャッシュレートとは、金融機関の間で翌日物貸付に適用される利率で、翌日物資金の需要と供給によってマネーマーケットで決定される。',
  'spring_festival.description:ja:DEFAULT': '',
  'spring_festival.description:ja:China': '',
  '15-year_btp_auction.description:ja:DEFAULT': '',
  '15-year_btp_auction.description:ja:Italy': '',
  'post-brexit_meeting.description:ja:DEFAULT': '',
  'post-brexit_meeting.description:ja:Euro Area': '',
  'michigan_inflation_expectations_prel.description:ja:DEFAULT': '',
  'michigan_inflation_expectations_prel.description:ja:United States':
    '消費者信頼感指数は、（1）消費者自身の財務状況の先行き見通し、（2）目先の経済全体に関する先行き見通し、（3）長期的な経済全体に関する先行き見通しの3つの分野に注目するもので、毎月実施されるアンケート調査には50項目の中核的な設問が含まれ、それぞれの設問が消費者の態度と予想を追跡する。消費者調査のサンプルは統計的にアラスカ州とハワイ州を除くすべての米国世帯を代表するように設計され、毎月500人以上に電話でインタビューが実施される。',
  'rba_ellis_speech.description:ja:DEFAULT': '',
  'rba_ellis_speech.description:ja:Australia':
    'オーストラリアでは金利決定はオーストラリア準備銀行理事会が行い、政策金利はキャッシュレートである。キャッシュレートとは、金融機関の間で翌日物貸付に適用される利率で、翌日物資金の需要と供給によってマネーマーケットで決定される。',
  'fed_pace_of_mbs_purchase_program.description:ja:DEFAULT': '',
  'fed_pace_of_mbs_purchase_program.description:ja:United States': '',
  'us-turkey_meeting.description:ja:DEFAULT': '',
  'us-turkey_meeting.description:ja:Belgium': '',
  'rba_gov_glenn_speech.description:ja:DEFAULT': '',
  'rba_gov_glenn_speech.description:ja:Australia': '',
  'aig_performance_serv_index.description:ja:DEFAULT': '',
  'aig_performance_serv_index.description:ja:Australia': '',
  'total_business_investment_qoq.description:ja:DEFAULT': '',
  'total_business_investment_qoq.description:ja:United Kingdom': '',
  'inflation_rate_yoy_flash.description:ja:DEFAULT': '',
  'inflation_rate_yoy_flash.description:ja:Poland':
    'ポーランドでは、消費者物価指数の中で最も重要なカテゴリーは、食品および非アルコール飲料（全ウエートの24％）、住居/エネルギー/維持（21％）、輸送（9％）、娯楽・文化（7％）、アルコール飲料・タバコ、その他の財とサービス、および衣料品（それぞれ６％）で、残りの17％は、通信、レストラン、ホテル、住居備品、教育が占める。',
  'inflation_rate_yoy_flash.description:ja:Russia':
    'ロシアでは、消費者物価指数の中で最も重要なカテゴリーは、食品および非アルコール飲料（全ウエートの30％）、輸送（14％）で、同指数には、衣料品と履物（11％）、住居・水道・電気・ガスその他燃料（11％）、娯楽・文化活動（6％）、アルコール飲料・タバコ（6％）、家電（６％）も含まれる。残りの16％は、医療、通信、教育、ホテル、レストラン、その他の財とサービスが占める。',
  'inflation_rate_yoy_flash.description:ja:Euro Area':
    'ユーロ圏では、インフレ率は調和消費者物価指数（HICP）合計の加重平均を用いて算出される。主要コンポーネントは、食品・アルコール・タバコ（全ウェートの19%）、エネルギー（11%）、非エネルギー 工業製品（29%）、サービス（41％）。HICP集計値は各国のHICPコンポーネントの加重平均として算出され、各国のウエートはその国のグループ合計における家計の最終支出の割合であり、各国HICPは各国の統計局がユーロスタットに提供する。',
  'chancellor_rishi_sunak_testimony.description:ja:DEFAULT': '',
  'chancellor_rishi_sunak_testimony.description:ja:United Kingdom': '',
  'rbi_press_conference.description:ja:DEFAULT': '',
  'rbi_press_conference.description:ja:India': '',
  'nuclear_withdrawal_initiative_referendum.description:ja:DEFAULT': '',
  'nuclear_withdrawal_initiative_referendum.description:ja:Switzerland': '',
  'bank_loan_growth_yoy.description:ja:DEFAULT': '',
  'bank_loan_growth_yoy.description:ja:India':
    'インドでは、銀行貸出成長率とは商業銀行による与信（クレジット）の前年比変化を示す。食料クレジット、非食料クレジットおよびローン、現金クレジット、オーバードラフトなどが含まれる。',
  'rba_heath_speech.description:ja:DEFAULT': '',
  'rba_heath_speech.description:ja:Australia': '',
  'oecd_head_angel_gurria_speech.description:ja:DEFAULT': '',
  'oecd_head_angel_gurria_speech.description:ja:Japan': '',
  'pm_morrison_television_address_on_coronavirus.description:ja:DEFAULT': '',
  'pm_morrison_television_address_on_coronavirus.description:ja:Australia': '',
  'capacity_utilization_mom.description:ja:DEFAULT': '',
  'capacity_utilization_mom.description:ja:Japan':
    '日本では設備稼働率は生産量を生産能力の量で割って算出され、製造業における様々な工場の稼働状況を表す。絶対的な稼働率を測定するものではなく、基準年と比較した相対的な稼働率として示される。',
  'rba_lowe_speech.description:ja:DEFAULT': '',
  'rba_lowe_speech.description:ja:Australia': '',
  'us_president_trump_speech.description:ja:DEFAULT': '',
  'us_president_trump_speech.description:ja:Switzerland': '',
  'us_president_trump_speech.description:ja:United States': '',
  'rba_meeting_minutes.description:ja:DEFAULT': '',
  'rba_meeting_minutes.description:ja:Australia':
    'オーストラリアでは金利決定はオーストラリア準備銀行理事会が行い、政策金利はキャッシュレートである。キャッシュレートとは、金融機関の間で翌日物貸付に適用される利率で、翌日物資金の需要と供給によってマネーマーケットで決定される。',
  'producer_and_import_prices_mom.description:ja:DEFAULT': '',
  'producer_and_import_prices_mom.description:ja:Switzerland': '',
  'ifo_expectations_final.description:ja:DEFAULT': '',
  'ifo_expectations_final.description:ja:Germany': '',
  'rba_rate_statement.description:ja:DEFAULT': '',
  'rba_rate_statement.description:ja:Australia': '',
  'fed_monetary_policy_report.description:ja:DEFAULT': '',
  'fed_monetary_policy_report.description:ja:United States':
    '米国では、金利設定の権限は連邦準備制度理事会（理事会）と連邦公開市場委員会（FOMC）に分けられている。理事会は、1つ以上の地区連邦準備銀行から提出された勧告を受けて、割引率の変更を決定する。FOMCは、中央銀行の資金量やフェデラルファンド市場金利などの公開市場操作を決定する。',
  'rba_richards_speech.description:ja:DEFAULT': '',
  'rba_richards_speech.description:ja:Australia': '',
  'spring_budget_2019.description:ja:DEFAULT': '',
  'spring_budget_2019.description:ja:United Kingdom': '',
  'pm_johnson_statement_on_coronavirus.description:ja:DEFAULT': '',
  'pm_johnson_statement_on_coronavirus.description:ja:United Kingdom': '',
  'hsbc_markit_pmi.description:ja:DEFAULT': '',
  'hsbc_markit_pmi.description:ja:Czech Republic': '',
  'rba_simon_speech.description:ja:DEFAULT': '',
  'rba_simon_speech.description:ja:Australia': '',
  'prospective_plantings_-_corn.description:ja:DEFAULT': '',
  'prospective_plantings_-_corn.description:ja:United States': '',
  'monthly_budget_statement.description:ja:DEFAULT': '',
  'monthly_budget_statement.description:ja:United States':
    '米国の財政収支は、財政歳入から財政支出を差し引いた差額で、プラスであれば財政黒字を示し、マイナスであれば財政赤字を示す。',
  'rba_weighted_mean_cpi_qoq.description:ja:DEFAULT': '',
  'rba_weighted_mean_cpi_qoq.description:ja:Australia': '',
  'inflation_rate_mom_flash.description:ja:DEFAULT': '',
  'inflation_rate_mom_flash.description:ja:Russia':
    '前月比インフレ率は、財とサービスの価格の前月比変化を測定する。',
  'inflation_rate_mom_flash.description:ja:Euro Area':
    '前月比インフレ率は、財とサービスの価格の前月比変化を測定する。',
  'inflation_rate_mom_flash.description:ja:Germany': '',
  'inflation_rate_mom_flash.description:ja:Poland':
    'ポーランドでは、消費者物価指数の中で最も重要なカテゴリーは、食品および非アルコール飲料（全ウエートの25％）、住居・水道・電気その他燃料（19％）、運輸（10％）、娯楽・文化（6％）、アルコール飲料・タバコ（6％）、レストラン・ホテル（6％）、家具・住宅備品、通常の住居維持（6％）、その他の財およびサービス（6％）で、残りの15％は、医療、衣料品と履物、通信、教育である。',
  'fomc_member_raskin_speech.description:ja:DEFAULT': '',
  'fomc_member_raskin_speech.description:ja:United States': '',
  'rba_trimmed_mean_cpi_yoy.description:ja:DEFAULT': '',
  'rba_trimmed_mean_cpi_yoy.description:ja:Australia':
    'オーストラリアではコア・インフレ率によって、価格変動の激しい項目を一部除いた財のバスケットに対して消費者が支払う価格の変動を測定する。',
  'ppi_input_qoq.description:ja:DEFAULT': '',
  'ppi_input_qoq.description:ja:New Zealand':
    'ニュージーランドでは、生産者物価指数（PPI）は、一定期間中に卸売市場で製造者や生産者が販売した財やサービスの価格の平均的な変化を測定する。',
  'national_labor_day.description:ja:DEFAULT': '',
  'national_labor_day.description:ja:Thailand': '',
  'rba_s_gov_stevens_speech.description:ja:DEFAULT': '',
  'rba_s_gov_stevens_speech.description:ja:Australia': '',
  'birthday_of_h_m_queen_suthida.description:ja:DEFAULT': '',
  'birthday_of_h_m_queen_suthida.description:ja:Thailand': '',
  'international_labor_day.description:ja:DEFAULT': '',
  'international_labor_day.description:ja:Indonesia': '',
  'rba_s_governor_glenn_stevens_speech.description:ja:DEFAULT': '',
  'rba_s_governor_glenn_stevens_speech.description:ja:Australia': '',
  'rbnz_economic_assesment.description:ja:DEFAULT': '',
  'rbnz_economic_assesment.description:ja:New Zealand': '',
  'challenger_job_cuts.description:ja:DEFAULT': '',
  'challenger_job_cuts.description:ja:United States':
    'チャレンジャー人員削減数は、業界・地域別に企業による人員削減数情報を提供する。',
  'prospective_plantings_-_cotton.description:ja:DEFAULT': '',
  'prospective_plantings_-_cotton.description:ja:United States': '',
  'exports_yoy.description:ja:DEFAULT': '',
  'exports_yoy.description:ja:Taiwan':
    '台湾は輸出志向経済であり、全GDPの70%を輸出が占める。その内容はこの40年の間に農産物中心から工業製品へと変化し、今では工業製品が輸出の98%を占める。主要な輸出製品はエレクトロニクス（全体の33.1%）、情報・通信・オーディオビジュアル製品（10.8%）、ベースメタル（8.8%）、プラスチックとゴム（7.1%）、機械（7.5％）。主な輸出相手国は中国本土および香港（全体の40%）、ASEAN諸国（18.3%）、米国（12%）、欧州（9%）、日本（7%）。',
  'exports_yoy.description:ja:Philippines':
    'フィリピンの輸出は同国GDP全体の3分の1近くを占め、主要な輸出製品は、 エレクトロニクス製品（全体の42％）、その他製品（10％）、木製品と家具（6％）である。フィリピンはまた、世界最大のココナッツ、パイナップル、 マニラ麻の生産国。主な輸出相手国は日本（21％）、米国（15％）、中国（12％）、香港（8％）。',
  'exports_yoy.description:ja:Australia': '',
  'exports_yoy.description:ja:Hong Kong':
    '香港は輸出志向経済であり、出荷高の99%は再輸出が占める。主要な輸出製品は電気機械・器具・製品で、全体の出荷高の36％を示す。その他の輸出製品は、通信・サウンド機器（20％）、オフィスおよび自動データ処理機械（10％）、その他製品（5％）、非金属鉱物製造品（5%）。主な輸出相手国は中国本土（全体の40%）、米国（8%）、ベトナム（6％）、シンガポール（5％）、台湾、マカオ。',
  'exports_yoy.description:ja:Japan':
    '日本では1960年以来、ハイテク製品が経済の成長エンジンとなってきた。全GDPに対して輸出が占める比率は19％。2018年には過去最高の輸出額を記録したが、2019年には世界の貿易摩擦によって5.6％減少した。主要な輸出製品は、輸送機器（輸出全体の24％）で、車両が16％を占める。発電機と半導体機器などの機械は20％、半導体、IC、電気器具などの電気機械は17％、プラスチック素材や有機化学を主体とする化学品は11％、鉄や鉄鋼などの工業製品は11％である。主な輸出相手国は米国（20％）、中国（19％）、EU（12％）（特にドイツが3％）、韓国（7％）、台湾（6％）、香港（5％）、タイ（4％）。',
  'exports_yoy.description:ja:Colombia':
    'コロンビア経済はコモディティ輸出に大きく依存しており、原油・石炭輸出が全体の出荷額の59％、金が5％を占める。ただし近年では化学品や機械、切花など他の製品の重要性が高まっている。コロンビアの主な輸出相手国は米国（輸出全体の36％）、中国（6％）、スペイン（5％）、ベネズエラ（4％）、で、その他オランダ、エクアドル、ペルー、インド、ブラジル、英国にも輸出している。',
  'exports_yoy.description:ja:South Korea':
    '韓国は輸出志向経済であり、全GDPの50%以上を輸出が占める。同国の輸出は2018年に過去最高に達した後、2019年には米中間の貿易摩擦や日本の輸出規制、ブレグジット、香港の抗議運動などによって10.3％落ち込み、5,324億米ドルとなった。主な輸出製品は、電気機械・機器（全輸出高の28％）、原子炉・ボイラー・機械（13％）、 道路走行車両（12％）、鉱物性燃料、鉱物油・瀝青物質（8％）、プラスチックおよびその付属品（6％）、鉄・鉄鋼（4％）、光学・写真・映像・測定（4％）、有機化学（4％）、船舶（3％）。 韓国にとって最大の輸出相手国は中国（全輸出高の25％）で、それに米国（14％）、ベトナム（9％）、香港（6％）、日本（5％）、台湾（3％）、インド（3％）が続く。シンガポール、メキシコ、マレーシア、ドイツ、フィリピンはそれぞれ2％である。',
  'exports_yoy.description:ja:China':
    '中国経済の急成長を支える主要なエンジンは輸出の伸びであるが、2019年には貿易摩擦によって米国への売上高が激減し、中国の輸出伸び率は2018年の10％から0.5％へと大きく減速した。機械と輸送機器の輸出が全体の48％を占め、特に電気機器と器具、電化製品（14％）、通信・録音および複製機器（12％）、事務用機械および自動データ処理機器（8％）、および一般産業機器および設備とその部品（5％）が大きな比率を占める。その他主要な輸出カテゴリーとしては、雑製品（23％）（うち3％は家具部品）、繊維糸や織物、既製品（5％）など、主に素材によって分類される製品（16％）、金属製品（4％）、鉄および鉄鋼（2％）、化学品および関連製品（6％）、食品および動物（3％）などがある。中国にとって最大の出荷先相手国はEUと米国で、それぞれ17％を占め、次に香港（11％）、日本（6％）、韓国（4％）、ベトナム（4％）、ドイツ（3％）、インド（3％）、オランダ（3％）と続く。英国、台湾、シンガポール、マレーシア、ロシア、オーストラリア、インドネシア、タイ、フィリピンはそれぞれ2％を占める。',
  'exports_yoy.description:ja:Indonesia':
    'インドネシアにとって輸出は経済成長エンジンである。しかし、2012年にピークに達した後はコモディティ価格の下落と世界需要の低迷によって低下基調が続いている。主要な輸出品は、石油・ガス（全輸出の12.4％を占め、うち天然ガスは6.9％、 原油は4.3％、石油製品は1.2％）、動物性または植物性油脂（14％）、電気機器・機械（10.45％）で、その他の輸出品としては、履物およびその付属品（3.4％）、 ニット以外の衣類（3％）、鉱石・スラグ・灰（2.5％）などがある。主要な輸出相手国は、米国（11.6％）、中国（10％）、日本（9.9％）、インド（8.8％）、シンガポール（7％）。',
  'exports_yoy.description:ja:Thailand':
    'タイは輸出志向経済であり、全GDPの65%を輸出が占める。主要な輸出製品は製造品で（全出荷の86％）、うち、エレクトロニクス製品 (14％）、車両（13％）、機械・機器（7.5％）、食品（7.5％）が最も重要である。米とゴムを中心とした農産物は全出荷の8％を占める。主要な輸出相手国は、中国（12％）、日本（10％）、米国（10％）、欧州連合（9.5％）で、その他マレーシア、オーストラリア、シンガポールにも輸出している。',
  'qing_ming_jie.description:ja:DEFAULT': '',
  'qing_ming_jie.description:ja:China': '',
  'rbnz_gov_orr_speech.description:ja:DEFAULT': '',
  'rbnz_gov_orr_speech.description:ja:New Zealand': '',
  'rbnz_gov_wheeler_speech.description:ja:DEFAULT': '',
  'rbnz_gov_wheeler_speech.description:ja:New Zealand': '',
  'national_day_of_the_peoples_republic_of_china.description:ja:DEFAULT': '',
  'national_day_of_the_peoples_republic_of_china.description:ja:Hong Kong': '',
  'reinz_house_price_index_mom.description:ja:DEFAULT': '',
  'reinz_house_price_index_mom.description:ja:New Zealand':
    'ニュージーランドでは、住宅価格指数は住宅不動産価格の中央値で測定される。',
  'nonfarm_productivity_qoq_final.description:ja:DEFAULT': '',
  'nonfarm_productivity_qoq_final.description:ja:United States':
    '米国では、非農業部門労働者の生産性は1労働時間当たりの財・サービス生産量で測定される。労働生産性は、実質生産指数を、被雇用者、経営者、無給の家族従事者を含むすべての人の労働時間指数で除して算出する。',
  'last_day_flag_referendum.description:ja:DEFAULT': '',
  'last_day_flag_referendum.description:ja:New Zealand': '',
  'halifax_house_price_index_yoy.description:ja:DEFAULT': '',
  'halifax_house_price_index_yoy.description:ja:United Kingdom':
    '英国では、住宅価格指数はハリファックス住宅価格指数を指す。同指数は1カ月当たり約1万5000件の住宅購入件数をカバーする住宅ローンデータ・サンプルに基づいて集計される。',
  'pce_prices_qoq_-_2_est.description:ja:DEFAULT': '',
  'pce_prices_qoq_-_2_est.description:ja:United States': '',
  'riksbank_interest_rate.description:ja:DEFAULT': '',
  'riksbank_interest_rate.description:ja:Sweden':
    'スウェーデンでは、ベンチマーク金利はスウェーデン中央銀行（リクスバンク）の執行委員会が決定する。主要金利は、7日間リクスバンクから銀行が融資を受けるか預金する際の金利であるレポ金利。リスクバンクはインフレ率をCPIF（金利一定の下での消費者物価指数）で年間2％程度に保つことを目標としている。',
  'tokyo_cpi_yoy.description:ja:DEFAULT': '',
  'tokyo_cpi_yoy.description:ja:Japan':
    '東京区部では、消費者物価指数の中で最も重要なカテゴリーは、住居（全ウェートの26％）と食品（25％）で、それに教養娯楽（10％）、交通・通信（10％）、光熱・水道（6％）、諸雑費（5％）、教育（3.3％）、被服および履物（5％）、保健医療（4％）、家具・家事用品（3％）と続く。',
  'the_queen_s_birthday_substitute.description:ja:DEFAULT': '',
  'the_queen_s_birthday_substitute.description:ja:Thailand': '',
  'rba_weighted_mean_cpi_yoy.description:ja:DEFAULT': '',
  'rba_weighted_mean_cpi_yoy.description:ja:Australia': '',
  'boe_woods_speech.description:ja:DEFAULT': '',
  'boe_woods_speech.description:ja:United Kingdom': '',
  'gdp_growth_rate_yoy_prel.description:ja:DEFAULT': '',
  'gdp_growth_rate_yoy_prel.description:ja:Euro Area':
    'ユーロ圏は世界で2番目に大きな経済圏である。加盟している19カ国のうち、最も大きいのはドイツ（GDP合計の29％）、フランス（20％）、イタリア（15％）、スペイン（10％）である。支出面では家計消費がGDPの主な構成要素で、総支出の54％を占めており、次いで総固定資本形成（21％）、政府支出（20％）となっている。財・サービスの輸出はGDPの47%、輸入は43%であるため、GDP全体に4%寄与している。',
  'gdp_growth_rate_yoy_prel.description:ja:Netherlands':
    'オランダ経済はユーロ圏で第6位、ヨーロッパの重要な交通拠点である。オランダ経済は外国貿易に大きく依存しており、輸出はGDPの83％、輸入は72％を占める。GDPの主な構成要素は家計消費（45％）で、次いで政府支出（26％）、総固定資本形成（18％）、純輸出（11％）となっている。',
  'gdp_growth_rate_yoy_prel.description:ja:Spain':
    '支出側では家計消費がスペインGDPの主な構成要素で58％を占めており、次いで総固定資本形成（21％）、政府支出（18％）となっている。投資のうち、有形固定資産は、建設（10％）、機械・設備・兵器システム（7％）などを含めGDPの18％を占めており、残りの3％は知的財産製品である。財・サービスの輸出はGDPの34%である一方、輸入は31%であるため、GDP全体に3%寄与している。',
  'gdp_growth_rate_yoy_prel.description:ja:South Korea':
    '韓国の経済は、過去50年間で世界で最も多様化し、技術的にも進歩した国の一つとなった。多くの先進国と同様、GDPに占めるサービスの割合が最も高くなっている（GDPの約57％）。サービス業の中でも特に重要なのは、行政（6％）、教育（5.3％）、情報・通信（5％）、ビジネス活動（4.6％）である。韓国を主要な輸出国にした強力な製造業は、GDPの32％を占めている。',
  'gdp_growth_rate_yoy_prel.description:ja:Russia':
    'ロシアは世界有数の石油・天然ガスの生産国であり、鉄鋼や一次アルミニウムなどの金属の輸出国でもある。生産面では、サービス業が経済の最大セクターであり、GDPの58％を占めている。サービス業の中で最も重要な分野は、卸売・小売（GDP合計の17％）、行政・医療・教育（12％）である。工業は総生産の40％、農業は残りの2％。',
  'gdp_growth_rate_yoy_prel.description:ja:Greece':
    'ギリシャでは家計消費がGDPの主な構成要素であり、その総支出の72％を占めており、次いで政府支出（20％）、総固定資本形成（12％）となっている。財・サービスの輸出はGDPの33%、輸入は35%であるため、GDP合計に2%のマイナス寄与となっている。',
  'gdp_growth_rate_yoy_prel.description:ja:Hungary':
    'ハンガリーでは、電子機器の製造と研究などがイノベーションと経済成長の主な原動力である。また、モバイル技術、情報セキュリティ、および関連するハードウェア研究の主要な中心地としても成長している。支出面では、家計消費がGDPの主な構成要素として全体の50%を占め、総固定資本形成（22％）、政府支出（20％）と続く。財・サービスの輸出はGDPの89%を占め、輸入は82%であるため、GDP全体に7%寄与している。',
  'gdp_growth_rate_yoy_prel.description:ja:Denmark':
    'デンマークでは家計消費がGDPの主な構成要素であり、その総支出の49％を占めており、次いで政府支出（27％とEU諸国では最高）、総固定資本形成（19％）となっている。財・サービスの輸出はGDPの54%に達する一方、輸入は48%であるため、GDP合計に６%のプラス寄与となっている。',
  'gdp_growth_rate_yoy_prel.description:ja:Portugal':
    '支出面では、家計消費がGDPの主な構成要素であり、その合計支出の66％を占めている。続いて、政府支出（19％）、総固定資本形成（15％）となっている。財・サービスの輸出はGDPの40％、輸入も40％を占める。',
  'gdp_growth_rate_yoy_prel.description:ja:United Kingdom':
    'イギリスの経済規模は、世界第6位、欧州ではドイツに次ぐ第2位である。サービス業が最も重要で、GDP全体の79％を占めている。サービス業の中で最も大きな分野は、政府、教育、医療（GDP全体の19％）、不動産（12％）、専門的・科学的・技術的活動および行政・支援サービス（12％）、卸売・小売業（11％）、金融・保険（8％）である。工業分野はGDPの21％を占め、この分野の最大のセグメントは製造業（GDP全体の10％）と建設業（6％）である。農業は、GDPのわずか1%を占めるにすぎない。',
  'gdp_growth_rate_yoy_prel.description:ja:Czech Republic':
    'チェコ共和国では家計消費がGDPの主な構成要素であり、その総支出の49％を占めており、次いで総固定資本形成（25％）、政府支出（19％）となっている。財・サービスの輸出はGDPの84%である一方、輸入は77%であるため、GDP合計に７%のプラス寄与となっている。',
  'gdp_growth_rate_yoy_prel.description:ja:France':
    'フランスの経済は世界第7位、ユーロ圏では第2位の規模。フランスは、航空機、軍事機器、香水、医薬品、ワイン、牛肉、小麦の生産など、大規模かつ多様な工業・農業基盤を有している。少なくとも年間8,200万人の外国人観光客が訪れるフランスは、世界で最も観光客の多い国であり、観光業による収入は世界第3位を維持している。経済の最大部門はサービス業（GDP全体の79％）で、工業は19％。農業のGDPへの寄与度は2%だが、フランスは世界第2位の農産物の輸出国である。',
  'gdp_growth_rate_yoy_prel.description:ja:Mexico':
    'メキシコの経済規模は中南米で第2位である。輸出志向の経済で、貿易の90％以上が自由貿易協定の下で行われている。メキシコでは、サービス業がGDP全体の62%を占めている。サービス業の中で最も大きな分野は、卸売・小売（16％）、不動産（10％）、輸送・倉庫・通信（7％）、金融サービス・保険（6％）である。工業は生産高の18％を占めており、その最大のセグメントは自動車（GDP全体の4％）と食品（3.8％）である。それ以外は建設、水道・ガス・配電（8％）、鉱業（5％）、政府（4％）、農業・林業・漁業・狩猟（3％）。',
  'gdp_growth_rate_yoy_prel.description:ja:Poland':
    'ポーランドの消費者市場は3,800万人と、ヨーロッパでも最大級の規模を誇る。ポーランドの主要産業は、食品・飲料加工、造船、機械・鉄鋼製品・化学品・ガラス・繊維製品の製造である。支出面では、家計消費がGDPの60％を占めており、次いで総固定資本形成（20％）、政府支出（18％）である。財・サービスの輸出はGDPの47％を占め、輸入は46％で、GDP全体に1％寄与している。',
  'gdp_growth_rate_yoy_prel.description:ja:Finland':
    'フィンランド経済最大のセクターは65％を占めるサービス業で、次いで製造業と精製が31％を占める。第一次産業は3％。フィンランドの主要な工業製品は紙・板・電子・金属製品である。製造業のうち主要セクターはエンジニアリングとハイテク業界。支出面では、GDPの55％を占める家計消費が最大で、次いで政府支出（25％）、総固定資本形成（20％）。財・サービスの輸出はGDPの38％である一方、輸入は39％であるため、GDP合計に1％のマイナス寄与となっている。',
  'fed_daly_speech.description:ja:DEFAULT': '',
  'fed_daly_speech.description:ja:United States':
    '米国では、金利設定の権限は連邦準備制度理事会（理事会）と連邦公開市場委員会（FOMC）に分けられている。理事会は、1つ以上の地区連邦準備銀行から提出された勧告を受けて、割引率の変更を決定する。FOMCは、中央銀行の資金量やフェデラルファンド市場金利などの公開市場操作を決定する。',
  'chicago_pmi.description:ja:DEFAULT': '',
  'chicago_pmi.description:ja:United States':
    'シカゴ購買担当者景気指数（PMI）（ISMシカゴビジネスバロメーター）はシカゴ地区の製造業および非製造業の業況を測定するもので、生産（ウェート0.25）、新規受注（0.35）、受注残（0.15）、雇用（0.10）、サプライヤー納期（0.15）の5つの加重サブ指数から算出され、季節調整を加えて前月と比較、指数値が50を上回れば景気拡大、下回れば縮小、50は横ばいを示す。シカゴPMIはISM製造業景況感指数の1日前に発表される。',
  'nationwide_housing_prices_yoy.description:ja:DEFAULT': '',
  'nationwide_housing_prices_yoy.description:ja:United Kingdom':
    '全国住宅価格指数は、英国の住宅価格の傾向を示す指標である。この指数は、住宅ローンを利用した持ち家購入の取引に基づいて算出される。賃貸のための購入や現金での購入は含まれない。',
  'services_pmi.description:ja:DEFAULT': '',
  'services_pmi.description:ja:Spain': '',
  'services_pmi.description:ja:Germany': '',
  'services_pmi.description:ja:France': '',
  'services_pmi.description:ja:Sweden':
    'スウェーデンでは、スウェドバンクのサービス業購買担当者景気指数（PMI）が200社の調査結果に基づいてサービス業の業況を測定する。同指数は、新規受注（全ウェートの35％）、取引量（25％）、雇用（20％）、サプライヤー納期（20％）などの変数を追跡し、指数値が50を超えれば全体的にサービス業が拡大していることを、50を切れば縮小していることを示す。',
  'premier_li_keqiang_speech.description:ja:DEFAULT': '',
  'premier_li_keqiang_speech.description:ja:China': '',
  'singaporean_pmi.description:ja:DEFAULT': '',
  'singaporean_pmi.description:ja:Singapore': '',
  'non-farm_payrolls_qoq.description:ja:DEFAULT': '',
  'non-farm_payrolls_qoq.description:ja:France': '',
  'caixin_services_pmi.description:ja:DEFAULT': '',
  'caixin_services_pmi.description:ja:China':
    '財新中国サービス業購買担当者景気指数（PMI）は、約400社の民間企業の購買担当幹部から収集したアンケート調査の回答を基に集計され、売上高、雇用、在庫、価格などの変数を追跡し、指数が50を超えれば全体的にサービス業が全体的に拡大していることを、50を切れば全体的に縮小していることを示す。',
  'private_consumption_mom.description:ja:DEFAULT': '',
  'private_consumption_mom.description:ja:Thailand': '',
  'household_consumption_mom.description:ja:DEFAULT': '',
  'household_consumption_mom.description:ja:Norway':
    'ノルウェーでは、家計支出によって財（耐久財と非耐久財）に対する家計の消費動向を測定している。この指数には、クロスボーダーショッピングなど、海外での消費は含まれない。',
  'household_consumption_mom.description:ja:Sweden':
    'スウェーデン統計局は2013年以来、家計消費に関する月次統計を編纂している。国内総生産（GDP）の47％を家計消費が占めるため、この統計は景気にとって重要な指標となる。',
  'household_consumption_mom.description:ja:France':
    'フランスでは、家計消費は財の消費のみが含まれ、経済分析にとって重要な家計の消費行動に関する短期的な情報を提供している。2010年には家計による財の消費はユーロ建て実質消費総額の95％近くを占め、財とサービスの家計消費支出の50％を占めていた。',
  'tokyo_cpi_ex_food_energy_yoy.description:ja:DEFAULT': '',
  'tokyo_cpi_ex_food_energy_yoy.description:ja:Japan': '',
  'pce_price_index_mom.description:ja:DEFAULT': '',
  'pce_price_index_mom.description:ja:United States':
    '米国では、個人消費支出（PCE）価格指数が国内で財とサービス購入のために支払った価格を測定する。消費者物価指数は財の固定バスケットに対して数年間変更されない支出ウェートを使用するが、PCE価格指数は連鎖指数を使用し、現行期間とその前の期間の支出データを使用する（フィッシャー価格指数と呼ばれる）。',
  'eco_watchers_survey_outlook.description:ja:DEFAULT': '',
  'eco_watchers_survey_outlook.description:ja:Japan':
    '景気ウォッチャー調査の現状判断指数は、理容・美容店やタクシー運転手、ウェイターなど、消費者に直接サービスを提供する事業の現状に関する実感を測定するもので、約2,050人の労働者を対象に調査する。50.0を上回れば楽観的、下回れば悲観的な見方であることを示す。',
  'ifo_business_climate_final.description:ja:DEFAULT': '',
  'ifo_business_climate_final.description:ja:Germany':
    'ドイツでは、Ifo企業景況感指数によって、現状と向こう6カ月間の先行きにについて企業の景況感を測定する。この調査は電話で行われ、調査対象は製造業、サービス業、貿易業及び建設業の9,000企業。楽観的な上級経営陣と悲観的な経営陣の比率の差が、マイナス100（全回答者が現状を「悪い」と評価し、今後悪化を予想）から＋100（全回答者が現状を「良い」と評価し、今後改善を予想）までの企業景況感バランス値として算出され、それが基準年（現行は2015年）の平均値に標準化されてIfo企業景況感指数が算出される。',
  'fed_yellen_testifies.description:ja:DEFAULT': '',
  'fed_yellen_testifies.description:ja:United States': '',
  'second_day_of_christmas.description:ja:DEFAULT': '',
  'second_day_of_christmas.description:ja:Poland': '',
  'budget_2021.description:ja:DEFAULT': '',
  'budget_2021.description:ja:United Kingdom': '',
  'fed_chair_yellen_speaks.description:ja:DEFAULT': '',
  'fed_chair_yellen_speaks.description:ja:United States': '',
  'rba_trimmed_mean_cpi_qoq.description:ja:DEFAULT': '',
  'rba_trimmed_mean_cpi_qoq.description:ja:Australia': '',
  'budget_proposal.description:ja:DEFAULT': '',
  'budget_proposal.description:ja:India': '',
  'wholesale_prices_n_s_a_mom.description:ja:DEFAULT': '',
  'wholesale_prices_n_s_a_mom.description:ja:Austria':
    '卸売物価指数は卸売業者が販売する財の価格動向を反映し、毎月約200社の卸売業者が自主的に約1400種の卸売販売価格（VATを除く）を提供することで、財バスケットに含まれる387項目の価格が調査される。',
  '10-year_bund_auction.description:ja:DEFAULT': '',
  '10-year_bund_auction.description:ja:Germany':
    '一般的に国債は国家政府により発行され、その国の通貨により値付けされる。国から発行された債券で外貨建てのものは通常ソブリン債と呼ばれる。資金をその国の政府に融資する投資家が必要とする利回りは、予測インフレ率および元本の償還可能性を考慮に含めたものとなる。',
  'st_cyril_and_methodius_day.description:ja:DEFAULT': '',
  'st_cyril_and_methodius_day.description:ja:Czech Republic': '',
  'rbnz_inflation_expectations_yoy.description:ja:DEFAULT': '',
  'rbnz_inflation_expectations_yoy.description:ja:New Zealand': '',
  'core_pce_prices_qoq_adv.description:ja:DEFAULT': '',
  'core_pce_prices_qoq_adv.description:ja:United States': '',
  'jp_foreign_reserves.description:ja:DEFAULT': '',
  'jp_foreign_reserves.description:ja:Japan': '',
  'ecb_constancio_speech.description:ja:DEFAULT': '',
  'ecb_constancio_speech.description:ja:Euro Area': '',
  'snb_studer_speech.description:ja:DEFAULT': '',
  'snb_studer_speech.description:ja:Switzerland': '',
  'prime_rate.description:ja:DEFAULT': '',
  'prime_rate.description:ja:South Africa':
    '南アフリカでは銀行の貸出金利は商業銀行のプライムレートを指し、レポ金利に3.5ベーシスポイントを上乗せしたベンチマーク金利であり、各銀行はベンチマーク金利を基準として顧客への貸出金利を決定する。',
  'showa_day.description:ja:DEFAULT': '',
  'showa_day.description:ja:Japan': '',
  'foreign_bond_investment.description:ja:DEFAULT': '',
  'foreign_bond_investment.description:ja:Japan':
    'この純額データは、長期債券の購入と売却の差額を示し、プラスであれば日本人投資家による外国債券の買い越しを、マイナスであれば売り越しと日本への資金流入を示す。日本銀行は除く。',
  'jibun_bank_services_pmi.description:ja:DEFAULT': '',
  'jibun_bank_services_pmi.description:ja:Japan':
    '日本のサービス業（PMI）は、日本の民間サービス業を代表する400社を超える企業のパネルから収集したデータを基に集計される。指数は、売上高、雇用、在庫、価格などの変数を追跡し、50を超えれば、サービス業が全体的に拡大傾向にあることを示し、50を下回れば、全般に縮小傾向にあることを示す。',
  'total_social_financing.description:ja:DEFAULT': '',
  'total_social_financing.description:ja:China':
    '中国では、民間セクターへの融資は社会融資総量を指す。これは特定期間中における実体経済（国内の非金融企業と家計）への融資額である。社会融資総量は新規株式公開や信託会社からの融資、債券販売など、オフバランスの金融も含むため、経済の信用と流動性を幅広く測定する。',
  'rba_gov_lowe_speech.description:ja:DEFAULT': '',
  'rba_gov_lowe_speech.description:ja:Australia':
    'オーストラリアでは金利決定はオーストラリア準備銀行理事会が行い、政策金利はキャッシュレートである。キャッシュレートとは、金融機関の間で翌日物貸付に適用される利率で、翌日物資金の需要と供給によってマネーマーケットで決定される。',
  'foreign_currency_reserves.description:ja:DEFAULT': '',
  'foreign_currency_reserves.description:ja:Switzerland': '',
  'ascension_day_of_jesus_christ.description:ja:DEFAULT': '',
  'ascension_day_of_jesus_christ.description:ja:Indonesia': '',
  'ecb_monetary_policy_statement.description:ja:DEFAULT': '',
  'ecb_monetary_policy_statement.description:ja:Euro Area': '',
  'tourist_arrivals.description:ja:DEFAULT': '',
  'tourist_arrivals.description:ja:Spain': '',
  'trade_balance_mom.description:ja:DEFAULT': '',
  'trade_balance_mom.description:ja:New Zealand': '',
  'snb_chair_jordan_speech.description:ja:DEFAULT': '',
  'snb_chair_jordan_speech.description:ja:Switzerland':
    'スイスではスイス国立銀行（SNB）が金利を決定し、公式金利はSNBの政策金利である。SNBは有担保短期スイスフラン建てマネーマーケット金利をSNBの政策金利近傍に維持することを目標としている。今日、これらの最も代表的な金利はSARONである。2019年６月13日時点で従来SNBが金融政策戦略に使用していた３カ月物スイスフランLIBOR（ロンドン銀行間取引金利）の目標レンジに代わり、SNB政策金利が採用されることになった。その理由はLIBORを決定するためのマネーマーケット取引が不足し、LIBORが最も重要な参照金利としての重要性を失いつつあったためである。2011年9月6日から2015年1月15日までの期間の主な注目点は最低為替レートとして1EUR＝1.20CHFをSNBが強制執行したことであった。2014年12月18日にSNBは要求払い預金金利をマイナス0.25％とすることを決定した。マイナス金利導入の発表に伴い、当時使われていたLIBORの目標レンジは初めてマイナス圏となった。2015年1月15日、SNBは要求払い預金金利をさらにマイナス0.75％に引き下げ、目標レンジをマイナス1.25％～マイナス0.25％の間とした。2015年1月22日以来マイナス金利が適用されており、現在SNBの政策金利に対応している。',
  'ecb_fernandez-bollo_speech.description:ja:DEFAULT': '',
  'ecb_fernandez-bollo_speech.description:ja:Euro Area': '',
  'third_day_of_lunar_new_year.description:ja:DEFAULT': '',
  'third_day_of_lunar_new_year.description:ja:Hong Kong': '',
  'employment_cost_-_benefits_qoq.description:ja:DEFAULT': '',
  'employment_cost_-_benefits_qoq.description:ja:United States': '',
  'treasury_sec_lew_speaks.description:ja:DEFAULT': '',
  'treasury_sec_lew_speaks.description:ja:United States': '',
  'constitution_memorial_day_substitute_day.description:ja:DEFAULT': '',
  'constitution_memorial_day_substitute_day.description:ja:Japan': '',
  'consumer_confidence.description:ja:DEFAULT': '',
  'consumer_confidence.description:ja:United States': '',
  'consumer_confidence.description:ja:Taiwan':
    '台湾では、中央大学台湾経済発展研究センター（台経中心）が発表する消費者信頼感指数（CCI）によって今後6カ月間の先行きに対する消費者の予想を測定する。CCI調査では、国内の事業状況、雇用機会、家計経済状況、株式投資、インフレ期待、耐久消費財購買意欲の6指数を追跡し、全体的な指数はこれら6つのサブ指数の非加重平均である。指数値が0から100の間であれば悲観的な見方であることを、100から200までの値は楽観的な見方であることを示す。同指数は、ランダムに選択された約2,400人の20歳以上の成人を対象に毎月第3週に全国的に行われる電話調査に基づいて算出される。',
  'consumer_confidence.description:ja:Euro Area':
    'ユーロ圏では、消費者の経済に対する楽観的な見方を示す指標として、消費者景況感指数が用いられている。この調査は電話で行われ、ユーロ圏の23,000世帯を対象としている。調査対象となる世帯数はユーロ圏全体にわたり異なる。質問は現在の経済・金融状況、貯蓄計画、および消費者物価指数や経済全般、大型耐久財購入計画などに関する見通しを中心としたもので、この指数はマイナス100からプラス100までの間となり、マイナス100は極端に悲観的な見方を、0はニュートラルを、プラス100は極端に楽観的な見方を示す。',
  'consumer_confidence.description:ja:Philippines':
    'フィリピンでは、5,000世帯以上（約50％はマニラ首都圏内の世帯）を調査して消費者信頼感指数を算出している。設問は経済の全体的な状況と家計の経済状況、世帯収入に関するもので、指数は肯定的な回答の比率から否定的回答の比率を差し引いて算出される。指数値はマイナス100からプラス100の間で、プラスの値は楽観的な見方を、マイナスの値は悲観的な見方を、ゼロはニュートラルであることを示す。',
  'consumer_confidence.description:ja:Colombia':
    'コロンビアでは消費者意見調査（EOC）が家計の経済状況とコロンビアの全体的な経済状況に関する家計の楽観度を前年と翌年の情報を考慮して測定する。この調査は消費者による家電や自動車の購買意欲と、与信が承認されるかどうかに関する知見を提供する。調査対象は、所得水準の異なる（低・中・高）コロンビアの4大都市に居住する25歳から44歳までの約600人。',
  'consumer_confidence.description:ja:Thailand':
    'タイでは、消費者信頼感指数（CCI）は現在および将来の経済状況、雇用見通し、所得見通しに関する消費者の態度を測定する。同指数は約2,440世帯の調査に基づき、100を超える指数は見通し改善を、100を切る指数は悪化を示す。',
  'consumer_confidence.description:ja:Indonesia':
    'インドネシアでは、消費者信頼感指数（CCI）は6カ月前に対する現在の所得と雇用機会、耐久財購入に適切な時期、今後6カ月間の全体的な経済状況と雇用機会先行きに関する消費者予想を測定するもので、主要都市（GDPの約78％に相当する都市）における中・高所得層の約4,600世帯調査に基づいて算出している。データは電話インタビューと戸別訪問を通して集められている。同指数の主要な2つのコンポーネントは現況指数と期待指数で、CCIは「改善」と「悪化」の回答比率の差として算出され、100を超えると楽観的な見通し、下回ると悲観的な見通しを示す。',
  'consumer_confidence.description:ja:Norway':
    'ノルウェーでは、ファイナンス・ノルウェーとTNSギャラップ消費者信頼感調査によって、経済状況に関する消費者の楽観度が測定される。一般的に失業率が低くGDP成長率が高ければ消費者信頼感は高い。平均的な消費者信頼感の測定値は、消費者がどれくらい支出する可能性があるかを予測する際に有益な指標となる。',
  'consumer_confidence.description:ja:Mexico':
    'メキシコの消費者信頼感指数（ICC）は、耐久消費財や非耐久消費財の購入、雇用、インフレ、貯蓄などに関して、人々が自分や家族、国全体の経済状況に対して抱いている現在の認識と将来の予想を測定したものである。この指数は、国内の32の主要都市に所在する2,336世帯のサンプルに基づいている。 ICCは2003年1月時点を100として基準にしており、100以上は楽観的、100は中立、100以下は悲観的を示す。',
  'consumer_confidence.description:ja:Japan':
    '日本では、毎月の消費動向調査は戸別訪問によって2人以上の世帯約4,700を対象に行い、暮らし向き、収入の増え方、雇用環境、耐久消費財の買い時判断の4つを質問する。それぞれに対し、消費者が6カ月後の状況をどう考えているかの評価の回答に基づいて指標が作成される。消費者態度指数はこれら4つの消費者態度の単純平均で、指数が50を超えると楽観的な見方を、50未満は悲観的な見方を、50はニュートラルであることを示す。',
  'consumer_confidence.description:ja:Israel':
    'イスラエルでは、消費者信頼感指数は、向こう12カ月間における家計状況の変化とイスラエルの全体的な経済状況、失業率、家計の貯蓄計画を消費者がどうみているかを測定するもので、21歳以上の成人約759人を対象とした4つの質問に対する回答の均等加重での算術的均等加重平均を示す。値はマイナス100からプラス100までの間で、マイナス100は極端に悲観的な見方を、0はニュートラルな見方を、プラス100は極端に楽観的な見方を示す。',
  'consumer_confidence.description:ja:Ireland':
    'アイルランドの消費者態度指数調査は国内全地域で最低1,100世帯を対象にしている。アンケートによって回答者の過去1年間の経済全般に対する認識と今後1年間の予想、失業率とインフレ率の最近の傾向に対する認識、家計の状況の最近の傾向と今後の変化の可能性、貯蓄や大型購入の意向などについて評価している。この消費者センチメント指数は、「良い」と答えた回答の割合から「悪い」とした回答の割合を引いたものに100を加えて算出される。この指標は0から200の間で変化し、0は極めて信頼感が低く、100は中立、200は極めて信頼感が強いことを示す。',
  'consumer_confidence.description:ja:Finland':
    'フィンランドでは、フィンランド統計局の消費者調査によって、経済に関する消費者の見方や意図が示される。フィンランド統計局の消費者調査に関するデータシステムは、電話インタビューによる回答者別の元データ（開示は禁止されている）と回答の分布と時系列に関する発表データから構成される。一般的に失業率が低くGDP成長率が高ければ消費者信頼感は高い。平均的な消費者信頼感の測定値は、消費者がどれくらい支出する可能性があるかを予測する際に有益な指標となる。',
  'consumer_confidence.description:ja:Greece':
    'ギリシャでは、消費者景況感指数（ESI）は、消費者の経済状況に対する楽観度を測定する。この調査はギリシャの1,500世帯を対象に電話で行われ、質問は現在の経済・金融状況、貯蓄計画、および消費者物価指数や経済全般、大型耐久財購入計画などに関する見通しを中心としたものだ。消費者ESI値はマイナス100からプラス100までの間を取り、マイナス100は極端に悲観的な見方を、0はニュートラルを、プラス100は極端に楽観的な見方を示す。',
  'consumer_confidence.description:ja:Portugal':
    'ポルトガルでは、3期移動平均消費者信頼感指数は、同国の現在と将来の経済状況および回答者の購買傾向について消費者に質問した結果に基づき、ポジティブな回答の比率とネガティブな回答の比率の差を使って推定される。ただし、ニュートラルな回答の比率は含まない。移動平均を使うことで異常な動きが取り除かれ、短期的な傾向を検出でき、時系列が平滑化される。',
  'consumer_confidence.description:ja:Italy':
    'イタリアの信頼感指数はイタリア人消費者2,000人を対象とし、電話で家計の財務状況、雇用、貯蓄見通し、耐久財購入計画などを質問して算出される。信頼感指数は季節的影響が調整され、基準年を2005年としている。指数100は消費者センチメントに何の変化もないことを、100を超える値は信頼感が高まっていることを、100未満は信頼感が低いことを示す。',
  'consumer_confidence.description:ja:Netherlands':
    'オランダでは消費者信頼感調査は、1,000世帯を対象に、電話で行われる。質問内容は、過去12カ月間の国内経済や回答者自身の財務状況に対する評価と、今後12カ月間の見通しについてである。この指数は、楽観的な回答者の割合と悲観的な回答者の割合の差として算出される。したがって指数はマイナス100（すべての回答者が自分の状況を悪く評価し、さらに悪くなると予想している）からプラス100（すべての参加者が現在の状況に満足し、さらに改善すると予想している）の間の値をとり、0は中立を表す。',
  'consumer_confidence.description:ja:France':
    'フランスでは、消費者信頼感指数は約2000人を対象にした調査に基づいて算出される。質問は主に、過去と未来のフランスの経済状況、過去と未来の個人の経済状況、失業、大型購入の意図、現在の貯蓄能力と予想される貯蓄能力などに関するもので、この指標は因子分析の手法を用い、1987年から2011年までの過去の指数値に関連した現在のセンチメントを測定する方法で算出される。110を超える値は異例に高い楽観を、90未満の値は異例に高い悲観を示し、100は中立を示す。',
  'consumer_confidence.description:ja:South Africa':
    '南アフリカでは、BER消費者信頼感指数が首都圏や都市部、町や村の2,500世帯を対象に、戸別訪問による調査を基に経済全体の見通し、家計の経済状況、大型支出の意図を測定する。同指数は、改善を予想する回答の比率と悪化を予想する回答の比率との差として算出され、マイナス100からプラス100までの間の数値である。マイナス100は極端に悲観的な見方を、0はニュートラルを、プラス100は極端に楽観的な見方を示す。',
  'consumer_confidence.description:ja:Belgium':
    'ベルギーでは、消費者信頼感指数は経済の進展状況に関する消費者の信頼感に関する情報を簡潔に提供するもので、1,000人以上の消費者に対し電話でベルギーの雇用状況と景気見通し、回答者自身の経済状況と貯蓄能力について質問した結果に基づく。同指数は肯定的回答と否定的回答の差であり、ゼロ値は、来年状況が改善するか悪化するかについて、ベルギー人の間で見方が均等に分かれていることを示す。',
  'consumer_confidence.description:ja:Russia':
    'ロシアでは、消費者信頼感指数は国内全地域に居住する16歳以上の5,000人を対象とした調査結果に基づく。同指数は、回答者の過去12カ月と今後12カ月の個人的な経済状況、過去1年と今後12カ月の同国の経済状況の変化、そして耐久財購入に向けた現在の状況の5つの指数の算術平均をとったもの。同指数は楽観的な回答をした者の比率と悲観的な回答をした者の比率の差を示し、マイナス100（回答者すべてが現状を悪いと評価し、今後悪化を予想）からプラス100（回答者すべてが現状に満足し、今後改善を予想）までの間で、0はニュートラルを示す。',
  'consumer_confidence.description:ja:Czech Republic':
    'チョコ共和国では、チェコ統計局消費者信頼感指数は、消費者による今後12カ月間の個人の経済状況の予想、全体的な経済状況の予想、全体的な失業率の予想（符号反転）、貯蓄計画という4つの指数の平均である。一般的に失業率が低くGDP成長率が高ければ消費者信頼感は高い。平均的な消費者信頼感の測定値は、消費者がどれくらい支出する可能性があるかを予測する際に有益な指標となる。',
  'consumer_confidence.description:ja:Spain':
    'スペインでは、消費者信頼感調査はスペイン社会全体を代表する16歳以上の1,000人の個人を対象に電話で調査を行い、経済・金融状況の現状、貯蓄計画、国の全体的な経済と個人の経済状況の先行き、大型耐久財の購買計画を中心に質問する。同指数は、楽観的な回答者の比率と悲観的な回答者の比率の差を表し、値は0から200までの間で、0は極端に悲観的な見方を、100はニュートラルな見方を、200は極端に楽観的な見方を示す。',
  'consumer_confidence.description:ja:Denmark':
    'デンマークでは、消費者信頼感指数は、消費者が特定時期においてデンマークの全体的な経済状況と家計状況に関して消費者がどう見ているかを尋ね、消費者マインドを測定する。現在はオムニバス方式で調査が行われ、調査対象はデンマークに居住する16～74歳の人口の中から約1,500人を抽出している。',
  'consumer_confidence.description:ja:Austria':
    'オーストリアでは消費者信頼感指数は、今後12カ月間の全般的な経済状況、家計の財務状況、失業（符号反転）、貯蓄に関する予想を調査し、その回答のバランス値の算術平均をとったものである（パーセントポイント値）。バランス値は季節調整される。',
  'consumer_confidence.description:ja:Sweden':
    'スウェーデンでは、消費者信頼感指数は毎月1,500世帯に個人的な財務状況とスウェーデン経済の状況、金利とインフレ予想、大型購入や貯蓄の計画などについて消費者の評価を尋ね、その調査結果を基に算出される。',
  'consumer_confidence.description:ja:South Korea':
    '韓国では、総合消費者信頼感指数（CCSI）で経済状況に関する消費者楽観度が測定される。同指数は約2,200世帯の調査に基づき、現在の生活水準、予想される世帯所得と支出を含む6つの変数から構成される。CCSIが100を超えれば見通しが改善していることを、100を切れば悪化していることを示す。',
  'consumer_confidence.description:ja:Brazil':
    'ブラジルの全国消費者期待感指数（INEC）はブラジル都市からランダムに抽出した約2,000人の個人を対象に調査したもので、消費者の現在の経済状況と、今後6カ月間におけるインフレ率、失業率、賃金、大型購入計画に関する消費者の予想について質問する。この消費者信頼感指数はディフュージョン指数で、値は0から100までの間であり、50を超える値は信頼感が高まっていることを、50を切る値は信頼感が低下していることを示す。',
  'consumer_confidence.description:ja:Switzerland':
    'スイスでは毎年、1月、4月、7月、10月に消費者信頼感調査が実施されている。約1,000世帯を対象に、経済状況、その世帯の財務状況、インフレ、雇用の安定性などに関する主観的な評価について、経済担当国家事務局が質問する。一般的に失業率が低くGDP成長率が高ければ消費者信頼感は高い。消費者信頼感の平均値という指標は、消費者がどれくらい支出する可能性があるかを予測する際に有益となる。',
  'ppi_output_yoy.description:ja:DEFAULT': '',
  'ppi_output_yoy.description:ja:United Kingdom':
    '英国では、生産者物価指数（PPI）は、生産者が売買した財の1カ月間の価格変動を測定するもので、インフレに関する重要な測定値である。工場渡し価格（アウトプット価格）は英国の製造業者が販売する財の価格で、人件費、原材料費、光熱費、借入利息、敷地や建物の維持費、賃料などが含まれ、税金は除く。。',
  'world_economic_forum_-_davos.description:ja:DEFAULT': '',
  'world_economic_forum_-_davos.description:ja:Switzerland': '',
  'good_friday.description:ja:DEFAULT': '',
  'good_friday.description:ja:Hungary': '',
  'good_friday.description:ja:United States': '',
  'good_friday.description:ja:Netherlands': '',
  'good_friday.description:ja:Switzerland': '',
  'good_friday.description:ja:Brazil': '',
  'good_friday.description:ja:Belgium': '',
  'good_friday.description:ja:France': '',
  'good_friday.description:ja:South Africa': '',
  'good_friday.description:ja:Australia': '',
  'good_friday.description:ja:Singapore': '',
  'good_friday.description:ja:Germany': '',
  'good_friday.description:ja:Austria': '',
  'good_friday.description:ja:Sweden': '',
  'good_friday.description:ja:Finland': '',
  'good_friday.description:ja:Colombia': '',
  'good_friday.description:ja:Philippines': '',
  'good_friday.description:ja:Hong Kong': '',
  'good_friday.description:ja:Canada': '',
  'good_friday.description:ja:Indonesia': '',
  'good_friday.description:ja:United Kingdom': '',
  'good_friday.description:ja:Ireland': '',
  'good_friday.description:ja:Denmark': '',
  'good_friday.description:ja:Czech Republic': '',
  'good_friday.description:ja:Spain': '',
  'good_friday.description:ja:India': '',
  'good_friday.description:ja:New Zealand': '',
  'good_friday.description:ja:Mexico': '',
  'good_friday.description:ja:Norway': '',
  'good_friday.description:ja:Portugal': '',
  'snb_maechler_speech.description:ja:DEFAULT': '',
  'snb_maechler_speech.description:ja:Switzerland':
    'スイスではスイス国立銀行（SNB）が金利を決定し、公式金利はSNBの政策金利である。SNBは有担保短期スイスフラン建てマネーマーケット金利をSNBの政策金利近傍に維持することを目標としている。今日、これらの最も代表的な金利はSARONである。2019年６月13日時点で従来SNBが金融政策戦略に使用していた３カ月物スイスフランLIBOR（ロンドン銀行間取引金利）の目標レンジに代わり、SNB政策金利が採用されることになった。その理由はLIBORを決定するためのマネーマーケット取引が不足し、LIBORが最も重要な参照金利としての重要性を失いつつあったためである。2011年9月6日から2015年1月15日までの期間の主な注目点は最低為替レートとして1EUR＝1.20CHFをSNBが強制執行したことであった。2014年12月18日にSNBは要求払い預金金利をマイナス0.25％とすることを決定した。マイナス金利導入の発表に伴い、当時使われていたLIBORの目標レンジは初めてマイナス圏となった。2015年1月15日、SNBは要求払い預金金利をさらにマイナス0.75％に引き下げ、目標レンジをマイナス1.25％～マイナス0.25％の間とした。2015年1月22日以来マイナス金利が適用されており、現在SNBの政策金利に対応している。',
  'wage_inflation_mom.description:ja:DEFAULT': '',
  'wage_inflation_mom.description:ja:Italy':
    'イタリアでは賃金伸び率は時給指数の年次変化を指し、労働協約によって設定される契約上の時給や月給の推移を示す。',
  'external_debt.description:ja:DEFAULT': '',
  'external_debt.description:ja:South Korea':
    '韓国では、対外債務は国外の債権者に負う合計債務の一部である。',
  'external_debt.description:ja:India':
    'インドでは、対外債務は国外の債権者に負う合計債務の一部である。',
  'external_debt.description:ja:Czech Republic':
    'チェコ共和国では、対外債務は国外の債権者に負う合計債務の一部である。',
  'no_trading.description:ja:DEFAULT': '',
  'no_trading.description:ja:Taiwan': '',
  'davivienda_manufacturing_pmi.description:ja:DEFAULT': '',
  'davivienda_manufacturing_pmi.description:ja:Colombia':
    'コロンビア製造業購買担当者景気指数（PMI）は、約350社の製造業企業の購買管理者に送信されたアンケート調査の結果を集計するもので、対象企業はGDPへの寄与度に基づき、詳細セクターと企業の社員数によって階層化さている。アンケート回答は月半ばに集計され、前月に比べた変化の方向を示す。各調査変数に関してディフュージョンインデックス（DI）が算出され、同指数は「改善」と回答した比率に、「横ばい」と回答した比率の半分を加算して算出する。指数の値は0～50の間で、50を超える場合は全体的に前月からの改善・増加を、50未満の場合は悪化・減少を表す。DIはIHSマークイットが社内開発した手法を用いて季節調整される。この購買担当者指数（PMI）は、新規受注（30％）、生産（25％）、雇用（20％）、サプライヤー納期（15％）、購買品在庫（10％）の5つの指数の加重平均である。PMIの算出においてはサプライヤー納期インデックスは他のインデックスと同じ方向に動くよう、符号を反転させている。',
  'fed_yellen_testimony_hfsc.description:ja:DEFAULT': '',
  'fed_yellen_testimony_hfsc.description:ja:United States': '',
  'legislative_elections.description:ja:DEFAULT': '',
  'legislative_elections.description:ja:Colombia': '',
  'legislative_elections.description:ja:Israel': '',
  'private_new_capital_expenditure_qoq.description:ja:DEFAULT': '',
  'private_new_capital_expenditure_qoq.description:ja:Australia': '',
  'last_day_of_pesach_passover.description:ja:DEFAULT': '',
  'last_day_of_pesach_passover.description:ja:Israel': '',
  'house_of_councillors_election.description:ja:DEFAULT': '',
  'house_of_councillors_election.description:ja:Japan': '',
  'rics_house_price_balance.description:ja:DEFAULT': '',
  'rics_house_price_balance.description:ja:United Kingdom': '',
  'unit_labour_costs_qoq_prel.description:ja:DEFAULT': '',
  'unit_labour_costs_qoq_prel.description:ja:United States':
    '単位労働コストは1時間当たりの報酬と労働生産性または1時間当たりの実質生産量との間の関係を示し、生産者にかかるインフレ圧力の指標として使うことができる。',
  'gnp_yoy.description:ja:DEFAULT': '',
  'gnp_yoy.description:ja:Ireland':
    'アイルランドでは、GNP（国民総生産)は、GDP（国内総生産）と、アイルランドに居住する個人や企業が海外で得た投資収入（金利、利益など）およびアイルランドに居住する個人や企業が海外で得た労働収入（流入）と、アイルランド国内で非居住者が得た同様の収入（流出）との差である海外からの純所得（NFI）との総和である。NFIは２種類の多額の資金フローの差であるため、その規模は四半期ごとに大きく変動する可能性があり、同じ四半期であってもGDPとGNPの成長率が大きく異なる場合がある。',
  'personal_consumption_expenditure_yoy.description:ja:DEFAULT': '',
  'personal_consumption_expenditure_yoy.description:ja:United States': '',
  'total_business_investment_yoy.description:ja:DEFAULT': '',
  'total_business_investment_yoy.description:ja:United Kingdom': '',
  'independence_day_observed.description:ja:DEFAULT': '',
  'independence_day_observed.description:ja:United States': '',
  'factory_orders_mom.description:ja:DEFAULT': '',
  'factory_orders_mom.description:ja:United States':
    '工場受注報告は「製造業者の出荷、在庫、受注（M3）サーベイ」によって集計され、耐久財（全受注の50％）と非耐久財双方の工場受注を表す。このサーベイは通常、耐久財受注報告の1週間後に発表される。。',
  'factory_orders_mom.description:ja:Germany':
    'ドイツの工場受注は製造業者の新規受注量の月次変動を報告する。ドイツの工場受注は地政学的イベントや、一時的な需要ショック、一度限りの取引などによって大きく影響されるため、非常に変動が激しい場合がある。',
  'us_budget_plan_fy_2022.description:ja:DEFAULT': '',
  'us_budget_plan_fy_2022.description:ja:United States':
    '米国の財政収支は、財政歳入から財政支出を差し引いた差額で、プラスであれば財政黒字を示し、マイナスであれば財政赤字を示す。',
  'marine_day.description:ja:DEFAULT': '',
  'marine_day.description:ja:Japan': '',
  'fed_rosengren_speech.description:ja:DEFAULT': '',
  'fed_rosengren_speech.description:ja:United States':
    '米国では、金利設定の権限は連邦準備制度理事会（理事会）と連邦公開市場委員会（FOMC）に分けられている。理事会は、1つ以上の地区連邦準備銀行から提出された勧告を受けて、割引率の変更を決定する。FOMCは、中央銀行の資金量やフェデラルファンド市場金利などの公開市場操作を決定する。',
  'saxony-anhalt_state_election.description:ja:DEFAULT': '',
  'saxony-anhalt_state_election.description:ja:Germany': '',
  'us_monetary_policy_forum.description:ja:DEFAULT': '',
  'us_monetary_policy_forum.description:ja:United States': '',
  'spring_meeting_of_the_world_bank_group-imf.description:ja:DEFAULT': '',
  'spring_meeting_of_the_world_bank_group-imf.description:ja:United States': '',
  'reuters_michigan_consumer_sentiment_prel.description:ja:DEFAULT': '',
  'reuters_michigan_consumer_sentiment_prel.description:ja:United States':
    '消費者信頼感指数は、（1）消費者自身の財務状況の先行き見通し、（2）目先の経済全体に関する先行き見通し、（3）長期的な経済全体に関する先行き見通しの3つの分野に注目するもので、毎月実施されるアンケート調査には50項目の中核的な設問が含まれ、それぞれの設問が消費者の態度と予想を追跡する。消費者調査のサンプルは統計的にアラスカ州とハワイ州を除くすべての米国世帯を代表するように設計され、毎月500人以上に電話でインタビューが実施される。',
  'economic_sentiment.description:ja:DEFAULT': '',
  'economic_sentiment.description:ja:Euro Area':
    'ユーロ圏では、景況感指数は、製造業（指数全体の40％）、サービス業（30％）、消費者（20％）、小売業（5％）、建設業（5％）の信頼感を算出する総合指標（平均＝100）である。',
  'tiradentes_day.description:ja:DEFAULT': '',
  'tiradentes_day.description:ja:Brazil': '',
  'chancellor_hammond_budget_statement.description:ja:DEFAULT': '',
  'chancellor_hammond_budget_statement.description:ja:United Kingdom': '',
  'fx_reserves.description:ja:DEFAULT': '',
  'fx_reserves.description:ja:Switzerland': '',
  'fx_reserves.description:ja:Czech Republic': '',
  'westpac_mni_consumer_sentiment_indicator.description:ja:DEFAULT': '',
  'westpac_mni_consumer_sentiment_indicator.description:ja:China': '',
  'president-elect_trump_speech.description:ja:DEFAULT': '',
  'president-elect_trump_speech.description:ja:United States': '',
  'private_spending_qoq.description:ja:DEFAULT': '',
  'private_spending_qoq.description:ja:Mexico':
    'メキシコでは、消費支出は居住者世帯による消費財とサービスの購入を指す。GDPの1つの構成要素である。',
  'yom_haatzmaut.description:ja:DEFAULT': '',
  'yom_haatzmaut.description:ja:Israel': '',
  'boj_kuroda_speech.description:ja:DEFAULT': '',
  'boj_kuroda_speech.description:ja:Japan': '',
  'us_gov_temporary_funding_expires.description:ja:DEFAULT': '',
  'us_gov_temporary_funding_expires.description:ja:United States': '',
  'trade_balance_yoy.description:ja:DEFAULT': '',
  'trade_balance_yoy.description:ja:New Zealand': '',
  'us-russia_meeting.description:ja:DEFAULT': '',
  'us-russia_meeting.description:ja:Switzerland': '',
  'usmca_trade_deal_signature.description:ja:DEFAULT': '',
  'usmca_trade_deal_signature.description:ja:United States': '',
  'orthodox_whit_monday.description:ja:DEFAULT': '',
  'orthodox_whit_monday.description:ja:Greece': '',
  'employment_change_yoy_prel.description:ja:DEFAULT': '',
  'employment_change_yoy_prel.description:ja:Euro Area':
    'ユーロ圏では雇用変化は、賃金または利益のために働く者と無給家族労働者の数の前四半期比変化を指し、推定値にはフルタイムとパートタイム双方が含まれる。',
  'summit_on_brexit.description:ja:DEFAULT': '',
  'summit_on_brexit.description:ja:European Union': '',
  'christmas_day_observed.description:ja:DEFAULT': '',
  'christmas_day_observed.description:ja:Hong Kong': '',
  'establishment_of_the_czechoslovak_republic.description:ja:DEFAULT': '',
  'establishment_of_the_czechoslovak_republic.description:ja:Czech Republic': '',
  'ecb_nouy_speech.description:ja:DEFAULT': '',
  'ecb_nouy_speech.description:ja:Euro Area': '',
  'early_may_bank_holiday.description:ja:DEFAULT': '',
  'early_may_bank_holiday.description:ja:United Kingdom': '',
  'senate_election.description:ja:DEFAULT': '',
  'senate_election.description:ja:Czech Republic': '',
  'current_account.description:ja:DEFAULT': '',
  'current_account.description:ja:Switzerland':
    '経常収支とは、貿易収支（物品・サービスの輸出額から輸入額を差し引いたもの）、純要素所得（利子や配当金など）、純移転支出（対外援助など）の合計である。',
  'current_account.description:ja:Brazil':
    '経常収支とは、貿易収支（物品・サービスの輸出額から輸入額を差し引いたもの）、純要素所得（利子や配当金など）、純移転支出（対外援助など）の合計である。',
  'current_account.description:ja:Greece':
    '経常収支とは、貿易収支（物品・サービスの輸出額から輸入額を差し引いたもの）、純要素所得（利子や配当金など）、純移転支出（対外援助など）の合計である。',
  'current_account.description:ja:Hong Kong':
    '経常収支とは、貿易収支（物品・サービスの輸出額から輸入額を差し引いたもの）、純要素所得（利子や配当金など）、純移転支出（対外援助など）の合計である。',
  'current_account.description:ja:Netherlands':
    '経常収支とは、貿易収支（物品・サービスの輸出額から輸入額を差し引いたもの）、純要素所得（利子や配当金など）、純移転支出（対外援助など）の合計である。',
  'current_account.description:ja:Italy':
    '経常収支とは、貿易収支（物品・サービスの輸出額から輸入額を差し引いたもの）、純要素所得（利子や配当金など）、純移転支出（対外援助など）の合計である。',
  'current_account.description:ja:Colombia':
    '経常収支とは、貿易収支（物品・サービスの輸出額から輸入額を差し引いたもの）、純要素所得（利子や配当金など）、純移転支出（対外援助など）の合計である。',
  'current_account.description:ja:Euro Area':
    '経常収支とは、貿易収支（物品・サービスの輸出額から輸入額を差し引いたもの）、純要素所得（利子や配当金など）、純移転支出（対外援助など）の合計である。',
  'current_account.description:ja:Singapore':
    '経常収支とは、貿易収支（物品・サービスの輸出額から輸入額を差し引いたもの）、純要素所得（利子や配当金など）、純移転支出（対外援助など）の合計である。',
  'current_account.description:ja:Japan':
    '経常収支とは、貿易収支（物品・サービスの輸出額から輸入額を差し引いたもの）、純要素所得（利子や配当金など）、純移転支出（対外援助など）の合計である。',
  'current_account.description:ja:Mexico':
    '経常収支とは、貿易収支（物品・サービスの輸出額から輸入額を差し引いたもの）、純要素所得（利子や配当金など）、純移転支出（対外援助など）の合計である。',
  'current_account.description:ja:Norway':
    '経常収支とは、貿易収支（物品・サービスの輸出額から輸入額を差し引いたもの）、純要素所得（利子や配当金など）、純移転支出（対外援助など）の合計である。',
  'current_account.description:ja:Austria':
    '経常収支とは、貿易収支（物品・サービスの輸出額から輸入額を差し引いたもの）、純要素所得（利子や配当金など）、純移転支出（対外援助など）の合計である。',
  'current_account.description:ja:Sweden':
    '経常収支とは、貿易収支（物品・サービスの輸出額から輸入額を差し引いたもの）、純要素所得（利子や配当金など）、純移転支出（対外援助など）の合計である。',
  'current_account.description:ja:Australia':
    '経常収支とは、貿易収支（物品・サービスの輸出額から輸入額を差し引いたもの）、純要素所得（利子や配当金など）、純移転支出（対外援助など）の合計である。',
  'current_account.description:ja:Indonesia':
    '経常収支とは、貿易収支（物品・サービスの輸出額から輸入額を差し引いたもの）、純要素所得（利子や配当金など）、純移転支出（対外援助など）の合計である。',
  'current_account.description:ja:Canada':
    '経常収支とは、貿易収支（物品・サービスの輸出額から輸入額を差し引いたもの）、純要素所得（利子や配当金など）、純移転支出（対外援助など）の合計である。',
  'current_account.description:ja:Taiwan':
    '経常収支とは、貿易収支（物品・サービスの輸出額から輸入額を差し引いたもの）、純要素所得（利子や配当金など）、純移転支出（対外援助など）の合計である。',
  'current_account.description:ja:South Korea':
    '経常収支とは、貿易収支（物品・サービスの輸出額から輸入額を差し引いたもの）、純要素所得（利子や配当金など）、純移転支出（対外援助など）の合計である。',
  'current_account.description:ja:United Kingdom':
    '経常収支とは、貿易収支（物品・サービスの輸出額から輸入額を差し引いたもの）、純要素所得（利子や配当金など）、純移転支出（対外援助など）の合計である。',
  'current_account.description:ja:Ireland':
    '経常収支とは、貿易収支（物品・サービスの輸出額から輸入額を差し引いたもの）、純要素所得（利子や配当金など）、純移転支出（対外援助など）の合計である。',
  'current_account.description:ja:Hungary':
    '経常収支とは、貿易収支（物品・サービスの輸出額から輸入額を差し引いたもの）、純要素所得（利子や配当金など）、純移転支出（対外援助など）の合計である。',
  'current_account.description:ja:Israel':
    '経常収支とは、貿易収支（物品・サービスの輸出額から輸入額を差し引いたもの）、純要素所得（利子や配当金など）、純移転支出（対外援助など）の合計である。',
  'current_account.description:ja:United States':
    '経常収支とは、貿易収支（物品・サービスの輸出額から輸入額を差し引いたもの）、純要素所得（利子や配当金など）、純移転支出（対外援助など）の合計である。',
  'current_account.description:ja:Thailand':
    '経常収支とは、貿易収支（物品・サービスの輸出額から輸入額を差し引いたもの）、純要素所得（利子や配当金など）、純移転支出（対外援助など）の合計である。',
  'current_account.description:ja:Germany':
    '経常収支とは、貿易収支（物品・サービスの輸出額から輸入額を差し引いたもの）、純要素所得（利子や配当金など）、純移転支出（対外援助など）の合計である。',
  'current_account.description:ja:Poland':
    '経常収支とは、貿易収支（物品・サービスの輸出額から輸入額を差し引いたもの）、純要素所得（利子や配当金など）、純移転支出（対外援助など）の合計である。',
  'current_account.description:ja:Belgium':
    '経常収支とは、貿易収支（物品・サービスの輸出額から輸入額を差し引いたもの）、純要素所得（利子や配当金など）、純移転支出（対外援助など）の合計である。',
  'current_account.description:ja:France':
    '経常収支とは、貿易収支（物品・サービスの輸出額から輸入額を差し引いたもの）、純要素所得（利子や配当金など）、純移転支出（対外援助など）の合計である。',
  'current_account.description:ja:South Africa':
    '経常収支とは、貿易収支（物品・サービスの輸出額から輸入額を差し引いたもの）、純要素所得（利子や配当金など）、純移転支出（対外援助など）の合計である。',
  'current_account.description:ja:Russia':
    '経常収支とは、貿易収支（物品・サービスの輸出額から輸入額を差し引いたもの）、純要素所得（利子や配当金など）、純移転支出（対外援助など）の合計である。',
  'current_account.description:ja:Spain':
    '経常収支とは、貿易収支（物品・サービスの輸出額から輸入額を差し引いたもの）、純要素所得（利子や配当金など）、純移転支出（対外援助など）の合計である。',
  'current_account.description:ja:Czech Republic':
    '経常収支とは、貿易収支（物品・サービスの輸出額から輸入額を差し引いたもの）、純要素所得（利子や配当金など）、純移転支出（対外援助など）の合計である。',
  'current_account.description:ja:Denmark':
    '経常収支とは、貿易収支（物品・サービスの輸出額から輸入額を差し引いたもの）、純要素所得（利子や配当金など）、純移転支出（対外援助など）の合計である。',
  'current_account.description:ja:Finland':
    '経常収支とは、貿易収支（物品・サービスの輸出額から輸入額を差し引いたもの）、純要素所得（利子や配当金など）、純移転支出（対外援助など）の合計である。',
  'current_account.description:ja:India':
    '経常収支とは、貿易収支（物品・サービスの輸出額から輸入額を差し引いたもの）、純要素所得（利子や配当金など）、純移転支出（対外援助など）の合計である。',
  'current_account.description:ja:New Zealand':
    '経常収支とは、貿易収支（物品・サービスの輸出額から輸入額を差し引いたもの）、純要素所得（利子や配当金など）、純移転支出（対外援助など）の合計である。',
  'current_account.description:ja:China':
    '経常収支とは、貿易収支（物品・サービスの輸出額から輸入額を差し引いたもの）、純要素所得（利子や配当金など）、純移転支出（対外援助など）の合計である。',
  'current_account.description:ja:Portugal':
    '経常収支とは、貿易収支（物品・サービスの輸出額から輸入額を差し引いたもの）、純要素所得（利子や配当金など）、純移転支出（対外援助など）の合計である。',
  'may_no_confidence_vote_result.description:ja:DEFAULT': '',
  'may_no_confidence_vote_result.description:ja:United Kingdom': '',
  'labor_productivity_qoq.description:ja:DEFAULT': '',
  'labor_productivity_qoq.description:ja:Canada':
    'カナダでは、労働生産性は、一定期間における1労働単位当たりの実質生産高である。',
  'ifo_current_conditions.description:ja:DEFAULT': '',
  'ifo_current_conditions.description:ja:Germany': '',
  'eia_heating_oil_stocks_change.description:ja:DEFAULT': '',
  'eia_heating_oil_stocks_change.description:ja:United States': '',
  'nzier_capacity_utilization.description:ja:DEFAULT': '',
  'nzier_capacity_utilization.description:ja:New Zealand':
    'ニュージーランドでは、設備稼働率は生産能力と実際の稼働状況との差を示す。労働力と資本が最も良く活用され、実際の生産量が生産能力に近い時に、稼働率は高くなる。',
  'gdp_growth_mainland_qoq_final.description:ja:DEFAULT': '',
  'gdp_growth_mainland_qoq_final.description:ja:Norway':
    'ノルウェーでは、移動３カ月成長率は、その前の３カ月の期間と比較して算出される。例えば、５-７月期であれば２-4月期と比較される。',
  'queen_s_birthday_day.description:ja:DEFAULT': '',
  'queen_s_birthday_day.description:ja:Australia': '',
  'queen_s_birthday_day.description:ja:New Zealand': '',
  'unemployed_persons_nsa.description:ja:DEFAULT': '',
  'unemployed_persons_nsa.description:ja:Germany':
    'ドイツでは、失業者は、仕事がなく、積極的に職を探している個人を指す。',
  'spring_bank_holiday.description:ja:DEFAULT': '',
  'spring_bank_holiday.description:ja:United Kingdom': '',
  'hsbc_manufacturing_pmi.description:ja:DEFAULT': '',
  'hsbc_manufacturing_pmi.description:ja:China': '',
  'hsbc_manufacturing_pmi.description:ja:Hong Kong':
    '日経香港製造業購買担当者景気指数は製造業300社の調査に基づく製造業の業況を測定する。それぞれ加重された、新規受注（30%）、生産（25%）、雇用（20%）、サプライヤー納期（15%）、購買品在庫（10%）という5つの個別インデックスから構成され、納期インデックスは他のインデックスと比較ができるように符号が反転される。50を超える指数は製造業セクターが前期に比べて拡大していることを、50を切る指数は縮小していることを、50は変化がないことを示す。',
  'hsbc_manufacturing_pmi.description:ja:Indonesia':
    'IHSマークイット・インドネシア製造業購買担当者景気指数（PMI）は製造業400社の調査に基づき、製造業の業況を測定する。それぞれウエート付けされた、新規受注（30%）、生産（25%）、雇用（20%）、サプライヤー納期（15%）、購買品在庫（10%）という5つの個別インデックスから構成され、納期インデックスは他のインデックスと比較できるよう符号が反転される。50を超える指数は製造業セクターが前期に比べて拡大していることを、50を切る指数は縮小していることを、50は変化がないことを示す。',
  'hsbc_manufacturing_pmi.description:ja:South Korea':
    'IHSマークイット韓国製造業購買担当者景気指数（PMI）は製造業400社の調査に基づき、製造業の業況を測定する。それぞれウエート付けされた、新規受注（30%）、生産（25%）、雇用（20%）、サプライヤー納期（15%）、購買品在庫（10%）という5つの個別インデックスから構成され、納期インデックスは他のインデックスと比較できるように符号が反転される。50を超える指数は製造業セクターが前期に比べて拡大していることを、50を切る指数は縮小していることを、50は変化がないことを示す。',
  'hsbc_manufacturing_pmi.description:ja:Taiwan':
    'IHSマークイット台湾製造業購買担当者景気指数（PMI）は製造業300社の調査に基づき、製造業の業況を測定する。それぞれウエート付けされた、新規受注（30%）、生産（25%）、雇用（20%）、サプライヤー納期（15%）、購買品在庫（10%）という5つの個別インデックスから構成され、納期インデックスは他のインデックスと比較できるように符号が反転される。50を超える指数は製造業セクターが前期に比べて拡大していることを、50を切る指数は縮小していることを、50は変化がないことを示す。',
  'hsbc_manufacturing_pmi.description:ja:India':
    'IHSマークイット・インド製造業購買担当者景気指数（PMI）は製造業500社の調査に基づき、製造業の業況を測定する。それぞれウエート付けされた、新規受注（30%）、生産（25%）、雇用（20%）、サプライヤー納期（15%）、購買品在庫（10%）という5つの個別インデックスから構成され、納期インデックスは他のインデックスと比較できるよう符号が反転される。50を超える指数は製造業セクターが前期に比べて拡大していることを、50を切る指数は縮小していることを、50は変化がないことを示す。',
  'hsbc_manufacturing_pmi.description:ja:Poland':
    'マークイット・ポーランド製造業購買担当者景気指数（PMI）は製造業200社の調査に基づき、製造業の業況を測定し、それぞれウエート付けされた、新規受注（30%）、生産（25%）、雇用（20%）、サプライヤー納期（15%）、購買品在庫（10%）という5つの個別インデックスから構成され、納期インデックスは他のインデックスと揃えるため符号が反転される。50を超える指数は製造業セクターが前期に比べて拡大していることを、50を切る指数は縮小していることを、50は変化がないことを示す。',
  'hsbc_manufacturing_pmi.description:ja:Brazil':
    'ブラジルでは、マークイット製造業購買担当者景気指数（PMI）は製造業400社の調査に基づき、製造業の業況を測定し、それぞれウエート付けされた、新規受注（30%）、生産（25%）、雇用（20%）、サプライヤー納期（15%）、購買品在庫（10%）という5つの個別インデックスから構成され、納期インデックスは他のインデックスと揃えるため符号が反転される。50を超える指数は製造業セクターが前期に比べて拡大していることを、50を切る指数は縮小していることを、50は変化がないことを示す。',
  'hsbc_manufacturing_pmi.description:ja:Russia':
    'IHSマークイット・ロシア製造業購買担当者景気指数（PMI）は製造業300社の調査に基づき、製造業の業況を測定し、それぞれウエート付けされた、新規受注（30%）、生産（25%）、雇用（20%）、サプライヤー納期（15%）、購買品在庫（10%）という5つの個別インデックスから構成され、納期インデックスは他のインデックスと揃えるため符号が反転される。50を超える指数は製造業セクターが前期に比べて拡大していることを、50を切る指数は縮小していることを、50は変化がないことを示す。',
  'hsbc_manufacturing_pmi.description:ja:Czech Republic':
    'チョコ共和国では、マークイット製造業購買担当者景気指数（PMI）は製造業250社の調査に基づき、製造業の業況を測定し、それぞれウエート付けされた、新規受注（30%）、生産（25%）、雇用（20%）、サプライヤー納期（15%）、購買品在庫（10%）という5つの個別インデックスから構成され、納期インデックスは他のインデックスと揃えるため符号が反転される。50を超える指数は製造業セクターが前期に比べて拡大していることを、50を切る指数は縮小していることを、50は変化がないことを示す。',
  'hsbc_manufacturing_pmi.description:ja:Mexico':
    'マークイット・メキシコ製造業購買担当者景気指数（PMI）は製造業400社の調査に基づき、製造業の業況を測定し、それぞれウエート付けされた、新規受注（30%）、生産（25%）、雇用（20%）、サプライヤー納期（15%）、購買品在庫（10%）という5つの個別インデックスから構成され、納期インデックスは他のインデックスと揃えるため符号が反転される。50を超える指数は製造業セクターが前期に比べて拡大していることを、50を切る指数は縮小していることを、50は変化がないことを示す。',
  'unemployment_rate_-_final.description:ja:DEFAULT': '',
  'unemployment_rate_-_final.description:ja:Singapore':
    'シンガポールでは、失業率は労働力全体のうち、積極的に仕事を探している人口の比率を測定する。',
  'net_payrolls.description:ja:DEFAULT': '',
  'net_payrolls.description:ja:Brazil':
    'ブラジルでは、雇用者純増数は正規雇用数全体の変化を示し、新規創出から雇用消失を差し引いた数値である。',
  'third_and_fourth_day_of_chinese_new_year.description:ja:DEFAULT': '',
  'third_and_fourth_day_of_chinese_new_year.description:ja:Hong Kong': '',
  'unit_labour_costs_qoq.description:ja:DEFAULT': '',
  'unit_labour_costs_qoq.description:ja:United States':
    '単位労働コストは1時間当たりの報酬と労働生産性または1時間当たりの実質生産量との間の関係を示し、生産者にかかるインフレ圧力の指標として使うことができる。',
  'government_budget_value.description:ja:DEFAULT': '',
  'government_budget_value.description:ja:Brazil':
    'ブラジルでは、政府予算額は、中央政府と地方政府に公的企業から構成される名目財政収支を指し、連邦政府の債務返済費用と名目利息を含む。',
  'government_budget_value.description:ja:India':
    '政府予算収支とは、国家の歳入と歳出の差を示したものである。予算は、歳出が歳入と等しくなった場合に収支均衡し、歳入が歳出を超える場合は黒字、歳出が再入を超える場合は赤字となる。インドでは、政府予算額は現行財務年度における中央政府の累積財政赤字を指す。',
  'government_budget_value.description:ja:Mexico':
    'メキシコの公的予算には、政府の歳出歳入だけでなく予算が間接的管理下にある企業の財務収支と、対外および対内資金調達要件が含まれる。',
  'unemployment_rate_final.description:ja:DEFAULT': '',
  'unemployment_rate_final.description:ja:Singapore':
    'シンガポールでは、失業率は労働力全体のうち、積極的に仕事を探している人口の比率を測定する。',
  'danmarks_nationalbank_economic_forecast.description:ja:DEFAULT': '',
  'danmarks_nationalbank_economic_forecast.description:ja:Denmark': '',
  'unit_labor_costs_qoq.description:ja:DEFAULT': '',
  'unit_labor_costs_qoq.description:ja:United States': '',
  'eu-japan_summit.description:ja:DEFAULT': '',
  'eu-japan_summit.description:ja:Japan': '',
  'eu-japan_summit.description:ja:Euro Area': '',
  'russia-africa_summit.description:ja:DEFAULT': '',
  'russia-africa_summit.description:ja:Russia': '',
  'st_joseph_s_day.description:ja:DEFAULT': '',
  'st_joseph_s_day.description:ja:Spain': '',
  'holiday.description:ja:DEFAULT': '',
  'holiday.description:ja:Japan': '',
  'cb_leading_indicator.description:ja:DEFAULT': '',
  'cb_leading_indicator.description:ja:Australia': '',
  'un_general_assembly.description:ja:DEFAULT': '',
  'un_general_assembly.description:ja:United States': '',
  'td-mi_inflation_gauge_mom.description:ja:DEFAULT': '',
  'td-mi_inflation_gauge_mom.description:ja:Australia': '',
  'referendum_on_constitutional_reform.description:ja:DEFAULT': '',
  'referendum_on_constitutional_reform.description:ja:Italy': '',
  'ecb_president_draghi_speaks.description:ja:DEFAULT': '',
  'ecb_president_draghi_speaks.description:ja:Euro Area': '',
  'joe_biden_s_inauguration.description:ja:DEFAULT': '',
  'joe_biden_s_inauguration.description:ja:United States': '',
  'new_motor_vehicle_sales.description:ja:DEFAULT': '',
  'new_motor_vehicle_sales.description:ja:Canada':
    'カナダでは、新車販売台数は、乗用車とトラックを含み、1カ月の間に販売された新車台数を指す。',
  'simchat_torah_shemini_atzeret.description:ja:DEFAULT': '',
  'simchat_torah_shemini_atzeret.description:ja:Israel': '',
  'boe_inflation_report_hearings.description:ja:DEFAULT': '',
  'boe_inflation_report_hearings.description:ja:United Kingdom': '',
  'producer_price_index_qoq.description:ja:DEFAULT': '',
  'producer_price_index_qoq.description:ja:Australia': '',
  'wholesale_sales_mom_final.description:ja:DEFAULT': '',
  'wholesale_sales_mom_final.description:ja:Canada':
    '月次卸売統計は、カナダの卸売業者による月次売上高の推定値を測定するもので、卸売業の業況に関する情報を提供し、カナダ経済の状況の重要な指標となる。さらにビジネスコミュニティはこのデータを使って市場パフォーマンスを分析する。',
  'core_pce_prices_qoq.description:ja:DEFAULT': '',
  'core_pce_prices_qoq.description:ja:United States': '',
  'regional_elections_-_1st_round.description:ja:DEFAULT': '',
  'regional_elections_-_1st_round.description:ja:France': '',
  'ecb_president_lagarde_testimony.description:ja:DEFAULT': '',
  'ecb_president_lagarde_testimony.description:ja:Euro Area':
    'ユーロ圏の基準金利は欧州中央銀行（ECB）の理事会によって決定される。ECBの金融政策の主な目的は、物価の安定を維持することであり、それはインフレ率を中期的に2％未満でかつそれに近い水準に抑えることである。低インフレ・低金利が長期化した場合、ECBは資産購入プログラムなどの非標準的な金融政策を採用することもある。公式金利は、主要リファイナンスオペ金利である。。',
  'rba_edey_speech.description:ja:DEFAULT': '',
  'rba_edey_speech.description:ja:Australia': '',
  'anz_job_advertisements_mom.description:ja:DEFAULT': '',
  'anz_job_advertisements_mom.description:ja:Australia':
    'オーストラリアの主要都市の大手新聞およびインターネットサイトの求人広告件数を測るものである。',
  'unemployment_rate.description:ja:DEFAULT': '',
  'unemployment_rate.description:ja:Brazil':
    'ブラジルでは、失業率は労働力全体のうち、積極的に仕事を探している人口の比率を測定する。',
  'the_queens_birthday.description:ja:DEFAULT': '',
  'the_queens_birthday.description:ja:Thailand': '',
  'fed_evans_speech.description:ja:DEFAULT': '',
  'fed_evans_speech.description:ja:Euro Area':
    'ユーロ圏の基準金利は欧州中央銀行（ECB）の理事会によって決定される。ECBの金融政策の主な目的は、物価の安定を維持することであり、それはインフレ率を中期的に2％未満でかつそれに近い水準に抑えることである。低インフレ・低金利が長期化した場合、ECBは資産購入プログラムなどの非標準的な金融政策を採用することもある。公式金利は、主要リファイナンスオペ金利である。。',
  'fed_evans_speech.description:ja:United States':
    '米国では、金利設定の権限は連邦準備制度理事会（理事会）と連邦公開市場委員会（FOMC）に分けられている。理事会は、1つ以上の地区連邦準備銀行から提出された勧告を受けて、割引率の変更を決定する。FOMCは、中央銀行の資金量やフェデラルファンド市場金利などの公開市場操作を決定する。',
  'union_budget_2020.description:ja:DEFAULT': '',
  'union_budget_2020.description:ja:India': '',
  'uk-eu_brexit_talks.description:ja:DEFAULT': '',
  'uk-eu_brexit_talks.description:ja:United Kingdom': '',
  '6-month_t-bill_auction.description:ja:DEFAULT': '',
  '6-month_t-bill_auction.description:ja:Netherlands': '',
  'union_budget_2021-22.description:ja:DEFAULT': '',
  'union_budget_2021-22.description:ja:India': '',
  'reuters_michigan_consumer_expectations.description:ja:DEFAULT': '',
  'reuters_michigan_consumer_expectations.description:ja:United States':
    '消費者信頼感指数は、（1）消費者自身の財務状況の先行き見通し、（2）目先の経済全体に関する先行き見通し、（3）長期的な経済全体に関する先行き見通しの3つの分野に注目するもので、毎月実施されるアンケート調査には50項目の中核的な設問が含まれ、それぞれの設問が消費者の態度と予想を追跡する。消費者調査のサンプルは統計的にアラスカ州とハワイ州を除くすべての米国世帯を代表するように設計され、毎月500人以上に電話でインタビューが実施される。',
  'ecofin_video_conference.description:ja:DEFAULT': '',
  'ecofin_video_conference.description:ja:European Union': '',
  'rbnz_wheeler_speech.description:ja:DEFAULT': '',
  'rbnz_wheeler_speech.description:ja:New Zealand': '',
  'unit_labor_cost_qoq.description:ja:DEFAULT': '',
  'unit_labor_cost_qoq.description:ja:United States': '',
  'april_monetary_policy.description:ja:DEFAULT': '',
  'april_monetary_policy.description:ja:Singapore': '',
  'inflation_rate_ex-fresh_food_yoy.description:ja:DEFAULT': '',
  'inflation_rate_ex-fresh_food_yoy.description:ja:Japan': '',
  'palm_sunday.description:ja:DEFAULT': '',
  'palm_sunday.description:ja:Denmark': '',
  'palm_sunday.description:ja:Norway': '',
  'gdp_growth_rate_annualized.description:ja:DEFAULT': '',
  'gdp_growth_rate_annualized.description:ja:Canada':
    'GDP年率換算成長率は、前四半期比の変化が1年を通して維持されたと仮定した場合のGDP成長率を示す。支出面では、家計消費がGDPの主要コンポーネントで、全体の58％を占め、それに総固定資本形成（22％）と政府支出（19％）が続く。財とサービスの輸出はGDP全体の32％を占め、輸入は33％であるため、GDP全体に1％のマイナス寄与となっている。対家計非営利団体（NPISH）の支出と在庫投資が残りの2％を占める。',
  'wholesale_prices_nsa_yoy.description:ja:DEFAULT': '',
  'wholesale_prices_nsa_yoy.description:ja:Austria':
    '卸売物価指数は卸売業者が販売する財の価格動向を反映し、毎月約200社の卸売業者が自主的に約1400種の卸売販売価格（VATを除く）を提供することで、財バスケットに含まれる387項目の価格が調査される。',
  '13-weeks_bill_auction.description:ja:DEFAULT': '',
  '13-weeks_bill_auction.description:ja:Greece': '',
  'average_weekly_hours.description:ja:DEFAULT': '',
  'average_weekly_hours.description:ja:United States':
    '週平均労働時間は1週間当たりの合計労働時間をそれら労働時間に対して賃金を払われた労働者数で割ったもので、企業にとっては無給欠勤や離職、パートタイム勤務、ストライキなどが予定労働時間よりも低い平均労働時間の理由となる。',
  'ise_economic_activity_yoy.description:ja:DEFAULT': '',
  'ise_economic_activity_yoy.description:ja:Colombia':
    'コロンビアでは、ISE経済先行指標が１カ月間における様々な経済セクターの活動と将来のGDP推移予想の指標となる。同指数の78.8%は工業、農業、その他セクターの生産活動が占め、米、果物、牛乳、履物、飲料など特定商品が残りの21.2%を占める。',
  '5-year_btp_auction.description:ja:DEFAULT': '',
  '5-year_btp_auction.description:ja:Italy': '',
  'restoration_of_independence_day.description:ja:DEFAULT': '',
  'restoration_of_independence_day.description:ja:Portugal': '',
  'gdp_growth_annualized_prel.description:ja:DEFAULT': '',
  'gdp_growth_annualized_prel.description:ja:Japan':
    'GDP年率成長率は、前四半期比のGDP成長率が1年を通じて維持されたと仮定した場合の成長率を示す。',
  'gdp_annual_growth_rate_yoy_-_preliminary.description:ja:DEFAULT': '',
  'gdp_annual_growth_rate_yoy_-_preliminary.description:ja:Greece':
    'ギリシャでは家計消費がGDPの主な構成要素であり、その総支出の72％を占めており、次いで政府支出（20％）、総固定資本形成（12％）となっている。財・サービスの輸出はGDPの33%、輸入は35%であるため、GDP合計に2%のマイナス寄与となっている。',
  'eurogroup_meeting.description:ja:DEFAULT': '',
  'eurogroup_meeting.description:ja:Euro Area': '',
  'eu-india_summit.description:ja:DEFAULT': '',
  'eu-india_summit.description:ja:European Union': '',
  'eu-india_summit.description:ja:India': '',
  'prime_minister_modi_speech.description:ja:DEFAULT': '',
  'prime_minister_modi_speech.description:ja:India': '',
  'markit_cips_manufacturing_pmi_flash.description:ja:DEFAULT': '',
  'markit_cips_manufacturing_pmi_flash.description:ja:United Kingdom':
    'マークイット/CIPS英国製造業購買担当者景気指数（PMI）は製造業600社の調査に基づき、製造業の業況を測定し、それぞれウエート付けされた、新規受注（30%）、生産（25%）、雇用（20%）、サプライヤー納期（15%）、購買品在庫（10%）という5つの個別インデックスから構成され、納期インデックスは比較可能にするため符号が反転される。50を超える指数は製造業セクターが前期に比べて拡大していることを、50を切る指数は縮小していることを、50は変化がないことを示す。',
  'new_year_s_day.description:ja:DEFAULT': '',
  'new_year_s_day.description:ja:Thailand': '',
  'new_year_s_day.description:ja:Taiwan': '',
  'new_year_s_day.description:ja:Switzerland': '',
  'new_year_s_day.description:ja:Singapore': '',
  'new_year_s_day.description:ja:Portugal': '',
  'new_year_s_day.description:ja:Spain': '',
  'new_year_s_day.description:ja:Russia': '',
  'new_year_s_day.description:ja:Denmark': '',
  'new_year_s_day.description:ja:Czech Republic': '',
  'new_year_s_day.description:ja:Brazil': '',
  'new_year_s_day.description:ja:Sweden': '',
  'new_year_s_day.description:ja:Austria': '',
  'new_year_s_day.description:ja:United Kingdom': '',
  'new_year_s_day.description:ja:Ireland': '',
  'new_year_s_day.description:ja:Canada': '',
  'new_year_s_day.description:ja:Indonesia': '',
  'new_year_s_day.description:ja:Australia': '',
  'new_year_s_day.description:ja:Italy': '',
  'new_year_s_day.description:ja:Netherlands': '',
  'new_year_s_day.description:ja:Finland': '',
  'new_year_s_day.description:ja:Colombia': '',
  'new_year_s_day.description:ja:Philippines': '',
  'new_year_s_day.description:ja:Belgium': '',
  'new_year_s_day.description:ja:France': '',
  'new_year_s_day.description:ja:Norway': '',
  'new_year_s_day.description:ja:Mexico': '',
  'new_year_s_day.description:ja:Poland': '',
  'new_year_s_day.description:ja:Germany': '',
  'new_year_s_day.description:ja:Greece': '',
  'new_year_s_day.description:ja:Hong Kong': '',
  'new_year_s_day.description:ja:Hungary': '',
  'new_year_s_day.description:ja:New Zealand': '',
  'summer_bank_holiday.description:ja:DEFAULT': '',
  'summer_bank_holiday.description:ja:United Kingdom': '',
  'producer_price_index_ex_food_energy_yoy.description:ja:DEFAULT': '',
  'producer_price_index_ex_food_energy_yoy.description:ja:United States': '',
  'markit_cips_composite_pmi_final.description:ja:DEFAULT': '',
  'markit_cips_composite_pmi_final.description:ja:United Kingdom':
    '英国総合PMIは、製造業生産指数とサービス業活動指数の加重平均値である。',
  'rizal_day.description:ja:DEFAULT': '',
  'rizal_day.description:ja:Philippines': '',
  'fdi_ytd_yoy.description:ja:DEFAULT': '',
  'fdi_ytd_yoy.description:ja:China':
    '中国では、海外直接投資は1年間における非金融セクターの国内企業または法人に対する累積海外投資を示す。',
  'westpac_consumer_confidence_index.description:ja:DEFAULT': '',
  'westpac_consumer_confidence_index.description:ja:Australia':
    '消費者信頼感指数は1200以上のオーストラリアの世帯を調査した結果に基づいて算出される。この指数は、過去1年間と向こう1年間の消費者の財務状況に対する考えと、今後1年および5年間の経済状況予想、大型商品の購入状況など、5つの項目の平均をとったもので、指数が100を超えると悲観的な見方よりも楽観的な見方が多いことを示す。',
  'employment_cost_-_wages_qoq.description:ja:DEFAULT': '',
  'employment_cost_-_wages_qoq.description:ja:United States': '',
  'state_of_the_nation_address.description:ja:DEFAULT': '',
  'state_of_the_nation_address.description:ja:South Africa': '',
  'ny_fed_treasury_purchases_7_5_to_30_yrs.description:ja:DEFAULT': '',
  'ny_fed_treasury_purchases_7_5_to_30_yrs.description:ja:United States': '',
  'business_climate.description:ja:DEFAULT': '',
  'business_climate.description:ja:Euro Area': '',
  'terms_of_trade_index.description:ja:DEFAULT': '',
  'terms_of_trade_index.description:ja:New Zealand': '',
  'usd_consumption_indicator.description:ja:DEFAULT': '',
  'usd_consumption_indicator.description:ja:Switzerland': '',
  'ecb_general_council_meeting.description:ja:DEFAULT': '',
  'ecb_general_council_meeting.description:ja:Euro Area':
    'ユーロ圏の基準金利は欧州中央銀行（ECB）の理事会によって決定される。ECBの金融政策の主な目的は、物価の安定を維持することであり、それはインフレ率を中期的に2％未満でかつそれに近い水準に抑えることである。低インフレ・低金利が長期化した場合、ECBは資産購入プログラムなどの非標準的な金融政策を採用することもある。公式金利は、主要リファイナンスオペ金利である。。',
  'rba_boulton_speech.description:ja:DEFAULT': '',
  'rba_boulton_speech.description:ja:Australia': '',
  'rejoicing_of_the_law.description:ja:DEFAULT': '',
  'rejoicing_of_the_law.description:ja:Israel': '',
  'new_year_s_day.description:ja:South Korea': '',
  'new_year_s_day.description:ja:Japan': '',
  'new_year_s_day.description:ja:South Africa': '',
  'new_year_s_day.description:ja:China': '',
  'new_year_s_day.description:ja:United States': '',
  'industrial_production_mom_prel.description:ja:DEFAULT': '',
  'industrial_production_mom_prel.description:ja:Japan':
    '日本では鉱工業生産は製造業、鉱業、公益事業など経済の鉱工業セクターに属する事業の生産量を測定する。',
  'new_housing_price_index_yoy.description:ja:DEFAULT': '',
  'new_housing_price_index_yoy.description:ja:Canada':
    'カナダでは新築住宅価格指数（NHPI）は建設業者による新築住宅販売価格の変化を測定する。2つの連続した期間における各住宅の仕様は同じである。',
  'niesr_monthly_gdp_tracker.description:ja:DEFAULT': '',
  'niesr_monthly_gdp_tracker.description:ja:United Kingdom': '',
  'swedbank_manufacturing_pmi.description:ja:DEFAULT': '',
  'swedbank_manufacturing_pmi.description:ja:Sweden':
    'スウェーデンでは、スウェドバンク製造業購買担当者景気指数（PMI）は製造業200社の調査に基づき、製造業の業況を測定し、それぞれウエート付けされた、新規受注（30%）、生産（25%）、雇用（20%）、サプライヤー納期（15%）、購買品在庫（10%）という5つの個別インデックスから構成され、納期インデックスは比較可能にするため符号が反転される。50を超える指数は製造業セクターが前期に比べて拡大していることを、50を切る指数は縮小していることを、50は変化がないことを示す。',
  'wholesale_prices_nsa_mom.description:ja:DEFAULT': '',
  'wholesale_prices_nsa_mom.description:ja:Austria':
    '卸売物価指数は卸売業者が販売する財の価格動向を反映し、毎月約200社の卸売業者が自主的に約1400種の卸売販売価格（VATを除く）を提供することで、財バスケットに含まれる387項目の価格が調査される。',
  'the_ochi_day.description:ja:DEFAULT': '',
  'the_ochi_day.description:ja:Greece': '',
  'balance_of_trade_-_final.description:ja:DEFAULT': '',
  'balance_of_trade_-_final.description:ja:South Korea':
    '韓国は2019年、半導体、石油化学品、工業用石油製品の各業界の悪化、さらに米中貿易摩擦、日本の輸出規制、ブレグジット、香港の政情不安にもかかわらず、392億米ドルの貿易黒字となり11年連続輸出超過国となった。',
  'jobs_applications_ratio.description:ja:DEFAULT': '',
  'jobs_applications_ratio.description:ja:Japan':
    'このデータは、有効求人数の求職者数に対する比率で、全国の求人数（前月から繰り越された求人数と新たな求人数の合計）を公的な職業紹介所（「ハローワーク」）に登録している求職者数で割って算出し、求職者1人当たりの求人数を示す。この比率は毎月の有効求人数を有効求職数で割って算出される。',
  'virgin_of_guadalupe_day.description:ja:DEFAULT': '',
  'virgin_of_guadalupe_day.description:ja:Mexico': '',
  'housing_starts.description:ja:DEFAULT': '',
  'housing_starts.description:ja:United States':
    '住宅着工件数は、1カ月の間に工事が開始した新築住宅の建設工事数を指す。住宅着工件数の推定値には、既存の構造物の土台の上に建てられる、完全に建て直される建築物も含まれる。',
  'housing_starts.description:ja:Canada':
    'カナダでは、住宅着工件数は、住居となる建物の工事開始として定義され、工事の開始時期としては、通常は構造物の基礎全体にコンクリートが打ち込まれた段階か、または地下部分が構造物の一部とならない場合はその相当段階の2通りがある。',
  'home_loans_mom.description:ja:DEFAULT': '',
  'home_loans_mom.description:ja:Australia':
    'オーストラリアでは、住宅ローンは所有者が占有する住宅のために供与される新規ローンの価値を指す。住宅ユニットは、所有者または共同所有者がそこに住む場合に、所有者が占有するとみなされる。これは住宅市場需要の先行指標である。',
  'wwii_victory_day.description:ja:DEFAULT': '',
  'wwii_victory_day.description:ja:France': '',
  'president_trump_and_president_xi_jinping_meeting.description:ja:DEFAULT': '',
  'president_trump_and_president_xi_jinping_meeting.description:ja:United States': '',
  'nzier_business_confidence_qoq.description:ja:DEFAULT': '',
  'nzier_business_confidence_qoq.description:ja:New Zealand': '',
  'total_net_tic_flows.description:ja:DEFAULT': '',
  'total_net_tic_flows.description:ja:United States': '',
  'cnb_interest_rate_decision.description:ja:DEFAULT': '',
  'cnb_interest_rate_decision.description:ja:Czech Republic':
    'チェコ共和国の基準金利はチェコ国立銀行（Cesky Národní Bank、CNB）が設定する。公式金利は、商業銀行が終業時の余剰資金を中央銀行に預ける際の2週間物のレポ金利である。',
  'raw_materials_price_index_yoy.description:ja:DEFAULT': '',
  'raw_materials_price_index_yoy.description:ja:Canada': '',
  'feast_of_purim_jerusalem.description:ja:DEFAULT': '',
  'feast_of_purim_jerusalem.description:ja:Israel': '',
  'queen_suthida_s_birthday.description:ja:DEFAULT': '',
  'queen_suthida_s_birthday.description:ja:Thailand': '',
  'unemployment_rate.description:ja:Italy':
    'イタリアでは、失業率は労働力全体のうち、積極的に仕事を探している人口の比率を測定する。',
  'ura_property_index_qoq_prel.description:ja:DEFAULT': '',
  'ura_property_index_qoq_prel.description:ja:Singapore':
    'シンガポールでは、住宅指数は総合住宅不動産価格指数によって測定される。',
  'primary_budget_surplus.description:ja:DEFAULT': '',
  'primary_budget_surplus.description:ja:Brazil': '',
  'unemployment_rate.description:ja:Austria':
    'オーストリアでは、失業率は労働力全体のうち、積極的に仕事を探している人口の比率を測定する。',
  'wholesale_prices_yoy.description:ja:DEFAULT': '',
  'wholesale_prices_yoy.description:ja:India':
    '2013年に消費者物価指数が卸売物価指数（WPI）に代わりインドのインフレ率の主要指標となった。インドでは消費者物価指数の最も重要なカテゴリーは食品・飲料（全ウェートの45.86%）であり、うち穀物と穀類製品が9.67%、牛乳と乳製品が6.61%、野菜が6.04%、加工食品・スナック・菓子等が5.55%、肉・魚類が3.61%、油と油脂が3.56%である。その他カテゴリーは28.32%を占め、うち輸送・通信が8.59%、医療が5.89%、教育が4.46%。住居カテゴリーは10.07%、燃料と照明は6.84%、衣類・履物は6.53%、インドの嗜好品パーン、タバコ、酒類は2.38％。インドでは、輸入エネルギーへの依存度が高いことと劣悪な道路状況とインフラにより食品を市場に輸送するのが困難であること、そして多額の財政赤字により、消費者物価指数は非常に変動が激しい。',
  'wholesale_prices_yoy.description:ja:Germany':
    '卸売価格指数は、ドイツ国内で卸売業者が販売する、再販業者や加工業者、またはその他のバルク購入者との契約など、卸売販売に含まれる財の価格動向を測定する。これにはドイツで生産された品または輸入品が含まれる場合があり、生産者が小売業者または最終消費者に直接販売したものは除く。',
  'wholesale_prices_yoy.description:ja:Taiwan':
    '卸売価格指数は台湾地域における材料、半製品、完成品の販売価格の平均的な変化を測定する。',
  'wholesale_prices_yoy.description:ja:Austria':
    '卸売物価指数は卸売業者が販売する財の価格動向を反映し、毎月約200社の卸売業者が自主的に約1400種の卸売販売価格（VATを除く）を提供することで、財バスケットに含まれる387項目の価格が調査される。',
  'wholesale_prices_yoy.description:ja:Ireland':
    '生産者物価変動は、一定期間中に卸売市場で製造者や生産者が販売した財やサービスの価格の前年比変化を示す。',
  'pm_abe_press_conference.description:ja:DEFAULT': '',
  'pm_abe_press_conference.description:ja:Japan': '',
  'brc_retail_sales_monitor_-_all_yoy.description:ja:DEFAULT': '',
  'brc_retail_sales_monitor_-_all_yoy.description:ja:United Kingdom': '',
  'jibun_bank_services_pmi_flash.description:ja:DEFAULT': '',
  'jibun_bank_services_pmi_flash.description:ja:Japan':
    '日本のサービス業（PMI）は、日本の民間サービス業を代表する400社を超える企業のパネルから収集したデータを基に集計される。指数は、売上高、雇用、在庫、価格などの変数を追跡し、50を超えれば、サービス業が全体的に拡大傾向にあることを示し、50を下回れば、全般に縮小傾向にあることを示す。',
  'balance_of_trade_s_a.description:ja:DEFAULT': '',
  'balance_of_trade_s_a.description:ja:Germany': '',
  'industrial_production_yoy_prel.description:ja:DEFAULT': '',
  'industrial_production_yoy_prel.description:ja:Japan':
    '日本では鉱工業生産は製造業、鉱業、公益事業など経済の鉱工業セクターに属する事業の生産量を測定する。',
  'us_budget_plan_fy_2021.description:ja:DEFAULT': '',
  'us_budget_plan_fy_2021.description:ja:United States':
    '米国の財政収支は、財政歳入から財政支出を差し引いた差額で、プラスであれば財政黒字を示し、マイナスであれば財政赤字を示す。',
  'winter_economy_plan.description:ja:DEFAULT': '',
  'winter_economy_plan.description:ja:United Kingdom': '',
  'yom_haatzmaut_independence_day.description:ja:DEFAULT': '',
  'yom_haatzmaut_independence_day.description:ja:Israel': '',
  'wholesale_prices_n_s_a_yoy.description:ja:DEFAULT': '',
  'wholesale_prices_n_s_a_yoy.description:ja:Austria':
    '卸売物価指数は卸売業者が販売する財の価格動向を反映し、毎月約200社の卸売業者が自主的に約1400種の卸売販売価格（VATを除く）を提供することで、財バスケットに含まれる387項目の価格が調査される。',
  'euro-south_korea_summit.description:ja:DEFAULT': '',
  'euro-south_korea_summit.description:ja:Euro Area': '',
  'industrail_sales_mom.description:ja:DEFAULT': '',
  'industrail_sales_mom.description:ja:Italy': '',
  'womens_day.description:ja:DEFAULT': '',
  'womens_day.description:ja:South Africa': '',
  'northern_ireland_assembly_election.description:ja:DEFAULT': '',
  'northern_ireland_assembly_election.description:ja:United Kingdom': '',
  'westpac_cons_conf_change.description:ja:DEFAULT': '',
  'westpac_cons_conf_change.description:ja:Australia':
    '消費者信頼感指数は1200以上のオーストラリアの世帯を調査した結果に基づいて算出される。この指数は、過去1年間と向こう1年間の消費者の財務状況に対する考えと、今後1年および5年間の経済状況予想、大型商品の購入状況など、5つの項目の平均をとったもので、指数が100を超えると悲観的な見方よりも楽観的な見方が多いことを示す。',
  'us-north_korea_summit.description:ja:DEFAULT': '',
  'us-north_korea_summit.description:ja:United States': '',
  'purchasing_manager_index_services.description:ja:DEFAULT': '',
  'purchasing_manager_index_services.description:ja:Ireland': '',
  'industrial_production_yoy.description:ja:DEFAULT': '',
  'industrial_production_yoy.description:ja:United States':
    '米国では鉱工業生産は経済の鉱工業セクターに属する事業の生産量を測定する。製造業は最も重要なセクターで、全生産量の78%を占める。製造業の中でも最大の部門は、化学（12%）、食料品・飲料・タバコ（11%）、機械（6%）、金属製品（6%）、コンピューター・電子製品（6%）、自動車と自動車部品（6%）。鉱業と採石業は11%、公益事業が残りの11%を占める。',
  'industrial_production_yoy.description:ja:Hong Kong':
    '香港では鉱工業生産は製造業、鉱業、公益事業など経済の鉱工業セクターに属する事業の生産量を測定する。',
  'industrial_production_yoy.description:ja:New Zealand':
    'ニュージーランドでは製造業売上高は、同国経済の製造業セクターにおける売上高の前年比変化を示す。この指標には、（1）肉類・乳製品、（2）水産加工品、（3）果物・油・シリアル・その他食品、（3）飲料・タバコ、（5）繊維・革・衣類・履物、（6）材木・紙製品、（7）印刷、（8）石油・石炭製品、（9）化学品・ポリマー、ゴム製品、（10）非金属鉱物、（11）金属製品、（12）輸送機器・機械・装置および家具の12業界が含まれる。',
  'industrial_production_yoy.description:ja:India':
    'インドでは鉱工業生産は経済の鉱工業セクターに属する事業の生産量を測定する。製造業は最も重要なセクターで、全生産量の78%を占める。製造業の中でも最大の部門は、卑金属（13%）、コークスと精製石油製品（12%）、化学と化学製品（8%）、食料品（5%）、医薬品、薬用化学および植物製品（5%）、自動車、トレーラー、セミトレーラー（5%）、他に分類されない機械・機器（5%）、その他の非金属鉱物製品（4%）、 繊維（3%）、電気的装置（3%）、金属加工製品（3%）。鉱業は全体の生産量の14%、電力は8%を占める。',
  'industrial_production_yoy.description:ja:Austria':
    'オーストリアでは鉱工業生産は経済の鉱工業セクターに属する事業の生産量を測定する。製造業は最も重要なセクターで、全生産量の69%を占め、以下が含まれる：機械・機器（9％）、卑金属（6％）、食料品（6％）、自動車・トレーラー・セミトレーラー（6％）、機械・金属を除く金属加工製品 （6％）、電気的装置（5％）。電力・がス・蒸気・空調は全生産量の15％を占め、それに建設（13％）、上下水道・廃棄物管理（2％）、鉱業・採石業（1％）が続く。',
  'industrial_production_yoy.description:ja:Sweden':
    'スウェーデンでは鉱工業生産は製造業、鉱業など経済の鉱工業セクターに属する事業の生産量を測定する。',
  'industrial_production_yoy.description:ja:Poland':
    'ポーランドでは鉱工業生産は経済の鉱工業セクターに属する事業の生産量を測定する。製造業は最も重要なセクターで、全生産量の86%を占める。製造業の中でも最大の部門は、食料品（全生産量の16％） 、自動車・トレーラー・セミトレーラー（10％）、金属製品（7％）、ゴム・プラスチック製品（6％）、電気的装置（5％）、コークス・精製石油製品（5％）、化学薬品・化学製品（4％）、その他非金属鉱物製品（4％）、卑金属（4％）。 電力・ガス・蒸気・空調は全生産量の8％、鉱業・採石業は3％、上下水道・廃棄物管理・修理は2％を占める。',
  'industrial_production_yoy.description:ja:Germany':
    'ドイツでは鉱工業生産は経済の鉱工業セクターに属する事業の生産量を測定する。製造業は最も重要なセクターで、全生産量の79％を占める。製造業の中でも最大の部門は、機械・機器（全生産量の12％）、 自動車・トレーラー・セミトレーラー（12％）、卑金属・金属加工製品（10％）、コンピューター、電子・光学製品および電気的装置（10％）。建設業は全生産量の11％、エネルギー生産量は10％を占める。',
  'industrial_production_yoy.description:ja:Brazil':
    'ブラジルでは鉱工業生産は製造業、鉱業、公益事業など経済の鉱工業セクターに属する事業の生産量を測定する。',
  'industrial_production_yoy.description:ja:Taiwan':
    '台湾では鉱工業生産は経済の鉱工業セクターに属する事業の生産量を測定する。製造業は最も重要なセクターで、全生産量の92.7%を占める。製造業の中でも最大の部門は、電気部品及びコンポーネント（29.4%）、化学材料（10.8%）、非金属（9%）、コンピューター、電子・光学製品（8.8%）、機械・機器（5.1%）。電気およびガス供給は全生産量の4.6%、水道供給は0.5%、建設業は1.5%、鉱業・採石業は0.6%を占める。',
  'industrial_production_yoy.description:ja:South Korea':
    '韓国では鉱工業生産は製造業、鉱業、公益事業など経済の鉱工業セクターに属する事業の生産量を測定する。',
  'industrial_production_yoy.description:ja:Japan':
    '日本では鉱工業生産は製造業、鉱業、公益事業など経済の鉱工業セクターに属する事業の生産量を測定する。',
  'industrial_production_yoy.description:ja:Singapore':
    'シンガポールでは鉱工業生産は製造業、鉱業、公益事業など経済の鉱工業セクターに属する事業の生産量を測定する。',
  'industrial_production_yoy.description:ja:Switzerland':
    'スイスでは鉱工業生産は製造業、鉱業、公益事業など経済の鉱工業セクターに属する事業の生産量を測定する。',
  'industrial_production_yoy.description:ja:Thailand':
    'タイでは鉱工業生産は製造業、鉱業、公益事業など経済の鉱工業セクターに属する事業の生産量を測定する。',
  'industrial_production_yoy.description:ja:Israel':
    'イスラエルでは鉱工業生産は製造業、鉱業、公益事業など経済の鉱工業セクターに属する事業の生産量を測定する。',
  'industrial_production_yoy.description:ja:Hungary':
    'ハンガリーでは鉱工業生産は製造業、鉱業、公益事業など経済の鉱工業セクターに属する事業の生産量を測定する。',
  'industrial_production_yoy.description:ja:Russia':
    'ロシアでは鉱工業生産は経済の鉱工業セクターに属する事業の生産量を測定する。製造業は最も重要なセクターで、全生産量の55%を占める。製造業の中でも最大の部門は、冶金（9.6%）、コークスおよび精製石油製品（9.5%）、食料品・飲料・タバコ（8.4%）、化学薬品（4%）、機械・機器（3.8%）、輸送（3.7%）、電気的装置（3.4％）。鉱業・採石業は35%、天然ガスと原油採掘は29％を占める。また、電力・ガス・水道供給は11％を占める。',
  'industrial_production_yoy.description:ja:Czech Republic':
    'チェコ共和国では鉱工業生産は製造業、鉱業、公益事業など経済の鉱工業セクターに属する事業の生産量を測定する。',
  'industrial_production_yoy.description:ja:Denmark':
    'デンマークでは鉱工業生産は経済の鉱工業セクターに属する事業の生産量を測定する。製造業は最も重要なセクターで、全生産量の96%を占める。製造業の中でも最大の部門は、食料品・飲料・タバコ（全生産量の23%）、機械（19%）、医薬品（12%）、化学薬品と精製石油製品（10%）、家具およびその他の製造業（8%）、卑金属・金属加工製品（7%）、プラスチック・ガラス・コンクリート（5%）。鉱業・採石業は全生産量の4%を占める。',
  'industrial_production_yoy.description:ja:Spain':
    'スペインでは鉱工業生産は経済の鉱工業セクターに属する事業の生産量を測定する。製造業は最も重要なセクターで、全生産量の82%を占める。製造業の中でも最大の部門は、食料品（全体の生産量の12%） 機械・機器を除く金属加工製品（9%）、自動車、トレーラー、セミトレーラー（6%）、化学薬品・化学製品（6%）、その他の非金属鉱物製品（5%）、ゴム・プラスチック製品（4％）、機械・機器（4%）、卑金属（4%）、飲料（4%）。電力・ガス・蒸気・空調は生産量全体の14%、上下水道と廃棄物管理は3%、鉱業 ・採石業は1%を占める。',
  'industrial_production_yoy.description:ja:United Kingdom':
    '英国では鉱工業生産は経済の鉱工業セクターに属する事業の生産量を測定する。製造業は最も重要なセクターで、全生産量の70%を占める。製造業の中でも最大の部門は、食料品・飲料・タバコ（11%）、輸送機器（9%）、卑金属（8%）、その他製造および修理（6%）、医薬品および調剤（6%）、ゴム・プラスチック製品および非金属鉱物（6%）。鉱業と採石業は12%、石油・ガス採掘業は10%を占める。電気・ガス・蒸気・空調は生産量全体の11%、上下水道および廃棄物管理は8%を占める。',
  'industrial_production_yoy.description:ja:Ireland':
    'アイルランドでは鉱工業生産は経済の鉱工業セクターに属する事業の生産量を測定する。製造業は最も重要なセクターで、最大の部門は基礎医薬品および調剤（全生産の37%）、食料品（18%）、化学品および化学製品（15%）、コンピューター、電子・光学・電気機器 （11%）、その他機械と機器の製造・修理・設置（7%）、および非金属と金属製品、機械・機器（5%）。',
  'industrial_production_yoy.description:ja:Mexico':
    'メキシコでは鉱工業生産は製造業、鉱業、公益事業など経済の鉱工業セクターに属する事業の生産量を測定する。',
  'industrial_production_yoy.description:ja:Norway':
    'ノルウェーでは鉱工業生産は経済の鉱工業セクターに属する事業の生産量を測定する。採掘およびその関連サービスが最も重要なセクターで、全生産量の67%を占める。採掘およびその関連サービス業の中でも最大の部門は、天然ガス採掘（全生産量の31％）と原油採掘（30％）。製造業は全生産量の27％を占め、主に、食料品・飲料・タバコ（5％）、精製石油・化学薬品・医薬品（4％）、機械・機器（3％）、金属加工製品（2％）、船舶と石油プラットフォーム（2％）、コンピューター・電気的装置（2％）、機械の修理と設置（2％）、ゴム・プラスチック・鉱物製品（2％）など。電力・ガス・蒸気は全生産量の6％、鉱業・採石業は1％を占める。',
  'industrial_production_yoy.description:ja:Finland':
    'フィンランドでは鉱工業生産は経済の鉱工業セクターに属する事業の生産量を測定する。製造業は最も重要なセクターで、全生産量の87%を占める。製造業の中でも最大の部門は、化学（16%）、電気・エレクトロニクス（13%）、機械・機器（11%）、紙・紙製品（9%）、金属製品（8%）、食料品（7%）。電気・ガス・蒸気・空調は全体の生産量の11%、鉱業と採石業は2%を占める。',
  'industrial_production_yoy.description:ja:Colombia':
    'コロンビアでは鉱工業生産は製造業、鉱業、公益事業など経済の鉱工業セクターに属する事業の生産量を測定する。',
  'industrial_production_yoy.description:ja:Euro Area':
    'ユーロ圏では鉱工業生産は製造業、鉱業、公益事業など経済の鉱工業セクターに属する事業の生産量を測定する。',
  'industrial_production_yoy.description:ja:Philippines':
    'フィリピンでは鉱工業生産は製造業の生産価値指数（VaPI）で測定される。',
  'industrial_production_yoy.description:ja:South Africa':
    '南アフリカでは鉱工業生産は製造業、鉱業、公益事業など経済の鉱工業セクターに属する事業の生産量を測定する。',
  'industrial_production_yoy.description:ja:Belgium':
    'ベルギーでは鉱工業生産は経済の鉱工業セクターに属する事業の生産量を測定する。製造業は最も重要なセクターで、中でも最大の部門は、化学製品（全生産量の19％）、食料品・飲料（16％）、卑金属（11％）、自動車・トレーラー・セミトレーラー（10％）、医薬品（8％）機械・機器（5％）、金属加工製品（5％）。',
  'industrial_production_yoy.description:ja:France':
    'フランスでは鉱工業生産は経済の鉱工業セクターに属する事業の生産量を測定する。製造業は最も重要なセクターで、全生産量の86%を占める。製造業の中でも最大の部門は、食料品・飲料（全生産量の13％）、機械・機器（13％）（うちコンピューター、電子・光学製品は5％）、卑金属・金属加工製品（12％）、その他の機器の製造・修理・設置業（12％）、輸送機器（10％）、ゴム・プラスチック製品、およびその他の非金属鉱物製品（8％）、化学薬品・化学製品（6％）。電力・ガス・蒸気・空調は全生産量の11％を占め、上下水道・廃棄物管理・修復1％、鉱業・採石業も1％を占める。',
  'industrial_production_yoy.description:ja:Netherlands':
    'オランダでは鉱工業生産は製造業、鉱業、公益事業など経済の鉱工業セクターに属する事業の生産量を測定する。',
  'industrial_production_yoy.description:ja:Italy':
    'イタリアでは鉱工業生産は経済の鉱工業セクターに属する事業の生産量を測定する。製造業は最も重要なセクターで、全生産量の88%を占める。製造業の中でも最大の部門は、冶金と金属製品（全生産量の14%）、機械・機器（12%）、食料品・飲料・タバコ（10%）、ゴム・プラスチック・非金属鉱物製品（9%）、繊維・衣料・革製品（8%）、輸送機器 （7%）その他機械および機器の製造・修理・設置（7%）。電気・ガス・蒸気・空調は全体の生産量の10%、鉱業と採石業は1%を占める。',
  'industrial_production_yoy.description:ja:Portugal':
    'ポルトガルでは鉱工業生産は経済の鉱工業セクターに属する事業の生産量を測定する。製造業は最も重要なセクターで、全生産量の93.9%を占める。製造業の中でも最大の部門は、食料品（全生産量の13％） 、コークス・精製石油製品・燃料（10％）、自動車・トレーラー・セミトレーラー（9％）、金属製品（機械・機器を除く）（7％）、薬品を除く化学薬品・ 合成または人工繊維（5％）、ゴム・プラスチック製品（5％）、紙製品（5％）。電力・ガス・蒸気・空調は全生産量の5％、上下水道・廃棄物管理は1％を占める。',
  'industrial_production_yoy.description:ja:China':
    '中国では鉱工業生産は製造業、鉱業、公益事業など経済の鉱工業セクターに属する事業の生産量を測定する。',
  'industrial_production_yoy.description:ja:Greece':
    'ギリシャでは鉱工業生産は製造業、鉱業、公益事業など経済の鉱工業セクターに属する事業の生産量を測定する。',
  'wholesale_sales_yoy.description:ja:DEFAULT': '',
  'wholesale_sales_yoy.description:ja:Canada':
    '月次卸売統計は、カナダの卸売業者による月次売上高の推定値を測定するもので、卸売業の業況に関する情報を提供し、カナダ経済の状況の重要な指標となる。さらにビジネスコミュニティはこのデータを使って市場パフォーマンスを分析する。',
  'tankan_small_non-manufacturing_index.description:ja:DEFAULT': '',
  'tankan_small_non-manufacturing_index.description:ja:Japan': '',
  'construction_output_yoy.description:ja:DEFAULT': '',
  'construction_output_yoy.description:ja:Italy':
    '建設部門生産高は、建設業での指数として、ビル建設、土木工事を含む建設部門の生産高の変化を測るものである。',
  'construction_output_yoy.description:ja:Hungary':
    '建設部門生産高は、建設業での指数として、ビル建設、土木工事を含む建設部門の生産高の変化を測るものである。',
  'construction_output_yoy.description:ja:South Korea':
    '韓国の建設業生産高は、ビル建設、土木工事を含む建設部門の生産高の前年比変化を測る。',
  'construction_output_yoy.description:ja:Belgium':
    '建設部門生産高は、建設業での指数として、ビル建設、土木工事を含む建設部門の生産高の変化を測るものである。',
  'construction_output_yoy.description:ja:Euro Area':
    '建設部門生産高は、建設業での指数として、ビル建設、土木工事を含む建設部門の生産高の変化を測るものである。',
  'construction_output_yoy.description:ja:Greece':
    'ギリシャの建設業生産高指数（IPC）は、重要性の高い景気指標として、ビル建設および土木工事セクターの四半期毎の活動を示している。',
  'construction_output_yoy.description:ja:United Kingdom':
    '英国の建設業生産高は、顧客による建設業者への発注額の年次変化を測定している。生産高は、従業員100人以上または年間売上が6,000万ポンド以上の8,000社のサンプルから測られる。生産高予測は製造業におけるGDPとなっており、GDPの約6.4%を占めている。',
  'construction_output_yoy.description:ja:Czech Republic':
    'チェコ共和国の建設業生産高は、主な事業が建設業に分類される企業による建築工事の前年比変化を測るものである。',
  'construction_output_yoy.description:ja:Sweden':
    '建設部門生産高は、建設業での指数として、ビル建設、土木工事を含む建設部門の生産高の変化を測るものである。',
  'construction_output_yoy.description:ja:Ireland':
    'アイルランドの建設業生産高は、ビルおよび建設生産高の前年比変化を測る。',
  'presidential_election.description:ja:DEFAULT': '',
  'presidential_election.description:ja:Portugal': '',
  'presidential_election.description:ja:United States': '',
  'presidential_election.description:ja:Austria': '',
  'presidential_election.description:ja:Czech Republic': '',
  'presidential_election.description:ja:Poland': '',
  'presidential_election.description:ja:Brazil': '',
  'presidential_election.description:ja:Taiwan': '',
  'presidential_election.description:ja:Finland': '',
  'presidential_election.description:ja:Russia': '',
  'private_sector_credit_mom.description:ja:DEFAULT': '',
  'private_sector_credit_mom.description:ja:Australia':
    'オーストラリアでは、民間セクター与信は、住宅ローン、個人ローン、企業ローンなど経済の民間部門への与信残高の前月比増減を指し、証券化も含まれる。',
  'non_farm_payrolls_qoq_prel.description:ja:DEFAULT': '',
  'non_farm_payrolls_qoq_prel.description:ja:France':
    'フランスでは、雇用者数とは、雇用期間に関係なく四半期最終日の雇用者数を示す。すべてのセクターとすべてのフランス領土が含まれている（マヨットは除く）。',
  'existing_home_sales_mom.description:ja:DEFAULT': '',
  'existing_home_sales_mom.description:ja:United States':
    '中古住宅販売件数は、ローンが実行されると発生したとみなされ、ローンは通常、販売契約締結から30～60日後に実行される。',
  'reuters_michigan_current_conditions_prel.description:ja:DEFAULT': '',
  'reuters_michigan_current_conditions_prel.description:ja:United States':
    '消費者信頼感指数は、（1）消費者自身の財務状況の先行き見通し、（2）目先の経済全体に関する先行き見通し、（3）長期的な経済全体に関する先行き見通しの3つの分野に注目するもので、毎月実施されるアンケート調査には50項目の中核的な設問が含まれ、それぞれの設問が消費者の態度と予想を追跡する。消費者調査のサンプルは統計的にアラスカ州とハワイ州を除くすべての米国世帯を代表するように設計され、毎月500人以上に電話でインタビューが実施される。',
  'new_motor_vehicle_sales_yoy.description:ja:DEFAULT': '',
  'new_motor_vehicle_sales_yoy.description:ja:Australia':
    'オーストラリアでは、自動車登録台数とは、登録されている乗用車の総数のことである。',
  'wholesale_sales_mom_prel.description:ja:DEFAULT': '',
  'wholesale_sales_mom_prel.description:ja:Canada':
    '月次卸売統計は、カナダの卸売業者による月次売上高の推定値を測定するもので、卸売業の業況に関する情報を提供し、カナダ経済の状況の重要な指標となる。さらにビジネスコミュニティはこのデータを使って市場パフォーマンスを分析する。',
  'rba_governor_stevens_speaks.description:ja:DEFAULT': '',
  'rba_governor_stevens_speaks.description:ja:Australia': '',
  'the_foundation_day_of_korea.description:ja:DEFAULT': '',
  'the_foundation_day_of_korea.description:ja:South Korea': '',
  'vappu_may_day.description:ja:DEFAULT': '',
  'vappu_may_day.description:ja:Finland': '',
  'mortgage_lending.description:ja:DEFAULT': '',
  'mortgage_lending.description:ja:United Kingdom':
    'イギリスでは、住宅ローン貸出額として個人および住宅組合に貸し付けられた資金の月次変化を測っている。',
  'labour_cost_index_yoy.description:ja:DEFAULT': '',
  'labour_cost_index_yoy.description:ja:New Zealand': '',
  'non_manufacturing_pmi.description:ja:DEFAULT': '',
  'non_manufacturing_pmi.description:ja:China':
    '中国では、非製造業部門1,200社の代表者から集められたデータに基づき非製造業購買担当者景気指数調査が行われる。事業活動、新規受注、新規輸出受注、受注残、在庫、中間投入価格、定期契約価格、雇用、サプライヤー納期、事業活動の見通しなどが調査され、各質問に対してディフュージョンインデックスが算出される。まとまったPMIは存在しないため、事業活動指数は通常非製造業部門全体の変化を反映したものとして用いられている。PMIが50%を超えると前月比で拡大、50%未満だと縮小していることを示す。',
  'gdp_growth_rate_qoq_adv.description:ja:DEFAULT': '',
  'gdp_growth_rate_qoq_adv.description:ja:Czech Republic':
    'チェコ共和国では家計消費がGDPの主な構成要素であり、その総支出の49％を占めており、次いで総固定資本形成（25％）、政府支出（19％）となっている。財・サービスの輸出はGDPの84%である一方、輸入は77%であるため、GDP合計に７%のプラス寄与となっている。',
  'gdp_growth_rate_qoq_adv.description:ja:Hong Kong':
    '香港は輸出主導の経済となっており、国土は小さいものの、国内総生産（GDP）の4倍の額の財やサービスの貿易が行われ世界第8位の貿易大国となっている。近年、香港は、中国市場とのつながりを求める事業者、貿易、金融、輸入業者にとって国際的なハブとなっている。経済の最大のセクターはサービス業で、GDPの約93%を占めている。',
  'gdp_growth_rate_qoq_adv.description:ja:Denmark':
    'デンマークでは家計消費がGDPの主な構成要素であり、その総支出の49％を占めており、次いで政府支出（27％とEU諸国では最高）、総固定資本形成（19％）となっている。財・サービスの輸出はGDPの54%に達する一方、輸入は48%であるため、GDP合計に６%のプラス寄与となっている。',
  'gdp_growth_rate_qoq_adv.description:ja:Italy':
    'イタリアは世界で9番目に大きい経済規模を誇り、ユーロ圏では3番目に大きい経済国である。同国は、大部分が高品質消費者向け製品の製造が占める多様化した工業を基盤としている。GDPの支出構成は、家計消費（61％）、政府支出（19％）、総固定資本形成（17％）となっている。製品・サービスの輸出はGDPの30％、輸入は27％であるため、GDP合計の3％に寄与している。',
  'gdp_growth_rate_qoq_adv.description:ja:Singapore':
    'シンガポールは1963年に独立して以来目覚ましい経済成長を遂げ、今では人口1人当たりGDPにおいて世界の最上位国の1つとなっている。同国経済は、港湾活動と電子部品および精製石油の輸出を含む貿易に大きく依存している。シンガポールは世界中で最も競争力の高い、企業寄りの最も自由な経済の1つであることから、外国直接投資の投資先として他国をリードしており、重要な金融の中心地でもある。',
  'gdp_growth_rate_qoq_adv.description:ja:United States':
    '支出面では、個人消費支出がGDP全体の68%を占め、そのうち製品購入による支出が23%、サービスへの支出が45%となっている。民間投資はGDPの16%、政府による消費と投資は18%を占める。製品輸出高（13.5%）が製品輸入高（16.5%）より低いため、純輸出はGDPに3%のマイナス寄与。',
  'gdp_growth_rate_qoq_adv.description:ja:South Korea':
    '韓国は輸出主導の経済であり、ディスプレイと半導体記憶装置で世界をリードしており、船舶の製造では第2位となっている。GDPに占めるサービス業の割合が最も高くなっている（GDPの約57％）。サービス業の中でも大きな割合を占めるのは、行政（6％）、教育（5.3％）、情報・通信（5％）、事業活動（4.6％）である。韓国を大規模輸出国とする要因となった強力な製造業は、GDPの32％を占めている。',
  'gdp_growth_rate_qoq_adv.description:ja:Belgium':
    'ベルギーでは家計消費がGDPの主な構成要素であり、その総支出の52％を占めており、次いで政府支出（25％）、総固定資本形成（23％）となっている。財・サービスの輸出はGDPの84%である一方、輸入は83%であるため、GDP合計に1%のマイナス寄与となっている。',
  'eu-wide_stress_test_results.description:ja:DEFAULT': '',
  'eu-wide_stress_test_results.description:ja:European Union': '',
  'unemployment_rate_prel.description:ja:DEFAULT': '',
  'unemployment_rate_prel.description:ja:Singapore':
    'シンガポールでは、失業率は労働力全体のうち、積極的に仕事を探している人口の比率を測定する。',
  '2020_state_of_the_nation_address.description:ja:DEFAULT': '',
  '2020_state_of_the_nation_address.description:ja:South Africa': '',
  'national_activity_index.description:ja:DEFAULT': '',
  'national_activity_index.description:ja:United States': '',
  'victory_day.description:ja:DEFAULT': '',
  'victory_day.description:ja:Russia': '',
  'victory_day.description:ja:China': '',
  'greek_third_bailout_program_s_vote.description:ja:DEFAULT': '',
  'greek_third_bailout_program_s_vote.description:ja:Greece': '',
  'reuters_michigan_current_conditions.description:ja:DEFAULT': '',
  'reuters_michigan_current_conditions.description:ja:United States':
    '消費者信頼感指数は、（1）消費者自身の財務状況の先行き見通し、（2）目先の経済全体に関する先行き見通し、（3）長期的な経済全体に関する先行き見通しの3つの分野に注目するもので、毎月実施されるアンケート調査には50項目の中核的な設問が含まれ、それぞれの設問が消費者の態度と予想を追跡する。消費者調査のサンプルは統計的にアラスカ州とハワイ州を除くすべての米国世帯を代表するように設計され、毎月500人以上に電話でインタビューが実施される。',
  '3-year_bond_auction.description:ja:DEFAULT': '',
  '3-year_bond_auction.description:ja:Canada': '',
  'nbb_business_confidence.description:ja:DEFAULT': '',
  'nbb_business_confidence.description:ja:Belgium':
    'ベルギーの企業景況感調査は、企業を経営する人々が現在の経済状況についてどの程度楽観的に考えているか、また今後3カ月間の自社の見通しについてどのように感じているかを測定する。毎月、約6,000人のビジネスリーダーが調査の対象となる。',
  'general_prayer_day.description:ja:DEFAULT': '',
  'general_prayer_day.description:ja:Denmark': '',
  'feast_of_saint_peter_and_saint_paul.description:ja:DEFAULT': '',
  'feast_of_saint_peter_and_saint_paul.description:ja:Colombia': '',
  'yom_kippur.description:ja:DEFAULT': '',
  'yom_kippur.description:ja:Israel': '',
  'visakha_bucha.description:ja:DEFAULT': '',
  'visakha_bucha.description:ja:Thailand': '',
  'national_core_inflation_rate_yoy.description:ja:DEFAULT': '',
  'national_core_inflation_rate_yoy.description:ja:Japan': '',
  'seollal_holiday_day_1.description:ja:DEFAULT': '',
  'seollal_holiday_day_1.description:ja:South Korea': '',
  'gdp_growth_rate_qoq_2nd_est.description:ja:DEFAULT': '',
  'gdp_growth_rate_qoq_2nd_est.description:ja:Israel':
    'イスラエル経済の主要なけん引役は科学技術セクターで、天然資源が限定的であるにもかかわらず製造・農業セクターは高度に進化している。イスラエル経済最大のセクターはサービス業でGDPの80%を占め、うち最も重要なのは金融とビジネス（GDP寄与率28％）、政府（同17％）、住宅（同12％）、貿易、宿泊、レストラン（同10％）、輸送・保管・通信（同8％）。製造業の重要性は近年低下しており、今ではGDP寄与率は14％。',
  'gdp_growth_rate_qoq_2nd_est.description:ja:Poland':
    'ポーランドの経済規模は欧州連合で8番目でありながら、人口1人当たりのGDPはEU平均を大きく下回る。同国の産業基盤は石炭、繊維、化学品、機械、鉄、鉄鋼セクターであり、近年は肥料、石油化学、工作機械、電子機械、エレクトロニクス、自動車、造船にも広がっている。支出面では、家計消費がGDPの主要コンポーネントで、全体の60％を占め、次いで総固定資本形成（20％）と政府支出（18％）が続く。財とサービスの輸出はGDP全体の47％を占め、輸入は46％でGDP全体に1％の寄与となっている。',
  'ppi_input_yoy.description:ja:DEFAULT': '',
  'ppi_input_yoy.description:ja:United Kingdom': '',
  '12-month_letras_auction.description:ja:DEFAULT': '',
  '12-month_letras_auction.description:ja:Spain': '',
  'national_committee_of_the_cppcc.description:ja:DEFAULT': '',
  'national_committee_of_the_cppcc.description:ja:China': '',
  'tisha_b_av.description:ja:DEFAULT': '',
  'tisha_b_av.description:ja:Israel': '',
  'president_trump_speech.description:ja:DEFAULT': '',
  'president_trump_speech.description:ja:United States': '',
  'eia_refinery_crude_runs_change.description:ja:DEFAULT': '',
  'eia_refinery_crude_runs_change.description:ja:United States':
    '原油精製量は、製油所によって処理された原油量を示す。',
  'uk_finance_mortgage_approvals.description:ja:DEFAULT': '',
  'uk_finance_mortgage_approvals.description:ja:United Kingdom':
    '英国住宅ローン承認件数は、同国の住宅購入承認数を示しており、再ローンおよび他目的の融資は除外されている。データには、合計で同国の個人融資資産額の約2/3を占める、バークレー銀行、HSBC銀行、ロイズ・バンキング・グループ、ロイヤルバンク・オブ・スコットランド・グループ、サンタンデールUK、TSB およびバージン・マネーの消費者が含まれる。',
  'boe_gov_bailey_testimony.description:ja:DEFAULT': '',
  'boe_gov_bailey_testimony.description:ja:United Kingdom': '',
  'production_price_index_yoy.description:ja:DEFAULT': '',
  'production_price_index_yoy.description:ja:South Africa': '',
  'gdp_private_consumption_qoq_final.description:ja:DEFAULT': '',
  'gdp_private_consumption_qoq_final.description:ja:Japan':
    '日本では、家計の消費支出確報値は、供給側の測定値に加え家計調査などによる消費者側の測定値を考慮する。家計消費支出の確報値は、需給両側の加重平均によって算出される。',
  'referendum_on_responsible_business_initiative.description:ja:DEFAULT': '',
  'referendum_on_responsible_business_initiative.description:ja:Switzerland': '',
  'rba_deputy_gov_lowe_speech.description:ja:DEFAULT': '',
  'rba_deputy_gov_lowe_speech.description:ja:Australia': '',
  'manufacturing_sales_mom_prel.description:ja:DEFAULT': '',
  'manufacturing_sales_mom_prel.description:ja:Canada':
    'カナダでは、製造業売上高はカナダの製造業セクターによる製品売上高の特定時点での状況（スナップショット）であり、中・短期間におけるカナダ経済の状況と特定業界の状況の分析が可能である。',
  'cb_governor_oystein_olsen_speech.description:ja:DEFAULT': '',
  'cb_governor_oystein_olsen_speech.description:ja:Norway': '',
  'pesach_vii.description:ja:DEFAULT': '',
  'pesach_vii.description:ja:Israel': '',
  'pce_prices_qoq_final.description:ja:DEFAULT': '',
  'pce_prices_qoq_final.description:ja:United States': '',
  'global_dairy_trade_price_index.description:ja:DEFAULT': '',
  'global_dairy_trade_price_index.description:ja:New Zealand':
    '加重平均による9種の酪農製品の入札価格を2週間おきに計測したものである。',
  'tokyo_core_yoy.description:ja:DEFAULT': '',
  'tokyo_core_yoy.description:ja:Japan': '',
  'national_day_golden_week.description:ja:DEFAULT': '',
  'national_day_golden_week.description:ja:China': '',
  'ppi_yoy_prel.description:ja:DEFAULT': '',
  'ppi_yoy_prel.description:ja:Canada':
    'カナダでは、生産者物価変動は、一定期間中に卸売市場で製造者や生産者が販売した財やサービスの価格の平均的な変化を測定する。',
  'zew_survey_-_expectations.description:ja:DEFAULT': '',
  'zew_survey_-_expectations.description:ja:Switzerland': '',
  'wisakha_bucha_day.description:ja:DEFAULT': '',
  'wisakha_bucha_day.description:ja:Thailand': '',
  'national_cpi_ex_food_energy_yoy.description:ja:DEFAULT': '',
  'national_cpi_ex_food_energy_yoy.description:ja:Japan': '',
  'gdp_price_index_qoq_final.description:ja:DEFAULT': '',
  'gdp_price_index_qoq_final.description:ja:United States':
    'GDPデフレーターは、最終財とサービスの価格変化を測るものであり、将来の金融政策の方向性に影響するインフレ圧力の主要指標であるとされている。',
  'fed_richard_fisher_speech.description:ja:DEFAULT': '',
  'fed_richard_fisher_speech.description:ja:United States': '',
  'budget_speech.description:ja:DEFAULT': '',
  'budget_speech.description:ja:South Africa': '',
  'net_lending_to_individuals.description:ja:DEFAULT': '',
  'net_lending_to_individuals.description:ja:United Kingdom': '',
  'retail_sales_mom_final.description:ja:DEFAULT': '',
  'retail_sales_mom_final.description:ja:Australia':
    'オーストラリアでは、小売売上高が一定期間における小売商品とサービスの総売上高を測定する。この小売売上高は季節要因の影響を受け変動が大きく、経済全体に対する重要性も比較的高い。',
  'pmi.description:ja:DEFAULT': '',
  'pmi.description:ja:Singapore': '',
  'reuters_michigan_current_conditions_final.description:ja:DEFAULT': '',
  'reuters_michigan_current_conditions_final.description:ja:United States':
    '消費者信頼感指数は、（1）消費者自身の財務状況の先行き見通し、（2）目先の経済全体に関する先行き見通し、（3）長期的な経済全体に関する先行き見通しの3つの分野に注目するもので、毎月実施されるアンケート調査には50項目の中核的な設問が含まれ、それぞれの設問が消費者の態度と予想を追跡する。消費者調査のサンプルは統計的にアラスカ州とハワイ州を除くすべての米国世帯を代表するように設計され、毎月500人以上に電話でインタビューが実施される。',
  'loans_to_households_yoy.description:ja:DEFAULT': '',
  'loans_to_households_yoy.description:ja:Euro Area':
    'ユーロ圏では、世帯向け融資額の増加率は、販売と証券化を調整した世帯向け融資額を前年比で測定する。',
  'zew_investor_sentiment.description:ja:DEFAULT': '',
  'zew_investor_sentiment.description:ja:Switzerland': '',
  'bakri_id.description:ja:DEFAULT': '',
  'bakri_id.description:ja:India': '',
  'ny_fed_treasury_purchases_10_to_22_5_yrs.description:ja:DEFAULT': '',
  'ny_fed_treasury_purchases_10_to_22_5_yrs.description:ja:United States': '',
  'business_investment_yoy_final.description:ja:DEFAULT': '',
  'business_investment_yoy_final.description:ja:United Kingdom':
    '英国では企業投資とは、民間企業および公営企業による純投資額を指す。これには輸送、情報通信技術（ICT）機器、その他の機械装置、酪・農業生物資産、知的財産製品（IPP：ソフトウェア、研究開発、芸術的原作品、鉱物探査への投資を含む）、建物などの建造物への投資が含まれる。中央政府や地方政府による投資、住居への投資、非生産資産（土地など）の譲渡に伴う費用は含まれていない。。',
  'reuters_michigan_consumer_expectations_prel.description:ja:DEFAULT': '',
  'reuters_michigan_consumer_expectations_prel.description:ja:United States':
    '消費者信頼感指数は、（1）消費者自身の財務状況の先行き見通し、（2）目先の経済全体に関する先行き見通し、（3）長期的な経済全体に関する先行き見通しの3つの分野に注目するもので、毎月実施されるアンケート調査には50項目の中核的な設問が含まれ、それぞれの設問が消費者の態度と予想を追跡する。消費者調査のサンプルは統計的にアラスカ州とハワイ州を除くすべての米国世帯を代表するように設計され、毎月500人以上に電話でインタビューが実施される。',
  'tankan_small_manufacturing_outlook.description:ja:DEFAULT': '',
  'tankan_small_manufacturing_outlook.description:ja:Japan': '',
  'gdp_external_demand_qoq_prel.description:ja:DEFAULT': '',
  'gdp_external_demand_qoq_prel.description:ja:Japan': '',
  'yom_kippur_eve.description:ja:DEFAULT': '',
  'yom_kippur_eve.description:ja:Israel': '',
  'eia_distillate_stocks.description:ja:DEFAULT': '',
  'eia_distillate_stocks.description:ja:United States': '',
  'mid-month_inflation_rate_yoy.description:ja:DEFAULT': '',
  'mid-month_inflation_rate_yoy.description:ja:Mexico': '',
  'mining_production_mom.description:ja:DEFAULT': '',
  'mining_production_mom.description:ja:South Africa':
    '南アフリカでは、鉱山生産量指数として生産量の変化を統計にまとめている。石炭が最も重要なセクターとなっており、総鉱山生産高の24%を占めている。続いて白金族金属（23%）、金（16%）、鉄鉱石（12%）、その他非鉄金属（6%）、ダイヤモンド（4%）、マンガン鉱石およびクロミウム鉱石（各3%）、その他金属、建設用金属およびニッケル（各2%）、銅（1%）となっている。',
  'renzi_merkel_hollande_meet_on_brexit.description:ja:DEFAULT': '',
  'renzi_merkel_hollande_meet_on_brexit.description:ja:Italy': '',
  'westpac_mni_consumer_sentiment.description:ja:DEFAULT': '',
  'westpac_mni_consumer_sentiment.description:ja:China': '',
  'national_assembly_for_wales_elections.description:ja:DEFAULT': '',
  'national_assembly_for_wales_elections.description:ja:United Kingdom': '',
  'workers_day.description:ja:DEFAULT': '',
  'workers_day.description:ja:South Africa': '',
  '5-year_bonos_auction.description:ja:DEFAULT': '',
  '5-year_bonos_auction.description:ja:Spain': '',
  'king_s_day.description:ja:DEFAULT': '',
  'king_s_day.description:ja:Netherlands': '',
  'fed_bowman_speech.description:ja:DEFAULT': '',
  'fed_bowman_speech.description:ja:United States':
    '米国では、金利設定の権限は連邦準備制度理事会（理事会）と連邦公開市場委員会（FOMC）に分けられている。理事会は、1つ以上の地区連邦準備銀行から提出された勧告を受けて、割引率の変更を決定する。FOMCは、中央銀行の資金量やフェデラルファンド市場金利などの公開市場操作を決定する。',
  'holiday_-_ramzan_id.description:ja:DEFAULT': '',
  'holiday_-_ramzan_id.description:ja:India': '',
  'treasury_secretary_mnuchin_speech.description:ja:DEFAULT': '',
  'treasury_secretary_mnuchin_speech.description:ja:United States': '',
  'feast_of_the_immaculate_conception.description:ja:DEFAULT': '',
  'feast_of_the_immaculate_conception.description:ja:Spain': '',
  'feast_of_the_immaculate_conception.description:ja:Austria': '',
  'feast_of_the_immaculate_conception.description:ja:Colombia': '',
  'feast_of_the_immaculate_conception.description:ja:Italy': '',
  'feast_of_the_immaculate_conception.description:ja:Portugal': '',
  'westpac_leading_index_mom.description:ja:DEFAULT': '',
  'westpac_leading_index_mom.description:ja:Australia':
    'ウェストパック銀行とメルボルン研究所の経済活動先行指数は、経済活動の振れに通常先行する経済変数を信頼性のあるオーストラリアの景気指標としてまとめたものである。指標には、S&P/ASX 200、掘削許可数、米工業生産、オーストラリア準備銀行（RBA）コモディティ価格指標（豪ドル）、合計月間労働時間、ウェストパック銀行およびメルボルン研究所による失業率予想指標、10年債～90日債の利回りスプレッドが含まれる。指標は、1996年時点を100の基準値とする。',
  'wholesale_inventories_mom_adv.description:ja:DEFAULT': '',
  'wholesale_inventories_mom_adv.description:ja:United States':
    '卸売在庫は、卸売業者が保管する販売されていない在庫を示す。国内総製品の変化の主要要素となるのが在庫である。在庫量が多いと米国経済の減退を示し、在庫量が少ないと成長改善の兆しと見られる。',
  'us-russia_summit.description:ja:DEFAULT': '',
  'us-russia_summit.description:ja:United States': '',
  'white_house_jobs_announcement.description:ja:DEFAULT': '',
  'white_house_jobs_announcement.description:ja:United States': '',
  'nbb_business_climate.description:ja:DEFAULT': '',
  'nbb_business_climate.description:ja:Belgium':
    'ベルギーの企業景況感調査は、企業を経営する人々が現在の経済状況についてどの程度楽観的に考えているか、また今後3カ月間の自社の見通しについてどのように感じているかを測定する。毎月、約6,000人のビジネスリーダーが調査の対象となる。',
  'government_autumn_forecasts.description:ja:DEFAULT': '',
  'government_autumn_forecasts.description:ja:United Kingdom': '',
  'rba_aylmer_speech.description:ja:DEFAULT': '',
  'rba_aylmer_speech.description:ja:Australia': '',
  'harvest_festival.description:ja:DEFAULT': '',
  'harvest_festival.description:ja:South Korea': '',
  'wholesale_inventories_mom.description:ja:DEFAULT': '',
  'wholesale_inventories_mom.description:ja:United States':
    '卸売在庫は、卸売業者が保管する販売されていない在庫を示す。国内総製品の変化の主要要素となるのが在庫である。在庫量が多いと米国経済の減退を示し、在庫量が少ないと成長改善の兆しと見られる。',
  'tourist_arrivals_yoy.description:ja:DEFAULT': '',
  'tourist_arrivals_yoy.description:ja:Spain':
    '観光業はスペインのGDPの約12%、雇用の13%を占めている。',
  'tourist_arrivals_yoy.description:ja:Israel': '',
  'tourist_arrivals_yoy.description:ja:Indonesia':
    'インドネシアでは、観光客到着数で同国へ訪問した外国人旅行客の統計をとっている。',
  'tomb_sweeping_day.description:ja:DEFAULT': '',
  'tomb_sweeping_day.description:ja:Taiwan': '',
  'sipmm_manufacturing_pmi.description:ja:DEFAULT': '',
  'sipmm_manufacturing_pmi.description:ja:Singapore':
    'シンガポール製造業購買担当者景気指数（PMI）は、シンガポール製造業の主要なバロメーターである。50を超えると工場稼働が拡大傾向にあり、50を下回ると活動は縮小傾向にあることを示す。',
  'pm_may_statement_on_new_brexit_deal.description:ja:DEFAULT': '',
  'pm_may_statement_on_new_brexit_deal.description:ja:United Kingdom': '',
  'the_emperor_s_birthday.description:ja:DEFAULT': '',
  'the_emperor_s_birthday.description:ja:Japan': '',
  'winter_solstice_festival.description:ja:DEFAULT': '',
  'winter_solstice_festival.description:ja:Hong Kong': '',
  'unemployment_benefit_claims.description:ja:DEFAULT': '',
  'unemployment_benefit_claims.description:ja:France':
    'フランスでの失業手当申請は、フランス本土での失業手当申請者数をまとめている。',
  'ppi_mom.description:ja:DEFAULT': '',
  'ppi_mom.description:ja:Portugal':
    'ポルトガルの工業生産者物価指数は、国内及び海外市場向けの経済活動での取引価格の月次変化を示すものである。指数は、工業製品生産価格の電子的な調査から得られるもので、主に国内の採取産業、製造業、電気、ガス・水道産業の企業を対象としている。約10,123の価格情報が毎月集計される。生産者価格では中間工程品がPPI合計の36％と最大の比率を占め、消費者向け商品（31%）、エネルギー（20%）、資本財（12%）が続く。',
  'ppi_mom.description:ja:Czech Republic':
    'チェコ共和国では、生産者物価指数（PPI）が一定期間中に卸売市場で製造者や生産者が販売した財やサービスの価格の変化の平均を測定している。',
  'ppi_mom.description:ja:Euro Area':
    'ユーロ圏では、生産者物価指数（PPI）が一定期間中に卸売市場で製造者や生産者が販売した財やサービスの価格の変化の平均を測定している。',
  'ppi_mom.description:ja:Japan':
    '日本では、生産者物価指数（PPI）が一定期間中に卸売市場で製造者や生産者が販売した財やサービスの価格の変化の平均を測定している。',
  'ppi_mom.description:ja:France':
    'フランスでは、生産者物価指数（PPI）が一定期間中に卸売市場で製造者や生産者が販売した財やサービスの価格の変化の平均を測定している。',
  'ppi_mom.description:ja:Germany':
    'ドイツでは、生産者物価指数（PPI）が一定期間中に卸売市場で製造者や生産者が販売した財やサービスの価格の変化の平均を測定している。',
  'ppi_mom.description:ja:Sweden':
    'スウェーデンでは、生産者物価指数（PPI）が一定期間中に卸売市場で製造者や生産者が販売した財やサービスの価格の変化の平均を測定している。',
  'ppi_mom.description:ja:Austria':
    'オーストリアでは、生産者物価指数（PPI）が一定期間中に卸売市場で製造者や生産者が販売した財やサービスの価格の変化の平均を測定している。',
  'ppi_mom.description:ja:South Africa':
    '南アフリカでは、生産者物価指数（PPI）は、一定期間中に卸売市場で製造者や生産者が販売した財やサービスの価格の平均的な変化を測定する。',
  'ppi_mom.description:ja:United States':
    '米国では、最終需要の生産者価格指数が個人消費、資本投資、政府支出、輸出に対し販売された商品の価格変化を測定している。主に、食料とエネルギーを含む最終需要製品（全ウェイトの33%）、最終需要販売サービス（20%）、最終需要輸送及び倉庫サービス（4%）、貿易・輸送・倉庫を除く最終需要（41%）、最終需要建設（2%）そして全体の最終需要の6つの価格指標から構成される。',
  'ppi_mom.description:ja:Italy':
    'イタリアでは、鉱業生産者物価指数がイタリアで生産され国内及び非国内市場で販売された製品の生産価格の変化を測定している。',
  'ppi_mom.description:ja:Switzerland':
    'スイスでは、生産者物価指数（スイス国内で事業を行う企業が生産・販売する財の価格動向を測定）と輸入物価指数（輸入品の価格動向を測定）をまとめて幅広い財をカバーする1つの物価指数としており、まとめる際は生産者物価指数の国内販売のみが考慮される。',
  'ppi_mom.description:ja:Canada':
    'カナダでは、生産者物価指数（PPI）は、一定期間中に卸売市場で製造者や生産者が販売した財やサービスの価格の変化の平均を測定する。',
  'ppi_mom.description:ja:South Korea':
    '韓国では、生産者物価指数（PPI）が一定期間中に卸売市場で製造者や生産者が販売した財やサービスの価格の平均的な変化を測定している。',
  'ppi_mom.description:ja:Russia':
    'ロシアでは、生産者物価指数（PPI）が一定期間中に卸売市場で製造者や生産者が販売した財やサービスの価格の変化の平均を測定している。',
  'fed_tarullo_speech.description:ja:DEFAULT': '',
  'fed_tarullo_speech.description:ja:United States': '',
  'nato_summit.description:ja:DEFAULT': '',
  'nato_summit.description:ja:Belgium': '',
  'nato_summit.description:ja:Poland': '',
  'world_economic_forum_annual_meeting.description:ja:DEFAULT': '',
  'world_economic_forum_annual_meeting.description:ja:Switzerland': '',
  'rbnz_press_conference.description:ja:DEFAULT': '',
  'rbnz_press_conference.description:ja:New Zealand':
    'ニュージーランドでは、ニュージーランド準備銀行により金利が決定される。公式政策金利となるのが、オフィシャルキャッシュレート（OCR）である。OCRは1999年3月に導入され、準備銀行により年8回見直される。OCRはニュージーランドでの借り入れコストに影響し、経済活動とインフレに影響を与える。',
  'retail_trade_s_a_mom.description:ja:DEFAULT': '',
  'retail_trade_s_a_mom.description:ja:Japan': '',
  'obr_economic_and_fiscal_forecasts.description:ja:DEFAULT': '',
  'obr_economic_and_fiscal_forecasts.description:ja:United Kingdom': '',
  'prospective_plantings_-_wheat.description:ja:DEFAULT': '',
  'prospective_plantings_-_wheat.description:ja:United States': '',
  'government_budget.description:ja:DEFAULT': '',
  'government_budget.description:ja:Philippines':
    '政府予算は、政府歳入（税収およびその他収入）および政府歳出（購入および支払い）の項目別統計である。赤字予算は、政府が歳入以上に支出していることを示す。その反対は、黒字予算となる。',
  'government_budget.description:ja:Italy':
    '政府予算は、政府歳入（税収およびその他収入）および政府歳出（購入および支払い）の項目別統計である。赤字予算は、政府が歳入以上に支出していることを示す。その反対は、黒字予算となる。',
  'government_budget.description:ja:Germany':
    '政府予算は、政府歳入（税収およびその他収入）および政府歳出（購入および支払い）の項目別統計である。赤字予算は、政府が歳入以上に支出していることを示す。その反対は、黒字予算となる。',
  'visitor_arrivals_mom.description:ja:DEFAULT': '',
  'visitor_arrivals_mom.description:ja:New Zealand':
    'ニュージーランドでは、観光客到着数により12カ月未満の滞在目的での海外からの観光客受入れ数が測定されている。',
  'snb_press_conference.description:ja:DEFAULT': '',
  'snb_press_conference.description:ja:Switzerland':
    'スイスではスイス国立銀行（SNB）が金利を決定し、公式金利はSNBの政策金利である。SNBは有担保短期スイスフラン建てマネーマーケット金利をSNBの政策金利近傍に維持することを目標としている。今日、これらの最も代表的な金利はSARONである。2019年６月13日時点で従来SNBが金融政策戦略に使用していた３カ月物スイスフランLIBOR（ロンドン銀行間取引金利）の目標レンジに代わり、SNB政策金利が採用されることになった。その理由はLIBORを決定するためのマネーマーケット取引が不足し、LIBORが最も重要な参照金利としての重要性を失いつつあったためである。2011年9月6日から2015年1月15日までの期間の主な注目点は最低為替レートとして1EUR＝1.20CHFをSNBが強制執行したことであった。2014年12月18日にSNBは要求払い預金金利をマイナス0.25％とすることを決定した。マイナス金利導入の発表に伴い、当時使われていたLIBORの目標レンジは初めてマイナス圏となった。2015年1月15日、SNBは要求払い預金金利をさらにマイナス0.75％に引き下げ、目標レンジをマイナス1.25％～マイナス0.25％の間とした。2015年1月22日以来マイナス金利が適用されており、現在SNBの政策金利に対応している。',
  'non-manufacturing_business_activity.description:ja:DEFAULT': '',
  'non-manufacturing_business_activity.description:ja:United States':
    'ISM非製造業景気指数（Non-Manufacturing ISM Report On Business®）は、全国の購買および供給担当幹部からのアンケート調査回答に基づいて集計され、前月と比較した当月の変化があればそれが反映される。事業活動、新規受注、受注残、新規輸出受注、輸入、生産、サプライヤー納期、在庫、在庫見通し、輸入、価格、雇用、サプライヤー納期の各指標について、回答の割合、およびディフュージョン・インデックスが示される。50％を上回るとその非製造業の景気が全体的に拡大していることを、50％を切る場合は縮小していることを示す。サービス提供者への注文は、米国経済の約90%を占めている。',
  'snb_financial_stability_report.description:ja:DEFAULT': '',
  'snb_financial_stability_report.description:ja:Switzerland': '',
  'gdp_capital_expenditure_qoq_prel.description:ja:DEFAULT': '',
  'gdp_capital_expenditure_qoq_prel.description:ja:Japan':
    '総固定資本形成は、企業、政府、家計（非株式会社の法人は除く）が取得した新規固定資産または既存の固定資産の価値から固定資産の売却分を除いた価値を測定する。',
  '273-day_bill_auction.description:ja:DEFAULT': '',
  '273-day_bill_auction.description:ja:United States': '',
  'uk-eu_trade_talks.description:ja:DEFAULT': '',
  'uk-eu_trade_talks.description:ja:Belgium': '',
  'norges_bank_gov_olsen_speech.description:ja:DEFAULT': '',
  'norges_bank_gov_olsen_speech.description:ja:Norway': '',
  'treasury_secretary_mnuchin_testimony.description:ja:DEFAULT': '',
  'treasury_secretary_mnuchin_testimony.description:ja:United States': '',
  'hangeul_proclamation_day.description:ja:DEFAULT': '',
  'hangeul_proclamation_day.description:ja:South Korea': '',
  '6-month_bot_auction.description:ja:DEFAULT': '',
  '6-month_bot_auction.description:ja:Italy': '',
  'westpac_consumer_confidence.description:ja:DEFAULT': '',
  'westpac_consumer_confidence.description:ja:Australia':
    '消費者信頼感指数は1200以上のオーストラリアの世帯を調査した結果に基づいて算出される。この指数は、過去1年間と向こう1年間の消費者の財務状況に対する考えと、今後1年および5年間の経済状況予想、大型商品の購入状況など、5つの項目の平均をとったもので、指数が100を超えると悲観的な見方よりも楽観的な見方が多いことを示す。',
  'westpac_consumer_confidence.description:ja:New Zealand':
    'ニュージーランドでは、ウェストパックおよびマクダーモット・ミラーによる消費者信頼感指数が消費者の経済状況に対する楽観度を測定している。消費者信頼感指数では、消費者の個人的な経済状況、国の経済の見通しと大型消費に関する国際標準となっている5つの質問への回答が集計される。100を基準に、肯定的な回答と否定的な回答の平均を足し引きする。100を超えるスコアは楽観が優勢であることを、100を下回るスコアは悲観が優勢であることを示す。。',
  'milad-un-nabi.description:ja:DEFAULT': '',
  'milad-un-nabi.description:ja:Indonesia': '',
  'isra_mi_raj.description:ja:DEFAULT': '',
  'isra_mi_raj.description:ja:Indonesia': '',
  'yutaka_harada_speech.description:ja:DEFAULT': '',
  'yutaka_harada_speech.description:ja:Japan': '',
  'investment_lending_for_homes.description:ja:DEFAULT': '',
  'investment_lending_for_homes.description:ja:Australia': '',
  'veterans_day_substitute_day.description:ja:DEFAULT': '',
  'veterans_day_substitute_day.description:ja:United States': '',
  'our_lady_of_aparecida_day.description:ja:DEFAULT': '',
  'our_lady_of_aparecida_day.description:ja:Brazil': '',
  'qe_total.description:ja:DEFAULT': '',
  'qe_total.description:ja:United States':
    '米国では、金利設定の権限は連邦準備制度理事会（理事会）と連邦公開市場委員会（FOMC）に分けられている。理事会は、1つ以上の地区連邦準備銀行から提出された勧告を受けて、割引率の変更を決定する。FOMCは、中央銀行の資金量やフェデラルファンド市場金利などの公開市場操作を決定する。',
  'inflation_rate_yoy_final.description:ja:DEFAULT': '',
  'inflation_rate_yoy_final.description:ja:Russia':
    'ロシアでは、消費者物価指数の中で最も重要なカテゴリーは、食品および非アルコール飲料（全ウエートの30％）、輸送（14％）で、同指数には、衣料品と履物（11％）、住居・水道・電気・ガスその他燃料（11％）、娯楽・文化活動（6％）、アルコール飲料・タバコ（6％）、家電（６％）も含まれる。残りの16％は、医療、通信、教育、ホテル、レストラン、その他の財とサービスが占める。',
  'inflation_rate_yoy_final.description:ja:Poland':
    'ポーランドでは、消費者物価指数の中で最も重要なカテゴリーは、食品および非アルコール飲料（全ウエートの24％）、住居/エネルギー/維持（21％）、輸送（9％）、娯楽・文化（7％）、アルコール飲料・タバコ、その他の財とサービス、および衣料品（それぞれ６％）で、残りの17％は、通信、レストラン、ホテル、住居備品、教育が占める。',
  'inflation_rate_yoy_final.description:ja:Germany':
    'ドイツでは、消費者物価指数の中で最も重要なカテゴリーは、住居・水道・電気・ガスその他燃料（全ウエートの32％）、運輸（13％）、娯楽・エンターテインメント・文化（11％）、食品・非アルコール飲料（10％）で、また、同指数には、雑貨・サービス（7％）、家具・照明機器・家電・その他住宅備品（5％）、レストラン・ホテル（5％）、医療（5％）、衣料品・履物（5％）が含まれ、残りの7％はアルコール飲料、タバコ、通信、教育である。',
  'inflation_rate_yoy_final.description:ja:Spain':
    'スペインでは、消費者物価指数の中で最も重要なカテゴリーは、食品および非アルコール飲料（全ウエートの20％）、輸送（15%）、住居と光熱費（13％）、ホテル、カフェ、レストラン（12％）で、娯楽・文化（9%）、その他の財とサービス（7%）、衣料品・履物（7%）も含まれる。残りの18%は、家具・住居備品・住居維持、医療、通信、アルコール飲料・タバコ、教育が占める。',
  'inflation_rate_yoy_final.description:ja:Portugal':
    'ポルトガルでは、インフレ率が、標準的な財のバスケットに対し消費者が支払う価格の全体的な上下変動を測定する。ポルトガルの消費者物価指数の中で最も重要なカテゴリーは、食品および非アルコール飲料（全ウエートの20.8％）、輸送（14%）、その他財とサービス（10.4％）、住居・水道・電気・ガス・その他燃料（10.3%）で、レストラン・ホテルは8.6%、娯楽・文化は7.2%、衣料品・履物は6.9%、医療は6.3%、家具・住居備品・住居維持は6.2%で、残りの9.4%はアルコール飲料・タバコ、通信、教育が占める。',
  'inflation_rate_yoy_final.description:ja:Italy':
    'イタリアでは、消費者物価指数の中で最も重要なカテゴリーは、食品および非アルコール飲料（全ウエートの16％）、運輸（15％）、レストラン・ホテル（12％）、住居・水道・電気その他燃料（10％）、その他の財およびサービス（10％）である。また、同指数には、医療（9％）、娯楽・文化（8％）、衣料品・履物（7％）、家具と住宅備品（7％）も含まれ、残りの6％はアルコール飲料、タバコ、通信、教育である。',
  'inflation_rate_yoy_final.description:ja:France':
    'フランスでは、消費者物価指数の中で最も重要なカテゴリーはサービス（全ウエートの48％）で、特に住居の賃料とサービス（7％）、医療サービス（6％）、運輸（3％）、通信（2％）が重要である。また、同指数には、医療製品（4％）や衣料品・履物（4％）などの製造品（26％）、食料（16％）、石油製品（4％）などのエネルギー（8％）、タバコ（2％）も含まれる。',
  'inflation_rate_yoy_final.description:ja:Euro Area':
    'ユーロ圏では、インフレ率は調和消費者物価指数（HICP）合計の加重平均を用いて算出される。主要コンポーネントは、食品・アルコール・タバコ（全ウェートの19%）、エネルギー（11%）、非エネルギー 工業製品（29%）、サービス（41％）。HICP集計値は各国のHICPコンポーネントの加重平均として算出され、各国のウエートはその国のグループ合計における家計の最終支出の割合であり、各国HICPは各国の統計局がユーロスタットに提供する。',
  'king_s_birthday.description:ja:DEFAULT': '',
  'king_s_birthday.description:ja:Netherlands': '',
  'washington_s_birthday.description:ja:DEFAULT': '',
  'washington_s_birthday.description:ja:United States': '',
  'rba_meeting_s_minutes.description:ja:DEFAULT': '',
  'rba_meeting_s_minutes.description:ja:Australia': '',
  'boj_amamiya_speech.description:ja:DEFAULT': '',
  'boj_amamiya_speech.description:ja:Japan':
    '日本では、金利は政策委員会による金融政策決定会合で決められる。日銀の公式金利は、基準割引率である。金融政策決定会合では、次の会合までの間の金融市場操作におけるガイドラインが決められ、このガイドラインは無担保コール翌日物金利の目標として定められる。',
  'markit_pmi.description:ja:DEFAULT': '',
  'markit_pmi.description:ja:Hong Kong':
    '日経香港製造業購買担当者景気指数は製造業300社の調査に基づく製造業の業況を測定する。それぞれ加重された、新規受注（30%）、生産（25%）、雇用（20%）、サプライヤー納期（15%）、購買品在庫（10%）という5つの個別インデックスから構成され、納期インデックスは他のインデックスと比較ができるように符号が反転される。50を超える指数は製造業セクターが前期に比べて拡大していることを、50を切る指数は縮小していることを、50は変化がないことを示す。',
  'markit_pmi.description:ja:Singapore':
    'IHSマークイット・シンガポールの購買担当者景気指数（PMI™）は、製造業、サービス業、建設業、小売業400社以上の民間企業の上級購買担当者から収集したアンケート調査の回答を基に集計され、月の中旬に集計された回答に基づきその月と前月の変化があれば反映される。指数が50を超えれば全体的に民間セクターが拡大していることを、50を切れば全体的に縮小していることを示す。',
  'saint_joseph_s_day.description:ja:DEFAULT': '',
  'saint_joseph_s_day.description:ja:Colombia': '',
  'westpac_consumer_confidence_mom.description:ja:DEFAULT': '',
  'westpac_consumer_confidence_mom.description:ja:Australia': '',
  'tripartite_social_summit_video_conference.description:ja:DEFAULT': '',
  'tripartite_social_summit_video_conference.description:ja:European Union': '',
  'st_patricks_day.description:ja:DEFAULT': '',
  'st_patricks_day.description:ja:Ireland': '',
  'all_saints_day.description:ja:Mexico': '',
  'president_xi_jinping_speech.description:ja:DEFAULT': '',
  'president_xi_jinping_speech.description:ja:China': '',
  'visitor_arrivals_yoy.description:ja:DEFAULT': '',
  'visitor_arrivals_yoy.description:ja:New Zealand':
    'ニュージーランドでは、観光客到着数により12カ月未満の滞在目的での海外からの観光客受入れ数が測定されている。',
  'consumer_price_index_mom.description:ja:DEFAULT': '',
  'consumer_price_index_mom.description:ja:Italy': '',
  'consumer_price_index_mom.description:ja:Germany': '',
  'consumer_price_index_mom.description:ja:Euro Area': '',
  'industrial_production_yoy_-_final.description:ja:DEFAULT': '',
  'industrial_production_yoy_-_final.description:ja:Hungary':
    'ハンガリーでは鉱工業生産は製造業、鉱業、公益事業など経済の鉱工業セクターに属する事業の生産量を測定する。',
  'gdp_final_consumption.description:ja:DEFAULT': '',
  'gdp_final_consumption.description:ja:Australia':
    '家計最終消費支出とは、個人による財・サービスへの支出と、対家計民間非営利団体による支出の純額である。これには自動車その他の耐久財に対する個人支出、持ち家の帰属家賃、自家菜園での生産（農家で生産され消費される食品を含む）の価値、および現物支給の賃金・給与が含まれる。',
  'waitangi_day.description:ja:DEFAULT': '',
  'waitangi_day.description:ja:New Zealand': '',
  'harmonised_inflation_rate_yoy_prel.description:ja:DEFAULT': '',
  'harmonised_inflation_rate_yoy_prel.description:ja:Italy':
    '調和物価指数（HICP）は、欧州中央銀行（ECB）によるインフレおよび物価安定性の指標である。HICPは、ユーロスタットおよび各国の統計機関により調整統計手法によってまとめられる。ECBは、HICPが発表する中期に渡る年間インフレ率を2%弱に保つことを目標としている。',
  'harmonised_inflation_rate_yoy_prel.description:ja:France':
    '調和物価指数（HICP）は、欧州中央銀行（ECB）によるインフレおよび物価安定性の指標である。HICPは、ユーロスタットおよび各国の統計機関により調整統計手法によってまとめられる。ECBは、HICPが発表する中期に渡る年間インフレ率を2%弱に保つことを目標としている。',
  'harmonised_inflation_rate_yoy_prel.description:ja:Germany':
    '調和物価指数（HICP）は、欧州中央銀行（ECB）によるインフレおよび物価安定性の指標である。HICPは、ユーロスタットおよび各国の統計機関により調整統計手法によってまとめられる。ECBは、HICPが発表する中期に渡る年間インフレ率を2%弱に保つことを目標としている。',
  'harmonised_inflation_rate_yoy_prel.description:ja:Spain':
    '調和物価指数（HICP）は、欧州中央銀行（ECB）によるインフレおよび物価安定性の指標である。HICPは、ユーロスタットおよび各国の統計機関により調整統計手法によってまとめられる。ECBは、HICPが発表する中期に渡る年間インフレ率を2%弱に保つことを目標としている。',
  'hsbc_composite_pmi.description:ja:DEFAULT': '',
  'hsbc_composite_pmi.description:ja:Brazil':
    'ブラジルでは、マークイット・ブラジル総合購買担当者景気指数（PMI）が製造業およびサービス業の両方を代表する約800社の企業のパネルから収集したデータを基に、民間セクター全体の景気動向を測定している。指数は売上高、新規受注、雇用、在庫、価格などの変数の動きを追い、数値が50を超えれば企業活動が拡大傾向にあることを示し、50を下回れば全般に縮小傾向にあることを示す。',
  'upper_house_elections.description:ja:DEFAULT': '',
  'upper_house_elections.description:ja:Japan': '',
  'ism_non-manufacturing_pmi.description:ja:DEFAULT': '',
  'ism_non-manufacturing_pmi.description:ja:United States':
    'ISM非製造業景気指数（Non-Manufacturing ISM Report On Business®）は、全国の購買および供給担当幹部からのアンケート調査回答に基づいて集計され、前月と比較した当月の変化があればそれが反映される。事業活動、新規受注、受注残、新規輸出受注、輸入、生産、サプライヤー納期、在庫、在庫見通し、輸入、価格、雇用、サプライヤー納期の各指標について、回答の割合、およびディフュージョン・インデックスが示される。50％を上回るとその非製造業の景気が全体的に拡大していることを、50％を切る場合は縮小していることを示す。サービス提供者への注文は、米国経済の約90%を占めている。',
  'chinese_people_s_political_consultative_conference.description:ja:DEFAULT': '',
  'chinese_people_s_political_consultative_conference.description:ja:China': '',
  'prophet_muhammad_s_birthday.description:ja:DEFAULT': '',
  'prophet_muhammad_s_birthday.description:ja:Indonesia': '',
  'international_women_s_day.description:ja:DEFAULT': '',
  'international_women_s_day.description:ja:Russia': '',
  'sukkot.description:ja:DEFAULT': '',
  'sukkot.description:ja:Israel': '',
  'fed_yellen_testimony_before_congress.description:ja:DEFAULT': '',
  'fed_yellen_testimony_before_congress.description:ja:United States': '',
  'boe_quarterly_bulletin.description:ja:DEFAULT': '',
  'boe_quarterly_bulletin.description:ja:United Kingdom':
    'イギリスの金融政策委員会（MPC）が基準利率を設定する。中央銀行であるイングランド銀行の公式金利はレポ金利である。このレポ金利は、イングランド銀行の取引相手（銀行、住宅金融組合、証券会社）に対する公開市場操作での利率となる。',
  'inflation_rate_mom_final.description:ja:DEFAULT': '',
  'inflation_rate_mom_final.description:ja:Russia':
    '前月比インフレ率は、財とサービスの価格の前月比変化を測定する。',
  'inflation_rate_mom_final.description:ja:Poland':
    'ポーランドでは、消費者物価指数の中で最も重要なカテゴリーは、食品および非アルコール飲料（全ウエートの25％）、住居・水道・電気その他燃料（19％）、運輸（10％）、娯楽・文化（6％）、アルコール飲料・タバコ（6％）、レストラン・ホテル（6％）、家具・住宅備品、通常の住居維持（6％）、その他の財およびサービス（6％）で、残りの15％は、医療、衣料品と履物、通信、教育である。',
  'inflation_rate_mom_final.description:ja:Germany':
    'ドイツでは、消費者物価指数の中で最も重要なカテゴリーは、住居・水道・電気・ガスその他燃料（全ウエートの32％）、運輸（13％）、娯楽・エンターテインメント・文化（11％）、食品・非アルコール飲料（10％）で、また、同指数には、雑貨・サービス（7％）、家具・照明機器・家電・その他住宅備品（5％）、レストラン・ホテル（5％）、医療（5％）、衣料品・履物（5％）が含まれ、残りの7％はアルコール飲料、タバコ、通信、教育である。',
  'inflation_rate_mom_final.description:ja:Spain':
    '前月比インフレ率は、財とサービスの価格の前月比変化を測定する。',
  'inflation_rate_mom_final.description:ja:Portugal':
    '前月比インフレ率は、財とサービスの価格の前月比変化を測定する。',
  'inflation_rate_mom_final.description:ja:Italy':
    'イタリアでは、消費者物価指数の中で最も重要なカテゴリーは、食品および非アルコール飲料（全ウエートの16％）、運輸（15％）、レストラン・ホテル（12％）、住居・水道・電気その他燃料（10％）、その他の財およびサービス（10％）である。また、同指数には、医療（9％）、娯楽・文化（8％）、衣料品・履物（7％）、家具と住宅備品（7％）も含まれ、残りの6％はアルコール飲料、タバコ、通信、教育である。',
  'inflation_rate_mom_final.description:ja:France':
    'フランスでは、消費者物価指数の中で最も重要なカテゴリーはサービス（全ウエートの48％）で、特に住居の賃料とサービス（7％）、医療サービス（6％）、運輸（3％）、通信（2％）が重要である。また、同指数には、医療製品（4％）や衣料品・履物（4％）などの製造品（26％）、食料（16％）、石油製品（4％）などのエネルギー（8％）、タバコ（2％）も含まれる。',
  'inflation_rate_mom_final.description:ja:Euro Area':
    '前月比インフレ率は、財とサービスの価格の前月比変化を測定する。',
  'gdp_growth_annualized.description:ja:DEFAULT': '',
  'gdp_growth_annualized.description:ja:Israel':
    'GDP年率成長率は、前四半期比のGDP成長率が1年を通じて維持されたと仮定した場合の成長率を示す。',
  'wage_price_index_yoy.description:ja:DEFAULT': '',
  'wage_price_index_yoy.description:ja:Australia':
    'オーストラリアでは、賃金の伸びは、官民双方セクターにおける賞与を除く賃金の時給の年次変化を測定する。',
  'new_loans.description:ja:DEFAULT': '',
  'new_loans.description:ja:China': '',
  'fin_minister_hammond_speech.description:ja:DEFAULT': '',
  'fin_minister_hammond_speech.description:ja:United Kingdom': '',
  'new_car_registrations_yoy.description:ja:DEFAULT': '',
  'new_car_registrations_yoy.description:ja:Germany': '',
  'new_car_registrations_yoy.description:ja:France':
    'フランスでは、新車登録台数は自家用車新車の登録台数を示す。フランス国立統計経済研究所（INSEE）は四半期全国家計消費統計にこの情報を使用する。',
  'new_car_registrations_yoy.description:ja:European Union': '',
  'defenders_of_the_fatherland_holiday.description:ja:DEFAULT': '',
  'defenders_of_the_fatherland_holiday.description:ja:Russia': '',
  'wage_growth_yoy.description:ja:DEFAULT': '',
  'wage_growth_yoy.description:ja:Euro Area':
    'ユーロ圏では、実質賃金上昇率は、時給、成果給、固定給にかかわらず、会計期間における就労に対して支払われる現金その他のあらゆる給与所得（家内労働者も含む）の変化を示す。',
  'participation_rate.description:ja:DEFAULT': '',
  'participation_rate.description:ja:Canada':
    '労働参加率は、雇用されている、または未雇用だが求職中である人の数を総労働人口で割って計算したものである。',
  'urban_investment_ytd.description:ja:DEFAULT': '',
  'urban_investment_ytd.description:ja:China': '',
  'national_day.description:ja:DEFAULT': '',
  'national_day.description:ja:Hong Kong': '',
  'national_day.description:ja:Spain': '',
  'national_day.description:ja:Russia': '',
  'national_day.description:ja:Portugal': '',
  'national_day.description:ja:Sweden': '',
  'national_day.description:ja:Austria': '',
  'national_day.description:ja:China': '',
  'national_day.description:ja:Belgium': '',
  'national_day.description:ja:Switzerland': '',
  'national_day.description:ja:Singapore': '',
  'national_day.description:ja:Czech Republic': '',
  'national_day.description:ja:Taiwan': '',
  'victory_holiday.description:ja:DEFAULT': '',
  'victory_holiday.description:ja:Russia': '',
  'tankan_non-manufacturing_outlook.description:ja:DEFAULT': '',
  'tankan_non-manufacturing_outlook.description:ja:Japan': '',
  'fed_open_board_meeting.description:ja:DEFAULT': '',
  'fed_open_board_meeting.description:ja:United States': '',
  'hsbc_pmi_manufacturing.description:ja:DEFAULT': '',
  'hsbc_pmi_manufacturing.description:ja:Brazil': '',
  'finance_minister_sch_uble_speech.description:ja:DEFAULT': '',
  'finance_minister_sch_uble_speech.description:ja:Germany': '',
  'wpi_inflation_yoy.description:ja:DEFAULT': '',
  'wpi_inflation_yoy.description:ja:India':
    '2013年に消費者物価指数が卸売物価指数（WPI）に代わりインドのインフレ率の主要指標となった。インドでは消費者物価指数の最も重要なカテゴリーは食品・飲料（全ウェートの45.86%）であり、うち穀物と穀類製品が9.67%、牛乳と乳製品が6.61%、野菜が6.04%、加工食品・スナック・菓子等が5.55%、肉・魚類が3.61%、油と油脂が3.56%である。その他カテゴリーは28.32%を占め、うち輸送・通信が8.59%、医療が5.89%、教育が4.46%。住居カテゴリーは10.07%、燃料と照明は6.84%、衣類・履物は6.53%、インドの嗜好品パーン、タバコ、酒類は2.38％。インドでは、輸入エネルギーへの依存度が高いことと劣悪な道路状況とインフラにより食品を市場に輸送するのが困難であること、そして多額の財政赤字により、消費者物価指数は非常に変動が激しい。',
  'unemployed_persons.description:ja:DEFAULT': '',
  'unemployed_persons.description:ja:Austria':
    'オーストリアでは失業者数は、仕事がなく、積極的に職を探している個人の数である。',
  'unemployed_persons.description:ja:Norway':
    'ノルウェーでは失業者数は、仕事がなく、積極的に職を探している個人の数である。',
  'unemployed_persons.description:ja:South Africa':
    '南アフリカでは失業者数は、仕事がなく、積極的に職を探している個人の数である。',
  'participation_rate.description:ja:New Zealand':
    '労働参加率は、雇用されている、または未雇用だが求職中である人の数を総労働人口で割って計算したものである。',
  'participation_rate.description:ja:Australia':
    '労働参加率は、雇用されている、または未雇用だが求職中である人の数を総労働人口で割って計算したものである。',
  'participation_rate.description:ja:United States':
    '民間労働参加率は、雇用されている、または未雇用だが求職中である人の数を16歳以上の人口で割って計算したものである。',
  'government_payrolls.description:ja:DEFAULT': '',
  'government_payrolls.description:ja:United States':
    '政府雇用者数は、連邦政府、州政府、地方自治体を含む米国の政府での就労に従事する労働者の絶対数の変化をまとめている。',
  'eu_leaders_vote_on_art_50_extension.description:ja:DEFAULT': '',
  'eu_leaders_vote_on_art_50_extension.description:ja:European Union': '',
  'local_elections.description:ja:DEFAULT': '',
  'local_elections.description:ja:Taiwan': '',
  'local_elections.description:ja:United Kingdom': '',
  'local_elections.description:ja:Netherlands': '',
  'local_elections.description:ja:France': '',
  'local_elections.description:ja:Belgium': '',
  'local_elections.description:ja:Indonesia': '',
  'local_elections.description:ja:South Korea': '',
  'wage_inflation_yoy.description:ja:DEFAULT': '',
  'wage_inflation_yoy.description:ja:Italy':
    'イタリアでは賃金伸び率は時給指数の年次変化を指し、労働協約によって設定される契約上の時給や月給の推移を示す。',
  's_p_case-shiller_home_price_yoy.description:ja:DEFAULT': '',
  's_p_case-shiller_home_price_yoy.description:ja:United States':
    'S&P コアロジック・ケース・シラー20都市総合住宅価格指数は、アメリカの20都市（アトランタ、ボストン、シャーロット、シカゴ、クリーブランド、ダラス、デンバー、デトロイト、ラスベガス、ロサンゼルス、マイアミ、ミネアポリス、ニューヨーク、フェニックス、ポートランド、サンディエゴ、サンフランシスコ、シアトル、タンパ、ワシントンDC）の住宅価格の変化を測定したものである。',
  'michigan_expectations_prel.description:ja:DEFAULT': '',
  'michigan_expectations_prel.description:ja:United States':
    '消費者信頼感指数は、（1）消費者自身の財務状況の先行き見通し、（2）目先の経済全体に関する先行き見通し、（3）長期的な経済全体に関する先行き見通しの3つの分野に注目するもので、毎月実施されるアンケート調査には50項目の中核的な設問が含まれ、それぞれの設問が消費者の態度と予想を追跡する。消費者調査のサンプルは統計的にアラスカ州とハワイ州を除くすべての米国世帯を代表するように設計され、毎月500人以上に電話でインタビューが実施される。',
  'maundy_thursday.description:ja:DEFAULT': '',
  'maundy_thursday.description:ja:Portugal': '',
  'maundy_thursday.description:ja:Philippines': '',
  'maundy_thursday.description:ja:Colombia': '',
  'maundy_thursday.description:ja:Norway': '',
  'maundy_thursday.description:ja:Denmark': '',
  'maundy_thursday.description:ja:Spain': '',
  'maundy_thursday.description:ja:Mexico': '',
  'pm_may_s_plan_b_statement_on_brexit.description:ja:DEFAULT': '',
  'pm_may_s_plan_b_statement_on_brexit.description:ja:United Kingdom': '',
  'existing_home_sales.description:ja:DEFAULT': '',
  'existing_home_sales.description:ja:United States':
    '中古住宅販売件数は、ローンが実行されると発生したとみなされ、ローンは通常、販売契約締結から30～60日後に実行される。',
  'continuing_jobless_claims.description:ja:DEFAULT': '',
  'continuing_jobless_claims.description:ja:United States':
    '失業保険継続申請件数は、失業者と失業手当を少なくとも2週間前までに申請し受給している実人数をまとめたものである。',
  'ecofin_meeting.description:ja:DEFAULT': '',
  'ecofin_meeting.description:ja:European Union': '',
  'ecofin_meeting.description:ja:Euro Area': '',
  'foundation_day_of_korea.description:ja:DEFAULT': '',
  'foundation_day_of_korea.description:ja:South Korea': '',
  'purim_tel_aviv.description:ja:DEFAULT': '',
  'purim_tel_aviv.description:ja:Israel': '',
  'usda_prospective_plantings_report.description:ja:DEFAULT': '',
  'usda_prospective_plantings_report.description:ja:United States': '',
  'pce_prices_qoq.description:ja:DEFAULT': '',
  'pce_prices_qoq.description:ja:United States': '',
  'waisak_day.description:ja:DEFAULT': '',
  'waisak_day.description:ja:Indonesia': '',
  'employment_change_qoq_prel.description:ja:DEFAULT': '',
  'employment_change_qoq_prel.description:ja:Euro Area':
    'ユーロ圏では雇用変化は、賃金または利益のために働く者と無給家族労働者の数の前四半期比変化を指し、推定値にはフルタイムとパートタイム双方が含まれる。',
  'queen_s_birthday.description:ja:DEFAULT': '',
  'queen_s_birthday.description:ja:New Zealand': '',
  'queen_s_birthday.description:ja:Australia': '',
  'gfk_consumer_confidence_final.description:ja:DEFAULT': '',
  'gfk_consumer_confidence_final.description:ja:United Kingdom':
    '英国では消費者信頼感は向こう12カ月間の景気見通しに対する消費者の楽観度を測定する。GfK消費者信頼感指数は約2000人の消費者を対象に、個人の財務状況や大きな買い物に向けた環境、全体的な経済状況、貯蓄水準などを含めて過去と将来の相対的な経済状況を尋ねた調査から導き出される。',
  'fed_yellen_testimony.description:ja:DEFAULT': '',
  'fed_yellen_testimony.description:ja:United States': '',
  'm2_money_supply_yoy.description:ja:DEFAULT': '',
  'm2_money_supply_yoy.description:ja:Russia':
    'ロシアのマネーサプライM2は、M1に加え銀行での短期預金を含めたものである。',
  'm2_money_supply_yoy.description:ja:Taiwan':
    '台湾のマネーサプライM2は、M1に加え銀行での短期預金を含めたものである。',
  'm2_money_supply_yoy.description:ja:Czech Republic':
    'チェコ共和国のマネーサプライM2は、M1に銀行の短期定期預金を加えたもので、2016年にチェコ中央銀行は主要なマネーサプライ指標を、従来使用していた国内定義によるM2合計から、欧州中央銀行制度の手法に関する指針に沿って集計された調和マネタリー統計下の調和M3マネーサプライに変更した。',
  'm2_money_supply_yoy.description:ja:China':
    '中国のマネーサプライM2は、M1に加え銀行での短期預金を含めたものである。',
  'm2_money_supply_yoy.description:ja:Indonesia':
    'インドネシアのマネーサプライM2は、M1に加え銀行での短期預金を含めたものである。',
  'feast_of_the_sacred_heart.description:ja:DEFAULT': '',
  'feast_of_the_sacred_heart.description:ja:Colombia': '',
  'rba_kohler_speech.description:ja:DEFAULT': '',
  'rba_kohler_speech.description:ja:Australia': '',
  'boe_interest_rate_decision.description:ja:DEFAULT': '',
  'boe_interest_rate_decision.description:ja:United Kingdom':
    'イギリスの金融政策委員会（MPC）が基準利率を設定する。中央銀行であるイングランド銀行の公式金利はレポ金利である。このレポ金利は、イングランド銀行の取引相手（銀行、住宅金融組合、証券会社）に対する公開市場操作での利率となる。',
  'labor_cost.description:ja:DEFAULT': '',
  'labor_cost.description:ja:Euro Area': '',
  'public_sector_borrowing_mom.description:ja:DEFAULT': '',
  'public_sector_borrowing_mom.description:ja:United Kingdom': '',
  'gdp_growth_rate_qoq_-_p.description:ja:DEFAULT': '',
  'gdp_growth_rate_qoq_-_p.description:ja:Hungary':
    'ハンガリーでは家計消費がGDPの主な構成要素であり、総支出の50％を占め、総固定資本形成（22％）、政府支出（20％）がそれに続く。財・サービスの輸出はGDPの89%である一方、輸入は82%であるため、GDP合計に７%のプラス寄与となっている。',
  'fed_ccar_for_big_banks.description:ja:DEFAULT': '',
  'fed_ccar_for_big_banks.description:ja:United States': '',
  'manufacturing_production_mom.description:ja:DEFAULT': '',
  'manufacturing_production_mom.description:ja:Norway': '',
  'manufacturing_production_mom.description:ja:South Africa':
    '南アフリカでは鉱工業生産は製造業、鉱業、公益事業など経済の鉱工業セクターに属する事業の生産量を測定する。',
  'manufacturing_production_mom.description:ja:United States': '',
  'manufacturing_production_mom.description:ja:United Kingdom': '',
  'ram_navmi.description:ja:DEFAULT': '',
  'ram_navmi.description:ja:India': '',
  'ecb_c_ur_speech.description:ja:DEFAULT': '',
  'ecb_c_ur_speech.description:ja:Euro Area': '',
  'total_new_vehicle_sales.description:ja:DEFAULT': '',
  'total_new_vehicle_sales.description:ja:South Africa':
    '南アフリカでは、車両販売台数は、1カ月間におけるバス、大型車両を含めた車両販売台数合計を測定する。',
  'st_patrick_s_day.description:ja:DEFAULT': '',
  'st_patrick_s_day.description:ja:Ireland': '',
  'nfib_business_optimism_index.description:ja:DEFAULT': '',
  'nfib_business_optimism_index.description:ja:United States':
    'NFIB中小企業楽観指数は、約620のNFIB会員からの回答に基づいて算出される10の季節調整済み要素で構成されている。質問には、雇用増の計画、設備投資計画、在庫増計画、経済改善の見通し、不動産価格上昇の見通し、足元在庫、現在の人員募集状況、信用状況の見通し、現在が事業拡大に適した時期であるか、そして利益動向が含まれる。',
  'hari_raya_haji.description:ja:DEFAULT': '',
  'hari_raya_haji.description:ja:Indonesia': '',
  'hari_raya_haji.description:ja:Singapore': '',
  'feast_of_purim_tel_aviv.description:ja:DEFAULT': '',
  'feast_of_purim_tel_aviv.description:ja:Israel': '',
  'qe_treasuries.description:ja:DEFAULT': '',
  'qe_treasuries.description:ja:United States':
    '米国では、金利設定の権限は連邦準備制度理事会（理事会）と連邦公開市場委員会（FOMC）に分けられている。理事会は、1つ以上の地区連邦準備銀行から提出された勧告を受けて、割引率の変更を決定する。FOMCは、中央銀行の資金量やフェデラルファンド市場金利などの公開市場操作を決定する。',
  'ppi_core_output_yoy.description:ja:DEFAULT': '',
  'ppi_core_output_yoy.description:ja:United Kingdom':
    'イギリスでは、コア生産者価指数として毎月、食品、飲料、たばこ、石油製品を除く生産者により売買された製品の価格変化を調査している。',
  'bundesbank_mauderer_speech.description:ja:DEFAULT': '',
  'bundesbank_mauderer_speech.description:ja:Germany':
    'ドイツはユーロを採用している欧州連合（EU）加盟国である。ドイツの基準金利は欧州中央銀行が設定し、公式には主要リファイナンス・オペ（MRO）と呼ばれる。',
  'markit_cips_uk_services_pmi.description:ja:DEFAULT': '',
  'markit_cips_uk_services_pmi.description:ja:United Kingdom':
    'マークイット/CIPS英国サービス業購買担当者景気指数（PMI）は、英国の輸送および通信業、金融仲介業、ビジネスサービス業、個人向けサービス業、コンピューティングおよびIT業、ホテルおよびレストラン業から収集したデータを基に集計される。指数は、売上高、雇用、在庫、価格などの変数を追跡し、数値が50を超えれば、サービス業が全体的に拡大傾向にあることを示し、50を下回れば、全般に縮小傾向にあることを示す。。',
  'markit_manufacturing_pmi.description:ja:DEFAULT': '',
  'markit_manufacturing_pmi.description:ja:Taiwan':
    'IHSマークイット台湾製造業購買担当者景気指数（PMI）は製造業300社の調査に基づき、製造業の業況を測定する。それぞれウエート付けされた、新規受注（30%）、生産（25%）、雇用（20%）、サプライヤー納期（15%）、購買品在庫（10%）という5つの個別インデックスから構成され、納期インデックスは他のインデックスと比較できるように符号が反転される。50を超える指数は製造業セクターが前期に比べて拡大していることを、50を切る指数は縮小していることを、50は変化がないことを示す。',
  'redbook_mom.description:ja:DEFAULT': '',
  'redbook_mom.description:ja:United States':
    'ジョンソン・レッドブック指数は、アメリカの大手一般小売業者約9,000店舗の前年比既存店売上高成長率を、売上高を加重して算出したものである。既存店売上高とは、12カ月以上継続して営業している店舗の売上を指す。この指数は、ドルベースで米商務省が集計・公表する「公式」の小売売上高の80%に相当する。レッドブックは、小売事業者の業績予測をまとめ、解釈を加えることで構成される。指数とそのサブグループは、これらの予測の売上高を加重して合計したもので、統計内の週は小売り営業週間（日曜から土曜）であり、月内で均等加重となっている。',
  '15-year_obligacion_auction.description:ja:DEFAULT': '',
  '15-year_obligacion_auction.description:ja:Spain': '',
  'raw_material_price_mom.description:ja:DEFAULT': '',
  'raw_material_price_mom.description:ja:Canada': '',
  'parliamentary_vote_on_art_50_extension.description:ja:DEFAULT': '',
  'parliamentary_vote_on_art_50_extension.description:ja:United Kingdom': '',
  'private_capital_expenditure_qoq.description:ja:DEFAULT': '',
  'private_capital_expenditure_qoq.description:ja:Australia':
    'オーストラリアの民間企業設備投資は、民間設備投資の前四半期比変化を測定する。オーストラリアの設備投資調査では、鉱業、製造業に加え、電気、ガス、水道、廃棄物処理サービス、建設、卸売販売、小売販売、輸送、配送および倉庫、情報メディア、通信、金融およびレンタル、ハイヤーおよび不動産専門サービス、科学およびテクニカルサービスなどその他の産業が対象となっている。',
  'jibun_bank_manufacturing_pmi_final.description:ja:DEFAULT': '',
  'jibun_bank_manufacturing_pmi_final.description:ja:Japan':
    'じぶん銀行日本製造業購買担当者景況指数（PMI）®は、約400社の製造業企業の購買管理者に送信されたアンケート調査の結果をIHSマークイットが毎月集計するもので、対象企業はGDPへの寄与度に基づき、詳細セクターと企業の社員数によって階層化さている。アンケートの回答は前月に比べた変化の方向を示し、各調査変数に関してディフュージョンインデックス（DI）が算出される。同指数は「改善」と回答した割合（％）に、「横ばい」と回答した割合の半分を加算して算出する。指数の値は0～50の間で、50を超える場合は全体的に前月からの改善・増加を、50未満の場合は悪化・減少を表す。このPMIは、新規受注（50％）、生産（25％）、雇用（20％）、サプライヤー納期（15％）、購買品在庫（10％）の5つの指数の加重平均である。PMIの算出においてはサプライヤー納期インデックスは他のインデックスと同じ方向に動くよう、符号を反転させている。',
  'private_non_farm_payrolls_qoq_prel.description:ja:DEFAULT': '',
  'private_non_farm_payrolls_qoq_prel.description:ja:France':
    'フランスでは、民間セクタ―の雇用者数は、雇用期間に関係なく四半期最終日の雇用者数を示す。全セクターの雇用者数が推定され、2010年第3四半期まではフランス本土のみのデータが集計されていたが、2010年第4四半期以降はフランス領土すべてが含まれている（マヨットは除く）。',
  'capital_expenditure_qoq.description:ja:DEFAULT': '',
  'capital_expenditure_qoq.description:ja:Australia': '',
  'rba_bulletin.description:ja:DEFAULT': '',
  'rba_bulletin.description:ja:Australia':
    'オーストラリアでは金利決定はオーストラリア準備銀行理事会が行い、政策金利はキャッシュレートである。キャッシュレートとは、金融機関の間で翌日物貸付に適用される利率で、翌日物資金の需要と供給によってマネーマーケットで決定される。',
  'factory_orders_ex_transportation.description:ja:DEFAULT': '',
  'factory_orders_ex_transportation.description:ja:United States':
    '工場受注報告は「製造業者の出荷、在庫、受注（M3）サーベイ」によって集計され、耐久財（全受注の50％）と非耐久財双方の工場受注を表す。このサーベイは通常、耐久財受注報告の1週間後に発表される。。',
  'cabinet_meeting_on_stimulus_measures.description:ja:DEFAULT': '',
  'cabinet_meeting_on_stimulus_measures.description:ja:South Korea': '',
  'vesak_day.description:ja:DEFAULT': '',
  'vesak_day.description:ja:Indonesia': '',
  'vesak_day.description:ja:Singapore': '',
  'imf_meeting.description:ja:DEFAULT': '',
  'imf_meeting.description:ja:United States': '',
  'harvest_festival_day_2.description:ja:DEFAULT': '',
  'harvest_festival_day_2.description:ja:South Korea': '',
  'cpi_ex-fresh_food_yoy.description:ja:DEFAULT': '',
  'cpi_ex-fresh_food_yoy.description:ja:Japan': '',
  'gdp_growth_rate_2015_flash.description:ja:DEFAULT': '',
  'gdp_growth_rate_2015_flash.description:ja:Germany':
    '世界第5位、欧州最大の経済規模を誇るドイツ経済は、機械、自動車、化学品、家庭用機器などの主要な輸出国であり、高度に熟練した労働力の恩恵を受けている。GDPの支出側の構成：家計消費（55％）、総資本形成（20％、うち建設業が10％、機械装置が6％、その他が4％）、政府支出（19％）。財・サービスの輸出はGDPの46％、輸入は39％で、GDP全体に7％寄与している。',
  'shavuot.description:ja:DEFAULT': '',
  'shavuot.description:ja:Israel': '',
  'gross_fixed_investment_yoy.description:ja:DEFAULT': '',
  'gross_fixed_investment_yoy.description:ja:Mexico':
    'メキシコでは、総設備投資により有形無形双方かつ生産過程の結果として得られた固定資産の取得額の総額が測定される。これらは1年以上、他の生産過程で繰り返し使用できるもののみが対象となる。国内外からのもの、建設に関するものも含め機械と設備の定数価値を合計し、総設備投資の前年比が示される。これには、建造物、機械、設備、輸送設備、ソフトウェア、著作物、その他有形無形の固定資産、処理能力を拡大させたり耐用期間を拡大するための追加および更新が含まれる。国内起源の機械設備については、新規製品のみが考慮される。新規および中古の輸入機械設備が同国の資産価値の上昇に貢献している。建設総固定投資指数は、購入者の支払う価格での投資額となっている。',
  'emperor_s_birthday.description:ja:DEFAULT': '',
  'emperor_s_birthday.description:ja:Japan': '',
  'zew_economic_sentiment_index.description:ja:DEFAULT': '',
  'zew_economic_sentiment_index.description:ja:Switzerland':
    'スイスでは、CS-CFA社会景況感指数がアナリストによる今後6カ月の経済成長に対する楽観度を測定する。この調査では、最大350名の金融および経済アナリストが対象となる。指数は、経済成長に関する楽観視と悲観視のパーセンテージの差として表される。従って、ZEW指標はマイナス100（すべてのアナリストが景気悪化を予測）とプラス100（すべてのアナリストが景気改善を予測）の間の値となる。0は中立的な見通しであることを示す。',
  'zew_economic_sentiment_index.description:ja:Germany':
    'ドイツでは、ZEW社会景況感指数がアナリストによる今後6カ月の経済成長に関する楽観度を測定する。この調査では、最大350名の金融および経済アナリストが対象となる。指数は、経済成長に関する楽観視と悲観視のパーセンテージの差として表される。従って、ZEW指標は-100（すべてのアナリストが景気悪化を予測）からプラス100（すべてのアナリストが景気改善を予測）の間の値となる。0は中立的な見通しであることを示す。',
  'zew_economic_sentiment_index.description:ja:Euro Area':
    'ユーロ圏では、ZEW社会景況感指数がアナリストによる今後6カ月の経済成長に関する楽観度を測定する。この調査では、最大350名の金融および経済アナリストが対象となる。指数は、経済成長に関する楽観視と悲観視のパーセンテージの差として表される。従って、ZEW指標はマイナス100（すべての専門家が景気悪化を予測）からプラス100（すべての専門家が景気改善を予測）の間の値となる。0は中立的な見通しであることを示す。',
  'rhineland-palatinate_state_election.description:ja:DEFAULT': '',
  'rhineland-palatinate_state_election.description:ja:Germany': '',
  'labour_productivity_qoq_final.description:ja:DEFAULT': '',
  'labour_productivity_qoq_final.description:ja:United Kingdom':
    '英国では、労働生産性は一定期間における1労働単位当たりの実質生産高を示す。',
  'urban_investment_yoy.description:ja:DEFAULT': '',
  'urban_investment_yoy.description:ja:China': '',
  'fed_yellen_speaks.description:ja:DEFAULT': '',
  'fed_yellen_speaks.description:ja:United States': '',
  'international_children_s_day.description:ja:DEFAULT': '',
  'international_children_s_day.description:ja:South Korea': '',
  'international_children_s_day.description:ja:Japan': '',
  'international_children_s_day.description:ja:Taiwan': '',
  'us-china_trade_talks.description:ja:DEFAULT': '',
  'us-china_trade_talks.description:ja:United States': '',
  'us-china_trade_talks.description:ja:China': '',
  'new_year_s_eve.description:ja:DEFAULT': '',
  'new_year_s_eve.description:ja:Germany': '',
  'new_year_s_eve.description:ja:Philippines': '',
  'new_year_s_eve.description:ja:Sweden': '',
  'new_year_s_eve.description:ja:South Korea': '',
  'new_year_s_eve.description:ja:Thailand': '',
  'new_year_s_eve.description:ja:Israel': '',
  'gdp_growth_rate_qoq_1st_est.description:ja:DEFAULT': '',
  'gdp_growth_rate_qoq_1st_est.description:ja:France':
    'フランスは世界で7番目、ユーロ圏で2番目に大きい経済国である。GDPの支出を大きく占めるのは家計消費（55％）、政府支出（24％）、総固定資本形成（22％）となっている。財・サービスの輸出はGDPの29％、輸入は31％であるため、純輸出はGDP全体に2％のマイナス寄与となっている。',
  'gdp_growth_rate_qoq_1st_est.description:ja:Israel':
    'イスラエル経済の主要なけん引役は科学技術セクターで、天然資源が限定的であるにもかかわらず製造・農業セクターは高度に進化している。イスラエル経済最大のセクターはサービス業でGDPの80%を占め、うち最も重要なのは金融とビジネス（GDP寄与率28％）、政府（同17％）、住宅（同12％）、貿易、宿泊、レストラン（同10％）、輸送・保管・通信（同8％）。製造業の重要性は近年低下しており、今ではGDP寄与率は14％。',
  'total_vehicle_sales.description:ja:DEFAULT': '',
  'total_vehicle_sales.description:ja:United States':
    '米国では、年率換算された国内新車販売台数がその月の車両販売台数としてまとめられている。',
  'uk_supreme_court_ruling_on_article_50.description:ja:DEFAULT': '',
  'uk_supreme_court_ruling_on_article_50.description:ja:United Kingdom': '',
  'zew_current_conditions.description:ja:DEFAULT': '',
  'zew_current_conditions.description:ja:Germany': '',
  'quarterly_grain_stocks_-_wheat.description:ja:DEFAULT': '',
  'quarterly_grain_stocks_-_wheat.description:ja:United States': '',
  'ibc-br_economic_activity.description:ja:DEFAULT': '',
  'ibc-br_economic_activity.description:ja:Brazil':
    'ブラジルでは、経済活動指数（IBC-Br）はGDPを事前に表しているものとされ、中央銀行金融政策委員会が基本金利を見直す際に広く用いられている。同指数は、農業、鉱業、サービスの主要3分野の業況を反映し、2002年を100の基準値とする。',
  'vehicle_production_yoy.description:ja:DEFAULT': '',
  'vehicle_production_yoy.description:ja:Japan': '',
  'unity_day.description:ja:DEFAULT': '',
  'unity_day.description:ja:Russia': '',
  'rba_ryan_speech.description:ja:DEFAULT': '',
  'rba_ryan_speech.description:ja:Australia': '',
  'unit_labor_costs_qoq_prel.description:ja:DEFAULT': '',
  'unit_labor_costs_qoq_prel.description:ja:United States': '',
  'treasury_secretary_steven_mnuchin_testimony.description:ja:DEFAULT': '',
  'treasury_secretary_steven_mnuchin_testimony.description:ja:United States': '',
  'retail_sales_mom_prel.description:ja:DEFAULT': '',
  'retail_sales_mom_prel.description:ja:Australia':
    'オーストラリアでは、小売売上高が一定期間における小売商品とサービスの総売上高を測定する。この小売売上高は季節要因の影響を受け変動が大きく、経済全体に対する重要性も比較的高い。',
  'economic_sentiment_indicator.description:ja:DEFAULT': '',
  'economic_sentiment_indicator.description:ja:Euro Area': '',
  'orthodox_pentecost_monday.description:ja:DEFAULT': '',
  'orthodox_pentecost_monday.description:ja:Greece': '',
  'treasury_secretary_yellen_senate_confirmation_hearing.description:ja:DEFAULT': '',
  'treasury_secretary_yellen_senate_confirmation_hearing.description:ja:United States': '',
  'fed_chairman_nomination_vote.description:ja:DEFAULT': '',
  'fed_chairman_nomination_vote.description:ja:United States': '',
  'the_emperor_s_birthday_substitute_day.description:ja:DEFAULT': '',
  'the_emperor_s_birthday_substitute_day.description:ja:Japan': '',
  'european_council_meeting_on_brexit.description:ja:DEFAULT': '',
  'european_council_meeting_on_brexit.description:ja:European Union': '',
  '50-year_obligacion_auction.description:ja:DEFAULT': '',
  '50-year_obligacion_auction.description:ja:Spain': '',
  'nbp_interest_rate_decison.description:ja:DEFAULT': '',
  'nbp_interest_rate_decison.description:ja:Poland':
    'ポーランドでは金利決定はポーランド国立銀行（NBP）が行い、政策金利はレファレンス金利である。',
  'the_day_of_valor.description:ja:DEFAULT': '',
  'the_day_of_valor.description:ja:Philippines': '',
  'imports.description:ja:DEFAULT': '',
  'imports.description:ja:Philippines':
    'フィリピンの主な輸入品目は、電子製品（25%）、鉱物性燃料（21%）、輸送設備（10%）となっている。フィリピンの主な輸入相手国は、中国（13%）、米国（11%）、日本（8%）、台湾（8%）となっている。',
  'imports.description:ja:Russia':
    'ロシアの主な輸入品目は、機械および設備、輸送機器（輸入全体の45%）、化学製品（19%）、食料および農業製品（14.5%）となっている。2015年の主な輸入相手国は、中国（総輸入額の19%）、ドイツ（11%）、米国（6%）、ベラルーシ（5%）となっている。',
  'imports.description:ja:Portugal':
    '2017年時点でポルトガルの主な輸入品目は、機械・機械設備・電気機器（輸入全体の17%）、車両、航空機、船舶、関連輸送機器（14%）、鉱物製品（12%）、化学および関連産業製品（10%）、ベースメタルおよび関連製品（8%）、プラスチック・ゴム（6%）、繊維および繊維製品（6%）、家畜および家畜製品（5%）、加工済食料（5%）、野菜（4%）だった。ポルトガルの主要な輸入相手国はスペイン（全輸出の32%）、ドイツ（14%）、フランス（7%）、イタリアおよびオランダ（各5%）、英国および中国（各3%）、ロシアおよびブラジル（各2%）となっている。',
  'imports.description:ja:China':
    '中国の輸入高は、国内需要の減退とアメリカとの貿易摩擦によって2019年に3年ぶりの2.7%減となった。機械と輸送機器の輸入が全体の38％を占め、電気機器と器具および電化製品（21％）、車両（4％）、通信および録音再生機器と設備（3％）、オフィス機器および自動データ処理機（3％）が続いている。その他主要な輸入カテゴリーとしては、主に石油、関連製品（13%）、天然および生成ガス（3％）などの燃料、潤滑油、関連製品（17％）、鉄鉱石や鉄くず（9%）などの、燃料を除く未精製物質（14％）、天然化学品（3%）やプラスチック原料（3%）などの化学品（11%）、その他製造済雑品（7%）、主に原料で分類される製造品（7%）、食料および家畜（4%）となっている。輸入相手国上位は、EU（輸入全体の13%）、うちドイツが5%、フランスが2%を筆頭に、韓国、台湾、日本（各8%）、アメリカとオーストラリア（各6%）、ブラジル（4%）、マレーシア、ベトナム、ロシア、サウジアラビア（各3%）、タイ、シンガポール、インドネシア（各2%）となっている。',
  'imports.description:ja:South Korea':
    '2019年、韓国の輸入高は国内生産高と設備投資が縮小したため前年の過去最高額から6%減少し、5,032億米ドルとなった。主な輸入品目は、燃料、天然油、瀝青物質（輸入全体の25%）、電気機械および設備（18%）、原子炉、ボイラー、機械および工作機械（10%）、光学製品、写真、映像、計測機器（4%）、車両（3%）、鉄および鋼鉄（3%）、鉄鉱石、スラグ、灰（3%）、有機化学薬品（3%）、プラスチックおよび関連製品（2%）、化学製品雑品（2%）、非有機化学品、希少金属化合物（2%）となっている。中国が最大の貿易相手国（輸入額の21%）であり、アメリカ（12%）、日本（9%）、サウジアラビア、ベトナム、オーストラリア、ドイツ（各4%）、台湾、ロシア、カタール（各3%）、クウェート、マレーシア、アラブ首長国連邦、インドネシア（各2%）が続く。',
  'imports.description:ja:Taiwan':
    '天然資源の不足により、台湾は輸入依存となっている。台湾は主に、電子部品（総額の18.2%）、鉱業製品（15.2%）、機械（12.4%）、化学品（10.8%）、ベースメタル（7.3%）の部品を輸入に頼っている。主な輸入相手国は中国本土および香港（総額の19.6%）、日本（17.6%）、欧州（12.6%）、米国（12.4%）、ASEAN諸国（11.8%）、中東（7.7%）となっている。',
  'imports.description:ja:Canada':
    '2018年のカナダの主な輸入品目は、消費者向け商品（輸入全体の20%）、自動車・自動車部品（19%）、電子製品および電子設備と部品（12%）、工業機械、設備、部品（11%）、基本産業薬剤、プラスチック、ゴム製品（8%）、金属・非金属鉱業製品（7%）、エネルギー製品（6％）であった。カナダの主な輸入相手国上位は、米国（64%）、EU（10%）（うちドイツが3%）、中国（8%）、メキシコ（3%）、日本（2%）となっている。',
  'imports.description:ja:New Zealand':
    'ニュージーランドの主な輸入品目は、車両（13％）、原子炉、ボイラー、機械、機械部品（13%）、燃料（10％）、電子機械および設備（8％）、プラスチック（4％）、航空機、宇宙航空機（4%）である。主な輸入相手国は 中国（輸入全体の20％）、オーストラリアおよび米国（各12％）、日本（7%）、ドイツ（5％）、タイおよび韓国（4％）となっている。',
  'imports.description:ja:Colombia':
    'コロンビアの主な輸入品目は、機械および輸送機器（輸入全体の39%）、工業製品（22%）、化学品および関連製品（17%）、燃料および潤滑油と関連製品（10%）、食料および家畜（8%）となっている。主な貿易相手国は、米国（輸入全体の24%）、中国（16%）、メキシコ（11%）、ブラジル（5%）となっている。他には、ドイツ、アルゼンチン、日本、フランス、韓国、カナダが挙げられる。',
  'imports.description:ja:Brazil':
    'ブラジルの輸入品目は主に、機械、燃料及び潤滑油、化学品および薬品、自動車およびトラクターの部品と周辺部品を始めとする製造品（輸入全体の85%）となっている。また、おもに原油、石炭、天然ガス、小麦を始めとした原料（10%）も輸入している。主な貿易相手国は、中国（19%）、米国（16%）、アルゼンチンおよびドイツ（各6%）、韓国およびメキシコ（各3%）となっている。',
  'imports.description:ja:United States':
    '米国は、世界で2番めの輸入大国となっている。主な輸入品目は、資本財（22%）と消費者向け製品（21%）である。他には、自動車、部品とエンジン（12%）、食料、飼料、飲料（5%）が挙げられる。中国からの輸入は全体の21%を占めており、続いてメキシコ（14%）、カナダ（13%）、日本（6%）、ドイツ（5%）となっている。',
  'imports.description:ja:Israel':
    'イスラエルの主な輸入品目は、機械および輸送機器（輸入全体の28%）、ダイヤモンド（11%）、石油およびその他鉱物性燃料（9%）、輸送設備（7%）となっている。主な貿易相手国は、米国（輸入全体の12%）、中国（7%）、ドイツ（6.2%）、ベルギー（6%）となっている。他には、スイス、イタリア、イギリス、オランダが挙げられる。',
  'imports.description:ja:Hong Kong': '',
  'imports.description:ja:South Africa': '',
  'imports.description:ja:Japan':
    '日本の輸入高は2019年、世界の貿易摩擦により前年の過去最高額から5%減少した。主な輸入品目は、鉱物性燃料（輸入全体の22％）で、うち石油が10％、天然ガスが6%、通信、映像、半導体などの電気機械（15%）、医療製品と有機化学品などの化学品（10%）、コンピュータおよびユニットなどの機械（10%）、魚類および魚類加工前品、肉加工前品などの食料（9%）非鉄金属の加工品（9%）、非鉄鉱石および鉄鉱石とその凝集物などの原料（6%）となっている。主な輸出相手国は、中国（23%）、特にドイツ（3%）をはじめとするEU（12%）、アメリカ（11％）、オーストラリア（6％）、韓国（4％）サウジアラビア（4％）、台湾（4％）、アラブ首長国連邦（4％）、タイ（4％）となっている。',
  'imports.description:ja:Mexico':
    'メキシコの主な輸入品目は工業製品（輸入全体の87%）であり、金属製品、機械および装置（53%、うち自動車が13%）、電気製品および電子部品（20%）、各種産業での特殊設備（15%）となっている。石油製品は輸入全体の10%に登り、石油は製品と石油化学品が主なものとなっている。農業製品は3%に上る。主な貿易相手故国は、米国（購入全体の46%）、中国（18%）、日本、ドイツ、韓国（各4%）となっている。アメリカからの主な輸入品目は、車両のその他部品と周辺部品（輸入全体の8%）、電子機器（7%）、石油製品（6%）とコンピュータ付属品（6%）となっている。',
  'imports.description:ja:Czech Republic':
    'チェコ共和国の主な輸入品目は、機械および輸送設備、原材料、燃料および化学品となっている。主な輸入相手国は欧州連合諸国、中国、ロシア、日本となっている。このページでは、チェコ共和国輸入チャート、過去データおよびニュースを紹介する。',
  'imports.description:ja:Thailand': '',
  'imports.description:ja:Indonesia':
    'インドネシアでは人口の大半が中産階級となり石油と消費製品の多額の購入を後押ししたため、輸入量が2004～2012年までの間に3倍となった。しかし、2013年中盤から、コモディティ価格の下洛と国内消費および投資の減退から輸入は減少している。主な輸入品目は、石油およびガス（輸入全体の17%）、核反応炉、ボイラー、機械部品（19%）、鉄および鋼鉄（5.4%）、有機化学品（4.8%）および車両（4.5%）となっている。主な輸入相手国は、中国（25%）、日本（11%）、シンガポール（7.6%）、タイ（6.8%）、米国（6.4%）となっている。',
  'gdp_deflator_yoy_final.description:ja:DEFAULT': '',
  'gdp_deflator_yoy_final.description:ja:Japan':
    'GDPデフレーターは、最終財とサービスの価格変化を測るものであり、将来の金融政策の方向性に影響するインフレ圧力の主要指標であるとされている。',
  'private_sector_credit.description:ja:DEFAULT': '',
  'private_sector_credit.description:ja:South Africa':
    '南アフリカでは、民間部門への貸付は国内民間セクターへの信用供与を意味する。',
  'rbnz_mcdermott_speech.description:ja:DEFAULT': '',
  'rbnz_mcdermott_speech.description:ja:New Zealand': '',
  'tankan_small_manufacturers_index.description:ja:DEFAULT': '',
  'tankan_small_manufacturers_index.description:ja:Japan':
    '日本銀行が発表する全国企業短期経済観測調査（短観）の中小企業・製造業業況判断指数は、資本金2千万円以上の約22万社の民間企業（金融機関を除く）を対象とする調査に基づいている。調査は郵便またはEメールで行われ、参加者は足元の経済環境や企業と業界の業況、その後の3カ月間および1年間の企業活動の予想について、評価を行うよう求められる。指数は、肯定的な回答を選んだ企業の割合から、否定的な回答を選択した企業の割合を差し引いて算出される。指数はプラス100からマイナス100の範囲で変動し、数値がゼロを超えれば景気に対して楽観的、ゼロを下回れば悲観的、ゼロであれば中立と判断される。',
  'wpi_fuel_yoy.description:ja:DEFAULT': '',
  'wpi_fuel_yoy.description:ja:India':
    'インドでは、卸売物価指数（WPI）がインフレ率の主要な尺度とされる。WPIは卸売商品の代表的項目で構成されるバスケットの価格を測定し、燃料・電力（13.2%）、一次産品（22.6%）、工業品（64.2%）の3つのグループに大きく分類される。一次産品に含まれる食料品は、卸売物価全体の15.2%を占める。工業品の最も重要な構成項目は、卑金属（全体の9.7%）、食品（9.1%）、化学薬品・化学製品（6.5%）、繊維（4.9％）である。',
  'new_home_sales.description:ja:DEFAULT': '',
  'new_home_sales.description:ja:United States':
    '新築住宅の販売は、売買契約の締結または手付金の受領時に発生する。新築住宅の状態は、未着工、建設中、完成済みのいずれの段階でもよい。新築住宅の販売は、米国住宅市場の約10％を占める。新築一戸建て住宅販売は、前月比で非常に変動しやすく、速報値は主に建設許可証のデータから作成されるため大幅に修正される場合がある。',
  'tankan_non-manufacturers_outlook.description:ja:DEFAULT': '',
  'tankan_non-manufacturers_outlook.description:ja:Japan': '',
  'tankan_large_non-manufacturing_index.description:ja:DEFAULT': '',
  'tankan_large_non-manufacturing_index.description:ja:Japan':
    '日本銀行が発表する全国企業短期経済観測調査（短観）の大企業・非製造業業況判断指数は、非製造業部門で事業活動を行う資本金10億円以上の約1,050社をカバーしている。調査は郵便またはEメールで行われ、参加者は足元の経済環境や企業と業界の業況、3カ月先および1年先の企業活動の予想について、評価を行うよう求められる。指数は、肯定的な回答を選んだ企業の割合から、否定的な回答を選択した企業の割合を差し引いて算出される。指数はプラス100からマイナス100の範囲で変動し、数値がゼロを超えれば景気に対して楽観的、ゼロを下回れば悲観的、ゼロであれば中立と判断される。',
  'manufacturing_production.description:ja:DEFAULT': '',
  'manufacturing_production.description:ja:South Korea':
    '製造業は韓国GDPの3分の1もの割合を占め、チェボル（財閥）として知られる産業コングロマリットが支配的な存在となっている。',
  'bundesbank_weidmann_speech.description:ja:DEFAULT': '',
  'bundesbank_weidmann_speech.description:ja:Germany':
    'ドイツはユーロを採用している欧州連合（EU）加盟国である。ドイツの基準金利は欧州中央銀行が設定し、公式には主要リファイナンス・オペ（MRO）と呼ばれる。',
  'federal_budget_balance.description:ja:DEFAULT': '',
  'federal_budget_balance.description:ja:United States': '',
  'retail_sales_ex_fuel_mom.description:ja:DEFAULT': '',
  'retail_sales_ex_fuel_mom.description:ja:United Kingdom':
    '英国の小売売上高は、特定の期間における小売商品とサービスの総売上高を測定している。この小売売上高は、季節要因の影響を受け、変動的であり、経済全体に対する重要性も比較的高い。',
  'labour_productivity_qoq_prel.description:ja:DEFAULT': '',
  'labour_productivity_qoq_prel.description:ja:United Kingdom':
    '英国では、労働生産性は一定期間における1労働単位当たりの実質生産高を示す。',
  'nikkei_manufacturing_pmi_final.description:ja:DEFAULT': '',
  'nikkei_manufacturing_pmi_final.description:ja:Japan':
    'じぶん銀行日本製造業購買担当者景況指数（PMI）®は、約400社の製造業企業の購買管理者に送信されたアンケート調査の結果をIHSマークイットが毎月集計するもので、対象企業はGDPへの寄与度に基づき、詳細セクターと企業の社員数によって階層化さている。アンケートの回答は前月に比べた変化の方向を示し、各調査変数に関してディフュージョンインデックス（DI）が算出される。同指数は「改善」と回答した割合（％）に、「横ばい」と回答した割合の半分を加算して算出する。指数の値は0～50の間で、50を超える場合は全体的に前月からの改善・増加を、50未満の場合は悪化・減少を表す。このPMIは、新規受注（50％）、生産（25％）、雇用（20％）、サプライヤー納期（15％）、購買品在庫（10％）の5つの指数の加重平均である。PMIの算出においてはサプライヤー納期インデックスは他のインデックスと同じ方向に動くよう、符号を反転させている。',
  'referendum_on_corporate_tax_unification.description:ja:DEFAULT': '',
  'referendum_on_corporate_tax_unification.description:ja:Switzerland': '',
  'battle_of_boyac_day.description:ja:DEFAULT': '',
  'battle_of_boyac_day.description:ja:Colombia': '',
  'wpi_food_yoy.description:ja:DEFAULT': '',
  'wpi_food_yoy.description:ja:India':
    'インドでは、卸売物価指数（WPI）がインフレ率の主要な尺度とされる。WPIは卸売商品の代表的項目で構成されるバスケットの価格を測定し、燃料・電力（13.2%）、一次産品（22.6%）、工業品（64.2%）の3つのグループに大きく分類される。一次産品に含まれる食料品は、卸売物価全体の15.2%を占める。工業品の最も重要な構成項目は、卑金属（全体の9.7%）、食品（9.1%）、化学薬品・化学製品（6.5%）、繊維（4.9％）である。',
  'passover_i_eve.description:ja:DEFAULT': '',
  'passover_i_eve.description:ja:Israel': '',
  'personal_consumption_expenditures_-_price_index_mom.description:ja:DEFAULT': '',
  'personal_consumption_expenditures_-_price_index_mom.description:ja:United States': '',
  'markit_bme_manufacturing_pmi_final.description:ja:DEFAULT': '',
  'markit_bme_manufacturing_pmi_final.description:ja:Germany':
    'マークイット/BMEドイツ製造業購買担当者景気指数（PMI）は製造業500社の調査に基づき、製造業の業況を測定し、それぞれウエート付けされた、新規受注（30%）、生産（25%）、雇用（20%）、サプライヤー納期（15%）、購買品在庫（10%）という5つの個別インデックスから構成され、納期インデックスは他のインデックスと同じ方向に動くよう符号が反転される。50を超える指数は製造業セクターが前期に比べて拡大していることを、50を切る指数は縮小していることを、50は変化がないことを示す。',
  'ecb_hakkarainen_speech.description:ja:DEFAULT': '',
  'ecb_hakkarainen_speech.description:ja:Euro Area':
    'ユーロ圏の基準金利は欧州中央銀行（ECB）の理事会によって決定される。ECBの金融政策の主な目的は、物価の安定を維持することであり、それはインフレ率を中期的に2％未満でかつそれに近い水準に抑えることである。低インフレ・低金利が長期化した場合、ECBは資産購入プログラムなどの非標準的な金融政策を採用することもある。公式金利は、主要リファイナンスオペ金利である。。',
  'pentecost.description:ja:DEFAULT': '',
  'pentecost.description:ja:France': '',
  'pentecost.description:ja:Hungary': '',
  'pentecost.description:ja:Israel': '',
  'pentecost.description:ja:Sweden': '',
  'pentecost.description:ja:Denmark': '',
  'pentecost.description:ja:Poland': '',
  'pentecost.description:ja:Finland': '',
  'pentecost.description:ja:Netherlands': '',
  'pentecost.description:ja:Norway': '',
  'tankan_all_small_industry_capex.description:ja:DEFAULT': '',
  'tankan_all_small_industry_capex.description:ja:Japan': '',
  'anz_commodity_price.description:ja:DEFAULT': '',
  'anz_commodity_price.description:ja:New Zealand': '',
  'republic_implantation.description:ja:DEFAULT': '',
  'republic_implantation.description:ja:Portugal': '',
  'veterans_day.description:ja:DEFAULT': '',
  'veterans_day.description:ja:United States': '',
  'assumption_of_mary.description:ja:DEFAULT': '',
  'assumption_of_mary.description:ja:Spain': '',
  'assumption_of_mary.description:ja:Portugal': '',
  'assumption_of_mary.description:ja:Italy': '',
  'assumption_of_mary.description:ja:Greece': '',
  'assumption_of_mary.description:ja:Poland': '',
  'assumption_of_mary.description:ja:Germany': '',
  'assumption_of_mary.description:ja:Austria': '',
  'assumption_of_mary.description:ja:Colombia': '',
  'assumption_of_mary.description:ja:Belgium': '',
  'assumption_of_mary.description:ja:France': '',
  'buddhas_birthday.description:ja:DEFAULT': '',
  'buddhas_birthday.description:ja:Hong Kong': '',
  'buddhas_birthday.description:ja:Singapore': '',
  'buddhas_birthday.description:ja:Indonesia': '',
  'buddhas_birthday.description:ja:South Korea': '',
  'buddhas_birthday.description:ja:Thailand': '',
  'index_of_services_3m_3m.description:ja:DEFAULT': '',
  'index_of_services_3m_3m.description:ja:United Kingdom': '',
  'cb_leading_index_mom.description:ja:DEFAULT': '',
  'cb_leading_index_mom.description:ja:United Kingdom': '',
  'cb_leading_index_mom.description:ja:Australia': '',
  'cb_leading_index_mom.description:ja:United States':
    '米国の一致指数は、50州それぞれの一致指数で構成される。一致指数では、複数の指標を組み合わせて単一の統計で景気の現状をまとめている。それらの指標は、非農業部門雇用者数、製造業平均労働時間、失業率、消費者物価指数（米国都市平均）でデフレートした労働所得。各州の指数のトレンドは、その州のGDPのトレンドを示す。',
  'pm_may_no_confidence_vote.description:ja:DEFAULT': '',
  'pm_may_no_confidence_vote.description:ja:United Kingdom': '',
  'retail_trade_yoy.description:ja:DEFAULT': '',
  'retail_trade_yoy.description:ja:Japan': '',
  'unit_labour_costs_qoq_final.description:ja:DEFAULT': '',
  'unit_labour_costs_qoq_final.description:ja:United States':
    '単位労働コストは1時間当たりの報酬と労働生産性または1時間当たりの実質生産量との間の関係を示し、生産者にかかるインフレ圧力の指標として使うことができる。',
  'svme_pmi.description:ja:DEFAULT': '',
  'svme_pmi.description:ja:Switzerland': '',
  'us-japan_trade_talks.description:ja:DEFAULT': '',
  'us-japan_trade_talks.description:ja:Japan': '',
  'td_securities_inflation_mom.description:ja:DEFAULT': '',
  'td_securities_inflation_mom.description:ja:Australia':
    'オーストラリアでは、消費者物価指数の主要な構成項目は、住居費（全ウエートの22.3%）、食品・非アルコール飲料（16.8%）、娯楽・文化（12.6%）、輸送（11.6%）、家具・住宅設備・住宅サービス（9.1%）、アルコール飲料・タバコ（7.1%）、医療（5.3%）、保険・金融サービス（5.1%）で、衣料品・履物、教育、通信が残りの10.2%を占める。',
  'td_securities_inflation_yoy.description:ja:DEFAULT': '',
  'td_securities_inflation_yoy.description:ja:Australia':
    'オーストラリアでは、消費者物価指数の主要な構成項目は、住居費（全ウエートの22.3%）、食品・非アルコール飲料（16.8%）、娯楽・文化（12.6%）、輸送（11.6%）、家具・住宅設備・住宅サービス（9.1%）、アルコール飲料・タバコ（7.1%）、医療（5.3%）、保険・金融サービス（5.1%）で、衣料品・履物、教育、通信が残りの10.2%を占める。',
  'michigan_current_conditions_final.description:ja:DEFAULT': '',
  'michigan_current_conditions_final.description:ja:United States':
    '消費者信頼感指数は、（1）消費者自身の財務状況の先行き見通し、（2）目先の経済全体に関する先行き見通し、（3）長期的な経済全体に関する先行き見通しの3つの分野に注目するもので、毎月実施されるアンケート調査には50項目の中核的な設問が含まれ、それぞれの設問が消費者の態度と予想を追跡する。消費者調査のサンプルは統計的にアラスカ州とハワイ州を除くすべての米国世帯を代表するように設計され、毎月500人以上に電話でインタビューが実施される。',
  'rba_monetary_policy_statement.description:ja:DEFAULT': '',
  'rba_monetary_policy_statement.description:ja:Australia': '',
  'national_women_s_day.description:ja:DEFAULT': '',
  'national_women_s_day.description:ja:South Africa': '',
  'markit_services_pmi_final.description:ja:DEFAULT': '',
  'markit_services_pmi_final.description:ja:Australia':
    'IHSマークイットサービス業PMIは、オーストラリアの民間サービス業400社以上の購買担当幹部を代表するパネルに送られたアンケートへの毎月の回答を編纂したデータに基づいている。このパネルは、GDPと企業の従業員規模によって階層に分けられている。サービス業は以下の５つのカテゴリーに分類される：輸送・倉庫、消費者サービス、情報・通信、金融・保険、不動産・ビジネスサービス。数値が50を超えると、製造業が前月に比べて拡大していることを示し、50を下回ると縮小、50は変化がないことを示す。',
  'markit_services_pmi_final.description:ja:Germany':
    'ドイツのサービス業PMI（購買担当者景気指数）は、ドイツのサービス業を代表する500社を超える企業のパネルから収集したデータを基に集計される。この指数は、売上高、雇用、在庫、価格などの変数を追跡し、数値が50を超えれば、サービス業が全体的に拡大傾向にあることを示し、50を下回れば、全般に縮小傾向にあることを示す。',
  'markit_services_pmi_final.description:ja:France':
    'フランスのサービス業PMI（購買担当者景気指数）は、フランスのサービス業を代表する約400社のパネルから収集したデータを基に集計される。この指数は、売上高、雇用、在庫、価格などの変数を追跡し、数値が50を超えれば、サービス業が全体的に拡大傾向にあることを示し、50を下回れば、全般に縮小傾向にあることを示す。',
  'markit_services_pmi_final.description:ja:Euro Area':
    'ユーロ圏のサービス業PMI（購買担当者景気指数）は、サービス業を代表する約2.000社の民間企業のパネルから収集したデータを基に集計される。ドイツ、フランス、イタリア、スペイン、アイルランドの全国サービス業データが含まれる。この指数は、売上高、雇用、在庫、価格などの変数を追跡し、数値が50を超えれば、サービス業が全体的に拡大傾向にあることを示し、50を下回れば、全般に縮小傾向にあることを示す。',
  'markit_services_pmi_final.description:ja:United States':
    'マークイット米国サービス業PMI（購買担当者景気指数）は、米国のサービス業を代表する400社を超える民間企業のパネルから収集したデータを基に集計され、輸送・通信、金融仲介、ビジネスサービス・個人サービス、コンピューター・IT、ホテル・レストラン部門をカバーしている。この指数は、売上高、雇用、在庫、価格などの変数を追跡し、数値が50を超えれば、サービス業が全体的に拡大傾向にあることを示し、50を下回れば、全般に縮小傾向にあることを示す。',
  'black_awareness_day.description:ja:DEFAULT': '',
  'black_awareness_day.description:ja:Brazil': '',
  'fin_minister_osborne_speaks_in_parliament.description:ja:DEFAULT': '',
  'fin_minister_osborne_speaks_in_parliament.description:ja:United Kingdom': '',
  'riksbank_gov_ingves_speech.description:ja:DEFAULT': '',
  'riksbank_gov_ingves_speech.description:ja:Sweden': '',
  'industrial_production_yoy_-_prel.description:ja:DEFAULT': '',
  'industrial_production_yoy_-_prel.description:ja:Japan':
    '日本では鉱工業生産は製造業、鉱業、公益事業など経済の鉱工業セクターに属する事業の生産量を測定する。',
  'supplementary_budget.description:ja:DEFAULT': '',
  'supplementary_budget.description:ja:United Kingdom': '',
  'supplementary_budget.description:ja:South Africa': '',
  'referendum_on_limitation_initiative.description:ja:DEFAULT': '',
  'referendum_on_limitation_initiative.description:ja:Switzerland': '',
  'gdp_3-month_avg.description:ja:DEFAULT': '',
  'gdp_3-month_avg.description:ja:United Kingdom':
    'GDPは、英国で生産された財とサービスの価値を測る。この数値は3カ月間の成長率であり、月次の数値では変動性が高いため、月次のデータ以上にGDPの実質的伸び率を示すのに適している。そうした意味で、月次データは、単なる1カ月分の経済データではなく、月次の経済データの3カ月間分のデータのアップデートと捉えることができる。3カ月ローリング（移動）データは、3カ月間の成長率をそれ以前の3カ月間の成長率と比べることで算出される。例えば、3-5月の成長率を12-2月のデータと比較するなどだ。',
  'wpi_manufacturing_yoy.description:ja:DEFAULT': '',
  'wpi_manufacturing_yoy.description:ja:India':
    'インドでは、卸売物価指数（WPI）がインフレ率の主要な尺度とされる。WPIは卸売商品の代表的項目で構成されるバスケットの価格を測定し、燃料・電力（13.2%）、一次産品（22.6%）、工業品（64.2%）の3つのグループに大きく分類される。一次産品に含まれる食料品は、卸売物価全体の15.2%を占める。工業品の最も重要な構成項目は、卑金属（全体の9.7%）、食品（9.1%）、化学薬品・化学製品（6.5%）、繊維（4.9％）である。',
  'hia_new_home_sales.description:ja:DEFAULT': '',
  'hia_new_home_sales.description:ja:Australia':
    'オーストラリアの新築住宅販売件数は、民間住宅着工件数の年間市場シェアに基づくウェートを使用し、州ごとの推定値から得られた調査結果を基に集計されている。',
  'chancellor_osborne_budget_statement.description:ja:DEFAULT': '',
  'chancellor_osborne_budget_statement.description:ja:United Kingdom': '',
  'sunak_statement_on_covid_support.description:ja:DEFAULT': '',
  'sunak_statement_on_covid_support.description:ja:United Kingdom': '',
  'regional_elections.description:ja:DEFAULT': '',
  'regional_elections.description:ja:Germany': '',
  'regional_elections.description:ja:Italy': '',
  'regional_elections.description:ja:Austria': '',
  'money_supply_m2_cd_yoy.description:ja:DEFAULT': '',
  'money_supply_m2_cd_yoy.description:ja:Japan': '',
  'the_prophet_muhammad_s_birthday.description:ja:DEFAULT': '',
  'the_prophet_muhammad_s_birthday.description:ja:Indonesia': '',
  'president_obama_speech.description:ja:DEFAULT': '',
  'president_obama_speech.description:ja:United States': '',
  'prime_minister_theresa_may_speech.description:ja:DEFAULT': '',
  'prime_minister_theresa_may_speech.description:ja:United Kingdom': '',
  'great_prayer_day.description:ja:DEFAULT': '',
  'great_prayer_day.description:ja:Denmark': '',
  'struggle_for_freedom_and_democracy_day.description:ja:DEFAULT': '',
  'struggle_for_freedom_and_democracy_day.description:ja:Czech Republic': '',
  'stock_investment_by_foreigners.description:ja:DEFAULT': '',
  'stock_investment_by_foreigners.description:ja:Canada':
    'カナダ証券への外国からの投資には、債券、株式・投資ファンド持分が含まれる。取引はネットベースで記録される。',
  'stock_investment_by_foreigners.description:ja:Japan':
    '日本株式への外国からの投資とは、外国人投資家による日本株式市場への投資流入額と流出額の差をいう。',
  'president_s_fy2022_budget.description:ja:DEFAULT': '',
  'president_s_fy2022_budget.description:ja:United States':
    '米国の財政収支は、財政歳入から財政支出を差し引いた差額で、プラスであれば財政黒字を示し、マイナスであれば財政赤字を示す。',
  'property_price_index_yoy.description:ja:DEFAULT': '',
  'property_price_index_yoy.description:ja:Indonesia':
    'インドネシアでは、住宅価格指数は、16都市の大中小規模の住宅を含む住宅の価格を測定した住宅価格指数を指す。',
  'domestic_truck_sales.description:ja:DEFAULT': '',
  'domestic_truck_sales.description:ja:United States': '',
  'gdp_growth_yoy.description:ja:DEFAULT': '',
  'gdp_growth_yoy.description:ja:Spain': '',
  'gdp_growth_yoy.description:ja:United Kingdom': '',
  'the_kings_birthday.description:ja:DEFAULT': '',
  'the_kings_birthday.description:ja:Thailand': '',
  'non_farm_payrolls.description:ja:DEFAULT': '',
  'non_farm_payrolls.description:ja:Switzerland':
    'スイスでは、非農業部門雇用者数に含まれるのは、第2次、第3次産業の社員、自営業者、実習生、事業主。',
  'non_farm_payrolls.description:ja:United States':
    '非農業部門雇用者数は、通常毎月第1金曜日に発表される雇用統計であり、米ドル相場や債券、株式市場に大きな影響を及ぼす。米労働省労働統計局による雇用統計（Current Employment Statistics）では、約486,000の個別の職場を代表する約141,000の企業および政府機関を対象に調査が行われ、非農業部門の雇用者に含まれる労働者の雇用、労働時間、所得について詳細な産業別データが提示される。',
  'the_queen_mother_s_birthday.description:ja:DEFAULT': '',
  'the_queen_mother_s_birthday.description:ja:Thailand': '',
  'easter_sunday.description:ja:DEFAULT': '',
  'easter_sunday.description:ja:Italy': '',
  'easter_sunday.description:ja:Sweden': '',
  'easter_sunday.description:ja:Portugal': '',
  'easter_sunday.description:ja:Colombia': '',
  'easter_sunday.description:ja:Brazil': '',
  'easter_sunday.description:ja:Finland': '',
  'easter_sunday.description:ja:New Zealand': '',
  'easter_sunday.description:ja:Poland': '',
  'easter_sunday.description:ja:Austria': '',
  'easter_sunday.description:ja:Denmark': '',
  'easter_sunday.description:ja:Hungary': '',
  'easter_sunday.description:ja:Australia': '',
  'easter_sunday.description:ja:Netherlands': '',
  'easter_sunday.description:ja:Norway': '',
  'revolution_day_memorial.description:ja:DEFAULT': '',
  'revolution_day_memorial.description:ja:Mexico': '',
  'boe_gov_bailey_speech.description:ja:DEFAULT': '',
  'boe_gov_bailey_speech.description:ja:United Kingdom':
    'イギリスの金融政策委員会（MPC）が基準利率を設定する。中央銀行であるイングランド銀行の公式金利はレポ金利である。このレポ金利は、イングランド銀行の取引相手（銀行、住宅金融組合、証券会社）に対する公開市場操作での利率となる。',
  'defenders_of_the_fatherland_day_holiday.description:ja:DEFAULT': '',
  'defenders_of_the_fatherland_day_holiday.description:ja:Russia': '',
  'waisak_day_buddha_s_anniversary.description:ja:DEFAULT': '',
  'waisak_day_buddha_s_anniversary.description:ja:Indonesia': '',
  'st_stephen_s_day.description:ja:DEFAULT': '',
  'st_stephen_s_day.description:ja:Switzerland': '',
  'st_stephen_s_day.description:ja:Italy': '',
  'st_stephen_s_day.description:ja:Austria': '',
  'st_stephen_s_day.description:ja:Ireland': '',
  'st_stephen_s_day.description:ja:Hungary': '',
  'st_stephen_s_day.description:ja:Poland': '',
  'st_stephen_s_day.description:ja:Germany': '',
  'st_stephen_s_day.description:ja:Greece': '',
  'st_stephen_s_day.description:ja:Czech Republic': '',
  'fomc_press_conference.description:ja:DEFAULT': '',
  'fomc_press_conference.description:ja:United States': '',
  'employment_change_sa.description:ja:DEFAULT': '',
  'employment_change_sa.description:ja:Australia': '',
  'electronic_card_retail_sales_mom.description:ja:DEFAULT': '',
  'electronic_card_retail_sales_mom.description:ja:New Zealand': '',
  'nikkei_pmi.description:ja:DEFAULT': '',
  'nikkei_pmi.description:ja:Singapore':
    'IHSマークイット・シンガポールの購買担当者景気指数（PMI™）は、製造業、サービス業、建設業、小売業400社以上の民間企業の上級購買担当者から収集したアンケート調査の回答を基に集計され、月の中旬に集計された回答に基づきその月と前月の変化があれば反映される。指数が50を超えれば全体的に民間セクターが拡大していることを、50を切れば全体的に縮小していることを示す。',
  'nikkei_pmi.description:ja:Hong Kong':
    '日経香港製造業購買担当者景気指数は製造業300社の調査に基づく製造業の業況を測定する。それぞれ加重された、新規受注（30%）、生産（25%）、雇用（20%）、サプライヤー納期（15%）、購買品在庫（10%）という5つの個別インデックスから構成され、納期インデックスは他のインデックスと比較ができるように符号が反転される。50を超える指数は製造業セクターが前期に比べて拡大していることを、50を切る指数は縮小していることを、50は変化がないことを示す。',
  'st_peter_and_st_paul_day.description:ja:DEFAULT': '',
  'st_peter_and_st_paul_day.description:ja:Colombia': '',
  'spring_and_labor_day.description:ja:DEFAULT': '',
  'spring_and_labor_day.description:ja:Russia': '',
  'ecb_praet_speech.description:ja:DEFAULT': '',
  'ecb_praet_speech.description:ja:Euro Area': '',
  'spring_equinox_day.description:ja:DEFAULT': '',
  'spring_equinox_day.description:ja:Japan': '',
  'spring_budget_2018.description:ja:DEFAULT': '',
  'spring_budget_2018.description:ja:United Kingdom': '',
  'family_day.description:ja:DEFAULT': '',
  'family_day.description:ja:Australia': '',
  'family_day.description:ja:South Africa': '',
  'family_day.description:ja:Canada': '',
  'jobless_claims_4-week_average.description:ja:DEFAULT': '',
  'jobless_claims_4-week_average.description:ja:United States': '',
  'retail_price_index_mom.description:ja:DEFAULT': '',
  'retail_price_index_mom.description:ja:United Kingdom':
    '英国では、RPI（小売価格指数）は当初、第一次世界大戦中の物価上昇にから一般労働者を保護するための支援策として設計された指数に基づく補償指数として開発された。RPIは1947年以降のインフレ推定値を提供しており、1956年1月に初めて正式な消費者物価インフレ指数が発表されて以来、英国の消費者物価指数（CPI）が1996年に導入されるまで、英国の消費者物価インフレ指数を測定する指数はRPIとその派生指数に限られていた。RPI改善策に向けたコンサルテーションに続き、英国統計理事会のジル・マセソン顧問は、RPI算出に使用される数式の1つは国際基準を満たさないとして新たな指数を発表することを推奨した。RPIは民間の世帯のみを対象とするが、所得においてトップ4%の世帯と、所得の4分の3以上が年金である年金生活者は除外される。',
  'net_change_in_employment.description:ja:DEFAULT': '',
  'net_change_in_employment.description:ja:Canada': '',
  'special_holiday.description:ja:DEFAULT': '',
  'special_holiday.description:ja:Thailand': '',
  '12-month_bot_auction.description:ja:DEFAULT': '',
  '12-month_bot_auction.description:ja:Italy': '',
  'fed_stress_test_results_for_big_banks.description:ja:DEFAULT': '',
  'fed_stress_test_results_for_big_banks.description:ja:United States':
    '米国では、金利設定の権限は連邦準備制度理事会（理事会）と連邦公開市場委員会（FOMC）に分けられている。理事会は、1つ以上の地区連邦準備銀行から提出された勧告を受けて、割引率の変更を決定する。FOMCは、中央銀行の資金量やフェデラルファンド市場金利などの公開市場操作を決定する。',
  'ppi_yoy.description:ja:DEFAULT': '',
  'ppi_yoy.description:ja:Norway':
    'ノルウェーでは、生産者物価指数は、ノルウェーの生産者からノルウェーの市場および輸出に向けられる製品の一次卸販売価格の動きを測定する。製造価格がPPIの54%を占める。製造価格を構成する主要な項目は食品・飲料・タバコ（12%）、石油精製製品（8%）、機械設備（6%）、卑金属（5%）である。その他、採取・関連サービスがPPIの41%、電気・ガス・蒸気が4%、鉱業・採石業が1%を占める。',
  'ppi_yoy.description:ja:Philippines':
    '生産者物価変動は、一定期間中に卸売市場で製造者や生産者が販売した財やサービスの価格の前年比変化を示す。',
  'ppi_yoy.description:ja:Colombia':
    'コロンビアでは、生産者物価指数（PPI）は、一定期間中に卸売市場で製造者や生産者が販売した財やサービスの価格の平均変化を測定する。',
  'ppi_yoy.description:ja:Euro Area':
    'ユーロ圏では、生産者物価の変動は、一定期間中に卸売市場で製造者や生産者が販売した財やサービスの価格の平均変化を測定する。',
  'ppi_yoy.description:ja:Italy':
    'イタリアでは、鉱業生産者物価指数がイタリアで生産され国内及び非国内市場で販売された製品の生産価格の変化を測定している。',
  'ppi_yoy.description:ja:Canada':
    'カナダでは、生産者物価変動は、一定期間中に卸売市場で製造者や生産者が販売した財やサービスの価格の平均的な変化を測定する。',
  'ppi_yoy.description:ja:South Korea':
    '生産者物価変動は、一定期間中に卸売市場で製造者や生産者が販売した財やサービスの価格の前年比変化を示す。',
  'ppi_yoy.description:ja:Hungary':
    '生産者物価変動は、一定期間中に卸売市場で製造者や生産者が販売した財やサービスの価格の前年比変化を示す。',
  'ppi_yoy.description:ja:France':
    '生産者物価変動は、一定期間中に卸売市場で製造者や生産者が販売した財やサービスの価格の前年比変化を示す。',
  'ppi_yoy.description:ja:Belgium':
    '生産者物価変動は、一定期間中に卸売市場で製造者や生産者が販売した財やサービスの価格の前年比変化を示す。',
  'ppi_yoy.description:ja:South Africa':
    '南アフリカでは、生産者物価の変動は、一定期間中に卸売市場で製造者や生産者が販売した財やサービスの価格の前年比変化を示す。',
  'ppi_yoy.description:ja:Austria':
    '生産者物価変動は、一定期間中に卸売市場で製造者や生産者が販売した財やサービスの価格の前年比変化を示す。',
  'ppi_yoy.description:ja:Sweden':
    '生産者物価変動は、一定期間中に卸売市場で製造者や生産者が販売した財やサービスの価格の前年比変化を示す。',
  'ppi_yoy.description:ja:Poland':
    '生産者物価変動は、一定期間中に卸売市場で製造者や生産者が販売した財やサービスの価格の前年比変化を示す。',
  'ppi_yoy.description:ja:Germany':
    '生産者物価変動は、一定期間中に卸売市場で製造者や生産者が販売した財やサービスの価格の前年比変化を示す。',
  'ppi_yoy.description:ja:Greece':
    'ギリシャでは、生産者物価指数は、国内市場で生産され販売された財や、国外市場に輸出された財の価格の月次変化率を測定し、国内および国外市場のサブ指数で構成される。国外市場のPPIはさらに、ユーロ圏と非ユーロ圏という2つの市場の指数に細分される。',
  'ppi_yoy.description:ja:United States':
    '米国では、最終需要の生産者価格指数が個人消費、資本投資、政府支出、輸出に対し販売された商品の価格変化を測定している。主に、食料とエネルギーを含む最終需要製品（全ウェイトの33%）、最終需要販売サービス（20%）、最終需要輸送及び倉庫サービス（4%）、貿易・輸送・倉庫を除く最終需要（41%）、最終需要建設（2%）そして全体の最終需要の6つの価格指標から構成される。',
  'ppi_yoy.description:ja:Switzerland':
    'スイスでは、生産者物価指数（PPI）は正式には「生産者および輸入物価指数」と呼ばれる。生産者物価指数はスイス国内で事業を行う企業が生産・販売する財の価格動向を測定し、輸入物価指数は輸入品の価格動向を測定する。これらの指数を合わせる際は、生産者物価指数では国内販売のみが考慮される。',
  'ppi_yoy.description:ja:China':
    '中国では、生産者物価の変動は、一定期間中に卸売市場で製造者や生産者が販売した財やサービスの価格の年間平均変化を測定する。',
  'ppi_yoy.description:ja:Portugal':
    'ポルトガルの工業生産者物価指数は、国内及び海外市場向けの経済活動での取引価格の月次変化を示すものである。指数は、工業製品生産価格の電子的な調査から得られるもので、主に国内の採取産業、製造業、電気、ガス・水道産業の企業を対象としている。約10,123の価格情報が毎月集計される。生産者価格では中間工程品がPPI合計の36％と最大の比率を占め、消費者向け商品（31%）、エネルギー（20%）、資本財（12%）が続く。',
  'ppi_yoy.description:ja:Spain':
    '生産者物価変動は、一定期間中に卸売市場で製造者や生産者が販売した財やサービスの価格の前年比変化を示す。',
  'ppi_yoy.description:ja:Czech Republic':
    '生産者物価変動は、一定期間中に卸売市場で製造者や生産者が販売した財やサービスの価格の前年比変化を示す。',
  'ppi_yoy.description:ja:Russia':
    '生産者物価変動は、一定期間中に卸売市場で製造者や生産者が販売した財やサービスの価格の前年比変化を示す。',
  'ppi_yoy.description:ja:Finland':
    'フィンランドでは、工業製品の生産者物価指数は、国内で製造された製品の生産者価格の動きを測定する。この指数には、国内で販売された製品と輸出された製品のいずれもが含まれる。国内製品の価格は税抜きの工場出荷価格で、輸出製品の価格は本船渡し価格（FOB価格）である。PPIは産業カテゴリーBからEの商品を対象としている。',
  'ppi_yoy.description:ja:Australia':
    'オーストラリアでは、生産者物価の変動は、一定期間中に卸売市場で製造者や生産者が販売した財やサービスの価格の平均変化を測定する。',
  'ppi_yoy.description:ja:Singapore':
    'シンガポールでは、生産者物価は、国内供給価格指数の前年比変化を指す。この指数は、国内で生産された財や、国内経済での使用のために保持される輸入財の価格水準を測定することを目的としている。',
  'ppi_yoy.description:ja:Japan':
    '生産者物価変動は、一定期間中に卸売市場で製造者や生産者が販売した財やサービスの価格の前年比変化を示す。',
  'songkran_festival.description:ja:DEFAULT': '',
  'songkran_festival.description:ja:Thailand': '',
  'ecb_chief_lagarde_testimony.description:ja:DEFAULT': '',
  'ecb_chief_lagarde_testimony.description:ja:Euro Area': '',
  'hometrack_housing_prices_mom.description:ja:DEFAULT': '',
  'hometrack_housing_prices_mom.description:ja:United Kingdom': '',
  'rba_bullock_speech.description:ja:DEFAULT': '',
  'rba_bullock_speech.description:ja:Australia':
    'オーストラリアでは金利決定はオーストラリア準備銀行理事会が行い、政策金利はキャッシュレートである。キャッシュレートとは、金融機関の間で翌日物貸付に適用される利率で、翌日物資金の需要と供給によってマネーマーケットで決定される。',
  'rbnz_financial_stability_report.description:ja:DEFAULT': '',
  'rbnz_financial_stability_report.description:ja:New Zealand': '',
  'eu_bailout_expiration.description:ja:DEFAULT': '',
  'eu_bailout_expiration.description:ja:Greece': '',
  'simchat_torah.description:ja:DEFAULT': '',
  'simchat_torah.description:ja:Israel': '',
  'eurogroup_meeting_on_greece.description:ja:DEFAULT': '',
  'eurogroup_meeting_on_greece.description:ja:Euro Area': '',
  'new_brunswick_provincial_elections.description:ja:DEFAULT': '',
  'new_brunswick_provincial_elections.description:ja:Canada': '',
  'fed_kocherlakota_speech.description:ja:DEFAULT': '',
  'fed_kocherlakota_speech.description:ja:United States': '',
  'shushan_purim_jerusalem.description:ja:DEFAULT': '',
  'shushan_purim_jerusalem.description:ja:Israel': '',
  'rbi_interest_rate_decision.description:ja:DEFAULT': '',
  'rbi_interest_rate_decision.description:ja:India':
    'インドでは、インド準備銀行（RBI）の中央取締役会により政策金利が決定される。政策金利はベンチマークレポレート。RBIは2014年に金融政策の主要目的を物価安定と定め、政府による借り入れやルピーの為替レート安定、輸出保護の必要性の優先順位を引き下げた。政府と中央銀行は2015年2月、インフレ目標を消費者物価指数4%として、その上下2%に許容範囲を定め、2017年3月期からこれを施行することで合意した。',
  'gdp_growth_rate_yoy-final.description:ja:DEFAULT': '',
  'gdp_growth_rate_yoy-final.description:ja:Singapore':
    'シンガポール経済最大の分野はサービス業であり、GDPの72%を占める。サービス業の主要な部門は、卸売・小売業（合計GDPの18%）、ビジネスサービス（16%）、金融・保険（13%）、輸送・倉庫（10%）、情報・通信（5%）である。産業部門は総生産の28%を占め、その主要セグメントには、製造業（21%）、建設業（5%）がある。',
  'treasury_secretary_lew_speaks.description:ja:DEFAULT': '',
  'treasury_secretary_lew_speaks.description:ja:United States': '',
  'shushan_purim.description:ja:DEFAULT': '',
  'shushan_purim.description:ja:Israel': '',
  'new_orders_yoy.description:ja:DEFAULT': '',
  'new_orders_yoy.description:ja:Sweden':
    'スウェーデンでは、新規受注が企業の景況感に大きく影響するため、国内総生産成長率の先行指標となる。この統計は登録されたサンプルを集計し、月次ベースで新規受注の短期的変化と産業界の売上高を、総計および業界別、さらには国内市場と輸出市場別に測定する。この調査は他の経済指標調査とともに実施される。',
  'fed_bernanke_testifies.description:ja:DEFAULT': '',
  'fed_bernanke_testifies.description:ja:United States': '',
  'national_people_s_congress.description:ja:DEFAULT': '',
  'national_people_s_congress.description:ja:China': '',
  'mahatama_gandhi_jayanti.description:ja:DEFAULT': '',
  'mahatama_gandhi_jayanti.description:ja:India': '',
  'services_nz_psi.description:ja:DEFAULT': '',
  'services_nz_psi.description:ja:New Zealand':
    'ビジネスNZサービス業業況指数（PSI）は、売上高、新規受注、納入、在庫、雇用の各ディフュージョン指数を基にした複合指数である。数値が50を超えるとサービス業が前月に比べて拡大していること、50を切ると縮小、50で変化がないことを示す。',
  'export_orders_yoy.description:ja:DEFAULT': '',
  'export_orders_yoy.description:ja:Taiwan':
    '台湾では、新規受注が企業景況感に多大な影響を及ぼすため、GDP成長率の先行指標となる。',
  'seollal_holiday_day_2.description:ja:DEFAULT': '',
  'seollal_holiday_day_2.description:ja:South Korea': '',
  'boj_quarterly_outlook_report.description:ja:DEFAULT': '',
  'boj_quarterly_outlook_report.description:ja:Japan':
    '日本では、金利は政策委員会による金融政策決定会合で決められる。日銀の公式金利は、基準割引率である。金融政策決定会合では、次の会合までの間の金融市場操作におけるガイドラインが決められ、このガイドラインは無担保コール翌日物金利の目標として定められる。',
  'president_trump_speech_on_iran_deal.description:ja:DEFAULT': '',
  'president_trump_speech_on_iran_deal.description:ja:United States': '',
  'm3_money_supply_3m.description:ja:DEFAULT': '',
  'm3_money_supply_3m.description:ja:Euro Area': '',
  'labour_cost_index_qoq.description:ja:DEFAULT': '',
  'labour_cost_index_qoq.description:ja:New Zealand': '',
  'markit_composite_pmi_final.description:ja:DEFAULT': '',
  'markit_composite_pmi_final.description:ja:Australia':
    'オーストラリアのIHSマークイット・オーストラリア総合PMI生産指数は、コモンウェルス銀行製造業生産指数とコモンウェルス銀行サービス業事業活動指数のGDP加重平均値である。この指数は、オーストラリアの民間部門の経済全体における企業活動の変化を追跡する。この指数が50を超えると企業活動が拡大していることを、50を下回ると縮小していることを示す。',
  'markit_composite_pmi_final.description:ja:Germany':
    'ドイツの総合PMI生産指数は、製造業とサービス業全体の景気動向を追跡する。この指数は両セクターを代表する1,000社（製造業50%、サービス業50%）を超える企業のパネルから収集したデータを基に集計され、売上高、新規受注、雇用、在庫、価格などの変数の動きを追う。数値が50を超えれば、企業活動が拡大傾向にあることを示し、50を下回れば全般に縮小傾向にあることを示す。',
  'markit_composite_pmi_final.description:ja:France':
    'フランスのマークイット総合購買担当者景気指数（PMI）生産指数は、民間セクターを代表する約800社の企業のパネルから収集したデータを基に、民間セクター全体の景気動向を追跡する。指数は売上高、新規受注、雇用、在庫、価格などの変数の動きを追い、数値が50を超えれば、企業活動が拡大傾向にあることを示し、50を下回れば全般に縮小傾向にあることを示す。',
  'markit_composite_pmi_final.description:ja:Euro Area':
    'ユーロ圏のマークイット総合購買担当者景気指数（PMI）生産指数は、5,000社（製造業60%、サービス業40%）を超える企業のパネルから収集したデータを基に、製造業とサービス業全体の景気動向を追跡する。指数は売上高、新規受注、雇用、在庫、価格などの変数の動きを追い、ドイツ、フランス、イタリア、スペイン、オーストリア、オランダ、ギリシャ、アイルランドの全国データが含まれている。数値が50を超えれば、企業活動が拡大傾向にあることを示し、50を下回れば全般に縮小傾向にあることを示す。',
  'markit_composite_pmi_final.description:ja:United States':
    '米国のマークイット総合購買担当者景気指数（PMI）生産指数は、製造業とサービス業全体の景気動向を追跡する（製造業60%、サービス業40%）。この指数は両セクターを代表する1,000社を超える企業のパネルから収集したデータを基に集計され、売上高、新規受注、雇用、在庫、価格などの変数の動きを追う。数値が50を超えれば、企業活動が拡大傾向にあることを示し、50を下回れば縮小傾向にあることを示す。',
  'seollal_holiday.description:ja:DEFAULT': '',
  'seollal_holiday.description:ja:South Korea': '',
  'sentix_investors_sentiment.description:ja:DEFAULT': '',
  'sentix_investors_sentiment.description:ja:Euro Area': '',
  'new_year_holiday_week.description:ja:DEFAULT': '',
  'new_year_holiday_week.description:ja:Russia': '',
  'hsbc_manufacturing_pmi_flash.description:ja:DEFAULT': '',
  'hsbc_manufacturing_pmi_flash.description:ja:China': '',
  'house_price_index_mom.description:ja:DEFAULT': '',
  'house_price_index_mom.description:ja:Norway':
    'ノルウェーでは、住宅指数は全国の既存住宅の住宅価格指数を指す。',
  'house_price_index_mom.description:ja:United Kingdom': '',
  'house_price_index_mom.description:ja:China': '',
  'house_price_index_mom.description:ja:United States':
    '米連邦住宅金融局（FHFA）住宅価格指数は、連邦住宅抵当金庫（ファニーメイ）と連邦住宅貸付抵当公社（フレディーマック）が融資を保証している戸建住宅の平均価格の月次変化を測定する。',
  'rba_meetings_minutes.description:ja:DEFAULT': '',
  'rba_meetings_minutes.description:ja:Australia': '',
  'scottish_independence_referendum.description:ja:DEFAULT': '',
  'scottish_independence_referendum.description:ja:United Kingdom': '',
  'gdp_growth_rate_qoq_prel.description:ja:DEFAULT': '',
  'gdp_growth_rate_qoq_prel.description:ja:Japan':
    '産業化が進んだ日本の自由市場経済は世界第4位の規模で、世界最大のエレクトロニクス産業と第3位の自動車産業を有する。日本経済は輸出志向セクターの効率性と競争力の高さで知られているが、農業、流通、サービスといったセクターの生産性は、より低い。',
  'reuters_tankan_index.description:ja:DEFAULT': '',
  'reuters_tankan_index.description:ja:Japan':
    'ロイター短観は日本の大企業を対象に景況感を毎月調査している。以前はテレレート短観として知られていたが、トムソン・ロイターによるQuickマネーラインテレレートの買収に伴い改称された。200社の製造業企業と200社の非製造業企業で構成されるパネルが調査対象である。この月次指標は四半期の日銀短観の先行指標となることを目的としている。各社の業況に対する判断のうち「良い」との回答割合から「悪い」との回答割合を差し引いて算出されている。',
  'sacrifice_feast.description:ja:DEFAULT': '',
  'sacrifice_feast.description:ja:Singapore': '',
  'sacrifice_feast.description:ja:Indonesia': '',
  'aig_performance_construction_index.description:ja:DEFAULT': '',
  'aig_performance_construction_index.description:ja:Australia': '',
  'fed_williams_speech.description:ja:DEFAULT': '',
  'fed_williams_speech.description:ja:United States':
    '米国では、金利設定の権限は連邦準備制度理事会（理事会）と連邦公開市場委員会（FOMC）に分けられている。理事会は、1つ以上の地区連邦準備銀行から提出された勧告を受けて、割引率の変更を決定する。FOMCは、中央銀行の資金量やフェデラルファンド市場金利などの公開市場操作を決定する。',
  'tankan_large_all_industry_capex.description:ja:DEFAULT': '',
  'tankan_large_all_industry_capex.description:ja:Japan': '',
  'german_unity_day.description:ja:DEFAULT': '',
  'german_unity_day.description:ja:Germany': '',
  'total_credit_yoy.description:ja:DEFAULT': '',
  'total_credit_yoy.description:ja:Greece':
    '国内通貨金融機関による国内非通貨金融機関への与信合計。',
  'unemployment_rate.description:ja:Thailand':
    'タイでは、失業率は、積極的に仕事を探している人の数が労働力人口に占める割合を測定する。',
  'unemployment_rate.description:ja:China':
    '中国では、都市部調査失業率は標本調査に基づいて集計され、都市部失業者数の、就業者数と失業者数の合計に対する比率を指す。',
  'unemployment_rate.description:ja:Portugal':
    'ポルトガルでは、失業率は、積極的に仕事を探している人の数が労働力人口に占める割合を測定する。',
  'unemployment_rate.description:ja:New Zealand':
    'ニュージーランドでは、失業率は、積極的に仕事を探している人の数が労働力人口に占める割合を測定する。',
  'unemployment_rate.description:ja:Finland':
    'フィンランドでは、失業率は、積極的に仕事を探している人の数が労働力人口に占める割合を測定する。',
  'unemployment_rate.description:ja:South Korea':
    '韓国では、失業率（季節調整後）は、積極的に仕事を探している人の数が労働力人口に占める割合を測定する。',
  'unemployment_rate.description:ja:Hong Kong':
    '香港では、失業率は、積極的に仕事を探している人の数が労働力人口に占める割合を測定する。',
  'unemployment_rate.description:ja:Indonesia':
    'インドネシアでは、失業率は、積極的に仕事を探している人の数が労働力人口に占める割合を測定する。',
  'unemployment_rate.description:ja:Canada':
    'カナダでは、失業率は、積極的に仕事を探している人の数が労働力人口に占める割合を測定する。',
  'unemployment_rate.description:ja:Norway':
    'ノルウェーでは、失業率は、積極的に仕事を探している人の数が労働力人口に占める割合を測定する。',
  'unemployment_rate.description:ja:Mexico':
    'メキシコでは、失業率は、積極的に仕事を探している人の数が労働力人口に占める割合を測定する。',
  'unemployment_rate.description:ja:Singapore':
    'シンガポールでは、失業率は労働力全体のうち、積極的に仕事を探している人口の比率を測定する。',
  'unemployment_rate.description:ja:Japan':
    '日本では、失業率は、積極的に仕事を探している人の数が労働力人口に占める割合を測定する。',
  'unemployment_rate.description:ja:Israel':
    'イスラエルでは、失業率は、積極的に仕事を探している人の数が労働力人口に占める割合を測定する。',
  'unemployment_rate.description:ja:Hungary':
    'ハンガリーでは、失業率は、積極的に仕事を探している人の数が労働力人口に占める割合を測定する。',
  'unemployment_rate.description:ja:Netherlands':
    'オランダでは、失業率は、積極的に仕事を探している人の数が労働力人口に占める割合を測定する。',
  'unemployment_rate.description:ja:Australia':
    'オーストラリアでは、失業率は、積極的に仕事を探している人の数が労働力人口に占める割合を測定する。',
  'unemployment_rate.description:ja:Taiwan':
    '台湾では、失業率は、積極的に仕事を探している人の数が労働力人口に占める割合を測定する。',
  'unemployment_rate.description:ja:Poland':
    'ポーランドでは、失業率は、積極的に仕事を探している人の数が労働力人口に占める割合を測定する。',
  'unemployment_rate.description:ja:Germany':
    'ドイツでは、失業率は、積極的に仕事を探している人の数が労働力人口に占める割合を測定する。',
  'unemployment_rate.description:ja:Sweden':
    'スウェーデンでは、失業率は、積極的に仕事を探している人の数が労働力人口に占める割合を測定する。',
  'unemployment_rate.description:ja:South Africa':
    '南アフリカでは、失業率は、積極的に仕事を探している人の数が労働力人口に占める割合を測定する。',
  'unemployment_rate.description:ja:France':
    'フランスでは、失業率は、積極的に仕事を探している人の数が労働力人口に占める割合を測定する。',
  'unemployment_rate.description:ja:Belgium':
    'ベルギーでは、失業率は、積極的に仕事を探している人の数が労働力人口に占める割合を測定する。',
  'unemployment_rate.description:ja:United Kingdom':
    '英国では、失業率は、積極的に仕事を探している人の数が労働力人口に占める割合を測定する。',
  'unemployment_rate.description:ja:Ireland':
    'アイルランドでは、失業率は、積極的に仕事を探している人の数が労働力人口に占める割合を測定する。',
  'unemployment_rate.description:ja:United States':
    '米国では、失業率は、積極的に仕事を探している人の数が労働力人口に占める割合を測定する。',
  'unemployment_rate.description:ja:Greece':
    'ギリシャでは、失業率は、積極的に仕事を探している人の数が労働力人口に占める割合を測定する。',
  'unemployment_rate.description:ja:Spain':
    'スペインでは、失業率は、積極的に仕事を探している人の数が労働力人口に占める割合を測定する。',
  'unemployment_rate.description:ja:Russia':
    'ロシアでは、失業率は、積極的に仕事を探している人の数が労働力人口に占める割合を測定する。',
  'unemployment_rate.description:ja:Denmark':
    'デンマークでは、失業率は、積極的に仕事を探している人の数が労働力人口に占める割合を測定する。',
  'unemployment_rate.description:ja:Czech Republic':
    'チェコでは、失業率は、すぐに労働に従事できる15~64歳の生産年齢人口の失業者数が生産年齢人口全体に占める割合を示す。',
  'unemployment_rate.description:ja:Switzerland':
    'スイスでは、失業率は、積極的に仕事を探している人の数が労働力人口に占める割合を測定する。',
  'unemployment_rate.description:ja:Colombia':
    'コロンビアでは、失業率は、積極的に仕事を探している人の数が労働力人口に占める割合を測定する。',
  'unemployment_rate.description:ja:Euro Area':
    'ユーロ圏では、失業率は、積極的に仕事を探している人の数が労働力人口に占める割合を測定する。',
  'unemployment_rate.description:ja:Philippines':
    'フィリピンでは、失業率は、積極的に仕事を探している人の数が労働力人口に占める割合を測定する。',
  'mpc_member_paul_fisher_speech.description:ja:DEFAULT': '',
  'mpc_member_paul_fisher_speech.description:ja:United Kingdom': '',
  'pmi_services.description:ja:Sweden': '',
  'peace_memorial_day.description:ja:DEFAULT': '',
  'peace_memorial_day.description:ja:Taiwan': '',
  'non_trading.description:ja:DEFAULT': '',
  'non_trading.description:ja:Taiwan': '',
  'gross_fixed_investment_mom.description:ja:DEFAULT': '',
  'gross_fixed_investment_mom.description:ja:Mexico':
    'メキシコでは、総設備投資により有形無形双方かつ生産過程の結果として得られた固定資産の取得額の総額が測定される。これらは1年以上、他の生産過程で繰り返し使用できるもののみが対象となる。国内外からのもの、建設に関するものも含め機械と設備の定数価値を合計し、総設備投資の前年比が示される。これには、建造物、機械、設備、輸送設備、ソフトウェア、著作物、その他有形無形の固定資産、処理能力を拡大させたり耐用期間を拡大するための追加および更新が含まれる。国内起源の機械設備については、新規製品のみが考慮される。新規および中古の輸入機械設備が同国の資産価値の上昇に貢献している。建設総固定投資指数は、購入者の支払う価格での投資額となっている。',
  'wholesale_sales_mom_flash.description:ja:DEFAULT': '',
  'wholesale_sales_mom_flash.description:ja:Canada':
    '月次卸売統計は、カナダの卸売業者による月次売上高の推定値を測定するもので、卸売業の業況に関する情報を提供し、カナダ経済の状況の重要な指標となる。さらにビジネスコミュニティはこのデータを使って市場パフォーマンスを分析する。',
  'svme_-_purchasing_managers_index.description:ja:DEFAULT': '',
  'svme_-_purchasing_managers_index.description:ja:Switzerland': '',
  'norges_bank_monetary_policy_report.description:ja:DEFAULT': '',
  'norges_bank_monetary_policy_report.description:ja:Norway':
    'ノルウェーでは、基準金利はノルウェー中央銀行（ノルゲス銀行）が設定する。公式金利は主要政策金利で、サイト・デポジット・レートとも呼ばれる。',
  'rba_jones_testimony.description:ja:DEFAULT': '',
  'rba_jones_testimony.description:ja:Australia': '',
  'real_wage_growth_yoy.description:ja:DEFAULT': '',
  'real_wage_growth_yoy.description:ja:Russia':
    'ロシアでは、実質賃金上昇率はインフレ調整済み平均月次賃金の前年比変化を測定する。',
  'boc_rate_statement.description:ja:DEFAULT': '',
  'boc_rate_statement.description:ja:Canada': '',
  'yom_kippur_atonement_day.description:ja:DEFAULT': '',
  'yom_kippur_atonement_day.description:ja:Israel': '',
  'guru_nanak_jayanti.description:ja:DEFAULT': '',
  'guru_nanak_jayanti.description:ja:India': '',
  'fed_pace_of_treasury_purchase_program.description:ja:DEFAULT': '',
  'fed_pace_of_treasury_purchase_program.description:ja:United States': '',
  'day_of_german_unity.description:ja:DEFAULT': '',
  'day_of_german_unity.description:ja:Germany': '',
  'nonfarm_payrolls_private.description:ja:DEFAULT': '',
  'nonfarm_payrolls_private.description:ja:United States':
    '民間非農業部門雇用者数は、一般政府職員、家事労働者、個人または農場従業員にサポートを提供する非営利組織の従業員を除く、全企業雇用者の総数の変化を測定する。',
  'wholesale_prices_mom.description:ja:DEFAULT': '',
  'wholesale_prices_mom.description:ja:Ireland':
    'アイルランドでは、生産者物価指数（PPI）は、一定期間中に卸売市場で製造者や生産者が販売した財やサービスの価格の平均変化を測定する。',
  'wholesale_prices_mom.description:ja:Austria':
    '卸売物価指数は卸売業者が販売する財の価格動向を反映し、毎月約200社の卸売業者が自主的に約1400種の卸売販売価格（VATを除く）を提供することで、財バスケットに含まれる387項目の価格が調査される。',
  'wholesale_prices_mom.description:ja:Germany':
    '卸売価格指数は、ドイツ国内で卸売業者が販売する、再販業者や加工業者、またはその他のバルク購入者との契約など、卸売販売に含まれる財の価格動向を測定する。これにはドイツで生産された品または輸入品が含まれる場合があり、生産者が小売業者または最終消費者に直接販売したものは除く。',
  'snb_zurbr_gg_speech.description:ja:DEFAULT': '',
  'snb_zurbr_gg_speech.description:ja:Switzerland': '',
  'nominal_budget_balance.description:ja:DEFAULT': '',
  'nominal_budget_balance.description:ja:Brazil':
    'ブラジルでは、政府予算額は、中央政府と地方政府に公的企業から構成される名目財政収支を指し、連邦政府の債務返済費用と名目利息を含む。',
  'gross_domestic_product_annualized_r.description:ja:DEFAULT': '',
  'gross_domestic_product_annualized_r.description:ja:Japan': '',
  'us-mexico_trade_talks.description:ja:DEFAULT': '',
  'us-mexico_trade_talks.description:ja:United States': '',
  'canadian_investment_in_foreign_securities.description:ja:DEFAULT': '',
  'canadian_investment_in_foreign_securities.description:ja:Canada': '',
  'victory_in_europe_day.description:ja:DEFAULT': '',
  'victory_in_europe_day.description:ja:France': '',
  'victory_in_europe_day.description:ja:Czech Republic': '',
  'day_of_the_republic.description:ja:DEFAULT': '',
  'day_of_the_republic.description:ja:Hungary': '',
  'labor_thanksgiving_day.description:ja:DEFAULT': '',
  'labor_thanksgiving_day.description:ja:Japan': '',
  'real_consumer_spending.description:ja:DEFAULT': '',
  'real_consumer_spending.description:ja:United States': '',
  'industrial_sales_mom.description:ja:DEFAULT': '',
  'industrial_sales_mom.description:ja:Italy':
    'イタリアでは、鉱工業売上高は、足元の価格での鉱工業売上高の月次変化を測定する。',
  'august_bank_holiday.description:ja:DEFAULT': '',
  'august_bank_holiday.description:ja:Ireland': '',
  'ism_manufacturing_prices_paid.description:ja:DEFAULT': '',
  'ism_manufacturing_prices_paid.description:ja:United States': '',
  'bundesbank_semi-annual_forecasts.description:ja:DEFAULT': '',
  'bundesbank_semi-annual_forecasts.description:ja:Germany': '',
  'snb_monthly_bulletin.description:ja:DEFAULT': '',
  'snb_monthly_bulletin.description:ja:Switzerland': '',
  'house_prices_yoy.description:ja:DEFAULT': '',
  'house_prices_yoy.description:ja:Spain':
    'スペインでは、住宅価格指数は、不動産市場で取引される住宅を保有する際の1平方メートルあたりの価格で測定される。',
  'snb_moser_speech.description:ja:DEFAULT': '',
  'snb_moser_speech.description:ja:Switzerland': '',
  'snb_interest_rate_decision.description:ja:DEFAULT': '',
  'snb_interest_rate_decision.description:ja:Switzerland':
    'スイスではスイス国立銀行（SNB）が金利を決定し、公式金利はSNBの政策金利である。SNBは有担保短期スイスフラン建てマネーマーケット金利をSNBの政策金利近傍に維持することを目標としている。今日、これらの最も代表的な金利はSARONである。2019年６月13日時点で従来SNBが金融政策戦略に使用していた３カ月物スイスフランLIBOR（ロンドン銀行間取引金利）の目標レンジに代わり、SNB政策金利が採用されることになった。その理由はLIBORを決定するためのマネーマーケット取引が不足し、LIBORが最も重要な参照金利としての重要性を失いつつあったためである。2011年9月6日から2015年1月15日までの期間の主な注目点は最低為替レートとして1EUR＝1.20CHFをSNBが強制執行したことであった。2014年12月18日にSNBは要求払い預金金利をマイナス0.25％とすることを決定した。マイナス金利導入の発表に伴い、当時使われていたLIBORの目標レンジは初めてマイナス圏となった。2015年1月15日、SNBは要求払い預金金利をさらにマイナス0.75％に引き下げ、目標レンジをマイナス1.25％～マイナス0.25％の間とした。2015年1月22日以来マイナス金利が適用されており、現在SNBの政策金利に対応している。',
  'gdp_growth_rate_2nd_est.description:ja:DEFAULT': '',
  'gdp_growth_rate_2nd_est.description:ja:United States':
    '支出面では、個人消費支出がGDP全体の68%を占め、そのうち製品購入による支出が23%、サービスへの支出が45%となっている。民間投資はGDPの16%、政府による消費と投資は18%を占める。製品輸出高（13.5%）が製品輸入高（16.5%）より低いため、純輸出はGDPに3%のマイナス寄与。',
  'new_car_sales_yoy.description:ja:DEFAULT': '',
  'new_car_sales_yoy.description:ja:United Kingdom':
    '英国では、新車登録台数は、新車乗用車の総登録台数を意味する。',
  'new_car_sales_yoy.description:ja:Thailand': '',
  'new_car_sales_yoy.description:ja:Spain':
    'スペインでは、新車販売台数は、新車乗用車の国内総販売台数を意味する。',
  'retail_sales_qoq.description:ja:DEFAULT': '',
  'retail_sales_qoq.description:ja:New Zealand':
    'ニュージーランドの小売売上高は、特定の期間における小売商品とサービスの総売上高を測定している。この小売売上高は、季節要因の影響を受け、変動的であり、経済全体に対する重要性も比較的高い。',
  'snb_chairman_jordan_speech.description:ja:DEFAULT': '',
  'snb_chairman_jordan_speech.description:ja:Switzerland': '',
  'fixed_asset_investment_ytd_yoy.description:ja:DEFAULT': '',
  'fixed_asset_investment_ytd_yoy.description:ja:China':
    '中国では、都市部固定資産投資は設備投資の主要な測定値のひとつで、計画された投資の総額が500万元以上の建設プロジェクトや、機械・設備、都市部および農村部の不動産開発への国有企業、民間企業、機関、個人による投資を指す。',
  'import_prices_mom.description:ja:DEFAULT': '',
  'import_prices_mom.description:ja:United States':
    '米国では、輸入物価指数は、米国の居住者が国外の売り手から購入したり供給されたりした財とサービスの価格の変動率を指す。輸入物価は為替レートに大きく左右される。',
  'import_prices_mom.description:ja:Germany':
    'ドイツでは、輸入物価指数は、ドイツ居住者が国外の売り手から購入したり供給されたりした財とサービスの価格の変動率を指す。輸入物価は為替レートに大きく左右される。',
  'snb_annual_report.description:ja:DEFAULT': '',
  'snb_annual_report.description:ja:Switzerland':
    'スイスではスイス国立銀行（SNB）が金利を決定し、公式金利はSNBの政策金利である。SNBは有担保短期スイスフラン建てマネーマーケット金利をSNBの政策金利近傍に維持することを目標としている。今日、これらの最も代表的な金利はSARONである。2019年６月13日時点で従来SNBが金融政策戦略に使用していた３カ月物スイスフランLIBOR（ロンドン銀行間取引金利）の目標レンジに代わり、SNB政策金利が採用されることになった。その理由はLIBORを決定するためのマネーマーケット取引が不足し、LIBORが最も重要な参照金利としての重要性を失いつつあったためである。2011年9月6日から2015年1月15日までの期間の主な注目点は最低為替レートとして1EUR＝1.20CHFをSNBが強制執行したことであった。2014年12月18日にSNBは要求払い預金金利をマイナス0.25％とすることを決定した。マイナス金利導入の発表に伴い、当時使われていたLIBORの目標レンジは初めてマイナス圏となった。2015年1月15日、SNBは要求払い預金金利をさらにマイナス0.75％に引き下げ、目標レンジをマイナス1.25％～マイナス0.25％の間とした。2015年1月22日以来マイナス金利が適用されており、現在SNBの政策金利に対応している。',
  'ppi_input_mom.description:ja:DEFAULT': '',
  'ppi_input_mom.description:ja:United Kingdom': '',
  'president_trump_statement_on_coronavirus.description:ja:DEFAULT': '',
  'president_trump_statement_on_coronavirus.description:ja:United States': '',
  'sacci_business_confidence.description:ja:DEFAULT': '',
  'sacci_business_confidence.description:ja:South Africa':
    '南アフリカのSACCI気企業景況感指数は、企業の一般的な環境と状況を捉えて、市場の中立的なセンチメントを反映する。13の項目で構成されており、それには、エネルギー供給、製造、輸出、輸入、自動車・小売売上高、建設、インフレ率、株価、民間部門借入額、資金調達コスト、貴金属価格、為替レートなどが含まれる。',
  'reconciliation_day.description:ja:DEFAULT': '',
  'reconciliation_day.description:ja:Australia': '',
  'reconciliation_day.description:ja:South Africa': '',
  'imf_world_bank_spring_meetings.description:ja:DEFAULT': '',
  'imf_world_bank_spring_meetings.description:ja:United States': '',
  'imf_world_bank_spring_meetings.description:ja:Euro Area': '',
  'president_zuma_state_of_the_nation_speech.description:ja:DEFAULT': '',
  'president_zuma_state_of_the_nation_speech.description:ja:South Africa': '',
  'riksbank_rate_decision.description:ja:DEFAULT': '',
  'riksbank_rate_decision.description:ja:Sweden':
    'スウェーデンでは、ベンチマーク金利はスウェーデン中央銀行（リクスバンク）の執行委員会が決定する。主要金利は、7日間リクスバンクから銀行が融資を受けるか預金する際の金利であるレポ金利。リスクバンクはインフレ率をCPIF（金利一定の下での消費者物価指数）で年間2％程度に保つことを目標としている。',
  'bcb_copom_meeting_minutes.description:ja:DEFAULT': '',
  'bcb_copom_meeting_minutes.description:ja:Brazil':
    'ブラジルでは、ブラジル中銀の金融政策委員会（COPOM）が政策金利を決定する。政策金利は翌日物貸出金利であるSELIC（Special System of Clearance and Custody）金利である。',
  'gdp_yoy.description:ja:DEFAULT': '',
  'gdp_yoy.description:ja:United Kingdom':
    'GDPは、英国で生産された財とサービスの価値を測定する。数値は、前年比での伸び率を示している。',
  'gdp_yoy.description:ja:Finland':
    'GDP前年比伸び率は、英国経済が生産した財とサービスの価値を前年同月の水準と比べた変化を測定する。',
  'gdp_yoy.description:ja:Russia':
    'GDP前年比伸び率は、英国経済が生産した財とサービスの価値を前年同月の水準と比べた変化を測定する。',
  'boes_governor_king_speech.description:ja:DEFAULT': '',
  'boes_governor_king_speech.description:ja:United Kingdom': '',
  'loans_to_private_sector.description:ja:DEFAULT': '',
  'loans_to_private_sector.description:ja:Indonesia': '',
  'loans_to_private_sector.description:ja:Greece':
    'ギリシャの民間部門向け融資は、中央銀行を除く国内の通貨金融機関（MFI）による企業への与信を指す。',
  'pesach_vii_last_day_of_passover.description:ja:DEFAULT': '',
  'pesach_vii_last_day_of_passover.description:ja:Israel': '',
  'fed_waller_speech.description:ja:DEFAULT': '',
  'fed_waller_speech.description:ja:United States':
    '米国では、金利設定の権限は連邦準備制度理事会（理事会）と連邦公開市場委員会（FOMC）に分けられている。理事会は、1つ以上の地区連邦準備銀行から提出された勧告を受けて、割引率の変更を決定する。FOMCは、中央銀行の資金量やフェデラルファンド市場金利などの公開市場操作を決定する。',
  'riksbank_meeting_minutes.description:ja:DEFAULT': '',
  'riksbank_meeting_minutes.description:ja:Sweden': '',
  'presidential_elections.description:ja:DEFAULT': '',
  'presidential_elections.description:ja:Indonesia': '',
  'presidential_elections.description:ja:Colombia': '',
  'presidential_elections.description:ja:South Korea': '',
  'presidential_elections.description:ja:France': '',
  'presidential_elections.description:ja:Germany': '',
  'presidential_elections.description:ja:Taiwan': '',
  'presidential_elections.description:ja:Austria': '',
  'presidential_elections.description:ja:United States': '',
  'presidential_elections.description:ja:Philippines': '',
  'second_day_of_chinese_lunar_new_year.description:ja:DEFAULT': '',
  'second_day_of_chinese_lunar_new_year.description:ja:Hong Kong': '',
  'second_day_of_chinese_lunar_new_year.description:ja:Singapore': '',
  'richmond_fed_manufacturing_index.description:ja:DEFAULT': '',
  'richmond_fed_manufacturing_index.description:ja:United States':
    'リッチモンド連銀製造業指数は、コロンビア特別区、メリーランド州、ノースカロライナ州、サウスカロライナ州、バージニア州、ウェストバージニア州における製造業の業況を測定する。この指数は、190の製造工場を対象に行われた調査を基に、出荷指数（33%）、新規受注指数（40%）、雇用指数（27%）の3つの指数をカッコ内の割合で加重して算出される。指数はプラス100とマイナス100の幅で変動し、ゼロを上回った場合に拡大、下回った場合に縮小を示す。',
  'reverse_repo_rate.description:ja:DEFAULT': '',
  'reverse_repo_rate.description:ja:India':
    'インドのリバースレポ金利は固定金利で、現在、レポ金利を50bps下回る水準にある。中央銀行は、流動性調節ファシリティ（LAF）の下で、適格政府証券を担保に、リバースレポ金利で市中銀行から翌日物ベースで流動性を吸収する。',
  'raw_materials_prices_mom.description:ja:DEFAULT': '',
  'raw_materials_prices_mom.description:ja:Canada':
    '原材料価格指数（RPMI）は、カナダの製造業者が主要原材料に支払った価格を反映している。工業製品価格指数とは異なり、RMPIにはカナダで生産されていない財の価格も含まれる。',
  'reuters_michigan_inflation_expectations_prel.description:ja:DEFAULT': '',
  'reuters_michigan_inflation_expectations_prel.description:ja:United States': '',
  'ppi_mom_final.description:ja:DEFAULT': '',
  'ppi_mom_final.description:ja:Canada':
    'カナダでは、生産者物価指数（PPI）は、一定期間中に卸売市場で製造者や生産者が販売した財やサービスの価格の変化の平均を測定する。',
  'nab_quarterly_business_confidence.description:ja:DEFAULT': '',
  'nab_quarterly_business_confidence.description:ja:Australia': '',
  'referendum_on_sexual_orientation_discrimination.description:ja:DEFAULT': '',
  'referendum_on_sexual_orientation_discrimination.description:ja:Switzerland': '',
  'core_inflation_rate.description:ja:DEFAULT': '',
  'core_inflation_rate.description:ja:Ireland':
    'アイルランドではコア・インフレ率によって、価格変動の激しい項目を一部除いた財のバスケットに対して消費者が支払う価格の変動を測定する。',
  'reuters_michigan_consumer_sentiment_final.description:ja:DEFAULT': '',
  'reuters_michigan_consumer_sentiment_final.description:ja:United States':
    '消費者信頼感指数は、（1）消費者自身の財務状況の先行き見通し、（2）目先の経済全体に関する先行き見通し、（3）長期的な経済全体に関する先行き見通しの3つの分野に注目するもので、毎月実施されるアンケート調査には50項目の中核的な設問が含まれ、それぞれの設問が消費者の態度と予想を追跡する。消費者調査のサンプルは統計的にアラスカ州とハワイ州を除くすべての米国世帯を代表するように設計され、毎月500人以上に電話でインタビューが実施される。',
  'private_investment_mom.description:ja:DEFAULT': '',
  'private_investment_mom.description:ja:Thailand': '',
  'president_biden_speech_on_recovery_package.description:ja:DEFAULT': '',
  'president_biden_speech_on_recovery_package.description:ja:United States': '',
  'seco_economic_forecasts.description:ja:DEFAULT': '',
  'seco_economic_forecasts.description:ja:Switzerland': '',
  'inflation_rate.description:ja:DEFAULT': '',
  'inflation_rate.description:ja:Indonesia': '',
  'inflation_rate.description:ja:Germany': '',
  'inflation_rate.description:ja:South Africa': '',
  'inflation_rate.description:ja:Austria': '',
  'inflation_rate.description:ja:Ireland': '',
  'inflation_rate.description:ja:Japan': '',
  'inflation_rate.description:ja:Singapore': '',
  'inflation_rate.description:ja:Spain': '',
  'ivey_pmi_s_a.description:ja:DEFAULT': '',
  'ivey_pmi_s_a.description:ja:Canada':
    'アイビー（Ivey）購買担当者景気指数（PMI）は、カナダ全域の購買担当者のパネルが報告する経済活動の月次変化を測定する経済指標であり、ウエスタン大学のアイビー・ビジネススクールが作成する。このPMIは、官民両部門を対象に、アイビーPMIのパネルメンバーが購買、雇用、在庫、入荷、価格の5つのカテゴリーすべてについて、前月比で各自の組織の活動が拡大、横ばい、縮小したかを月末に判断したデータを基に集計を行う。数値が50を上回れば前月比での購買増、下回れば購買減を示す。',
  'api_crude_oil_stock_change.description:ja:DEFAULT': '',
  'api_crude_oil_stock_change.description:ja:United States':
    '原油在庫は、原油の供給状況の週次変化を指す。',
  'fed_s_beige_book.description:ja:DEFAULT': '',
  'fed_s_beige_book.description:ja:United States': '',
  'republic_day.description:ja:DEFAULT': '',
  'republic_day.description:ja:Portugal': '',
  'republic_day.description:ja:India': '',
  'republic_day.description:ja:Brazil': '',
  'republic_day.description:ja:Italy': '',
  'markit_cips_manufacturing_pmi_final.description:ja:DEFAULT': '',
  'markit_cips_manufacturing_pmi_final.description:ja:United Kingdom':
    'マークイット/CIPS英国製造業購買担当者景気指数（PMI）は製造業600社の調査に基づき、製造業の業況を測定し、それぞれウエート付けされた、新規受注（30%）、生産（25%）、雇用（20%）、サプライヤー納期（15%）、購買品在庫（10%）という5つの個別インデックスから構成され、納期インデックスは比較可能にするため符号が反転される。50を超える指数は製造業セクターが前期に比べて拡大していることを、50を切る指数は縮小していることを、50は変化がないことを示す。',
  'parliament_debate_on_brexit_alternatives.description:ja:DEFAULT': '',
  'parliament_debate_on_brexit_alternatives.description:ja:United Kingdom': '',
  'saint_stephen_s_day.description:ja:DEFAULT': '',
  'saint_stephen_s_day.description:ja:Switzerland': '',
  'saint_stephen_s_day.description:ja:Netherlands': '',
  'saint_stephen_s_day.description:ja:Czech Republic': '',
  'saint_stephen_s_day.description:ja:Austria': '',
  'saint_stephen_s_day.description:ja:Ireland': '',
  'saint_stephen_s_day.description:ja:Finland': '',
  'retail_sales_ex_fuel_yoy.description:ja:DEFAULT': '',
  'retail_sales_ex_fuel_yoy.description:ja:United Kingdom':
    '英国の小売売上高は、特定の期間における小売商品とサービスの総売上高を測定している。この小売売上高は、季節要因の影響を受け、変動的であり、経済全体に対する重要性も比較的高い。',
  'ppi_core_output_mom.description:ja:DEFAULT': '',
  'ppi_core_output_mom.description:ja:United Kingdom':
    'イギリスでは、コア生産者価指数として毎月、食品、飲料、たばこ、石油製品を除く生産者により売買された製品の価格変化を調査している。',
  'referendum_on_legalising_euthanasia.description:ja:DEFAULT': '',
  'referendum_on_legalising_euthanasia.description:ja:New Zealand': '',
  'westpac_consumer_survey.description:ja:DEFAULT': '',
  'westpac_consumer_survey.description:ja:New Zealand': '',
  'hsbc_china_services_pmi.description:ja:DEFAULT': '',
  'hsbc_china_services_pmi.description:ja:China':
    '財新中国サービス業購買担当者景気指数（PMI）は、約400社の民間企業の購買担当幹部から収集したアンケート調査の回答を基に集計され、売上高、雇用、在庫、価格などの変数を追跡し、指数が50を超えれば全体的にサービス業が全体的に拡大していることを、50を切れば全体的に縮小していることを示す。',
  'presidential_election_round_2.description:ja:DEFAULT': '',
  'presidential_election_round_2.description:ja:Czech Republic': '',
  'australia_day_observed.description:ja:DEFAULT': '',
  'australia_day_observed.description:ja:Australia': '',
  'retail_sales_yoy.description:ja:DEFAULT': '',
  'retail_sales_yoy.description:ja:Finland':
    'フィンランドの小売売上高の前年比変化は、特定の月の小売商品とサービスの総売上高を前年同月の数値と比較して算出される。',
  'retail_sales_yoy.description:ja:Australia':
    'オーストラリアの小売売上高の前年比変化は、特定の月の小売商品とサービスの総売上高を前年同月の数値と比較して算出される。',
  'retail_sales_yoy.description:ja:Thailand':
    'タイの小売売上高の前年比変化は、特定の月の小売商品とサービスの総売上高を前年同月の数値と比較して算出される。',
  'retail_sales_yoy.description:ja:Taiwan':
    '台湾の小売売上高の前年比変化は、特定の月の小売商品とサービスの総売上高を前年同月の数値と比較して算出される。',
  'retail_sales_yoy.description:ja:New Zealand':
    'ニュージーランドの小売売上高の前年比変化は、特定の月の小売商品とサービスの総売上高を前年同月の数値と比較して算出される。',
  'retail_sales_yoy.description:ja:Switzerland':
    'スイスの小売売上高の前年比変化は、特定の月の小売商品とサービスの総売上高を前年同月の数値と比較して算出される。',
  'retail_sales_yoy.description:ja:Poland':
    'ポーランドの小売売上高の前年比変化は、特定の月の小売商品とサービスの総売上高を前年同月の数値と比較して算出される。',
  'retail_sales_yoy.description:ja:Germany':
    'ドイツの小売売上高の前年比変化は、特定の月の小売商品とサービスの総売上高を前年同月の数値と比較して算出される。',
  'retail_sales_yoy.description:ja:South Korea':
    '韓国の小売売上高の前年比変化は、特定の月の小売商品とサービスの総売上高を前年同月の数値と比較して算出される。',
  'retail_sales_yoy.description:ja:Hong Kong':
    '香港の小売売上高の前年比変化は、特定の月の小売商品とサービスの総売上高を前年同月の数値と比較して算出される。',
  'retail_sales_yoy.description:ja:Greece':
    'ギリシャの小売売上高の前年比変化は、特定の月の小売商品とサービスの総売上高を前年同月の数値と比較して算出される。',
  'retail_sales_yoy.description:ja:Netherlands':
    'オランダの小売売上高の前年比変化は、特定の月の小売商品とサービスの総売上高を前年同月の数値と比較して算出される。',
  'retail_sales_yoy.description:ja:Italy':
    'イアリアの小売売上高の前年比変化は、特定の月の小売商品とサービスの総売上高を前年同月の数値と比較して算出される。',
  'retail_sales_yoy.description:ja:Canada':
    'カナダの小売売上高の前年比変化は、特定の月の小売商品とサービスの総売上高を前年同月の数値と比較して算出される。',
  'retail_sales_yoy.description:ja:Indonesia':
    'インドネシアの小売売上高の前年比変化は、特定の月の小売商品とサービスの総売上高を前年同月の数値と比較して算出される。',
  'retail_sales_yoy.description:ja:United Kingdom':
    '英国の小売売上高の前年比変化は、特定の月の小売商品とサービスの総売上高を前年同月の数値と比較して算出される。',
  'retail_sales_yoy.description:ja:Ireland':
    'アイルランドの小売売上高の前年比変化は、特定の月の小売商品とサービスの総売上高を前年同月の数値と比較して算出される。',
  'retail_sales_yoy.description:ja:United States':
    '米国の小売売上高の前年比変化は、特定の月の小売商品とサービスの総売上高を前年同月の数値と比較して算出される。',
  'retail_sales_yoy.description:ja:Mexico':
    'メキシコの小売売上高の前年比変化は、特定の月の小売商品とサービスの総売上高を前年同月の数値と比較して算出される。',
  'retail_sales_yoy.description:ja:Norway':
    'ノルウェーの小売売上高の前年比変化は、特定の月の小売商品とサービスの総売上高を前年同月の数値と比較して算出される。',
  'retail_sales_yoy.description:ja:South Africa':
    '南アフリカの小売売上高の前年比変化は、特定の月の小売商品とサービスの総売上高を前年同月の数値と比較して算出される。',
  'retail_sales_yoy.description:ja:France':
    'フランスの小売売上高の前年比変化は、特定の月の小売商品とサービスの総売上高を前年同月の数値と比較して算出される。',
  'retail_sales_yoy.description:ja:Belgium':
    'ベルギーの小売売上高の前年比変化は、特定の月の小売商品とサービスの総売上高を前年同月の数値と比較して算出される。',
  'retail_sales_yoy.description:ja:Colombia':
    'コロンビアの小売売上高の前年比変化は、特定の月の小売商品とサービスの総売上高を前年同月の数値と比較して算出される。',
  'retail_sales_yoy.description:ja:Euro Area':
    'ユーロ圏では、小売売上高は、販売された商品の総数量の動向を示す。商品の中では、食品・飲料・タバコが最大の割合を占め（39.3%）、そのほかは順に、電気製品・家具（12.0%）、コンピューター機器・書籍・その他（11.4%）、医薬品・医療品（9.9%）、繊維・衣類・履物（9.2%）、自動車燃料（9.1%）、その他の非食品商品（6.0%）、通販・インターネット（2.9%）となる。国別では、ドイツのウエートが最大で（25.9%）、それにフランス（21.7%）、イタリア（16.1%）、スペイン（11.4%）と続く。その他の国は、ウエートの大きい順にオランダ（5.2%）、ベルギー（4.3%）、ギリシャ（3.0%）オーストリア（2.8%）、ポルトガル（2.4%）、フィンランド（1.8%）、アイルランド（1.7%）、ルクセンブルク、スロバキア（ともに0.8%）、スロベニア（0.6%）、リトアニア（0.4%）、ラトビア、キプロス（ともに0.3%）、エストニア（0.2%）、マルタ（0.1%）となる。',
  'retail_sales_yoy.description:ja:Hungary':
    'ハンガリーの小売売上高の前年比変化は、特定の月の小売商品とサービスの総売上高を前年同月の数値と比較して算出される。',
  'retail_sales_yoy.description:ja:Sweden':
    'スウェーデンの小売売上高の前年比変化は、特定の月の小売商品とサービスの総売上高を前年同月の数値と比較して算出される。',
  'retail_sales_yoy.description:ja:Austria':
    'オーストリアの小売売上高の前年比変化は、特定の月の小売商品とサービスの総売上高を前年同月の数値と比較して算出される。',
  'retail_sales_yoy.description:ja:Portugal':
    'ポルトガルの小売売上高の前年比変化は、特定の月の小売商品とサービスの総売上高を前年同月の数値と比較して算出される。',
  'retail_sales_yoy.description:ja:China':
    '中国の小売売上高の前年比変化は、特定の月の小売商品とサービスの総売上高を前年同月の数値と比較して算出される。',
  'retail_sales_yoy.description:ja:Japan':
    '日本の小売売上高の前年比変化は、特定の月の小売商品とサービスの総売上高を前年同月の数値と比較して算出される。',
  'retail_sales_yoy.description:ja:Singapore':
    'シンガポールの小売売上高の前年比変化は、特定の月の小売商品とサービスの総売上高を前年同月の数値と比較して算出される。',
  'retail_sales_yoy.description:ja:Denmark':
    'デンマークの小売売上高の前年比変化は、特定の月の小売商品とサービスの総売上高を前年同月の数値と比較して算出される。',
  'retail_sales_yoy.description:ja:Russia':
    'ロシアの小売売上高の前年比変化は、特定の月の小売商品とサービスの総売上高を前年同月の数値と比較して算出される。',
  'retail_sales_yoy.description:ja:Spain':
    'スペインの小売売上高の前年比変化は、特定の月の小売商品とサービスの総売上高を前年同月の数値と比較して算出される。',
  'retail_sales_yoy.description:ja:Czech Republic':
    'チェコ共和国の小売売上高の前年比変化は、特定の月の小売商品とサービスの総売上高を前年同月の数値と比較して算出される。',
  'retail_sales_yoy.description:ja:Brazil':
    'ブラジルの小売売上高の前年比変化は、特定の月の小売商品とサービスの総売上高を前年同月の数値と比較して算出される。',
  'retail_sales_mom.description:ja:DEFAULT': '',
  'retail_sales_mom.description:ja:Indonesia':
    'インドネシアの小売売上高は、特定の期間における小売商品とサービスの総売上高を測定している。この小売売上高は、季節要因の影響を受け、変動的であり、経済全体に対する重要性も比較的高い。',
  'retail_sales_mom.description:ja:Canada':
    'カナダの小売売上高は、特定の期間における小売商品とサービスの総売上高を測定している。この小売売上高は、季節要因の影響を受け、変動的であり、経済全体に対する重要性も比較的高い。',
  'retail_sales_mom.description:ja:Switzerland':
    'スイスの小売売上高は、特定の期間における小売商品とサービスの総売上高を測定している。この小売売上高は、季節要因の影響を受け、変動的であり、経済全体に対する重要性も比較的高い。',
  'retail_sales_mom.description:ja:Colombia':
    'コロンビアの小売売上高は、特定の期間における小売商品とサービスの総売上高を測定している。この小売売上高は、季節要因の影響を受け、変動的であり、経済全体に対する重要性も比較的高い。',
  'retail_sales_mom.description:ja:Euro Area':
    'ユーロ圏では、小売売上高は、販売された商品の総数量の動向を示す。商品の中では、食品・飲料・タバコが最大の割合を占め（39.3%）、そのほかは順に、電気製品・家具（12.0%）、コンピューター機器・書籍・その他（11.4%）、医薬品・医療品（9.9%）、繊維・衣類・履物（9.2%）、自動車燃料（9.1%）、その他の非食品商品（6.0%）、通販・インターネット（2.9%）となる。国別では、ドイツのウエートが最大で（25.9%）、それにフランス（21.7%）、イタリア（16.1%）、スペイン（11.4%）と続く。その他の国は、ウエートの大きい順にオランダ（5.2%）、ベルギー（4.3%）、ギリシャ（3.0%）オーストリア（2.8%）、ポルトガル（2.4%）、フィンランド（1.8%）、アイルランド（1.7%）、ルクセンブルク、スロバキア（ともに0.8%）、スロベニア（0.6%）、リトアニア（0.4%）、ラトビア、キプロス（ともに0.3%）、エストニア（0.2%）、マルタ（0.1%）となる。',
  'retail_sales_mom.description:ja:Mexico':
    'メキシコの小売売上高は、特定の期間における小売商品とサービスの総売上高を測定している。この小売売上高は、季節要因の影響を受け、変動的であり、経済全体に対する重要性も比較的高い。',
  'retail_sales_mom.description:ja:Norway':
    'ノルウェーの小売売上高は、特定の期間における小売商品とサービスの総売上高を測定している。この小売売上高は、季節要因の影響を受け、変動的であり、経済全体に対する重要性も比較的高い。',
  'retail_sales_mom.description:ja:Finland':
    'フィンランドの小売売上高の前月比変化は、特定の月の小売商品とサービスの総売上高を前月の数値と比較して算出される。',
  'retail_sales_mom.description:ja:South Korea':
    '韓国の小売売上高は、特定の期間における小売商品とサービスの総売上高を測定している。この小売売上高は、季節要因の影響を受け、変動的であり、経済全体に対する重要性も比較的高い。',
  'retail_sales_mom.description:ja:Sweden':
    'スウェーデンの小売売上高は、特定の期間における小売商品とサービスの総売上高を測定している。この小売売上高は、季節要因の影響を受け、変動的であり、経済全体に対する重要性も比較的高い。',
  'retail_sales_mom.description:ja:Austria':
    'オーストリアの小売売上高は、特定の期間における小売商品とサービスの総売上高を測定している。この小売売上高は、季節要因の影響を受け、変動的であり、経済全体に対する重要性も比較的高い。',
  'retail_sales_mom.description:ja:Germany':
    'ドイツの小売売上高は、特定の期間における小売商品とサービスの総売上高を測定している。この小売売上高は、季節要因の影響を受け、変動的であり、経済全体に対する重要性も比較的高い。',
  'retail_sales_mom.description:ja:Poland':
    'ポーランドの小売売上高は、特定の期間における小売商品とサービスの総売上高を測定している。この小売売上高は、季節要因の影響を受け、変動的であり、経済全体に対する重要性も比較的高い。',
  'retail_sales_mom.description:ja:United Kingdom':
    '英国の小売売上高は、特定の期間における小売商品とサービスの総売上高を測定している。この小売売上高は、季節要因の影響を受け、変動的であり、経済全体に対する重要性も比較的高い。',
  'retail_sales_mom.description:ja:Ireland':
    'アイルランドの小売売上高は、特定の期間における小売商品とサービスの総売上高を測定している。この小売売上高は、季節要因の影響を受け、変動的であり、経済全体に対する重要性も比較的高い。',
  'retail_sales_mom.description:ja:United States':
    '米国の小売売上高は、特定の期間における小売商品とサービスの総売上高を測定している。小売業者は次の13の主要な部門に分類される。自動車・関連部品販売業者（全売上高の約20%）、食品・飲料販売店舗（13%）、総合小売店（12.5%）、飲食サービス（11%）、ガソリンスタンド（10％）、無店舗小売販売（9.2%）、建設資材・園芸用品販売（6%）、ヘルス・パーソナルケア製品販売（6%）、衣服・服飾品販売（5%）、雑貨店（2.3%）、家具販売（2%）、家電販売（2%）、スポーツ用品・趣味・書籍・楽器販売（1.7%）。',
  'retail_sales_mom.description:ja:South Africa':
    '南アフリカの小売売上高は、特定の期間における小売商品とサービスの総売上高を測定している。この小売売上高は、季節要因の影響を受け、変動的であり、経済全体に対する重要性も比較的高い。',
  'retail_sales_mom.description:ja:Belgium':
    'ベルギーの小売売上高は、特定の期間における小売商品とサービスの総売上高を測定している。この小売売上高は、季節要因の影響を受け、変動的であり、経済全体に対する重要性も比較的高い。',
  'retail_sales_mom.description:ja:France':
    'フランスの小売売上高は、特定の期間における小売商品とサービスの総売上高を測定している。この小売売上高は、季節要因の影響を受け、変動的であり、経済全体に対する重要性も比較的高い。',
  'retail_sales_mom.description:ja:Greece':
    'ギリシャの小売売上高は、特定の期間における小売商品とサービスの総売上高を測定している。この小売売上高は、季節要因の影響を受け、変動的であり、経済全体に対する重要性も比較的高い。',
  'retail_sales_mom.description:ja:Hungary':
    'ハンガリーの小売売上高は、特定の期間における小売商品とサービスの総売上高を測定している。この小売売上高は、季節要因の影響を受け、変動的であり、経済全体に対する重要性も比較的高い。',
  'retail_sales_mom.description:ja:Italy':
    'イタリアの小売売上高は、特定の期間における小売商品とサービスの総売上高を測定している。この小売売上高は、季節要因の影響を受け、変動的であり、経済全体に対する重要性も比較的高い。',
  'retail_sales_mom.description:ja:Netherlands':
    'オランダの小売売上高は、特定の期間における小売商品とサービスの総売上高を測定している。この小売売上高は、季節要因の影響を受け、変動的であり、経済全体に対する重要性も比較的高い。',
  'retail_sales_mom.description:ja:Australia':
    'オーストラリアでは、小売売上高が一定期間における小売商品とサービスの総売上高を測定する。この小売売上高は季節要因の影響を受け変動が大きく、経済全体に対する重要性も比較的高い。',
  'retail_sales_mom.description:ja:Japan':
    '日本の小売売上高は、特定の期間における小売商品とサービスの総売上高を測定している。この小売売上高は、季節要因の影響を受け、変動的であり、経済全体に対する重要性も比較的高い。',
  'retail_sales_mom.description:ja:Singapore':
    'シンガポールの小売売上高は、特定の期間における小売商品とサービスの総売上高を測定している。この小売売上高は、季節要因の影響を受け、変動的であり、経済全体に対する重要性も比較的高い。',
  'retail_sales_mom.description:ja:Portugal':
    'ポルトガルの小売売上高は、特定の期間における小売商品とサービスの総売上高を測定している。この小売売上高は、季節要因の影響を受け、変動的であり、経済全体に対する重要性も比較的高い。',
  'retail_sales_mom.description:ja:Russia':
    'ロシアの小売売上高は、特定の期間における小売商品とサービスの総売上高を測定している。この小売売上高は、季節要因の影響を受け、変動的であり、経済全体に対する重要性も比較的高い。',
  'retail_sales_mom.description:ja:Denmark':
    'デンマークの小売売上高は、特定の期間における小売商品とサービスの総売上高を測定している。この小売売上高は、季節要因の影響を受け、変動的であり、経済全体に対する重要性も比較的高い。',
  'retail_sales_mom.description:ja:Spain':
    'スペインの小売売上高は、特定の期間における小売商品とサービスの総売上高を測定している。この小売売上高は、季節要因の影響を受け、変動的であり、経済全体に対する重要性も比較的高い。',
  'retail_sales_mom.description:ja:Czech Republic':
    'チェコ共和国の小売売上高は、特定の期間における小売商品とサービスの総売上高を測定している。この小売売上高は、季節要因の影響を受け、変動的であり、経済全体に対する重要性も比較的高い。',
  'retail_sales_mom.description:ja:Brazil':
    'ブラジルの小売売上高は、特定の期間における小売商品とサービスの総売上高を測定している。この小売売上高は、季節要因の影響を受け、変動的であり、経済全体に対する重要性も比較的高い。',
  'part_time_employment_chg.description:ja:DEFAULT': '',
  'part_time_employment_chg.description:ja:Canada':
    'カナダでは、パートタイム雇用は、主な仕事または唯一の仕事での労働時間が通常週30時間未満の労働者で構成される。',
  'fed_monetary_policy_statement_and_press_conference.description:ja:DEFAULT': '',
  'fed_monetary_policy_statement_and_press_conference.description:ja:United States': '',
  'respect_for_the_aged_day.description:ja:DEFAULT': '',
  'respect_for_the_aged_day.description:ja:Japan': '',
  'foreign_exchange_reserves.description:ja:DEFAULT': '',
  'foreign_exchange_reserves.description:ja:Switzerland':
    'スイスでは、外貨準備は、中央銀行が保有し管理する対外資産をいう。外貨準備は金や特定の通貨で構成されるほか、特別引き出し権（SDR）や、米財務省短期証券、国債、社債、株式などの外貨建ての市場性有価証券、外貨建て貸付も含む場合もある。',
  'foreign_exchange_reserves.description:ja:China':
    '中国では、外貨準備は、中央銀行が保有し管理する対外資産をいう。外貨準備は金や特定の通貨で構成されるほか、特別引き出し権（SDR）や、米財務省短期証券、国債、社債、株式などの外貨建ての市場性有価証券、外貨建て貸付を含む場合もある。',
  'foreign_exchange_reserves.description:ja:Russia':
    'ロシアでは、外貨準備は、中央銀行が保有し管理する対外資産をいう。外貨準備は金や特定の通貨で構成されるほか、特別引き出し権（SDR）や、米財務省短期証券、国債、社債、株式などの外貨建ての市場性有価証券、外貨建て貸付を含む場合もある。',
  'foreign_exchange_reserves.description:ja:Singapore':
    'シンガポールでは、外貨準備は、中央銀行が保有し管理する対外資産をいう。外貨準備は金や特定の通貨で構成されるほか、特別引き出し権（SDR）や、米財務省短期証券、国債、社債、株式などの外貨建ての市場性有価証券、外貨建て貸付を含む場合もある。',
  'foreign_exchange_reserves.description:ja:Hong Kong':
    '香港では、外貨準備は、中央銀行が保有し、管理する対外資産をいう。外貨準備は金や特定の通貨で構成されるほか、特別引き出し権（SDR）や、米財務省短期証券、国債、社債、株式などの外貨建ての市場性有価証券、外貨建て貸付も含まれる場合がある。',
  'foreign_exchange_reserves.description:ja:Taiwan':
    '台湾では、外貨準備は、中央銀行が保有し管理する対外資産をいう。外貨準備は金や特定の通貨で構成されるほか、特別引き出し権（SDR）や、米財務省短期証券、国債、社債、株式などの外貨建ての市場性有価証券、外貨建て貸付を含む場合もある。',
  'foreign_exchange_reserves.description:ja:South Korea':
    '韓国では、外貨準備は、中央銀行が保有し管理する対外資産をいう。外貨準備は金や特定の通貨で構成されるほか、特別引き出し権（SDR）や、米財務省短期証券、国債、社債、株式などの外貨建ての市場性有価証券、外貨建て貸付を含む場合もある。',
  'foreign_exchange_reserves.description:ja:Indonesia':
    'インドネシアでは、外貨準備は、中央銀行が保有し管理する対外資産をいう。外貨準備は金や特定の通貨で構成されるほか、特別引き出し権（SDR）や、米財務省短期証券、国債、社債、株式などの外貨建ての市場性有価証券、外貨建て貸付を含む場合もある。',
  'foreign_exchange_reserves.description:ja:Czech Republic':
    'チェコ共和国では、外貨準備は、中央銀行が保有し管理する対外資産をいう。外貨準備は金や特定の通貨で構成されるほか、特別引き出し権（SDR）や、米財務省短期証券、国債、社債、株式などの外貨建ての市場性有価証券、外貨建て貸付を含む場合もある。',
  'foreign_exchange_reserves.description:ja:Thailand':
    'タイでは、外貨準備は、中央銀行が保有し管理する対外資産をいう。外貨準備は金や特定の通貨で構成されるほか、特別引き出し権（SDR）や、米財務省短期証券、国債、社債、株式などの外貨建ての市場性有価証券、外貨建て貸付を含む場合もある。',
  'foreign_exchange_reserves.description:ja:Denmark':
    'デンマークでは、外貨準備は、中央銀行が保有し管理する対外資産をいう。外貨準備は金や特定の通貨で構成されるほか、特別引き出し権（SDR）や、米財務省短期証券、国債、社債、株式などの外貨建ての市場性有価証券、外貨建て貸付を含む場合もある。',
  'foreign_exchange_reserves.description:ja:Poland':
    'ポーランドでは、外貨準備は、中央銀行が保有し管理する対外資産をいう。外貨準備は金や特定の通貨で構成されるほか、特別引き出し権（SDR）や、米財務省短期証券、国債、社債、株式などの外貨建ての市場性有価証券、外貨建て貸付を含む場合もある。',
  'foreign_exchange_reserves.description:ja:India':
    'インドでは、外貨準備は、中央銀行が保有し管理する対外資産をいう。外貨準備は金や特定の通貨で構成されるほか、特別引き出し権（SDR）や、米財務省短期証券、国債、社債、株式などの外貨建ての市場性有価証券、外貨建て貸付を含む場合もある。',
  'foreign_exchange_reserves.description:ja:South Africa':
    '南アフリカでは、外貨準備は、中央銀行が保有し管理する対外資産をいう。外貨準備は金や特定の通貨で構成されるほか、特別引き出し権（SDR）や、米財務省短期証券、国債、社債、株式などの外貨建ての市場性有価証券、外貨建て貸付を含む場合もある。',
  'foreign_exchange_reserves.description:ja:Israel':
    'イスラエルでは、外貨準備は、中央銀行が保有し管理する対外資産をいう。外貨準備は金や特定の通貨で構成されるほか、特別引き出し権（SDR）や、米財務省短期証券、国債、社債、株式などの外貨建ての市場性有価証券、外貨建て貸付を含む場合もある。',
  'foreign_exchange_reserves.description:ja:Mexico':
    'メキシコでは、外貨準備は、中央銀行が保有し管理する対外資産をいう。外貨準備は金や特定の通貨で構成されるほか、特別引き出し権（SDR）や、米財務省短期証券、国債、社債、株式などの外貨建ての市場性有価証券、外貨建て貸付を含む場合もある。',
  'foreign_exchange_reserves.description:ja:Japan':
    '日本では、外貨準備は、中央銀行が保有し管理する対外資産をいう。外貨準備は金や特定の通貨で構成されるほか、特別引き出し権（SDR）や、米財務省短期証券、国債、社債、株式などの外貨建ての市場性有価証券、外貨建て貸付を含む場合もある。',
  'foreign_exchange_reserves.description:ja:Philippines':
    'フィリピンでは、外貨準備は、中央銀行が保有し管理する対外資産をいう。外貨準備は金や特定の通貨で構成されるほか、特別引き出し権（SDR）や、米財務省短期証券、国債、社債、株式などの外貨建ての市場性有価証券、外貨建て貸付を含む場合もある。',
  'consumer_confidence_households.description:ja:DEFAULT': '',
  'consumer_confidence_households.description:ja:Japan':
    '日本では、毎月の消費動向調査は戸別訪問によって2人以上の世帯約4,700を対象に行い、暮らし向き、収入の増え方、雇用環境、耐久消費財の買い時判断の4つを質問する。それぞれに対し、消費者が6カ月後の状況をどう考えているかの評価の回答に基づいて指標が作成される。消費者態度指数はこれら4つの消費者態度の単純平均で、指数が50を超えると楽観的な見方を、50未満は悲観的な見方を、50はニュートラルであることを示す。',
  'snb_chair_jordan_speaks.description:ja:DEFAULT': '',
  'snb_chair_jordan_speaks.description:ja:Switzerland': '',
  'retail_sales_ex_gas_autos_mom.description:ja:DEFAULT': '',
  'retail_sales_ex_gas_autos_mom.description:ja:United States': '',
  'retail_sales_ex_autos_mom.description:ja:DEFAULT': '',
  'retail_sales_ex_autos_mom.description:ja:Canada':
    'カナダの小売売上高（自動車を除く）は、1カ月間の小売商品とサービスの総売上高（自動車セクターを除く）を測定する。',
  'retail_sales_ex_autos_mom.description:ja:United States':
    '米国の小売売上高（自動車を除く）は、1カ月間の小売商品とサービスの総売上高（自動車セクターを除く）を測定する。',
  'the_passing_of_king_bhumibol.description:ja:DEFAULT': '',
  'the_passing_of_king_bhumibol.description:ja:Thailand': '',
  'makha_bucha_day_substitute.description:ja:DEFAULT': '',
  'makha_bucha_day_substitute.description:ja:Thailand': '',
  'fed_harker_speech.description:ja:DEFAULT': '',
  'fed_harker_speech.description:ja:United States':
    '米国では、金利設定の権限は連邦準備制度理事会（理事会）と連邦公開市場委員会（FOMC）に分けられている。理事会は、1つ以上の地区連邦準備銀行から提出された勧告を受けて、割引率の変更を決定する。FOMCは、中央銀行の資金量やフェデラルファンド市場金利などの公開市場操作を決定する。',
  'retail_pmi.description:ja:DEFAULT': '',
  'retail_pmi.description:ja:Euro Area': '',
  'residential_property_prices_yoy.description:ja:DEFAULT': '',
  'residential_property_prices_yoy.description:ja:Ireland':
    'アイルランドの住宅価格指数とは、住宅用不動産価格指数（RPPI）を指し、国内で売却された住宅用不動産に支払われた価格の平均水準の変化を測定することを目的としている。RPPIは、2002年住宅法第13条の下、大手住宅ローン貸付機関8社が毎月提供する住宅ローン実行件数に関するデータを使って算出される。これは取引ベースの数値で、売却が行われた際にのみ物件の価格が記録される。住宅用不動産の取引すべてで住宅ローンが利用されるとは限らないため（現金払いの場合）、住宅ローンが関与しない取引はこの指数には入らない。',
  'culture_day.description:ja:DEFAULT': '',
  'culture_day.description:ja:Japan': '',
  'president_obama_statement_on_the_economy.description:ja:DEFAULT': '',
  'president_obama_statement_on_the_economy.description:ja:United States': '',
  'investec_services_pmi.description:ja:DEFAULT': '',
  'investec_services_pmi.description:ja:Ireland':
    'Inveseicサービス業PMIは、アイルランドの民間サービス企業約450社に送付する質問書への回答による月次の調査に基づいている。指数が50.0を超えると、その項目の活動（新規受注、価格、雇用状況など）が拡大、50.0より低い場合は活動の縮小を示す。50.0ちょうどの場合、前月と比較し状況が安定していることを示す。',
  'industrial_sentiment.description:ja:DEFAULT': '',
  'industrial_sentiment.description:ja:Euro Area':
    'ユーロ圏では、製造業者の信頼感の水準を測定する産業界景況感が、景気動向指標の40％を占めている。',
  'non-oil_exports_yoy.description:ja:DEFAULT': '',
  'non-oil_exports_yoy.description:ja:Singapore':
    'シンガポールの非石油国内輸出（NODX）は、IC、ディスクメディア製品、PC、PC 部品、ダイオード・トランジスタなどの電子製品と、特に、石油化学製品や医薬品などの化学製品を中心とした非電子製品に分けられる。',
  'markit_cips_manufacturing_pmi.description:ja:DEFAULT': '',
  'markit_cips_manufacturing_pmi.description:ja:United Kingdom':
    'マークイット/CIPS英国製造業購買担当者景気指数（PMI）は製造業600社の調査に基づき、製造業の業況を測定し、それぞれウエート付けされた、新規受注（30%）、生産（25%）、雇用（20%）、サプライヤー納期（15%）、購買品在庫（10%）という5つの個別インデックスから構成され、納期インデックスは比較可能にするため符号が反転される。50を超える指数は製造業セクターが前期に比べて拡大していることを、50を切る指数は縮小していることを、50は変化がないことを示す。',
  'easter_eve.description:ja:DEFAULT': '',
  'easter_eve.description:ja:Sweden': '',
  'new_year_s_day_substitute_day.description:ja:Spain': '',
  'new_year_s_day_substitute_day.description:ja:Canada': '',
  'new_year_s_day_substitute_day.description:ja:United States': '',
  'new_year_s_day_substitute_day.description:ja:Australia': '',
  'ram_navami.description:ja:DEFAULT': '',
  'ram_navami.description:ja:India': '',
  'aib_manufacturing_pmi.description:ja:DEFAULT': '',
  'aib_manufacturing_pmi.description:ja:Ireland':
    'AIB アイルランド製造業購買担当者景気指数（PMI）は製造業258社の調査に基づき、製造業の業況を測定する。製造業PMIはそれぞれウエート付けされた、新規受注（30%）、生産（25%）、雇用（20%）、サプライヤーの納期（15%）、購入品の在庫（10%）という5つの個別インデックスから構成され、納期インデックスは他のインデックスと同じ方向に動くよう符号を反転させる。50を超える指数は製造業セクターが前期に比べて拡大していることを、50を切る指数は縮小していることを、50は変化がないことを示す。',
  'boj_iwata_speech.description:ja:DEFAULT': '',
  'boj_iwata_speech.description:ja:Japan': '',
  'halifax_house_price_index_mom.description:ja:DEFAULT': '',
  'halifax_house_price_index_mom.description:ja:United Kingdom':
    '英国では、住宅価格指数はハリファックス住宅価格指数を指す。同指数は1カ月当たり約1万5000件の住宅購入件数をカバーする住宅ローンデータ・サンプルに基づいて集計される。',
  'food_price_index_mom.description:ja:DEFAULT': '',
  'food_price_index_mom.description:ja:New Zealand': '',
  'rba_gov_debelle_speech.description:ja:DEFAULT': '',
  'rba_gov_debelle_speech.description:ja:Australia': '',
  'labour_day.description:ja:DEFAULT': '',
  'labour_day.description:ja:South Korea': '',
  'labour_day.description:ja:Australia': '',
  'labour_day.description:ja:Hong Kong': '',
  'labour_day.description:ja:Thailand': '',
  'labour_day.description:ja:Taiwan': '',
  'labour_day.description:ja:Singapore': '',
  'labour_day.description:ja:Philippines': '',
  'labour_day.description:ja:Colombia': '',
  'labour_day.description:ja:Brazil': '',
  'labour_day.description:ja:Indonesia': '',
  'labour_day.description:ja:Canada': '',
  'labour_day.description:ja:Greece': '',
  'labour_day.description:ja:Sweden': '',
  'labour_day.description:ja:Austria': '',
  'labour_day.description:ja:Germany': '',
  'labour_day.description:ja:Poland': '',
  'labour_day.description:ja:South Africa': '',
  'labour_day.description:ja:France': '',
  'labour_day.description:ja:Belgium': '',
  'labour_day.description:ja:Mexico': '',
  'labour_day.description:ja:Norway': '',
  'labour_day.description:ja:Hungary': '',
  'labour_day.description:ja:Italy': '',
  'labour_day.description:ja:China': '',
  'labour_day.description:ja:Portugal': '',
  'labour_day.description:ja:Switzerland': '',
  'labour_day.description:ja:New Zealand': '',
  'labour_day.description:ja:Russia': '',
  'labour_day.description:ja:Spain': '',
  'labour_day.description:ja:Czech Republic': '',
  'procure_ch_manufacturing_pmi.description:ja:DEFAULT': '',
  'procure_ch_manufacturing_pmi.description:ja:Switzerland':
    'スイスの procure.ch製造業購買担当者景気指数（PMI）は、製造業の業況を測定し、翌月の購買予想に関する企業幹部へのアンケート結果に基づいて集計される。製造業PMIは、それぞれウエート付けされた、新規受注（30%）、生産（25%）、雇用（20%）、サプライヤー納期（15%）、購買品在庫（10%）という5つのサブインデックスから構成される。納期インデックスは他のインデックスと同じ方向に動くよう符号を反転させる。50を超える指数は製造業セクターが前期に比べて拡大していることを、50を切る指数は縮小していることを、50は変化がないことを示す。',
  'sacred_heart.description:ja:DEFAULT': '',
  'sacred_heart.description:ja:Colombia': '',
  'residential_property_prices_mom.description:ja:DEFAULT': '',
  'residential_property_prices_mom.description:ja:Ireland':
    'アイルランドの住宅価格指数とは、住宅用不動産価格指数（RPPI）を指し、国内で売却された住宅用不動産に支払われた価格の平均水準の変化を測定することを目的としている。RPPIは、2002年住宅法第13条の下、大手住宅ローン貸付機関8社が毎月提供する住宅ローン実行件数に関するデータを使って算出される。これは取引ベースの数値で、売却が行われた際にのみ物件の価格が記録される。住宅用不動産の取引すべてで住宅ローンが利用されるとは限らないため（現金払いの場合）、住宅ローンが関与しない取引はこの指数には入らない。',
  'repentance_day.description:ja:DEFAULT': '',
  'repentance_day.description:ja:Germany': '',
  'isra_and_mi_raj_prophet_s_ascension.description:ja:DEFAULT': '',
  'isra_and_mi_raj_prophet_s_ascension.description:ja:Indonesia': '',
  'christmas.description:ja:DEFAULT': '',
  'christmas.description:ja:Switzerland': '',
  'christmas.description:ja:Sweden': '',
  'christmas.description:ja:Portugal': '',
  'christmas.description:ja:Singapore': '',
  'christmas.description:ja:Netherlands': '',
  'christmas.description:ja:Mexico': '',
  'christmas.description:ja:Ireland': '',
  'christmas.description:ja:Philippines': '',
  'christmas.description:ja:Finland': '',
  'christmas.description:ja:Brazil': '',
  'christmas.description:ja:Colombia': '',
  'christmas.description:ja:Indonesia': '',
  'christmas.description:ja:Norway': '',
  'christmas.description:ja:Belgium': '',
  'christmas.description:ja:South Africa': '',
  'christmas.description:ja:Poland': '',
  'christmas.description:ja:Austria': '',
  'christmas.description:ja:Czech Republic': '',
  'christmas.description:ja:Denmark': '',
  'christmas.description:ja:Greece': '',
  'christmas.description:ja:Hungary': '',
  'rejoicing_of_the_law_eve.description:ja:DEFAULT': '',
  'rejoicing_of_the_law_eve.description:ja:Israel': '',
  'regional_elections_-_2nd_round.description:ja:DEFAULT': '',
  'regional_elections_-_2nd_round.description:ja:France': '',
  'reformation_day.description:ja:DEFAULT': '',
  'reformation_day.description:ja:Germany': '',
  'hsbc_manufacturing_pmi_prel.description:ja:DEFAULT': '',
  'hsbc_manufacturing_pmi_prel.description:ja:China': '',
  'president_biden_formal_press_conference.description:ja:DEFAULT': '',
  'president_biden_formal_press_conference.description:ja:United States': '',
  'referendum_on_legalising_cannabis.description:ja:DEFAULT': '',
  'referendum_on_legalising_cannabis.description:ja:New Zealand': '',
  'referendum.description:ja:DEFAULT': '',
  'referendum.description:ja:Hungary': '',
  'referendum.description:ja:Colombia': '',
  'redbook_yoy.description:ja:DEFAULT': '',
  'redbook_yoy.description:ja:United States':
    'ジョンソン・レッドブック指数は、アメリカの大手一般小売業者約9,000店舗の前年比既存店売上高成長率を、売上高を加重して算出したものである。既存店売上高とは、12カ月以上継続して営業している店舗の売上を指す。この指数は、ドルベースで米商務省が集計・公表する「公式」の小売売上高の80%に相当する。レッドブックは、小売事業者の業績予測をまとめ、解釈を加えることで構成される。指数とそのサブグループは、これらの予測の売上高を加重して合計したもので、統計内の週は小売り営業週間（日曜から土曜）であり、月内で均等加重となっている。',
  'raw_materials_prices_yoy.description:ja:DEFAULT': '',
  'raw_materials_prices_yoy.description:ja:Canada':
    '原材料価格指数（RPMI）は、カナダの製造業者が主要原材料に支払った価格を反映している。工業製品価格指数とは異なり、RMPIにはカナダで生産されていない財の価格も含まれる。',
  'private_sector_loans.description:ja:DEFAULT': '',
  'private_sector_loans.description:ja:Ireland': '',
  'raw_material_price_index.description:ja:DEFAULT': '',
  'raw_material_price_index.description:ja:Canada': '',
  'gdp_external_demand_qoq.description:ja:DEFAULT': '',
  'gdp_external_demand_qoq.description:ja:Japan': '',
  'nahb_housing_market_index.description:ja:DEFAULT': '',
  'nahb_housing_market_index.description:ja:United States':
    'NAHB（全米住宅建設業者協会）/ウェルズファーゴ銀行による住宅市場指数（HMI）は、住宅建設業者を対象とした月次調査に基づいている。調査では、現在の一戸建て住宅の販売状況と今後6カ月間の販売見通し、および購入希望者の動向について評価するよう住宅建設業者に求めている。各項目に対する回答のスコアをもとに、季節調整済みの総合指数が算出される。50以上の数値は、販売状況を「良い」と見なす業者が「悪い」と見なす業者よりも多いことを示す。',
  'feast_of_sacrifice.description:ja:DEFAULT': '',
  'feast_of_sacrifice.description:ja:India': '',
  'whitsun.description:ja:DEFAULT': '',
  'whitsun.description:ja:Sweden': '',
  'municipal_election_first_round.description:ja:DEFAULT': '',
  'municipal_election_first_round.description:ja:Brazil': '',
  'muharram_islamic_new_year.description:ja:DEFAULT': '',
  'muharram_islamic_new_year.description:ja:Indonesia': '',
  'revised_2016_budget.description:ja:DEFAULT': '',
  'revised_2016_budget.description:ja:Norway': '',
  'midsummer_s_eve.description:ja:DEFAULT': '',
  'midsummer_s_eve.description:ja:Finland': '',
  'constitution_day_substitute.description:ja:DEFAULT': '',
  'constitution_day_substitute.description:ja:Thailand': '',
  'the_independence_day_of_korea.description:ja:DEFAULT': '',
  'the_independence_day_of_korea.description:ja:South Korea': '',
  'muharram.description:ja:DEFAULT': '',
  'muharram.description:ja:India': '',
  'mountain_day.description:ja:DEFAULT': '',
  'mountain_day.description:ja:Japan': '',
  'foreign_direct_investment.description:ja:DEFAULT': '',
  'foreign_direct_investment.description:ja:Indonesia':
    'インドネシアでは、外国直接投資とは、海外から受け入れた外国直接投資資金の流入を指す。FDIデータには、銀行や石油・ガスセクターへの投資は含まれていない。',
  'foreign_direct_investment.description:ja:Philippines': '',
  'foreign_direct_investment.description:ja:Brazil':
    'ブラジルでは、外国直接投資とは、エクイティ・キャピタル及び企業間融資を含む、海外から受け取った外国直接投資の純流入を指す。',
  'reuters_michigan_consumer_sentiment_index.description:ja:DEFAULT': '',
  'reuters_michigan_consumer_sentiment_index.description:ja:United States': '',
  'motorbike_sales_yoy.description:ja:DEFAULT': '',
  'motorbike_sales_yoy.description:ja:Indonesia': '',
  'monthly_gdp_3-month_avg.description:ja:DEFAULT': '',
  'monthly_gdp_3-month_avg.description:ja:United Kingdom':
    'GDPは、英国で生産された財とサービスの価値を測る。この数値は3カ月間の成長率であり、月次の数値では変動性が高いため、月次のデータ以上にGDPの実質的伸び率を示すのに適している。そうした意味で、月次データは、単なる1カ月分の経済データではなく、月次の経済データの3カ月間分のデータのアップデートと捉えることができる。3カ月ローリング（移動）データは、3カ月間の成長率をそれ以前の3カ月間の成長率と比べることで算出される。例えば、3-5月の成長率を12-2月のデータと比較するなどだ。',
  'midsummer_eve.description:ja:DEFAULT': '',
  'midsummer_eve.description:ja:Sweden': '',
  'midsummer_eve.description:ja:Finland': '',
  'monthly_gdp.description:ja:DEFAULT': '',
  'monthly_gdp.description:ja:United Kingdom': '',
  'money_supply_m1_yoy.description:ja:DEFAULT': '',
  'money_supply_m1_yoy.description:ja:Israel': '',
  'consumer_confidence_westpac.description:ja:DEFAULT': '',
  'consumer_confidence_westpac.description:ja:New Zealand':
    'ニュージーランドでは、ウェストパックおよびマクダーモット・ミラーによる消費者信頼感指数が消費者の経済状況に対する楽観度を測定している。消費者信頼感指数では、消費者の個人的な経済状況、国の経済の見通しと大型消費に関する国際標準となっている5つの質問への回答が集計される。100を基準に、肯定的な回答と否定的な回答の平均を足し引きする。100を超えるスコアは楽観が優勢であることを、100を下回るスコアは悲観が優勢であることを示す。。',
  'money_supply_m1.description:ja:DEFAULT': '',
  'money_supply_m1.description:ja:Israel': '',
  'manufacturing_sales_mom.description:ja:DEFAULT': '',
  'manufacturing_sales_mom.description:ja:Canada':
    'カナダでは、製造業売上高はカナダの製造業セクターによる製品売上高の特定時点での状況（スナップショット）であり、中・短期間におけるカナダ経済の状況と特定業界の状況の分析が可能である。',
  'monetary_policy_report.description:ja:DEFAULT': '',
  'monetary_policy_report.description:ja:Sweden':
    'スウェーデンでは、ベンチマーク金利はスウェーデン中央銀行（リクスバンク）の執行委員会が決定する。主要金利は、7日間リクスバンクから銀行が融資を受けるか預金する際の金利であるレポ金利。リスクバンクはインフレ率をCPIF（金利一定の下での消費者物価指数）で年間2％程度に保つことを目標としている。',
  'monetary_policy_report.description:ja:Russia':
    'ロシアでは金利の決定はロシア連邦中央銀行が行う。2013年9月16日より公式金利は1週間物レポ金利となった。2013年9月15日までの公式金利はリファイナンス金利であり、借手の金利の上限とみなされ、納税額を算出する際の基準であった。',
  'monetary_policy_report.description:ja:United Kingdom':
    'イギリスの金融政策委員会（MPC）が基準利率を設定する。中央銀行であるイングランド銀行の公式金利はレポ金利である。このレポ金利は、イングランド銀行の取引相手（銀行、住宅金融組合、証券会社）に対する公開市場操作での利率となる。',
  'monetary_policy_report.description:ja:United States': '',
  'monetary_policy_meeting_minutes.description:ja:DEFAULT': '',
  'monetary_policy_meeting_minutes.description:ja:South Korea': '',
  'monetary_policy_meeting_minutes.description:ja:Mexico':
    'メキシコでは金利決定はメキシコ銀行（(Banco de México）が行う。2008年１月以来、商業銀行の中銀における当座預金残高水準（CORTO）に代えて翌日物銀行間金利を金利誘導目標としている。',
  'monetary_policy_meeting_minutes.description:ja:Sweden':
    'スウェーデンでは、ベンチマーク金利はスウェーデン中央銀行（リクスバンク）の執行委員会が決定する。主要金利は、7日間リクスバンクから銀行が融資を受けるか預金する際の金利であるレポ金利。リスクバンクはインフレ率をCPIF（金利一定の下での消費者物価指数）で年間2％程度に保つことを目標としている。',
  'monetary_policy_meeting_minutes.description:ja:India':
    'インドでは、インド準備銀行（RBI）の中央取締役会により政策金利が決定される。政策金利はベンチマークレポレート。RBIは2014年に金融政策の主要目的を物価安定と定め、政府による借り入れやルピーの為替レート安定、輸出保護の必要性の優先順位を引き下げた。政府と中央銀行は2015年2月、インフレ目標を消費者物価指数4%として、その上下2%に許容範囲を定め、2017年3月期からこれを施行することで合意した。',
  'monetary_policy_meeting_minutes.description:ja:Poland':
    'ポーランドでは金利決定はポーランド国立銀行（NBP）が行い、政策金利はレファレンス金利である。',
  'eu-turkey_migration_summit.description:ja:DEFAULT': '',
  'eu-turkey_migration_summit.description:ja:Euro Area': '',
  'rba_kent_speech.description:ja:DEFAULT': '',
  'rba_kent_speech.description:ja:Australia':
    'オーストラリアでは金利決定はオーストラリア準備銀行理事会が行い、政策金利はキャッシュレートである。キャッシュレートとは、金融機関の間で翌日物貸付に適用される利率で、翌日物資金の需要と供給によってマネーマーケットで決定される。',
  'moharram.description:ja:DEFAULT': '',
  'moharram.description:ja:India': '',
  'mining_production_yoy.description:ja:DEFAULT': '',
  'mining_production_yoy.description:ja:South Africa':
    '南アフリカでは、鉱山生産量指数として生産量の変化を統計にまとめている。石炭が最も重要なセクターとなっており、総鉱山生産高の24%を占めている。続いて白金族金属（23%）、金（16%）、鉄鉱石（12%）、その他非鉄金属（6%）、ダイヤモンド（4%）、マンガン鉱石およびクロミウム鉱石（各3%）、その他金属、建設用金属およびニッケル（各2%）、銅（1%）となっている。',
  'fed_powell_speech.description:ja:DEFAULT': '',
  'fed_powell_speech.description:ja:United States': '',
  'mid-month_inflation_rate_mom.description:ja:DEFAULT': '',
  'mid-month_inflation_rate_mom.description:ja:Mexico': '',
  'mid-month_core_inflation_rate_mom.description:ja:DEFAULT': '',
  'mid-month_core_inflation_rate_mom.description:ja:Mexico': '',
  'mid-autumn_festival.description:ja:DEFAULT': '',
  'mid-autumn_festival.description:ja:Taiwan': '',
  'mid-year_economic_and_fiscal_outlook.description:ja:DEFAULT': '',
  'mid-year_economic_and_fiscal_outlook.description:ja:Australia': '',
  'mid-term_elections.description:ja:DEFAULT': '',
  'mid-term_elections.description:ja:United States': '',
  'fed_yellen_speaks_at_jackson_hole.description:ja:DEFAULT': '',
  'fed_yellen_speaks_at_jackson_hole.description:ja:United States': '',
  '7-year_note_auction.description:ja:DEFAULT': '',
  '7-year_note_auction.description:ja:United States': '',
  'rejoicing_of_the_law_simchat_torah.description:ja:DEFAULT': '',
  'rejoicing_of_the_law_simchat_torah.description:ja:Israel': '',
  'mid-autumn_holiday.description:ja:DEFAULT': '',
  'mid-autumn_holiday.description:ja:China': '',
  'harmonised_inflation_rate_mom_final.description:ja:DEFAULT': '',
  'harmonised_inflation_rate_mom_final.description:ja:Italy':
    '調和物価指数（HICP）は、欧州中央銀行（ECB）によるインフレおよび物価安定性の指標である。HICPは、ユーロスタットおよび各国の統計機関により調整統計手法によってまとめられる。ECBは、HICPが発表する中期に渡る年間インフレ率を2%弱に保つことを目標としている。',
  'harmonised_inflation_rate_mom_final.description:ja:France':
    '調和物価指数（HICP）は、欧州中央銀行（ECB）によるインフレおよび物価安定性の指標である。HICPは、ユーロスタットおよび各国の統計機関により調整統計手法によってまとめられる。ECBは、HICPが発表する中期に渡る年間インフレ率を2%弱に保つことを目標としている。',
  'harmonised_inflation_rate_mom_final.description:ja:Spain':
    '調和物価指数（HICP）は、欧州中央銀行（ECB）によるインフレおよび物価安定性の指標である。HICPは、ユーロスタットおよび各国の統計機関により調整統計手法によってまとめられる。ECBは、HICPが発表する中期に渡る年間インフレ率を2%弱に保つことを目標としている。',
  'harmonised_inflation_rate_mom_final.description:ja:Germany':
    '調和物価指数（HICP）は、欧州中央銀行（ECB）によるインフレおよび物価安定性の指標である。HICPは、ユーロスタットおよび各国の統計機関により調整統計手法によってまとめられる。ECBは、HICPが発表する中期に渡る年間インフレ率を2%弱に保つことを目標としている。',
  'mid-autumn_festival.description:ja:South Korea': '',
  'mid-autumn_festival.description:ja:China': '',
  'michigan_inflation_expectations_final.description:ja:DEFAULT': '',
  'michigan_inflation_expectations_final.description:ja:United States':
    '消費者信頼感指数は、（1）消費者自身の財務状況の先行き見通し、（2）目先の経済全体に関する先行き見通し、（3）長期的な経済全体に関する先行き見通しの3つの分野に注目するもので、毎月実施されるアンケート調査には50項目の中核的な設問が含まれ、それぞれの設問が消費者の態度と予想を追跡する。消費者調査のサンプルは統計的にアラスカ州とハワイ州を除くすべての米国世帯を代表するように設計され、毎月500人以上に電話でインタビューが実施される。',
  'fiscal_year_gdp_growth_final.description:ja:DEFAULT': '',
  'fiscal_year_gdp_growth_final.description:ja:India':
    'インド経済の最大かつ最も急速な成長を遂げている部門はサービス業であり、その主要項目には、商業・ホテル・輸送・通信、金融・保険・不動産・ビジネスサービス、地域・社会・個人サービスがある。サービス業全体がGDPに占める割合は60%を超える一方、農林水産業の割合は約12%に過ぎないが、これが労働人口の50%超を抱える。製造業はGDPの15%、建設業は8%、鉱業・採石業・電力・ガス・水道は残りの5%を占める。',
  'michigan_consumer_sentiment_prel.description:ja:DEFAULT': '',
  'michigan_consumer_sentiment_prel.description:ja:United States':
    '消費者信頼感指数は、（1）消費者自身の財務状況の先行き見通し、（2）目先の経済全体に関する先行き見通し、（3）長期的な経済全体に関する先行き見通しの3つの分野に注目するもので、毎月実施されるアンケート調査には50項目の中核的な設問が含まれ、それぞれの設問が消費者の態度と予想を追跡する。消費者調査のサンプルは統計的にアラスカ州とハワイ州を除くすべての米国世帯を代表するように設計され、毎月500人以上に電話でインタビューが実施される。',
  'wasde_report.description:ja:DEFAULT': '',
  'wasde_report.description:ja:United States': '',
  'gdp_deflator_yoy_prel.description:ja:DEFAULT': '',
  'gdp_deflator_yoy_prel.description:ja:Japan':
    'GDPデフレーターは、最終財とサービスの価格変化を測るものであり、将来の金融政策の方向性に影響するインフレ圧力の主要指標であるとされている。',
  'finance_minister_aso_speech.description:ja:DEFAULT': '',
  'finance_minister_aso_speech.description:ja:Japan': '',
  'michigan_consumer_sentiment_final.description:ja:DEFAULT': '',
  'michigan_consumer_sentiment_final.description:ja:United States':
    '消費者信頼感指数は、（1）消費者自身の財務状況の先行き見通し、（2）目先の経済全体に関する先行き見通し、（3）長期的な経済全体に関する先行き見通しの3つの分野に注目するもので、毎月実施されるアンケート調査には50項目の中核的な設問が含まれ、それぞれの設問が消費者の態度と予想を追跡する。消費者調査のサンプルは統計的にアラスカ州とハワイ州を除くすべての米国世帯を代表するように設計され、毎月500人以上に電話でインタビューが実施される。',
  'michigan_consumer_expectations_final.description:ja:DEFAULT': '',
  'michigan_consumer_expectations_final.description:ja:United States':
    '消費者信頼感指数は、（1）消費者自身の財務状況の先行き見通し、（2）目先の経済全体に関する先行き見通し、（3）長期的な経済全体に関する先行き見通しの3つの分野に注目するもので、毎月実施されるアンケート調査には50項目の中核的な設問が含まれ、それぞれの設問が消費者の態度と予想を追跡する。消費者調査のサンプルは統計的にアラスカ州とハワイ州を除くすべての米国世帯を代表するように設計され、毎月500人以上に電話でインタビューが実施される。',
  'michigan_5_year_inflation_expectations_prel.description:ja:DEFAULT': '',
  'michigan_5_year_inflation_expectations_prel.description:ja:United States':
    '消費者信頼感指数は、（1）消費者自身の財務状況の先行き見通し、（2）目先の経済全体に関する先行き見通し、（3）長期的な経済全体に関する先行き見通しの3つの分野に注目するもので、毎月実施されるアンケート調査には50項目の中核的な設問が含まれ、それぞれの設問が消費者の態度と予想を追跡する。消費者調査のサンプルは統計的にアラスカ州とハワイ州を除くすべての米国世帯を代表するように設計され、毎月500人以上に電話でインタビューが実施される。',
  'michigan_5_year_inflation_expectations_final.description:ja:DEFAULT': '',
  'michigan_5_year_inflation_expectations_final.description:ja:United States':
    '消費者信頼感指数は、（1）消費者自身の財務状況の先行き見通し、（2）目先の経済全体に関する先行き見通し、（3）長期的な経済全体に関する先行き見通しの3つの分野に注目するもので、毎月実施されるアンケート調査には50項目の中核的な設問が含まれ、それぞれの設問が消費者の態度と予想を追跡する。消費者調査のサンプルは統計的にアラスカ州とハワイ州を除くすべての米国世帯を代表するように設計され、毎月500人以上に電話でインタビューが実施される。',
  'household_consumption_yoy.description:ja:DEFAULT': '',
  'household_consumption_yoy.description:ja:Netherlands':
    'オランダでは、家計消費前年比は、家計による消費支出の前年同比を測定する。消費支出には、個人や家計による財やサービスに対するすべての任意支出が含まれている。税金などの強制的な支出は含まれていない。サービス業は最も重要なセクターであり、消費全体の58％を占めている。 サービスの中で最も大きな分野は、住宅、金融・ビジネスサービス、輸送・通信サービス、医療・福祉である。 残りの42％は物品への消費で、特に耐久消費財（15％）と食品・飲料・タバコ（15％）の割合が大きい。',
  'memorial_day_holiday.description:ja:DEFAULT': '',
  'memorial_day_holiday.description:ja:United States': '',
  'boj_deputy_gov_nakaso_speech.description:ja:DEFAULT': '',
  'boj_deputy_gov_nakaso_speech.description:ja:Japan': '',
  'business_inventories_qoq.description:ja:DEFAULT': '',
  'business_inventories_qoq.description:ja:Australia':
    'オーストラリアの企業在庫では、製造業者、小売業者、および卸売業者の在庫の四半期ごとの変化率を測定する。 在庫は国内総生産の重要な構成要素である。.',
  'memorial_day.description:ja:DEFAULT': '',
  'memorial_day.description:ja:South Korea': '',
  'memorial_day.description:ja:Israel': '',
  'memorial_day.description:ja:United States': '',
  'housing_starts_s_a_yoy.description:ja:DEFAULT': '',
  'housing_starts_s_a_yoy.description:ja:Canada': '',
  'ramadan_feast.description:ja:DEFAULT': '',
  'ramadan_feast.description:ja:India': '',
  'goods_trade_balance_adv.description:ja:DEFAULT': '',
  'goods_trade_balance_adv.description:ja:United States':
    '米国では、財の輸出額から財の輸入額を差し引いたものが財貿易収支である。',
  'core_inflation_rate_yoy_final.description:ja:DEFAULT': '',
  'core_inflation_rate_yoy_final.description:ja:Euro Area':
    'ユーロ圏のコア・インフレ率は、価格変動の激しいエネルギー、食品、アルコール、タバコを除いた総合消費者物価指数（HICP）の加重平均を用いて算出される。',
  'may_day.description:ja:DEFAULT': '',
  'may_day.description:ja:China': '',
  'may_day.description:ja:Czech Republic': '',
  'may_day.description:ja:Ireland': '',
  'may_day.description:ja:United Kingdom': '',
  'may_day.description:ja:Finland': '',
  'fed_evans_speaks.description:ja:DEFAULT': '',
  'fed_evans_speaks.description:ja:United States': '',
  'ura_property_index_qoq.description:ja:DEFAULT': '',
  'ura_property_index_qoq.description:ja:Singapore':
    'シンガポールでは、住宅指数は総合住宅不動産価格指数によって測定される。',
  'may_bank_holiday.description:ja:DEFAULT': '',
  'may_bank_holiday.description:ja:Ireland': '',
  'westpac_leading_index.description:ja:DEFAULT': '',
  'westpac_leading_index.description:ja:Australia': '',
  'may_1st.description:ja:DEFAULT': '',
  'may_1st.description:ja:Sweden': '',
  'mass_layoffs.description:ja:DEFAULT': '',
  'mass_layoffs.description:ja:United States': '',
  'services_sentiment.description:ja:DEFAULT': '',
  'services_sentiment.description:ja:Euro Area':
    'ユーロ圏では、サービス業の景況感によって、サービス企業の信頼感の水準を測定する。これは景気動向指標の30％を占めている。',
  'martin_l_king_s_birthday.description:ja:DEFAULT': '',
  'martin_l_king_s_birthday.description:ja:United States': '',
  'markit_jmma_manufacturing_pmi_flash.description:ja:DEFAULT': '',
  'markit_jmma_manufacturing_pmi_flash.description:ja:Japan':
    'じぶん銀行日本製造業購買担当者景況指数（PMI）®は、約400社の製造業企業の購買管理者に送信されたアンケート調査の結果をIHSマークイットが毎月集計するもので、対象企業はGDPへの寄与度に基づき、詳細セクターと企業の社員数によって階層化さている。アンケートの回答は前月に比べた変化の方向を示し、各調査変数に関してディフュージョンインデックス（DI）が算出される。同指数は「改善」と回答した割合（％）に、「横ばい」と回答した割合の半分を加算して算出する。指数の値は0～50の間で、50を超える場合は全体的に前月からの改善・増加を、50未満の場合は悪化・減少を表す。このPMIは、新規受注（50％）、生産（25％）、雇用（20％）、サプライヤー納期（15％）、購買品在庫（10％）の5つの指数の加重平均である。PMIの算出においてはサプライヤー納期インデックスは他のインデックスと同じ方向に動くよう、符号を反転させている。',
  'general_affairs_council_meeting.description:ja:DEFAULT': '',
  'general_affairs_council_meeting.description:ja:European Union': '',
  'battle_of_puebla.description:ja:DEFAULT': '',
  'battle_of_puebla.description:ja:Mexico': '',
  'labor_day.description:ja:DEFAULT': '',
  'labor_day.description:ja:New Zealand': '',
  'labor_day.description:ja:Austria': '',
  'labor_day.description:ja:Hong Kong': '',
  'labor_day.description:ja:Greece': '',
  'labor_day.description:ja:Taiwan': '',
  'labor_day.description:ja:South Korea': '',
  'labor_day.description:ja:Singapore': '',
  'labor_day.description:ja:Thailand': '',
  'labor_day.description:ja:Spain': '',
  'labor_day.description:ja:Czech Republic': '',
  'labor_day.description:ja:Canada': '',
  'labor_day.description:ja:Indonesia': '',
  'labor_day.description:ja:Italy': '',
  'labor_day.description:ja:United States': '',
  'labor_day.description:ja:Brazil': '',
  'labor_day.description:ja:Philippines': '',
  'labor_day.description:ja:Colombia': '',
  'labor_day.description:ja:Portugal': '',
  'labor_day.description:ja:China': '',
  'labor_day.description:ja:Belgium': '',
  'labor_day.description:ja:France': '',
  'labor_day.description:ja:Norway': '',
  'labor_day.description:ja:Mexico': '',
  'labor_day.description:ja:Poland': '',
  'labor_day.description:ja:Germany': '',
  'labor_day.description:ja:Hungary': '',
  'employment_growth_yoy.description:ja:DEFAULT': '',
  'employment_growth_yoy.description:ja:Poland':
    'ポーランドでは、雇用成長率は企業部門の雇用成長率の年間変化を指し、9人以上の従業員を雇用し、以下の分野で経済活動を行っている部門を対象とする：林業・伐採業、海洋漁業、鉱業・採石業、製造業、電気・ガス・蒸気・空調供給業、水道業、下水道業、廃棄物処理・除染業、建設業、卸売・小売業、自動車・オートバイの修理業、輸送・保管業、宿泊・飲食サービス業、情報通信業、不動産業、法律・会計活動、本社の活動、経営コンサルタント活動、建築・エンジニアリング活動、技術試験・分析、広告・市場調査、その他の専門的・科学的・技術的活動、管理・支援サービス活動、芸術・娯楽・レクリエーション、コンピュータおよび個人・家庭用品の修理、その他の個人向けサービス活動。',
  'commbank_manufacturing_pmi_final.description:ja:DEFAULT': '',
  'commbank_manufacturing_pmi_final.description:ja:Australia':
    'IHSマークイット製造業PMIは、オーストラリアの民間製造業400社以上の購買担当幹部を代表するパネルに送られたアンケートへの毎月の回答を編纂したデータに基づいている。このパネルは、GDPと企業の従業員規模によって階層に分けられている。製造業は以下の9つのカテゴリーに分類される：食品・飲料、繊維・衣料、木材・紙、化学・プラスチック・ゴム、金属・金属製品、電子・電気機器、機械・装置、輸送機器、その他製造業。数値が50を超えると、製造業が前月に比べて拡大していることを示し、50を下回ると縮小、50は変化がないことを示す。',
  'markit_jmma_manufacturing_pmi_final.description:ja:DEFAULT': '',
  'markit_jmma_manufacturing_pmi_final.description:ja:Japan':
    'じぶん銀行日本製造業購買担当者景況指数（PMI）®は、約400社の製造業企業の購買管理者に送信されたアンケート調査の結果をIHSマークイットが毎月集計するもので、対象企業はGDPへの寄与度に基づき、詳細セクターと企業の社員数によって階層化さている。アンケートの回答は前月に比べた変化の方向を示し、各調査変数に関してディフュージョンインデックス（DI）が算出される。同指数は「改善」と回答した割合（％）に、「横ばい」と回答した割合の半分を加算して算出する。指数の値は0～50の間で、50を超える場合は全体的に前月からの改善・増加を、50未満の場合は悪化・減少を表す。このPMIは、新規受注（50％）、生産（25％）、雇用（20％）、サプライヤー納期（15％）、購買品在庫（10％）の5つの指数の加重平均である。PMIの算出においてはサプライヤー納期インデックスは他のインデックスと同じ方向に動くよう、符号を反転させている。',
  'markit_jmma_manufacturing_pmi.description:ja:DEFAULT': '',
  'markit_jmma_manufacturing_pmi.description:ja:Japan':
    'じぶん銀行日本製造業購買担当者景況指数（PMI）®は、約400社の製造業企業の購買管理者に送信されたアンケート調査の結果をIHSマークイットが毎月集計するもので、対象企業はGDPへの寄与度に基づき、詳細セクターと企業の社員数によって階層化さている。アンケートの回答は前月に比べた変化の方向を示し、各調査変数に関してディフュージョンインデックス（DI）が算出される。同指数は「改善」と回答した割合（％）に、「横ばい」と回答した割合の半分を加算して算出する。指数の値は0～50の間で、50を超える場合は全体的に前月からの改善・増加を、50未満の場合は悪化・減少を表す。このPMIは、新規受注（50％）、生産（25％）、雇用（20％）、サプライヤー納期（15％）、購買品在庫（10％）の5つの指数の加重平均である。PMIの算出においてはサプライヤー納期インデックスは他のインデックスと同じ方向に動くよう、符号を反転させている。',
  'markit_cips_uk_services_pmi_flash.description:ja:DEFAULT': '',
  'markit_cips_uk_services_pmi_flash.description:ja:United Kingdom':
    'マークイット/CIPS英国サービス業購買担当者景気指数（PMI）は、英国の輸送および通信業、金融仲介業、ビジネスサービス業、個人向けサービス業、コンピューティングおよびIT業、ホテルおよびレストラン業から収集したデータを基に集計される。指数は、売上高、雇用、在庫、価格などの変数を追跡し、数値が50を超えれば、サービス業が全体的に拡大傾向にあることを示し、50を下回れば、全般に縮小傾向にあることを示す。。',
  'api_weekly_gasoline_stock_change.description:ja:DEFAULT': '',
  'api_weekly_gasoline_stock_change.description:ja:United States': '',
  'markit_cips_uk_services_pmi_final.description:ja:DEFAULT': '',
  'markit_cips_uk_services_pmi_final.description:ja:United Kingdom':
    'マークイット/CIPS英国サービス業購買担当者景気指数（PMI）は、英国の輸送および通信業、金融仲介業、ビジネスサービス業、個人向けサービス業、コンピューティングおよびIT業、ホテルおよびレストラン業から収集したデータを基に集計される。指数は、売上高、雇用、在庫、価格などの変数を追跡し、数値が50を超えれば、サービス業が全体的に拡大傾向にあることを示し、50を下回れば、全般に縮小傾向にあることを示す。。',
  'mps_vote_on_brexit_bill.description:ja:DEFAULT': '',
  'mps_vote_on_brexit_bill.description:ja:United Kingdom': '',
  'fed_labor_market_conditions_index_mom.description:ja:DEFAULT': '',
  'fed_labor_market_conditions_index_mom.description:ja:United States': '',
  'raw_materials_price_index_mom.description:ja:DEFAULT': '',
  'raw_materials_price_index_mom.description:ja:Canada': '',
  'markit_bme_manufacturing_pmi_flash.description:ja:DEFAULT': '',
  'markit_bme_manufacturing_pmi_flash.description:ja:Germany':
    'マークイット/BMEドイツ製造業購買担当者景気指数（PMI）は製造業500社の調査に基づき、製造業の業況を測定し、それぞれウエート付けされた、新規受注（30%）、生産（25%）、雇用（20%）、サプライヤー納期（15%）、購買品在庫（10%）という5つの個別インデックスから構成され、納期インデックスは他のインデックスと同じ方向に動くよう符号が反転される。50を超える指数は製造業セクターが前期に比べて拡大していることを、50を切る指数は縮小していることを、50は変化がないことを示す。',
  'markit_adaci_manufacturing_pmi.description:ja:DEFAULT': '',
  'markit_adaci_manufacturing_pmi.description:ja:Italy':
    'イタリアでは、マークイット・イタリア製造業購買担当者指数は、製造業部門の業績を測定するもので、産業界の企業400社を対象とした調査に基づいている。製造業購買担当者指数は、以下の重み付けをした5つの個別指数：新規注文（30％）、生産（25％）、雇用（20％）、サプライヤー納期（15％）、購入品在庫（10％）の5つの個別のインデックスに基づいており、納期の指数は比較可能な方向に動くように反転されている。指数が50を超えると前月比で拡大、50を下回ると縮小、50は変化なしを意味する。',
  'ifo_business_climate_prel.description:ja:DEFAULT': '',
  'ifo_business_climate_prel.description:ja:Germany':
    'ドイツでは、Ifo企業景況感指数によって、現状と向こう6カ月間の先行きにについて企業の景況感を測定する。この調査は電話で行われ、調査対象は製造業、サービス業、貿易業及び建設業の9,000企業。楽観的な上級経営陣と悲観的な経営陣の比率の差が、マイナス100（全回答者が現状を「悪い」と評価し、今後悪化を予想）から＋100（全回答者が現状を「良い」と評価し、今後改善を予想）までの企業景況感バランス値として算出され、それが基準年（現行は2015年）の平均値に標準化されてIfo企業景況感指数が算出される。',
  'markit_services_pmi_prel.description:ja:DEFAULT': '',
  'markit_services_pmi_prel.description:ja:United States': '',
  'h_m_the_queen_s_birthday.description:ja:DEFAULT': '',
  'h_m_the_queen_s_birthday.description:ja:Thailand': '',
  'boi_annual_report.description:ja:DEFAULT': '',
  'boi_annual_report.description:ja:Italy': '',
  'markit_services_pmi_flash.description:ja:DEFAULT': '',
  'markit_services_pmi_flash.description:ja:Australia':
    'IHSマークイットサービス業PMIは、オーストラリアの民間サービス業400社以上の購買担当幹部を代表するパネルに送られたアンケートへの毎月の回答を編纂したデータに基づいている。このパネルは、GDPと企業の従業員規模によって階層に分けられている。サービス業は以下の５つのカテゴリーに分類される：輸送・倉庫、消費者サービス、情報・通信、金融・保険、不動産・ビジネスサービス。数値が50を超えると、製造業が前月に比べて拡大していることを示し、50を下回ると縮小、50は変化がないことを示す。',
  'markit_services_pmi_flash.description:ja:Germany':
    'ドイツのサービス業PMI（購買担当者景気指数）は、ドイツのサービス業を代表する500社を超える企業のパネルから収集したデータを基に集計される。この指数は、売上高、雇用、在庫、価格などの変数を追跡し、数値が50を超えれば、サービス業が全体的に拡大傾向にあることを示し、50を下回れば、全般に縮小傾向にあることを示す。',
  'markit_services_pmi_flash.description:ja:France':
    'フランスのサービス業PMI（購買担当者景気指数）は、フランスのサービス業を代表する約400社のパネルから収集したデータを基に集計される。この指数は、売上高、雇用、在庫、価格などの変数を追跡し、数値が50を超えれば、サービス業が全体的に拡大傾向にあることを示し、50を下回れば、全般に縮小傾向にあることを示す。',
  'markit_services_pmi_flash.description:ja:Euro Area':
    'ユーロ圏のサービス業PMI（購買担当者景気指数）は、サービス業を代表する約2.000社の民間企業のパネルから収集したデータを基に集計される。ドイツ、フランス、イタリア、スペイン、アイルランドの全国サービス業データが含まれる。この指数は、売上高、雇用、在庫、価格などの変数を追跡し、数値が50を超えれば、サービス業が全体的に拡大傾向にあることを示し、50を下回れば、全般に縮小傾向にあることを示す。',
  'markit_services_pmi_flash.description:ja:United States':
    'マークイット米国サービス業PMI（購買担当者景気指数）は、米国のサービス業を代表する400社を超える民間企業のパネルから収集したデータを基に集計され、輸送・通信、金融仲介、ビジネスサービス・個人サービス、コンピューター・IT、ホテル・レストラン部門をカバーしている。この指数は、売上高、雇用、在庫、価格などの変数を追跡し、数値が50を超えれば、サービス業が全体的に拡大傾向にあることを示し、50を下回れば、全般に縮小傾向にあることを示す。',
  'markit_services_pmi.description:ja:DEFAULT': '',
  'markit_services_pmi.description:ja:Russia':
    'マークイット・ロシアのサービス業購買担当者景気指数（PMI）は、約300社の民間企業の上級購買担当者から収集したアンケート調査の回答を基に集計され、売上高、雇用、在庫、価格などの変数を追跡し、指数が50を超えればサービス業が全体的に拡大していることを、50を切れば全体的に縮小していることを示す。',
  'markit_services_pmi.description:ja:Brazil':
    'マークイット・ブラジルのサービス業購買担当者景気指数(PMI)は、民間サービス業の企業約350社の購買担当役員を対象に毎月実施されるアンケートの回答データに基づいて算出される。この指数は、売上高、雇用、在庫、価格などの変数を追跡している。指数が50を超えると、その変数が全体的に増加していることを示し、50を下回ると全体的に減少していることを示す。',
  'markit_services_pmi.description:ja:Sweden': '',
  'markit_services_pmi.description:ja:Germany':
    'ドイツのサービス業PMI（購買担当者景気指数）は、ドイツのサービス業を代表する500社を超える企業のパネルから収集したデータを基に集計される。この指数は、売上高、雇用、在庫、価格などの変数を追跡し、数値が50を超えれば、サービス業が全体的に拡大傾向にあることを示し、50を下回れば、全般に縮小傾向にあることを示す。',
  'markit_services_pmi.description:ja:India':
    'IHSマークイット・インドのサービス業購買担当者景気指数（PMI）は、約350社の民間企業の購買担当幹部から収集したアンケート調査の回答を基に集計され、売上高、雇用、在庫、価格などの変数を追跡する。指数が50を超えれば全体的にサービス業が拡大していることを、50を切れば縮小していることを示す。',
  'markit_services_pmi.description:ja:United Kingdom': '',
  'markit_services_pmi.description:ja:Japan':
    '日本のサービス業（PMI）は、日本の民間サービス業を代表する400社を超える企業のパネルから収集したデータを基に集計される。指数は、売上高、雇用、在庫、価格などの変数を追跡し、50を超えれば、サービス業が全体的に拡大傾向にあることを示し、50を下回れば、全般に縮小傾向にあることを示す。',
  'markit_services_pmi.description:ja:France':
    'フランスのサービス業PMI（購買担当者景気指数）は、フランスのサービス業を代表する約400社のパネルから収集したデータを基に集計される。この指数は、売上高、雇用、在庫、価格などの変数を追跡し、数値が50を超えれば、サービス業が全体的に拡大傾向にあることを示し、50を下回れば、全般に縮小傾向にあることを示す。',
  'markit_services_pmi.description:ja:Euro Area': '',
  'markit_services_pmi.description:ja:Spain':
    'マークイット・スペインのサービス業購買担当者景気指数(PMI)は、サービス業を代表する300社以上から成るパネルから収集したデータに基づいている。この指数は、売上高、雇用、在庫、価格などの変数を追跡している。この指数が50を超えると、サービス業が全般的に拡大していることを示し、50を下回ると、全般的に減少していることを示す。',
  'markit_services_pmi.description:ja:Ireland': '',
  'markit_manufacturing_pmi_flash.description:ja:DEFAULT': '',
  'markit_manufacturing_pmi_flash.description:ja:France':
    'フランスでは、マークイット製造業購買担当者景気指数（PMI）は製造業400社の調査に基づき、製造業の業況を測定し、それぞれウエート付けされた、新規受注（30%）、生産（25%）、雇用（20%）、サプライヤー納期（15%）、購買品在庫（10%）という5つの個別インデックスから構成され、納期インデックスは他のインデックスと同じ方向に動くよう符号が反転される。50を超える指数は製造業セクターが前期に比べて拡大していることを、50を切る指数は縮小していることを、50は変化がないことを示す。',
  'pending_home_sales_mom.description:ja:DEFAULT': '',
  'pending_home_sales_mom.description:ja:United States': '',
  'health-sports_day.description:ja:DEFAULT': '',
  'health-sports_day.description:ja:Japan': '',
  'fed_duke_speech.description:ja:DEFAULT': '',
  'fed_duke_speech.description:ja:United States': '',
  'markit_manufacturing_pmi.description:ja:Spain':
    'スペインでは、マークイット製造業購買担当者指数は、製造業部門の業績を測定するもので、産業界の企業400社を対象とした調査に基づいている。製造業購買担当者指数は、以下の重み付けをした以下5つの個別インデックス－新規受注（30％）、生産（25％）、雇用（20％）、サプライヤー納期（15％）、購入品在庫（10%）に基づいており、納期の指数は比較可能な方向に動くように反転させる。 製造業部門の指数が50を超えると前月比で拡大、50を下回ると縮小、50は変化なしを意味する。',
  'markit_manufacturing_pmi_flash.description:ja:Australia':
    'IHSマークイット製造業PMIは、オーストラリアの民間製造業400社以上の購買担当幹部を代表するパネルに送られたアンケートへの毎月の回答を編纂したデータに基づいている。このパネルは、GDPと企業の従業員規模によって階層に分けられている。製造業は以下の9つのカテゴリーに分類される：食品・飲料、繊維・衣料、木材・紙、化学・プラスチック・ゴム、金属・金属製品、電子・電気機器、機械・装置、輸送機器、その他製造業。数値が50を超えると、製造業が前月に比べて拡大していることを示し、50を下回ると縮小、50は変化がないことを示す。',
  'markit_manufacturing_pmi_flash.description:ja:Germany':
    'マークイット/BMEドイツ製造業購買担当者景気指数（PMI）は製造業500社の調査に基づき、製造業の業況を測定し、それぞれウエート付けされた、新規受注（30%）、生産（25%）、雇用（20%）、サプライヤー納期（15%）、購買品在庫（10%）という5つの個別インデックスから構成され、納期インデックスは他のインデックスと同じ方向に動くよう符号が反転される。50を超える指数は製造業セクターが前期に比べて拡大していることを、50を切る指数は縮小していることを、50は変化がないことを示す。',
  'markit_manufacturing_pmi_flash.description:ja:Euro Area':
    'ユーロ圏では、マークイット製造業購買担当者景気指数（PMI）は製造業3.000社の調査に基づき、製造業の業況を測定する。ドイツ、フランス、イタリア、スペイン、オーストリア、オランダ、ギリシャ、アイルランドの全国データが含まれており、これらの国を合わせると、ユーロ圏製造業の90％を占めると推定される。製造業PMI製造業は、それぞれウエート付けされた、新規受注（30%）、生産高（25%）、雇用（20%）、サプライヤー納期（15%）、購買品在庫（10%）という5つの個別インデックスから構成され、納期インデックスは他のインデックスと同じ方向に動くよう符号が反転される。50を超える指数は製造業セクターが前期に比べて拡大していることを、50を切る指数は縮小していることを、50は変化がないことを示す。',
  'markit_manufacturing_pmi_flash.description:ja:United States':
    '米国では、マークイット製造業購買担当者景気指数（PMI）は製造業600社の調査に基づき、製造業の業況を測定し、それぞれウエート付けされた、新規受注（30%）、生産（25%）、雇用（20%）、サプライヤー納期（15%）、購買品在庫（10%）という5つの個別インデックスから構成され、納期インデックスは他のインデックスと同じ方向に動くよう符号が反転される。50を超える指数は製造業セクターが前期に比べて拡大していることを、50を切る指数は縮小していることを、50は変化がないことを示す。',
  'milad-un-nabi_holy_prophet_s_birthday.description:ja:DEFAULT': '',
  'milad-un-nabi_holy_prophet_s_birthday.description:ja:Indonesia': '',
  'fed_yellen_testimony_sbc.description:ja:DEFAULT': '',
  'fed_yellen_testimony_sbc.description:ja:United States': '',
  'markit_composite_pmi_flash.description:ja:DEFAULT': '',
  'markit_composite_pmi_flash.description:ja:Australia':
    'オーストラリアのIHSマークイット・オーストラリア総合PMI生産指数は、コモンウェルス銀行製造業生産指数とコモンウェルス銀行サービス業事業活動指数のGDP加重平均値である。この指数は、オーストラリアの民間部門の経済全体における企業活動の変化を追跡する。この指数が50を超えると企業活動が拡大していることを、50を下回ると縮小していることを示す。',
  'markit_composite_pmi_flash.description:ja:Germany':
    'ドイツの総合PMI生産指数は、製造業とサービス業全体の景気動向を追跡する。この指数は両セクターを代表する1,000社（製造業50%、サービス業50%）を超える企業のパネルから収集したデータを基に集計され、売上高、新規受注、雇用、在庫、価格などの変数の動きを追う。数値が50を超えれば、企業活動が拡大傾向にあることを示し、50を下回れば全般に縮小傾向にあることを示す。',
  'markit_composite_pmi_flash.description:ja:France':
    'フランスのマークイット総合購買担当者景気指数（PMI）生産指数は、民間セクターを代表する約800社の企業のパネルから収集したデータを基に、民間セクター全体の景気動向を追跡する。指数は売上高、新規受注、雇用、在庫、価格などの変数の動きを追い、数値が50を超えれば、企業活動が拡大傾向にあることを示し、50を下回れば全般に縮小傾向にあることを示す。',
  'markit_composite_pmi_flash.description:ja:Euro Area':
    'ユーロ圏のマークイット総合購買担当者景気指数（PMI）生産指数は、5,000社（製造業60%、サービス業40%）を超える企業のパネルから収集したデータを基に、製造業とサービス業全体の景気動向を追跡する。指数は売上高、新規受注、雇用、在庫、価格などの変数の動きを追い、ドイツ、フランス、イタリア、スペイン、オーストリア、オランダ、ギリシャ、アイルランドの全国データが含まれている。数値が50を超えれば、企業活動が拡大傾向にあることを示し、50を下回れば全般に縮小傾向にあることを示す。',
  'markit_composite_pmi_flash.description:ja:United States':
    '米国のマークイット総合購買担当者景気指数（PMI）生産指数は、製造業とサービス業全体の景気動向を追跡する（製造業60%、サービス業40%）。この指数は両セクターを代表する1,000社を超える企業のパネルから収集したデータを基に集計され、売上高、新規受注、雇用、在庫、価格などの変数の動きを追う。数値が50を超えれば、企業活動が拡大傾向にあることを示し、50を下回れば縮小傾向にあることを示す。',
  'mark_carney_will_become_the_new_bank_of_england_governor.description:ja:DEFAULT': '',
  'mark_carney_will_become_the_new_bank_of_england_governor.description:ja:United Kingdom': '',
  'marginal_lending_rate.description:ja:DEFAULT': '',
  'marginal_lending_rate.description:ja:Euro Area':
    'ユーロ圏では、限界貸付制度は、夜間の流動性を提供し、金融政策の全般的なスタンスを示すことを目的としており、通常、翌日物市場金利の上限となる。この制度は、一定の運用アクセス条件を満たすことを条件に、適格なカウンターパーティが自らの意思で利用できる。カウンターパーティは、限界貸付制度を利用して、適格資産を担保に各国中央銀行から夜間流動性を得ることができる。通常の場合、カウンターパーティがこの方法を利用する際には、十分な原資産を提示しなければならないことを除けば、与信限度やその他の制限はない。限界貸付制度は、各国の中央銀行によって、分散して管理されている。',
  'parliamentary_elections.description:ja:DEFAULT': '',
  'parliamentary_elections.description:ja:Switzerland': '',
  'parliamentary_elections.description:ja:Finland': '',
  'parliamentary_elections.description:ja:Belgium': '',
  'parliamentary_elections.description:ja:Denmark': '',
  'parliamentary_elections.description:ja:Hungary': '',
  'parliamentary_elections.description:ja:Japan': '',
  'parliamentary_elections.description:ja:Mexico': '',
  'parliamentary_elections.description:ja:France': '',
  'parliamentary_elections.description:ja:Canada': '',
  'parliamentary_elections.description:ja:Portugal': '',
  'parliamentary_elections.description:ja:Greece': '',
  'parliamentary_elections.description:ja:United Kingdom': '',
  'parliamentary_elections.description:ja:Poland': '',
  'parliamentary_elections.description:ja:Spain': '',
  'parliamentary_elections.description:ja:Ireland': '',
  'parliamentary_elections.description:ja:Russia': '',
  'parliamentary_elections.description:ja:Singapore': '',
  'parliamentary_elections.description:ja:Netherlands': '',
  's_p_case-shiller_home_price_mom.description:ja:DEFAULT': '',
  's_p_case-shiller_home_price_mom.description:ja:United States':
    'S&P コアロジック・ケース・シラー20都市総合住宅価格指数は、アメリカの20都市（アトランタ、ボストン、シャーロット、シカゴ、クリーブランド、ダラス、デンバー、デトロイト、ラスベガス、ロサンゼルス、マイアミ、ミネアポリス、ニューヨーク、フェニックス、ポートランド、サンディエゴ、サンフランシスコ、シアトル、タンパ、ワシントンDC）の住宅価格の変化を測定したものである。',
  'manufacturing_shipments_yoy.description:ja:DEFAULT': '',
  'manufacturing_shipments_yoy.description:ja:Canada': '',
  'presidential_election_results.description:ja:DEFAULT': '',
  'presidential_election_results.description:ja:United States': '',
  'revolution_day.description:ja:DEFAULT': '',
  'revolution_day.description:ja:Mexico': '',
  'revolution_day.description:ja:Hungary': '',
  'finance_minister_schelling_speech.description:ja:DEFAULT': '',
  'finance_minister_schelling_speech.description:ja:Austria': '',
  'bcc_quarterly_economic_survey.description:ja:DEFAULT': '',
  'bcc_quarterly_economic_survey.description:ja:United Kingdom': '',
  'manufacturing_shipments_mom.description:ja:DEFAULT': '',
  'manufacturing_shipments_mom.description:ja:Canada': '',
  'manufacturing_sales_mom_final.description:ja:DEFAULT': '',
  'manufacturing_sales_mom_final.description:ja:Canada':
    'カナダでは、製造業売上高はカナダの製造業セクターによる製品売上高の特定時点での状況（スナップショット）であり、中・短期間におけるカナダ経済の状況と特定業界の状況の分析が可能である。',
  'manufacturing_production_yoy.description:ja:DEFAULT': '',
  'manufacturing_production_yoy.description:ja:New Zealand':
    'ニュージーランドでは製造業売上高は、同国経済の製造業セクターにおける売上高の前年比変化を示す。この指標には、（1）肉類・乳製品、（2）水産加工品、（3）果物・油・シリアル・その他食品、（3）飲料・タバコ、（5）繊維・革・衣類・履物、（6）材木・紙製品、（7）印刷、（8）石油・石炭製品、（9）化学品・ポリマー、ゴム製品、（10）非金属鉱物、（11）金属製品、（12）輸送機器・機械・装置および家具の12業界が含まれる。',
  'manufacturing_production_yoy.description:ja:Netherlands':
    'オランダでは、製造業の中で最も大きな分野は、製油・化学（総生産の23％）、電子機器・機械（22％）、食品・飲料（20％）、卑金属・卑金属製品（12％）、輸送機器（5％）、紙・印刷（5％）、木材・建築材料（4％）、繊維・皮革製品（2％）である。',
  'manufacturing_production_yoy.description:ja:Norway':
    'ノルウェーでは、製造業が総生産の27％を占めており、主な内訳は、食品、飲料、タバコ（5％）、石油精製品、化学品、医薬品（4％）、機械・設備（3％）、金属加工品（2％）、船舶、ボート、石油プラットフォーム（2％）、コンピュータ・電気機器（2％）、機械の修理・設置（2％）、ゴム・プラスチック・鉱物製品（2％）、卑金属（2％）となっている。',
  'manufacturing_production_yoy.description:ja:South Korea':
    '製造業は韓国GDPの3分の1もの割合を占め、チェボル（財閥）として知られる産業コングロマリットが支配的な存在となっている。',
  'manufacturing_production_yoy.description:ja:United States':
    '製造業生産は、製造業を営む企業の生産を測定するものである。製造業は最も重要な部門であり、全生産量の78％を占めている。製造業の中で最も大きな分野は以下の通りである。化学製品（総生産の12％）、食品、飲料、タバコ（11％）、機械（6％）、金属加工品（6％）、コンピュータおよび電子製品（6％）、自動車およびその部品（6％）。',
  'manufacturing_production_yoy.description:ja:South Africa':
    '南アフリカでは鉱工業生産は製造業、鉱業、公益事業など経済の鉱工業セクターに属する事業の生産量を測定する。',
  'manufacturing_production_yoy.description:ja:United Kingdom':
    '製造業生産は、製造業を営む企業の生産を測定するものである。製造業は英国で最も重要な部門であり、全生産の70％を占めている。製造業の中で最も大きな分野は以下の通りである。食品、飲料、タバコ（総生産の11％）、輸送機器（9％）、卑金属（8％）、その他の製造業および修理（6％）、医薬品および調剤（6％）、ゴム・プラスチック製品および非金属鉱物製品（6％）。',
  'manufacturing_production_yoy.description:ja:India':
    '製造業生産は、製造業を営む企業の生産を測定するものである。インドでは、製造業が工業生産全体の78％を占めている。主な産業分野は、卑金属（総生産の13%）、コークスおよび石油精製品（12%）、化学品および化学製品（8%）、食品（5%）、医薬品、薬用化学品および植物製品（5%）、自動車、トレーラーおよびセミトレーラー（5%）、その他機械および装置（5%）、その他の非金属鉱物製品（4%）、繊維製品、電気機器および金属加工品（各3%）である。',
  'semi-annual_monetary_policy_decision.description:ja:DEFAULT': '',
  'semi-annual_monetary_policy_decision.description:ja:Singapore': '',
  'electronic_card_retail_sales_yoy.description:ja:DEFAULT': '',
  'electronic_card_retail_sales_yoy.description:ja:New Zealand': '',
  'manufacturing_production_index_yoy.description:ja:DEFAULT': '',
  'manufacturing_production_index_yoy.description:ja:South Africa': '',
  '2-year_btan_auction.description:ja:DEFAULT': '',
  '2-year_btan_auction.description:ja:France': '',
  'songkran.description:ja:DEFAULT': '',
  'songkran.description:ja:Thailand': '',
  'manufacturing_prod_yoy.description:ja:DEFAULT': '',
  'manufacturing_prod_yoy.description:ja:New Zealand':
    'ニュージーランドでは製造業売上高は、同国経済の製造業セクターにおける売上高の前年比変化を示す。この指標には、（1）肉類・乳製品、（2）水産加工品、（3）果物・油・シリアル・その他食品、（3）飲料・タバコ、（5）繊維・革・衣類・履物、（6）材木・紙製品、（7）印刷、（8）石油・石炭製品、（9）化学品・ポリマー、ゴム製品、（10）非金属鉱物、（11）金属製品、（12）輸送機器・機械・装置および家具の12業界が含まれる。',
  'manufacturing_prod_yoy.description:ja:Netherlands':
    'オランダでは、製造業の中で最も大きな分野は、製油・化学（総生産の23％）、電子機器・機械（22％）、食品・飲料（20％）、卑金属・卑金属製品（12％）、輸送機器（5％）、紙・印刷（5％）、木材・建築材料（4％）、繊維・皮革製品（2％）である。',
  'manufacturing_prod_yoy.description:ja:South Africa':
    '南アフリカでは鉱工業生産は製造業、鉱業、公益事業など経済の鉱工業セクターに属する事業の生産量を測定する。',
  'manufacturing_prod_yoy.description:ja:Norway':
    'ノルウェーでは、製造業が総生産の27％を占めており、主な内訳は、食品、飲料、タバコ（5％）、石油精製品、化学品、医薬品（4％）、機械・設備（3％）、金属加工品（2％）、船舶、ボート、石油プラットフォーム（2％）、コンピュータ・電気機器（2％）、機械の修理・設置（2％）、ゴム・プラスチック・鉱物製品（2％）、卑金属（2％）となっている。',
  'unit_labor_costs_yoy.description:ja:DEFAULT': '',
  'unit_labor_costs_yoy.description:ja:United States': '',
  'liberal_democratic_party_leadership_vote.description:ja:DEFAULT': '',
  'liberal_democratic_party_leadership_vote.description:ja:Japan': '',
  'national_day_double_tenth_day.description:ja:DEFAULT': '',
  'national_day_double_tenth_day.description:ja:Taiwan': '',
  'manufacturing_payrolls.description:ja:DEFAULT': '',
  'manufacturing_payrolls.description:ja:United States':
    '「製造業給与」は、米国の製造業に従事する従業員数の絶対値の変化を報告している。',
  'manufacturing_pmi.description:ja:DEFAULT': '',
  'manufacturing_pmi.description:ja:South Africa': '',
  'manufacturing_pmi.description:ja:Japan': '',
  'manufacturing_pmi.description:ja:Israel':
    'イスラエルでは、製造業購買担当者指数（PMI）は、製造業の業況を測定するもので、多数の企業を対象とした調査に基づいている。 購買担当者指数は、新規受注、生産、雇用、サプライヤーの納期、在庫、原材料価格の6つの指標に基づいている。 数値が50を超えると、製造業が前月に比べて拡大していることを示し、50を下回ると縮小を、50は変化なしを示す。',
  'manufacturing_pmi.description:ja:Italy': '',
  'manufacturing_pmi.description:ja:France': '',
  'fiscal_year_gdp_growth.description:ja:DEFAULT': '',
  'fiscal_year_gdp_growth.description:ja:India':
    'インド経済の最大かつ最も急速な成長を遂げている部門はサービス業であり、その主要項目には、商業・ホテル・輸送・通信、金融・保険・不動産・ビジネスサービス、地域・社会・個人サービスがある。サービス業全体がGDPに占める割合は60%を超える一方、農林水産業の割合は約12%に過ぎないが、これが労働人口の50%超を抱える。製造業はGDPの15%、建設業は8%、鉱業・採石業・電力・ガス・水道は残りの5%を占める。',
  'cbi_distributive_trades.description:ja:DEFAULT': '',
  'cbi_distributive_trades.description:ja:United Kingdom':
    '英国産業連盟（CBI）の流通業調査（DTS）は、小売業の健全性を測定している。この調査結果は、約150社の小売・卸売企業を対象としたアンケート調査から作成されている。この調査には、流通業全体の販売活動に関する指標が含まれている。これは個人消費の先行指標である。この数値は、売上高が増加したと回答した小売企業の割合と、減少したと回答した小売企業の割合の差である。',
  'manufacturing_confidence.description:ja:DEFAULT': '',
  'manufacturing_confidence.description:ja:Netherlands':
    'オランダでは製造業1,700社を対象に生産者信頼感調査を実施し、製造業のあらゆる活動に関する経済動向の最新情報を収集している。この調査では以下3つの要素を調査している：当月の自社の受注状況の評価、当月の完成品在庫の評価、その後3カ月間の経済活動の見通し。この指数は、肯定的な回答の割合から否定的な回答の割合を差し引いて算出される。したがってこの指数の範囲は、マイナス100（すべての参加者が自社の状況を悪いと評価し、さらに悪化すると予想している）からプラス100（すべての企業が現在の状況に満足し、さらに改善すると予想している）までで、0は中立を表す。',
  'presidential_and_legislative_elections.description:ja:DEFAULT': '',
  'presidential_and_legislative_elections.description:ja:Mexico': '',
  'ism_manufacturing_pmi.description:ja:DEFAULT': '',
  'ism_manufacturing_pmi.description:ja:United States':
    'ISM製造業景気指数（Manufacturing ISM Report On Business®）は、全国の購買および供給担当幹部からのアンケート調査回答に基づいて集計され、前月と比較した当月の変化があればそれが反映される。新規受注、受注残、新規輸出受注、輸入、生産、サプライヤー納期、在庫、顧客在庫、雇用、価格の各指標について、景気がよくなるとした回答数と悪くなると回答した回答数との差、それぞれの回答の割合、およびディフュージョン・インデックス（DI）を示す。50％を上回るPMI®は製造業の景気が全体的に拡大していることを、50％を切る場合は縮小していることを示す。',
  'israel_municipal_election_day.description:ja:DEFAULT': '',
  'israel_municipal_election_day.description:ja:Israel': '',
  'exports_final.description:ja:DEFAULT': '',
  'exports_final.description:ja:India':
    '近年、インドの主な輸出品目は、真珠、貴石、半貴石、宝飾品（全体の16％）、鉱物燃料、油脂、ワックス、瀝青物質（12％）、自動車、部品、付属品（5％）、原子炉、ボイラー、機械、機械器具（5％）、医薬品（5％）、有機化学品（4％）である。インドの主な輸出相手国は 米国（総輸出の15%）、アラブ首長国連邦（11%）、香港（5%）、中国（4%）、シンガポール（4%）、英国（3%）。',
  'makha_bucha_day.description:ja:DEFAULT': '',
  'makha_bucha_day.description:ja:Thailand': '',
  'finance_summit_2019.description:ja:DEFAULT': '',
  'finance_summit_2019.description:ja:France': '',
  'unemployment_rate_nsa.description:ja:DEFAULT': '',
  'unemployment_rate_nsa.description:ja:Norway':
    'ノルウェーでは、失業率は、積極的に仕事を探している人の数が労働力人口に占める割合を測定する。',
  'mahavir_jayanti.description:ja:DEFAULT': '',
  'mahavir_jayanti.description:ja:India': '',
  'prophet_s_ascension.description:ja:DEFAULT': '',
  'prophet_s_ascension.description:ja:Indonesia': '',
  'mahatma_gandhi_s_birthday.description:ja:DEFAULT': '',
  'mahatma_gandhi_s_birthday.description:ja:India': '',
  'hsbc_india_services_pmi.description:ja:DEFAULT': '',
  'hsbc_india_services_pmi.description:ja:India':
    'IHSマークイット・インドのサービス業購買担当者景気指数（PMI）は、約350社の民間企業の購買担当幹部から収集したアンケート調査の回答を基に集計され、売上高、雇用、在庫、価格などの変数を追跡する。指数が50を超えれば全体的にサービス業が拡大していることを、50を切れば縮小していることを示す。',
  'mahatma_gandhi_birthday.description:ja:DEFAULT': '',
  'mahatma_gandhi_birthday.description:ja:India': '',
  'mahashivratri.description:ja:DEFAULT': '',
  'mahashivratri.description:ja:India': '',
  'mahashivarati.description:ja:DEFAULT': '',
  'mahashivarati.description:ja:India': '',
  'machinery_orders_yoy.description:ja:DEFAULT': '',
  'machinery_orders_yoy.description:ja:Japan':
    '日本では、機械受注とは、変動の大きい船舶向けや電力会社向けを除いた、民間部門の機械受注額の前月比での変化を指す。',
  'ibd_tipp_economic_optimism.description:ja:DEFAULT': '',
  'ibd_tipp_economic_optimism.description:ja:United States':
    'IBD/TIPP景気楽観指数とは、アメリカ人の経済に対する意見や見通しを測定したものである。この指数は、全国の成人900人を対象とした調査に基づいており、6カ月間の経済見通し、個人の財務見通し、連邦政府の経済政策に対する信頼感などを評価している。50を超えると楽観的、50を下回ると悲観的であることを示す。',
  'primary_budget_balance.description:ja:DEFAULT': '',
  'primary_budget_balance.description:ja:Brazil': '',
  'rba_girn_speech.description:ja:DEFAULT': '',
  'rba_girn_speech.description:ja:Australia': '',
  'machine_tool_orders_yoy.description:ja:DEFAULT': '',
  'machine_tool_orders_yoy.description:ja:Japan':
    '工作機械受注は、工作機械メーカーに発注された新規受注額の変化を測定する。',
  'labour_day_holiday.description:ja:DEFAULT': '',
  'labour_day_holiday.description:ja:Russia': '',
  'fda_hearing_on_covid-19_vaccines.description:ja:DEFAULT': '',
  'fda_hearing_on_covid-19_vaccines.description:ja:United States': '',
  'mps_vote_on_withdrawal_agreement_bill.description:ja:DEFAULT': '',
  'mps_vote_on_withdrawal_agreement_bill.description:ja:United Kingdom': '',
  'gdp_consumer_spending_yoy.description:ja:DEFAULT': '',
  'gdp_consumer_spending_yoy.description:ja:United States': '',
  'mps_vote_on_pm_election_call.description:ja:DEFAULT': '',
  'mps_vote_on_pm_election_call.description:ja:United Kingdom': '',
  'mps_vote_on_internal_market_bill.description:ja:DEFAULT': '',
  'mps_vote_on_internal_market_bill.description:ja:United Kingdom': '',
  'mps_vote_on_benn_bill.description:ja:DEFAULT': '',
  'mps_vote_on_benn_bill.description:ja:United Kingdom': '',
  'freedom_and_democracy_day.description:ja:DEFAULT': '',
  'freedom_and_democracy_day.description:ja:Czech Republic': '',
  'monetary_policy_statement.description:ja:DEFAULT': '',
  'monetary_policy_statement.description:ja:New Zealand': '',
  'monetary_policy_statement.description:ja:Singapore':
    'シンガポール金融管理局は、金利を監視することで金融システムを管理するのではなく、シンガポールドル（SGD）の為替レートを主要な貿易相手国や競合国の通貨に対する貿易加重バスケットに対して管理している。2015年1月28日、シンガポール金融管理局は、原油価格の下落によるインフレ期待の低下を理由に、主要通貨に対するSGD上昇率を市場予想に反して引き下げた。シンガポール翌日物無担保平均金利（SORA）は、シンガポールの無担保翌日物銀行間SGD現金市場における午前8時から午後6時15分までの借り入れ取引の数量加重平均金利である。',
  'mpc_member_bean_speech.description:ja:DEFAULT': '',
  'mpc_member_bean_speech.description:ja:United Kingdom': '',
  'boe_mpc_vote_cut.description:ja:DEFAULT': '',
  'boe_mpc_vote_cut.description:ja:United Kingdom':
    'イギリスの金融政策委員会（MPC）が基準利率を設定する。中央銀行であるイングランド銀行の公式金利はレポ金利である。このレポ金利は、イングランド銀行の取引相手（銀行、住宅金融組合、証券会社）に対する公開市場操作での利率となる。',
  'seollal_substitute_holiday.description:ja:DEFAULT': '',
  'seollal_substitute_holiday.description:ja:South Korea': '',
  'ihs_markit_manufacturing_pmi.description:ja:DEFAULT': '',
  'ihs_markit_manufacturing_pmi.description:ja:Italy':
    'イタリアでは、マークイット・イタリア製造業購買担当者指数は、製造業部門の業績を測定するもので、産業界の企業400社を対象とした調査に基づいている。製造業購買担当者指数は、以下の重み付けをした5つの個別指数：新規注文（30％）、生産（25％）、雇用（20％）、サプライヤー納期（15％）、購入品在庫（10％）の5つの個別のインデックスに基づいており、納期の指数は比較可能な方向に動くように反転されている。指数が50を超えると前月比で拡大、50を下回ると縮小、50は変化なしを意味する。',
  'mpc_meeting_minutes.description:ja:DEFAULT': '',
  'mpc_meeting_minutes.description:ja:United Kingdom':
    'イギリスの金融政策委員会（MPC）が基準利率を設定する。中央銀行であるイングランド銀行の公式金利はレポ金利である。このレポ金利は、イングランド銀行の取引相手（銀行、住宅金融組合、証券会社）に対する公開市場操作での利率となる。',
  'rba_gov_glenn_stevens_speech.description:ja:DEFAULT': '',
  'rba_gov_glenn_stevens_speech.description:ja:Australia': '',
  'mof_asakawa_speech.description:ja:DEFAULT': '',
  'mof_asakawa_speech.description:ja:Japan': '',
  'large_retailer_sales.description:ja:DEFAULT': '',
  'large_retailer_sales.description:ja:Japan': '',
  'holiday_-_ganesh_chaturthi.description:ja:DEFAULT': '',
  'holiday_-_ganesh_chaturthi.description:ja:India': '',
  'mni_consumer_sentiment_indicator.description:ja:DEFAULT': '',
  'mni_consumer_sentiment_indicator.description:ja:Russia': '',
  'mni_consumer_sentiment_indicator.description:ja:India': '',
  'st_berchtold.description:ja:DEFAULT': '',
  'st_berchtold.description:ja:Switzerland': '',
  'industrial_sales_yoy.description:ja:DEFAULT': '',
  'industrial_sales_yoy.description:ja:Italy':
    'イタリアでは、鉱工業売上高は、足元の価格での鉱工業売上高の月次変化を測定する。',
  'mni_business_sentiment_indicator.description:ja:DEFAULT': '',
  'mni_business_sentiment_indicator.description:ja:China': '',
  '10-year_oat_auction.description:ja:DEFAULT': '',
  '10-year_oat_auction.description:ja:France':
    '一般的に国債は国家政府により発行され、その国の通貨により値付けされる。国から発行された債券で外貨建てのものは通常ソブリン債と呼ばれる。資金をその国の政府に融資する投資家が必要とする利回りは、予測インフレ率および元本の償還可能性を考慮に含めたものとなる。',
  'ecb_draghi_speaks_at_jackson_hole.description:ja:DEFAULT': '',
  'ecb_draghi_speaks_at_jackson_hole.description:ja:Euro Area': '',
  'mba_mortgage_applications_wow.description:ja:DEFAULT': '',
  'mba_mortgage_applications_wow.description:ja:United States': '',
  'foreign_securities_purchases_by_canadians.description:ja:DEFAULT': '',
  'foreign_securities_purchases_by_canadians.description:ja:Canada': '',
  'mba_mortgage_applications.description:ja:DEFAULT': '',
  'mba_mortgage_applications.description:ja:United States':
    '米国では、MBA住宅ローン申請件数調査は、全米の住宅ローン市場を包括的に把握するためのもので、商業銀行、貯蓄貸付組合、住宅ローン会社を含むあらゆるタイプの住宅ローンの貸手を対象としている。市場指数は市場全体を表す指数で、借入の目的が住宅の購入か借り換えかにかかわらず、その週のすべての住宅ローン申請を対象としている。',
  'uk_general_election.description:ja:DEFAULT': '',
  'uk_general_election.description:ja:United Kingdom': '',
  'mba_30-year_mortgage_rate.description:ja:DEFAULT': '',
  'mba_30-year_mortgage_rate.description:ja:United States':
    'MBA30年住宅ローン金利は、住宅ローン銀行協会が支援する30年固定金利住宅ローンの平均貸付金利で、平均貸付金利は報告される週に測定される。',
  'mas_chair_shanmugaratnam_speech.description:ja:DEFAULT': '',
  'mas_chair_shanmugaratnam_speech.description:ja:Singapore':
    'Siborは、シンガポールの銀行間市場において、銀行が無担保資金を相互に貸し出す際の金利に基づく参照レートである。シンガポールでは、金融政策の決定はシンガポール金融管理局（Penguasa Kewangan Singapura）が行っている。 シンガポール金融管理局は、金利を監視することで金融システムを管理するのではなく、シンガポールドル（SGD）の為替レートを主要な貿易相手国や競合国の通貨に対する貿易加重バスケットに対して管理している。2015年1月28日、シンガポール金融管理局は、原油価格の下落によるインフレ期待の低下を理由に、市場予想に反して、主要通貨に対するSGD上昇率を引き下げた。',
  'm3_money_supply_yoy.description:ja:DEFAULT': '',
  'm3_money_supply_yoy.description:ja:Euro Area':
    'ユーロ圏のマネーサプライ M3は、M2、レポ契約、マネー・マーケット・ファンドの株式/ユニット、満期2年以内の債券の合計である。',
  'm3_money_supply_yoy.description:ja:New Zealand': '',
  'm3_money_supply_yoy.description:ja:Poland':
    'ポーランド・マネーサプライM3は、M2および銀行の長期定期預金を含んでいる。',
  'm3_money_supply_yoy.description:ja:India': '',
  'm3_money_supply_yoy.description:ja:South Africa':
    '南アフリカのマネーサプライM3は、M2および銀行の長期定期預金を含んでいる。',
  'm3_money_supply_yoy.description:ja:Czech Republic':
    'チェコ共和国のマネーサプライ M3は、M2、レポ契約、マネー・マーケット・ファンドの株式/ユニット、満期2年以内の債券の合計である。',
  'm1_money_supply_yoy.description:ja:DEFAULT': '',
  'm1_money_supply_yoy.description:ja:Israel': '',
  'nab_business_confidence.description:ja:DEFAULT': '',
  'nab_business_confidence.description:ja:Australia':
    'NABの月次景況感調査は、非農業系の中小企業および大企業約600社を対象とした電話調査に基づいている。本調査では、翌月の業況に対する期待を測定しており、回答者が自社について報告した取引、収益性、雇用の各指標の単純平均値を算出している。指標は、「良い」「非常に良い」「上昇」と答えた回答者と、「悪い」「非常に悪い」「下降」と答えた回答者の割合の差をとることで算出される。',
  'london_mayoral_election.description:ja:DEFAULT': '',
  'london_mayoral_election.description:ja:United Kingdom': '',
  'london_assembly_election.description:ja:DEFAULT': '',
  'london_assembly_election.description:ja:United Kingdom': '',
  'loans_to_private_sector_yoy.description:ja:DEFAULT': '',
  'loans_to_private_sector_yoy.description:ja:Greece':
    'ギリシャの民間部門向け融資は、中央銀行を除く国内の通貨金融機関（MFI）による企業への与信を指す。',
  'loans_to_companies_yoy.description:ja:DEFAULT': '',
  'loans_to_companies_yoy.description:ja:Euro Area':
    'ユーロ圏では、民間部門向け融資は、ユーロ圏における非金融機関への調整後融資額である。',
  'jerusalem_day.description:ja:DEFAULT': '',
  'jerusalem_day.description:ja:Israel': '',
  'loan_prime_rate_5y.description:ja:DEFAULT': '',
  'loan_prime_rate_5y.description:ja:China': '',
  'asalha_bucha.description:ja:DEFAULT': '',
  'asalha_bucha.description:ja:Thailand': '',
  'loan_officer_survey.description:ja:DEFAULT': '',
  'loan_officer_survey.description:ja:United States': '',
  'loan_growth_yoy.description:ja:DEFAULT': '',
  'loan_growth_yoy.description:ja:Euro Area':
    'ユーロ圏では、世帯向け融資額の増加率は、販売と証券化を調整した世帯向け融資額を前年比で測定する。',
  'loan_growth_yoy.description:ja:Indonesia':
    'インドネシアでは、ローン成長率は商業銀行の債権残高の前年比の変化を指す。',
  'loan_growth_yoy.description:ja:Norway':
    'ノルウェーにおけるローン成長率は、一般市民の国内総負債の年間変化を指す。',
  'liberty_day.description:ja:DEFAULT': '',
  'liberty_day.description:ja:Portugal': '',
  'liberation_from_fascism.description:ja:DEFAULT': '',
  'liberation_from_fascism.description:ja:Czech Republic': '',
  'imf_world_bank_annual_meetings.description:ja:DEFAULT': '',
  'imf_world_bank_annual_meetings.description:ja:Indonesia': '',
  'imf_world_bank_annual_meetings.description:ja:United States': '',
  'lending_to_households_yoy.description:ja:DEFAULT': '',
  'lending_to_households_yoy.description:ja:Sweden':
    'スウェーデンの家計向け貸出前年比は、国内の金融機関（MFIs）からスウェーデンの家計への貸出を指す。',
  'legislative_election.description:ja:DEFAULT': '',
  'legislative_election.description:ja:South Korea': '',
  'legislative_election.description:ja:Israel': '',
  'leading_economic_index_prel.description:ja:DEFAULT': '',
  'leading_economic_index_prel.description:ja:Japan':
    '日本では、総合先行指数は、在庫率や機械受注、株価、その他の経済先行指数など、12の指標から構成される。この指数は向こう数カ月間の景気の方向の変化を予測し、一般的に指標が上昇すれば景気が拡大期にあり、低下すれば縮小期にあることを示す。この指標は景気サイクルの開始時期と終了時期を公式に判断するために使われる。',
  'manufacturing_output_mom.description:ja:DEFAULT': '',
  'manufacturing_output_mom.description:ja:Norway': '',
  'leading_composite_index_prel.description:ja:DEFAULT': '',
  'leading_composite_index_prel.description:ja:Japan':
    '日本では、総合先行指数は、在庫率や機械受注、株価、その他の経済先行指数など、12の指標から構成される。この指数は向こう数カ月間の景気の方向の変化を予測し、一般的に指標が上昇すれば景気が拡大期にあり、低下すれば縮小期にあることを示す。この指標は景気サイクルの開始時期と終了時期を公式に判断するために使われる。',
  'leading_composite_index_final.description:ja:DEFAULT': '',
  'leading_composite_index_final.description:ja:Japan':
    '日本では、総合先行指数は、在庫率や機械受注、株価、その他の経済先行指数など、12の指標から構成される。この指数は向こう数カ月間の景気の方向の変化を予測し、一般的に指標が上昇すれば景気が拡大期にあり、低下すれば縮小期にあることを示す。この指標は景気サイクルの開始時期と終了時期を公式に判断するために使われる。',
  'treasury_secretary_yellen_testimony.description:ja:DEFAULT': '',
  'treasury_secretary_yellen_testimony.description:ja:United States': '',
  'eid_ul-fitr.description:ja:DEFAULT': '',
  'eid_ul-fitr.description:ja:Philippines': '',
  'eid_ul-fitr.description:ja:Indonesia': '',
  'large_retailer_s_sales.description:ja:DEFAULT': '',
  'large_retailer_s_sales.description:ja:Japan': '',
  'bcb_focus_market_readout.description:ja:DEFAULT': '',
  'bcb_focus_market_readout.description:ja:Brazil':
    'ブラジルでは、ブラジル中銀の金融政策委員会（COPOM）が政策金利を決定する。政策金利は翌日物貸出金利であるSELIC（Special System of Clearance and Custody）金利である。',
  'construction_pmi.description:ja:DEFAULT': '',
  'construction_pmi.description:ja:Italy':
    '調査回答は、月半ばに収集されたデータに基づいて、前月と比較して、当月に変化があった場合、それを反映している。「報告書」には、各指標について、それぞれの回答を報告した割合、高かった／良かったと答えた数と低かった／悪かったと答えた数の差、および「ディフュージョン」指数が記載されている。この指数は、肯定的な回答数に、「同じ」と回答した数の半分を加えたものである。ディフュージョン指数は先行指標の性質を持っており、全般的な変化の方向性を示す便利な要約指標である。指数が50を超えると、その変数が全体的に増加していることを示し、50を下回ると全体的に減少していることを示す。',
  'construction_pmi.description:ja:United Kingdom':
    '英国では、マークイット/CIPS購買担当者景気指数 (PMI) によって建設セクターのパフォーマンスを測定している。調査では170社の建設会社を対象としている。',
  'construction_pmi.description:ja:Ireland':
    'アルスター銀行建設購買担当者指数®は、アイルランドの総建設活動の変化を追跡するために作成された季節調整済みの指数で、データは月半ばに収集され、回答者に様々なビジネス状況を1カ月前の状況と比較するよう求めている。指数が50.0未満であれば、経済が全般的に縮小していることを示し、50.0を超えていれば全般的に拡大していることを示し、50.0ちょうどであれば、前月に記録した水準から変化がないことを示す。',
  'construction_pmi.description:ja:Germany':
    'ドイツ建設購買担当者景気指数®は、ドイツの建設部門に従事する200社以上の代表的な企業から収集した独自の調査データに基づいている。データは月半ばに収集され、回答者に様々な建設状況を1カ月前の状況と比較するよう求めている。指数が50.0未満の場合は建設活動が全般的に低下していることを示し、50.0を超える場合は全般的に拡大していることを示し、50.0ちょうどの場合は前月の水準から変化がないことを示している。',
  'construction_pmi.description:ja:France':
    'データは月半ばに収集され、回答者はさまざまなビジネス状況を1ヵ月前の状況と比較するよう求められている。指数が50.0未満の場合はおおむね景気が後退していることを示し、50.0を超える場合はおおむね景気が拡大していることを示し、50.0ちょうどの場合は前月の水準から変化がないことを示す。',
  'construction_pmi.description:ja:Euro Area':
    'データは月半ばに収集され、回答者はさまざまなビジネス状況を1ヵ月前の状況と比較するよう求められている。指数が50.0未満の場合はおおむね景気が後退していることを示し、50.0を超える場合はおおむね景気が拡大していることを示し、50.0ちょうどの場合は前月の水準から変化がないことを示す。',
  'm4_money_supply_yoy.description:ja:DEFAULT': '',
  'm4_money_supply_yoy.description:ja:United Kingdom': '',
  'rba_ellis_testimony.description:ja:DEFAULT': '',
  'rba_ellis_testimony.description:ja:Australia': '',
  'labour_thanksgiving_day.description:ja:DEFAULT': '',
  'labour_thanksgiving_day.description:ja:Japan': '',
  'labour_productivity_qoq.description:ja:DEFAULT': '',
  'labour_productivity_qoq.description:ja:Canada': '',
  'labour_productivity_qoq.description:ja:United Kingdom':
    '英国では、労働生産性は一定期間における1労働単位当たりの実質生産高を示す。',
  'labour_costs_qoq.description:ja:DEFAULT': '',
  'labour_costs_qoq.description:ja:United Kingdom':
    '英国では、単位労働コスト（ULC）は、単位経済生産にかかる社会保障費や雇用者年金拠出金を含む労働コストの全額を反映している。労働コストの変化は、生産コストの全体的な変化の大きな要因となる。例えば、コストの増加が生産の増加に反映されない場合、商品やサービスの価格に上昇圧力がかかる可能性があり、「インフレ圧力」と呼ばれることもある。',
  'frankfurt_finance_summit_2016.description:ja:DEFAULT': '',
  'frankfurt_finance_summit_2016.description:ja:Germany': '',
  'labour_costs_index_yoy.description:ja:DEFAULT': '',
  'labour_costs_index_yoy.description:ja:New Zealand':
    '労働コスト指数は、労働コストの変化を測定するものである。これらのコストには、基本給、通常の時間給、残業代のほか、年次休暇や法定休日、退職金、ACC雇用者保険料、医療保険、自家用車、低金利ローンなど、賃金以外の労働関連コストが含まれる。',
  'labour_costs_index_qoq.description:ja:DEFAULT': '',
  'labour_costs_index_qoq.description:ja:New Zealand':
    '労働コスト指数は、労働コストの変化を測定するものである。これらのコストには、基本給、通常の時間給、残業代のほか、年次休暇や法定休日、退職金、ACC雇用者保険料、医療保険、自家用車、低金利ローンなど、賃金以外の労働関連コストが含まれる。',
  'labor_costs_qoq.description:ja:DEFAULT': '',
  'labor_costs_qoq.description:ja:United States': '',
  'rba_statement_on_monetary_policy.description:ja:DEFAULT': '',
  'rba_statement_on_monetary_policy.description:ja:Australia':
    'オーストラリアでは金利決定はオーストラリア準備銀行理事会が行い、政策金利はキャッシュレートである。キャッシュレートとは、金融機関の間で翌日物貸付に適用される利率で、翌日物資金の需要と供給によってマネーマーケットで決定される。',
  'election_for_national_assembly.description:ja:DEFAULT': '',
  'election_for_national_assembly.description:ja:South Korea': '',
  'hsbc_manufacturing_pmi_final.description:ja:DEFAULT': '',
  'hsbc_manufacturing_pmi_final.description:ja:China': '',
  'kof_leading_indicator.description:ja:DEFAULT': '',
  'kof_leading_indicator.description:ja:Switzerland':
    'スイスではKOFスイス経済研究所の経済バロメーターが、企業経営者が景気についてどの程度楽観的に考えているか、自社の見通しについてどのように感じているかを測定している。KOF経済バロメーターは、3つのモジュールによるマルチセクター設計に基づいている。2014年版は219の指標変数が、統計的に決定されたウェイトに基づいて組み合わせられている。最も重要なモジュールは、建設業と銀行業を除いたGDPであり、これがスイスのGDPの90％以上を占めている。',
  'ecb_annual_report_2019.description:ja:DEFAULT': '',
  'ecb_annual_report_2019.description:ja:Euro Area':
    'ユーロ圏の基準金利は欧州中央銀行（ECB）の理事会によって決定される。ECBの金融政策の主な目的は、物価の安定を維持することであり、それはインフレ率を中期的に2％未満でかつそれに近い水準に抑えることである。低インフレ・低金利が長期化した場合、ECBは資産購入プログラムなどの非標準的な金融政策を採用することもある。公式金利は、主要リファイナンスオペ金利である。。',
  'june_bank_holiday.description:ja:DEFAULT': '',
  'june_bank_holiday.description:ja:Ireland': '',
  'boe_press_conference.description:ja:DEFAULT': '',
  'boe_press_conference.description:ja:United Kingdom': '',
  'jobseekers_total.description:ja:DEFAULT': '',
  'jobseekers_total.description:ja:France':
    'フランスの求職者数は、積極的な求職活動が義務付けられている雇用センターに登録されている月末時点の失業者数を指す。',
  'jibun_bank_manufacturing_pmi_flash.description:ja:DEFAULT': '',
  'jibun_bank_manufacturing_pmi_flash.description:ja:Japan':
    'じぶん銀行日本製造業購買担当者景況指数（PMI）®は、約400社の製造業企業の購買管理者に送信されたアンケート調査の結果をIHSマークイットが毎月集計するもので、対象企業はGDPへの寄与度に基づき、詳細セクターと企業の社員数によって階層化さている。アンケートの回答は前月に比べた変化の方向を示し、各調査変数に関してディフュージョンインデックス（DI）が算出される。同指数は「改善」と回答した割合（％）に、「横ばい」と回答した割合の半分を加算して算出する。指数の値は0～50の間で、50を超える場合は全体的に前月からの改善・増加を、50未満の場合は悪化・減少を表す。このPMIは、新規受注（50％）、生産（25％）、雇用（20％）、サプライヤー納期（15％）、購買品在庫（10％）の5つの指数の加重平均である。PMIの算出においてはサプライヤー納期インデックスは他のインデックスと同じ方向に動くよう、符号を反転させている。',
  'ecb_const_ncio_speech.description:ja:DEFAULT': '',
  'ecb_const_ncio_speech.description:ja:Euro Area': '',
  'jibun_bank_composite_pmi_flash.description:ja:DEFAULT': '',
  'jibun_bank_composite_pmi_flash.description:ja:Japan':
    '日本では、日経日本総合生産指数が、約800社の代表的な企業のパネルから収集したデータに基づいて、民間部門の事業活動の動向を追跡している。この指数は、売上高、新規受注、雇用、在庫、価格などの変数を追跡している。指数が50を超えると企業活動が拡大していることを示し、50を下回ると全般的に減少していることを示す。',
  'jibun_bank_composite_pmi_final.description:ja:DEFAULT': '',
  'jibun_bank_composite_pmi_final.description:ja:Japan':
    '日本では、日経日本総合生産指数が、約800社の代表的な企業のパネルから収集したデータに基づいて、民間部門の事業活動の動向を追跡している。この指数は、売上高、新規受注、雇用、在庫、価格などの変数を追跡している。指数が50を超えると企業活動が拡大していることを示し、50を下回ると全般的に減少していることを示す。',
  'gdp_price_index_yoy_final.description:ja:DEFAULT': '',
  'gdp_price_index_yoy_final.description:ja:Japan':
    'GDPデフレーターは、最終財とサービスの価格変化を測るものであり、将来の金融政策の方向性に影響するインフレ圧力の主要指標であるとされている。',
  'jewish_new_year_ii.description:ja:DEFAULT': '',
  'jewish_new_year_ii.description:ja:Israel': '',
  'ism_new_york_index.description:ja:DEFAULT': '',
  'ism_new_york_index.description:ja:United States':
    'ISMニューヨーク指数は、ニューヨーク市域の企業における事業部門のパフォーマンスを測定するものである。この指数は、300社以上の製造業企業に対する調査に基づき、生産水準、新規受注、在庫水準、サプライヤーからの納品、雇用環境などを追跡している。指数が50を超えると前月に比べて拡大したことを示し、50を下回ると縮小、50は変化がないことを示す。',
  'tuen_ng_festival.description:ja:DEFAULT': '',
  'tuen_ng_festival.description:ja:Hong Kong': '',
  'jewish_new_year_eve.description:ja:DEFAULT': '',
  'jewish_new_year_eve.description:ja:Israel': '',
  'gdp_growth_annualized_qoq_prel.description:ja:DEFAULT': '',
  'gdp_growth_annualized_qoq_prel.description:ja:Japan': '',
  'jewish_new_year.description:ja:DEFAULT': '',
  'jewish_new_year.description:ja:Israel': '',
  'unemployment_rate_harmonised.description:ja:DEFAULT': '',
  'unemployment_rate_harmonised.description:ja:Germany':
    'ドイツでは、失業率は、積極的に仕事を探している人の数が労働力人口に占める割合を測定する。',
  'jan_hus_day.description:ja:DEFAULT': '',
  'jan_hus_day.description:ja:Czech Republic': '',
  'jackson_hole_symposium.description:ja:DEFAULT': '',
  'jackson_hole_symposium.description:ja:United States': '',
  'mps_vote_on_brexit_timetable.description:ja:DEFAULT': '',
  'mps_vote_on_brexit_timetable.description:ja:United Kingdom': '',
  'jackson_hole_economic_symposium.description:ja:DEFAULT': '',
  'jackson_hole_economic_symposium.description:ja:United States': '',
  'jackson_hole_economic_policy_symposium.description:ja:DEFAULT': '',
  'jackson_hole_economic_policy_symposium.description:ja:United States':
    '米国では、金利設定の権限は連邦準備制度理事会（理事会）と連邦公開市場委員会（FOMC）に分けられている。理事会は、1つ以上の地区連邦準備銀行から提出された勧告を受けて、割引率の変更を決定する。FOMCは、中央銀行の資金量やフェデラルファンド市場金利などの公開市場操作を決定する。',
  'house_price_index_qoq.description:ja:DEFAULT': '',
  'house_price_index_qoq.description:ja:Australia':
    'オーストラリアでは、シドニー、メルボルン、ブリスベン、アデレード、キャンベラ、ホバート、ダーウィン、パースの8つの州都で、住宅物件の価格変動の加重平均によって住宅価格指数を測定している。 住宅用不動産は、築年数に関係なく、一区画の土地に建てられた一戸建て住宅として定義されている。。',
  'boe_saunders_speech.description:ja:DEFAULT': '',
  'boe_saunders_speech.description:ja:United Kingdom':
    'イギリスの金融政策委員会（MPC）が基準利率を設定する。中央銀行であるイングランド銀行の公式金利はレポ金利である。このレポ金利は、イングランド銀行の取引相手（銀行、住宅金融組合、証券会社）に対する公開市場操作での利率となる。',
  'jolts_job_openings.description:ja:DEFAULT': '',
  'jolts_job_openings.description:ja:United States':
    '米国では、求人件数とは、その月の最終営業日に空いている（埋まっていない）すべてのポジションを指す。求人件数は、JOLTS（Job Openings and Labor Turnover Survey）の一部である。この調査は、全米50州およびコロンビア特別区の小売業や製造業を含む約1,600の非農業部門の事業所、および連邦政府、州政府、地方自治体からデータを収集している。JOLTSは、米国の労働市場における労働力の未充足需要を評価するもので、2014年にはイエレン米連邦準備制度理事会（FRB）議長の選好する労働市場指標として注目を集めた。',
  'yom_kippur_eve_eve_of_atonement_day.description:ja:DEFAULT': '',
  'yom_kippur_eve_eve_of_atonement_day.description:ja:Israel': '',
  'italy_pm_confidence_vote_in_parliament.description:ja:DEFAULT': '',
  'italy_pm_confidence_vote_in_parliament.description:ja:Italy': '',
  'gdp_growth_rate_2015_prel.description:ja:DEFAULT': '',
  'gdp_growth_rate_2015_prel.description:ja:Poland':
    'ポーランドの消費者市場は3,800万人と、ヨーロッパでも最大級の規模を誇る。ポーランドの主要産業は、食品・飲料加工、造船、機械・鉄鋼製品・化学品・ガラス・繊維製品の製造である。支出面では、家計消費がGDPの60％を占めており、次いで総固定資本形成（20％）、政府支出（18％）である。財・サービスの輸出はGDPの47％を占め、輸入は46％で、GDP全体に1％寄与している。',
  'gdp_growth_rate_2015_prel.description:ja:Russia':
    'ロシアは世界有数の石油・天然ガスの生産国であり、鉄鋼や一次アルミニウムなどの金属の輸出国でもある。生産面では、サービス業が経済の最大セクターであり、GDPの58％を占めている。サービス業の中で最も重要な分野は、卸売・小売（GDP合計の17％）、行政・医療・教育（12％）である。工業は総生産の40％、農業は残りの2％。',
  'commbank_composite_pmi_final.description:ja:DEFAULT': '',
  'commbank_composite_pmi_final.description:ja:Australia':
    'オーストラリアのIHSマークイット・オーストラリア総合PMI生産指数は、コモンウェルス銀行製造業生産指数とコモンウェルス銀行サービス業事業活動指数のGDP加重平均値である。この指数は、オーストラリアの民間部門の経済全体における企業活動の変化を追跡する。この指数が50を超えると企業活動が拡大していることを、50を下回ると縮小していることを示す。',
  'fed_brainard_speech.description:ja:DEFAULT': '',
  'fed_brainard_speech.description:ja:United States':
    '米国では、金利設定の権限は連邦準備制度理事会（理事会）と連邦公開市場委員会（FOMC）に分けられている。理事会は、1つ以上の地区連邦準備銀行から提出された勧告を受けて、割引率の変更を決定する。FOMCは、中央銀行の資金量やフェデラルファンド市場金利などの公開市場操作を決定する。',
  'israel_election_day.description:ja:DEFAULT': '',
  'israel_election_day.description:ja:Israel': '',
  'snb_jordan_speech.description:ja:DEFAULT': '',
  'snb_jordan_speech.description:ja:Switzerland':
    'スイスではスイス国立銀行（SNB）が金利を決定し、公式金利はSNBの政策金利である。SNBは有担保短期スイスフラン建てマネーマーケット金利をSNBの政策金利近傍に維持することを目標としている。今日、これらの最も代表的な金利はSARONである。2019年６月13日時点で従来SNBが金融政策戦略に使用していた３カ月物スイスフランLIBOR（ロンドン銀行間取引金利）の目標レンジに代わり、SNB政策金利が採用されることになった。その理由はLIBORを決定するためのマネーマーケット取引が不足し、LIBORが最も重要な参照金利としての重要性を失いつつあったためである。2011年9月6日から2015年1月15日までの期間の主な注目点は最低為替レートとして1EUR＝1.20CHFをSNBが強制執行したことであった。2014年12月18日にSNBは要求払い預金金利をマイナス0.25％とすることを決定した。マイナス金利導入の発表に伴い、当時使われていたLIBORの目標レンジは初めてマイナス圏となった。2015年1月15日、SNBは要求払い預金金利をさらにマイナス0.75％に引き下げ、目標レンジをマイナス1.25％～マイナス0.25％の間とした。2015年1月22日以来マイナス金利が適用されており、現在SNBの政策金利に対応している。',
  'financial_stability_review.description:ja:DEFAULT': '',
  'financial_stability_review.description:ja:Euro Area': '',
  'eve_of_rosh_hashanah.description:ja:DEFAULT': '',
  'eve_of_rosh_hashanah.description:ja:Israel': '',
  'islamic_new_year.description:ja:DEFAULT': '',
  'islamic_new_year.description:ja:India': '',
  'islamic_new_year.description:ja:Indonesia': '',
  'international_women_s_holiday.description:ja:DEFAULT': '',
  'international_women_s_holiday.description:ja:Russia': '',
  'interest_rate_decision.description:ja:DEFAULT': '',
  'interest_rate_decision.description:ja:Euro Area':
    'ユーロ圏の基準金利は欧州中央銀行（ECB）の理事会によって決定される。ECBの金融政策の主な目的は、物価の安定を維持することであり、それはインフレ率を中期的に2％未満でかつそれに近い水準に抑えることである。低インフレ・低金利が長期化した場合、ECBは資産購入プログラムなどの非標準的な金融政策を採用することもある。公式金利は、主要リファイナンスオペ金利である。。',
  'interest_rate_decision.description:ja:Australia':
    'オーストラリアでは金利決定はオーストラリア準備銀行理事会が行い、政策金利はキャッシュレートである。キャッシュレートとは、金融機関の間で翌日物貸付に適用される利率で、翌日物資金の需要と供給によってマネーマーケットで決定される。',
  'interest_rate_decision.description:ja:Hong Kong':
    '香港では、金利の決定は香港金融管理局（HKMA）が行っている。主な金利は基本金利で、香港ドルの需要と供給に応じて調整される。',
  'interest_rate_decision.description:ja:China':
    '中国人民銀行（PBOC）は2019年8月17日、中央銀行の基準金利である1年物貸出金利に代わり、ローンプライムレート（LPR）を家計や企業への新規銀行融資の新たな貸出ベンチマークに指定した。 この金利は、18の商業銀行からの貸出金利の加重平均に基づいている。これらの商業銀行は、公開市場操作でPBOCの流動性に対して入札した金額に基づいたLPR見積書を、毎月20日午前9時（中国標準時）までに全国銀行間資金調達センターに提出する。',
  'interest_rate_decision.description:ja:Mexico':
    'メキシコでは金利決定はメキシコ銀行（(Banco de México）が行う。2008年１月以来、商業銀行の中銀における当座預金残高水準（CORTO）に代えて翌日物銀行間金利を金利誘導目標としている。',
  'interest_rate_decision.description:ja:Philippines':
    'フィリピンでは、金利の決定はBangko Sentral ng Pilipinas (BSP)のThe Monetary Boardによって行われる。公式金利は翌日物借入金利であるリバースレポ金利（RR/P）である。フィリピン共和国の中央銀行は、物価の安定を促進・維持し、バランスのとれた持続可能な経済成長を促す強固な金融システムを実現するために、積極的なリーダーシップを発揮することを約束している。',
  'interest_rate_decision.description:ja:New Zealand':
    'ニュージーランドでは、ニュージーランド準備銀行により金利が決定される。公式政策金利となるのが、オフィシャルキャッシュレート（OCR）である。OCRは1999年3月に導入され、準備銀行により年8回見直される。OCRはニュージーランドでの借り入れコストに影響し、経済活動とインフレに影響を与える。',
  'interest_rate_decision.description:ja:India':
    'インドでは、インド準備銀行（RBI）の中央取締役会により政策金利が決定される。政策金利はベンチマークレポレート。RBIは2014年に金融政策の主要目的を物価安定と定め、政府による借り入れやルピーの為替レート安定、輸出保護の必要性の優先順位を引き下げた。政府と中央銀行は2015年2月、インフレ目標を消費者物価指数4%として、その上下2%に許容範囲を定め、2017年3月期からこれを施行することで合意した。',
  'interest_rate_decision.description:ja:South Africa':
    '南アフリカでは、金利の決定は南アフリカ準備銀行の金融政策委員会（MPC）が行う。公式金利はレポ金利である。これは、中央銀行が預金通貨銀行に貸し出したり適格債券を割り引いたりする際の金利で、通常、期末ベースで表示される。',
  'interest_rate_decision.description:ja:Poland':
    'ポーランドでは金利決定はポーランド国立銀行（NBP）が行い、政策金利はレファレンス金利である。',
  'interest_rate_decision.description:ja:United Kingdom':
    'イギリスの金融政策委員会（MPC）が基準利率を設定する。中央銀行であるイングランド銀行の公式金利はレポ金利である。このレポ金利は、イングランド銀行の取引相手（銀行、住宅金融組合、証券会社）に対する公開市場操作での利率となる。',
  'interest_rate_decision.description:ja:Thailand':
    'タイでは、金利の決定はタイ銀行の金融政策委員会が行う。主な金利は1日のレポ金利である。',
  'interest_rate_decision.description:ja:Canada':
    'カナダでは、基準金利が中央銀行であるカナダ銀行（BoC）理事会によって定められる。公式金利は翌日物レートとなっている。1996年以来、銀行金利は短期金融市場の翌日物レートでの運用幅の上限に設定されている。1980年3月から1996年2月までは、この金利は3カ月割引国債の週間入札金利平均より25ベーシスポイント上に設定されていた。',
  'interest_rate_decision.description:ja:Taiwan':
    '台湾では、金利の決定は中華民国（台湾）中央銀行が行う。公式金利は、銀行への10日間の融資に対する割引率である。',
  'interest_rate_decision.description:ja:South Korea':
    '韓国では、金利の決定は韓国銀行（BOK）の金融政策委員会によって行われる。公式金利は、2008年3月に翌日物コールレートから変更された韓国銀行基準金利である。',
  'interest_rate_decision.description:ja:Indonesia':
    'インドネシアでは、金利の決定はインドネシア共和国中央銀行が行っている。2016年4月、政策担当者は2016年8月に、公式割引金利を、新しい7日物リバース・レポ金利に交代させることを発表した。この金利は、中央銀行が預金通貨銀行に対して貸し出したり適格債券を割り引いたりする際のレートで、通常、期末ベースで表示される。',
  'interest_rate_decision.description:ja:Brazil':
    'ブラジルでは、ブラジル中銀の金融政策委員会（COPOM）が政策金利を決定する。政策金利は翌日物貸出金利であるSELIC（Special System of Clearance and Custody）金利である。',
  'interest_rate_decision.description:ja:Colombia':
    'コロンビアでは、金利の決定はコロンビア中央銀行（The Banco de la República）が行う。コロンビア中央銀行は、金利を変更することで金融政策を実施し、金融政策では経済に流動性を供給したり、市場の流動性を減少させたりする。公式金利は介入金利（Tasas）である。',
  'interest_rate_decision.description:ja:Russia':
    'ロシアでは金利の決定はロシア連邦中央銀行が行う。2013年9月16日より公式金利は1週間物レポ金利となった。2013年9月15日までの公式金利はリファイナンス金利であり、借手の金利の上限とみなされ、納税額を算出する際の基準であった。',
  'interest_rate_decision.description:ja:Israel':
    'イスラエルでは、金利の決定はイスラエル銀行が行う。公式金利は「ヘッドライン」金利と呼ばれる金利である。',
  'interest_rate_decision.description:ja:Hungary':
    'ハンガリーでは政策金利はハンガリー国立銀行（MNB）の金融理事会が決定する。主要政策金利は、中央銀行が金融機関に貸し付ける1年物融資のリファイナンス金利に基づく基準金利である。',
  'hia_new_home_sales_mom.description:ja:DEFAULT': '',
  'hia_new_home_sales_mom.description:ja:Australia':
    'オーストラリアの新築住宅販売件数は、民間住宅着工件数の年間市場シェアに基づくウェートを使用し、州ごとの推定値から得られた調査結果を基に集計されている。',
  'epiphany_holiday.description:ja:DEFAULT': '',
  'epiphany_holiday.description:ja:Spain': '',
  'bank_holiday_-_mid-year_accounts_closing.description:ja:DEFAULT': '',
  'bank_holiday_-_mid-year_accounts_closing.description:ja:India': '',
  'gdp_growth_rate_yoy_-_third_estimate.description:ja:DEFAULT': '',
  'gdp_growth_rate_yoy_-_third_estimate.description:ja:Poland':
    'ポーランドの消費者市場は3,800万人と、ヨーロッパでも最大級の規模を誇る。ポーランドの主要産業は、食品・飲料加工、造船、機械・鉄鋼製品・化学品・ガラス・繊維製品の製造である。支出面では、家計消費がGDPの60％を占めており、次いで総固定資本形成（20％）、政府支出（18％）である。財・サービスの輸出はGDPの47％を占め、輸入は46％で、GDP全体に1％寄与している。',
  'st_wenceslas_day.description:ja:DEFAULT': '',
  'st_wenceslas_day.description:ja:Czech Republic': '',
  'initial_jobless_claims.description:ja:DEFAULT': '',
  'initial_jobless_claims.description:ja:United States':
    '新規失業保険申請件数は金融市場に大きな影響を与える。というのも、失業保険を既に申請している人の数を測定する継続的な申請のデータとは異なり、新規失業保険申請は新たな失業者を測定するものだからである。',
  'gdp_growth_annualized-2_est.description:ja:DEFAULT': '',
  'gdp_growth_annualized-2_est.description:ja:Israel':
    'GDP年率成長率は、前四半期比のGDP成長率が1年を通じて維持されたと仮定した場合の成長率を示す。',
  'infrastructure_output_yoy.description:ja:DEFAULT': '',
  'infrastructure_output_yoy.description:ja:India':
    'インドでは、8つの基幹産業「製油所生産（ウェイト28％）、発電（19.9％）、鉄鋼生産（17.9％）、石炭生産（10.3％）、原油生産（8.9％）、天然ガス生産（6.9％）、セメント生産（5.4％）、肥料生産（2.6％）」のパフォーマンスを測る指標を合わせてインフラ生産と呼んでいる。インドの工業生産の約40％をインフラが占めている。',
  'inflation_report_hearings.description:ja:DEFAULT': '',
  'inflation_report_hearings.description:ja:United Kingdom': '',
  'boj_gov_kuroda_speech.description:ja:DEFAULT': '',
  'boj_gov_kuroda_speech.description:ja:Japan':
    '日本では、金利は政策委員会による金融政策決定会合で決められる。日銀の公式金利は、基準割引率である。金融政策決定会合では、次の会合までの間の金融市場操作におけるガイドラインが決められ、このガイドラインは無担保コール翌日物金利の目標として定められる。',
  'rba_financial_stability_review.description:ja:DEFAULT': '',
  'rba_financial_stability_review.description:ja:Australia':
    'オーストラリアでは金利決定はオーストラリア準備銀行理事会が行い、政策金利はキャッシュレートである。キャッシュレートとは、金融機関の間で翌日物貸付に適用される利率で、翌日物資金の需要と供給によってマネーマーケットで決定される。',
  'industrial_production_mom.description:ja:DEFAULT': '',
  'industrial_production_mom.description:ja:Mexico':
    'メキシコでは鉱工業生産は製造業、鉱業、公益事業など経済の鉱工業セクターに属する事業の生産量を測定する。',
  'nonfarm_productivity_qoq_prel.description:ja:DEFAULT': '',
  'nonfarm_productivity_qoq_prel.description:ja:United States':
    '米国では、非農業部門労働者の生産性は1労働時間当たりの財・サービス生産量で測定される。労働生産性は、実質生産指数を、被雇用者、経営者、無給の家族従事者を含むすべての人の労働時間指数で除して算出する。',
  'inflation_rate_yoy.description:ja:Australia':
    'オーストラリアでは、消費者物価指数の主要な構成項目は、住居費（全ウエートの22.3%）、食品・非アルコール飲料（16.8%）、娯楽・文化（12.6%）、輸送（11.6%）、家具・住宅設備・住宅サービス（9.1%）、アルコール飲料・タバコ（7.1%）、医療（5.3%）、保険・金融サービス（5.1%）で、衣料品・履物、教育、通信が残りの10.2%を占める。',
  'inflation_rate_yoy.description:ja:Hong Kong':
    '香港の総合消費者物価指数は、香港全域で毎月実施される調査に基づいている。この指標は、9つの主要グループに分類された980の消費財・サービスで構成されている。住宅が全体の31.6％、食品が27.5％、教育、通信、情報、医療などの雑多なサービスが15.9％を占めている。運輸は全体の8.5%、耐久財は5.2%、雑多な財は4.2%、衣料・履物は3.4%を占めている。電気・ガス・水道は3.1％、酒類・タバコは0.6％である。',
  'inflation_rate_yoy.description:ja:Germany':
    'ドイツでは、消費者物価指数の中で最も重要なカテゴリーは、住居・水道・電気・ガスその他燃料（全ウエートの32％）、運輸（13％）、娯楽・エンターテインメント・文化（11％）、食品・非アルコール飲料（10％）で、また、同指数には、雑貨・サービス（7％）、家具・照明機器・家電・その他住宅備品（5％）、レストラン・ホテル（5％）、医療（5％）、衣料品・履物（5％）が含まれ、残りの7％はアルコール飲料、タバコ、通信、教育である。',
  'inflation_rate_yoy.description:ja:Poland':
    'ポーランドでは、消費者物価指数の中で最も重要なカテゴリーは、食品および非アルコール飲料（全ウエートの24％）、住居/エネルギー/維持（21％）、輸送（9％）、娯楽・文化（7％）、アルコール飲料・タバコ、その他の財とサービス、および衣料品（それぞれ６％）で、残りの17％は、通信、レストラン、ホテル、住居備品、教育が占める。',
  'inflation_rate_yoy.description:ja:Finland':
    'フィンランドでは、消費者物価指数の中で最も重要なカテゴリーは、住宅、水道、電気、ガス、その他燃料（全体の24.6％）である。食品とノンアルコール飲料が13.5パーセント、輸送が13.4パーセント、レクリエーションと文化が11.5パーセントとなっている。フィンランドの消費者物価指数には、雑多な商品とサービス（7.4%）、レストランとホテル（6.8%）、家具、家庭用機器、日常的な家庭メンテナンス（5.4%）も含まれている。残りの17.3%は、健康、アルコール飲料とタバコ、衣類と履物、通信と教育である。',
  'inflation_rate_yoy.description:ja:New Zealand':
    'ニュージーランドでは、消費者物価指数の中で最も重要なカテゴリーは「住宅・光熱費」（全体の24.2％）である。食品・非アルコール飲料」が18.8％、「輸送」が15％、「レクリエーション・文化」が9.4％、「その他の商品・サービス」が7.4％、「アルコール飲料・タバコ」が7％となっている。残りの18.2%は、家具・家庭用品・メンテナンス、衣類・履物、健康、通信、教育である。',
  'inflation_rate_yoy.description:ja:India':
    '2013年に消費者物価指数が卸売物価指数（WPI）に代わりインドのインフレ率の主要指標となった。インドでは消費者物価指数の最も重要なカテゴリーは食品・飲料（全ウェートの45.86%）であり、うち穀物と穀類製品が9.67%、牛乳と乳製品が6.61%、野菜が6.04%、加工食品・スナック・菓子等が5.55%、肉・魚類が3.61%、油と油脂が3.56%である。その他カテゴリーは28.32%を占め、うち輸送・通信が8.59%、医療が5.89%、教育が4.46%。住居カテゴリーは10.07%、燃料と照明は6.84%、衣類・履物は6.53%、インドの嗜好品パーン、タバコ、酒類は2.38％。インドでは、輸入エネルギーへの依存度が高いことと劣悪な道路状況とインフラにより食品を市場に輸送するのが困難であること、そして多額の財政赤字により、消費者物価指数は非常に変動が激しい。',
  'inflation_rate_yoy.description:ja:United Kingdom':
    'イギリスでは、消費者物価指数の中で最も重要なカテゴリーは、「交通」（全体の16％）と「レクリエーションと文化」（15％）である。 住宅、水道、電気、ガス、その他の燃料が13％、レストランとホテルが12％、食品とノンアルコール飲料が10％となっている。 また、この指数には 雑多な商品とサービス（9％）、衣類と履物（7％）、家具、家庭用機器とメンテナンス（6％）、酒類・たばこ、健康・コミュニケーション・教育（残りの11％）も含まれている。',
  'inflation_rate_yoy.description:ja:Ireland':
    'アイルランドの消費者物価指数で最も重要なカテゴリーは、住宅、水道、電気、ガス、その他燃料（全体の17％）、交通（15％）、レストランとホテル（14％）、食品とノンアルコール飲料（11％）となっている。また、レクリエーションと文化（8％）、衣類と履物（5％）、アルコール飲料とタバコ（5％）、健康（5％）、通信（4％）も含まれている。残りの16％は、家具、家庭用品、教育、その他の商品・サービスである。',
  'inflation_rate_yoy.description:ja:Italy':
    'イタリアでは、消費者物価指数の中で最も重要なカテゴリーは、食品および非アルコール飲料（全ウエートの16％）、運輸（15％）、レストラン・ホテル（12％）、住居・水道・電気その他燃料（10％）、その他の財およびサービス（10％）である。また、同指数には、医療（9％）、娯楽・文化（8％）、衣料品・履物（7％）、家具と住宅備品（7％）も含まれ、残りの6％はアルコール飲料、タバコ、通信、教育である。',
  'inflation_rate_yoy.description:ja:Netherlands':
    'オランダでは、消費者物価指数の中で最も重要なカテゴリーは、「住宅、水道、電気、ガス」（全体の24.5％）、「交通」（11.6％）、「食品とノンアルコール飲料」（11.3％）となっている。また、レクリエーション・文化（10.3％）、家具・家庭用品（6％）、衣類・履物（4.9％）、ホテル・レストラン（4.2％）、通信（3.3％）、アルコール飲料・タバコ（3.1％）も含まれており、残りの20.8%は健康、教育、その他の財・サービスである。',
  'inflation_rate_yoy.description:ja:South Korea':
    '韓国では、消費者物価指数の中で最も重要なカテゴリーは、「住宅、水道、電気、ガス、その他の燃料」（全体の17％）と「食品とノンアルコール飲料」（全体の13.6％）である。その他には、レストラン・ホテル（12％）、教育（11.4％）、交通（10.9％）、健康（7.3％）、衣類・履物（6.2％）などがある。最も少ない構成要素は、通信（5.8％）、雑多な商品とサービス（5.4％）、娯楽と文化（5.3％）、家具、家庭用機器、日常的なメンテナンス（3.8％）、アルコール飲料とタバコ（1.24％）である。',
  'inflation_rate_yoy.description:ja:Taiwan':
    '台湾の消費者物価指数は、7つのカテゴリーで構成されている。食品（全体の23.7％）、住宅（22.7％）、交通・通信（15.3％）、教育・娯楽（14.72％）、雑貨・サービス（14.67％）、衣料品（4.6％）、医薬品・医療（4.3％）である。',
  'inflation_rate_yoy.description:ja:Canada':
    'カナダでは、消費者物価指数バスケットの中で最も重要なカテゴリーは「住居」（全体の27.5％）と「交通」（19.3％）である。 食品は16.1パーセント、家庭用品・家具・設備は11.8パーセント、娯楽・教育・読書は11.8パーセント、衣類・履物は5.7パーセント、健康・パーソナルケアは5パーセント、アルコール飲料・タバコ製品（残りの3パーセント）となっている。消費者物価指数バスケットは、家計調査をもとに4年ごとに見直される。現在の構成比は2009年の支出パターンに基づいている。',
  'inflation_rate_yoy.description:ja:Indonesia':
    'インドネシアでは、消費者物価指数 (CPI) は、特定の人口が消費のために取得、使用、または支払う財やサービスの価格の一般的な水準の一定期間における変化を示す指標である。消費者物価指数は、インドネシアの44の州都と県の首都の都市人口を対象としている。インドネシアの消費者物価指数で最も重要なカテゴリーは、「食品、飲料、タバコ」（全体の25%）、「住宅、水道、電気、家庭用燃料」（20.4%）、「交通」（12.4%）、「食品・飲料業者/レストラン」（8.7%）となっている。この指数には、家庭用機器、工具、定期的なメンテナンス（6％）、パーソナルケアおよびその他のサービス（5.9％）、情報・通信・金融サービス（5.8％）、教育（5.6％）、衣料および履物（5.4％）、健康、レクリエーション、スポーツ、文化（残りの4.7%）も含まれている。',
  'inflation_rate_yoy.description:ja:South Africa':
    '南アフリカでは、消費者物価指数の中で最も重要なカテゴリーは、「住宅・水道光熱費」（全体の24.5％）、「交通費」（16.4％）、「食品・非アルコール飲料」（15.4％）である。その他には、パーソナルケア、保険、金融などの雑多な商品・サービス（14.7％）、酒類・タバコ（5.4％）、家庭用品・機器・メンテナンス（4.8％）、レクリエーション・文化（4.1％）、衣類・履物（4.1％）、レストラン・ホテル、教育・通信・健康（残りの10％）が含まれている。消費者物価指数バスケットは2013年1月に改定された。',
  'inflation_rate_yoy.description:ja:France':
    'フランスでは、消費者物価指数の中で最も重要なカテゴリーはサービス（全ウエートの48％）で、特に住居の賃料とサービス（7％）、医療サービス（6％）、運輸（3％）、通信（2％）が重要である。また、同指数には、医療製品（4％）や衣料品・履物（4％）などの製造品（26％）、食料（16％）、石油製品（4％）などのエネルギー（8％）、タバコ（2％）も含まれる。',
  'inflation_rate_yoy.description:ja:Belgium':
    'ベルギーでは、消費者物価指数で最も重要なカテゴリーは「住宅・水道光熱費」（全体の17.6％）である。「食品・非アルコール飲料」は16.4％、「輸送」は15.9％、「娯楽・文化」は9.3％、「その他の商品・サービス」は9％、「レストラン・ホテル」は7.8％となっている。残りの24％は、家具・家庭用品・メンテナンス、衣類・履物、通信、健康、酒類・たばこ、教育となっている。',
  'inflation_rate_yoy.description:ja:United States':
    '米国では、都市部の全消費者を対象とした調整前消費者物価指数は、食料品（全体の14％）、エネルギー（9.3％）、日用品（食料品とエネルギー商品を除く19.4％）、サービス（エネルギーサービスを除く、57.3％）で構成される市場バスケットの価格に基づいて算出されている。「サービス」のカテゴリーは、住居（32.1％）、医療サービス（5.8％）、輸送サービス（5.5％）で構成されている。',
  'inflation_rate_yoy.description:ja:Singapore':
    'シンガポールでは、消費者物価指数の中で最も重要なカテゴリーは、住宅（全体の25％）と食品（22％）である。また、交通費（16％）、教育費（7％）、健康費（6％）、通信費（5％）、衣類・履物（3％）も含まれている。娯楽、アルコール飲料、タバコ、その他が残りの16％を占めている。',
  'inflation_rate_yoy.description:ja:Japan':
    '日本の消費者物価指数で最も重要なカテゴリーは、食品（全体の25％）と住宅（21％）である。交通・通信が14％、文化・娯楽が11.5％、燃料・光熱・水道料金が7％、医療が4.3％、衣料・履物が4％となっている。 残りは、家具・家庭用品、教育、その他の商品・サービスとなっている。',
  'inflation_rate_yoy.description:ja:Norway':
    'ノルウェーでは、消費者物価指数の中で最も重要なカテゴリーは「住宅・光熱費」（全体の23％）である。交通機関が16％、食品と非アルコール飲料が13％、娯楽と文化が12％、その他の商品とサービスが9％、家具、家庭用品、メンテナンスが7％を占めている。レストラン・ホテル、衣類・履物、アルコール飲料・タバコ、健康、通信・教育が残りの21％を占めている。',
  'inflation_rate_yoy.description:ja:Mexico':
    'メキシコでは、消費者物価指数バスケットの中で最も重要なカテゴリーは「非食品」（全体の19.7％）、「住宅」（18.7％）、「その他のサービス」（18.4％）である。食品、飲料、たばこは14.8パーセント、エネルギーは9.5パーセントである。その他は以下の通り－政府の補助金を受けた製品（5.3％）、教育（5.1％）、肉と卵（4.8％）、果物と野菜（3.7％）。消費者物価指数は、2010年12月時点の100を基準としている。全国の指数は、46の大・中・小都市を対象としている。',
  'inflation_rate_yoy.description:ja:Euro Area':
    'ユーロ圏では、インフレ率は調和消費者物価指数（HICP）合計の加重平均を用いて算出される。主要コンポーネントは、食品・アルコール・タバコ（全ウェートの19%）、エネルギー（11%）、非エネルギー 工業製品（29%）、サービス（41％）。HICP集計値は各国のHICPコンポーネントの加重平均として算出され、各国のウエートはその国のグループ合計における家計の最終支出の割合であり、各国HICPは各国の統計局がユーロスタットに提供する。',
  'inflation_rate_yoy.description:ja:Philippines':
    'フィリピンでは、消費者物価指数の中で最も重要なカテゴリーは、食品とノンアルコール飲料（全体の39％）、住宅、水道、電気、ガス、その他の燃料（22％）、交通（8％）となっている。また、健康（3％）、教育（3％）、衣類および履物（3％）、通信（2％）、娯楽および文化（2％）も含まれている。残りの15％は、アルコール飲料、タバコ、家具、家庭用機器、レストラン、その他の商品・サービスである。',
  'inflation_rate_yoy.description:ja:Colombia':
    'コロンビアでは、消費者物価指数の主な構成要素は次の3つである。住宅・光熱費（全体の33.1％）、食品・非アルコール飲料（15.1％）、交通費（12.9％）。その他は、レストラン・ホテル（9.4％）、雑多な商品・サービス（5.4％）、教育（4.4％）、情報・通信（4.3％）。残りの15.4%は、「家具・衣類・履物」、「娯楽・文化」、「アルコール飲料・タバコ」、「健康」が占めている。消費者物価指数は、国内で登録された最大の人口を占め、国内の他の都市と比較して最もダイナミックな経済環境を持つ、一部の都市圏を含む、38の首都圏区の都市人口を対象としている。',
  'inflation_rate_yoy.description:ja:Brazil':
    'ブラジルでは、インフレ率は、消費者が標準的な商品のバスケットに支払う価格の広範な上昇または下降を測定する。 この指数の最も重要なカテゴリーは、 食品・飲料（総ウェイトの26％）、交通費（18％）、住居費（15％）、医療費（12％）、個人的支出（11％）である。また、衣類が6％、教育が5％、家庭用品が4％、通信が4％となっている。データは、サンパウロ、リオデジャネイロ、ベロオリゾンテ、ポルトアレグレ、ベレン、フォルタレザ、レシフェ、サルバドール、クリチバの大都市圏と、ゴイアニア、ブラジリアの各都市で収集されている。',
  'inflation_rate_yoy.description:ja:Switzerland':
    'スイスでは、消費者物価指数の中で最も重要なカテゴリーは次のとおりである。住宅とエネルギー（全体の27％）と医療（18％）、そして交通費が10％、食品とノンアルコール飲料が12％、レストランとホテルが7％、レクリエーションと文化が7％となっている。 また、この指数には 雑多な商品およびサービス（6％）、家庭用品およびサービス（4％）、衣料および履物（3％）、通信、酒類・タバコ、教育（残りの6％）も含まれている。',
  'inflation_rate_yoy.description:ja:Portugal':
    'ポルトガルでは、インフレ率が、標準的な財のバスケットに対し消費者が支払う価格の全体的な上下変動を測定する。ポルトガルの消費者物価指数の中で最も重要なカテゴリーは、食品および非アルコール飲料（全ウエートの20.8％）、輸送（14%）、その他財とサービス（10.4％）、住居・水道・電気・ガス・その他燃料（10.3%）で、レストラン・ホテルは8.6%、娯楽・文化は7.2%、衣料品・履物は6.9%、医療は6.3%、家具・住居備品・住居維持は6.2%で、残りの9.4%はアルコール飲料・タバコ、通信、教育が占める。',
  'inflation_rate_yoy.description:ja:China':
    '中国では、消費者物価指数バスケットの最も重要な構成要素は食品（全体の31.8％）と住居（17.2％）である。レクリエーション、教育、文化用品は13.8パーセント、交通・通信は10パーセント、医療・個人用品は9.6パーセント、衣料品は8.5パーセント、家庭用施設・用品・サービスは5.6パーセント、残りの3.5パーセントはタバコ・酒・用品となっています。消費者物価指数バスケットは、家計調査をもとに5年ごとに見直される。国家統計局によると、新しい消費パターンや経済発展を反映して改定されており、前回の改定は2011年に行われた。',
  'inflation_rate_yoy.description:ja:Spain':
    'スペインでは、消費者物価指数の中で最も重要なカテゴリーは、食品および非アルコール飲料（全ウエートの20％）、輸送（15%）、住居と光熱費（13％）、ホテル、カフェ、レストラン（12％）で、娯楽・文化（9%）、その他の財とサービス（7%）、衣料品・履物（7%）も含まれる。残りの18%は、家具・住居備品・住居維持、医療、通信、アルコール飲料・タバコ、教育が占める。',
  'inflation_rate_yoy.description:ja:Russia':
    'ロシアでは、消費者物価指数の中で最も重要なカテゴリーは、食品および非アルコール飲料（全ウエートの30％）、輸送（14％）で、同指数には、衣料品と履物（11％）、住居・水道・電気・ガスその他燃料（11％）、娯楽・文化活動（6％）、アルコール飲料・タバコ（6％）、家電（６％）も含まれる。残りの16％は、医療、通信、教育、ホテル、レストラン、その他の財とサービスが占める。',
  'inflation_rate_yoy.description:ja:Czech Republic':
    'チェコでは、消費者物価指数で最も重要なカテゴリーは「住宅・公共料金」（全体の26.6％）である。「食品・非アルコール飲料」は17.1％、「輸送」は10.1％、「アルコール飲料・タバコ」は9.5％、「レクリエーション・文化」は8.8％、「その他の商品・サービス」は6.8％となっている。残りの21.1%は、家具・家庭用品・メンテナンス、レストラン・ホテル、衣類・履物、通信、健康・教育である。',
  'inflation_rate_yoy.description:ja:Denmark':
    'デンマークでは、消費者物価指数で最も重要なカテゴリーは「住宅・水道光熱費」（全体の29.4％）である。食品・非アルコール飲料」は12.2パーセント、「交通」は11.8パーセント、「娯楽・文化」は10.9パーセント、「雑多な商品・サービス」は9.6パーセント、「レストラン・ホテル」は6.2パーセントとなっている。残りの19.9%は、家具・家庭用品・メンテナンス、衣類・履物、アルコール飲料・タバコ、保健・通信・教育となっている。',
  'inflation_rate_yoy.description:ja:Israel':
    'イスラエルでは、消費者物価指数の中で最も重要なカテゴリーは、「住宅」（全体の25.2％）と「交通・通信」（20.6％）である。「食品」は16.5パーセント、「教育・文化・娯楽」は11.7パーセント、「住居の維持」は9.7パーセントとなっている。その他は、健康（5.2％）、雑多な商品とサービス（4.3％）、家具と家庭用機器（3.7％）、衣類と履物（3.1％）である。全国の指数は、2012年時点で100を基準としている。',
  'inflation_rate_yoy.description:ja:Hungary':
    'ハンガリーでは、消費者物価指数で最も重要なカテゴリーは「食品と非アルコール飲料」（全体の21.3%）である。「輸送」が16.2パーセント、「住宅・水道光熱費」が13.2パーセント、「アルコール飲料・タバコ」が8.3パーセント、「娯楽・文化」が7.7パーセント、「その他の商品・サービス」が7.3パーセントとなっている。レストラン・ホテル、健康、家具・家庭用品・メンテナンス、通信、衣類・履物、教育が残りの26％を占めている。',
  'inflation_rate_yoy.description:ja:Greece':
    'ギリシャでは、消費者物価指数の中で最も重要なカテゴリーは、食品とノンアルコール飲料（全体の17％）、輸送（13％）、住宅（12％）、ホテル、カフェ、レストラン（11％）である。また、衣服・履物（9％）、健康（7％）、家具・家庭用品（7％）、娯楽・文化（5％）、通信（5％）も含まれている。教育、アルコール飲料、タバコ、その他の商品・サービスが残りの14％を占めている。',
  'inflation_rate_yoy.description:ja:Austria':
    'オーストリアでは、インフレ率は、消費者が標準的な商品バスケットに支払う価格の広範な上昇または下降を測定する。.',
  'inflation_rate_yoy.description:ja:Sweden':
    'スウェーデンでは、消費者物価指数の中で最も重要なカテゴリーは「住宅・水道光熱費」（全体の23％）である。「食品・非アルコール飲料」が14％、「交通」が14％、「娯楽・文化」が12％、「レストラン・ホテル」が7％、「その他の商品・サービス」が7％となっている。残りの22.5%は、家具・家庭用品・メンテナンス、衣類・履物、アルコール飲料・タバコ、健康、通信・教育である。',
  'inflation_rate_mom.description:ja:DEFAULT': '',
  'inflation_rate_mom.description:ja:Greece':
    'ギリシャでは、消費者物価指数の中で最も重要なカテゴリーは、食品とノンアルコール飲料（全体の17％）、輸送（13％）、住宅（12％）、ホテル、カフェ、レストラン（11％）である。また、衣服・履物（9％）、健康（7％）、家具・家庭用品（7％）、娯楽・文化（5％）、通信（5％）も含まれている。教育、アルコール飲料、タバコ、その他の商品・サービスが残りの14％を占めている。',
  'inflation_rate_mom.description:ja:Italy':
    'イタリアでは、消費者物価指数の中で最も重要なカテゴリーは、食品および非アルコール飲料（全ウエートの16％）、運輸（15％）、レストラン・ホテル（12％）、住居・水道・電気その他燃料（10％）、その他の財およびサービス（10％）である。また、同指数には、医療（9％）、娯楽・文化（8％）、衣料品・履物（7％）、家具と住宅備品（7％）も含まれ、残りの6％はアルコール飲料、タバコ、通信、教育である。',
  'inflation_rate_mom.description:ja:Germany':
    'ドイツでは、消費者物価指数の中で最も重要なカテゴリーは、住居・水道・電気・ガスその他燃料（全ウエートの32％）、運輸（13％）、娯楽・エンターテインメント・文化（11％）、食品・非アルコール飲料（10％）で、また、同指数には、雑貨・サービス（7％）、家具・照明機器・家電・その他住宅備品（5％）、レストラン・ホテル（5％）、医療（5％）、衣料品・履物（5％）が含まれ、残りの7％はアルコール飲料、タバコ、通信、教育である。',
  'inflation_rate_mom.description:ja:Poland':
    'ポーランドでは、消費者物価指数の中で最も重要なカテゴリーは、食品および非アルコール飲料（全ウエートの25％）、住居・水道・電気その他燃料（19％）、運輸（10％）、娯楽・文化（6％）、アルコール飲料・タバコ（6％）、レストラン・ホテル（6％）、家具・住宅備品、通常の住居維持（6％）、その他の財およびサービス（6％）で、残りの15％は、医療、衣料品と履物、通信、教育である。',
  'inflation_rate_mom.description:ja:Brazil':
    '前月比インフレ率は、財とサービスの価格の前月比変化を測定する。',
  'inflation_rate_mom.description:ja:South Korea':
    '前月比インフレ率は、財とサービスの価格の前月比変化を測定する。',
  'inflation_rate_mom.description:ja:Taiwan':
    '前月比インフレ率は、財とサービスの価格の前月比変化を測定する。',
  'inflation_rate_mom.description:ja:Canada':
    '前月比インフレ率は、財とサービスの価格の前月比変化を測定する。',
  'inflation_rate_mom.description:ja:Indonesia':
    'インドネシアでは、消費者物価指数 (CPI) は、特定の人口が消費のために取得、使用、または支払う財やサービスの価格の一般的な水準の一定期間における変化を示す指標である。消費者物価指数は、インドネシアの44の州都と県の首都の都市人口を対象としている。インドネシアの消費者物価指数で最も重要なカテゴリーは、「食品、飲料、タバコ」（全体の25%）、「住宅、水道、電気、家庭用燃料」（20.4%）、「交通」（12.4%）、「食品・飲料業者/レストラン」（8.7%）となっている。この指数には、家庭用機器、工具、定期的なメンテナンス（6％）、パーソナルケアおよびその他のサービス（5.9％）、情報・通信・金融サービス（5.8％）、教育（5.6％）、衣料および履物（5.4％）、健康、レクリエーション、スポーツ、文化（残りの4.7%）も含まれている。',
  'inflation_rate_mom.description:ja:Euro Area':
    '前月比インフレ率は、財とサービスの価格の前月比変化を測定する。',
  'inflation_rate_mom.description:ja:Colombia':
    '前月比インフレ率は、財とサービスの価格の前月比変化を測定する。',
  'inflation_rate_mom.description:ja:Philippines':
    '前月比インフレ率は、財とサービスの価格の前月比変化を測定する。',
  'inflation_rate_mom.description:ja:Finland':
    '前月比インフレ率は、財とサービスの価格の前月比変化を測定する。',
  'inflation_rate_mom.description:ja:United States':
    '前月比インフレ率は、財とサービスの価格の前月比変化を測定する。',
  'inflation_rate_mom.description:ja:United Kingdom':
    '前月比インフレ率は、財とサービスの価格の前月比変化を測定する。',
  'inflation_rate_mom.description:ja:Ireland':
    '前月比インフレ率は、財とサービスの価格の前月比変化を測定する。',
  'inflation_rate_mom.description:ja:Switzerland':
    'スイスでは、消費者物価指数の中で最も重要なカテゴリーは次のとおりである。住宅とエネルギー（全体の27％）と医療（18％）、そして交通費が10％、食品とノンアルコール飲料が12％、レストランとホテルが7％、レクリエーションと文化が7％となっている。 また、この指数には 雑多な商品およびサービス（6％）、家庭用品およびサービス（4％）、衣料および履物（3％）、通信、酒類・タバコ、教育（残りの6％）も含まれている。',
  'inflation_rate_mom.description:ja:Singapore':
    '前月比インフレ率は、財とサービスの価格の前月比変化を測定する。',
  'inflation_rate_mom.description:ja:Japan':
    '前月比インフレ率は、財とサービスの価格の前月比変化を測定する。',
  'inflation_rate_mom.description:ja:Norway':
    '前月比インフレ率は、財とサービスの価格の前月比変化を測定する。',
  'inflation_rate_mom.description:ja:Mexico':
    '前月比インフレ率は、財とサービスの価格の前月比変化を測定する。',
  'inflation_rate_mom.description:ja:South Africa':
    '前月比インフレ率は、財とサービスの価格の前月比変化を測定する。',
  'inflation_rate_mom.description:ja:Belgium':
    '前月比インフレ率は、財とサービスの価格の前月比変化を測定する。',
  'inflation_rate_mom.description:ja:France':
    'フランスでは、消費者物価指数の中で最も重要なカテゴリーはサービス（全ウエートの48％）で、特に住居の賃料とサービス（7％）、医療サービス（6％）、運輸（3％）、通信（2％）が重要である。また、同指数には、医療製品（4％）や衣料品・履物（4％）などの製造品（26％）、食料（16％）、石油製品（4％）などのエネルギー（8％）、タバコ（2％）も含まれる。',
  'inflation_rate_mom.description:ja:Spain':
    '前月比インフレ率は、財とサービスの価格の前月比変化を測定する。',
  'inflation_rate_mom.description:ja:Russia':
    '前月比インフレ率は、財とサービスの価格の前月比変化を測定する。',
  'inflation_rate_mom.description:ja:Czech Republic':
    '前月比インフレ率は、財とサービスの価格の前月比変化を測定する。',
  'inflation_rate_mom.description:ja:Israel':
    '前月比インフレ率は、財とサービスの価格の前月比変化を測定する。',
  'inflation_rate_mom.description:ja:Hungary':
    '前月比インフレ率は、財とサービスの価格の前月比変化を測定する。',
  'inflation_rate_mom.description:ja:Austria':
    '前月比インフレ率は、財とサービスの価格の前月比変化を測定する。',
  'inflation_rate_mom.description:ja:Sweden':
    '前月比インフレ率は、財とサービスの価格の前月比変化を測定する。',
  'inflation_rate_mom.description:ja:China':
    '前月比インフレ率は、財とサービスの価格の前月比変化を測定する。',
  'inflation_rate_mom.description:ja:Portugal':
    '前月比インフレ率は、財とサービスの価格の前月比変化を測定する。',
  'hari_raya_puasa.description:ja:DEFAULT': '',
  'hari_raya_puasa.description:ja:Singapore': '',
  'inflation_rate_ex-food_and_energy_yoy.description:ja:DEFAULT': '',
  'inflation_rate_ex-food_and_energy_yoy.description:ja:Japan':
    '日本ではコア・インフレ率によって、生鮮食品を除いた財のバスケットに対して消費者が支払う価格の変動を測定する。',
  '2nd_christmas_day.description:ja:DEFAULT': '',
  '2nd_christmas_day.description:ja:Denmark': '',
  'industrial_profits_ytd_yoy.description:ja:DEFAULT': '',
  'industrial_profits_ytd_yoy.description:ja:China':
    '中国では、年間売上高が500万元以上のすべての国有工業企業および非国有工業企業の利益総額を企業利益と呼んでいる。',
  'day_of_good_will.description:ja:DEFAULT': '',
  'day_of_good_will.description:ja:South Africa': '',
  'industrial_production_yoy_final.description:ja:DEFAULT': '',
  'industrial_production_yoy_final.description:ja:Japan':
    '日本では鉱工業生産は製造業、鉱業、公益事業など経済の鉱工業セクターに属する事業の生産量を測定する。',
  'industrial_production_mom.description:ja:Israel': '',
  'industrial_production_mom_final.description:ja:DEFAULT': '',
  'industrial_production_mom_final.description:ja:Japan':
    '日本では鉱工業生産は製造業、鉱業、公益事業など経済の鉱工業セクターに属する事業の生産量を測定する。',
  'greek_parliament_elections.description:ja:DEFAULT': '',
  'greek_parliament_elections.description:ja:Greece': '',
  'spring_budget_2020.description:ja:DEFAULT': '',
  'spring_budget_2020.description:ja:United Kingdom': '',
  'industrial_production_mom.description:ja:Hungary': '',
  'industrial_production_mom.description:ja:Finland': '',
  'industrial_production_mom.description:ja:Poland':
    'ポーランドでは鉱工業生産は経済の鉱工業セクターに属する事業の生産量を測定する。製造業は最も重要なセクターで、全生産量の86%を占める。製造業の中でも最大の部門は、食料品（全生産量の16％） 、自動車・トレーラー・セミトレーラー（10％）、金属製品（7％）、ゴム・プラスチック製品（6％）、電気的装置（5％）、コークス・精製石油製品（5％）、化学薬品・化学製品（4％）、その他非金属鉱物製品（4％）、卑金属（4％）。 電力・ガス・蒸気・空調は全生産量の8％、鉱業・採石業は3％、上下水道・廃棄物管理・修理は2％を占める。',
  'industrial_production_mom.description:ja:United States':
    '米国では鉱工業生産は経済の鉱工業セクターに属する事業の生産量を測定する。製造業は最も重要なセクターで、全生産量の78%を占める。製造業の中でも最大の部門は、化学（12%）、食料品・飲料・タバコ（11%）、機械（6%）、金属製品（6%）、コンピューター・電子製品（6%）、自動車と自動車部品（6%）。鉱業と採石業は11%、公益事業が残りの11%を占める。',
  'industrial_production_mom.description:ja:Czech Republic':
    'チェコ共和国では鉱工業生産は製造業、鉱業、公益事業など経済の鉱工業セクターに属する事業の生産量を測定する。',
  'industrial_production_mom.description:ja:United Kingdom':
    '英国では鉱工業生産は経済の鉱工業セクターに属する事業の生産量を測定する。製造業は最も重要なセクターで、全生産量の70%を占める。製造業の中でも最大の部門は、食料品・飲料・タバコ（11%）、輸送機器（9%）、卑金属（8%）、その他製造および修理（6%）、医薬品および調剤（6%）、ゴム・プラスチック製品および非金属鉱物（6%）。鉱業と採石業は12%、石油・ガス採掘業は10%を占める。電気・ガス・蒸気・空調は生産量全体の11%、上下水道および廃棄物管理は8%を占める。',
  'industrial_production_mom.description:ja:Brazil':
    'ブラジルでは鉱工業生産は製造業、鉱業、公益事業など経済の鉱工業セクターに属する事業の生産量を測定する。',
  'industrial_production_mom.description:ja:Portugal':
    'ポルトガルでは鉱工業生産は経済の鉱工業セクターに属する事業の生産量を測定する。製造業は最も重要なセクターで、全生産量の93.9%を占める。製造業の中でも最大の部門は、食料品（全生産量の13％） 、コークス・精製石油製品・燃料（10％）、自動車・トレーラー・セミトレーラー（9％）、金属製品（機械・機器を除く）（7％）、薬品を除く化学薬品・ 合成または人工繊維（5％）、ゴム・プラスチック製品（5％）、紙製品（5％）。電力・ガス・蒸気・空調は全生産量の5％、上下水道・廃棄物管理は1％を占める。',
  'industrial_production_mom.description:ja:Italy':
    'イタリアでは鉱工業生産は経済の鉱工業セクターに属する事業の生産量を測定する。製造業は最も重要なセクターで、全生産量の88%を占める。製造業の中でも最大の部門は、冶金と金属製品（全生産量の14%）、機械・機器（12%）、食料品・飲料・タバコ（10%）、ゴム・プラスチック・非金属鉱物製品（9%）、繊維・衣料・革製品（8%）、輸送機器 （7%）その他機械および機器の製造・修理・設置（7%）。電気・ガス・蒸気・空調は全体の生産量の10%、鉱業と採石業は1%を占める。',
  'industrial_production_mom.description:ja:South Korea':
    '韓国では鉱工業生産は製造業、鉱業、公益事業など経済の鉱工業セクターに属する事業の生産量を測定する。',
  'industrial_production_mom.description:ja:Netherlands':
    'オランダでは鉱工業生産は製造業、鉱業、公益事業など経済の鉱工業セクターに属する事業の生産量を測定する。',
  'industrial_production_mom.description:ja:Singapore':
    'シンガポールでは鉱工業生産は製造業、鉱業、公益事業など経済の鉱工業セクターに属する事業の生産量を測定する。',
  'industrial_production_mom.description:ja:Germany':
    'ドイツでは鉱工業生産は経済の鉱工業セクターに属する事業の生産量を測定する。製造業は最も重要なセクターで、全生産量の79％を占める。製造業の中でも最大の部門は、機械・機器（全生産量の12％）、 自動車・トレーラー・セミトレーラー（12％）、卑金属・金属加工製品（10％）、コンピューター、電子・光学製品および電気的装置（10％）。建設業は全生産量の11％、エネルギー生産量は10％を占める。',
  'industrial_production_mom.description:ja:Sweden':
    'スウェーデンでは鉱工業生産は製造業、鉱業など経済の鉱工業セクターに属する事業の生産量を測定する。',
  'industrial_production_mom.description:ja:Denmark':
    'デンマークでは鉱工業生産は経済の鉱工業セクターに属する事業の生産量を測定する。製造業は最も重要なセクターで、全生産量の96%を占める。製造業の中でも最大の部門は、食料品・飲料・タバコ（全生産量の23%）、機械（19%）、医薬品（12%）、化学薬品と精製石油製品（10%）、家具およびその他の製造業（8%）、卑金属・金属加工製品（7%）、プラスチック・ガラス・コンクリート（5%）。鉱業・採石業は全生産量の4%を占める。',
  'industrial_production_mom.description:ja:Belgium':
    'ベルギーでは鉱工業生産は経済の鉱工業セクターに属する事業の生産量を測定する。製造業は最も重要なセクターで、中でも最大の部門は、化学製品（全生産量の19％）、食料品・飲料（16％）、卑金属（11％）、自動車・トレーラー・セミトレーラー（10％）、医薬品（8％）機械・機器（5％）、金属加工製品（5％）。',
  'industrial_production_mom.description:ja:Euro Area':
    'ユーロ圏では鉱工業生産は製造業、鉱業、公益事業など経済の鉱工業セクターに属する事業の生産量を測定する。',
  'industrial_production_mom.description:ja:Norway':
    'ノルウェーの工業生産は、工業部門に属する企業の生産を示している。採掘・関連サービス業は最も重要な部門で、総生産の67%を占めている。採掘・関連サービス部門の中で最も大きな部門は天然ガスの採掘（全体の31％）と原油の採掘（30％）である。製造業は総生産の27％を占め、主なものは、食品、飲料、タバコ（5％）、石油精製品、化学品、医薬品（4％）、機械設備（3％）、金属加工品（2％）、船舶、ボート、石油プラットフォーム（2％）、コンピュータ、電気機器（2％）、機械の修理、設置（2％）、ゴム、プラスチック、鉱物製品（2％）、卑金属（2％）などである。電気・ガス・蒸気は総生産の6％、鉱業・採石業は1％を占めている。',
  'industrial_production_mom.description:ja:Japan':
    '日本では鉱工業生産は製造業、鉱業、公益事業など経済の鉱工業セクターに属する事業の生産量を測定する。',
  'industrial_production_mom.description:ja:France':
    'フランスでは鉱工業生産は経済の鉱工業セクターに属する事業の生産量を測定する。製造業は最も重要なセクターで、全生産量の86%を占める。製造業の中でも最大の部門は、食料品・飲料（全生産量の13％）、機械・機器（13％）（うちコンピューター、電子・光学製品は5％）、卑金属・金属加工製品（12％）、その他の機器の製造・修理・設置業（12％）、輸送機器（10％）、ゴム・プラスチック製品、およびその他の非金属鉱物製品（8％）、化学薬品・化学製品（6％）。電力・ガス・蒸気・空調は全生産量の11％を占め、上下水道・廃棄物管理・修復1％、鉱業・採石業も1％を占める。',
  'euro_finance_week.description:ja:DEFAULT': '',
  'euro_finance_week.description:ja:Germany': '',
  'st_joseph_s_day.description:ja:Colombia': '',
  'kagiso_manufacturing_pmi.description:ja:DEFAULT': '',
  'kagiso_manufacturing_pmi.description:ja:South Africa':
    'Absa製造業購買担当者景気指数（PMI）は、南アフリカの製造業購買担当者を調査対象とする月次指数で、製造業の業況を示す先行指標である。指数が50%超では製造業の経済は概して拡大しており、50%未満は縮小していることを示す。',
  'industrial_production.description:ja:DEFAULT': '',
  'industrial_production.description:ja:Russia':
    'ロシアでは鉱工業生産は経済の鉱工業セクターに属する事業の生産量を測定する。製造業は最も重要なセクターで、全生産量の55%を占める。製造業の中でも最大の部門は、冶金（9.6%）、コークスおよび精製石油製品（9.5%）、食料品・飲料・タバコ（8.4%）、化学薬品（4%）、機械・機器（3.8%）、輸送（3.7%）、電気的装置（3.4％）。鉱業・採石業は35%、天然ガスと原油採掘は29％を占める。また、電力・ガス・水道供給は11％を占める。',
  'industrial_production.description:ja:Portugal': '',
  'industrial_production.description:ja:Singapore': '',
  'industrial_production.description:ja:Taiwan': '',
  'industrial_production.description:ja:Thailand': '',
  'industrial_production.description:ja:South Korea': '',
  'industrial_product_price_yoy.description:ja:DEFAULT': '',
  'industrial_product_price_yoy.description:ja:Canada': '',
  'lmi_logistics_managers_index_current.description:ja:DEFAULT': '',
  'lmi_logistics_managers_index_current.description:ja:United States':
    '物流担当者調査 (LMI) は、米国の物流活動の状況を明らかにすることを目的とした月例調査である。LMIスコアは、物流業界を構成する8つの要素（在庫水準・コスト、倉庫容量・稼働率・価格、輸送容量・稼働率・価格）を組み合わせて算出される。 LMIは、ディフュージョン指数を使用して計算され、50％を超える値は、ロジスティクスが拡大していることを示し、50％未満の値は、ロジスティクス業界が縮小していることを示している。 .',
  'industrial_product_price_mom.description:ja:DEFAULT': '',
  'industrial_product_price_mom.description:ja:Canada': '',
  'industrial_output_mom.description:ja:DEFAULT': '',
  'industrial_output_mom.description:ja:Brazil': '',
  'industrial_orders_mom.description:ja:DEFAULT': '',
  'industrial_orders_mom.description:ja:Italy':
    '工業受注は、国内および海外の顧客から産業企業が受け取った受注の月ごとの変化を測定する。.',
  'industrial_confidence.description:ja:DEFAULT': '',
  'industrial_confidence.description:ja:Euro Area': '',
  'industrial_confidence.description:ja:Norway':
    'ノルウェーの景況感調査である製造業信頼感指数（ICI）は、生産予測、総受注残、自社製品在庫に関する質問への回答の数学的平均値（バランス）である。この指標では、生産量増加の予想はその次の四半期の生産量増加のシグナルとなり、総受注残の増加は受注のフルフィルメントによる生産量の増加を示し、自社製品在庫の増加は販売の減速と活動の縮小を示すことから、工業生産水準の目安となる。',
  'gdp_growth_rate_qoq_2st_est.description:ja:DEFAULT': '',
  'gdp_growth_rate_qoq_2st_est.description:ja:Israel':
    'イスラエル経済の主要なけん引役は科学技術セクターで、天然資源が限定的であるにもかかわらず製造・農業セクターは高度に進化している。イスラエル経済最大のセクターはサービス業でGDPの80%を占め、うち最も重要なのは金融とビジネス（GDP寄与率28％）、政府（同17％）、住宅（同12％）、貿易、宿泊、レストラン（同10％）、輸送・保管・通信（同8％）。製造業の重要性は近年低下しており、今ではGDP寄与率は14％。',
  'indonesian_independence_day.description:ja:DEFAULT': '',
  'indonesian_independence_day.description:ja:Indonesia': '',
  'indonesia_hsbc_pmi.description:ja:DEFAULT': '',
  'indonesia_hsbc_pmi.description:ja:Indonesia': '',
  'india_general_election_voting_starts.description:ja:DEFAULT': '',
  'india_general_election_voting_starts.description:ja:India': '',
  'ism_non-manufacturing_employment.description:ja:DEFAULT': '',
  'ism_non-manufacturing_employment.description:ja:United States':
    'ISM非製造業景気指数（Non-Manufacturing ISM Report On Business®）は、全国の購買および供給担当幹部からのアンケート調査回答に基づいて集計され、前月と比較した当月の変化があればそれが反映される。事業活動、新規受注、受注残、新規輸出受注、輸入、生産、サプライヤー納期、在庫、在庫見通し、輸入、価格、雇用、サプライヤー納期の各指標について、回答の割合、およびディフュージョン・インデックスが示される。50％を上回るとその非製造業の景気が全体的に拡大していることを、50％を切る場合は縮小していることを示す。サービス提供者への注文は、米国経済の約90%を占めている。',
  'gdp_growth_rate_yoy_-_adv.description:ja:DEFAULT': '',
  'gdp_growth_rate_yoy_-_adv.description:ja:South Korea':
    '韓国の経済は、過去50年間で世界で最も多様化し、技術的にも進歩した国の一つとなった。多くの先進国と同様、GDPに占めるサービスの割合が最も高くなっている（GDPの約57％）。サービス業の中でも特に重要なのは、行政（6％）、教育（5.3％）、情報・通信（5％）、ビジネス活動（4.6％）である。韓国を主要な輸出国にした強力な製造業は、GDPの32％を占めている。',
  'gdp_growth_rate_yoy_-_adv.description:ja:Singapore':
    'シンガポール経済最大の分野はサービス業であり、GDPの72%を占める。サービス業の主要な部門は、卸売・小売業（合計GDPの18%）、ビジネスサービス（16%）、金融・保険（13%）、輸送・倉庫（10%）、情報・通信（5%）である。産業部門は総生産の28%を占め、その主要セグメントには、製造業（21%）、建設業（5%）がある。',
  'tankan_small_non-manufacturing_outlook.description:ja:DEFAULT': '',
  'tankan_small_non-manufacturing_outlook.description:ja:Japan': '',
  'fed_kashkari_speech.description:ja:DEFAULT': '',
  'fed_kashkari_speech.description:ja:United States':
    '米国では、金利設定の権限は連邦準備制度理事会（理事会）と連邦公開市場委員会（FOMC）に分けられている。理事会は、1つ以上の地区連邦準備銀行から提出された勧告を受けて、割引率の変更を決定する。FOMCは、中央銀行の資金量やフェデラルファンド市場金利などの公開市場操作を決定する。',
  'non-oil_exports_mom.description:ja:DEFAULT': '',
  'non-oil_exports_mom.description:ja:Singapore':
    'シンガポールの非石油国内輸出（NODX）は、IC、ディスクメディア製品、PC、PC 部品、ダイオード・トランジスタなどの電子製品と、特に、石油化学製品や医薬品などの化学製品を中心とした非電子製品に分けられる。',
  'india_general_election_voting_ends.description:ja:DEFAULT': '',
  'india_general_election_voting_ends.description:ja:India': '',
  'independent_czechoslovak_state_day.description:ja:DEFAULT': '',
  'independent_czechoslovak_state_day.description:ja:Czech Republic': '',
  'midterm_elections.description:ja:DEFAULT': '',
  'midterm_elections.description:ja:United States': '',
  'snb_president_studer_speech.description:ja:DEFAULT': '',
  'snb_president_studer_speech.description:ja:Switzerland': '',
  'independence_of_cartagena_day.description:ja:DEFAULT': '',
  'independence_of_cartagena_day.description:ja:Colombia': '',
  'gdp_price_index_qoq.description:ja:DEFAULT': '',
  'gdp_price_index_qoq.description:ja:United States':
    'GDPデフレーターは、最終財とサービスの価格変化を測るものであり、将来の金融政策の方向性に影響するインフレ圧力の主要指標であるとされている。',
  'employment_change_yoy.description:ja:DEFAULT': '',
  'employment_change_yoy.description:ja:Euro Area':
    'ユーロ圏では雇用変化は、賃金または利益のために働く者と無給家族労働者の数の前四半期比変化を指し、推定値にはフルタイムとパートタイム双方が含まれる。',
  'independence_of_cartagena.description:ja:DEFAULT': '',
  'independence_of_cartagena.description:ja:Colombia': '',
  'independence_day.description:ja:DEFAULT': '',
  'independence_day.description:ja:Greece': '',
  'independence_day.description:ja:Poland': '',
  'independence_day.description:ja:India': '',
  'independence_day.description:ja:Israel': '',
  'independence_day.description:ja:United States': '',
  'independence_day.description:ja:Finland': '',
  'independence_day.description:ja:Philippines': '',
  'independence_day.description:ja:Brazil': '',
  'independence_day.description:ja:Colombia': '',
  'independence_day.description:ja:Indonesia': '',
  'independence_day.description:ja:Mexico': '',
  'independence_day.description:ja:Czech Republic': '',
  '30-year_bund_auction.description:ja:DEFAULT': '',
  '30-year_bund_auction.description:ja:Germany': '',
  'riksbank_skingsley_speech.description:ja:DEFAULT': '',
  'riksbank_skingsley_speech.description:ja:Sweden': '',
  'imports_yoy.description:ja:DEFAULT': '',
  'imports_yoy.description:ja:Taiwan':
    '天然資源の不足により、台湾は輸入依存となっている。台湾は主に、電子部品（総額の18.2%）、鉱業製品（15.2%）、機械（12.4%）、化学品（10.8%）、ベースメタル（7.3%）の部品を輸入に頼っている。主な輸入相手国は中国本土および香港（総額の19.6%）、日本（17.6%）、欧州（12.6%）、米国（12.4%）、ASEAN諸国（11.8%）、中東（7.7%）となっている。',
  'imports_yoy.description:ja:Philippines':
    'フィリピンの主な輸入品目は、電子製品（25%）、鉱物性燃料（21%）、輸送設備（10%）となっている。フィリピンの主な輸入相手国は、中国（13%）、米国（11%）、日本（8%）、台湾（8%）となっている。',
  'imports_yoy.description:ja:Australia': '',
  'imports_yoy.description:ja:Hong Kong':
    '香港の主な輸入品目は、機械・輸送機器（輸入全体の66％）、雑多な製造品(14％）、製造品（9％）、食品・生きている動物（4％）である。主な輸入相手国は 中国（47％）、台湾（8％）、シンガポール（7％）。その他は日本、韓国、米国である。',
  'imports_yoy.description:ja:Japan':
    '日本の輸入高は2019年、世界の貿易摩擦により前年の過去最高額から5%減少した。主な輸入品目は、鉱物性燃料（輸入全体の22％）で、うち石油が10％、天然ガスが6%、通信、映像、半導体などの電気機械（15%）、医療製品と有機化学品などの化学品（10%）、コンピュータおよびユニットなどの機械（10%）、魚類および魚類加工前品、肉加工前品などの食料（9%）非鉄金属の加工品（9%）、非鉄鉱石および鉄鉱石とその凝集物などの原料（6%）となっている。主な輸出相手国は、中国（23%）、特にドイツ（3%）をはじめとするEU（12%）、アメリカ（11％）、オーストラリア（6％）、韓国（4％）サウジアラビア（4％）、台湾（4％）、アラブ首長国連邦（4％）、タイ（4％）となっている。',
  'imports_yoy.description:ja:Colombia':
    'コロンビアの主な輸入品目は、機械および輸送機器（輸入全体の39%）、工業製品（22%）、化学品および関連製品（17%）、燃料および潤滑油と関連製品（10%）、食料および家畜（8%）となっている。主な貿易相手国は、米国（輸入全体の24%）、中国（16%）、メキシコ（11%）、ブラジル（5%）となっている。他には、ドイツ、アルゼンチン、日本、フランス、韓国、カナダが挙げられる。',
  'imports_yoy.description:ja:South Korea':
    '2019年、韓国の輸入高は国内生産高と設備投資が縮小したため前年の過去最高額から6%減少し、5,032億米ドルとなった。主な輸入品目は、燃料、天然油、瀝青物質（輸入全体の25%）、電気機械および設備（18%）、原子炉、ボイラー、機械および工作機械（10%）、光学製品、写真、映像、計測機器（4%）、車両（3%）、鉄および鋼鉄（3%）、鉄鉱石、スラグ、灰（3%）、有機化学薬品（3%）、プラスチックおよび関連製品（2%）、化学製品雑品（2%）、非有機化学品、希少金属化合物（2%）となっている。中国が最大の貿易相手国（輸入額の21%）であり、アメリカ（12%）、日本（9%）、サウジアラビア、ベトナム、オーストラリア、ドイツ（各4%）、台湾、ロシア、カタール（各3%）、クウェート、マレーシア、アラブ首長国連邦、インドネシア（各2%）が続く。',
  'imports_yoy.description:ja:China':
    '中国の輸入高は、国内需要の減退とアメリカとの貿易摩擦によって2019年に3年ぶりの2.7%減となった。機械と輸送機器の輸入が全体の38％を占め、電気機器と器具および電化製品（21％）、車両（4％）、通信および録音再生機器と設備（3％）、オフィス機器および自動データ処理機（3％）が続いている。その他主要な輸入カテゴリーとしては、主に石油、関連製品（13%）、天然および生成ガス（3％）などの燃料、潤滑油、関連製品（17％）、鉄鉱石や鉄くず（9%）などの、燃料を除く未精製物質（14％）、天然化学品（3%）やプラスチック原料（3%）などの化学品（11%）、その他製造済雑品（7%）、主に原料で分類される製造品（7%）、食料および家畜（4%）となっている。輸入相手国上位は、EU（輸入全体の13%）、うちドイツが5%、フランスが2%を筆頭に、韓国、台湾、日本（各8%）、アメリカとオーストラリア（各6%）、ブラジル（4%）、マレーシア、ベトナム、ロシア、サウジアラビア（各3%）、タイ、シンガポール、インドネシア（各2%）となっている。',
  'imports_yoy.description:ja:Indonesia':
    'インドネシアでは人口の大半が中産階級となり石油と消費製品の多額の購入を後押ししたため、輸入量が2004～2012年までの間に3倍となった。しかし、2013年中盤から、コモディティ価格の下洛と国内消費および投資の減退から輸入は減少している。主な輸入品目は、石油およびガス（輸入全体の17%）、核反応炉、ボイラー、機械部品（19%）、鉄および鋼鉄（5.4%）、有機化学品（4.8%）および車両（4.5%）となっている。主な輸入相手国は、中国（25%）、日本（11%）、シンガポール（7.6%）、タイ（6.8%）、米国（6.4%）となっている。',
  'imports_yoy.description:ja:Thailand':
    'タイは主に原材料と中間財を輸入している（輸入全体の約56％）。燃料が19％、電子機器の部品が11％、卑金属の材料が9％、化学品が5.5％となっている。コンピュータや機械などの機械・設備・消耗品は輸入全体の25％、消費財は8％である。主な輸入相手国は、日本（総輸入の20％）、中国（15％）、欧州連合（8％）。その他には、アラブ首長国連邦、マレーシア、米国などがある。',
  'ecb_monthly_report.description:ja:DEFAULT': '',
  'ecb_monthly_report.description:ja:Euro Area': '',
  'imports_prel.description:ja:DEFAULT': '',
  'imports_prel.description:ja:India':
    'インドの主な輸入品目は、鉱物燃料、油脂、ワックス、瀝青物質（総輸入量の27％）、真珠、貴石、半貴石、宝飾品（14％）、電気機械・設備（10％）、原子炉、ボイラー、機械、機械器具（8％）、有機化学品（4％）である。インドの主な輸入相手国は 中国（総輸入量の16％）、米国（6％）、アラブ首長国連邦（6％）、サウジアラビア（5％）、スイス（5％）である。',
  'aig_performance_of_services_index.description:ja:DEFAULT': '',
  'aig_performance_of_services_index.description:ja:Australia': '',
  'imports_mom_s_a.description:ja:DEFAULT': '',
  'imports_mom_s_a.description:ja:Germany': '',
  'eid_al-adha.description:ja:DEFAULT': '',
  'eid_al-adha.description:ja:Singapore': '',
  'eid_al-adha.description:ja:Philippines': '',
  'eid_al-adha.description:ja:Indonesia': '',
  'imports_mom.description:ja:DEFAULT': '',
  'imports_mom.description:ja:Australia':
    'オーストラリアは主に機械・輸送機器を輸入しており（総輸入の40％）、そのうち道路車両が12％、産業機械が6％、電気機械が5％、通信・録音が5％を占めている。また、石油（11％）、製造品（12％）、化学品および関連製品（10％）、食品および生きた動物（5％）を輸入している。主な輸入先は、中国（総輸入の23％）、米国（11％）、日本（7％）、韓国、タイ、ドイツ（各5％）、マレーシア（4％）である。',
  'imports_final.description:ja:DEFAULT': '',
  'imports_final.description:ja:India':
    'インドの主な輸入品目は、鉱物燃料、油脂、ワックス、瀝青物質（総輸入量の27％）、真珠、貴石、半貴石、宝飾品（14％）、電気機械・設備（10％）、原子炉、ボイラー、機械、機械器具（8％）、有機化学品（4％）である。インドの主な輸入相手国は 中国（総輸入量の16％）、米国（6％）、アラブ首長国連邦（6％）、サウジアラビア（5％）、スイス（5％）である。',
  'medium-term_budget_policy_statement.description:ja:DEFAULT': '',
  'medium-term_budget_policy_statement.description:ja:South Africa': '',
  'gdp_annual_growth_rate_yoy_final.description:ja:DEFAULT': '',
  'gdp_annual_growth_rate_yoy_final.description:ja:Japan':
    '世界第4位の規模を誇る日本の経済は、多様性に富み、技術的にも進んでいる。多くの先進国と同様に、GDPに占めるサービス業の割合が最も高くなっている（GDPの約62％）。サービス業の中で最も重要なのは、卸売・小売業（GDPの約7％）と不動産業（GDPの約6％）である。製造業は日本経済の原動力であり、GDPの約22%を占めている。 また、建設業も重要な役割を果たしており、GDPの約5％を占めている。',
  'import_prices_yoy.description:ja:DEFAULT': '',
  'import_prices_yoy.description:ja:Singapore':
    'シンガポールでは、輸入価格は、その国の居住者が外国の販売者から購入し、供給される商品やサービスの価格の変化率に相当する。輸入価格は、為替レートの影響を大きく受ける。',
  'import_prices_yoy.description:ja:Germany':
    'ドイツでは、輸入物価指数は、ドイツ居住者が国外の売り手から購入したり供給されたりした財とサービスの価格の変動率を指す。輸入物価は為替レートに大きく左右される。',
  'import_prices_yoy.description:ja:Finland':
    'フィンランドでは、輸入価格は、その国の居住者が外国の販売者から購入し、供給される商品やサービスの価格の変化率に相当する。輸入価格は為替レートの影響を大きく受ける。',
  'import_prices_yoy.description:ja:United States':
    '米国では、輸入物価指数は、米国の居住者が国外の売り手から購入したり供給されたりした財とサービスの価格の変動率を指す。輸入物価は為替レートに大きく左右される。',
  'import_prices_yoy.description:ja:South Korea':
    'フィンランドでは、輸入価格は、その国の居住者が外国の販売者から購入し、供給される商品やサービスの価格の変化率に相当する。輸入価格は為替レートの影響を大きく受ける。',
  'import_prices.description:ja:DEFAULT': '',
  'import_prices.description:ja:Finland':
    'フィンランドでは、輸入価格は、その国の居住者が外国の販売者から購入し、供給される商品やサービスの価格の変化率に相当する。輸入価格は為替レートの影響を大きく受ける。',
  'import_price_index_qoq.description:ja:DEFAULT': '',
  'import_price_index_qoq.description:ja:Australia': '',
  'parliamentary_debate_vote_on_brexit.description:ja:DEFAULT': '',
  'parliamentary_debate_vote_on_brexit.description:ja:European Union': '',
  'harmonised_inflation_rate_yoy.description:ja:DEFAULT': '',
  'harmonised_inflation_rate_yoy.description:ja:Germany': '',
  'harmonised_inflation_rate_yoy.description:ja:Denmark':
    '調和物価指数（HICP）は、欧州中央銀行（ECB）によるインフレおよび物価安定性の指標である。HICPは、ユーロスタットおよび各国の統計機関により調整統計手法によってまとめられる。ECBは、HICPが発表する中期に渡る年間インフレ率を2%弱に保つことを目標としている。',
  'harmonised_inflation_rate_yoy.description:ja:Greece':
    '調和物価指数（HICP）は、欧州中央銀行（ECB）によるインフレおよび物価安定性の指標である。HICPは、ユーロスタットおよび各国の統計機関により調整統計手法によってまとめられる。ECBは、HICPが発表する中期に渡る年間インフレ率を2%弱に保つことを目標としている。',
  'harmonised_inflation_rate_yoy.description:ja:France':
    '調和物価指数（HICP）は、欧州中央銀行（ECB）によるインフレおよび物価安定性の指標である。HICPは、ユーロスタットおよび各国の統計機関により調整統計手法によってまとめられる。ECBは、HICPが発表する中期に渡る年間インフレ率を2%弱に保つことを目標としている。',
  'harmonised_inflation_rate_yoy.description:ja:Ireland':
    '調和物価指数（HICP）は、欧州中央銀行（ECB）によるインフレおよび物価安定性の指標である。HICPは、ユーロスタットおよび各国の統計機関により調整統計手法によってまとめられる。ECBは、HICPが発表する中期に渡る年間インフレ率を2%弱に保つことを目標としている。',
  'harmonised_inflation_rate_yoy.description:ja:Austria':
    '調和物価指数（HICP）は、欧州中央銀行（ECB）によるインフレおよび物価安定性の指標である。HICPは、ユーロスタットおよび各国の統計機関により調整統計手法によってまとめられる。ECBは、HICPが発表する中期に渡る年間インフレ率を2%弱に保つことを目標としている。',
  'gdp_growth_rate_qoq_2nd_est.description:ja:Portugal':
    '支出面では、家計消費がGDPの主な構成要素であり、その合計支出の66％を占めている。続いて、政府支出（19％）、総固定資本形成（15％）となっている。財・サービスの輸出はGDPの40％、輸入も40％を占める。',
  'gdp_growth_rate_qoq_2nd_est.description:ja:France':
    'フランスは世界で7番目、ユーロ圏で2番目に大きい経済国である。GDPの支出を大きく占めるのは家計消費（55％）、政府支出（24％）、総固定資本形成（22％）となっている。財・サービスの輸出はGDPの29％、輸入は31％であるため、純輸出はGDP全体に2％のマイナス寄与となっている。',
  'gdp_growth_rate_qoq_2nd_est.description:ja:Euro Area':
    'ユーロ圏とは、通貨としてユーロを採用している欧州19カ国の経済通貨同盟のことである。世界第2位の経済圏であり、人口は3億4100万人で、国としてみれば人口は世界第3位である。ドイツ、フランス、イタリア、スペインが最も重要な経済圏で、それぞれGDPの29％、20％、15％、10％を占めている。',
  'gdp_growth_rate_qoq_2nd_est.description:ja:Belgium':
    'ベルギーでは家計消費がGDPの主な構成要素であり、その総支出の52％を占めており、次いで政府支出（25％）、総固定資本形成（23％）となっている。財・サービスの輸出はGDPの84%である一方、輸入は83%であるため、GDP合計に1%のマイナス寄与となっている。',
  'gdp_growth_rate_qoq_2nd_est.description:ja:United States':
    '支出面では、個人消費支出がGDP全体の68%を占め、そのうち製品購入による支出が23%、サービスへの支出が45%となっている。民間投資はGDPの16%、政府による消費と投資は18%を占める。製品輸出高（13.5%）が製品輸入高（16.5%）より低いため、純輸出はGDPに3%のマイナス寄与。',
  'gdp_growth_rate_qoq_2nd_est.description:ja:Hungary':
    'ハンガリーでは家計消費がGDPの主な構成要素であり、総支出の50％を占め、総固定資本形成（22％）、政府支出（20％）がそれに続く。財・サービスの輸出はGDPの89%である一方、輸入は82%であるため、GDP合計に７%のプラス寄与となっている。',
  'gdp_growth_rate_qoq_2nd_est.description:ja:United Kingdom':
    'サービス業は最も重要で、イギリスのGDPの79%を占めている。サービス業の中で最も大きな分野は、政府・教育・保健（GDP全体の19％）、不動産（12％）、専門的・科学的・技術的活動および管理・支援サービス（12％）、卸売・小売業（11％）、金融・保険（8％）である。工業分野はGDPの21％を占め、この分野の最大のセグメントは、製造業（GDP全体の10％）と建設業（6％）である。農業部門はGDPのわずか1％を占めている。支出面でのGDPの構成は、家計消費（65％）、政府支出（20％）、総固定資本形成（17％）となっている。財・サービスの輸出はGDPの28％、輸入は30％を占めるため、輸出入はGDPに2％のマイナス寄与となっている。',
  'gdp_growth_rate_qoq_2nd_est.description:ja:Czech Republic':
    'チェコ共和国では家計消費がGDPの主な構成要素であり、その総支出の49％を占めており、次いで総固定資本形成（25％）、政府支出（19％）となっている。財・サービスの輸出はGDPの84%である一方、輸入は77%であるため、GDP合計に７%のプラス寄与となっている。',
  'gdp_growth_rate_qoq_2nd_est.description:ja:Denmark':
    'デンマークでは家計消費がGDPの主な構成要素であり、その総支出の49％を占めており、次いで政府支出（27％とEU諸国では最高）、総固定資本形成（19％）となっている。財・サービスの輸出はGDPの54%に達する一方、輸入は48%であるため、GDP合計に６%のプラス寄与となっている。',
  'gdp_growth_rate_qoq_2nd_est.description:ja:Netherlands':
    'オランダ経済はユーロ圏で第6位、ヨーロッパの重要な交通拠点である。オランダ経済は外国貿易に大きく依存しており、輸出はGDPの83％、輸入は72％を占める。GDPの主な構成要素は家計消費（45％）で、次いで政府支出（26％）、総固定資本形成（18％）、純輸出（11％）となっている。',
  'immaculate_conception_of_mary.description:ja:DEFAULT': '',
  'immaculate_conception_of_mary.description:ja:Philippines': '',
  'imaculate_conception_day.description:ja:DEFAULT': '',
  'imaculate_conception_day.description:ja:Colombia': '',
  'monetary_policy_announcement.description:ja:DEFAULT': '',
  'monetary_policy_announcement.description:ja:Australia': '',
  'ifo_expectations_prel.description:ja:DEFAULT': '',
  'ifo_expectations_prel.description:ja:Germany': '',
  'construction_spending_mom.description:ja:DEFAULT': '',
  'construction_spending_mom.description:ja:United States':
    '建設支出とは、米国の民間および公共部門において、毎月、新しい構造物や既存の構造物の改良のために行われた建設工事の合計金額の月次推定値を指す。2016年には、民間の建設支出が全体の75％、公共が25％を占めた。また、非住宅建設への支出が全体の60％、住宅が40％を占めている。',
  'gdp_growth_rate_yoy_second_est.description:ja:DEFAULT': '',
  'gdp_growth_rate_yoy_second_est.description:ja:United States':
    '米国は世界最大の経済大国である。しかし、他の多くの先進国と同様に、この20年間でその成長率は低下している。 50年代、60年代の平均成長率が4％を超えていたのに対し、70年代、80年代には3％前後にまで落ち込んだ。 過去10年間の平均成長率は2％を下回り、2000年の第2四半期以降は一度も5％台に達していない。',
  'ifo_expectations.description:ja:DEFAULT': '',
  'ifo_expectations.description:ja:Germany': '',
  'leading_business_cycle_indicator_mom.description:ja:DEFAULT': '',
  'leading_business_cycle_indicator_mom.description:ja:South Africa':
    '南アフリカでは、総合景気先行指数は、実質的な経済活動がどのような方向に向かっているかをリアルタイムで調べるものである。この指標は、建築計画の承認、新車販売台数、主要輸出商品の商品価格指数、ヨハネスブルグ証券取引所 (JSE) で取引されている全クラスの株式の価格指数、求人広告、製造業の受注量、実質M1、製造業の工場労働者1人当たりの平均労働時間、金利スプレッド、主要貿易相手国の総合景気先行指標、企業景況感指数、営業総利益の対GDP比などを基に算出される。この指数は、2010年時点での基準値を100としている。',
  'employment_cost_index.description:ja:DEFAULT': '',
  'employment_cost_index.description:ja:United States': '',
  'new_year_s_eve.description:ja:Switzerland': '',
  'ifo_current_conditions_final.description:ja:DEFAULT': '',
  'ifo_current_conditions_final.description:ja:Germany': '',
  'snb_zurbrugg_speech.description:ja:DEFAULT': '',
  'snb_zurbrugg_speech.description:ja:Switzerland': '',
  'ifo_business_climate.description:ja:DEFAULT': '',
  'ifo_business_climate.description:ja:Germany':
    'ドイツでは、Ifo企業景況感指数によって、現状と向こう6カ月間の先行きにについて企業の景況感を測定する。この調査は電話で行われ、調査対象は製造業、サービス業、貿易業及び建設業の9,000企業。楽観的な上級経営陣と悲観的な経営陣の比率の差が、マイナス100（全回答者が現状を「悪い」と評価し、今後悪化を予想）から＋100（全回答者が現状を「良い」と評価し、今後改善を予想）までの企業景況感バランス値として算出され、それが基準年（現行は2015年）の平均値に標準化されてIfo企業景況感指数が算出される。',
  'idul_juha.description:ja:DEFAULT': '',
  'idul_juha.description:ja:India': '',
  'mid_year_day.description:ja:DEFAULT': '',
  'mid_year_day.description:ja:Thailand': '',
  'ism_prices_paid.description:ja:DEFAULT': '',
  'ism_prices_paid.description:ja:United States':
    'ISM製造業景気指数（Manufacturing ISM Report On Business®）は、全国の購買および供給担当幹部からのアンケート調査回答に基づいて集計され、前月と比較した当月の変化があればそれが反映される。新規受注、受注残、新規輸出受注、輸入、生産、サプライヤー納期、在庫、顧客在庫、雇用、価格の各指標について、景気がよくなるとした回答数と悪くなると回答した回答数との差、それぞれの回答の割合、およびディフュージョン・インデックス（DI）を示す。50％を上回るPMI®は製造業の景気が全体的に拡大していることを、50％を切る場合は縮小していることを示す。',
  'markit_manufacturing_pmi.description:ja:Euro Area': '',
  'markit_manufacturing_pmi.description:ja:Germany': '',
  'markit_manufacturing_pmi.description:ja:Russia':
    'IHSマークイット・ロシア製造業購買担当者景気指数（PMI）は製造業300社の調査に基づき、製造業の業況を測定し、それぞれウエート付けされた、新規受注（30%）、生産（25%）、雇用（20%）、サプライヤー納期（15%）、購買品在庫（10%）という5つの個別インデックスから構成され、納期インデックスは他のインデックスと揃えるため符号が反転される。50を超える指数は製造業セクターが前期に比べて拡大していることを、50を切る指数は縮小していることを、50は変化がないことを示す。',
  'markit_manufacturing_pmi.description:ja:Brazil':
    'ブラジルでは、マークイット製造業購買担当者景気指数（PMI）は製造業400社の調査に基づき、製造業の業況を測定し、それぞれウエート付けされた、新規受注（30%）、生産（25%）、雇用（20%）、サプライヤー納期（15%）、購買品在庫（10%）という5つの個別インデックスから構成され、納期インデックスは他のインデックスと揃えるため符号が反転される。50を超える指数は製造業セクターが前期に比べて拡大していることを、50を切る指数は縮小していることを、50は変化がないことを示す。',
  'markit_manufacturing_pmi.description:ja:India':
    'IHSマークイット・インド製造業購買担当者景気指数（PMI）は製造業500社の調査に基づき、製造業の業況を測定する。それぞれウエート付けされた、新規受注（30%）、生産（25%）、雇用（20%）、サプライヤー納期（15%）、購買品在庫（10%）という5つの個別インデックスから構成され、納期インデックスは他のインデックスと比較できるよう符号が反転される。50を超える指数は製造業セクターが前期に比べて拡大していることを、50を切る指数は縮小していることを、50は変化がないことを示す。',
  'markit_manufacturing_pmi.description:ja:Poland':
    'マークイット・ポーランド製造業購買担当者景気指数（PMI）は製造業200社の調査に基づき、製造業の業況を測定し、それぞれウエート付けされた、新規受注（30%）、生産（25%）、雇用（20%）、サプライヤー納期（15%）、購買品在庫（10%）という5つの個別インデックスから構成され、納期インデックスは他のインデックスと揃えるため符号が反転される。50を超える指数は製造業セクターが前期に比べて拡大していることを、50を切る指数は縮小していることを、50は変化がないことを示す。',
  'markit_manufacturing_pmi.description:ja:Hong Kong': '',
  'markit_manufacturing_pmi.description:ja:Greece':
    'ギリシャではマークイット製造業購買担当者景気指数（PMI）は製造業350社の調査に基づき、製造業の業況を測定する。指数は、それぞれウエート付けされた、新規受注（30%）、生産高（25%）、雇用（20%）、サプライヤー納期（15%）、購買品在庫（10%）という5つの個別インデックスから構成され、納期インデックスは比較可能にするため符号が反転される。50を超える指数は製造業セクターが前期に比べて拡大していることを、50を切る指数は縮小していることを、50は変化がないことを示す。',
  'markit_manufacturing_pmi.description:ja:United States': '',
  'markit_manufacturing_pmi.description:ja:United Kingdom': '',
  'markit_manufacturing_pmi.description:ja:Thailand':
    'IHSマークイット・タイ製造業購買担当者景気指数（PMI）は製造業450社の調査に基づき、製造業の業況を測定する。それぞれウエート付けされた、新規受注（30%）、生産（25%）、雇用（20%）、サプライヤー納期（15%）、購買品在庫（10%）という5つの個別インデックスから構成され、納期インデックスは他のインデックスと比較できるように符号が反転される。50を超える指数は製造業セクターが前期に比べて拡大していることを、50を切る指数は縮小していることを、50は変化がないことを示す。',
  'markit_manufacturing_pmi.description:ja:Czech Republic':
    'チョコ共和国では、マークイット製造業購買担当者景気指数（PMI）は製造業250社の調査に基づき、製造業の業況を測定し、それぞれウエート付けされた、新規受注（30%）、生産（25%）、雇用（20%）、サプライヤー納期（15%）、購買品在庫（10%）という5つの個別インデックスから構成され、納期インデックスは他のインデックスと揃えるため符号が反転される。50を超える指数は製造業セクターが前期に比べて拡大していることを、50を切る指数は縮小していることを、50は変化がないことを示す。',
  'markit_manufacturing_pmi.description:ja:France': '',
  'markit_manufacturing_pmi.description:ja:Mexico':
    'マークイット・メキシコ製造業購買担当者景気指数（PMI）は製造業400社の調査に基づき、製造業の業況を測定し、それぞれウエート付けされた、新規受注（30%）、生産（25%）、雇用（20%）、サプライヤー納期（15%）、購買品在庫（10%）という5つの個別インデックスから構成され、納期インデックスは他のインデックスと揃えるため符号が反転される。50を超える指数は製造業セクターが前期に比べて拡大していることを、50を切る指数は縮小していることを、50は変化がないことを示す。',
  'markit_manufacturing_pmi.description:ja:Indonesia':
    'IHSマークイット・インドネシア製造業購買担当者景気指数（PMI）は製造業400社の調査に基づき、製造業の業況を測定する。それぞれウエート付けされた、新規受注（30%）、生産（25%）、雇用（20%）、サプライヤー納期（15%）、購買品在庫（10%）という5つの個別インデックスから構成され、納期インデックスは他のインデックスと比較できるよう符号が反転される。50を超える指数は製造業セクターが前期に比べて拡大していることを、50を切る指数は縮小していることを、50は変化がないことを示す。',
  'markit_manufacturing_pmi.description:ja:Canada':
    'IHS マークイット・カナダ製造業購買担当者景気指数™（PMI）は、製造業の業況を測定する。製造業PMIはそれぞれウエート付けされた、新規受注（30%）、生産（25%）、雇用（20%）、サプライヤー納期（15%）、購買品在庫（10%）という5つのサブインデックスから構成され、納期インデックスは他のインデックスと同じ方向に動くよう符号を反転させる。50を超える指数は製造業セクターが前期に比べて拡大していることを、50を切る指数は縮小していることを、50は変化がないことを示す。',
  'markit_manufacturing_pmi.description:ja:South Korea':
    'IHSマークイット韓国製造業購買担当者景気指数（PMI）は製造業400社の調査に基づき、製造業の業況を測定する。それぞれウエート付けされた、新規受注（30%）、生産（25%）、雇用（20%）、サプライヤー納期（15%）、購買品在庫（10%）という5つの個別インデックスから構成され、納期インデックスは他のインデックスと比較できるように符号が反転される。50を超える指数は製造業セクターが前期に比べて拡大していることを、50を切る指数は縮小していることを、50は変化がないことを示す。',
  'markit_manufacturing_pmi.description:ja:Philippines':
    'IHSマークイット・フィリピン製造業購買担当者景気指数（PMI）は製造業450社の調査に基づき、製造業の業況を測定する。それぞれウエート付けされた、新規受注（30%）、生産（25%）、雇用（20%）、サプライヤー納期（15%）、購買品在庫（10%）という5つの個別インデックスから構成され、納期インデックスは他のインデックスと比較できるように符号が反転される。50を超える指数は製造業セクターが前期に比べて拡大していることを、50を切る指数は縮小していることを、50は変化がないことを示す。',
  'ism_non-manufacturing_prices.description:ja:DEFAULT': '',
  'ism_non-manufacturing_prices.description:ja:United States':
    'ISM非製造業景気指数（Non-Manufacturing ISM Report On Business®）は、全国の購買および供給担当幹部からのアンケート調査回答に基づいて集計され、前月と比較した当月の変化があればそれが反映される。事業活動、新規受注、受注残、新規輸出受注、輸入、生産、サプライヤー納期、在庫、在庫見通し、輸入、価格、雇用、サプライヤー納期の各指標について、回答の割合、およびディフュージョン・インデックスが示される。50％を上回るとその非製造業の景気が全体的に拡大していることを、50％を切る場合は縮小していることを示す。サービス提供者への注文は、米国経済の約90%を占めている。',
  'sukkot_i.description:ja:DEFAULT': '',
  'sukkot_i.description:ja:Israel': '',
  'victoria_day.description:ja:DEFAULT': '',
  'victoria_day.description:ja:Canada': '',
  'ism_non-manufacturing_new_orders.description:ja:DEFAULT': '',
  'ism_non-manufacturing_new_orders.description:ja:United States':
    'ISM非製造業景気指数（Non-Manufacturing ISM Report On Business®）は、全国の購買および供給担当幹部からのアンケート調査回答に基づいて集計され、前月と比較した当月の変化があればそれが反映される。事業活動、新規受注、受注残、新規輸出受注、輸入、生産、サプライヤー納期、在庫、在庫見通し、輸入、価格、雇用、サプライヤー納期の各指標について、回答の割合、およびディフュージョン・インデックスが示される。50％を上回るとその非製造業の景気が全体的に拡大していることを、50％を切る場合は縮小していることを示す。サービス提供者への注文は、米国経済の約90%を占めている。',
  'boe_report_on_eu_withdrawal_scenarios.description:ja:DEFAULT': '',
  'boe_report_on_eu_withdrawal_scenarios.description:ja:United Kingdom': '',
  'saints_cyril_and_methodius.description:ja:DEFAULT': '',
  'saints_cyril_and_methodius.description:ja:Czech Republic': '',
  'referendum_on_bailout_terms.description:ja:DEFAULT': '',
  'referendum_on_bailout_terms.description:ja:Greece': '',
  'finance_ministers_conference_call.description:ja:DEFAULT': '',
  'finance_ministers_conference_call.description:ja:Euro Area': '',
  'ism_manufacturing_prices.description:ja:DEFAULT': '',
  'ism_manufacturing_prices.description:ja:United States':
    'ISM製造業景気指数（Manufacturing ISM Report On Business®）は、全国の購買および供給担当幹部からのアンケート調査回答に基づいて集計され、前月と比較した当月の変化があればそれが反映される。新規受注、受注残、新規輸出受注、輸入、生産、サプライヤー納期、在庫、顧客在庫、雇用、価格の各指標について、景気がよくなるとした回答数と悪くなると回答した回答数との差、それぞれの回答の割合、およびディフュージョン・インデックス（DI）を示す。50％を上回るPMI®は製造業の景気が全体的に拡大していることを、50％を切る場合は縮小していることを示す。',
  'ism_manufacturing_employment.description:ja:DEFAULT': '',
  'ism_manufacturing_employment.description:ja:United States':
    'ISM製造業景気指数（Manufacturing ISM Report On Business®）は、全国の購買および供給担当幹部からのアンケート調査回答に基づいて集計され、前月と比較した当月の変化があればそれが反映される。新規受注、受注残、新規輸出受注、輸入、生産、サプライヤー納期、在庫、顧客在庫、雇用、価格の各指標について、景気がよくなるとした回答数と悪くなると回答した回答数との差、それぞれの回答の割合、およびディフュージョン・インデックス（DI）を示す。50％を上回るPMI®は製造業の景気が全体的に拡大していることを、50％を切る場合は縮小していることを示す。',
  'g7_video_conference_meeting.description:ja:DEFAULT': '',
  'g7_video_conference_meeting.description:ja:United States': '',
  'ipc-fipe_inflation_mom.description:ja:DEFAULT': '',
  'ipc-fipe_inflation_mom.description:ja:Brazil': '',
  'imf_world_bank_virtual_annual_meeting.description:ja:DEFAULT': '',
  'imf_world_bank_virtual_annual_meeting.description:ja:United States': '',
  'fed_yellen_testimony_to_the_senate.description:ja:DEFAULT': '',
  'fed_yellen_testimony_to_the_senate.description:ja:United States': '',
  'consumer_price_index_ex_food_energy_yoy.description:ja:DEFAULT': '',
  'consumer_price_index_ex_food_energy_yoy.description:ja:United States': '',
  'imf_world_economic_outlook.description:ja:DEFAULT': '',
  'imf_world_economic_outlook.description:ja:United States': '',
  'national_foundation_day.description:ja:DEFAULT': '',
  'national_foundation_day.description:ja:South Korea': '',
  'national_foundation_day.description:ja:Japan': '',
  'imf_spring_meeting.description:ja:DEFAULT': '',
  'imf_spring_meeting.description:ja:United States': '',
  'imf_loan_repayment.description:ja:DEFAULT': '',
  'imf_loan_repayment.description:ja:Greece': '',
  'michigan_current_conditions_prel.description:ja:DEFAULT': '',
  'michigan_current_conditions_prel.description:ja:United States':
    '消費者信頼感指数は、（1）消費者自身の財務状況の先行き見通し、（2）目先の経済全体に関する先行き見通し、（3）長期的な経済全体に関する先行き見通しの3つの分野に注目するもので、毎月実施されるアンケート調査には50項目の中核的な設問が含まれ、それぞれの設問が消費者の態度と予想を追跡する。消費者調査のサンプルは統計的にアラスカ州とハワイ州を除くすべての米国世帯を代表するように設計され、毎月500人以上に電話でインタビューが実施される。',
  'iif_spring_membership_meeting_2016.description:ja:DEFAULT': '',
  'iif_spring_membership_meeting_2016.description:ja:Spain': '',
  'construction_output_mom.description:ja:DEFAULT': '',
  'construction_output_mom.description:ja:Euro Area': '',
  'ihs_markit_pmi.description:ja:DEFAULT': '',
  'ihs_markit_pmi.description:ja:South Africa':
    '南アフリカでは、IHSマークイット南アフリカ購買担当者景気指数（PMI）が、鉱業、製造業、サービス業、建設業、小売業などの民間セクターを代表する約400社のパネルから収集したデータを基に景気動向を追跡する。この指数は、新規受注、生産、雇用、サプライヤー納期、在庫、価格などの変数を追跡し、指数が50を超えれば全体的に企業活動が拡大していることを、50を切れば縮小していることを示す。',
  'ihs_markit_composite_pmi.description:ja:DEFAULT': '',
  'ihs_markit_composite_pmi.description:ja:Italy':
    'イタリアの総合購買担当者景気指数（PMI）は、製造業生産指数とサービス業活動指数の加重平均値である。アンケート調査回答に基づいて集計され、前月と比較した当月の変化があればそれが反映される。各指標は毎月、各回答の比率と、「増える/改善する」との回答と「減る/悪化する」との正味の差、およびディフュージョン・インデックス（DI）を示す。この指数は、肯定的な回答と「変化なし」との回答の半数の合計で、50を上回る指数はサービス業の景気が全体的に拡大していることを、50を切る場合は悪化していることを示す。',
  '2020_spending_review.description:ja:DEFAULT': '',
  '2020_spending_review.description:ja:United Kingdom': '',
  'average_hourly_earnings_yoy.description:ja:DEFAULT': '',
  'average_hourly_earnings_yoy.description:ja:United States':
    '米国では、平均時給は特定月に従業員が1時間当たりに稼ぐ平均賃金である。FRBは金利を引き上げるか引き下げるかを判断する際、平均時給を考慮する。',
  'iea_oil_market_report.description:ja:DEFAULT': '',
  'iea_oil_market_report.description:ja:France': '',
  'iea_oil_market_report.description:ja:United States': '',
  'interest_rate.description:ja:DEFAULT': '',
  'interest_rate.description:ja:Israel': '',
  'interest_rate.description:ja:Thailand':
    'タイでは、金利の決定はタイ銀行の金融政策委員会が行う。主な金利は1日のレポ金利である。',
  'interest_rate.description:ja:South Korea':
    '韓国では、金利の決定は韓国銀行（BOK）の金融政策委員会によって行われる。公式金利は、2008年3月に翌日物コールレートから変更された韓国銀行基準金利である。',
  'interest_rate.description:ja:Philippines':
    'フィリピンでは、金利の決定はBangko Sentral ng Pilipinas (BSP)のThe Monetary Boardによって行われる。公式金利は翌日物借入金利であるリバースレポ金利（RR/P）である。フィリピン共和国の中央銀行は、物価の安定を促進・維持し、バランスのとれた持続可能な経済成長を促す強固な金融システムを実現するために、積極的なリーダーシップを発揮することを約束している。',
  'interest_rate.description:ja:South Africa':
    '南アフリカでは、金利の決定は南アフリカ準備銀行の金融政策委員会（MPC）が行う。公式金利はレポ金利である。これは、中央銀行が預金通貨銀行に貸し出したり適格債券を割り引いたりする際の金利で、通常、期末ベースで表示される。',
  'hungary_national_day.description:ja:DEFAULT': '',
  'hungary_national_day.description:ja:Hungary': '',
  'new_yuan_loans.description:ja:DEFAULT': '',
  'new_yuan_loans.description:ja:China':
    '中国では、新規人民元建て融資は、金融機関のバランスシート上の人民元建て与信額を指し、全融資額の5分の4以上を占める。',
  'fomc_economic_projections.description:ja:DEFAULT': '',
  'fomc_economic_projections.description:ja:United States':
    '米国では、金利設定の権限は連邦準備制度理事会（理事会）と連邦公開市場委員会（FOMC）に分けられている。理事会は、1つ以上の地区連邦準備銀行から提出された勧告を受けて、割引率の変更を決定する。FOMCは、中央銀行の資金量やフェデラルファンド市場金利などの公開市場操作を決定する。',
  'housing_starts_yoy.description:ja:DEFAULT': '',
  'housing_starts_yoy.description:ja:Canada': '',
  'housing_starts_yoy.description:ja:Japan':
    '日本では住宅着工戸数は新設住宅建設工事が開始した戸数の前年比変化を指す。',
  'housing_starts_mom.description:ja:DEFAULT': '',
  'housing_starts_mom.description:ja:United States':
    '住宅着工件数は、1カ月の間に工事が開始した新築住宅の建設工事数を指す。住宅着工件数の推定値には、既存の構造物の土台の上に建てられる、完全に建て直される建築物も含まれる。',
  'household_spending_yoy.description:ja:DEFAULT': '',
  'household_spending_yoy.description:ja:Japan':
    '日本では家計支出は、農業、林業、漁業を営む世帯を含む二人以上の世帯を対象に、消費支出（食料、住居、光熱・水道、家具、被覆、保健医療、教育、交通・通信、娯楽など）の年間増減率を実質ベースで測定する。',
  'household_spending_mom.description:ja:DEFAULT': '',
  'household_spending_mom.description:ja:Japan': '',
  'household_saving_ratio.description:ja:DEFAULT': '',
  'household_saving_ratio.description:ja:Ireland':
    'アイルランドでは、総貯蓄率は、総可処分所得に対する貯蓄の比率を表す。家計貯蓄率の季節調整済み推定値は、間接的な季節調整アプローチを使用して算出される。このアプローチでは、家計の可処分所得と家計の最終消費支出という2つの主要な総額が個別に調整され、これら調整済みの2系列の差が貯蓄となる。CSO（アイルランド中央統計局）は、家計貯蓄率など、季節調整後のこれら2つの総額の正味差分を推定する手法が、より適切であるとしている。',
  'leading_economic_index.description:ja:DEFAULT': '',
  'leading_economic_index.description:ja:Thailand':
    'タイでは、一致指数は経済動向と短期的な経済予測を評価するための補完的なツールである。特に景気の反転時期やピーク、あるいは底を判断したり、目先（3-4カ月先）の経済予測をしたりする際に役立つ。同指数は2000年を基準年（値100）とする。',
  'leading_economic_index.description:ja:Japan': '',
  'leading_economic_index.description:ja:Israel':
    'イスラエルの総合経済状況指数は、実際の経済活動がどの方向にあるかをリアルタイムで検証する合成指標である。この指数は、鉱工業生産指数、貿易収入、サービス収入、消費財の輸入、製造用原材料の輸入、商品の輸出、サービスの輸出、民間部門の雇用数、求人率、建設着工数の10種類の要素に基づいて算出される。この指数は、2011年時点での基準値を100とする。',
  'unemployment_rate-prel.description:ja:DEFAULT': '',
  'unemployment_rate-prel.description:ja:Singapore':
    'シンガポールでは、失業率は労働力全体のうち、積極的に仕事を探している人口の比率を測定する。',
  'household_lending_growth_yoy.description:ja:DEFAULT': '',
  'household_lending_growth_yoy.description:ja:Sweden':
    'スウェーデンでは、家計貸出伸び率は国内の金融機関による家計向け与信額の前年比変化を示す。',
  'household_consumption_mm.description:ja:DEFAULT': '',
  'household_consumption_mm.description:ja:Sweden':
    'スウェーデン統計局は2013年以来、家計消費に関する月次統計を編纂している。国内総生産（GDP）の47％を家計消費が占めるため、この統計は景気にとって重要な指標となる。',
  'house_price_index_yoy.description:ja:DEFAULT': '',
  'house_price_index_yoy.description:ja:Norway':
    'ノルウェーでは、住宅指数は全国の既存住宅の住宅価格指数を指す。',
  'house_price_index_yoy.description:ja:China':
    '中国では住宅価格指数は大中規模70都市の住宅価格の前年比変化を測定する。',
  'house_price_index_yoy.description:ja:United States':
    '米連邦住宅金融局（FHFA）住宅価格指数は、連邦住宅抵当金庫（ファニーメイ）と連邦住宅貸付抵当公社（フレディーマック）が融資を保証している戸建住宅の平均価格の月次変化を測定する。',
  'house_price_index_yoy.description:ja:Australia':
    'オーストラリアでは、シドニー、メルボルン、ブリスベン、アデレード、キャンベラ、ホバート、ダーウィン、パースの8つの州都で、住宅物件の価格変動の加重平均によって住宅価格指数を測定している。 住宅用不動産は、築年数に関係なく、一区画の土地に建てられた一戸建て住宅として定義されている。。',
  'house_price_index_yoy.description:ja:United Kingdom': '',
  'ecb_president_lagarde_speech.description:ja:DEFAULT': '',
  'ecb_president_lagarde_speech.description:ja:Euro Area':
    'ユーロ圏の基準金利は欧州中央銀行（ECB）の理事会によって決定される。ECBの金融政策の主な目的は、物価の安定を維持することであり、それはインフレ率を中期的に2％未満でかつそれに近い水準に抑えることである。低インフレ・低金利が長期化した場合、ECBは資産購入プログラムなどの非標準的な金融政策を採用することもある。公式金利は、主要リファイナンスオペ金利である。。',
  'gdp_growth_rate_qoq_prel.description:ja:Netherlands':
    'オランダ経済はユーロ圏で第6位、ヨーロッパの重要な交通拠点である。オランダ経済は外国貿易に大きく依存しており、輸出はGDPの83％、輸入は72％を占める。GDPの主な構成要素は家計消費（45％）で、次いで政府支出（26％）、総固定資本形成（18％）、純輸出（11％）となっている。',
  'gdp_growth_rate_qoq_prel.description:ja:Germany':
    'ドイツは世界第5位、ユーロ圏最大の経済大国で、輸出は世界第2位であり、ドイツ全体の生産高の3分の1以上を輸出が占める。従って、ドイツでは高付加価値製品の輸出が近年の成長ドライバーとなってきた。GDPの支出側の構成は、家計消費（55％）、総資本形成（20％、うち建設業が10％、機械装置が6％、その他が4％）、政府支出（19％）となっている。財・サービスの輸出はGDPの46％、輸入は39％で、純輸出はGDP全体に7％の寄与となっている。',
  'gdp_growth_rate_qoq_prel.description:ja:Italy':
    'イタリアは世界で9番目に大きい経済規模を誇り、ユーロ圏では3番目に大きい経済国である。同国は、大部分が高品質消費者向け製品の製造が占める多様化した工業を基盤としている。GDPの支出構成は、家計消費（61％）、政府支出（19％）、総固定資本形成（17％）となっている。製品・サービスの輸出はGDPの30％、輸入は27％であるため、GDP合計の3％に寄与している。',
  'gdp_growth_rate_qoq_prel.description:ja:Spain':
    'スペインはユーロ圏第4位の経済国で、欧州連合内では第5位の規模である。同国は堅固で多様な製造業を有し、世界でも最も観光客が訪れる国の1つである。支出側では、家計消費がスペインGDPの主な構成要素で58％を占めており、次いで総固定資本形成（21％）、政府支出（18％）となっている。投資のうち、有形固定資産は、建設（10％）、機械・設備・兵器システム（7％）などを含めGDPの18％を占めており、残りの3％は知的財産製品である。財・サービスの輸出はGDPの34%である一方、輸入は31%であるため、純輸出はGDP全体に1%寄与している。',
  'gdp_growth_rate_qoq_prel.description:ja:Norway':
    'ノルウェーは世界で最も裕福な国のひとつである。原油・ガス、水力、魚類などの天然資源がその富の基盤となり、発達した海運業がそれを支えている。支出面のGDPの内訳は、家計消費支出（41%）、総固定資本形成（24%）、政府消費支出（22%）となる。財とサービスの輸出はGDPの38%、輸入は30%を占め、純輸出が8%のプラス寄与となる。',
  'gdp_growth_rate_qoq_prel.description:ja:South Korea':
    '韓国は輸出主導の経済であり、ディスプレイと半導体記憶装置で世界をリードしており、船舶の製造では第2位となっている。GDPに占めるサービス業の割合が最も高くなっている（GDPの約57％）。サービス業の中でも大きな割合を占めるのは、行政（6％）、教育（5.3％）、情報・通信（5％）、事業活動（4.6％）である。韓国を大規模輸出国とする要因となった強力な製造業は、GDPの32％を占めている。',
  'gdp_growth_rate_qoq_prel.description:ja:Greece':
    'ギリシャはサービス業を基盤とした経済で、世界中で最も観光客が訪れる国の1つとなっている。1980年に欧州連合、2000年にはユーロ圏に加入して以来、ギリシャ経済は高成長を記録していたが、その成長は主に低コストの信用と公的セクターの成長に依存していたため、2008年には財政赤字と公的債務が持続不可能な水準に達した。その結果、ギリシャは1974年以来最悪の危機に直面、IMFと欧州委員会が救済策の一環として厳しい改革プログラムを執行することとなった。',
  'gdp_growth_rate_qoq_prel.description:ja:Hungary':
    'ハンガリーでは家計消費がGDPの主な構成要素であり、総支出の50％を占め、総固定資本形成（22％）、政府支出（20％）がそれに続く。財・サービスの輸出はGDPの89%である一方、輸入は82%であるため、GDP合計に７%のプラス寄与となっている。',
  'gdp_growth_rate_qoq_prel.description:ja:Denmark':
    'デンマークでは家計消費がGDPの主な構成要素であり、その総支出の49％を占めており、次いで政府支出（27％とEU諸国では最高）、総固定資本形成（19％）となっている。財・サービスの輸出はGDPの54%に達する一方、輸入は48%であるため、GDP合計に６%のプラス寄与となっている。',
  'gdp_growth_rate_qoq_prel.description:ja:Portugal':
    '支出面では、家計消費がGDPの主な構成要素であり、その合計支出の66％を占めている。続いて、政府支出（19％）、総固定資本形成（15％）となっている。財・サービスの輸出はGDPの40％、輸入も40％を占める。',
  'gdp_growth_rate_qoq_prel.description:ja:United Kingdom':
    'サービス業は最も重要で、イギリスのGDPの79%を占めている。サービス業の中で最も大きな分野は、政府・教育・保健（GDP全体の19％）、不動産（12％）、専門的・科学的・技術的活動および管理・支援サービス（12％）、卸売・小売業（11％）、金融・保険（8％）である。工業分野はGDPの21％を占め、この分野の最大のセグメントは、製造業（GDP全体の10％）と建設業（6％）である。農業部門はGDPのわずか1％を占めている。支出面でのGDPの構成は、家計消費（65％）、政府支出（20％）、総固定資本形成（17％）となっている。財・サービスの輸出はGDPの28％、輸入は30％を占めるため、輸出入はGDPに2％のマイナス寄与となっている。',
  'gdp_growth_rate_qoq_prel.description:ja:Czech Republic':
    'チェコ共和国では家計消費がGDPの主な構成要素であり、その総支出の49％を占めており、次いで総固定資本形成（25％）、政府支出（19％）となっている。財・サービスの輸出はGDPの84%である一方、輸入は77%であるため、GDP合計に７%のプラス寄与となっている。',
  'gdp_growth_rate_qoq_prel.description:ja:Poland':
    'ポーランドの経済規模は欧州連合で8番目でありながら、人口1人当たりのGDPはEU平均を大きく下回る。同国の産業基盤は石炭、繊維、化学品、機械、鉄、鉄鋼セクターであり、近年は肥料、石油化学、工作機械、電子機械、エレクトロニクス、自動車、造船にも広がっている。支出面では、家計消費がGDPの主要コンポーネントで、全体の60％を占め、次いで総固定資本形成（20％）と政府支出（18％）が続く。財とサービスの輸出はGDP全体の47％を占め、輸入は46％でGDP全体に1％の寄与となっている。',
  'gdp_growth_rate_qoq_prel.description:ja:Finland':
    'フィンランド経済ではサービス業が65%を占めるが、主要セクターは主に材木、金属、エンジニアリング、通信、エレクトロニクスなどの製造業である。支出面では、家計消費がGDPの主な構成要素であり、その総支出の55％を占めており、次いで政府支出（25％）、総固定資本形成（20％）となっている。財・サービスの輸出はGDPの38%、輸入は39%であるため、GDP全体に1%のマイナス寄与となっている。。',
  'gdp_growth_rate_qoq_prel.description:ja:Mexico':
    'メキシコではサービス業がGDP全体の62%を占めている。サービス業の中で最も大きな分野は、卸売・小売（16％）、不動産（10％）、輸送・倉庫・通信（7％）、金融サービス・保険（6％）である。工業は生産高の18％を占めており、その最大のセグメントは自動車（GDP全体の4％）と食品（3.8％）である。それ以外は建設、水道・ガス・配電（8％）、鉱業（5％）、政府（4％）、農業・林業・漁業・狩猟（3％）が占める。',
  'gdp_growth_rate_qoq_prel.description:ja:France':
    'フランスは世界で7番目、ユーロ圏で2番目に大きい経済国である。GDPの支出を大きく占めるのは家計消費（55％）、政府支出（24％）、総固定資本形成（22％）となっている。財・サービスの輸出はGDPの29％、輸入は31％であるため、純輸出はGDP全体に2％のマイナス寄与となっている。',
  'consumer_price_index_eu_norm_yoy.description:ja:DEFAULT': '',
  'consumer_price_index_eu_norm_yoy.description:ja:Italy': '',
  'hong_kong_special_administrative_region_establishment_day.description:ja:DEFAULT': '',
  'hong_kong_special_administrative_region_establishment_day.description:ja:Hong Kong': '',
  'hometrack_housing_prices_s_a.description:ja:DEFAULT': '',
  'hometrack_housing_prices_s_a.description:ja:United Kingdom': '',
  'all_car_sales.description:ja:DEFAULT': '',
  'all_car_sales.description:ja:United States': '',
  'freedom_day.description:ja:DEFAULT': '',
  'freedom_day.description:ja:South Africa': '',
  'rosh_hashanah.description:ja:DEFAULT': '',
  'rosh_hashanah.description:ja:Israel': '',
  'finance_minister_guindos_speech.description:ja:DEFAULT': '',
  'finance_minister_guindos_speech.description:ja:Spain': '',
  'eu_tusk_juncker_speak_at_european_parliament.description:ja:DEFAULT': '',
  'eu_tusk_juncker_speak_at_european_parliament.description:ja:Euro Area': '',
  'holy_spirit_monday.description:ja:DEFAULT': '',
  'holy_spirit_monday.description:ja:Greece': '',
  'tokyo_cpi_ex_fresh_food_yoy.description:ja:DEFAULT': '',
  'tokyo_cpi_ex_fresh_food_yoy.description:ja:Japan': '',
  'greenery_day.description:ja:DEFAULT': '',
  'greenery_day.description:ja:Japan': '',
  'pancasila_day.description:ja:DEFAULT': '',
  'pancasila_day.description:ja:Indonesia': '',
  'holy_saturday.description:ja:DEFAULT': '',
  'holy_saturday.description:ja:Philippines': '',
  'holi_feast.description:ja:DEFAULT': '',
  'holi_feast.description:ja:India': '',
  'hindu_day_of_silence_1935.description:ja:DEFAULT': '',
  'hindu_day_of_silence_1935.description:ja:Indonesia': '',
  'heritage_day.description:ja:DEFAULT': '',
  'heritage_day.description:ja:South Africa': '',
  'general_elections_2nd_round.description:ja:DEFAULT': '',
  'general_elections_2nd_round.description:ja:Brazil': '',
  'cbi_industrial_trends_orders.description:ja:DEFAULT': '',
  'cbi_industrial_trends_orders.description:ja:United Kingdom':
    '英国では、英国産業連盟（CBI）の企業動向調査受注指数が、製造業38セクター約500社の工場受注水準の変化を追跡する。この調査は、国内受注と輸出受注、在庫、価格、投資意欲、生産見通しの各変数に対し、製造業幹部に現状を通常以上・通常・通常以下のいずれかから判断するよう求めるもので、その結果はウェート付けされたバランスのパーセント、つまり各設問に対する「増加・改善」との回答比率から「減少・悪化」との回答比率を差し引いたものである。',
  'health_and_sports_day.description:ja:DEFAULT': '',
  'health_and_sports_day.description:ja:Japan': '',
  'ecb_lautenschl_ger_speech.description:ja:DEFAULT': '',
  'ecb_lautenschl_ger_speech.description:ja:Euro Area': '',
  'ipca_mid-month_cpi_mom.description:ja:DEFAULT': '',
  'ipca_mid-month_cpi_mom.description:ja:Brazil': '',
  'harvest_festival_day_3.description:ja:DEFAULT': '',
  'harvest_festival_day_3.description:ja:South Korea': '',
  'harvest_festival_day_1.description:ja:DEFAULT': '',
  'harvest_festival_day_1.description:ja:South Korea': '',
  'harmonized_inflation_rate_yoy.description:ja:DEFAULT': '',
  'harmonized_inflation_rate_yoy.description:ja:Greece': '',
  'brazil_investment_forum_2017.description:ja:DEFAULT': '',
  'brazil_investment_forum_2017.description:ja:Brazil': '',
  'ecb_macroeconomic_projections.description:ja:DEFAULT': '',
  'ecb_macroeconomic_projections.description:ja:Euro Area':
    'ユーロ圏の基準金利は欧州中央銀行（ECB）の理事会によって決定される。ECBの金融政策の主な目的は、物価の安定を維持することであり、それはインフレ率を中期的に2％未満でかつそれに近い水準に抑えることである。低インフレ・低金利が長期化した場合、ECBは資産購入プログラムなどの非標準的な金融政策を採用することもある。公式金利は、主要リファイナンスオペ金利である。。',
  'harmonised_inflation_rate_yoy_final.description:ja:DEFAULT': '',
  'harmonised_inflation_rate_yoy_final.description:ja:Italy':
    '調和物価指数（HICP）は、欧州中央銀行（ECB）によるインフレおよび物価安定性の指標である。HICPは、ユーロスタットおよび各国の統計機関により調整統計手法によってまとめられる。ECBは、HICPが発表する中期に渡る年間インフレ率を2%弱に保つことを目標としている。',
  'harmonised_inflation_rate_yoy_final.description:ja:France':
    '調和物価指数（HICP）は、欧州中央銀行（ECB）によるインフレおよび物価安定性の指標である。HICPは、ユーロスタットおよび各国の統計機関により調整統計手法によってまとめられる。ECBは、HICPが発表する中期に渡る年間インフレ率を2%弱に保つことを目標としている。',
  'harmonised_inflation_rate_yoy_final.description:ja:Spain':
    '調和物価指数（HICP）は、欧州中央銀行（ECB）によるインフレおよび物価安定性の指標である。HICPは、ユーロスタットおよび各国の統計機関により調整統計手法によってまとめられる。ECBは、HICPが発表する中期に渡る年間インフレ率を2%弱に保つことを目標としている。',
  'harmonised_inflation_rate_yoy_final.description:ja:Germany':
    '調和物価指数（HICP）は、欧州中央銀行（ECB）によるインフレおよび物価安定性の指標である。HICPは、ユーロスタットおよび各国の統計機関により調整統計手法によってまとめられる。ECBは、HICPが発表する中期に渡る年間インフレ率を2%弱に保つことを目標としている。',
  '3-year_note_auction.description:ja:DEFAULT': '',
  '3-year_note_auction.description:ja:United States': '',
  'gdp_capital_expenditure_qoq.description:ja:DEFAULT': '',
  'gdp_capital_expenditure_qoq.description:ja:Australia':
    '総固定資本形成は、生産者による会計期間中の固定資産の取得から処分を控除したものに、生産者自身が行った設備投資と、制度単位の生産活動によって実現した非生産資産の価値増大を加えたものに等しい。',
  'gdp_capital_expenditure_qoq.description:ja:Japan':
    '総固定資本形成は、企業、政府、家計（非株式会社の法人は除く）が取得した新規固定資産または既存の固定資産の価値から固定資産の売却分を除いた価値を測定する。',
  'gurunanak_jayanti.description:ja:DEFAULT': '',
  'gurunanak_jayanti.description:ja:India': '',
  'european_commission_recovery_fund_proposal.description:ja:DEFAULT': '',
  'european_commission_recovery_fund_proposal.description:ja:European Union': '',
  'boc_press_conference.description:ja:DEFAULT': '',
  'boc_press_conference.description:ja:Canada': '',
  'harmonised_inflation_rate_mom_prel.description:ja:DEFAULT': '',
  'harmonised_inflation_rate_mom_prel.description:ja:Italy':
    '調和物価指数（HICP）は、欧州中央銀行（ECB）によるインフレおよび物価安定性の指標である。HICPは、ユーロスタットおよび各国の統計機関により調整統計手法によってまとめられる。ECBは、HICPが発表する中期に渡る年間インフレ率を2%弱に保つことを目標としている。',
  'harmonised_inflation_rate_mom_prel.description:ja:France':
    '調和物価指数（HICP）は、欧州中央銀行（ECB）によるインフレおよび物価安定性の指標である。HICPは、ユーロスタットおよび各国の統計機関により調整統計手法によってまとめられる。ECBは、HICPが発表する中期に渡る年間インフレ率を2%弱に保つことを目標としている。',
  'harmonised_inflation_rate_mom_prel.description:ja:Germany':
    '調和物価指数（HICP）は、欧州中央銀行（ECB）によるインフレおよび物価安定性の指標である。HICPは、ユーロスタットおよび各国の統計機関により調整統計手法によってまとめられる。ECBは、HICPが発表する中期に渡る年間インフレ率を2%弱に保つことを目標としている。',
  'harmonised_inflation_rate_mom_prel.description:ja:Spain':
    '調和物価指数（HICP）は、欧州中央銀行（ECB）によるインフレおよび物価安定性の指標である。HICPは、ユーロスタットおよび各国の統計機関により調整統計手法によってまとめられる。ECBは、HICPが発表する中期に渡る年間インフレ率を2%弱に保つことを目標としている。',
  'fed_tarullo_testifies.description:ja:DEFAULT': '',
  'fed_tarullo_testifies.description:ja:United States': '',
  'maharashtra_day.description:ja:DEFAULT': '',
  'maharashtra_day.description:ja:India': '',
  'harmonised_inflation_rate_mom.description:ja:DEFAULT': '',
  'harmonised_inflation_rate_mom.description:ja:Austria':
    '調和物価指数（HICP）は、欧州中央銀行（ECB）によるインフレおよび物価安定性の指標である。HICPは、ユーロスタットおよび各国の統計機関により調整統計手法によってまとめられる。ECBは、HICPが発表する中期に渡る年間インフレ率を2%弱に保つことを目標としている。',
  'harmonised_inflation_rate_mom.description:ja:Ireland':
    '調和物価指数（HICP）は、欧州中央銀行（ECB）によるインフレおよび物価安定性の指標である。HICPは、ユーロスタットおよび各国の統計機関により調整統計手法によってまとめられる。ECBは、HICPが発表する中期に渡る年間インフレ率を2%弱に保つことを目標としている。',
  'harmonised_inflation_rate_mom.description:ja:Germany': '',
  'harmonised_inflation_rate_mom.description:ja:France':
    '調和物価指数（HICP）は、欧州中央銀行（ECB）によるインフレおよび物価安定性の指標である。HICPは、ユーロスタットおよび各国の統計機関により調整統計手法によってまとめられる。ECBは、HICPが発表する中期に渡る年間インフレ率を2%弱に保つことを目標としている。',
  'hari_raya_aidil_fitri_1434_hijriyah.description:ja:DEFAULT': '',
  'hari_raya_aidil_fitri_1434_hijriyah.description:ja:Indonesia': '',
  'the_queen_s_birthday.description:ja:DEFAULT': '',
  'the_queen_s_birthday.description:ja:Thailand': '',
  'hangul_day.description:ja:DEFAULT': '',
  'hangul_day.description:ja:South Korea': '',
  'job_vacancies.description:ja:DEFAULT': '',
  'job_vacancies.description:ja:Germany':
    'ドイツでは、求人数は、調査の基準日において積極的に人員を募集しており、即座に有効な求人数を指す。',
  'job_vacancies.description:ja:Canada':
    'カナダでは、以下の条件が満たされると欠員が生じているとみなされる：基準日（月初日）に欠員がある、またはその月に欠員が予定されていること。その職に対し、その月に遂行すべき仕事があること。かつ、雇用主がその欠員を補充するため積極的に社外から人員を募集していること。雇用形態は、フルタイム、パートタイム、終身雇用、一時雇用、臨時雇用、季節雇用を問わない。下請け業者や社外コンサルタント、その他社員とみなされない労働者用の仕事は除外される。',
  'hammond_presents_autumn_statement.description:ja:DEFAULT': '',
  'hammond_presents_autumn_statement.description:ja:United Kingdom': '',
  'hsbc_markit_services_pmi.description:ja:DEFAULT': '',
  'hsbc_markit_services_pmi.description:ja:India': '',
  'kof_leading_indicators.description:ja:DEFAULT': '',
  'kof_leading_indicators.description:ja:Switzerland':
    'スイスではKOFスイス経済研究所の経済バロメーターが、企業経営者が景気についてどの程度楽観的に考えているか、自社の見通しについてどのように感じているかを測定している。KOF経済バロメーターは、3つのモジュールによるマルチセクター設計に基づいている。2014年版は219の指標変数が、統計的に決定されたウェイトに基づいて組み合わせられている。最も重要なモジュールは、建設業と銀行業を除いたGDPであり、これがスイスのGDPの90％以上を占めている。',
  'hsbc_markit_manufacturing_pmi.description:ja:DEFAULT': '',
  'hsbc_markit_manufacturing_pmi.description:ja:India': '',
  'hsbc_markit_manufacturing_pmi.description:ja:South Korea': '',
  'hsbc_markit_manuf_pmi.description:ja:DEFAULT': '',
  'hsbc_markit_manuf_pmi.description:ja:South Korea': '',
  'g7_finance_ministers_meeting.description:ja:DEFAULT': '',
  'g7_finance_ministers_meeting.description:ja:Germany': '',
  'hsbc_services_pmi.description:ja:DEFAULT': '',
  'hsbc_services_pmi.description:ja:United States': '',
  'hsbc_services_pmi.description:ja:Russia':
    'マークイット・ロシアのサービス業購買担当者景気指数（PMI）は、約300社の民間企業の上級購買担当者から収集したアンケート調査の回答を基に集計され、売上高、雇用、在庫、価格などの変数を追跡し、指数が50を超えればサービス業が全体的に拡大していることを、50を切れば全体的に縮小していることを示す。',
  'hsbc_services_pmi.description:ja:Brazil':
    'マークイット・ブラジルのサービス業購買担当者景気指数(PMI)は、民間サービス業の企業約350社の購買担当役員を対象に毎月実施されるアンケートの回答データに基づいて算出される。この指数は、売上高、雇用、在庫、価格などの変数を追跡している。指数が50を超えると、その変数が全体的に増加していることを示し、50を下回ると全体的に減少していることを示す。',
  'hsbc_services_pmi.description:ja:India': '',
  'hsbc_services_pmi.description:ja:China': '',
  'gdp_growth_rate_yoy-2_est.description:ja:DEFAULT': '',
  'gdp_growth_rate_yoy-2_est.description:ja:Poland':
    'ポーランドの消費者市場は3,800万人と、ヨーロッパでも最大級の規模を誇る。ポーランドの主要産業は、食品・飲料加工、造船、機械・鉄鋼製品・化学品・ガラス・繊維製品の製造である。支出面では、家計消費がGDPの60％を占めており、次いで総固定資本形成（20％）、政府支出（18％）である。財・サービスの輸出はGDPの47％を占め、輸入は46％で、GDP全体に1％寄与している。',
  'hm_the_king_s_birthday.description:ja:DEFAULT': '',
  'hm_the_king_s_birthday.description:ja:Thailand': '',
  'average_weekly_earnings_yoy.description:ja:DEFAULT': '',
  'average_weekly_earnings_yoy.description:ja:Ireland':
    'アイルランドでは平均週給（前年比）は、四半期の合計所得を同時期における雇用人数で割り、それを13（1四半期当たりの週数）で割ったものに相当し、平均週給の年次変化を測定する。',
  'average_weekly_earnings_yoy.description:ja:Canada':
    'カナダでは、平均週給（残業代を含む）は、課税対象給与合計（残業代を含む）を被雇用者数で割った数値で、あらゆるタイプの被雇用者が含まれる。一般的に、週給の変化は、賃金の伸び、業界別の雇用構成の変化、職種と経験水準、1週間当たりの平均労働時間など、多くの要因を反映したものとなる。',
  'hicp_yoy.description:ja:DEFAULT': '',
  'hicp_yoy.description:ja:Spain': '',
  'gfk_consumer_confidence_flash.description:ja:DEFAULT': '',
  'gfk_consumer_confidence_flash.description:ja:United Kingdom':
    '英国では消費者信頼感は向こう12カ月間の景気見通しに対する消費者の楽観度を測定する。GfK消費者信頼感指数は約2000人の消費者を対象に、個人の財務状況や大きな買い物に向けた環境、全体的な経済状況、貯蓄水準などを含めて過去と将来の相対的な経済状況を尋ねた調査から導き出される。',
  'gdp_growth_rate_yoy_third_estimate.description:ja:DEFAULT': '',
  'gdp_growth_rate_yoy_third_estimate.description:ja:United States':
    '米国は世界最大の経済大国である。しかし、他の多くの先進国と同様に、この20年間でその成長率は低下している。 50年代、60年代の平均成長率が4％を超えていたのに対し、70年代、80年代には3％前後にまで落ち込んだ。 過去10年間の平均成長率は2％を下回り、2000年の第2四半期以降は一度も5％台に達していない。',
  'halpim_manufacturing_pmi.description:ja:DEFAULT': '',
  'halpim_manufacturing_pmi.description:ja:Hungary':
    'ハンガリーでは、HALPIM（ハンガリーロジスティックス購買・在庫管理協会）の製造業購買担当者景気指数（PMI）が、製造業企業の購買担当上級幹部に行う調査結果を基に製造業の業況を測定する。同指数はそれぞれウエート付けされた、新規受注（30%）、生産（25%）、雇用（20%）、サプライヤー納期（15%）、購買品在庫（10%）という5つの個別インデックスから構成される。50を超える指数は製造業セクターが前期に比べて拡大していることを、50を切る指数は縮小していることを、50は変化がないことを示す。',
  'gudi_padwa.description:ja:DEFAULT': '',
  'gudi_padwa.description:ja:India': '',
  '17_may_constitution_day.description:ja:DEFAULT': '',
  '17_may_constitution_day.description:ja:Norway': '',
  'eu-south_korea_video_conference_meeting.description:ja:DEFAULT': '',
  'eu-south_korea_video_conference_meeting.description:ja:European Union': '',
  'gross_wage_yoy.description:ja:DEFAULT': '',
  'gross_wage_yoy.description:ja:Hungary':
    '総賃金は国内経済におけるフルタイム従業員の月次平均賃金の前年比変化を測定する。',
  'gross_domestic_product_price_index.description:ja:DEFAULT': '',
  'gross_domestic_product_price_index.description:ja:United States': '',
  'scottish_parliament_election.description:ja:DEFAULT': '',
  'scottish_parliament_election.description:ja:United Kingdom': '',
  'gross_domestic_product_annualized.description:ja:DEFAULT': '',
  'gross_domestic_product_annualized.description:ja:United States': '',
  'gross_domestic_product_mom.description:ja:DEFAULT': '',
  'gross_domestic_product_mom.description:ja:Canada': '',
  'government_debt_to_gdp.description:ja:DEFAULT': '',
  'government_debt_to_gdp.description:ja:Euro Area':
    '一般的に、政府債務残高のGDP比は、国家が将来公的債務を返済する能力を測る指標として投資家によって注目される。国の返済能力は国家の借り入れコストと国債利回りに影響する。',
  'government_budget_to_gdp.description:ja:DEFAULT': '',
  'government_budget_to_gdp.description:ja:Euro Area':
    '政府予算は、政府歳入（税収およびその他収入）および政府歳出（購入および支払い）の項目別統計である。赤字予算は、政府が歳入以上に支出していることを示す。その反対は、黒字予算となる。',
  'queen_s_speech_at_the_parliament.description:ja:DEFAULT': '',
  'queen_s_speech_at_the_parliament.description:ja:United Kingdom': '',
  'goodwill_day.description:ja:DEFAULT': '',
  'goodwill_day.description:ja:South Africa': '',
  'global_business_summit.description:ja:DEFAULT': '',
  'global_business_summit.description:ja:India': '',
  'bundesbank_balz_speech.description:ja:DEFAULT': '',
  'bundesbank_balz_speech.description:ja:Germany':
    'ドイツはユーロを採用している欧州連合（EU）加盟国である。ドイツの基準金利は欧州中央銀行が設定し、公式には主要リファイナンス・オペ（MRO）と呼ばれる。',
  'childrens_day.description:ja:DEFAULT': '',
  'childrens_day.description:ja:South Korea': '',
  'childrens_day.description:ja:Taiwan': '',
  'import_prices_qoq.description:ja:DEFAULT': '',
  'import_prices_qoq.description:ja:New Zealand':
    'ニュージーランドでは、輸入物価指数は、ニュージーランド居住者が国外の売り手から購入したり供給されたりした財とサービスの価格の変動率を指す。輸入物価は為替レートに大きく左右される。',
  'import_prices_qoq.description:ja:Australia':
    'オーストラリアでは、輸入物価指数は、オーストラリア居住者が国外の売り手から購入したり供給されたりした財とサービスの価格の変動率を指す。輸入物価は為替レートに大きく左右される。',
  'general_government_budget.description:ja:DEFAULT': '',
  'general_government_budget.description:ja:Euro Area':
    '政府予算は、政府歳入（税収およびその他収入）および政府歳出（購入および支払い）の項目別統計である。赤字予算は、政府が歳入以上に支出していることを示す。その反対は、黒字予算となる。',
  'general_election_results.description:ja:DEFAULT': '',
  'general_election_results.description:ja:India': '',
  'part_time_employment_chg.description:ja:Australia':
    'オーストラリアでは、パートタイムの就労者は、すべての雇用で通常の労働時間が週35時間未満の労働者をいう。',
  'general_election.description:ja:DEFAULT': '',
  'general_election.description:ja:New Zealand': '',
  'general_election.description:ja:Thailand': '',
  'general_election.description:ja:Spain': '',
  'general_election.description:ja:United Kingdom': '',
  'general_election.description:ja:Singapore': '',
  'european_council_special_meeting.description:ja:DEFAULT': '',
  'european_council_special_meeting.description:ja:European Union': '',
  'fipes_ipc_inflation.description:ja:DEFAULT': '',
  'fipes_ipc_inflation.description:ja:Brazil': '',
  'general_council_meeting.description:ja:DEFAULT': '',
  'general_council_meeting.description:ja:Euro Area': '',
  'riksbank_monetary_policy_report.description:ja:DEFAULT': '',
  'riksbank_monetary_policy_report.description:ja:Sweden': '',
  'industrial_capacity_capacity_utilization.description:ja:DEFAULT': '',
  'industrial_capacity_capacity_utilization.description:ja:China':
    '中国では、工場稼働率は生産能力と実際の稼働状況との差を示し、労働力と資本が最も良く活用され、実際の生産量が生産能力に近い時に、稼働率は高くなる。調査対象は約9万社。',
  'gasoline_inventories.description:ja:DEFAULT': '',
  'gasoline_inventories.description:ja:United States': '',
  'gnp_yoy_final.description:ja:DEFAULT': '',
  'gnp_yoy_final.description:ja:Ireland':
    'アイルランドでは、GNP（国民総生産)は、GDP（国内総生産）と、アイルランドに居住する個人や企業が海外で得た投資収入（金利、利益など）およびアイルランドに居住する個人や企業が海外で得た労働収入（流入）と、アイルランド国内で非居住者が得た同様の収入（流出）との差である海外からの純所得（NFI）との総和である。NFIは２種類の多額の資金フローの差であるため、その規模は四半期ごとに大きく変動する可能性があり、同じ四半期であってもGDPとGNPの成長率が大きく異なる場合がある。',
  'gnp_qoq_final.description:ja:DEFAULT': '',
  'gnp_qoq_final.description:ja:Ireland':
    'アイルランドでは、GNP（国民総生産)は、GDP（国内総生産）と、アイルランドに居住する個人や企業が海外で得た投資収入（金利、利益など）およびアイルランドに居住する個人や企業が海外で得た労働収入（流入）と、アイルランド国内で非居住者が得た同様の収入（流出）との差である海外からの純所得（NFI）との総和である。NFIは２種類の多額の資金フローの差であるため、その規模は四半期ごとに大きく変動する可能性があり、同じ四半期であってもGDPとGNPの成長率が大きく異なる場合がある。',
  'tankan_non-manufacturing_index.description:ja:DEFAULT': '',
  'tankan_non-manufacturing_index.description:ja:Japan': '',
  'gnp_qoq.description:ja:DEFAULT': '',
  'gnp_qoq.description:ja:Ireland':
    'アイルランドでは、GNP（国民総生産)は、GDP（国内総生産）と、アイルランドに居住する個人や企業が海外で得た投資収入（金利、利益など）およびアイルランドに居住する個人や企業が海外で得た労働収入（流入）と、アイルランド国内で非居住者が得た同様の収入（流出）との差である海外からの純所得（NFI）との総和である。NFIは２種類の多額の資金フローの差であるため、その規模は四半期ごとに大きく変動する可能性があり、同じ四半期であってもGDPとGNPの成長率が大きく異なる場合がある。',
  'fed_george_testimony.description:ja:DEFAULT': '',
  'fed_george_testimony.description:ja:United States': '',
  'markit_composite_pmi.description:ja:DEFAULT': '',
  'markit_composite_pmi.description:ja:Spain':
    '総合生産高指数は、製造業生産高指数とサービス事業活動指数との加重平均である。それぞれのウエートは、公式GDPデータに基づく製造業とサービス業の相対的な規模を反映したもので、総合生産高指数は「総合PMI」と呼ばれることもあるが、製造業購買担当者景気指数（PMI）の発表値と同じではない。',
  'markit_composite_pmi.description:ja:Brazil':
    'ブラジルでは、マークイット・ブラジル総合購買担当者景気指数（PMI）が製造業およびサービス業の両方を代表する約800社の企業のパネルから収集したデータを基に、民間セクター全体の景気動向を測定している。指数は売上高、新規受注、雇用、在庫、価格などの変数の動きを追い、数値が50を超えれば企業活動が拡大傾向にあることを示し、50を下回れば全般に縮小傾向にあることを示す。',
  'markit_composite_pmi.description:ja:India':
    'インドでは、製造業生産高指数とサービス事業活動指数の加重平均である日経インド総合生産指数が、約800社の代表的な企業パネルから収集したデータに基づいて、民間部門の事業活動の動向を追跡している。この指数は、売上高、新規受注、雇用、在庫、価格などの変数を追跡し、指数が50を超えると企業活動が拡大していることを示し、50を下回ると全般的に減少していることを示す。。',
  'markit_composite_pmi.description:ja:Euro Area': '',
  'markit_composite_pmi.description:ja:Russia':
    'マークイット・ロシア総合購買担当者景気指数（PMI）生産指数は、民間セクターを代表する約300社の企業のパネルから収集したデータを基に、民間セクター全体の景気動向を追跡する。指数は売上高、新規受注、雇用、在庫、価格などの変数の動きを追い、数値が50を超えれば企業活動が拡大傾向にあることを示し、50を下回れば全般に縮小傾向にあることを示す。',
  'gdp_private_consumption_qoq.description:ja:DEFAULT': '',
  'gdp_private_consumption_qoq.description:ja:Japan':
    '日本では、家計の消費支出確報値は、供給側の測定値に加え家計調査などによる消費者側の測定値を考慮する。家計消費支出の確報値は、需給両側の加重平均によって算出される。',
  'gross_domestic_product_annualized_final.description:ja:DEFAULT': '',
  'gross_domestic_product_annualized_final.description:ja:Japan': '',
  'full_year_gdp_growth.description:ja:DEFAULT': '',
  'full_year_gdp_growth.description:ja:Russia':
    'ロシアは世界有数の石油・天然ガスの生産国であり、鉄鋼や一次アルミニウムなどの金属の輸出国でもある。生産面では、サービス業が経済の最大セクターであり、GDPの58％を占めている。サービス業の中で最も重要な分野は、卸売・小売（GDP合計の17％）、行政・医療・教育（12％）である。工業は総生産の40％、農業は残りの2％。',
  'full_year_gdp_growth.description:ja:Philippines':
    'フィリピン経済最大の分野はサービス業であり、GDPの57%を占める。サービス業は主に、商業・自動車および家庭用品の修理（GDPの17%）、不動産・レンタルおよびビジネス活動（11%）、輸送・倉庫・通信（8%）、金融サービス（7%）、行政・防衛・社会保障（4%）からなる。産業部門はGDPの31%を占め、主に、製造業（22%）、建設業（5%）がある。農業は残りの12%を占める。',
  'full_year_gdp_growth.description:ja:Italy':
    'イタリアは欧州で2番目に大きい製造業経済であり、ユーロ圏で3番目に大きい経済国である。GDPの支出側の構成：家計消費（61％）、政府支出（19％）、総固定資本形成（17％）。財・サービスの輸出はGDPの30％、輸入は27％であるため、GDP全体に3％寄与している。',
  'full_year_gdp_growth.description:ja:Germany':
    '世界第5位、欧州最大の経済規模を誇るドイツ経済は、機械、自動車、化学品、家庭用機器などの主要な輸出国であり、高度に熟練した労働力の恩恵を受けている。GDPの支出側の構成：家計消費（55％）、総資本形成（20％、うち建設業が10％、機械装置が6％、その他が4％）、政府支出（19％）。財・サービスの輸出はGDPの46％、輸入は39％で、GDP全体に7％寄与している。',
  'full_year_gdp_growth.description:ja:Poland':
    'ポーランドの消費者市場は3,800万人と、ヨーロッパでも最大級の規模を誇る。ポーランドの主要産業は、食品・飲料加工、造船、機械・鉄鋼製品・化学品・ガラス・繊維製品の製造である。支出面では、家計消費がGDPの60％を占めており、次いで総固定資本形成（20％）、政府支出（18％）である。財・サービスの輸出はGDPの47％を占め、輸入は46％で、GDP全体に1％寄与している。',
  'full_year_gdp_growth.description:ja:Thailand':
    'タイは、輸出主導型の新興経済国である。このため、製造業が最も重要な経済分野であり、GDPの34%を占める。サービス業はGDPの約44%を占め、そのうち最も重要なのは卸売・小売（GDPの13%）、輸送・倉庫・通信（7%）、宿泊・飲食サービス（5%）、行政・国防・社会保障（4.5%）である。農業も経済成長に大きく貢献する分野であり、GDPの約13%を占める。',
  'full_year_gdp_growth.description:ja:Indonesia':
    'インドネシアは東南アジア最大の経済国である。産業部門がGDPの最大の割合を占め（46.5%）、その最も重要な構成項目である製造業は、主要な成長エンジンのひとつとなっている（GDPの24%）。鉱業・採石業はGDPの12%、建設業が10%、電力・ガス・水道が0.75%を占める。サービス業はGDPの38%を担い、その主要項目には商業・ホテル・レストラン（GDPの約14%）、輸送・通信（7%）、金融・不動産・ビジネスサービス（7%）、政府サービス（6%）がある。農業が残りの15%を占める。',
  'gdp_price_index_yoy_prel.description:ja:DEFAULT': '',
  'gdp_price_index_yoy_prel.description:ja:Japan':
    'GDPデフレーターは、最終財とサービスの価格変化を測るものであり、将来の金融政策の方向性に影響するインフレ圧力の主要指標であるとされている。',
  'parliamentary_debate.description:ja:DEFAULT': '',
  'parliamentary_debate.description:ja:United Kingdom': '',
  'gdp_price_index_qoq_adv.description:ja:DEFAULT': '',
  'gdp_price_index_qoq_adv.description:ja:United States':
    'GDPデフレーターは、最終財とサービスの価格変化を測るものであり、将来の金融政策の方向性に影響するインフレ圧力の主要指標であるとされている。',
  'ifo_-_current_assessment.description:ja:DEFAULT': '',
  'ifo_-_current_assessment.description:ja:Germany': '',
  'fed_harker_mester_lockhart_speech.description:ja:DEFAULT': '',
  'fed_harker_mester_lockhart_speech.description:ja:United States': '',
  'gdp_price_index_qoq_2nd_est.description:ja:DEFAULT': '',
  'gdp_price_index_qoq_2nd_est.description:ja:United States':
    'GDPデフレーターは、最終財とサービスの価格変化を測るものであり、将来の金融政策の方向性に影響するインフレ圧力の主要指標であるとされている。',
  'labour_cost_index_yoy.description:ja:Euro Area':
    'ユーロ圏の労働コスト指数は、雇用者が負担する時間当たりの労働コストの動向を示す短期的な指標で、物価の変動を調整しない名目ベースのものである。これは、自国通貨建ての労働コストを労働時間数で割って算出される。時間当たりの雇用者のコストの四半期ごとの変化は、総労働コストとその主な構成要素である賃金・給与、および非賃金コスト（賃金・給与以外の労働コスト）について測定される。総労働コスト（TOT）は、賃金と賃金以外のコストから補助金を差し引いたものであり、職業訓練費、採用費、作業着などのその他の支出は含まれていない。',
  'fed_brainard_speaks.description:ja:DEFAULT': '',
  'fed_brainard_speaks.description:ja:United States': '',
  'gdp_price_index.description:ja:DEFAULT': '',
  'gdp_price_index.description:ja:United States': '',
  '2016_convergence_report.description:ja:DEFAULT': '',
  '2016_convergence_report.description:ja:Euro Area': '',
  'finance_min_osborne_speaks_on_brexit.description:ja:DEFAULT': '',
  'finance_min_osborne_speaks_on_brexit.description:ja:United Kingdom': '',
  'real_wages_yoy.description:ja:DEFAULT': '',
  'real_wages_yoy.description:ja:Czech Republic':
    'チェコ共和国では、実質賃金上昇率は、フルタイム社員相当の被雇用者1人当たりインフレ調整済み平均月次賃金の前年比変化を測定する。',
  'gdp_mom.description:ja:DEFAULT': '',
  'gdp_mom.description:ja:United Kingdom': '',
  'gdp_mom.description:ja:Canada':
    'カナダでは、景気先行指数は、同国の経済が生産した財とサービスの価値を前月の水準と比べた変化を測定する、GDP前月比成長率を指す。',
  'gdp_implicit_price_qoq.description:ja:DEFAULT': '',
  'gdp_implicit_price_qoq.description:ja:Canada':
    'カナダでは、インプリシット物価指数は、足元価格で表される支出系列を不変価格で表される対応系列で割って算出される、デフレーション法の副産物である。すべての経済・財政会計で発表される総合値に対して、インプリシット物価指数は連鎖フィッシャー物価指数である。',
  'national_day_holiday.description:ja:DEFAULT': '',
  'national_day_holiday.description:ja:Russia': '',
  'gdp_growth_rate_yoy_flash.description:ja:DEFAULT': '',
  'gdp_growth_rate_yoy_flash.description:ja:Greece':
    'ギリシャでは家計消費がGDPの主な構成要素であり、その総支出の72％を占めており、次いで政府支出（20％）、総固定資本形成（12％）となっている。財・サービスの輸出はGDPの33%、輸入は35%であるため、GDP合計に2%のマイナス寄与となっている。',
  'gdp_growth_rate_yoy_flash.description:ja:Euro Area':
    'ユーロ圏とは、通貨としてユーロを採用している欧州19カ国の経済通貨同盟のことである。世界第2位の経済圏であり、人口は3億4100万人で、国としてみれば人口は世界第3位である。ドイツ、フランス、イタリア、スペインが最も重要な経済圏で、それぞれGDPの29％、20％、15％、10％を占めている。',
  'gdp_growth_rate_yoy_flash.description:ja:Sweden':
    'スウェーデンは、木材、水力発電、鉄鉱石などの資源をベースに、外国との貿易を重視した経済である。工業部門は、生産額と輸出額の約50%を占めている。支出面では、家計消費がGDPの主な構成要素であり、総支出の46％を占め、次いで政府消費（26％）、総固定資本形成（23％）となっている。財・サービスの輸出はGDPの45％、輸入は41％であることから、GDP全体に4％寄与している。',
  'gdp_growth_rate_yoy_flash.description:ja:Austria':
    'オーストリアでは家計消費がGDPの主な構成要素で54％を占めており、次いで総固定資本形成（22％）、政府支出（20％）となっている。財・サービスの輸出はGDPの53%を占める一方、輸入は50%であるため、正味の財・サービスの輸出はGDP全体に3%寄与している。',
  'gdp_growth_rate_yoy_flash.description:ja:Spain':
    '支出側では家計消費がスペインGDPの主な構成要素で58％を占めており、次いで総固定資本形成（21％）、政府支出（18％）となっている。投資のうち、有形固定資産は、建設（10％）、機械・設備・兵器システム（7％）などを含めGDPの18％を占めており、残りの3％は知的財産製品である。財・サービスの輸出はGDPの34%である一方、輸入は31%であるため、GDP全体に3%寄与している。',
  'gdp_growth_rate_yoy_flash.description:ja:Poland':
    'ポーランドの消費者市場は3,800万人と、ヨーロッパでも最大級の規模を誇る。ポーランドの主要産業は、食品・飲料加工、造船、機械・鉄鋼製品・化学品・ガラス・繊維製品の製造である。支出面では、家計消費がGDPの60％を占めており、次いで総固定資本形成（20％）、政府支出（18％）である。財・サービスの輸出はGDPの47％を占め、輸入は46％で、GDP全体に1％寄与している。',
  'gdp_growth_rate_yoy_flash.description:ja:Germany':
    '世界第5位、欧州最大の経済規模を誇るドイツ経済は、機械、自動車、化学品、家庭用機器などの主要な輸出国であり、高度に熟練した労働力の恩恵を受けている。GDPの支出側の構成：家計消費（55％）、総資本形成（20％、うち建設業が10％、機械装置が6％、その他が4％）、政府支出（19％）。財・サービスの輸出はGDPの46％、輸入は39％で、GDP全体に7％寄与している。',
  'gdp_growth_rate_yoy_flash.description:ja:Netherlands':
    'オランダ経済はユーロ圏で第6位、ヨーロッパの重要な交通拠点である。オランダ経済は外国貿易に大きく依存しており、輸出はGDPの83％、輸入は72％を占める。GDPの主な構成要素は家計消費（45％）で、次いで政府支出（26％）、総固定資本形成（18％）、純輸出（11％）となっている。',
  'democratic_primaries_super_tuesday.description:ja:DEFAULT': '',
  'democratic_primaries_super_tuesday.description:ja:United States': '',
  'gdp_growth_rate_yoy_adv.description:ja:DEFAULT': '',
  'gdp_growth_rate_yoy_adv.description:ja:Czech Republic':
    'チェコ共和国では家計消費がGDPの主な構成要素であり、その総支出の49％を占めており、次いで総固定資本形成（25％）、政府支出（19％）となっている。財・サービスの輸出はGDPの84%である一方、輸入は77%であるため、GDP合計に７%のプラス寄与となっている。',
  'gdp_growth_rate_yoy_adv.description:ja:United States':
    '米国は世界最大の経済大国である。しかし、他の多くの先進国と同様に、この20年間でその成長率は低下している。 50年代、60年代の平均成長率が4％を超えていたのに対し、70年代、80年代には3％前後にまで落ち込んだ。 過去10年間の平均成長率は2％を下回り、2000年の第2四半期以降は一度も5％台に達していない。',
  'gdp_growth_rate_yoy_adv.description:ja:Greece':
    'ギリシャでは家計消費がGDPの主な構成要素であり、その総支出の72％を占めており、次いで政府支出（20％）、総固定資本形成（12％）となっている。財・サービスの輸出はGDPの33%、輸入は35%であるため、GDP合計に2%のマイナス寄与となっている。',
  'gdp_growth_rate_yoy_adv.description:ja:Hong Kong':
    '近年、香港は、中国市場へのアクセスを希望するビジネス、貿易、金融、輸入業者にとって、国際的なハブとなっている。そのため、経済の最大のセクターはサービス業で、GDPの約93%を占めている。サービス業の中で最も重要なのは、卸売・小売（GDPの25％）、行政・社会サービス（17％）、金融・保険（16％）、不動産・ビジネスサービス（11％）、不動産所有（10％）、輸送・郵便サービス（6％）である。情報・通信、および食品・宿泊は8パーセントである。建設業はGDPの3.5％、電気・水・ガス供給、廃棄物処理、製造業は3.4％を占める。',
  'gdp_growth_rate_yoy_adv.description:ja:South Korea':
    '韓国の経済は、過去50年間で世界で最も多様化し、技術的にも進歩した国の一つとなった。多くの先進国と同様、GDPに占めるサービスの割合が最も高くなっている（GDPの約57％）。サービス業の中でも特に重要なのは、行政（6％）、教育（5.3％）、情報・通信（5％）、ビジネス活動（4.6％）である。韓国を主要な輸出国にした強力な製造業は、GDPの32％を占めている。',
  'gdp_growth_rate_yoy_adv.description:ja:Singapore':
    'シンガポール経済最大の分野はサービス業であり、GDPの72%を占める。サービス業の主要な部門は、卸売・小売業（合計GDPの18%）、ビジネスサービス（16%）、金融・保険（13%）、輸送・倉庫（10%）、情報・通信（5%）である。産業部門は総生産の28%を占め、その主要セグメントには、製造業（21%）、建設業（5%）がある。',
  'gdp_growth_rate_yoy_adv.description:ja:Taiwan':
    '台湾の経済は輸出主導型であるため、製造業が最も重要な経済分野であり、GDPの31%を占める。大半の先進国同様、サービス業がGDP最大の構成要素として65%近くを占め、その主要な構成セグメントには卸売・小売（GDPの17.3%）、不動産（8%）、行政・国防（6.4%）がある。',
  'gdp_growth_rate_yoy_adv.description:ja:Belgium':
    'ベルギーでは家計消費がGDPの主な構成要素であり、その総支出の52％を占めており、次いで政府支出（25％）、総固定資本形成（23％）となっている。財・サービスの輸出はGDPの84%である一方、輸入は83%であるため、GDP合計に1%のマイナス寄与となっている。',
  'gdp_growth_rate_yoy_adv.description:ja:Italy':
    'イタリアは欧州で2番目に大きい製造業経済であり、ユーロ圏で3番目に大きい経済国である。GDPの支出側の構成：家計消費（61％）、政府支出（19％）、総固定資本形成（17％）。財・サービスの輸出はGDPの30％、輸入は27％であるため、GDP全体に3％寄与している。',
  'boe_gov_carney_testimony.description:ja:DEFAULT': '',
  'boe_gov_carney_testimony.description:ja:United Kingdom': '',
  'economy_and_finance_minister_padoan_speech.description:ja:DEFAULT': '',
  'economy_and_finance_minister_padoan_speech.description:ja:Italy': '',
  'easter.description:ja:DEFAULT': '',
  'easter.description:ja:Poland': '',
  'gdp_growth_rate_yoy_3rd_est.description:ja:DEFAULT': '',
  'gdp_growth_rate_yoy_3rd_est.description:ja:Euro Area':
    'ユーロ圏は世界で2番目に大きな経済圏である。加盟している19カ国のうち、最も大きいのはドイツ（GDP合計の29％）、フランス（20％）、イタリア（15％）、スペイン（10％）である。支出面では家計消費がGDPの主な構成要素で、総支出の54％を占めており、次いで総固定資本形成（21％）、政府支出（20％）となっている。財・サービスの輸出はGDPの47%、輸入は43%であるため、GDP全体に4%寄与している。',
  'gdp_growth_rate_yoy_1st_est.description:ja:DEFAULT': '',
  'gdp_growth_rate_yoy_1st_est.description:ja:France':
    'フランスの経済は世界第7位、ユーロ圏では第2位の規模。フランスは、航空機、軍事機器、香水、医薬品、ワイン、牛肉、小麦の生産など、大規模かつ多様な工業・農業基盤を有している。少なくとも年間8,200万人の外国人観光客が訪れるフランスは、世界で最も観光客の多い国であり、観光業による収入は世界第3位を維持している。経済の最大部門はサービス業（GDP全体の79％）で、工業は19％。農業のGDPへの寄与度は2%だが、フランスは世界第2位の農産物の輸出国である。',
  'foreign_portfolio_investment_in_canadian_securities.description:ja:DEFAULT': '',
  'foreign_portfolio_investment_in_canadian_securities.description:ja:Canada': '',
  'extra_holiday.description:ja:DEFAULT': '',
  'extra_holiday.description:ja:Thailand': '',
  '69-day_bill_auction.description:ja:DEFAULT': '',
  '69-day_bill_auction.description:ja:United States': '',
  'gdp_growth_rate_yoy_-_2nd_est.description:ja:DEFAULT': '',
  'gdp_growth_rate_yoy_-_2nd_est.description:ja:Israel':
    'イスラエルは中東で最も技術的に進んだ先進的な市場経済である。イスラエル経済最大のセクターはサービス業で、GDPの80%を占め、うち最も重要なのは金融とビジネス（GDPの28％）、政府（同17％）、住宅（同12％）、貿易、宿泊、レストラン（同10％）、輸送・倉庫・通信（同8％）。製造業の重要性は近年低下しており、今ではGDPの14％。',
  'gdp_growth_rate_yoy_-_final.description:ja:DEFAULT': '',
  'gdp_growth_rate_yoy_-_final.description:ja:Denmark':
    'デンマークでは家計消費がGDPの主な構成要素であり、その総支出の49％を占めており、次いで政府支出（27％とEU諸国では最高）、総固定資本形成（19％）となっている。財・サービスの輸出はGDPの54%に達する一方、輸入は48%であるため、GDP合計に６%のプラス寄与となっている。',
  'gdp_growth_rate_yoy.description:ja:DEFAULT': '',
  'gdp_growth_rate_yoy.description:ja:Hungary':
    'ハンガリーでは、電子機器の製造と研究などがイノベーションと経済成長の主な原動力である。また、モバイル技術、情報セキュリティ、および関連するハードウェア研究の主要な中心地としても成長している。支出面では、家計消費がGDPの主な構成要素として全体の50%を占め、総固定資本形成（22％）、政府支出（20％）と続く。財・サービスの輸出はGDPの89%を占め、輸入は82%であるため、GDP全体に7%寄与している。',
  'gdp_growth_rate_yoy.description:ja:Israel':
    'イスラエルは中東で最も技術的に進んだ先進的な市場経済である。イスラエル経済最大のセクターはサービス業で、GDPの80%を占め、うち最も重要なのは金融とビジネス（GDPの28％）、政府（同17％）、住宅（同12％）、貿易、宿泊、レストラン（同10％）、輸送・倉庫・通信（同8％）。製造業の重要性は近年低下しており、今ではGDPの14％。',
  'gdp_growth_rate_yoy.description:ja:Germany':
    '世界第5位、欧州最大の経済規模を誇るドイツ経済は、機械、自動車、化学品、家庭用機器などの主要な輸出国であり、高度に熟練した労働力の恩恵を受けている。GDPの支出側の構成：家計消費（55％）、総資本形成（20％、うち建設業が10％、機械装置が6％、その他が4％）、政府支出（19％）。財・サービスの輸出はGDPの46％、輸入は39％で、GDP全体に7％寄与している。',
  'gdp_growth_rate_yoy.description:ja:Poland':
    'ポーランドの消費者市場は3,800万人と、ヨーロッパでも最大級の規模を誇る。ポーランドの主要産業は、食品・飲料加工、造船、機械・鉄鋼製品・化学品・ガラス・繊維製品の製造である。支出面では、家計消費がGDPの60％を占めており、次いで総固定資本形成（20％）、政府支出（18％）である。財・サービスの輸出はGDPの47％を占め、輸入は46％で、GDP全体に1％寄与している。',
  'gdp_growth_rate_yoy.description:ja:Italy':
    'イタリアは欧州で2番目に大きい製造業経済であり、ユーロ圏で3番目に大きい経済国である。GDPの支出側の構成：家計消費（61％）、政府支出（19％）、総固定資本形成（17％）。財・サービスの輸出はGDPの30％、輸入は27％であるため、GDP全体に3％寄与している。',
  'gdp_growth_rate_yoy.description:ja:Netherlands':
    'オランダ経済はユーロ圏で第6位、ヨーロッパの重要な交通拠点である。オランダ経済は外国貿易に大きく依存しており、輸出はGDPの83％、輸入は72％を占める。GDPの主な構成要素は家計消費（45％）で、次いで政府支出（26％）、総固定資本形成（18％）、純輸出（11％）となっている。',
  'gdp_growth_rate_yoy.description:ja:South Korea':
    '韓国の経済は、過去50年間で世界で最も多様化し、技術的にも進歩した国の一つとなった。多くの先進国と同様、GDPに占めるサービスの割合が最も高くなっている（GDPの約57％）。サービス業の中でも特に重要なのは、行政（6％）、教育（5.3％）、情報・通信（5％）、ビジネス活動（4.6％）である。韓国を主要な輸出国にした強力な製造業は、GDPの32％を占めている。',
  'gdp_growth_rate_yoy.description:ja:Portugal':
    '支出面では、家計消費がGDPの主な構成要素であり、その合計支出の66％を占めている。続いて、政府支出（19％）、総固定資本形成（15％）となっている。財・サービスの輸出はGDPの40％、輸入も40％を占める。',
  'gdp_growth_rate_yoy.description:ja:China':
    '中国のGDPは第1次産業、第2次産業、第3次産業の3つに分けられる。第1次産業には農業、林業、畜産、漁業が含まれ、GDPの約9%を占める。第2次産業では製造業（GDPの40%）、建設業（9%）などが主要項目である。第3次産業はGDPの残り44%を占め、小売・卸売、輸送・倉庫・郵便、金融仲介、不動産、ホテル・ケータリングサービス、その他サービスで構成される。',
  'gdp_growth_rate_yoy.description:ja:Brazil':
    'ブラジルは世界で10番目、ラテンアメリカ諸国では最大の経済大国である。サービス業が最も重要な経済分野でGDPの63%を占める。サービス業の主要項目には、政府・国防・教育・医療（GDPの15%）、その他サービス（15%）、卸売・小売（11%）、不動産（8%）、金融サービス（7%）がある。GDPの18%を占める産業部門も重要な分野であり、製造業（11%）と建設業（4%）がその主な部分を担う。農業・畜産部門はGDPの5%を占める。支出面では、家計支出がGDPの主要項目であり、支出全体の63%を占める。それに続くのが、政府支出（20%）、総固定資本形成（16%）となる。財とサービスの輸出はGDPの13%、輸入は12%を占め、純輸出では1%のプラス寄与となる。',
  'gdp_growth_rate_yoy.description:ja:Finland':
    'フィンランド経済最大のセクターは65％を占めるサービス業で、次いで製造業と精製が31％を占める。第一次産業は3％。フィンランドの主要な工業製品は紙・板・電子・金属製品である。製造業のうち主要セクターはエンジニアリングとハイテク業界。支出面では、GDPの55％を占める家計消費が最大で、次いで政府支出（25％）、総固定資本形成（20％）。財・サービスの輸出はGDPの38％である一方、輸入は39％であるため、GDP合計に1％のマイナス寄与となっている。',
  'gdp_growth_rate_yoy.description:ja:Euro Area':
    'ユーロ圏は世界で2番目に大きな経済圏である。加盟している19カ国のうち、最も大きいのはドイツ（GDP合計の29％）、フランス（20％）、イタリア（15％）、スペイン（10％）である。支出面では家計消費がGDPの主な構成要素で、総支出の54％を占めており、次いで総固定資本形成（21％）、政府支出（20％）となっている。財・サービスの輸出はGDPの47%、輸入は43%であるため、GDP全体に4%寄与している。',
  'gdp_growth_rate_yoy.description:ja:Colombia':
    'コロンビア経済の最大かつ最も急速な成長を遂げている部門はサービス業（GDPの53%を占める）であり、その主要項目には金融・保険・不動産・ビジネスサービス、地域・社会・個人サービス、商業・修理・レストラン・ホテル、輸送・倉庫・通信がある。このほか、製造業・鉱業・採石業がGDPの20%を占める。その他の部門には、政府（10%）、農林水産業（6.3%）、建設業（6%）、電力・ガス・水道（4%）が含まれる。',
  'gdp_growth_rate_yoy.description:ja:Philippines':
    'フィリピン経済最大の分野はサービス業であり、GDPの57%を占める。サービス業は主に、商業・自動車および家庭用品の修理（GDPの17%）、不動産・レンタルおよびビジネス活動（11%）、輸送・倉庫・通信（8%）、金融サービス（7%）、行政・防衛・社会保障（4%）からなる。産業部門はGDPの31%を占め、主に、製造業（22%）、建設業（5%）がある。農業は残りの12%を占める。',
  'gdp_growth_rate_yoy.description:ja:Switzerland':
    'スイスは世界で最も裕福な国のひとつである。経済の主要な分野には、マイクロテクノロジー、ハイテク、バイオテクノロジー・医薬品、銀行・保険業務ノウハウなどがある。',
  'gdp_growth_rate_yoy.description:ja:Austria':
    'オーストリアでは家計消費がGDPの主な構成要素で54％を占めており、次いで総固定資本形成（22％）、政府支出（20％）となっている。財・サービスの輸出はGDPの53%を占める一方、輸入は50%であるため、正味の財・サービスの輸出はGDP全体に3%寄与している。',
  'gdp_growth_rate_yoy.description:ja:Sweden':
    'スウェーデンは、木材、水力発電、鉄鉱石などの資源をベースに、外国との貿易を重視した経済である。工業部門は、生産額と輸出額の約50%を占めている。支出面では、家計消費がGDPの主な構成要素であり、総支出の46％を占め、次いで政府消費（26％）、総固定資本形成（23％）となっている。財・サービスの輸出はGDPの45％、輸入は41％であることから、GDP全体に4％寄与している。',
  'gdp_growth_rate_yoy.description:ja:United Kingdom':
    'イギリスの経済規模は、世界第6位、欧州ではドイツに次ぐ第2位である。サービス業が最も重要で、GDP全体の79％を占めている。サービス業の中で最も大きな分野は、政府、教育、医療（GDP全体の19％）、不動産（12％）、専門的・科学的・技術的活動および行政・支援サービス（12％）、卸売・小売業（11％）、金融・保険（8％）である。工業分野はGDPの21％を占め、この分野の最大のセグメントは製造業（GDP全体の10％）と建設業（6％）である。農業は、GDPのわずか1%を占めるにすぎない。',
  'gdp_growth_rate_yoy.description:ja:Ireland':
    '1995年から2007年にかけて、アイルランドは個人消費や建設投資、企業投資の増加により、年平均6％の高成長を遂げていた。しかし2008年には不動産市場の暴落により経済活動が急激に落ち込み、10年以上ぶりに景気後退に陥った。その後、財政赤字と政府債務の削減を目的とした一連の経済改革を経て、2010年第3四半期からGDPが拡大に転じ、2012年には3四半期連続で縮小した後、ユーロ圏で最も成長率の高い経済国の地位を獲得した。',
  'gdp_growth_rate_yoy.description:ja:Australia':
    'オーストラリア経済では、サービス業がその中心的な役割を担っている（GDPの65%）。とはいえ、近年の好調な景気を支えているのは鉱業（GDPの13.5%）と農業（2%）であり、同国は主要なコモディティ輸出国となっている。経済の他の主要分野には、製造業（11%）と建設業（9.5%）などがある。',
  'gdp_growth_rate_yoy.description:ja:Singapore':
    'シンガポール経済最大の分野はサービス業であり、GDPの72%を占める。サービス業の主要な部門は、卸売・小売業（合計GDPの18%）、ビジネスサービス（16%）、金融・保険（13%）、輸送・倉庫（10%）、情報・通信（5%）である。産業部門は総生産の28%を占め、その主要セグメントには、製造業（21%）、建設業（5%）がある。',
  'gdp_growth_rate_yoy.description:ja:Canada':
    'カナダでは、サービス業がGDPの70%超を占める。サービス業で最も重要なのは、金融・保険・不動産・リース・企業管理（GDPの21%）、小売・卸売（12%）、医療（8%）、行政管理（6%）である。製造業はGDPの13%、建設業は6%を占める。鉱業・石油ガス採掘はGDPの4%を占めるに過ぎないが、カナダはエネルギーの純輸出国である。また、農林水産業はGDPの2%を占める。支出面では、個人消費がGDPの主要項目であり、支出全体の58%を占め、それに総固定資本形成（22%）と政府支出（19%）が続く。財とサービスの輸出はGDPの32%、輸入は33%を占めるため、その差の純輸入1%がマイナス寄与となる。対家計非営利団体の最終消費支出と在庫投資が残りの2%を占める。',
  'gdp_growth_rate_yoy.description:ja:Indonesia':
    'インドネシアは東南アジア最大の経済国である。産業部門がGDPの最大の割合を占め（46.5%）、その最も重要な構成項目である製造業は、主要な成長エンジンのひとつとなっている（GDPの24%）。鉱業・採石業はGDPの12%、建設業が10%、電力・ガス・水道が0.75%を占める。サービス業はGDPの38%を担い、その主要項目には商業・ホテル・レストラン（GDPの約14%）、輸送・通信（7%）、金融・不動産・ビジネスサービス（7%）、政府サービス（6%）がある。農業が残りの15%を占める。',
  'gdp_growth_rate_yoy.description:ja:Hong Kong':
    '近年、香港は、中国市場へのアクセスを希望するビジネス、貿易、金融、輸入業者にとって、国際的なハブとなっている。そのため、経済の最大のセクターはサービス業で、GDPの約93%を占めている。サービス業の中で最も重要なのは、卸売・小売（GDPの25％）、行政・社会サービス（17％）、金融・保険（16％）、不動産・ビジネスサービス（11％）、不動産所有（10％）、輸送・郵便サービス（6％）である。情報・通信、および食品・宿泊は8パーセントである。建設業はGDPの3.5％、電気・水・ガス供給、廃棄物処理、製造業は3.4％を占める。',
  'gdp_growth_rate_yoy.description:ja:Greece':
    'ギリシャでは家計消費がGDPの主な構成要素であり、その総支出の72％を占めており、次いで政府支出（20％）、総固定資本形成（12％）となっている。財・サービスの輸出はGDPの33%、輸入は35%であるため、GDP合計に2%のマイナス寄与となっている。',
  'gdp_growth_rate_yoy.description:ja:Thailand':
    'タイは、輸出主導型の新興経済国である。このため、製造業が最も重要な経済分野であり、GDPの34%を占める。サービス業はGDPの約44%を占め、そのうち最も重要なのは卸売・小売（GDPの13%）、輸送・倉庫・通信（7%）、宿泊・飲食サービス（5%）、行政・国防・社会保障（4.5%）である。農業も経済成長に大きく貢献する分野であり、GDPの約13%を占める。',
  'gdp_growth_rate_yoy.description:ja:Denmark':
    'デンマークでは家計消費がGDPの主な構成要素であり、その総支出の49％を占めており、次いで政府支出（27％とEU諸国では最高）、総固定資本形成（19％）となっている。財・サービスの輸出はGDPの54%に達する一方、輸入は48%であるため、GDP合計に６%のプラス寄与となっている。',
  'gdp_growth_rate_yoy.description:ja:Spain':
    '支出側では家計消費がスペインGDPの主な構成要素で58％を占めており、次いで総固定資本形成（21％）、政府支出（18％）となっている。投資のうち、有形固定資産は、建設（10％）、機械・設備・兵器システム（7％）などを含めGDPの18％を占めており、残りの3％は知的財産製品である。財・サービスの輸出はGDPの34%である一方、輸入は31%であるため、GDP全体に3%寄与している。',
  'gdp_growth_rate_yoy.description:ja:Russia':
    'ロシアは世界有数の石油・天然ガスの生産国であり、鉄鋼や一次アルミニウムなどの金属の輸出国でもある。生産面では、サービス業が経済の最大セクターであり、GDPの58％を占めている。サービス業の中で最も重要な分野は、卸売・小売（GDP合計の17％）、行政・医療・教育（12％）である。工業は総生産の40％、農業は残りの2％。',
  'gdp_growth_rate_yoy.description:ja:Czech Republic':
    'チェコ共和国では家計消費がGDPの主な構成要素であり、その総支出の49％を占めており、次いで総固定資本形成（25％）、政府支出（19％）となっている。財・サービスの輸出はGDPの84%である一方、輸入は77%であるため、GDP合計に７%のプラス寄与となっている。',
  'gdp_growth_rate_yoy.description:ja:Norway':
    'ノルウェーは世界で最も裕福な国のひとつである。原油・ガス、水力、魚類などの天然資源がその富の基盤となり、発達した海運業がそれを支えている。支出面のGDPの内訳は、家計消費支出（41%）、総固定資本形成（24%）、政府消費支出（22%）となる。財とサービスの輸出はGDPの38%、輸入は30%を占め、純輸出が8%のプラス寄与となる。',
  'gdp_growth_rate_yoy.description:ja:Mexico':
    'メキシコの経済規模は中南米で第2位である。輸出志向の経済で、貿易の90％以上が自由貿易協定の下で行われている。メキシコでは、サービス業がGDP全体の62%を占めている。サービス業の中で最も大きな分野は、卸売・小売（16％）、不動産（10％）、輸送・倉庫・通信（7％）、金融サービス・保険（6％）である。工業は生産高の18％を占めており、その最大のセグメントは自動車（GDP全体の4％）と食品（3.8％）である。それ以外は建設、水道・ガス・配電（8％）、鉱業（5％）、政府（4％）、農業・林業・漁業・狩猟（3％）。',
  'gdp_growth_rate_yoy.description:ja:France':
    'フランスの経済は世界第7位、ユーロ圏では第2位の規模。フランスは、航空機、軍事機器、香水、医薬品、ワイン、牛肉、小麦の生産など、大規模かつ多様な工業・農業基盤を有している。少なくとも年間8,200万人の外国人観光客が訪れるフランスは、世界で最も観光客の多い国であり、観光業による収入は世界第3位を維持している。経済の最大部門はサービス業（GDP全体の79％）で、工業は19％。農業のGDPへの寄与度は2%だが、フランスは世界第2位の農産物の輸出国である。',
  'gdp_growth_rate_yoy.description:ja:Belgium':
    'ベルギーでは家計消費がGDPの主な構成要素であり、その総支出の52％を占めており、次いで政府支出（25％）、総固定資本形成（23％）となっている。財・サービスの輸出はGDPの84%である一方、輸入は83%であるため、GDP合計に1%のマイナス寄与となっている。',
  'gdp_growth_rate_yoy.description:ja:South Africa':
    '南アフリカはアフリカ大陸で最も発達した国で、2014年まで最大の経済国であったが、その後ナイジェリアにその地位を奪われた。南アフリカ経済で最大のセクターはサービス業であり、GDPの約73%を占める。サービス業の主要項目には、金融・不動産・ビジネスサービス（21.6%）、政府サービス（17%）、卸売・小売・自動車販売・ケータリング・宿泊（15%）、輸送・倉庫・通信（9.3%）がある。製造業はGDPの13.9%、鉱業・採石業が約8.3%を占める一方、農業が全体に占める割合はわずか2.6%にとどまる。',
  'gdp_growth_rate_yoy.description:ja:New Zealand':
    'ニュージーランドではサービス業が最大の経済部門で、GDPの75%を占める。サービス業の主要項目には、金融・保険・ビジネスサービス（30%）、個人・地域サービス（13%）、輸送・通信（11%）がある。産業部門はGDPの17%を占め、そのうち製造業がGDPの13%、建設業が4%を担う。残りの8%は、農業、漁業、林業、鉱業が占める。',
  'gdp_growth_rate_yoy.description:ja:India':
    'インド経済の最大かつ最も急速な成長を遂げている部門はサービス業であり、その主要項目には、商業・ホテル・輸送・通信、金融・保険・不動産・ビジネスサービス、地域・社会・個人サービスがある。サービス業全体がGDPに占める割合は60%を超える一方、農林水産業の割合は約12%に過ぎないが、これが労働人口の50%超を抱える。製造業はGDPの15%、建設業は8%、鉱業・採石業・電力・ガス・水道は残りの5%を占める。',
  'gdp_growth_rate_yoy.description:ja:Taiwan':
    '台湾の経済は輸出主導型であるため、製造業が最も重要な経済分野であり、GDPの31%を占める。大半の先進国同様、サービス業がGDP最大の構成要素として65%近くを占め、その主要な構成セグメントには卸売・小売（GDPの17.3%）、不動産（8%）、行政・国防（6.4%）がある。',
  'birthday_of_martin_luther_king_jr.description:ja:DEFAULT': '',
  'birthday_of_martin_luther_king_jr.description:ja:United States': '',
  'gdp_growth_rate_qoq-final.description:ja:DEFAULT': '',
  'gdp_growth_rate_qoq-final.description:ja:Singapore':
    'シンガポールは1963年に独立して以来目覚ましい経済成長を遂げ、今では人口1人当たりGDPにおいて世界の最上位国の1つとなっている。同国経済は、港湾活動と電子部品および精製石油の輸出を含む貿易に大きく依存している。シンガポールは世界中で最も競争力の高い、企業寄りの最も自由な経済の1つであることから、外国直接投資の投資先として他国をリードしており、重要な金融の中心地でもある。',
  'gdp_growth_annualized_final.description:ja:DEFAULT': '',
  'gdp_growth_annualized_final.description:ja:Israel':
    'GDP年率成長率は、前四半期比のGDP成長率が1年を通じて維持されたと仮定した場合の成長率を示す。',
  'gdp_growth_annualized_final.description:ja:Japan':
    'GDP年率成長率は、前四半期比のGDP成長率が1年を通じて維持されたと仮定した場合の成長率を示す。',
  'eco_watchers_survey_current.description:ja:DEFAULT': '',
  'eco_watchers_survey_current.description:ja:Japan':
    '景気ウォッチャー調査の現状判断指数は、理容・美容店やタクシー運転手、ウェイターなど、消費者に直接サービスを提供する事業の現状に関する実感を測定するもので、約2,050人の労働者を対象に調査する。50.0を上回れば楽観的、下回れば悲観的な見方であることを示す。',
  'gdp_growth_rate_qoq_flash.description:ja:DEFAULT': '',
  'gdp_growth_rate_qoq_flash.description:ja:Denmark':
    'デンマークでは家計消費がGDPの主な構成要素であり、その総支出の49％を占めており、次いで政府支出（27％とEU諸国では最高）、総固定資本形成（19％）となっている。財・サービスの輸出はGDPの54%に達する一方、輸入は48%であるため、GDP合計に６%のプラス寄与となっている。',
  'gdp_growth_rate_qoq_flash.description:ja:Euro Area':
    'ユーロ圏とは、通貨としてユーロを採用している欧州19カ国の経済通貨同盟のことである。世界第2位の経済圏であり、人口は3億4100万人で、国としてみれば人口は世界第3位である。ドイツ、フランス、イタリア、スペインが最も重要な経済圏で、それぞれGDPの29％、20％、15％、10％を占めている。',
  'gdp_growth_rate_qoq_flash.description:ja:Sweden':
    '支出面では、家計支出がスウェーデンGDPの主な構成要素であり、その総支出の46％を占めており、次いで政府支出（26％）、総固定資本形成（23％）となっている。財・サービスの輸出はGDPの45%である一方、輸入は41%であるため、GDP合計に4%のプラス寄与となっている。',
  'gdp_growth_rate_qoq_flash.description:ja:Austria':
    'オーストリア経済ではサービス業が大半を占め（63%）、25%を確立された工業セクターが占める。農業セクターは比率は1%と少ないが、非常に先進的である。支出面では、家計消費がGDPの主な構成要素で54％を占めており、次いで総固定資本形成（22％）、政府支出（20％）となっている。財・サービスの輸出はGDPの53%を占める一方、輸入は50%であるため、正味の財・サービスの輸出はGDP全体に3%寄与している。',
  'gdp_growth_rate_qoq_flash.description:ja:Spain':
    'スペインはユーロ圏第4位の経済国で、欧州連合内では第5位の規模である。同国は堅固で多様な製造業を有し、世界でも最も観光客が訪れる国の1つである。支出側では、家計消費がスペインGDPの主な構成要素で58％を占めており、次いで総固定資本形成（21％）、政府支出（18％）となっている。投資のうち、有形固定資産は、建設（10％）、機械・設備・兵器システム（7％）などを含めGDPの18％を占めており、残りの3％は知的財産製品である。財・サービスの輸出はGDPの34%である一方、輸入は31%であるため、純輸出はGDP全体に1%寄与している。',
  'gdp_growth_rate_qoq_flash.description:ja:Germany':
    'ドイツは世界第5位、ユーロ圏最大の経済大国で、輸出は世界第2位であり、ドイツ全体の生産高の3分の1以上を輸出が占める。従って、ドイツでは高付加価値製品の輸出が近年の成長ドライバーとなってきた。GDPの支出側の構成は、家計消費（55％）、総資本形成（20％、うち建設業が10％、機械装置が6％、その他が4％）、政府支出（19％）となっている。財・サービスの輸出はGDPの46％、輸入は39％で、純輸出はGDP全体に7％の寄与となっている。',
  'gdp_growth_rate_qoq_flash.description:ja:Netherlands':
    'オランダ経済はユーロ圏で第6位、ヨーロッパの重要な交通拠点である。オランダ経済は外国貿易に大きく依存しており、輸出はGDPの83％、輸入は72％を占める。GDPの主な構成要素は家計消費（45％）で、次いで政府支出（26％）、総固定資本形成（18％）、純輸出（11％）となっている。',
  'foreign_investment_in_canadian_securities.description:ja:DEFAULT': '',
  'foreign_investment_in_canadian_securities.description:ja:Canada': '',
  'employment_change_qoq.description:ja:DEFAULT': '',
  'employment_change_qoq.description:ja:New Zealand':
    'ニュージーランドでは雇用者数変化は、雇用されている人数の前四半期比の変化を測定する。',
  'employment_change_qoq.description:ja:Euro Area':
    'ユーロ圏では雇用変化は、賃金または利益のために働く者と無給家族労働者の数の前四半期比変化を指し、推定値にはフルタイムとパートタイム双方が含まれる。',
  'gdp_growth_rate_qoq_final.description:ja:DEFAULT': '',
  'gdp_growth_rate_qoq_final.description:ja:Taiwan':
    '「アジアの虎4カ国」の1つ、台湾経済は輸出主導型経済で、エレクトロニクスと機械製造に特化している。台湾は、コンピューターチップ、LCDパネル、DRAMコンピューターメモリ、ネットワーク機器、消費者向けエレクトロニクスの生産において世界最大の生産国の1つである。',
  'gdp_growth_rate_qoq_final.description:ja:Euro Area':
    'ユーロ圏とは、通貨としてユーロを採用している欧州19カ国の経済通貨同盟のことである。世界第2位の経済圏であり、人口は3億4100万人で、国としてみれば人口は世界第3位である。ドイツ、フランス、イタリア、スペインが最も重要な経済圏で、それぞれGDPの29％、20％、15％、10％を占めている。',
  'gdp_growth_rate_qoq_final.description:ja:Hong Kong':
    '香港は輸出主導の経済となっており、国土は小さいものの、国内総生産（GDP）の4倍の額の財やサービスの貿易が行われ世界第8位の貿易大国となっている。近年、香港は、中国市場とのつながりを求める事業者、貿易、金融、輸入業者にとって国際的なハブとなっている。経済の最大のセクターはサービス業で、GDPの約93%を占めている。',
  'gdp_growth_rate_qoq_final.description:ja:Portugal':
    '支出面では、家計消費がGDPの主な構成要素であり、その合計支出の66％を占めている。続いて、政府支出（19％）、総固定資本形成（15％）となっている。財・サービスの輸出はGDPの40％、輸入も40％を占める。',
  'gdp_growth_rate_qoq_final.description:ja:Greece':
    'ギリシャはサービス業を基盤とした経済で、世界中で最も観光客が訪れる国の1つとなっている。1980年に欧州連合、2000年にはユーロ圏に加入して以来、ギリシャ経済は高成長を記録していたが、その成長は主に低コストの信用と公的セクターの成長に依存していたため、2008年には財政赤字と公的債務が持続不可能な水準に達した。その結果、ギリシャは1974年以来最悪の危機に直面、IMFと欧州委員会が救済策の一環として厳しい改革プログラムを執行することとなった。',
  'gdp_growth_rate_qoq_final.description:ja:Finland':
    'フィンランド経済ではサービス業が65%を占めるが、主要セクターは主に材木、金属、エンジニアリング、通信、エレクトロニクスなどの製造業である。支出面では、家計消費がGDPの主な構成要素であり、その総支出の55％を占めており、次いで政府支出（25％）、総固定資本形成（20％）となっている。財・サービスの輸出はGDPの38%、輸入は39%であるため、GDP全体に1%のマイナス寄与となっている。。',
  'gdp_growth_rate_qoq_final.description:ja:United States':
    '支出面では、個人消費支出がGDP全体の68%を占め、そのうち製品購入による支出が23%、サービスへの支出が45%となっている。民間投資はGDPの16%、政府による消費と投資は18%を占める。製品輸出高（13.5%）が製品輸入高（16.5%）より低いため、純輸出はGDPに3%のマイナス寄与。',
  'gdp_growth_rate_qoq_final.description:ja:Hungary':
    'ハンガリーでは家計消費がGDPの主な構成要素であり、総支出の50％を占め、総固定資本形成（22％）、政府支出（20％）がそれに続く。財・サービスの輸出はGDPの89%である一方、輸入は82%であるため、GDP合計に７%のプラス寄与となっている。',
  'gdp_growth_rate_qoq_final.description:ja:Israel':
    'イスラエル経済の主要なけん引役は科学技術セクターで、天然資源が限定的であるにもかかわらず製造・農業セクターは高度に進化している。イスラエル経済最大のセクターはサービス業でGDPの80%を占め、うち最も重要なのは金融とビジネス（GDP寄与率28％）、政府（同17％）、住宅（同12％）、貿易、宿泊、レストラン（同10％）、輸送・保管・通信（同8％）。製造業の重要性は近年低下しており、今ではGDP寄与率は14％。',
  'gdp_growth_rate_qoq_final.description:ja:Ireland':
    'アイルランド経済は貿易、工業、投資を基盤としており、主要なハイテク生産国であるとともに、医薬品とソフトウェアに関しては世界最大の輸出国の1つである。支出面では、家計消費がGDPの主な構成要素であり、合計の44％を占めている。続いて総固定資本形成（19％）、政府支出（17％）となっている。輸出はGDPの114％、輸入は95％であり、純輸出はGDPに19％プラス寄与している。',
  'gdp_growth_rate_qoq_final.description:ja:United Kingdom':
    'サービス業は最も重要で、イギリスのGDPの79%を占めている。サービス業の中で最も大きな分野は、政府・教育・保健（GDP全体の19％）、不動産（12％）、専門的・科学的・技術的活動および管理・支援サービス（12％）、卸売・小売業（11％）、金融・保険（8％）である。工業分野はGDPの21％を占め、この分野の最大のセグメントは、製造業（GDP全体の10％）と建設業（6％）である。農業部門はGDPのわずか1％を占めている。支出面でのGDPの構成は、家計消費（65％）、政府支出（20％）、総固定資本形成（17％）となっている。財・サービスの輸出はGDPの28％、輸入は30％を占めるため、輸出入はGDPに2％のマイナス寄与となっている。',
  'gdp_growth_rate_qoq_final.description:ja:Belgium':
    'ベルギーでは家計消費がGDPの主な構成要素であり、その総支出の52％を占めており、次いで政府支出（25％）、総固定資本形成（23％）となっている。財・サービスの輸出はGDPの84%である一方、輸入は83%であるため、GDP合計に1%のマイナス寄与となっている。',
  'gdp_growth_rate_qoq_final.description:ja:France':
    'フランスは世界で7番目、ユーロ圏で2番目に大きい経済国である。GDPの支出を大きく占めるのは家計消費（55％）、政府支出（24％）、総固定資本形成（22％）となっている。財・サービスの輸出はGDPの29％、輸入は31％であるため、純輸出はGDP全体に2％のマイナス寄与となっている。',
  'gdp_growth_rate_qoq_final.description:ja:Norway':
    'ノルウェーは世界で最も裕福な国のひとつである。原油・ガス、水力、魚類などの天然資源がその富の基盤となり、発達した海運業がそれを支えている。支出面のGDPの内訳は、家計消費支出（41%）、総固定資本形成（24%）、政府消費支出（22%）となる。財とサービスの輸出はGDPの38%、輸入は30%を占め、純輸出が8%のプラス寄与となる。',
  'gdp_growth_rate_qoq_final.description:ja:Mexico':
    'メキシコではサービス業がGDP全体の62%を占めている。サービス業の中で最も大きな分野は、卸売・小売（16％）、不動産（10％）、輸送・倉庫・通信（7％）、金融サービス・保険（6％）である。工業は生産高の18％を占めており、その最大のセグメントは自動車（GDP全体の4％）と食品（3.8％）である。それ以外は建設、水道・ガス・配電（8％）、鉱業（5％）、政府（4％）、農業・林業・漁業・狩猟（3％）が占める。',
  'gdp_growth_rate_qoq_final.description:ja:South Korea':
    '韓国は輸出主導の経済であり、ディスプレイと半導体記憶装置で世界をリードしており、船舶の製造では第2位となっている。GDPに占めるサービス業の割合が最も高くなっている（GDPの約57％）。サービス業の中でも大きな割合を占めるのは、行政（6％）、教育（5.3％）、情報・通信（5％）、事業活動（4.6％）である。韓国を大規模輸出国とする要因となった強力な製造業は、GDPの32％を占めている。',
  'gdp_growth_rate_qoq_final.description:ja:Italy':
    'イタリアは世界で9番目に大きい経済規模を誇り、ユーロ圏では3番目に大きい経済国である。同国は、大部分が高品質消費者向け製品の製造が占める多様化した工業を基盤としている。GDPの支出構成は、家計消費（61％）、政府支出（19％）、総固定資本形成（17％）となっている。製品・サービスの輸出はGDPの30％、輸入は27％であるため、GDP合計の3％に寄与している。',
  'gdp_growth_rate_qoq_final.description:ja:Netherlands':
    'オランダ経済はユーロ圏で第6位、ヨーロッパの重要な交通拠点である。オランダ経済は外国貿易に大きく依存しており、輸出はGDPの83％、輸入は72％を占める。GDPの主な構成要素は家計消費（45％）で、次いで政府支出（26％）、総固定資本形成（18％）、純輸出（11％）となっている。',
  'gdp_growth_rate_qoq_final.description:ja:Japan':
    '産業化が進んだ日本の自由市場経済は世界第4位の規模で、世界最大のエレクトロニクス産業と第3位の自動車産業を有する。日本経済は輸出志向セクターの効率性と競争力の高さで知られているが、農業、流通、サービスといったセクターの生産性は、より低い。',
  'gdp_growth_rate_qoq_final.description:ja:Singapore':
    'シンガポールは1963年に独立して以来目覚ましい経済成長を遂げ、今では人口1人当たりGDPにおいて世界の最上位国の1つとなっている。同国経済は、港湾活動と電子部品および精製石油の輸出を含む貿易に大きく依存している。シンガポールは世界中で最も競争力の高い、企業寄りの最も自由な経済の1つであることから、外国直接投資の投資先として他国をリードしており、重要な金融の中心地でもある。',
  'gdp_growth_rate_qoq_final.description:ja:Spain':
    'スペインはユーロ圏第4位の経済国で、欧州連合内では第5位の規模である。同国は堅固で多様な製造業を有し、世界でも最も観光客が訪れる国の1つである。支出側では、家計消費がスペインGDPの主な構成要素で58％を占めており、次いで総固定資本形成（21％）、政府支出（18％）となっている。投資のうち、有形固定資産は、建設（10％）、機械・設備・兵器システム（7％）などを含めGDPの18％を占めており、残りの3％は知的財産製品である。財・サービスの輸出はGDPの34%である一方、輸入は31%であるため、純輸出はGDP全体に1%寄与している。',
  'gdp_growth_rate_qoq_final.description:ja:Czech Republic':
    'チェコ共和国では家計消費がGDPの主な構成要素であり、その総支出の49％を占めており、次いで総固定資本形成（25％）、政府支出（19％）となっている。財・サービスの輸出はGDPの84%である一方、輸入は77%であるため、GDP合計に７%のプラス寄与となっている。',
  'gdp_growth_rate_qoq_final.description:ja:Denmark':
    'デンマークでは家計消費がGDPの主な構成要素であり、その総支出の49％を占めており、次いで政府支出（27％とEU諸国では最高）、総固定資本形成（19％）となっている。財・サービスの輸出はGDPの54%に達する一方、輸入は48%であるため、GDP合計に６%のプラス寄与となっている。',
  'gdp_growth_rate_qoq_final.description:ja:Poland':
    'ポーランドの経済規模は欧州連合で8番目でありながら、人口1人当たりのGDPはEU平均を大きく下回る。同国の産業基盤は石炭、繊維、化学品、機械、鉄、鉄鋼セクターであり、近年は肥料、石油化学、工作機械、電子機械、エレクトロニクス、自動車、造船にも広がっている。支出面では、家計消費がGDPの主要コンポーネントで、全体の60％を占め、次いで総固定資本形成（20％）と政府支出（18％）が続く。財とサービスの輸出はGDP全体の47％を占め、輸入は46％でGDP全体に1％の寄与となっている。',
  'gdp_growth_rate_qoq_final.description:ja:Germany':
    'ドイツは世界第5位、ユーロ圏最大の経済大国で、輸出は世界第2位であり、ドイツ全体の生産高の3分の1以上を輸出が占める。従って、ドイツでは高付加価値製品の輸出が近年の成長ドライバーとなってきた。GDPの支出側の構成は、家計消費（55％）、総資本形成（20％、うち建設業が10％、機械装置が6％、その他が4％）、政府支出（19％）となっている。財・サービスの輸出はGDPの46％、輸入は39％で、純輸出はGDP全体に7％の寄与となっている。',
  'gdp_growth_rate_qoq_final.description:ja:Austria':
    'オーストリア経済ではサービス業が大半を占め（63%）、25%を確立された工業セクターが占める。農業セクターは比率は1%と少ないが、非常に先進的である。支出面では、家計消費がGDPの主な構成要素で54％を占めており、次いで総固定資本形成（22％）、政府支出（20％）となっている。財・サービスの輸出はGDPの53%を占める一方、輸入は50%であるため、正味の財・サービスの輸出はGDP全体に3%寄与している。',
  'gdp_growth_rate_qoq_final.description:ja:Sweden':
    '支出面では、家計支出がスウェーデンGDPの主な構成要素であり、その総支出の46％を占めており、次いで政府支出（26％）、総固定資本形成（23％）となっている。財・サービスの輸出はGDPの45%である一方、輸入は41%であるため、GDP合計に4%のプラス寄与となっている。',
  'gdp_growth_rate_qoq_3st_est.description:ja:DEFAULT': '',
  'gdp_growth_rate_qoq_3st_est.description:ja:Israel':
    'イスラエル経済の主要なけん引役は科学技術セクターで、天然資源が限定的であるにもかかわらず製造・農業セクターは高度に進化している。イスラエル経済最大のセクターはサービス業でGDPの80%を占め、うち最も重要なのは金融とビジネス（GDP寄与率28％）、政府（同17％）、住宅（同12％）、貿易、宿泊、レストラン（同10％）、輸送・保管・通信（同8％）。製造業の重要性は近年低下しており、今ではGDP寄与率は14％。',
  'gdp_growth_rate_qoq_3rd_est.description:ja:DEFAULT': '',
  'gdp_growth_rate_qoq_3rd_est.description:ja:Euro Area':
    'ユーロ圏とは、通貨としてユーロを採用している欧州19カ国の経済通貨同盟のことである。世界第2位の経済圏であり、人口は3億4100万人で、国としてみれば人口は世界第3位である。ドイツ、フランス、イタリア、スペインが最も重要な経済圏で、それぞれGDPの29％、20％、15％、10％を占めている。',
  'gdp_growth_rate_qoq_3rd_est.description:ja:Israel':
    'イスラエル経済の主要なけん引役は科学技術セクターで、天然資源が限定的であるにもかかわらず製造・農業セクターは高度に進化している。イスラエル経済最大のセクターはサービス業でGDPの80%を占め、うち最も重要なのは金融とビジネス（GDP寄与率28％）、政府（同17％）、住宅（同12％）、貿易、宿泊、レストラン（同10％）、輸送・保管・通信（同8％）。製造業の重要性は近年低下しており、今ではGDP寄与率は14％。',
  'gdp_growth_rate_qoq_-_third_estimate.description:ja:DEFAULT': '',
  'gdp_growth_rate_qoq_-_third_estimate.description:ja:Israel':
    'イスラエル経済の主要なけん引役は科学技術セクターで、天然資源が限定的であるにもかかわらず製造・農業セクターは高度に進化している。イスラエル経済最大のセクターはサービス業でGDPの80%を占め、うち最も重要なのは金融とビジネス（GDP寄与率28％）、政府（同17％）、住宅（同12％）、貿易、宿泊、レストラン（同10％）、輸送・保管・通信（同8％）。製造業の重要性は近年低下しており、今ではGDP寄与率は14％。',
  'deposit_interest_rate.description:ja:DEFAULT': '',
  'deposit_interest_rate.description:ja:Hungary':
    '預金金利とは、商業銀行が個人または法人に支払う預金の平均金利である。',
  'financial_stability_report_2017.description:ja:DEFAULT': '',
  'financial_stability_report_2017.description:ja:Sweden': '',
  'gdp_growth_rate_qoq_-_p.description:ja:Euro Area':
    'ユーロ圏とは、通貨としてユーロを採用している欧州19カ国の経済通貨同盟のことである。世界第2位の経済圏であり、人口は3億4100万人で、国としてみれば人口は世界第3位である。ドイツ、フランス、イタリア、スペインが最も重要な経済圏で、それぞれGDPの29％、20％、15％、10％を占めている。',
  'gdp_growth_rate_qoq_-_flash.description:ja:DEFAULT': '',
  'gdp_growth_rate_qoq_-_flash.description:ja:Euro Area':
    'ユーロ圏とは、通貨としてユーロを採用している欧州19カ国の経済通貨同盟のことである。世界第2位の経済圏であり、人口は3億4100万人で、国としてみれば人口は世界第3位である。ドイツ、フランス、イタリア、スペインが最も重要な経済圏で、それぞれGDPの29％、20％、15％、10％を占めている。',
  'gdp_growth_rate_qoq_-_flash.description:ja:Netherlands':
    'オランダ経済はユーロ圏で第6位、ヨーロッパの重要な交通拠点である。オランダ経済は外国貿易に大きく依存しており、輸出はGDPの83％、輸入は72％を占める。GDPの主な構成要素は家計消費（45％）で、次いで政府支出（26％）、総固定資本形成（18％）、純輸出（11％）となっている。',
  'gdp_growth_rate_qoq_-_flash.description:ja:Poland':
    'ポーランドの経済規模は欧州連合で8番目でありながら、人口1人当たりのGDPはEU平均を大きく下回る。同国の産業基盤は石炭、繊維、化学品、機械、鉄、鉄鋼セクターであり、近年は肥料、石油化学、工作機械、電子機械、エレクトロニクス、自動車、造船にも広がっている。支出面では、家計消費がGDPの主要コンポーネントで、全体の60％を占め、次いで総固定資本形成（20％）と政府支出（18％）が続く。財とサービスの輸出はGDP全体の47％を占め、輸入は46％でGDP全体に1％の寄与となっている。',
  'gdp_growth_rate_qoq_-_flash.description:ja:Finland':
    'フィンランド経済ではサービス業が65%を占めるが、主要セクターは主に材木、金属、エンジニアリング、通信、エレクトロニクスなどの製造業である。支出面では、家計消費がGDPの主な構成要素であり、その総支出の55％を占めており、次いで政府支出（25％）、総固定資本形成（20％）となっている。財・サービスの輸出はGDPの38%、輸入は39%であるため、GDP全体に1%のマイナス寄与となっている。。',
  'consumer_price_index_yoy.description:ja:DEFAULT': '',
  'consumer_price_index_yoy.description:ja:Germany': '',
  'consumer_price_index_yoy.description:ja:Euro Area': '',
  'foreign_bond_investment.description:ja:United States':
    '外国債券投資は、外国の個人または法人による米国債の買い越しを示し、マイナス数値は米国居住者への外国人による売り越しまたは米国への資本の純流出を示す。。',
  'gdp_growth_rate_qoq_-_2nd_est.description:ja:DEFAULT': '',
  'gdp_growth_rate_qoq_-_2nd_est.description:ja:Israel':
    'イスラエル経済の主要なけん引役は科学技術セクターで、天然資源が限定的であるにもかかわらず製造・農業セクターは高度に進化している。イスラエル経済最大のセクターはサービス業でGDPの80%を占め、うち最も重要なのは金融とビジネス（GDP寄与率28％）、政府（同17％）、住宅（同12％）、貿易、宿泊、レストラン（同10％）、輸送・保管・通信（同8％）。製造業の重要性は近年低下しており、今ではGDP寄与率は14％。',
  'gdp_growth_rate_qoq_-_1st_est.description:ja:DEFAULT': '',
  'gdp_growth_rate_qoq_-_1st_est.description:ja:Israel':
    'イスラエル経済の主要なけん引役は科学技術セクターで、天然資源が限定的であるにもかかわらず製造・農業セクターは高度に進化している。イスラエル経済最大のセクターはサービス業でGDPの80%を占め、うち最も重要なのは金融とビジネス（GDP寄与率28％）、政府（同17％）、住宅（同12％）、貿易、宿泊、レストラン（同10％）、輸送・保管・通信（同8％）。製造業の重要性は近年低下しており、今ではGDP寄与率は14％。',
  'nbs_press_conference.description:ja:DEFAULT': '',
  'nbs_press_conference.description:ja:China': '',
  'gdp_growth_rate_qoq.description:ja:Germany':
    'ドイツは世界第5位、ユーロ圏最大の経済大国で、輸出は世界第2位であり、ドイツ全体の生産高の3分の1以上を輸出が占める。従って、ドイツでは高付加価値製品の輸出が近年の成長ドライバーとなってきた。GDPの支出側の構成は、家計消費（55％）、総資本形成（20％、うち建設業が10％、機械装置が6％、その他が4％）、政府支出（19％）となっている。財・サービスの輸出はGDPの46％、輸入は39％で、純輸出はGDP全体に7％の寄与となっている。',
  'gdp_growth_rate_qoq.description:ja:Poland':
    'ポーランドの経済規模は欧州連合で8番目でありながら、人口1人当たりのGDPはEU平均を大きく下回る。同国の産業基盤は石炭、繊維、化学品、機械、鉄、鉄鋼セクターであり、近年は肥料、石油化学、工作機械、電子機械、エレクトロニクス、自動車、造船にも広がっている。支出面では、家計消費がGDPの主要コンポーネントで、全体の60％を占め、次いで総固定資本形成（20％）と政府支出（18％）が続く。財とサービスの輸出はGDP全体の47％を占め、輸入は46％でGDP全体に1％の寄与となっている。',
  'gdp_growth_rate_qoq.description:ja:Taiwan':
    '「アジアの虎4カ国」の1つ、台湾経済は輸出主導型経済で、エレクトロニクスと機械製造に特化している。台湾は、コンピューターチップ、LCDパネル、DRAMコンピューターメモリ、ネットワーク機器、消費者向けエレクトロニクスの生産において世界最大の生産国の1つである。',
  'gdp_growth_rate_qoq.description:ja:South Korea':
    '韓国は輸出主導の経済であり、ディスプレイと半導体記憶装置で世界をリードしており、船舶の製造では第2位となっている。GDPに占めるサービス業の割合が最も高くなっている（GDPの約57％）。サービス業の中でも大きな割合を占めるのは、行政（6％）、教育（5.3％）、情報・通信（5％）、事業活動（4.6％）である。韓国を大規模輸出国とする要因となった強力な製造業は、GDPの32％を占めている。',
  'gdp_growth_rate_qoq.description:ja:Japan':
    '産業化が進んだ日本の自由市場経済は世界第4位の規模で、世界最大のエレクトロニクス産業と第3位の自動車産業を有する。日本経済は輸出志向セクターの効率性と競争力の高さで知られているが、農業、流通、サービスといったセクターの生産性は、より低い。',
  'gdp_growth_rate_qoq.description:ja:Netherlands':
    'オランダ経済はユーロ圏で第6位、ヨーロッパの重要な交通拠点である。オランダ経済は外国貿易に大きく依存しており、輸出はGDPの83％、輸入は72％を占める。GDPの主な構成要素は家計消費（45％）で、次いで政府支出（26％）、総固定資本形成（18％）、純輸出（11％）となっている。',
  'gdp_growth_rate_qoq.description:ja:Italy':
    'イタリアは世界で9番目に大きい経済規模を誇り、ユーロ圏では3番目に大きい経済国である。同国は、大部分が高品質消費者向け製品の製造が占める多様化した工業を基盤としている。GDPの支出構成は、家計消費（61％）、政府支出（19％）、総固定資本形成（17％）となっている。製品・サービスの輸出はGDPの30％、輸入は27％であるため、GDP合計の3％に寄与している。',
  'gdp_growth_rate_qoq.description:ja:Israel':
    'イスラエル経済の主要なけん引役は科学技術セクターで、天然資源が限定的であるにもかかわらず製造・農業セクターは高度に進化している。イスラエル経済最大のセクターはサービス業でGDPの80%を占め、うち最も重要なのは金融とビジネス（GDP寄与率28％）、政府（同17％）、住宅（同12％）、貿易、宿泊、レストラン（同10％）、輸送・保管・通信（同8％）。製造業の重要性は近年低下しており、今ではGDP寄与率は14％。',
  'gdp_growth_rate_qoq.description:ja:Hungary':
    'ハンガリーでは家計消費がGDPの主な構成要素であり、総支出の50％を占め、総固定資本形成（22％）、政府支出（20％）がそれに続く。財・サービスの輸出はGDPの89%である一方、輸入は82%であるため、GDP合計に７%のプラス寄与となっている。',
  'gdp_growth_rate_qoq.description:ja:Brazil':
    'ブラジルは世界で10番目の経済大国、ラテンアメリカ諸国では最大である。サービス業が最も重要な経済分野でGDPの63%を占める。サービス業の主要項目では、政府・国防・教育・健康（GDPの15%）、その他サービス（15%）、卸売・小売（11%）、不動産（8%）、金融サービス（7%）がある。GDPの18%を占める産業部門も重要な分野であり、製造業（11%）と建設業（4%）がその主な部分を担う。農業・畜産部門はGDPの5%を占める。支出面では、家計支出がGDPの主要項目であり、支出全体の63%を占める。それに続くのが、政府支出（20%）、総固定資本形成（16%）となる。財とサービスの輸出はGDPの13%、輸入は12%であり、純輸出は1%のプラス寄与となる。',
  'gdp_growth_rate_qoq.description:ja:New Zealand':
    'ニュージーランドは先進的な市場経済を持ち、国際貿易に大きく依存している。ニュージーランドはオーストラリアと密接な関係にあり、オーストラリアは同国製品の最大の輸入国であり、供給国であり、投資国でもある。ニュージーランドで最も発達した産業は、観光業および農産物輸出に集中しており、これが成長の主な源となっている。サービス業は経済の最大セクターであり、金融・保険・ビジネスサービス（30％）、パーソナル・コミュニティサービス（13％）、運送・通信（11％）を含めて、GDP全体の75％を占めている。工業は、製造業が13％、建設業が4％で、GDPの17％を占めている。残りの8％は農業、漁業、林業、鉱業である。',
  'gdp_growth_rate_qoq.description:ja:Portugal':
    '支出面では、家計消費がGDPの主な構成要素であり、その合計支出の66％を占めている。続いて、政府支出（19％）、総固定資本形成（15％）となっている。財・サービスの輸出はGDPの40％、輸入も40％を占める。',
  'gdp_growth_rate_qoq.description:ja:China':
    '中国ではGDPの成長率は、その四半期に中国経済が生産した財・サービスの季節調整後の価値の変化を測定する。 中国の伝統的な成長エンジンである製造業や建設業が減速する中、サービス業が新たな原動力として台頭してきた。過去数四半期においてはサービスと消費が好調で、製造業と輸出の軟化を補っている。。',
  'gdp_growth_rate_qoq.description:ja:United States':
    '支出面では、個人消費支出がGDP全体の68%を占め、そのうち製品購入による支出が23%、サービスへの支出が45%となっている。民間投資はGDPの16%、政府による消費と投資は18%を占める。製品輸出高（13.5%）が製品輸入高（16.5%）より低いため、純輸出はGDPに3%のマイナス寄与。',
  'gdp_growth_rate_qoq.description:ja:Austria':
    'オーストリア経済ではサービス業が大半を占め（63%）、25%を確立された工業セクターが占める。農業セクターは比率は1%と少ないが、非常に先進的である。支出面では、家計消費がGDPの主な構成要素で54％を占めており、次いで総固定資本形成（22％）、政府支出（20％）となっている。財・サービスの輸出はGDPの53%を占める一方、輸入は50%であるため、正味の財・サービスの輸出はGDP全体に3%寄与している。',
  'gdp_growth_rate_qoq.description:ja:Sweden':
    '支出面では、家計支出がスウェーデンGDPの主な構成要素であり、その総支出の46％を占めており、次いで政府支出（26％）、総固定資本形成（23％）となっている。財・サービスの輸出はGDPの45%である一方、輸入は41%であるため、GDP合計に4%のプラス寄与となっている。',
  'gdp_growth_rate_qoq.description:ja:Switzerland':
    '支出面では、家計消費がスイスGDPの主な構成要素であり、合計の54％を占めている。続いて総固定資本形成（24％）、政府支出（11％）となっている。輸出はGDPの64％、輸入は53％であり、純輸出はGDPに11％寄与している。',
  'gdp_growth_rate_qoq.description:ja:Indonesia':
    'インドネシアの経済は東南アジア最大である。ここ数年、主に国内消費の拡大と、製造業製品やコモディティの輸出の伸びにより、安定成長を続けている。',
  'gdp_growth_rate_qoq.description:ja:Canada':
    'カナダの経済は多様性に富み、高度に発展している。外国貿易はカナダGDPの約45％であり、米国が突出して最大の貿易相手国となっている。支出面では、家計消費がGDPの主要コンポーネントで、全体の58％を占め、それに総固定資本形成（22％）と政府支出（19％）が続く。財とサービスの輸出はGDP全体の32％、輸入は33％であるためGDP全体に1％のマイナス寄与となっている。対家計非営利団体（NPISH）の支出と在庫投資が残りの2％を占める。',
  'gdp_growth_rate_qoq.description:ja:Australia':
    'オーストラリア経済では、サービス業が中心的な役割を果たしている（GDPの65%）。とはいえ、同国は主要なコモディティ輸出国であり、近年の好調な景気を支えているのは鉱業（GDPの13.5%）と農業（2%）である。経済の他の主要分野には、製造業（11%）と建設業（9.5%）などがある。',
  'gdp_growth_rate_qoq.description:ja:Hong Kong':
    '香港は輸出主導の経済となっており、国土は小さいものの、国内総生産（GDP）の4倍の額の財やサービスの貿易が行われ世界第8位の貿易大国となっている。近年、香港は、中国市場とのつながりを求める事業者、貿易、金融、輸入業者にとって国際的なハブとなっている。経済の最大のセクターはサービス業で、GDPの約93%を占めている。',
  'gdp_growth_rate_qoq.description:ja:Greece':
    'ギリシャはサービス業を基盤とした経済で、世界中で最も観光客が訪れる国の1つとなっている。1980年に欧州連合、2000年にはユーロ圏に加入して以来、ギリシャ経済は高成長を記録していたが、その成長は主に低コストの信用と公的セクターの成長に依存していたため、2008年には財政赤字と公的債務が持続不可能な水準に達した。その結果、ギリシャは1974年以来最悪の危機に直面、IMFと欧州委員会が救済策の一環として厳しい改革プログラムを執行することとなった。',
  'gdp_growth_rate_qoq.description:ja:Thailand':
    'タイは、輸出主導型の新興経済国である。このため、製造業が最も重要な経済分野であり、GDPの34%を占める。サービス業はGDPの約44%を占め、そのうち最も重要なのは卸売・小売（GDPの13%）、輸送・倉庫・通信（7%）、宿泊・飲食サービス（5%）、行政・国防・社会保障（4.5%）である。農業も経済成長に大きく貢献する分野であり、GDPの約13%を占める。',
  'gdp_growth_rate_qoq.description:ja:Denmark':
    'デンマークでは家計消費がGDPの主な構成要素であり、その総支出の49％を占めており、次いで政府支出（27％とEU諸国では最高）、総固定資本形成（19％）となっている。財・サービスの輸出はGDPの54%に達する一方、輸入は48%であるため、GDP合計に６%のプラス寄与となっている。',
  'gdp_growth_rate_qoq.description:ja:Spain':
    'スペインはユーロ圏第4位の経済国で、欧州連合内では第5位の規模である。同国は堅固で多様な製造業を有し、世界でも最も観光客が訪れる国の1つである。支出側では、家計消費がスペインGDPの主な構成要素で58％を占めており、次いで総固定資本形成（21％）、政府支出（18％）となっている。投資のうち、有形固定資産は、建設（10％）、機械・設備・兵器システム（7％）などを含めGDPの18％を占めており、残りの3％は知的財産製品である。財・サービスの輸出はGDPの34%である一方、輸入は31%であるため、純輸出はGDP全体に1%寄与している。',
  'gdp_growth_rate_qoq.description:ja:Czech Republic':
    'チェコ共和国では家計消費がGDPの主な構成要素であり、その総支出の49％を占めており、次いで総固定資本形成（25％）、政府支出（19％）となっている。財・サービスの輸出はGDPの84%である一方、輸入は77%であるため、GDP合計に７%のプラス寄与となっている。',
  'gdp_growth_rate_qoq.description:ja:Ireland':
    'アイルランド経済は貿易、工業、投資を基盤としており、主要なハイテク生産国であるとともに、医薬品とソフトウェアに関しては世界最大の輸出国の1つである。支出面では、家計消費がGDPの主な構成要素であり、合計の44％を占めている。続いて総固定資本形成（19％）、政府支出（17％）となっている。輸出はGDPの114％、輸入は95％であり、純輸出はGDPに19％プラス寄与している。',
  'gdp_growth_rate_qoq.description:ja:United Kingdom':
    'サービス業は最も重要で、イギリスのGDPの79%を占めている。サービス業の中で最も大きな分野は、政府・教育・保健（GDP全体の19％）、不動産（12％）、専門的・科学的・技術的活動および管理・支援サービス（12％）、卸売・小売業（11％）、金融・保険（8％）である。工業分野はGDPの21％を占め、この分野の最大のセグメントは、製造業（GDP全体の10％）と建設業（6％）である。農業部門はGDPのわずか1％を占めている。支出面でのGDPの構成は、家計消費（65％）、政府支出（20％）、総固定資本形成（17％）となっている。財・サービスの輸出はGDPの28％、輸入は30％を占めるため、輸出入はGDPに2％のマイナス寄与となっている。',
  'gdp_growth_rate_qoq.description:ja:Norway':
    'ノルウェーは世界で最も裕福な国のひとつである。原油・ガス、水力、魚類などの天然資源がその富の基盤となり、発達した海運業がそれを支えている。支出面のGDPの内訳は、家計消費支出（41%）、総固定資本形成（24%）、政府消費支出（22%）となる。財とサービスの輸出はGDPの38%、輸入は30%を占め、純輸出が8%のプラス寄与となる。',
  'gdp_growth_rate_qoq.description:ja:Mexico':
    'メキシコではサービス業がGDP全体の62%を占めている。サービス業の中で最も大きな分野は、卸売・小売（16％）、不動産（10％）、輸送・倉庫・通信（7％）、金融サービス・保険（6％）である。工業は生産高の18％を占めており、その最大のセグメントは自動車（GDP全体の4％）と食品（3.8％）である。それ以外は建設、水道・ガス・配電（8％）、鉱業（5％）、政府（4％）、農業・林業・漁業・狩猟（3％）が占める。',
  'gdp_growth_rate_qoq.description:ja:France':
    'フランスは世界で7番目、ユーロ圏で2番目に大きい経済国である。GDPの支出を大きく占めるのは家計消費（55％）、政府支出（24％）、総固定資本形成（22％）となっている。財・サービスの輸出はGDPの29％、輸入は31％であるため、純輸出はGDP全体に2％のマイナス寄与となっている。',
  'gdp_growth_rate_qoq.description:ja:Belgium':
    'ベルギーでは家計消費がGDPの主な構成要素であり、その総支出の52％を占めており、次いで政府支出（25％）、総固定資本形成（23％）となっている。財・サービスの輸出はGDPの84%である一方、輸入は83%であるため、GDP合計に1%のマイナス寄与となっている。',
  'gdp_growth_rate_qoq.description:ja:South Africa':
    '南アフリカはアフリカ大陸で最も発達した国で、2014年まで最大の経済国であったが、その後ナイジェリアにその地位を奪われた。南アフリカ経済で最大のセクターはサービス業であり、GDPの約73%を占める。サービス業の主要項目には、金融・不動産・ビジネスサービス（21.6%）、政府サービス（17%）、卸売・小売・自動車販売・ケータリング・宿泊（15%）、輸送・倉庫・通信（9.3%）がある。製造業はGDPの13.9%、鉱業・採石業が約8.3%を占める一方、農業が全体に占める割合はわずか2.6%にとどまる。',
  'gdp_growth_rate_qoq.description:ja:Euro Area':
    'ユーロ圏とは、通貨としてユーロを採用している欧州19カ国の経済通貨同盟のことである。世界第2位の経済圏であり、人口は3億4100万人で、国としてみれば人口は世界第3位である。ドイツ、フランス、イタリア、スペインが最も重要な経済圏で、それぞれGDPの29％、20％、15％、10％を占めている。',
  'gdp_growth_rate_qoq.description:ja:Colombia':
    'コロンビアは、ラテンアメリカで4番目に大きな経済国である。近年は石油、石炭、コーヒーなどのコモディティの輸出増加を主な要因として、急成長を遂げている。コロンビア経済で最も急速な成長を遂げている最大の部門はサービス業（GDPの53%を占める）であり、その主要分野には金融・保険・不動産・ビジネスサービス、地域・社会・個人サービス、商業・修理・レストラン・ホテル、輸送・倉庫・通信がある。このほか、製造業・鉱業がGDPの20%を占める。その他の部門には、政府（10%）、農林水産業（6.3%）、建設業（6%）、電力・ガス・水道（4%）が含まれる。',
  'gdp_growth_rate_qoq.description:ja:Philippines':
    'フィリピンは新興国としての地位を確立している。近年は、海外からの直接投資や送金などにより、着実に成長している。フィリピンは世界最大のビジネス・プロセス・アウトソーシングの中心地である。また、海外企業向けの電子機器やハイテク部品の製造を中心とした強力な工業部門を有している。フィリピンは天然資源に恵まれており、クロム鉄鉱、ニッケル、銅、石炭、石油など豊富な埋蔵量を誇る。',
  'gdp_growth_rate_qoq.description:ja:Finland':
    'フィンランド経済ではサービス業が65%を占めるが、主要セクターは主に材木、金属、エンジニアリング、通信、エレクトロニクスなどの製造業である。支出面では、家計消費がGDPの主な構成要素であり、その総支出の55％を占めており、次いで政府支出（25％）、総固定資本形成（20％）となっている。財・サービスの輸出はGDPの38%、輸入は39%であるため、GDP全体に1%のマイナス寄与となっている。。',
  'gdp_growth_rate_annualized_qoq.description:ja:DEFAULT': '',
  'gdp_growth_rate_annualized_qoq.description:ja:Israel': '',
  'gdp_growth_rate_2018.description:ja:DEFAULT': '',
  'gdp_growth_rate_2018.description:ja:Philippines':
    'フィリピン経済最大の分野はサービス業であり、GDPの57%を占める。サービス業は主に、商業・自動車および家庭用品の修理（GDPの17%）、不動産・レンタルおよびビジネス活動（11%）、輸送・倉庫・通信（8%）、金融サービス（7%）、行政・防衛・社会保障（4%）からなる。産業部門はGDPの31%を占め、主に、製造業（22%）、建設業（5%）がある。農業は残りの12%を占める。',
  'gdp_growth_rate_2017.description:ja:DEFAULT': '',
  'gdp_growth_rate_2017.description:ja:Poland':
    'ポーランドの消費者市場は3,800万人と、ヨーロッパでも最大級の規模を誇る。ポーランドの主要産業は、食品・飲料加工、造船、機械・鉄鋼製品・化学品・ガラス・繊維製品の製造である。支出面では、家計消費がGDPの60％を占めており、次いで総固定資本形成（20％）、政府支出（18％）である。財・サービスの輸出はGDPの47％を占め、輸入は46％で、GDP全体に1％寄与している。',
  'gdp_growth_rate_2017.description:ja:Philippines':
    'フィリピン経済最大の分野はサービス業であり、GDPの57%を占める。サービス業は主に、商業・自動車および家庭用品の修理（GDPの17%）、不動産・レンタルおよびビジネス活動（11%）、輸送・倉庫・通信（8%）、金融サービス（7%）、行政・防衛・社会保障（4%）からなる。産業部門はGDPの31%を占め、主に、製造業（22%）、建設業（5%）がある。農業は残りの12%を占める。',
  'gdp_growth_rate_2016.description:ja:DEFAULT': '',
  'gdp_growth_rate_2016.description:ja:Philippines':
    'フィリピン経済最大の分野はサービス業であり、GDPの57%を占める。サービス業は主に、商業・自動車および家庭用品の修理（GDPの17%）、不動産・レンタルおよびビジネス活動（11%）、輸送・倉庫・通信（8%）、金融サービス（7%）、行政・防衛・社会保障（4%）からなる。産業部門はGDPの31%を占め、主に、製造業（22%）、建設業（5%）がある。農業は残りの12%を占める。',
  'gdp_growth_rate_2015.description:ja:DEFAULT': '',
  'gdp_growth_rate_2015.description:ja:Italy':
    'イタリアは欧州で2番目に大きい製造業経済であり、ユーロ圏で3番目に大きい経済国である。GDPの支出側の構成：家計消費（61％）、政府支出（19％）、総固定資本形成（17％）。財・サービスの輸出はGDPの30％、輸入は27％であるため、GDP全体に3％寄与している。',
  'gdp_growth_rate_2015.description:ja:Indonesia':
    'インドネシアは東南アジア最大の経済国である。産業部門がGDPの最大の割合を占め（46.5%）、その最も重要な構成項目である製造業は、主要な成長エンジンのひとつとなっている（GDPの24%）。鉱業・採石業はGDPの12%、建設業が10%、電力・ガス・水道が0.75%を占める。サービス業はGDPの38%を担い、その主要項目には商業・ホテル・レストラン（GDPの約14%）、輸送・通信（7%）、金融・不動産・ビジネスサービス（7%）、政府サービス（6%）がある。農業が残りの15%を占める。',
  'gdp_growth_rate_2015.description:ja:Philippines':
    'フィリピン経済最大の分野はサービス業であり、GDPの57%を占める。サービス業は主に、商業・自動車および家庭用品の修理（GDPの17%）、不動産・レンタルおよびビジネス活動（11%）、輸送・倉庫・通信（8%）、金融サービス（7%）、行政・防衛・社会保障（4%）からなる。産業部門はGDPの31%を占め、主に、製造業（22%）、建設業（5%）がある。農業は残りの12%を占める。',
  'gdp_growth_rate_2014.description:ja:DEFAULT': '',
  'gdp_growth_rate_2014.description:ja:Germany':
    '世界第5位、欧州最大の経済規模を誇るドイツ経済は、機械、自動車、化学品、家庭用機器などの主要な輸出国であり、高度に熟練した労働力の恩恵を受けている。GDPの支出側の構成：家計消費（55％）、総資本形成（20％、うち建設業が10％、機械装置が6％、その他が4％）、政府支出（19％）。財・サービスの輸出はGDPの46％、輸入は39％で、GDP全体に7％寄与している。',
  'purim.description:ja:DEFAULT': '',
  'purim.description:ja:Israel': '',
  'epiphany_day.description:ja:DEFAULT': '',
  'epiphany_day.description:ja:Sweden': '',
  'epiphany_day.description:ja:Poland': '',
  'epiphany_day.description:ja:Greece': '',
  'epiphany_day.description:ja:Finland': '',
  'gdp_growth_rate.description:ja:DEFAULT': '',
  'gdp_growth_rate.description:ja:Germany':
    'ドイツは世界第5位、ユーロ圏最大の経済大国で、輸出は世界第2位であり、ドイツ全体の生産高の3分の1以上を輸出が占める。従って、ドイツでは高付加価値製品の輸出が近年の成長ドライバーとなってきた。GDPの支出側の構成は、家計消費（55％）、総資本形成（20％、うち建設業が10％、機械装置が6％、その他が4％）、政府支出（19％）となっている。財・サービスの輸出はGDPの46％、輸入は39％で、純輸出はGDP全体に7％の寄与となっている。',
  'gdp_growth_rate.description:ja:Finland': '',
  'gdp_growth_rate.description:ja:South Africa': '',
  'gdp_growth_rate.description:ja:Hong Kong': '',
  'gdp_growth_rate.description:ja:United Kingdom': '',
  'gdp_growth_rate.description:ja:Denmark': '',
  'norges_bank_press_conference.description:ja:DEFAULT': '',
  'norges_bank_press_conference.description:ja:Norway':
    'ノルウェーでは、基準金利はノルウェー中央銀行（ノルゲス銀行）が設定する。公式金利は主要政策金利で、サイト・デポジット・レートとも呼ばれる。',
  'rba_interest_rate_decision.description:ja:DEFAULT': '',
  'rba_interest_rate_decision.description:ja:Australia':
    'オーストラリアでは金利決定はオーストラリア準備銀行理事会が行い、政策金利はキャッシュレートである。キャッシュレートとは、金融機関の間で翌日物貸付に適用される利率で、翌日物資金の需要と供給によってマネーマーケットで決定される。',
  'municipal_election_second_round.description:ja:DEFAULT': '',
  'municipal_election_second_round.description:ja:Brazil': '',
  'gdp_growth_mainland_qoq_prel.description:ja:DEFAULT': '',
  'gdp_growth_mainland_qoq_prel.description:ja:Norway':
    'ノルウェーでは、移動３カ月成長率は、その前の３カ月の期間と比較して算出される。例えば、５-７月期であれば２-4月期と比較される。',
  'gdp_growth_mainland_qoq.description:ja:DEFAULT': '',
  'gdp_growth_mainland_qoq.description:ja:Norway':
    'ノルウェーでは、移動３カ月成長率は、その前の３カ月の期間と比較して算出される。例えば、５-７月期であれば２-4月期と比較される。',
  'communist_party_annual_meeting.description:ja:DEFAULT': '',
  'communist_party_annual_meeting.description:ja:China': '',
  'gdp_growth_annualized_2nd_est.description:ja:DEFAULT': '',
  'gdp_growth_annualized_2nd_est.description:ja:Israel':
    'GDP年率成長率は、前四半期比のGDP成長率が1年を通じて維持されたと仮定した場合の成長率を示す。',
  'gdp_private_consumption_qoq_prel.description:ja:DEFAULT': '',
  'gdp_private_consumption_qoq_prel.description:ja:Japan':
    '日本では、家計の消費支出確報値は、供給側の測定値に加え家計調査などによる消費者側の測定値を考慮する。家計消費支出の確報値は、需給両側の加重平均によって算出される。',
  'gdp_capital_expenditure.description:ja:DEFAULT': '',
  'gdp_capital_expenditure.description:ja:Australia':
    '総固定資本形成は、生産者による会計期間中の固定資産の取得から処分を控除したものに、生産者自身が行った設備投資と、制度単位の生産活動によって実現した非生産資産の価値増大を加えたものに等しい。',
  'gdp_growth_annualized_1st_est.description:ja:DEFAULT': '',
  'gdp_growth_annualized_1st_est.description:ja:Israel':
    'GDP年率成長率は、前四半期比のGDP成長率が1年を通じて維持されたと仮定した場合の成長率を示す。',
  'gdp_external_demand_qoq_final.description:ja:DEFAULT': '',
  'gdp_external_demand_qoq_final.description:ja:Japan': '',
  'proclamation_of_the_republic_day.description:ja:DEFAULT': '',
  'proclamation_of_the_republic_day.description:ja:Brazil': '',
  'gdp_deflator_qoq.description:ja:DEFAULT': '',
  'gdp_deflator_qoq.description:ja:United States': '',
  'gdp_deflator_qoq.description:ja:Australia':
    'オーストラリアでは、インプリシット価格デフレーター（IPD）は、足元価格を実質価格（連鎖数量速度）で割ったものである。GDPなど主要な国民経済計算合計値から算出される場合、IPDはオーストラリア統計局が発表する個々の物価指数が代表するよりももっと幅広い財とサービスに関連する。インプリシット物価デフレーターの変動は、価格変動とデフレーターの算出に使う総合値の構成内容の変化の双方を反映する。',
  '119-day_bill_auction.description:ja:DEFAULT': '',
  '119-day_bill_auction.description:ja:United States': '',
  'gdp_deflator.description:ja:DEFAULT': '',
  'gdp_deflator.description:ja:United States': '',
  'gdp_deflator.description:ja:Australia':
    'オーストラリアでは、インプリシット価格デフレーター（IPD）は、足元価格を実質価格（連鎖数量速度）で割ったものである。GDPなど主要な国民経済計算合計値から算出される場合、IPDはオーストラリア統計局が発表する個々の物価指数が代表するよりももっと幅広い財とサービスに関連する。インプリシット物価デフレーターの変動は、価格変動とデフレーターの算出に使う総合値の構成内容の変化の双方を反映する。',
  'jewish_new_year_i.description:ja:DEFAULT': '',
  'jewish_new_year_i.description:ja:Israel': '',
  'gdp_consumer_spending_qoq_final.description:ja:DEFAULT': '',
  'gdp_consumer_spending_qoq_final.description:ja:United States':
    '消費支出は財やサービスに対する民間の支出であり、GDPの主要な構成要素である。',
  'eu-ukraine_summit.description:ja:DEFAULT': '',
  'eu-ukraine_summit.description:ja:European Union': '',
  'eu-ukraine_summit.description:ja:Euro Area': '',
  'gdp_consumer_spending_qoq_adv.description:ja:DEFAULT': '',
  'gdp_consumer_spending_qoq_adv.description:ja:United States':
    '消費支出は財やサービスに対する民間の支出であり、GDPの主要な構成要素である。',
  'reuters_michigan_consumer_expectations_final.description:ja:DEFAULT': '',
  'reuters_michigan_consumer_expectations_final.description:ja:United States':
    '消費者信頼感指数は、（1）消費者自身の財務状況の先行き見通し、（2）目先の経済全体に関する先行き見通し、（3）長期的な経済全体に関する先行き見通しの3つの分野に注目するもので、毎月実施されるアンケート調査には50項目の中核的な設問が含まれ、それぞれの設問が消費者の態度と予想を追跡する。消費者調査のサンプルは統計的にアラスカ州とハワイ州を除くすべての米国世帯を代表するように設計され、毎月500人以上に電話でインタビューが実施される。',
  'independence_movement_day.description:ja:DEFAULT': '',
  'independence_movement_day.description:ja:South Korea': '',
  'gdp_annual_growth_rate_yoy_-_second_estimate.description:ja:DEFAULT': '',
  'gdp_annual_growth_rate_yoy_-_second_estimate.description:ja:Euro Area':
    'ユーロ圏は世界で2番目に大きな経済圏である。加盟している19カ国のうち、最も大きいのはドイツ（GDP合計の29％）、フランス（20％）、イタリア（15％）、スペイン（10％）である。支出面では家計消費がGDPの主な構成要素で、総支出の54％を占めており、次いで総固定資本形成（21％）、政府支出（20％）となっている。財・サービスの輸出はGDPの47%、輸入は43%であるため、GDP全体に4%寄与している。',
  'gdp_annual_growth_rate_yoy.description:ja:DEFAULT': '',
  'gdp_annual_growth_rate_yoy.description:ja:Portugal':
    '支出面では、家計消費がGDPの主な構成要素であり、その合計支出の66％を占めている。続いて、政府支出（19％）、総固定資本形成（15％）となっている。財・サービスの輸出はGDPの40％、輸入も40％を占める。',
  'gdp_annual_growth_rate_yoy.description:ja:Italy':
    'イタリアは欧州で2番目に大きい製造業経済であり、ユーロ圏で3番目に大きい経済国である。GDPの支出側の構成：家計消費（61％）、政府支出（19％）、総固定資本形成（17％）。財・サービスの輸出はGDPの30％、輸入は27％であるため、GDP全体に3％寄与している。',
  'gdp_annual_growth_rate_yoy.description:ja:Czech Republic':
    'チェコ共和国では家計消費がGDPの主な構成要素であり、その総支出の49％を占めており、次いで総固定資本形成（25％）、政府支出（19％）となっている。財・サービスの輸出はGDPの84%である一方、輸入は77%であるため、GDP合計に７%のプラス寄与となっている。',
  'gdp_annual_growth_rate_yoy.description:ja:Greece':
    'ギリシャでは家計消費がGDPの主な構成要素であり、その総支出の72％を占めており、次いで政府支出（20％）、総固定資本形成（12％）となっている。財・サービスの輸出はGDPの33%、輸入は35%であるため、GDP合計に2%のマイナス寄与となっている。',
  'gdp_annual_growth_rate_yoy.description:ja:Australia':
    'オーストラリア経済では、サービス業がその中心的な役割を担っている（GDPの65%）。とはいえ、近年の好調な景気を支えているのは鉱業（GDPの13.5%）と農業（2%）であり、同国は主要なコモディティ輸出国となっている。経済の他の主要分野には、製造業（11%）と建設業（9.5%）などがある。',
  'gdp_annual_growth_rate_yoy.description:ja:Austria':
    'オーストリアでは家計消費がGDPの主な構成要素で54％を占めており、次いで総固定資本形成（22％）、政府支出（20％）となっている。財・サービスの輸出はGDPの53%を占める一方、輸入は50%であるため、正味の財・サービスの輸出はGDP全体に3%寄与している。',
  'gdp_annual_growth_rate_yoy.description:ja:Belgium':
    'ベルギーでは家計消費がGDPの主な構成要素であり、その総支出の52％を占めており、次いで政府支出（25％）、総固定資本形成（23％）となっている。財・サービスの輸出はGDPの84%である一方、輸入は83%であるため、GDP合計に1%のマイナス寄与となっている。',
  'gdp_annual_growth_rate_yoy.description:ja:Hungary':
    'ハンガリーでは、電子機器の製造と研究などがイノベーションと経済成長の主な原動力である。また、モバイル技術、情報セキュリティ、および関連するハードウェア研究の主要な中心地としても成長している。支出面では、家計消費がGDPの主な構成要素として全体の50%を占め、総固定資本形成（22％）、政府支出（20％）と続く。財・サービスの輸出はGDPの89%を占め、輸入は82%であるため、GDP全体に7%寄与している。',
  '3-year_bonos_auction.description:ja:DEFAULT': '',
  '3-year_bonos_auction.description:ja:Spain': '',
  'domestic_car_sales.description:ja:DEFAULT': '',
  'domestic_car_sales.description:ja:United States': '',
  'gdp_annual_growth_rate_-_adv.description:ja:DEFAULT': '',
  'gdp_annual_growth_rate_-_adv.description:ja:South Korea':
    '韓国の経済は、過去50年間で世界で最も多様化し、技術的にも進歩した国の一つとなった。多くの先進国と同様、GDPに占めるサービスの割合が最も高くなっている（GDPの約57％）。サービス業の中でも特に重要なのは、行政（6％）、教育（5.3％）、情報・通信（5％）、ビジネス活動（4.6％）である。韓国を主要な輸出国にした強力な製造業は、GDPの32％を占めている。',
  'boc_beaudry_speech.description:ja:DEFAULT': '',
  'boc_beaudry_speech.description:ja:Canada':
    'カナダでは、基準金利が中央銀行であるカナダ銀行（BoC）理事会によって定められる。公式金利は翌日物レートとなっている。1996年以来、銀行金利は短期金融市場の翌日物レートでの運用幅の上限に設定されている。1980年3月から1996年2月までは、この金利は3カ月割引国債の週間入札金利平均より25ベーシスポイント上に設定されていた。',
  'g7_summit.description:ja:DEFAULT': '',
  'g7_summit.description:ja:United Kingdom': '',
  'g7_summit.description:ja:Japan': '',
  'g7_summit.description:ja:Italy': '',
  'g7_summit.description:ja:Canada': '',
  'g7_summit.description:ja:France': '',
  'g7_meeting_on_afghanistan_crisis.description:ja:DEFAULT': '',
  'g7_meeting_on_afghanistan_crisis.description:ja:United Kingdom': '',
  'passenger_vehicles_sales_mom.description:ja:DEFAULT': '',
  'passenger_vehicles_sales_mom.description:ja:India': '',
  'labour_day_substitute_day.description:ja:DEFAULT': '',
  'labour_day_substitute_day.description:ja:Greece': '',
  'labour_day_substitute_day.description:ja:Spain': '',
  'fed_ccar_results_for_big_banks.description:ja:DEFAULT': '',
  'fed_ccar_results_for_big_banks.description:ja:United States': '',
  'hispanic_day.description:ja:DEFAULT': '',
  'hispanic_day.description:ja:Spain': '',
  'g7_foreign_ministers_meeting.description:ja:DEFAULT': '',
  'g7_foreign_ministers_meeting.description:ja:Italy': '',
  'credit_expansion_yoy.description:ja:DEFAULT': '',
  'credit_expansion_yoy.description:ja:Greece':
    '国内通貨金融機関による国内非通貨金融機関への与信合計。',
  'g7_finance_ministers_and_central_bank_governors_meeting.description:ja:DEFAULT': '',
  'g7_finance_ministers_and_central_bank_governors_meeting.description:ja:Japan': '',
  'unemployment_change.description:ja:DEFAULT': '',
  'unemployment_change.description:ja:Norway':
    'ノルウェーでは失業者数は、仕事がなく、積極的に職を探している個人の数である。',
  'unemployment_change.description:ja:Spain':
    'スペインでは、失業者数変化は、前月比の失業者数の増減を測定する。',
  'unemployment_change.description:ja:Germany':
    'ドイツでは、雇用者数変化は、当該月における失業者数の絶対ベースでの増減を測定する。',
  'g7_finance_ministers_and_central_bank_governors_meeting.description:ja:France': '',
  'g7_fin_ministers_and_cb_governors_meeting.description:ja:DEFAULT': '',
  'g7_fin_ministers_and_cb_governors_meeting.description:ja:Italy': '',
  'buddha_s_birthday.description:ja:DEFAULT': '',
  'buddha_s_birthday.description:ja:Hong Kong': '',
  'buddha_s_birthday.description:ja:Singapore': '',
  'buddha_s_birthday.description:ja:South Korea': '',
  'buddha_s_birthday.description:ja:Thailand': '',
  'buddha_s_birthday.description:ja:Indonesia': '',
  'g30_international_banking_seminar.description:ja:DEFAULT': '',
  'g30_international_banking_seminar.description:ja:United States': '',
  'g20-conference.description:ja:DEFAULT': '',
  'g20-conference.description:ja:Germany': '',
  'g20_summit_meeting.description:ja:DEFAULT': '',
  'g20_summit_meeting.description:ja:Japan': '',
  'g20_summit.description:ja:DEFAULT': '',
  'g20_summit.description:ja:Germany': '',
  'boe_quarterly_bulletin_q2.description:ja:DEFAULT': '',
  'boe_quarterly_bulletin_q2.description:ja:United Kingdom': '',
  'g20_meeting.description:ja:DEFAULT': '',
  'g20_meeting.description:ja:Australia': '',
  'g20_meeting.description:ja:Japan': '',
  'g20_meeting.description:ja:China': '',
  'g20_meeting.description:ja:United States': '',
  'president_jinping_speech_at_boao_forum.description:ja:DEFAULT': '',
  'president_jinping_speech_at_boao_forum.description:ja:China': '',
  'g20_foreign_ministers_meeting.description:ja:DEFAULT': '',
  'g20_foreign_ministers_meeting.description:ja:Germany': '',
  'g20_finance_and_central_bank_deputies_meeting.description:ja:DEFAULT': '',
  'g20_finance_and_central_bank_deputies_meeting.description:ja:Italy': '',
  'g20_finance_ministers_and_central_bank_governors_meeting.description:ja:DEFAULT': '',
  'g20_finance_ministers_and_central_bank_governors_meeting.description:ja:United States': '',
  'rba_lowe_speaks_before_parliament.description:ja:DEFAULT': '',
  'rba_lowe_speaks_before_parliament.description:ja:Australia': '',
  'riksbank_ingves_speech.description:ja:DEFAULT': '',
  'riksbank_ingves_speech.description:ja:Sweden': '',
  'financial_stability_report_2018.description:ja:DEFAULT': '',
  'financial_stability_report_2018.description:ja:Sweden': '',
  'snb_quarterly_bulletin.description:ja:DEFAULT': '',
  'snb_quarterly_bulletin.description:ja:Switzerland':
    'スイスではスイス国立銀行（SNB）が金利を決定し、公式金利はSNBの政策金利である。SNBは有担保短期スイスフラン建てマネーマーケット金利をSNBの政策金利近傍に維持することを目標としている。今日、これらの最も代表的な金利はSARONである。2019年６月13日時点で従来SNBが金融政策戦略に使用していた３カ月物スイスフランLIBOR（ロンドン銀行間取引金利）の目標レンジに代わり、SNB政策金利が採用されることになった。その理由はLIBORを決定するためのマネーマーケット取引が不足し、LIBORが最も重要な参照金利としての重要性を失いつつあったためである。2011年9月6日から2015年1月15日までの期間の主な注目点は最低為替レートとして1EUR＝1.20CHFをSNBが強制執行したことであった。2014年12月18日にSNBは要求払い預金金利をマイナス0.25％とすることを決定した。マイナス金利導入の発表に伴い、当時使われていたLIBORの目標レンジは初めてマイナス圏となった。2015年1月15日、SNBは要求払い預金金利をさらにマイナス0.75％に引き下げ、目標レンジをマイナス1.25％～マイナス0.25％の間とした。2015年1月22日以来マイナス金利が適用されており、現在SNBの政策金利に対応している。',
  'european_council_video_conference.description:ja:DEFAULT': '',
  'european_council_video_conference.description:ja:European Union': '',
  'g20_finance_ministers_and_cb_governors_meeting.description:ja:DEFAULT': '',
  'g20_finance_ministers_and_cb_governors_meeting.description:ja:Indonesia': '',
  'g20_finance_ministers_and_cb_governors_meeting.description:ja:United States': '',
  'g20_finance_ministers_and_cb_governors_meeting.description:ja:Germany': '',
  'tankan_large_manufacturing_outlook.description:ja:DEFAULT': '',
  'tankan_large_manufacturing_outlook.description:ja:Japan': '',
  'mni_business_sentiment.description:ja:DEFAULT': '',
  'mni_business_sentiment.description:ja:China': '',
  'employment_change_yoy_final.description:ja:DEFAULT': '',
  'employment_change_yoy_final.description:ja:Euro Area':
    'ユーロ圏では雇用変化は、賃金または利益のために働く者と無給家族労働者の数の前四半期比変化を指し、推定値にはフルタイムとパートタイム双方が含まれる。',
  'g20_fin_ministers_and_cb_governors_meeting.description:ja:DEFAULT': '',
  'g20_fin_ministers_and_cb_governors_meeting.description:ja:United States': '',
  'g20_fin_ministers_and_cb_governors_meeting.description:ja:China': '',
  'eve_of_pesach_passover.description:ja:DEFAULT': '',
  'eve_of_pesach_passover.description:ja:Israel': '',
  'fulltime_employment.description:ja:DEFAULT': '',
  'fulltime_employment.description:ja:Australia': '',
  'full_time_employment_chg.description:ja:DEFAULT': '',
  'full_time_employment_chg.description:ja:Canada':
    'カナダでは、フルタイム雇用者は、主な仕事または唯一の仕事での労働時間が通常週30時間以上の労働者で構成される。',
  'full_time_employment_chg.description:ja:Australia':
    'オーストラリアでは、フルタイム雇用者数変化は、1週間当たり通常35時間以上勤務する者（あらゆる職種）と、勤務時間が通常は1週間当たり35時間未満であっても基準週に35時間以上勤務した者の総数の変化を示す。',
  'employment_wages_qoq.description:ja:DEFAULT': '',
  'employment_wages_qoq.description:ja:United States': '',
  'full_employment_change.description:ja:DEFAULT': '',
  'full_employment_change.description:ja:Canada': '',
  'exports.description:ja:DEFAULT': '',
  'exports.description:ja:Philippines':
    'フィリピンの輸出は同国GDP全体の3分の1近くを占め、主要な輸出製品は、 エレクトロニクス製品（全体の42％）、その他製品（10％）、木製品と家具（6％）である。フィリピンはまた、世界最大のココナッツ、パイナップル、 マニラ麻の生産国。主な輸出相手国は日本（21％）、米国（15％）、中国（12％）、香港（8％）。',
  'exports.description:ja:Russia':
    'ロシア経済はコモディティ輸出に大きく依存しており、ロシア連邦予算の歳入の約半分は原油や石油製品、天然ガスが占める。2015年時点でロシアの主要な輸出品目は、燃料およびエネルギー製品（出荷全体の63％、うち原油は26%、天然ガスは12%）、金属（10％）、機械・機器（7.4%）、化学製品（7.4%）、食料品・農産物（5％）だった。主要な貿易相手国は中国（12%）、ドイツ（9%）、オランダ（8.4%）、イタリア（5.8%）、ベラルーシ（4.7%）、トルコ（4.4%）、日本（4.1%)。。',
  'exports.description:ja:Portugal':
    '2017年時点でポルトガルの主な輸出品目は、機械・機械設備・電気機器（全輸出の15%）、車両、航空機、船舶、関連輸送機器（12%）、繊維・繊維製品（10%）鉱物製品（9%）、ベースメタル・ベースメタル製品（8%）、プラスチック・ゴム（8%）、加工食品（6%）、化学および関連業界の製品（5%）、材木のパルプまたは繊維状セルロース素材（5%）だった。ポルトガルの主要な貿易相手国はスペイン（全輸出の25%）、フランス（13%）、ドイツ（11%）、英国（7%）、米国（5%）で、イタリアとオランダはそれぞれ4%を占める。',
  'exports.description:ja:China':
    '中国経済の急成長を支える主要なエンジンは輸出の伸びであるが、2019年には貿易摩擦によって米国への売上高が激減し、中国の輸出伸び率は2018年の10％から0.5％へと大きく減速した。機械と輸送機器の輸出が全体の48％を占め、特に電気機器と器具、電化製品（14％）、通信・録音および複製機器（12％）、事務用機械および自動データ処理機器（8％）、および一般産業機器および設備とその部品（5％）が大きな比率を占める。その他主要な輸出カテゴリーとしては、雑製品（23％）（うち3％は家具部品）、繊維糸や織物、既製品（5％）など、主に素材によって分類される製品（16％）、金属製品（4％）、鉄および鉄鋼（2％）、化学品および関連製品（6％）、食品および動物（3％）などがある。中国にとって最大の出荷先相手国はEUと米国で、それぞれ17％を占め、次に香港（11％）、日本（6％）、韓国（4％）、ベトナム（4％）、ドイツ（3％）、インド（3％）、オランダ（3％）と続く。英国、台湾、シンガポール、マレーシア、ロシア、オーストラリア、インドネシア、タイ、フィリピンはそれぞれ2％を占める。',
  'exports.description:ja:South Korea':
    '韓国は輸出志向経済であり、全GDPの50%以上を輸出が占める。同国の輸出は2018年に過去最高に達した後、2019年には米中間の貿易摩擦や日本の輸出規制、ブレグジット、香港の抗議運動などによって10.3％落ち込み、5,324億米ドルとなった。主な輸出製品は、電気機械・機器（全輸出高の28％）、原子炉・ボイラー・機械（13％）、 道路走行車両（12％）、鉱物性燃料、鉱物油・瀝青物質（8％）、プラスチックおよびその付属品（6％）、鉄・鉄鋼（4％）、光学・写真・映像・測定（4％）、有機化学（4％）、船舶（3％）。 韓国にとって最大の輸出相手国は中国（全輸出高の25％）で、それに米国（14％）、ベトナム（9％）、香港（6％）、日本（5％）、台湾（3％）、インド（3％）が続く。シンガポール、メキシコ、マレーシア、ドイツ、フィリピンはそれぞれ2％である。',
  'exports.description:ja:Taiwan':
    '台湾は輸出志向経済であり、全GDPの70%を輸出が占める。その内容はこの40年の間に農産物中心から工業製品へと変化し、今では工業製品が輸出の98%を占める。主要な輸出製品はエレクトロニクス（全体の33.1%）、情報・通信・オーディオビジュアル製品（10.8%）、ベースメタル（8.8%）、プラスチックとゴム（7.1%）、機械（7.5％）。主な輸出相手国は中国本土および香港（全体の40%）、ASEAN諸国（18.3%）、米国（12%）、欧州（9%）、日本（7%）。',
  'exports.description:ja:Canada':
    'カナダではGDP全体の30%を輸出が占め、2018年時点でカナダの主な輸出品目は、エネルギー製品（19%）、自動車・自動車部品（15%）、消費財（11%）、金属および非金属鉱物製品（11%）、林産物・建材・包装材（8%）、農産物・漁肉製品・中間食品（7%）、産業用機械・装置・部品（7％）、基礎・産業化学薬品・ラスチック・ゴム製品（6%）だった。米国が突出してカナダ最大の輸出相手国であり（全輸出の74%）、その次にEU（8%）、ドイツ（3%）、中国（5%）、日本（2%）、メキシコ（2%）と続く。',
  'exports.description:ja:United States':
    '米国は世界第3位の輸出大国であるが、輸出が米国GDPに占める比率は13%に過ぎない。主な輸出品目は、資本財（全輸出の22%）と工業用原材料（22%）。その他、消費財は8%、石油は7%を占める。石油輸出額は2018年に1,724億ドルと過去最高を記録した。2018年時点で主な輸出相手国は、カナダ（全輸出の18%）、メキシコ（16%）、中国（7%）、日本（4.5%）、英国（4%）、ドイツ（3.5％）だった。',
  'exports.description:ja:Israel':
    'イスラエルではGDP全体の40%を輸出が占める。主な輸出品目は、研磨済みおよび未研磨ダイヤモンド、真珠、その他貴金属と貴石（33%）、電気機器・設備・機械設備・機器、録音録画機器、複製機、コンピューター（22%）、化学製品（11%）。主な輸出相手国は米国（全輸出の28%）と香港（8%）だが、ベルギー、英国、インド、中国への輸出も多い。',
  'exports.description:ja:New Zealand':
    'ニュージランド経済は貿易に大きく依存しており、非常に効率的な農業システムからの輸出が伝統的に基盤となっている。主な輸出品は、乳製品、鳥の卵、天然蜂蜜、動物由来の食品（全輸出の24%）、食肉および食用キノコ（14%）、木材・木材製品（7%）、果物とナッツ、柑橘類またはメロンの皮（5%）、飲料、蒸留酒、酢（4%）である。主な輸出相手国は中国（18%）、オーストラリア（17%）、米国（12%）、日本（6%）、英国（3%）、韓国（3%）である。',
  'exports.description:ja:Brazil':
    'ブラジルは輸出中心の経済で、原材料（全輸出の50%）と製造物（36%）の輸出に依存している。主な輸出品は、大豆・関連大豆製品（全輸出の17%）、輸送機器および部品（12%）、石油・石油製品（13%）である。主な輸出相手国は、中国（27%）、米国（12%）、アルゼンチン（6%）、オランダ（5%）、チリ（3%）である。',
  'exports.description:ja:Hong Kong': '',
  'exports.description:ja:South Africa': '',
  'exports.description:ja:Japan':
    '日本では1960年以来、ハイテク製品が経済の成長エンジンとなってきた。全GDPに対して輸出が占める比率は19％。2018年には過去最高の輸出額を記録したが、2019年には世界の貿易摩擦によって5.6％減少した。主要な輸出製品は、輸送機器（輸出全体の24％）で、車両が16％を占める。発電機と半導体機器などの機械は20％、半導体、IC、電気器具などの電気機械は17％、プラスチック素材や有機化学を主体とする化学品は11％、鉄や鉄鋼などの工業製品は11％である。主な輸出相手国は米国（20％）、中国（19％）、EU（12％）（特にドイツが3％）、韓国（7％）、台湾（6％）、香港（5％）、タイ（4％）。',
  'exports.description:ja:Mexico':
    'メキシコは輸出中心の経済で、主な輸入品目は工業製品（全出荷の89%）および石油と石油製品（6%）である。工業製品の中でも主な輸入品目は、金属製品、機械および装置（69%）で、自動車は全体の31%を占める。その他、重要なカテゴリーには、電気・電子機器（18%）や、工業向け特殊機器（13%）などがある。農産物は4%、鉱物は1%を占める。メキシコの輸出相手国最上位は米国（80%）で、次にカナダ（3%）、ドイツ（2%）、中国（2%）が続く。米国への主な輸出品目には、自動車のその他部品および付属品（総輸出額の14%）、トラック、バス、特殊目的車両（10%）、乗用車（10%）、コンピューター（6%）、電話通信機器（5%）がある。',
  'exports.description:ja:Czech Republic':
    'チェコ共和国では国際貿易が盛んに行われている。主な輸出品目は、自動車製造用の各種製品、家具、電気家電となっている。大半は欧州各国に輸出され、特にドイツへの輸出が多い。',
  'exports.description:ja:Thailand': '',
  'exports.description:ja:Indonesia':
    'インドネシアにとって輸出は経済成長エンジンである。しかし、2012年にピークに達した後はコモディティ価格の下落と世界需要の低迷によって低下基調が続いている。主要な輸出品は、石油・ガス（全輸出の12.4％を占め、うち天然ガスは6.9％、 原油は4.3％、石油製品は1.2％）、動物性または植物性油脂（14％）、電気機器・機械（10.45％）で、その他の輸出品としては、履物およびその付属品（3.4％）、 ニット以外の衣類（3％）、鉱石・スラグ・灰（2.5％）などがある。主要な輸出相手国は、米国（11.6％）、中国（10％）、日本（9.9％）、インド（8.8％）、シンガポール（7％）。',
  'friday_after_ascension_day.description:ja:DEFAULT': '',
  'friday_after_ascension_day.description:ja:Belgium': '',
  'french_and_german_leaders_meeting_on_greece.description:ja:DEFAULT': '',
  'french_and_german_leaders_meeting_on_greece.description:ja:Euro Area': '',
  'employment_benefits_qoq.description:ja:DEFAULT': '',
  'employment_benefits_qoq.description:ja:United States': '',
  'boe_cleland_speech.description:ja:DEFAULT': '',
  'boe_cleland_speech.description:ja:United Kingdom': '',
  'boj_interest_rate_decision.description:ja:DEFAULT': '',
  'boj_interest_rate_decision.description:ja:Japan':
    '日本では、金利は政策委員会による金融政策決定会合で決められる。日銀の公式金利は、基準割引率である。金融政策決定会合では、次の会合までの間の金融市場操作におけるガイドラインが決められ、このガイドラインは無担保コール翌日物金利の目標として定められる。',
  'private_spending_yoy.description:ja:DEFAULT': '',
  'private_spending_yoy.description:ja:Mexico':
    'メキシコでは、消費支出は居住者世帯による消費財とサービスの購入を指す。GDPの1つの構成要素である。',
  'wholesale_sales_mom.description:ja:DEFAULT': '',
  'wholesale_sales_mom.description:ja:Canada':
    '月次卸売統計は、カナダの卸売業者による月次売上高の推定値を測定するもので、卸売業の業況に関する情報を提供し、カナダ経済の状況の重要な指標となる。さらにビジネスコミュニティはこのデータを使って市場パフォーマンスを分析する。',
  'frankfurt_finance_summit_2017.description:ja:DEFAULT': '',
  'frankfurt_finance_summit_2017.description:ja:Germany': '',
  'fourth_day_of_lunar_new_year.description:ja:DEFAULT': '',
  'fourth_day_of_lunar_new_year.description:ja:Hong Kong': '',
  'consumer_inflation_expectations.description:ja:DEFAULT': '',
  'consumer_inflation_expectations.description:ja:Euro Area':
    'ユーロ圏では、消費者信頼感の価格トレンドは、全回答のうち、肯定的な回答の比率と否定的な回答の比率との差（バランス）として示される。',
  'consumer_inflation_expectations.description:ja:United Kingdom':
    '英国では、インフレ期待によって、向こう12カ月間における消費者の物価予想の中央値を測定する。。',
  'consumer_inflation_expectations.description:ja:Australia':
    'オーストラリアでは、メルボルン研究所が調査するインフレ期待によって、向こう12カ月間における消費者の物価予想の中央値を測定する。',
  'consumer_inflation_expectations.description:ja:United States':
    '米国では、インフレ期待は1年先のインフレ予想の中央値を指す。調査は全国を代表する約1,200人の世帯主を対象に、入れ替わり制でインターネットを通じて実施される。',
  'consumer_inflation_expectations.description:ja:Sweden':
    'スウェーデンでは、インフレ期待は1年先のインフレ予想の中央値を指す。',
  'foreign_securities_purchases.description:ja:DEFAULT': '',
  'foreign_securities_purchases.description:ja:Canada':
    'カナダ証券への外国からの投資には、債券、株式・投資ファンド持分が含まれる。取引はネットベースで記録される。',
  'snb_interest_rate_decison.description:ja:DEFAULT': '',
  'snb_interest_rate_decison.description:ja:Switzerland':
    'スイスではスイス国立銀行（SNB）が金利を決定し、公式金利はSNBの政策金利である。SNBは有担保短期スイスフラン建てマネーマーケット金利をSNBの政策金利近傍に維持することを目標としている。今日、これらの最も代表的な金利はSARONである。2019年６月13日時点で従来SNBが金融政策戦略に使用していた３カ月物スイスフランLIBOR（ロンドン銀行間取引金利）の目標レンジに代わり、SNB政策金利が採用されることになった。その理由はLIBORを決定するためのマネーマーケット取引が不足し、LIBORが最も重要な参照金利としての重要性を失いつつあったためである。2011年9月6日から2015年1月15日までの期間の主な注目点は最低為替レートとして1EUR＝1.20CHFをSNBが強制執行したことであった。2014年12月18日にSNBは要求払い預金金利をマイナス0.25％とすることを決定した。マイナス金利導入の発表に伴い、当時使われていたLIBORの目標レンジは初めてマイナス圏となった。2015年1月15日、SNBは要求払い預金金利をさらにマイナス0.75％に引き下げ、目標レンジをマイナス1.25％～マイナス0.25％の間とした。2015年1月22日以来マイナス金利が適用されており、現在SNBの政策金利に対応している。',
  'foreign_direct_investment_yoy.description:ja:DEFAULT': '',
  'foreign_direct_investment_yoy.description:ja:Indonesia':
    'インドネシアでは、外国直接投資とは、海外から受け入れた外国直接投資資金の流入を指す。FDIデータには、銀行や石油・ガスセクターへの投資は含まれていない。',
  'food_inflation_yoy.description:ja:DEFAULT': '',
  'food_inflation_yoy.description:ja:New Zealand':
    'ニュージーランドでは、食品価格指数（FPI）は、家計が食品に対して支払う価格の変化を測定する。',
  'rba_gov_kent_speech.description:ja:DEFAULT': '',
  'rba_gov_kent_speech.description:ja:Australia': '',
  'european_council_summit.description:ja:DEFAULT': '',
  'european_council_summit.description:ja:Euro Area': '',
  'cb_leading_index.description:ja:DEFAULT': '',
  'cb_leading_index.description:ja:Australia': '',
  'cb_leading_index.description:ja:United Kingdom': '',
  'financial_stability_report.description:ja:DEFAULT': '',
  'financial_stability_report.description:ja:United Kingdom': '',
  'financial_stability_report.description:ja:Sweden':
    'スウェーデンでは、ベンチマーク金利はスウェーデン中央銀行（リクスバンク）の執行委員会が決定する。主要金利は、7日間リクスバンクから銀行が融資を受けるか預金する際の金利であるレポ金利。リスクバンクはインフレ率をCPIF（金利一定の下での消費者物価指数）で年間2％程度に保つことを目標としている。',
  'financial_stability_report.description:ja:New Zealand': '',
  'statement_on_monetary_policy.description:ja:DEFAULT': '',
  'statement_on_monetary_policy.description:ja:Australia': '',
  'financial_accounts_of_the_us.description:ja:DEFAULT': '',
  'financial_accounts_of_the_us.description:ja:United States': '',
  'finance_minister_osborne_speech.description:ja:DEFAULT': '',
  'finance_minister_osborne_speech.description:ja:United Kingdom': '',
  'finance_minister_morneau_speech.description:ja:DEFAULT': '',
  'finance_minister_morneau_speech.description:ja:Canada': '',
  'budget_report.description:ja:DEFAULT': '',
  'budget_report.description:ja:United Kingdom': '',
  'fed_yellen_testimony_to_the_congress.description:ja:DEFAULT': '',
  'fed_yellen_testimony_to_the_congress.description:ja:United States': '',
  'human_rights_day.description:ja:DEFAULT': '',
  'human_rights_day.description:ja:South Africa': '',
  'federal_tax_revenues.description:ja:DEFAULT': '',
  'federal_tax_revenues.description:ja:Brazil':
    '歳入とは、税金、関税、国営企業からの収入、資本収入、海外からの支援などを含み、政府のすべての収入を指す。歳入は政府収支の一部である。',
  'fed_barkins_speech.description:ja:DEFAULT': '',
  'fed_barkins_speech.description:ja:United States':
    '米国では、金利設定の権限は連邦準備制度理事会（理事会）と連邦公開市場委員会（FOMC）に分けられている。理事会は、1つ以上の地区連邦準備銀行から提出された勧告を受けて、割引率の変更を決定する。FOMCは、中央銀行の資金量やフェデラルファンド市場金利などの公開市場操作を決定する。',
  'federal_fast_day.description:ja:DEFAULT': '',
  'federal_fast_day.description:ja:Switzerland': '',
  'nab_business_survey.description:ja:DEFAULT': '',
  'nab_business_survey.description:ja:Australia': '',
  'fed_press_conference.description:ja:DEFAULT': '',
  'fed_press_conference.description:ja:United States':
    '米国では、金利設定の権限は連邦準備制度理事会（理事会）と連邦公開市場委員会（FOMC）に分けられている。理事会は、1つ以上の地区連邦準備銀行から提出された勧告を受けて、割引率の変更を決定する。FOMCは、中央銀行の資金量やフェデラルファンド市場金利などの公開市場操作を決定する。',
  'fiscal_balance.description:ja:DEFAULT': '',
  'fiscal_balance.description:ja:Mexico':
    'メキシコの公的予算には、政府の歳出歳入だけでなく予算が間接的管理下にある企業の財務収支と、対外および対内資金調達要件が含まれる。',
  'fed_yellen_testymony_-_senate.description:ja:DEFAULT': '',
  'fed_yellen_testymony_-_senate.description:ja:United States': '',
  'fed_bullard_speaks.description:ja:DEFAULT': '',
  'fed_bullard_speaks.description:ja:United States': '',
  'fed_yellen_speech.description:ja:DEFAULT': '',
  'fed_yellen_speech.description:ja:United States': '',
  'the_king_s_birthday.description:ja:DEFAULT': '',
  'the_king_s_birthday.description:ja:Thailand': '',
  'fed_yellen_imf_s_lagarde_speech.description:ja:DEFAULT': '',
  'fed_yellen_imf_s_lagarde_speech.description:ja:United States': '',
  'civic_holiday.description:ja:DEFAULT': '',
  'civic_holiday.description:ja:Canada': '',
  'fed_quarles_testimony.description:ja:DEFAULT': '',
  'fed_quarles_testimony.description:ja:United States':
    '米国では、金利設定の権限は連邦準備制度理事会（理事会）と連邦公開市場委員会（FOMC）に分けられている。理事会は、1つ以上の地区連邦準備銀行から提出された勧告を受けて、割引率の変更を決定する。FOMCは、中央銀行の資金量やフェデラルファンド市場金利などの公開市場操作を決定する。',
  'ecb_monetary_policy_meeting_accounts.description:ja:DEFAULT': '',
  'ecb_monetary_policy_meeting_accounts.description:ja:Euro Area':
    'ユーロ圏の基準金利は欧州中央銀行（ECB）の理事会によって決定される。ECBの金融政策の主な目的は、物価の安定を維持することであり、それはインフレ率を中期的に2％未満でかつそれに近い水準に抑えることである。低インフレ・低金利が長期化した場合、ECBは資産購入プログラムなどの非標準的な金融政策を採用することもある。公式金利は、主要リファイナンスオペ金利である。。',
  'fed_quarles_speech.description:ja:DEFAULT': '',
  'fed_quarles_speech.description:ja:United States':
    '米国では、金利設定の権限は連邦準備制度理事会（理事会）と連邦公開市場委員会（FOMC）に分けられている。理事会は、1つ以上の地区連邦準備銀行から提出された勧告を受けて、割引率の変更を決定する。FOMCは、中央銀行の資金量やフェデラルファンド市場金利などの公開市場操作を決定する。',
  'employment_change_s_a.description:ja:DEFAULT': '',
  'employment_change_s_a.description:ja:Australia': '',
  'new_years_s_day.description:ja:DEFAULT': '',
  'new_years_s_day.description:ja:Japan': '',
  'fed_bank_stress_test_results.description:ja:DEFAULT': '',
  'fed_bank_stress_test_results.description:ja:United States':
    '米国では、金利設定の権限は連邦準備制度理事会（理事会）と連邦公開市場委員会（FOMC）に分けられている。理事会は、1つ以上の地区連邦準備銀行から提出された勧告を受けて、割引率の変更を決定する。FOMCは、中央銀行の資金量やフェデラルファンド市場金利などの公開市場操作を決定する。',
  'fed_mester_speech.description:ja:DEFAULT': '',
  'fed_mester_speech.description:ja:Euro Area':
    'ユーロ圏の基準金利は欧州中央銀行（ECB）の理事会によって決定される。ECBの金融政策の主な目的は、物価の安定を維持することであり、それはインフレ率を中期的に2％未満でかつそれに近い水準に抑えることである。低インフレ・低金利が長期化した場合、ECBは資産購入プログラムなどの非標準的な金融政策を採用することもある。公式金利は、主要リファイナンスオペ金利である。。',
  'fed_mester_speech.description:ja:United States':
    '米国では、金利設定の権限は連邦準備制度理事会（理事会）と連邦公開市場委員会（FOMC）に分けられている。理事会は、1つ以上の地区連邦準備銀行から提出された勧告を受けて、割引率の変更を決定する。FOMCは、中央銀行の資金量やフェデラルファンド市場金利などの公開市場操作を決定する。',
  'ash_wednesday.description:ja:DEFAULT': '',
  'ash_wednesday.description:ja:Brazil': '',
  'bba_mortgage_approvals.description:ja:DEFAULT': '',
  'bba_mortgage_approvals.description:ja:United Kingdom':
    '英国住宅ローン承認件数は、同国の住宅購入承認数を示しており、再ローンおよび他目的の融資は除外されている。データには、合計で同国の個人融資資産額の約2/3を占める、バークレー銀行、HSBC銀行、ロイズ・バンキング・グループ、ロイヤルバンク・オブ・スコットランド・グループ、サンタンデールUK、TSB およびバージン・マネーの消費者が含まれる。',
  'president_biden_remarks_on_the_economy.description:ja:DEFAULT': '',
  'president_biden_remarks_on_the_economy.description:ja:United States': '',
  'fed_meeting_under_expedited_procedures.description:ja:DEFAULT': '',
  'fed_meeting_under_expedited_procedures.description:ja:United States': '',
  'fed_lacker_speech.description:ja:DEFAULT': '',
  'fed_lacker_speech.description:ja:United States': '',
  'fed_kaplan_speech.description:ja:DEFAULT': '',
  'fed_kaplan_speech.description:ja:United States':
    '米国では、金利設定の権限は連邦準備制度理事会（理事会）と連邦公開市場委員会（FOMC）に分けられている。理事会は、1つ以上の地区連邦準備銀行から提出された勧告を受けて、割引率の変更を決定する。FOMCは、中央銀行の資金量やフェデラルファンド市場金利などの公開市場操作を決定する。',
  'boe_deputy_gov_bailey_speech.description:ja:DEFAULT': '',
  'boe_deputy_gov_bailey_speech.description:ja:United Kingdom': '',
  'fed_george_speech.description:ja:DEFAULT': '',
  'fed_george_speech.description:ja:United States':
    '米国では、金利設定の権限は連邦準備制度理事会（理事会）と連邦公開市場委員会（FOMC）に分けられている。理事会は、1つ以上の地区連邦準備銀行から提出された勧告を受けて、割引率の変更を決定する。FOMCは、中央銀行の資金量やフェデラルファンド市場金利などの公開市場操作を決定する。',
  'fed_fischer_speech.description:ja:DEFAULT': '',
  'fed_fischer_speech.description:ja:United States': '',
  'late_summer_bank_holiday.description:ja:DEFAULT': '',
  'late_summer_bank_holiday.description:ja:United Kingdom': '',
  'fed_dudley_speech.description:ja:DEFAULT': '',
  'fed_dudley_speech.description:ja:United States': '',
  'fed_clarida_speech.description:ja:DEFAULT': '',
  'fed_clarida_speech.description:ja:United States':
    '米国では、金利設定の権限は連邦準備制度理事会（理事会）と連邦公開市場委員会（FOMC）に分けられている。理事会は、1つ以上の地区連邦準備銀行から提出された勧告を受けて、割引率の変更を決定する。FOMCは、中央銀行の資金量やフェデラルファンド市場金利などの公開市場操作を決定する。',
  'fed_chairman_yellen_speaks.description:ja:DEFAULT': '',
  'fed_chairman_yellen_speaks.description:ja:United States': '',
  'aggregate_demand_qoq.description:ja:DEFAULT': '',
  'aggregate_demand_qoq.description:ja:Mexico': '',
  'fed_chairman_bernanke_speech.description:ja:DEFAULT': '',
  'fed_chairman_bernanke_speech.description:ja:United States': '',
  'reuters_econometer.description:ja:DEFAULT': '',
  'reuters_econometer.description:ja:South Africa': '',
  'fed_chairman_bernanke_speaks.description:ja:DEFAULT': '',
  'fed_chairman_bernanke_speaks.description:ja:United States': '',
  'fed_chair_powell_testimony.description:ja:DEFAULT': '',
  'fed_chair_powell_testimony.description:ja:United States':
    '米国では、金利設定の権限は連邦準備制度理事会（理事会）と連邦公開市場委員会（FOMC）に分けられている。理事会は、1つ以上の地区連邦準備銀行から提出された勧告を受けて、割引率の変更を決定する。FOMCは、中央銀行の資金量やフェデラルファンド市場金利などの公開市場操作を決定する。',
  '6-month_letras_auction.description:ja:DEFAULT': '',
  '6-month_letras_auction.description:ja:Spain': '',
  'fed_chair_powell_speech.description:ja:DEFAULT': '',
  'fed_chair_powell_speech.description:ja:United States':
    '米国では、金利設定の権限は連邦準備制度理事会（理事会）と連邦公開市場委員会（FOMC）に分けられている。理事会は、1つ以上の地区連邦準備銀行から提出された勧告を受けて、割引率の変更を決定する。FOMCは、中央銀行の資金量やフェデラルファンド市場金利などの公開市場操作を決定する。',
  'fed_bowman_testimony.description:ja:DEFAULT': '',
  'fed_bowman_testimony.description:ja:United States': '',
  'fed_brainard_testimony.description:ja:DEFAULT': '',
  'fed_brainard_testimony.description:ja:United States': '',
  'fed_bostic_speech.description:ja:DEFAULT': '',
  'fed_bostic_speech.description:ja:United States':
    '米国では、金利設定の権限は連邦準備制度理事会（理事会）と連邦公開市場委員会（FOMC）に分けられている。理事会は、1つ以上の地区連邦準備銀行から提出された勧告を受けて、割引率の変更を決定する。FOMCは、中央銀行の資金量やフェデラルファンド市場金利などの公開市場操作を決定する。',
  'fed_bernanke_speech.description:ja:DEFAULT': '',
  'fed_bernanke_speech.description:ja:United States': '',
  'fed_barkin_speech.description:ja:DEFAULT': '',
  'fed_barkin_speech.description:ja:United States':
    '米国では、金利設定の権限は連邦準備制度理事会（理事会）と連邦公開市場委員会（FOMC）に分けられている。理事会は、1つ以上の地区連邦準備銀行から提出された勧告を受けて、割引率の変更を決定する。FOMCは、中央銀行の資金量やフェデラルファンド市場金利などの公開市場操作を決定する。',
  'central_bank_reserves.description:ja:DEFAULT': '',
  'central_bank_reserves.description:ja:Russia': '',
  'fed_bank_stress_test_results_2nd_round.description:ja:DEFAULT': '',
  'fed_bank_stress_test_results_2nd_round.description:ja:United States': '',
  'boe_haldane_speech.description:ja:DEFAULT': '',
  'boe_haldane_speech.description:ja:United Kingdom':
    'イギリスの金融政策委員会（MPC）が基準利率を設定する。中央銀行であるイングランド銀行の公式金利はレポ金利である。このレポ金利は、イングランド銀行の取引相手（銀行、住宅金融組合、証券会社）に対する公開市場操作での利率となる。',
  'constitution_day.description:ja:DEFAULT': '',
  'constitution_day.description:ja:Thailand': '',
  'constitution_day.description:ja:Denmark': '',
  'constitution_day.description:ja:Spain': '',
  'constitution_day.description:ja:Mexico': '',
  'constitution_day.description:ja:Japan': '',
  'constitution_day.description:ja:Poland': '',
  'constitution_day.description:ja:Norway': '',
  'swiss_national_day.description:ja:DEFAULT': '',
  'swiss_national_day.description:ja:Switzerland': '',
  'fed_chairman_bernanke_testifies.description:ja:DEFAULT': '',
  'fed_chairman_bernanke_testifies.description:ja:United States': '',
  'chulalongkorn_day.description:ja:DEFAULT': '',
  'chulalongkorn_day.description:ja:Thailand': '',
  'loan_prime_rate_1y.description:ja:DEFAULT': '',
  'loan_prime_rate_1y.description:ja:China':
    '中国人民銀行（PBOC）は2019年8月17日、中央銀行の基準金利である1年物貸出金利に代わり、ローンプライムレート（LPR）を家計や企業への新規銀行融資の新たな貸出ベンチマークに指定した。 この金利は、18の商業銀行からの貸出金利の加重平均に基づいている。これらの商業銀行は、公開市場操作でPBOCの流動性に対して入札した金額に基づいたLPR見積書を、毎月20日午前9時（中国標準時）までに全国銀行間資金調達センターに提出する。',
  'gdp_growth_annualized_3rd_est.description:ja:DEFAULT': '',
  'gdp_growth_annualized_3rd_est.description:ja:Israel':
    'GDP年率成長率は、前四半期比のGDP成長率が1年を通じて維持されたと仮定した場合の成長率を示す。',
  'feast_of_tabernacles_eve.description:ja:DEFAULT': '',
  'feast_of_tabernacles_eve.description:ja:Israel': '',
  'feast_of_tabernacles.description:ja:DEFAULT': '',
  'feast_of_tabernacles.description:ja:Israel': '',
  'father_s_day.description:ja:DEFAULT': '',
  'father_s_day.description:ja:Thailand': '',
  'employment_change_qoq_final.description:ja:DEFAULT': '',
  'employment_change_qoq_final.description:ja:Euro Area':
    'ユーロ圏では雇用変化は、賃金または利益のために働く者と無給家族労働者の数の前四半期比変化を指し、推定値にはフルタイムとパートタイム双方が含まれる。',
  'riksbank_ingves_speaks_before_parliament.description:ja:DEFAULT': '',
  'riksbank_ingves_speaks_before_parliament.description:ja:Sweden': '',
  'manufacturing_prod_mom.description:ja:DEFAULT': '',
  'manufacturing_prod_mom.description:ja:South Africa':
    '南アフリカでは鉱工業生産は製造業、鉱業、公益事業など経済の鉱工業セクターに属する事業の生産量を測定する。',
  'fast_day.description:ja:DEFAULT': '',
  'fast_day.description:ja:Israel': '',
  'factory_utilization.description:ja:DEFAULT': '',
  'factory_utilization.description:ja:Brazil':
    'ブラジルでは稼働率は、通常の運転条件下における生産能力に対して製造業で使用されている稼働率（最大能力に対する比率）を指す。',
  'bali_s_day_of_silence_and_hindu_new_year.description:ja:DEFAULT': '',
  'bali_s_day_of_silence_and_hindu_new_year.description:ja:Indonesia': '',
  'ism_non-manufacturing_business_activity.description:ja:DEFAULT': '',
  'ism_non-manufacturing_business_activity.description:ja:United States':
    'ISM非製造業景気指数（Non-Manufacturing ISM Report On Business®）は、全国の購買および供給担当幹部からのアンケート調査回答に基づいて集計され、前月と比較した当月の変化があればそれが反映される。事業活動、新規受注、受注残、新規輸出受注、輸入、生産、サプライヤー納期、在庫、在庫見通し、輸入、価格、雇用、サプライヤー納期の各指標について、回答の割合、およびディフュージョン・インデックスが示される。50％を上回るとその非製造業の景気が全体的に拡大していることを、50％を切る場合は縮小していることを示す。サービス提供者への注文は、米国経済の約90%を占めている。',
  'factory_orders_yoy.description:ja:DEFAULT': '',
  'factory_orders_yoy.description:ja:Germany': '',
  'fomc_policy_statement_and_press_conference.description:ja:DEFAULT': '',
  'fomc_policy_statement_and_press_conference.description:ja:United States': '',
  'fomc_minutes.description:ja:DEFAULT': '',
  'fomc_minutes.description:ja:United States':
    '米国では、金利設定の権限は連邦準備制度理事会（理事会）と連邦公開市場委員会（FOMC）に分けられている。理事会は、1つ以上の地区連邦準備銀行から提出された勧告を受けて、割引率の変更を決定する。FOMCは、中央銀行の資金量やフェデラルファンド市場金利などの公開市場操作を決定する。',
  'fomc_member_yellen_speech.description:ja:DEFAULT': '',
  'fomc_member_yellen_speech.description:ja:United States': '',
  'fgv_consumer_confidence.description:ja:DEFAULT': '',
  'fgv_consumer_confidence.description:ja:Brazil':
    'IBRE/FGVの月次消費者信頼感指数は、経済全体および個人の財務状況に対する消費者のセンチメントを測定することを目的としている。満足している/楽観的な消費者は消費を増やす可能性が高く、不満足/悲観的な消費者は消費を減らす可能性が高いため、消費者信頼感は経済成長に影響する。消費者信頼感をモニターすることで、目先の経済状況予測に役立つ将来の消費や貯蓄に関するシグナルを把握できる。IBRE/FGV調査は、調査時点とその後数カ月における地方と家計の経済状況に関する消費者の判断と先行き判断や、雇用見通し、今後６カ月間の高価値財の購入意図・可能性など、様々な情報を測定する。消費者信頼感調査は2002年に開始し、ブラジルの７州都（ベロオリゾンテ、ブラジリア、ポルトアレグレ、レシフェ、サルバドール、リオデジャネイロ、サンパウロ）で2,000人からデータを収集している。',
  'european_council_meeting.description:ja:DEFAULT': '',
  'european_council_meeting.description:ja:Euro Area': '',
  'flash_gdp_forecast.description:ja:DEFAULT': '',
  'flash_gdp_forecast.description:ja:Canada': '',
  'exports_prel.description:ja:DEFAULT': '',
  'exports_prel.description:ja:India':
    '近年、インドの主な輸出品目は、真珠、貴石、半貴石、宝飾品（全体の16％）、鉱物燃料、油脂、ワックス、瀝青物質（12％）、自動車、部品、付属品（5％）、原子炉、ボイラー、機械、機械器具（5％）、医薬品（5％）、有機化学品（4％）である。インドの主な輸出相手国は 米国（総輸出の15%）、アラブ首長国連邦（11%）、香港（5%）、中国（4%）、シンガポール（4%）、英国（3%）。',
  'treasury_select_committee_hearing.description:ja:DEFAULT': '',
  'treasury_select_committee_hearing.description:ja:United Kingdom': '',
  'exports_mom_s_a.description:ja:DEFAULT': '',
  'exports_mom_s_a.description:ja:Germany': '',
  'ecofin_meeting_on_greece.description:ja:DEFAULT': '',
  'ecofin_meeting_on_greece.description:ja:Euro Area': '',
  'exports_mom.description:ja:DEFAULT': '',
  'exports_mom.description:ja:Germany':
    'ドイツは世界第3位の輸出大国で、生産高全体の約半分近くを輸出が占める。2017年時点でドイツの主要な輸出品は、自動車、トレーダー、シミトレーラー（全輸出の18%）、機械・設備（14%）、化学薬品・化学製品（9%）、コンピューター、電子および光学製品（9%）、電気機器（6%）、 基礎医薬品および製剤（6%）、その他の輸送機器（5%）、食品（4%）、ベースメタル（4%）、、ゴム・プラスチック製品（4%）。主な輸出相手国は米国（全輸出の9%）、フランス（8%）、中国（7%）、英国（7%）、オランダ（7%）、イタリア（5%）、オーストリア（5%）、ポーランド（5%）、スイス（4%）、ベルギー（3%）、スペイン（3%）、チェコ共和国（3%）。',
  'exports_mom.description:ja:Australia':
    '天然資源に恵まれたオーストラリアはコモディティの大輸出国で、全輸出のうち、金属含有鉱石と金属スクラップが29%、石炭、コークス、練炭は15%、天然ガスは7%を占める。その他、食品および家畜（14%）（主に肉類［5%］とシリアル［4%］）、工業製品（6%）（主に非鉄金属［4%］）、機械および輸送機器（6%）、が主な輸出品目である。オーストラリアにとって主要な輸出相手国は、 中国（全輸出の32%）、日本（16%）、韓国（7%）、米国（5%）、インド（4%）、ニュージーランド（3%）、シンガポール（3%）、台湾（3%）。',
  'export_prices_yoy.description:ja:DEFAULT': '',
  'export_prices_yoy.description:ja:Finland':
    'フィンランドでは、輸出物価指数は、フィンランドの居住者が国外の買い手に販売する商品やサービスの価格の変化率に相当する。輸出価格は為替レートの影響を大きく受ける。',
  'export_prices_yoy.description:ja:South Korea':
    '韓国では、輸出物価指数は、韓国の居住者が国外の買い手に販売する商品やサービスの価格の変化率に相当する。輸出価格は為替レートの影響を大きく受ける。',
  'export_prices_yoy.description:ja:Singapore':
    'シンガポールでは、輸出物価指数は、シンガポールの居住者が国外の買い手に販売する商品やサービスの価格の変化率に相当する。輸出価格は為替レートの影響を大きく受ける。',
  'export_prices_yoy.description:ja:United States':
    '米国では、輸出物価指数は、米国の居住者が国外の買い手に販売する商品やサービスの価格の変化率に相当する。輸出価格は為替レートの影響を大きく受ける。',
  'european_council_meeting.description:ja:European Union': '',
  'export_prices_qoq.description:ja:DEFAULT': '',
  'export_prices_qoq.description:ja:New Zealand':
    'ニュージーランドでは、輸出物価指数は、ニュージーランドの居住者が国外の買い手に販売する商品やサービスの価格の変化率に相当する。輸出価格は為替レートの影響を大きく受ける。',
  'export_prices_qoq.description:ja:Australia':
    'オーストラリアでは、輸出物価指数は、オーストラリアの居住者が国外の買い手に販売する商品やサービスの価格の変化率に相当する。輸出価格は為替レートの影響を大きく受ける。',
  'ambedkar_jayanti.description:ja:DEFAULT': '',
  'ambedkar_jayanti.description:ja:India': '',
  'money_supply_m3.description:ja:DEFAULT': '',
  'money_supply_m3.description:ja:Hong Kong':
    '香港のマネーサプライM3は、M2に銀行の長期的な定期預金を加算したものである。',
  'export_prices_mom.description:ja:DEFAULT': '',
  'export_prices_mom.description:ja:United States':
    '米国では、輸出物価指数は、米国の居住者が国外の買い手に販売する商品やサービスの価格の変化率に相当する。輸出価格は為替レートの影響を大きく受ける。',
  'eurozone_leaders_meeting.description:ja:DEFAULT': '',
  'eurozone_leaders_meeting.description:ja:Euro Area': '',
  'european_union_membership_referendum.description:ja:DEFAULT': '',
  'european_union_membership_referendum.description:ja:United Kingdom': '',
  'average_hourly_earnings_mom.description:ja:DEFAULT': '',
  'average_hourly_earnings_mom.description:ja:United States':
    '米国では、平均時給は特定月に従業員が1時間当たりに稼ぐ平均賃金である。FRBは金利を引き上げるか引き下げるかを判断する際、平均時給を考慮する。',
  'european_commission_hill_speech.description:ja:DEFAULT': '',
  'european_commission_hill_speech.description:ja:Euro Area': '',
  'european_commission_growth_forecasts.description:ja:DEFAULT': '',
  'european_commission_growth_forecasts.description:ja:Euro Area': '',
  'makha_bucha.description:ja:DEFAULT': '',
  'makha_bucha.description:ja:Thailand': '',
  'european_commission_forecasts.description:ja:DEFAULT': '',
  'european_commission_forecasts.description:ja:European Union': '',
  'european_commission_forecasts.description:ja:Euro Area': '',
  'eurogroup_video_conference.description:ja:DEFAULT': '',
  'eurogroup_video_conference.description:ja:Euro Area': '',
  'european_business_summit.description:ja:DEFAULT': '',
  'european_business_summit.description:ja:Euro Area': '',
  'goods_trade_balance.description:ja:DEFAULT': '',
  'goods_trade_balance.description:ja:United Kingdom':
    '英国では、財貨の輸出額から財貨の輸入額を差し引いたものが商品貿易収支である。',
  'goods_trade_balance.description:ja:United States':
    '米国では、財の輸出額から財の輸入額を差し引いたものが財貿易収支である。',
  'euro_zone_leaders_summit_on_greece.description:ja:DEFAULT': '',
  'euro_zone_leaders_summit_on_greece.description:ja:Euro Area': '',
  'euro_summit.description:ja:DEFAULT': '',
  'euro_summit.description:ja:European Union': '',
  'euro_summit.description:ja:Euro Area': '',
  '3-year_btan_auction.description:ja:DEFAULT': '',
  '3-year_btan_auction.description:ja:France': '',
  'anz_job_advertisements.description:ja:DEFAULT': '',
  'anz_job_advertisements.description:ja:Australia': '',
  'euro_finance_week_2017.description:ja:DEFAULT': '',
  'euro_finance_week_2017.description:ja:Germany': '',
  'establishment_day.description:ja:DEFAULT': '',
  'establishment_day.description:ja:Hong Kong': '',
  'international_women_s_day_holiday.description:ja:DEFAULT': '',
  'international_women_s_day_holiday.description:ja:Russia': '',
  'epiphany.description:ja:DEFAULT': '',
  'epiphany.description:ja:Colombia': '',
  'epiphany.description:ja:Spain': '',
  'epiphany.description:ja:Italy': '',
  'epiphany.description:ja:Sweden': '',
  'epiphany.description:ja:Austria': '',
  'epiphany.description:ja:Poland': '',
  'epiphany.description:ja:Finland': '',
  'epiphany.description:ja:Germany': '',
  'epiphany.description:ja:Greece': '',
  'end_of_ramadan.description:ja:DEFAULT': '',
  'end_of_ramadan.description:ja:Singapore': '',
  'end_of_ramadan.description:ja:India': '',
  'end_of_ramadan.description:ja:Philippines': '',
  'end_of_ramadan.description:ja:Indonesia': '',
  'gdp_growth_annualized_qoq_final.description:ja:DEFAULT': '',
  'gdp_growth_annualized_qoq_final.description:ja:Japan': '',
  'employment_cost_index_qoq.description:ja:DEFAULT': '',
  'employment_cost_index_qoq.description:ja:United States':
    '雇用コスト指数（ECI）は被雇用者への報酬（賃金と福利厚生）の伸び率を測定する。ECIは各四半期の最終月における雇用主の給与支払調査に基づき、企業のあらゆるレベルの社員に関し、賃金、福利厚生、賞与などを含む労働コストの推移を追跡する。',
  'hm_the_king_s_memorial_day.description:ja:DEFAULT': '',
  'hm_the_king_s_memorial_day.description:ja:Thailand': '',
  'employment_change.description:ja:DEFAULT': '',
  'employment_change.description:ja:Canada':
    'カナダでは雇用者数変化は、賃金または利益のために働く者と無給家族労働者の数の変化を指し、推定値にはフルタイムとパートタイム双方が含まれる。',
  'employment_change.description:ja:United Kingdom':
    '英国では雇用者数変化は、16歳以上の雇用者数の3カ月移動平均を前期と比較して測定する。',
  'employment_change.description:ja:Australia':
    'オーストラリアでは雇用者数変化は、賃金または利益のために働く者と無給家族労働者の数の絶対的な変化を指し、推定値にはフルタイムとパートタイム双方が含まれる。',
  'rba_kearns_speech.description:ja:DEFAULT': '',
  'rba_kearns_speech.description:ja:Australia':
    'オーストラリアでは金利決定はオーストラリア準備銀行理事会が行い、政策金利はキャッシュレートである。キャッシュレートとは、金融機関の間で翌日物貸付に適用される利率で、翌日物資金の需要と供給によってマネーマーケットで決定される。',
  'employed_persons.description:ja:DEFAULT': '',
  'employed_persons.description:ja:Germany':
    'ドイツでは、被雇用者は、企業に一定期間勤務する最低就業年齢以上の個人を指す。',
  'electronic_retail_card_spending_yoy.description:ja:DEFAULT': '',
  'electronic_retail_card_spending_yoy.description:ja:New Zealand':
    'ニュージランドでは、電子カード小売売上高は、消耗品、耐久財、ホスピタリティ、アパレル、自動車燃料に対する個人のクレジットカード支出を測定する。電子カード取引データには、ニュージーランドを拠点とするマーチャントのデビットカード、クレジットカード、チャージカード取引すべてが含まれ、消費支出と経済活動の変化を捉えるために使用できる。',
  'boe_mccafferty_speech.description:ja:DEFAULT': '',
  'boe_mccafferty_speech.description:ja:United Kingdom': '',
  'immaculate_conception.description:ja:DEFAULT': '',
  'immaculate_conception.description:ja:Colombia': '',
  'immaculate_conception.description:ja:Austria': '',
  'immaculate_conception.description:ja:Italy': '',
  'immaculate_conception.description:ja:Portugal': '',
  'immaculate_conception.description:ja:Spain': '',
  'electronic_retail_card_spending_mom.description:ja:DEFAULT': '',
  'electronic_retail_card_spending_mom.description:ja:New Zealand':
    'ニュージランドでは、電子カード小売売上高は、消耗品、耐久財、ホスピタリティ、アパレル、自動車燃料に対する個人のクレジットカード支出を測定する。電子カード取引データには、ニュージーランドを拠点とするマーチャントのデビットカード、クレジットカード、チャージカード取引すべてが含まれ、消費支出と経済活動の変化を捉えるために使用できる。',
  '5-year_tips_auction.description:ja:DEFAULT': '',
  '5-year_tips_auction.description:ja:United States': '',
  'pm_conte_press_conference.description:ja:DEFAULT': '',
  'pm_conte_press_conference.description:ja:Italy': '',
  'industrial_inventories_qoq.description:ja:DEFAULT': '',
  'industrial_inventories_qoq.description:ja:Sweden':
    'スウェーデンでは、鉱工業在庫は鉱山・採石場と製造業の在庫の前四半期比変化を測定する。',
  'election_day.description:ja:DEFAULT': '',
  'election_day.description:ja:Philippines': '',
  'aggregate_demand_yoy.description:ja:DEFAULT': '',
  'aggregate_demand_yoy.description:ja:Mexico': '',
  'tankan_large_manufacturers_index.description:ja:DEFAULT': '',
  'tankan_large_manufacturers_index.description:ja:Japan':
    '日本銀行が発表する全国企業短期経済観測調査（短観）の大企業・製造業業況判断指数は、資本金10億円以上の約1,100社をカバーしている。調査は郵便またはEメールで行われ、参加者は足元の経済環境や企業と業界の業況、3カ月先および1年先の企業活動の予想について、評価を行うよう求められる。指数は、「好ましい」回答を選んだ企業の割合から、「好ましくない」回答を選択した企業の割合を差し引いて算出される。指数はプラス100からマイナス100の範囲で変動し、数値がゼロを超えれば景気に対して楽観的、ゼロを下回れば悲観的、ゼロであれば中立と判断される。',
  'october_bank_holiday.description:ja:DEFAULT': '',
  'october_bank_holiday.description:ja:Ireland': '',
  'economic_sentiment_index.description:ja:DEFAULT': '',
  'economic_sentiment_index.description:ja:Switzerland':
    'スイスでは、CS-CFA社会景況感指数がアナリストによる今後6カ月の経済成長に対する楽観度を測定する。この調査では、最大350名の金融および経済アナリストが対象となる。指数は、経済成長に関する楽観視と悲観視のパーセンテージの差として表される。従って、ZEW指標はマイナス100（すべてのアナリストが景気悪化を予測）とプラス100（すべてのアナリストが景気改善を予測）の間の値となる。0は中立的な見通しであることを示す。',
  'economic_bulletin.description:ja:DEFAULT': '',
  'economic_bulletin.description:ja:Euro Area': '',
  'economic_activity_yoy.description:ja:DEFAULT': '',
  'economic_activity_yoy.description:ja:Portugal':
    'ポルトガルでは、経済活動の前年比とは、ポルトガルの経済活動に関する8つの主要指標（実質GDP、小売売販売量（小売業界調査）、大型商用車販売台数、セメント売上高、鉱工業生産、家計の経済状況（消費者調査）、新規求人数、外部環境代理指数）を含む一致指数の年間変化を指す。',
  'economic_activity_yoy.description:ja:Mexico':
    'メキシコでは経済がどの方向に向かっているか、先行指数が初期のシグナルを示す。先行指数は、製造業における雇用トレンド、原油以外の輸出、実質ベースのメキシコ株価指数、実質為替レート、銀行間金利、S&P500 指数を集計したもので、基準値は2008年を100とする。',
  'ecofin_summit_on_greece.description:ja:DEFAULT': '',
  'ecofin_summit_on_greece.description:ja:Euro Area': '',
  'easter_saturday.description:ja:DEFAULT': '',
  'easter_saturday.description:ja:Australia': '',
  'boe_churm_speech.description:ja:DEFAULT': '',
  'boe_churm_speech.description:ja:United Kingdom': '',
  'easter_monday.description:ja:DEFAULT': '',
  'easter_monday.description:ja:Switzerland': '',
  'easter_monday.description:ja:New Zealand': '',
  'easter_monday.description:ja:Italy': '',
  'easter_monday.description:ja:Ireland': '',
  'easter_monday.description:ja:United Kingdom': '',
  'easter_monday.description:ja:Czech Republic': '',
  'easter_monday.description:ja:Norway': '',
  'easter_monday.description:ja:Belgium': '',
  'easter_monday.description:ja:France': '',
  'easter_monday.description:ja:Poland': '',
  'easter_monday.description:ja:Austria': '',
  'easter_monday.description:ja:Netherlands': '',
  'easter_monday.description:ja:Australia': '',
  'easter_monday.description:ja:Hong Kong': '',
  'easter_monday.description:ja:Denmark': '',
  'easter_monday.description:ja:Finland': '',
  'easter_monday.description:ja:Sweden': '',
  'easter_monday.description:ja:Germany': '',
  'easter_monday.description:ja:Hungary': '',
  'zew_expectations.description:ja:DEFAULT': '',
  'zew_expectations.description:ja:Switzerland': '',
  'eu-china_summit.description:ja:DEFAULT': '',
  'eu-china_summit.description:ja:European Union': '',
  'eu-china_summit.description:ja:Euro Area': '',
  'eu-china_summit.description:ja:Belgium': '',
  'eu-china_summit.description:ja:China': '',
};
