import { useEconCalendarParams } from '../../hooks/use-econ-calendar-params';

export default function MarketOpen() {
  const { currentTheme } = useEconCalendarParams();
  const fillColor = currentTheme === 'dark' ? '#fff' : '#11203E';
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1260_11956)">
        <circle cx="8" cy="8" r="3.75" fill="#5A5E5F" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.67717 11.4121C4.97951 11.7144 4.97951 12.2046 4.67717 12.507L3.21734 13.9668C2.915 14.2691 2.42481 14.2691 2.12246 13.9668C1.82012 13.6645 1.82012 13.1743 2.12246 12.8719L3.5823 11.4121C3.88464 11.1098 4.37483 11.1098 4.67717 11.4121Z"
          fill="#5A5E5F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.00076 16C7.57318 16 7.22656 15.6534 7.22656 15.2258V13.1613C7.22656 12.7337 7.57318 12.3871 8.00076 12.3871C8.42833 12.3871 8.77495 12.7337 8.77495 13.1613V15.2258C8.77495 15.6534 8.42833 16 8.00076 16Z"
          fill="#5A5E5F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.3228 11.4121C11.0205 11.7144 11.0205 12.2046 11.3228 12.507L12.7827 13.9668C13.085 14.2691 13.5752 14.2691 13.8775 13.9668C14.1799 13.6645 14.1799 13.1743 13.8775 12.8719L12.4177 11.4121C12.1154 11.1098 11.6252 11.1098 11.3228 11.4121Z"
          fill="#5A5E5F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.3867 8.00002C12.3867 7.57245 12.7333 7.22583 13.1609 7.22583H15.2254C15.653 7.22583 15.9996 7.57245 15.9996 8.00002C15.9996 8.4276 15.653 8.77422 15.2254 8.77422H13.1609C12.7333 8.77422 12.3867 8.4276 12.3867 8.00002Z"
          fill="#5A5E5F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.3228 4.67647C11.0205 4.37412 11.0205 3.88393 11.3228 3.58159L12.7827 2.12176C13.085 1.81942 13.5752 1.81942 13.8775 2.12176C14.1799 2.4241 14.1799 2.91429 13.8775 3.21663L12.4177 4.67647C12.1154 4.97881 11.6252 4.97881 11.3228 4.67647Z"
          fill="#5A5E5F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.67717 4.67647C4.97951 4.37412 4.97951 3.88393 4.67717 3.58159L3.21734 2.12176C2.915 1.81942 2.42481 1.81942 2.12246 2.12176C1.82012 2.4241 1.82012 2.91429 2.12246 3.21663L3.5823 4.67647C3.88464 4.97881 4.37483 4.97881 4.67717 4.67647Z"
          fill="#5A5E5F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.00076 3.61292C7.57318 3.61292 7.22656 3.2663 7.22656 2.83872V0.774205C7.22656 0.34663 7.57318 1.19209e-05 8.00076 1.19209e-05C8.42833 1.19209e-05 8.77495 0.34663 8.77495 0.774205V2.83872C8.77495 3.2663 8.42833 3.61292 8.00076 3.61292Z"
          fill="#5A5E5F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 8.00002C0 7.57245 0.346618 7.22583 0.774194 7.22583H2.83871C3.26628 7.22583 3.6129 7.57245 3.6129 8.00002C3.6129 8.4276 3.26628 8.77422 2.83871 8.77422H0.774194C0.346618 8.77422 0 8.4276 0 8.00002Z"
          fill="#5A5E5F"
        />
      </g>
      <defs>
        <clipPath id="clip0_1260_11956">
          <rect width="16" height="16" fill={fillColor} />
        </clipPath>
      </defs>
    </svg>
  );
}
