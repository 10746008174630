import { useEconCalendarParams } from '../../hooks/use-econ-calendar-params';

const NoEventsMobileScreen = () => {
  const { currentTheme } = useEconCalendarParams();
  const fillColor = currentTheme === 'dark' ? '#8292a6' : '#5a5e5f';
  return (
    <svg width="73" height="72" viewBox="0 0 73 72" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M45.8877 41.6375H43.9127L43.2127 40.9625C45.6627 38.1125 47.1377 34.4125 47.1377 30.3875C47.1377 21.4125 39.8627 14.1375 30.8877 14.1375C21.9127 14.1375 14.6377 21.4125 14.6377 30.3875C14.6377 39.3625 21.9127 46.6375 30.8877 46.6375C34.9127 46.6375 38.6127 45.1625 41.4627 42.7125L42.1377 43.4125V45.3875L54.6377 57.8625L58.3627 54.1375L45.8877 41.6375ZM30.8877 41.6375C24.6627 41.6375 19.6377 36.6125 19.6377 30.3875C19.6377 24.1625 24.6627 19.1375 30.8877 19.1375C37.1127 19.1375 42.1377 24.1625 42.1377 30.3875C42.1377 36.6125 37.1127 41.6375 30.8877 41.6375Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default NoEventsMobileScreen;
