import { useEconCalendarParams } from '../../hooks/use-econ-calendar-params';

export default function CalendarIcon() {
  const { currentTheme } = useEconCalendarParams();
  const fillColor = currentTheme === 'dark' ? '#fff' : '#11203E';
  return (
    <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.31815 0.521739V2.08696C4.31815 2.37511 4.07395 2.6087 3.7727 2.6087C3.47145 2.6087 3.22725 2.37511 3.22725 2.08696V0.521739C3.22725 0.233591 3.47145 0 3.7727 0C4.07395 0 4.31815 0.233591 4.31815 0.521739ZM12.5 2.08694V10.9565C12.5 11.5328 12.0116 12 11.4091 12H1.59091C0.988417 12 0.5 11.5328 0.5 10.9565V2.08694C0.5 1.51065 0.988417 1.04347 1.59091 1.04347H2.40909C2.55971 1.04347 2.68182 1.16026 2.68182 1.30433V2.04213C2.67228 2.59062 3.09651 3.05779 3.66563 3.12552C3.97211 3.15439 4.27708 3.05802 4.50527 2.8602C4.73346 2.66238 4.86363 2.38152 4.86364 2.08694V1.30433C4.86364 1.16026 4.98574 1.04347 5.13636 1.04347H7.86364C8.01426 1.04347 8.13636 1.16026 8.13636 1.30433V2.04213C8.12683 2.5906 8.55103 3.05776 9.12012 3.12552C9.42661 3.1544 9.7316 3.05805 9.9598 2.86022C10.188 2.6624 10.3182 2.38153 10.3182 2.08694V1.30433C10.3182 1.16026 10.4403 1.04347 10.5909 1.04347H11.4091C12.0116 1.04347 12.5 1.51065 12.5 2.08694ZM11.1364 5.21739H1.86363C1.713 5.21739 1.5909 5.33418 1.5909 5.47826V10.6956C1.5909 10.8397 1.713 10.9565 1.86363 10.9565H11.1364C11.287 10.9565 11.4091 10.8397 11.4091 10.6956V5.47826C11.4091 5.33418 11.287 5.21739 11.1364 5.21739ZM9.22725 0C9.5285 0 9.77271 0.233591 9.77271 0.521739V2.08696C9.77271 2.37511 9.5285 2.6087 9.22725 2.6087C8.926 2.6087 8.6818 2.37511 8.6818 2.08696V0.521739C8.6818 0.233591 8.926 0 9.22725 0Z"
        fill={fillColor}
      />
    </svg>
  );
}
