import { useEconCalendarParams } from '../../hooks/use-econ-calendar-params';

export default function SearchExpandIcon() {
  const { currentTheme } = useEconCalendarParams();
  const fillColor = currentTheme === 'dark' ? '#fff' : '#11203E';
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.5 26H26.71L26.43 25.73C27.41 24.59 28 23.11 28 21.5C28 17.91 25.09 15 21.5 15C17.91 15 15 17.91 15 21.5C15 25.09 17.91 28 21.5 28C23.11 28 24.59 27.41 25.73 26.43L26 26.71V27.5L31 32.49L32.49 31L27.5 26ZM21.5 26C19.01 26 17 23.99 17 21.5C17 19.01 19.01 17 21.5 17C23.99 17 26 19.01 26 21.5C26 23.99 23.99 26 21.5 26Z"
        fill={fillColor}
      />
    </svg>
  );
}
