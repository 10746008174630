const PlaceholderFlag = () => {
  return (
    <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.22208 0.000976562C0.994938 0.000976562 0 0.965301 0 2.15469V11.8464C0 13.0358 0.994938 14.0001 2.22208 14.0001H5.55521V0.000976562H2.22208ZM17.7767 0.000976562H14.4435V14.0001H17.7767C19.0038 14.0001 19.9988 13.0358 19.9988 11.8464V2.15469C19.9988 0.965301 19.0038 0.000976562 17.7767 0.000976562Z"
        fill="#EEEEEE"
      />
      <path d="M3 0H17V14H3V0Z" fill="#EEEEEE" />
      <path
        d="M10.5 4.83341L10.1667 4.16675H7.5V9.83341H8.16667V7.50008H9.83333L10.1667 8.16675H12.5V4.83341H10.5ZM11.8333 7.50008H10.5L10.1667 6.83341H8.16667V4.83341H9.83333L10.1667 5.50008H11.8333V7.50008Z"
        fill="#6F6F6F"
      />
    </svg>
  );
};

export default PlaceholderFlag;
