import { useEconCalendar } from '../hooks/useEconCalendar';
import EconCalendarTableRows from './EconCalendarTableRows';
import { convertDateToString } from '../utils/util';
import { useEconCalendarParams } from '../hooks/use-econ-calendar-params';
import style from '../styles/EconCalendarTableData.module.css';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import useScreenDimensions from '../hooks/useScreenDimension';
import EarningsMobileHeadings from './EarningsMobileHeadings';
import { useSessionData } from '../hooks/use-session-data';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef } from 'react';
import MobileDetailsiOSPage from './MobileDetailsiOSPage';
import MobileErrorScreen from './MobileErrorScreen';
import DesktopErrorScreen from './DesktopErrorScreen';

export default function EconCalendarTableData() {
  const {
    data,
    dates,
    isDataLoading,
    isTabChanged,
    activeTab,
    showDetail,
    errorMessage,
    isClearFilters,
  } = useEconCalendar();
  const { t } = useTranslation();
  const { isIos, eventIdForDetails } = useEconCalendarParams();
  const mainSectionRef = useRef<HTMLDivElement | null>(null);

  const { locale, baseColorSkeleton, highlightColorSkeleton } = useEconCalendarParams();
  const { timezoneOffset } = useSessionData();
  const isMobile = useScreenDimensions();
  const shimmerHeight = isMobile ? '33.3%' : 230;
  const uniqueDates =
    dates &&
    dates?.reduce((accumulator, currentValue) => {
      const date = currentValue.substring(0, 10);
      if (data && Object.values(data).length > 0 && data[currentValue]?.length > 0) {
        if (!accumulator[date]) {
          accumulator[date] = [currentValue];
        } else {
          accumulator[date].push(currentValue);
        }
      }
      return accumulator;
    }, {});

  useEffect(() => {
    const handleFocus = (event) => {
      if (mainSectionRef.current && mainSectionRef.current.contains(document.activeElement)) {
        const activeElement = document.activeElement;
        const rect = activeElement.getBoundingClientRect();
        const mainSectionRect = mainSectionRef.current.getBoundingClientRect();
        if (rect.top < mainSectionRect.top + 56 || rect.bottom > mainSectionRect.bottom) {
          activeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
          mainSectionRef.current.scrollTop -= 66;
        }
      }
    };

    document.addEventListener('focusin', handleFocus);
    return () => {
      document.removeEventListener('focusin', handleFocus);
    };
  }, []);

  return (
    <>
      {!eventIdForDetails && (
        <div
          className={`${
            isMobile ? style.mainSectionMobile : style.mainSectionHeight
          } ${style.mainSection} ${
            isMobile && isIos ? style.iosMainSection : style.none
          } ${isMobile && style.mobileStyling}`}
          data-testid="data-section"
          ref={mainSectionRef}
        >
          {dates &&
          dates.length > 0 &&
          !isDataLoading &&
          !isTabChanged &&
          uniqueDates &&
          Object.keys(uniqueDates) &&
          Object.keys(uniqueDates).length > 0 ? (
            Object.keys(uniqueDates)
              .sort()
              .map((date, _) => {
                return (
                  data &&
                  Object.entries(data).length > 0 && (
                    <div
                      className={isMobile ? style.dateDataWrapperMobile : style.dateDataWrapper}
                      key={date}
                    >
                      <div
                        className={isMobile ? style.dateMobileWrapper : style.dateDesktopWrapper}
                      >
                        <div
                          className={`${isMobile ? style.dateMobile : style.date} ${
                            isMobile ? 'calendar-medium' : 'none'
                          }`}
                        >
                          {convertDateToString(
                            date,
                            timezoneOffset,
                            isMobile,
                            locale != 'en' ? locale : undefined,
                            t,
                          )}
                        </div>
                      </div>
                      {!isMobile && <div className={style.gapFill}></div>}
                      {!showDetail && activeTab === 'earnings-reports' && isMobile && (
                        <EarningsMobileHeadings />
                      )}
                      <div
                        className={isMobile ? style.tableRowsWrapperMobile : style.tableRowsWrapper}
                      >
                        <EconCalendarTableRows data={data} date={date} />
                      </div>
                    </div>
                  )
                );
              })
          ) : isDataLoading || isClearFilters ? (
            <SkeletonTheme baseColor={baseColorSkeleton} highlightColor={highlightColorSkeleton}>
              <div className={isMobile ? style.shimmerMobile : style.shimmer}>
                <Skeleton width={'100%'} height={shimmerHeight} />
                <Skeleton width={'100%'} height={shimmerHeight} />
                <Skeleton width={'100%'} height={shimmerHeight} />
              </div>
            </SkeletonTheme>
          ) : isMobile ? (
            <MobileErrorScreen t={t} errorMessage={errorMessage} />
          ) : (
            <DesktopErrorScreen t={t} errorMessage={errorMessage} />
          )}
        </div>
      )}
      {showDetail && isMobile && isIos ? (
        <MobileDetailsiOSPage />
      ) : (
        eventIdForDetails && (
          <SkeletonTheme baseColor={baseColorSkeleton} highlightColor={highlightColorSkeleton}>
            <div className={isMobile ? style.shimmerMobile : style.shimmer}>
              <Skeleton width={'100%'} height={shimmerHeight} />
              <Skeleton width={'100%'} height={shimmerHeight} />
              <Skeleton width={'100%'} height={shimmerHeight} />
            </div>
          </SkeletonTheme>
        )
      )}
    </>
  );
}
