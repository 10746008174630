import { useEconCalendarParams } from '../../hooks/use-econ-calendar-params';

const NoNetworkMobile = () => {
  const { currentTheme } = useEconCalendarParams();
  const fillColor = currentTheme === 'dark' ? '#8292a6' : '#5a5e5f';

  return (
    <svg width="73" height="72" viewBox="0 0 73 72" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M66.5 41.175C66.5 34.575 61.375 29.225 54.875 28.775C53.175 20.15 45.6 13.675 36.5 13.675C33.175 13.675 30.075 14.575 27.375 16.1L31.1 19.825C32.775 19.1 34.575 18.675 36.5 18.675C44.1 18.675 50.25 24.825 50.25 32.425V33.675H54C58.15 33.675 61.5 37.025 61.5 41.175C61.5 43.65 60.3 45.8 58.475 47.175L62 50.7C64.725 48.4 66.5 45.025 66.5 41.175ZM17.525 13.325L14 16.85L20.925 23.775H19.875C12.35 24.575 6.5 30.95 6.5 38.675C6.5 46.95 13.225 53.675 21.5 53.675H50.825L55.825 58.675L59.35 55.15L17.525 13.325ZM21.5 48.675C15.975 48.675 11.5 44.2 11.5 38.675C11.5 33.15 15.975 28.675 21.5 28.675H25.825L45.825 48.675H21.5Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default NoNetworkMobile;
