import { useEconCalendarParams } from '../../hooks/use-econ-calendar-params';

export default function EventCollapse() {
  const { currentTheme } = useEconCalendarParams();
  const fillColor = currentTheme === 'dark' ? '#fff' : '#11203E';
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.56015 6.42812C9.61818 6.37003 9.68708 6.32394 9.76293 6.2925C9.83878 6.26106 9.92008 6.24487 10.0022 6.24487C10.0843 6.24487 10.1656 6.26106 10.2414 6.2925C10.3173 6.32394 10.3862 6.37003 10.4442 6.42812L15.1331 11.1194C15.1925 11.1772 15.2397 11.2462 15.2722 11.3224C15.3047 11.3985 15.3217 11.4804 15.3223 11.5632C15.3228 11.646 15.3069 11.7281 15.2755 11.8047C15.2441 11.8814 15.1978 11.951 15.1392 12.0096C15.0807 12.0681 15.0111 12.1145 14.9345 12.1459C14.8579 12.1774 14.7758 12.1933 14.693 12.1928C14.6101 12.1922 14.5283 12.1753 14.4521 12.1428C14.3759 12.1104 14.3069 12.0631 14.2491 12.0038L10.0022 7.75481L5.75534 12.0038C5.69758 12.0632 5.62858 12.1106 5.55236 12.1431C5.47613 12.1756 5.39421 12.1927 5.31134 12.1933C5.22847 12.1938 5.14631 12.1779 5.06964 12.1465C4.99296 12.115 4.92331 12.0687 4.86472 12.0101C4.80613 11.9514 4.75977 11.8818 4.72834 11.8051C4.69692 11.7284 4.68105 11.6463 4.68165 11.5634C4.68226 11.4805 4.69934 11.3986 4.73188 11.3224C4.76443 11.2462 4.8118 11.1772 4.87125 11.1194L9.56015 6.42812Z"
        fill={fillColor}
      />
    </svg>
  );
}
