export const accounts = {
  iggb: 'United Kingdom',
  igat: 'Austria',
  igau: 'Australia',
  ignz: 'Australia',
  igcn: 'Australia',
  igde: 'Germany',
  iges: 'Spain',
  igfr: 'France',
  igit: 'Italy',
  ignl: 'Netherlands',
  igno: 'Norway',
  igse: 'Sweden',
  igsg: 'Singapore',
  igjp: 'Japan',
  igza: 'South Africa',
  igch: 'Switzerland',
  igus: 'United States',
  igintlfr: 'France',
  igintlpo: 'Portugal',
  igie: 'Ireland',
  igae: 'United Arab Emirates',
  igfi: 'Finland',
  igdk: 'Denmark',
  igcz: 'Czech Republic',
  iggr: 'Greece',
  ighu: 'Hungary',

  igbg: 'Bulgaria',
  igcy: 'Cyprus',
  igee: 'Estonia',
  ighr: 'Croatia',
  iglt: 'Lithuania',
  iglv: 'Latvia',
  igmt: 'Malta',
  igro: 'Romania',
  igsi: 'Slovenia',
  igsk: 'Slovakia',
  igke: 'Kenya',
  igis: 'Iceland',
  igintl: 'Bermuda',

  // igintlen: 'European English', -> no default country filter selected for country id -> igintlen
};

export const countryContinentMapping: Record<string, string[]> = {
  Americas: ['igus'],
  'Euro Area': [
    'igbg',
    'igcy',
    'igee',
    'ighr',
    'iglt',
    'iglv',
    'igmt',
    'igro',
    'igsi',
    'igsk',
    'igde',
    'igfr',
    'iges',
    'igit',
    'iggr',
    'igat',
    'igie',
    'igfi',
    'ignl',
    'igintlpo',
  ],
  'Non-Eurozone Europe': ['igis', 'igcz', 'igdk', 'iggb', 'igse', 'igch', 'igno', 'ighu', 'igintl'],
  'Asia and Pacific': ['igjp', 'igau', 'ignz', 'igcn', 'igsg'],
  'Middle East and Africa': ['igke', 'igza', 'igae'],
};
