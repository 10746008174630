import { useEffect, useRef, useState } from 'react';
import { useEconCalendar } from '../../hooks/useEconCalendar';
import style from '../../styles/filters/ImportanceFilter.module.css';
import { useTranslation } from 'react-i18next';
import CustomCheckbox from './CustomCheckbox';
import useScreenDimensions from '../../hooks/useScreenDimension';
import { useEconCalendarParams } from '../../hooks/use-econ-calendar-params';
import CrossIconCalendarTypeIos from '../../assets/svgs/CrossIconCalendarTypeIos';
import CancelSearchIos from '../../assets/svgs/CancelSearchIos';

const importanceLevels = ['high', 'medium', 'low'];

const ImportanceFilter = () => {
  const {
    selectedImportance,
    handleImportance,
    showFilters,
    extendedImportanceFilter,
    updateExtendedImportanceFilter,
  } = useEconCalendar();

  const sectionRef = useRef(null);
  const { t } = useTranslation();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const isMobile = useScreenDimensions();
  const { isIos } = useEconCalendarParams();

  const handleKeySelection = (importance) => {
    handleImportance(importance);
    updateExtendedImportanceFilter();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const clickedElement = event.target;
      const importanceFilterElement = clickedElement.closest('.importanceFilter'); // Select closest ancestor with class
      if (
        extendedImportanceFilter &&
        sectionRef.current &&
        !sectionRef.current.contains(event.target) &&
        !importanceFilterElement
      ) {
        updateExtendedImportanceFilter();
      }
    };

    const handleKeyDown = (event) => {
      const { key } = event;
      const optionsLength = importanceLevels.length;

      if (key === 'ArrowDown') {
        setSelectedIndex((prevIndex) => (prevIndex < optionsLength - 1 ? prevIndex + 1 : 0));
        event.preventDefault();
      } else if (key === 'ArrowUp') {
        setSelectedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : optionsLength - 1));
        event.preventDefault();
      } else if (key === 'Escape') {
        updateExtendedImportanceFilter();
      } else if (key === ' ' || key === 'Enter') {
        handleKeySelection(importanceLevels[selectedIndex]);
      }
      event.stopPropagation();
    };

    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [extendedImportanceFilter, selectedIndex, handleKeySelection, updateExtendedImportanceFilter]);

  const handleSelection = (importance, event) => {
    handleImportance(importance);
    event.stopPropagation();
  };

  return (
    <>
      {isMobile && isIos && (
        <div className={style.modal} onClick={updateExtendedImportanceFilter} role="button"></div>
      )}
      <div
        className={
          showFilters
            ? style.importanceFilterMobilePage
            : isMobile && isIos
              ? style.importanceFilterPageIos
              : style.expandedImportanceSection
        }
        ref={sectionRef}
      >
        {showFilters && (
          <div className={`${style.importanceHeading} ${isMobile ? 'calendar-medium' : 'none'}`}>
            {t('Importance')}
          </div>
        )}
        {isMobile && isIos && (
          <div className={style.importanceIosMenuSection}>
            <div className={style.importanceIosMenuText}>
              <span>{t('Importance')} </span>
            </div>
            <button onClick={updateExtendedImportanceFilter}>
              <CancelSearchIos />
            </button>
          </div>
        )}
        <div
          className={
            showFilters
              ? style.importanceContainerFilterPage
              : isMobile && isIos
                ? style.expandedContainerIosMobile
                : style.expandedContainer
          }
        >
          {importanceLevels.map((importance, index) => {
            const styleWrapper =
              importance === 'high'
                ? showFilters
                  ? style.highImportanceWrapperFilterPage
                  : isMobile && isIos
                    ? style.highImportanceWrapperIos
                    : style.highImportanceWrapper
                : importance === 'medium'
                  ? showFilters
                    ? style.mediumImportanceWrapperFilterPage
                    : isMobile && isIos
                      ? style.mediumImportanceWrapperIos
                      : style.mediumImportanceWrapper
                  : showFilters
                    ? style.lowImportanceWrapperFilterPage
                    : isMobile && isIos
                      ? style.lowImportanceWrapperIos
                      : style.lowImportanceWrapper;
            const styleDiv =
              importance === 'high'
                ? showFilters
                  ? style.highImportanceFilterPage
                  : isMobile && isIos
                    ? style.highImportanceIos
                    : style.highImportance
                : importance === 'medium'
                  ? showFilters
                    ? style.mediumImportanceFilterPage
                    : isMobile && isIos
                      ? style.mediumImportanceIos
                      : style.mediumImportance
                  : showFilters
                    ? style.lowImportanceFilterPage
                    : isMobile && isIos
                      ? style.lowImportanceIos
                      : style.lowImportance;

            return (
              <div
                key={importance}
                className={`${
                  !isMobile && selectedIndex === index ? style.applyBorder : ''
                } ${styleWrapper}`}
                data-testid={`div_${importance}`}
              >
                <div
                  className={`${
                    showFilters
                      ? style.checkBoxMobileFilterPage
                      : isIos && isMobile
                        ? style.checkBoxIos
                        : style.checkBox
                  }`}
                >
                  <div>
                    <input
                      type="checkbox"
                      id={importance}
                      checked={selectedImportance?.includes(importance)}
                      onChange={() => handleImportance(importance)}
                      className={style.checkbox_input}
                    />
                    <CustomCheckbox
                      onClickHandler={handleImportance}
                      value={importance}
                      checked={selectedImportance?.includes(importance)}
                    />
                  </div>
                  <button
                    className={`${styleDiv} ${isMobile ? 'calendar-regular' : 'none'}`}
                    onClick={(e) => {
                      handleSelection(importance, e);
                    }}
                    aria-label={t(importance)}
                    data-testid={importance}
                  >
                    <p>
                      <span>{t(importance[0])}</span>
                    </p>
                    <span>{t(importance)}</span>
                  </button>
                </div>
              </div>
            );
          })}
        </div>
        {isMobile && isIos && (
          <div className={style.applyBtnIosWrapper}>
            <button className={style.applyBtnIos} onClick={updateExtendedImportanceFilter}>
              {t('Ok')}
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default ImportanceFilter;
