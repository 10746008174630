import { useEffect, useRef, useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import EventsBasedOnImportance from './EventsBasedOnImportance';
import useScreenDimensions from '../hooks/useScreenDimension';
import { useTranslation } from 'react-i18next';
import { useEconCalendarParams } from '../hooks/use-econ-calendar-params';
import CalendarIcon from '../assets/svgs/CalendarIcon';
import style from '../styles/DayPicker.module.css';
import { useEconCalendar } from '../hooks/useEconCalendar';
import { tabsTitles } from '../utils/tabsDataAccess-utils';
import { getDateForAnalytics } from '../utils/econ-utils';
import CancelSearchIos from '../assets/svgs/CancelSearchIos';

const pickOptions = [
  'yesterday',
  'today',
  'tomorrow',
  'next 7 days',
  'next 14 days',
  'custom date',
];

const DayPicker = () => {
  const {
    activeDateFilter,
    changeDateFilter,
    activeTab,
    isDataLoading,
    showCalendar,
    updateCalendarVisibility,
  } = useEconCalendar();
  const isImportanceValid = activeTab === 'events' || activeTab === 'earnings-reports';
  const isMobile = useScreenDimensions();
  const { t } = useTranslation();
  const { isIos, isAndroid, currentTheme, fullLocale } = useEconCalendarParams();
  const calendarBackgroundStyle = currentTheme === 'dark' ? style.darkTheme : style.whiteTheme;
  const [focusedButton, setFocusedButton] = useState(null);
  const [date, setDate] = useState(
    activeDateFilter?.[0] === 'custom date' ? new Date(activeDateFilter[1]) : new Date(),
  );
  const calendarRef = useRef(null);
  const dayPickerRef = useRef(null);
  const today = new Date();
  const maxDate = new Date(today.setMonth(today.getMonth() + 3));

  const handleButtonClick = (event, value, buttonElement) => {
    if (value !== 'custom date' && activeDateFilter[0] !== value) {
      dataLayer.push({
        event: 'econ_calendar_interaction',
        interaction_type: 'navigation',
        interaction_value: 'date',
        econ_calendar_period: value, // can be yesterday, today, tomorrow, next 7 days, next 14 days
        econ_calendar_tab: tabsTitles[activeTab], // the tab the user is currently viewing
      });
      changeDateFilter(value, '');
      updateCalendarVisibility(false);
    }

    if (value === 'custom date') {
      event.stopPropagation();
      updateCalendarVisibility(!showCalendar);
    }

    setFocusedButton(value);
    if (buttonElement) {
      buttonElement.blur();
    }
  };

  const handleKeyDown = (event, value) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleButtonClick(event, value, event.currentTarget);
      event.preventDefault();
    }
  };

  const formatDay = (locale, date) => {
    if (locale === 'ja-JP' || locale === 'ja_JP' || locale === 'ja-jp' || locale === 'ja_jp')
      return date.getDate();
    else return date.toLocaleDateString(locale, { day: '2-digit' });
  };

  const handleClickOutside = (event) => {
    if (
      calendarRef.current &&
      !calendarRef.current.contains(event.target) &&
      !event.target.closest('button')
    ) {
      updateCalendarVisibility(false);
    }
  };

  const handleGlobalKeyDown = (event) => {
    if (event.key === 'Escape') {
      showCalendar && updateCalendarVisibility(false);
    }
  };

  useEffect(() => {
    activeDateFilter && activeDateFilter?.[0] === 'custom date'
      ? setDate(new Date(activeDateFilter[1]))
      : setDate(new Date());
  }, [activeDateFilter]);

  useEffect(() => {
    if (showCalendar) {
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('keydown', handleGlobalKeyDown);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleGlobalKeyDown);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleGlobalKeyDown);
    };
  }, [showCalendar]);

  useEffect(() => {
    // Find the clicked tab element and scroll into view
    const clickedTab = document.getElementById(activeDateFilter[0]);
    if (clickedTab) {
      clickedTab.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center',
      });
    }
  }, [activeDateFilter]);

  const onChange = (newDate) => {
    dataLayer.push({
      event: 'econ_calendar_interaction',
      interaction_type: 'navigation',
      interaction_value: 'date',
      econ_calendar_date: getDateForAnalytics(newDate), // the date the user navigated to in YYYY-MM-DD format
      econ_calendar_tab: tabsTitles[activeTab], // the tab the user is currently viewing
    });
    setDate(newDate);
    updateCalendarVisibility(false);
    changeDateFilter('custom date', newDate);
  };

  return (
    <div
      className={`${isMobile ? (isAndroid ? style.dayPickerAndroid : style.dayPickerMobile) : style.dayPickerDesktop}`}
    >
      <div
        className={`${isMobile ? style.dayPickerContainerMobile : style.dayPickerContainer} ${isAndroid ? style.androidHeight : style.none}`}
        ref={dayPickerRef}
      >
        {pickOptions.map((value) => {
          const isActive = activeDateFilter[0] === value;
          return (
            <div
              role="button"
              tabIndex={0}
              id={value}
              key={value}
              className={`${isMobile ? style.mobileOption : style.option} ${isAndroid ? style.androidOption : style.none}
                ${
                  isActive
                    ? !isMobile
                      ? style.activeDate
                      : style.activeDateMobile
                    : !isMobile
                      ? style.inactive
                      : style.inactiveMobile
                }
                ${focusedButton === value ? style.focusedButton : ''}
                ${isDataLoading ? style.noCursor : style.cursor} ${isIos ? style.iosOption : isAndroid ? style.androidOption : style.none}`}
              data-testid={value}
              onClick={(e) => handleButtonClick(e, value, e.currentTarget)}
              onKeyDown={(event) => handleKeyDown(event, value)}
              aria-pressed={isActive}
              aria-label={t(value)}
              onBlur={() => setFocusedButton(null)}
              disabled={isDataLoading}
            >
              <div className={isMobile ? 'calendar-medium' : 'none'}> {t(value)} </div>
              {value === 'custom date' ? (
                <div className={style.dateWrapper}>
                  {!isMobile && <CalendarIcon />}
                  {showCalendar && (
                    <div
                      onClick={(e) => e.stopPropagation()}
                      ref={calendarRef}
                      role="button"
                      tabIndex={0}
                      onKeyDown={(event) => event.stopPropagation()}
                      aria-label={t('Select custom date')}
                    >
                      {isMobile && (
                        <div
                          className={style.modal}
                          onClick={() => updateCalendarVisibility(false)}
                          role="button"
                        />
                      )}
                      {isMobile && (
                        <div className={isIos ? style.iosCustomDate : style.androidCustomDate}>
                          <div
                            className={
                              isIos ? style.customDateIosMenu : style.customDateAndroidMenu
                            }
                          >
                            <button
                              className={style.cancelBtn}
                              onClick={() => updateCalendarVisibility(false)}
                            >
                              {t('cancel')}
                            </button>
                            {isIos && <span> {t('custom-date')} </span>}
                            {isAndroid && (
                              <button
                                className={style.doneBtn}
                                onClick={() => updateCalendarVisibility(false)}
                              >
                                {t('done')}
                              </button>
                            )}
                            {isIos && (
                              <button
                                className={style.iosCalendarCancelButton}
                                onClick={() => updateCalendarVisibility(false)}
                              >
                                <CancelSearchIos />
                              </button>
                            )}
                          </div>
                          <div
                            className={`${isIos ? style.iosCalendarWrapper : style.androidCalendarWrapper} calendarIos ${isIos ? style.iosFont : isAndroid ? style.androidFont : style.webFont}`}
                          >
                            <Calendar
                              className={`${style.iosCalendar} ${style.mobileTheme} calendar-regular`}
                              onChange={onChange}
                              value={date}
                              maxDate={maxDate}
                              locale={fullLocale}
                              formatDay={formatDay}
                              aria-label={t('Calendar')}
                            />
                          </div>
                        </div>
                      )}
                      {!isMobile && (
                        <Calendar
                          className={`${
                            !isMobile ? style.calendar : style.calendarMobile
                          } ${calendarBackgroundStyle} calendar-regular`}
                          onChange={onChange}
                          value={date}
                          maxDate={maxDate}
                          locale={fullLocale}
                          formatDay={formatDay}
                          aria-label={t('Calendar')}
                        />
                      )}
                    </div>
                  )}
                </div>
              ) : (
                isImportanceValid &&
                !isMobile && (
                  <EventsBasedOnImportance
                    activeDate={isActive}
                    dateFilterOption={value}
                    aria-hidden={!isActive}
                  />
                )
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DayPicker;
