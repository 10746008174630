import { useEffect, useState } from 'react';
import { useEconCalendar } from '../../hooks/useEconCalendar';
import style from '../../styles/filters/SearchBox.module.css';
import useScreenDimensions from '../../hooks/useScreenDimension';
import { useTranslation } from 'react-i18next';
import SearchIcon from '../IconComponents/SearchIcon';
import CrossIconDesktop from '../IconComponents/CrossIconDesktop';
import CrossIconMobile from '../IconComponents/CrossIconMobile';
import { useEconCalendarParams } from '../../hooks/use-econ-calendar-params';
import SearchIconIos from '../../assets/svgs/searchIconIos';
import CancelSearchIos from '../../assets/svgs/CancelSearchIos';

const SearchBox = () => {
  const [debouncedInputValue, setDebouncedInputValue] = useState('');
  const { activeTab, updateSearchText, isDataLoading, inputRef, searchText } = useEconCalendar();
  const [searchTextInput, setSearchTextInput] = useState('');
  const isMobile = useScreenDimensions();
  const { isIos } = useEconCalendarParams();
  const { t } = useTranslation();

  const search_placeholder = {
    events: 'Search for events',
    'earnings-reports': 'Search for earning announcements',
    'dividends-reports': 'Search for dividend announcements',
    ipos: 'Search for upcoming IPOs',
    'stock-splits': 'Search for upcoming stock splits',
  };

  const updateInput = (e) => {
    e.preventDefault();
    setSearchTextInput(e.target.value);
  };

  useEffect(() => {
    updateSearchText(debouncedInputValue.trim());
  }, [debouncedInputValue]);

  useEffect(() => {
    const delayInputTimeoutId = setTimeout(() => {
      setDebouncedInputValue(searchTextInput);
    }, 500);
    return () => clearTimeout(delayInputTimeoutId);
  }, [searchTextInput]);

  useEffect(() => {
    if (searchText === '') setSearchTextInput('');
  }, [searchText]);

  const clearText = () => {
    setSearchTextInput('');
  };

  return (
    <div
      className={`${isMobile ? style.searchBoxWrapperMobile : style.searchBoxWrapper} ${
        isMobile && isIos ? style.iosSearchBoxWrapper : style.none
      }`}
    >
      <div
        className={`${isMobile ? style.formMobile : style.form} ${
          isMobile && isIos ? style.iosInput : style.none
        }`}
      >
        {!isMobile && <SearchIcon />}
        {isMobile && isIos && <SearchIconIos />}
        <input
          type="text"
          placeholder={isMobile ? t('Search') : t(search_placeholder[activeTab])}
          className={`${isDataLoading ? style.disabled : style.not_disabled} ${
            isMobile ? 'calendar-medium' : 'none'
          }`}
          onChange={(e) => updateInput(e)}
          maxLength={50}
          data-testid="search_input"
          value={searchTextInput}
          aria-label="Search" // Added aria-label for accessibility
          disabled={isDataLoading}
          ref={inputRef}
        />
        {!isMobile && searchTextInput.length > 0 && (
          <button
            className={style.clearTextDesktopButtonShow}
            onClick={clearText}
            data-testid="clear-searchbox"
          >
            <CrossIconDesktop />
          </button>
        )}
        {isMobile && !isIos && (
          <button className={style.androidClearSearch} onClick={clearText}>
            <CrossIconMobile />
          </button>
        )}
        {isMobile && isIos && searchTextInput.length > 0 && (
          <button className={style.iosCancelSearchBtn} onClick={clearText}>
            <CancelSearchIos width="22" height="26" />
          </button>
        )}
      </div>
    </div>
  );
};

export default SearchBox;
